/**
 * Sorterdatepicker component
 *
 * @author João Dias <jdias@ubiwhere.com>
 *
 *
 */

import React, { useEffect, useState, useRef } from 'react'
import i18n from "i18next";
import { DateTime } from 'luxon';
import DatePicker from 'react-datepicker'
import Calendar from 'assets/icons/calendar'
import theme from 'ui/theme'
import { checkIsDefaultSelected, QuickOptions, returnOptionDate, presetByDate, generateLabel } from "./utils";
import * as Styles from './styles'

interface PropTypes {
  filterName: string,
  onChange: Function,
  range?: boolean,
  hasBorder?: boolean,
  defaultValue: { startDay: Date, endDay?: Date },
  presets?: Array<string>,
  minDate?: Date,
  reRender?: boolean,
  disabled?: boolean
  child?: boolean,
  noDate?: boolean
  showMonthYearDropdown?: boolean
}

interface ValueContainerProps extends React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> {
  isDefaultSelected: boolean,
  hasBorder?: boolean,
  filterName: string,
  label: string,
  noDate?: boolean
}

const ValueContainer = React.forwardRef<
  HTMLInputElement,
  ValueContainerProps
>((props, ref) => (
  <Styles.SelectContainer nopointer={props.disabled} hasBorder={props.hasBorder} isdefaultselected={props.isDefaultSelected} onClick={props.onClick} ref={ref}>
    {React.cloneElement(<Calendar />, { fill: props.isDefaultSelected ? theme().gray500Color : theme().primary })}
    <Styles.CurrentValueContainer isdefaultselected={props.isDefaultSelected}>{ props.noDate ? i18n.t('common.noDate') : props.isDefaultSelected ? props.filterName : props.label}</Styles.CurrentValueContainer>
    <Styles.ArrowContainer>
      <Styles.CustomDropdownArrow fill={props.isDefaultSelected ? theme().gray500Color : theme().primary} isdefaultselected={props.isDefaultSelected} />
    </Styles.ArrowContainer>
  </Styles.SelectContainer >
))

const FilterDatePicker = (props: PropTypes) => {

  const ref = useRef(false)

  const [startDate, setStartDate] = useState<Date | null>(props.defaultValue.startDay);
  const [label, setLabel] = useState(props.filterName);
  const [selectedQuickOption, setSelectedQuickOption]
    = useState<QuickOptions | null>(props.range ? "Hoje" : null);
  const [endDate, setEndDate] = useState<Date | null>(props.defaultValue.endDay ? props.defaultValue.endDay : props.defaultValue.startDay);
  const [isDefaultSelected, setIsDefaultSelected] = useState(true)

  const onChangeRange = (dates) => {

    const [start, end] = dates;
    setSelectedQuickOption(null);
    setStartDate(start);
    setEndDate(end);
    if (end) {
      setSelectedQuickOption(presetByDate(start, end));
    }

    props.onChange({ startDate: start, endDate: end });
    setLabel(generateLabel(start, end));
    setIsDefaultSelected(checkIsDefaultSelected(start, end, { startDay: new Date(), endDay: new Date() }))
  };

  const onChangeSingle = (date) => {
    setStartDate(date);
    setLabel(DateTime.fromJSDate(date).toFormat("dd/LL/yyyy"));
    setIsDefaultSelected(checkIsDefaultSelected(date, date, { startDay: new Date(), endDay: new Date() }))
    props.onChange({ startDate: date });
  };

  //temporary solution to useEffect
  useEffect(() => {
    if (!props.range) {
      setStartDate(props.defaultValue.startDay)
      setLabel(DateTime.fromJSDate(props.defaultValue.startDay).toFormat("dd/LL/yyyy"));
    }

    //must be executed after the first render only
    if (ref.current) {
      if (props.range) {
        onChangeRange([props.defaultValue.startDay, props.defaultValue.endDay])
      }
      else {
        onChangeSingle(props.defaultValue.startDay)
      }
    }
    else {
      if (props.child) {
        onChangeRange([props.defaultValue.startDay, props.defaultValue.endDay])
      }
      else {
        ref.current = true
      }
    }

    // eslint-disable-next-line
  }, [props.range, props.reRender])


  const selectOptionHandle = (option) => {
    setLabel(option);
    setSelectedQuickOption(option);
    const [start, end] = returnOptionDate(option);
    setIsDefaultSelected(checkIsDefaultSelected(start, end, { startDay: new Date(), endDay: new Date() }))
    setStartDate(start);
    setEndDate(end);
    props.onChange({ startDate: start, endDate: end });
  };

  //const isDefaultSelected = checkIsDefaultSelected(startDate, endDate, props.defaultValue);

  return (
    <Styles.Wrapper startDate={startDate} endDate={endDate} showTitle={!props.showMonthYearDropdown}>
      {props.range
        ?
        (<DatePicker
          disabled={props.disabled}
          popperClassName="calendarPopper"
          dateFormat="dd/MM/yyyy"
          onChange={dates => {
            onChangeRange(dates);
          }}
          onClickOutside={
            (e) => {
              if (startDate && endDate === null) {
                props.onChange({ startDate: startDate, endDate: startDate });
                setLabel(generateLabel(startDate, startDate));
                setIsDefaultSelected(checkIsDefaultSelected(startDate, startDate, { startDay: new Date(), endDay: new Date() }))
              }
            }
          }
          shouldCloseOnSelect={false}
          startDate={startDate}
          endDate={endDate}
          selectsRange={props.range}
          calendarStartDay={1}
          minDate={props.minDate}
          showMonthDropdown={props.showMonthYearDropdown}
          showYearDropdown={props.showMonthYearDropdown}
          customInput={<ValueContainer isDefaultSelected={isDefaultSelected} hasBorder={props.hasBorder} label={label} filterName={props.filterName} noDate={props.noDate} />}
          calendarContainer={({ children }) =>
            <Styles.CalendarContainer>
              <Styles.QuickOptionContainer>
                {
                  props.presets && props.presets.map(p => {
                    return <Styles.QuickOptionElement onClick={() => selectOptionHandle(p)} selected={selectedQuickOption === p}>{p}</Styles.QuickOptionElement>
                  })
                }
              </Styles.QuickOptionContainer>
              <div style={{ position: "relative" }}>{children}</div>
            </Styles.CalendarContainer>
          }
        />)
        :
        (<DatePicker
          popperClassName="calendarPopper"
          dateFormat="dd/MM/yyyy"
          showMonthDropdown={props.showMonthYearDropdown}
          showYearDropdown={props.showMonthYearDropdown}
          selected={startDate}
          onChange={date => {
            onChangeSingle(date);
          }}
          startDate={startDate}
          minDate={props.minDate}
          customInput={<ValueContainer isDefaultSelected={isDefaultSelected} label={label} filterName={DateTime.fromJSDate(props.defaultValue.startDay).toFormat("dd/LL/yyyy")} />}
        />)
      }

    </Styles.Wrapper>
  )
}

export default FilterDatePicker