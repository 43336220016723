import styled from 'styled-components'
import { CTableDataCell } from '@coreui/react-pro'
import { CustomTableRow } from 'shared/genericStyledComponents'

export const CustomCTableRow = styled(CustomTableRow)`

  td:first-child {
    padding-left:15px;
    width:10%;
  }

  td:nth-child(2){
    width:12%;
  }

  td:nth-child(3){
    width:5%;
  }

  td:nth-child(4){
    width:5%;
  }

  td:nth-child(5){
    width:6%;
    padding:0;
    padding-left:7px;
  }
`

export const NameCell = styled(CTableDataCell)`
  cursor: pointer;
`