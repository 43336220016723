import * as React from "react"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width={12}
            height={11}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M.667 5.5l.94.94 3.726-3.72v8.113h1.334V2.72l3.72 3.727.946-.947L6 .167.667 5.5z"
                fill={props.fill ? props.fill : "#636F83"}
            />
        </svg>
    )
}

export default SvgComponent