import React from 'react';
import Translation from 'shared/containers/Translation';
import { User } from 'types/users/user';

export const DEFAULT_USER : User = {
  email: '',
  uuid: '',
  role: '',
  userType: {
    id: 0,
    uid: 'guest'
  },
  token:''
};

export const GENERATE_TRANSLATIONS = (apiTranslations) => {
  let translations = {};
  
  Object.keys(apiTranslations).forEach((value, key) => {
    translations[value] = (
      <Translation transKey={value} key={key}>
        {apiTranslations[value]}
      </Translation>
    );
  });

  return translations;
};

export const createTranslationBundles = (translations) => {
  let translationBundles = { pt: {}, en: {} };
  translations.forEach((module) => {
    module.properties.forEach((property) => {
      if (property.key) {
        translationBundles.pt[module.name] = {
          ...translationBundles.pt[module.name],
          [property.key]: property.value,
        };
        translationBundles.en[module.name] = {
          ...translationBundles.en[module.name],
          [property.key]: property.valueEn,
        };
      }
    });
  });
  return translationBundles;
};

export const createRoutesTranslationBundles = (translations) => {
  let translationBundles = { routes: {}};
  translations.translations.forEach((property) => {
    if (property.key) {
      translationBundles.routes = {
        ...translationBundles.routes,
        [property.key]: property.pt,
      };
    }
  });

  return translationBundles
}