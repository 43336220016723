import { PayloadAction } from '@reduxjs/toolkit'
import { takeLatest, putResolve, call, select } from 'redux-saga/effects'
import API from 'api'
import { actions, RootState } from 'store/rootSlices'
import { toast } from 'react-toastify';
import * as Check from 'validations';
import { CircuitFormValidationsEdit, CircuitFormValidations, stopRowValidation, SubmitSagaPayload } from '../utils'
import i18n from 'i18next';
import { hasPermission } from 'permissions'
import config from 'config'

//state
const { PERMISSIONS } = config
const crcID = (state: RootState) => state.TripCircuitForm.circuitID
const inbndS = (state: RootState) => state.TripCircuitForm.inboundStops
const outbndS = (state: RootState) => state.TripCircuitForm.outboundStops

function* onMountSaga({ payload }: PayloadAction<number>) {
  const { permissions } = yield select((state: RootState) => state.App);

  try {
    //get stops
    const locals = yield call(API.Locals.GetAllStops)

    if(locals){
      yield putResolve(actions.TripCircuitForm.setInboundStops(locals))
      yield putResolve(actions.TripCircuitForm.setOutboundStops(locals))
    }
  } catch (error) {}

  if (payload) {
    
    yield putResolve(actions.TripCircuitForm.setHasEditPermission(hasPermission(permissions, PERMISSIONS.TRIPS.CIRCUITS.EDIT_CIRCUIT)))
    const circuitID = payload

    try {
      //get circuit from api
      const circuitInfo = yield call(API.Circuits.GetCircuit, circuitID)

      if (circuitInfo) {
        const inS = yield select(inbndS)
        const outS = yield select(outbndS)
        
        yield putResolve(actions.TripCircuitForm.setCircuitID(circuitID))
        yield putResolve(actions.TripCircuitForm.setAction(true))
        yield putResolve(actions.TripCircuitForm.populateForm(circuitInfo))
        yield putResolve(actions.TripCircuitForm.populateInboundStops({inboundStops:circuitInfo.inboundStops, inS}))
        yield putResolve(actions.TripCircuitForm.populateOutboundStops({outboundStops:circuitInfo.outboundStops, outS}))
      }
    } catch (error) {
    }

  } else {    
    // creating a new circuit -> (get stops first)
    yield putResolve(actions.TripCircuitForm.setAction(false))
  }
}

function* onSubmitSaga({ payload }: PayloadAction<SubmitSagaPayload>) {

  yield putResolve(actions.TripCircuitForm.setSubmitButtonDisabled(true))

  const validation = payload.edit === true ?
    Check.checkValidationAlternative(payload.circuitForm, CircuitFormValidationsEdit)
    :
    Check.checkValidationAlternative(payload.circuitForm, CircuitFormValidations)

  const outboundStopsValidation = Check.checkListValidation(payload.outboundStops.filter(c => c.isActive.value === true), stopRowValidation)
  const inboundStopsValidation = Check.checkListValidation(payload.inboundStops.filter(c => c.isActive.value === true), stopRowValidation)

  if (validation.invalid === true || outboundStopsValidation.invalid || inboundStopsValidation.invalid) {
    const updateValidationMessages = Check.setAndCheckValidationAlternative(payload.circuitForm, payload.circuitForm, CircuitFormValidationsEdit)

    yield putResolve(actions.TripCircuitForm.onUpdateStopsValidationMessages({
      outboundStopsValidation, 
      inboundStopsValidation, 
      outbounds: payload.outboundStops, 
      inbounds: payload.inboundStops
    }))
    yield putResolve(actions.TripCircuitForm.onFormChange(updateValidationMessages))
    yield putResolve(actions.TripCircuitForm.setSubmitButtonDisabled(false))
    toast.error(i18n.t("toasts.invalidFields"));
  }
  else {
    if (!payload.edit) {
      
      try {
        const finalForm = { 
          circuitForm: payload.circuitForm,
          outboundStops: payload.outboundStops,
          inboundStops: payload.inboundStops,  
        }
        
        const circuit = yield call(API.Circuits.CreateCircuit, finalForm)

        if (circuit) {
          yield putResolve(actions.App.navigateTo('/viagens/configuracoes'))
          yield putResolve(actions.Tripsconfiguration.setMakeApiCals(true))
          toast.success(i18n.t("toasts.createCircuitSuccess"));
        }

      } catch (error) {
        toast.error(i18n.t("toasts.createCircuitError"));
      } 
    }
    else {
      const circuitID = yield select(crcID)
      
      try {
        const success = yield call(API.Circuits.EditCircuit, circuitID, payload)

        if (success) {
          yield putResolve(actions.App.navigateTo('/viagens/configuracoes'))
          toast.success(i18n.t("toasts.editCircuitSuccess"))
        }
      } catch (error) {
        toast.warning(i18n.t("toasts.editCircuitError"))
      }
    }
  }

  yield putResolve(actions.TripCircuitForm.setSubmitButtonDisabled(false))
}

function* onUnmountSaga() {}


export default function* watcherSignin() {
  yield takeLatest('tripcircuitform/onMount', onMountSaga)
  yield takeLatest('tripcircuitform/onUnmount', onUnmountSaga)
  yield takeLatest('tripcircuitform/onSubmit', onSubmitSaga)
}


