/**
 * Historylogrow component
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */

 import { CTableDataCell } from '@coreui/react-pro';
 import React from 'react'
 import { ActionsCell, NameAndRoleCell } from 'shared/genericStyledComponents'
 import { Text, CustomCTableRow } from './styles'
 import { DateTime } from 'luxon';
 import config  from 'config';
import { parseChangedFields } from 'scenes/Historylog/utils';

const Historylogrow = (props) => {

  const { DATE_FORMAT } = config
  const {
    t,
    log:{
      date,
      diff,
      //extra,
      //new,
      old
    }
  } = props

  const historyLog = parseChangedFields(diff)
  
  return (
    <>
      <CustomCTableRow overlay={false}>
        <CTableDataCell>{date && DateTime.fromSQL(date).toFormat(DATE_FORMAT.DATE)}</CTableDataCell>
        <CTableDataCell>{date && DateTime.fromSQL(date).toFormat(DATE_FORMAT.TIME)}</CTableDataCell>
        <NameAndRoleCell><h5>{old?.name}</h5><h6>{old?.professionalCategory}</h6></NameAndRoleCell>
        <CTableDataCell>
          {
            historyLog?.map((el) => {
              return (
                <Text>
                  {
                    t("historyLog.logDescription", {
                      field: el.field,
                      old: el.oldValue,
                      new: el.newValue
                    })
                  }
                </Text>)
            })
          }
        </CTableDataCell>
        <CTableDataCell></CTableDataCell>
        <ActionsCell>
        </ActionsCell>
      </CustomCTableRow>
    </>
  )
}

export default Historylogrow