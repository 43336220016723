/**
 * Fleetmaintenance scene sagas
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */
import { putResolve, takeLatest, select, call } from 'redux-saga/effects'
import { actions, RootState } from 'store/rootSlices';
import API from 'api'
import { toast } from 'react-toastify';
import i18n from 'i18next';
import { AnyAction, PayloadAction } from '@reduxjs/toolkit';
import { MaintenancesList } from 'types/fleet/maintenance';
import { DateTime } from 'luxon'

//import { actions } from 'store/rootSlices'

function* onMountFleetMaintenance({ payload }: AnyAction) {
  try {
    yield getURLParamsSaga(payload);
    yield putResolve(actions.FleetMaintenance.fetchFinishedMaintenances());
    yield putResolve(actions.FleetMaintenance.fetchRequestedMaintenances());

  } catch (err) {
  }
}

function* fetchFinishedMaintenancesSaga() {

  try {
    const { finishedPage, searchTerm, sort, filter, vehicle } = yield select((state: RootState) => state.FleetMaintenance);
    yield putResolve(actions.FleetMaintenance.setLoading(true));

    const finished: MaintenancesList = yield call(API.Fleet.GetAllMaintenances, finishedPage, searchTerm, sort, true, filter, vehicle ? vehicle : undefined);

    if (finished) {
      yield putResolve(actions.FleetMaintenance.setTotalFinished(finished.count));
      yield putResolve(actions.FleetMaintenance.setMaintenancesFinished(finished.results ?? []));
    }
  } catch (err) {
    toast.error(i18n.t("toasts.maintenanceGetError"));
  } finally {
    yield putResolve(actions.FleetMaintenance.setLoading(false));
  }
}

function* fetchRequestedMaintenancesSaga() {

  try {
    const { requestedPage, searchTerm, sort, filter, vehicle } = yield select((state: RootState) => state.FleetMaintenance);
    yield putResolve(actions.FleetMaintenance.setLoading(true));

    const requested: MaintenancesList = yield call(API.Fleet.GetAllMaintenances, requestedPage, searchTerm, sort, false, filter, vehicle ? vehicle : undefined);

    if (requested) {
      yield putResolve(actions.FleetMaintenance.setTotalRequested(requested.count));
      yield putResolve(actions.FleetMaintenance.setMaintenancesRequested(requested.results ?? []));
    }
  } catch (err) {
    toast.error(i18n.t("toasts.maintenanceGetError"));
  } finally {
    yield putResolve(actions.FleetMaintenance.setLoading(false));
  }
}

function* fetchAllMaintenances() {
  try {
    yield putResolve(actions.FleetMaintenance.setRequestedPage(1))
    yield putResolve(actions.FleetMaintenance.setFinishedPage(1))

    yield putResolve(actions.FleetMaintenance.fetchRequestedMaintenances())
    yield putResolve(actions.FleetMaintenance.fetchFinishedMaintenances())

  } catch (error) {
    toast.error(i18n.t("toasts.ServicesGetError"))
  }
}

function* onUnmountSaga() {
  yield putResolve(actions.FleetMaintenance.setMaintenancesFinished([]));
  yield putResolve(actions.FleetMaintenance.setMaintenancesRequested([]));
  yield putResolve(actions.FleetMaintenance.setLoading(false));
}

function* getURLParamsSaga(payload: AnyAction) {

  let params = new URLSearchParams(payload.location.search)

  const {
    searchTerm,
    filter,
    vehicle,
    date
  } = yield select((state: RootState) => state.FleetMaintenance);

  if (params.toString() !== "") {
    yield putResolve(actions.FleetMaintenance.populateFiltersOnMount({
      searchTerm: params.get('search') ?? "",
      filter: params.get('vehicleType') ?? "",
      vehicle: params.get('vehicle') ?? "",
      date: DateTime.fromISO(params.get('date')).toJSDate() ?? DateTime.now().toJSDate(),
    }))
  }
  else {
    params.set('search', searchTerm)
    params.set('vehicleType', filter)
    params.set('vehicle', vehicle)
    params.set('date', DateTime.fromJSDate(date).toISODate())
    payload.replace({ search: (params).toString() })
  }
}


function* setTabSaga({ payload }: PayloadAction<string>) {

  switch (payload) {
    case i18n.t('maintenances.requested'):
      yield putResolve(actions.FleetMaintenance.setTabName(i18n.t('maintenances.requested')))
      break;
    case i18n.t('maintenances.finished'):
      yield putResolve(actions.FleetMaintenance.setTabName(i18n.t('maintenances.finished')))
      break;
    default:
      break;
  }
}

export default function* watcherFleetMaintenance() {
  yield takeLatest('Fleetmaintenance/onMount', onMountFleetMaintenance)
  yield takeLatest('Fleetmaintenance/onUnmount', onUnmountSaga)
  yield takeLatest('Fleetmaintenance/fetchRequestedMaintenances', fetchRequestedMaintenancesSaga)
  yield takeLatest('Fleetmaintenance/fetchFinishedMaintenances', fetchFinishedMaintenancesSaga)
  yield takeLatest('Fleetmaintenance/getURLParams', getURLParamsSaga);
  yield takeLatest('Fleetmaintenance/setTab', setTabSaga);
  yield takeLatest('Fleetmaintenance/setSearchTerm', fetchAllMaintenances)
  yield takeLatest('Fleetmaintenance/setFilter', fetchAllMaintenances);
  yield takeLatest('Fleetmaintenance/setVehicle', fetchAllMaintenances);
  yield takeLatest('Fleetmaintenance/setDate', fetchAllMaintenances);
  yield takeLatest('Fleetmaintenance/setSort', fetchAllMaintenances)
}


