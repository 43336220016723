/**
 * Changepassword scene sagas
 *
 * @author Manuel Gil <mgil@ubiwhere.pt>
 *
 *
 */
import API from 'api'
import { DEFAULT_USER } from 'app/utils';
//import { removeAuthorizationInterceptor } from 'interceptors';
import { call, putResolve, select, takeLatest } from 'redux-saga/effects'
import { parseJwt } from 'scenes/Login/utils';
import { actions, RootState } from 'store/rootSlices'
import { /*AccessTokens, ResetPassword,*/ User } from 'types/users/user';
import * as Check from 'validations'
import { changePasswordValidations } from "../utils";
import { history } from 'utils';
import { toast } from 'react-toastify';
import i18n from 'i18next';

//state
const state = (state: RootState) => state.ChangePassword

//import { actions } from 'store/rootSlices'

function* onMountSaga() {
  const JWT = window.localStorage.getItem('access_token')

  if (JWT) {
    const decodedJWT = JWT ? parseJwt(JWT) : null
    try {
      const user: User = yield call(API.Auth.GetUser, { uuid: decodedJWT.extra_fields.uuid, userType: 'team_member'})
      if (user) {
        yield putResolve(actions.App.setIsInApp(false))
        yield putResolve(actions.App.setUser(DEFAULT_USER))

        //set email and uuid and token from user info to change the password on 'onSubmitPasswordSaga'
        yield putResolve(actions.ChangePassword.setUid(user.uuid))
        yield putResolve(actions.ChangePassword.setToken(user.token))
        yield putResolve(actions.ChangePassword.setEmail({ value: user.email, message: '', valid: true }))
      }
    } catch (error) {
      yield putResolve(actions.ChangePassword.onError("Ocorreu um erro"))
    }
  }
  else {
    //get email url and store token
    if (history.location.pathname.split('/')[3] !== " ") {
      yield putResolve(actions.ChangePassword.setToken(history.location.pathname.split('/')[3]))
      yield putResolve(actions.ChangePassword.showEmail(false))
      yield putResolve(actions.ChangePassword.showChangePassword(true))
    }
  }
}

function* onUnmountSaga() {
  yield putResolve(actions.ChangePassword.onError(""))
  yield putResolve(actions.ChangePassword.setEmailSent(false))
  yield putResolve(actions.ChangePassword.showEmail(true))
  yield putResolve(actions.ChangePassword.showChangePassword(false))
}

function* onSubmitEmailSaga() {
  try {
    const params = yield select(state)
    const { token } = yield call(API.Auth.SendEmail, { email: params.email.value, userType: 'team_member'})
    yield putResolve(actions.ChangePassword.setToken(token))
    yield putResolve(actions.ChangePassword.setEmailSent(true))
  } catch (error) {
    yield putResolve(actions.ChangePassword.onError("Email inválido"))
  }

}

function* onSubmitPasswordSaga() {
  //get reset token and form values
  const params = yield select(state)
  const changePassword = params.passwordForm.changePassword.value
  const confirmPassword = params.passwordForm.confirmPassword.value
  const uuid = params.uuid
  const token = params.token

  //validate form
  const validation = Check.checkValidationAlternative(params.passwordForm, changePasswordValidations)


  if (validation.invalid === false) {
    if (changePassword === confirmPassword) {
      
      try {
        if(uuid !== ""){
          const reset = yield call(API.Auth.AcceptTerms, uuid, { password: confirmPassword })
          if (reset.status === 200) {
            toast.info(i18n.t("toasts.passwordChangeSuccess"))
            yield putResolve(actions.Login.logout())
            
            // if the flow is to login use the instructions below
            // get token -> decode JWT -> call Auth.GetUser with param id
            // const JWT = window.localStorage.getItem('access_token')
            // const decodedJWT = JWT ? parseJwt(JWT) : null
            //try {
            //   const user: User = yield call(API.Auth.GetUser, uuid)
            //   if(user)  yield putResolve(actions.Login.onLogin(user))
            // } catch (error) {
            //}
           
          }
        }
        else{
          const reset = yield call(API.Auth.resetPassword, token, { password: confirmPassword })
          if(reset.status === 200){
            toast.info(i18n.t("toasts.passwordChangeSuccess"))
            yield putResolve(actions.Login.setIsLoggedIn(false))
            yield putResolve(actions.App.setIsInApp(false))
            yield putResolve(actions.App.navigateTo('/login'))
          }
        }
        
      } catch (error) {
        yield putResolve(actions.ChangePassword.onError(i18n.t("toasts.passwordChangeError")))
      }
    }
    else {
      yield putResolve(actions.ChangePassword.onError(i18n.t("toasts.passwordsDoNotMatch")))
    }
  } else {
    yield putResolve(actions.ChangePassword.onError(i18n.t("toasts.invalidForm")))
  }

}

export default function* watcherChangePassword() {
  yield takeLatest('changepassword/onSubmitEmail', onSubmitEmailSaga)
  yield takeLatest('changepassword/onSubmitPassword', onSubmitPasswordSaga)
  yield takeLatest('changepassword/onMount', onMountSaga)
  yield takeLatest('changepassword/onUnmount', onUnmountSaga)
}


