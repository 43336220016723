/**
 * Breadcrumb manager component
 *
 * @author João Dias <jdias@ubiwhere.com>
 *
 *
 */

import React from 'react'
import { useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store/rootSlices';
import { actions } from 'store/rootSlices'
import routes from 'routes';
import { CBreadcrumb } from '@coreui/react-pro'
import { CustomBreadcrumbItem, CustomComponentContainer, Wrapper } from './styles'

const BreadcrumbManager = ({ t, i18n }) => {

  const {
    dynamicBreadcrumb
  } = useSelector((state: RootState) => state.Dashboard)

  const dispatch = useDispatch()
  const currentLocation = useLocation().pathname

  const getRouteName = (pathname, routes) => {

    const currentRoute = routes.find((route) => {
      const dynamicValues = [':id', ':uuid', ':memid']
      const splitedPathName = pathname.split('/')
      const splitedRoutePath = route.path.split('/')

      if (pathname === route.path) {
        return true
      } 
      
      for(const dynamicValue of dynamicValues) {
        if (splitedRoutePath.includes(dynamicValue)) {
          const indexOfVal = splitedRoutePath.indexOf(dynamicValue)
          splitedPathName.splice(indexOfVal, 1)
          splitedRoutePath.splice(indexOfVal, 1)
          if (splitedPathName.join('/') === splitedRoutePath.join('/')) {
            return true
          }
        }
      }

      return false
    })

    return currentRoute?.name ?? ""
  }

  const getRoutePath = (pathname, routes) => {

    const currentPath = routes.find((route) => {
      return pathname === route.path
    })
    return currentPath?.path ?? ""
  }

  const getBreadcrumbs = (location) => {
    const breadcrumbs: Array<{ pathname: string, name: string, active: boolean }> = [];
    const routeName = getRouteName(location, routes);

    let path = ``

    if(dynamicBreadcrumb) {
      dynamicBreadcrumb?.path.split('/').forEach((str, idx) => {
        path = path + `/${t(`routes.${str}`, { ns: 'routes' }).toLocaleLowerCase()}`
        if(str && str === dynamicBreadcrumb.name) {
          breadcrumbs.push({
            pathname: getRoutePath(path, routes),
            name: dynamicBreadcrumb.name,
            active: getRoutePath(path, routes) === "" || idx === routeName.split('/').length - 1,
          })
        }else if (str) {
          breadcrumbs.push({
            pathname: getRoutePath(path, routes),
            name: t(`routes.${str}`), // getting routeNames from default Namespace
            active: getRoutePath(path, routes) === "" || idx === routeName.split('/').length - 1,
          })
        }
      })
    } else {
      routeName.split('/').forEach((str, idx) => {
        //construct route for each location on the breadcrumb
        //get path name from routes namespace (pathname is only in PT)
        path = path + `/${t(`routes.${str}`, { ns: 'routes' }).toLocaleLowerCase()}`
  
        if (str) {
          breadcrumbs.push({
            pathname: getRoutePath(path, routes),
            name: t(`routes.${str}`), // getting routeNames from default Namespace
            active: getRoutePath(path, routes) === "" || idx === routeName.split('/').length - 1,
          })
        }
      });
    }

    return breadcrumbs
  }

  const breadcrumbs = getBreadcrumbs(currentLocation)

  return (
    <Wrapper>
      <CBreadcrumb className="m-0 ms-2">
        <CustomBreadcrumbItem href="/dashboard">{i18n.t(`routes.home`)}</CustomBreadcrumbItem>
        {breadcrumbs.map((breadcrumb, index) => {
          return (
            <CustomBreadcrumbItem
              id={`breadcrumb-${breadcrumb.name}-${index}`}
              {...(breadcrumb.active ? { active: true } : { href: breadcrumb.pathname })}
              key={index}
              onClick={(e) => {
                e.preventDefault()
                if(!breadcrumb.active) dispatch(actions.App.navigateTo(breadcrumb.pathname))
              }}
            >
              {breadcrumb.name}
            </CustomBreadcrumbItem>
          )
        })}
      </CBreadcrumb>
      <CustomComponentContainer>
        {/* CUSTOM COMPONENT, REMOVE WHEN LOGIC IS IMPLEMENTED */}
        {/* <p>Custom Component (notifications, etc.)</p> */}
      </CustomComponentContainer>
    </Wrapper>
  )
}

export default React.memo(BreadcrumbManager)