import * as React from "react";
import theme from "ui/theme";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg 
            width="16" 
            height="16" 
            viewBox="0 0 16 16" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M4.66 10.6668L9.33333 10.6668L9.33333 9.3335L4.66 9.3335L4.66 7.3335L2 10.0002L4.66 12.6668L4.66 10.6668ZM14 6.00016L11.34 3.3335L11.34 5.3335L6.66667 5.3335L6.66667 6.66683L11.34 6.66683L11.34 8.66683L14 6.00016ZM4.66 10.6668L9.33333 10.6668L9.33333 9.3335L4.66 9.3335L4.66 7.3335L2 10.0002L4.66 12.6668L4.66 10.6668ZM14 6.00016L11.34 3.3335L11.34 5.3335L6.66667 5.3335L6.66667 6.66683L11.34 6.66683L11.34 8.66683L14 6.00016Z" 
                fill={props.fill ? props.fill : theme().darkColor}
            />
        </svg>
    )
}

export default SvgComponent
