/**
 * Customnumberinput component
 *
 * @author João Flores <jflores@ubiwhere.com>
 *
 *
 */

import React, { useRef, useState, useEffect } from 'react'
import Minus from 'assets/icons/remove_minus';
import Plus from 'assets/icons/add';
import { CFormLabel } from '@coreui/react-pro';
import theme from 'ui/theme';
import { InlineContainer, TextInput, SubButton, AddButton} from './styles'

interface PropTypes {
    id?: string,
    disabled?: boolean,
    required?: boolean,
    label?: string,
    valid?: boolean,
    helpText?: string,
    value: string,
    onChange: Function,
    onClickAdd: Function,
    onClickSub: Function,
    placeholder?: string,
    initialValue?: string,
    max?: number,
    min?: number,
    delay?: boolean,
}

const CustomNumberInput = (props: PropTypes) => {
    const inputRef = useRef(null);
    const [isMinDisabled, setIsMinDisabled] = useState(false);
    const [isMaxDisabled, setIsMaxDisabled] = useState(false);

    useEffect(() => {
        checkMinDisabled();
        checkMaxDisabled();
    });

    const checkMinDisabled = () => {
        if (props.disabled) {
            setIsMinDisabled(true);
        }
        else if (props.hasOwnProperty("min") && props.min !== undefined && props.hasOwnProperty("value") && !isNaN(+props.value)) {
            setIsMinDisabled(+props.value <= props.min);
        }
        else {
            setIsMinDisabled(false);
        }
    };

    const checkMaxDisabled = () => {
        if (props.disabled) {
            setIsMaxDisabled(true);
        }
        else if (props.hasOwnProperty("max") && props.max !== undefined && props.hasOwnProperty("value") && !isNaN(+props.value)) {
            setIsMaxDisabled(+props.value >= props.max);
        }
        else {
            setIsMaxDisabled(false);
        }
    };

    return (
        <>
            {props.label &&
                <CFormLabel>{props.label}<span>{!!props.required ? "*" : ""}</span></CFormLabel>}
            <InlineContainer valid={props.valid}>
                <SubButton
                    id={`${props.id}-sub`}
                    onClick={e => {
                        props.onClickSub(e, inputRef.current);
                        checkMinDisabled();
                        checkMaxDisabled();
                    }}
                    disabled={isMinDisabled}
                    type="button"
                >
                    <Minus fill={isMinDisabled ? theme().gray300Color : theme().darkColor} />
                </SubButton>
                <TextInput
                    id={props.id}
                    defaultValue={props.initialValue}
                    onChange={e => {
                        if (e.target.value.trim().length && isNaN(+e.target.value)) {
                            e.preventDefault();
                        }
                        else {
                            props.onChange(e);
                            checkMinDisabled();
                            checkMaxDisabled();
                        }
                    }}
                    placeholder={props.placeholder}
                    value={props.initialValue ? undefined : props.value}
                    disabled={props.disabled}
                    type="text"
                    ref={inputRef}
                />
                <AddButton
                    id={`${props.id}-add`}
                    onClick={e => {
                        props.onClickAdd(e, inputRef.current);
                        checkMinDisabled();
                        checkMaxDisabled();
                    }}
                    disabled={isMaxDisabled}
                    type="button"
                >
                    <Plus fill={isMaxDisabled ? theme().gray300Color : theme().darkColor} />
                </AddButton>
            </InlineContainer>
        </>
    )
}

export default CustomNumberInput
