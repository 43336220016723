import * as React from "react"
import theme from "ui/theme";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M19.5 3.5L18 2L16.5 3.5L15 2L13.5 3.5L12 2L10.5 3.5L9 2L7.5 3.5L6 2V16H3V19C3 20.66 4.34 22 6 22H18C19.66 22 21 20.66 21 19V2L19.5 3.5ZM15 20H6C5.45 20 5 19.55 5 19V18H15V20ZM19 19C19 19.55 18.55 20 18 20C17.45 20 17 19.55 17 19V16H8V5H19V19Z"
                fill={props.fill ? props.fill : theme().success}
            />
            <path
                d="M12.2984 11.9593L10.7057 10.3666L10.1634 10.9051L12.2984 13.0402L16.8818 8.45686L16.3432 7.91832L12.2984 11.9593Z"
                fill={props.fill ? props.fill : theme().success}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.3434 7.21138L17.5889 8.45686L12.2984 13.7473L9.455 10.9039L10.707 9.66072L12.2986 11.2523L16.3434 7.21138Z"
                fill={props.fill ? props.fill : theme().success}
            />
        </svg>
    )
}

export default SvgComponent
