/**
 * Planninglisttablerow component
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */
import React from 'react'
// Styles
import * as Styles from './styles'
// Components
import Pad from './components/Pad';
import PaddingCircuit from './components/PadCircuit';
import PadActions from './components/PadActions';
// Types
import { PlanningListItem } from 'types/trips/plannings';

type PropTypes = {
  propActions: JSX.Element,
  planning: PlanningListItem,
  permission: boolean
}

const Beachplanningtablerow = ({ permission, planning }: PropTypes) => {

  const {
    activities: [
      mainActivity,
    ],
    location,
  } = planning;


  return (
    <Styles.TableRow activitycolor={mainActivity?.activity.color}>
      <Styles.Info>
        <Styles.Summary>
          <Pad
            location={location?.name ?? ''}
            mainColor={mainActivity?.activity.color}
          />
          <PaddingCircuit
            planning={planning}
          />
          <PadActions
            planning={planning}
            permission={permission}
          />
        </Styles.Summary>
      </Styles.Info>
    </Styles.TableRow>
  )
}

export default Beachplanningtablerow