/**
 * Controlscontainer component
 *
 * @author Stephane Ribeiro <sgribeiro@ubiwhere.com>
 *
 *
 */

import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { DateTime } from 'luxon';
import i18n from 'i18next';
// Utils & Configs
import config from 'config'
import { hasPermission } from 'permissions'
import { presetsLists } from 'shared/components/FilterDatePicker/utils'
// Store
import { actions, RootState } from 'store/rootSlices'
// Components
import CustomButton from 'shared/components/CustomButton'
import FilterComponent from 'shared/components/FilterComponent'
import FilterDatePicker from 'shared/components/FilterDatePicker'
// Icons
import DirectionsBoat from 'assets/icons/directions_boat'
import Boarding from 'assets/icons/login'
import Landing from 'assets/icons/logout'
import Map from 'assets/icons/map'
// Styles
import { Controls1, Controls2, ControlsContainer } from 'shared/genericStyledComponents'

const Controlscontainer = (props) => {
  const {
    activityFilter,
    vehicleFilter,
    departureFilter,
    arrivalFilter,
    startDate,
    endDate,
    hasDateParams,
    activities
  } = useSelector((state: RootState) => state.TripPlannings)
  const { allLocals } = useSelector((state: RootState) => state.TripsConfiguration)
  const { fleetLongList } = useSelector((state: RootState) => state.FleetManagement)
  const { permissions } = useSelector((state: RootState) => state.App)
  const {
    setVehicleFilter,
    setActivityFilter,
    setDepartureFilter,
    setArrivalFilter,
    setDatesInterval,
  } = actions.TripPlannings
  const { PERMISSIONS } = config
  const dispatch = useDispatch()
  const history = useHistory()

  const _renderCustomButton = () => {
    return hasPermission(permissions, PERMISSIONS.TRIPS.PLANNINGS.CREATE_PLANNING) && (
      <CustomButton 
        id='tripPlannings-newButton' 
        text={i18n.t('tripPlannings.schedulePlanning')} 
        icon="add" 
        color="success" 
        buttonStyle={{ color: "#FFFFFF" }} 
        onClick={(e) => { dispatch(actions.App.navigateTo(`/viagens/agendamentos/novo-agendamento`)) }} 
      />
    )
  }

  return (
    <ControlsContainer>
      <Controls1>
        <FilterDatePicker filterName={i18n.t('tripPlannings.today')}
          range
          showMonthYearDropdown
          presets={presetsLists().plannings}
          reRender={hasDateParams}
          defaultValue={
            {
              startDay: startDate ? DateTime.fromISO(startDate).toJSDate() : new Date(),
              endDay: endDate ? DateTime.fromISO(endDate).toJSDate() : new Date(),
            }
          }
          onChange={(e) => {
            if (e.startDate && e.endDate) {
              dispatch(setDatesInterval({ e, history })) //clears plannings array and resets page number
            }
          }} />
        <FilterComponent
          id='tripPlannings-activityFilter'
          filterName={i18n.t('tripPlannings.activities')}
          defaultOption={
            {
              label: activities.find(ct => ct.id.toString() === activityFilter)?.shortName as string,
              value: activityFilter
            }
          }
          options={activities?.map((a) => {
            return (
              {
                label: a.shortName,
                value: a.id.toString()
              }
            )
          })} icon={<Map />} onChange={(e) => {
            let params = new URLSearchParams(history.location.search);
            e.value !== "" ? params.set('activity', e.value) : params.delete('activity')
            history.replace({ search: (params).toString() })
            dispatch(setActivityFilter(e.value)) //clears plannings array
          }} />
        <FilterComponent
          id='tripPlannings-vehicleFilter'
          filterName={i18n.t('tripPlannings.vehicle')}
          defaultOption={
            {
              label: fleetLongList.find(ct => ct.uuid.toString() === vehicleFilter)?.shortName as string,
              value: vehicleFilter
            }
          }
          options={fleetLongList?.map((v) => {
            return (
              {
                label: v.shortName,
                value: v.uuid.toString()
              }
            )
          })} icon={<DirectionsBoat />} onChange={(e) => {
            let params = new URLSearchParams(history.location.search);
            e.value !== "" ? params.set('vehicle', e.value) : params.delete('vehicle')
            history.replace({ search: (params).toString() })
            dispatch(setVehicleFilter(e.value))
          }} />
        <FilterComponent
          id='tripPlannings-departureFilter'
          filterName={i18n.t('tripPlannings.boardings')}
          defaultOption={
            {
              label: allLocals.find(ct => ct.id.toString() === departureFilter)?.shortName as string,
              value: departureFilter
            }
          }
          options={allLocals?.map((l) => {
            return (
              {
                label: l.name,
                value: l.id.toString()
              }
            )
          })} icon={<Boarding />} onChange={(e) => {
            let params = new URLSearchParams(history.location.search);
            e.value !== "" ? params.set('departure', e.value) : params.delete('departure')
            history.replace({ search: (params).toString() })
            dispatch(setDepartureFilter(e.value))
          }} />
        <FilterComponent
          id='tripPlannings-arrivalFilter'
          filterName={i18n.t('tripPlannings.landings')}
          defaultOption={
            {
              label: allLocals.find(ct => ct.id.toString() === arrivalFilter)?.shortName as string,
              value: arrivalFilter
            }
          }
          options={allLocals?.map((l) => {
            return (
              {
                label: l.name,
                value: l.id.toString()
              }
            )
          })} icon={<Landing />} onChange={(e) => {
            let params = new URLSearchParams(history.location.search);
            e.value !== "" ? params.set('arrival', e.value) : params.delete('arrival')
            history.replace({ search: (params).toString() })
            dispatch(setArrivalFilter(e.value))
          }} />
      </Controls1>
      <Controls2>
        { _renderCustomButton() }
      </Controls2>
    </ControlsContainer>
  )
}

export default Controlscontainer

