import * as React from "react"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 817.5 143.48"
            {...props}
        >
            <defs>
                <style>{".prefix__cls-2{fill:#0e364f}"}</style>
            </defs>
            <g id="prefix__Camada_2" data-name="Camada 2">
                <g id="prefix__Camada_1-2" data-name="Camada 1">
                    <path
                        className="prefix__cls-2"
                        d="M293.9 113.6L288.05 97h-34.59l-5.85 16.56H232.4l28-77.4c1.57-4.84 5.07-7.32 10.93-7.32 5.51 0 9.12 2.59 10.7 7.21l27.26 77.51zM271 44.2l-14.27 41.46h28.16zM337.18 113.6h-13.29V38.68c0-6.42 3.94-9.69 10.47-9.69 5.41 0 8.56 2.71 10.82 6.31l34.47 57.79V29.78h13.4v74.92c0 6.3-4.05 9.57-10.59 9.57-5.29 0-8.44-2.7-10.58-6.31l-34.7-57.79zM413.8 113.6V29.78H429v83.82zM492.32 94l19.27-55.77c1.8-5.4 4.95-9 12.39-9 6.53 0 10.93 3 10.93 9.8v74.57h-13.52V48.48l-20.84 59.93c-1.13 4-3.83 5.64-8.56 5.64s-7.44-1.81-8.79-5.64l-20.39-60v65.19h-13.18V39c0-6.76 4.73-9.8 11.38-9.8 7.54 0 11 3.61 12.84 9zM611 113.6L605.11 97h-34.58l-5.86 16.56h-15.21l28-77.4c1.58-4.84 5.07-7.32 10.93-7.32 5.52 0 9.12 2.59 10.7 7.21l27.31 77.55zm-22.9-69.4l-14.31 41.46H602zM721.4 113.6V29.78h15.21v83.82zM796.09 67.41c13.63 2.93 21.41 9.59 21.41 22.53 0 16.79-13.19 24.67-28.28 24.67-9.69 0-17.69-1.58-27.15-4.62l-6.64-2.14L758 95l6.64 2.25c7.89 2.71 16.34 5 24.34 5 7.21 0 14-3.38 14-11.61 0-5.85-3.15-9.23-10.7-10.92l-15.2-3.38c-13.52-3-21.3-9.91-21.3-22.64 0-16.79 13-24.45 28.17-24.45 9.68 0 17.34 1.69 26.92 4.73l6.53 2-2.7 13-6.7-2.41c-8-2.82-15.77-5-23.88-5-7.55 0-13.85 3.27-13.85 11.61 0 5.74 3 9.12 10.7 10.81zM701.69 56.27c0 17.16-4.63 23.43-17.5 25.42l20.16 31.91h-16.22l-19.46-31-12.67.08v30.92h-14.9V31.29c0-1.21.34-1.43 1.65-1.54 8.81-.66 18.71-.88 26.31-.88 24.37 0 32.63 5.06 32.63 27.4zM656 41.19v30.15h11.73c15.63 0 19.7-1.32 19.7-14.74 0-13.1-4.07-15.41-19.7-15.41zM85.08 143.48a72 72 0 01-58-29.52 71.74 71.74 0 11129.74-42.22A71.16 71.16 0 01143.08 114a72 72 0 01-58 29.48zm0-135.87a64.13 64.13 0 1051.85 101.87 63.56 63.56 0 0012.28-37.74A64.2 64.2 0 0085.08 7.61z"
                    />
                    <path fill="#94a6c7" d="M0 103.73h170.17v13.34H0z" />
                    <path
                        d="M162.2 69.88c-6.92 1.36-9.91 3.61-13.28 5.63a33.12 33.12 0 01-3.53 1.94c-7.95 3.52-18.53-.9-20.56-1.82a23.18 23.18 0 01-3.89-2.24 18.05 18.05 0 00-8.15-3.39c-6.07-1-10.19.94-14.17 2.78a61.72 61.72 0 01-6 2.48C89.11 76.4 77 80 68.17 75.7a15.11 15.11 0 01-5.24-4.55A22.09 22.09 0 0058.79 67C51 61.52 35.3 63 0 73.29v13.92C36.85 76.34 48.33 76 51.06 77.92a15.68 15.68 0 011.65 1.81 28.18 28.18 0 009.63 8c13 6.31 28.52 2.19 34.58.13a71.32 71.32 0 007.29-3c3.43-1.58 4.5-2 6.49-1.71a5.42 5.42 0 012.74 1.29 36.22 36.22 0 005.89 3.37c4.13 1.88 18.64 7.55 31.46 1.86a38.67 38.67 0 005-2.69c5.62-3.49 10.37-3 14.41-4V69.34c-.51.07-7.39.42-8 .54z"
                        fill="#56b787"
                    />
                    <path
                        d="M168.06 29.11a49.16 49.16 0 00-9.85 1.57 29.28 29.28 0 00-10.91 6.7c-2.79 2.52-3.58 3-8.31 5.38-2.36 1.18-7.61 2.17-11.89 2.57 2.66-3.93 5.63-9 2.32-14-4.5-6.73-14.64-3.42-21.41-1.22C95 34.4 84.65 41.94 77.58 44.12c0 0 6-9 2.67-14-4.5-6.73-14.64-3.42-21.42-1.22C45.78 33.19 7.07 40.13 0 42.3v14.58C12.56 52.94 48 47.8 62.81 43l.77-.25c-2 3.54-3.95 9-1.44 13.08 5.57 9 21.65-3.12 39.31-9.5l10.75-3.5.77-.25c-2 3.54-3.36 7.65-.85 11.7 5.57 9 27.29 4.15 30.74 2.53 8.12-3.83 10.2-6.72 13.37-9.49 4.89-4.31 11.75-4.63 11.83-4.65h2.11V29.11a15 15 0 00-2.11 0z"
                        fill="#fece4f"
                    />
                </g>
            </g>
        </svg>
    )
}

export default SvgComponent
