/**
 * Usercashmanagement scene
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 */
import React, { useEffect } from 'react'
import _ from 'lodash'
import { useParams, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { DateTime } from 'luxon'
// Utils & Configs
import config from 'config'
import { paginationCurrentPage, paginationElementsCount } from 'utils'
import { hasPermission } from 'permissions'
// Store
import { actions, RootState } from 'store/rootSlices'
// Types
import { StatementStatus } from 'types/invoicing/statements'
// Components
import SearchBox from 'shared/components/SearchBox'
import TableContainer from 'shared/components/TableContainer'
import SorterComponent from 'shared/components/SorterComponent'
import CustomButton from 'shared/components/CustomButton'
import CustomModal from 'shared/components/CustomModal'
import Cashmovementrow from './components/Cashmovementrow'
import Usercashmanagementrow from './components/Usercashmanagementrow'
import Photoscomponent from 'shared/components/Photoscomponent'
import SectionContainer from 'shared/components/Sectioncontainer'
// Icons
import Feed from 'assets/icons/feed'
import Ticket from 'assets/icons/ticket'
import Receipt from 'assets/icons/receipt'
import Gas from 'assets/icons/local_gas_station'
import Calendar from 'assets/icons/calendar'
// Styles
import theme from 'ui/theme'
import { CustomWrapper, ControlsContainer, Controls1, Controls2 } from 'shared/genericStyledComponents'
import { Title, ButtonWrapper, DateInfo } from './styles'

const Usercashmanagement = ({ t, i18n }) => {

  const {
    statementEntries,
    statement,
    userID,
    userStatementName,
    showModal,
    statementStatus,
    searchTerm
  } = useSelector((state: RootState) => state.UserCashManagement)

  const {
    permissions
  } = useSelector((state: RootState) => state.App)

  const {
    photos
  } = useSelector((state: RootState) => state.UserCashManagementForms)

  const {
    startDate
  } = useSelector((state: RootState) => state.CashManagement)

  const {
    onMount,
    onUnmount,
    openStatement,
    setSearchTerm,
    setShowModal,
    closeModal,
  } = actions.UserCashManagement

  const {
    navigateTo,
    navigateBack
  } = actions.App

  const { PERMISSIONS } = config
  const history = useHistory()
  const { uuid } = useParams()
  const dispatch = useDispatch()
  const dbDispatch = _.debounce(dispatch, 500);

  useEffect(() => {

    dispatch(onMount({ uuid, history }))
    return () => {
      dispatch(onUnmount())
    }
  }, [dispatch, onMount, onUnmount, history, uuid]);

  return (
    <CustomWrapper>
      <ControlsContainer>
        <Controls1>
          <SearchBox
            id="invoicingSummary-searchBox"
            defaultValue={searchTerm}
            onSubmit={(term) => {
              let params = new URLSearchParams(history.location.search);
              term !== "" ? params.set('search', term) : params.delete('search')
              history.replace({ search: (params).toString() })
              dbDispatch(setSearchTerm(term))
            }}
          />
          <DateInfo><span><Calendar /></span>{DateTime.fromJSDate(startDate).toFormat(config.DATE_FORMAT.DATE)}</DateInfo>
        </Controls1>
        <Controls2>
          {
            statementStatus === StatementStatus.OPEN && hasPermission(permissions, PERMISSIONS.INVOICING.CASH_MANAGEMENT.CREATE_BALANCE) &&
            <CustomButton
              id="cashManagement-newButton"
              onClick={() => dispatch(navigateTo(`${userID}/novo-movimento`))}
              text={t('common.new')}
              icon="add"
              color="success"
              buttonStyle={{ color: "#FFFFFF" }}
            />
          }
        </Controls2>
      </ControlsContainer>
      <Title>{t('cashManagement.cash')}</Title>
      <TableContainer
        id={`collaboratorsManagement-table`}
        count={paginationElementsCount(0)}
        currentPage={paginationCurrentPage(0, 1)}
        elementsPerPage={1}
        onPageChange={pg => { /* dispatch(setPage(pg)) */ }}
        headerElements={[
          <SorterComponent id="cashManagement-userSorter" filterName={t('common.name')} onChange={(dir) => { /* dispatch(setSort({ field: "user", direction: dir })) */ }} />,
          <SorterComponent id="cashManagement-incomeSorter" filterName={t('cashManagement.income')} onChange={(dir) => { /* dispatch(setSort({ field: "user", direction: dir })) */ }} />,
          <SorterComponent id="cashManagement-expensesSorter" filterName={t('cashManagement.expenses')} onChange={(dir) => { /* dispatch(setSort({ field: "user", direction: dir })) */ }} />,
          <SorterComponent id="cashManagement-multibancoSalesSorter" filterName={t('cashManagement.multibancoSales')} onChange={(dir) => { /* dispatch(setSort({ field: "user", direction: dir })) */ }} />,
          <SorterComponent id="cashManagement-bankDepositSorter" filterName={t('cashManagement.bankDeposit')} onChange={(dir) => { /* dispatch(setSort({ field: "user", direction: dir })) */ }} />,
          <SorterComponent id="cashManagement-notDepositedSorter" filterName={t('cashManagement.notDeposited')} onChange={(dir) => { /* dispatch(setSort({ field: "user", direction: dir })) */ }} />,
          <SorterComponent id="cashManagement-totalSorter" filterName={t('cashManagement.total')} onChange={(dir) => { /* dispatch(setSort({ field: "user", direction: dir })) */ }} />,
          <SorterComponent id="cashManagement-estimatedSorter" filterName={t('cashManagement.estimated')} onChange={(dir) => { /* dispatch(setSort({ field: "user", direction: dir })) */ }} />,
          <SorterComponent id="cashManagement-balanceSorter" filterName={t('cashManagement.balance')} onChange={(dir) => { /* dispatch(setSort({ field: "user", direction: dir })) */ }} />,
          '',
        ]}
      >
        {statement && <Usercashmanagementrow
          id={1}
          permission={true}
          name={userStatementName}
          statement={statement}
          actions={
            <React.Fragment></React.Fragment>
          } />
        }
      </TableContainer>
      <br />
      <Title>{t('cashManagement.statementsHistory')}</Title>
      {<TableContainer
        id={`collaboratorsManagement-table`}
        count={paginationElementsCount(0)}
        currentPage={paginationCurrentPage(0, 1)}
        elementsPerPage={1}
        onPageChange={pg => { /* dispatch(setPage(pg)) */ }}
        headerElements={[
          "Date/Hour",
          <SorterComponent id="cashManagement-date" filterName={t('cashManagement.movementType')} onChange={(dir) => { /* dispatch(setSort({ field: "user", direction: dir })) */ }} />,
          <SorterComponent id="cashManagement-fundSorter" filterName={t('cashManagement.paymentType')} onChange={(dir) => { /* dispatch(setSort({ field: "user", direction: dir })) */ }} />,
          <SorterComponent id="cashManagement-incomeSorter" filterName={t('cashManagement.value')} onChange={(dir) => { /* dispatch(setSort({ field: "user", direction: dir })) */ }} />,
          'Acoes'
        ]}
      >
        {
          statementEntries.map((entry, idx) => {
            return (
              <Cashmovementrow
                id={1}
                permission={true}
                entry={entry}
                actions={
                  <React.Fragment>
                    <Feed />
                    <Receipt className='pointer' onClick={() => {
                      dispatch(setShowModal({ show: true, id: entry.id }))
                    }} />
                    {
                      entry.balanceEntryType === "fuel" ?
                        <a target="_blank" rel="noopener noreferrer" href={`/frota/combustivel`}>
                          <Gas className='pointer' />
                        </a>
                        :
                        entry.balanceEntryType === "booking" ?
                          <a target="_blank" rel="noopener noreferrer" href={`/viagens/reservas/editar-reserva/${entry.booking.uuid}`}>
                            <Ticket className='pointer' />
                          </a>
                          :
                          null
                    }
                    <div onClick={() => {
                      dispatch(navigateTo(`${userID}/editar-movimento/${entry.id}`))
                    }}>
                    </div>
                  </React.Fragment>
                } />
            )
          })
        }
      </TableContainer>}
      {
        showModal.show &&
        <CustomModal
          size='lg'
          warningType='message'
          warningName={i18n.t('cashManagement.linkedReceipts')}
          visible={showModal.show}
          noButtons={true}
          withForm={false}
          textAlign='center'
          footerPosition='flex-end'
          onclose={() => { dispatch(closeModal()) }}
          onsubmit={() => { }}
        >
          <SectionContainer style={{ width: '100%', paddingBottom: '24px' }}>
            <Photoscomponent
              id='cashManagementPhotos'
              array={photos}
              cantDelete
              noSignal
              disableDrag
              onDrag={e => { }}
              onDelete={(e, index) => { }}
            />
          </SectionContainer>
        </CustomModal>
      }
      {statement?.isClosed && <ButtonWrapper>
        <CustomButton
          id='cashMovement-cancel'
          onClick={() => dispatch(navigateBack())}
          text={i18n.t('common.back')}
          color="link"
          buttonStyle={{ color: theme().primary, textDecoration: 'unset' }}
        />
        <CustomButton
          id='timeRecord-discard'
          text={t('cashManagement.reOpen')}
          icon={"lock"}
          onClick={() => {
            if (hasPermission(permissions, PERMISSIONS.INVOICING.CASH_MANAGEMENT.OPEN_STATEMENT)) {
              dispatch(openStatement())
            }
          }}
          color={"danger"}
          buttonStyle={{ color: theme().white }}
        />
      </ButtonWrapper>}
    </CustomWrapper>
  )
}

export default Usercashmanagement