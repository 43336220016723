import styled from 'styled-components'
import { CButtonGroup } from '@coreui/react-pro';
import theme from 'ui/theme';


export const Wrapper = styled.div<{ title: string | undefined }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;

  > h5 {
    color:${theme().darkColor};
    line-height:1.9;
    font-size: 14px;
    padding-bottom: 5px;
    width: 100%;
    border-bottom: ${props => props.title ? `1px solid ${theme().gray200Color}` : '0px' };
  }
`

export const ButtonGroup = styled(CButtonGroup)`
  width: 100%;
  justify-content: flex-start;
  flex-wrap:wrap;
  > div {
    margin-right: 40px;
  }
`