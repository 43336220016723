import styled from 'styled-components'
import { CButton, CRow } from '@coreui/react-pro'
import theme from 'ui/theme';


export const Row = styled(CRow)`
  --cui-gutter-y:0rem;
  margin-top:26px;
`

export const ButtonWrapper = styled.div`
    display:flex;
    justify-content:flex-end;
    padding-top:22px;
 `

export const RulesRow = styled(CRow)`
  --cui-gutter-y:0rem;
  padding-bottom:24px;

  &:last-child {
    padding-bottom: 0px;
  }
`

export const Button = styled(CButton)<{icon : boolean}>`
  background-color:${props => props.icon ? theme().white : theme().primary};
  border-color: ${props => props.icon ? theme().danger : theme().primary};
  color:${props => props.icon ? theme().danger : theme().white};
  width:${props => props.icon ? '75%' : '100%'};
  line-height:1.3;
  height:50%;
  align-self:center;
  box-shadow: 0 0 0 0;
  &:hover{
    color:${props => props.icon ? theme().white : theme().primary};
    background-color: ${props => props.icon ? theme().danger : theme().white};
    border-color:${props => props.icon ? theme().danger : theme().primary};
  }
  &:focus{
    box-shadow: 0 0 0 0;
    color:${props => props.icon ? theme().white : theme().white};
    background-color: ${props => props.icon ? theme().danger : theme().primary};
    border-color:${props => props.icon ? theme().white : theme().primary};
  }
`

export const SVGComponentWrapper = styled.div`
  > * {
    font-family: 'Inter';
  }

  > label {
    font-size: 14px;
    color: ${theme().darkColor};
    > span {
      color: ${theme().primary};
    }
  }

  .form-text {
    color: ${theme().danger};
  } 
`

export const SVGWrapper = styled.div`
  border: 1px solid ${theme().gray200Color};
  border-radius: 4px;
  height: 35px;
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const SVGComponentDeleteWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

export const SVGComponentUploadWrapper = styled.div`
`