import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions, RootState } from 'store/rootSlices';
import { DateTime } from 'luxon';
import i18n from 'i18next'
//configs
import config from 'config';
//styles
import { TableRow, ActionsWrapper } from '../../../../styles';
import theme from 'ui/theme';
import { CustomLink } from '../../styles'
import { EmptyCell } from './styles'
//icons
import SwapHorz from 'assets/icons/swap_horz';
import TrendingFlat from 'assets/icons/trending_flat';
import CheckedIn from 'assets/icons/ticket_checkin';
import CheckedOut from 'assets/icons/ticket_checkout';
import Cancel from 'assets/icons/cancel';
import ChevronRight from 'assets/icons/chevron_right';
//Components
import { CTableDataCell } from '@coreui/react-pro';
import { hasPermission } from 'permissions';
// Types
import { BookingLeg } from 'types/trips/bookings';

const { DATE_FORMAT } = config;

const EditTableContent = () => {
  const {
    outbound,
    inbound,
    hasEditPermission,
    bookingState,
    bookedPassengerTypes,
    samePassengers,
  } = useSelector((state: RootState) => state.BookingForm);

  const { cancelLeg, setLockPassengerInputs } = actions.BookingForm;

  const dispatch = useDispatch();

  const { permissions } = useSelector((state: RootState) => state.App);
  const { PERMISSIONS } = config;

  const _renderActions = (leg: BookingLeg, isOutbound: boolean) => {
    return hasEditPermission && leg.checkedIn === false && (
      <ActionsWrapper>
        {bookingState !== 'pending' &&
          samePassengers &&
          hasPermission(permissions, PERMISSIONS.TRIPS.BOOKINGS.CANCEL_BOOKING) ? (
          <Cancel
            onClick={() => {
              hasEditPermission &&
                leg.checkedIn === false &&
                dispatch(cancelLeg({ leg, direction: isOutbound ? 'outbound' : 'inbound' }));
              hasEditPermission &&
                leg.checkedIn === false &&
                dispatch(setLockPassengerInputs(true));
            }}
          />
        ) : (
          <Cancel style={{ cursor: 'default', opacity: '0.8' }} onClick={() => { }} />
        )}
      </ActionsWrapper>
    )
  }

  const _renderOutboundRow = (leg: BookingLeg, isOutbound: boolean) => {
    const legNotCanceled = leg.id > 0 && !leg.isCanceled
    const legAndBookingCanceled = leg.isCanceled && bookingState === 'canceled'

    return (legNotCanceled || legAndBookingCanceled) && (
      <TableRow>
        <CTableDataCell>{leg?.tripActivityLeg.activity.name}</CTableDataCell>
        <CTableDataCell>
          {DateTime.fromISO(leg.tripActivityLeg.date).toFormat(DATE_FORMAT.DATE)}
        </CTableDataCell>
        <CTableDataCell>
          {DateTime.fromISO(leg.tripActivityLeg.time).toFormat(DATE_FORMAT.TIME)}
        </CTableDataCell>
        <CTableDataCell>
          <CustomLink
            to={`/viagens/agendamentos/editar-agendamento/${leg?.tripActivityLeg.trip}`}
            target="_blank"
            rel="noopener"
          >
            {leg.tripActivityLeg.activity.isHopOnHopOff ? (
              <>
                {leg.tripActivityLeg.departure.name} <SwapHorz />{' '}
                {leg.tripActivityLeg.arrival.name}
              </>
            ) : (
              <>
                {leg.tripActivityLeg.departure.name} <TrendingFlat />{' '}
                {leg.tripActivityLeg.arrival.name}
              </>
            )}
            <ChevronRight className="pointer" fill={theme().gray700Color} />
          </CustomLink>
        </CTableDataCell>
        <CTableDataCell>
          {bookedPassengerTypes.reduce((prev, curr) => {
            let val = isNaN(+curr.quantity.value) ? 0 : parseInt(curr.quantity.value, 10);

            return prev + val;
          }, 0)}
        </CTableDataCell>
        <CTableDataCell>
          {leg.checkedIn ? (
            <>
              <CheckedIn style={{ marginRight: '8px' }} />
              {i18n.t('tripBookings.checkInMade')}
            </>
          ) : (
            <>
              <CheckedOut style={{ marginRight: '8px' }} />
              {i18n.t('tripBookings.checkInNotMade')}
            </>
          )}
        </CTableDataCell>
        <CTableDataCell>
          <b>{leg.totalPrice}€</b>
        </CTableDataCell>
        <CTableDataCell>
          {!legAndBookingCanceled && _renderActions(leg, isOutbound)}
        </CTableDataCell>
      </TableRow>
    )
  }

  const _renderEmptyRow = () => {
    return inbound.isCanceled && outbound.isCanceled && bookingState !== 'canceled' && (
      <TableRow>
        <EmptyCell colSpan={8}>{i18n.t('tripBookings.noLegs')}</EmptyCell>
      </TableRow>
    )
  }

  return (
    <>
      {_renderOutboundRow(outbound, true)}
      {_renderEmptyRow()}
      {_renderOutboundRow(inbound, false)}
    </>
  );
};

export default EditTableContent;
