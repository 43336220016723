import * as React from "react"

const SvgComponent = (props) => (
    <svg
        width={16}
        height={10}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="m12 1.667-.94-.94-4.227 4.226.94.94L12 1.667Zm2.827-.94L7.773 7.78 4.987 5l-.94.94 3.726 3.727 8-8-.946-.94ZM.273 5.94 4 9.667l.94-.94L1.22 5l-.947.94Z"
            fill="#fff"
        />
    </svg>
)

export default SvgComponent