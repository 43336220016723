/**
 * Cashmanagementtablerow component
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */

import { CTableDataCell } from '@coreui/react-pro'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import i18n from 'i18next'
// Components
import CustomCheckbox from 'shared/components/CustomCheckbox'
import { ChevronCell, CustomTableRow, ColoredDataCellText } from 'shared/genericStyledComponents'
// Icons
import ChevronRight from 'assets/icons/chevron_right'
// State
import { actions, RootState } from 'store/rootSlices'
// Types
import { TableRowPropTypes } from 'types/misc'
import { StatementsTab } from 'types/invoicing/statements'
import { CashManagementTeamMember } from 'types/invoicing/cashManagement'
// Styles
import { StatementStateLabel } from './styles'
import theme from 'ui/theme'

type PropTypes = TableRowPropTypes & {
  id?: number,
  statement: CashManagementTeamMember,
  permission: boolean,
}

const Cashmanagementtablerow = (props: PropTypes) => {

  const {
    tab,
    approvedStatements,
    disapprovedStatements,
  } = useSelector((state: RootState) => state.CashManagement)

  const {
    addApprovedStatement,
    addDisapprovedStatement,
    removeApprovedStatement,
    removeDisapprovedStatement
  } = actions.CashManagement

  const {
    statement: {
      uuid,
      name,
      statement,
    }
  } = props

  const dispatch = useDispatch()

  return (
    <CustomTableRow id={`statement-${statement?.id}`}>
      <CTableDataCell>
        {name}
      </CTableDataCell>
      <CTableDataCell>
        {statement ?
          statement.isClosed ?
            <StatementStateLabel
              className={"closed"}>
              {i18n.t(`cashManagement.closed`)}
            </StatementStateLabel>
            :
            <StatementStateLabel
              className={"open"}>
              {i18n.t(`cashManagement.open`)}
            </StatementStateLabel>
          :
          `-`
        }
      </CTableDataCell>
      <ColoredDataCellText color={theme().success}>
        {statement ? `${statement.income}€` : "-"}
      </ColoredDataCellText>
      <ColoredDataCellText color={theme().danger}>
        {statement ? `${statement.expenses}€` : "-"}
      </ColoredDataCellText>
      <CTableDataCell>
        {statement ? `${statement.estimated}€` : "-"}
      </CTableDataCell>
      <ColoredDataCellText color={theme().darkColor} trigger>
        {statement ? `${statement.balance}€` : "-"}
      </ColoredDataCellText>
      <CTableDataCell>
        {
          statement && statement.isClosed && <CustomCheckbox
            checkStyle={{ marginTop: '5px' }}
            id={`cashManagement-statement-${statement?.id}`}
            disabled={false}
            checked={tab === StatementsTab.PENDING ?
              approvedStatements.find(id => +id === statement?.id) ? true : false
              :
              disapprovedStatements.find(id => +id === statement?.id) ? true : false
            }
            onClick={e => {
              const newStatus = tab === StatementsTab.PENDING ?
                approvedStatements.find(id => +id === statement?.id) ? false : true
                :
                disapprovedStatements.find(id => +id === statement?.id) ? false : true


              tab === StatementsTab.PENDING ?
                newStatus === true ? dispatch(addApprovedStatement({ id: statement?.id, approvedStatements })) : dispatch(removeApprovedStatement({ id: statement?.id, approvedStatements }))
                :
                newStatus === true ? dispatch(addDisapprovedStatement({ id: statement?.id, disapprovedStatements })) : dispatch(removeDisapprovedStatement({ id: statement?.id, disapprovedStatements }))
            }}
          />
        }

      </CTableDataCell>
      <CTableDataCell>
        {props.actions}
        <ChevronCell id={`${props.id}-chevron`}>
          {
            <a href={`/faturacao/gestao-caixa/${uuid}`}
              onClick={(e) => {
                e.preventDefault()
                dispatch(actions.App.navigateTo(`/faturacao/gestao-caixa/${uuid}`))
              }}>
              <ChevronRight></ChevronRight>
            </a>
          }
        </ChevronCell>
      </CTableDataCell>
    </CustomTableRow>
  )
}

export default Cashmanagementtablerow