import * as Check from 'validations'
import { ActivityForm } from 'types/trips/activity/activityForm'

export const ActivityFormValidations = {
    descriptionPt: [Check.isToLong],
    descriptionEn: [Check.isToLong],
    descriptionEs: [Check.isToLong],
    descriptionFr: [Check.isToLong],
    namePt: [Check.isRequired],
    nameEn: [],
    nameEs: [],
    nameFr: [],
    shortName: [Check.isRequired],
    getyourguideCode: [],
    invoiceArticle: [Check.isRequired],
    maxCapacity: [Check.isInteger, Check.isNumber, Check.isRequired],
    duration: [Check.isRequired],
    category: [Check.isRequired],
    color: [Check.isRequired],
    tax: [Check.isRequired],
    allowDiscounts: [],
    allowCommissions: [],
    allowMultipleActivities: [],
    generateIslandTrips: [],
    generateMiddleTrips: [],
    isDirect: [],
    isHopOnHopOff: [],
    isActive: [],
    isBeach: [],
    isPrivate: [],
    allowOnlyOneBooking: [],
    minimumPrice: [],
    includedSeats: []
}

export const ActivityFormValidationsEdit = {
    descriptionPt: [Check.isToLong],
    descriptionEn: [Check.isToLong],
    descriptionEs: [Check.isToLong],
    descriptionFr: [Check.isToLong],
    namePt: [Check.isRequired],
    nameEn: [],
    nameEs: [],
    nameFr: [],
    shortName: [Check.isRequired],
    invoiceArticle: [Check.isRequired],
    maxCapacity: [Check.isInteger, Check.isNumber, Check.isRequired],
    duration: [Check.isRequired],
    category: [Check.isRequired],
    color: [Check.isRequired],
    tax: [Check.isRequired],
    allowDiscounts: [],
    allowCommissions: [],
    allowMultipleActivities: [],
    generateIslandTrips: [],
    generateMiddleTrips: [],
    isDirect: [],
    isHopOnHopOff: [],
    isActive: [],
    isPrivate: [],
    isBeach: [],
    allowOnlyOneBooking: [],
    minimumPrice: [],
    includedSeats: []
}

export const PrivateActivityFormValidations = {
   ...ActivityFormValidations,
   minimumPrice: [Check.isRequired],
   includedSeats: [Check.isRequired]
}

export const PrivateActivityFormValidationsEdit = {
    ...ActivityFormValidationsEdit,
   minimumPrice: [Check.isRequired],
   includedSeats: [Check.isRequired]
}

export const ActivityExtrasValidations = {
    price: [/* Check.isRequired, Check.isNumber */],
}

export const ActivityPassengerTypesValidations = {
    price: [Check.isRequired, Check.isNumber],
    maxCapacity: [Check.isInteger, Check.isNumber, Check.isRequired]
}

export const defaultActivityForm: ActivityForm = {
    shortName: {
        value: "",
        valid: true,
        message: "",
    },
    category: {
        value: '',
        valid: true,
        message: "",
    },
    color: {
        value: "",
        valid: true,
        message: "",
    },
    descriptionPt: {
        value: "",
        valid: true,
        message: "",
    },
    descriptionEn: {
        value: "",
        valid: true,
        message: "",
    },
    descriptionFr: {
        value: "",
        valid: true,
        message: "",
    },
    descriptionEs: {
        value: "",
        valid: true,
        message: "",
    },
    duration: {
        value: "",
        valid: true,
        message: "",
    },
    isActive: {
        value: true,
        valid: true,
        message: "",
    },
    maxCapacity: {
        value: "",
        valid: true,
        message: "",
    },
    namePt: {
        value: "",
        valid: true,
        message: "",
    },
    nameEn: {
        value: "",
        valid: true,
        message: "",
    },
    nameFr: {
        value: "",
        valid: true,
        message: "",
    },
    nameEs: {
        value: "",
        valid: true,
        message: "",
    },
    invoiceArticle: {
        value: "",
        valid: true,
        message: "",
    },
    tax: {
        value: '',
        valid: true,
        message: "",
    },
    minimumPrice: {
        value: "",
        valid: true,
        message: "",
    },
    includedSeats: {
        value: "",
        valid: true,
        message: "",
    },
    getyourguideCode: { value: "", valid: true, message: "" },
    allowDiscounts: { value: false, valid: true, message: "" },
    allowCommissions: { value: false, valid: true, message: "" },
    allowMultipleActivities: { value: false, valid: true, message: "" },
    generateIslandTrips: { value: false, valid: true, message: "" },
    generateMiddleTrips: { value: false, valid: true, message: "" },
    isDirect: { value: true, valid: true, message: "" },
    isHopOnHopOff: { value: false, valid: true, message: "" },
    isBeach: { value: false, valid: true, message: "" },
    isPrivate: { value: false, valid: true, message: "" },
    allowOnlyOneBooking: { value: false, valid: true, message: "" },
}