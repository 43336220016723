/**
 * App reducers
 * Please write a description
 *
 * @author Carlos Silva <csilva@ubiwhere.com>
 *
 *
 */

import { createSlice } from '@reduxjs/toolkit';

interface InitialState {
  value: string,
  welcome: string,
  isSidebarOpen: boolean,
  dynamicBreadcrumb: {
    path: string,
    name: string
  } | null,
}

const initialState: InitialState = {
  value: 'Press one of the buttons bellow',
  welcome: 'Welcome',
  isSidebarOpen: true, 
  dynamicBreadcrumb: null, // this var is used to make the dynamic breadcrumb 
};

export default createSlice({
  name: 'Dashboard',
  initialState,
  reducers: {
    onMount: () => { },
    onUnmount: () => { },
    setValue: (state, { payload }) => {
      state.value = payload;
    },
    updateValue: () => { },
    hasMails: () => { },
    hasNotifications: () => { },
    setUnfoldability: (state, { payload }) => {
      state.isSidebarOpen = payload
    },
    setDynamicBreadcrumb: (state, { payload }) => {
      state.dynamicBreadcrumb = payload
    }
  },
});
