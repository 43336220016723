import styled from 'styled-components'
import { CTableRow, CTableDataCell } from '@coreui/react-pro';
import theme from 'ui/theme';

export const TableRow = styled(CTableRow) <{ activitycolor: string }>`
  td:first-child {
    padding:0;
    border-left: 4px solid ${props => props.activitycolor};
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`
export const Icon = styled.div <{ marginr: string }>`
  display: flex;
  flex-direction: column;
  margin-right: ${props => props.marginr}px;
`

export const Info = styled(CTableDataCell)`
  display:flex;
  flex-direction: column;
`

export const Summary = styled.div`
  background-color: ${theme().white};
  padding-block:16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 1250px) {
    padding-block: 26px;
  }
`


export const Text = styled.p`
  margin:0px;
`
