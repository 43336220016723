/**
 * App reducers
 * Please write a description
 *
 * @author Carlos Silva <csilva@ubiwhere.com>
 *
 *
 */

import { createSlice } from '@reduxjs/toolkit';
import { User } from 'types/users/user';
import { DEFAULT_USER, GENERATE_TRANSLATIONS } from './../utils';

const initialState = {
  loading: true,
  isInApp:false,
  user: DEFAULT_USER as User,
  userInfo:{
    avatar:'',
    name:'',
    profile:'',
  },
  permissions:[],
  currentLanguage: "pt",
  apiTranslationsEdit: false,
  translation: false,
  countryCodes: [],
  api_t: {}  //as {API_TRANSLATION_TEST?: any}
};



export default createSlice({
  name: 'App',
  initialState,
  reducers: {
    onMount: () => {},
    loadAppInfo: () => { },
    unsetLoading: (state) => {
      state.loading = false;
    },
    setCurrentLanguage: (state, { payload }) => {
      state.currentLanguage = payload
    },
    setUser: (state, { payload }) => {

      if(payload){
        const user : User = {
          email: payload.email,
          uuid: payload.uuid,
          role: payload.role,
          userType: { 
            id: payload.userType.id,
            uid: payload.userType.uid
          },
          token: payload.token
        }
        state.user = user
      }


    },
    getApiTranslations: () => { },
    getCountryCodes: () => { },
    setCountryCodes: (state, { payload }) => {
      const countries = payload

      const favoriteOrder: any = {}
      countries.forEach((country) => {
        const countryCode = country.code;
        favoriteOrder[countryCode] = country.name;
      });

      state.countryCodes = favoriteOrder;
    },
    setApiTranslationsEdit: (state, { payload }) => {
      state.apiTranslationsEdit = payload;
    },
    setApiTranslations: (state, { payload }) => {
      state.api_t = GENERATE_TRANSLATIONS(payload);
    },
    setTranslation:( state, { payload }) => {
      state.translation = payload
    },
    toggleTranslation: (state, { payload }) => { },
    navigateTo: (state, { payload }) => {},
    navigateBack: () => {},
    toggleApiTranslationsEdit: () => {},
    updateApiTranslationKey: (state, { payload }) => {},
    updateUserRole: (state, { payload }) => {},
    setIsInApp: (state, { payload }) => {
      state.isInApp = payload
    },
    setUserInfo:(state, {payload}) => {
      state.userInfo = payload
    },
    setPermissions: (state, {payload}) => {

      const permissions : any = Array.from([])

      Object.keys(payload).map(el => {
        payload[el].forEach(element => {
          permissions.push(element)
        });
        
        return true;
      })

      state.permissions = permissions
    }
  },
});
