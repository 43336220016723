import styled from 'styled-components'

//! REmover estes styles do outro file
export const Wrapper = styled.div`
  width: 30%;
  display:flex;
  justify-content: space-evenly;
  align-items: center;
  column-gap:5px;
  min-width: 180px;
  flex-wrap:wrap;
`

export const PassengerCounter = styled.div`
  display:flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
`

export const Counter = styled.div<{ margin: string }>`
  display:flex;
  justify-content: flex-start;
  margin-right: ${(props) => props.margin}px;
`

export const ButtonIcon = styled.div<{ marginr?: string, marginl?: string, pointer?: string }>`
  margin-right: ${props => props.marginr ? props.marginr : '0'}px;
  margin-left: ${props => props.marginl ? props.marginl : '0'}px;
  cursor: ${props => props.pointer ? props.pointer : 'pointer'};
`

export const ActionsWrapper = styled.div`
  display:flex;
  justify-content: space-between;
  min-width:140px;
`