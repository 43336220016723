import React from 'react';
import AnimarisLogoDash from 'assets/icons/logo/animarisLogoMasterHorizontal';
import { Wrapper } from './styles';

const Dashboard = ({ t, i18n }) => {

  return (
    <Wrapper>
      <AnimarisLogoDash />
    </Wrapper>
  );
};

export default Dashboard;


