import * as React from "react"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.666504 1.33331L1.60931 0.390504L15.4668 14.248L14.524 15.1908L0.666504 1.33331Z" fill="#E55353" />
            <path fillRule="evenodd" clipRule="evenodd" d="M1.27362 2.88328C0.908792 3.12185 0.666504 3.53401 0.666504 4.00002V9.33335C0.666504 10.0667 1.2665 10.6667 1.99984 10.6667H9.05703L7.72369 9.33335H1.99984V4.00002H2.39036L1.27362 2.88328ZM4.6984 6.30806C4.67744 6.42438 4.6665 6.54422 4.6665 6.66669C4.6665 7.77335 5.55984 8.66669 6.6665 8.66669C6.78897 8.66669 6.90882 8.65575 7.02513 8.63479L4.6984 6.30806ZM7.70841 8.37526L4.95793 5.62478C5.19536 5.23555 5.56212 4.93432 5.99903 4.78026L8.55293 7.33416C8.39887 7.77107 8.09764 8.13783 7.70841 8.37526ZM8.6665 9.33335L9.99984 10.6667H11.3332C11.4974 10.6667 11.6549 10.6366 11.8005 10.5817L10.5521 9.33335H8.6665ZM11.3332 9.17159L12.3523 10.1907C12.5481 9.95849 12.6665 9.65907 12.6665 9.33335V4.00002C12.6665 3.26669 12.0665 2.66669 11.3332 2.66669H4.82826L6.1616 4.00002H11.3332V9.17159ZM6.83524 4.67366L8.65953 6.49795C8.5788 5.52623 7.80696 4.75439 6.83524 4.67366ZM5.21879 4.00002H3.33317L1.99984 2.66669H3.88545L5.21879 4.00002ZM13.2188 12H11.3332L12.6665 13.3334H13.9998C14.164 13.3334 14.3215 13.3033 14.4671 13.2484L13.2188 12ZM15.019 12.8574L13.9998 11.8383V4.66669H15.3332V12C15.3332 12.3257 15.2148 12.6252 15.019 12.8574ZM11.7237 13.3334L10.3904 12H2.6665V13.3334H11.7237Z" fill="#E55353" />
        </svg>

    )
}

export default SvgComponent
