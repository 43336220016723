import { ActivityPhotoForm, ActivityForm } from 'types/trips/activity/activityForm'
import { defaultActivityForm } from '../utils'


export interface InitialState {
    activityID: string,
    edit: Boolean,
    activityForm: ActivityForm,
    taxes: any,
    vehiclesEnabled: boolean,
    vehicles: any,
    circuitsEnabled: boolean,
    extras: any,
    extrasEnabled: boolean,
    circuits: any,
    passengerTypesEnabled: boolean,
    passengerTypes: any,
    photos: ActivityPhotoForm[],
    newPhotosID: number,
    hasEditPermission: boolean,
    submitButtonState: boolean
    hasBeachRule: boolean,
}

export const initialState: InitialState = {
    activityID: "",
    edit: false,
    hasEditPermission: true,
    submitButtonState: false,
    hasBeachRule: false,
    activityForm: defaultActivityForm,
    taxes: [],
    photos: [],
    newPhotosID: 99,
    vehiclesEnabled: true,
    vehicles: [
        {
            name: "",
            isAvailable: false,
            maxCapacity: 0,
            isActive: { value: false, valid: true, message: "" }
        }
    ],
    circuitsEnabled: true,
    circuits: [
        {
            name: "",
            isAvailable: false,
            duration: "",
            isActive: { value: false, valid: true, message: "" }
        }
    ],
    passengerTypesEnabled: true,
    passengerTypes: [
        {
            name: "",
            priceForIsland: { value: "", valid: true, message: "" },
            price: { value: 0, valid: true, message: "" },
            isActive: { value: false, valid: true, message: "" }
        }
    ],
    extrasEnabled: true,
    extras: [
        {
            name: "",
            price: { value: 0, valid: true, message: "" },
            isActive: { value: false, valid: true, message: "" },
            maxCapacity: { value: 0, valid: true, message: "" }
        }
    ],
}
