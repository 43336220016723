/**
 * Tripplanningsform scene utils
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */
import * as Check from 'validations'

//  TODO: passar vehiclesArray e verificar se todos os veiculos ja adicionados constam na atividade/serviço que se pretende adcionar
export const activityFilter = (activities, services, currentOption, vehicles) => {

    const a: any[] = Array.from(activities)
    return a.filter(item => {
        return item.allowMultipleActivities
    }).filter((item: any) => {
        if (services.find(serv => serv.serviceDetails.id.value === item.id)) {
            if (currentOption === item.id) {
                return item
            }
            return false
        }
        else {
            return item
        }
    }).map((act) => {
        return ({
            label: act.name,
            value: act.id,
        })
    })
}

export const vehiclesIntersectionFilter = (fleet, services, vehiclesArray, currentOption) => {

    const v: any[] = Array.from(fleet)
    return v.filter((item: any) => {

        let intersection: any = []
        services.forEach(serv => {
            //serv.serviceDetails?.vehicles.find(v => v.vehicle?.uuid === item.uuid)
            serv.serviceDetails?.vehicles.forEach((vehicle) => {
                if (vehicle?.uuid === item.uuid)
                    intersection.push(item)
            })
        });
        if (intersection.length !== 0 && intersection.length === services.length) {
            return item
        }
        return false

    }).filter((item: any) => {
        if (vehiclesArray.find(vhc => vhc.uuid.value === item.uuid)) {
            if (currentOption === item.uuid) {
                return item
            }
            return false
        }
        else {
            return item
        }
    }).map((vhc: any) => {
        return {
            label: vhc.name,
            value: vhc.uuid
        }
    })
}

export const vehicleReinforcementFilter = (fleet, activities, planning, isUpdatingCircuit, currentVehicle) => {


    const availableActivityVehicles: any[] = Array.from(activities).filter((act: any) => {
        if (planning.activities.find(plActivity => plActivity.activity.id === act.id)) return act
        return false
    }).map((act: any) => {
        return act.vehicles
    })


    const firstActivityAvailableVehicles: any[] = Array.from(availableActivityVehicles[0])

    return firstActivityAvailableVehicles.filter(v => {

        //if addign new vehicle, only available vehicles that are not being used must be in the dropdown
        //if we want to replace vehicle or change vehicle circuits the modal default vehicle must be available in case we only want to change its stops
        if (!isUpdatingCircuit) {
            if (planning.vehicles.find(plVHC => plVHC.vehicle.uuid === v.uuid)) {
                return false
            }
        }
        else {
            if (planning.vehicles.find(plVHC => plVHC.vehicle.uuid === v.uuid)) {
                if (currentVehicle === v.uuid) {
                    return true
                }
                return false
            }
        }

        if (v.value === "") {
            return true
        }


        return true

    }).map((vhc: any) => {
        return {
            label: vhc.name,
            value: vhc.uuid
        }
    })
}

export const circuitsIntersectionFilter = (circuits, services) => {

    const c = circuits.filter((item) => {

        let intersection: any = []
        services.forEach(serv => {
            serv.serviceDetails?.circuits.forEach((circuit) => {
                if (circuit.id === item.id) {
                    intersection.push(item)
                }
            })
        });

        if (intersection.length !== 0 && intersection.length === services.length) {
            return true
        }
        return false

        //return item
    }).map((crc: any) => {
        return {
            label: crc.name,
            value: crc.id
        }
    })

    return c
}

export const PlanningDatesValidation = {
    date: [Check.isRequired],
}

export const PlanningVehiclesValidation = {
    uuid: [],
    capacity: [],
}

export const PlanningServiceValidation = {
    activity: [Check.isRequired]
}

export const PlaninngMaxCapacityValidation = {
    maxCapacity: [Check.isRequired, Check.isNumber]
}

export const PlaninngMinimumPriceValidation = {
    minimumPrice: [Check.isRequired, Check.isNumber]
}

export const PlaninngIncludedSeatsValidation = {
    includedSeats: [Check.isRequired, Check.isNumber]
}

export const PlanningServicePassengerTypesValidation = {
    id: [],
    priceForIsland: [],
    price: [Check.isRequired, Check.isNumber]
}

export const PlanningStopsValidation = {
    uuid: [],
    time: [Check.isRequired] //change this
}

export const PlanningCargoValidation = {
    observations: [Check.isRequired],
    destination: [Check.isRequired],
    recipient: [Check.isRequired],
}
export const PlanningCargoValidationNoCargo = {
    observations: [],
    destination: [],
    recipient: [],
}

export const PlanningCircuitValidation = {
    circuit: [Check.isRequired],
}

export const PlanningModalStopsValidation = {
    time: [Check.isRequired, Check.isCorrectTimeFormat],
}