import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import i18n from 'i18next';
// Styles
import theme from 'ui/theme';
import * as Styles from './styles'
import { Text, Icon, BoardingLabel } from '../../styles';
// Icons
import StartTrip from 'assets/icons/start_trip'
import EndTrip from 'assets/icons/end_trip'
import Properties from 'assets/icons/properties'
import Boat from 'assets/icons/boat_icon'
import StatusLabel from 'assets/icons/status_label'
import PartnerStatusLabel from 'assets/icons/partner_status_label'
import WebsiteStatusLabel from 'assets/icons/website_status_label'
// Components
import CustomButton from 'shared/components/CustomButton';
// Store
import { actions, RootState } from 'store/rootSlices';
// Utils & Configs
import config from 'config';
import { hasPermission } from 'permissions';
// Types 
import type { PlanningListItem } from 'types/trips/plannings';

type PropTypes = {
  planning: PlanningListItem,
  onClick: () => void;
  outboundsLength: number;
  inboundsLength: number;
}

const PaddingCircuit = ({ planning, onClick, outboundsLength, inboundsLength }: PropTypes) => {

  const { allLocals } = useSelector((state: RootState) => state.TripsConfiguration)
  const { selectedVehicle } = useSelector((state: RootState) => state.TripPlannings)
  const {
    setOpenPlanning,
    setShowModal,
    setSelectedVehicle,
    getTripStopDetails,
  } = actions.TripPlannings
  const { permissions } = useSelector((state: RootState) => state.App)
  const dispatch = useDispatch()
  const { PERMISSIONS } = config

  const _renderAssignUnitBtn = () => {
    return planning.vehicles && planning.vehicles.length === 0 && hasPermission(permissions, PERMISSIONS.TRIPS.PLANNINGS.EDIT_PLANNING) && (
      <CustomButton
        onClick={e => {
          dispatch(setSelectedVehicle({ vehicle: "", planning }))
          dispatch(setShowModal({ show: true, planningId: planning.id }))
          dispatch(setOpenPlanning({ planning, locals: allLocals, selectedVehicle: selectedVehicle.value }))
          dispatch(getTripStopDetails())
        }}
        text={i18n.t('tripPlannings.assignVehicle')}
        icon='add'
        iconFirst={true}
        variant='outline'
        color='secondary'
        buttonStyle={{ color: `${theme().gray900Color}`, border: `1px solid ${theme().gray900Color}` }} />
    )
  }

  const _renderAvailabilityInfo = () => {
    return (planning.availableForTeamMembers || planning.availableForAgents || planning.availableForPublic) && (
      <Styles.AvailabilityInfo>
        {planning.availableForTeamMembers && <StatusLabel fill={theme().gray400Color} />}
        {planning.availableForAgents && <PartnerStatusLabel fill={theme().gray400Color} />}
        {planning.availableForPublic && <WebsiteStatusLabel fill={theme().gray400Color} />}
      </Styles.AvailabilityInfo>
    )
  }

  const _renderActivityLabel = () => {
    return planning.activities.find(act => act.activity.isHopOnHopOff) && (
      <Styles.ActivityLabel>{planning.activities.find(act => act.activity.isHopOnHopOff)?.activity.shortName}</Styles.ActivityLabel>
    )
  }

  const _renderIsBoarding = () => {
    return planning.isBoarding && <BoardingLabel>Boarding</BoardingLabel>
  }

  const _renderPropertiesIcon = () => {
    return planning.hasCargo && <Icon marginr='8'><Properties /></Icon>
  }

  return (
    <Styles.PadCircuit onClick={onClick}>
      <Styles.PadCircuitInfo>
        <Icon marginr='20'>
          {outboundsLength > 0 && <StartTrip />}
          {inboundsLength > 0 && <EndTrip />}
        </Icon>
        <Styles.TitleActivity>{planning.activities[0]?.activity.name}</Styles.TitleActivity>
        <Styles.TitleCircuit>{planning.circuit?.name}</Styles.TitleCircuit>
        {_renderActivityLabel()}
        {_renderIsBoarding()}
      </Styles.PadCircuitInfo>
      <Styles.PadCircuitRightInfo>
        {
          planning.vehicles ? planning.vehicles.length === 1 ? (
            <Styles.UnitsInfo>
              {_renderPropertiesIcon()}
              <Icon marginr='8'><Boat fill={planning.vehicles[0]?.vehicle.color} /></Icon>
              <Text>{planning.vehicles[0]?.vehicle.name}</Text>
            </Styles.UnitsInfo>
          ) : planning.vehicles.length > 1 ? (
            <Styles.UnitsInfo>
              {_renderPropertiesIcon()}
              <div style={{ marginRight: '8px' }}><Boat fill={planning.vehicles[0].vehicle.color} /></div>
              <Text>{planning.vehicles.length} {i18n.t('tripPlannings.vehicles')}</Text>
            </Styles.UnitsInfo>
          ) : null : null
        }
        {_renderAssignUnitBtn()}
        {_renderAvailabilityInfo()}
      </Styles.PadCircuitRightInfo>
    </Styles.PadCircuit>
  )
}

export default PaddingCircuit