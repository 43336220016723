import * as React from "react"
import theme from "ui/theme"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="icon icon-lg" role="img">
            <rect width="352" height="32" x="80" y="96" fill={theme().gray600Color} className="ci-primary"></rect>
            <rect width="352" height="32" x="80" y="240" fill={theme().gray600Color} className="ci-primary"></rect>
            <rect width="352" height="32" x="80" y="384" fill={theme().gray600Color} className="ci-primary"></rect>
        </svg>
    )
}

export default SvgComponent
