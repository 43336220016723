/**
 * Fuellocationconfigform scene slice
 *
 * @author João Dias <jdias@ubiwhere.com>
 *
 *
 */
import { createSlice } from '@reduxjs/toolkit'
import { defaultFuelLocationForm, fuelLocationConfigValidations, FuelLocationForm } from '../utils'
import { FuellingLocation } from "types/fleet/fuel";
import * as Check from "validations"

type State = {
  edit: boolean,
  loading: boolean,
  fuelLocation: FuellingLocation | null;
  fuelLocationForm: FuelLocationForm,
  hasEditPermission: boolean,
  submitButtonState: boolean,
}

const initialState: State = {
  edit: false,
  loading: true,
  fuelLocation: null,
  fuelLocationForm: defaultFuelLocationForm,
  hasEditPermission: true,
  submitButtonState: false
}

export default createSlice({
  name: 'Fuellocationconfigform',
  initialState,
  reducers: {
    onMount: (state, { payload }) => { },
    onUnmount: () => { },
    onSubmit: () => { },
    getFuelLocationConfig: (state, { payload }) => { },
    setFuelLocation: (state, { payload }) => {
      state.fuelLocation = payload;
    },
    setForm: (state, { payload }) => {
      state.fuelLocationForm = payload;
    },
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
    setHasEditPermission: (state, { payload }) => {
      state.hasEditPermission = payload
    },
    setEditMode: (state, { payload }) => {
      state.edit = payload;
    },
    setSubmitButtonDisabled:(state, { payload }) => {
      state.submitButtonState = payload
    },
    onFormChange: (state, { payload }) => {
      state.fuelLocationForm = Check.setAndCheckValidation(state.fuelLocationForm, payload, fuelLocationConfigValidations)
    }
  }
})