
import { CFormLabel, CFormText } from '@coreui/react-pro';

import styled from 'styled-components'
import theme from 'ui/theme'

export const CustomFormLabel = styled(CFormLabel)`
  display: flex;
  justify-content: space-between;
`

export const Wrapper = styled.div`
  > label {
    font-size: 14px;
    color: ${theme().darkColor};
    > span {
    color: ${theme().primary};
    }
  }
`;

export const HelpText = styled(CFormText) <{ valid: boolean | undefined }>`
  color: ${(props) => props.valid === false ? theme().danger : theme().gray200Color};
`