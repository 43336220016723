import * as React from "react"
import theme from "ui/theme";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M19.5 4L18 2.5L16.5 4L15 2.5L13.5 4L12 2.5L10.5 4L9 2.5L7.5 4L6 2.5V16.5H3V19.5C3 21.16 4.34 22.5 6 22.5H18C19.66 22.5 21 21.16 21 19.5V2.5L19.5 4ZM6 20.5C5.45 20.5 5 20.05 5 19.5V18.5H15V20.5H6ZM19 19.5C19 20.05 18.55 20.5 18 20.5C17.45 20.5 17 20.05 17 19.5V16.5H8V5.5H19V19.5ZM9 7.5V9.5H15V7.5H9ZM16 7.5H18V9.5H16V7.5ZM9 10.5V12.5H15V10.5H9ZM16 10.5H18V12.5H16V10.5Z" fill={props.fill ? props.fill : theme().gray600Color} />
        </svg>

    )
}

export default SvgComponent
