import axios from 'axios'
import endpoints from 'api/endpoints'
import { generateUrl } from 'utils'

const GetAllDashboards = (page: number) => {
  let endpoint = `${endpoints.TRIPS.BI.GET_ALL_DASHBOARDS}?page=${page}`;

  return axios.get(endpoint)
    .then(res => res.data)
    .catch(err => {
    })
}

const GetDashboard = (id: string) => {
  const endpoint = generateUrl(endpoints.TRIPS.BI.GET_DASHBOARD, { id })

  return axios.get(endpoint)
    .then(res => res.data)
    .catch(err => {
    })
}

export default {
  GetAllDashboards,
  GetDashboard
}