/**
 * Entities scene sagas
 *
 * @author João Dias <jdias@ubiwhere.com>
 *
 *
 */
import api from 'api';
import { call, putResolve, select, takeLatest } from 'redux-saga/effects'
import { actions, RootState } from 'store/rootSlices';
import { AnyAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify'
import i18n from 'i18next';
import { EntitiesList } from 'types/entity/entity';
import { ItemsList } from 'types/misc';

function* onMountSaga({ payload }: AnyAction) {
  yield getURLParamsSaga(payload);
  yield putResolve(actions.Entities.setLoading(true));
  yield putResolve(actions.Entities.fetchEntities());
}

function* onUnmountSaga() {
  yield putResolve(actions.Entities.setEntities([]));
  yield putResolve(actions.Entities.setTotal(0));
  yield putResolve(actions.Entities.setLoading(true));
}

function* fetchEntitiesSaga() {
  try {
    yield putResolve(actions.Entities.setLoading(true));
    const { page, searchTerm, sort } = yield select((state: RootState) => state.Entities);
    const res: EntitiesList = yield call(api.Entities.GetEntities, page, searchTerm, sort);
    yield putResolve(actions.Entities.setEntities(res.results ?? []));
    yield putResolve(actions.Entities.setTotal(res.count));
  } catch (err) {
    toast.error(i18n.t("toasts.getEntitiesError"))
  } finally {
    yield putResolve(actions.Entities.setLoading(false));
  }
}

function* fetchEntityTypesSaga() {
  try {
    const res: ItemsList = yield call(api.Entities.GetPartnerTypes);
    yield putResolve(actions.Entities.setEntityTypes(res));
  } catch (err) {
    toast.error(i18n.t("toasts.getEntitiesTypesError"))
  }
}

function* getURLParamsSaga(payload: AnyAction) {

  let params = new URLSearchParams(payload.location.search)

  const {
    searchTerm,
  } = yield select((state: RootState) => state.Entities);

  if (params.toString() !== "") {
    yield putResolve(actions.Entities.populateFiltersOnMount({
      searchTerm: params.get('search') ?? ""
    }))
  }
  else {
    params.set('search', searchTerm)
    payload.replace({ search: (params).toString() })
  }
}

export default function* watcherSignin() {
  yield takeLatest('Entities/onMount', onMountSaga)
  yield takeLatest('Entities/onUnmount', onUnmountSaga)
  yield takeLatest('Entities/fetchEntities', fetchEntitiesSaga)
  yield takeLatest('Entities/setSort', fetchEntitiesSaga)
  yield takeLatest('Entities/setPage', fetchEntitiesSaga)
  yield takeLatest('Entities/getEntityTypes', fetchEntityTypesSaga)
  yield takeLatest('Entities/setSearchTerm', fetchEntitiesSaga)
  yield takeLatest('Entities/getURLParams', getURLParamsSaga);
}



