import styled from 'styled-components'
import theme from 'ui/theme'

const StatementStateLabel = styled.span`
  padding:2px 4px;
  color:${theme().white};
  font-weight:700;
  border-radius:5px;

  &.closed {
      background-color: ${theme().success};
  }
  &.open {
      background-color: ${theme().danger};
  }
`

export {
  StatementStateLabel
}