import styled from "styled-components"

export const PadWrapper = styled.div`
  display:flex;
  flex-direction:column;
  justify-content: space-around;
  align-items:center;
  width: 5%;
  min-width: 55px;
  text-align:center;
  border-right: 1px solid #D8DBE0;
`

export const Text = styled.p`
  margin:0px;
`