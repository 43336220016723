/**
 * Tripcategoryform component
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */

import { CCol, CForm, CFormLabel, CRow } from '@coreui/react-pro'
import { useParams } from 'react-router-dom';
import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { actions, RootState } from 'store/rootSlices'
import { Title, TitleBox, ContainerBox } from 'shared/genericStyledComponents';
import CustomTextInput from 'shared/components/CustomTextInput'
import SectionContainer from 'shared/components/Sectioncontainer'
import TabsContainer from 'shared/components/Tabscontainer';
import ContainerDelete from 'shared/components/FormComponents/containerdelete';
import CustomButton from 'shared/components/CustomButton';
import theme from 'ui/theme';
import CustomCheckbox from 'shared/components/CustomCheckbox';
import i18n from 'i18next';
import CIcon from '@coreui/icons-react';
import * as icons from '@coreui/icons'
import FileUpload from 'assets/icons/file_upload';
import { toast } from 'react-toastify';
import config from 'config';
import { hasPermission } from 'permissions';
import * as Styles from './styles'


const Tripcategoryform = (props) => {

  const dispatch = useDispatch();
  const { id } = useParams();

  const { 
    onFormChange, 
    onMount, 
    onUnmount, 
    onSubmit, 
    setIsIconRemoved, 
    setIsIconChanged
  } = actions.TripCategoryForm;
  const { 
    edit, 
    categoryForm, 
    isIconRemoved, 
    isIconChanged, 
    hasEditPermission,
    submitButtonState
  } = useSelector((state: RootState) => state.TripCategoryForm)
  const { navigateBack } = actions.App;
  const { permissions } = useSelector((state: RootState) => state.App)
  const { PERMISSIONS } = config

  const svgRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    dispatch(onMount(id))

    return () => {
      dispatch(onUnmount())
    }

  }, [dispatch, id, onMount, onUnmount]);

  const handleSubmit = (e) => {
    dispatch(onSubmit({ categoryForm, e, edit, isIconRemoved, isIconChanged }))
  }

  const handleChange = (e) => {
    if (e.target.value.toLowerCase().includes(".svg")) {
      dispatch(setIsIconRemoved(false))
      dispatch(setIsIconChanged(true))
      dispatch(onFormChange({ ...categoryForm, icon: { value: e.target.files[0], valid: true, message: "" } }))
    }
    else {
      if(e.target.value!==""){
        toast.error("file type not supported")
      }
    }
  }

  const handleButtonClick = e => {
    if(svgRef.current !== null ){
      svgRef.current.click();
    }
  };

  const handleDeleteSvg = (e) => {
    dispatch(onFormChange({ ...categoryForm, icon: { value: null, valid: true, message: "" }}))
    dispatch(setIsIconRemoved(true))
    dispatch(setIsIconChanged(false))
  }

  return (
    <div>
      <CForm>
        <CustomCheckbox
          id='tripCategoryForm-isActive'
          disabled={!hasEditPermission}
          wrapperStyle={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '-25px', fontSize: '20px' }}
          checked={categoryForm.isActive.value ? true : false}
          onClick={e => dispatch(onFormChange({ ...categoryForm, isActive: { value: e.target.checked, valid: true, message: "" } }))}
          text={i18n.t('tripCategoryForm.isActive')}
          switch
        />
        <div style={{ width: '100%', paddingBottom: '24px' }}>
          <TitleBox>
            <Title>{i18n.t('tripCategoryForm.categoryTitle')}</Title>
          </TitleBox>
          <TabsContainer id="tripActivityForm-tabsDetailsContainer" onChangeTab={tab => { }} content={[
            {
              title: i18n.t('tripCategoryForm.category'),
              content: <ContainerBox>
                <CRow className="g-3">
                  <CCol sm={10}>
                    <CustomTextInput
                      id='tripCategoryForm-name'
                      disabled={!hasEditPermission}
                      required={true}
                      valid={categoryForm.namePt.valid}
                      value={categoryForm.namePt.value}
                      helpText={categoryForm.namePt.message}
                      placeholder={i18n.t('placeholders.name')}
                      label={i18n.t('tripCategoryForm.name')}
                      onChange={(e) => dispatch(onFormChange({ ...categoryForm, namePt: { value: e.target.value, message: "", valid: true } }))}
                    />
                  </CCol>
                  <CCol>
                    <Styles.SVGComponentWrapper>
                      <CFormLabel>Icon</CFormLabel>
                      {categoryForm.icon.value !== null ?
                        <Styles.SVGComponentDeleteWrapper>
                          <Styles.SVGWrapper>
                            <img alt='icon' src={ isIconChanged ? URL.createObjectURL(categoryForm.icon.value) : categoryForm.icon.value}/>
                          </Styles.SVGWrapper>
                          <Styles.Button id='tripCategoryForm-deleteIcon' icon={true} color="none" onClick={e => hasEditPermission && handleDeleteSvg(e)}>{i18n.t('common.deleteBtn')}<CIcon height={15} icon={icons.cilTrash} size={"sm"} /></Styles.Button>
                        </Styles.SVGComponentDeleteWrapper>
                        :
                        <Styles.SVGComponentUploadWrapper>
                            <Styles.Button id='tripCategoryForm-uploadIcon' icon={false} color="none" onClick={e => hasEditPermission && handleButtonClick(e)}>{i18n.t('tripCategoryForm.uploadIcon')}<FileUpload /></Styles.Button>
                            <input style={{display:"none"}} accept=".svg" ref={svgRef} id="file-upload" type="file" onChange={e => handleChange(e)}/>
                        </Styles.SVGComponentUploadWrapper>
                      }
                    </Styles.SVGComponentWrapper>
                  </CCol>
                </CRow>
                <Styles.Row>
                  <CCol>
                    <CustomTextInput
                      id='tripCategoryForm-description'
                      disabled={!hasEditPermission}
                      required={true}
                      valid={categoryForm.descriptionPt.valid}
                      value={categoryForm.descriptionPt.value}
                      helpText={categoryForm.descriptionPt.message}
                      placeholder={i18n.t('placeholders.description')}
                      label={i18n.t('tripCategoryForm.description')}
                      onChange={(e) => dispatch(onFormChange({ ...categoryForm, descriptionPt: { value: e.target.value, message: "", valid: true } }))}
                    />
                  </CCol>
                </Styles.Row>
              </ContainerBox>
            },
            {
              title: i18n.t('common.translations'),
              content: <ContainerBox>
                <CRow className="g-3">
                  <CCol sm>
                    <CustomTextInput
                      id='tripCategoryForm-nameEn'
                      disabled={!hasEditPermission}
                      required={false}
                      valid={categoryForm.nameEn.valid}
                      value={categoryForm.nameEn.value}
                      helpText={categoryForm.nameEn.message}
                      placeholder={i18n.t('placeholders.nameEn')}
                      label={i18n.t('tripActivityForm.nameEn')}
                      onChange={(e) => dispatch(onFormChange({ ...categoryForm, nameEn: { value: e.target.value, message: "", valid: true } }))}
                    />
                  </CCol>
                  <CCol sm={8}>
                    <CustomTextInput
                      id='tripCategoryForm-description'
                      disabled={!hasEditPermission}
                      required={false}
                      valid={categoryForm.descriptionEn.valid}
                      value={categoryForm.descriptionEn.value}
                      helpText={categoryForm.descriptionEn.message}
                      placeholder={i18n.t('placeholders.descriptionEn')}
                      label={i18n.t('tripActivityForm.descriptionEn')}
                      onChange={(e) => dispatch(onFormChange({ ...categoryForm, descriptionEn: { value: e.target.value, message: "", valid: true } }))}
                    />
                  </CCol>
                </CRow>
                <Styles.Row>
                  <CCol sm>
                    <CustomTextInput
                      id='tripCategoryForm-nameEs'
                      disabled={!hasEditPermission}
                      required={false}
                      valid={categoryForm.nameEs.valid}
                      value={categoryForm.nameEs.value}
                      helpText={categoryForm.nameEs.message}
                      placeholder={i18n.t('placeholders.nameEs')}
                      label={i18n.t('tripActivityForm.nameEs')}
                      onChange={(e) => dispatch(onFormChange({ ...categoryForm, nameEs: { value: e.target.value, message: "", valid: true } }))}
                    />
                  </CCol>
                  <CCol sm={8}>
                    <CustomTextInput
                      id='tripCategoryForm-descriptionEs'
                      disabled={!hasEditPermission}
                      required={false}
                      valid={categoryForm.descriptionEs.valid}
                      value={categoryForm.descriptionEs.value}
                      helpText={categoryForm.descriptionEs.message}
                      placeholder={i18n.t('placeholders.descriptionEs')}
                      label={i18n.t('tripActivityForm.descriptionEs')}
                      onChange={(e) => dispatch(onFormChange({ ...categoryForm, descriptionEs: { value: e.target.value, message: "", valid: true } }))}
                    />
                  </CCol>
                </Styles.Row>
                <Styles.Row>
                  <CCol sm>
                    <CustomTextInput
                      id='tripCategoryForm-nameFr'
                      disabled={!hasEditPermission}
                      required={false}
                      valid={categoryForm.nameFr.valid}
                      value={categoryForm.nameFr.value}
                      helpText={categoryForm.nameFr.message}
                      placeholder={i18n.t('placeholders.nameFr')}
                      label={i18n.t('tripActivityForm.nameFr')}
                      onChange={(e) => dispatch(onFormChange({ ...categoryForm, nameFr: { value: e.target.value, message: "", valid: true } }))}
                    />
                  </CCol>
                  <CCol sm={8}>
                    <CustomTextInput
                      id='tripCategoryForm-descriptionFr'
                      disabled={!hasEditPermission}
                      required={false}
                      valid={categoryForm.descriptionFr.valid}
                      value={categoryForm.descriptionFr.value}
                      helpText={categoryForm.descriptionFr.message}
                      placeholder={i18n.t('placeholders.descriptionFr')}
                      label={i18n.t('tripActivityForm.descriptionFr')}
                      onChange={(e) => dispatch(onFormChange({ ...categoryForm, descriptionFr: { value: e.target.value, message: "", valid: true } }))}
                    />
                  </CCol>
                </Styles.Row>
              </ContainerBox>
            }
          ]}/>
        </div>
        
        {/** Rules Section - Flags */}
        <SectionContainer title={i18n.t('tripCategoryForm.rulesTitle')} style={{ width: '100%', paddingBottom: '24px' }}>
          <Styles.RulesRow>
            <CCol>
              <CustomCheckbox
                id={`tripCategoryForm-applyDiscounts`}
                disabled={!hasEditPermission}
                checked={categoryForm.allowDiscounts.value === true ? true : false}
                onClick={e => dispatch(
                  onFormChange(
                    {
                      ...categoryForm,
                      allowDiscounts: { value: e.target.checked, valid: true, message: "" }

                    }
                  )
                )}
                text={i18n.t('tripCategoryForm.applyDiscounts')}
              />
            </CCol>
            <CCol>
              <CustomCheckbox
                id={`tripCategoryForm-applyComissions`}
                disabled={!hasEditPermission}
                checked={categoryForm.allowComissions.value === true ? true : false}
                onClick={e => dispatch(
                  onFormChange(
                    {
                      ...categoryForm,
                      allowComissions: { value: e.target.checked, valid: true, message: "" }

                    }
                  )
                )}
                text={i18n.t('tripCategoryForm.applyComissions')}
              />
            </CCol>
            <CCol>
              <CustomCheckbox
                id={`tripCategoryForm-allowMultipleActivities`}
                disabled={!hasEditPermission}
                checked={categoryForm.allowMultipleActivities.value === true ? true : false}
                onClick={e => dispatch(
                  onFormChange(
                    {
                      ...categoryForm,
                      allowMultipleActivities: { value: e.target.checked, valid: true, message: "" },
                      isPrivate: { value: false, valid: true, message: "" }
                    }
                  )
                )}
                text={i18n.t('tripCategoryForm.addToOtherActivities')}
              />
            </CCol>
          </Styles.RulesRow>
          <Styles.RulesRow>
            <CCol>
              <CustomCheckbox
                id={`tripCategoryForm-isTransport`}
                disabled={!hasEditPermission}
                checked={categoryForm.isTransport.value === true ? true : false}
                onClick={e => dispatch(
                  onFormChange(
                    {
                      ...categoryForm,
                      isTransport: { value: e.target.checked, valid: true, message: "" },
                      isHopOnHopOff: { value: false, valid: true, message: "" },
                      isPrivate: { value: false, valid: true, message: "" },
                    }
                  )
                )}
                text={i18n.t('tripCategoryForm.isTransport')}
              />
            </CCol>
            <CCol>
              <CustomCheckbox
                id={`tripCategoryForm-isBeach`}
                disabled={!hasEditPermission}
                checked={categoryForm.isBeach.value === true ? true : false}
                onClick={e => dispatch(onFormChange({
                  ...categoryForm,
                  isBeach: { value: e.target.checked, valid: true, message: "" },
                  isPrivate: { value: false, valid: true, message: "" },
                  isHopOnHopOff: { value: false, valid: true, message: "" },

                }))}
                text={i18n.t('tripActivityForm.isBeach')}
              />
            </CCol>
            <CCol>
              <CustomCheckbox
                id={`tripCategoryForm-allowOnlyOneBooking`}
                disabled={!hasEditPermission}
                checked={categoryForm.allowOnlyOneBooking.value === true ? true : false}
                onClick={e => dispatch(onFormChange({
                  ...categoryForm,
                  allowOnlyOneBooking: { 
                    value: categoryForm.isPrivate.value ? true : e.target.checked, 
                    valid: true, 
                    message: "" 
                  },
                }))}
                text={i18n.t('tripActivityForm.allowOnlyOneBooking')}
              />
            </CCol>
          </Styles.RulesRow>
          <Styles.RulesRow>     
            <CCol>
              <CustomCheckbox
                id={`tripCategoryForm-isHopOnHopOff`}
                disabled={!hasEditPermission}
                checked={categoryForm.isHopOnHopOff.value === true ? true : false}
                onClick={e => dispatch(
                  onFormChange(
                    {
                      ...categoryForm,
                      isHopOnHopOff: { value: e.target.checked, valid: true, message: "" },
                      isDirect: { value: !e.target.checked, valid: true, message: "" },
                      isTransport: { value: false, valid: true, message: "" },
                      isPrivate: { value: false, valid: true, message: "" },
                      isBeach: { value: false, valid: true, message: "" },
                    }
                  )
                )}
                text={i18n.t('tripCategoryForm.isHopOnHopOff')}
              />
            </CCol>  
            <CCol>
              <CustomCheckbox
                id={`tripCategoryForm-generateIslandTrips`}
                disabled={!hasEditPermission || categoryForm.isHopOnHopOff.value}
                checked={categoryForm.generateIslandTrips.value === true ? true : false}
                onClick={e => dispatch(
                  onFormChange(
                    {
                      ...categoryForm,
                      generateIslandTrips: { value: e.target.checked, valid: true, message: "" },
                      isHopOnHopOff: { value: false, valid: true, message: "" },
                      isDirect: { value: true, valid: true, message: "" },
                      isPrivate: { value: false, valid: true, message: "" },
                    }
                  )
                )}
                text={i18n.t('tripCategoryForm.generateIslandTrips')}
              />
            </CCol>
            <CCol>
              <CustomCheckbox
                id={`tripCategoryForm-generateMiddleTrips`}
                disabled={!hasEditPermission || categoryForm.isHopOnHopOff.value}
                checked={categoryForm.generateMiddleTrips.value === true ? true : false}
                onClick={e => dispatch(
                  onFormChange(
                    {
                      ...categoryForm,
                      generateMiddleTrips: { value: e.target.checked, valid: true, message: "" },
                      isHopOnHopOff: { value: false, valid: true, message: "" },
                      isDirect: { value: true, valid: true, message: "" },
                      isPrivate: { value: false, valid: true, message: "" },
                    }
                  )
                )}
                text={i18n.t('tripCategoryForm.generateMiddleTrips')}
              />
            </CCol>
          </Styles.RulesRow>
          <Styles.RulesRow>     
            <CCol>
              <CustomCheckbox
                id={`tripCategoryForm-showInDigitalsignage`}
                disabled={!hasEditPermission}
                checked={categoryForm.showInDigitalsignage.value === true}
                onClick={e => dispatch(
                  onFormChange(
                    {
                      ...categoryForm,
                      showInDigitalsignage: { value: e.target.checked, valid: true, message: "" }
                    }
                  )
                )}
                text={i18n.t('tripCategoryForm.showInDigitalsignage')}
              />
            </CCol>
            <CCol>
              <CustomCheckbox
                id={`tripCategoryForm-isPrivate`}
                disabled={!hasEditPermission}
                checked={categoryForm.isPrivate.value}
                onClick={e => dispatch(
                  onFormChange(
                    {
                      ...categoryForm,
                      isPrivate: { value: e.target.checked, valid: true, message: "" },
                      isHopOnHopOff: { value: false, valid: true, message: "" },
                      isBeach: { value: false, valid: true, message: "" },
                      generateMiddleTrips: { value: false, valid: true, message: "" },
                      generateIslandTrips: { value: false, valid: true, message: "" },
                      isTransport: { value: false, valid: true, message: "" },
                      allowMultipleActivities: { value: false, valid: true, message: "" },
                      ...(e.target.checked && { allowOnlyOneBooking: { value: true, valid: true, message: "" } }),
                    }
                  )
                )}
                text={i18n.t('tripCategoryForm.isPrivate')}
              />
            </CCol>
            <CCol>

            </CCol>
          </Styles.RulesRow>
        </SectionContainer>
        {
          edit === true && hasPermission(permissions, PERMISSIONS.TRIPS.CATEGORIES.DELETE_CATEGORY) && <ContainerDelete id='tripCategoryForm-delete' title={i18n.t('tripCategoryForm.removeCategory')} text={<p>{i18n.t('tripCategoryForm.removeCategoryText')}<br />{i18n.t('common.deleteLine2')}</p>}></ContainerDelete>
        }
        <Styles.ButtonWrapper>
          <CustomButton id='tripCategoryForm-cancel' onClick={() => dispatch(navigateBack())} text={i18n.t('common.cancelBtn')} color="link" buttonStyle={{ color: theme().danger, textDecoration: 'unset' }} />
          {
            edit ?
              hasPermission(permissions, PERMISSIONS.TRIPS.CATEGORIES.EDIT_CATEGORY) &&
              <CustomButton
                id='tripCategoryForm-save'
                disabled={submitButtonState}
                text={i18n.t('common.saveBtn')}
                icon="confirm"
                onClick={e => handleSubmit(e)}
                color="success"
                buttonStyle={{ color: "#FFFFFF" }} />
              :
              hasPermission(permissions, PERMISSIONS.TRIPS.CATEGORIES.CREATE_CATEGORY) &&
              <CustomButton
                id='tripCategoryForm-save'
                disabled={submitButtonState}
                text={i18n.t('common.saveBtn')}
                icon="confirm"
                onClick={e => handleSubmit(e)}
                color="success"
                buttonStyle={{ color: "#FFFFFF" }} />
          }
        </Styles.ButtonWrapper>
      </CForm>
    </div>
  )
}

export default Tripcategoryform