/**
 * Tripplanningsform scene sagas
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */
import { PayloadAction } from '@reduxjs/toolkit';
import { takeLatest, putResolve, call, select } from 'redux-saga/effects'
import { toast } from 'react-toastify';
import API from 'api'
import { actions, RootState } from 'store/rootSlices'
import i18n from 'i18next';
// Other Sagas
import { getLegsSaga } from '../components/CircuitsRows/logic/sagas';
// Utils & Configs
import config from 'config';
import { hasPermission } from 'permissions'
import { isTripService } from '../components/ServicesRows/utils';
import { prepareBeachActivity, prepareGenericActivity } from './utils';
const { PERMISSIONS } = config


function* onMountSaga({ payload }: PayloadAction<number>) {
  const { permissions } = yield select((state: RootState) => state.App);

  try {
    let { allPassengerTypes, allExtras } = yield select((state: RootState) => state.TripsConfiguration);
    if (allPassengerTypes?.length === 0) {
      allPassengerTypes = yield call(API.PassengerTypes.GetAllPassengerTypes)
    }

    if (allExtras?.length === 0) {
      allExtras = yield call(API.Extras.GetAllExtras)
    }

    const stopsInfo = yield call(API.Locals.GetAllStops)

    if (allPassengerTypes && allExtras && stopsInfo) {
      yield putResolve(actions.TripPlanningsForm.setPassengerTypesInfo(allPassengerTypes))
      yield putResolve(actions.TripPlanningsForm.setExtrasInfo(allExtras))
      yield putResolve(actions.TripPlanningsForm.setStopsInfo(stopsInfo))
    }
  } catch (error) {
    toast.error(i18n.t("toasts.passengerTypesGetError"));
    yield putResolve(actions.TripPlanningsForm.setLoading(false));
  }

  if (payload) {
    yield putResolve(actions.TripPlanningsForm.setEdit(true))
    yield putResolve(actions.TripPlanningsForm.setHasEditPermission(hasPermission(permissions, PERMISSIONS.TRIPS.PLANNINGS.EDIT_PLANNING)))
    const planningID = payload

    try {
      //get planning from api
      const planningInfo = yield call(API.Plannings.GetPlanning, planningID)
      const { allActivities } = yield select((state: RootState) => state.TripsConfiguration)
      const { outboundStops, inboundStops, vehicles, passengerTypes, extras } = yield select((state: RootState) => state.TripPlanningsForm)

      if (planningInfo) {
        //has to be in this order
        yield putResolve(actions.TripPlanningsForm.setPlanningID(planningID))
        yield putResolve(actions.TripPlanningsForm.setBookingsNumber(planningInfo.seats))
        yield putResolve(actions.TripPlanningsForm.populateServices({
          extras,
          passengerTypes,
          planningInfo,
          activities: allActivities
        }))
        yield putResolve(actions.TripPlanningsForm.populateCircuit(planningInfo))
        yield putResolve(actions.TripPlanningsForm.populateCargo(planningInfo))
        yield putResolve(actions.TripPlanningsForm.populateRules(planningInfo))
        yield putResolve(actions.TripPlanningsForm.populateVehicles(planningInfo))
        yield putResolve(actions.TripPlanningsForm.populateStops({
          planningInfo, //what comes from the api
          outboundStops, //FE structure
          inboundStops, //FE structure
          vehicles
        }))
        yield putResolve(actions.TripPlanningsForm.setEditDate({ editDate: planningInfo.date, editFirstDate: true }))
        yield putResolve(actions.TripPlanningsForm.populateDates(planningInfo))
        yield putResolve(actions.TripPlanningsForm.setShowIsOverbookedModal(planningInfo.isOverbooked))
        yield putResolve(actions.TripPlanningsForm.populateMinimumPrice(planningInfo.minimumPrice))
        yield putResolve(actions.TripPlanningsForm.populateIncludedSeats(planningInfo.includedSeats))
      }
    } catch (error) {
      toast.error(i18n.t("toasts.getTripPlanningError"))
    }

  } else {
    //creating a new planning
    yield putResolve(actions.TripPlanningsForm.setEdit(false))
  }

  yield putResolve(actions.TripPlanningsForm.setLoading(false));
}

function* onSubmitSaga() {
  const {
    services,
  } = yield select((state: RootState) => state.TripPlanningsForm);

  //disable submit button
  yield putResolve(actions.TripPlanningsForm.setSubmitButtonDisabled(true))
  isTripService(services[0]) ? yield prepareGenericActivity() : yield prepareBeachActivity()
}

function* setDeletePlanningSaga({ payload }: PayloadAction<{ id: number }>) {
  const {
    editFirstDate,
  } = yield select((state: RootState) => state.TripPlanningsForm);
  if (payload) {
    const planningID = payload.id
    const finalForm = {
      updateRelatedTrips: editFirstDate ? 0 : 1
    }

    try {
      const affectedBookings = yield call(API.Plannings.DeletePlanning, planningID, finalForm)
      yield putResolve(actions.TripPlanningsForm.setAffectedBookings(affectedBookings))
      yield putResolve(actions.TripPlanningsForm.setEdit(false))

      if (affectedBookings.length) {
        yield putResolve(actions.TripPlanningsForm.setShowBookingsModal(true))
      } else {
        yield putResolve(actions.App.navigateTo('/viagens/agendamentos'))
      }
      toast.success(i18n.t("toasts.deletePlanningSuccess"))
    } catch (error) {
      toast.error(i18n.t("toasts.deletePlanningError"))
    } finally {
      yield putResolve(actions.TripPlanningsForm.setDeleteButtonState(false))
    }
  }
}

function* onPlanningLegsFormSaveSaga() {
  const { planningLegsForm, planningID, editFirstDate } = yield select((state: RootState) => state.TripPlanningsForm);

  try {
    let allLegs = planningLegsForm.flatMap(item => item.legs);
    let legsFormated = allLegs.map((leg) => {
      return {
        id: leg.id,
        isActive: leg.isActive.value
      }
    })
    const finalForm = {
      legs: legsFormated,
      updateRelatedTrips: editFirstDate ? 0 : 1
    }
    const affectedBookings = yield call(API.Plannings.EditPlanningLegs, planningID, finalForm)
    yield putResolve(actions.TripPlanningsForm.setAffectedBookings(affectedBookings.data))

    if (affectedBookings.data.length) {
      yield putResolve(actions.TripPlanningsForm.setShowBookingsModal(true))
    } else {
      yield putResolve(actions.App.navigateTo('/viagens/agendamentos'))
    }
    toast.success(i18n.t("toasts.planningLegsEditSuccess"));

  } catch (error) {
    toast.error(i18n.t("toasts.planningLegsEditError"));
  }

}

function* onUnmountSaga() { }


export default function* watcherSignin() {
  yield takeLatest('Tripplanningsform/onMount', onMountSaga)
  yield takeLatest('Tripplanningsform/onSubmit', onSubmitSaga)
  yield takeLatest('Tripplanningsform/onUnmount', onUnmountSaga)
  yield takeLatest('Tripplanningsform/getLegs', getLegsSaga)
  yield takeLatest('Tripplanningsform/setDeletePlanning', setDeletePlanningSaga)
  yield takeLatest('Tripplanningsform/onPlanningLegsFormSave', onPlanningLegsFormSaveSaga)
}