/**
 * Customtextinput component
 *
 * @author João Dias <jdias@ubiwhere.com>
 *
 *
 */
import React, { ReactElement, useState } from 'react'
import { CFormLabel, CFormInput } from '@coreui/react-pro';
import { PatternFormat } from 'react-number-format';
import * as Styles from './styles'
import { HelpText } from 'shared/genericStyledComponents';
//SVG Assets
import Visibility from 'assets/icons/visibility';
import Password from 'assets/icons/password';
import AlternateEmail from 'assets/icons/alternate_email';
import { getIconNameByPropString } from '../CustomButton/utils';

interface PropTypes {
  id?: string,
  isPassword?: boolean,
  wrapperStyle?: React.CSSProperties,
  disabled?: boolean,
  required?: boolean,
  label?: string,
  labelHelpText?: ReactElement,
  type?: string,
  valid?: boolean,
  helpText?: string,
  value?: string,
  onChange?: Function,
  placeholder?: string,
  icon?: string,
  rows?: string,
  initialValue?: string,
  format?: string,
  edited?: boolean,
  blockedText?: string;
  buttonIcon?: JSX.Element;
}

const CustomTextInput = (props: PropTypes) => {
  const [visible, toggleVisible] = useState(false);

  return (
    <Styles.Wrapper style={props.wrapperStyle}>
      {
        props.labelHelpText 
          ? 
        <Styles.CustomFormLabel><div>{props.label}<span>{!!props.required ? "*" : ""}</span></div>{props.labelHelpText}</Styles.CustomFormLabel>
         :
        props.label && <CFormLabel>{props.label}<span>{!!props.required ? "*" : ""}</span></CFormLabel>
      }
      <Styles.InlineContainer isCustom={props.icon === 'customText'} valid={props.valid} btnIcon={!!props.buttonIcon} icon={!!props.icon || (props.type === "password")} edited={props.edited}>
        {((props.icon || props.type === "password") && props.type !== "textarea") && (
          <Styles.IconContainer isCustom={props.icon === 'customText'}>
            {props.icon === 'euro' && getIconNameByPropString(props.icon)}
            {props.icon === 'email' && <AlternateEmail />}
            {props.type === 'password' && <Password />}
            {props.icon === 'percent' && <Styles.CustomPercentIcon>%</Styles.CustomPercentIcon>}
            {props.icon === 'customText' && <Styles.CustomText>{props.blockedText}</Styles.CustomText>}
          </Styles.IconContainer>
        )}
        {(!props.type || (props.type !== "textarea" && props.type !== "password")) && (
          props.format ?
            <PatternFormat
              autoComplete="new-field"
              format={props.format}
              mask="_"
              id={props.id}
              defaultValue={props.initialValue}
              onChange={e => props.onChange ? props.onChange(e) : null}
              placeholder={props.placeholder}
              value={props.initialValue ? undefined : props.value}
              disabled={props.disabled}
              customInput={CFormInput}
            />
            :
            <CFormInput
              autoComplete="new-field"
              id={props.id}
              defaultValue={props.initialValue}
              onChange={e => props.onChange ? props.onChange(e) : null}
              placeholder={props.placeholder}
              value={props.initialValue ? undefined : props.value}
              disabled={props.disabled}
              type={props.type}
            />
        )}
        {props.type && props.type === "textarea" && (
          <Styles.FormTextarea
            id={props.id}
            isvalid={props.valid}
            defaultValue={props.initialValue}
            onChange={e => props.onChange ? props.onChange(e) : null}
            placeholder={props.placeholder}
            value={props.initialValue ? undefined : props.value}
            disabled={props.disabled}
          />
        )}
        {props.type && props.type === "password" && (
          <CFormInput
            autoComplete="new-password"
            id={props.id}
            onChange={e => props.onChange ? props.onChange(e) : null}
            placeholder={props.placeholder}
            disabled={props.disabled}
            type={visible ? "text" : "password"}
          />
        )}
        {props.type && props.type === 'password' && (
          <Styles.PasswordToggleContainer id="pwdToggle" onMouseDown={() => toggleVisible(true)} onMouseUp={() => toggleVisible(false)}>
            <Visibility />
          </Styles.PasswordToggleContainer>
        )}
        {props.buttonIcon && (
          <Styles.BtnIconContainer>
            {props.buttonIcon}
          </Styles.BtnIconContainer>
        )}
      </Styles.InlineContainer>
      {props.helpText && <HelpText valid={props.valid}>{props.helpText}</HelpText>}
    </Styles.Wrapper>
  )
}

export default CustomTextInput