import styled from 'styled-components'
import { CRow, CTableRow, CButton, CTableFoot } from '@coreui/react-pro'

import theme from 'ui/theme';
import { SyncStatus } from 'types/invoicing/invoices'


const CustomLinkWrapper = styled.div`

  > * {
    font-family: 'Inter';
  }

  > label {
    font-size: 14px;
    color: ${theme().darkColor};
    > span {
      color: ${theme().primary};
    }
  }
`

const Link = styled.div`
  font-size: 14px;
  border: 1px solid #D8DBE0;
  border-radius: .25rem;
  background-color: ${theme().gray200Color};
  color: rgba(44,56,74,.95);
  width: 100%;
  height: 35px;
  padding: 6px 12px;
  cursor: pointer;
`

const Wrapper = styled.div``

const PaymentsFooter = styled(CTableFoot)`
    tr {
        background-color: ${theme().offWhite};
        td {
            font-size: 14px;
            font-weight: 700;
            span {
                font-weight: 400;
            }
        }
    }
`;

const AmountNumber = styled.p<{positive: boolean}>`
  margin:0px;
  color: ${props => props.positive ? theme().success : theme().danger}
`

const Bold = styled.h6<{syncStatus: string}>`
  margin: 0px;
  font-size: 19px;
  font-weight: 600;
  color: ${props => props.syncStatus === SyncStatus.SUCCESS ? theme().success : props.syncStatus === SyncStatus.FAILED ? theme().danger : theme().warning};
`

const Row = styled(CRow)`
    --cui-gutter-y:0rem;
     margin-top:26px;
 `

const ButtonWrapper = styled.div`
    display:flex;
    justify-content:flex-end;
    padding-top:22px;
`

const Text = styled.p`
  margin:0px;
`

const TableRow = styled(CTableRow)`
  > td {
      vertical-align: middle;
      color: ${theme().darkColor};
      border-bottom: solid 1px ${theme().gray200Color};
      font-size: 14px;

      > .smaller {
          font-size: 11px;
      }
  }
`;

const CustomWrapper = styled.div`
  color: 'blue';
  > table {
    > thead {
      > tr {
        > th {
          color: ${theme().darkColor};
          font-family: 'Inter';
          font-size: 14px;
          font-weight: 700;
          width: 25%;
        }
      }
    }
  }
`

const CustomRow = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: start;
  padding-left: 5px;
`

const CustomLink = styled.a`
  margin-left: 15px;
  width: fit-content;
  cursor: pointer;
` 

const ConfirmButtonPrimary = styled(CButton)`
    color:${theme().white};
    font-size:14px;
    box-shadow:0px 0px;
    &:hover {
      border-color:${theme().success};
      background-color: ${theme().success};
      color: white;
  }
`
const CancelButtonPrimary = styled(CButton)`
    color:${theme().danger};
    font-size:14px;
    box-shadow:0px 0px;
    &:hover {
      border-color:${theme().danger};
      background-color: ${theme().danger};
      color: white;
  }
`

const FormFooter = styled.div<{ footerposition: "flex-start" | "center" | "flex-end" }>`
    display:flex;
    justify-content:${(props) => props.footerposition};
    column-gap:10px;
    padding-top:20px;
    padding-bottom:20px;
`

export {
  CustomLinkWrapper,
  Link,
  PaymentsFooter,
  Wrapper,
  AmountNumber,
  Bold,
  Row,
  ButtonWrapper,
  Text,
  TableRow,
  CustomWrapper,
  CustomRow,
  CustomLink,
  CancelButtonPrimary,
  ConfirmButtonPrimary,
  FormFooter
}