import styled from 'styled-components'

const Wrapper = styled.div`
  width:100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
`

export { Wrapper }