/**
 * Timerecord scene
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 */
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { DateTime } from 'luxon'
import { CBadge } from '@coreui/react-pro'
// Store
import { actions, RootState } from 'store/rootSlices'
// Components
import FilterComponent from 'shared/components/FilterComponent'
import Loadingcontainer from 'shared/components/Loadingcontainer'
import TableContainer from 'shared/components/TableContainer'
import Usertimerecordtablerow from './components/Usertimerecordtablerow'
import SorterComponent from 'shared/components/SorterComponent'
import TabsContainer from 'shared/components/Tabscontainer'
import FilterDatePicker from 'shared/components/FilterDatePicker'
import { presetsLists } from 'shared/components/FilterDatePicker/utils'
import CustomButton from 'shared/components/CustomButton'
import CustomCheckbox from 'shared/components/CustomCheckbox'
import SearchBox from 'shared/components/SearchBox'
// Icons
import ManageAccounts from 'assets/icons/manage_accounts'
import Download from 'assets/icons/file_download';
import People from 'assets/icons/people'
// Types
import { StatementsTab } from 'types/invoicing/statements'
import { TimeRecordListItem } from 'types/users/user'
// Styles
import theme from 'ui/theme'
import { ButtonWrapper, DownloadButton } from './styles'
import { Wrapper, ControlsContainer, Controls1, Controls2 } from 'shared/genericStyledComponents'
// Utils & Configs
import { paginationCurrentPage, paginationElementsCount } from 'utils'
import { hasPermission } from 'permissions'
import config from 'config'


const TimeRecord = ({ t, i18n }) => {

  const {
    onMount,
    onUnmount,
    setFilter,
    setSort,
    setPage,
    setTab,
    setDatesInterval,
    getLists,
    getTimeRecordListFile,
    setGroupFilter,
    changePendingListTimeRecordStatusAll,
    changeApprovedListTimeRecordStatusAll,
    submitApprovedTimeRecords,
    submitDisapprovedTimeRecords,
    setSearchTerm
  } = actions.TimeRecord

  const {
    PERMISSIONS
  } = config

  const {
    filter,
    loading,
    sort,
    tab,
    startDate,
    endDate,
    hasDateParams,
    groupFilter,
    approvedList,
    pendingList,
    approvedTotal,
    pendingTotal,
    toApproveList,
    toDisapproveList,
    pendingListApprovalStatus,
    approvedListApprovalStatus,
    searchTerm,
    exportLoading
  } = useSelector((state: RootState) => state.TimeRecord);

  const {
    collaboratorTypes,
    teams
  } = useSelector((state: RootState) => state.CollaboratorsManagement)

  const {
    permissions,
    /* user */
  } = useSelector((state: RootState) => state.App);

  const dispatch = useDispatch();
  const history = useHistory()

  useEffect(() => {

    dispatch(onMount(history))
    return () => {
      dispatch(onUnmount())
    }
  }, [dispatch, onMount, onUnmount, history]);

  return (
    <Wrapper>
      <ControlsContainer>
        <Controls1>
          <SearchBox
              id="invoicingSummary-searchBox"
              defaultValue={searchTerm}
              onSubmit={(term) => {
                let params = new URLSearchParams(history.location.search);
                term !== "" ? params.set('search', term) : params.delete('search')
                history.replace({ search: (params).toString() })
                dispatch(setSearchTerm(term))
              }}
            />
          <FilterDatePicker
            presets={presetsLists().timeRecord}
            filterName={i18n.t('common.today')}
            showMonthYearDropdown
            range
            reRender={hasDateParams}
            defaultValue={
              {
                startDay: startDate ? DateTime.fromISO(startDate).toJSDate() : DateTime.now().startOf("week").toJSDate(),
                endDay: endDate ? DateTime.fromISO(endDate).toJSDate() : DateTime.now().endOf("week").toJSDate(),
              }
            }
            onChange={(e) => {
              if (e.startDate && e.endDate) {
                dispatch(setDatesInterval({ e, history }))
                dispatch(getLists())
              }
            }}
          />
          {
            hasPermission(permissions, PERMISSIONS.USERS.TIME_RECORD.LIST_WORKDAY) && <FilterComponent
              id="timeRecord-roleFilter"
              icon={<ManageAccounts
                width={20}
                height={20}
                viewBox={"0 0 18 18"} />}
              defaultOption={
                {
                  label: collaboratorTypes.find((ct: any) => ct.uuid.toString() === filter)?.name as string,
                  value: filter
                }
              }
              onChange={(e) => {
                let params = new URLSearchParams(history.location.search);
                e.value !== "" ? params.set('role', e.value) : params.delete('role')
                history.replace({ search: (params).toString() })

                dispatch(setFilter(e.value))
              }}
              filterName={"Colaborador"} options={
                collaboratorTypes.map((ct: any) => {
                  return {
                    label: ct.name,
                    value: ct.uuid.toString()
                  }
                })
              } />
          }
          {
            hasPermission(permissions, PERMISSIONS.USERS.TIME_RECORD.LIST_WORKDAY) && <FilterComponent
              id="timeRecord-groupFilter"
              icon={<People
                width={20}
                height={20}
                viewBox={"0 0 17 12"} />}
              defaultOption={
                {
                  label: teams.find(ct => ct.uuid.toString() === groupFilter)?.name as string,
                  value: groupFilter
                }
              }
              onChange={(e) => {
                let params = new URLSearchParams(history.location.search);
                e.value !== "" ? params.set('group', e.value) : params.delete('group')
                history.replace({ search: (params).toString() })
                dispatch(setGroupFilter(e.value))
              }}
              filterName={"Equipa"} options={
                teams.map(ct => {
                  return {
                    label: ct.name,
                    value: ct.uuid.toString()
                  }
                })
              } />
          }
        </Controls1>
        <Controls2>
          {hasPermission(permissions, PERMISSIONS.USERS.TIME_RECORD.EXPORT_WORKDAY) &&
            <DownloadButton onClick={() => {
              if(!exportLoading) dispatch(getTimeRecordListFile())
            }}>
              <Download width={30} height={30} />
            </DownloadButton>
          }
        </Controls2>
      </ControlsContainer>

      {
        loading ?
          <Loadingcontainer />
          :
          <>
            <TabsContainer
              message={tab === StatementsTab.PENDING ? t("timeRecord.tableInfo1") : t("timeRecord.tableInfo2")}
              id='timeRecord-tabsContainer'
              onChangeTab={(tab) => { dispatch(setTab(tab === t('timeRecord.approved') ? StatementsTab.APPROVED : StatementsTab.PENDING)) }}
              content={[
                {
                  title: t("timeRecord.pending"),
                  badge: <CBadge color="warning">{pendingTotal}</CBadge>,
                  content:
                    <TableContainer
                      count={paginationElementsCount(0)}
                      currentPage={paginationCurrentPage(0, 1)}
                      elementsPerPage={-1}
                      onPageChange={pg => { dispatch(setPage(pg)) }}
                      key="timeRecord-pendingTable"
                      headerElements={[
                        <SorterComponent
                          sort={sort}
                          id="timeRecord-clockSorter"
                          icon={<ManageAccounts viewBox="0 0 14 14" fill={'#636f83'} />}
                          filterName="avatar"
                          active={sort.field === "clockin"}
                          onChange={(dir) => { dispatch(setSort({ field: "clockin", direction: dir })) }}
                        />,
                        <SorterComponent
                          sort={sort}
                          id="timeRecord-collabSorter"
                          filterName={t('timeRecord.collaborator')}
                          active={sort.field === "collaborator"}
                          onChange={(dir) => { dispatch(setSort({ field: "collaborator", direction: dir })) }}
                        />,
                        <SorterComponent
                          sort={sort}
                          id="timeRecord-estimatedSorter"
                          filterName={t('timeRecord.estimated')}
                          active={sort.field === "estimated"}
                          onChange={(dir) => { dispatch(setSort({ field: "estimated", direction: dir })) }}
                        />,
                        <SorterComponent
                          sort={sort}
                          id="timeRecord-timeWorkingSorter"
                          filterName={t('timeRecord.timeWorking')}
                          active={sort.field === "timeWorking"}
                          onChange={(dir) => { dispatch(setSort({ field: "timeWorking", direction: dir })) }}
                        />,
                        <SorterComponent
                          sort={sort}
                          id="timeRecord-hoursOnBoardSorter"
                          filterName={t('timeRecord.hoursOnBoard')}
                          active={sort.field === "hoursOnBoard"}
                          onChange={(dir) => { dispatch(setSort({ field: "hoursOnBoard", direction: dir })) }}
                        />,
                        <SorterComponent
                          sort={sort}
                          id="timeRecord-BalanceSorter"
                          filterName={t('timeRecord.balance')}
                          active={sort.field === "balance"}
                          onChange={(dir) => { dispatch(setSort({ field: "balance", direction: dir })) }}
                        />,
                        <CustomCheckbox
                          checkStyle={{ marginTop: '5px' }}
                          id={`timeRecord-approveAll`}
                          disabled={false}
                          checked={pendingListApprovalStatus}
                          onClick={e => {
                            dispatch(changePendingListTimeRecordStatusAll({ status: !pendingListApprovalStatus, pendingList }))
                          }}
                        />,
                        '',

                      ]}
                    >
                      {
                        pendingList.map((cl: TimeRecordListItem, idx: number) => {
                          return <Usertimerecordtablerow
                            key={`timeRecordPendingRow-${idx}-${cl.id}`}
                            id={`collaboratorsManagement-tableRow-${idx}`}
                            permission={true}
                            onClick={() => {
                              dispatch(actions.App.navigateTo(`/colaboradores/registo-horas/editar/${cl.uuid}`))
                            }}
                            user={cl}
                            idx={idx}
                            actions={
                              <React.Fragment>
                              </React.Fragment>
                            }
                          />
                        })
                      }
                    </TableContainer>
                },
                {
                  title: t("timeRecord.approved"),
                  badge: <CBadge color="primary">{approvedTotal}</CBadge>,
                  content:
                    <TableContainer
                      key="timeRecord-paidTable"
                      count={paginationElementsCount(0)}
                      currentPage={paginationCurrentPage(0, 1)}
                      elementsPerPage={-1}
                      onPageChange={() => { }}
                      headerElements={[
                        <SorterComponent
                          sort={sort}
                          id="timeRecord-clockSorter"
                          icon={<ManageAccounts viewBox="0 0 14 14" fill={'#636f83'} />}
                          filterName="avatar"
                          active={sort.field === "clock"}
                          onChange={(dir) => { dispatch(setSort({ field: "clock", direction: dir })) }}
                        />,
                        <SorterComponent
                          sort={sort}
                          id="timeRecord-collabSorter"
                          filterName={t('timeRecord.collaborator')}
                          active={sort.field === "collaborator"}
                          onChange={(dir) => { dispatch(setSort({ field: "collaborator", direction: dir })) }}
                        />,
                        <SorterComponent
                          sort={sort}
                          id="timeRecord-estimatedSorter"
                          filterName={t('timeRecord.estimated')}
                          active={sort.field === "estimated"}
                          onChange={(dir) => { dispatch(setSort({ field: "estimated", direction: dir })) }}
                        />,
                        <SorterComponent
                          sort={sort}
                          id="timeRecord-timeWorkingSorter"
                          filterName={t('timeRecord.timeWorking')}
                          active={sort.field === "timeWorking"}
                          onChange={(dir) => { dispatch(setSort({ field: "timeWorking", direction: dir })) }}
                        />,
                        <SorterComponent
                          sort={sort}
                          id="timeRecord-hoursOnBoardSorter"
                          filterName={t('timeRecord.hoursOnBoard')}
                          active={sort.field === "hoursOnBoard"}
                          onChange={(dir) => { dispatch(setSort({ field: "hoursOnBoard", direction: dir })) }}
                        />,
                        <SorterComponent
                          sort={sort}
                          id="timeRecord-BalanceSorter"
                          filterName={t('timeRecord.balance')}
                          active={sort.field === "balance"}
                          onChange={(dir) => { dispatch(setSort({ field: "balance", direction: dir })) }}
                        />,
                        <CustomCheckbox
                          checkStyle={{ marginTop: '5px' }}
                          id={`timeRecord-disapproveAll`}
                          disabled={false}
                          checked={approvedListApprovalStatus}
                          onClick={e => {
                            dispatch(changeApprovedListTimeRecordStatusAll({ status: !approvedListApprovalStatus, approvedList }))
                          }}
                        />,
                        '',

                      ]}
                    >
                      {
                        approvedList.map((cl: TimeRecordListItem, idx: number) => {
                          return <Usertimerecordtablerow
                          key={`timeRecordPaidRow-${idx}-${cl.id}`}
                            id={`timeRecord-tableRow-${idx}`}
                            permission={true}
                            user={cl}
                            idx={idx}
                            actions={
                              <React.Fragment>
                              </React.Fragment>
                            }
                          />
                        })
                      }
                    </TableContainer>
                }
              ]}
            />
            {<ButtonWrapper>
              <CustomButton
                id='timeRecord-discard'
                text={tab === StatementsTab.PENDING ? t('timeRecord.approve', { numChecks: toApproveList.length }) : t('timeRecord.disapprove', { numChecks: toDisapproveList.length })}
                icon={tab === StatementsTab.PENDING ? "confirm" : "cancel"}
                onClick={() => {
                  tab === StatementsTab.PENDING ? dispatch(submitApprovedTimeRecords()) : dispatch(submitDisapprovedTimeRecords())
                }}
                color={tab === StatementsTab.PENDING ? "success" : "danger"}
                buttonStyle={{ color: theme().white }}
              />
            </ButtonWrapper>}
          </>
      }
    </Wrapper>
  )
}

export default TimeRecord