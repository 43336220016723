/**
 * Dropdown component
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */

import React from 'react'
import styled from 'styled-components'
import { CFormLabel, CInputGroup } from '@coreui/react';
import Select, { components } from 'react-select'
import theme from 'ui/theme';
import { CFormText } from '@coreui/react-pro';

interface PropTypes {
  id?: string,
  required?: boolean,
  options: Array<{
      label: string,
      value: any,
      icon?: JSX.Element
  }>,
  label?: string,
  value: {
    label: string,
    value: any,
    icon?: JSX.Element
  } | null,
  onChange: Function,
  placeholder?: string,
  valid?: boolean
  helpText?: string,
  disabled?: boolean,
}

interface Labels {
  value: string,
  label: string,
  icon?: JSX.Element
}

const Dropdown = (props: PropTypes) => {

  let formatedOptions: Labels[] = []
  if(props.options && props.options.length > 0) {
    formatedOptions = props.options.map((opt, idx) => (
      {
        value: opt.value,
        label: opt.label,
        icon: opt.icon
      }
    ))
  }

  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      zIndex: 10,
      fontSize: "14px",
      border: `solid 1px ${theme().gray200Color}`,
      borderRadius: "0 0 4px 4px",
      backgroundColor: `${theme().white}`,
      padding: `${theme().em(5, 14)} 0 0`,
    }),
    option: (provided, state) => ({
      ...provided,
      padding: `${theme().em(8, 14)} ${theme().em(14, 14)}`,
      cursor: "pointer",
      backgroundColor: state.isSelected ? `${theme().gray500Color}` : 'transparent',
      ':hover': {
        backgroundColor: `${theme().gray200Color}`,
      },
    }),
    control: (provided) => ({
      ...provided,
      borderColor: `${theme().gray200Color}`,
      ':focus-within': {
        borderColor: "#321fdb40",
        outlineWidth: "0.1px",
        outlineColor: "#321fdb40",
        outlineStyle: "solid",
        outlineOffset: "0px",
        boxShadow: "0 0 1px 5px #321fdb40",
      },
    }),
    container: (provided) => ({
      ...provided,
      width: "100%",
    }),
  };


  return (
      <Wrapper>
        {props.label && <CFormLabel>{props.label}<span>{!!props.required ? "*" : ""}</span></CFormLabel>}
        <CInputGroup id={`${props.id}DropdownWrapper`}>
            {
              <Select 
                id={props.id}
                onChange={e => props.onChange ? props.onChange(e) : null}
                options={formatedOptions}
                isClearable={false}
                isDisabled={props.disabled}
                isSearchable={false}
                styles={customStyles}
                placeholder={props.placeholder}
                components={{ 
                  Option: (props) => <Option {...props} />, 
                  SingleValue: (props) => <CustomSingleValue {...props} /> 
                }}
                defaultValue={props.value}
              />  
            }
        </CInputGroup>
        {props.helpText && <HelpText valid={props.valid}>{props.helpText}</HelpText>}
      </Wrapper>
  )
}

export default Dropdown

// Custom Option component
const Option = (props) => {
  const { label, data } = props;
  return (
    <components.Option {...props}>
      {data.icon && <span style={{marginRight: "5px"}}>{data.icon}</span>}
      {label}
    </components.Option>
  );
};

const CustomSingleValue = (props) => {
  const { data } = props;
  return (
    <components.SingleValue {...props}>
      {data && (
        <>
          {data.icon && <span style={{marginRight: "5px"}}>{data.icon}</span>}
          {data.label}
        </>
      )}
    </components.SingleValue>
  );
};

const Wrapper = styled.div<{ edited?: boolean }>`
   width: 100%;
 
   > label {
     font-size: 14px;
     color: ${theme().darkColor};
     > span {
       color: ${theme().primary};
     }
   }


 `

const HelpText = styled(CFormText) <{ valid: boolean | undefined }>`
   color: ${(props) => props.valid === false ? theme().danger : '#D8DBE0'};
   text-align: left;
 `
// const FormSelect = styled(CFormSelect) <{ isvalid: boolean | undefined, edited?: boolean }>`
//      width:'100%';
//      border: ${({ isvalid, edited }) => isvalid === false ?
//         `1px solid ${theme().danger}`
//         : edited === true ?
//             `2px dashed ${theme().gray200Color}`
//             :
//             `1px solid #D8DBE0`} !important; 
//      color:${theme().gray600Color};
//      font-size:14px;
//      background-image: url("data:image/svg+xml,%3Csvg width='8' height='13' viewBox='0 0 8 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.99994 2.38667L6.11328 4.5L7.05328 3.56L3.99994 0.5L0.939941 3.56L1.88661 4.5L3.99994 2.38667ZM3.99994 10.6133L1.88661 8.5L0.946608 9.44L3.99994 12.5L7.05994 9.44L6.11328 8.5L3.99994 10.6133Z' fill='%23636F83'/%3E%3C/svg%3E");
//      &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
//          color: ${theme().gray500Color};
//          opacity: 1; /* Firefox */
//      }
//  `
