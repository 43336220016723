import * as React from "react"

const SvgComponent = (props) => (
    <svg
        width={11}
        height={15}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M9.5 4.833h-.667V3.5a3.335 3.335 0 0 0-6.667 0v1.333H1.5c-.733 0-1.333.6-1.333 1.334v6.666c0 .734.6 1.334 1.333 1.334h8c.733 0 1.333-.6 1.333-1.334V6.167c0-.734-.6-1.334-1.333-1.334ZM3.5 3.5c0-1.107.893-2 2-2 1.106 0 2 .893 2 2v1.333h-4V3.5Zm6 9.333h-8V6.167h8v6.666Zm-4-2c.733 0 1.333-.6 1.333-1.333s-.6-1.333-1.333-1.333c-.734 0-1.333.6-1.333 1.333s.6 1.333 1.333 1.333Z"
            fill={props.fill ?? "#D8DBE0"}
        />
    </svg>
)

export default SvgComponent