/**
 * Loadingcontainer component
 *
 * @author João Dias <jdias@ubiwhere.com>
 *
 *
 */

import React from 'react'
import { Wrapper, Spinner } from './styles'

const Loadingcontainer = (props) => {
  return (
    <Wrapper>
      <Spinner><div></div><div></div><div></div><div></div></Spinner>
    </Wrapper>
  )
}

export default Loadingcontainer
