import styled from 'styled-components'
import { CButton, CCol } from '@coreui/react-pro'


export const GenerateColumnButton = styled(CCol)`
 display:flex;
 justify-content:space-evenly;
 flex-wrap:wrap;
`

export const Button = styled(CButton) <{ valid: boolean }>`
  line-height:1.35;
  align-self:${props => props.valid === false ? 'center' : 'flex-end'}
`