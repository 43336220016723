/**
 * Customerform scene slice
 *
 * @author João Dias <jdias@ubiwhere.com>
 *
 *
 */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ChangePasswordForm, OnChangePasswordSaveSagaPayload, OnCreateUserAuthSagaPayload } from 'types/customers/customerForm'
import { initialState } from './initialState'

export default createSlice({
  name: 'Customerform',
  initialState,
  reducers: {
    onMount: (state, { payload }) => { },
    onUnmount: () => { 
      return initialState
    },
    onSubmit: () => { },
    getCustomer: (state, { payload }) => { },
    setEditMode: (state, { payload }) => {
      state.edit = payload;
    },
    setCustomer: (state, { payload }) => {
      state.customer = payload;
    },
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
    setHasEditPermission: (state, { payload }) => {
      state.hasEditPermission = payload
    },
    setSubmitButtonDisabled:(state, { payload }) => {
      state.submitButtonState = payload
    },
    setForm: (state, { payload }) => {
      state.customerForm = payload;
    },
    onFormChange: (state, { payload }) => {
      state.customerForm = payload
    },
    onRemoveCustomerAvatar:(state, { payload }) => {
      state.customer = payload
    },
    setIsAvatarChanged:(state, { payload }) => {
      state.isAvatarChanged = payload
    },
    setIsAvatarRemoved:(state, { payload }) => {
      state.isAvatarRemoved = payload
    },
    setShowDeleteModal: (state, { payload }) => {
      state.showDeleteModal = payload
    },
    onDeleteConfirm: (state, { payload }: PayloadAction<string>) => { },
    setShowChangePasswordModal: (state, { payload }: PayloadAction<boolean>) => {
      state.showChangePasswordModal = payload
    },
    getUserAuth:(state, { payload }: PayloadAction<string>) => {},
    setUserAuthExists: (state, { payload }: PayloadAction<boolean>) => {
      state.userAuthExists = payload
    },
    onChangePasswordFormChange: (state, { payload }: PayloadAction<ChangePasswordForm>) => {
      state.changePasswordForm = payload
    },
    onReSendEmail:() => {},
    onChangePasswordSave:(state, { payload }: PayloadAction<OnChangePasswordSaveSagaPayload>) => {},
    createUserAuth:(state, { payload }: PayloadAction<OnCreateUserAuthSagaPayload>) => {},
  }
})