/**
 * Login scene utils
 *
 * @author João Dias <jdias@ubiwhere.com>
 *
 *
 */
import * as Check from 'validations'


export const loginValidations = {
    password: [Check.isRequired],
    username: [Check.isRequired]
}

export const parseJwt = (token) => {
	const base64Url = token.split('.')[1]
	const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
	return JSON.parse(window.atob(base64))
}