/**
 * Customerpurchaserow component
 *
 * @author João Dias <jdias@ubiwhere.com>
 *
 *
 */

import { camelize } from 'humps'
import { CTableDataCell } from '@coreui/react-pro';
import i18n from "i18next";
import { DateTime } from 'luxon';
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
// Icons
import StatusLabel from 'assets/icons/status_label'
import PartnerStatusLabel from 'assets/icons/partner_status_label'
import WebsiteStatusLabel from 'assets/icons/website_status_label'
// Store
import { RootState } from 'store/rootSlices'
import ChevronRight from 'assets/icons/chevron_right';
import { actions } from 'store/rootSlices'
// Configs & Utils
import config  from 'config';
import { hasPermission } from 'permissions';
// Styles
import theme from 'ui/theme';
import { CustomTableRow, ActionsCell } from 'shared/genericStyledComponents'
import { BookingStateLabel, ClickableTableDataCell, BookingNumber, ServiceInfo } from './styles'

const { DATE_FORMAT } = config;


const Customerpurchaserow = (props) => {

  const dispatch = useDispatch()

  const {
      booking:{
        tripDate,
        outboundTrip,
        inboundTrip,
        seats,
        total,
      },
      key
  } = props;

  const { permissions } = useSelector((state: RootState) => state.App)
  const { PERMISSIONS } = config


  return (
    <>
      <CustomTableRow id={key}>
          <ClickableTableDataCell onClick={() => props.onClick()}>
              <BookingNumber>
                  {  
                      props.booking.source === 'partner' ? <PartnerStatusLabel/> :  props.booking.source === 'website' ? <WebsiteStatusLabel/> : <StatusLabel />
                  }
                  {props.booking.uid}
              </BookingNumber>
          </ClickableTableDataCell>
          <CTableDataCell>{DateTime.fromISO(tripDate).toFormat(DATE_FORMAT.DATETIME)}</CTableDataCell>
          <CTableDataCell>
              <ServiceInfo>
                  {
                      outboundTrip ?
                          outboundTrip.tripActivityLeg?.trip.name
                          :
                          inboundTrip?.tripActivityLeg?.trip.name
                  }
              </ServiceInfo>
          </CTableDataCell>
          <CTableDataCell>
              {
                  seats
              }
          </CTableDataCell>
          <CTableDataCell>€{total}</CTableDataCell>
          <CTableDataCell>
              <BookingStateLabel
                  className={props.booking.state}>
                  {
                      i18n.t(`tripBookings.${camelize(props.booking.state === 'paid' || props.booking.state === 'refunded' ? props.booking.state + 'F' : props.booking.state)}`)
                  }
              </BookingStateLabel>
          </CTableDataCell>
          <ActionsCell nopointer>
              {props.actions}
              {
                  hasPermission(permissions, PERMISSIONS.TRIPS.BOOKINGS.VIEW_BOOKING) 
                      &&
                  <ChevronRight className='pointer' fill={theme().gray500Color} onClick={() => {
                      dispatch(actions.App.navigateTo(`/viagens/reservas/editar-reserva/${props.booking.id}`))
                  }}/>
              }
          </ActionsCell>
      </CustomTableRow>
    </>
  )
}

export default Customerpurchaserow