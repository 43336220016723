import * as React from "react"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width={props.width ? props.width : 14}
            height={props.width ? props.width : 14}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M12.25 1.75v1.5h-3v-1.5h3zm-7.5 0v4.5h-3v-4.5h3zm7.5 6v4.5h-3v-4.5h3zm-7.5 3v1.5h-3v-1.5h3zm9-10.5h-6v4.5h6V.25zm-7.5 0h-6v7.5h6V.25zm7.5 6h-6v7.5h6v-7.5zm-7.5 3h-6v4.5h6v-4.5z"
                fill={props.fill ? props.fill : "#EBEDEF"}
            />
        </svg>
    )
}

export default SvgComponent