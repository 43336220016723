import { PayloadAction } from '@reduxjs/toolkit'
import { takeLatest, putResolve, call, select } from 'redux-saga/effects'
import API from 'api'
import { actions, RootState } from 'store/rootSlices'
import { toast } from 'react-toastify';
import * as Check from 'validations';
import { CategoryFormValidations, CategoryFormValidationsEdit, SubmitSagaPayload } from '../utils'
import i18n from 'i18next';
import config from 'config';
import { hasPermission } from 'permissions'
const { PERMISSIONS } = config

//state
const catID = (state: RootState) => state.TripCategoryForm.categoryID

function* onMountSaga({ payload }: PayloadAction<number>) {
  const { permissions } = yield select((state: RootState) => state.App);

  if (payload) {

    // yield putResolve(actions.TripCategoryForm.setAction(true))
    // yield putResolve(actions.TripCategoryForm.populateForm(""))
    const id = payload

    try {
      //get category from api
      const category = yield call(API.Categories.GetCategory, id)

      if (category) {
        
        yield putResolve(actions.TripCategoryForm.setCategoryID(id))
        yield putResolve(actions.TripCategoryForm.setAction(true))
        yield putResolve(actions.TripCategoryForm.setHasEditPermission(hasPermission(permissions, PERMISSIONS.TRIPS.CATEGORIES.EDIT_CATEGORY)))
        yield putResolve(actions.TripCategoryForm.populateForm(category))
      }
      else {
        toast.error(i18n.t("toasts.getCategoryError"));
      }
    } catch (error) {
      toast.error(i18n.t("toasts.getCategoryError"));
    }

  } else {
    // creating a new category
    yield putResolve(actions.TripCategoryForm.setAction(false))
  }
}

function* onSubmitSaga({ payload }: PayloadAction<SubmitSagaPayload>) {

  yield putResolve(actions.TripCategoryForm.setSubmitButtonDisabled(true))

  const validation = payload.edit === true ?
    Check.checkValidationAlternative(payload.categoryForm, CategoryFormValidationsEdit)
    :
    Check.checkValidationAlternative(payload.categoryForm, CategoryFormValidations)

  if (validation.invalid === true) {
    const updateValidationMessages = Check.setAndCheckValidationAlternative(payload.categoryForm, payload.categoryForm, CategoryFormValidations)
    yield putResolve(actions.TripCategoryForm.onFormChange(updateValidationMessages))

    yield putResolve(actions.TripCategoryForm.setSubmitButtonDisabled(false))
    toast.error(i18n.t("toasts.invalidFields"));
  }
  else {
    const categoryForm = payload.categoryForm
    const finalForm = {
      ...payload.categoryForm,
      ...(categoryForm.isHopOnHopOff.value ? {generateIslandTrips : {...categoryForm.generateIslandTrips, value: false}} : { generateIslandTrips : categoryForm.generateIslandTrips }),
      ...(categoryForm.isHopOnHopOff.value ? {generateMiddleTrips : {...categoryForm.generateMiddleTrips, value: false}} : { generateMiddleTrips : categoryForm.generateMiddleTrips })
    }
    
    if (!payload.edit) {
      try {
        
        const category = yield call(API.Categories.CreateCategory, finalForm)

        if (category) {
          yield putResolve(actions.App.navigateTo('/viagens/configuracoes'))
          yield putResolve(actions.Tripsconfiguration.setMakeApiCals(true))
          toast.success(i18n.t("toasts.createCategorySuccess"));
        }

      } catch (error) {
        toast.error(i18n.t("toasts.createCategoryError"));
      }
    }
    else {
      const categoryID = yield select(catID)
      
      try {
        const success = yield call(API.Categories.EditCategory, categoryID, finalForm, payload.isIconRemoved, payload.isIconChanged)

        if (success) {
          yield putResolve(actions.App.navigateTo('/viagens/configuracoes'))
          toast.success(i18n.t("toasts.editCategorySuccess"))
        }
      } catch (error) {
        toast.warning(i18n.t("toasts.editCategoryError"))
      }
    }
  }

  yield putResolve(actions.TripCategoryForm.setSubmitButtonDisabled(false))

}

function* onUnmountSaga() { }


export default function* watcherSignin() {
  yield takeLatest('tripcategoryform/onMount', onMountSaga)
  yield takeLatest('tripcategoryform/onUnmount', onUnmountSaga)
  yield takeLatest('tripcategoryform/onSubmit', onSubmitSaga)
}
