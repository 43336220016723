import { CButton, CRow, CTable, CTableBody, CTableHead } from '@coreui/react-pro'
import styled from 'styled-components'
import theme from 'ui/theme'

const Wrapper = styled.div`
  width: 100%;
  > div {
    border-radius: 5px 5px 0 0;
  }
`

const Row = styled(CRow)`
    --cui-gutter-y:0rem;
    margin-top:26px;
`

const ConfirmButtonPrimary = styled(CButton)`
    color:${theme().white};
    font-size:14px;
    box-shadow:0px 0px;
    &:hover {
      border-color:${theme().success};
      background-color: ${theme().success};
      color: white;
  }
`
const CancelButtonPrimary = styled(CButton)`
    color:${theme().danger};
    font-size:14px;
    box-shadow:0px 0px;
    &:hover {
      border-color:${theme().danger};
      background-color: ${theme().danger};
      color: white;
  }
`

const FormFooter = styled.div<{ footerposition: "flex-start" | "center" | "flex-end" }>`
    display:flex;
    justify-content:${(props) => props.footerposition};
    column-gap:10px;
    padding-top:20px;
    padding-bottom:20px;
`

const Table = styled(CTable)`
`

const CustomCTableHead = styled(CTableHead)`
  background-color: ${theme().gray200Color};

  > tr {
    > th {
      color: ${theme().darkColor};
      font-family: 'Inter';
      font-size: 14px;
      font-weight: 700;
    }

    th:last-child{
      width: 5%;
    }

    th:first-child {
      > svg {
        width: 100%;
        height: 24px;
        margin: auto;
        color: ${theme().darkColor};
        fill: ${theme().darkColor};
      }
    }
  }
`

const CustomCTableBody = styled(CTableBody)`
  border: 1px solid ${theme().gray200Color};
`

export {
  Wrapper,
  Row,
  ConfirmButtonPrimary,
  CancelButtonPrimary,
  FormFooter,
  Table,
  CustomCTableBody,
  CustomCTableHead
}