import styled from 'styled-components'
import { devicesMax } from 'ui/theme'

const ButtonWrapper = styled.div`
  width: 100%;
  display:flex;
  justify-content:flex-end;
  padding-top:22px;
`
const StatementsFilter = styled.div`
  width: 100%;  
  display: flex;
  justify-content: flex-end;

  @media  ${devicesMax.laptop} {
    > div {
      margin-bottom: 5px !important;
    }
  }
`

const DownloadButton = styled.div`
  cursor: pointer;
` 

export {
  ButtonWrapper,
  StatementsFilter,
  DownloadButton,
}