/**
 * Fleetmanagement scene
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */
import React, { useEffect } from 'react'
import {
  Wrapper,
  ControlsContainer,
  Controls1,
  Controls2,
} from 'shared/genericStyledComponents'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { actions, RootState } from 'store/rootSlices'
import SearchBox from 'shared/components/SearchBox'
import FilterComponent from 'shared/components/FilterComponent'
import CustomButton from 'shared/components/CustomButton'
import TableContainer from 'shared/components/TableContainer'
import SorterComponent from 'shared/components/SorterComponent'
import { VehicleListSingle } from 'types/fleet/vehicle'
import VehicleListTableRow from 'scenes/Fleetmanagement/components/VehiclesListTableRow'
import Loadingcontainer from 'shared/components/Loadingcontainer'

import Feed from 'assets/icons/feed';
import Repair from 'assets/icons/repair_service'
import Gas from 'assets/icons/local_gas_station'
import DirectionsBoat from 'assets/icons/directions_boat'
import config from 'config'
import { paginationCurrentPage, paginationElementsCount } from 'utils'
import { hasPermission } from 'permissions'
import theme from 'ui/theme'

//import config from 'config'

const FleetComponent = ({ t }) => {

  const { fleet, loading, total, page, sort, vehicleTypes, filter, searchTerm } = useSelector((state: RootState) => state.FleetManagement);
  const { permissions } = useSelector((state: RootState) => state.App);
  const { onMount, onUnmount, setSearchTerm, setPage, setSort, setFilter } = actions.FleetManagement
  const { navigateTo } = actions.App;

  const { ELEMENTS_PER_PAGE, PERMISSIONS } = config
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    dispatch(onMount(history))
    return () => {
      dispatch(onUnmount())
    }
  }, [dispatch, onMount, onUnmount, history]);

  return (
    <Wrapper>
      <ControlsContainer>
        <Controls1>
          <SearchBox
            id="fleetManagement-searchBox"
            defaultValue={searchTerm}
            onSubmit={(term) => {
              let params = new URLSearchParams(history.location.search);
              term !== "" ? params.set('search', term) : params.delete('search')
              history.replace({ search: (params).toString() })
              dispatch(setSearchTerm(term))
            }}
          />
          <FilterComponent
            id="fleetManagement-vehicleFilter"
            defaultOption={
              {
                label: vehicleTypes.find(ct => ct.id.toString() === filter)?.name as string,
                value: filter
              }
            }
            options={vehicleTypes.map(ct => {
              return {
                label: ct.name,
                value: ct.id.toString()
              }
            })}
            icon={<DirectionsBoat />}
            onChange={(e) => {
              let params = new URLSearchParams(history.location.search);
              e.value !== "" ? params.set('vehicleType', e.value) : params.delete('vehicleType')
              history.replace({ search: (params).toString() })
              dispatch(setFilter(e.value))
            }}
            filterName={t('fleet.vehicle')} />
        </Controls1>
        <Controls2>
          {
            hasPermission(permissions, PERMISSIONS.FLEET.CREATE_VEHICLE) && <CustomButton id="fleetManagement-newButton" text={t('common.new')} icon="add" color="success" buttonStyle={{ color: "#FFFFFF" }} onClick={() => dispatch(navigateTo('/frota/veiculos/novo-veiculo'))} />
          }
        </Controls2>
      </ControlsContainer>
      {
        loading ?
          <Loadingcontainer />
          :
          <TableContainer
            count={paginationElementsCount(total)}
            currentPage={paginationCurrentPage(total, page)}
            elementsPerPage={ELEMENTS_PER_PAGE}
            onPageChange={pg => { dispatch(setPage(pg)); }}
            headerElements={[
              <SorterComponent sort={sort} active={sort.field === "name"} id="fleetManagement-nameSorter" filterName={t('fleet.name')} onChange={(dir) => { dispatch(setSort({ field: "name", direction: dir })) }} />,
              <SorterComponent sort={sort} active={sort.field === "short_name"} id="fleetManagement-abbrSorter" filterName={t('fleet.abbreviation')} onChange={(dir) => { dispatch(setSort({ field: "short_name", direction: dir })) }} />,
              <SorterComponent sort={sort} active={sort.field === "capacity"} id="fleetManagement-capacitySorter" filterName={t('fleet.capacity')} onChange={(dir) => { dispatch(setSort({ field: "capacity", direction: dir })) }} />,
              <SorterComponent sort={sort} active={sort.field === "is_available"} id="fleetManagement-statusSorter" filterName={t('fleet.status')} onChange={(dir) => { dispatch(setSort({ field: "is_available", direction: dir })) }} />,
              <SorterComponent sort={sort} active={sort.field === "fuel_capacity"} id="fleetManagement-tankSorter" filterName={t('fleet.tank')} onChange={(dir) => { dispatch(setSort({ field: "fuel_capacity", direction: dir })) }} />,
              t('common.actions')
            ]}
          >
            {fleet.map((el: VehicleListSingle, idx: number) =>
              <React.Fragment key={`vehicle-list-${idx}`}>
                <VehicleListTableRow
                  id={`fleetManagement-tablerow-${idx}`}
                  permission={hasPermission(permissions, PERMISSIONS.FLEET.VIEW_VEHICLE)}
                  onClick={() => {
                    if (hasPermission(permissions, PERMISSIONS.FLEET.VIEW_VEHICLE)) {
                      dispatch(actions.App.navigateTo(`/frota/veiculos/editar-veiculo/${el.id}`))
                    }
                  }}
                  vehicle={el}
                  actions={
                    <React.Fragment>
                      <Feed fill={`${theme().secondaryColor}`} />
                      <Repair
                        className='pointer'
                        onClick={() => {
                          if (hasPermission(permissions, PERMISSIONS.FLEET.VIEW_MAINTENANCE)) {
                            dispatch(actions.App.navigateTo(`/frota/manutencoes?vehicle=${el.id}`))
                          }
                        }}
                      />
                      <Gas
                        className='pointer'
                        onClick={() => {
                          if (hasPermission(permissions, PERMISSIONS.FLEET.VIEW_FUEL)) {
                            dispatch(actions.App.navigateTo(`/frota/combustivel?vehicle=${el.id}`))
                          }
                        }}
                      />
                    </React.Fragment>
                  }
                />
              </React.Fragment>
            )}
          </TableContainer>
      }
    </Wrapper>
  )
}

export default FleetComponent