/**
 * Tripplannings scene
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 */
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
// Utils & Configs
import config from 'config'
import { hasPermission } from 'permissions'
// Store
import { actions, RootState } from 'store/rootSlices'
// Components
import Planninglisttablerow from './components/Planninglisttablerow'
import PlanningsTableContainer from './components/Planningstablecontainer'
import Loadingcontainer from 'shared/components/Loadingcontainer'
import Datescontainer from './components/Datescontainer';
import Controlscontainer from './components/Controlscontainer';
// Icons
import Boarding from 'assets/icons/login'
import Landing from 'assets/icons/logout'
// Types
import { PlanningListItem } from 'types/trips/plannings'
// Styles
import { Wrapper, Title } from './styles'
import Beachplanningtablerow from './components/Beachplanningtablerow'

const Tripplannings = ({ t, i18n }) => {

  const {
    loading,
    plannings,
    beachPlannings,
    selectedDay,
    fetchTrips,
    bottomLoading,
    hasNextPage,
    scrollLoading,
  } = useSelector((state: RootState) => state.TripPlannings)
  const { permissions } = useSelector((state: RootState) => state.App)
  const {
    onMount,
    onUnmount,
    getPlanningsScroll,
  } = actions.TripPlannings
  const { PERMISSIONS } = config
  const dispatch = useDispatch()
  const history = useHistory()


  useEffect(() => {
    if (!fetchTrips) return;
    if (hasNextPage) dispatch(getPlanningsScroll());
    // eslint-disable-next-line 
  }, [fetchTrips]);

  useEffect(() => {
    dispatch(onMount(history))
    return () => {
      dispatch(onUnmount(false))
    }
    // eslint-disable-next-line 
  }, [dispatch, onMount, onUnmount, history]);

  const _renderLoading = () => {
    return loading && <Loadingcontainer />
  }

  const _renderBeachRow = (planning: PlanningListItem) => { 
    return (
      <React.Fragment key={`beach-planning-${planning.id}`}>
        <Beachplanningtablerow
          permission={hasPermission(permissions, PERMISSIONS.TRIPS.PLANNINGS.VIEW_PLANNING)}
          planning={planning}
          propActions={
            <React.Fragment >
              <Boarding />
              <Landing />
            </React.Fragment>
          }
        />
      </React.Fragment>
    )
  }

  const _renderTripRow = (planning: PlanningListItem) => { 
    return (
      <React.Fragment key={`trip-planning-${planning.id}`}>
        <Planninglisttablerow
          permission={hasPermission(permissions, PERMISSIONS.TRIPS.PLANNINGS.VIEW_PLANNING)}
          planning={planning}
          propActions={
            <React.Fragment >
              <Boarding />
              <Landing />
            </React.Fragment>
          }
        />
      </React.Fragment>
    )
  }

  const _renderContainer = () => {

    return !loading && (
      <PlanningsTableContainer
        id={`tripPlannings-tableContainer`}
        bottomLoading={bottomLoading}
      >
        {
          (beachPlannings.length > 0 && beachPlannings[0].date === selectedDay) 
            ?  
          _renderBeachRow(beachPlannings[0]) 
            : 
          <></>
        }
        {
          plannings?.map((pl: PlanningListItem) => {
            if (pl.date === selectedDay) {
              //* Este check é feito porque com filtros o user pode passar a atividade praia e os resultados
              //* seram guardados no plannings array
              if (pl.activities[0].activity.isBeach) {
                return _renderBeachRow(pl)
              }
              return _renderTripRow(pl)
            }
            return (<></>)
          })
        }
        {
          scrollLoading && <Loadingcontainer />
        }
      </PlanningsTableContainer>
    )
  }

  return (
    <Wrapper >
      <Controlscontainer />
      <Title>{i18n.t('tripPlannings.schedules')}</Title>
      <Datescontainer />
      {_renderLoading()}
      {_renderContainer()}
    </Wrapper>
  )
}

export default Tripplannings