import { SubmitSagaPayload } from 'types/trips/activity/activityForm';
import * as Check from 'validations';

import {
    ActivityExtrasValidations,
    ActivityFormValidations,
    ActivityFormValidationsEdit,
    ActivityPassengerTypesValidations,
    PrivateActivityFormValidations,
    PrivateActivityFormValidationsEdit
} from '../utils'
import { putResolve, call, select } from 'redux-saga/effects'
import { actions, RootState } from 'store/rootSlices'
import { toast } from 'react-toastify';
import i18n from 'i18next';
import API from 'api'

const actvtID = (state: RootState) => state.TripActivityForm.activityID

export function* prepareBeachActivity(payload: SubmitSagaPayload) {

    const validation = payload.edit === true ?
        Check.checkValidationAlternative(payload.activityForm, ActivityFormValidationsEdit)
        :
        Check.checkValidationAlternative(payload.activityForm, ActivityFormValidations)
    const validationExtras = Check.checkListValidation(payload.extras.filter(ext => ext.isOn.value === true), ActivityExtrasValidations)

    if (validation.invalid === true || validationExtras.invalid === true) {

        const updateValidationMessages = Check.setAndCheckValidationAlternative(payload.activityForm, payload.activityForm, ActivityFormValidationsEdit)
        yield putResolve(actions.TripActivityForm.onExtrasValidation({ validationExtras, extras: payload.extras }))
        yield putResolve(actions.TripActivityForm.onFormChange(updateValidationMessages))
        yield putResolve(actions.TripActivityForm.setSubmitButtonDisabled(false))
        toast.error(i18n.t("toasts.invalidFields"));
    }
    else {
        const finalForm = {
            activityForm: payload.activityForm,
            extras: payload.extras.map(ext => {
                return {
                    ...ext,
                    maxCapacity: { value: ext.maxCapacity.value, valid: true, message: "" },
                    price: { value: Number.parseFloat(ext.price.value).toFixed(2), valid: true, message: "" }
                }
            }),
        }

        yield prepareCommonSteps(payload, finalForm)
    }
}

export function* prepareGenericActivity(payload: SubmitSagaPayload) {

    let validationSchema
    if (payload.activityForm.isPrivate.value) {
        validationSchema = payload.edit ? PrivateActivityFormValidationsEdit : PrivateActivityFormValidations;
    } else {
        validationSchema = payload.edit ? ActivityFormValidationsEdit : ActivityFormValidations;
    }
    const validation = Check.checkValidationAlternative(payload.activityForm, validationSchema);

    const validationExtras = Check.checkListValidation(payload.extras.filter(ext => ext.isOn.value === true), ActivityExtrasValidations)
    const validationPassengerTypes = Check.checkListValidation(payload.passengerTypes.filter(pt => pt.isOn.value === true), ActivityPassengerTypesValidations)

    if (validation.invalid === true || validationExtras.invalid === true || validationPassengerTypes.invalid === true) {

        const updateValidationMessages = Check.setAndCheckValidationAlternative(payload.activityForm, payload.activityForm, ActivityFormValidationsEdit)
        yield putResolve(actions.TripActivityForm.onPassengerTypesValidation({ validationPassengerTypes, passengerTypes: payload.passengerTypes }))
        yield putResolve(actions.TripActivityForm.onExtrasValidation({ validationExtras, extras: payload.extras }))
        yield putResolve(actions.TripActivityForm.onFormChange(updateValidationMessages))
        yield putResolve(actions.TripActivityForm.setSubmitButtonDisabled(false))
        toast.error(i18n.t("toasts.invalidFields"));
    }
    else {
        const finalForm = {
            activityForm: payload.activityForm,
            vehicles: payload.vehicles,
            circuits: payload.circuits,
            passengerTypes: payload.passengerTypes.map(pt => {
                return {
                    ...pt,
                    price: { value: Number.parseFloat(pt.price.value).toFixed(2), valid: true, message: "" }
                }
            }),
            extras: payload.extras.map(ext => {
                return {
                    ...ext,
                    price: { value: Number.parseFloat(ext.price.value).toFixed(2), valid: true, message: "" }
                }
            }),
        }

        yield prepareCommonSteps(payload, finalForm)
    }
}

function* prepareCommonSteps(payload: SubmitSagaPayload, finalForm: any) {
    if (!payload.edit) {
        try {

            const activity = yield call(API.Activities.CreateActivity, finalForm)

            if (activity) {
                yield putResolve(actions.TripActivityForm.createPhoto({ photos: payload.photos, stop: activity.id }))
                yield putResolve(actions.App.navigateTo('/viagens/configuracoes'))
                yield putResolve(actions.Tripsconfiguration.setMakeApiCals(true))
                toast.success(i18n.t("toasts.createActivitySuccess"));
            }

        } catch (error) {
            toast.error(i18n.t("toasts.createActivityError"));
        }
    } else {
        const activityID = yield select(actvtID)

        try {
            const success = yield call(API.Activities.EditActivity, activityID, finalForm)

            if (success) {
                yield putResolve(actions.TripActivityForm.editPhotoArray({ photos: payload.photos, activity: activityID }))
                yield putResolve(actions.App.navigateTo('/viagens/configuracoes'))
                toast.success(i18n.t("toasts.editActivitySuccess"))
            }
        } catch (error) {
            toast.warning(i18n.t("toasts.editActivityError"))
        }
    }
}