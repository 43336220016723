/**
 * Passengercontrol scene utils
 *
 * @author ManuelGil <mgil@ubiwhere.com>
 *
 *
 */
import { NewPlanningForm } from 'types/trips/plannings'
import * as Check from 'validations';

export const NewPlanningValidation = {
  vehicle: [Check.isRequired],
  trip: [Check.isRequired]
}

export const mock = [
    {
        activity: {
            id: 1,
            name: "shuttle asdsa",
            shortName: "RAP"
        },
        id: 0,
        number: "xxx",
        name: "Manelel",
        phone: "9999999",
        PAX: 1,
        isPayementComplete: true,
        passengerTypes: [
            {
                passengerType: {
                    id: 7,
                    name: "Adulto",
                    shortName: "ADT"
                },
                tickets: 1
            }
        ],
        checkIn: {
            stop: {
                id: 1,
                name: 'Faro',
                ShortName: "FAR"
            },
            isCheckedIn: true
        },
        checkOut: {
            stop: {
                id: 2,
                name: 'Deserta',
                ShortName: "DES"
            },
            isCheckedOut: false
        },
    }, {
        activity: {
            id: 1,
            name: "shuttle asdsa",
            shortName: "RAP"
        },
        id: 2,
        number: "xxx1",
        name: "Joao",
        phone: "9999999",
        PAX: 2,
        isPayementComplete: true,
        passengerTypes: [
            {
                passengerType: {
                    id: 7,
                    name: "Adulto",
                    shortName: "ADT"
                },
                tickets: 2
            }
        ],
        checkIn: {
            stop: {
                id: 1,
                name: 'Faro',
                ShortName: "FAR"
            },
            isCheckedIn: false
        },
        checkOut: {
            stop: {
                id: 2,
                name: 'Deserta',
                ShortName: "DES"
            },
            isCheckedOut: false
        },
    }, {
        activity: {
            id: 1,
            name: "shuttle asdsa",
            shortName: "RAP"
        },
        id: 3,
        number: "xxx2",
        name: "Jooaooo",
        phone: "9999999",
        PAX: 3,
        isPayementComplete: false,
        passengerTypes: [
            {
                passengerType: {
                    id: 7,
                    name: "Adulto",
                    shortName: "ADT"
                },
                tickets: 2
            },
            {
                passengerType: {
                    id: 8,
                    name: "Criança",
                    shortName: "CRI"
                },
                tickets: 1
            }
        ],
        checkIn: {
            stop: {
                id: 1,
                name: 'Faro',
                ShortName: "FAR"
            },
            isCheckedIn: true
        },
        checkOut: {
            stop: {
                id: 2,
                name: 'Deserta',
                ShortName: "DES"
            },
            isCheckedOut: false
        },
    },
    {
        activity: {
            id: 1,
            name: "shuttle asdsa",
            shortName: "RAP"
        },
        id: 4,
        number: "xxx3",
        name: "outraa",
        phone: "9999999",
        PAX: 2,
        isPayementComplete: true,
        passengerTypes: [
            {
                passengerType: {
                    id: 7,
                    name: "Adulto",
                    shortName: "ADT"
                },
                tickets: 1
            },
            {
                passengerType: {
                    id: 8,
                    name: "Criança",
                    shortName: "CRI"
                },
                tickets: 1
            }
        ],
        checkIn: {
            stop: {
                id: 1,
                name: 'Faro',
                ShortName: "FAR"
            },
            isCheckedIn: false
        },
        checkOut: {
            isCheckedOut: false
        },
    }
]

export const initialTripForm: NewPlanningForm = {
    trip: {
        value: "",
        valid: true,
        message: ""
    },
    vehicle: {
        value: "",
        valid: true,
        message: ""
    },
}

export type SetNewCheckinSagaType = {
    outbound: boolean,
    location: number
}

export type SetNewCheckLocationSagaType = {
    id: number,
    location: number,
    checkinId: number,
    checkoutId: number,
    outbound: boolean
}