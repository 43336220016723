import styled from 'styled-components'
import { CTableRow, CTableDataCell } from '@coreui/react-pro';
import theme from 'ui/theme';

export const TextCounter = styled.div`
  font-family: 'Inter';
  font-size: 11px;
  color:${theme().darkColor};
  letter-spacing: 0.1px;
  display:flex;
  align-items:center;
  
  b {
    font-size: 14px;
  }
`

export const BoardingLabel = styled.div`
  margin-right: 20px;
  background-color: ${theme().success};
  color:${theme().white};
  border-radius: 4px;
  font-size: 11px;
  padding: 0px 5px;
`

export const TableRow = styled(CTableRow) <{ activitycolor: string }>`
  background-color:#FFFFFF80;
  td:first-child {
    padding:0;
    border-left: 4px solid ${props => props.activitycolor};
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`
export const Icon = styled.div <{ marginr: string }>`
  display: flex;
  flex-direction: column;
  margin-right: ${props => props.marginr}px;
`

export const Info = styled(CTableDataCell)`
  display:flex;
  flex-direction: column;
`

export const Summary = styled.div`
  background-color: ${theme().white};
  padding-block:16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 1250px) {
    padding-block: 26px;
  }
`

export const Details = styled.div <{ noinbounds: boolean }>`
  background-color: #FFFFFF00;
  padding:${props => props.noinbounds ? '0px 16px' : '16px'};
  display:flex;
  justify-content: space-between;
  flex-direction: row;
  
  @media (max-width: 1200px) {
    flex-wrap: wrap-reverse;
  }

`

export const Stops = styled.div`
  background-color: #FFFFFF00;
  padding-top:16px;
  padding-bottom:16px;
  display:flex;
  width:75%;
  flex-direction: column;
  justify-content: center;
  
  @media (max-width: 1200px) {
    width: 100%;
  }
`

export const Text = styled.p`
  margin:0px;
`
