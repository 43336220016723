import API from 'api'
import { putResolve, call, select } from 'redux-saga/effects'
import { actions, RootState } from 'store/rootSlices'
import { toast } from 'react-toastify'
import i18n from 'i18next';
import { PayloadAction } from '@reduxjs/toolkit';
import { SetNewCheckLocationProps, SetNewCheckinProps } from 'types/trips/plannings';

export function* setNewCheckLocationSaga({ payload }: PayloadAction<SetNewCheckLocationProps>) {
  yield putResolve(actions.TripPlannings.setCheckingIn(true))
  const checkId = payload.id;
  if(!checkId) {
    yield putResolve(actions.TripPlannings.setCheckingIn(false))
    return toast.error(i18n.t('toasts.noCheckinError'))
  }
  if(payload.location === payload.checkinId) {
    yield putResolve(actions.TripPlannings.setCheckingIn(false))
    return toast.error(i18n.t('toasts.checkinNeededError')) 
  }
  
  let loadingToast
  let edited = 'none'
  try {
    const finalForm = {
      isOutbound: payload.outbound
    };

    if(payload.checkoutId) {
      finalForm['checkin'] = payload.location
      finalForm['checkout'] = null
      edited = 'checkin'
    } else if( payload.checkinId) {
      finalForm['checkout'] = payload.location
      edited = 'checkout'
    }
    
    loadingToast = toast.loading(i18n.t('toasts.editingCheckLocation'));
    
    const success = yield call(API.Checkins.EditCheckin, checkId, finalForm);
    if (success) {
      const {
        showCheckinModal
      } = yield select((state: RootState) => state.TripPlannings);

      yield putResolve(actions.TripPlannings.setCheckinModalUnit(showCheckinModal))
      toast.update(loadingToast, {render: i18n.t('toasts.editCheckLocationSuccess', {check: edited }), type: "success", isLoading: false, closeButton: true, autoClose: 5000});
      yield putResolve(actions.TripPlannings.setCheckingIn(false))
    }
  } catch (error) {
    if (loadingToast !== undefined) {
      toast.update(loadingToast, {render: i18n.t('toasts.editCheckLocationError'), type: "error", isLoading: false, closeButton: true, autoClose: 5000});
    } else {
      toast.error(i18n.t('toasts.editCheckLocationError'));
    }
    yield putResolve(actions.TripPlannings.setCheckingIn(false))
  }
}

export function* setNewCheckinSaga({ payload }: PayloadAction<SetNewCheckinProps>) {  
  yield putResolve(actions.TripPlannings.setCheckingIn(true))
  let loadingToast
  
  try {
    const finalForm = {
      isOutbound: payload.outbound,
      checkin: payload.location,
      booking: payload.booking,
      vehicle: payload.vehicle,
      trip: payload.trip
    };
    
    loadingToast = toast.loading(i18n.t(`toasts.${payload.isElegible ? "creatingElegibleCheckin" : "creatingCheckin"}`))
    
    const success = yield call(API.Checkins.CreateCheckin, finalForm);
    if (success) {
      const {
        showCheckinModal
      } = yield select((state: RootState) => state.TripPlannings);

      yield putResolve(actions.TripPlannings.setCheckinModalUnit(showCheckinModal))

      toast.update(loadingToast, {render: i18n.t(`toasts.${payload.isElegible ? "createElegibleCheckinSuccess" : "createCheckinSuccess"}`), type: "success", isLoading: false, closeButton: true, autoClose: 5000});
    }
  } catch (error) {
    if (loadingToast !== undefined) {
      toast.update(loadingToast, {render: i18n.t(`toasts.${payload.isElegible ? "createElegibleCheckinError" : "createCheckinError"}`), type: "error", isLoading: false, closeButton: true, autoClose: 5000});
    } else {
      toast.error(i18n.t(`toasts.${payload.isElegible ? "createElegibleCheckinError" : "createCheckinError"}`)) 
    }
    yield putResolve(actions.TripPlannings.setCheckingIn(false))
  }
}