import styled from 'styled-components'
import { CustomTableRow } from 'shared/genericStyledComponents'
import theme from 'ui/theme';

const CustomCTableRow = styled(CustomTableRow)`
  max-height: 54px;
  background-color: ${theme().white};
  border-bottom: 1px solid ${theme().gray200Color};
 
  td:first-child {
    padding-left:15px;
    width:20%;
  }

  > td {
    padding:15px 8px;
    text-transform: capitalize;
  }
`

export {
  CustomCTableRow
}