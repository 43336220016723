/**
 * Dropdown component
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */

import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { CFormLabel, CFormInput, CInputGroup, CDropdown, CDropdownToggle, CDropdownMenu, CDropdownItem, CFormSelect } from '@coreui/react';
import theme from 'ui/theme';
import { CFormText } from '@coreui/react-pro';

interface PropTypes {
    id?: string,
    wrapperStyle?: React.CSSProperties,
    required?: boolean,
    dropdown?: boolean,
    options: Array<{
        label: string,
        value: any,
        prefix?: string,
    }>,
    label?: string,
    value?: string,
    onChange: Function,
    placeholder?: string,
    valid?: boolean
    helpText?: string,
    disabled?: boolean,
    defaultOption?: {
        label: string,
        value: any,
        prefix?: string,
    },
}

const FormComponent = (props: PropTypes) => {

    //state for the default dropdown
    const [currentDropdownOption, setCurrentDropdownOption] = useState(props.defaultOption ? { label: props.defaultOption.label, value: props.defaultOption.value } : { label: props.options[0]?.value, value: props.options[0]?.value })

    //states for menu dropdown
    const [currentOption, setCurrentOption] = useState(props.value ? { value: "País", prefix: "" } : { value: props.options[0]?.value, prefix: props.options[0]?.prefix })
    const [inputValue, setInputValue] = useState(props.value ? { value: props.value } : { value: "(" + props.options[0]?.prefix + ") " })
    const inputRef = useRef<HTMLInputElement>(null)

    //to check if there's already a prefix on the menu dropdown value
    useEffect(() => {

        if (!props.dropdown) {
            let foundPrefixOnInput = "";

            if (props.value) {
                setInputValue({ value: props.value })
            }
            props.options.forEach(option => {
                if (props.value && option.prefix && props.value.includes(option.prefix)) {
                    if (option.prefix.length > foundPrefixOnInput.length) {
                        foundPrefixOnInput = option.prefix
                        setCurrentOption({ value: option.value, prefix: option.prefix })
                        setInputValue({ value: "(" + option.prefix + ") " + props.value.split(option.prefix)[1] })
                    }
                }
            });
        }
    }, [props.value, props.dropdown, props.options])

    // handlers for the menu dropdown
    const handleOptionChange = (opt) => {
        let regExp = /\(([^)]+)\)/;
        let foundPrefixOnInput = false;

        setCurrentOption({ value: opt.value, prefix: opt.prefix })

        props.options.every(option => {
            let prefix = inputRef.current?.value && regExp.exec(inputRef.current?.value)

            if (prefix && prefix[1] === option.prefix) {
                foundPrefixOnInput = true
                let replacing = inputValue.value.replace(option.prefix, opt.prefix)
                setInputValue({ value: replacing })
                return false;
            }
            else {
                if (!foundPrefixOnInput) {
                    let prefix = inputRef.current?.value && regExp.exec(inputRef.current?.value)

                    if (!prefix) {
                        setInputValue({ value: "(" + opt.prefix + ") " + inputRef.current?.value })
                    }
                    else {
                        if (inputRef.current) {
                            setInputValue({ value: inputRef.current.value.replace(prefix[1], opt.prefix) })
                        }
                    }
                }
                return true;
            }
        });
    }

    const handleInputChange = e => {
        let regExp = /\(([^)]+)\)/;
        let foundPrefix = false

        if (e.target.value === "") {
            setInputValue({ ...inputValue, value: e.target.value })
        }

        props.options.every(option => {
            let prefix = regExp.exec(e.target.value)

            if (prefix && prefix[1] === option.prefix) {
                foundPrefix = true
                setCurrentOption({ value: option.value, prefix: option.prefix })
                setInputValue({ ...inputValue, value: e.target.value })
                return false;
            }
            else {
                if (!foundPrefix) {
                    setCurrentOption({ value: 'País', prefix: "" })
                }
                setInputValue({ ...inputValue, value: e.target.value })
                return true;
            }
        });

        if (props.onChange !== null) {
            const regex = /[()\s]/g
            props.onChange(e.target.value.replace(regex, ""))
        }
    }

    return (
        <Wrapper style={props.wrapperStyle}>
            {props.label && <CFormLabel>{props.label}<span>{!!props.required ? "*" : ""}</span></CFormLabel>}
            <CInputGroup>
                {
                    props.dropdown && (<>
                        <FormSelect isvalid={props.valid} required onChange={e => props.onChange ? props.onChange(e) : null} disabled={props.disabled === true ? true : false}>
                            {
                                props.options != null && props.options.map((opt, idx) => {
                                    if (currentDropdownOption.label !== opt.label) {
                                        return <option key={idx} onClick={() => {
                                            if (props.onChange) props.onChange({ target: { label: opt.label, value: opt.value } });
                                            setCurrentDropdownOption({ label: opt.label, value: opt.value });
                                        }}>
                                            {opt.label}
                                        </option>
                                    } else {
                                        return <option key={idx} > {currentDropdownOption.label !== "" ? currentDropdownOption.label : props.placeholder} </option>
                                    }
                                })
                            }
                        </FormSelect> </>)
                }
                {
                    !props.dropdown && (
                        <>
                            <CDropdown variant="input-group">
                                <DropdownToggle id={`${props.id}-currentOption`} color="secondary" variant="outline">{currentOption.value}</DropdownToggle>
                                <CDropdownMenu style={{ overflow: 'auto', maxHeight: '100vh', top: '35px' }} >
                                    {
                                        props.options != null && props.options.map((opt, idx) => {

                                            return <DropdownItem id={`${props.id}-option${idx}`} key={idx} onClick={(e) => handleOptionChange(opt)}>
                                                {opt.value}
                                            </DropdownItem>
                                        })
                                    }
                                </CDropdownMenu>
                            </CDropdown>
                            <FormInput
                                id={props.id}
                                isvalid={props.valid}
                                ref={inputRef}
                                disabled={props.disabled === true ? true : false}
                                required style={{ fontSize: 14 }}
                                value={inputValue.value}
                                onChange={e => handleInputChange(e)} />
                        </>)
                }

            </CInputGroup>
            {props.helpText && <HelpText valid={props.valid}>{props.helpText}</HelpText>}
        </Wrapper>
    )
}

export default FormComponent

const Wrapper = styled.div`
  > * {
    font-family: 'Inter';
  }

  > label {
    font-size: 14px;
    color: ${theme().darkColor};
    > span {
      color: ${theme().primary};
    }
  }
`

const DropdownToggle = styled(CDropdownToggle)`
  background-color:${theme().gray600Color};
  color:${theme().white};
  font-size: 14px;
`

const HelpText = styled(CFormText) <{ valid: boolean | undefined }>`
  color: ${(props) => props.valid === false ? theme().danger : '#D8DBE0'};
`

const FormSelect = styled(CFormSelect) <{ isvalid: boolean | undefined }>`
    width:'100%';
    border-color:${(props) => props.isvalid === false ? theme().danger : '#D8DBE0'};
    color:${theme().gray600Color};
    font-size:14px;
    background-image: url("data:image/svg+xml,%3Csvg width='8' height='13' viewBox='0 0 8 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.99994 2.38667L6.11328 4.5L7.05328 3.56L3.99994 0.5L0.939941 3.56L1.88661 4.5L3.99994 2.38667ZM3.99994 10.6133L1.88661 8.5L0.946608 9.44L3.99994 12.5L7.05994 9.44L6.11328 8.5L3.99994 10.6133Z' fill='%23636F83'/%3E%3C/svg%3E");
    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: ${theme().gray500Color};
        opacity: 1; /* Firefox */
    }
`
const DropdownItem = styled(CDropdownItem)`
    font-size:14px;
    &:active{
        background-color:${theme().gray600Color};
    }
`
const FormInput = styled(CFormInput) <{ isvalid: boolean | undefined }>`
    font-size:14px;
    border-color:${(props) => props.isvalid === false ? theme().danger : '#D8DBE0'};
    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: ${theme().gray500Color};
        opacity: 1; /* Firefox */
    }
`
