import theme from 'ui/theme'
import styled from 'styled-components'
import { CTableDataCell, CTableRow } from '@coreui/react-pro'


const CustomCTableRow = styled(CTableRow)`
    max-height: 54px;
    background-color: ${theme().white};
    border-bottom: 1px solid ${theme().gray200Color};

    > td {
        vertical-align: middle;
        font-family: Inter;
        font-size: 14px;
        color: ${theme().gray700Color};
    }

    td:first-child {
        padding-left:15px;
    }

    td:nth-child(5) {
        padding-left: 7px;
    }
`

const BookingStateLabel = styled.span`
    padding:2px 4px;
    color:${theme().white};
    font-weight:700;
    border-radius:5px;

    &.to_refund {
        background-color: ${theme().warning};
    }
    &.to_pay {
        background-color: ${theme().warning};
    }
    &.canceled {
        background-color: ${theme().darkColor};
    }
    &.pending {
        background-color: ${theme().warning};
    }
    &.paid {
        background-color: ${theme().success};
    }
    &.refunded {
        background-color: ${theme().danger};
    }
`

const ClickableTableDataCell = styled(CTableDataCell)`
  cursor: pointer
`

const BookingNumber = styled.div`
  display:flex;
  justify-content: flex-start;
  column-gap: 8px;
`
const BookingInfo = styled.div`
  display:flex;
  justify-content: flex-start;
  column-gap: 8px;
`
const ServiceInfo = styled.div`
  display:flex;
  justify-content: space-between;
  column-gap: 8px;
`
const Tooltip = styled.div`
  position: relative;
  visibility: visible;
  color: #fff;

  &:hover {
    > span {
      visibility: visible
    }
  }
`
const CustomSpan = styled.span`
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 105%;
  left: -20px;

`

export {
  CustomCTableRow,
  BookingStateLabel,
  ClickableTableDataCell,
  BookingInfo,
  BookingNumber,
  ServiceInfo,
  Tooltip,
  CustomSpan
}