/**
 * BookingForm scene utils
 *
 * @author João Flores <jflores@ubiwhere.com>
 *
 *
 */

import * as Check from "validations";
import { TripActivityLeg, BookingLeg } from 'types/trips/bookings';

export const BookingCustomerValidations = {
    name: [Check.isRequired],
    phone: [Check.hasValue],
    observations: [Check.hasValue],
    lang: [Check.hasValue],
    nif: [Check.hasValue],
    email: [Check.isRequired],
    country: [],
}

export const TripInfoValidations = {
    customerName: [Check.isRequired],
    phone: [Check.hasValue],
    observations: [Check.hasValue],
    lang: [Check.hasValue],
    nif: [Check.hasValue],
    email: [],
    country: [],
}

export const BookingPassengerTypeValidations = {
    quantity: [Check.isInteger],
}

export const BookingPaymentValidation = {
    amount: [Check.isNumber],
    processedBy: [Check.isRequired],
    source: [Check.isRequired],
    type: [Check.isRequired],
}

export const BookingInvoiceValidation = {
    nif: [Check.isRequired],
}

export const PartnerValidation = {
    partner: [],
}


export const convertTimeStringToSeconds = val => {
    let time = val.split(':');

    return (Number(time[0]) * 3600) + (Number(time[1]) * 60);
};

export const defaultCustomerForm = {
    uuid: "",
    name: {
        value: "",
        valid: true,
        message: "",
    },
    observations: {
        value: "",
        valid: true,
        message: "",
    },
    phone: {
        value: "",
        valid: true,
        message: "",
    },
    email: {
        value: "",
        valid: true,
        message: "",
    },
    lang: {
        value: "",
        valid: true,
        message: ""
    },
    country: {
        value: "",
        valid: true,
        message: ""
    },
    nif: {
        value: "",
        valid: true,
        message: "",
    },
}

export const defaultOutboundLeg: TripActivityLeg = {
    id: -1,
    date: '',
    time: '',
    price: 0,
    availableTickets: 0,
    minimumPrice: "",
    includedSeats: "",
    maxCapacity: 0,
    allowOverbooking: false,
    departure: {
      id: 0,
      stop: 0,
      name: '',
      shortName: '',
      time: '',
      isOutbound: true,
    },
    arrival: {
      id: 0,
      stop: 0,
      name: '',
      shortName: '',
      time: '',
      isOutbound: true,
    },
    activity: {
      id: 0,
      name: '',
      short_name: '',
      duration: '',
      isHopOnHopOff: false,
      isPrivate: false,
      passengerTypes: [],
    },
}

export const defaultInboundLeg: TripActivityLeg = { 
    ...defaultOutboundLeg,
    departure: {
        ...defaultOutboundLeg.departure,
        isOutbound: false,
    },
    arrival: {
        ...defaultOutboundLeg.arrival,
        isOutbound: false,
    },
}

export const defaultOutbound: BookingLeg = {
    id: -1,
    checkedIn: false,
    isCanceled: false,
    wasRescheduled: false,
    isOutbound: true,
    tripActivityLeg: defaultOutboundLeg,
    totalPrice: 0,
}

export const defaultInbound: BookingLeg = {
    id: -1,
    checkedIn: false,
    isCanceled: false,
    wasRescheduled: false,
    isOutbound: false,
    tripActivityLeg: defaultInboundLeg,
    totalPrice: 0,
}

export const defaultBeach: BookingLeg = {
    ...defaultOutbound,
    tripActivityLeg: defaultInboundLeg,
    beachTripActivity: defaultOutboundLeg
}