/**
 * Header component
 *
 * @author João Dias <jdias@ubiwhere.com>
 *
 *
 */

import React, { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { actions, RootState } from 'store/rootSlices';
import { CDropdown, CDropdownDivider, CDropdownToggle,  CNavItem, CNavLink } from '@coreui/react-pro'
import Hamburguer from 'assets/icons/hamburguer'
import config from 'config';
import { hasPermission } from 'permissions';
// Styles
import * as Styles from './styles'

const Header = (props) => {

  const {
    setUnfoldability
  } = actions.Dashboard

  const {
    isSidebarOpen
  } = useSelector((state: RootState) => state.Dashboard)

  const {
    userInfo,
    user,
    permissions
  } = useSelector((state: RootState) => state.App)

  const {
    logout
  } = actions.Login

  const {
    PERMISSIONS
  } = config

  const dropdownRef = useRef<HTMLDivElement>(null)
  const dispatch = useDispatch()

  //TODO add to slice later
  const [read, setRead] = useState(true);
  const [hasMail, setHasMail] = useState(false);


  return (
    <Styles.Wrapper>
      <Styles.CustomHeader position="sticky">
        <Styles.HamburguerWrapper
          onClick={() => {
            dispatch(setUnfoldability(!isSidebarOpen))
          }}
        >
          <Hamburguer />
        </Styles.HamburguerWrapper>
        <Styles.HeaderNav>

          <CNavItem>
            <CNavLink >
              {read ? <Styles.Notification
                fill='#E55353'
                id="header-notif-bell"
                onClick={e => setRead(!read)} /> : <Styles.Notification fill='gray' onClick={e => setRead(!read)} />}
            </CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink >
              {hasMail ? <Styles.Mails
                fill="#E55353"
                id="header-notif-mail"
                onClick={e => setHasMail(!hasMail)} /> : <Styles.Mails fill="gray" onClick={e => setHasMail(!hasMail)} />}
            </CNavLink>
          </CNavItem>

          {/* TODO: REPLACE REMAINING LOGIC */}
          <Styles.ProfileInfoContainer id='header-profile-wrapper' onClick={e => { dropdownRef.current?.click() }}>
            <CDropdown alignment={{ xl: 'start' }} variant="nav-item" popper={false}>
              <CDropdownToggle
                id="header-profile-toggle"
                caret={false}>
                <Styles.CustomAvatar
                  id='header-profile-avatar'
                  color='secondary'
                  ref={dropdownRef}
                  src={userInfo.avatar}>
                  foto
                </Styles.CustomAvatar>
              </CDropdownToggle>
              <Styles.DropdownMenu>
                {hasPermission(permissions, PERMISSIONS.USERS.EDIT_TEAM_MEMBER) && <Styles.DropdownItem
                  id="header-edit-link"
                  onClick={() => {
                    dispatch(actions.App.navigateTo(`/colaboradores/utilizadores/editar-utilizador/${user.uuid}`))
                  }}>
                  Editar Perfil
                </Styles.DropdownItem>}
                <Styles.DropdownItem
                  id="header-message-link"
                  href="#">
                  Mensagens
                </Styles.DropdownItem>
                <CDropdownDivider />
                <Styles.DropdownItem
                  id="header-logout-link"
                  onClick={e => dispatch(logout())}>
                  Logout
                </Styles.DropdownItem>
              </Styles.DropdownMenu>
            </CDropdown>
            <Styles.UserInformation id='header-profile-info'>
              <p>{userInfo.name}</p>
              <p>{userInfo.profile}</p>
            </Styles.UserInformation>
          </Styles.ProfileInfoContainer>
        </Styles.HeaderNav>
      </Styles.CustomHeader>
    </Styles.Wrapper>
  )
}

export default Header