import styled from 'styled-components'
import { CRow, CCol, CButton } from '@coreui/react-pro'
import theme from 'ui/theme'

export const Wrapper = styled.div``

export const Row = styled(CRow)`
  --cui-gutter-y:0rem;
  margin-top:26px;
`

export const ButtonWrapper = styled.div<{ closed: boolean | undefined }>`
  width: 100%;
  display:flex;
  justify-content:${({ closed }) => closed ? 'space-between' : 'flex-end'};
  padding-top:22px;
`

export const Button = styled(CButton) <{ icon: boolean }>`
  background-color:${props => props.icon ? theme().white : theme().primary};
  border-color: ${props => props.icon ? theme().danger : theme().primary};
  color:${props => props.icon ? theme().danger : theme().white};
  width:${props => props.icon ? '75%' : '100%'};
  line-height:1.3;
  align-self:center;
  box-shadow: 0 0 0 0;
  &:hover{
    color:${props => props.icon ? theme().white : theme().primary};
    background-color: ${props => props.icon ? theme().danger : theme().white};
    border-color:${props => props.icon ? theme().danger : theme().primary};
  }
  &:focus{
    box-shadow: 0 0 0 0;
    color:${props => props.icon ? theme().white : theme().white};
    background-color: ${props => props.icon ? theme().danger : theme().primary};
    border-color:${props => props.icon ? theme().white : theme().primary};
  }
`

export const ColButton = styled(CCol)`
  display:flex;
  flex-direction: column;
  justify-content: flex-end;
`