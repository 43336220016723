/**
 * Userslisttablerow component
 *
 * @author João Dias <jdias@ubiwhere.com>
 *
 *
 */

import React from 'react'
import { CTableDataCell, CAvatar } from '@coreui/react-pro';
import { useDispatch } from 'react-redux';
import i18n from 'i18next';
import { DateTime } from 'luxon'
// Icons
import ChevronRight from 'assets/icons/chevron_right';
import User from 'assets/icons/user';
import Boat from 'assets/icons/boat_icon'
// Store
import { actions } from 'store/rootSlices'
// Types
import { CollaboratorListItem } from 'types/collaborators/collaborator';
// Styles
import { Tooltip, TooltipWrapper, CustomSpan, CustomNameAndRoleCell } from './styles'
import { CustomTableRow, ActionsCell, ChevronCell, ImageCell } from 'shared/genericStyledComponents'


type PropTypes = {
  id?: string,
  user: CollaboratorListItem,
  actions: JSX.Element,
  onClick: Function,
  permission: boolean
}

const UsersListTableRow = (props: PropTypes) => {

  const dispatch = useDispatch()
  const {
    permission,
    user: {
      name,
      email,
      role,
      phone,
      professionalCategory,
      avatar,
      uuid,
    }
  } = props;

  return (
    <CustomTableRow id={props.id}>
      <ImageCell>
        <div onClick={() => props.onClick()}>
          {avatar ?
            <CAvatar color='secondary' src={avatar} status={props.user.lastClocked && props.user.lastClocked.hasFinished === false ? `success` : `danger`} ></CAvatar>
            :
            <CAvatar color='secondary' status={props.user.lastClocked && props.user.lastClocked.hasFinished === false ? `success` : `danger`} ><User /></CAvatar>
          }
        </div>
      </ImageCell>
      <CustomNameAndRoleCell>
        <div>
          <div>
            <h5 onClick={() => props.onClick()}>{name}</h5>
            <h6>{professionalCategory}</h6>
          </div>
          <TooltipWrapper>
            {
              props.user.currentAssignment !== null &&
              <Tooltip>
                <Boat width={20} height={20} fill={props.user.currentAssignment.vehicle.color} />
                <CustomSpan>
                  <p>
                    {
                      i18n.t('users.serviceTooltip', {
                        date: DateTime.fromISO(props.user.currentAssignment.startedAt).toFormat("HH:mm 'of' dd LLL"),
                        vehicle: props.user.currentAssignment.vehicle.name
                      })
                    }
                  </p>
                </CustomSpan>
              </Tooltip>
            }
          </TooltipWrapper>
        </div>
      </CustomNameAndRoleCell>
      <CTableDataCell>{role.name}</CTableDataCell>
      <CTableDataCell>{phone}</CTableDataCell>
      <CTableDataCell>{email}</CTableDataCell>
      <ActionsCell nopointer>
        {props.actions}
        <ChevronCell id={`${props.id}-chevron`}>
          {
            permission &&
            <a href={`/colaboradores/utilizadores/editar-utilizador/${uuid}`} onClick={(e) => {
              e.preventDefault()
              dispatch(actions.App.navigateTo(`/colaboradores/utilizadores/editar-utilizador/${uuid}`))
            }}>
              <ChevronRight></ChevronRight>
            </a>
          }
        </ChevronCell>
      </ActionsCell>
    </CustomTableRow>
  )
}

export default UsersListTableRow