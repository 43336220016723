import * as React from "react";
import theme from "ui/theme";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={props.style}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.80485 2.66663H2.66683C1.9335 2.66663 1.34016 3.25996 1.34016 3.99996V6.66663C2.0735 6.66663 2.66683 7.26663 2.66683 7.99996C2.66683 8.73329 2.0735 9.33329 1.3335 9.33329V12C1.3335 12.7333 1.9335 13.3333 2.66683 13.3333H13.3335C14.0668 13.3333 14.6668 12.7333 14.6668 12V9.33329C13.9335 9.33329 13.3335 8.73329 13.3335 7.99996C13.3335 7.68321 13.4454 7.39133 13.6317 7.16194C13.2153 7.27959 12.7676 7.33329 12.3335 7.33329C12.2509 7.33329 12.1689 7.33056 12.0876 7.32518C12.0306 7.54111 12.0002 7.76734 12.0002 7.99996C12.0002 8.97996 12.5402 9.84663 13.3335 10.3066V12H2.66683V10.3066C3.46016 9.84663 4.00016 8.97996 4.00016 7.99996C4.00016 7.01329 3.46683 6.15329 2.6735 5.69329L2.66683 3.99996H8.68178C8.67188 3.89017 8.66683 3.77899 8.66683 3.66663C8.66683 3.31995 8.71494 2.98451 8.80485 2.66663ZM8.66683 9.99996H7.3335V11.3333H8.66683V9.99996ZM8.66683 7.33329H7.3335V8.66663H8.66683V7.33329ZM8.66683 4.66663H7.3335V5.99996H8.66683V4.66663Z"
                fill={props.fill ? props.fill : theme().danger}
            />
            <g clipPath="url(#clip0_6590_26296)">
                <path 
                    d="M14.0448 2.30011L13.7001 1.95544L12.3337 3.32189L10.9672 1.95544L10.6226 2.30011L11.989 3.66656L10.6226 5.033L10.9672 5.37767L12.3337 4.01122L13.7001 5.37767L14.0448 5.033L12.6783 3.66656L14.0448 2.30011Z" 
                    fill={props.fill ? props.fill : theme().danger}
                    stroke={props.stroke ? props.stroke : theme().danger}
                />
            </g>
            <defs>
                <clipPath id="clip0_6590_26296">
                    <rect width="5.86667" height="5.86667" fill="white" transform="translate(9.40039 0.733276)"/>
                </clipPath>
            </defs>
        </svg>
    )
}

export default SvgComponent;
