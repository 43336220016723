import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { actions, RootState } from 'store/rootSlices'
import { DateTime } from "luxon"
import theme from 'ui/theme'
//configs
import config from 'config';
//styles
import { LegRow, IconTableCell, CustomLink } from '../../styles'
import { ExtrasCounterWrapper } from './styles'
//icons
import ChevronRight from 'assets/icons/chevron_right';
//Components
import { CTableDataCell } from '@coreui/react-pro'
import Loadingcontainer from 'shared/components/Loadingcontainer'
import CustomRadioButton from 'shared/components/CustomRadioButton';
import Beach from 'assets/icons/beach'

const { DATE_FORMAT } = config;


const BeachRescheduleTableContent = () => {
    const {
        beachTrips,
        selectedBeachTrip,
        tableLoading
        /* hasEditPermission, */
    } = useSelector((state: RootState) => state.BookingForm);

    const { setRescheduleBeachTrip } = actions.BookingForm;

    const dispatch = useDispatch();

    /* const calculatePriceDiff = (legInboundView, leg) => {
        let priceDiff = 0;
        if (legInboundView) {
            priceDiff = (bookedInbound.tripActivityLeg.price - leg.price) * -1;
        }
        else {
            priceDiff = (bookedOutbound.tripActivityLeg.price - leg.price) * -1;
        }

        let cls;
        if (priceDiff > 0) {
            cls = "more";
        }
        else if (priceDiff < 0) {
            cls = "less";
        }

        if (legInboundView) {
            if (inboundToCancel || bookedInbound.isCanceled || bookedInbound.wasCanceled) {
                return <b>{leg.price}€</b>
            }
        }
        else {
            if (outboundToCancel || bookedOutbound.isCanceled || bookedOutbound.wasCanceled) {
                return <b>{leg.price}€</b>
            }
        }

        return (
            <><b className={cls}>{priceDiff}€</b>({leg.price}€)</>
        )
    }; */

    if (tableLoading) {
        return <CTableDataCell colSpan={8}><Loadingcontainer /></CTableDataCell>
    }

    return <>
        {
            beachTrips.map((trip, idx) => (
                <LegRow
                    key={idx}
                    id={`newBooking-beachTripRow-${trip.id}`}
                    className={trip.id === selectedBeachTrip?.beachTripActivity?.id ? 'selected' : ''}
                    onClick={e => { dispatch(setRescheduleBeachTrip(trip)) }}
                >
                    <CTableDataCell style={{ "padding": "1em" }}>
                        <CustomRadioButton
                            group="legs"
                            value={trip.id}
                            checked={trip.id === selectedBeachTrip?.beachTripActivity?.id}
                        />
                    </CTableDataCell>
                    <IconTableCell>
                        {
                            <Beach fill={theme().gray500Color} />
                        }
                    </IconTableCell>
                    <CTableDataCell>{trip.activity.name}</CTableDataCell>
                    <CTableDataCell>{DateTime.fromISO(trip.date).toFormat(DATE_FORMAT.DATE)}</CTableDataCell>
                    <CTableDataCell onClick={(e) => e.stopPropagation()}>
                        <CustomLink
                            to={`/viagens/agendamentos/editar-agendamento/${trip?.trip}`}
                            target="_blank"
                            rel="noopener"
                        >
                            {
                                trip.departure.name
                            }
                            <ChevronRight className='pointer' fill={theme().gray700Color} />
                        </CustomLink>
                    </CTableDataCell>
                    <CTableDataCell>

                        {
                            trip.availableExtras?.map(extra => {
                                return (
                                    <ExtrasCounterWrapper>
                                        {extra.icon ? <img src={extra.icon} style={{ width: '12px' }} alt={`extraIcon${extra.name}`} /> : null} <b>{extra.maxCapacity - (extra.occupied ?? 0)}</b>/<span className="smaller">{extra.maxCapacity}</span>
                                    </ExtrasCounterWrapper>
                                )
                            })
                        }
                    </CTableDataCell>
                </LegRow>
            ))
        }
    </>
};

export default BeachRescheduleTableContent

/*                     className={(() => {
                        if (legInboundView) {
                            if (inbound.tripActivityLeg.id === leg.id && !inboundToCancel) {
                                return 'selected';
                            }

                            if (bookedInbound.tripActivityLeg.id === leg.id) {
                                return 'booked';
                            }
                        }
                        else {
                            if (outbound.tripActivityLeg.id === leg.id && !outboundToCancel) {
                                return 'selected';
                            }

                            if (bookedOutbound.tripActivityLeg.id === leg.id) {
                                return 'booked';
                            }
                        }

                        return '';
                    })()}
                    onClick={e => { legInboundView ? dispatch(setRescheduleInbound(leg)) : dispatch(setRescheduleOutbound(leg)) }} */

/* <CustomRadioButton
        disabled={!hasEditPermission}
        group="legs"
        value={leg.id}
        checked={legInboundView ? leg.id === inbound.tripActivityLeg.id && !inboundToCancel : leg.id === outbound.tripActivityLeg.id && !outboundToCancel}
    /> */

/* <PriceCell>{calculatePriceDiff(legInboundView, leg)}</PriceCell> */