import type { FormField, ListResponse, ItemType } from "types/misc"
import type { ActivityExtrasForm, ActivityPassengerTypesForm } from "./activity/activityForm"
import type { PassengerType } from "./passengerTypes";
export interface InOutBoundStops {
    stop: Stop;
    time: string;
    bookedSeats: number;
    passengersPassingThrough: number;
    passengersCheckingOut: number;
    details?: (DetailsEntity)[] | null;
    activities?: (ActivityInPlanning)[] | null;
    createdAt: string;
    updatedAt: string;
}
export interface Stop {
    id: number;
    name: string;
    sort: number;
    shortName: string;
    isActive: boolean;
    isIsland: boolean;
    createdAt: string;
    updatedAt: string;
}
export interface DetailsEntity {
    vehicle: Vehicle;
    boarding?: Boarding | null;
    arrivedAt?: string | null;
    hasArrived: boolean;
    departedAt?: string | null;
    hasDeparted: boolean;
    activities?: (ActivityInPlanning)[] | null;
    createdAt: string;
    updatedAt: string;
}

export interface Boarding {
    id: number;
    uuid: string;
    startedAt: string;
    endedAt?: null;
    hasEnded: boolean;
    startedBy: ItemType;
    endedBy?: null;
    createdBy?: null;
    updatedBy?: null;
}

export interface ActivityInPlanning {
    createdAt: string;
    updatedAt: string;
    activity: Activity;
}

type Activity = {
    id: number;
    name: string;
    uuid: string;
    shortName: string;
    description: string;
    color: string;
    invoiceArticle: string;
    isActive: boolean;
    isBeach: boolean;
    allowOnlyOneBooking: boolean;
    allowDiscounts: boolean;
    allowCommissions: boolean;
    allowMultipleActivities: boolean;
    generateIslandTrips: boolean;
    generateMiddleTrips: boolean;
    isHopOnHopOff: boolean;
    createdAt: string;
    updatedAt: string;
}

export type PlanningCircuit = {
    id: number,
    name: string,
    duration: string,
    isActive: boolean
}

export type PlanningListItem = {
    allowOverbooking: boolean,
    availableForAgents: boolean,
    availableForPublic: boolean,
    availableForTeamMembers: boolean,
    createdAt: string,
    id: number,
    date: string,
    hasCargo: boolean,
    isBoarding: boolean,
    maxCapacity: number,
    seats: number,
    seatsCheckedIn: number,
    outboundSeats: number,
    inboundSeats: number,
    outboundSeatsCheckedIn: number,
    inboundSeatsCheckedIn: number,
    isOverbooked: boolean,
    updatedAt: string,
    uuid: string,
    location?: any,
    activities: ActivityInPlanning[],
    circuit: PlanningCircuit,
    inbound: InOutBoundStops[],
    outbound: InOutBoundStops[],
    vehicles: Vehicles[]
}

export type PlanningsList = ListResponse<PlanningListItem>

export type Vehicles = {
    arrival: InOutBoundStops | null,
    departure: InOutBoundStops | null,
    inTransit: boolean,
    inboundCompleted: boolean,
    outboundCompleted: boolean,
    uuid: string,
    vehicle: Vehicle,
}

export type Vehicle = {
    capacity: number,
    color: string,
    createdAt: string,
    isAvaiable: boolean,
    name: string,
    shortName: string,
    updatedAt: string,
    uuid: string,
    vehicleClass: string,
    vehicleType: string
}


// PlannedLEgsForm
export type PlannedLegs = {
    activity: {
        id: number,
        name: string,
    },
    legs: {
        id: number,
        isActive: boolean,
        isOutbound: number,
        arrival: {
            id: number,
            name: string,
            shortName: string,
            time: string
        },
        departure: {
            id: number,
            name: string,
            shortName: string,
            time: string
        }
    }
}

export type PlannedLegsForm = {
    activity: {
        id: number,
        name: string,
    },
    legs: PlannedLegForm[]
}

export type PlannedLegForm = {
    id: number,
    isActive: FormField<boolean>,
    isOutbound: number,
    arrival: {
        id: number,
        name: string,
        shortName: string,
        time: string
    },
    departure: {
        id: number,
        name: string,
        shortName: string,
        time: string
    }
}

//duplicado
//TODO: ir buscar às configurations
export type PlanningVehicle = {
    uuid: string,
    name: string,
    shortName: string,
    capacity: number,
    isAvailable: boolean,
}

export type PlanningCargo = {
    cargoType: cargoType
    destination: string,
    receiver: string,
    observations: string,
}

export type cargoType = {
    id: number,
    name: string,
}

export type ActivityP = {
    id: number,
    name: string,
    uuid: string,
    shortName: string,
    description: string,
    color: string,
    isActive: boolean
}


export type PlanningStops = {
    stop: StopP,
    time: string,
    isOutbounds: boolean,
    details: StopDetailsP[]
}

export type StopP = {
    id: string,
    name: string,
    shortName: string,
    isActive: boolean,
    isAvailable: boolean
}

export type StopDetailsP = {
    vehicle: PlanningVehicle,
    activities: ActivityP[],
}


// PLANNING FORM
export type CargoTypeForm = {
    destination: FormField<string>,
    observations: FormField<string>,
    recipient: FormField<string>
}

export type PlanningCircuitForm = {
    circuit: FormField<string>
}

export type TripService = {
    id: FormField<any>,
    shortName: string,
    duration: string,
    generateIslandTrips: boolean,
    vehicles: PlanningVehicle[],
    circuits: PlanningCircuit[],
    allowMultipleActivities: boolean,
    isPrivate: boolean,
}

export type BeachService = {
    id: FormField<any>,
    shortName: string,
    duration: string,
    location: string,
    allowMultipleActivities: boolean,
    isPrivate: boolean,
}

export enum ServiceTypes {
    ACTIVITY = 1,
    BEACH = 2
}

export type ServiceDetails = TripService | BeachService

export type PlanningServiceForm = {
    serviceType: ServiceTypes,
    passengerTypes?: ActivityPassengerTypesForm[],
    extras?: ActivityExtrasForm[],
    serviceDetails: ServiceDetails
}

export type PlanningStopDetailsVehicleForm = {
    uuid: string,
    name: string,
}

export type PlanningStopDetailsActivitiesForm = {
    id: number,
    shortName: string,
    isOn: FormField<boolean>
}

export type PlanningStopDetailsForm = {
    vehicle?: PlanningStopDetailsVehicleForm,
    activities: PlanningStopDetailsActivitiesForm[],
}

export type PlanningStopsForm = {
    isEdited: boolean,
    time: FormField<string>,
    activities: PlanningStopDetailsActivitiesForm[],
    stop: {
        id: number,
        isActive: boolean,
        isAvailable: boolean,
        name: string,
        shortName: string,
        details: PlanningStopDetailsForm[]
    }
}

export type PlanningVehiclesForm = {
    uuid: FormField<string>,
    name: FormField<string>,
    capacity: FormField<string>
}

export type PlanningDatesForm = {
    date: FormField<string>,
}

export type PlanningRulesForm = {
    allowOverbooking: boolean,
    availableForAgents: boolean,
    availableForPublic: boolean,
    availableForTeamMembers: boolean
}

export type PlanningMaxCapacityForm = FormField<string>
export type PlanningMinimumPrice = FormField<string>
export type PlanningIncludedSeats = FormField<string>
export type PlanningBeachLocationForm = FormField<string>

export type SetNewCheckinProps = {
    location: number,
    outbound: boolean,
    vehicle: string,
    trip: number,
    booking: string,
    isElegible: boolean,
}

export type SetNewCheckLocationProps = {
    id: number,
    location: number,
    outbound: boolean,
    checkinId: number | null,
    checkoutId: number | null
}

export type NewPlanningForm = {
    trip: FormField<string>,
    vehicle: FormField<string>
}

export type openPlanningStopForm = {
    time: FormField<string>,
    isActive: FormField<boolean>,
    stop: openPlanningStop
}

export type openPlanningStop = {
    id: number,
    isActive: boolean,
    isAvailable: boolean,
    name: string,
    shortName: string,
    details: {
        vehicle: Vehicle;
        boarding?: Boarding | null;
        arrivedAt?: string | null;
        hasArrived: boolean;
        departedAt?: string | null;
        hasDeparted: boolean;
        activities: (openPlanningStopActivity)[] | null;
        createdAt: string;
        updatedAt: string;
    }[]
}

export type openPlanningStopActivity = {
    activity: Activity;
    createdAt: string;
    updatedAt: string;
    duration: string;
    isOn: FormField<boolean>;
    legs: {
        createdAt: string;
        date: string;
        id: number;
        time: string;
        updatedAt: string;
        arrival: openPlanningDirection;
        departure: openPlanningDirection;
    }[];
    passengerTypes: {
        createdAt: string;
        price: string;
        priceForIsland: string;
        updatedAt: string;
        passengerType: PassengerType
    }[];
}

export type openPlanningDirection = {
    id: number;
    isOutbound: boolean;
    name: string;
    shortName: string;
    stop: number;
    time: string;
}