import { takeLatest, putResolve, call, select } from 'redux-saga/effects'
import { actions, RootState } from 'store/rootSlices'
import API from 'api';
import { toast } from 'react-toastify'
import i18n from 'i18next';
import type { BiList } from 'types/trips/bi';


function* onMountSaga() {
  yield getBiListSaga()
}

function* getBiListSaga() {
  yield putResolve(actions.BusinessInteligence.setLoading(true))
  
  try {
    const { page } = yield select((state: RootState) => state.BusinessInteligence);
    const dashboards: BiList = yield call(API.Bi.GetAllDashboards, page);

    if (dashboards) {
      yield putResolve(actions.BusinessInteligence.setBiList(dashboards.results))
      yield putResolve(actions.BusinessInteligence.setTotal(dashboards.count))

    }
  } catch (error) {
    toast.error(i18n.t("toasts.getBiListError"))
  } finally {
    yield putResolve(actions.BusinessInteligence.setLoading(false))
  }
}


export default function* watcherSignin() {
  yield takeLatest('BusinessInteligence/onMount', onMountSaga)
  yield takeLatest('BusinessInteligence/getBiList', getBiListSaga)
  yield takeLatest('BusinessInteligence/setPage', getBiListSaga)
}


