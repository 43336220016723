import { CTableDataCell, CTableHead } from '@coreui/react-pro'
import styled from 'styled-components'
import theme from 'ui/theme'

export const CustomCTableHead = styled(CTableHead)`
  background-color: ${theme().white};

  > tr {
    > th {
      color: ${theme().darkColor};
      font-family: 'Inter';
      font-size: 14px;
      font-weight: 700;
    }

    th:last-child{
      width: 5%;
    }

    th:first-child {
      > svg {
        width: 100%;
        height: 24px;
        margin: auto;
        color: ${theme().darkColor};
        fill: ${theme().darkColor};
      }
    }
  }
`

export const PAXDiv = styled.div`
  display:flex;
  flex-direction:column;
  color: ${theme().gray700Color} !important;
`

export const Text = styled.p <{ weight: string }>`
  margin:0;
  font-family: Inter;
  font-weight: ${props => props.weight};
`

export const TableDataCell = styled(CTableDataCell)<{color?: string}>`
  height: 54px;
  ${({color}) => color ? `color: ${color} !important;` : null }
`

export const Tooltip = styled.div`
  position: relative;
  visibility: visible;
  color: #fff;

  &:hover {
    > span {
      visibility: visible
    }
  }
`

export const CustomSpan = styled.span`
  visibility: hidden;
  width: fit-content;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  top: 115%;
  left: -60px;
`

export const Icon = styled.div <{ marginr: string }>`
  margin-right: ${props => props.marginr}px;
`

export const BookingInfo = styled.div`
  display: flex;
  justify-content: space-between;
`

export const TooltipWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 25%;
  alignItems: center;
`