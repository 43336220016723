/**
 * Entitycommissiontablerow component
 *
 * @author João Dias <jdias@ubiwhere.com>
 *
 *
 */

import { CTableDataCell } from '@coreui/react-pro'
import React from 'react'
import CustomButton from 'shared/components/CustomButton'
import CustomTextInput from 'shared/components/CustomTextInput'
import CustomCheckbox from 'shared/components/CustomCheckbox'

import i18n from "i18next"
import { CustomCTableRow } from './styles'


type PropTypes = {
  id?: string,
  commission: {
    uuid: string,
    name: string,
    isCustom?: boolean,
    commission: {
      value: string,
      valid: boolean,
      message: string
    }
  },
  isMain?: boolean,
  onChange: Function,
  onChangeToggler?: Function,
  onClickAll?: Function,
  hasEditPermission: boolean,
  showCheckbox?: boolean;
}

const Entitycommissiontablerow = ({
  id,
  commission: {
    isCustom,
    uuid,
    name,
    commission
  },
  isMain,
  onChangeToggler,
  onChange,
  onClickAll,
  hasEditPermission,
  showCheckbox = true
}: PropTypes) => {

  const _renderCheckbox = () => { 
    return (!isMain && showCheckbox) && (
      <CustomCheckbox 
        id={`${id}-toggler`}
        disabled={!hasEditPermission}
        checked={isCustom}
        onClick={e => onChangeToggler && onChangeToggler(e.target.checked)}
        wrapperStyle={{ display: 'flex', justifyContent: 'center', fontSize: '22px' }}
        switch
      />
    )
  }

  return (
    <CustomCTableRow ismain={!!(isMain && onClickAll)}>
      <CTableDataCell style={{ width: "40%" }}>{name}</CTableDataCell>
      <CTableDataCell style={{ width: "10%" }}>
        { _renderCheckbox() }
      </CTableDataCell>
      <CTableDataCell style={{ width: "25%" }}>
        <CustomTextInput
          id={`${id}-percentage`}
          disabled={!hasEditPermission || !isCustom}
          valid={commission.valid}
          helpText={commission.message}
          icon='percent'
          placeholder={i18n.t('placeholders.percentage')} value={commission.value ? commission.value : ""}
          onChange={e => onChange(uuid, e.target.value)}
        />
      </CTableDataCell>
      {isMain && onClickAll
        && <CTableDataCell style={{ width: "20%" }}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CustomButton
              id={`${id}-applyAll`}
              icon="doubleCheck"
              onClick={value => hasEditPermission && onClickAll(value)}
              buttonStyle={{ color: "#FFFFFF" }}
              color='success'
              text={i18n.t('entityForm.resetAllDefault')}
            />
          </div>
        </CTableDataCell>}
    </CustomCTableRow>
  )
}

export default Entitycommissiontablerow