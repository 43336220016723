import * as React from "react";
import theme from "ui/theme";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg 
            width="16"
            height="16"
            viewBox="0 0 16 16" 
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path 
                d="M10.0002 3.33333V8H6.00016V3.33333H10.0002ZM10.0002 2H6.00016C5.26683 2 4.66683 2.6 4.66683 3.33333V9.33333H11.3335V3.33333C11.3335 2.6 10.7335 2 10.0002 2ZM14.6668 6.66667H12.6668V8.66667H14.6668V6.66667ZM3.3335 6.66667H1.3335V8.66667H3.3335V6.66667ZM13.3335 10H2.66683V14H4.00016V11.3333H12.0002V14H13.3335V10Z" 
                fill={props.fill ? props.fill : theme().gray500Color}
            />
        </svg>
    )
}

export default SvgComponent
