import axios from 'axios'
import endpoints from 'api/endpoints'
import { generateUrl, generateSortString } from 'utils'
import { decamelizeKeys, camelizeKeys } from 'humps'
import { SortType } from 'types/misc'




const GetAllCollaborators = (role?: string, group?: Array<string>) => {
    const roleQS = `${role ? `&role=${role}` : ''}`;
    const groupQS = `${group ? `group=${group[0]}&group=${group[1]}` : ''}`;

    const endpoint: string = `${endpoints.COLLABORATORS.GET_COLLABORATORS}?${roleQS}${groupQS}`;
    return axios.get(endpoint)
        .then(res => res.data)
        .catch(err => { throw err })
}


const GetCollaborators = (page: number, searchTerm: string, sort: SortType, role?: string, group?: string) => {
    //const endpoint = generateUrl(endpoints.COLLABORATORS.GET_COLLABORATORS, {i})
    const roleQS = `${role ? `&role=${role}` : ''}`;
    const groupQS = `${group ? `&group=${group}` : ''}`;
    const searchTermQS = `${searchTerm ? `&search=${searchTerm}` : ''}`;

    let endpoint: string = `${endpoints.COLLABORATORS.GET_COLLABORATORS}?page=${page}${searchTermQS}${roleQS}${groupQS}`;

    if (sort.field !== null && sort.direction !== null && sort.direction !== "") {
        endpoint += generateSortString(sort);
    }

    return axios.get(endpoint)
        .then(res => res.data)
        .catch(err => { throw err })
}

const GetCollaborator = (uuid) => {
    const token = window.localStorage.getItem('access_token')
    const endpoint = generateUrl(endpoints.COLLABORATORS.GET_COLLABORATOR, { uuid, token })
    return axios.get(endpoint)
        .then(res => res.data)
        .catch(err => { throw err })
}

const GetCollaboratorCommissions = (uuid, dateAfter: string, dateBefore: string) => {
    const dateAfterQS = `date_after=${dateAfter}`;
    const dateBeforeQS = `&date_before=${dateBefore}`;

    let endpoint = `${endpoints.COLLABORATORS.COLLABORATOR_COMMISSIONS_LIST}?${dateAfterQS}${dateBeforeQS}`
    endpoint = generateUrl(endpoint, { uuid })
    
    return axios.get(endpoint)
        .then(res => res.data)
        .catch(err => { throw err })
}


const CreateCollaborator = (params) => {
    const token = window.localStorage.getItem('access_token')
    const decMLZParams = decamelizeKeys(params)

    return axios.create({
        headers:{
            'Authorization': 'Bearer ' + token, 
            'Content-Type': 'application/json',
        }
    }).post(endpoints.COLLABORATORS.CREATE_COLLABORATOR, decMLZParams)
        .then(res => {
            return camelizeKeys(res.data)
        })
        .catch(err => {
            throw err 
        })
}

const SendAvatar = (uuid, params) => {

    const endpoint = generateUrl(endpoints.COLLABORATORS.EDIT_COLLABORATOR, { uuid })
    return axios.patch(endpoint, params)
        .then(res => res.data)
        .catch(err => { throw err })

}

const EditCollaborator = (uuid, params) => {
    const decMLZParams = decamelizeKeys(params)
    const token = window.localStorage.getItem('access_token')
    const endpoint = generateUrl(endpoints.COLLABORATORS.EDIT_COLLABORATOR, { uuid })

    return axios.create({
        headers:{
            'Authorization': 'Bearer ' + token, 
            'Content-Type': 'application/json',
        }
    }).patch(endpoint, decMLZParams)
        .then(res => {
            return camelizeKeys(res.data)
        })
        .catch(err => {
            throw err 
        })
}

const DeleteCollaborator = (uuid) => {
    const token = window.localStorage.getItem("access_token")
    const endpoint = generateUrl(endpoints.COLLABORATORS.DELETE_COLLABORATOR, { uuid })
    return axios.create({
        headers: {
            Authorization: "Bearer " + token
        }
    }).delete(endpoint, uuid)
        .then(res => res.data)
        .catch(err => { })
}

const GetCollaboratorTypes = () => {
    return axios.get(endpoints.COLLABORATORS.GET_COLLABORATOR_TYPES)
        .then(res => res.data)
        .catch(err => { throw err })
}

const ExportTimeRecord = (dateAfter: string, dateBefore: string, uuid: string) => {
    const dateAfterQS = `${dateAfter ? `date_after=${dateAfter}` : ''}`;
    const dateBeforeQS = `${dateBefore ? `&date_before=${dateBefore}` : ''}`;
    const teamMemberQS = `${uuid ? `&team_member=${uuid}` : ''}`;

    const endpoint = `${endpoints.COLLABORATORS.TIME_RECORD.EXPORT_USER_TIME_RECORD}?${dateAfterQS}${dateBeforeQS}${teamMemberQS}`;
    const token = window.localStorage.getItem("access_token")

    return fetch(endpoint, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        }
    })
        .then(res => res.blob())
        .catch(err => { throw err })
}

const ExportTimeRecordList = (dateAfter: string, dateBefore: string, role?: string, group?: string, searchTearm?: string) => {
    const dateAfterQS = `${dateAfter ? `date_after=${dateAfter}` : ''}`;
    const dateBeforeQS = `${dateBefore ? `&date_before=${dateBefore}` : ''}`;
    const roleQS = `${role ? `&role=${role}` : ''}`;
    const groupQS = `${group ? `&group=${group}` : ''}`;
    const searchTearmQS = `${searchTearm ? `&search=${searchTearm}` : ''}`;


    const endpoint = `${endpoints.COLLABORATORS.TIME_RECORD.TIME_RECORDS_EXPORT}?${dateAfterQS}${dateBeforeQS}${roleQS}${groupQS}${searchTearmQS}`;
    const token = window.localStorage.getItem("access_token")

    return fetch(endpoint, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        }
    })
        .then(res => res.blob())
        .catch(err => { throw err })
}

const GetUserTimeRecord = (dateAfter: string, dateBefore: string, uuid: string) => {
    const dateAfterQS = `${dateAfter ? `?date_after=${dateAfter}` : ''}`;
    const dateBeforeQS = `${dateBefore ? `&date_before=${dateBefore}` : ''}`;
    const teamMemberQS = `${uuid ? `&team_member=${uuid}` : ''}`;

    const endpoint = `${endpoints.COLLABORATORS.TIME_RECORD.GET_USER_TIME_RECORD_ENTRIES}${dateAfterQS}${dateBeforeQS}${teamMemberQS}`;

    return axios.get(endpoint)
        .then(res => res.data)
        .catch(err => { throw err })
}

const GetTimeRecordList = (searchTerm: string, sort: SortType, role?: string, dateAfter?: string, dateBefore?: string, group?: string) => {
    const roleQS = `${role ? `&role=${role}` : ''}`;
    const groupQS = `${group ? `&group=${group}` : ''}`;
    const searchTermQS = `${searchTerm ? `&search=${searchTerm}` : ''}`;
    const dateAfterQS = `${dateAfter ? `&date_after=${dateAfter}` : ''}`;
    const dateBeforeQS = `${dateBefore ? `&date_before=${dateBefore}` : ''}`;

    let endpoint: string = `${endpoints.COLLABORATORS.TIME_RECORD.TIME_RECORDS}?${roleQS}${searchTermQS}${dateAfterQS}${dateBeforeQS}${groupQS}`;

    if (sort.field !== null && sort.direction !== null && sort.direction !== "") {
        endpoint += generateSortString(sort);
    }

    return axios.get(endpoint)
        .then(res => res.data)
        .catch(err => { throw err })
}

const GetWorkdays = (dateAfter?: string, dateBefore?: string, uuid?: string) => {
    const dateAfterQS = `${dateAfter ? `?date_after=${dateAfter}` : ''}`;
    const dateBeforeQS = `${dateBefore ? `&date_before=${dateBefore}` : ''}`;
    const teamMemberQS = `${uuid ? `&team_member=${uuid}` : ''}`;

    const endpoint = `${endpoints.COLLABORATORS.TIME_RECORD.TIME_RECORD_LIST}${dateAfterQS}${dateBeforeQS}${teamMemberQS}`;


    return axios.get(endpoint)
        .then(res => res.data)
        .catch(err => { throw err })
}


const EditTimeRecordEntries = (params) => {

    const token = window.localStorage.getItem("access_token")
    const decMLZParams = decamelizeKeys(params)

    return axios.create({
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
        }
    }).post(endpoints.COLLABORATORS.TIME_RECORD.BULK_EDIT_USER_TIME_RECORD_ENTRIES, decMLZParams)
        .then(res => camelizeKeys(res))
        .catch(err => { throw err })
}

const CreateUserClockIn = (params) => {
    const decMLZParams = decamelizeKeys(params)

    return axios.post(endpoints.COLLABORATORS.TIME_RECORD.USER_TIME_RECORD_CLOCK_IN, decMLZParams)
        .then(res => res)
        .catch(err => { throw err })
}

const CreateUserClockOut = (params) => {
    const decMLZParams = decamelizeKeys(params)

    return axios.post(endpoints.COLLABORATORS.TIME_RECORD.USER_TIME_RECORD_CLOCK_OUT, decMLZParams)
        .then(res => res)
        .catch(err => { throw err })
}

const GetCollaboratorPublicInfo = (uuid: string) => {
    const endpoint = generateUrl(endpoints.COLLABORATORS.GET_COLLABORATOR_PUBLIC_INFO, { uuid })

    return axios.get(endpoint)
        .then(res => res.data)
        .catch(err => { throw err })

}

const QrCodeClockIn = (params) => {
    const decMLZParams = decamelizeKeys(params)

    return axios.create({
        headers: {
            'Content-Type': 'application/json',
        }
    }).post(endpoints.COLLABORATORS.TIME_RECORD.USER_TIME_RECORD_CLOCK_IN, decMLZParams)
        .then(res => res)
        .catch(err => { throw err })
}

const QrCodeClockOut = (params) => {
    const decMLZParams = decamelizeKeys(params)

    return axios.create({
        headers: {
            'Content-Type': 'application/json',
        }
    }).post(endpoints.COLLABORATORS.TIME_RECORD.USER_TIME_RECORD_CLOCK_OUT, decMLZParams)
        .then(res => res)
        .catch(err => { throw err })
}

const DeleteTimeRecordEntry = (id) => {
    const endpoint = generateUrl(endpoints.COLLABORATORS.TIME_RECORD.DELETE_USER_TIME_RECORD_ENTRY, { id })

    return axios.delete(endpoint)
        .then(res => res)
        .catch(err => { throw err })
}

const ApproveTimeRecords = (params) => {
    const token = window.localStorage.getItem("access_token")
    const decMLZParams = decamelizeKeys(params)

    return axios.create({
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
        }
    }).post(endpoints.COLLABORATORS.TIME_RECORD.APPROVE_TIME_RECORDS, decMLZParams)
        .then(res => camelizeKeys(res))
        .catch(err => { throw err })

    // return axios.post(endpoints.COLLABORATORS.TIME_RECORD.APPROVE_TIME_RECORDS, decMLZParams)
    //     .then(res => camelizeKeys(res))
    //     .catch(err => { throw err })
}

const DisapproveTimeRecords = (params) => {

    const decMLZParams = decamelizeKeys(params)

    return axios.post(endpoints.COLLABORATORS.TIME_RECORD.APPROVE_TIME_RECORDS, decMLZParams)
        .then(res => res)
        .catch(err => { throw err })
}
const GetHistoryLog = (id, searchTerm?) => {

    const searchTermQS = `${searchTerm ? `?search=${searchTerm}` : ''}`;

    const endpoint = generateUrl(endpoints.COLLABORATORS.GET_HISTORY_LOG, { id })
    const enpointWithQS = `${endpoint}${searchTermQS}`

    return axios.get(enpointWithQS)
        .then(res => res)
        .catch(err => { throw err })
}

const GetTeams = () => {

    return axios.get(endpoints.COLLABORATORS.TEAMS)
        .then(res => res.data)
        .catch(err => { throw err })
}

const GetDefaultCommissions = () => {

    return axios.get(endpoints.COLLABORATORS.GET_COLLABORATOR_DEFAULT_COMMISSIONS)
        .then(res => res.data)
        .catch(err => { throw err })
}

const EditDefaultCommissions = (params) => {
    const decMLZParams = decamelizeKeys(params)

    return axios.post(endpoints.COLLABORATORS.EDIT_COLLABORATOR_DEFAULT_COMMISSIONS, decMLZParams)
        .then(res => res.data)
        .catch(err => { throw err })
}

export default {
    GetCollaborator,
    GetCollaborators,
    GetAllCollaborators,
    CreateCollaborator,
    SendAvatar,
    EditCollaborator,
    DeleteCollaborator,
    GetCollaboratorTypes,
    ExportTimeRecord,
    ExportTimeRecordList,
    GetWorkdays,
    GetTimeRecordList,
    GetUserTimeRecord,
    EditTimeRecordEntries,
    CreateUserClockIn,
    CreateUserClockOut,
    DeleteTimeRecordEntry,
    GetHistoryLog,
    QrCodeClockIn,
    QrCodeClockOut,
    GetCollaboratorPublicInfo,
    GetTeams,
    ApproveTimeRecords,
    DisapproveTimeRecords,
    GetCollaboratorCommissions,
    GetDefaultCommissions,
    EditDefaultCommissions
}
