import * as React from "react"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 4C0 1.79086 1.79086 0 4 0H13C15.2091 0 17 1.79086 17 4V13C17 15.2091 15.2091 17 13 17H4C1.79086 17 0 15.2091 0 13V4Z" fill={props.fill ? props.fill : "#3399FF"} />
            <path d="M10.1245 5H11.8159V10.1953C11.8159 10.7786 11.6766 11.2891 11.3979 11.7266C11.1219 12.1641 10.7352 12.5052 10.2378 12.75C9.7404 12.9922 9.16097 13.1133 8.49951 13.1133C7.83545 13.1133 7.25472 12.9922 6.75732 12.75C6.25993 12.5052 5.87321 12.1641 5.59717 11.7266C5.32113 11.2891 5.18311 10.7786 5.18311 10.1953V5H6.87451V10.0508C6.87451 10.3555 6.94092 10.6263 7.07373 10.8633C7.20915 11.1003 7.39925 11.2865 7.64404 11.4219C7.88883 11.5573 8.17399 11.625 8.49951 11.625C8.82764 11.625 9.11279 11.5573 9.35498 11.4219C9.59977 11.2865 9.78857 11.1003 9.92139 10.8633C10.0568 10.6263 10.1245 10.3555 10.1245 10.0508V5Z" fill="white" />
        </svg>
    )
}

export default SvgComponent