
import React, { /* useEffect */ } from 'react'
import SectionContainer from '../../shared/components/Sectioncontainer/index';
import CustomTextInput from '../../shared/components/CustomTextInput/index';
import CustomRadioButtonGroup from '../../shared/components/CustomRadioButtonGroup';
import CustomRadioButton from '../../shared/components/CustomRadioButton';
import { CCol, CRow } from '@coreui/react-pro';
import { actions, RootState } from 'store/rootSlices'
import { useSelector, useDispatch } from 'react-redux'
import i18n from 'i18next';
import _ from "lodash";


const SituacaoFamiliar = (props) => {
    const dispatch = useDispatch();
    const dbDispatch = _.debounce(dispatch, 300);

    const { onFormChange } = actions.userForm;
    const { userForm, hasEditPermission } = useSelector((state: RootState) => state.UserForm);

    return (
        <SectionContainer required title={i18n.t('userForm.title5')}  style={{ width: '100%', paddingBottom: '24px' }}>
            <CRow className="g-3">
                <CCol>
                    <CustomRadioButtonGroup title={i18n.t('userForm.maritalStatus')}  role="group1" helpText={userForm.maritalStatus.message} valid={userForm.maritalStatus.valid}>
                        <CustomRadioButton group="group1" text={i18n.t('userForm.marital3')}  value="single"
                            checked={userForm.maritalStatus.value === 'single' ? true : false}
                            disabled={!hasEditPermission}
                            onClick={e => dispatch(onFormChange({ ...userForm, maritalStatus: { value: e.target.value, message: "", valid: true }}))} />
                        <CustomRadioButton group="group1" text={i18n.t('userForm.marital1')}  value="married"
                            checked={userForm.maritalStatus.value === 'married' ? true : false}
                            disabled={!hasEditPermission}
                            onClick={e => dispatch(onFormChange({ ...userForm, maritalStatus: { value: e.target.value, message: "", valid: true }}))} />
                        <CustomRadioButton group="group1" text={i18n.t('userForm.marital2')}  value="married_2"
                            checked={userForm.maritalStatus.value === 'married_2' ? true : false}
                            disabled={!hasEditPermission}
                            onClick={e => dispatch(onFormChange({ ...userForm, maritalStatus: { value: e.target.value, message: "", valid: true }}))} />
                    </CustomRadioButtonGroup>
                </CCol>
                <CCol>
                    <CustomTextInput
                        id='userForm-numberOfDependents'
                        disabled={!hasEditPermission}
                        valid={userForm.numberOfDependents.valid}
                        initialValue={userForm.numberOfDependents.value}
                        helpText={userForm.numberOfDependents.message}
                        placeholder={i18n.t('placeholders.dependents')}
                        label={i18n.t('userForm.dependents')} 
                        onChange={(e) => dbDispatch(onFormChange({ ...userForm, numberOfDependents: { value: e.target.value, message: "", valid: true } }))}
                    />
                </CCol>
            </CRow>
        </SectionContainer>
    )
}

export default SituacaoFamiliar
