/**
 * Tripmodal component
 *
 * @author Stephane Ribeiro <sgribeiro@ubiwhere.com>
 *
 *
 */

import React from 'react'
import { CRow, CCol } from '@coreui/react-pro'
import { useSelector, useDispatch } from 'react-redux'
import i18n from 'i18next';

import Loadingcontainer from 'shared/components/Loadingcontainer'
import CustomModal from 'shared/components/CustomModal'
import Searchabledropdown from 'shared/components/Searchabledropdown'
import BoatDropdown from 'shared/components/FormComponents/boatDropdown'
import Boat from 'assets/icons/boat_icon'
import StartTrip from 'assets/icons/start_trip'
import EndTrip from 'assets/icons/end_trip'

import { RootState } from 'store/rootSlices'
import { actions } from 'store/rootSlices'
import { Vehicles, PlanningListItem } from 'types/trips/plannings'
import { SetNewCheckLocationSagaType } from '../../utils'; 
import * as Styles from './styles'


type PropTypes = React.PropsWithChildren<{
  onClose: Function,
}>

const Tripmodal = (props: PropTypes) => {
  const dispatch = useDispatch()
  const { 
    plannings, 
    isPlanningsLastPage, 
    newPlanningForm,
    planningModalLoading,
    tripModal,
    outboundSelected,
    checkingIn
  } = useSelector((state: RootState) => state.PassengerControl);

  const {
    getPlannings,
    onPlanningFormChange,
    setTripModal,
    setOutboundSelected,
    setShowWarningModal,
    setWarningModalData,
    setNewCheckLocation,
    setNewCheckin
  } = actions.PassengerControl

  const { allLocals } = useSelector((state: RootState) => state.TripsConfiguration);

  const handleClick = (data: SetNewCheckLocationSagaType) => {
    if(allLocals.some((l) => l.id === data.location && l.shortName === 'FAR') && !data?.checkoutId) {
      dispatch(setShowWarningModal(true))
      dispatch(setWarningModalData({
        id: data.id, 
        location: data.location,
        outbound: data.outbound,
        checkinId: data.checkinId,
        checkoutId: data.checkoutId
      }))
    } else {
      dispatch(setNewCheckLocation({
        id: data.id, 
        location: data.location,
        outbound: data.outbound,
        checkinId: data.checkinId,
        checkoutId: data.checkoutId
      }))
    }
  }


  return (
    <CustomModal
      visible={tripModal.isVisible}
      warningType='message'
      warningName={i18n.t('passengerControl.tripModalTitle')}
      buttonIcon={false}
      footerPosition='flex-start'
      textAlign='start'
      noButtons={true}
      size="lg"
      children={
        <Styles.CustomForm>
          {
            planningModalLoading ? (
              <Loadingcontainer />
            ) : (
              <>
                <CRow>
                  <CCol>
                    <Searchabledropdown 
                      id="passengerControl-plannings"
                      placeholder={i18n.t("tripPlannings.schedules")}
                      initialResults={plannings}
                      parseResults={r => { 
                        const time = (r.outbound && r.outbound.length > 0 ) ? 
                          `${r.outbound[0]?.time} - `
                            : 
                          (r.inbound && r.inbound.length > 0 ) ?
                            `${r.inbound[0]?.time} - `
                            : ''
                          
                        return `${time} ${r.activities[0].activity.name} - ${r.circuit.name}` 
                      }}
                      disabled={false}
                      label={i18n.t('tripPlannings.schedules')}
                      helpText={newPlanningForm.trip.message}
                      valid={newPlanningForm.trip.valid}
                      value={tripModal.planning}
                      isLastPage={isPlanningsLastPage}
                      onSelectResult={(value) => {
                        dispatch(setTripModal({
                          ...tripModal,
                          vehicle: null,
                          planning: plannings.find((pl: PlanningListItem) => pl.uuid === value)
                        }))
                        dispatch(onPlanningFormChange({ trip: { value: value, message: "", valid: true }, vehicle: { value: "", message: "", valid: true }}))
                      }}
                      onScrollBottom={() => dispatch(getPlannings())}
                    />
                  </CCol>
                  <CCol>
                    <BoatDropdown
                        id="passengerControl-unitDropdown"
                        key={`passengerControl-boatInput-${tripModal.vehicle ? tripModal.vehicle.name : ''}`}
                        valid={newPlanningForm.vehicle.valid}
                        label={i18n.t('tripPlannings.vehicle')}
                        placeholder={i18n.t('tripPlannings.selectNewVehicle')}
                        helpText={newPlanningForm.vehicle.message}
                        value={
                          tripModal.vehicle ?
                          { 
                            label: tripModal.vehicle.name, 
                            value: tripModal.vehicle.uuid, 
                            icon: <Boat fill={tripModal.vehicle.color} />
                          } : null
                        }
                        onChange={e => {
                          dispatch(setTripModal({
                            ...tripModal,
                            vehicle: tripModal.planning.vehicles.find((vh: Vehicles) => vh.vehicle.uuid === e.value).vehicle,
                          }))
                          dispatch(onPlanningFormChange({ ...newPlanningForm, vehicle: { value: e.value, message: "", valid: true } }))
                        }}
                        options={
                          tripModal.planning.vehicles.map((vh: Vehicles) => ({ label: vh.vehicle.name, value: vh.vehicle.uuid, icon: <Boat fill={vh.vehicle.color} /> } ))
                        }

                        disabled={false}
                    />
                  </CCol>
                </CRow>
                <Styles.Row>
                  <CCol>
                    <Styles.TabsWrapper id="tripPlannigns-checkinToogle" size="sm" role="group" aria-label="Basic checkbox toggle button group" >
                      {
                        tripModal.planning.outbound.length > 0 && (
                          <Styles.Tab
                            checked={outboundSelected}
                            type="radio"
                            onClick={e => {
                              dispatch(setOutboundSelected(true))
                            }}
                            button={{ color: 'secondary', variant: 'outline' }}
                            name="btnradio"
                            id="tripPlannigns-checkinToogle-going"
                            label={
                              <Styles.TabsCustomSpan>
                                <StartTrip /> {i18n.t('tripPlannings.going')} {outboundSelected && <Styles.GreenFlag />}
                              </Styles.TabsCustomSpan>
                            }
                          />
                        )
                      }
                        {
                          tripModal.planning.activities[0].activity.shortName !== 'HPP' && tripModal.planning.inbound.length > 0 && (
                            <Styles.Tab
                              checked={!outboundSelected}
                              type="radio"
                              onClick={e => {
                                dispatch(setOutboundSelected(false))
                              }}
                              name="btnradio"
                              button={{ color: "secondary", variant: "outline" }}
                              id="tripPlannigns-checkinToogle-return"
                              label={
                                <Styles.TabsCustomSpan>
                                  <EndTrip /> {i18n.t('tripPlannings.return')} {!outboundSelected && <Styles.GreenFlag />}
                                </Styles.TabsCustomSpan>
                              }
                            />
                          )
                        }
                    </Styles.TabsWrapper>
                  </CCol>
                  <CCol></CCol>
                </Styles.Row>
                <Styles.StopsRow stopsLength={allLocals?.length}>
                  {
                    allLocals.map((st, idx) => {
                      if(outboundSelected) {
                        const isActiveStop = tripModal.planning.outbound.find((plStop) => plStop.stop.id === st.id)
                        const checkinDetails = tripModal.lastCheckin.booking.lastCheckIn
                        const hasChanged = tripModal.initialPlanningId !== tripModal.planning.id
                        
                        return (
                          <Styles.TableDataCellStops
                            className={idx === 0 ? 'first-cell' : ''}
                            key={`tabbleStopCell-${st.name}-${idx}`}
                            colSpan={1}
                            start={hasChanged ? false : checkinDetails?.stopIn?.stop.id === st.id ? true : false}
                            exit={hasChanged ? false : checkinDetails?.stopOut?.stop.id === st.id ? true : false}
                            current={false}
                            activ={isActiveStop ? true : false}
                            onClick={e => {
                              if(!hasChanged && isActiveStop && !checkingIn) {
                                handleClick({
                                  id: checkinDetails?.id,
                                  location: st.id,
                                  outbound: true,
                                  checkinId: checkinDetails?.stopIn?.stop.id ? checkinDetails?.stopIn?.stop.id : null,
                                  checkoutId: checkinDetails?.stopOut?.stop.id ? checkinDetails?.stopOut?.stop.id : null,
                                })
                              } else if(hasChanged && isActiveStop && !checkingIn) {
                                dispatch(setNewCheckin({
                                  location: st.id,
                                  outbound: true
                                }))
                              }
                              
                            }}
                          >
                            {st.shortName}
                          </Styles.TableDataCellStops>
                        )
                      } else {
                        const isActiveStop = tripModal.planning.inbound.find((plStop) => plStop.stop.id === st.id)
                        const checkinDetails = tripModal.lastCheckin.booking.lastCheckIn
                        const hasChanged = tripModal.initialPlanningId !== tripModal.planning.id

                        return (
                          <Styles.TableDataCellStops
                            className={idx === 0 ? 'first-cell' : ''}
                            key={`tabbleStopCell-${st.name}-${idx}`}
                            colSpan={1}
                            start={hasChanged ? false : checkinDetails?.stopIn?.stop.id === st.id ? true : false}
                            exit={hasChanged ? false : checkinDetails?.stopOut?.stop.id === st.id ? true : false}
                            current={false}
                            activ={isActiveStop ? true : false}
                            onClick={() => {
                              if(!hasChanged && isActiveStop && !checkingIn) {
                                handleClick({
                                  id: checkinDetails?.id,
                                  location: st.id,
                                  outbound: false,
                                  checkinId: checkinDetails?.stopIn?.stop.id ? checkinDetails?.stopIn?.stop.id : null,
                                  checkoutId: checkinDetails?.stopOut?.stop.id ? checkinDetails?.stopOut?.stop.id : null,
                                })
                              } else if(hasChanged && isActiveStop && !checkingIn) {
                                dispatch(setNewCheckin({
                                  location: st.id,
                                  outbound: false
                                }))
                              }
                            }}
                          >
                            {st.shortName}
                          </Styles.TableDataCellStops>
                        )
                      }
                    })
                  }
                </Styles.StopsRow>
              </>
            )

          }
        </Styles.CustomForm>
      }
      onclose={e => props.onClose(false)}
      onsubmit={e => {/* handleDeleteCollaboratorConfirm(e) */ }} 
    />
  )
}

export default Tripmodal