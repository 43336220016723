/**
 * FuelLevel component
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */

import { useDispatch } from 'react-redux'
import React from 'react'
import * as Styles from './styles'
import FuelCan from '../../../assets/icons/fuel_can'
import { actions } from 'store/rootSlices'

interface PropTypes {
    vehicles?: Array<any>,
    unit: string,
    tanks?: Array<any>
}

const FuelLevelComponent = (props: PropTypes) => {
    const dispatch = useDispatch()
    const { navigateTo } = actions.App

    return (
        <Styles.ComponentsWrapper style={props.tanks && {marginBottom: '15px', flexWrap: "wrap"}}>
            {
                props.vehicles && props.vehicles.map((item) => {
                    const percent = Math.min(Math.max(Math.round(item.vehicle.currentTankQuantity / item.vehicle.maxTankCapacity * 100), 0), 100);
                    return (
                        <Styles.Wrapper key={`vehicle_${item.vehicle.id}`}>
                            <Styles.ButtonLabel color='secondary'><FuelCan />{item.vehicle.name}</Styles.ButtonLabel>
                            <Styles.GaugeWrapper>
                                <Styles.TextContainer>
                                    <span>
                                        <b>
                                        {
                                                item.vehicle.currentTankQuantity > item.vehicle.maxTankCapacity ? item.vehicle.maxTankCapacity : item.vehicle.currentTankQuantity
                                        }
                                        </b> / {item.vehicle.maxTankCapacity}&nbsp;{props.unit}
                                    </span>
                                </Styles.TextContainer>
                                <Styles.GaugeContainer percent={percent}>
                                    <div className="gauge-value" />
                                    <div className="gauge-empty" />
                                </Styles.GaugeContainer>
                            </Styles.GaugeWrapper>
                        </Styles.Wrapper>
                    )
                })
            }
            {
                props.tanks && props.tanks.map((tank) => {
                    const percent = Math.min(Math.max(Math.round(tank.fuelLevel / tank.fuelCapacity * 100), 0), 100);
                    return (
                        <Styles.WrapperTank key={`tank_${tank.id}`} style={props.tanks && {marginBottom: '5px'}}>
                            <Styles.ButtonLabel
                                onClick={() => dispatch(navigateTo(`configuracoes/editar-local-abastecimento/${tank.id}`))}
                                color='secondary'><FuelCan />{tank.name}</Styles.ButtonLabel>
                            <Styles.GaugeWrapper>
                                <Styles.TextContainer>
                                    <span>
                                        <b>
                                        {
                                                parseInt(tank.fuelLevel) > parseInt(tank.fuelCapacity) ? tank.fuelCapacity : tank.fuelLevel
                                        }
                                        </b> / {tank.fuelCapacity}&nbsp;{props.unit}
                                    </span>
                                </Styles.TextContainer>
                                <Styles.GaugeContainer percent={percent}>
                                    <div className="gauge-value" />
                                    <div className="gauge-empty" />
                                </Styles.GaugeContainer>
                            </Styles.GaugeWrapper>
                        </Styles.WrapperTank>
                    )
                })
            }
        </Styles.ComponentsWrapper>
    )
}

export default FuelLevelComponent