/**
 * Addvehicletablerowform component
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */

import React, { useEffect, useState } from 'react'
import { TableRowPropTypes } from 'types/misc'
import { CTableDataCell } from '@coreui/react-pro'
import CustomCheckbox from 'shared/components/CustomCheckbox'
import Timepicker from 'shared/components/FormComponents/timepicker'
import { PlanningStopDetailsForm } from 'types/trips/plannings'
import { CustomCTableRow, CheckBoxDataCell } from '../../styles'

type PropTypes = TableRowPropTypes & {
  disabled?: boolean
}


const Addvehicletablerowform = (props) => {

  const {
    stop,
    services,
    vehicle
  } = props

  const [currentDetail, setCurrentDetail] = useState<PlanningStopDetailsForm>()
  const [timerDisabled, setTimerDisabled] = useState<boolean>(false)

  useEffect(() => {
    let allActivitiesOff: boolean
    if(vehicle) {
      let detail = stop.stop.details.find(det => det.vehicle?.uuid === vehicle)
      if(detail) {
        setCurrentDetail(detail)
        allActivitiesOff = detail.activities.every((d) => !d.isOn.value)
        setTimerDisabled(allActivitiesOff)
      }
    } else {
      allActivitiesOff = stop.activities.every((act) => !act.isOn.value)
      setTimerDisabled(allActivitiesOff)
    }
  }, [stop.stop.details, vehicle, stop.activities])

  return (
    <CustomCTableRow>
      <>
        <CTableDataCell>{stop.stop.name}</CTableDataCell>
        <CTableDataCell>
          <Timepicker
            timeFormat={'HH:mm'}
            wrapperStyle={{ minWidth: '70px' }}
            disabled={props.disabled || timerDisabled}
            onChange={(e) => props.onChange({ e: e, details: stop.stop.details, stopId: stop.stop.id, vehicle: vehicle, inputType: 'time' })}
            value={stop.time.value}
            valid={stop.time.valid}
          />
        </CTableDataCell>

        {
          services?.map((serv, idx) => {
            let currentServiceAct
            if(vehicle) {
              currentServiceAct = currentDetail?.activities.find((act) => act.id === serv.serviceDetails.id.value)
            } else {
              currentServiceAct = stop.activities.find((act) => act.id === serv.serviceDetails.id.value)
            }
            return (
              <CheckBoxDataCell key={`tripPlanningsForm-switch-${serv.serviceDetails.id.value}-${idx}`}>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <CustomCheckbox
                    checkStyle={{ width: '40px', height: '20px', marginTop: '5px' }}
                    id={`act${0}`}
                    disabled={props.disabled}
                    checked={currentServiceAct?.isOn.value}
                    onClick={e => props.onChange({
                      e: !currentServiceAct?.isOn.value,
                      details: stop.stop.details,
                      actId: currentServiceAct?.id,
                      stopId: stop.stop.id,
                      vehicle: vehicle,
                      inputType: 'activities'
                    })}
                    switch
                  />
                </div>
              </CheckBoxDataCell>
            )
          })
        }  
      </>
    </CustomCTableRow>
  )
}

export default Addvehicletablerowform