import { combineReducers } from 'redux';

import appSlice from 'app/logic/slice';
import appToolsSlice from 'app/AppTools/logic/slice';
import dashboardSlice from 'scenes/Dashboard/logic/slice';
import settingsSlice from 'scenes/Settings/logic/slice';

// WARNING!! Don't erase the nextline as it is necessary for hygen to automatically import your components
// Define imports here
import invoicedetailsSlice from 'scenes/Invoicedetails/logic/slice'
import usercashmovementformSlice from 'scenes/Usercashmovementform/logic/slice'
import usercashmanagementformsSlice from 'scenes/Usercashmanagementforms/logic/slice'
import usercashmanagementSlice from 'scenes/Usercashmanagement/logic/slice'
import cashmanagementSlice from 'scenes/Cashmanagement/logic/slice'
import clockqrreaderSlice from 'scenes/Clockqrreader/logic/slice'
import historylogSlice from 'scenes/Historylog/logic/slice'
import usertimerecorddetailsSlice from 'scenes/Usertimerecorddetails/logic/slice'
import timerecordSlice from 'scenes/Timerecord/logic/slice'
import configurationsSlice from 'scenes/Configurations/logic/slice'
import invoicingrequestsSlice from 'scenes/InvoicingRequests/logic/slice'
import invoicingsummarySlice from 'scenes/InvoicingSummary/logic/slice'
import bookingformSlice from 'scenes/BookingForm/logic/slice'
import passengercontrolSlice from 'scenes/Passengercontrol/logic/slice'
import tripbookingsSlice from 'scenes/Tripbookings/logic/slice'
import tripplanningsformSlice from 'scenes/TripForms/Tripplanningsform/logic/slice'
import tripplanningsSlice from 'scenes/Tripplannings/logic/slice'
import entitymemberformSlice from 'scenes/Entitymemberform/logic/slice'
import entitymemberslistSlice from 'scenes/Entitymemberslist/logic/slice'
import entityformSlice from 'scenes/Entityform/logic/slice'
import entitiesSlice from 'scenes/Entities/logic/slice'
import customerpurchasehistorySlice from 'scenes/Customerpurchasehistory/logic/slice'
import tripcampaigncodesSlice from 'scenes/Tripcampaigncodes/logic/slice'
import customerformSlice from 'scenes/CustomerForm/logic/slice'
import customersSlice from 'scenes/Customers/logic/slice'
import fuellocationconfigformSlice from 'scenes/Fuellocationconfigform/logic/slice'
import documentconfigformSlice from 'scenes/Documentconfigform/logic/slice'
import fleetconfigsSlice from 'scenes/Fleetconfigs/logic/slice'
import tripsconfigurationSlice from 'scenes/Tripsconfiguration/logic/slice'
import tripactivityformSlice from 'scenes/TripForms/Tripactivityform/logic/slice'
import tripcategoryformSlice from 'scenes/TripForms/Tripcategoryform/logic/slice'
import triplocalformSlice from 'scenes/TripForms/Triplocalform/logic/slice'
import trippassengertypeformSlice from 'scenes/TripForms/Trippassengertypeform/logic/slice'
import tripextraformSlice from 'scenes/TripForms/Tripextraform/logic/slice'
import tripcampaignformSlice from 'scenes/TripForms/Tripcampaignform/logic/slice'
import tripcampaigncodesformSlice from 'scenes/TripForms/Tripcampaigncodesform/logic/slice'
import tripcircuitformSlice from 'scenes/TripForms/Tripcircuitform/logic/slice'
import tripserviceformSlice from 'scenes/TripForms/Tripserviceform/logic/slice'
import changepasswordSlice from 'scenes/Changepassword/logic/slice'
import fleetmaintenanceFormSlice from 'scenes/FleetMaintenanceForm/logic/slice'
import fleetmaintenanceSlice from 'scenes/Fleetmaintenance/logic/slice'
import fleetmanagementformSlice from 'scenes/FleetManagementForm/logic/slice'
import fleetmanagementSlice from 'scenes/Fleetmanagement/logic/slice'
import fleetfuellingSlice from 'scenes/Fleetfuelling/logic/slice'
import FleetFuellingFormSlice from 'scenes/FleetFuellingForms/logic/slice'
import userFormSlice from 'scenes/UserForms/logic/slice'
import collaboratorsManagementSlice from 'scenes/CollaboratorsManagement/logic/slice'
import loginSlice from 'scenes/Login/logic/slice'
import BusinessInteligenceSlice from 'scenes/BusinessInteligence/logic/slice';
import BiDashboardSlice from 'scenes/BiDashboard/logic/slice';


export const rootReducer = combineReducers({
  InvoiceDetails: invoicedetailsSlice.reducer,
  UserCashMovementForm: usercashmovementformSlice.reducer,
  UserCashManagementForms: usercashmanagementformsSlice.reducer,
  UserCashManagement: usercashmanagementSlice.reducer,
  CashManagement: cashmanagementSlice.reducer,
  ClockQrReader: clockqrreaderSlice.reducer,
  HistoryLog: historylogSlice.reducer,
  UserTimeRecordDetails: usertimerecorddetailsSlice.reducer,
  TimeRecord: timerecordSlice.reducer,
  Configurations: configurationsSlice.reducer,
  BusinessInteligence: BusinessInteligenceSlice.reducer,
  BiDashboard: BiDashboardSlice.reducer,
  InvoicingRequests: invoicingrequestsSlice.reducer,
  InvoicingSummary: invoicingsummarySlice.reducer,
  BookingForm: bookingformSlice.reducer,
  PassengerControl: passengercontrolSlice.reducer,
  TripBookings: tripbookingsSlice.reducer,
  TripPlanningsForm: tripplanningsformSlice.reducer,
  TripPlannings: tripplanningsSlice.reducer,
  Entitymemberform: entitymemberformSlice.reducer,
  Entitymemberslist: entitymemberslistSlice.reducer,
  Entityform: entityformSlice.reducer,
  Entities: entitiesSlice.reducer,
  Customerpurchasehistory: customerpurchasehistorySlice.reducer,
  TripCampaignCodes: tripcampaigncodesSlice.reducer,
  Customerform: customerformSlice.reducer,
  Customers: customersSlice.reducer,
  Fuellocationconfigform: fuellocationconfigformSlice.reducer,
  Documentconfigform: documentconfigformSlice.reducer,
  Fleetconfigs: fleetconfigsSlice.reducer,
  TripsConfiguration: tripsconfigurationSlice.reducer,
  TripActivityForm: tripactivityformSlice.reducer,
  TripCategoryForm: tripcategoryformSlice.reducer,
  TripLocalForm: triplocalformSlice.reducer,
  TripPassengerTypeForm: trippassengertypeformSlice.reducer,
  TripExtraForm: tripextraformSlice.reducer,
  TripCampaignForm: tripcampaignformSlice.reducer,
  TripCampaignCodesForm: tripcampaigncodesformSlice.reducer,
  TripCircuitForm: tripcircuitformSlice.reducer,
  TripServiceForm: tripserviceformSlice.reducer,
  ChangePassword: changepasswordSlice.reducer,
  FleetMaintenanceForm: fleetmaintenanceFormSlice.reducer,
  FleetMaintenance: fleetmaintenanceSlice.reducer,
  FleetManagementForm: fleetmanagementformSlice.reducer,
  Fleetfuelling: fleetfuellingSlice.reducer,
  FleetFuellingForm: FleetFuellingFormSlice.reducer,
  UserForm: userFormSlice.reducer,
  CollaboratorsManagement: collaboratorsManagementSlice.reducer,
  FleetManagement: fleetmanagementSlice.reducer,
  Login: loginSlice.reducer,
  App: appSlice.reducer,
  AppTools: appToolsSlice.reducer,
  Dashboard: dashboardSlice.reducer,
  Settings: settingsSlice.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export const actions = {
  Invoicedetails: invoicedetailsSlice.actions,
  UserCashMovementForm: usercashmovementformSlice.actions,
  UserCashManagementForms: usercashmanagementformsSlice.actions,
  UserCashManagement: usercashmanagementSlice.actions,
  CashManagement: cashmanagementSlice.actions,
  ClockQrReader: clockqrreaderSlice.actions,
  HistoryLog: historylogSlice.actions,
  UserTimeRecordDetails: usertimerecorddetailsSlice.actions,
  TimeRecord: timerecordSlice.actions,
  Configurations: configurationsSlice.actions,
  BusinessInteligence: BusinessInteligenceSlice.actions,
  BiDashboard: BiDashboardSlice.actions,
  InvoicingRequests: invoicingrequestsSlice.actions,
  InvoicingSummary: invoicingsummarySlice.actions,
  BookingForm: bookingformSlice.actions,
  PassengerControl: passengercontrolSlice.actions,
  TripBookings: tripbookingsSlice.actions,
  TripPlanningsForm: tripplanningsformSlice.actions,
  TripPlannings: tripplanningsSlice.actions,
  Entitymemberform: entitymemberformSlice.actions,
  Entitymemberslist: entitymemberslistSlice.actions,
  Entityform: entityformSlice.actions,
  Entities: entitiesSlice.actions,
  Customerpurchasehistory: customerpurchasehistorySlice.actions,
  TripCampaignCodes: tripcampaigncodesSlice.actions,
  Customerform: customerformSlice.actions,
  Customers: customersSlice.actions,
  Fuellocationconfigform: fuellocationconfigformSlice.actions,
  Documentconfigform: documentconfigformSlice.actions,
  Fleetconfigs: fleetconfigsSlice.actions,
  Tripsconfiguration: tripsconfigurationSlice.actions,
  TripActivityForm: tripactivityformSlice.actions,
  TripCategoryForm: tripcategoryformSlice.actions,
  TripLocalForm: triplocalformSlice.actions,
  TripPassengerTypeForm: trippassengertypeformSlice.actions,
  TripExtraForm: tripextraformSlice.actions,
  TripCampaignForm: tripcampaignformSlice.actions,
  TripCampaignCodesForm: tripcampaigncodesformSlice.actions,
  TripCircuitForm: tripcircuitformSlice.actions,
  TripServiceForm: tripserviceformSlice.actions,
  ChangePassword: changepasswordSlice.actions,
  FleetMaintenanceForm: fleetmaintenanceFormSlice.actions,
  FleetMaintenance: fleetmaintenanceSlice.actions,
  FleetManagementForm: fleetmanagementformSlice.actions,
  Fleetfuelling: fleetfuellingSlice.actions,
  FleetFuellingForm: FleetFuellingFormSlice.actions,
  userForm: userFormSlice.actions,
  CollaboratorsManagement: collaboratorsManagementSlice.actions,
  FleetManagement: fleetmanagementSlice.actions,
  Login: loginSlice.actions,
  App: appSlice.actions,
  AppTools: appToolsSlice.actions,
  Dashboard: dashboardSlice.actions,
  Settings: settingsSlice.actions,
};
