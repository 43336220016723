/**
 * Fleetfuelling scene
 *
 * @author João Dias <jdias@ubiwhere.com>
 *
 *
 */
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { DateTime } from 'luxon'
// Styles
import { Wrapper, ControlsContainer } from 'shared/genericStyledComponents'
import { FuellingsTable, ConsumptionTable } from './styles'
// Components
import SearchBox from 'shared/components/SearchBox'
import FilterComponent from 'shared/components/FilterComponent'
import CustomButton from 'shared/components/CustomButton'
import SorterComponent from 'shared/components/SorterComponent'
import Consumptionlisttablerow from './components/Consumptionlisttablerow'
import FuellingListTableRow from './components/Fuellinglisttablerow'
import TabsContainer from '../../shared/components/Tabscontainer/index'
import FuelLevelComponent from 'shared/components/FuelLevellComponent'
import Loadingcontainer from 'shared/components/Loadingcontainer'
import FilterDatePicker from 'shared/components/FilterDatePicker'
// Types
import { FuelConsumptionObject, FuellingListItem } from 'types/fleet/fuel'
// Icons
import DirectionsBoat from 'assets/icons/directions_boat'
import Map from 'assets/icons/map'
import Feed from 'assets/icons/feed'
// Store
import { actions, RootState } from 'store/rootSlices'
// Utils & Configs
import { paginationCurrentPage, paginationElementsCount } from 'utils'
import config from 'config'
import { hasPermission } from 'permissions'
import { presetsLists } from 'shared/components/FilterDatePicker/utils'

const Fleetfuelling = ({ t, i18n }) => {

  const {
    sort,
    loading,
    consumptionsList,
    fuellingsList,
    consumptionsPage,
    consumptionsTotal,
    fuellingsPage,
    fuellingsTotal,
    filter,
    searchTerm,
    tanks,
    startDate,
    endDate,
    vehicle,
    fuelLocationsList,
    fuelStation,
    hasDateParams
  } = useSelector((state: RootState) => state.Fleetfuelling)

  const {
    onMount,
    onUnmount,
    setSearchTerm,
    setSort,
    setConsumptionsPage,
    setFuellingsPage,
    setFilter,
    setVehicle,
    setFuelStation,
    setDatesInterval,
    getFuellingsList,
    getConsumptionsList
  } = actions.Fleetfuelling

  const {
    vehicleTypes,
    fleetLongList
  } = useSelector((state: RootState) => state.FleetManagement);

  const { navigateTo } = actions.App
  const { permissions } = useSelector((state: RootState) => state.App)
  const { ELEMENTS_PER_PAGE, PERMISSIONS } = config

  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(onMount(history))
    return () => {
      dispatch(onUnmount())
    }
  }, [dispatch, onMount, onUnmount, history]);

  return (
    <Wrapper>
      <ControlsContainer>
        <div>
          <SearchBox
            id="fleetFuelling-searchBox"
            defaultValue={searchTerm}
            onSubmit={(term) => {
              let params = new URLSearchParams(history.location.search);
              term !== "" ? params.set('search', term) : params.delete('search')
              history.replace({ search: (params).toString() })
              dispatch(setSearchTerm(term))
            }}
          />
          <FilterComponent
            id="fleetFuelling-vehicleFilter"
            defaultOption={
              {
                label: vehicleTypes.find(ct => ct.id.toString() === filter)?.name as string,
                value: filter
              }
            }
            options={vehicleTypes.map(ct => {
              return {
                label: ct.name,
                value: ct.id.toString()
              }
            })}
            icon={<Map />}
            onChange={(e) => {
              let params = new URLSearchParams(history.location.search);
              e.value !== "" ? params.set('vehicleType', e.value) : params.delete('vehicleType')
              history.replace({ search: (params).toString() })
              dispatch(setFilter(e.value))
            }}
            filterName={t('fleet.vehicleType')} />
          <FilterComponent
            id="maintenances-vehicleFilter"
            defaultOption={
              {
                label: fleetLongList.find(ct => ct.id.toString() === vehicle)?.name as string,
                value: vehicle ? vehicle : ""
              }
            }
            options={fleetLongList.map(ct => {
              return {
                label: ct.name,
                value: ct.id.toString()
              }
            })}
            icon={<DirectionsBoat />}
            onChange={(e) => {
              let params = new URLSearchParams(history.location.search);
              e.value !== "" ? params.set('vehicle', e.value) : params.delete('vehicle')
              history.replace({ search: (params).toString() })
              dispatch(setVehicle(e.value))
            }}
            filterName={t('fleet.vehicle')} 
          />
          
          <FilterComponent
            id="maintenances-fuelStationFilter"
            defaultOption={
              {
                label: fuelLocationsList.find(ct => ct.id.toString() === fuelStation)?.name as string,
                value: fuelStation ? fuelStation : ""
              }
            }
            options={fuelLocationsList.map(ct => {
              return {
                label: ct.name,
                value: ct.id.toString()
              }
            })}
            icon={<DirectionsBoat />}
            onChange={(e) => {
              let params = new URLSearchParams(history.location.search);
              e.value !== "" ? params.set('fuelStation', e.value) : params.delete('fuelStation')
              history.replace({ search: (params).toString() })
              dispatch(setFuelStation(e.value))
            }}
            filterName={t('fleet.local')} 
          />

          <FilterDatePicker
            presets={presetsLists().timeRecord}
            filterName={i18n.t('common.today')}
            showMonthYearDropdown
            reRender={hasDateParams}
            range
            defaultValue={
              {
                startDay: startDate ? DateTime.fromISO(startDate).toJSDate() : new Date(),
                endDay: endDate ? DateTime.fromISO(endDate).toJSDate() : new Date(),
              }
            }
            onChange={(e) => {
              if (e.startDate && e.endDate) {
                dispatch(setDatesInterval({ e, history }))
                dispatch(getFuellingsList())
                dispatch(getConsumptionsList())
              }
            }}
          />
        </div>
        <div>
          {
            hasPermission(permissions, PERMISSIONS.FLEET.CREATE_FUEL_TO_VEHICLE) && <CustomButton id="fleetFuelling-newButton" onClick={() => dispatch(navigateTo('/frota/combustivel/novo-abastecimento'))} text={t('common.new')} icon="add" color="success" buttonStyle={{ color: "#FFFFFF" }} />
          }
        </div>
      </ControlsContainer>
      {
        loading ?
          <Loadingcontainer />
          :
          <>
            <FuelLevelComponent
              // tanks={ fuellingsList.filter(item => item.tank && item.tank !== null)}
              tanks={tanks}
              unit="Litres"
            />
            <TabsContainer
              onChangeTab={() => { dispatch(setSort({ direction: null, field: null })) }}
              content={[
                {
                  title: t('fuel.dailyConsumption'),
                  content: <ConsumptionTable
                    count={paginationElementsCount(consumptionsTotal)}
                    currentPage={paginationCurrentPage(consumptionsTotal, consumptionsPage)}
                    elementsPerPage={ELEMENTS_PER_PAGE}
                    onPageChange={pg => {
                      dispatch(setConsumptionsPage(pg));
                    }}
                    headerElements={[
                      <SorterComponent sort={sort} active={sort.field === "vehicle"} id="fleetFuelling-consumptions-vehicleSorter" filterName={t('fuel.vehicle')} onChange={(dir) => { dispatch(setSort({ field: "vehicle", direction: dir })) }} />,
                      t('common.name'),
                      t('fuel.usedLitres'),
                      t('fuel.tank'),
                      <SorterComponent sort={sort} active={sort.field === "created_at_after"} id="fleetFuelling-fuellings-date" filterName={t('fuel.date')} onChange={(dir) => { dispatch(setSort({ field: "created_at_after", direction: dir })) }} />,
                      t('common.actions'),
                    ]}
                  >
                    {
                      consumptionsList.length > 0 && consumptionsList.map((el: FuelConsumptionObject, idx: number) =>
                        <React.Fragment key={`colab-list-${idx}`}>
                          <Consumptionlisttablerow
                            id={`fleetFuelling-consumptionTableRow-${idx}`}
                            onClick={() => { }}
                            consumption={el}
                            actions={
                              <React.Fragment>
                                <Feed />
                              </React.Fragment>
                            }
                          />
                        </React.Fragment>
                      )}
                  </ConsumptionTable>
                },
                {
                  title: t('fuel.fuellings'),
                  content: <FuellingsTable
                    count={paginationElementsCount(fuellingsTotal)}
                    currentPage={paginationCurrentPage(fuellingsTotal, fuellingsPage)}
                    elementsPerPage={ELEMENTS_PER_PAGE}
                    onPageChange={pg => {
                      dispatch(setFuellingsPage(pg));
                    }}
                    headerElements={[
                      <SorterComponent sort={sort} active={sort.field === "vehicle"} id="fleetFuelling-fuellings-vehicleSorter" filterName={t('fuel.vehicle')} onChange={(dir) => { dispatch(setSort({ field: "vehicle", direction: dir })) }} />,
                      t('common.name'),
                      <SorterComponent sort={sort} active={sort.field === "litres"} id="fleetFuelling-fuellings-litresSorter" filterName={t('fuel.litres')} onChange={(dir) => { dispatch(setSort({ field: "litres", direction: dir })) }} />,
                      t('fuel.location'),
                      <SorterComponent sort={sort} active={sort.field === "fuel_date"} id="fleetFuelling-fuellings-date" filterName={t('fuel.date')} onChange={(dir) => { dispatch(setSort({ field: "fuel_date", direction: dir })) }} />,
                      t('fuel.tank'),
                      t('common.actions'),
                    ]}
                  >
                    {
                      fuellingsList.length > 0 && fuellingsList.map((el: FuellingListItem, idx: number) =>
                        <React.Fragment key={`colab-list-${idx}`}>
                          <FuellingListTableRow
                            id={`fleetFuelling-fuellingsTableRow-${idx}`}
                            permission={hasPermission(permissions, PERMISSIONS.FLEET.VIEW_FUEL)}
                            onClick={() => {
                              if (hasPermission(permissions, PERMISSIONS.FLEET.VIEW_FUEL)) {
                                dispatch(navigateTo(`combustivel/editar-abastecimento/${el.id}`))
                              }
                            }}
                            refuelling={el}
                            actions={
                              <React.Fragment>
                                <Feed />
                              </React.Fragment>
                            }
                          />
                        </React.Fragment>
                      )}
                  </FuellingsTable>
                }
              ]}
            />
          </>
      }
    </Wrapper>
  )
}

export default Fleetfuelling