/**
 * Fuellocationconfigform scene
 *
 * @author João Dias <jdias@ubiwhere.com>
 *
 *
 */
import React, { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { actions, RootState } from 'store/rootSlices'
import CustomCheckbox from 'shared/components/CustomCheckbox'
import { CCol, CForm, CRow } from '@coreui/react-pro'
import SectionContainer from 'shared/components/Sectioncontainer'
import CustomTextInput from 'shared/components/CustomTextInput'
import Loadingcontainer from 'shared/components/Loadingcontainer'
import CustomButton from 'shared/components/CustomButton'
import theme from 'ui/theme'
import { Wrapper, FuelLocationCheckbox, CheckboxContainer, Row, ButtonWrapper } from './styles'

const Fuellocationconfigform = ({ t, i18n }) => {

  const { fuelLocationForm, loading, hasEditPermission, submitButtonState } = useSelector((state: RootState) => state.Fuellocationconfigform)

  const { onMount, onUnmount, onFormChange, onSubmit } = actions.Fuellocationconfigform
  const { navigateBack } = actions.App

  const dispatch = useDispatch()
  const { id } = useParams()

  useEffect(() => {

    dispatch(onMount(id))
    return () => {
      dispatch(onUnmount())
    }
  }, [dispatch, onMount, onUnmount, id]);

  if (loading) {
    return <Wrapper>
      <Loadingcontainer />
    </Wrapper>
  }

  return (
    <Wrapper>
      <CustomCheckbox
        id='fuelLocationConfigForm-isActive'
        disabled={!hasEditPermission}
        wrapperStyle={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '-25px', fontSize: '20px' }}
        checked={fuelLocationForm.isActive.value ? true : false}
        onClick={e => dispatch(onFormChange({ ...fuelLocationForm, isActive: { value: !fuelLocationForm.isActive.value, valid: true, message: "" } }))}
        text={i18n.t('tripServiceForm.isActive')}
        switch
      />
      <CForm>
        <div style={{ width: '100%', paddingBottom: '24px', paddingTop: '24px' }}>
          <SectionContainer title={t('documentConfigs.title1')} style={{ width: '100%', paddingBottom: '24px' }}>
            <CRow className="g-3">
              <CCol>
                <CustomTextInput
                  id='fuelLocationConfigForm-name'
                  disabled={!hasEditPermission}
                  value={fuelLocationForm.name.value || ''}
                  valid={fuelLocationForm.name.valid}
                  helpText={fuelLocationForm.name.message}
                  required
                  label={i18n.t('fuelLocationConfigs.name')}
                  placeholder={i18n.t('placeholders.name')}
                  onChange={(e) => dispatch(onFormChange({ ...fuelLocationForm, name: { value: e.target.value, valid: true, message: "" } }))}
                />
              </CCol>
            </CRow>
            <Row className="g-3">
              <CCol>
                <CustomTextInput
                  id='fuelLocationConfigForm-address'
                  disabled={!hasEditPermission}
                  required
                  value={fuelLocationForm.address.value || ''}
                  valid={fuelLocationForm.address.valid}
                  helpText={fuelLocationForm.address.message}
                  label={i18n.t('fuelLocationConfigs.address')}
                  placeholder={i18n.t('placeholders.address')}
                  onChange={(e) => dispatch(onFormChange({ ...fuelLocationForm, address: { value: e.target.value, valid: true, message: "" } }))}
                />
              </CCol>
              <CCol>
                <CustomTextInput
                  id='fuelLocationConfigForm-city'
                  disabled={!hasEditPermission}
                  required
                  value={fuelLocationForm.city.value || ''}
                  valid={fuelLocationForm.city.valid}
                  helpText={fuelLocationForm.city.message}
                  label={i18n.t('fuelLocationConfigs.city')}
                  placeholder={i18n.t('placeholders.city')}
                  onChange={(e) => dispatch(onFormChange({ ...fuelLocationForm, city: { value: e.target.value, valid: true, message: "" } }))}
                />
              </CCol>
            </Row>
          </SectionContainer>
        </div>
        <SectionContainer title={t('fuelLocationConfigs.title2')}>
          <CheckboxContainer>
            <FuelLocationCheckbox
              id='fuelLocationConfigForm-isTank'
              disabled={!hasEditPermission}
              checked={fuelLocationForm.isTank.value ? true : false}
              onClick={e => dispatch(onFormChange({ ...fuelLocationForm, isTank: { value: !fuelLocationForm.isTank.value, valid: true, message: "" } }))}
              text={i18n.t('fuelLocationConfigs.isTank')}
            />
          </CheckboxContainer>
          <CRow>
            <CCol md={4}>
              <CustomTextInput
                id='fuelLocationConfigForm-tankCapacity'
                value={(fuelLocationForm.fuelCapacity.value && parseFloat(fuelLocationForm.fuelCapacity.value) > 0) ? fuelLocationForm.fuelCapacity.value : ''}
                label={i18n.t('fuelLocationConfigs.tankCapacity')}
                placeholder={i18n.t('placeholders.tankCapacity')}
                onChange={(e) => dispatch(onFormChange({ ...fuelLocationForm, fuelCapacity: { value: e.target.value, valid: true, message: "" } }))}
                disabled={!hasEditPermission || !fuelLocationForm.isTank.value}
              />
            </CCol>
            <CCol style={{ marginTop: "35px" }}>
              <FuelLocationCheckbox
                id='fuelLocationConfigForm-isMain'
                checked={fuelLocationForm.isMain.value ? true : false}
                onClick={e => dispatch(onFormChange({ ...fuelLocationForm, isMain: { value: !fuelLocationForm.isMain.value, valid: true, message: "" } }))}
                text={i18n.t('fuelLocationConfigs.isMain')}
                disabled={!hasEditPermission || !fuelLocationForm.isTank.value}
              />
            </CCol>
          </CRow>
        </SectionContainer>
        <ButtonWrapper>
          <CustomButton
            id='fuelLocationConfigForm-cancel'
            onClick={() => dispatch(navigateBack())}
            text={i18n.t('common.cancelBtn')}
            color="link"
            buttonStyle={{ color: theme().danger, textDecoration: 'unset' }} />
          <CustomButton
            id='fuelLocationConfigForm-save'
            disabled={submitButtonState}
            onClick={() => dispatch(onSubmit())}
            text={i18n.t('common.saveBtn')}
            icon="confirm"
            color="success"
            buttonStyle={{ color: "#FFFFFF" }} />
        </ButtonWrapper>
      </CForm>
    </Wrapper>
  )
}

export default Fuellocationconfigform