import styled from 'styled-components'


export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(27, 27, 27, 0.541);
  display: flex;
  align-items: center;
  z-index: 5;
`
export const OverlayImg = styled.img`
  display: block;
  max-width: 60%;
  max-height: 80%;
  margin: 60px auto;
  box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);
`

export const OverlayClose = styled.span`
  position: absolute;
  top: 50px;
  right: 10px;
  font-size: 30px;
  color: #ffffff;
  z-index: 10;
  cursor: pointer;
`

export const OverlayArrowLeft = styled.div`
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 6;
  cursor: pointer;
`

export const OverlayArrowRight = styled.div`
  display: flex;
  justify-content: space-between;
  position: absolute;
  right: 0;
  top: 50%;
  z-index: 6;
  cursor: pointer;
`