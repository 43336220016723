/**
 * userform sagas
 *
 * @author João Dias <jdias@ubiwhere.com>
 *
 *
 */

//import { Action } from 'redux'
import API from 'api'
import { PayloadAction } from '@reduxjs/toolkit';
import { takeLatest, putResolve, call, select } from 'redux-saga/effects'
import * as Check from 'validations';
import { 
  UserFormValidations, 
  UserFormValidationsEdit, 
  CredentialsFormValidations, 
} from '../utils'
import {
  SubmitSagaPayload,
  OnChangePasswordSaveSagaPayload,
  OnCreateUserAuthSagaPayload
} from 'types/collaborators/userForm'
import { activityCommissionForm, extraCommissionForm } from 'types/collaborators/collaborator'
//
import { actions, RootState } from 'store/rootSlices'
import { toast } from 'react-toastify';
import i18n from 'i18next';
import { defaultChangePasswordForm } from '../../Entitymemberform/utils';
import config from 'config';
import { hasPermission } from 'permissions'
const { PERMISSIONS } = config

//const userform = (state: RootState) => state.UserForm.userForm
//const haschangedpassword = (state: RootState) => state.UserForm.hasChangedPassword

function* onMountSaga({ payload }: PayloadAction<string>) {
  const { permissions } = yield select((state: RootState) => state.App);

  let { allActivities, allExtras } = yield select((state: RootState) => state.TripsConfiguration);
  if(allActivities?.length === 0 || allExtras?.length === 0) {
    allActivities = yield call(API.Activities.GetAllActivities)
    allExtras = yield call(API.Extras.GetAllExtras)
  }

  const defaultCommissions = yield call(API.Collaborators.GetDefaultCommissions)
  
  if (allActivities && allExtras) {
    yield putResolve(actions.userForm.setUserCommissions({ activities: allActivities, extras: allExtras }))
  }
  const activitiesCommissionForm: Array<activityCommissionForm> = yield select((state: RootState) => state.UserForm.activitiesCommissionForm);
  const extrasCommissionForm: Array<extraCommissionForm> = yield select((state: RootState) => state.UserForm.extrasCommissionForm);

  if(defaultCommissions) {
    yield putResolve(actions.userForm.populateDefaultCommissionsOnMount({ extrasCommissionForm, activitiesCommissionForm, extras: defaultCommissions.extras, activities: defaultCommissions.activities }))
  }

  if (payload) {
    const userID = payload

    try {
      //get collaborator from api
      const userInfo = yield call(API.Collaborators.GetCollaborator, userID)

      if (userInfo) {
        yield putResolve(actions.userForm.setUser(userInfo))
        yield putResolve(actions.userForm.getUserAuth(userInfo.uuid))
        yield putResolve(actions.userForm.setUserID(userID))
        yield putResolve(actions.userForm.setAction(true))
        yield putResolve(actions.userForm.setHasEditPermission(hasPermission(permissions, PERMISSIONS.USERS.EDIT_TEAM_MEMBER)))
        yield putResolve(actions.userForm.populateForm(userInfo))
        yield putResolve(actions.userForm.setLoading(false))
        yield putResolve(actions.userForm.populateUserCommissionsOnMount({ 
          extrasCommissionForm, 
          activitiesCommissionForm, 
          extras: userInfo.extraCommissions, 
          activities: userInfo.activityCommissions,
          activitiesCommissionDefault: userInfo.activitiesCommissionDefault,
          extrasCommissionDefault: userInfo.extrasCommissionDefault
        }))
      
      }
    } catch (error) {
      toast.error(i18n.t("toasts.getCollaboratorError"));
    } finally {
      yield putResolve(actions.userForm.setLoading(false));
    }

  } else {
    // creating a new collaborator
    yield putResolve(actions.userForm.setAction(false))
    yield putResolve(actions.userForm.setLoading(false))
  }
}

function* getUserAuthSaga({ payload }: PayloadAction<string>) {
  try {
    const userAuthInfo = yield call(API.Auth.GetUser, { uuid: payload, userType: 'team_member'})

    if (userAuthInfo.username !== null) {
      yield putResolve(actions.userForm.setUserAuthExists(true))
    }
    else {
      yield putResolve(actions.userForm.setUserAuthExists(false))
    }

  } catch (error) {
    yield putResolve(actions.userForm.setUserAuthExists(false))
  }
}

function* onSubmitSaga({ payload }: PayloadAction<SubmitSagaPayload>) {

  yield putResolve(actions.userForm.setSubmitButtonDisabled(true))
  const activitiesCommissionForm: Array<activityCommissionForm> = yield select((state: RootState) => state.UserForm.activitiesCommissionForm);
  const extrasCommissionForm: Array<extraCommissionForm> = yield select((state: RootState) => state.UserForm.extrasCommissionForm);

  const activitiesCommissionsValidation = Check.checkCommissionsValidation(activitiesCommissionForm)
  const extrasCommissionsValidation = Check.checkCommissionsValidation(extrasCommissionForm)
  const validation = payload.edit === true ?
    Check.checkValidationAlternative(payload.userForm, UserFormValidationsEdit)
    :
    Check.checkValidationAlternative(payload.userForm, UserFormValidations)

  if (validation.invalid || activitiesCommissionsValidation.invalid || extrasCommissionsValidation.invalid) {
    yield putResolve(actions.userForm.updateValidationsOnEntityCommissions({ activities: activitiesCommissionsValidation.form, extras: extrasCommissionsValidation.form }))
    const updateValidationMessages = Check.setAndCheckValidationAlternative(payload.userForm, payload.userForm, UserFormValidations)
    yield putResolve(actions.userForm.onFormChange(updateValidationMessages))
    toast.error(i18n.t("toasts.invalidFields"));

    if (!payload.edit) {
      payload.termsAccepted ? yield putResolve(actions.userForm.setErrorTerms("")) : yield putResolve(actions.userForm.setErrorTerms(i18n.t("userForm.errorTermsAndConditions")))
    }

    yield putResolve(actions.userForm.setSubmitButtonDisabled(false))

  }
  else {
    const activeActivitiyCommissions = activitiesCommissionForm.filter(act => act.isCustom)
    const activeExtrasCommissions = extrasCommissionForm.filter(ext => ext.isCustom)
    let finalForm = {}
    
    // avatar is sent separately; group field is an array 
    Object.keys(payload.userForm).forEach(key => {
      if (key === 'groups') {
        finalForm = {
          ...finalForm,
          [key]: payload.userForm[key].value.map(v => v.value)
        }
      }
      else if (payload.userForm[key].value !== null && payload.userForm[key].value !== '' && key !== "avatar") {
        finalForm = {
          ...finalForm,
          [key]: payload.userForm[key].value
        }
      }
    })

    finalForm = {
      ...finalForm,
      activitiesCommissionDefault:  Number.parseFloat(payload.userForm.activitiesCommissionDefault.value).toFixed(2),
      extrasCommissionDefault: Number.parseFloat(payload.userForm.extrasCommissionDefault.value).toFixed(2),
      activityCommissions: activeActivitiyCommissions.map((act: activityCommissionForm) => {
        return {
          activity: act.uuid,
          amount: Number.parseFloat(act.commission.value).toFixed(2)
        }
      }),
      extraCommissions: activeExtrasCommissions.map((act: activityCommissionForm) => {
        return {
          extra: act.uuid,
          amount: Number.parseFloat(act.commission.value).toFixed(2)
        }
      })
    }
    
    
    if (!payload.edit) {
      if (payload.termsAccepted) {
        try {
          const colab = yield call(API.Collaborators.CreateCollaborator, finalForm)

          if (colab) {
            yield sendAvatar(colab.uuid)
            yield putResolve(actions.App.navigateTo('/colaboradores/utilizadores'))
            toast.success(i18n.t("toasts.collaboratorCreateSuccess"));
          }

        } catch (error) {
          const err: any = error
          if(err.response.status === 400) {
      
            if(err.response?.data?.errors?.fields?.email === 'not_unique'){
              toast.error(i18n.t("toasts.duplicateEmailUser"))
            } else {
              toast.error(i18n.t("toasts.collaboratorCreateError"));
            }      
          }
          if(err.response.status === 500) {
            toast.error(i18n.t("toasts.collaboratorCreateError"));
          }
        }
      }
      else {
        yield putResolve(actions.userForm.setErrorTerms(i18n.t("userForm.errorTermsAndConditions")))
      }
    }
    else {
      const { uuid } = yield select((state: RootState) => state.UserForm);

      try {

        const success = yield call(API.Collaborators.EditCollaborator, uuid, finalForm)

        if (success) {
          yield sendAvatar(uuid)
          yield putResolve(actions.App.navigateTo('/colaboradores/utilizadores'))
          toast.success(i18n.t("toasts.collaboratorEditSuccess"))
        }
      } catch (error) {
        const err: any = error
        if(err.response.status === 400) {
    
          if(err.response?.data?.errors?.fields?.email === 'not_unique'){
            toast.error(i18n.t("toasts.duplicateEmailUser"))
          } else {
            toast.warning(i18n.t("toasts.collaboratorEditError"))
          }      
        }
        if(err.response.status === 500) {
          toast.warning(i18n.t("toasts.collaboratorEditError"))
        }
      }

    }
    yield putResolve(actions.userForm.setSubmitButtonDisabled(false))
  }
}

function* sendAvatar(payload: PayloadAction<string>) {

  const { userForm, isAvatarChanged, isAvatarRemoved } = yield select((state: RootState) => state.UserForm)
  const { user } = yield select((state: RootState) => state.App)

  try {
    let finalForm = new FormData()
    if (isAvatarRemoved) {
      finalForm.append("avatar", "")
    }
    else if (isAvatarChanged) {
      finalForm.append("avatar", userForm.avatar.value)
    }
    else {
      return false;
    }
    const resAvatar = yield call(API.Collaborators.SendAvatar, payload, finalForm)

    if (resAvatar) {
      if (user.uuid === resAvatar.uuid) {
        yield putResolve(actions.App.setUserInfo({
          avatar: resAvatar.avatar,
          profile: resAvatar.role.name,
          name: resAvatar.name
        }))
      }

    }

  } catch (error) {

  }

}

function* onChangePasswordSaveSaga({ payload }: PayloadAction<OnChangePasswordSaveSagaPayload>) {

  const { uuid, user } = yield select((state: RootState) => state.UserForm);
  const newPassword = payload.changePasswordForm.newPassword.value
  const confirmPassword = payload.changePasswordForm.confirmPassword.value


  //object for validation
  const accessData = {
    confirmPassword: payload.changePasswordForm.confirmPassword,
    newPassword: payload.changePasswordForm.newPassword,
  }

  //validate form
  const validation = Check.checkValidation(accessData, CredentialsFormValidations)
  if (validation.invalid) {
    yield putResolve(actions.userForm.onChangePasswordFormChange({ 
      newPassword: validation.form.newPassword,
      confirmPassword: validation.form.confirmPassword
    }))
    toast.error(i18n.t("toasts.invalidFields"))
    return false;
  }

  if(newPassword !== confirmPassword) {
    return toast.error(i18n.t("toasts.passwordsDoNotMatch"))
  }

  yield putResolve(actions.userForm.showChangePasswordModal(false))

  if (confirmPassword !== "") {
    yield putResolve(actions.userForm.createUserAuth({
      password: confirmPassword,
      userType: "team_member",
      role: user.role.uuid,
      uuid: uuid
    }))
  }
}

function* onReSendEmailSaga() {
  const { userForm } = yield select((state: RootState) => state.UserForm);

  try {
    const status = yield call(API.Auth.SendEmail, {
      email: userForm.email.value,
      userType: 'team_member'
    })

    if (status) {
      toast.info(i18n.t("toasts.emailSent"))
    }
  } catch (error) {
    toast.warning(i18n.t("toasts.errorSendingEmail"));
  }

}

function* onCreateUserAuthSaga({ payload }: PayloadAction<OnCreateUserAuthSagaPayload>) {
  try {
    const res = yield call(API.Auth.CreateUser, payload)

    if (res) {
      toast.success(i18n.t("toasts.credentialsCreated"))
      yield putResolve(actions.userForm.setUserAuthExists(true))
      yield putResolve(actions.userForm.onChangePasswordFormChange(defaultChangePasswordForm))
    }
  } catch (error) {
    toast.error(i18n.t("toasts.credentialsCreationError"));
  }
}


function* onDeleteCollaboratorSaga({ payload }: PayloadAction<string>) {

  try {
    //yield call(Delete user and Collaborator)
    const del = yield call(API.Collaborators.DeleteCollaborator, payload)

    if (del.status === 200) {
      yield putResolve(actions.App.navigateTo('/colaboradores/utilizadores'))
      toast.info(del.status)
    }
  } catch (error) {
    toast.warning(i18n.t("toasts.collaboratorDeleteError"));
  }

}


export default function* watcherSignin() {
  yield takeLatest('collaboratorsform/onMount', onMountSaga)
  yield takeLatest('collaboratorsform/getUserAuth', getUserAuthSaga)
  yield takeLatest('collaboratorsform/onDeleteConfirm', onDeleteCollaboratorSaga)
  yield takeLatest('collaboratorsform/onSubmit', onSubmitSaga)
  yield takeLatest('collaboratorsform/onChangePasswordSave', onChangePasswordSaveSaga)
  yield takeLatest('collaboratorsform/onReSendEmail', onReSendEmailSaga)
  yield takeLatest('collaboratorsform/createUserAuth', onCreateUserAuthSaga)
}


