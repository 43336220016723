import * as Check from 'validations'
import { LocalForm, LocalPhotoForm } from 'types/trips/locals'

export const LocalFormValidations = {
    description: [],
    name: [Check.isRequired],
    shortName: [Check.isRequired],
    address: [],
    postalCode: [],
    country: [Check.isRequired],
    city: [Check.isRequired],
    phone: [],
    timeBefore: [Check.isRequired],
    latitude: [Check.isRequired, Check.isNumber],
    longitude: [Check.isRequired, Check.isNumber],
    // isAvailable: [Check.isRequired],
    isActive: [],
    isIsland: [],
    isBeach: [],
}

export const LocalFormValidationsEdit = {
    description: [],
    name: [Check.isRequired],
    shortName: [Check.isRequired],
    address: [],
    postalCode: [],
    country: [Check.isRequired],
    city: [Check.isRequired],
    phone: [],
    timeBefore: [Check.isRequired],
    latitude: [Check.isRequired, Check.isNumber],
    longitude: [Check.isRequired, Check.isNumber],
    // isAvailable: [Check.isRequired],
    isActive: [],
    isBeach: [],
}

export type SubmitSagaPayload = {
    e?: any,
    edit: boolean,
    localForm: LocalForm,
    photos: LocalPhotoForm[]
}

export type PhotosSagasPayload = {
    photos: LocalPhotoForm[],
    stop: number
}

export type DeletePhotosSagasPayload = {
    photos: LocalPhotoForm[],
    e?: any,
    index: number,
    localID: string,
    newPhotosID: number
}