import { DateTime, Duration } from 'luxon';
import initialState from "scenes/BookingForm/logic/initialState"
import type { InitialStateProps } from "scenes/BookingForm/logic/initialState"
// Utils
import { getBookedFeeEntries, getCanceledEntries, getPaidEntriesTotal, ifHopOnHopOffAddFee } from './utils';
import {
  addFeeEntrie,
  addEntrie,
  getEntriesTotalWithoutDiscount,
  updateEntriesIsRefund,
  updateEntriesTotal,
  getCorrectionValue,
  getTripTotalAmount,
  getPrivateTripTotalAmount,
  updateExtrasTypes,
  getBeachTripTotalAmount,
  addBeachEntrie,
  addNewPrivateOrNormalEntrie,
  getRescheduledLegTotalAmount,
  getLegTotalAmount
} from 'scenes/BookingForm/logic/utils'
import { updatePassengerTypes, removeInboundOrOutboundPrice } from '../../PassengerTypes/utils';
import config from 'config';
import { convertTimeStringToSeconds } from '../../../utils';
import { PayloadAction } from '@reduxjs/toolkit';
import { TripActivityLeg } from 'types/trips/bookings';

const { DATE_FORMAT } = config;


const BookingLegsReducers = {
  setPage: (state: InitialStateProps, { payload }) => {
    state.page = payload;
  },
  setSort: (state: InitialStateProps, { payload }) => {
    state.sort = payload;
  },
  setLegInboundView: (state: InitialStateProps, { payload }) => {
    state.directionFilter = payload ? 'inbound' : 'outbound';
    state.page = 1;

    if (payload) {
      if (state.outbound.tripActivityLeg.id > 0) {
        let outboundTime = DateTime.fromISO(state.outbound.tripActivityLeg.date).plus(
          Duration.fromISOTime(state.outbound.tripActivityLeg.time)
        );
        let now = DateTime.now();
        let limitChange = outboundTime.minus({
          hours: state.tripsCancelRescheduleSettings.timeLimit.hours,
          minutes: state.tripsCancelRescheduleSettings.timeLimit.minutes,
        });

        if (now < limitChange) {
          state.departureFilter = state.outbound.tripActivityLeg.arrival.id.toString();
          state.arrivalFilter = state.outbound.tripActivityLeg.departure.id.toString();
          state.startTimeFilter = state.outbound.tripActivityLeg.time;
          state.endTimeFilter = '23:59:59';
          state.dateFilter = DateTime.fromISO(state.outbound.tripActivityLeg.date).toJSDate();
        } else {
          state.departureFilter = '';
          state.arrivalFilter = '';
          state.startTimeFilter = '';
          state.endTimeFilter = '';
        }
      }
    } else {
      state.departureFilter = '';
      state.arrivalFilter = '';
      state.startTimeFilter = '';
      state.endTimeFilter = '';
    }

    state.legInboundView = payload;
  },
  setBeachView: (state: InitialStateProps, { payload }) => {
    state.directionFilter = 'beach'
    state.page = 1;
  },
  setActivityFilter: (state: InitialStateProps, { payload }) => {
    state.page = 1;
    state.activityFilter = payload;
  },
  setDepartureFilter: (state: InitialStateProps, { payload }) => {
    state.page = 1;
    state.departureFilter = payload;
  },
  setArrivalFilter: (state: InitialStateProps, { payload }) => {
    state.page = 1;
    state.arrivalFilter = payload;
  },
  setDateFilter: (state: InitialStateProps, { payload }) => {
    state.page = 1;
    state.dateFilter = payload.e;

    //if today, we need to limit the timeFilter
    let newDate = DateTime.fromJSDate(payload.e);
    let now = DateTime.now();
    let timeString = now.toFormat(DATE_FORMAT.TIME);
    if (
      newDate.hasSame(now, 'day') &&
      newDate.hasSame(now, 'month') &&
      newDate.hasSame(now, 'year')
    ) {
      state.hasTimeFilterChanged = false;
      state.minTimeFilter = convertTimeStringToSeconds(timeString);
      state.startTimeFilter = timeString;
      state.endTimeFilter = '23:59:59';
    } else {
      if (!payload.hasTimeFilterChanged) {
        state.minTimeFilter = convertTimeStringToSeconds('00:00:00');
        state.startTimeFilter = '00:00:00';
      }
    }
  },
  setBeachDateFilter: (state: InitialStateProps, { payload }) => {
    state.page = 1;
    state.dateFilter = payload.e;

    //if today, we need to limit the timeFilter
    let newDate = DateTime.fromJSDate(payload.e);
    let now = DateTime.now();
    let timeString = now.toFormat(DATE_FORMAT.TIME);
    if (
      newDate.hasSame(now, 'day') &&
      newDate.hasSame(now, 'month') &&
      newDate.hasSame(now, 'year')
    ) {
      state.hasTimeFilterChanged = false;
      state.minTimeFilter = convertTimeStringToSeconds(timeString);
      state.startTimeFilter = timeString;
      state.endTimeFilter = '23:59:59';
    } else {
      if (!payload.hasTimeFilterChanged) {
        state.minTimeFilter = convertTimeStringToSeconds('00:00:00');
        state.startTimeFilter = '00:00:00';
      }
    }
  },
  setTimeFilter: (state: InitialStateProps, { payload }) => {
    state.hasTimeFilterChanged = true;
    state.page = 1;
    state.startTimeFilter = payload.start;
    state.endTimeFilter = payload.end;
  },
  setRescheduleBeachTrip: (state: InitialStateProps, { payload }) => {
    let entries = state.entries

    //* Is the same leg
    if (payload.id === state.bookedBeachTrip.beachTripActivity?.id) {
      state.selectedBeachTrip = { ...state.bookedBeachTrip };

      //* Here we restore the outbound entries to the initial state
      entries = []

    } else {
      state.selectedBeachTrip = { ...initialState.selectedBeachTrip, beachTripActivity: payload, id: -1 };
    }

    state.selectedBeachExtras = updateExtrasTypes(
      state.selectedBeachTrip.beachTripActivity,
      state.selectedBeachExtras,
      false,
    );

    if (payload.id !== state.bookedOutbound.tripActivityLeg.id) {

      let hasPaidBeachEntries = state.entries.some((e) => e.leg?.beachTripActivity && e.state === 'paid' && !e.isFee)
      let hasPendingBeachEntries = state.entries.some((e) => e.leg?.beachTripActivity && e.state === 'pending')
      let amount: number = 0


      if (state.bookingState === 'paid') {
        if (hasPendingBeachEntries) {
          entries = entries.filter((e) => !(e.state === 'pending' && e.leg?.isOutbound)) //* Removed pending beach entries
        }

        if (hasPaidBeachEntries) { //* There was a booked outbound leg;

          //* Add outbound fee if necessary
          //entries = addFeeEntrie(true, false, entries, state.bookedOutbound, state.applyFeeToTrips, state.tripsCancelRescheduleSettings)

          amount = +getBeachTripTotalAmount(state.selectedBeachExtras).toFixed(2) - state.bookedBeachTrip.totalPrice

          if (amount !== 0) {
            //* Add an assetlement Outbound entrie
            entries.push(addBeachEntrie(false, true, state.selectedBeachTrip, amount))
          }
        }
      }
      else if (state.bookingState === 'to_pay' || state.bookingState === 'to_refund') {
        if (!hasPaidBeachEntries && hasPendingBeachEntries) {
          //* Here we remove the beach pending entrie
          entries = entries.filter((e) => !(e.leg?.beachTripActivity && e.state === 'pending'))
          //* Concat booked beach fee entries if there where any and add beahc fee if necessary
          //entries = entries.concat(getBookedFeeEntries(true, state.initialEntries))
          //entries = addFeeEntrie(true, false, entries, state.bookedOutbound, state.applyFeeToTrips, state.tripsCancelRescheduleSettings) 

          //we add a new beach entry
          entries.push(addBeachEntrie(false, false, state.selectedBeachTrip, +getBeachTripTotalAmount(state.selectedBeachExtras).toFixed(2)))
        }
        else if (hasPaidBeachEntries && !hasPendingBeachEntries) {
          //* Concat booked beach fee entries if there where any and add beahc fee if necessary
          //entries = entries.concat(getBookedFeeEntries(true, state.initialEntries))
          //entries = addFeeEntrie(true, false, entries, state.bookedOutbound, state.applyFeeToTrips, state.tripsCancelRescheduleSettings) 
          amount = +getBeachTripTotalAmount(state.selectedBeachExtras).toFixed(2) - state.bookedBeachTrip.totalPrice
          if (amount !== 0) {
            //* Add an assetlement Outbound entrie
            entries.push(addBeachEntrie(false, true, state.selectedBeachTrip, amount))
          }
        }
        else if (hasPaidBeachEntries && hasPendingBeachEntries) {
          entries = entries.filter((e) => !(e.leg?.beachTripActivity && e.state === 'pending')) //* Here we remove the outbound pending entrie
          //* Concat booked beach fee entries if there where any and add beahc fee if necessary
          //entries = entries.concat(getBookedFeeEntries(true, state.initialEntries))
          //entries = addFeeEntrie(true, false, entries, state.bookedOutbound, state.applyFeeToTrips, state.tripsCancelRescheduleSettings) 

          //* Checks if there are pending canceled entries
          let hasCanceledEntries = state.initialEntries.some((e) => e.leg?.beachTripActivity && !e.isFee && e.state === 'pending' && e.leg?.isCanceled)

          if (hasCanceledEntries) {
            //* If a canceled pending outbound entrie exists the amount will be the total amount instead of the difference
            entries = entries.concat(getCanceledEntries(false, state.initialEntries, true))
            amount = +getBeachTripTotalAmount(state.selectedBeachExtras).toFixed(2)
          } else {
            amount = +getBeachTripTotalAmount(state.selectedBeachExtras).toFixed(2) - getPaidEntriesTotal(false, entries, true)
          }

          if (amount !== 0) {
            //* Add an assetlement Outbound entrie
            entries.push(addBeachEntrie(false, true, state.selectedBeachTrip, amount))
          }
        }
        else if (!hasPaidBeachEntries && !hasPendingBeachEntries) {
          //* Add new beach entrie
          entries.push(addBeachEntrie(false, false, state.selectedBeachTrip, +getBeachTripTotalAmount(state.selectedBeachExtras).toFixed(2)))
        }

      }
    }

    state.beachToCancel = false
    state.entries = entries;
    state.paymentForm.correction.value = getCorrectionValue(state.entries, state.paymentForm, state.isFree);
    state.entriesTotal = Math.abs(+updateEntriesTotal(state.entries, state.paymentForm).toFixed(2));
    state.showPaymentForm = getEntriesTotalWithoutDiscount(state.entries) !== 0;
    const isRefund = updateEntriesIsRefund(+updateEntriesTotal(state.entries, state.paymentForm).toFixed(2));
    state.entriesIsRefund = isRefund
    state.paymentForm.isRefund = isRefund
  },
  setRescheduleOutbound: (state: InitialStateProps, { payload }) => {
    let isHopOnHopOff = payload.activity.isHopOnHopOff
    let entries = state.entries
    const bookedTotalPrice = +state.bookedOutbound.totalPrice

    //* Is the same leg
    if (payload.id === state.bookedOutbound.tripActivityLeg.id) {
      state.outbound = { ...state.bookedOutbound };

      //* Here we restore the outbound entries to the initial state
      let removedOutboundEntries = entries.filter((e) => !e.leg?.isOutbound)
      let initialOutboundEntries = state.initialEntries.filter((e) => e.leg?.isOutbound)
      entries = [...removedOutboundEntries, ...initialOutboundEntries]
    } else {
      state.outbound = { ...initialState.outbound, tripActivityLeg: payload, id: -1 };
    }

    if (isHopOnHopOff) {
      if (state.inbound.tripActivityLeg.id > 0) {
        entries = ifHopOnHopOffAddFee(entries, state.bookedInbound, state.applyFeeToTrips, state.tripsCancelRescheduleSettings, state.initialEntries)
      }
      state.unlockInbounds = false;
      state.inbound = initialState.inbound;
    } else {
      state.unlockInbounds = true;
    }

    //* Here we check if the legs in this trip allow over booking
    if (state.inbound.tripActivityLeg.id > 0) {
      state.allowOverbooking =
        state.inbound.tripActivityLeg.allowOverbooking &&
        state.outbound.tripActivityLeg.allowOverbooking;
    } else {
      state.allowOverbooking = state.outbound.tripActivityLeg.allowOverbooking;
    }

    state.selectedPassengerTypes = updatePassengerTypes(
      state.outbound.tripActivityLeg,
      state.inbound.tripActivityLeg,
      state.selectedPassengerTypes,
      state.allowOverbooking,
      state.bookedTotalPassengers
    );

    //* Check if the user selected a different outbound leg
    if (payload.id !== state.bookedOutbound.tripActivityLeg.id) {
      let hasPaidOutEntries = state.entries.some((e) => e.leg?.isOutbound && e.state === 'paid' && !e.isFee)
      let hasPendingOutEntries = state.entries.some((e) => e.leg?.isOutbound && e.state === 'pending')

      let amount: number = 0

      if (state.bookingState === 'paid') {

        //* If the booking state is paid and there are pending outbound entries we remove them;
        //* This means the user has already selected a different outbound leg before this one;
        if (hasPendingOutEntries) entries = entries.filter((e) => !(e.state === 'pending' && e.leg?.isOutbound)) //* Removed pending outbound entries

        if (hasPaidOutEntries) { //* There was a booked outbound leg;

          //* Add outbound fee if necessary
          entries = addFeeEntrie(true, false, entries, state.bookedOutbound, state.applyFeeToTrips, state.tripsCancelRescheduleSettings)

          //* If the new leg is private, calculate the amount in a different way
          amount = getRescheduledLegTotalAmount(state.outbound, state.selectedPassengerTypes, bookedTotalPrice)

          if (amount !== 0) {
            //* Add an assetlement Outbound entrie
            entries.push(addEntrie(false, true, state.outbound, amount))
          }

        } else { //* There was no booked outbound leg(Booking with only inbound);
          //* Add new outbound entrie
          addNewPrivateOrNormalEntrie(state.outbound, state.selectedPassengerTypes, entries)
        }
      }
      else if (state.bookingState === 'to_pay' || state.bookingState === 'to_refund') {
        if (!hasPaidOutEntries && hasPendingOutEntries) {
          entries = entries.filter((e) => !(e.leg?.isOutbound && e.state === 'pending')) //* Here we remove the outbound pending entrie

          //* Concat booked outbound fee entries if there where any
          entries = entries.concat(getBookedFeeEntries(true, state.initialEntries))

          //* Add outbound fee if necessary
          entries = addFeeEntrie(true, false, entries, state.bookedOutbound, state.applyFeeToTrips, state.tripsCancelRescheduleSettings)

          //* Add new outbound entrie
          addNewPrivateOrNormalEntrie(state.outbound, state.selectedPassengerTypes, entries)
        }
        else if (hasPaidOutEntries && !hasPendingOutEntries) {
          //* Add outbound fee if necessary
          entries = addFeeEntrie(true, false, entries, state.bookedOutbound, state.applyFeeToTrips, state.tripsCancelRescheduleSettings)

          //* If the new leg is private, calculate the amount in a different way
          amount =  getRescheduledLegTotalAmount(state.outbound, state.selectedPassengerTypes, bookedTotalPrice)
          
          if (amount !== 0) {
            //* Add an assetlement Outbound entrie
            entries.push(addEntrie(false, true, state.outbound, amount))
          }

        }
        else if (hasPaidOutEntries && hasPendingOutEntries) {
          entries = entries.filter((e) => !(e.leg?.isOutbound && e.state === 'pending')) //* Here we remove the outbound pending entrie

          //* Concat booked outbound fee entries if there where any
          entries = entries.concat(getBookedFeeEntries(true, state.initialEntries))

          //* Add outbound fee if necessary
          entries = addFeeEntrie(true, false, entries, state.bookedOutbound, state.applyFeeToTrips, state.tripsCancelRescheduleSettings)

          //* Checks if there are pending canceled entries
          let hasCanceledEntries = state.initialEntries.some((e) => e.leg?.isOutbound && !e.isFee && e.state === 'pending' && e.leg?.isCanceled)

          if (hasCanceledEntries) {
            //* If a canceled pending outbound entrie exists the amount will be the total amount instead of the difference
            entries = entries.concat(getCanceledEntries(true, state.initialEntries))
            amount = getLegTotalAmount(state.outbound, state.selectedPassengerTypes)
          } else {
            amount =  getRescheduledLegTotalAmount(state.outbound, state.selectedPassengerTypes, getPaidEntriesTotal(true, entries))
          }

          if (amount !== 0) {
            //* Add an assetlement Outbound entrie
            entries.push(addEntrie(false, true, state.outbound, amount))
          }
        }
        else if (!hasPaidOutEntries && !hasPendingOutEntries) {
          //* Add new outbound entrie
          addNewPrivateOrNormalEntrie(state.outbound, state.selectedPassengerTypes, entries)
        }
      }
    }

    state.outboundToCancel = false;
    state.entries = entries;
    state.paymentForm.correction.value = getCorrectionValue(state.entries, state.paymentForm, state.isFree);
    state.entriesTotal = Math.abs(
      +updateEntriesTotal(state.entries, state.paymentForm).toFixed(2)
    );
    state.showPaymentForm = getEntriesTotalWithoutDiscount(state.entries) !== 0;
    const isRefund = updateEntriesIsRefund(+updateEntriesTotal(state.entries, state.paymentForm).toFixed(2));
    state.entriesIsRefund = isRefund
    state.paymentForm.isRefund = isRefund
  },
  setRescheduleInbound: (state: InitialStateProps, { payload }) => {
    let entries = state.entries
    const bookedTotalPrice = +state.bookedInbound.totalPrice


    if (payload.id === state.bookedInbound.tripActivityLeg.id) {
      state.inbound = { ...state.bookedInbound };

      //* Here we restore the inbound entries to the initial state
      let removedInboundEntries = entries.filter((e) => e.leg?.isOutbound)
      let initialInboundEntries = state.initialEntries.filter((e) => !e.leg?.isOutbound)
      entries = [...removedInboundEntries, ...initialInboundEntries]
    } else {
      state.inbound = { ...initialState.inbound, tripActivityLeg: payload, id: -1 };
    }

    if (state.outbound.tripActivityLeg.id > 0) {
      state.allowOverbooking =
        state.outbound.tripActivityLeg.allowOverbooking &&
        state.inbound.tripActivityLeg.allowOverbooking;
    } else {
      state.allowOverbooking = state.inbound.tripActivityLeg.allowOverbooking;
    }

    state.selectedPassengerTypes = updatePassengerTypes(
      state.outbound.tripActivityLeg,
      state.inbound.tripActivityLeg,
      state.selectedPassengerTypes,
      state.allowOverbooking,
      state.bookedTotalPassengers
    );

    if (state.inbound.tripActivityLeg.id !== state.bookedInbound.tripActivityLeg.id || state.bookedOutbound.tripActivityLeg.activity.isHopOnHopOff) {
      let hasPaidInEntries = state.entries.some((e) => !e.leg?.isOutbound && e.state === 'paid' && !e.isFee)
      let hasPendingInEntries = state.entries.some((e) => !e.leg?.isOutbound && e.state === 'pending')

      let amount: number = 0

      if (state.bookingState === 'paid') {
        if (hasPendingInEntries) entries = entries.filter((e) => !(e.state === 'pending' && !e.leg?.isOutbound))

        if (hasPaidInEntries) { //* There was a booked inbound leg;

          //* Add inbond fee if necessary
          entries = addFeeEntrie(false, false, entries, state.bookedInbound, state.applyFeeToTrips, state.tripsCancelRescheduleSettings)

          //* If the new leg is private, calculate the amount in a different way
          amount = getRescheduledLegTotalAmount(state.inbound, state.selectedPassengerTypes, bookedTotalPrice)

          if (amount !== 0) {
            //* Add an assetlement inbound entrie
            entries.push(addEntrie(false, true, state.inbound, amount))
          }

        } else { //* There was no booked inbound leg so add a new inbound entrie;
          //* Add new inbound entrie
          addNewPrivateOrNormalEntrie(state.inbound, state.selectedPassengerTypes, entries)
        }
      }
      else if (state.bookingState === 'to_pay' || state.bookingState === 'to_refund') {
        if (!hasPaidInEntries && hasPendingInEntries) {
          entries = entries.filter((e) => !(!e.leg?.isOutbound && e.state === 'pending')) //* Here we remove the inbound pending entrie

          //* Concat booked inbound fee entries if there where any
          entries = entries.concat(getBookedFeeEntries(false, state.initialEntries))

          //* Add inbound fee if necessary
          entries = addFeeEntrie(false, false, entries, state.bookedInbound, state.applyFeeToTrips, state.tripsCancelRescheduleSettings)

          //* Add new inbound entrie
          addNewPrivateOrNormalEntrie(state.inbound, state.selectedPassengerTypes, entries)
        }
        else if (hasPaidInEntries && !hasPendingInEntries) {
          //* Add inbond fee if necessary
          entries = addFeeEntrie(false, false, entries, state.bookedInbound, state.applyFeeToTrips, state.tripsCancelRescheduleSettings)

          //* If the new leg is private, calculate the amount in a different way
          amount =  getRescheduledLegTotalAmount(state.inbound, state.selectedPassengerTypes, bookedTotalPrice)

          if (amount !== 0) {
            //* Add an assetlement inbound entrie
            entries.push(addEntrie(false, true, state.inbound, amount))
          }
        }
        else if (hasPaidInEntries && hasPendingInEntries) {
          entries = entries.filter((e) => !(!e.leg?.isOutbound && e.state === 'pending')) //* Here we remove the inbound pending entrie

          //* Concat booked inbound fee entries if there where any
          entries = entries.concat(getBookedFeeEntries(false, state.initialEntries))

          //* Add inbound fee if necessary
          entries = addFeeEntrie(false, false, entries, state.bookedInbound, state.applyFeeToTrips, state.tripsCancelRescheduleSettings)

          //* Checks if there are pending canceled entries
          let hasCanceledEntries = state.initialEntries.some((e) => !e.leg?.isOutbound && !e.isFee && e.state === 'pending' && e.leg?.isCanceled)

          if (hasCanceledEntries) {
            //* If a canceled pending inbound entrie exists the amount will be the total amount instead of the difference
            entries = entries.concat(getCanceledEntries(false, state.initialEntries))
            amount = getLegTotalAmount(state.inbound, state.selectedPassengerTypes)
          } else {
            amount =  getRescheduledLegTotalAmount(state.inbound, state.selectedPassengerTypes, getPaidEntriesTotal(false, entries))
          }

          if (amount !== 0) {
            //* Add an assetlement Outbound entrie
            entries.push(addEntrie(false, true, state.inbound, amount))
          }
        }
        else if (!hasPaidInEntries && !hasPendingInEntries) {
          //* Add new outbound entrie
          addNewPrivateOrNormalEntrie(state.inbound, state.selectedPassengerTypes, entries)
        }
      }
    }

    state.inboundToCancel = false;
    state.entries = entries;
    state.paymentForm.correction.value = getCorrectionValue(state.entries, state.paymentForm, state.isFree);
    state.entriesTotal = Math.abs(
      +updateEntriesTotal(state.entries, state.paymentForm).toFixed(2)
    );
    state.showPaymentForm = getEntriesTotalWithoutDiscount(state.entries) !== 0;
    const isRefund = updateEntriesIsRefund(+updateEntriesTotal(state.entries, state.paymentForm).toFixed(2));
    state.entriesIsRefund = isRefund
    state.paymentForm.isRefund = isRefund

  },
  setNewBeachTrip: (state: InitialStateProps, { payload }: PayloadAction<TripActivityLeg>) => {
    //clearing payment entries
    let entries = state.entries.filter(
      (e) => e.leg && e.leg.beachTripActivity && e.leg?.beachTripActivity.id !== state.selectedBeachTrip.beachTripActivity?.id
    );

    if (payload.id === state.selectedBeachTrip?.beachTripActivity?.id) {
      state.selectedBeachTrip = initialState.selectedBeachTrip

      //update entries after deselect
    }
    else {
      state.selectedBeachTrip = { ...state.selectedBeachTrip, beachTripActivity: payload }
    }

    //update extras table
    state.selectedBeachExtras = updateExtrasTypes(
      state.selectedBeachTrip.beachTripActivity,
      state.selectedBeachExtras,
      false,
    );

    //add entries
    entries.push(addBeachEntrie(false, false, state.selectedBeachTrip, +getBeachTripTotalAmount(state.selectedBeachExtras).toFixed(2)))

    state.entries = entries;
    state.paymentForm.correction.value = getCorrectionValue(state.entries, state.paymentForm, state.isFree);
    state.entriesTotal = +updateEntriesTotal(entries, state.paymentForm).toFixed(2);
    state.showPaymentForm = getEntriesTotalWithoutDiscount(state.entries) !== 0;
    state.entriesIsRefund = updateEntriesIsRefund(
      +updateEntriesTotal(state.entries, state.paymentForm).toFixed(2)
    );
    state.paymentForm.isRefund = updateEntriesIsRefund(state.entriesTotal);
  },
  setNewOutbound: (state: InitialStateProps, { payload }) => {
    //clearing payment entries
    let entries = state.entries.filter(
      (e) => e.leg && e.leg.tripActivityLeg && e.leg?.tripActivityLeg.id !== state.outbound?.tripActivityLeg.id
    );

    //deselecting
    if (payload.id === state.outbound.tripActivityLeg.id) {
      state.outbound = initialState.outbound;
      if (payload.activity.isHopOnHopOff) {
        state.unlockInbounds = true;
      }

      if (state.inbound.tripActivityLeg.id > 0) {
        state.allowOverbooking = state.inbound.tripActivityLeg.allowOverbooking;
      } else {
        state.allowOverbooking = false;
      }

      //* Here since the user deselected the outbound leg we need to remove the outbound price from the passengers
      const passengerTypes = state.selectedPassengerTypes;
      removeInboundOrOutboundPrice(state.selectedPassengerTypes, true)
      state.selectedPassengerTypes = passengerTypes;

      //update unpaid entries
      state.entries = [...entries];
      state.paymentForm.correction.value = getCorrectionValue(state.entries, state.paymentForm, state.isFree);
      state.entriesTotal = +updateEntriesTotal([...entries], state.paymentForm).toFixed(2);
      state.showPaymentForm = getEntriesTotalWithoutDiscount(state.entries) !== 0;
    } else {
      state.outbound = { ...state.outbound, tripActivityLeg: payload };

      if (!payload.activity.isHopOnHopOff) {
        state.unlockInbounds = true;
      } else {
        state.unlockInbounds = false;
        entries = entries.filter(
          (e) => e.leg?.tripActivityLeg.id !== state.inbound.tripActivityLeg.id
        );
        state.inbound = initialState.inbound;
      }

      if (state.inbound.tripActivityLeg.id > 0) {
        state.allowOverbooking =
          state.inbound.tripActivityLeg.allowOverbooking &&
          state.outbound.tripActivityLeg.allowOverbooking;
      } else {
        state.allowOverbooking = state.outbound.tripActivityLeg.allowOverbooking;
      }

      state.selectedPassengerTypes = updatePassengerTypes(
        state.outbound.tripActivityLeg,
        state.inbound.tripActivityLeg,
        state.selectedPassengerTypes,
        state.allowOverbooking,
        0
      );

      if(payload.activity.isPrivate) { 
        const passengersAmount: number = +getPrivateTripTotalAmount(state.selectedPassengerTypes, true, +payload.includedSeats)
        entries.push(addEntrie(false, false, state.outbound, +payload.minimumPrice + passengersAmount))
      } else {
        entries.push(addEntrie(false, false, state.outbound, +getTripTotalAmount(state.selectedPassengerTypes, 'outbound').toFixed(2)))
      }

      state.entries = entries;
      state.paymentForm.correction.value = getCorrectionValue(state.entries, state.paymentForm, state.isFree);
      state.entriesTotal = +updateEntriesTotal(entries, state.paymentForm).toFixed(2);
      state.showPaymentForm = getEntriesTotalWithoutDiscount(state.entries) !== 0;
      state.entriesIsRefund = updateEntriesIsRefund(
        +updateEntriesTotal(state.entries, state.paymentForm).toFixed(2)
      );
      state.paymentForm.isRefund = updateEntriesIsRefund(state.entriesTotal);
    }
  },
  setNewInbound: (state: InitialStateProps, { payload }) => {
    let entries = state.entries.filter(
      (e) => e.leg?.tripActivityLeg.id !== state.inbound.tripActivityLeg.id
    );

    //deselecting leg
    if (payload.id === state.inbound.tripActivityLeg.id) {
      state.inbound = initialState.inbound;

      //* Here since the user deselected the inbound leg we need to remove the inbound price from the passengers
      if (state.outbound.tripActivityLeg.id > 0) {
        state.allowOverbooking = state.outbound.tripActivityLeg.allowOverbooking;
      } else {
        state.allowOverbooking = false;
      }

      const passengerTypes = state.selectedPassengerTypes;
      removeInboundOrOutboundPrice(state.selectedPassengerTypes, false)
      state.selectedPassengerTypes = passengerTypes;

      //update unpaid entries
      state.entries = [...entries];
      state.paymentForm.correction.value = getCorrectionValue(state.entries, state.paymentForm, state.isFree);
      state.entriesTotal = +updateEntriesTotal([...entries], state.paymentForm).toFixed(2);
      state.showPaymentForm = getEntriesTotalWithoutDiscount(state.entries) !== 0;
    } else {
      state.inbound = { ...state.inbound, tripActivityLeg: payload };
      if (state.outbound.tripActivityLeg.id > 0) {
        state.allowOverbooking =
          state.outbound.tripActivityLeg.allowOverbooking &&
          state.inbound.tripActivityLeg.allowOverbooking;
      } else {
        state.allowOverbooking = state.inbound.tripActivityLeg.allowOverbooking;
      }

      state.selectedPassengerTypes = updatePassengerTypes(
        state.outbound.tripActivityLeg,
        state.inbound.tripActivityLeg,
        state.selectedPassengerTypes,
        state.allowOverbooking,
        0
      );

      if(payload.activity.isPrivate) { 
        const passengersAmount: number = +getPrivateTripTotalAmount(state.selectedPassengerTypes, true, +payload.includedSeats)
        entries.push(addEntrie(false, false, state.inbound, +payload.minimumPrice + passengersAmount))
      } else {
        entries.push(addEntrie(false, false, state.inbound, +getTripTotalAmount(state.selectedPassengerTypes, 'inbound').toFixed(2)))
      }

      state.entries = entries;
      state.paymentForm.correction.value = getCorrectionValue(state.entries, state.paymentForm, state.isFree);
      state.entriesTotal = +updateEntriesTotal(entries, state.paymentForm).toFixed(2);
      state.showPaymentForm = getEntriesTotalWithoutDiscount(state.entries) !== 0;
      state.entriesIsRefund = updateEntriesIsRefund(
        +updateEntriesTotal(state.entries, state.paymentForm).toFixed(2)
      );
      state.paymentForm.isRefund = updateEntriesIsRefund(state.entriesTotal);
    }
  },
  cancelLeg: (state: InitialStateProps, { payload }) => {
    if (payload.direction === 'outbound') {
      if (state.canCancelLegs) {
        //deleted all pending entries
        let entries = state.entries
          .filter((e) => e.leg?.id === state.outbound.id || e.leg?.id !== state.outbound.id)
          .map((e) => {
            if (e.leg?.id === state.outbound.id) {
              return {
                ...e,
                canceled: true,
              };
            }
            return e;
          });

        //* Remove all outbound legs created from changing the num of passengers
        entries = entries.filter((e) => !(e.isBecausePassenger && e.leg?.isOutbound))

        let legTotal = getLegTotalAmount(state.outbound, state.selectedPassengerTypes)
        if (entries.length) {
          entries.push(addEntrie(false, false, state.outbound, legTotal, true))
        }

        entries = addFeeEntrie(true, false, entries, state.outbound, state.applyFeeToTrips, state.tripsCancelRescheduleSettings, legTotal)

        state.entries = [...entries];
      }

      state.outboundToCancel = true;
      state.outbound = { ...state.outbound, isCanceled: true };
      state.entriesTotal = Math.abs(
        +updateEntriesTotal(state.entries, state.paymentForm).toFixed(2)
      );
      state.showPaymentForm = getEntriesTotalWithoutDiscount(state.entries) !== 0;
      state.entriesIsRefund = updateEntriesIsRefund(
        +updateEntriesTotal(state.entries, state.paymentForm).toFixed(2)
      );
      state.paymentForm.isRefund = updateEntriesIsRefund(
        +updateEntriesTotal(state.entries, state.paymentForm).toFixed(2)
      );
    } else if (payload.direction === 'inbound') {
      //deleted all pending entries
      let entries = state.entries
        .filter((e) => e.leg?.id === state.inbound.id || e.leg?.id !== state.inbound.id)
        .map((e) => {
          if (e.leg?.id === state.inbound.id) {
            return {
              ...e,
              canceled: true,
            };
          }
          return e;
        });

      //* Remove all inbound legs created from changing the num of passengers
      entries = entries.filter((e) => !(e.isBecausePassenger && !e.leg?.isOutbound))

      let legTotal = getLegTotalAmount(state.inbound, state.selectedPassengerTypes)
      if (entries.length) {
        entries.push(addEntrie(false, false, state.inbound, legTotal, true))
      }

      entries = addFeeEntrie(false, false, entries, state.inbound, state.applyFeeToTrips, state.tripsCancelRescheduleSettings, legTotal)

      state.entries = [...entries];
      state.inboundToCancel = true;
      state.inbound = { ...state.inbound, isCanceled: true };
      state.entriesTotal = Math.abs(
        +updateEntriesTotal(state.entries, state.paymentForm).toFixed(2)
      );
      state.showPaymentForm = getEntriesTotalWithoutDiscount(state.entries) !== 0;
      state.entriesIsRefund = updateEntriesIsRefund(
        +updateEntriesTotal(state.entries, state.paymentForm).toFixed(2)
      );
      state.paymentForm.isRefund = updateEntriesIsRefund(
        +updateEntriesTotal(state.entries, state.paymentForm).toFixed(2)
      );
    } else if (payload.direction === 'beach') {
      if (state.canCancelLegs) {
        //deleted all pending entries
        let entries = state.entries
          .filter((e) => e.leg?.id === state.selectedBeachTrip.id)
          .map((e) => {
            if (e.leg?.id === state.selectedBeachTrip.id) {
              return {
                ...e,
                canceled: true,
              };
            }
            return e;
          });
        //* Remove all outbound legs created from changing the num of passengers
        //TODO: entries = entries.filter((e) => !(e.isBecausePassenger && e.leg?.isOutbound))

        let tripTotal = +getBeachTripTotalAmount(state.selectedBeachExtras).toFixed(2);
        if (entries.length) {
          entries.push(addBeachEntrie(false, false, state.selectedBeachTrip, tripTotal, true))
        }
        //DOUBT: are there canceling fees for extras
        //entries = addFeeEntrie(true, false, entries, state.outbound, state.applyFeeToTrips, state.tripsCancelRescheduleSettings, tripTotal)

        state.entries = [...entries];
      }

      state.beachToCancel = true;
      state.selectedBeachTrip = { ...state.selectedBeachTrip, isCanceled: true };
      state.entriesTotal = Math.abs(
        +updateEntriesTotal(state.entries, state.paymentForm).toFixed(2)
      );
      state.showPaymentForm = getEntriesTotalWithoutDiscount(state.entries) !== 0;
      state.entriesIsRefund = updateEntriesIsRefund(
        +updateEntriesTotal(state.entries, state.paymentForm).toFixed(2)
      );
      state.paymentForm.isRefund = updateEntriesIsRefund(
        +updateEntriesTotal(state.entries, state.paymentForm).toFixed(2)
      );
    }
  },
}

export default BookingLegsReducers