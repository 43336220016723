/**
 * Activitylisttablerow component
 *
 * @author Manuel Gil <mgi@ubiwhere.com>
 *
 *
 */

import React from 'react'
import styled from 'styled-components'
import { actions as act } from 'store/rootSlices'
import { ActivityExtrasForm } from 'types/trips/activity/activityForm'
import { TableRowPropTypes } from 'types/misc'
import theme from 'ui/theme'
import { CTableDataCell, CTableRow } from '@coreui/react-pro'
import CustomTextInput from 'shared/components/CustomTextInput'
import CustomNumberInput from 'shared/components/CustomNumberInput'
import { useDispatch } from 'react-redux'
import { PlanningServiceForm } from 'types/trips/plannings'

type PropTypes = TableRowPropTypes & {
    serviceIdx: number,
    services: PlanningServiceForm[],
    id?: string,
    extra: ActivityExtrasForm
}

const PlanningExtraTableRow = (props: PropTypes) => {

    const { onChangeExtraMaxCapacity } = act.TripPlanningsForm

    const dispatch = useDispatch()

    const {
        actions,
        serviceIdx,
        services,
        extra: {
            id,
            name,
            isActive,
            maxCapacity,
            extraMaxCapacity,
            price,
            isOn
        }
    } = props

    return (
        <CustomCTableRow>
            <CTableDataCell>{name}</CTableDataCell>
            <CTableDataCell>
                <AvailabilityTableDataCell isavailable={isActive?.value}>{isActive?.value ? "Disponível" : "Indisponível"}</AvailabilityTableDataCell>
            </CTableDataCell>
            <CTableDataCell>
                {
                    <CustomNumberInput
                        id={`extra-${name}-maxCapacity`}
                        value={isOn.value ? maxCapacity?.value ?? "0" : "0"}
                        valid={maxCapacity ? maxCapacity.valid : true}
                        onChange={e => {
                            const newValue = e.target.value.trim().length ? parseInt(e.target.value, 10) : 0

                            dispatch(onChangeExtraMaxCapacity({
                                services,
                                serviceIdx,
                                extraId: id,
                                value: newValue > Number(extraMaxCapacity) ? Number(extraMaxCapacity) : newValue
                            }));
                        }}
                        onClickAdd={(e, numberInput) => {
                            dispatch(onChangeExtraMaxCapacity({
                                services,
                                serviceIdx,
                                extraId: id,
                                value: parseInt(numberInput.value, 10) + 1,
                            }));
                        }}
                        onClickSub={(e, numberInput) => {
                            dispatch(onChangeExtraMaxCapacity({
                                services,
                                serviceIdx,
                                extraId: id,
                                value: parseInt(numberInput.value, 10) - 1,
                            }));
                        }}
                        disabled={isActive.value === false || isOn.value === false /* || Number(maxCapacity.value) === 0 */}
                        min={0}
                        max={Number(extraMaxCapacity)}
                    />
                }
            </CTableDataCell>
            <CTableDataCell>
                <CustomTextInput
                    id={`${props.id}-price`}
                    required={false}
                    disabled={isOn?.value && isActive?.value /* && Number(maxCapacity?.value) > 0 */ ? false : true}
                    valid={price?.valid}
                    value={price?.value.toString()}
                    helpText={price?.message}
                    icon='euro'
                    placeholder="Adicione um preço"
                    onChange={(e) => props.onClick(e, 'price')}
                />
            </CTableDataCell>
            <ActionsCell>
                {actions}
            </ActionsCell>
        </CustomCTableRow>
    )
}
export default PlanningExtraTableRow

const CustomCTableRow = styled(CTableRow)`
    max-height: 54px;
    background-color: ${theme().white};
    border-bottom: 1px solid ${theme().gray200Color};
  
    > td {
      text-transform: capitalize;
      vertical-align: middle;
      font-family: Inter;
      font-size: 14px;
      color: ${theme().gray700Color}
    }

    td:first-child {
      padding-left:15px;
      width:30%;
    }

    td:nth-child(2){
        width:15%;
      }

    td:nth-child(3){
        width:15%;
    }

    td:nth-child(4){
      width:15%;
    }


  `

const AvailabilityTableDataCell = styled.p<{ isavailable: boolean }>`
     width:65%;
     margin-top:15px;
     background-color:${props => props.isavailable ? theme().success : theme().danger};
     color:white;
     font-weight:700;
     text-align:center;
     border-radius:5px;
 `

const ActionsCell = styled(CTableDataCell)`

  `
