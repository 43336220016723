import styled from 'styled-components'
import { CModal, CModalBody, CModalHeader, CModalTitle, CButton, CModalFooter, CFormTextarea } from '@coreui/react';
import theme, { devicesMax } from '../../../ui/theme';
import CIcon from '@coreui/icons-react';


export const TitleExtra = styled.span`
    margin-left: 5px;
`

export const CheckboxWrapper = styled.div`
    display:flex;
    padding-top:5px;
    width:85%;
    align-self:center;
`

export const Modal = styled(CModal) <{ width: number | undefined }>`

    .modal-content{
        width: ${props => props.width ? `${props.width}px` : '100%'};

        @media  ${devicesMax.tablet} {
            width: 100%;
        }
    }
`

export const ModalHeader = styled(CModalHeader) <{ warningtype: "warning" | "message" }>`
    background-color:${(props) => props.warningtype === "warning" ? theme().danger : theme().gray200Color};
    border-bottom:0px; 
    padding:12px;
`
export const ModalTitle = styled(CModalTitle) <{ warningtype: "warning" | "message" }> `
    color:${(props) => props.warningtype === "warning" ? theme().white : theme().darkColor};
    font-size:14px;
    padding:0px;
`
export const CIconClose = styled(CIcon) <{ warningtype: "warning" | "message" }>`
    color: ${(props) => props.warningtype === "warning" ? theme().white : theme().darkColor};
`
export const ModalBody = styled(CModalBody) <{ warningtype: "warning" | "message", textalign: "start" | "center" | "end" }> `
    overflow: overlay;
    display:flex;
    flex-direction:column;
    justify-content:space-evenly;
    text-align:${props => props.textalign};
    font-size:14px;
    padding:15px;
    color: ${(props) => props.warningtype === "warning" ? theme().danger : theme().darkColor};
    border-style:solid;
    border-width:0px 1px 0px 1px;
    border-color:${(props) => props.warningtype === "warning" ? theme().danger : theme().gray200Color};
`
export const FormFooter = styled.div<{ footerposition: "flex-start" | "center" | "flex-end" | "space-between" }>`
    display:flex;
    justify-content:${(props) => props.footerposition};
    padding-top:20px;
    padding-bottom:20px;
`
export const ModalFooter = styled(CModalFooter) <{ warningtype: "warning" | "message", footerposition: "flex-start" | "center" | "flex-end" | "space-between" }> `
    display:flex;
    flex-wrap: wrap;
    justify-content:${(props) => props.footerposition};
    padding-top:15px;
    padding-bottom:20px;
    border-style:solid;
    border-width:0px 1px 1px 1px;
    border-color:${(props) => props.warningtype === "warning" ? theme().danger : theme().gray200Color};
`
export const CancelButton = styled(CButton) <{ warningtype: "warning" | "message" }> `
    color: ${(props) => props.warningtype === "warning" ? theme().darkColor : theme().danger};
    border-color: ${(props) => props.warningtype === "warning" ? theme().gray200Color : theme().danger};
    background-color: ${(props) => props.warningtype === "warning" ? theme().gray200Color : theme().white};
    font-size:14px;
    box-shadow:0px 0px;
    &:hover {
        border-color:${(props) => props.warningtype === "warning" ? theme().darkColor : theme().danger};
        background-color: ${(props) => props.warningtype === "warning" ? theme().darkColor : theme().danger};
    }
    &:focus{
        box-shadow:0px 0px;
        border-color:${(props) => props.warningtype === "warning" ? theme().darkColor : theme().danger};
        background-color: ${(props) => props.warningtype === "warning" ? theme().darkColor : theme().danger};
    }
`
export const ConfirmButton = styled(CButton) <{ warningtype: "warning" | "message" }> `
    color:${theme().white};
    background-color: ${(props) => props.warningtype === "warning" ? theme().danger : '#2EB85C'};
    font-size:14px;
    box-shadow:0px 0px;
    &:hover {
        border-color:${(props) => props.warningtype === "warning" ? theme().danger : '#2EB85C'};
        background-color: ${(props) => props.warningtype === "warning" ? theme().danger : '#2EB85C'};
    }
    &:focus{
        box-shadow:0px 0px;
        border-color:${(props) => props.warningtype === "warning" ? theme().danger : '#2EB85C'};
        background-color: ${(props) => props.warningtype === "warning" ? theme().danger : '#2EB85C'};
    }
`

export const ConfirmButtonPrimary = styled(CButton) <{ buttonColor?: string }>`
    color: ${theme().white};
    font-size:14px;
    box-shadow:0px 0px;
`

export const TextArea = styled(CFormTextarea)`
    width: 85%;
    font-size: 14px;
    color: #d8dbe0;
    align-self: center;
`
export const FormWrapper = styled.div`
    padding-bottom: 10px;
`