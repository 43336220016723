
import React from 'react'
import SectionContainer from '../../shared/components/Sectioncontainer/index';
import CustomTextInput from '../../shared/components/CustomTextInput/index';
import FormComponent from '../../shared/components/FormComponents/index';
import { CCol, CRow } from '@coreui/react-pro';

import { actions, RootState } from 'store/rootSlices'
import { useSelector, useDispatch } from 'react-redux'
import i18n from 'i18next';
import _ from 'lodash'

const DadosBancarios = (props) => {
    const dispatch = useDispatch()
    const dbDispatch = _.debounce(dispatch, 300);

    const { onFormChange } = actions.userForm;
    const { userForm, hasEditPermission } = useSelector((state: RootState) => state.UserForm);

    return (
        <SectionContainer title={i18n.t('userForm.title7')} style={{ width: '100%', paddingBottom: '24px' }}>
            <CRow className="g-3">
                <CCol>
                    <FormComponent
                        id='userForm-bankNumber'
                        disabled={!hasEditPermission}
                        valid={userForm.bankNumber.valid}
                        value={userForm.bankNumber.value || ""}
                        helpText={userForm.bankNumber.message}
                        dropdown={false}
                        label={i18n.t('userForm.iban')}
                        placeholder="Portugal"
                        options={[{ label: "Portugal", value: "Portugal", prefix: "PT50" }]}  
                        onChange={(e) => dispatch(onFormChange({ ...userForm, bankNumber: {value: e, message:"", valid:true}}))}
                    />
                </CCol>
                <CCol>
                    <CustomTextInput
                        id='userForm-bankName'
                        disabled={!hasEditPermission}
                        valid={userForm.bankName.valid}
                        initialValue={userForm.bankName.value || ""}
                        helpText={userForm.bankName.message}
                        placeholder={i18n.t('placeholders.name')}
                        label={i18n.t('userForm.bank')}
                        onChange={(e) => dbDispatch(onFormChange({ ...userForm, bankName: {value: e.target.value, message:"", valid:true}}))}
                    />
                </CCol>
            </CRow>
        </SectionContainer>
    )
}

export default DadosBancarios