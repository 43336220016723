import { useSelector, useDispatch } from 'react-redux'
import React from 'react'
import { RootState, actions } from 'store/rootSlices';
// Styles
import { TableDataCellStops } from './styles'

interface HandleCheckinProps {
  location: number,
  isOutbound: boolean,
  hasCheckedin: boolean,
  isElegible: boolean,
  vehicle?: string,
  trip?: number,
  booking?: string,
  id?: number, 
  checkinId?: number | null,
  checkoutId?: number | null
}

type PropTypes = React.PropsWithChildren<{
  outbound: boolean;
  isElegible: boolean;
  reservation: any;
  stop: any;
  planningOutStops?: any,
  planningInStops?: any
}>

const StopsCell = ({ outbound, reservation, stop, planningOutStops, planningInStops, isElegible }: PropTypes) => {
  const dispatch = useDispatch()
  const { 
    modalLoading, 
    showCheckinModal,
    checkingIn,
  } = useSelector((state: RootState) => state.TripPlannings);
  const { setNewCheckin, setNewCheckLocation } = actions.TripPlannings

  const handleCheckin = (data: HandleCheckinProps) => {
    if(!data.hasCheckedin) {
      dispatch(setNewCheckin({
        location: data.location,
        outbound: data.isOutbound,
        vehicle: data.vehicle as string,
        trip: data.trip as number,
        booking: data.booking as string,
        isElegible: data.isElegible,
      }))
    } else {
      dispatch(setNewCheckLocation({
        id: data.id as number, 
        location: data.location,
        outbound: data.isOutbound,
        checkinId: data.checkinId as number | null,
        checkoutId: data.checkoutId as number | null
      }))
    }
  }

  const checkedIn = outbound ? reservation.outboundTrip.checkedIn : reservation.inboundTrip?.checkedIn
  const checkInInfo = checkedIn ? outbound ? reservation.outboundTrip.checkInDetails : reservation.inboundTrip?.checkInDetails : null
  const isActiveStop = outbound ? planningOutStops?.find((plStop) => plStop.stop.id === stop.id ? true : false) : planningInStops?.find((plStop) => plStop.stop.id === stop.id ? true : false) 

  return (
    <TableDataCellStops
      colSpan={1}
      start={checkedIn ? checkInInfo.stopIn?.stop.id === stop.id ? true : false : false}
      exit={checkedIn ? checkInInfo.stopOut?.stop.id === stop.id ? true : false : false}
      current={false}
      activ={isActiveStop}
      onClick={() => {
        if(isActiveStop && !checkingIn && !modalLoading) {
          handleCheckin({
            location: stop.id,
            isOutbound: outbound ? true : false,
            isElegible: isElegible ? true : false,
            hasCheckedin: checkedIn,
            vehicle: showCheckinModal.vehicle.vehicle.uuid,
            trip: showCheckinModal.planningId,
            booking: reservation.uuid,
            ...(!isElegible ? { id: checkInInfo?.id } : null),
            ...(!isElegible ? { checkinId: checkInInfo?.stopIn?.stop.id } : null),
            ...(!isElegible ? { checkoutId: checkInInfo?.stopOut?.stop.id ?? null } : null),
          })
        }
      }}
    >
      {stop.shortName}
    </TableDataCellStops>
  )
}

export default StopsCell