/**
 * Cashmanagement scene
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */
import React, { useEffect } from 'react'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { actions, RootState } from 'store/rootSlices'
import { useHistory } from 'react-router-dom'
import { presetsLists } from 'shared/components/FilterDatePicker/utils'
import { CBadge } from '@coreui/react-pro'
import { paginationCurrentPage, paginationElementsCount } from 'utils'
import { StatementsTab } from 'types/invoicing/statements'
import { DateTime } from 'luxon'
// Components
import FilterDatePicker from 'shared/components/FilterDatePicker'
import TabsContainer from 'shared/components/Tabscontainer'
import Loadingcontainer from 'shared/components/Loadingcontainer'
import TableContainer from 'shared/components/TableContainer'
import Cashmanagementtablerow from './components/Cashmanagementtablerow'
import CustomCheckbox from 'shared/components/CustomCheckbox'
import CustomButton from 'shared/components/CustomButton'
import Cashmanagementtotals from './components/Cashmanagementtotals'
import SearchBox from 'shared/components/SearchBox'
import FilterComponent from 'shared/components/FilterComponent'
import { CustomWrapper, ControlsContainer, Controls1, Controls2 } from 'shared/genericStyledComponents'
// Utils
import { hasPermission } from 'permissions'
import config from 'config'
// Icons
import People from 'assets/icons/people'
import Download from 'assets/icons/file_download';
// Styles
import theme from 'ui/theme'
import { ButtonWrapper, StatementsFilter, DownloadButton } from './styles';


const Cashmanagement = ({ t, i18n }) => {
  const {
    page,
    startDate,
    loading,
    showUsersWithNoMovements,
    tab,
    searchTerm,
    pendingListApprovalStatus,
    approvedListApprovalStatus,
    pendingCashManagementList,
    approvedCashManagementList,
    approvedStatements,
    disapprovedStatements,
    incomeTotal,
    expensesTotal,
    cashTotal,
    groupFilter,
    exportLoading
  } = useSelector((state: RootState) => state.CashManagement)

  const {
    teams
  } = useSelector((state: RootState) => state.CollaboratorsManagement)

  const {
    permissions
  } = useSelector((state: RootState) => state.App)

  const {
    onMount,
    onUnmount,
    setTab,
    setSearchTerm,
    setShowUsersWithNoMovements,
    changePendingListListStatusAll,
    changeApprovedListStatusAll,
    submitApprovedStatements,
    submitDisapprovedStatements,
    setStartDate,
    setGroupFilter,
    exportStatements,
  } = actions.CashManagement

  const { ELEMENTS_PER_PAGE, PERMISSIONS } = config
  const history = useHistory()
  const dispatch = useDispatch()
  const dbDispatch = _.debounce(dispatch, 500);

  useEffect(() => {

    dispatch(onMount(history))
    return () => {
      dispatch(onUnmount())
    }
  }, [dispatch, onMount, onUnmount, history]);

  return (
    <CustomWrapper>
      <ControlsContainer>
        <Controls1>
          <SearchBox
            id="cashManagement-searchBox"
            defaultValue={searchTerm}
            onSubmit={(term) => {
              let params = new URLSearchParams(history.location.search);
              term !== "" ? params.set('search', term) : params.delete('search')
              history.replace({ search: (params).toString() })
              dbDispatch(setSearchTerm(term))
            }} />

          <FilterDatePicker
            presets={presetsLists().invoicingSummary}
            filterName={i18n.t('common.today')}
            showMonthYearDropdown
            defaultValue={{ startDay: startDate ? startDate : new Date() }}
            onChange={(e) => {
              if (e.startDate) {
                let params = new URLSearchParams(history.location.search);
                e.startDate !== "" ? params.set('date', DateTime.fromJSDate(e.startDate).toISODate()) : params.delete('date')
                history.replace({ search: (params).toString() })

                dispatch(setStartDate(e.startDate))
              }
            }}
          />
          <FilterComponent
            id="collaboratorsManagement-groupFilter"
            icon={<People
              width={20}
              height={20}
              viewBox={"0 0 17 12"} />}
            defaultOption={
              {
                label: teams.find(ct => ct.uuid === groupFilter)?.name as string,
                value: groupFilter
              }
            }
            onChange={(e) => {
              let params = new URLSearchParams(history.location.search);
              e.value !== "" ? params.set('group', e.value) : params.delete('group')
              history.replace({ search: (params).toString() })
              dispatch(setGroupFilter(e.value))
            }}
            filterName={"Equipa"} options={
              teams.map(ct => {
                return {
                  label: ct.name,
                  value: ct.uuid
                }
              })
            } />
        </Controls1>
        <Controls2>
            <DownloadButton onClick={() => {
                if(!exportLoading) dispatch(exportStatements())
            }}>
                <Download width={30} height={30} />
            </DownloadButton>
        </Controls2>
      </ControlsContainer>

      <Cashmanagementtotals
        incomeTotal={incomeTotal}
        expensesTotal={expensesTotal}
        cashTotal={cashTotal}
        t={t}
      />
      <StatementsFilter>
        <CustomCheckbox
          id='cashManagement-showUsersWithNoMovements'
          disabled={false}
          wrapperStyle={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginBottom: '-55px', fontSize: '20px' }}
          checked={showUsersWithNoMovements}
          onClick={e => dispatch(setShowUsersWithNoMovements({ showUsersWithNoMovements: !showUsersWithNoMovements, pendingCashManagementList }))}
          text={i18n.t('cashManagement.showUsersWithNoMovements')}
          switch
        />
      </StatementsFilter>
      {
        loading && hasPermission(permissions, PERMISSIONS.INVOICING.CASH_MANAGEMENT.LIST_STATEMENT) ?
          <Loadingcontainer />
          :
          <TabsContainer
            id="cashManagement-tabsContainer"
            onChangeTab={tab => {
              dispatch(setTab(tab === t('cashManagement.approved') ? StatementsTab.APPROVED : StatementsTab.PENDING))
            }}
            content={[
              {
                title: t('cashManagement.requests'),
                badge: <CBadge color="info">{pendingCashManagementList.length}</CBadge>,
                content: <TableContainer
                  count={paginationElementsCount(0)}
                  currentPage={paginationCurrentPage(0, page)}
                  elementsPerPage={ELEMENTS_PER_PAGE}
                  onPageChange={(pg) => { /* dispatch(setPage(pg)) */ }}
                  headerElements={[
                    t('common.name'),
                    t('common.state'),
                    t('cashManagement.income'),
                    t('cashManagement.expenses'),
                    t('cashManagement.estimated'),
                    t('cashManagement.balance'),
                    <CustomCheckbox
                      checkStyle={{ marginTop: '5px' }}
                      id={`cashManagement-approveAll`}
                      disabled={false}
                      checked={pendingListApprovalStatus}
                      onClick={e => {
                        dispatch(changePendingListListStatusAll({ status: !pendingListApprovalStatus, pendingCashManagementList }))
                      }}
                    />,
                    '',
                  ]}
                >
                  {
                    pendingCashManagementList.map((cm, index) => {
                      return (
                        <Cashmanagementtablerow
                          id={1}
                          statement={cm}
                          permission={true}
                          onClick={() => { }}
                          actions={
                            <React.Fragment>
                            </React.Fragment>
                          } />
                      )
                    })
                  }
                </TableContainer>
              },
              {
                title: t('cashManagement.approved'),
                badge: <CBadge color="info">{approvedCashManagementList.length}</CBadge>,
                content: <TableContainer
                  count={paginationElementsCount(0)}
                  currentPage={paginationCurrentPage(0, page)}
                  elementsPerPage={ELEMENTS_PER_PAGE}
                  onPageChange={(pg) => { /* dispatch(setPage(pg)) */ }}
                  headerElements={[
                    t('common.name'),
                    t('common.state'),
                    t('cashManagement.income'),
                    t('cashManagement.expenses'),
                    t('cashManagement.estimated'),
                    t('cashManagement.balance'),
                    <CustomCheckbox
                      checkStyle={{ marginTop: '5px' }}
                      id={`cashManagement-disapproveAll`}
                      disabled={false}
                      checked={approvedListApprovalStatus}
                      onClick={e => {
                        dispatch(changeApprovedListStatusAll({ status: !approvedListApprovalStatus, approvedCashManagementList }))
                      }}
                    />,
                    '',
                  ]}
                >
                  {
                    approvedCashManagementList.map((cm, index) => {
                      return (
                        <Cashmanagementtablerow
                          id={1}
                          statement={cm}
                          permission={true}
                          onClick={() => { }}
                          actions={
                            <React.Fragment>
                            </React.Fragment>
                          } />
                      )
                    })
                  }
                </TableContainer>
              }
            ]} />

      }
      {hasPermission(permissions, PERMISSIONS.INVOICING.CASH_MANAGEMENT.APPROVE_STATEMENT) && <ButtonWrapper>
        <CustomButton
          id='cashManagement-discard'
          text={tab === StatementsTab.PENDING ?
            t('cashManagement.approve', { numChecks: approvedStatements.length })
            :
            t('cashManagement.discard', { numChecks: disapprovedStatements.length })
          }
          onClick={() => {
            tab === StatementsTab.PENDING ?
              dispatch(submitApprovedStatements())
              :
              dispatch(submitDisapprovedStatements())
          }}
          color={tab === StatementsTab.PENDING ?
            "success"
            :
            "danger"
          }
          buttonStyle={{ color: theme().white }}
        />
      </ButtonWrapper>}
    </CustomWrapper>
  )
}

export default Cashmanagement