/**
 * Entitymemberform scene slice
 *
 * @author João Dias <jdias@ubiwhere.com>
 *
 *
 */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { entityMemberValidations } from '../utils'
import { 
  ChangePasswordForm, 
  OnChangePasswordSaveSagaPayload ,
  OnCreateUserAuthSagaPayload} from 'types/entity/entityMemberForm'
import * as Check from "validations"
import { initialState } from './initialState'


export default createSlice({
  name: 'Entitymemberform',
  initialState,
  reducers: {
    onMount: (state, { payload }) => { },
    onUnmount: () => {
      return initialState
    },
    setEntityMemberID: (state, { payload }) => {
      state.uuid = payload
    },
    fetchEntityMember: (state, { payload }) => { },
    getUserAuth:(state, { payload }: PayloadAction<string>) => {},
    setUserAuthExists: (state, { payload }: PayloadAction<boolean>) => {
      state.userAuthExists = payload
    },
    createUserAuth:(state, { payload }: PayloadAction<OnCreateUserAuthSagaPayload>) => {},
    showChangePasswordModal: (state, { payload }: PayloadAction<boolean>) => {
      state.changePasswordModal = payload
    },
    setHasChangedPassword :(state, { payload }) => {
      state.hasChangedPassword = payload
    },
    setEditMode: (state, { payload }) => {
      state.edit = payload;
    },
    setHasEditPermission: (state, { payload }) => {
      state.hasEditPermission = payload
    },
    setSubmitButtonDisabled:(state, { payload }) => {
      state.submitButtonState = payload
    },
    setEntityMember: (state, { payload }) => {
      state.entity = payload;
    },
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
    /* setShowModal: (state, { payload }) => {
      state.showModal = payload;
    }, */
    setForm: (state, { payload }) => {
      state.entityMemberForm = payload;
    },
    onFormChange: (state, { payload }) => {
      state.entityMemberForm = Check.setAndCheckValidation(state.entityMemberForm, payload, entityMemberValidations)
    },
    onChangePasswordFormChange: (state, { payload }: PayloadAction<ChangePasswordForm>) => {
      state.changePasswordForm = payload
    },
    onSubmit: () => { },
    onChangePasswordSave:(state, { payload }: PayloadAction<OnChangePasswordSaveSagaPayload>) => {},
    changePasswordSubmit:(state, { payload }) => {},
    onReSendEmail:() => {},
    setShowDeleteModal: (state, { payload }) => {
      state.showDeleteModal = payload
    },
    onDeleteConfirm: (state, { payload }) => { },
  }
})