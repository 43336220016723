import * as React from "react"

import theme from "ui/theme"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
        <rect width="17" height="17" rx="4" fill={props.fill ? props.fill : theme().success}/>
        <path d="M6.45996 13H4.64746L7.40918 5H9.58887L12.3467 13H10.5342L8.53027 6.82812H8.46777L6.45996 13ZM6.34668 9.85547H10.6279V11.1758H6.34668V9.85547Z" fill="white"/>
      </svg>
    )
}

export default SvgComponent