import { CCol } from '@coreui/react-pro'
import { useParams } from 'react-router-dom';
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { actions, RootState } from 'store/rootSlices'
import CustomTextInput from 'shared/components/CustomTextInput';
import { Row } from '../../styles';
import { Tooltip, CustomSpan } from '../styles'
import Info from 'assets/icons/info'
import i18n from 'i18next';
import TableContainer from 'shared/components/TableContainer';
import CustomCheckbox from 'shared/components/CustomCheckbox';
import PackExtrasRow from './packExtrasRow';
import { PackForm } from 'types/trips/extras';
import { doesPackCapacityExcedeExtraMinimumQuantity } from '../utils';

const ExtrasQuantityRow = () => {
    const dispatch = useDispatch();
    const { id } = useParams();

    const {
        onFormChange,
        onToggleExtra
    } = actions.TripExtraForm;
    const {
        extraForm,
        packForm,
        hasEditPermission,
    } = useSelector((state: RootState) => state.TripExtraForm)

    const {
        allExtras
    } = useSelector((state: RootState) => state.TripsConfiguration)

    return (
        <>
            <Row>
                <CCol>
                    <CustomCheckbox
                        id={`tripExtraForm-isPack`}
                        disabled={!hasEditPermission}
                        checked={extraForm.isPack.value === true ? true : false}
                        onClick={e => dispatch(
                            onFormChange(
                                {
                                    ...extraForm,
                                    isPack: { value: e.target.checked, valid: true, message: "" }

                                }
                            )
                        )}
                        text={i18n.t('tripExtraForm.isPack')}
                    />
                </CCol>
            </Row>
            <Row>
                <CCol>
                    <CustomTextInput
                        labelHelpText={
                            doesPackCapacityExcedeExtraMinimumQuantity(packForm, Number(extraForm.maxCapacity.value)) ?
                                <Tooltip>
                                    <Info fill={"#D4A8EA"} />
                                    <CustomSpan>A lotação do pack é superior à quantidade definida para um dos extras selecionados</CustomSpan>
                                </Tooltip>
                                :
                                undefined
                        }
                        id='tripExtraForm-maxCapacity'
                        disabled={!hasEditPermission}
                        required
                        valid={extraForm.maxCapacity.valid}
                        value={extraForm.maxCapacity.value}
                        helpText={extraForm.maxCapacity.message}
                        placeholder={i18n.t('placeholders.maxCapacity')}
                        label={i18n.t('tripExtraForm.maxCapacity')}
                        onChange={(e) => dispatch(onFormChange({ ...extraForm, maxCapacity: { value: e.target.value, message: "", valid: true } }))}
                    />
                </CCol>
                <CCol>
                    <CustomTextInput
                        id='tripExtraForm-price'
                        disabled={!hasEditPermission}
                        required={true}
                        icon='euro'
                        valid={extraForm.price.valid}
                        value={extraForm.price.value}
                        helpText={extraForm.price.message}
                        placeholder={i18n.t('placeholders.price')}
                        label={i18n.t('tripExtraForm.price')}
                        onChange={(e) => dispatch(onFormChange({ ...extraForm, price: { value: e.target.value, message: "", valid: true } }))}
                    />
                </CCol>
            </Row>
            {extraForm.isPack.value === true &&
                <Row>
                    <TableContainer
                        count={-1}
                        elementsPerPage={10}
                        currentPage={-1}
                        onPageChange={() => { }}
                        headerElements={[
                            i18n.t('tripExtraForm.extra'),
                            i18n.t('tripExtraForm.maxCapacity'),
                            i18n.t('tripExtraForm.quantity'),
                            ''
                        ]}
                    >
                        {
                            allExtras?.filter(ext => ext.id !== Number(id) && !ext.isPack).map((extra, idx) => {
                                const selectedExtra: PackForm | undefined = packForm.find(selectedExtra => selectedExtra.id === extra.id)

                                return (
                                    <React.Fragment key={`circuit-going-${idx}`}>
                                        <PackExtrasRow
                                            onClick={(e, id) => { return }}
                                            hasEditPermission={hasEditPermission}
                                            extra={extra}
                                            selectedExtra={selectedExtra}
                                            id={`tripExtraForm-packExtra${idx}`}
                                            actions={
                                                <React.Fragment>
                                                    <CustomCheckbox
                                                        checkStyle={{ width: '40px', height: '20px', marginTop: '5px' }}
                                                        id={`tripExtraForm-packExtra${idx}-checkBox`}
                                                        disabled={!hasEditPermission || !extra.isActive}
                                                        checked={selectedExtra?.isSelected.value}
                                                        onClick={e => {
                                                            dispatch(onToggleExtra({ extra }))
                                                        }}
                                                        switch
                                                    />
                                                </React.Fragment>
                                            }
                                        />
                                    </React.Fragment>
                                )
                            })
                        }
                    </TableContainer>
                </Row>
            }
        </>
    )
}

export default ExtrasQuantityRow