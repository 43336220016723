import { CTableDataCell } from '@coreui/react-pro'
import styled from 'styled-components'
import theme from 'ui/theme'

export const TableDataCellStops = styled(CTableDataCell) <{ start: boolean, exit: boolean, current: boolean, activ: boolean }>`
  height: 54px;
  color: ${props => 
    props.activ ? 
      props.start ? 
        `${theme().success} !important` 
        :
        props.exit ?   
          `${theme().danger} !important` 
          : 
          `${theme().gray700Color} !important`
      :
      `${theme().gray200Color} !important`
  }; 
  background-color: ${props =>
    props.activ ?
      props.start ? 
        `${theme().success10} !important` 
        :
        props.exit ? 
        `${theme().danger10} !important`
        :
        `${theme().white} !important`
      :
      `${theme().white} !important`
  }; 
  font-weight: ${props => props.start || props.exit ? 700 : 400};
  /* border-left: ${props => props.current ? props.exit ? `1px dashed ${theme().danger} !important` : `1px dashed ${theme().success} !important` : null};
  border-right: ${props => props.current ? props.exit ? `1px dashed ${theme().danger} !important` : `1px dashed ${theme().success} !important` : null}; */  
  text-align:center;
  cursor: ${props => props.activ ? 'pointer' : 'default'};
`