import * as React from "react"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width={props.width || 14}
            height={props.height || 12}
            viewBox={props.viewBox || "0 0 14 12" }
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M13.6666 2.00002C13.6666 1.26669 13.0666 0.666687 12.3333 0.666687H1.66659C0.933252 0.666687 0.333252 1.26669 0.333252 2.00002V10C0.333252 10.7334 0.933252 11.3334 1.66659 11.3334H12.3333C13.0666 11.3334 13.6666 10.7334 13.6666 10V2.00002ZM12.3333 2.00002L6.99992 5.32669L1.66659 2.00002H12.3333ZM12.3333 10H1.66659V3.33335L6.99992 6.66669L12.3333 3.33335V10Z" fill="#636F83" />
        </svg>
    )
}

export default SvgComponent
