/**
 * FuellingForm component
 *
 * @author João Dias <jdias@ubiwhere.com>
 *
 *
 */

import React, { useEffect } from 'react'
import { RootState } from 'store/rootSlices';
import { useHistory, useParams } from 'react-router-dom';
import theme from 'ui/theme';
import { CForm, CCol, CRow } from '@coreui/react-pro';

import { TankListItem, VehicleListSingle } from 'types/fleet/vehicle';

import SectionContainer from 'shared/components/Sectioncontainer';

import { actions } from 'store/rootSlices';
import { useDispatch, useSelector } from 'react-redux';
import { FuellingLocation } from 'types/fleet/fuel';
import Datepicker from 'shared/components/FormComponents/datepickertime';
import CustomTextInput from 'shared/components/CustomTextInput';
import CustomButton from 'shared/components/CustomButton';
import FileUploader from 'shared/components/FormComponents/fileuploader'
import Photoscomponent from 'shared/components/Photoscomponent'
import i18n from "i18next";
import Loadingcontainer from 'shared/components/Loadingcontainer';
import CustomModal from 'shared/components/CustomModal';
import SearchSelect from 'shared/components/Searchselect';
import CustomCheckbox from 'shared/components/CustomCheckbox';
import Dropdown from 'shared/components/FormComponents/dropdown'
import { hasPermission } from 'permissions';
import config from 'config';
import { Wrapper, Row, ButtonWrapper, CustomColumn} from './styles'

interface PropTypes {
    action?: string,
}

const FuellingForm = ({ action }: PropTypes) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { id } = useParams()

    const { navigateBack } = actions.App;

    const {
        onFormChange,
        onMount,
        onUnmount,
        showDeleteModal,
        onSubmit,
        setIsTank,
        addToDocumentsArray,
        deleteDocumentFromArray,
        reOrderDocsArray,
        createEntryDocument,
        getPricePerLitre
    } = actions.FleetFuellingForm;

    const {
        fuellingForm,
        loading,
        isEdit,
        deleteModal,
        isTank,
        hasEditPermission,
        submitButtonState,
        documents,
        newDocumentsID,
        fuelling,
        pricePerLitre,
        collaborators
    } = useSelector((state: RootState) => state.FleetFuellingForm)

    const { fuelLocationsList, fuelPaymentTypes } = useSelector((state: RootState) => state.Fleetfuelling)
    const { fleetWithTanks, fleetLongList } = useSelector((state: RootState) => state.FleetManagement)
    const { PERMISSIONS } = config
    const { permissions } = useSelector((state: RootState) => state.App)

    useEffect(() => {
        dispatch(onMount(id))

        return () => {
            dispatch(onUnmount())
        }

    }, [dispatch, history, id, onMount, onUnmount]);

    const handleAddDocument = (e) => {
        dispatch(addToDocumentsArray({ documents, e, newDocumentsID }))
    }

    let isTankLocation = false
    isTankLocation = fuelLocationsList.some(fl => fuellingForm.fuelStation.value === fl.id && fl.isTank)

    useEffect(() => {
        if(!isTank && isTankLocation) {
            dispatch(getPricePerLitre(fuellingForm.fuelStation.value))
        }
        
    }, [dispatch, isTank, isTankLocation, id, onMount, onUnmount, fuellingForm.fuelStation.value, getPricePerLitre]);

    if (loading) return <Wrapper><Loadingcontainer /></Wrapper>

    return (
        <Wrapper>
            <CForm>
                <SectionContainer title="Dados">
                    <CRow>
                        <CustomCheckbox
                            id='tripActivityForm-isActive'
                            disabled={!hasEditPermission}
                            wrapperStyle={{ display: 'flex', justifyContent: 'flex-start', fontSize: '20px' }}
                            checked={isTank}
                            onClick={e => dispatch(setIsTank(!isTank))}
                            text={'Depósito?'}
                            switch
                        />
                    </CRow>
                    <Row>
                        <CCol>
                            <SearchSelect
                                id='fuellingForm-vehicle'
                                disabled={!hasEditPermission}
                                required
                                value={fuellingForm.vehicle.value}
                                valid={fuellingForm.vehicle.valid}
                                helpText={fuellingForm.vehicle.message}
                                label={isTank ? i18n.t('fleet.tank') : i18n.t('fleet.vehicle')}
                                placeholder={i18n.t('placeholders.select')}
                                options={isTank ? fleetWithTanks ? fleetWithTanks.map((el: TankListItem) => {
                                    return ({
                                        name: el.name ?? el?.shortName,
                                        value: el.id,
                                    })
                                })
                                    :
                                    []
                                    :
                                    fleetLongList ? fleetLongList.map((el: VehicleListSingle) => {
                                        return ({
                                            name: el.name ?? el?.shortName,
                                            value: el.id,
                                        })
                                    })
                                        :
                                        []
                                }
                                onChange={(e) => dispatch(onFormChange({ ...fuellingForm, vehicle: { value: e, valid: true, message: "" } }))}
                            />
                        </CCol>
                        <CCol>
                            <SearchSelect
                                id='fuellingForm-fuelStation'
                                disabled={!hasEditPermission}
                                required
                                value={fuellingForm.fuelStation.value}
                                valid={fuellingForm.fuelStation.valid}
                                helpText={fuellingForm.fuelStation.message}
                                label={i18n.t('fuel.fuelLocation')}
                                placeholder={i18n.t('placeholders.select')}
                                options={
                                    fuelLocationsList ? fuelLocationsList.map((el: FuellingLocation) => {
                                        return ({
                                            name: el.name,
                                            value: el.id,
                                        })
                                    }) : []
                                }
                                onChange={(e) => dispatch(onFormChange({ ...fuellingForm, fuelStation: { value: e, valid: true, message: "" } }))}
                            />
                        </CCol>
                        <CCol>
                            <SearchSelect
                                id='fuellingForm-fuelPaymentTypes'
                                disabled={!hasEditPermission || (!isTank && isTankLocation)}
                                value={fuellingForm.paymentType.value}
                                valid={fuellingForm.paymentType.valid}
                                helpText={fuellingForm.paymentType.message}
                                label={i18n.t('fuel.fuelPaymentType')}
                                placeholder={i18n.t('placeholders.select')}
                                options={
                                    fuelPaymentTypes ? fuelPaymentTypes.map((el: any) => {
                                        return ({
                                            name: el.name,
                                            value: el.uid,
                                        })
                                    }) : []
                                }
                                onChange={(e) => dispatch(onFormChange({ ...fuellingForm, paymentType: { value: e, valid: true, message: "" } }))}
                            />
                        </CCol>
                    </Row>
                    <Row>
                        <CCol>
                            <Datepicker
                                id='fuellingForm-fuelDate'
                                disabled={!hasEditPermission}
                                value={fuellingForm.fuelDate.value}
                                valid={fuellingForm.fuelDate.valid}
                                helpText={fuellingForm.fuelDate.message}
                                required
                                label={i18n.t('common.date')}
                                onChange={(date, e) => dispatch(onFormChange({ ...fuellingForm, fuelDate: { value: date, valid: true, message: "" } }))}
                            />
                        </CCol>
                        <CCol>
                            <CustomTextInput
                                id='fuellingForm-litres'
                                disabled={!hasEditPermission}
                                value={fuellingForm.litres.value || ''}
                                valid={fuellingForm.litres.valid}
                                helpText={fuellingForm.litres.message}
                                required
                                label={i18n.t('fuel.litres')}
                                placeholder="0"
                                onChange={(e) => dispatch(onFormChange({ ...fuellingForm, litres: { value: e.target.value, valid: true, message: "" } }))}
                            />
                        </CCol>
                        <CCol>
                            <CustomTextInput
                                id='fuellingForm-priceperLitre'
                                disabled={!hasEditPermission || (!isTank && isTankLocation)}
                                value={(!isTank && isTankLocation) ? pricePerLitre.toString() : fuellingForm.pricePerLitre.value || ''}
                                valid={fuellingForm.pricePerLitre.valid}
                                helpText={fuellingForm.pricePerLitre.message}
                                icon="euro"
                                label={i18n.t('fuel.costLitre')}
                                placeholder="0.00"
                                onChange={(e) => dispatch(onFormChange({ ...fuellingForm, pricePerLitre: { value: e.target.value, valid: true, message: "" } }))}
                            />
                        </CCol>
                    </Row>
                    <Row>
                        <CustomColumn>
                            <Dropdown
                                id="fuellingForm-collaboratorName"
                                valid={fuellingForm.fuelledBy.valid}
                                helpText={fuellingForm.fuelledBy.message}
                                value={fuellingForm.fuelledBy.value}
                                placeholder={i18n.t("placeholders.name")}
                                label={i18n.t('common.name')}
                                onChange={e => { dispatch(onFormChange({ ...fuellingForm, fuelledBy: { value: e.target.value, message: "", valid: true } })) }}
                                options={collaborators.map((s: any) => ({ label: s.name, value: s.uuid } ))}
                                disabled={!hasEditPermission || !hasPermission(permissions, PERMISSIONS.FLEET.EDIT_FUEL_FUELLED_BY)}
                            />
                        </CustomColumn>
                        <CCol>
                            <CustomTextInput
                                id='fuellingForm-description'
                                disabled={!hasEditPermission}
                                value={fuellingForm.description.value || ''}
                                valid={fuellingForm.description.valid}
                                helpText={fuellingForm.description.message}
                                placeholder={i18n.t('placeholders.description')}
                                label={i18n.t('common.description')}
                                onChange={(e) => dispatch(onFormChange({ ...fuellingForm, description: { value: e.target.value, valid: true, message: "" } }))}
                            />
                        </CCol>
                    </Row>
                </SectionContainer>
                {/*edit && hasPermission(permissions.fleet, PERMISSIONS.FLEET.DELETE_FUEL) && <SectionContainerDelete
                    id='fuellingForm-delete'
                    title={i18n.t('fleetForm.deleteFuelling')}
                    text={<p>{i18n.t('fleetForm.deleteFuelling1')}</p>}
                    onClick={e => dispatch(showDeleteModal(true))} />
                */}
                <SectionContainer title={i18n.t('cashManagement.depositReceipt')} style={{ width: '100%', paddingBottom: '24px', marginTop: '20px' }}>
                    {
                        hasEditPermission
                        &&
                        <FileUploader
                            id='fleet-fuelUpload'
                            disabled={false}
                            required={false}
                            label={'Upload'}
                            accept='.pdf'
                            fileSizeLimit={100000000}
                            onChange={(e) => { handleAddDocument(e) }}
                        />
                    }
                    {
                        hasPermission(permissions, PERMISSIONS.FLEET.EDIT_FUEL)
                        &&
                        <Photoscomponent
                            id='fleetFuelPhotos'
                            array={documents}
                            onDrag={e => dispatch(reOrderDocsArray({ documents, e }))}
                            onDelete={(e, index) => dispatch(deleteDocumentFromArray({ documents, index }))}
                        />
                    }
                </SectionContainer>
                <ButtonWrapper>
                    <CustomButton id='fuellingForm-cancel' onClick={() => dispatch(navigateBack())} text={i18n.t('common.cancelBtn')} color="link" buttonStyle={{ color: theme().danger, textDecoration: 'unset' }} />
                    {
                        isEdit ?
                            hasPermission(permissions, PERMISSIONS.FLEET.EDIT_FUEL) &&
                            <CustomButton id='fuellingForm-save'
                                onClick={() => dispatch(createEntryDocument(fuelling.id))}
                                disabled={submitButtonState}
                                text={i18n.t('common.saveBtn')}
                                icon="confirm"
                                color="success"
                                buttonStyle={{ color: "#FFFFFF" }} />
                            :
                            isTank ?
                                hasPermission(permissions, PERMISSIONS.FLEET.CREATE_FUEL_TO_STATION) &&
                                <CustomButton
                                    id='fuellingForm-save'
                                    disabled={submitButtonState}
                                    onClick={() => dispatch(onSubmit())}
                                    text={i18n.t('common.saveBtn')}
                                    icon="confirm"
                                    color="success"
                                    buttonStyle={{ color: "#FFFFFF" }} />
                                :
                                hasPermission(permissions, PERMISSIONS.FLEET.CREATE_FUEL_TO_VEHICLE) &&
                                <CustomButton
                                    id='fuellingForm-save'
                                    disabled={submitButtonState}
                                    onClick={() => dispatch(onSubmit())}
                                    text={i18n.t('common.saveBtn')}
                                    icon="confirm"
                                    color="success"
                                    buttonStyle={{ color: "#FFFFFF" }} />
                    }
                </ButtonWrapper>
            </CForm>
            {
                hasPermission(permissions, PERMISSIONS.FLEET.DELETE_FUEL) && <CustomModal
                    id='fuellingForm-deleteFuellingModal'
                    visible={deleteModal}
                    warningType='warning'
                    warningName='Aviso'
                    buttonIcon={false}
                    footerPosition='center'
                    textAlign='center'
                    children={<p>{i18n.t('fuelForm.deleteFuelling')}</p>}
                    onclose={e => dispatch(showDeleteModal(false))}
                    onsubmit={e => { }} />
            }
        </Wrapper>
    )
}

export default FuellingForm