import axios from 'axios'
import endpoints from 'api/endpoints'
import { decamelizeKeys  } from 'humps'
import { ServiceFormValidations, ServiceFormValidationsEdit } from 'scenes/TripForms/Tripserviceform/utils'
import { generateUrl, generateSortString } from 'utils'
import { SortType } from 'types/misc'

/* import { generateUrl } from 'utils'
import { toast } from 'react-toastify'
import i18n from 'i18next' */

const GetServices = (page: number, searchTerm: string, sort?: SortType) => {
    const searchTermQS = `${searchTerm ? `&search=${searchTerm}` : ''}`;

    let endpoint = `${endpoints.TRIPS.SERVICES.GET_SERVICES}?page=${page}${searchTermQS}`;
    //const endpoint = generateUrl(endpoints.TRIPS.SERVICES.GET_SERVICES, { i })
    
    if (sort && sort.field !== null && sort.direction !== null && sort.direction !== "") {
        endpoint += generateSortString(sort);
    }
    return axios.get(endpoint)
        .then(res => res.data)
        .catch(err => {
        })
}

const GetAllServices = () => {
    return axios.get(endpoints.TRIPS.SERVICES.GET_ALL_SERVICES)
        .then(res => res.data)
        .catch(err => {
        })
}

const GetService = (id) => {
    const endpoint = generateUrl(endpoints.TRIPS.SERVICES.GET_SERVICE, { id })
    return axios.get(endpoint)
        .then(res => res.data)
        .catch(err => {
        })
}

const CreateService = (params) => {
    const decMLZParams = decamelizeKeys(params)

    const serviceFormData = new FormData();
    Object.keys(decamelizeKeys(ServiceFormValidations)).forEach(valKey => {
        //console.log(valKey, decMLZParams[valKey].value);
        serviceFormData.append(valKey, decMLZParams[valKey].value)
    })
    return axios.post(endpoints.TRIPS.SERVICES.CREATE_SERVICE, serviceFormData)
        .then(res => res.data)
        .catch(err => { throw err })
}

const EditService = (id, params) => {
    const decMLZParams = decamelizeKeys(params)
    const endpoint = generateUrl(endpoints.TRIPS.SERVICES.EDIT_SERVICE, { id })
    
    const serviceFormData = new FormData();
    Object.keys(decamelizeKeys(ServiceFormValidationsEdit)).forEach(valKey => {
        serviceFormData.append(valKey, decMLZParams[valKey].value)
    })

    return axios.patch(endpoint, serviceFormData)
        .then(res => res.data)
        .catch(err => { throw err })  
}

export default {
    GetAllServices,
    GetServices,
    GetService,
    CreateService,
    EditService,
}