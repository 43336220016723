import styled from 'styled-components'
import { CustomTableRow } from 'shared/genericStyledComponents'
import theme from 'ui/theme';

const CustomCTableRow = styled(CustomTableRow)`
  max-height: 54px;
  background-color: ${theme().white};
  border-bottom: 1px solid ${theme().gray200Color};
 
  td:first-child {
    padding-left:15px;
    width:100%;
  }

  td:nth-child(2){
    width:15%;
  }
  td:nth-child(3){
    width:15%;
  }
 
  td:nth-child(5){
    width:20%;
  }
 
  > td {
    text-transform: capitalize;
  }
 `

export {
  CustomCTableRow
}