/**
 * Fleetmanagement scene sagas
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */
import { putResolve, takeLatest, call, select } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import API from 'api'
import i18n from 'i18next'

import { actions, RootState } from 'store/rootSlices'
import { VehiclesList, TanksList } from 'types/fleet/vehicle'

import { AnyAction } from 'redux';

type TAction = {
	type: AnyAction;
	payload: number;
	meta?: any;
	error?: boolean;
};


function* onMountFleet({ payload }: AnyAction ) {
  try {
    yield getURLParamsSaga(payload);
    yield putResolve(actions.FleetManagement.fetchFleet());
  } catch (err) {
  } 
}

function* onUnmountFleet({ payload }: AnyAction ) {
  yield putResolve(actions.FleetManagement.setLoading(true));
  yield putResolve(actions.FleetManagement.setFleet([]));
}

function* fetchFleetSaga() {
  try {
    const { page, searchTerm, sort, filter } = yield select((state: RootState) => state.FleetManagement);
    yield putResolve(actions.FleetManagement.setLoading(true));
    const fleet: VehiclesList = yield call(API.Fleet.GetFleet, page, searchTerm, sort, filter);
    if (fleet) {
      yield putResolve(actions.FleetManagement.setTotal(fleet.count));
      //yield putResolve(actions.FleetManagement.setFleet(fleet));
      yield putResolve(actions.FleetManagement.setFleet(fleet.results ?? []));
    }
  } catch (err) {
    toast.error(i18n.t("toasts.fleetGetError"));
  } finally {
    yield putResolve(actions.FleetManagement.setLoading(false));
  }
}

function* getLongFleetListSaga() {
  const fleet: VehiclesList = yield call(API.Fleet.GetAllFleet);
  yield putResolve(actions.FleetManagement.setAllFleet(fleet));
}

function* getVehicleTypesSaga() {
  try {
    const res = yield call(API.Fleet.GetVehicleTypes);
    if (res) {
      yield putResolve(actions.FleetManagement.setVehicleTypes(res.data ?? []));
    }
  } catch (err) {
    toast.error(i18n.t("toasts.vehicleTypesGetError"));
  }
}

function* getFuelTypesSaga() {
  try {
    const res = yield call(API.Fleet.GetFuelTypes);
    if (res) {
      yield putResolve(actions.FleetManagement.setFuelTypes(res.data ?? []));
    }
  } catch (err) {
    toast.error(i18n.t("toasts.fuelTypesGetError"));
  }
}

function* getVehicleClassesSaga() {
  try {
    const res = yield call(API.Fleet.GetVehicleClasses);
    if (res) {
      yield putResolve(actions.FleetManagement.setVehicleClasses(res.data ?? []));
    }
  } catch (err) {
    toast.error(i18n.t("toasts.vehicleTypesGetError"));
  }
}

function* getDocumentTypesSaga() {
  try {
    const res = yield call(API.Fleet.GetDocumentTypes);
    if (res) {
      const data = res.data.filter(el => el.isActive);
      yield putResolve(actions.FleetManagement.setDocumentTypes(data ?? []));
    }
  } catch (err) {
    toast.error(i18n.t("toasts.documentTypesGetError"));
  }
}

function* getFleetWithTanksSaga() {
  try {
    const res: TanksList = yield call(API.Fleet.GetFleetWithTanks);
    if (res) {
      yield putResolve(actions.FleetManagement.setFleetWithTanks(res));
    }
  } catch (err) {
    toast.error(i18n.t("toasts.fleetGetError"));
  }
}

function* getURLParamsSaga( payload : AnyAction) {

  let params = new URLSearchParams(payload.location.search)

  const {
      searchTerm,
      filter
  } = yield select((state: RootState) => state.FleetManagement);

  if (params.toString() !== "") {
    yield putResolve(actions.FleetManagement.populateFiltersOnMount({
      searchTerm: params.get('search') ?? "",
      filter: params.get('vehicleType') ?? "",
    }))
  }
  else {
      params.set('search', searchTerm)
      params.set('vehicleType', filter)
      payload.replace({ search: (params).toString() })
  }
}

export default function* watcherFleet() {
  yield takeLatest('fleetmanagement/onMount', onMountFleet);
  yield takeLatest('fleetmanagement/onUnmount', onUnmountFleet);
  yield takeLatest('fleetmanagement/setPage', fetchFleetSaga);
  yield takeLatest('fleetmanagement/setSearchTerm', fetchFleetSaga);
  yield takeLatest('fleetmanagement/setSort', fetchFleetSaga);
  yield takeLatest('fleetmanagement/setFilter', fetchFleetSaga);
  yield takeLatest('fleetmanagement/fetchFleet', fetchFleetSaga);
  yield takeLatest('fleetmanagement/getVehicleTypes', getVehicleTypesSaga);
  yield takeLatest('fleetmanagement/getFuelTypes', getFuelTypesSaga);
  yield takeLatest('fleetmanagement/getVehicleClasses', getVehicleClassesSaga);
  yield takeLatest('fleetmanagement/getDocumentTypes', getDocumentTypesSaga);
  yield takeLatest('fleetmanagement/getAllFleet', getLongFleetListSaga);
  yield takeLatest('fleetmanagement/getFleetWithTanks', getFleetWithTanksSaga);
  yield takeLatest('fleetmanagement/getURLParams', getURLParamsSaga);
}


