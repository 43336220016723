/**
 * Tabs container component
 *
 * @author João Dias <jdias@ubiwhere.com>
 *
 *
 */

import React from 'react'
import { CBadge } from '@coreui/coreui-pro'
import { Wrapper, TabObject, TabRow, ContentContainer } from './styles'

interface PropTypes {
  id?: string,
  content: Array<{ title: string, badge?: typeof CBadge, content: JSX.Element, disabled?: boolean }>;
  message?: string | JSX.Element,
  onChangeTab?: Function
  defaultTab?: number
}

const TabsContainer = (props: PropTypes) => {
  const [currentTab, setCurrentTab] = React.useState(props.defaultTab ? props.defaultTab : 0);

  const handleChangeTab = (idx, title) => {
    setCurrentTab(idx)

    if (props.onChangeTab) {
      props.onChangeTab(title)
    }
  }

  return (
    <Wrapper id={props.id}>
      <TabRow>
        {props.content.map((el, idx) => {
          if (el.disabled) return null
          return (
            <TabObject id={`${props.id}-tab-${idx}`} key={`tabObject-${idx}`} isSelected={currentTab === idx} onClick={() => handleChangeTab(idx, el.title)}>
              {el.title}
              {el.badge ? el.badge : null}
            </TabObject>)
        })}
        <div>
          <p>{props.message ? props.message : ""}</p>
        </div>
      </TabRow>
      <ContentContainer >
        {props.content.map((el, idx) => {
          if (el.disabled) return null
          if (currentTab === idx) return el.content;
          return null;
        })}
      </ContentContainer>
    </Wrapper>
  )
}

export default TabsContainer