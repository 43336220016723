/**
 * Tripcampaignform component
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */

import { CCol, CForm, CRow } from '@coreui/react-pro'
import { useParams } from 'react-router-dom';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { actions, RootState } from 'store/rootSlices'

import CustomTextInput from 'shared/components/CustomTextInput'
import SectionContainer from 'shared/components/Sectioncontainer'
import ContainerDelete from 'shared/components/FormComponents/containerdelete';
import CustomButton from 'shared/components/CustomButton';
import theme from 'ui/theme';
import CustomRadioButtonGroup from 'shared/components/CustomRadioButtonGroup';
import CustomRadioButton from 'shared/components/CustomRadioButton';
import Dropdown from 'shared/components/FormComponents/dropdown';
//import CustomModal from 'shared/components/CustomModal';
import i18n from 'i18next';
import CustomCheckbox from 'shared/components/CustomCheckbox';
import Dropdowndiscountcomponent from 'shared/components/Dropdowndiscountcomponent';
import config from 'config';
import { hasPermission } from 'permissions';
import { Row, ButtonWrapper } from '../styles';
import { RadioGroupRow, GenerateColumnButton, Button } from './styles'


const Tripcampaignform = (props) => {

  const dispatch = useDispatch();
  const { id } = useParams();

  const { onFormChange, onMount, onUnmount, onSubmit, onActivitiesSelectChange } = actions.TripCampaignForm;
  const { edit, campaignForm, campaignActivities, hasEditPermission, submitButtonState } = useSelector((state: RootState) => state.TripCampaignForm)
  const { allActivities } = useSelector((state: RootState) => state.TripsConfiguration)
  const { navigateBack } = actions.App;
  const { permissions } = useSelector((state: RootState) => state.App)
  const { PERMISSIONS } = config

  useEffect(() => {
    dispatch(onMount(id))

    return () => {
      dispatch(onUnmount())
    }

  }, [dispatch, id, onMount, onUnmount]);

  const generateRandomPrefix = (length) => {
    return Math.random().toString(26).substring(length).toLocaleUpperCase()
  }

  const handleSubmit = (e) => {
    dispatch(onSubmit({ campaignForm, e, edit, campaignActivities }))
  }

  return (
    <div>
      <CForm>
        {/* {edit === true && <DropdownExport id='tripCampaignForm-dropdownExport' dropdown={true} wrapperStyle={{ maxWidth: '100%' }} />} */}
        <CustomCheckbox
          id='tripCampaignForm-isActive'
          disabled={!hasEditPermission}
          wrapperStyle={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '-25px', fontSize: '20px' }}
          checked={campaignForm.isActive.value ? true : false}
          onClick={e => dispatch(onFormChange({ ...campaignForm, isActive: { value: e.target.checked, valid: true, message: "" } }))}
          text={i18n.t('tripCampaignForm.isActive')}
          switch
        />
        <SectionContainer title={i18n.t('tripCampaignForm.titleCampaign')}  style={{ width: '100%', paddingBottom: '24px' }}>
          <CRow className="g-3">
            <CCol sm={8}>
              <CustomTextInput
                id='tripCampaignForm-name'
                disabled={!hasEditPermission}
                required={true}
                valid={campaignForm.name.valid}
                value={campaignForm.name.value}
                helpText={campaignForm.name.message}
                placeholder={i18n.t('placeholders.name')}
                label={i18n.t('tripCampaignForm.name')}
                onChange={(e) => dispatch(onFormChange({ ...campaignForm, name: { value: e.target.value, message: "", valid: true } }))}
              />
            </CCol>
            <GenerateColumnButton>
              <CustomTextInput
                id='tripCampaignForm-prefix'
                disabled={!hasEditPermission}
                required={true}
                valid={campaignForm.prefix.valid}
                value={campaignForm.prefix.value || ''}
                helpText={campaignForm.prefix.message}
                wrapperStyle={{ width: '75%', alignSelf: 'flex-start' }}
                placeholder={i18n.t('placeholders.prefix')}
                label={i18n.t('tripCampaignForm.prefix')}
                onChange={(e) => dispatch(onFormChange({ ...campaignForm, prefix: { value: e.target.value, message: "", valid: true } }))}
              />
              <Button id='tripCampaignForm-generatePrefix' color="primary" valid={campaignForm.name.valid === false || campaignForm.prefix.valid === false ? false : true}
                variant="outline"
                onClick={(e) => hasEditPermission && dispatch(onFormChange({ ...campaignForm, prefix: { value: generateRandomPrefix(9), message: "", valid: true } }))}>
                {i18n.t('tripCampaignForm.generateButton')}
              </Button>
            </GenerateColumnButton>
          </CRow>
          <Row>
            <CCol>
              <Dropdowndiscountcomponent
                disabled={!hasEditPermission}
                required={true}
                onOptionChange={(e) => dispatch(onFormChange({ ...campaignForm, isDiscountPercentage: { value: e, message: "", valid: true } }))}
                onChange={(e) => dispatch(onFormChange({ ...campaignForm, discount: { value: e.target.value, message: "", valid: true } }))}
                value={campaignForm.discount.value}
                isPercentage={campaignForm.isDiscountPercentage.value}
                label={i18n.t('tripCampaignForm.discount')}
                helpText={campaignForm.discount.message}
                placeholder={i18n.t('placeholders.discount')}
              />
              {/* <CustomTextInput
                required={true}
                valid={campaignForm.discount.valid}
                value={campaignForm.discount.value}
                helpText={campaignForm.discount.message}
                placeholder={i18n.t('placeholders.discount')}
                label={i18n.t('tripCampaignForm.discount')}
                onChange={(e) => dispatch(onFormChange({ ...campaignForm, discount: { value: e.target.value, message: "", valid: true } }))}
              /> */}
            </CCol>
            <CCol>
              <CustomTextInput
                id='tripCampaignForm-quantity'
                disabled={!hasEditPermission}
                required={true}
                valid={campaignForm.quantity.valid}
                value={campaignForm.quantity.value}
                helpText={campaignForm.quantity.message}
                placeholder={i18n.t('placeholders.quantity')}
                label={i18n.t('tripCampaignForm.quantity')}
                onChange={(e) => dispatch(onFormChange({ ...campaignForm, quantity: { value: e.target.value, message: "", valid: true } }))}
              />
            </CCol>
            <CCol>
              <Dropdown
                id='tripCampaignForm-expiration'
                disabled={!hasEditPermission}
                required={true}
                valid={campaignForm.validityMonths.valid}
                helpText={campaignForm.validityMonths.message}
                label={i18n.t('tripCampaignForm.expiration')}
                placeholder={i18n.t('placeholders.expiration')}
                options={[{ label: "1 mês", value: 1 }, { label: "2 meses", value: 2 }, { label: "3 meses", value: 3 }]}
                onChange={(e) => {

                  const value = e.target.value
                  
                  dispatch(
                    onFormChange(
                      {
                        ...campaignForm,
                        validityMonths: { value: value, message: "", valid: true }
                      }
                    )
                  )
                }}
              />
            </CCol>
          </Row>
        </SectionContainer>
        <SectionContainer title={i18n.t('tripCampaignForm.titleCodeSpecification')} style={{ width: '100%', paddingBottom: '24px' }}>
          <RadioGroupRow className="g-3">
            <CCol>
              <CustomRadioButtonGroup role="group1" helpText={campaignForm.hasMultipleCodes.message} valid={campaignForm.hasMultipleCodes.valid}>
                <CustomRadioButton group="group1" text={i18n.t('tripCampaignForm.unique')} value="false"
                  checked={campaignForm.hasMultipleCodes.value === false ? true : false}
                  disabled={!hasEditPermission}
                  onClick={e => dispatch(onFormChange({ ...campaignForm, hasMultipleCodes: { value: false, message: "", valid: true } }))} />
                <CustomRadioButton group="group1" text={i18n.t('tripCampaignForm.multiple')} value="true"
                  checked={campaignForm.hasMultipleCodes.value === true ? true : false}
                  disabled={!hasEditPermission}
                  onClick={e => dispatch(onFormChange({ ...campaignForm, hasMultipleCodes: { value: true, message: "", valid: true } }))} />
              </CustomRadioButtonGroup>
            </CCol>
          </RadioGroupRow>
          <Row>
            <CCol>
              <Dropdown
                id='tripCampaignForm-activityName'
                disabled={!hasEditPermission}
                required={true}
                label={i18n.t('tripCampaignForm.associateActivities')}
                placeholder={i18n.t('placeholders.associateActivities')}
                value={campaignActivities[0]}
                options={allActivities.map(act => {
                  return {
                    label: act.name,
                    value: act.id
                  }
                })}
                onChange={(e) => {
                  const value = e.target.value
                  
                  dispatch(
                    onActivitiesSelectChange([Number(value)])
                  )
                }}
              />
            </CCol>
            <CCol>
              <CustomTextInput
                id='tripCampaignForm-sendEmailAfterNumberReservations'
                disabled={!hasEditPermission}
                required={true}
                valid={campaignForm.sendEmailAfterNumberReservations.valid}
                value={campaignForm.sendEmailAfterNumberReservations.value}
                helpText={campaignForm.sendEmailAfterNumberReservations.message}
                placeholder={i18n.t('placeholders.automaticSend')}
                label={i18n.t('tripCampaignForm.autoSend')}
                onChange={(e) => dispatch(onFormChange({ ...campaignForm, sendEmailAfterNumberReservations: { value: e.target.value, message: "", valid: true } }))}
              />
            </CCol>
          </Row>
          <Row>
            <CustomTextInput
              id='tripCampaignForm-sendEmailBody'
              disabled={!hasEditPermission}
              type="textarea"
              required={true}
              valid={campaignForm.sendEmailBody.valid}
              value={campaignForm.sendEmailBody.value}
              helpText={campaignForm.sendEmailBody.message}
              placeholder={i18n.t('placeholders.description')}
              label={i18n.t('tripCampaignForm.description')}
              onChange={(e) => dispatch(onFormChange({ ...campaignForm, sendEmailBody: { value: e.target.value, message: "", valid: true } }))}
            />
          </Row>
        </SectionContainer>
        {
          edit === true && hasPermission(permissions, PERMISSIONS.TRIPS.ACTIVITIES.DELETE_ACTIVITY) && <ContainerDelete id='tripCampaignForm-delete' title={i18n.t('tripCampaignForm.removeCampaign')} text={<p>{i18n.t('tripCampaignForm.removeCampaignText')}<br />{i18n.t('common.deleteLine2')}</p>}></ContainerDelete>
        }
        <ButtonWrapper>
          <CustomButton id='tripCampaignForm-cancel' onClick={() => dispatch(navigateBack())} text={i18n.t('common.cancelBtn')} color="link" buttonStyle={{ color: theme().danger, textDecoration: 'unset' }} />
          {
            edit ?  
            hasPermission(permissions, PERMISSIONS.TRIPS.CAMPAIGNS.EDIT_CAMPAIGN) && <CustomButton id='tripCampaignForm-save' disabled={submitButtonState} text={i18n.t('common.saveBtn')} icon="confirm" onClick={e => handleSubmit(e)} color="success" buttonStyle={{ color: "#FFFFFF" }} />
            :
            hasPermission(permissions, PERMISSIONS.TRIPS.CAMPAIGNS.CREATE_CAMPAIGN) && <CustomButton id='tripCampaignForm-save' disabled={submitButtonState} text={i18n.t('common.saveBtn')} icon="confirm" onClick={e => handleSubmit(e)} color="success" buttonStyle={{ color: "#FFFFFF" }} />
          }
        </ButtonWrapper>
      </CForm>
    </div>
  )
}

export default Tripcampaignform