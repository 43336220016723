/**
 * Tripsconfiguration scene slice
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */
import { createSlice } from '@reduxjs/toolkit'
import { ActivityListItem } from 'types/trips/activity/activity'
import { CategoryListItem } from 'types/trips/categories'
import { LocalsListItem } from 'types/trips/locals'
import { PassengerTypeListItem } from 'types/trips/passengerTypes'
import { ExtrasListItem } from 'types/trips/extras'
import { CampaignsListItem } from 'types/trips/campaigns'
import { CircuitsListItem } from 'types/trips/circuits'
import { ServicesListItem } from 'types/trips/services'

import i18n from 'i18next';
import { SortType } from 'types/misc';

interface InitialState {
  tabName: string,
  path:string,
  page: number,
  searchTerm: string,
  activities: ActivityListItem[],
  allActivities: ActivityListItem[],
  totalActivities: number,
  categories: CategoryListItem[],
  allCategories: CategoryListItem[],
  totalCategories: number,
  locals: LocalsListItem[],
  totalLocals: number,
  passengerTypes: PassengerTypeListItem[]
  allPassengerTypes: PassengerTypeListItem[]
  totalPassengerTypes: number,
  extras: ExtrasListItem[],
  allExtras: ExtrasListItem[],
  totalExtras: number,
  campaigns: CampaignsListItem[]
  totalCampaigns: number,
  circuits: CircuitsListItem[],
  allCircuits: CircuitsListItem[],
  totalCircuits: number,
  services: ServicesListItem[],
  totalServices: number,
  loading: boolean,
  hasCreatePermission: boolean,
  sort: SortType,
  tabId: number,
  makeApiCals: boolean,
  allLocals: LocalsListItem[]
}

const initialState: InitialState = {
  page: 1,
  sort: {
    field: null,
    direction: null,
  },
  searchTerm: "",
  totalActivities: 0,
  totalCategories: 0,
  totalLocals: 0,
  totalPassengerTypes: 0,
  totalExtras: 0,
  totalCampaigns: 0,
  totalCircuits: 0,
  totalServices: 0,
  tabName: '',
  path:'/viagens/configuracoes/nova-atividade',
  activities: [],
  allActivities: [],
  categories: [],
  allCategories: [],
  locals: [],
  allLocals: [],
  passengerTypes: [],
  allPassengerTypes: [],
  extras: [],
  allExtras: [],
  campaigns: [],
  circuits: [],
  allCircuits: [],
  services: [],
  loading: true,
  hasCreatePermission: false,
  tabId: 0,
  makeApiCals: false
}

export default createSlice({
  name: 'Tripsconfiguration',
  initialState,
  reducers: {
    onMount: (state, { payload }) => {
      switch (state.tabId) {
        case 0:
          state.tabName = i18n.t('tripSettings.activityButton')
          state.path = '/viagens/configuracoes/nova-atividade'
          break;
        case 1:
          state.tabName = i18n.t('tripSettings.categoryButton')
          state.path = '/viagens/configuracoes/nova-categoria'
          break;
        case 2:
          state.tabName = i18n.t('tripSettings.localButton')
          state.path = '/viagens/configuracoes/novo-local'
          break;
        case 3:
          state.tabName = i18n.t('tripSettings.passengerTypeButton')
          state.path = '/viagens/configuracoes/novo-tipo'
          break;
        case 4:
          state.tabName = i18n.t('tripSettings.extraButton')
          state.path = '/viagens/configuracoes/novo-extra'
          break;
        case 5:
          state.tabName = i18n.t('tripSettings.campaignButton')
          state.path = '/viagens/configuracoes/nova-campanha'
          break;
        case 6:
          state.tabName = i18n.t('tripSettings.circuitButton')
          state.path = '/viagens/configuracoes/novo-circuito'
          break;
        case 7:
          state.tabName = i18n.t('tripSettings.serviceButton')
          state.path = '/viagens/configuracoes/novo-servico'
          break;
        default:
          break;
      }
    },
    onUnmount: (state) => {
      switch (state.tabId) {
        case 0:
          state.tabName = i18n.t('tripSettings.activityButton')
          state.path = '/viagens/configuracoes/nova-atividade'
          break;
        case 1:
          state.tabName = i18n.t('tripSettings.categoryButton')
          state.path = '/viagens/configuracoes/nova-categoria'
          break;
        case 2:
          state.tabName = i18n.t('tripSettings.localButton')
          state.path = '/viagens/configuracoes/novo-local'
          break;
        case 3:
          state.tabName = i18n.t('tripSettings.passengerTypeButton')
          state.path = '/viagens/configuracoes/novo-tipo'
          break;
        case 4:
          state.tabName = i18n.t('tripSettings.extraButton')
          state.path = '/viagens/configuracoes/novo-extra'
          break;
        case 5:
          state.tabName = i18n.t('tripSettings.campaignButton')
          state.path = '/viagens/configuracoes/nova-campanha'
          break;
        case 6:
          state.tabName = i18n.t('tripSettings.circuitButton')
          state.path = '/viagens/configuracoes/novo-circuito'
          break;
        case 7:
          state.tabName = i18n.t('tripSettings.serviceButton')
          state.path = '/viagens/configuracoes/novo-servico'
          break;
        default:
          break;
      }
    },
    setMakeApiCals: (state, { payload }) => {
      state.makeApiCals = payload
    },
    setTabName:(state, { payload }) => {
      state.tabName = payload
    },
    setTabId:(state, { payload }) => {
      state.tabId = payload
    },
    setPage:(state, { payload }) => {
      state.page = payload
    },
    setSort: (state, { payload }) => {
      state.sort = payload
    },
    setPath:(state, { payload }) => {
      state.path = payload
    },
    clearSearchTerm:(state) => {
      state.searchTerm = ""
    },
    setTab: (state, { payload }) => {},
    getURLParams:(state, payload) => {},
    getActivities: ()=> {},
    setActivities: (state, { payload }) => {
      state.activities = payload;
    },
    getAllActivities: ()=> {},
    setAllActivities: (state, { payload }) => {
      state.allActivities = payload;
    },
    getCategories: ()=> {},
    getAllCategories:() => {},
    setCategories: (state, { payload }) => {
      state.categories = payload;
    },
    setAllCategories: (state, { payload }) => {
      state.allCategories = payload;
    },
    getLocals: ()=> {},
    setLocals: (state, { payload }) => {
      state.locals = payload;
    },
    getAllLocals: ()=> {},
    setAllLocals: (state, { payload }) => {
      state.allLocals = payload;
    },
    getPassengerTypes: ()=> {},
    setPassengerTypes: (state, { payload }) => {
      state.passengerTypes = payload;
    },
    getAllPassengerTypes: ()=> {},
    setAllPassengerTypes: (state, { payload }) => {
      state.allPassengerTypes = payload;
    },
    getExtras: ()=> {},
    setExtras: (state, { payload }) => {
      state.extras = payload;
    },
    getAllExtras: ()=> {},
    setAllExtras: (state, { payload }) => {
      state.allExtras = payload;
    },
    getCampaigns: ()=> {},
    setCampaigns: (state, { payload }) => {
      state.campaigns = payload;
    },
    getCircuits: ()=> {},
    setCircuits: (state, { payload }) => {
      state.circuits = payload;
    },
    getAllCircuits: ()=> {},
    setAllCircuits: (state, { payload }) => {
      state.allCircuits = payload;
    },
    getServices: ()=> {},
    setServices: (state, { payload }) => {
      state.services = payload;
    },
    setLoading: (state, { payload }) => {
      state.loading = payload
    },
    setTotalActivities: (state, { payload }) => {
      state.totalActivities = payload;
    },
    setTotalCategories: (state, { payload }) => {
      state.totalCategories = payload;
    },
    setTotalLocals: (state, { payload }) => {
      state.totalLocals = payload;
    },
    setTotalPassengerTypes: (state, { payload }) => {
      state.totalPassengerTypes = payload;
    },
    setTotalExtras: (state, { payload }) => {
      state.totalExtras = payload;
    },
    setTotalCampaigns: (state, { payload }) => {
      state.totalCampaigns = payload;
    },
    setTotalCircuits: (state, { payload }) => {
      state.totalCircuits = payload;
    },
    setTotalServices: (state, { payload }) => {
      state.totalServices = payload;
    },
    setSearchTerm: (state, { payload }) => {
      state.searchTerm = payload
    },
    setHasCreatePermission: (state, { payload }) => {
      state.hasCreatePermission = payload
    },
    populateFiltersOnMount:(state, { payload }) => {
      state.searchTerm = payload.searchTerm
    }
  }
})