import styled from 'styled-components'
 import { CustomTableRow } from 'shared/genericStyledComponents'
 import { CTableDataCell } from '@coreui/react-pro'

export const CustomCTableRow = styled(CustomTableRow)`
  td:first-child {
    padding-left:15px;
    width:9%;
  }

  td:nth-child(2){
    width:22%;
  }
  
`

export const NameCell = styled(CTableDataCell)`
  cursor: pointer;
`