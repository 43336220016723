/**
 * Gaugecomponent component
 *
 * @author João Dias <jdias@ubiwhere.com>
 *
 *
 */

import React from 'react'
import i18n from "i18next"
import { Wrapper, TextContainer, GaugeContainer} from './styles'

interface PropTypes {
  maxValue: number,
  currentValue: number,
  unit: string
}

const GaugeComponent = (props: PropTypes) => {
  const percent = Math.min(Math.max(Math.round(props.currentValue / props.maxValue * 100), 0), 100);

  return (
    <Wrapper>
      <TextContainer>
        <span><b>{props.currentValue > props.maxValue ? props.maxValue : props.currentValue}</b>/{props.maxValue}&nbsp;{i18n.t(`common.${props.unit}`)}</span>
      </TextContainer>
      <GaugeContainer percent={percent}>
        <div className="gauge-value" />
        <div className="gauge-empty" />
      </GaugeContainer>
    </Wrapper>
  )
}

export default GaugeComponent
