/**
 * Tripcampaigncodesform scene slice
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */
import { createSlice } from '@reduxjs/toolkit'
import { CampaignCodesForm } from 'types/trips/configurations'
import { camelizeKeys } from 'humps'

interface InitialState {
  campaignCodeID:string,
  campaignPrefix: string,
  edit: Boolean,
  campaignCodeForm: CampaignCodesForm,
  submitButtonState: boolean
}

const initialState: InitialState = {
  campaignCodeID:"",
  campaignPrefix:"",
  edit: false,
  submitButtonState: false,
  campaignCodeForm: {
    uid: {
      value: "",
      valid: true,
      message: ""
    },
    destinationBody: {
      value: "",
      valid: true,
      message: ""
    },
    destinationName: {
      value: "",
      valid: true,
      message: ""
    },
    expireAt: {
      value: "",
      valid: true,
      message: ""
    },
    destinationEmail: {
      value: "",
      valid: true,
      message: ""
    },
    isActive:{
      value: "",
      valid: true,
      message: ""
    },
    discount:{
      value: "0",
      valid: true,
      message: ""
    },
    isDiscountPercentage:{
      value: true,
      valid: true,
      message: ""
    },
  }
}

export default createSlice({
  name: 'Tripcampaigncodesform',
  initialState,
  reducers: {
    onMount: (state, { payload }) => { },
    onUnmount: () => { 
      return initialState;
    },
    setAction: (state, { payload }) => {
      state.edit = payload
    },
    setSubmitButtonState: (state, { payload }) => {
      state.submitButtonState = payload
    },
    setCampaignCodeID: (state, { payload }) => {
      state.campaignCodeID = payload
    },
    setCampaignPrefix: (state, { payload }) => {
      state.campaignPrefix = payload
    },
    setUID:(state, { payload })=>{
      state.campaignCodeForm.uid.value = payload
    },
    populateForm: (state, { payload }) => {
      const campaignCode = camelizeKeys(payload)
      //const campaignCode = payload //TripCampaignsMock[0]
      const baseForm = { ...initialState.campaignCodeForm }

      Object.keys(campaignCode).forEach(key => {
        if (key in baseForm) {
          baseForm[key] = {
            ...baseForm[key],
            value: campaignCode[key],
          }
        }
      })
      state.campaignCodeForm = baseForm;
    },
    onFormChange: (state, { payload }) => {
      state.campaignCodeForm = payload //Check.setAndCheckValidation(state.categoryForm, payload, CategoryFormValidations)
    },
    onSubmit:(state , { payload }) => {}
  }
})