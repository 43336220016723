import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions, RootState } from 'store/rootSlices';
//styles
import { Row } from '../../../../styles';
//Components
import { CCol } from '@coreui/react-pro';
import CustomCheckbox from 'shared/components/CustomCheckbox';
import CustomTextInput from 'shared/components/CustomTextInput';
import Dropdown from 'shared/components/FormComponents/dropdown';

const InvoiceFormRows = ({ t }) => {
  const {
    enablePayment,
    paymentForm,
    invoicePaymentForm,
    hasEditPermission,
    enableInvoice
  } = useSelector((state: RootState) => state.BookingForm);

  const { onInvoicePaymentFormChange, setEnableInvoice } = actions.BookingForm;

  const { countryCodes } = useSelector((state: RootState) => state.App);

  const dispatch = useDispatch();

  return (
    <>
      <Row>
        <CCol sm={12}>
          <CustomCheckbox
            id="bookingForm-enableInvoice"
            checked={enableInvoice}
            disabled={!hasEditPermission || !enablePayment}
            onClick={(e) => {
              dispatch(setEnableInvoice(!enableInvoice));
            }}
            text={
              paymentForm.isRefund
                ? t('tripBookings.issueCredit')
                : t('tripBookings.issueInvoice')
            }
          />
        </CCol>
      </Row>
      <Row>
        <CCol sm={3}>
          <CustomTextInput
            id="tripBookings-nif"
            type="text"
            required={false}
            valid={invoicePaymentForm.nif.valid}
            value={invoicePaymentForm.nif.value}
            helpText={invoicePaymentForm.nif.message}
            placeholder={t('placeholders.nif')}
            label={t('common.nif')}
            disabled={!hasEditPermission || !enablePayment || !enableInvoice}
            onChange={(e) =>
              dispatch(
                onInvoicePaymentFormChange({
                  ...invoicePaymentForm,
                  nif: { value: e.target.value, message: '', valid: true },
                })
              )
            }
          />
        </CCol>
        <CCol sm={3}>
          <CustomTextInput
            id="tripBookings-email"
            type="text"
            required={false}
            valid={invoicePaymentForm.email.valid}
            value={invoicePaymentForm.email.value}
            helpText={invoicePaymentForm.email.message}
            placeholder={t('placeholders.email')}
            label={t('common.email')}
            disabled={!hasEditPermission || !enablePayment || !enableInvoice}
            onChange={(e) =>
              dispatch(
                onInvoicePaymentFormChange({
                  ...invoicePaymentForm,
                  email: { value: e.target.value, message: '', valid: true },
                })
              )
            }
          />
        </CCol>
        <CCol sm={3}>
          <CustomTextInput
            id="tripBookings-phone"
            type="text"
            required={false}
            valid={invoicePaymentForm.phone.valid}
            value={invoicePaymentForm.phone.value}
            helpText={invoicePaymentForm.phone.message}
            placeholder={t('placeholders.phone')}
            label={t('common.phone')}
            disabled={!hasEditPermission || !enablePayment || !enableInvoice}
            onChange={(e) =>
              dispatch(
                onInvoicePaymentFormChange({
                  ...invoicePaymentForm,
                  phone: { value: e.target.value, message: '', valid: true },
                })
              )
            }
          />
        </CCol>
        <CCol sm={3}>
          <Dropdown
            id="tripBookings-country"
            required={false}
            valid={invoicePaymentForm.country.valid}
            value={invoicePaymentForm.country.value}
            helpText={invoicePaymentForm.country.message}
            placeholder={t('placeholders.country')}
            label={t('customerForm.country')}
            disabled={!hasEditPermission || !enablePayment || !enableInvoice}
            onChange={(e) =>
              dispatch(
                onInvoicePaymentFormChange({
                  ...invoicePaymentForm,
                  country: { value: e.target.value, message: '', valid: true },
                })
              )
            }
            options={Object.keys(countryCodes).map((key) => {
              return {
                label: countryCodes[key],
                value: key,
              };
            })}
          />
        </CCol>
      </Row>
      <Row>
        <CCol sm={12}>
          <CustomTextInput
            id="tripBookings-notes"
            type="textarea"
            required={false}
            valid={invoicePaymentForm.notes.valid}
            value={invoicePaymentForm.notes.value}
            helpText={invoicePaymentForm.notes.message}
            placeholder={t('placeholders.paymentNotes')}
            label={t('tripBookings.paymentNotes')}
            disabled={!hasEditPermission || !enablePayment || !enableInvoice}
            onChange={(e) =>
              dispatch(
                onInvoicePaymentFormChange({
                  ...invoicePaymentForm,
                  notes: { value: e.target.value, message: '', valid: true },
                })
              )
            }
          />
        </CCol>
      </Row>
    </>
  );
};

export default InvoiceFormRows;