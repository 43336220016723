import styled from 'styled-components'
import { CustomTableRow } from 'shared/genericStyledComponents'

const CustomCTableRow = styled(CustomTableRow)`
  td:first-child {
      width: 50%;
      padding-left:15px;
  }
`

export {
  CustomCTableRow
}