/**
 * Usercashmovementform scene sagas
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */
import { AnyAction } from 'redux'
import { takeLatest, call, putResolve, select } from 'redux-saga/effects'
import { actions, RootState } from 'store/rootSlices'
import { EntryFormValidations } from '../utils';
import { toast } from 'react-toastify'
import { DateTime } from 'luxon'
import { hasPermission } from 'permissions'
import i18n from 'i18next';
import API from 'api'
import * as Check from 'validations';
import config from 'config';
import { StatementItem } from 'types/invoicing/statements';

//import { actions } from 'store/rootSlices'

function* onMountSaga({ payload }: AnyAction) {

  const { PERMISSIONS } = config
  const { permissions } = yield select((state: RootState) => state.App);
  const { startDate } = yield select((state: RootState) => state.CashManagement);
  
  const now = DateTime.now().toJSDate()
  const newDate = startDate
  newDate.setHours(now.getHours())
  newDate.setMinutes(now.getMinutes())
  newDate.setSeconds(now.getSeconds())


  if (payload) {
    const id = payload.id
    const uuid = payload.uuid
    const history = payload.history
    const user = yield call(API.Collaborators.GetCollaborator, uuid)

    yield getURLParamsSaga(history);
    yield putResolve(actions.UserCashManagement.setUserID(uuid))
    yield putResolve(actions.UserCashManagement.getStatement())

    //if the URL brings an id, we are seeing an already existing movement
    if (payload.id) {
      yield putResolve(actions.UserCashMovementForm.setHasEditPermission(hasPermission(permissions, PERMISSIONS.INVOICING.CASH_MANAGEMENT.EDIT_BALANCE)))
      yield putResolve(actions.UserCashMovementForm.setAction(true))
      yield putResolve(actions.UserCashMovementForm.setEntryID(id))
      yield putResolve(actions.Dashboard.setDynamicBreadcrumb({
        path: `invoicing/cashManagement/${user?.name}/editMovement`,
        name: user?.name,
      }))

      try {
        const movement: StatementItem = yield call(API.Invoicing.GetEntry, id)

        if (movement) {
          yield putResolve(actions.UserCashMovementForm.setEntry(movement))
          yield putResolve(actions.UserCashMovementForm.populateForm(movement))
          yield getMovementPhotosSaga(id)
        }
      } catch (error) {
        toast.error(i18n.t("toasts.getEntryError"))
      }
    }
    else {
      //creating a new movement
      yield putResolve(actions.UserCashMovementForm.setHasEditPermission(true))
      yield putResolve(actions.UserCashMovementForm.setAction(false))
      yield putResolve(actions.UserCashMovementForm.setProcessedByOnMount(uuid))
      yield putResolve(actions.UserCashMovementForm.setMovementDate(DateTime.fromJSDate(newDate).toISO()))
      yield putResolve(actions.Dashboard.setDynamicBreadcrumb({
        path: `invoicing/cashManagement/${user?.name}/newMovement`,
        name: user?.name,
      }))
    }
  }
}

function* onSubmitSaga() {
  yield putResolve(actions.UserCashMovementForm.setSubmitButtonState(true))

  const { entryForm, isEdit, entryID } = yield select((state: RootState) => state.UserCashMovementForm)
  const { userID } = yield select((state: RootState) => state.UserCashManagement)
  const { startDate } = yield select((state: RootState) => state.CashManagement)
  const now = DateTime.now().toJSDate()
  const newDate = startDate
  newDate.setHours(now.getHours())
  newDate.setMinutes(now.getMinutes())
  newDate.setSeconds(now.getSeconds())

  const validation = Check.checkValidation(entryForm, EntryFormValidations)

  if (!validation.invalid) {

    if (isEdit) {

      try {
        const finalForm = {
          amount: entryForm.amount.value,
          description: entryForm.description.value,
          balanceEntryType: entryForm.balanceEntryType.value,
          paymentType: entryForm.paymentType.value, // must be the uuid
          processedBy: entryForm.processedBy.value,
          supplier: entryForm.supplier.value,
        }

        const response = yield call(API.Invoicing.EditEntry, entryID, finalForm)
        if (response) {
          yield putResolve(actions.App.navigateTo(`/faturacao/gestao-caixa/${userID}`))
          yield putResolve(actions.UserCashMovementForm.setSubmitButtonState(false))
          toast.success(i18n.t("toasts.editEntrySuccess"));
        }
      } catch (error) {
        yield putResolve(actions.UserCashMovementForm.setSubmitButtonState(false))
        toast.warning(i18n.t("toasts.editEntryError"))
      }

    }
    else {
      try {
        const finalForm = {
          amount: entryForm.amount.value,
          description: entryForm.description.value,
          balanceEntryType: entryForm.balanceEntryType.value,
          paymentType: entryForm.paymentType.value,
          processedBy: entryForm.processedBy.value,
          supplier: entryForm.supplier.value,
          date: DateTime.fromJSDate(newDate).toISO()
        }

        const response = yield call(API.Invoicing.CreateEntry, finalForm)

        //because its a new movement, we can only add photos after the movement is created
        if (response) {

          yield putResolve(actions.UserCashMovementForm.setEntryID(response.id))
          yield CreateEntryPhotoSaga()
          toast.success(i18n.t("toasts.createEntrySuccess"));
        }
      } catch (error) {
        toast.warning(i18n.t("toasts.createEntryError"))
      }
      finally {
        yield putResolve(actions.App.navigateTo(`/faturacao/gestao-caixa/${userID}`))
      }
    }
  }
  else {
    yield putResolve(actions.UserCashMovementForm.updateEntryFormOnValidation(validation.form))
    yield putResolve(actions.UserCashMovementForm.setSubmitButtonState(false))
    toast.error(i18n.t("toasts.invalidFields"))
  }
}

function* CreateEntryPhotoSaga() {
  const { photos, entryID, isEdit, entryForm } = yield select((state: RootState) => state.UserCashMovementForm);

  const validation = Check.checkValidation(entryForm, EntryFormValidations)

  if (!validation.invalid) {
    try {

      photos.forEach(async (photo, idx) => {
        if (photo.new === true) {
          const body = {
            balanceEntry: entryID,
            sort: idx + 1,
            file: photo.file
          }
          await API.Invoicing.AddEntryPhoto(body)
        }
        else {
          if (photo.edited === true) {
            const body = {
              balanceEntry: entryID,
              sort: idx + 1,
            }
            await API.Invoicing.EditEntryPhoto(photo.id, body)
          }
        }
      })

    } catch (error) {
      toast.warning(i18n.t("toasts.createUserStatementPhotoError"))
    }
    finally {
      if (isEdit) {
        yield onSubmitSaga()
      }
    }
  }
  else {
    yield putResolve(actions.UserCashMovementForm.updateEntryFormOnValidation(validation.form))
    toast.error(i18n.t("toasts.invalidFields"))
  }

}

function* createTicketSaga() {
  const { isEdit, entryID } = yield select((state: RootState) => state.UserCashMovementForm)

  try {
    if (isEdit) {
      const generate = yield call(API.Invoicing.PrintTicket, entryID)
      if (generate) {
        yield putResolve(actions.UserCashMovementForm.downloadTicket({ file: generate }))
        toast.success(i18n.t("toasts.printEntrySuccess"));
      }
    }
  } catch (error) {
    toast.error(i18n.t("toasts.printEntryError"));
  }
}

function* getMovementPhotosSaga(payload: AnyAction) {

  const movementID = Number(payload)

  if (movementID) {
    try {
      const photos = yield call(API.Invoicing.ListEntryPhotos, movementID)

      if (photos) {
        yield putResolve(actions.UserCashMovementForm.populatePhotosForm({ photos, movementID }))
      }
    } catch (error) {
      toast.error(i18n.t("toasts.getUserStatementPhotosError"))
    }
  }
}

function* deletePhotoSaga({ payload }: AnyAction) {
  const index = payload.index
  const photosArray = payload.photos


  if (index !== null && photosArray[index].new === false) {
    try {
      const del = yield call(API.Invoicing.DeleteEntryPhoto, photosArray[index].id)

      if (del.status === 204) {
        toast.success(i18n.t("toasts.deletedPhoto"))
      }

    } catch (error) {
      toast.error(i18n.t("toasts.removePhotoError"))
    }
  }
}

function* onUnmountSaga() {
  yield putResolve(actions.Dashboard.setDynamicBreadcrumb(null))
}

function* getURLParamsSaga(payload: AnyAction) {
  let params = new URLSearchParams(payload.location.search)

  const {
    startDate,
  } = yield select((state: RootState) => state.CashManagement);

  if (params.toString() !== "") {
    //yield putResolve(actions.UserCashMovementForm.setMovementDate(DateTime.fromISO(params.get('date')).toISO() ?? DateTime.now().toISO()))
  }
  else {
    params.set('date', DateTime.fromJSDate(startDate).toISODate())
    payload.replace({ search: (params).toString() })
  }
}

export default function* watcherSignin() {
  yield takeLatest('Usercashmovementform/onMount', onMountSaga)
  yield takeLatest('Usercashmovementform/onUnmount', onUnmountSaga)
  yield takeLatest('Usercashmovementform/createEntryPhoto', CreateEntryPhotoSaga)
  yield takeLatest('Usercashmovementform/generateTicket', createTicketSaga)
  yield takeLatest('Usercashmovementform/deletePhotoFromArray', deletePhotoSaga)
  yield takeLatest('Usercashmovementform/onSubmit', onSubmitSaga)
}


