import { CTableDataCell, CTableRow } from '@coreui/react-pro'
import styled from 'styled-components'
import theme from 'ui/theme'


const CustomCTableRow = styled(CTableRow)`
    max-height: 54px;
    background-color: ${theme().white};
    border-bottom: 1px solid ${theme().gray200Color};

    > td {
        vertical-align: middle;
        font-family: Inter;
        font-size: 14px;
        color: ${theme().gray700Color};
    }

    td:first-child {
        padding-left:15px;
    }
`
const ActionsCell = styled(CTableDataCell)`
  height: 54px;
  
  > svg {
    cursor: pointer;
    margin-right: 15px;
  }
` 

const AmountNumber = styled.p<{positive: boolean}>`
  margin:0px;
  color: ${props => props.positive ? theme().success : theme().danger}
`

const InvoicingTypeLabel = styled.span`
    padding:2px 4px;
    color:${theme().white};
    font-weight:700;
    border-radius:5px;

    &.pending {
        background-color: ${theme().warning};
    }
    &.paid {
        background-color: ${theme().success};
    }
    &.refund {
      background-color: ${theme().danger};
    }
`

export {
  CustomCTableRow,
  ActionsCell,
  AmountNumber,
  InvoicingTypeLabel
}