/**
 * Customtextinput component
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */

import React from 'react'
import styled from 'styled-components'
import { CFormLabel, CFormInput, CFormText } from '@coreui/react-pro';
import theme from 'ui/theme';
import transparent from 'assets/images/transparent.png';

interface PropTypes {
  id?: string,
  wrapperStyle?: React.CSSProperties,
  required?: boolean,
  label?: string,
  value?: string,
  onChange?: Function,
  defaultValue?: string,
  disabled?: boolean,
  valid?: boolean,
  helpText?: string,
}

const ColorPicker = (props: PropTypes) => {

  const ref = React.useRef<HTMLInputElement>(null);

  return (
    <Wrapper style={props.wrapperStyle}>
      {props.label && <CFormLabel>{props.label}<span>{!!props.required ? "*" : ""}</span></CFormLabel>}
      {(!props.value || props.value === "") && <TransparentImage id={`${props.id}-transparent`} onClick={() => { if (ref.current) ref.current.click() }}><div /></TransparentImage>}
      <FormInput id={`${props.id}-input`} ref={ref} value={props.value} disabled={props.disabled === true ? true : false} type="color" onChange={e => props.onChange ? props.onChange(e) : null} defaultValue={props.defaultValue} />
      {props.helpText && <HelpText valid={props.valid?.toString()}>{props.helpText}</HelpText>}
    </Wrapper>
  )
}

export default ColorPicker

const TransparentImage = styled.div`
  position: relative;
  top: 19px;
  max-width: 98%;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
  margin-top: -10px;

  > div {
    height: 10px;
    background-image: url(${transparent});
    background-position: center;
    background-size: 160px;
    border: 1px solid ${theme().gray300Color};
    border-radius: 3px;
  }
`

const Wrapper = styled.div`

  > * {
    font-family: 'Inter';
  }

  > label {
    font-size: 14px;
    color: ${theme().darkColor};
    > span {
      color: ${theme().primary};
    }
  }
`

const FormInput = styled(CFormInput)`
    max-width:100%;
    font-size:0.4rem;
    padding-top: 0.5rem;
    padding-bottom: 0.4rem;
    &:focus{
        box-shadow: 0px 0px 0px 0px ${theme().gray200Color};
        border-color:${theme().gray600Color};
    }
`

const HelpText = styled(CFormText) <{ valid: string | undefined }>`
  color: ${(props) => props.valid === "false" ? theme().danger : '#D8DBE0'};
`