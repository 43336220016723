import styled from 'styled-components'
import theme from 'ui/theme'
import { CTableDataCell, CTableRow } from '@coreui/react-pro'

const CustomCTableRow = styled(CTableRow)`
  max-height: 54px;
  background-color: ${theme().white};
  border-bottom: 1px solid ${theme().gray200Color};

  > td {
    text-transform: capitalize;
    vertical-align: middle;
    font-family: Inter;
    font-size: 14px;
    color: ${theme().gray700Color}
  }

  td:first-child {
    padding-left:15px;
    width:23%;
  }

  td:nth-child(2){
    width:15%;
  }

  td:nth-child(3){
    width:7%;
  }

  td:nth-child(4){
    width:11%;
  }

  td:nth-child(5){
    width:12%;
    padding:0;
    padding-left: 7px;
  }
`

const ActionsCell = styled(CTableDataCell)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 49px;

  > svg {
    cursor: pointer;
    margin-right: 15px;
  }

  td:nth-child(3){
    width:30%;
  }
`
const ChevronCell = styled.div`
  display:flex;
  justify-content: flex-end;
  cursor: pointer;
  width: 45px;
`

export { CustomCTableRow, ActionsCell, ChevronCell }