import * as React from "react"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="10" cy="10" r="10" fill="#E55353" />
            <path d="M14.6668 6.2735L13.7268 5.3335L10.0002 9.06016L6.2735 5.3335L5.3335 6.2735L9.06016 10.0002L5.3335 13.7268L6.2735 14.6668L10.0002 10.9402L13.7268 14.6668L14.6668 13.7268L10.9402 10.0002L14.6668 6.2735Z" fill="white" />
        </svg>

    )
}

export default SvgComponent


