import { BookingPassengerTypeValidations } from "scenes/BookingForm/utils";
import { BookingPassengerTypeForm, TripActivityLeg } from "types/trips/bookings";
import * as Check from 'validations';

//* PASSENGER TYPES 
//* Here we have all the utility functions that handle the passenger types logic

//* Here we update the value of the passenger types
export const updatePassengerTypes = (outboundLeg: TripActivityLeg, inboundLeg: TripActivityLeg, currentPassengerTypes: BookingPassengerTypeForm[], allowOverbooking, bookedPassengers?: number) => {
  const hasOutbound = outboundLeg.id > 0;
  const hasInbound = inboundLeg.id > 0;

  const isOutboundPrivate = hasOutbound && outboundLeg.activity.isPrivate;
  const isInboundPrivate = hasInbound && inboundLeg.activity.isPrivate;

  //* Here we get an array of passenger type id's from the outbound activity
  let outboundActivePTypes: number[] = [];
  if (hasOutbound) {
    outboundActivePTypes = outboundLeg.activity.passengerTypes.map((el) => el.id);
  }

  //* Here we get an array of passenger type id's from the inbound activity
  let inboundActivePTypes: number[] = [];
  if (hasInbound) {
    inboundActivePTypes = inboundLeg.activity.passengerTypes.map((el) => el.id);
  }

  /* The bellow code is checking if the `outboundLeg.id` and `inboundLeg.id` are greater than 0. If both
  are greater than 0, it filters the `outboundActivePTypes` array to only include elements that are
  also present in the `inboundActivePTypes` array and assigns the result to the `allActivePTypes`
  array. */
  let allActivePTypes: number[] = [];
  if (hasOutbound && hasInbound) {
    allActivePTypes = outboundActivePTypes.filter((p) => inboundActivePTypes.includes(p));
  } else if (hasOutbound) {
    allActivePTypes = outboundActivePTypes;
  } else {
    allActivePTypes = inboundActivePTypes;
  }

  let newPTypes = currentPassengerTypes.map((c) => {
    if (allActivePTypes.includes(c.id)) {
      let outPType;
      if (hasOutbound) {
        outPType = outboundLeg.activity.passengerTypes.find((p) => p.id === c.id);
      }

      let inPType;
      if (hasInbound) {
        inPType = inboundLeg.activity.passengerTypes.find((p) => p.id === c.id);
      }

      let newPType: BookingPassengerTypeForm = {
        ...c,
        quantity: { ...c.quantity, value: c.quantity.value !== '-' ? c.quantity.value : '0' },
        isDisabled: false,
      };

      if (outPType && inPType) {
        newPType.priceOutbound = +outPType.price;
        newPType.priceInbound = +inPType.price;
        newPType.totalPriceOutbound = (outPType.price * Number(newPType.quantity.value)).toFixed(2);
        newPType.totalPriceInbound = (inPType.price * Number(newPType.quantity.value)).toFixed(2);
        newPType.price = Number(outPType.price) + Number(inPType.price);
        newPType.maxCapacity = Math.min((inboundLeg.availableTickets + (bookedPassengers ?? 0)), (outboundLeg.availableTickets + (bookedPassengers ?? 0)));
        newPType.totalPrice = (Number(newPType.totalPriceOutbound) + Number(newPType.totalPriceInbound)).toFixed(2);
      } else if (outPType) {
        newPType.priceOutbound = +outPType.price;
        newPType.totalPriceOutbound = (outPType.price * Number(newPType.quantity.value)).toFixed(2);
        newPType.price = +outPType.price;
        newPType.maxCapacity = outboundLeg.availableTickets + (bookedPassengers ?? 0);
        newPType.totalPrice = Number(newPType.totalPriceOutbound).toFixed(2);
      } else {
        newPType.priceInbound = +inPType.price;
        newPType.totalPriceInbound = (inPType.price * Number(newPType.quantity.value)).toFixed(2);
        newPType.price = +inPType.price;
        newPType.maxCapacity = inboundLeg.availableTickets + (bookedPassengers ?? 0);
        newPType.totalPrice = Number(newPType.totalPriceInbound).toFixed(2);
      }

      if (newPType.occupySeat) {
        const valid = +newPType.quantity.value <= newPType.maxCapacity;
        newPType.quantity.valid = valid || allowOverbooking;
      } else {
        newPType.quantity.valid = true;
      }

      return newPType;
    } else {
      return {
        ...c,
        isDisabled: true,
        quantity: { ...c.quantity, valid: true },
      };
    }
  });

  let totalPassengers = getTotalPassengersQty(newPTypes)

  if(isOutboundPrivate) {
    // Sort passenger types by price in descending order
    sortPassengerTypesByPrice(newPTypes, true)

    // Calculate included seats
    const includedSeats = +outboundLeg.includedSeats;

    if(totalPassengers > includedSeats) {
      updatePrivatePassengerPrices(newPTypes, includedSeats, true)
    } else {
      resetPassengersTotalLegPrice(newPTypes, true)
    }
  }

  if(isInboundPrivate) {
    // Sort passenger types by price in descending order
    sortPassengerTypesByPrice(newPTypes, false)

    // Calculate included seats
    const includedSeats = +inboundLeg.includedSeats;

    if(totalPassengers > includedSeats) {
      updatePrivatePassengerPrices(newPTypes, includedSeats, false)
    } else {
      resetPassengersTotalLegPrice(newPTypes, false)
    }
  }

  if(outboundLeg.activity.isHopOnHopOff) {
    removeInboundOrOutboundPrice(newPTypes, false)
  }

  return newPTypes;
};

//* This function checks if the current selected passenger are the same as the booked passengers
export const checkIfSelectedPassAreSameAsBookedPass = (bookedPTypes: BookingPassengerTypeForm[], selectedPTypes: BookingPassengerTypeForm[]) => {
  return bookedPTypes.every((bookedPasssenger) => {
    const newPassengerType = selectedPTypes.find((npt) => npt.id === bookedPasssenger.id)
    return bookedPasssenger.quantity.value === newPassengerType?.quantity.value
  })
}

//* This function removes the priceInbound or priceOutbound from the passenger types
export const removeInboundOrOutboundPrice = (passengerTypes: BookingPassengerTypeForm[], isOutbound: boolean) => {
  passengerTypes.forEach((passenger) => {
    if(isOutbound) {
      passenger.priceOutbound = 0;
      passenger.totalPriceOutbound = '0';
    } else {
      passenger.priceInbound = 0;
      passenger.totalPriceInbound = '0';
    }
    passenger.totalPrice = (Number(passenger.totalPriceOutbound) + Number(passenger.totalPriceInbound)).toFixed(2);
  });
};

//* Here we sort the passenger types by price in descending order
export const sortPassengerTypesByPrice = (passengerTypes: BookingPassengerTypeForm[], isOutbound) => {
  passengerTypes.sort((a, b) => {
    const priceA = isOutbound ? a.priceOutbound : a.priceInbound;
    const priceB = isOutbound ? b.priceOutbound : b.priceInbound;
    return priceB - priceA;
  });
};

//* Here we calculate the total passengers quantity
export const getTotalPassengersQty = (passengerTypes: BookingPassengerTypeForm[]) => {
  return passengerTypes.reduce((prev, curr) => {
    return prev + Number(curr.quantity.value);
  }, 0);
}
  
//* Here we reset the passenger prices
export const resetPassengersTotalLegPrice = (passengerTypes, isOutbound) => {
  passengerTypes.forEach((passenger) => {
    if (passenger.isDisabled) return;
    if(isOutbound) {
      passenger.totalPriceOutbound = '0'
    } else {
      passenger.totalPriceInbound = '0'
    }
      passenger.totalPrice = (Number(passenger.totalPriceOutbound) + Number(passenger.totalPriceInbound)).toFixed(2);
  });
};

//* Here we update the passenger prices based on the included seats
export const updatePrivatePassengerPrices = (passengerTypes: BookingPassengerTypeForm[], includedSeats, isOutbound) => {
  let occupiedIncludedSeats = 0;

  passengerTypes.forEach((passenger) => {
    if (passenger.isDisabled) return;

    const passengerPrice = isOutbound ? passenger.priceOutbound : passenger.priceInbound;
    const qty = +passenger.quantity.value;
    const freeSeats = includedSeats - occupiedIncludedSeats;

    if (qty <= freeSeats) {
      // All seats for this passenger type are within the included seats
      if(isOutbound) {
        passenger.totalPriceOutbound = '0'
      } else {
        passenger.totalPriceInbound = '0'
      }

      passenger.totalPrice = (Number(passenger.totalPriceOutbound) + Number(passenger.totalPriceInbound)).toFixed(2);
      occupiedIncludedSeats += qty;
    } else {
      //* Here we calculate the price of the chargable seats
      const chargeableSeats = qty - freeSeats;
      if(isOutbound) {
        passenger.totalPriceOutbound = (chargeableSeats * passengerPrice).toFixed(2);
      } else {
        passenger.totalPriceInbound = (chargeableSeats * passengerPrice).toFixed(2);
      }
      passenger.totalPrice = (Number(passenger.totalPriceOutbound) + Number(passenger.totalPriceInbound)).toFixed(2);
      occupiedIncludedSeats = includedSeats;
    }
  });
};

//* This function gets to total outbound or inbound price of all passenger
export const getTotalPassengerLegPrice = (passengerTypes: BookingPassengerTypeForm[], isOutbound: boolean): number => { 
  return passengerTypes.reduce((prev, curr) => {
    if (curr.isDisabled) return prev;
    return prev + Number(isOutbound ? curr.totalPriceOutbound : curr.totalPriceInbound);
  }, 0)
}

//* This function calculates the new passenger types
export const getNewPassengerTypes = (passengers: BookingPassengerTypeForm[], id, value, allowOverbooking) => {
  const newPTypes: BookingPassengerTypeForm[] = passengers.map((c) => {
    if (c.id === id) {
      let newPType: BookingPassengerTypeForm = { 
        ...c, 
        quantity: { 
          ...c.quantity,
          value: value.toString()
        }, 
      };

      if(newPType.priceInbound && newPType.priceOutbound) { 
        newPType.totalPriceOutbound = (value * newPType.priceOutbound).toString();
        newPType.totalPriceInbound = (value * newPType.priceInbound).toString();
        newPType.totalPrice = ((value * newPType.priceInbound) + (value * newPType.priceOutbound)).toString();
      } else if (newPType.priceInbound) {
        newPType.totalPriceInbound = (value * newPType.priceInbound).toString();
        newPType.totalPrice = (value * newPType.priceInbound).toString();
      } else {
        newPType.totalPriceOutbound = (value * newPType.priceOutbound).toString();
        newPType.totalPrice = (value * newPType.priceOutbound).toString();
      }

      if (c.occupySeat) {
        let valid = +newPType.quantity.value <= newPType.maxCapacity;
        newPType.quantity.valid = valid || allowOverbooking;
      }

      return newPType;
    } else {
      return { ...c, quantity: { ...c.quantity }, totalPrice: c.totalPrice };
    }
  });

  return newPTypes;
}

export const validatePassengerTypes = (
    selectedPassengerTypes: BookingPassengerTypeForm[],
    totalPassengers: number,
    allowsOverbooking: boolean,
    bookedTotalPassengers?: number,
  ) => {
    const validatePTypes = Check.checkValidation(
      selectedPassengerTypes,
      BookingPassengerTypeValidations
    );
    let foundErr = false;
    let validatedTypes = validatePTypes.form.map((p) => {
      if (!p.isDisabled) {
        if ((totalPassengers > (p.maxCapacity + bookedTotalPassengers) && !allowsOverbooking) || totalPassengers <= 0) {
          foundErr = true;
          return { ...p, quantity: { ...p.quantity, valid: false } };
        }
      }
  
      return p;
    });
  
    if (foundErr || validatePTypes.invalid) {
      return { invalid: true, form: validatedTypes };
    } else {
      return { invalid: false, form: validatedTypes };
    }
};