import * as React from "react"
import theme from "ui/theme";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    return (
      <svg 
        xmlns="http://www.w3.org/2000/svg" 
        width="24" 
        height="24" 
        viewBox="0 0 24 24" 
        fill="none"
      >
        <g id="receipt_error">
          <path 
            id="Vector" 
            d="M19.5 3.5L18 2L16.5 3.5L15 2L13.5 3.5L12 2L10.5 3.5L9 2L7.5 3.5L6 2V16H3V19C3 20.66 4.34 22 6 22H18C19.66 22 21 20.66 21 19V2L19.5 3.5ZM15 20H6C5.45 20 5 19.55 5 19V18H15V20ZM19 19C19 19.55 18.55 20 18 20C17.45 20 17 19.55 17 19V16H8V5H19V19Z" 
            fill={props.fill ? props.fill : theme().danger}
          />
          <path 
            id="Union" 
            fillRule="evenodd" 
            clipRule="evenodd" 
            d="M14.7107 10.5L16.7071 8.50359L15.4964 7.29291L13.5 9.28934L11.5036 7.29291L10.2929 8.50359L12.2893 10.5L10.2929 12.4964L11.5036 13.7071L13.5 11.7107L15.4964 13.7071L16.7071 12.4964L14.7107 10.5ZM11 8.50358L11 8.50359L12.9964 10.5L12.9964 10.5L11 8.50358ZM11 12.4964L11 12.4964L11.5036 13L13.5 11.0036L15.4964 13L16 12.4964L16 12.4964L15.4964 13L13.5 11.0036L11.5036 13L11 12.4964ZM14.0036 10.5L14.0036 10.5L16 8.50358L16 8.50359L14.0036 10.5Z" 
            fill={props.fill ? props.fill : theme().danger}
          />
        </g>
      </svg>
    )
}

export default SvgComponent
