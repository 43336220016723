/**
 * Customers scene
 *
 * @author João Dias <jdias@ubiwhere.com>
 *
 *
 */
import React, { useEffect } from 'react'
import { useDispatch, useSelector, /*useSelector*/ } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { actions, RootState } from 'store/rootSlices'
import SearchBox from 'shared/components/SearchBox'
import CustomButton from 'shared/components/CustomButton'
import Download from 'assets/icons/file_download';
import Loadingcontainer from 'shared/components/Loadingcontainer'
import TableContainer from 'shared/components/TableContainer'
import SorterComponent from 'shared/components/SorterComponent'
import Customerstablerow from './components/Customerstablerow'
import { Wrapper, Controls1, Controls2, ControlsContainer } from 'shared/genericStyledComponents'
import Feed from 'assets/icons/feed';
import Mail from 'assets/icons/mail';
import ManageAccounts from 'assets/icons/manage_accounts';
import Ticket from 'assets/icons/ticket';
import { CustomerListItem } from 'types/customers/customers'
import { paginationCurrentPage, paginationElementsCount } from 'utils'
import config from 'config'
import { hasPermission } from 'permissions'
import theme from 'ui/theme'


const Customers = ({ t, i18n }) => {

  const { loading, page, total, customers, searchTerm, sort, exportLoading } = useSelector((state: RootState) => state.Customers)

  const { onMount, onUnmount, setSearchTerm, setPage, setSort, getCustomersFile } = actions.Customers
  const { navigateTo } = actions.App
  const { permissions } = useSelector((state: RootState) => state.App)
  const { ELEMENTS_PER_PAGE, PERMISSIONS } = config
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {

    dispatch(onMount(history))
    return () => {
      dispatch(onUnmount())
    }
  }, [dispatch, onMount, onUnmount, history]);

  return (
    <Wrapper>
      <ControlsContainer>
        <Controls1>
          <SearchBox
            id="customer-searchBox"
            defaultValue={searchTerm}
            onSubmit={(term) => {
              let params = new URLSearchParams(history.location.search);
              term !== "" ? params.set('search', term) : params.delete('search')
              history.replace({ search: (params).toString() })
              dispatch(setSearchTerm(term))
            }}
          />
        </Controls1>
        <Controls2>
        {hasPermission(
          permissions,
          PERMISSIONS.USERS.LIST_CUSTOMER
        ) && (
          <div style={{ cursor: 'pointer', marginRight: '1rem' }} onClick={() => {
            if(!exportLoading) dispatch(getCustomersFile())
          }}>
            <Download width={30} height={30} fill={exportLoading ? theme().gray300Color : theme().gray700Color} />
          </div>
        )}
          <CustomButton id="customer-newButton" text={t('common.new')} icon="add" color="success" buttonStyle={{ color: "#FFFFFF" }} onClick={() => dispatch(navigateTo('/clientes/novo-cliente'))} />
        </Controls2>
      </ControlsContainer>
      {loading ?
        <Loadingcontainer />
        : <TableContainer
          count={paginationElementsCount(total)}
          currentPage={paginationCurrentPage(total, page)}
          elementsPerPage={ELEMENTS_PER_PAGE}
          onPageChange={pg => { dispatch(setPage(pg)); }}
          headerElements={[
            <ManageAccounts viewBox="0 0 18 18" fill={'#636f83'} />,
            <SorterComponent sort={sort} active={sort.field === 'name'} id="customer-customerSorter" filterName={t('customers.customer')} onChange={(dir) => dispatch(setSort({ field: "name", direction: dir }))} />,
            <SorterComponent sort={sort} active={sort.field === 'phone'} id="customer-phoneSorter" filterName={t('customers.phone')} onChange={(dir) => dispatch(setSort({ field: "phone", direction: dir }))} />,
            t('customers.email'),
            t('customers.country'),
            t('common.actions')
          ]}
        >
          {customers.map((el: CustomerListItem, idx: number) =>
            <React.Fragment key={`vehicle-list-${idx}`}>
              <Customerstablerow
                id={`customer-tablerow-${idx}`}
                permission={hasPermission(permissions, PERMISSIONS.USERS.VIEW_CUSTOMER)}
                onClick={() => {
                  if (hasPermission(permissions, PERMISSIONS.USERS.VIEW_CUSTOMER)) {
                    dispatch(actions.App.navigateTo(`/clientes/editar-cliente/${el.uuid}`))
                  }
                }}
                customer={el}
                actions={
                  <React.Fragment>
                    <Ticket className='pointer' onClick={() => {
                      dispatch(actions.App.navigateTo(`/clientes/historico-compras/${el.uuid}`))
                    }} />
                    <Feed fill={`${theme().secondaryColor}`} />
                    <Mail fill={`${theme().secondaryColor}`} />
                  </React.Fragment>
                }
              />
            </React.Fragment>
          )}
        </TableContainer>}
    </Wrapper>
  )
}

export default Customers
