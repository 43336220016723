/**
 * Fuellinglisttablerow component
 *
 * @author João Dias <jdias@ubiwhere.com>
 *
 *
 */

import React from 'react'
import { 
  CustomTableRow, 
  ActionsCell,
  ChevronCell,
} from 'shared/genericStyledComponents'
import { DateTime } from "luxon"
import { CTableDataCell } from '@coreui/react-pro'
import { TableRowPropTypes } from 'types/misc'
import ChevronRight from 'assets/icons/chevron_right'
import GaugeComponent from 'shared/components/GaugeComponent'
import { FuellingListItem } from 'types/fleet/fuel'
import { actions } from 'store/rootSlices'
import { useDispatch } from 'react-redux';
import config from 'config'

type PropTypes = TableRowPropTypes & {
  id?: string,
  refuelling: FuellingListItem,
  permission: boolean
}

const Consumptionlisttablerow = (props: PropTypes) => {

  const dispatch = useDispatch()
  const {
    permission,
    refuelling: {
      fuelStation,
      litres,
      fuelDate,
      id,
      vehicleFuelLevel,
      fuelledBy
    }
  } = props;
  
  return (
    <CustomTableRow id={props.id}>
      <CTableDataCell>{props.refuelling.vehicle ? props.refuelling.vehicle.shortName : props.refuelling.tank.name}</CTableDataCell>
      <CTableDataCell>{ fuelledBy ? fuelledBy?.name : ''}</CTableDataCell>
      <CTableDataCell>{litres}</CTableDataCell>
      <CTableDataCell>{fuelStation.name}</CTableDataCell>
      <CTableDataCell>{DateTime.fromISO(fuelDate).toFormat(config.DATE_FORMAT.DATETIME)}</CTableDataCell>
      <CTableDataCell>
        <GaugeComponent unit="liters" currentValue={
          props.refuelling.vehicle ?
          vehicleFuelLevel
          :
          Number.parseInt(props.refuelling.tank?.fuelLevel)
        }
          maxValue={
            props.refuelling.vehicle ?
              Number.parseInt(props.refuelling.vehicle?.fuelCapacity)
              :
              Number.parseInt(props.refuelling.tank?.fuelCapacity)
          } />
      </CTableDataCell>
      <ActionsCell>
        {props.actions}
        <ChevronCell id={`${props.id}-chevron`} >
        {
            permission &&
            <a href={`combustivel/editar-abastecimento/${id}`} onClick={(e) => {
              e.preventDefault()
              dispatch(actions.App.navigateTo(`combustivel/editar-abastecimento/${id}`))
              }}>
              <ChevronRight></ChevronRight>
            </a>
        }
        </ChevronCell>
      </ActionsCell>
    </CustomTableRow>
  )
}

export default Consumptionlisttablerow
