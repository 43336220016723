/**
 * Usertimerecorddetails scene utils
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */

import * as Check from 'validations';
import { Duration } from 'luxon'

export const TimeRecordValidations = {
    day: [Check.isValidDate],
    startTimeRecord: [Check.isRequired, Check.isCorrectTimeFormat],
    endTimeRecord: [Check.isRequired, Check.isCorrectTimeFormat],
    entryType: [Check.isRequired],
}

export const TimeRecordClockInValidations = {
    day: [Check.isValidDate],
    entryType: [Check.isRequired],
    startTimeRecord: [Check.isRequired, Check.isCorrectTimeFormat],
}

export const TimeRecordMealValidations = {
    day: [Check.isValidDate],
    entryType: [Check.isRequired],
}

export const TimeRecordAbsenseValidations = {
    day: [Check.isValidDate],
    entryType: [Check.isRequired],
    absenseType: [Check.isRequired]
}

export const TimeRecordClockOutValidations = {
    day: [Check.isValidDate],
    entryType: [Check.isRequired],
    endTimeRecord: [Check.isRequired, Check.isCorrectTimeFormat],
}

export const TimeRecordEditValidations = {
    start: [Check.isRequired, Check.isCorrectTimeFormat],
    end: [Check.isRequired, Check.isCorrectTimeFormat],
}


export const LineChartConfig = {
    plugins: {
        legend: {
            display: true,
        },
        tooltip: {
            enabled: true,
            callbacks: {
                label: (tooltipItem: any) => {
                    return `${tooltipItem.dataset.label}: ${tooltipItem.raw}`;
                }
            },
        },
    },
    scales: {
        y: {
            min: 0,
            suggestedMax: 10,
        }
    },
}

export const convertMinutesToHours = (value: number) => {
    return Duration.fromISO(`PT${value}M`).toFormat('hh:mm');
}

export const convertMinutesToHoursAlternate = (value: number) => {
    const num = value;
    const hours = (num / 60);
    const rhours = Math.floor(hours);
    const minutes = (hours - rhours) * 60;
    const rminutes = Math.round(minutes);

    return rhours + "h " + rminutes + "m";
}

export const allAbsenseTypes = (i18n) => {
    
    return [
        { label: i18n.t('timeRecord.dayLeave'), value: "day_leave" },
        { label: i18n.t('timeRecord.vacation'), value: "vacation" },
        { label: i18n.t('timeRecord.holiday'), value: "holiday" },
        { label: i18n.t('timeRecord.noShow'), value: "no_show" },
        { label: i18n.t('timeRecord.medicalLeave'), value: "medical_leave" },
        { label: i18n.t('timeRecord.badWeather'), value: "bad_weather" },
        { label: i18n.t('timeRecord.other'), value: "other" },
    ]
}
