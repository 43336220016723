import * as React from "react"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M1.41 7.5L6 2.92L10.59 7.5L12 6.09L6 0.0899996L1.23266e-07 6.09L1.41 7.5Z" fill="#8A93A2" />
        </svg>
    )
}

export default SvgComponent
