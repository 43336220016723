/**
 * Customerform scene sagas
 *
 * @author João Dias <jdias@ubiwhere.com>
 *
 *
 */
import { PayloadAction } from '@reduxjs/toolkit';
import { call, putResolve, select, takeLatest } from 'redux-saga/effects'
import * as Check from "validations";
import { toast } from 'react-toastify';
import i18n from 'i18next';
// Types
import type { Customer } from 'types/customers/customers';
import type { OnChangePasswordSaveSagaPayload, OnCreateUserAuthSagaPayload } from 'types/customers/customerForm';
// Store & API
import { actions, RootState } from 'store/rootSlices';
import API from 'api';
// Utils
import { CustomerFormValidations, defaultCustomerForm, defaultChangePasswordForm, CredentialsFormValidations } from '../utils';
// Configs
import config from 'config';
import { hasPermission } from 'permissions'
const { PERMISSIONS } = config



function* onMountSaga({ payload }: PayloadAction<string>) {
  const { permissions } = yield select((state: RootState) => state.App);

  try {
    yield putResolve(actions.App.getCountryCodes());
    if (payload) {
      yield putResolve(actions.Customerform.setEditMode(true));
      yield putResolve(actions.Customerform.setHasEditPermission(hasPermission(permissions, PERMISSIONS.USERS.EDIT_CUSTOMER)))
      yield putResolve(actions.Customerform.getCustomer(payload));
    } else {
      yield putResolve(actions.Customerform.setEditMode(false));
      yield putResolve(actions.Customerform.setForm(defaultCustomerForm));
    }
  } finally {
    yield putResolve(actions.Documentconfigform.setLoading(false));
  }
}

function* onUnmountSaga() {
  yield putResolve(actions.Customerform.setForm(defaultCustomerForm));
}

function* getUserAuthSaga({ payload }: PayloadAction<string>) {
  try {
    const userAuthInfo = yield call(API.Auth.GetUser, { uuid: payload, userType: 'customer'})

    if (userAuthInfo.username !== null) {
      yield putResolve(actions.Customerform.setUserAuthExists(true))
    }
    else {
      yield putResolve(actions.Customerform.setUserAuthExists(false))
    }
  } catch (error) {
    yield putResolve(actions.Customerform.setUserAuthExists(false))
  }
}

function* getCustomerSaga({ payload }: PayloadAction<string>) {
  try {
    const res: Customer = yield call(API.Customers.GetSingleCustomer, payload);
    yield putResolve(actions.Customerform.setCustomer(res));
    yield putResolve(actions.Customerform.getUserAuth(res.uuid))
  } catch (error) {
    toast.error(i18n.t('toasts.getCustomerError'));
  } finally {
    yield putResolve(actions.Customerform.setLoading(false));
  }
}

function* setCustomerSaga({ payload }: PayloadAction<Customer>) {
  const form = {};
  Object.keys(defaultCustomerForm).forEach(key => {
    form[key] = {
      ...defaultCustomerForm[key],
      value: payload[key]
    }
  });
  yield putResolve(actions.Customerform.setForm(form));
}

function* onSubmitSaga() {
  const { customerForm, edit, customer, isAvatarChanged, isAvatarRemoved } = yield select((state: RootState) => state.Customerform);
  yield putResolve(actions.Customerform.setSubmitButtonDisabled(true))

  const validation = Check.checkValidation(customerForm, CustomerFormValidations);
  if (validation.invalid) {
    yield putResolve(actions.Customerform.setForm(validation.form));
    yield putResolve(actions.Customerform.setSubmitButtonDisabled(false))
    toast.error(i18n.t('toasts.invalidFields'));
    return false;
  }
  const body = {
    name: customerForm.name.value,
    observations: customerForm.observations.value,
    phone: customerForm.phone.value,
    email: customerForm.email.value,
    address: customerForm.address.value,
    country: customerForm.country.value,
    postal_code: customerForm.postalCode.value,
    city: customerForm.city.value,
    nif: customerForm.nif.value,
    avatar: customerForm.avatar.value
  }

  try {
    yield putResolve(actions.Customerform.setLoading(true));
    if (edit) {
      const res = yield call(API.Customers.EditCustomer, customer.uuid, body, isAvatarChanged, isAvatarRemoved);
      toast.success(i18n.t('toasts.editedCustomerSuccess'));
      yield putResolve(actions.Customerform.setCustomer(res));
    } else {
      const res = yield call(API.Customers.CreateCustomer, body);
      if (res.status === 201) {
        toast.success(i18n.t('toasts.createCustomerSuccess'));
        yield putResolve(actions.App.navigateTo("/clientes"));
      }
    }
  } catch (error) {
    const err: any = error
    if(err.response.status === 400) {

      if(err.response?.data?.errors?.fields?.email === 'not_unique'){
        toast.error(i18n.t("toasts.duplicateEmailUser"))
      } else {
        edit ? toast.error(i18n.t('toasts.editCustomerError')) : toast.error(i18n.t('toasts.createCustomerError'))
      }      
    }
    if(err.response.status === 500) {
      edit ? toast.error(i18n.t('toasts.editCustomerError')) : toast.error(i18n.t('toasts.createCustomerError'))
    }
  } finally {
    yield putResolve(actions.Customerform.setLoading(false));
    yield putResolve(actions.Customerform.setSubmitButtonDisabled(false))
  }
  
}

function* onReSendEmailSaga() {
  const { customerForm } = yield select((state: RootState) => state.Customerform);

  try {
    const status = yield call(API.Auth.SendEmail, {
      userType: 'customer',
      email: customerForm.email.value
    })

    if (status) {
      toast.info(i18n.t("toasts.emailSent"))
    }
  } catch (error) {
    toast.warning(i18n.t("toasts.errorSendingEmail"));
  }
}

function* onChangePasswordSaveSaga({ payload }: PayloadAction<OnChangePasswordSaveSagaPayload>) {

  const { customer } = yield select((state: RootState) => state.Customerform);

  const newPassword = payload.changePasswordForm.newPassword.value
  const confirmPassword = payload.changePasswordForm.confirmPassword.value

  //object for validation
  const accessData = {
    confirmPassword: payload.changePasswordForm.confirmPassword,
    newPassword: payload.changePasswordForm.newPassword,
  }

  //validate form
  const validation = Check.checkValidation(accessData, CredentialsFormValidations)

  if (validation.invalid) {
    yield putResolve(actions.Customerform.onChangePasswordFormChange({ 
      newPassword: validation.form.newPassword,
      confirmPassword: validation.form.confirmPassword
    }))
    toast.error(i18n.t("toasts.invalidFields"))
    return false;
  }

  if(newPassword !== confirmPassword) {
    return toast.error(i18n.t("toasts.passwordsDoNotMatch"))
  }

  //save new password on userForm
  yield putResolve(actions.Customerform.setShowChangePasswordModal(false))

  if (confirmPassword !== "") {
    yield putResolve(actions.Customerform.createUserAuth({
      password: confirmPassword,
      userType: 'customer',
      uuid: customer.uuid
    }))
  }

}

function* onCreateUserAuthSaga({ payload }: PayloadAction<OnCreateUserAuthSagaPayload>) {

  try {
    const res = yield call(API.Auth.CreateUser, payload)

    if (res) {
      toast.success(i18n.t("toasts.credentialsCreated"))
      yield putResolve(actions.Customerform.setUserAuthExists(true))
      yield putResolve(actions.Customerform.onChangePasswordFormChange(defaultChangePasswordForm))
    }
  } catch (error) {
    toast.error(i18n.t("toasts.credentialsCreationError"));
  }
}

export default function* watcherSignin() {
  yield takeLatest('Customerform/onMount', onMountSaga)
  yield takeLatest('Customerform/onUnmount', onUnmountSaga)
  yield takeLatest('Customerform/getCustomer', getCustomerSaga)
  yield takeLatest('Customerform/setCustomer', setCustomerSaga)
  yield takeLatest('Customerform/onSubmit', onSubmitSaga)
  yield takeLatest('Customerform/getUserAuth', getUserAuthSaga)
  yield takeLatest('Customerform/onReSendEmail', onReSendEmailSaga)
  yield takeLatest('Customerform/onChangePasswordSave', onChangePasswordSaveSaga)
  yield takeLatest('Customerform/createUserAuth', onCreateUserAuthSaga)
}


