import styled from 'styled-components'
import theme from 'ui/theme';

const Wrapper = styled.div`
  &:hover th {
    cursor: pointer;    
  }
  
`

const Controls2 = styled.div`
  margin-bottom:28px;
  display:flex;
  justify-content:space-between;
`

const FilterControls = styled.div`
  display:flex;
  justify-content: space-between;
  column-gap: 10%;
  padding-bottom: 12px;
`

const Title = styled.h6`
  font-size: 19px;
  font-weight:500;
  color:${theme().darkColor}
`

export {
  Wrapper,
  Controls2,
  FilterControls,
  Title
}