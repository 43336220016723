/**
 * Invoicingsummary scene slice
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */
import { createSlice } from '@reduxjs/toolkit'
import { SortType, ItemsList } from 'types/misc'
import { BalanceListItem, MovementsList } from "types/invoicing/invoices"

interface InitialState {
  page: number,
  searchTerm: string,
  filter: string,
  paymentTypeFilter: string,
  movementTypeFilter:string,
  sort: SortType,
  totalEntries: number,
  loading: boolean,
  exportLoading: boolean,
  showModal: boolean,
  entries: BalanceListItem[],
  counters:{
    expenses: string,
    income: string,
    total: string
  },
  hasDateParams: boolean,
  startDate: string,
  endDate: string,
  paymentTypes: ItemsList,
  movementTypes: MovementsList,
}

const now = new Date().toISOString().slice(0, 10);
const initialState: InitialState = {
  page: 1,
  searchTerm:"", 
  filter:"", 
  paymentTypeFilter: "",
  movementTypeFilter:"",
  sort: {
    field: null,
    direction: null,
  },
  totalEntries: 0,
  loading: false,
  exportLoading: false,
  showModal: false,
  entries: [],
  counters:{
    expenses:"--",
    income:"--",
    total:"--"
  },
  hasDateParams: false,
  startDate: now,
  endDate: now,
  paymentTypes:[] as any,
  movementTypes: [] as any
}

export default createSlice({
  name: 'InvoicingSummary',
  initialState,
  reducers: {
    onMount: (state, { payload }) => { },
    onUnmount: () => { },
    setPage: (state, { payload }) => {
      state.page = payload;
    },
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
    setExportLoading: (state, { payload }) => {
      state.exportLoading = payload;
    },
    setTotalEntries:(state, { payload }) => {
      state.totalEntries = payload
    },
    getEntries: () => { },
    setEntries: (state, { payload }) => {
      state.entries = payload;
    },
    setCounters: (state, { payload }) => {
      state.counters.expenses = payload.expenses !== '0.00' ? payload.expenses : '--';
      state.counters.income = payload.income !== '0.00' ? payload.income : '--';
      state.counters.total = payload.total !== '0.00' ? payload.total : '--';
    },
    getPaymentTypes: () => {},
    getMovementTypes: () => {},
    setPaymentTypes: (state, { payload }) => {
      if(Array.isArray(payload)){
        state.paymentTypes = payload;
      }
    },
    setSearchTerm:( state, {payload} ) => {
      state.page = 1
      state.searchTerm = payload
    },
    setFilter:(state, {payload}) => {
      state.page = 1
      state.filter = payload
    },
    setPaymentTypeFilter:(state, {payload}) => {
      state.page = 1
      state.paymentTypeFilter = payload
    },
    setDatesInterval: (state, { payload }) => {
      state.page = 1
    },
    getURLParams:(state, payload) => {},
    setSort: (state, { payload }) => {
      state.sort = payload;
    },
    setHasDateParams:(state, { payload }) => {
      state.hasDateParams = payload
    },
    setStartDate:(state, { payload }) => {
      state.startDate = payload
    },
    setEndDate:(state, { payload }) => {
      state.endDate = payload
    },
    populateFiltersOnMount:(state, { payload }) => {
      state.searchTerm = payload.searchTerm
      state.filter = payload.filter
      state.paymentTypeFilter = payload.paymentTypeFilter
      state.startDate = payload.startDate
      state.endDate = payload.endDate
      state.movementTypeFilter = payload.movementTypeFilter
    },
    exportStatements: () => { },
    downloadStatements: (state, { payload }) => {
      const file = payload
      const fileName = `statements-list.xlsx`
      const finalFileName = fileName.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(" ", "");

      const url = URL.createObjectURL(file)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute(
        'download',
        finalFileName
      )
      document.body.appendChild(link);
      link.click()
      link.parentNode?.removeChild(link)
    },
    setMovementTypes: (state, { payload }) => {
      if(Array.isArray(payload)){
        state.movementTypes = payload;
      }
    },
    setMovementTypeFilter:(state, {payload}) => {
      state.page = 1
      state.movementTypeFilter = payload
    },
  }
})
