import { CButtonGroup, CFormCheck } from '@coreui/react-pro'
import styled from 'styled-components'
import theme from 'ui/theme'

export const SearchBoxWrapper = styled.div`
  padding:0;  
  margin-bottom: 5px;

  > div {
    width: 95%;
    max-width: unset !important;
  }
`

export const TabsWrapper = styled(CButtonGroup)`
  display:flex;
  justify-content: flex-start;
  background-color: ${theme().white};

  .btn-check:checked+.btn {
    background-color: ${theme().gray300Color};
  }

  .btn {
    &:hover {
      background-color: ${theme().gray300Color};
    }
  }
`

export const Tab = styled(CFormCheck)`
  margin:0px;
  border: 1px solid ${theme().secondaryColor};
  padding: 5px 11px !important;
  color: ${theme().secondaryColor};
`

export const TabsCustomSpan = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 5px;
`

export const GreenFlag = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: ${theme().success};
`