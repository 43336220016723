/**
 * Customerform scene
 *
 * @author João Dias <jdias@ubiwhere.com>
 *
 *
 */
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, Link } from 'react-router-dom'
import { CCol, CForm, CRow } from '@coreui/react-pro'
// Store
import { actions, RootState } from 'store/rootSlices'
// Components
import SectionContainer from 'shared/components/Sectioncontainer'
import CustomTextInput from 'shared/components/CustomTextInput'
import FormComponent from 'shared/components/FormComponents'
import { country_codes } from 'shared/components/FormComponents/utils'
import Dropdown from 'shared/components/FormComponents/dropdown'
import CustomButton from 'shared/components/CustomButton'
import Avataruploader from 'shared/components/Avataruploader'
import ContainerDelete from 'shared/components/FormComponents/containerdelete';
import CustomerformModals from './components/modals'
// Styles
import theme from 'ui/theme'
import { AvatarCol } from 'shared/genericStyledComponents'
import { Wrapper, Row, FirstRow, ButtonWrapper, AccessButtonWrapper } from './styles'
// Icons
import User from 'assets/icons/user'
// Config
import { hasPermission } from 'permissions'
import config from 'config'


const Customerform = ({ t, i18n }) => {

  const { countryCodes, permissions } = useSelector((state: RootState) => state.App)
  const { PERMISSIONS } = config
  const { 
    customerForm, 
    customer, 
    edit, 
    hasEditPermission,
    submitButtonState,
    userAuthExists,
  } = useSelector((state: RootState) => state.Customerform)
  const { 
    onMount, 
    onUnmount, 
    onFormChange, 
    onSubmit, 
    onRemoveCustomerAvatar, 
    setIsAvatarChanged, 
    setIsAvatarRemoved, 
    setShowDeleteModal ,
    setShowChangePasswordModal,
  } = actions.Customerform
  const { navigateBack } = actions.App

  const dispatch = useDispatch()
  const { uuid } = useParams()

  useEffect(() => {
    dispatch(onMount(uuid))
    return () => {
      dispatch(onUnmount())
    }
  }, [dispatch, onMount, onUnmount, uuid]);

  const handleDeleteCustomer = (e) => {
    dispatch(setShowDeleteModal(true))
  }

  return (
    <Wrapper>
      <CForm>
        <SectionContainer 
          title={i18n.t('customerForm.title1')} 
          style={{ width: '100%', paddingBottom: '24px' }}
          extra={
            <Link style={{textDecoration: 'none'}} to={`/clientes/historico-compras/${customer.uuid}`} target="_blank">{t('customerForm.purchaseHistory')}</Link>
          }
        >
          <FirstRow>
            <AvatarCol sm={1}>
              <Avataruploader
                hasEditPermission={hasEditPermission}
                id='customerForm-avatar'
                defaultAvatar={<User height={80} width={80} viewBox="0 0 17 19" />}
                value={
                  customer.avatar ? customer.avatar
                    : customerForm.avatar.value ? URL.createObjectURL(customerForm.avatar.value) : ""
                }
                valid={customerForm.avatar.valid}
                helpText={customerForm.avatar.message}
                label={i18n.t('common.photo')}
                cantUpload
                onChange={(e) => {
                  if (e === "") {
                    dispatch(onRemoveCustomerAvatar({ ...customer, avatar: "" }));
                    dispatch(onFormChange({ ...customerForm, avatar: { value: "", message: "", valid: true } }));
                    dispatch(setIsAvatarRemoved(true))
                    dispatch(setIsAvatarChanged(false))
                  } else {
                    dispatch(onFormChange({ ...customerForm, avatar: { value: e, message: "", valid: true } }));
                    dispatch(setIsAvatarRemoved(false))
                    dispatch(setIsAvatarChanged(true))
                  }

                  //dispatch(onFormChange({ ...customerForm, avatar: { value: e, message: "", valid: true } }));
                }}
              />
            </AvatarCol>
            <CCol>
              <CRow>
                <CustomTextInput
                  id='customerForm-name'
                  disabled={!hasEditPermission}
                  required={true}
                  valid={customerForm.name.valid}
                  value={customerForm.name.value}
                  helpText={customerForm.name.message}
                  placeholder={i18n.t('placeholders.name')}
                  label={i18n.t('customerForm.name')}
                  onChange={(e) => dispatch(onFormChange({ ...customerForm, name: { value: e.target.value, message: "", valid: true } }))} />

              </CRow>
              <Row>
                <CustomTextInput
                  id='customerForm-nif'
                  disabled={!hasEditPermission}
                  valid={customerForm.nif.valid}
                  value={customerForm.nif.value}
                  helpText={customerForm.nif.message}
                  placeholder={i18n.t('placeholders.number')}
                  label={i18n.t('customerForm.nif')}
                  onChange={(e) => dispatch(onFormChange({ ...customerForm, nif: { value: e.target.value, message: "", valid: true } }))} />
              </Row>
            </CCol>
            <CCol>
              <CRow>
                <AccessButtonWrapper>
                  <CustomButton
                    id='entityMemberForm-accessDataButton'
                    disabled={!edit || !hasEditPermission}
                    iconFirst
                    icon='lock'
                    buttonStyle={{ borderColor: '#321fdb' }}
                    text={userAuthExists ? i18n.t('userForm.changeAccessDataModalTitle') : i18n.t('userForm.defineAccessDataModalTitle')}
                    color="primary"
                    variant="outline"
                    onClick={(e) => { dispatch(setShowChangePasswordModal(true)) }} 
                  />
                </AccessButtonWrapper>
              </CRow>
              <Row className="g-3">
                
                <CustomTextInput
                id='customerForm-postalCode'
                disabled={!hasEditPermission}
                valid={customerForm.postalCode.valid}
                value={customerForm.postalCode.value}
                helpText={customerForm.postalCode.message}
                placeholder={i18n.t('placeholders.postalCode')}
                label={i18n.t('customerForm.postalCode')}
                onChange={(e) => dispatch(onFormChange({ ...customerForm, postalCode: { value: e.target.value, message: "", valid: true } }))} />
              </Row>
            </CCol>
          </FirstRow>
          <Row className="g-3">
            <CCol>
              <CustomTextInput
                  id='customerForm-address'
                  disabled={!hasEditPermission}
                  valid={customerForm.address.valid}
                  value={customerForm.address.value}
                  helpText={customerForm.address.message}
                  placeholder={i18n.t('placeholders.address')}
                  label={i18n.t('customerForm.address')}
                  onChange={(e) => dispatch(onFormChange({ ...customerForm, address: { value: e.target.value, message: "", valid: true } }))}
                />
            </CCol>
            <CCol sm>
              <Dropdown
                  id='customerForm-country'
                  disabled={!hasEditPermission}
                  valid={customerForm.country.valid}
                  value={customerForm.country.value}
                  helpText={customerForm.country.message}
                  placeholder={i18n.t('placeholders.country')}
                  label={i18n.t('customerForm.country')}
                  onChange={(e) => dispatch(onFormChange({ ...customerForm, country: { value: e.target.value, message: "", valid: true } }))}
                  options={Object.keys(countryCodes).map(key => {
                    return {
                      label: countryCodes[key],
                      value: key
                    }
                  })}
                />
            </CCol>
            <CCol sm>
              <CustomTextInput
                id='customerForm-city'
                disabled={!hasEditPermission}
                valid={customerForm.city.valid}
                value={customerForm.city.value}
                helpText={customerForm.city.message}
                placeholder={i18n.t('placeholders.city')}
                label={i18n.t('customerForm.city')}
                onChange={(e) => dispatch(onFormChange({ ...customerForm, city: { value: e.target.value, message: "", valid: true } }))} />
            </CCol>
          </Row>
        </SectionContainer>
        <SectionContainer title={i18n.t('customerForm.title2')} style={{ width: '100%', paddingBottom: '24px' }}>
          <CRow>
            <CCol>
              <CustomTextInput
                id='customerForm-email'
                disabled={!hasEditPermission}
                valid={customerForm.email.valid}
                value={customerForm.email.value}
                helpText={customerForm.email.message}
                required
                label={i18n.t('common.email')}
                placeholder={i18n.t('placeholders.email')}
                onChange={(e) => dispatch(onFormChange({ ...customerForm, email: { value: e.target.value, message: "", valid: true } }))}
              />
            </CCol>
            <CCol>
              <FormComponent
                id='customerForm-phone'
                disabled={!hasEditPermission}
                valid={customerForm.phone.valid}
                value={customerForm.phone.value}
                helpText={customerForm.phone.message}
                dropdown={false}
                label={i18n.t('common.phone')}
                placeholder="Portugal"
                options={country_codes}
                onChange={(e) => dispatch(onFormChange({ ...customerForm, phone: { value: e, message: "", valid: true } }))}
              />
            </CCol>
          </CRow>
        </SectionContainer>
        <SectionContainer title={i18n.t('customerForm.title3')} style={{ width: '100%', paddingBottom: '24px' }}>
          <CCol>
            <CustomTextInput
              id='customerForm-observations'
              disabled={!hasEditPermission}
              valid={customerForm.observations.valid}
              value={customerForm.observations.value}
              helpText={customerForm.observations.message}
              label={i18n.t('customerForm.observations')}
              placeholder={i18n.t('placeholders.observations')}
              onChange={(e) => dispatch(onFormChange({ ...customerForm, observations: { value: e.target.value, message: "", valid: true } }))}
            />
          </CCol>
        </SectionContainer>
        {
          edit === true && hasPermission(permissions, PERMISSIONS.USERS.DELETE_TEAM_MEMBER) && <ContainerDelete
            id='customerForm-delete'
            text={<p>{i18n.t('userForm.deleteUserText')}</p>}
            onClick={e => handleDeleteCustomer(e)} title={i18n.t('userForm.deleteUser')} />
        }
        <ButtonWrapper>
          <CustomButton id='customerForm-cancel' onClick={() => dispatch(navigateBack())} text={i18n.t('common.cancelBtn')} color="link" buttonStyle={{ color: theme().danger, textDecoration: 'unset' }} />
          {
            edit ?
              hasPermission(permissions, PERMISSIONS.USERS.EDIT_CUSTOMER) &&
              <CustomButton
                id='customerForm-save'
                disabled={submitButtonState}
                onClick={() => dispatch(onSubmit())}
                text={i18n.t('common.saveBtn')}
                icon="confirm"
                color="success"
                buttonStyle={{ color: "#FFFFFF" }} />
              :
              hasPermission(permissions, PERMISSIONS.USERS.CREATE_CUSTOMER) &&
              <CustomButton
                id='customerForm-save'
                disabled={submitButtonState}
                onClick={() => dispatch(onSubmit())}
                text={i18n.t('common.saveBtn')}
                icon="confirm"
                color="success"
                buttonStyle={{ color: "#FFFFFF" }} />
          }
        </ButtonWrapper>
      </CForm>
      
      <CustomerformModals />
    </Wrapper>
  )
}

export default Customerform