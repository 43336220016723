import { CreatedBy, ListResponse, ItemType, FormField } from "types/misc"

export enum SyncStatus {
    PENDING = "pending",
    SUCCESS = "success",
    FAILED = "failed",
    NONE = ""
}

export enum InvoiceType {
    CREDIT = "credit",
    DEBIT = "debit",
    NONE = ""
}

export type BalancesList = {
    expenses: string,
    income: string,
    total: string,
    entries: {
        count: number,
        extra: {} | null,
        next: string, 
        previous: string,
        results:BalanceListItem[]
    },
}

export type BalanceListItem = {
    amount: string,
    id: number, 
    relatedTo: string,
    balanceEntryType: {
        id: number,
        name: string,
        showInAdd: boolean,
        uid: string,
    },
    booking: {
        id: number,
        uuid: string,
        uid: string,
        isCanceled: boolean,
        customer: {
            id: number,
            name: string,
            email: string,
            lang: string,
            nif: string,
            country: string,
            uuid: string,
            createdAt: string,
            updatedAt: string,
        },
    },
    date: string,
    description: string,
    hasReceipt: boolean,
    isExpense: boolean,
    isRefund: boolean,
    paymentType: ItemType,
    processedBy: {
        createdAt: string,
        updatedAt: string,
        id: number,
        name: string,
        uuid: string,
        groups: ItemType[],
    },
    supplier: string,
    source: {
        id: number,
        uuid: string,
        uid: string,
        name: string,
        allowPartner: boolean,
        createdAt: string,
        updatedAt: string,
    },
    //! Esta valor a baixo ñ vem na call
    state?: string,
}

export type InvoicesList = ListResponse<InvoiceListItem>

export type InvoiceListItem = {
    amount: string,
    booking: {
        id: number,
        isCanceled: boolean,
        uid: string,
        uuid: string,
        customer: {
            country: string,
            createdAt: string,
            email: string,
            id: number,
            lang: string,
            name: string,
            nif: string,
            updatedAt: string,
            uuid: string,
        },
    },
    country: string,
    createdAt: string,
    createdBy: CreatedBy,
    customerName: string,
    date: string,
    description: string,
    email: string,
    id: number,
    isRefund: boolean,
    payment: string,
    phone: string,
    syncDate: string,
    syncId: number | null,
    syncPdf: string,
    syncStatus: string,
    syncUid: string,
    uid: string,
    uuid: string,
    vat: string,
}

export type Invoice = InvoiceListItem & {
    entries: {
        amount: string, 
        article: string, 
        description: string,
        id: number,
        isRefund: boolean,
    },
}

export type MovementListItem = {
    id: number,
    name: string,
    showInAdd: boolean,
    uid: string
}

export type MovementsList = MovementListItem[]

export type InvoiceDetailsForm = {
    customerName: FormField<string>,
    email: FormField<string>,
    country: FormField<string>,
    phone: FormField<string>,
    vat: FormField<string>
}

export type InvoiceDetail = {
    id: string,
    email: string,
    customerName: string,
    country: string,
    phone: string,
    vat: string,
    syncDate: string,
    syncId: number | null,
    syncPdf: string,
    syncStatus: string,
    syncUid: string,
    amount: string
}