import styled from 'styled-components'

const DisplaysContainer = styled.div`
  width:100%;
`

const TextH5 = styled.h5`
  margin:0;
  font-size: 23px;
`

const Text = styled.p`
  margin:0;
  font-size: 14px;
`

const DownloadButton = styled.div`
  cursor: pointer;
` 

export {
  DisplaysContainer,
  Text,
  TextH5,
  DownloadButton
}