/**
    * Fleetfuelling scene slice
*
    * @author João Dias <jdias@ubiwhere.com>
*
    *
    */
import { createSlice } from '@reduxjs/toolkit'
import { fuellingFormDefault, FuellingForm } from '../utils'
import { FuellingListItem } from 'types/fleet/fuel'
import { DateTime } from 'luxon'


interface InitialState {
  fuelling: FuellingListItem,
  fuelPaymenttypes: [],
  isEdit: boolean,
  loading: boolean,
  deleteModal: boolean,
  isTank: boolean,
  hasEditPermission: boolean,
  submitButtonState: boolean,
  documents: any[],
  newDocumentsID: number,
  fuellingForm: FuellingForm,
  pricePerLitre: number,
  collaborators: any[]
}

const initialState = {
  fuelling: {} as FuellingListItem,
  fuellingForm: fuellingFormDefault,
  isEdit: false,
  loading: true,
  deleteModal: false,
  isTank: false,
  hasEditPermission: true,
  submitButtonState: false,
  documents: [] as any[],
  newDocumentsID: 99,
  pricePerLitre: 0,
  collaborators: []
}

export default createSlice({
  name: 'FleetFuellingForm',
  initialState,
  reducers: {
    onMount: (state, { payload }) => { },
    onUnmount: (state) => {
      return initialState
    },
    setCollaborators: (state, { payload }) => {
      state.collaborators = payload;
    },
    getPricePerLitre: (state, { payload }) => {},
    setPricePerLitre: (state, { payload }) => {
      state.pricePerLitre = payload
    },
    onSubmit: () => { },
    showDeleteModal: (state, { payload }) => { state.deleteModal = payload },
    setEditMode: (state, { payload }) => {
      state.isEdit = payload;
    },
    setFuelling: (state, { payload }) => {
      state.fuelling = payload;

      if (!payload.isVehicleFueled) {
        state.fuelling["vehicle"] = payload.tank;
      }
    },
    requestFuelling: (state, { payload }) => { },
    setLoading: (state, { payload }) => {
      state.loading = payload
    },
    setIsTank: (state, { payload }) => {
      state.isTank = payload
    },
    setForm: (state, { payload }) => {
      const baseform = { ...initialState.fuellingForm }

      Object.keys(payload).forEach((key) => {
        if (key in baseform) {
          if (key === 'paymentType') {
            baseform[key] = {
              ...baseform[key],
              value: payload[key]?.uid
            }
          }
          else if (key === 'vehicle') {
            baseform[key] = {
              ...baseform[key],
              value: payload[key].id
            }
          }
          else if (key === 'fuelStation') {
            baseform[key] = {
              ...baseform[key],
              value: payload[key].id
            }
          }
          else if (key === 'fuelDate') {
            baseform[key] = {
              ...baseform[key],
              value: DateTime.fromISO(payload[key]).toISO()
            }
          }
          else if (key === 'fuelledBy') {
            baseform[key] = {
              ...baseform[key],
              value: payload[key].uuid
            }
          }
          else {
            baseform[key] = {
              ...baseform[key],
              value: payload[key]
            }
          }
        }
      })

      state.fuellingForm = baseform;
    },
    setHasEditPermission: (state, { payload }) => {
      state.hasEditPermission = payload
    },
    setSubmitButtonDisabled: (state, { payload }) => {
      state.submitButtonState = payload
    },
    onFormChange: (state, { payload }) => {
      state.fuellingForm = payload
    },
    createEntryDocument: (state, { payload }) => { },
    addToDocumentsArray: (state, { payload }) => {
      const document = payload.e
      const documents: any[] = Array.from(payload.documents)

      documents.push({
        id: payload.newDocumentsID + 1,
        fuelling: null,
        file: document,
        new: true,
        edited: false,
        sort: documents.length + 1
      })

      state.newDocumentsID = payload.newDocumentsID + 1
      state.documents = documents
    },
    deleteDocumentFromArray: (state, { payload }) => {
      const items: any[] = Array.from(payload.documents)

      items.splice(items.indexOf(items[payload.index]), 1)

      const deletedDocuments = items.map((doc, idx) => {
        if (doc.sort !== idx + 1) {
          return {
            ...doc,
            edited: true,
            sort: idx + 1
          }
        }
        else {
          return {
            ...doc,
            sort: idx + 1
          }
        }
      })

      state.documents = deletedDocuments
    },
    populateDocumentsForm: (state, { payload }) => {
      const documents: any[] = Array.from([])

      payload.documents.forEach((doc) => {
        documents.push({
          fuelling: payload.fuellingID,
          id: doc.id,
          file: doc.file,
          edited: false,
          new: false,
          sort: doc.sort
        })
      })

      state.documents = documents.sort((a, b) => { return a.sort - b.sort })
    },
    reOrderDocsArray: (state, { payload }) => {
      if (!payload.e.destination) return;
      const items: any[] = Array.from(payload.documents)


      const [reOrderedItem] = items.splice(payload.e.source.index, 1);
      items.splice(payload.e.destination.index, 0, reOrderedItem)
      const sorted = items.map((doc, idx) => {
        return {
          ...doc,
          sort: idx + 1,
          edited: true,
        }
      })

      state.documents = sorted
    },
    resetDocumentsArray: (state) => {
      state.documents = []
    },
    updateFormOnValidation: (state, { payload }) => {
      state.fuellingForm = payload
    }
  }
})
