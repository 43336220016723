/**
 * userform container slice
 *
 * @author João Dias <jdias@ubiwhere.com>
 *
 *
 */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { activityCommissionForm, extraCommissionForm } from 'types/collaborators/collaborator'
import { camelizeKeys } from 'humps'
import { initialState } from './initialState'
import { 
  ChangePasswordForm,
  OnChangePasswordSaveSagaPayload, 
  OnCreateUserAuthSagaPayload } from 'types/collaborators/userForm'

export default createSlice({
  name: 'collaboratorsform',
  initialState,
  reducers: {
    onMount: (state, { payload }) => { },
    onUnmount: () => {
      return initialState
    },
    getUserAuth: (state, { payload }: PayloadAction<string>) => { },
    setUserAuthExists: (state, { payload }: PayloadAction<boolean>) => {
      state.userAuthExists = payload
    },
    setHasEditPermission: (state, { payload }) => {
      state.hasEditPermission = payload
    },
    setSubmitButtonDisabled: (state, { payload }) => {
      state.submitButtonState = payload
    },
    changeUserAuthStatus: (state, { payload }) => {
      state.userForm.isActive.value = payload
    },
    createUserAuth: (state, { payload }: PayloadAction<OnCreateUserAuthSagaPayload>) => { },
    onFormChange: (state, { payload }) => {
      state.userForm = payload
    },
    onChangePasswordFormChange: (state, { payload }: PayloadAction<ChangePasswordForm>) => {
      state.changePasswordForm = payload
    },
    populateForm: (state, { payload }) => {
      const user = camelizeKeys(payload)

      const baseForm = { ...initialState.userForm }

      Object.keys(user).forEach(key => {
        if (key in baseForm) {
          baseForm[key] = {
            ...baseForm[key],
            value: user[key],
          }
        }
        if (key === 'role' && user['role'] !== null) {
          baseForm['role'] = {
            ...baseForm['role'],
            value: user['role']['id'],
          }
          baseForm['roleName'] = {
            ...baseForm['roleName'],
            value: user['role']['name'],
          }
        }
        if (key === 'groups' && user['groups'] !== null) {
          baseForm['groups'] = {
            ...baseForm['groups'],
            value: user.groups.map(gr => ({ label: gr.name, value: gr.id })),
          }
        }
      })

      state.userForm = baseForm;
    },
    setAction: (state, { payload }) => {
      state.edit = payload
    },
    setUserID: (state, { payload }) => {
      state.uuid = payload
    },
    setLoading: (state, { payload }) => {
      state.loading = payload
    },
    showDeleteModal: (state, { payload }) => {
      state.deleteModal = payload
    },
    showChangePasswordModal: (state, { payload }: PayloadAction<boolean>) => {
      state.changePasswordModal = payload
    },
    setHasChangedPassword: (state, { payload }) => {
      state.hasChangedPassword = payload
    },
    setAcceptedTerms: (state, { payload }) => {
      state.termsAccepted = payload
    },
    setErrorTerms: (state, { payload }) => {
      state.errorTerms = payload
    },
    onDeleteConfirm: (state, { payload }) => { },
    onSubmit: (state, { payload }) => { },
    onChangePasswordSave: (state, { payload }: PayloadAction<OnChangePasswordSaveSagaPayload>) => { },
    onReSendEmail: () => { },
    setUser: (state, { payload }) => {
      state.user = payload;
    },
    onRemoveUserAvatar: (state, { payload }) => {
      state.user = payload
    },
    setIsAvatarChanged: (state, { payload }) => {
      state.isAvatarChanged = payload
    },
    setIsAvatarRemoved: (state, { payload }) => {
      state.isAvatarRemoved = payload
    },
    setConfirmAllModal: (state, { payload }) => {
      state.confirmAllModal = payload.confirmAll;
      state.isApplyAllOnActivities = payload.isApplyAllOnActivities
    },
    changeDefaultCommissionValues: (state, { payload }) => { 
      if(payload.isActivity) {
        state.activitiesCommissionForm = state.activitiesCommissionForm.map((act) => {
          if(act.isCustom) return act
          return {
            ...act,
            commission: {
              ...act.commission,
              value: payload.value
            }
          }
        })
      } else {
        state.extrasCommissionForm = state.extrasCommissionForm.map((ext) => {
          if(ext.isCustom) return ext
          return {
            ...ext,
            commission: {
              ...ext.commission,
              value: payload.value
            }
          }
        })
      }
    },
    onCommissionChangeToggler: (state, { payload }) => {
      if(payload.isActivity) {
        state.activitiesCommissionForm[payload.idx] = {
          ...state.activitiesCommissionForm[payload.idx],
          isCustom: payload.value,
          commission: { value: payload.default, valid: true, message: "" }
        }
      } else {
        state.extrasCommissionForm[payload.idx] = {
          ...state.extrasCommissionForm[payload.idx],
          isCustom: payload.value,
          commission: { value: payload.default, valid: true, message: "" }
        }
      }
      
    },
    updateValidationsOnEntityCommissions: (state, { payload }) => {
      state.activitiesCommissionForm = payload.activities?.map((act) => {
        return ({
          uuid: act.uuid,
          name: act.name,
          isCustom: act.isCustom,
          commission: act.commission
        })
      });
      state.extrasCommissionForm = payload.extras?.map((ext) => {
        return ({
          uuid: ext.uuid,
          name: ext.name,
          isCustom: ext.isCustom,
          commission: ext.commission
        })
      });
    },
    populateUserCommissionsOnMount: (state, { payload }) => {
      state.activitiesCommissionForm = payload.activitiesCommissionForm?.map((act) => {

        if (payload.activities.find(a => a.activity.uuid === act.uuid)) {
          const a = payload.activities.find(a => a.activity.uuid === act.uuid)
          return ({
            ...act,
            isCustom: true,
            commission: {
              value: a.amount,
              valid: true,
              message: ""
            }
          })
        } else {
          return {
            ...act,
            isCustom: false,
            commission: {
              ...act.commission,
              value: payload.activitiesCommissionDefault
            }
          }
        }
      });

      state.extrasCommissionForm = payload.extrasCommissionForm?.map((ext) => {

        if (payload.extras.find(e => e.extra.uuid === ext.uuid)) {
          const e = payload.extras.find(e => e.extra.uuid === ext.uuid)
          return ({
            ...ext,
            isCustom: true,
            commission: {
              value: e.amount,
              valid: true,
              message: ""
            }
          })
        } else {
          return {
            ...ext,
            isCustom: false,
            commission: {
              ...ext.commission,
              value: payload.extrasCommissionDefault 
            }
          }
        }
      });

    },
    setUserCommissions: (state, { payload }) => {
      state.activitiesCommissionForm = payload.activities?.map((act) => {
        return ({
          uuid: act.uuid,
          name: act.name,
          isCustom: false,
          commission: {
            value: "",
            valid: true,
            message: ""
          }
        })
      });
      state.extrasCommissionForm = payload.extras?.map((ext) => {
        return ({
          uuid: ext.uuid,
          name: ext.name,
          isCustom: false,
          commission: {
            value: "",
            valid: true,
            message: ""
          }
        })
      });
    },
    onActivityCommissionChange: (state, { payload }) => {
      state.activitiesCommissionForm[payload.idx] = {
        ...state.activitiesCommissionForm[payload.idx],
        commission: { value: payload.e, valid: true, message: "" }
      }
    },
    onExtraCommissionChange: (state, { payload }) => {
      state.extrasCommissionForm[payload.idx] = {
        ...state.extrasCommissionForm[payload.idx],
        commission: { value: payload.e, valid: true, message: "" }
      }
    },
    applyValueOnAllActivitiesComissions: (state, { payload }) => {
      const activitiesCommissionForm: Array<activityCommissionForm> = Array.from(payload.activitiesCommissionForm)

      state.activitiesCommissionForm = activitiesCommissionForm.map((act) => {
        return {
          ...act,
          isCustom: false,
          commission: {
            ...act.commission,
            value: payload.defaultCommission
          }
        }
      })
    },
    applyValueOnAllExtrasComissions: (state, { payload }) => {
      const extrasCommissionForm: Array<extraCommissionForm> = Array.from(payload.extrasCommissionForm)

      state.extrasCommissionForm = extrasCommissionForm.map((ext) => {
        return {
          ...ext,
          isCustom: false,
          commission: {
            ...ext.commission,
            value: payload.defaultCommission
          }
        }
      })
    },
    populateDefaultCommissionsOnMount: (state, { payload }) => {
      state.defaultActivitiesCommissions = payload.activitiesCommissionForm?.map((act) => {

        if (payload.activities.find(a => a.activity.uuid === act.uuid)) {
          const a = payload.activities.find(a => a.activity.uuid === act.uuid)
          return ({
            ...act,
            isCustom: true,
            commission: {
              value: a.amount,
              valid: true,
              message: ""
            }
          })
        } else return act
      });

      state.defaultExtrasCommissions = payload.extrasCommissionForm?.map((ext) => {

        if (payload.extras.find(e => e.extra.uuid === ext.uuid)) {
          const e = payload.extras.find(e => e.extra.uuid === ext.uuid)
          return ({
            ...ext,
            isCustom: true,
            commission: {
              value: e.amount,
              valid: true,
              message: ""
            }
          })
        } else return ext
      });

    },
  }
})

