/**
 * circuitstoptablerow component
 *
 * @author Manuel Gil <mgi@ubiwhere.com>
 *
 *
 */

import React from 'react'
import styled from 'styled-components'

import { CircuitStopForm } from 'types/trips/circuits'
import { TableRowPropTypes } from 'types/misc'
import theme from 'ui/theme'
import { CTableDataCell, CTableRow } from '@coreui/react-pro'
import Timepicker from 'shared/components/FormComponents/timepicker'

type PropTypes = TableRowPropTypes & {
  isInBound: boolean,
  id?: string,
  circuitStop: CircuitStopForm,
  hasEditPermission: boolean
}

const Circuitstoptablerow = (props: PropTypes) => {


  const {
    id,
    actions,
    hasEditPermission,
    circuitStop: {
      stop,
      time,
      isActive
    }
  } = props

  return (
    <CustomCTableRow>
      <CTableDataCell>{stop.name}</CTableDataCell>
      <CTableDataCell>
        {
          <Timepicker
            id={`${props.id}-time`}
            disabled={!hasEditPermission || isActive.value === false ? true : false}
            onChange={(e) => props.onClick(e,id)}
            value={time.value} 
            valid={time.valid}
            helpText={time.message}
            timeFormat="HH:mm:ss"
          />
        }
      </CTableDataCell>
      <ActionsCell>
        {actions}
      </ActionsCell>
    </CustomCTableRow>
  )
}

export default Circuitstoptablerow

const CustomCTableRow = styled(CTableRow)`
    max-height: 54px;
    background-color: ${theme().white};
    border-bottom: 1px solid ${theme().gray200Color};
  
    > td {
      text-transform: capitalize;
      vertical-align: middle;
      font-family: Inter;
      font-size: 14px;
      color: ${theme().gray700Color}
    }

    td:first-child {
      padding-left:15px;
      width:77%;
    }
  `

const ActionsCell = styled(CTableDataCell)`

  `
