import * as React from "react"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path 
                d="M18 4H15V2C15 0.9 14.1 0 13 0H7C5.9 0 5 0.9 5 2V4H2C0.9 4 0 4.9 0 6V16H20V6C20 4.9 19.1 4 18 4ZM7 2H13V4H7V2ZM18 14H2V11H4V12H6V11H14V12H16V11H18V14ZM16 9V8H14V9H6V8H4V9H2V6H5H15H18V9H16Z" 
                fill={props.fill ? props.fill : "#8A93A2"}
            />
        </svg>

    )
}

export default SvgComponent
