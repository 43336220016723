import { PlanningListItem, Vehicles, openPlanningStopForm } from 'types/trips/plannings'
import { FormField } from 'types/misc';
import { ActivityListItem } from 'types/trips/activity/activity'

export interface InitialStateProps {
  page: 1,
  totalPlannings: number,
  loading: boolean,
  bottomLoading: boolean,
  scrollLoading: boolean,
  modalLoading: boolean,
  modalElegibleLoading: boolean,
  days: any[],
  hasDateParams: boolean,
  startDate: string,
  endDate: string,
  activityFilter: string,         // id
  vehicleFilter: string,          // id
  departureFilter: string,        // id
  arrivalFilter: string,          // id
  selectedDay: string,
  selectedVehicle: FormField<string>,
  modalInitialVehicle: FormField<string>,
  reinforcementCapacity: FormField<string>;
  showEndedExits: boolean,
  showModal: {
    planningId: number,
    show: boolean
  },
  showCheckinModal: {
    planningId: number,
    show: boolean,
    openBy: 'stop' | 'vehicle' | 'generic',
    vehicle: Vehicles,
    showElegibles: boolean,
    stop: {
      id: number,
      name: string
    }
  },
  checkingIn: boolean,
  plannings: PlanningListItem[],
  beachPlannings: PlanningListItem[],
  openPlanningTripPassengers: any,
  openPlanningElegiblePassengers: any,
  openPlanningTotalBookings: any,
  openPlanning: any;                //change type
  openPlanningOutboundStops: openPlanningStopForm[],   //change type
  openPlanningInboundStops: openPlanningStopForm[],    //change type
  fetchTrips: boolean,
  hasNextPage: boolean,
  disableScroll: boolean,
  checkinSearch: string,
  checkinOutboundSelected: boolean,
  isUpdatingCircuit: boolean,
  isRemovingVehicle: boolean,
  didStopsChange: boolean,
  tripsDetails: any[],
  activities: ActivityListItem[],
}

const initialState: InitialStateProps = {
  page: 1,
  totalPlannings: 0,
  loading: false,
  bottomLoading: true,
  scrollLoading: false,
  modalLoading: true,
  modalElegibleLoading: false,
  showEndedExits: false,
  showModal: {
    planningId: 0,
    show: false
  },
  checkingIn: false,
  showCheckinModal: {
    planningId: 0,
    show: false,
    showElegibles: false,
    vehicle: {
      arrival: null,
      departure: null,
      inTransit: false,
      inboundCompleted: false,
      outboundCompleted: false,
      uuid: "",
      vehicle: {
        capacity: 0,
        color: "",
        createdAt: "",
        isAvaiable: true,
        name: "",
        shortName: "",
        updatedAt: "",
        uuid: "",
        vehicleClass: "",
        vehicleType: "",
      }
    },
    openBy: 'generic',
    stop: {
      id: -1,
      name: ""
    },
  },
  activityFilter: "",
  vehicleFilter: "",
  departureFilter: "",
  arrivalFilter: "",
  checkinSearch: "",
  days: [
    {
      date: new Date().toISOString().slice(0, 10),
      active: true
    }
  ],
  selectedVehicle: {
    value: "",
    valid: true,
    message: "",
  },
  modalInitialVehicle: {
    value: "",
    valid: true,
    message: "",
  },
  hasDateParams: false,
  selectedDay: new Date().toISOString().slice(0, 10),
  startDate: new Date().toISOString().slice(0, 10),
  endDate: new Date().toISOString().slice(0, 10),
  openPlanning: {},
  openPlanningTripPassengers: null,
  openPlanningElegiblePassengers: null,
  openPlanningTotalBookings: null,
  plannings: [],
  beachPlannings: [],
  reinforcementCapacity: {
    value: "",
    valid: true,
    message: "",
  },
  openPlanningOutboundStops: [],
  openPlanningInboundStops: [],
  fetchTrips: false,
  hasNextPage: false,
  disableScroll: false,
  checkinOutboundSelected: true,
  isUpdatingCircuit: false,
  isRemovingVehicle: false,
  didStopsChange: false,
  tripsDetails: [],
  activities: [],
}

export default initialState