/**
 * Usercashmanagementforms scene sagas
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */
import API from 'api'
import { AnyAction } from '@reduxjs/toolkit';
import { call, putResolve, takeLatest, select } from 'redux-saga/effects'
import { actions, RootState } from 'store/rootSlices'
import { toast } from 'react-toastify'
import { StatementFormValidations } from '../utils'
import { hasPermission } from 'permissions'
import i18n from 'i18next';
import * as Check from 'validations';
import config from 'config';

function* onMountSaga({ payload }: AnyAction) {
  const statementID = payload.id
  const userID = payload.uuid

  const { PERMISSIONS } = config
  const { permissions } = yield select((state: RootState) => state.App);

  yield putResolve(actions.UserCashManagement.setUserID(userID))
  yield putResolve(actions.UserCashManagementForms.setStatementID(statementID))
  yield putResolve(actions.UserCashManagementForms.setHasEditPermission(hasPermission(permissions, PERMISSIONS.INVOICING.CASH_MANAGEMENT.OPEN_STATEMENT)))

  yield getStatementFormSaga(statementID)
  yield getStatementPhotosSaga(statementID)
}

function* CreateStatementPhotosSaga() {
  const { photos, statementID } = yield select((state: RootState) => state.UserCashManagementForms);

  try {

    photos.forEach(async (photo, idx) => {
      if (photo.new === true) {
        const body = {
          statement: statementID,
          sort: idx + 1,
          file: photo.file
        }
        await API.Invoicing.AddStatementPhoto(body)
      }
      else {
        if (photo.edited === true) {
          const body = {
            statement: statementID,
            sort: idx + 1,
          }
          await API.Invoicing.EditStatementPhoto(photo.id, body)
        }
      }
    })

  } catch (error) {
    toast.warning(i18n.t("toasts.createUserStatementPhotoError"))
  }
  finally {
    yield closeStatementWithPhotoSaga()
  }
}

function* getStatementPhotosSaga(payload: AnyAction) {

  const statementID = Number(payload)

  if (statementID) {
    try {
      const photos = yield call(API.Invoicing.ListStatementPhotos, statementID)

      if (photos) {
        yield putResolve(actions.UserCashManagementForms.populatePhotosForm({ photos, statementID }))
      }
    } catch (error) {
      toast.error(i18n.t("toasts.getUserStatementPhotosError"))
    }
  }
}

function* getStatementFormSaga(payload: AnyAction) {

  try {
    const statementInfo = yield call(API.Invoicing.GetStatment,
      Number(payload)
    )

    if (statementInfo) {
      yield putResolve(actions.UserCashManagement.setUserStatementName(statementInfo.teamMember.name))


      if (statementInfo !== null) {
        yield putResolve(actions.UserCashManagement.setStatementStatus(statementInfo.isClosed))
        yield putResolve(actions.UserCashManagement.setStatement(statementInfo))
        yield putResolve(actions.UserCashManagementForms.setStatementForm(statementInfo))
        yield putResolve(actions.Dashboard.setDynamicBreadcrumb({
          path: `invoicing/cashManagement/${statementInfo.teamMember.name}/edit`,
          name: statementInfo.teamMember.name,
        }))
      }
    }
  } catch {
    toast.error(i18n.t("toasts.getStatementError"))
  }
}

function* onUnmountSaga() {
  yield putResolve(actions.Dashboard.setDynamicBreadcrumb(null))
}

function* closeStatementWithPhotoSaga() {
  const { statementForm } = yield select((state: RootState) => state.UserCashManagementForms);
  const { statement } = yield select((state: RootState) => state.UserCashManagement);

  const validation = Check.checkValidation(statementForm, StatementFormValidations)

  if (!validation.invalid) {

    //submit
    try {

      const finalForm = {
        bankDeposit: statementForm.bankDeposit.value,
        cashFund: statementForm.cashFund.value,
        multibancoSales: statementForm.multibancoSales.value,
        notDeposited: statementForm.notDeposited.value
      }

      const closed = yield call(API.Invoicing.CloseStatement, statement.id, finalForm)

      if (closed) {
        yield putResolve(actions.UserCashManagement.setStatementStatus(closed.isClosed))
        yield putResolve(actions.UserCashManagement.setStatement(closed))
        yield putResolve(actions.UserCashManagementForms.setStatementForm(closed))
      }
    } catch (error) {
      toast.error(i18n.t("toasts.closeUserStatementError"))
    }
  }
  else {
    toast.error(i18n.t("toasts.invalidFields"));
    yield putResolve(actions.UserCashManagementForms.updateStatementFormOnValidation(validation.form))
  }
}

function* deletePhotoSaga({ payload }: AnyAction) {
  const index = payload.index
  const photosArray = payload.photos


  if (index !== null && photosArray[index].new === false) {
    try {
      const del = yield call(API.Invoicing.DeleteEntryPhoto, photosArray[index].id)

      if (del.status === 204) {
        toast.success(i18n.t("toasts.deletedPhoto"))
      }

    } catch (error) {
      toast.error(i18n.t("toasts.removePhotoError"))
    }
  }
}


export default function* watcherSignin() {
  yield takeLatest('Usercashmanagementforms/onMount', onMountSaga)
  yield takeLatest('Usercashmanagementforms/onUnmount', onUnmountSaga)
  yield takeLatest('Usercashmanagementforms/getStatementPhotos', getStatementPhotosSaga)
  yield takeLatest('Usercashmanagementforms/deletePhotoFromArray', deletePhotoSaga)
  yield takeLatest('Usercashmanagementforms/createStatementPhotos', CreateStatementPhotosSaga)
}


