import styled from 'styled-components'
import theme from 'ui/theme'

export const Wrapper = styled.div``

export const Title = styled.h6`
  display: inline-block;
  font-size: 19px;
  font-weight:500;
  color:${theme().darkColor};
  
  > span {
    margin-right: 12px;
    cursor: pointer;
  }
`
export const ButtonWrapper = styled.div`
  width: 100%;
  display:flex;
  justify-content:flex-end;
  padding-top:22px;

  > button {
    > svg {
      height: 17px !important;
      margin-left: 4px;

      > path {
        fill: ${theme().white} !important;
      }
    }
  }
`

export const StatementInfoWrapper = styled.div`
  width: 100%;
  display:flex;
  justify-content:space-between;
`
export const DateInfo = styled.p`
  color:${theme().gray600Color};

  > span {
    margin-right:12px;
  }
`