//api prod: api.animaris.pt

export default {
    PROD_CONFIG: 'PROD_CONFIG',
    API_URL: 'https://api.animaris.pt',
    API_INVOICING_URL: 'https://api.animaris.pt/invoicing',
    API_TRIPS_URL: 'https://api.animaris.pt/trips',
    API_FLEET_URL: 'https://api.animaris.pt/fleet',
    API_USERS_URL: 'https://api.animaris.pt/users',
    API_AUTH_URL: 'https://api.animaris.pt/auth',
    API_TRANS_URL: 'https://dev-animaris.ubiwhere.com/translations',
}
