/**
 * Invoicedetails scene utils
 *
 * @author Stephane Ribeiro <sgribeiro@ubiwhere.com>
 *
 *
 */
import * as Check from 'validations'
import { InvoiceDetailsForm, InvoiceDetail } from 'types/invoicing/invoices'


export const InvoiceDetailsValidationsEdit = {
    customerName:[Check.isRequired],
    email:[Check.Email],
    phone: [],
    country: [Check.isRequired],
    vat: [Check.isRequired]
}

export type SubmitSagaPayload = {
    invoiceForm: InvoiceDetailsForm
}

export type CheckIfModalSagaPayload = {
    invoiceForm: InvoiceDetailsForm, 
    invoice: InvoiceDetail
}