import { CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle } from '@coreui/react-pro'
import React  from 'react'
import i18n from 'i18next';
import { useDispatch, useSelector } from 'react-redux'
// Icons
import Phone from 'assets/icons/phone'
import MoreVert from 'assets/icons/more_vert'
import NoPayement from 'assets/icons/no_payement'
import ExpandMore from 'assets/icons/expand_more'
import ExpandLess from 'assets/icons/expand_less'
// Components
import PassengersCell from '../PassengersCell';
import StopsCell from '../StopsCell';
// Store
import { actions, RootState } from 'store/rootSlices';
// Styles
import theme from 'ui/theme'
import { CustomCTableBody, CustomCTableRow, CustomTableHeaderCell} from '../../styles'
import { TableDataCell, Tooltip, Text, TooltipWrapper, CustomCTableHead, Icon, CustomSpan, BookingInfo, PAXDiv } from '../styles'
import { CustomTable, ElegibleTitle, ExpandIconWrapper} from './styles'

type PropTypes = React.PropsWithChildren<{
  elegibleBookings?: any,
  planningOutStops?: any,
  planningInStops?: any,
}>

const ElegiblesTable = ({ elegibleBookings, planningInStops, planningOutStops}: PropTypes) => {

  const { allPassengerTypes, allLocals } = useSelector((state: RootState) => state.TripsConfiguration)
  const { 
    checkinOutboundSelected, 
    showCheckinModal,
  } = useSelector((state: RootState) => state.TripPlannings);
  const {
    setShowCheckinElegibles 
  } = actions.TripPlannings
  const dispatch = useDispatch()

  return (
    <CustomTable expanded={showCheckinModal.showElegibles}>
      <CustomCTableHead>
        <CustomTableHeaderCell colSpan={10}>
          <ElegibleTitle>{i18n.t('tripPlannings.elegibleCustomers')}</ElegibleTitle>
        </CustomTableHeaderCell>
        <CustomTableHeaderCell style={{textAlign: 'right', verticalAlign: "top"}} colSpan={6}>
          <ExpandIconWrapper>
            {
              showCheckinModal.showElegibles ? (
                <ExpandLess width="15px" height="15px" cursor="pointer" onClick={() => dispatch(setShowCheckinElegibles(false))}/>
              ) : (
                <ExpandMore width="15px" height="15px" cursor="pointer" onClick={() => dispatch(setShowCheckinElegibles(true))}/>
              )
            }
          </ExpandIconWrapper>
        </CustomTableHeaderCell>                  
      </CustomCTableHead>
      <CustomCTableBody expanded={showCheckinModal.showElegibles}>
        {
          elegibleBookings?.map((res, idx) => (
            <CustomCTableRow key={`custom-elegibles-table-row-idx-${idx}`}>
              <TableDataCell
                colSpan={1}
                style={{ width: '10%', fontWeight: '700' }}
                color={`${res.outboundTrip?.tripActivityLeg.activity.color}`}
              >
                {
                  `${res.outboundTrip?.tripActivityLeg ? res.outboundTrip?.tripActivityLeg.activity.shortName : ``}
                    ${res.inboundTrip?.tripActivityLeg ? ` + ${res.inboundTrip?.tripActivityLeg.activity.shortName}` : ``}`
                }
              </TableDataCell>
              <TableDataCell
                colSpan={1} 
                style={{ width: '11%' }}
                color={`${res.hasPendingEntries ? theme().danger : null}`}
              >
                {res.uid}
              </TableDataCell>
              <TableDataCell
                colSpan={1} 
                style={{ width: '4%' }}
                color={`${res.hasPendingEntries ? theme().danger : null}`}
              >
                <Tooltip>
                  <Icon marginr='5'>
                    <Phone fill={`${res.hasPendingEntries ? theme().danger : theme().gray500Color}`}  />
                  </Icon>
                  {
                    res.customer?.email && <CustomSpan>{res.customer?.email}</CustomSpan>
                  }
                </Tooltip>
              </TableDataCell>
              <TableDataCell 
                colSpan={1}
                style={{ width: '20%' }}
                color={`${res.hasPendingEntries ? theme().danger : null}`}
              >
                <BookingInfo>
                  <Text weight='400'>{ res.customerName }</Text>
                  <TooltipWrapper>
                    {
                      res.hasPendingEntries && <Tooltip>
                        <NoPayement />
                        <CustomSpan>{i18n.t('tripPlannings.noPayment')}</CustomSpan>
                      </Tooltip>
                    }
                  </TooltipWrapper>
                </BookingInfo>
              </TableDataCell>
              <TableDataCell
                colSpan={1} 
                style={{ width: '6%' }}
              >
                <PAXDiv>
                  <Text weight='400'>PAX</Text>
                  <Text weight='700'>{res.seats}</Text>
                </PAXDiv>
              </TableDataCell>
              {
                allPassengerTypes?.map((pt, idx) => 
                  <PassengersCell 
                    key={`custom-elegibles-table-row-passenger-cell-idx-${idx}`}
                    reservation={res}
                    passengerType={pt}
                  />
                )
              }
              {
                allLocals.map((st) => (
                  <StopsCell 
                    key={`elegibles-table-stops-cell-idx-${idx}-id-${st.id}`}
                    reservation={res}
                    stop={st}
                    planningInStops={planningInStops}
                    planningOutStops={planningOutStops}
                    isElegible={true}
                    outbound={checkinOutboundSelected}
                  />
                ))
              }
              <TableDataCell style={{ width: '5%' }} colSpan={1}>
                <CDropdown>
                  <CDropdownToggle color='noColor' caret={false}><Icon marginr='5'><MoreVert fill={theme().gray500Color} /></Icon></CDropdownToggle>
                  <CDropdownMenu>
                    <CDropdownItem rel="noopener noreferrer" target="_blank" href={`/viagens/reservas/editar-reserva/${res.uuid}`}>{i18n.t('tripPlannings.checkBooking')}</CDropdownItem>
                  </CDropdownMenu>
                </CDropdown>
              </TableDataCell>
            </CustomCTableRow>
          ))
        }
      </CustomCTableBody>
    </CustomTable>
  )
}

export default ElegiblesTable