/**
 * PassengerTypes component
 *
 * @author João Flores <jflores@ubiwhere.com>
 *
 *
 */

import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { actions, RootState } from 'store/rootSlices'
//styles
import { TableRow } from '../../styles'
//Components
import TableContainer from 'shared/components/TableContainer'
import { CTableDataCell } from '@coreui/react-pro'
import CustomTextInput from 'shared/components/CustomTextInput'
import CustomNumberInput from 'shared/components/CustomNumberInput'

const ExtrasTypes = ({ t, extrasTypes }) => {
    const {
        selectedBeachExtras,
        lockExtraInputs,
        edit,
        bookedBeachExtras
    } = useSelector((state: RootState) => state.BookingForm);

    const {
        setSelectedExtras,
        setEditExtras,
    } = actions.BookingForm;

    const dispatch = useDispatch();



    return (
        <TableContainer
            count={0}
            elementsPerPage={0}
            currentPage={-1}
            onPageChange={() => { }}
            headerElements={[
                t('tripBookings.extras'),
                t('common.quantity'),
                t('common.price'),
            ]}
        >
            {extrasTypes.map((extra, idx) => {
                const selected = selectedBeachExtras.find(p => p.id === extra.id);
                const bookedExtraQuantity = Number(bookedBeachExtras.find(p => p.id === extra.id)?.quantity.value ?? 0);

                return (
                    <TableRow key={`passenger-type-${idx}`}>
                        <CTableDataCell>{extra.name}</CTableDataCell>
                        <CTableDataCell style={{ width: "30%" }}>
                            {
                                edit ? (
                                    <CustomNumberInput
                                        id={`booking-extra-quantity-${idx}`}
                                        value={selected?.quantity.value || ""}
                                        valid={selected ? selected.quantity.valid : true}
                                        onChange={e => {
                                            const newValue = e.target.value.trim().length ? parseInt(e.target.value, 10) : 0
                                            const selectedExtraMaxCapacity = Number(selected?.maxCapacity) ?? 0
                                            //if the booking had more quantity than the actual max capacity of the extra we let the max be the old booking quantity
                                            const upperBoundCapacity = Math.max(selectedExtraMaxCapacity, bookedExtraQuantity)
                                            const quantity = Math.max(
                                                newValue > upperBoundCapacity ? upperBoundCapacity : newValue,
                                                0
                                            )
                                            dispatch(setEditExtras({
                                                id: extra.id,
                                                value: quantity
                                            }));

                                        }}
                                        onClickAdd={(e, numberInput) => {
                                            dispatch(setEditExtras({
                                                id: extra.id,
                                                value: parseInt(numberInput.value, 10) + 1,
                                            }));
                                        }}
                                        onClickSub={(e, numberInput) => {
                                            dispatch(setEditExtras({
                                                id: extra.id,
                                                value: parseInt(numberInput.value, 10) - 1,
                                            }));
                                        }}
                                        disabled={!selected || selected.isDisabled || lockExtraInputs}
                                        min={0}
                                        max={Math.max(
                                            Number(selected?.maxCapacity) ?? 0,
                                            bookedExtraQuantity,
                                            0
                                        )}
                                    />
                                ) : (
                                    <CustomNumberInput
                                        id={`booking-extra-quantity-${idx}`}
                                        value={selected?.quantity.value || ""}
                                        valid={selected ? selected.quantity.valid : true}
                                        onChange={e => {
                                            const newValue = e.target.value.trim().length ? parseInt(e.target.value, 10) : 0

                                            dispatch(setSelectedExtras({
                                                id: extra.id,
                                                value: newValue > Number(selected?.maxCapacity) ? selected?.maxCapacity : newValue
                                            }));
                                        }}
                                        onClickAdd={(e, numberInput) => {
                                            dispatch(setSelectedExtras({
                                                id: extra.id,
                                                value: parseInt(numberInput.value, 10) + 1,
                                            }));
                                        }}
                                        onClickSub={(e, numberInput) => {
                                            dispatch(setSelectedExtras({
                                                id: extra.id,
                                                value: parseInt(numberInput.value, 10) - 1,
                                            }));
                                        }}
                                        disabled={!selected || selected.isDisabled || lockExtraInputs}
                                        min={0}
                                        max={selected?.maxCapacity}
                                    />
                                )
                            }

                        </CTableDataCell>
                        <CTableDataCell style={{ width: "20%", minWidth: '120px' }}>
                            <CustomTextInput
                                icon="euro"
                                disabled={true}
                                value={selected?.totalPrice || "-"}
                            />
                        </CTableDataCell>
                    </TableRow>
                )
            })}
        </TableContainer>
    )
}

export default ExtrasTypes