/**
 * Vehicleform scene sagas
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */
import { /* putResolve */ putResolve, takeLatest, call, select } from 'redux-saga/effects'
import * as Check from 'validations';
import { AnyAction } from 'redux';
import Fleet from 'api/calls/fleet';

import { Vehicle, VehicleDocumentsForm } from 'types/fleet/vehicle';
import { actions, RootState } from 'store/rootSlices'
import { defaultForm, DocumentFormValidations, VehicleFormValidationsAvailable, VehicleFormValidationsUnavailable } from '../utils';
import fleet from 'api/calls/fleet';
import { toast } from 'react-toastify';
import i18n from "i18next";
import config from 'config';
import { hasPermission } from 'permissions'
import { PayloadAction } from '@reduxjs/toolkit';
const { PERMISSIONS } = config

type TAction = {
  type: AnyAction;
  payload: number;
  meta?: any;
  error?: boolean;
};

function* onMountFleetManagementForm({ payload }: PayloadAction<number>) {
  const { permissions } = yield select((state: RootState) => state.App);

  try {
    if (payload) {
      yield putResolve(actions.FleetManagementForm.setEditMode(true));
      yield putResolve(actions.FleetManagementForm.setHasEditPermission(hasPermission(permissions, PERMISSIONS.FLEET.EDIT_VEHICLE)))
      yield putResolve(actions.FleetManagementForm.requestVehicle(payload));
    } else {
      yield putResolve(actions.FleetManagementForm.setEditMode(false));
      yield putResolve(actions.FleetManagementForm.setForm(defaultForm));
      yield putResolve(actions.FleetManagementForm.setDocumentForm([]))
      yield putResolve(actions.FleetManagementForm.setLoading(false));
    }
  } catch (err) {
    toast.success(i18n.t("toasts.errorMountingScreen"))
  }
}

function* requestVehicle({ payload }: PayloadAction<number>) {
  try {
    const data: Vehicle = yield call(Fleet.GetVehicle, payload);
    yield putResolve(actions.FleetManagementForm.setVehicle(data));
  } catch (error) {
  }
}

function* deleteVehicleSaga() {
  const {
    FleetManagementForm: {
      vehicle
    }
  } = yield select((state: RootState) => state);
  try {
    const res = yield call(Fleet.DeleteVehicle, vehicle.id);
    if (res.status === 201) {
      yield putResolve(actions.App.navigateTo("/frota/veiculos"));
    }
  } catch (error) {
  }
}

function* onSubmitSaga() {
  const {
    FleetManagementForm: {
      vehicleForm,
      documentsForm,
      edit,
      vehicle
    }
  } = yield select((state: RootState) => state);

  yield putResolve(actions.FleetManagementForm.setSubmitButtonDisabled(true))

  try {
    let validations = vehicleForm.isAvailable.value ? VehicleFormValidationsAvailable : VehicleFormValidationsUnavailable;
    const mainValidation = Check.checkValidation(vehicleForm, validations);
    const docsValidation = Check.checkListValidation(documentsForm, DocumentFormValidations);
    //console.log(docsValidation);
    if (docsValidation.invalid || mainValidation.invalid) {
      if (mainValidation.invalid) {
        yield putResolve(actions.FleetManagementForm.setForm(mainValidation.form));
        toast.error(i18n.t("toasts.invalidFields"));
      }
      if (docsValidation.invalid) {
        yield putResolve(actions.FleetManagementForm.setDocumentForm(docsValidation.form));
        toast.error(i18n.t("toasts.invalidFields"));
      }
      yield putResolve(actions.FleetManagementForm.setSubmitButtonDisabled(false))
      return false;
    }
    yield putResolve(actions.FleetManagementForm.setLoading(true));
    const body = {
      name: vehicleForm.name.value,
      vehicle_type: vehicleForm.vehicleTypeId.value,
      vehicle_class: vehicleForm.vehicleClassId.value,
      description: vehicleForm.description.value,
      color: vehicleForm.color.value,
      short_name: vehicleForm.shortName.value,
      plate_num: vehicleForm.plateNum.value,
      fuel_type: vehicleForm.fuelTypeId.value,
      fuel_capacity: vehicleForm.fuelCapacity.value,
      capacity: vehicleForm.capacity.value,
      location: vehicleForm.location.value,
      motor: vehicleForm.motor.value,
      next_maintenance: vehicleForm.nextMaintenance.value,
      next_maintenance_location: vehicleForm.nextMaintenanceLocation.value,
      is_active: vehicleForm.isActive.value,
      is_available: vehicleForm.isAvailable.value,
      unavailability_start: vehicleForm.unavailabilityStart.value,
      unavailability_end: vehicleForm.unavailabilityEnd.value,
      unavailability_text: vehicleForm.unavailabilityText.value,
    }


    let res;
    try {
      if (edit) {
        res = yield call(fleet.EditVehicle, body, vehicle.id);
        if (res.status === 200) {
          yield putResolve(actions.FleetManagementForm.onSubmitDocuments());
        }
      } else {
        res = yield call(fleet.CreateVehicle, body);
        if (res.status === 200) {
          yield putResolve(actions.FleetManagementForm.onSubmitDocuments());
        }
      }
    } catch (err) {
    } finally {
      if (res.data) yield putResolve(actions.FleetManagementForm.setVehicle(res.data));
    }

  } catch (err) {
  } finally {
    yield putResolve(actions.FleetManagementForm.setSubmitButtonDisabled(false))
  }
}

function* onSubmitDocumentsSaga() {
  const {
    FleetManagementForm: {
      documentsForm,
      vehicle,
      deletedDocQueue
    }
  } = yield select((state: RootState) => state);

  try {
    const editedDocuments = documentsForm.filter(el => !el.new && el.dirty);
    const newDocuments = documentsForm.filter(el => el.new);

    if (editedDocuments.length === 0 && newDocuments.length === 0 && deletedDocQueue.length === 0) return;
    //post new docs
    newDocuments.forEach(async (doc: VehicleDocumentsForm) => {
      const body = {
        documentType: doc.documentType.value,
        vehicle: vehicle.id,
        ...(doc.expiration.value !== "" ? { expiration: doc.expiration.value } : null),
        file: doc.file
      }
      await fleet.CreateDocument(body);
    })
    //edit docs
    editedDocuments.forEach(async (doc: VehicleDocumentsForm) => {
      const body = {
        documentType: doc.documentType.value,
        ...(doc.expiration.value !== "" ? { expiration: doc.expiration.value } : null),
      }
      await fleet.EditDocument(body, doc.id.value);
    })

    //delete docs
    deletedDocQueue.forEach(async (doc: VehicleDocumentsForm) => {
      await fleet.DeleteDocument(doc.id.value);
    })

  } catch (err) {
    toast.error(i18n.t("toasts.setVehicleError"));
  } finally {
    yield putResolve(actions.FleetManagementForm.emptyDeletedQueue());
    yield putResolve(actions.FleetManagementForm.setLoading(false));
    yield putResolve(actions.App.navigateTo("/frota/veiculos"));
  }
}

function* setVehicleSaga({ payload }: AnyAction) {
  //state.vehicle = payload;
  const form = { ...defaultForm }
  //populating documentForm
  let docs = payload.documents.map(doc => {
    return {
      dirty: false,
      documentType: {
        value: doc.documentType?.id ?? -1,
        valid: true,
        message: ""
      },
      expiration: {
        value: doc.expiration,
        valid: true,
        message: ""
      },
      url: doc.file,
      fileName: doc.name,
      id: {
        value: doc.id,
        valid: true,
        message: ""
      },
      isActive: {
        value: doc.isActive,
        valid: true,
        message: ""
      }
    }
  });

  try {
    Object.keys(form).forEach((key) => {
      //populating vehicleForm
      if (key === "vehicleClassId") {
        form[key] = {
          ...form[key],
          value: payload.vehicleClass?.id ?? ""
        }
      } else if (key === "vehicleTypeId") {
        form[key] = {
          ...form[key],
          value: payload.vehicleType?.id ?? ""
        }
      } else if (key === "fuelTypeId") {
        form[key] = {
          ...form[key],
          value: payload.fuelType?.id ?? ""
        }
      } else {
        form[key] = {
          ...form[key],
          value: payload[key]
        }
      }
    })
    yield putResolve(actions.FleetManagementForm.setDocumentForm(docs));
    yield putResolve(actions.FleetManagementForm.setForm(form));
  } catch (err) {
  } finally {
    yield putResolve(actions.FleetManagementForm.setLoading(false));
  }
  //state.vehicleForm = form;
}

export default function* watcherVehicleForm() {
  yield takeLatest('fleetmanagementform/onMount', onMountFleetManagementForm);
  yield takeLatest('fleetmanagementform/requestVehicle', requestVehicle);
  yield takeLatest('fleetmanagementform/onSubmit', onSubmitSaga);
  yield takeLatest('fleetmanagementform/deleteVehicle', deleteVehicleSaga);
  yield takeLatest('fleetmanagementform/setVehicle', setVehicleSaga);
  yield takeLatest('fleetmanagementform/onSubmitDocuments', onSubmitDocumentsSaga);
}

