import { DateTime, Interval } from 'luxon';
import type { InitialStateProps } from "../../../logic/initialState"
import { PlanningDatesForm, PlanningServiceForm, PlanningStopsForm, ServiceTypes } from 'types/trips/plannings'
import { ActivityExtrasForm, ActivityPassengerTypesForm } from 'types/trips/activity/activityForm';
import { days } from 'utils';
import { PayloadAction } from '@reduxjs/toolkit';
import { createServiceSettingsStructure, createServiceTypeDetailsStructure, isTripService } from '../utils';

const ServicesReducers = {
  addPrincipalService: (state: InitialStateProps, { payload }: PayloadAction<{
    services,
    serviceType: ServiceTypes
    settings?: ActivityPassengerTypesForm[] | ActivityExtrasForm[]
  }>
  ) => {

    const services: PlanningServiceForm[] = Array.from(payload.services)
    const serviceType: ServiceTypes = payload.serviceType

    const serviceDetails = createServiceTypeDetailsStructure(serviceType)

    let newService = {
      serviceType: serviceType ?? '',
      serviceDetails: serviceDetails,
      ...createServiceSettingsStructure(serviceType, payload.settings ?? [])
    }

    if (Array.isArray(services) && !services.length) {
      services.push(newService)
    }
    else {
      services[0] = newService
    }

    state.services = services
  },
  addSecondaryServices: (state: InitialStateProps, { payload }: PayloadAction<{
    services,
    passengerTypes?: ActivityPassengerTypesForm[],
  }>) => {
    //A secondary Service, for now, can only be of type Trip

    const services: PlanningServiceForm[] = Array.from(payload.services)
    const servicePassengerTypes: ActivityPassengerTypesForm[] = Array.from(payload.passengerTypes ?? [])

    //The secondary services are always equals to the main service.
    //Thats what this instruction is doing (i dont know why)
    const serviceType: ServiceTypes = payload.services[0].serviceType
    const serviceDetails = createServiceTypeDetailsStructure(serviceType)

    services.push({
      serviceType: serviceType ?? '',
      passengerTypes: servicePassengerTypes,
      serviceDetails: serviceDetails
    })

    state.services = services
  },
  changeServiceType: (state: InitialStateProps, { payload }) => {
    const services: PlanningServiceForm[] = Array.from(payload.services)
    const serviceType: ServiceTypes = payload.serviceType
    const serviceDetails = createServiceTypeDetailsStructure(serviceType)

    services[payload.idx] = {
      ...services[payload.idx],
      serviceType: serviceType ?? '',
      serviceDetails: serviceDetails,
      ...createServiceSettingsStructure(serviceType, payload.settings ?? [])
    }

    state.services = services
  },
  onSelectActivity: (state: InitialStateProps, { payload }) => {
    // TODO: refactor this method (important for type safety)
    // activity passengertypes/extras are imported here and added to the planning configuration. They can later be overrided

    const services: PlanningServiceForm[] = Array.from(payload.services)
    const serviceSettings = isTripService(services[0]) ?
      Array.from(services[payload.idx].passengerTypes ?? []) as any[]
      :
      Array.from(services[payload.idx].extras ?? []) as any[]
    let updatedArray: any[] = []

    if (isTripService(services[0])) {

      //importing passenger types from the selected activity (serviceInfo)
      updatedArray = serviceSettings.map((pt) => {
        if (payload.serviceInfo?.passengerTypes.find(p => p.id === pt.id)) {
          const p = payload.serviceInfo?.passengerTypes.find(p => p.id === pt.id)
          return {
            ...pt,
            isActive: true,
            price: { value: p.price, valid: true, message: "" },
            priceForIsland: { value: p.priceForIsland, valid: true, message: "" },
            isOn: { value: true, valid: true, message: "" }
          }
        }
        else {
          return {
            ...pt,
            isActive: false,
            isOn: { value: false, valid: true, message: "" }
          }
        }
      }) as ActivityPassengerTypesForm[]
    }
    else {
      //importing extras from the selected activity (serviceInfo)
      updatedArray = serviceSettings.map((ex) => {

        if (payload.serviceInfo?.extras.find(p => p.id === ex.id)) {
          const p = payload.serviceInfo?.extras.find(p => p.id === ex.id)

          return {
            ...ex,
            extraMaxCapacity: String(p.maxCapacity),
            maxCapacity: { value: p.maxCapacity ?? ex.maxCapacity.value, valid: true, message: "" },
            isActive: { value: true, valid: true, message: "" },
            price: { value: p.price, valid: true, message: "" },
            isOn: { value: true, valid: true, message: "" }
          }
        }
        else {
          return {
            ...ex,
            isActive: { value: true, valid: true, message: "" },
            isOn: { value: false, valid: true, message: "" }
          }
        }
      }) as ActivityExtrasForm[]
    }

    services[payload.idx] = {
      ...services[payload.idx],
      ...(isTripService(services[0]) ? { passengerTypes: updatedArray } : { extras: updatedArray }),
      serviceDetails: {
        id: { value: payload.serviceInfo?.id, valid: true, message: "" },
        generateIslandTrips: payload.serviceInfo ? payload.serviceInfo.generateIslandTrips : false,
        shortName: payload.serviceInfo ? payload.serviceInfo.shortName : "",
        duration: payload.serviceInfo ? payload.serviceInfo.duration : "",
        vehicles: payload.serviceInfo ? payload.serviceInfo.vehicles : [],
        circuits: payload.serviceInfo ? payload.serviceInfo.circuits : [],
        isPrivate: payload.serviceInfo ? payload.serviceInfo.isPrivate : false,
        allowMultipleActivities: payload.serviceInfo ? payload.serviceInfo.allowMultipleActivities : false
      }
    } as any

    //* If the service is private we populate the minimum price and included seats
    if(payload.serviceInfo.isPrivate) {
      state.minimumPrice = { value: payload.serviceInfo.minimumPrice, valid: true, message: "" }
      state.includedSeats = { value: payload.serviceInfo.includedSeats, valid: true, message: "" }  
    } else {
      //* We do this in the case the user changed the activity from a private to a non-private one
      state.minimumPrice = { value: "", valid: true, message: "" }
      state.includedSeats = { value: "", valid: true, message: "" }  
    }

    const outS: PlanningStopsForm[] = payload.outboundStops?.map((st: PlanningStopsForm) => {
      const originalServiceHasActiveStop = st.activities[0]?.isOn.value

      let finalDetails
      let newActivity = {
        id: services[payload.idx].serviceDetails.id.value,
        shortName: services[payload.idx].serviceDetails.shortName,
        isOn: { value: originalServiceHasActiveStop || false, valid: true, message: "" }
      }

      if (st.stop.details.length > 0) {
        finalDetails = st.stop.details.map((detail) => {
          return {
            ...detail,
            activities: [
              ...detail.activities,
              newActivity
            ]
          }
        })
      } else {
        finalDetails = []
      }

      st = {
        ...st,
        activities: [...st.activities, newActivity],
        stop: {
          ...st.stop,
          details: finalDetails
        }
      }
      return st
    })

    const inS: PlanningStopsForm[] = payload.inboundStops?.map((st) => {
      const originalServiceHasActiveStop = st.activities[0]?.isOn.value

      let finalDetails
      let newActivity = {
        id: services[payload.idx].serviceDetails.id.value,
        shortName: services[payload.idx].serviceDetails.shortName,
        isOn: { value: originalServiceHasActiveStop || false, valid: true, message: "" }
      }

      if (st.stop.details.length > 0) {
        finalDetails = st.stop.details.map((detail) => {
          return {
            ...detail,
            activities: [
              ...detail.activities,
              newActivity
            ]
          }
        })
      } else {
        finalDetails = []
      }

      st = {
        ...st,
        activities: [...st.activities, newActivity],
        stop: {
          ...st.stop,
          details: finalDetails
        }
      }

      return st
    })

    state.passengerTypesSwitch = true
    state.extrasSwitch = true
    state.services = services
    state.outboundStops = outS
    state.inboundStops = inS

    state.updatedActivities = true
    state.updatedStops = true
    state.updatedCircuit = true
  },
  onPassengerTypesFormChange: (state: InitialStateProps, { payload }) => {
    //check which service is being updated
    const services: PlanningServiceForm[] = Array.from(payload.services)
    const servicePassengerTypes: ActivityPassengerTypesForm[] = Array.from(services[payload.serviceIdx].passengerTypes ?? [])

    if (payload.el.hasOwnProperty("id")) {
      const updatedArray: ActivityPassengerTypesForm[] = servicePassengerTypes.map((pt, idx) => {
        if (pt.id === payload.el.id) {
          return {
            ...pt,
            priceForIsland: { value: payload.inputName === 'priceForIsland' ? payload.e.target.value : pt.priceForIsland.value, valid: true, message: "" },
            price: { value: payload.inputName === 'price' ? payload.e.target.value : pt.price.value, valid: true, message: "" },
            isOn: { value: payload.inputName === 'none' ? !pt.isOn.value : pt.isOn.value, valid: true, message: "" }
          }
        } else {
          return pt
        }
      })

      services[payload.serviceIdx] = {
        ...services[payload.serviceIdx],
        passengerTypes: updatedArray
      }
    }

    state.services = services
    state.updatedActivities = true
  },
  onExtrasFormChange: (state: InitialStateProps, { payload }) => {

    //check which service is being updated
    const services: PlanningServiceForm[] = Array.from(payload.services)
    const serviceExtras: ActivityExtrasForm[] = Array.from(services[payload.serviceIdx].extras ?? [])

    if (payload.el.hasOwnProperty("id")) {
      const updatedArray: ActivityExtrasForm[] = serviceExtras.map((extra, idx) => {
        if (extra.id === payload.el.id) {
          return {
            ...extra,
            price: { value: payload.inputName === 'price' ? payload.e.target.value : extra.price.value, valid: true, message: "" },
            isOn: { value: payload.inputName === 'none' ? !extra.isOn.value : extra.isOn.value, valid: true, message: "" }
          }
        } else {
          return extra
        }
      })

      services[payload.serviceIdx] = {
        ...services[payload.serviceIdx],
        extras: updatedArray
      }
    }

    state.services = services
    state.updatedActivities = true
  },
  onChangeExtraMaxCapacity: (state, { payload }: PayloadAction<{
    services: PlanningServiceForm[]
    serviceIdx: number,
    extraId: number,
    value: number
  }>) => {

    //check which service is being updated
    const services: PlanningServiceForm[] = Array.from(payload.services)
    const serviceExtras: ActivityExtrasForm[] = Array.from(services[payload.serviceIdx].extras ?? [])

    const updatedArray: ActivityExtrasForm[] = serviceExtras.map((extra, idx) => {
      if (extra.id === payload.extraId) {
        return {
          ...extra,
          maxCapacity: {
            value: String(payload.value),
            valid: true,
            message: ""
          }
        }
      } else {
        return extra
      }
    })

    services[payload.serviceIdx] = {
      ...services[payload.serviceIdx],
      extras: updatedArray
    }


    state.services = services
    state.updatedActivities = true
  },
  onDateTypeChange: (state: InitialStateProps, { payload }) => {
    if (payload.dateType === 'date_interval') {
      state.dateType = payload.dateType
      state.dates = [
        {
          date: {
            value: payload.dates[0] ? payload.dates[0].date.value : "",
            valid: true,
            message: ""
          }
        },
        {
          date: {
            value: "",
            valid: true,
            message: ""
          }
        }
      ]
    } else {

      state.dateType = payload.dateType
      state.dates = [
        {
          date: {
            value: payload.dates[0] ? payload.dates[0].date.value : "",
            valid: true,
            message: ""
          }
        }
      ]
    }

  },
  addNewDate: (state: InitialStateProps, { payload }) => {
    const dates: PlanningDatesForm[] = Array.from(payload.dates)
    dates.push({
      date: {
        value: "",
        valid: true,
        message: ""
      }
    })

    state.dates = dates
  },
  onDateChange: (state: InitialStateProps, { payload }) => {
    const dates: PlanningDatesForm[] = Array.from(payload.dates)
    dates[payload.idx] = {
      ...payload.form
    }

    if (payload.dateType === "date_interval" && dates[1]?.date.value !== "") {
      let interval = Interval.fromDateTimes(DateTime.fromFormat(dates[0].date?.value, "yyyy-LL-dd"), DateTime.fromFormat(dates[1].date?.value, "yyyy-LL-dd"))

      //to check if the date interval is valid
      if (interval.s && interval.e) {
        const arr = Array.from(days(interval))
        state.dates = arr.map((d, idx) => {
          return ({
            date: { value: DateTime.fromISO(d.toISO()).toFormat("yyyy-LL-dd"), message: "", valid: true }
          })
        })
      }
      else {
        dates[1] = {
          date: { value: '', message: "", valid: true }
        }
        state.dates = dates
      }
    }
    else {
      state.dates = dates
    }
  },
  onDeleteDate: (state: InitialStateProps, { payload }) => {
    const items: PlanningDatesForm[] = Array.from(payload.dates)
    items.splice(items.indexOf(items[payload.index]), 1)

    //clear dependencies also
    state.dates = items
  },
  setShowEditDatesModal: (state: InitialStateProps, { payload }) => {
    if (payload !== false) {
      state.editFirstDate = true
    }
    state.showEditDatesModal = payload
  },
  onDeleteService: (state: InitialStateProps, { payload }) => {
    const items: PlanningServiceForm[] = Array.from(payload.services)
    items.splice(items.indexOf(items[payload.index]), 1)

    //clear dependencies also
    state.updatedActivities = true
    state.services = items
  },
  onServiceVehicleCapacityFormChange: (state: InitialStateProps, { payload }) => {
    state.maxCapacity = { value: payload.e, valid: true, message: "" }
  },
  onServiceMinimumPriceFormChange: (state: InitialStateProps, { payload }) => {
    state.minimumPrice = { value: payload.e, valid: true, message: "" }
  },
  onServiceIncludedSeatsFormChange: (state: InitialStateProps, { payload }) => {
    state.includedSeats = { value: payload.e, valid: true, message: "" }
  },
  onServiceBeachLocationFormChange: (state: InitialStateProps, { payload }) => {
    state.beachLocation = { value: payload.e, valid: true, message: "" }
  },
  toogleAllPassengerTypes: (state: InitialStateProps, { payload }) => {
    const passengerTypes: ActivityPassengerTypesForm[] = Array.from(payload.passengerTypes)

    state.passengerTypesSwitch = payload.passengerTypesSwitch
    state.services[payload.serviceIdx].passengerTypes = passengerTypes.map((pt) => {
      return {
        ...pt,
        isOn: { value: pt.isActive ? payload.passengerTypesSwitch : false, valid: true, message: "" }
      }
    })

    state.updatedActivities = true
  },
  toogleAllExtras: (state: InitialStateProps, { payload }) => {
    const extras: ActivityExtrasForm[] = Array.from(payload.extras)

    state.extrasSwitch = payload.extrasSwitch
    state.services[payload.serviceIdx].extras = extras.map((pt) => {
      return {
        ...pt,
        isOn: { value: pt.isActive ? payload.extrasSwitch : false, valid: true, message: "" }
      }
    })

    state.updatedActivities = true
  },
  setShowSuspendedTripsModal: (state: InitialStateProps, { payload }) => {
    state.showSuspendedTripsModal = payload
  },
}

export default ServicesReducers