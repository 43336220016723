import * as React from "react"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width={14}
            height={14}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M13.75 12.678l-1.07 1.07-4.832-4.831 1.073-1.071 4.829 4.832zM7.84.25c-1.935 0-3.87.735-5.355 2.212l-.007.008a7.591 7.591 0 000 10.732L13.203 2.47A7.564 7.564 0 007.84.25zM2.605 10.953a6.047 6.047 0 01-.51-5.115 8.463 8.463 0 001.552 4.08l-1.042 1.035zm2.13-2.13C3.722 7.284 3.34 5.447 3.7 3.7c.435-.09.87-.135 1.313-.135 1.35 0 2.662.413 3.81 1.17L4.734 8.822zm1.103-6.728a6.047 6.047 0 015.115.51L9.91 3.647a8.437 8.437 0 00-4.072-1.552z"
                fill={props.fill ? props.fill : "#EBEDEF"}
            />
        </svg>
    )
}

export default SvgComponent
