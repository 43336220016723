import styled from "styled-components"

export const PadWrapper = styled.div<{ activitycolor: string }>`
  display:flex;
  align-items:center;
  justify-content: space-around;
  width: 10%;
  min-width: 60px;
`

export const Text = styled.p`
  margin:0px;
`