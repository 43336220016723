/**
 * TripCategoryform scene slice
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */
import { createSlice } from '@reduxjs/toolkit'
import { CircuitForm, CircuitStopForm } from 'types/trips/circuits'
import { LocalSummaryCircuit  } from 'types/trips/locals'
import { camelizeKeys } from 'humps'


interface InitialState {
    circuitID: string,
    edit: boolean,
    circuitForm: CircuitForm,
    inboundStops: CircuitStopForm[],
    inboundStopsEnabled: boolean,
    outboundStops: CircuitStopForm[],
    outboundStopsEnabled: boolean,
    hasEditPermission: boolean,
    submitButtonState: boolean,
}

const initialState: InitialState = {
    circuitID: "",
    edit: false,
    circuitForm: {
        name: {
            value: "",
            valid: true,
            message: ""
        },
        duration: {
            value: "",
            valid: true,
            message: ""
        },
        isActive: {
            value: true,
            valid: true,
            message: ""
        }
    },
    inboundStops: [],
    inboundStopsEnabled: true,
    outboundStops: [],
    outboundStopsEnabled: true,
    hasEditPermission: true,
    submitButtonState: false
}

export default createSlice({
    name: 'tripcircuitform',
    initialState,
    reducers: {
        onMount: (state, { payload }) => { },
        onUnmount: () => {
            return initialState
        },
        setAction: (state, { payload }) => {
            state.edit = payload
        },
        setCircuitID: (state, { payload }) => {
            state.circuitID = payload
        },
        setHasEditPermission: (state, { payload }) => {
            state.hasEditPermission = payload
        },
        setSubmitButtonDisabled:(state, { payload }) => {
            state.submitButtonState = payload
        },
        setInboundStops: (state, { payload }) => {
            const stopForm: CircuitStopForm[] = Array.from([])
            payload.forEach((inboundStop) => {

                stopForm.push({
                    time: { value: "", valid: true, message: "" },
                    isActive: { value: false, valid: true, message: "" },
                    stop: {
                        id: inboundStop.id,
                        isActive: inboundStop.isActive,
                        // isAvailable: inboundStop.isAvailable,
                        name: inboundStop.name,
                        shortName: inboundStop.shortName
                    }
                })
            })

            state.inboundStops = stopForm.reverse()

        },
        setOutboundStops: (state, { payload }) => {
            const stopForm: CircuitStopForm[] = Array.from([])
            
            payload.forEach((outboundStop) => {
                stopForm.push({
                    time: { value: "", valid: true, message: "" },
                    isActive: { value: false, valid: true, message: "" },
                    stop: {
                        id: outboundStop.id,
                        isActive: outboundStop.isActive,
                        // isAvailable: outboundStop.isAvailable,
                        name: outboundStop.name,
                        shortName: outboundStop.shortName
                    }
                })
            })

            state.outboundStops = stopForm
        },
        populateForm: (state, { payload }) => {
            //const circuit = TripCircuitsMock[0]
            const circuit = camelizeKeys(payload)
            const baseForm = { ...initialState.circuitForm }
            

            Object.keys(circuit).forEach(key => {
                if (key in baseForm) {
                    baseForm[key] = {
                        ...baseForm[key],
                        value: circuit[key],
                    }
                }
            })
            state.circuitForm = baseForm;
        },
        populateInboundStops: (state, { payload }) => {

            const stops = camelizeKeys(payload)

            const final = stops.inS.map((item) => {
                const st: LocalSummaryCircuit[] = Array.from([])
                stops.inboundStops.forEach(stop => {

                    if (stop.stop.id === item.stop.id) {
                        st.push(stop)
                    }
                })

                if (st.length > 0) {
                    return {
                        time: { value: st[0].time, valid: true, message: "" },
                        isActive: { value: true, valid: true, message: "" },
                        stop: st[0].stop
                    }
                } else {
                    return item
                }
            })


            state.inboundStops = final
        },
        populateOutboundStops: (state, { payload }) => {
            const stops = camelizeKeys(payload)

            const final = stops.outS.map((item) => {
                const st: LocalSummaryCircuit[] = Array.from([])
                stops.outboundStops.forEach(stop => {

                    if (stop.stop.id === item.stop.id) {
                        st.push(stop)
                    }
                })

                if (st.length > 0) {
                    return {
                        time: { value: st[0].time, valid: true, message: "" },
                        isActive: { value: true, valid: true, message: "" },
                        stop: st[0].stop
                    }
                } else {
                    return item
                }
            })

            state.outboundStops = final
        },
        onFormChange: (state, { payload }) => {
            if (state.edit === true) {
                state.circuitForm = payload
            }
            else {
                state.circuitForm = payload
            }

        },
        onInboundStopChange: (state, { payload }) => {
            if (payload.hasOwnProperty("id")) {
                const updatedArray = payload.inboundStops.map((st, idx) => {
                    if (idx === payload.id) {
                        return {
                            time: { value: payload.e, valid: true, message: "" },
                            isActive: st.isActive,
                            stop: st.stop
                        }
                    }
                    else {
                        return st
                    }
                })
                state.inboundStops = updatedArray
            }
            else{
                const updatedArray = payload.inboundStops.map((st, idx) => {
                    if (idx === payload.idx) {
                        return {
                            time: !st.isActive.value === true ? st.time : { value: "", valid: true, message: "" },
                            isActive: { value: !st.isActive.value, valid: true, message: "" },
                            stop: st.stop
                        }
                    }
                    else {
                        return st
                    }
    
                })
                state.inboundStops = updatedArray
            }

        },
        onOutboundStopChange: (state, { payload }) => {
            if (payload.hasOwnProperty("id")) {
                const updatedArray = payload.outboundStops.map((st, idx) => {

                    if (idx === payload.id) {
                        return {
                            time: { value: payload.e, valid: true, message: "" },
                            isActive: st.isActive,
                            stop: st.stop
                        }
                    }
                    else {
                        return st
                    }
                })
                state.outboundStops = updatedArray
            }
            else{
                const updatedArray = payload.outboundStops.map((st, idx) => {
                    if (idx === payload.idx) {
                        return {
                            time: !st.isActive.value === true ? st.time : { value: "", valid: true, message: "" },
                            isActive: { value: !st.isActive.value, valid: true, message: "" },
                            stop: st.stop
                        }
                    }
                    else {
                        return st
                    }
    
                })
                state.outboundStops = updatedArray
            }
            
        },
        toogleAllInboundStops: (state, { payload }) => {
            state.inboundStopsEnabled = payload.enabled
            const inbounds: CircuitStopForm[] = Array.from([])

            payload.inboundStops.forEach((inboundStop) => {
                if(inboundStop.stop.isAvailable){
                    inbounds.push({
                        time: payload.enabled ? inboundStop.time : {value:"", valid: true, message: ""},
                        isActive: { value: payload.enabled, valid: true, message: "" },
                        stop: inboundStop.stop
                    })
                }
                else{
                    inbounds.push(inboundStop)
                }
            })

            state.inboundStops = inbounds
        },
        toogleAllOutboundStops: (state, { payload }) => {
            state.outboundStopsEnabled = payload.enabled
            const outbounds: CircuitStopForm[] = Array.from([])

            payload.outboundStops.forEach((outboundStop) => {
                if(outboundStop.stop.isAvailable){
                    outbounds.push({
                        time: payload.enabled ? outboundStop.time : {value:"", valid: true, message: ""},
                        isActive: { value: payload.enabled, valid: true, message: "" },
                        stop: outboundStop.stop
                    })
                }
                else{
                    outbounds.push(outboundStop)
                }
            })

            state.outboundStops = outbounds
        },
        onUpdateStopsValidationMessages: (state, { payload }) => {

            const outboundsUpdated = payload.outbounds.map((st,stIdx) => {
                if(payload.outboundStopsValidation.form.find(s => s.stop.id === st.stop.id)){
                    const s = payload.outboundStopsValidation.form.find(s => s.stop.id === st.stop.id)
                    return{
                        ...st,
                        time: s.time
                    }
                }
                else return st 
            })

            const inboundsUpdated = payload.inbounds.map((st,stIdx) => {
                if(payload.inboundStopsValidation.form.find(s => s.stop.id === st.stop.id)){
                    const s = payload.inboundStopsValidation.form.find(s => s.stop.id === st.stop.id)
                    return{
                        ...st,
                        time: s.time
                    }
                }
                else return st 
            })

            state.outboundStops = outboundsUpdated
            state.inboundStops = inboundsUpdated
        },
        onSubmit: (state, { payload }) => { }
    }
})