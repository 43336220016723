/**
 * BookingForm scene slice
 *
 * @author João Flores <jflores@ubiwhere.com>
 *
 *
 */
import { createSlice } from '@reduxjs/toolkit';
import { DateTime, Duration } from 'luxon';
import { BookingExtrasForm, BookingPaymentEntry } from 'types/trips/bookings';
import initialState from './initialState';
// Other Reducers
import BookingLegsReducers from '../components/BookingLegs/logic/reducers';
import CustomerFormReducers from '../components/CustomerForm/logic/reducers';
import ModalsReducers from '../components/Modals/logic/reducers';
import PartnerFormReducers from '../components/Partnerform/logic/reducers';
import PassengerTypesReducers from '../components/PassengerTypes/logic/reducers'
// Types
import {
  getEntriesTotalWithoutDiscount,
  updateEntriesIsRefund,
  updateEntriesTotal,
  getCorrectionValue,
  updateBeachEntryAmount,
  updateExtrasTypes,
  getBeachTripTotalAmount,
  addBeachEntrie
} from './utils'

import { updatePassengerTypes } from '../components/PassengerTypes/utils';


export default createSlice({
  name: 'BookingForm',
  initialState,
  reducers: {
    ...BookingLegsReducers,
    ...CustomerFormReducers,
    ...ModalsReducers,
    ...PartnerFormReducers,
    ...PassengerTypesReducers,
    onMount: (state, { payload }) => { },
    onUnmount: () => {
      return initialState;
    },
    onSubmit: () => { },
    setCancelFullBooking: (state, { payload }) => {
      state.cancelFullBooking = payload;
    },
    setCreatedBy: (state, { payload }) => {
      state.createdBy = {
        ...state.createdBy,
        name: payload.name,
        uuid: payload.uuid,
        userType: payload.userType
      }
    },
    setCreatedTime: (state, { payload }) => {
      state.createdBy = {
        ...state.createdBy,
        date: DateTime.fromISO(payload).toFormat('dd/LL/yyyy HH:mm')
      }
    },
    setCanceledBy: (state, { payload }) => {
      state.canceledBy = {
        ...state.canceledBy,
        name: payload.name,
        uuid: payload.uuid,
        userType: payload.userType
      }
    },
    setCanceledTime: (state, { payload }) => {

      state.canceledBy = {
        ...state.canceledBy,
        date: DateTime.fromISO(payload).toFormat('dd/LL/yyyy HH:mm')
      }
    },
    setPartners: (state, { payload }) => {
      state.partners = [...payload.partners, ...payload.newPartners];
    },
    setIsFree: (state, { payload }) => {
      state.isFree = payload
    },
    setPartnersPage: (state, { payload }) => {
      state.partnersPage = payload;
    },
    setIsPartnersLastPage: (state, { payload }) => {
      state.isPartnersLastPage = payload;
    },
    setCustomers: (state, { payload }) => {
      state.customers = [...payload.customers, ...payload.newCustomers];
    },
    setCustomersPage: (state, { payload }) => {
      state.customersPage = payload;
    },
    setIsCustomersLastPage: (state, { payload }) => {
      state.isCustomersLastPage = payload;
    },
    setTableLoading: (state, { payload }) => {
      state.tableLoading = payload
    },
    populatePartnerForm: (state, { payload }) => {
      let partnerForm = {
        partner: {
          ...state.partnerForm.partner,
          value: payload,
        },
      };
      state.partnerForm = partnerForm;
    },
    cancelBooking: () => { },
    setBookingState: (state, { payload }) => {
      state.bookingState = payload;
    },
    onSendTicketFormChange: (state, { payload }) => {
      state.sendTicketForm = payload;
    },
    setDirectionFilter: (state, { payload }) => {
      state.directionFilter = payload;
    },
    setCanRescheduleLegs: (state, { payload }) => {
      state.canRescheduleLegs = payload;
    },
    onPaymentFormChange: (state, { payload }) => {
      state.paymentForm = payload;
    },
    onInvoicePaymentFormChange: (state, { payload }) => {
      state.invoicePaymentForm = payload;
    },
    setRefundPending: (state, { payload }) => {
      state.refundPending = payload;
    },
    onInvoiceRefundFormChange: (state, { payload }) => {
      state.invoiceRefundForm = payload;
    },
    setHasEditPermission: (state, { payload }) => {
      state.hasEditPermission = payload;
    },
    setLockPassengerInputs: (state, { payload }) => {
      state.lockPassengerInputs = payload
    },
    setLockExtraInputs: (state, { payload }) => {
      state.lockExtraInputs = payload
    },
    setTripDate: (state, { payload }) => {
      //DOUBT: should apply fee if is beach booking?

      state.tripDate = DateTime.fromISO(payload);
      if (state.tripDate) {
        let now = DateTime.now();
        let paysFee = state.tripDate.minus({
          hours: state.tripsCancelRescheduleSettings.timeNoFee.hours,
          minutes: state.tripsCancelRescheduleSettings.timeNoFee.minutes,
        });
        if (now < paysFee) {
          state.applyFeeToTrips = false;
        } else {
          state.applyFeeToTrips = true;
        }
      }
    },
    setInboundDate: (state, { payload }) => {
      if (payload?.tripActivityLeg) {
        let inboundTime = DateTime.fromISO(payload.tripActivityLeg.date).plus(
          Duration.fromISOTime(payload.tripActivityLeg.time)
        );
        let now = DateTime.now();
        let paysFee = inboundTime.minus({
          hours: state.tripsCancelRescheduleSettings.timeNoFee.hours,
          minutes: state.tripsCancelRescheduleSettings.timeNoFee.minutes,
        });

        if (now < paysFee) {
          state.applyFeeToTrips = false;
        } else {
          state.applyFeeToTrips = true;
        }
      }
    },
    setTripsCancelRescheduleSettings: (state, { payload }) => {
      state.tripsCancelRescheduleSettings = payload;
    },
    getCancelRescheduleSettings: () => { },
    getBooking: (state, { payload }) => { },
    setBookingID: (state, { payload }) => {
      state.bookingID = payload;
    },
    setBookingUID: (state, { payload }) => {
      state.bookingUID = payload;
    },
    setBookingUUID: (state, { payload }) => {
      state.bookingUUID = payload;
    },
    setEditMode: (state, { payload }) => {
      state.edit = payload;
    },
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
    setSubmitButtonDisabled: (state, { payload }) => {
      state.submitButtonState = payload;
    },
    setExistingCustomer: (state, { payload }) => {
      state.customer = {
        uuid: payload.uuid,
        name: {
          value: payload.name,
          valid: true,
          message: '',
        },
        observations: {
          value: payload.observations || '',
          valid: true,
          message: '',
        },
        phone: {
          value: payload.phone || '',
          valid: true,
          message: '',
        },
        email: {
          value: payload.email || '',
          valid: true,
          message: '',
        },
        lang: {
          value: payload.lang || '',
          valid: true,
          message: '',
        },
        country: {
          value: payload.country || '',
          valid: true,
          message: '',
        },
        nif: {
          value: payload.nif || '',
          valid: true,
          message: '',
        },
      };
    },
    setCurrentCustomer: (state, { payload }) => {
      state.currentCustomer = {
        uuid: payload.uuid,
        name: payload.name,
        nif: payload.nif
      }
    },
    onTripInfoFormChange: (state, { payload }) => {
      state.tripInfoForm = payload;
    },
    setTripLegs: (state, { payload }) => {
      state.tripLegs = payload;
    },
    setTotalLegs: (state, { payload }) => {
      state.totalLegs = payload;
    },
    setBeachTrips: (state, { payload }) => {
      state.beachTrips = payload;
    },
    setTotalBeachTrips: (state, { payload }) => {
      state.totalBeachTrips = payload;
    },
    setOutboundCategoryFilter: (state, { payload }) => {
      state.outboundCategoryFilter = payload;
    },
    setDisableInboundView: (state, { payload }) => {
      state.disableInboundView = payload;
    },
    setOutbound: (state, { payload }) => {
      if (!state.outbound.wasCanceled) {
        if (payload !== null) {
          if (payload.hasOwnProperty('id')) {
            //field wasCanceled to check if the outbound leg was already canceled but not refunded yet
            //if wasCanceled is true we dont need to cancel the trip again.
            const outbound = {
              ...payload,
              ...(payload.isCanceled ? { wasCanceled: true } : { wasCanceled: false }),
            };

            state.outbound = outbound;

          } else {
            state.outbound = initialState.outbound;
          }
        } else {
          state.outbound = {
            ...initialState.outbound,
            wasCanceled: true,
          };
        }

        state.disableInboundView = payload?.checkedIn ? true : false;


        //it means it is a beach booking
        if (payload?.tripActivityLeg?.availableExtras?.length > 0) {
          state.directionFilter = 'beach'
          state.selectedBeachTrip = {
            ...payload,
            ...(payload.isCanceled ? { wasCanceled: true } : { wasCanceled: false }),
            beachTripActivity: payload.tripActivityLeg //we change the field name here ( from tripActivityLeg to beachTripActivity ) because is a beach trip
          }

          state.selectedBeachExtras = updateExtrasTypes(
            state.selectedBeachTrip.beachTripActivity,
            state.selectedBeachExtras,
            false,
          );

          state.bookedBeachExtras = updateExtrasTypes(
            state.selectedBeachTrip.beachTripActivity,
            state.selectedBeachExtras,
            false,
          );
        }
        else {
          state.directionFilter = payload?.checkedIn
            ? 'inbound'
            : DateTime.fromISO(payload?.tripActivityLeg?.date)
              .plus(Duration.fromISOTime(payload?.tripActivityLeg?.departure?.time))
              .toISO() < DateTime.now().toISO()
              ? 'inbound'
              : 'outbound';

          state.selectedPassengerTypes = updatePassengerTypes(
            state.outbound.tripActivityLeg,
            state.inbound.tripActivityLeg,
            state.selectedPassengerTypes,
            state.allowOverbooking,
            state.bookedTotalPassengers
          );

          state.bookedPassengerTypes = updatePassengerTypes(
            state.outbound.tripActivityLeg,
            state.inbound.tripActivityLeg,
            state.selectedPassengerTypes,
            state.allowOverbooking,
            state.bookedTotalPassengers
          );
        }
      }
    },
    setInbound: (state, { payload }) => {
      if (!state.inbound.wasCanceled) {
        if (payload !== null) {
          //field wasCanceled to check if the inbound leg was already canceled but not refunded yet
          //if wasCanceled is true we dont need to cancel the trip again.
          const inbound = {
            ...payload,
            ...(payload.isCanceled ? { wasCanceled: true } : { wasCanceled: false }),
          };

          state.inbound = inbound;
        } else {
          state.inbound = {
            ...initialState.inbound,
            wasCanceled: true,
          };
        }

        state.selectedPassengerTypes = updatePassengerTypes(
          state.outbound.tripActivityLeg,
          state.inbound.tripActivityLeg,
          state.selectedPassengerTypes,
          state.allowOverbooking,
          state.bookedTotalPassengers
        );

        state.bookedPassengerTypes = updatePassengerTypes(
          state.outbound.tripActivityLeg,
          state.inbound.tripActivityLeg,
          state.selectedPassengerTypes,
          state.allowOverbooking,
          state.bookedTotalPassengers
        );
      }
    },
    resetSelectedExtras: (state, { payload }) => {
      state.selectedBeachExtras = payload;
    },
    populateSelectedExtras: (state, { payload }) => {
      if (state.edit) {
        const selectedExtras = state.selectedBeachExtras.map((c) => {
          let bookingExtra = payload.find((p) => p.extra === c.id);

          if (bookingExtra) {
            let newExtra = {
              ...c,
              price: +bookingExtra.price,
              quantity: { ...c.quantity },
              totalPrice: c.totalPrice,
            };
            newExtra.quantity.value = bookingExtra.quantity.toString();
            newExtra.totalPrice = (bookingExtra.quantity * newExtra.price).toString();

            return newExtra;
          } else {
            return { ...c, quantity: { ...c.quantity }, totalPrice: c.totalPrice };
          }
        });


        state.bookedBeachExtras = selectedExtras
        state.selectedBeachExtras = selectedExtras;

        //DOUBT: should i have total extras also?
        /*         let totalPassengers = state.selectedPassengerTypes.reduce((prev, curr) => {
                  return prev + Number(curr.quantity.value);
                }, 0);
        
                state.totalPassengers = totalPassengers
                state.bookedTotalPassengers = totalPassengers */
      }
    },
    setEditExtras: (state, { payload }) => {

      //* Calculate new extras and 
      const newExtras = state.selectedBeachExtras.map((extra) => {
        if (extra.id === payload.id) {
          let newExtra = { ...extra, quantity: { ...extra.quantity }, totalPrice: extra.totalPrice };
          newExtra.quantity.value = payload.value.toString();
          newExtra.totalPrice = (payload.value * newExtra.price).toString();

          return newExtra;
        } else {
          return { ...extra, quantity: { ...extra.quantity }, totalPrice: extra.totalPrice };
        }
      });

      state.selectedBeachExtras = newExtras;

      //* Checks if new extras are the same as the booked
      let sameExtras = state.bookedBeachExtras.every((extra) => {
        const newExtra = newExtras.find((newExtra) => newExtra.id === extra.id)
        return extra.quantity.value === newExtra?.quantity.value ? true : false
      })
      state.sameExtras = sameExtras

      let entries: BookingPaymentEntry[] = state.entries

      //DOUBT: Should we Apply taxes if new total extras is less than before and if there where no taxes already
      //if so we need to create TotalExtras and use the same logic that is in setEditPassengerTypes

      //* Here I calculate the diference between the bookedExtras and the new value
      let calculatedExtras: BookingExtrasForm[] = []
      calculatedExtras = state.selectedBeachExtras.map((extra, idx) => {
        if (extra.quantity.value === state.bookedBeachExtras[idx].quantity.value) {
          // If the number of extras didn´t change the amount added will be 0;
          return {
            ...extra,
            quantity: { ...extra.quantity, value: "0" }
          }
        } else {
          return {
            ...extra,
            quantity: { ...extra.quantity, value: (Number(extra.quantity.value) - Number(state.bookedBeachExtras[idx].quantity.value)).toString() }
          }
        }
      })

      //* Variables needed bellow
      let calcTotalExtrasAmount = 0

      calcTotalExtrasAmount = +(
        getBeachTripTotalAmount(calculatedExtras) - calcTotalExtrasAmount
      ).toFixed(2);


      if (!sameExtras) {
        if (
          state.selectedBeachTrip?.beachTripActivity?.id &&
          state.selectedBeachTrip?.beachTripActivity?.id > 0
        ) {
          //* Remove all pending refund entries that don´t have an id (it means they were still not saved in the DB)
          entries = entries.filter((e) => !(e.leg?.beachTripActivity && e.state === 'pending' && !e.id && !e.isFee))

          //* add new pending entrie
          entries.push(addBeachEntrie(true, true, state.selectedBeachTrip, calcTotalExtrasAmount));
        }
      } else {
        entries = state.initialEntries
      }

      state.entries = entries;
      state.paymentForm.correction.value = getCorrectionValue(state.entries, state.paymentForm, state.isFree, true);
      state.entriesTotal = Math.abs(+updateEntriesTotal(state.entries, state.paymentForm, true).toFixed(2));
      state.showPaymentForm = getEntriesTotalWithoutDiscount(state.entries, true) !== 0;
      state.entriesIsRefund = updateEntriesIsRefund(+updateEntriesTotal(state.entries, state.paymentForm, true).toFixed(2));
      state.paymentForm.isRefund = updateEntriesIsRefund(+updateEntriesTotal(state.entries, state.paymentForm, true).toFixed(2));
    },
    setSelectedExtras: (state, { payload }) => {

      const newExtras = state.selectedBeachExtras.map((extra) => {
        if (extra.id === payload.id) {
          let newExtra = {
            ...extra,
            quantity: { ...extra.quantity },
            totalPrice: extra.totalPrice
          };
          newExtra.quantity.value = payload.value.toString();
          newExtra.totalPrice = (payload.value * extra.price).toString();

          return newExtra;
        } else {
          return { ...extra, quantity: { ...extra.quantity }, totalPrice: extra.totalPrice };
        }
      });

      //DOUBT: should i have total extras to?
      /*       let totalPassengers = newPTypes.reduce((prev, curr) => {
              return prev + Number(curr.quantity.value);
            }, 0); */

      state.selectedBeachExtras = newExtras;
      state.entries = updateBeachEntryAmount(
        state.selectedBeachTrip,
        state.selectedBeachExtras,
        state.entries
      );

      state.paymentForm.correction.value = getCorrectionValue(state.entries, state.paymentForm, state.isFree, true);
      state.entriesTotal = +updateEntriesTotal(state.entries, state.paymentForm).toFixed(2);
      state.showPaymentForm = getEntriesTotalWithoutDiscount(state.entries) !== 0;
      state.entriesIsRefund = updateEntriesIsRefund(state.entriesTotal);
      state.paymentForm.isRefund = updateEntriesIsRefund(state.entriesTotal);
    },
    setPayments: (state, { payload }) => {
      state.payments = payload;
    },
    setEntries: (state, { payload }) => {
      state.entries = payload.entries.map((e) => {

        if (state.directionFilter === 'beach') {
          //changing the name to beachTripActivity and removing tripActivityLeg
          const leg = { ...e.leg, beachTripActivity: e.leg.tripActivityLeg }
          const { tripActivityLeg, ...beachLeg } = leg

          return {
            ...e,
            leg: beachLeg,
            canceled: false,
            isBecausePassenger: false
          }
        }
        else {
          return {
            ...e,
            canceled: false,
            isBecausePassenger: false
          }
        }

      });

      state.initialEntries = payload.entries.map((e) => {
        if (state.directionFilter === 'beach') {
          //changing the name to beachTripActivity and removing tripActivityLeg
          const leg = { beachTripActivity: e.leg.tripActivityLeg, ...e.leg }
          const { tripActivityLeg, ...beachLeg } = leg

          return {
            ...e,
            leg: beachLeg,
            canceled: false,
            isBecausePassenger: false
          }
        }
        else {
          return {
            ...e,
            canceled: false,
            isBecausePassenger: false
          }
        }
      });

      if (payload.entries?.some((e) => e.isRefund)) {
        state.paymentForm.correction.value = getCorrectionValue(state.entries, state.paymentForm, state.isFree);
        state.entriesTotal = Math.abs(
          +updateEntriesTotal(state.entries, state.paymentForm).toFixed(2)
        );
        state.showPaymentForm = getEntriesTotalWithoutDiscount(state.entries) !== 0;
        state.entriesIsRefund = updateEntriesIsRefund(
          +updateEntriesTotal(state.entries, state.paymentForm).toFixed(2)
        );
        state.paymentForm.isRefund = updateEntriesIsRefund(
          +updateEntriesTotal(state.entries, state.paymentForm).toFixed(2)
        );
      } else {
        state.paymentForm.correction.value = getCorrectionValue(state.entries, state.paymentForm, state.isFree);
        state.entriesTotal = +updateEntriesTotal(state.entries, state.paymentForm).toFixed(2);
        state.showPaymentForm = getEntriesTotalWithoutDiscount(state.entries) !== 0;
        state.entriesIsRefund = updateEntriesIsRefund(state.entriesTotal);
        state.paymentForm.isRefund = updateEntriesIsRefund(state.entriesTotal);
      }
    },
    setEnableInvoice: (state, { payload }) => {
      state.enableInvoice = payload;
    },
    setShowSendTicketModal: (state, { payload }) => {
      state.showSendTicketModal = payload;
    },
    setShowCancelationModal: (state, { payload }) => {
      state.showCancelationModal = payload;
    },
    setShowPaymentForm: (state, { payload }) => {
      state.showPaymentForm = payload;
    },
    setPaymentTypes: (state, { payload }) => {
      state.paymentTypes = payload;
    },
    setLanguages: (state, { payload }) => {
      state.languages = payload;
    },
    setPaymentSources: (state, { payload }) => {
      state.paymentSources = payload;
    },
    setPaymentForm: (state, { payload }) => {
      state.paymentForm = payload;
    },
    setInvoiceForm: (state, { payload }) => {
      state.invoicePaymentForm = payload;
    },
    setCollaborators: (state, { payload }) => {
      state.collaborators = payload;
    },
    setEnablePayment: (state, { payload }) => {
      state.enablePayment = payload;
      if (state.paymentForm.isRefund) {
        const payments = state.payments;
        state.paymentForm = {
          ...state.paymentForm,
          processedBy: {
            value: payments[payments.length - 1].processedBy
              ? payments[payments.length - 1].processedBy.uuid
              : '',
            message: '',
            valid: true,
          },
          source: {
            value: payments[payments.length - 1].source
              ? payments[payments.length - 1].source.uuid
              : '',
            message: '',
            valid: true,
          },
          type: {
            value: payments[payments.length - 1].type
              ? payments[payments.length - 1].type.uuid
              : '',
            message: '',
            valid: true,
          },
          amount: {
            ...state.paymentForm.amount,
            value: state.entriesTotal.toString(),
          },
        };
      } else {
        state.paymentForm = {
          ...state.paymentForm,
          amount: {
            ...state.paymentForm.amount,
            value: state.entriesTotal.toString(),
          },
        };
      }
    },
    setEntriesTotal: (state) => {
      if (Math.abs(+updateEntriesTotal(state.entries, state.paymentForm).toFixed(2)) === 0) {
        state.isFree = true
      } else {
        state.isFree = false
      }
      state.entriesTotal = Math.abs(+updateEntriesTotal(state.entries, state.paymentForm).toFixed(2))
      state.entriesIsRefund = updateEntriesIsRefund(+updateEntriesTotal(state.entries, state.paymentForm).toFixed(2));
      state.paymentForm.isRefund = updateEntriesIsRefund(+updateEntriesTotal(state.entries, state.paymentForm).toFixed(2));
    },
    setEntriesNoValue: (state) => {
      let correctionValue: string;
      if (Number(updateEntriesTotal(state.entries, state.paymentForm).toFixed(2)) > 0) {
        correctionValue = (-Math.abs(
          +updateEntriesTotal(state.entries, state.paymentForm)
        )).toFixed(2);
      } else {
        correctionValue = Math.abs(+updateEntriesTotal(state.entries, state.paymentForm)).toFixed(
          2
        );
      }

      state.paymentForm = {
        ...state.paymentForm,
        correction: {
          ...state.paymentForm.correction,
          value: correctionValue,
        },
      };

      state.entriesTotal = +updateEntriesTotal(state.entries, state.paymentForm).toFixed(2);
    },
    setIsReschedule: (state, { payload }) => {
      let totalPassengers = 0,
        passengerTypes: number[] = [];

      state.selectedPassengerTypes.forEach((p) => {
        let qty = +p.quantity.value;
        if (p.id > -1 && p.occupySeat && qty > 0) {
          totalPassengers += qty;
          passengerTypes.push(p.id);
        }
      });

      state.isReschedule = payload;
      state.bookedOutbound = { ...state.outbound };
      state.bookedInbound = { ...state.inbound };
      state.availabilityFilter = totalPassengers;
      state.passengerTypesFilter = passengerTypes;
    },
    setIsBeachReschedule: (state, { payload }) => {

      state.isReschedule = payload;
      state.bookedBeachTrip = { ...state.selectedBeachTrip };
      state.directionFilter = 'beach'
    },
    setRescheduleTax: (state, { payload }) => {
      state.rescheduleTax = payload;
    },
  },
});