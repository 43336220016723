import * as React from "react"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width={18}
            height={18}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M14.25 2.25h-3.135A2.258 2.258 0 009 .75c-.975 0-1.8.63-2.115 1.5H3.75c-.825 0-1.5.675-1.5 1.5v10.5c0 .825.675 1.5 1.5 1.5h10.5c.825 0 1.5-.675 1.5-1.5V3.75c0-.825-.675-1.5-1.5-1.5zM9 2.062c.165 0 .307.076.412.188a.56.56 0 01.15.375.567.567 0 01-.562.563.567.567 0 01-.563-.563.56.56 0 01.15-.375A.562.562 0 019 2.062zm5.25 12.188H3.75V3.75h10.5v10.5zM9 4.5a2.257 2.257 0 00-2.25 2.25A2.257 2.257 0 009 9a2.257 2.257 0 002.25-2.25A2.257 2.257 0 009 4.5zm0 3a.752.752 0 01-.75-.75c0-.412.338-.75.75-.75s.75.338.75.75-.338.75-.75.75zm-4.5 4.852V13.5h9v-1.148c0-1.875-2.977-2.684-4.5-2.684-1.522 0-4.5.802-4.5 2.684zM6.232 12c.518-.42 1.785-.84 2.768-.84.982 0 2.258.42 2.768.84H6.232z"
                fill={props.fill ? props.fill : "#EBEDEF"}
            />
        </svg>
    )
}

export default SvgComponent
