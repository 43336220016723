import { Link } from 'react-router-dom';
import styled from 'styled-components'

import { CTableDataCell } from '@coreui/react-pro'
import { TableRow } from '../../styles'
import theme from 'ui/theme'

export const CustomLink = styled(Link)`
    font-size: 14px;
    text-decoration: none;
    color: ${theme().gray900Color};

    &:hover {
        color: ${theme().gray700Color};

        > svg {
            fill: ${theme().gray700Color};
        }
    }
`

export const BorderedWrapper = styled.div`
    padding: 1em;
    border-radius: 4px;
    border: solid 1px ${theme().gray200Color};
    margin-bottom: 24px;
`;

export const LegRow = styled(TableRow)`
    cursor: pointer;
    &.selected {
        background-color: ${theme().primary10};
    }
    &.booked {
        background-color: ${theme().success10};
    }
`;

export const IconTableCell = styled(CTableDataCell)`
    text-align: center;
`;

