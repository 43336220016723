/**
 * Datepicker component
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */

import React, { useEffect, useState } from 'react'
import { DateTime } from "luxon"
import styled from 'styled-components'
import { CFormLabel, CFormText } from '@coreui/react';
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"
import "./datepicker.css";
import theme from 'ui/theme';

interface PropTypes {
  id?: string,
  wrapperStyle?: React.CSSProperties,
  required?: boolean,
  label?: string,
  value?: DateTime,
  onChange?: Function,
  defaultValue?: string,
  timeFormat: string,
  timeInterval?: number,
  disabled?: boolean,
  valid?: boolean
  helpText?: string,
  onlyTime?: boolean,
  edited?: boolean
}

const Timepicker = (props: PropTypes) => {

  const [startDate, setStartDate] = useState<DateTime | null>(props.value ? props.value : null);

  //useEffectHere to parse the date correctly
  useEffect(() => {
    if (props.value) {
      setStartDate(DateTime.fromFormat(props.value, props.timeFormat))
    }
    else {
      setStartDate(null)
    }
  }, [props.value, props.timeFormat])

  const handleDate = (date, e) => {
    if (props.onChange != null && date !== null) {
      const luxonDate = DateTime.fromJSDate(date);
      setStartDate(luxonDate);
      props.onChange(DateTime.fromJSDate(date).toFormat(props.timeFormat));
    }
  }

  return (
    <Wrapper style={props.wrapperStyle} >
      {props.label && <CFormLabel>{props.label}<span>{!!props.required ? "*" : ""}</span></CFormLabel>}
      <TimeP
        id={props.id}
        valid={props.valid}
        disabled={props.disabled === true ? true : false}
        selected={startDate ? startDate.toJSDate ? startDate.toJSDate() : DateTime.fromFormat(startDate, props.timeFormat).toJSDate() : null}
        autoComplete="off"
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={props.timeInterval || 5}
        timeFormat={props.timeFormat}
        dateFormat="HH:mm"
        dropdownMode="select"
        placeholderText={"hh:mm"}
        onChange={(date, e) => handleDate(date, e)}
        edited={props.edited}
      />
      {props.helpText && <HelpText valid={props.valid}>{props.helpText}</HelpText>}
    </Wrapper>
  )
}

export default Timepicker

const Wrapper = styled.div`
 
  > * {
    font-family: 'Inter';
  }
 
  > label {
    font-size: 14px;
    color: ${theme().darkColor};
    > span {
      color: ${theme().primary};
    }
  }

  .react-datepicker-popper {
    z-index:20 !important;
  };
 `

const HelpText = styled(CFormText) <{ valid: boolean | undefined }>`
   color: ${(props) => props.valid === false ? theme().danger : '#D8DBE0'};
 `
const TimeP = styled(DatePicker) <{ valid: boolean | undefined, edited?: boolean }>`
     font-size: 14px;
     width: 100%;
     color:${theme().gray900Color};
     border-color:${(props) => props.valid === false ? theme().danger : '#D8DBE0'};
     line-height:1.5;
     border-style: ${({ edited }) => edited && edited === true ? 'dashed' : 'solid'};
     border-width: ${({ edited }) => edited && edited === true ? '2px' : '1px'};
     border-radius: 4px;
     padding: 0.375rem 0.75rem;
     background-position:97%;
     background-repeat:no-repeat;
     transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;

     .react-datepicker__triangle{
       left: -40px !important;
     };

     &:focus{
       border-color: #321fdb50;
       outline-width: 0.1px;
       outline-color: #321fdb50;
       outline-style: solid;
       outline-offset: 0px;
       box-shadow: 0 0 1px 5px #321fdb50;
     };

     &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
         color: ${theme().gray500Color};
         opacity: 1; /* Firefox */
     };

     &:disabled{
       background-color:#d8dbe0;
     };
 `
