import styled from 'styled-components'
import { CFormInput, CButton } from '@coreui/react-pro';
import theme from 'ui/theme';

interface InlineContainerProps {
  valid?: boolean
}


export const InlineContainer = styled.div<InlineContainerProps>`
  display: flex;

  > * {
      height: 35px;
  }

  > input {
      font-size: 14px;
      border: 1px solid ${(props) => props.valid === false ? theme().danger : theme().gray200Color};
  }
`;

export const TextInput = styled(CFormInput)`
    background-color: ${theme().white};
    border-radius: 0;
    border: solid 1px ${theme().gray200Color};
    border-width: 1px 0;
    min-width:40px;

    &.disabled, &:disabled {
        background-color: ${theme().gray100Color};
        border: solid 1px ${theme().gray200Color};
    }
`;

export const CommonButton = styled(CButton)`
    line-height: 1;
    background-color: ${theme().gray100Color};
    border: solid 1px ${theme().gray200Color};

    &:hover, &:focus, &:active {
        background-color: ${theme().gray200Color};
        border: solid 1px ${theme().gray300Color};
    }
    &.disabled, &:disabled {
        background-color: ${theme().gray100Color};
        border: solid 1px ${theme().gray200Color};
    }
    &:focus, &:active, &:focus:active {
        box-shadow: 0 0 0 .25rem ${theme().gray200Color};
    }
`;

export const SubButton = styled(CommonButton)`
    border-radius: 4px 0px 0px 4px;
`;

export const AddButton = styled(CommonButton)`
    border-radius: 0px 4px 4px 0px;
`;