import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions, RootState } from 'store/rootSlices';
//configs
import { country_codes } from 'shared/components/FormComponents/utils';
//Components
import { CCol } from '@coreui/react-pro';
import CustomTextInput from 'shared/components/CustomTextInput';
import FormComponent from 'shared/components/FormComponents';
import Dropdown from 'shared/components/FormComponents/dropdown';
//Styles
import { Row } from '../../../../styles';

const CustomerInfo = ({ t }) => {
  const { customer, hasEditPermission, tripInfoForm } = useSelector((state: RootState) => state.BookingForm);

  const { onCustomerFormChange, onTripInfoFormChange } = actions.BookingForm;

  const dispatch = useDispatch();

  const { countryCodes } = useSelector((state: RootState) => state.App);

  const langs = [
    { label: t('common.portuguese'), value: 'portuguese' },
    { label: t('common.english'), value: 'english' },
    { label: t('common.spanish'), value: 'spanish' },
    { label: t('common.french'), value: 'french' },
    { label: t('common.italian'), value: 'italian' },
    { label: t('common.german'), value: 'german' },
  ];

  return (
    <>
      <Row>
        <CCol>
          <CustomTextInput
            required={true}
            id="bookingForm-customerName"
            valid={customer.name.valid}
            value={customer.name.value}
            disabled={!hasEditPermission || customer.uuid ? true : false}
            helpText={customer.name.message}
            placeholder={t('placeholders.name')}
            label={t('common.name')}
            onChange={(e) => {
              dispatch(
                onCustomerFormChange({
                  ...customer,
                  name: { value: e.target.value, message: '', valid: true },
                })
              );
              dispatch(onTripInfoFormChange({ ...tripInfoForm, customerName: { value: e.target.value, message: "", valid: true } }))
            }}
          />
        </CCol>
        <CCol sm={6}>
          <CustomTextInput
            id="bookingForm-customerNif"
            valid={customer.nif.valid}
            value={customer.nif.value}
            disabled={!hasEditPermission || customer.uuid ? true : false}
            helpText={customer.nif.message}
            placeholder={t('placeholders.nif')}
            label={t('common.nif')}
            onChange={(e) => {
              dispatch(
                onCustomerFormChange({
                  ...customer,
                  nif: { value: e.target.value, message: '', valid: true },
                })
              );
              dispatch(onTripInfoFormChange({ ...tripInfoForm, nif: { value: e.target.value, message: "", valid: true } }))

            }}
          />
        </CCol>
      </Row>
      <Row>
        <CCol sm={6}>
          <CustomTextInput
            required
            id="bookingForm-customerEmail"
            valid={customer.email.valid}
            value={customer.email.value}
            disabled={!hasEditPermission || customer.uuid ? true : false}
            helpText={customer.email.message}
            placeholder={t('placeholders.email')}
            label={t('common.email')}
            onChange={(e) => {
              dispatch(
                onCustomerFormChange({
                  ...customer,
                  email: { value: e.target.value, message: '', valid: true },
                })
              )
              dispatch(onTripInfoFormChange({ ...tripInfoForm, email: { value: e.target.value, message: "", valid: true } }))
            }}
          />
        </CCol>
        <CCol sm={6}>
          <FormComponent
            id="bookingForm-customerPhone"
            valid={customer.phone.valid}
            value={customer.phone.value}
            helpText={customer.phone.message}
            disabled={!hasEditPermission || customer.uuid ? true : false}
            dropdown={false}
            label={t('common.phone')}
            placeholder="Portugal"
            options={country_codes}
            onChange={(e) => {
              dispatch(
                onCustomerFormChange({
                  ...customer,
                  phone: { value: e, message: '', valid: true },
                })
              );
              dispatch(onTripInfoFormChange({ ...tripInfoForm, phone: { value: e, message: "", valid: true } }))
            }}
          />
        </CCol>
      </Row>
      <Row>
        <CCol sm={6}>
          <Dropdown
            id="bookingForm-customerCountry"
            required={false}
            valid={customer.country.valid}
            value={customer.country.value}
            disabled={!hasEditPermission || customer.uuid ? true : false}
            helpText={customer.country.message}
            placeholder={t('placeholders.country')}
            label={t('customerForm.country')}
            onChange={(e) => {
              dispatch(
                onCustomerFormChange({
                  ...customer,
                  country: { value: e.target.value, message: '', valid: true },
                })
              );
              dispatch(onTripInfoFormChange({ ...tripInfoForm, country: { value: e.target.value, message: "", valid: true } }))
            }}
            options={Object.keys(countryCodes).map((key) => {
              return {
                label: countryCodes[key],
                value: key,
              };
            })}
          />
        </CCol>
        <CCol sm={6}>
          <Dropdown
            id="bookingForm-customerLanguage"
            disabled={!hasEditPermission}
            valid={customer.lang.valid}
            value={customer.lang.value}
            helpText={customer.lang.message}
            placeholder={t('placeholders.language')}
            label={t('common.language')}
            onChange={(e) => {
              dispatch(
                onCustomerFormChange({
                  ...customer,
                  lang: { value: e.target.value, message: '', valid: true },
                })
              );
              dispatch(onTripInfoFormChange({ ...tripInfoForm, lang: { value: e.target.value, message: "", valid: true } }))
            }}
            options={langs}
          />
        </CCol>
      </Row>
      <Row>
        <CCol sm={12}>
          <CustomTextInput
            id="bookingForm-customerObservations"
            valid={customer.observations.valid}
            value={customer.observations.value}
            disabled={!hasEditPermission || customer.uuid ? true : false}
            helpText={customer.observations.message}
            placeholder={t('placeholders.observations')}
            label={t('common.observations')}
            onChange={(e) => {
              dispatch(
                onCustomerFormChange({
                  ...customer,
                  observations: { value: e.target.value, message: '', valid: true },
                })
              );
            }}
          />
        </CCol>
      </Row>
    </>
  );
};

export default CustomerInfo;