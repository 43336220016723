/**
 * Invoicedetails scene
 *
 * @author Stephane Ribeiro <sgribeiro@ubiwhere.com>
 *
 *
 */
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { CForm, CRow, CCol, CTableDataCell, CFormLabel} from '@coreui/react-pro'
import { DateTime } from 'luxon'
// Components
import TableContainer from 'shared/components/TableContainer'
import CustomModal from 'shared/components/CustomModal'
import CustomTextInput from 'shared/components/CustomTextInput'
import CustomButton from 'shared/components/CustomButton';
import SectionContainer from 'shared/components/Sectioncontainer'
import Dropdown from 'shared/components/FormComponents/dropdown'
import Loadingcontainer from 'shared/components/Loadingcontainer'
import FormComponent from 'shared/components/FormComponents'
import { country_codes } from 'shared/components/FormComponents/utils'
// Icons
import DownloadIcon from 'assets/icons/file_download'
// Styles
import theme from 'ui/theme';
import * as Styles from './styles'
// Store
import { actions, RootState } from 'store/rootSlices'
// Configs
import { hasPermission } from 'permissions';
import config from 'config';
// Types
import { SyncStatus } from 'types/invoicing/invoices'

const Invoicedetails = ({ t, i18n }) => {
  
  const { id } = useParams()
  const dispatch = useDispatch()
  const { navigateBack, navigateTo } = actions.App;
  const { countryCodes } = useSelector((state: RootState) => state.App)
  const { permissions } = useSelector((state: RootState) => state.App)
  const { PERMISSIONS, DATE_FORMAT } = config
  
  const { 
    loading, 
    syncLoading,
    hasEditPermission,
    invoiceForm,
    submitButtonState,
    showEditInvoiceModal,
    entries,
    invoice,
    bookingUid,
    bookingUuid
  } = useSelector((state: RootState) => state.InvoiceDetails)

  const { 
    onMount, 
    onUnmount,
    onFormChange,
    onSubmit,
    checkIfModal,
    cancelEditForm,
    syncInvoice
  } = actions.Invoicedetails

  useEffect(() => {

    dispatch(onMount(id))
    return () => {
      dispatch(onUnmount())
    }
  }, [dispatch, onMount, onUnmount, id]);


  return (
    <Styles.Wrapper>
      {
        loading 
        ?
          <Loadingcontainer />
        :
        <>
          <SectionContainer 
            title={i18n.t('invoiceDetailsForm.syncDetails')} 
            style={{ width: '100%', paddingBottom: '24px' }}
            extra={
              <Styles.CustomRow>
                <Styles.Bold syncStatus={invoice.syncStatus}>- {t(`invoicingRequests.${invoice.syncStatus}`)} </Styles.Bold>
                <div>
                  <CustomButton
                    disabled={(invoice.syncStatus === SyncStatus.SUCCESS || syncLoading) ? true : false}
                    id='bookingForm-downloadInvoice'
                    onClick={() => dispatch(syncInvoice(invoice.id))}
                    text={t('invoiceDetailsForm.syncInvoice')}
                    color="primary"
                    variant="outline"
                    buttonStyle={{borderColor: theme().primary, height: '40px' }}
                    icon="refresh"
                  />
                  <Styles.CustomLink href={invoice.syncPdf}>
                    <DownloadIcon 
                      cursor={invoice.syncPdf ? 'pointer' : 'default'} 
                      fill={invoice.syncPdf ? theme().gray900Color : theme().gray300Color} 
                      width="30px"
                      height="30px"
                      viewBox='0 0 40px 40px'
                    />
                  </Styles.CustomLink>
                </div>
              </Styles.CustomRow>
            }
          >

              <CRow className="g-3">
                <CCol sm={6}>
                  <Styles.CustomLinkWrapper>
                    <CFormLabel>{i18n.t('common.reserveNumber')}</CFormLabel>
                    <Styles.Link onClick={() => dispatch(navigateTo(`/viagens/reservas/editar-reserva/${bookingUuid}`))}>{bookingUid ? bookingUid : 'N/A'}</Styles.Link>
                  </Styles.CustomLinkWrapper>
                </CCol>
                <CCol sm={6}>
                  <CustomTextInput
                    disabled={true}
                    id='invoiceDetails-syncUid'
                    required={false}
                    valid={true}
                    value={invoice.syncUid ? invoice.syncUid?.toString() : 'N/A'}
                    helpText={""}
                    placeholder={i18n.t('invoiceDetailsForm.syncUid')}
                    label={i18n.t('invoiceDetailsForm.syncUid')}
                  />
                </CCol>
              </CRow>
              <Styles.Row className="g-3">
                <CCol sm={6}>
                  <CustomTextInput
                    disabled={true}
                    id='invoiceDetails-syncDate'
                    required={false}
                    valid={true}
                    value={invoice.syncDate ? DateTime.fromISO(invoice.syncDate).toFormat(DATE_FORMAT.DATETIME) : 'N/A'}
                    helpText={""}
                    placeholder={i18n.t('invoiceDetailsForm.syncDate')}
                    label={i18n.t('invoiceDetailsForm.syncDate')}
                  />
                </CCol>
                <CCol sm={6}>
                  <CustomTextInput
                    disabled={true}
                    id='invoiceDetails-syncId'
                    required={false}
                    valid={true}
                    value={invoice.syncId ? invoice.syncId?.toString() : 'N/A'}
                    helpText={""}
                    placeholder={i18n.t('invoiceDetailsForm.syncId')}
                    label={i18n.t('invoiceDetailsForm.syncId')}
                  />
                </CCol>
              </Styles.Row>
              
            </SectionContainer>
          
          <CForm>
            <SectionContainer title={i18n.t('invoiceDetailsForm.titleService')} style={{ width: '100%', paddingBottom: '24px' }}>
              <CRow className="g-3">
                <CCol sm={6}>
                  <CustomTextInput
                    disabled={!hasEditPermission}
                    id='invoiceDetailsForm-name'
                    required={true}
                    valid={invoiceForm.customerName.valid}
                    value={invoiceForm.customerName.value}
                    helpText={invoiceForm.customerName.message}
                    placeholder={i18n.t('placeholders.name')}
                    label={i18n.t('invoiceDetailsForm.customerName')}
                    onChange={(e) => dispatch(onFormChange({ ...invoiceForm, customerName: { value: e.target.value, message: "", valid: true } }))}
                  />
                </CCol>
                <CCol sm={6}>
                  <CustomTextInput
                    disabled={!hasEditPermission}
                    id='invoiceDetailsForm-email'
                    valid={invoiceForm.email.valid}
                    value={invoiceForm.email.value}
                    helpText={invoiceForm.email.message}
                    placeholder={i18n.t('placeholders.email')}
                    label={i18n.t('invoiceDetailsForm.email')}
                    onChange={(e) => dispatch(onFormChange({ ...invoiceForm, email: { value: e.target.value, message: "", valid: true } }))}
                  />
                </CCol>
              </CRow>

              <Styles.Row>
                <CCol sm={6}>
                  <FormComponent
                      id='invoiceDetailsForm-phone'
                      disabled={!hasEditPermission}
                      valid={invoiceForm.phone.valid}
                      value={invoiceForm.phone.value}
                      helpText={invoiceForm.phone.message}
                      dropdown={false}
                      label={i18n.t('invoiceDetailsForm.phone')}
                      placeholder="Portugal"
                      options={country_codes}
                      onChange={(e) => dispatch(onFormChange({ ...invoiceForm, phone: { value: e, message: "", valid: true } }))}
                  />
                </CCol>

                <CCol sm={6}>
                    <Dropdown
                        id='invoiceForm-country'
                        disabled={!hasEditPermission}
                        valid={invoiceForm.country.valid}
                        helpText={invoiceForm.country.message}
                        value={invoiceForm.country.value}
                        required={true}
                        label={i18n.t('invoiceDetailsForm.country')}
                        placeholder={i18n.t('placeholders.country')}
                        options={Object.keys(countryCodes).map(key => {
                            return {
                                label: countryCodes[key],
                                value: key
                            }
                        })}
                        onChange={(e) => { dispatch(onFormChange({ ...invoiceForm, country: { value: e.target.value, message: "", valid: true } })) }}
                    />
                </CCol>
              </Styles.Row>

              <Styles.Row>
                <CCol sm={12}>
                    <CustomTextInput
                      disabled={!hasEditPermission}
                      id='invoiceDetailsForm-vat'
                      required={true}
                      valid={invoiceForm.vat.valid}
                      value={invoiceForm.vat.value}
                      helpText={invoiceForm.vat.message}
                      placeholder={i18n.t('placeholders.number')}
                      label={i18n.t('invoiceDetailsForm.vat')}
                      onChange={(e) => dispatch(onFormChange({ ...invoiceForm, vat: { value: e.target.value, message: "", valid: true } }))}
                    />
                  </CCol>
              </Styles.Row>
              
            </SectionContainer>

            <SectionContainer title={i18n.t('invoiceDetailsForm.titleMovements')}>
              
              <TableContainer
                className={Styles.CustomWrapper}
                count={0}
                elementsPerPage={0}
                currentPage={-1}
                onPageChange={() => { }}
                headerElements={[
                    t('invoiceDetailsForm.article'),
                    t('common.description'),
                    t('common.price'),
                ]}
                footer={
                  <Styles.PaymentsFooter>
                    <Styles.TableRow>
                      <CTableDataCell colSpan={2}>{t("common.total")} <span>{t("common.taxLabel")}</span></CTableDataCell>
                      <CTableDataCell colSpan={1}> {invoice.amount}€</CTableDataCell>
                    </Styles.TableRow>
                  </Styles.PaymentsFooter>
                } 
              >
                    
                  {entries && entries.map((entrie) => (
                    <Styles.TableRow
                      key={entrie.id}
                    >
                      <CTableDataCell> { entrie.article } </CTableDataCell>
                      <CTableDataCell> { entrie.description }</CTableDataCell>
                      {
                        entrie.isRefund 
                          ?
                        <CTableDataCell> <Styles.AmountNumber positive={false}> -{ entrie.amount }€ </Styles.AmountNumber></CTableDataCell> 
                          :
                        <CTableDataCell> <Styles.AmountNumber positive={true}> +{ entrie.amount }€ </Styles.AmountNumber></CTableDataCell>
                      }
                      
                        
                    </Styles.TableRow>
                  ))}
              </TableContainer>
            </SectionContainer>

            <Styles.ButtonWrapper>
              <CustomButton id='tripServiceForm-cancel' onClick={() => dispatch(navigateBack())} text={i18n.t('common.cancelBtn')} color="link" buttonStyle={{ color: theme().danger, textDecoration: 'unset' }} />
              {
                hasPermission(permissions, PERMISSIONS.INVOICING.EDIT_INVOICE) &&
                <CustomButton
                  id='invoiceDetailsForm-save'
                  disabled={submitButtonState}
                  text={i18n.t('common.saveBtn')}
                  icon="confirm"
                  onClick={() => dispatch(checkIfModal({invoice, invoiceForm }))}
                  color="success"
                  buttonStyle={{ color: "#FFFFFF" }} />
              }
            </Styles.ButtonWrapper>
            
            <CustomModal
              id={`invoiceDetailsForm-editModal`}
              warningType='message'
              warningName={i18n.t('common.warning')}
              visible={showEditInvoiceModal}
              buttonIcon={true}
              footerPosition='space-between'
              textAlign='start'
              buttonName={i18n.t('common.confirm')}
              width={600}
              noButtons={true}
              children={
                <CForm>
                  <div>
                    <Styles.Text>{i18n.t('invoiceDetailsForm.editConfirm')}</Styles.Text>
                  </div>
                  <Styles.FormFooter footerposition='flex-end' onMouseUp={(e) => { }} >
                    <Styles.CancelButtonPrimary color='danger' variant='outline' onClick={(e) => { dispatch(cancelEditForm(invoice)) }}>
                      {i18n.t('common.discardChanges')}
                    </Styles.CancelButtonPrimary>
                    <Styles.ConfirmButtonPrimary color='success' onClick={(e) => {
                      dispatch(onSubmit({ invoiceForm }))
                    }
                    }>
                      {i18n.t('common.confirm')} 
                    </Styles.ConfirmButtonPrimary>
                  </Styles.FormFooter>
                </CForm>
                
              }
              onclose={e => dispatch(cancelEditForm(invoice))}
              onsubmit={e => {}} 
            />
        
          </CForm>
        </>
        
      }
      
      
      
    </Styles.Wrapper>
  )
}

export default Invoicedetails