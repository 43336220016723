import * as Check from 'validations'
import { CategoryForm } from 'types/trips/categories'

export const CategoryFormValidations = {
    descriptionPt: [Check.isRequired, Check.isToLong],
    descriptionEn: [Check.isToLong],
    descriptionEs: [Check.isToLong],
    descriptionFr: [Check.isToLong],
    namePt: [Check.isRequired],
    nameEn: [],
    nameEs: [],
    nameFr: [],
    allowDiscounts:[],
    allowComissions:[],
    allowMultipleActivities:[],
    generateIslandTrips: [],
    generateMiddleTrips: [],
    isDirect: [],
    isHopOnHopOff: [],
    isActive:[],
    isPrivate:[],
    icon:[],
    isTransport: [],
    isBeach: [],
    allowOnlyOneBooking: [],
    showInDigitalsignage: []
}

export const CategoryFormValidationsEdit = {
    descriptionPt: [Check.isRequired, Check.isToLong],
    descriptionEn: [Check.isToLong],
    descriptionEs: [Check.isToLong],
    descriptionFr: [Check.isToLong],
    namePt: [Check.isRequired],
    nameEn: [],
    nameEs: [],
    nameFr: [],
    allowDiscounts:[],
    allowComissions:[],
    allowMultipleActivities:[],
    generateIslandTrips: [],
    generateMiddleTrips: [],
    isDirect: [],
    isHopOnHopOff: [],
    isActive:[],
    isPrivate:[],
    icon:[],
    isTransport: [],
    isBeach: [],
    allowOnlyOneBooking: [],
    showInDigitalsignage: []
}

export type SubmitSagaPayload = {
    e?: any,
    edit: boolean,
    isIconChanged: boolean,
    isIconRemoved: boolean,
    categoryForm: CategoryForm
}