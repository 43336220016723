/**
 * Locallisttablerow component
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */
import React from 'react'
import { AvailabilityTableDataCell } from 'shared/genericStyledComponents'
import { TableRowPropTypes } from 'types/misc'
import { CTableDataCell } from '@coreui/react-pro'
import ChevronRight from 'assets/icons/chevron_right'
import { LocalsListItem } from 'types/trips/locals'
import i18n from "i18next";
import { actions } from 'store/rootSlices'
import { useDispatch } from 'react-redux';
import { CustomChevronCell } from '../../styles'
import { CustomCTableRow, NameCell, CustomActionsCell } from './styles'

type PropTypes = TableRowPropTypes & {
  id?: string,
  local: LocalsListItem,
  permission: boolean
}

const Locallisttablerow = (props: PropTypes) => {

  const dispatch = useDispatch()
  const {
    permission,
    local: {
      name,
      city,
      timeBefore,
      isActive,
      id
    }
  } = props

  return (
    <CustomCTableRow id={props.id}>
      <NameCell id={`${props.id}-name`} onClick={() => props.onClick()} >{name}</NameCell>
      <CTableDataCell>{city}</CTableDataCell>
      <CTableDataCell>{`${timeBefore} min`}</CTableDataCell>
      <CTableDataCell>
        <AvailabilityTableDataCell isactive={isActive}>{isActive ? i18n.t('common.available') : i18n.t('common.notAvailable')}</AvailabilityTableDataCell>
      </CTableDataCell>
      <CustomActionsCell nopointer>
        {props.actions}
        <CustomChevronCell id={`${props.id}-chevron`} >
        {
            permission &&
            <a href={`/viagens/configuracoes/editar-local/${id}`} onClick={(e) => {
              e.preventDefault()
              dispatch(actions.App.navigateTo(`/viagens/configuracoes/editar-local/${id}`))
              }}>
              <ChevronRight></ChevronRight>
            </a>
        }
        </CustomChevronCell>
      </CustomActionsCell>
    </CustomCTableRow>
  )
}

export default Locallisttablerow