/**
 * Accessdatamodal component
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */
import React from 'react'
import CustomModal from '../CustomModal'
import i18n from "i18next";
import theme from 'ui/theme';
import CustomTextInput from '../CustomTextInput';
import CIcon from '@coreui/icons-react';
import * as icon from '@coreui/icons'
import { FormField } from 'types/misc';
import * as Styles from './styles'
interface PropTypes {
  id?: string,
  newPassword: FormField<string>
  confirmPassword: FormField<string>,
  userAuthExists?: boolean,
  handleReSendEmail: Function,
  onChangeNewPassword: Function,
  onChangeConfirmPassword: Function,
  handleSubmit: Function,
  showChangePasswordModal: Function,
  visible: boolean,
  width?: number
}


const Accessdatamodal = (props: PropTypes) => {
  return (
    <div>
      <CustomModal
        visible={props.visible}
        warningType='message'
        warningName={props.userAuthExists ?  i18n.t('userForm.changeAccessDataModalTitle') : i18n.t('userForm.defineAccessDataModalTitle')}
        buttonIcon={false}
        footerPosition='flex-end'
        textAlign='start'
        noButtons={true}
        children={
          <Styles.Wrapper>
            {
              props.userAuthExists &&
              <div>
                <Styles.Text style={{ fontWeight: '700' }}>{i18n.t('login.recoverPassword')}</Styles.Text>
                <Styles.Text style={{ color: theme().gray400Color }}>{i18n.t('login.recoverPasswordInstructions')}</Styles.Text>
                <Styles.FormFooter onMouseUp={(e) => { }} footerposition='flex-start'>
                  <Styles.ConfirmButtonPrimary id={`${props.id}-recoverPassword`} color='primary' onClick={(e) => props.handleReSendEmail(e)}>
                    {i18n.t('login.sendEmail')}
                  </Styles.ConfirmButtonPrimary>
                </Styles.FormFooter>
                <hr style={{ marginLeft: '-20px', marginRight: '-20px', marginTop: 0 }} />
              </div>
            }
            {
              props.userAuthExists ?
                <div>
                  <Styles.Text style={{ fontWeight: '700' }}>{i18n.t('login.changePassword')}</Styles.Text>
                  <Styles.Text style={{ color: theme().gray400Color }}>{i18n.t('login.changePasswordInstructions')}</Styles.Text>
                </div>
                :
                <div>
                  <Styles.Text style={{ fontWeight: '700' }}>{i18n.t('login.createCredentialsText')}</Styles.Text>
                  <Styles.Text style={{ color: theme().gray400Color }}>{i18n.t('login.createCredentialsInstructionText')}</Styles.Text>
                </div>
            }
            <Styles.Row className="g-3">
              <CustomTextInput
                id={`${props.id}-newPassword`}
                type="password"
                valid={props.newPassword.valid}
                initialValue={props.newPassword.value}
                helpText={props.newPassword.message}
                placeholder={i18n.t('placeholders.newPassword')}
                label={i18n.t('login.newPassword')}
                onChange={(e) => { props.onChangeNewPassword(e) }} />
            </Styles.Row>
            <Styles.Row className="g-3">
              <CustomTextInput
                id={`${props.id}-confirmPassword`}
                type="password"
                valid={props.confirmPassword.valid}
                initialValue={props.confirmPassword.value}
                helpText={props.confirmPassword.message}
                placeholder={i18n.t('placeholders.confirmPassword')}
                label={i18n.t('login.confirmPassword')}
                onChange={(e) => { props.onChangeConfirmPassword(e) }} />
            </Styles.Row>
            <Styles.FormFooter style={{ marginTop: '20px' }} onMouseUp={(e) => { }} footerposition='flex-end'>
              <Styles.CancelButton id={`${props.id}-cancel`} warningtype="message" variant="ghost" onClick={(e) => { props.showChangePasswordModal(false) }}>
                Cancelar
              </Styles.CancelButton>
              <Styles.ConfirmButton id={`${props.id}-confirm`} warningtype="message" variant="ghost" onClick={(e) => {props.handleSubmit(e)}}>
                Guardar <CIcon height={14} icon={icon.cilCheckAlt} size={"sm"} />
              </Styles.ConfirmButton>
            </Styles.FormFooter>
          </Styles.Wrapper>
        }
        onclose={e => { props.showChangePasswordModal(false) }}
        onsubmit={e => {/* handleDeleteCollaboratorConfirm(e) */ }} /></div>
  )
}

export default Accessdatamodal