import React from 'react'
import i18n from 'i18next';
import { useDispatch, useSelector } from 'react-redux'
// Components
import SearchBox from 'shared/components/SearchBox'
// Icons
import StartTrip from 'assets/icons/start_trip'
import EndTrip from 'assets/icons/end_trip'
// Store
import { actions, RootState } from 'store/rootSlices';
// Styles
import { CustomTableHeaderCell} from '../../styles'
import  { SearchBoxWrapper, TabsWrapper, Tab, TabsCustomSpan, GreenFlag } from './styles'
import { CustomCTableHead, PAXDiv, Text} from '../styles'

type PropTypes = React.PropsWithChildren<{
  tripBookings?: any,
  totalBookings?: any
}>

const StopsHeaderRow = ({ tripBookings, totalBookings}: PropTypes) => {
  const { allPassengerTypes } = useSelector((state: RootState) => state.TripsConfiguration)
  const { 
    checkinSearch, 
    checkinOutboundSelected, 
    showCheckinModal,
  } = useSelector((state: RootState) => state.TripPlannings);
  const { 
    setCheckinSearch, 
    setCheckinOutboundSelected,
    setShowCheckInModal,
  } = actions.TripPlannings
  const dispatch = useDispatch()

  const _renderPaxDiv = () => {
    return tripBookings?.length > 0 && (
      <CustomTableHeaderCell colSpan={1}>
        <PAXDiv>
          <Text weight='400'>PAX</Text>
          <Text weight='700'>
          {totalBookings?.total}
          </Text>
        </PAXDiv>
      </CustomTableHeaderCell>
    )
  }

  const _renderPassengerTypes = () => {
    return tripBookings?.length > 0 && allPassengerTypes?.map((pt, idx) => {
      const type = Object.keys(totalBookings)?.find(p => p.toUpperCase() === pt.shortName)

      return (
        <CustomTableHeaderCell colSpan={1} key={`table-head-passengerType-cel-idx-${idx}`}>
          <PAXDiv>
            <Text weight='400'>{pt.shortName}</Text>
            <Text weight='700'>
              {
                type ? totalBookings[type] : 0
              }
            </Text>
          </PAXDiv>
        </CustomTableHeaderCell>
      )
    })
  }

  const _renderOutboundTab = () => {
    return (
      <Tab
        checked={checkinOutboundSelected}
        type="radio"
        onClick={e => {
          dispatch(setCheckinOutboundSelected(true))
          dispatch(setShowCheckInModal({ ...showCheckinModal }))
        }}
        button={{ color: 'secondary', variant: 'outline' }}
        name="btnradio"
        id="tripPlannigns-checkinToogle-going"
        label={
          <TabsCustomSpan>
            <StartTrip /> {i18n.t('tripPlannings.going')} {checkinOutboundSelected && <GreenFlag />}
          </TabsCustomSpan>
        }
      />
    )
  }

  const _renderInboundTab = () => {
    return (
      <Tab
        checked={!checkinOutboundSelected}
        type="radio"
        onClick={e => {
          dispatch(setCheckinOutboundSelected(false))
          dispatch(setShowCheckInModal({ ...showCheckinModal }))
        }}
        name="btnradio"
        button={{ color: "secondary", variant: "outline" }}
        id="tripPlannigns-checkinToogle-return"
        label={
          <TabsCustomSpan>
            <EndTrip /> {i18n.t('tripPlannings.return')} {!checkinOutboundSelected && <GreenFlag />}
          </TabsCustomSpan>
        }
      />
    )
  }

  return (
    <CustomCTableHead>
      <CustomTableHeaderCell colSpan={4}>
        <SearchBoxWrapper>
            <SearchBox
            id="tripsConfig-searchBox"
            defaultValue={checkinSearch}
            onSubmit={(term) => {
              dispatch(setCheckinSearch(term)) 
            }}
          />
        </SearchBoxWrapper>
      </CustomTableHeaderCell>
      { _renderPaxDiv() } 
      { _renderPassengerTypes() }
      <CustomTableHeaderCell colSpan={6}>
        <TabsWrapper id="tripPlannigns-checkinToogle" size="sm" role="group" aria-label="Basic checkbox toggle button group" >
          {
            // If Modal was opened by clicking on a stop, only show the outbound or inbound tab;
            // Otherwise show both tabs;
            showCheckinModal.openBy === 'stop' ? checkinOutboundSelected ? (
              _renderOutboundTab()
            ) : (
              _renderInboundTab()
            ) : (
              <>
                { _renderOutboundTab() }
                { _renderInboundTab() }
              </>
            )
          }
        </TabsWrapper>
      </CustomTableHeaderCell>

    </CustomCTableHead>
  )
}

export default StopsHeaderRow