/**
 * Entitymemberform scene
 *
 * @author João Dias <jdias@ubiwhere.com>
 *
 *
 */
import React, { useEffect } from 'react'

import { useDispatch, useSelector, /*useSelector*/ } from 'react-redux'
import { useParams } from 'react-router-dom'
import { actions, RootState } from 'store/rootSlices'
import Loadingcontainer from 'shared/components/Loadingcontainer'
import SectionContainer from 'shared/components/Sectioncontainer'
import { CCol, CForm, CRow } from '@coreui/react-pro'
import CustomTextInput from 'shared/components/CustomTextInput'
import CustomButton from 'shared/components/CustomButton'
import theme from 'ui/theme'
import CustomCheckbox from 'shared/components/CustomCheckbox'
import _ from "lodash";
import Accessdatamodal from 'shared/components/Accessdatamodal'
import CustomModal from 'shared/components/CustomModal'
import ContainerDelete from 'shared/components/FormComponents/containerdelete';
import config from 'config'
import { hasPermission } from 'permissions'
import { Wrapper, CheckboxRow, ButtonContainer, ButtonWrapper} from './styles'


const Entitymemberform = ({ t, i18n }) => {

  //const { } = useSelector(state => state.Entitymemberform)

  const { navigateBack } = actions.App
  const { permissions } = useSelector((state: RootState) => state.App)
  const { PERMISSIONS } = config
  const {
    onMount,
    onUnmount,
    onFormChange,
    onChangePasswordFormChange,
    onSubmit,
    onChangePasswordSave,
    onReSendEmail,
    showChangePasswordModal,
    setShowDeleteModal
  } = actions.Entitymemberform
  const {
    loading,
    entityMemberForm,
    edit,
    changePasswordForm,
    changePasswordModal,
    showDeleteModal,
    userAuthExists,
    hasEditPermission,
    submitButtonState
  } = useSelector((state: RootState) => state.Entitymemberform);

  const dispatch = useDispatch()
  const dbDispatch = _.debounce(dispatch, 300);
  const { id, memid } = useParams()

  useEffect(() => {
    dispatch(onMount({ id, memid }))
    return () => {
      dispatch(onUnmount())
    }
  }, [dispatch, onMount, onUnmount, id, memid]);

  const handleSubmitNewPassword = (e) => {
    dispatch(onChangePasswordSave({ changePasswordForm }))
  }

  const handleReSendEmail = (e) => {
    dispatch(onReSendEmail())
  }

  const handleDeleteEntityMember = (e) => {
    dispatch(setShowDeleteModal(true))
  }
  const handleDeleteEntityMemberConfirm = (e) => {
    //dispatch(onDeleteConfirm(uuid))
  }

  if (loading) return <Wrapper><Loadingcontainer /></Wrapper>

  return (
    <Wrapper>
      <CForm>
        <CustomCheckbox
          id='entityMemberForm-isActive'
          disabled={!hasEditPermission}
          wrapperStyle={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '-25px', fontSize: '20px' }}
          checked={entityMemberForm.isActive.value ? true : false}
          onClick={e => dispatch(onFormChange({ ...entityMemberForm, isActive: { value: !entityMemberForm.isActive.value, valid: true, message: "" } }))}
          text={i18n.t('tripServiceForm.isActive')}
          switch
        />
        <SectionContainer title={i18n.t('entityMemberForm.titleData')} style={{ width: '100%', paddingBottom: '24px' }}>
          <CRow>
            <CCol sm={5}>
              <CustomTextInput
                id='entityMemberForm-name'
                disabled={!hasEditPermission}
                value={entityMemberForm.name.value || ''}
                required
                label={i18n.t('common.name')}
                placeholder={i18n.t('placeholders.name')}
                onChange={(e) => dispatch(onFormChange({ ...entityMemberForm, name: { value: e.target.value, valid: true, message: "" } }))}
              />
            </CCol>
            <CCol sm={4}>
              <CustomTextInput
                id='entityMemberForm-email'
                disabled={!hasEditPermission}
                value={entityMemberForm.email.value || ''}
                required
                label={i18n.t('common.email')}
                placeholder={i18n.t('placeholders.email')}
                onChange={(e) => dispatch(onFormChange({ ...entityMemberForm, email: { value: e.target.value, valid: true, message: "" } }))}
              />
            </CCol>
            <CCol sm={3}>
              <ButtonContainer>
                <CustomButton
                  id='entityMemberForm-accessDataButton'
                  disabled={!edit || !hasEditPermission}
                  iconFirst
                  icon='lock'
                  buttonStyle={{ borderColor: '#321fdb' }}
                  text={userAuthExists ? i18n.t('userForm.changeAccessDataModalTitle') : i18n.t('userForm.defineAccessDataModalTitle')}
                  color="primary"
                  variant="outline"
                  onClick={(e) => { dispatch(showChangePasswordModal(true)) }} />
              </ButtonContainer>
            </CCol>
          </CRow>
        </SectionContainer>
        <SectionContainer title={i18n.t('entityMemberForm.titleRules')} style={{ width: '100%', paddingBottom: '24px' }}>
          <CheckboxRow>
            <CustomCheckbox
              id='entityMemberForm-isAdmin'
              disabled={!hasEditPermission}
              checked={entityMemberForm.isAdmin.value ? true : false}
              onClick={e => dispatch(onFormChange({ ...entityMemberForm, isAdmin: { value: !entityMemberForm.isAdmin.value, valid: true, message: "" } }))}
              text={i18n.t('entityMemberForm.isAdmin')}
            />
          </CheckboxRow>
        </SectionContainer>
        {
          edit === true && hasPermission(permissions, PERMISSIONS.USERS.DELETE_PARTNER_USER) && <ContainerDelete
            id='entityMemberForm-delete'
            text={<p>{i18n.t('userForm.deleteUserText')}</p>}
            onClick={e => handleDeleteEntityMember(e)} title={i18n.t('userForm.deleteUser')} />
        }
        <ButtonWrapper>
          <CustomButton id='entityMemberForm-cancel' onClick={() => dispatch(navigateBack())} text={i18n.t('common.cancelBtn')} color="link" buttonStyle={{ color: theme().danger, textDecoration: 'unset' }} />
          {
            edit ?
              hasPermission(permissions, PERMISSIONS.USERS.EDIT_PARTNER_USER) &&
              <CustomButton
                id='entityMemberForm-save'
                disabled={submitButtonState}
                onClick={() => dispatch(onSubmit())}
                text={i18n.t('common.saveBtn')}
                icon="confirm"
                color="success"
                buttonStyle={{ color: "#FFFFFF" }} />
              :
              hasPermission(permissions, PERMISSIONS.USERS.CREATE_PARTNER_USER) &&
              <CustomButton
                id='entityMemberForm-save'
                disabled={submitButtonState}
                onClick={() => dispatch(onSubmit())}
                text={i18n.t('common.saveBtn')}
                icon="confirm"
                color="success"
                buttonStyle={{ color: "#FFFFFF" }} />
          }
        </ButtonWrapper>
      </CForm>
      {
        hasPermission(permissions, PERMISSIONS.USERS.DELETE_PARTNER_USER) && <CustomModal
          id='entityMemberForm-deleteUserModal'
          visible={showDeleteModal}
          warningType='warning'
          warningName='Aviso'
          buttonIcon={false}
          footerPosition='center'
          textAlign='center'
          children={<p>Tem a certeza que pretende remover {entityMemberForm.name.value}?</p>}
          onclose={e => dispatch(setShowDeleteModal(false))}
          onsubmit={e => handleDeleteEntityMemberConfirm(e)} />
      }
      {
        <Accessdatamodal
          id='entityMemberForm-accessDataModal'
          userAuthExists={userAuthExists}
          newPassword={changePasswordForm.newPassword}
          confirmPassword={changePasswordForm.confirmPassword}
          visible={changePasswordModal}
          showChangePasswordModal={e => dispatch(showChangePasswordModal(e))}

          handleReSendEmail={e => handleReSendEmail(e)}
          handleSubmit={e => handleSubmitNewPassword(e)}

          onChangeNewPassword={e => dbDispatch(onChangePasswordFormChange({ ...changePasswordForm, newPassword: { value: e.target.value, message: "", valid: true } }))}
          onChangeConfirmPassword={e => dbDispatch(onChangePasswordFormChange({ ...changePasswordForm, confirmPassword: { value: e.target.value, message: "", valid: true } }))}
        />
      }
    </Wrapper>
  )
}

export default Entitymemberform