/**
 * Usercashmanagementforms scene utils
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */

import * as Check from 'validations'

export const StatementFormValidations = {
    cashFund: [Check.isRequired, Check.isNumber],
    income: [],
    expenses: [],
    bankDeposit: [Check.isRequired, Check.isNumber],
    multibancoSales: [Check.isRequired, Check.isNumber],
    notDeposited: [Check.isRequired, Check.isNumber],
    estimated: [],
}