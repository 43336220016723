/**
 * Documentconfigform scene sagas
 *
 * @author João Dias <jdias@ubiwhere.com>
 *
 *
 */
import api from 'api'
import { call, putResolve, select, takeLatest } from 'redux-saga/effects'
import { actions, RootState } from 'store/rootSlices'
import { defaultDocumentConfigForm, DocumentConfigForm, documentConfigFormValidations, SetDocumentSagaPayload } from '../utils'
import * as Check from "validations"
import i18n from 'i18next'
import { toast } from 'react-toastify'
import config from 'config';
import { hasPermission } from 'permissions'
import { PayloadAction } from '@reduxjs/toolkit'
const { PERMISSIONS } = config

//import { actions } from 'store/rootSlices'

function* onMountSaga({ payload }: PayloadAction<number>) {
  const { permissions } = yield select((state: RootState) => state.App);

  try {
    if (payload) {
      yield putResolve(actions.Documentconfigform.setEditMode(true));
      yield putResolve(actions.Documentconfigform.setHasEditPermission(hasPermission(permissions, PERMISSIONS.FLEET.EDIT_FLEET_SETTINGS)))
      yield putResolve(actions.Documentconfigform.getDocumentConfig(payload));
    } else {
      yield putResolve(actions.Documentconfigform.setEditMode(false));
      yield putResolve(actions.Documentconfigform.setForm(defaultDocumentConfigForm));
    }
  } finally {
    yield putResolve(actions.Documentconfigform.setLoading(false));
  }
}

function* getDocumentConfigSaga({ payload }: PayloadAction<number>) {
  try {
    const res = yield call(api.Fleet.GetSingleDocumentType, payload);
    yield putResolve(actions.Documentconfigform.setDocument(res.data));
  } catch (error) {
    toast.error(i18n.t('toasts.getDocumentError'));
  } finally {
    yield putResolve(actions.Documentconfigform.setLoading(false));
  }
}

function* setDocumentSaga({ payload }: PayloadAction<SetDocumentSagaPayload>) {
  const form: DocumentConfigForm = {
    isActive: {
      value: payload.isActive,
      message: "",
      valid: true,
    },
    name: {
      value: payload.name,
      message: "",
      valid: true,
    }
  }

  yield putResolve(actions.Documentconfigform.setForm(form));
}

function* onSubmitSaga() {
  const { documentConfigForm, edit, document } = yield select((state: RootState) => state.Documentconfigform);
  yield putResolve(actions.Documentconfigform.setSubmitButtonDisabled(true))

  const validation = Check.checkValidation(documentConfigForm, documentConfigFormValidations);
  if (validation.invalid) {
    yield putResolve(actions.Documentconfigform.setForm(validation.form));
    toast.error(i18n.t('toasts.invalidFields'));
    yield putResolve(actions.Documentconfigform.setSubmitButtonDisabled(false))
    return false;
  }
  const body = {
    is_active: documentConfigForm.isActive.value,
    name: documentConfigForm.name.value
  }

  try {
    yield putResolve(actions.Documentconfigform.setLoading(true));
    if (edit) {
      const res = yield call(api.Fleet.EditDocumentType, body, document.id);
      if (res) yield putResolve(actions.App.navigateTo("/frota/configuracoes"));
    } else {
      const res = yield call(api.Fleet.CreateDocumentType, body);
      if (res.status === 201) {
        yield putResolve(actions.App.navigateTo("/frota/configuracoes"));
      }
    }
  } catch (err) {
  } finally {
    yield putResolve(actions.Documentconfigform.setSubmitButtonDisabled(false))
    yield putResolve(actions.Documentconfigform.setLoading(false));
  }
}

function* onUnmountSaga() { }

export default function* watcherSignin() {
  yield takeLatest('Documentconfigform/onMount', onMountSaga)
  yield takeLatest('Documentconfigform/getDocumentConfig', getDocumentConfigSaga)
  yield takeLatest('Documentconfigform/setDocument', setDocumentSaga)
  yield takeLatest('Documentconfigform/onSubmit', onSubmitSaga)
  yield takeLatest('Documentconfigform/onUnmount', onUnmountSaga)
}


