/**
 * circuitstoptablerow component
 *
 * @author Manuel Gil <mgi@ubiwhere.com>
 *
 *
 */

import React from 'react'
import styled from 'styled-components'
import { TableRowPropTypes } from 'types/misc'
import theme from 'ui/theme'
import { CTableDataCell, CTableRow } from '@coreui/react-pro'
import { ExtrasListItem, PackForm } from 'types/trips/extras'
import CustomNumberInput from 'shared/components/CustomNumberInput'
import Info from 'assets/icons/info'
import { Tooltip, CustomSpan, ExtraInfo } from '../styles'
import { useDispatch } from 'react-redux'
import { actions as storeActions } from 'store/rootSlices'

type PropTypes = TableRowPropTypes & {
    id?: string,
    hasEditPermission: boolean
    selectedExtra?: PackForm
    extra: ExtrasListItem,
}

const PackExtrasRow = (props: PropTypes): JSX.Element => {

    const dispatch = useDispatch();

    const {
        id: testId,
        actions,
        extra: {
            id,
            name,
            maxCapacity
        },
        selectedExtra,
    } = props

    const { onChangeExtraQuantity, onToggleExtra } = storeActions.TripExtraForm

    return (
        <CustomCTableRow>
            <CTableDataCell>

                <ExtraInfo>
                    {name}
                    {maxCapacity < Number(selectedExtra?.quantity.value) && (
                        <Tooltip>
                            <Info fill={"#D4A8EA"} />
                            <CustomSpan>A quantidade selecionada é superior à capacidade máxima da definição do extra</CustomSpan>
                        </Tooltip>
                    )}
                </ExtraInfo>
            </CTableDataCell>
            <ClickableDataCell onClick={() => {
                dispatch(onToggleExtra({ extra: props.extra, setMax: true }))
            }
            }>
                {maxCapacity}
            </ClickableDataCell>
            <CTableDataCell style={{ width: "30%" }}>
                {
                    <CustomNumberInput
                        id={`extra-quantity-${testId}`}
                        value={selectedExtra?.quantity.value || ""}
                        valid={selectedExtra ? selectedExtra.quantity.valid : true}
                        onChange={e => {
                            const newValue = e.target.value.trim().length ? parseInt(e.target.value, 10) : 0

                            dispatch(onChangeExtraQuantity({
                                extraId: id,
                                value: newValue > maxCapacity ? maxCapacity : newValue
                            }));
                        }}
                        onClickAdd={(e, numberInput) => {
                            dispatch(onChangeExtraQuantity({
                                extraId: id,
                                value: parseInt(numberInput.value, 10) + 1,
                            }));
                        }}
                        onClickSub={(e, numberInput) => {
                            dispatch(onChangeExtraQuantity({
                                extraId: id,
                                value: parseInt(numberInput.value, 10) - 1,
                            }));
                        }}
                        disabled={selectedExtra === undefined || selectedExtra?.isSelected.value === false}
                        min={0}
                        max={maxCapacity}
                    />
                }
            </CTableDataCell>
            <CTableDataCell>
                {actions}
            </CTableDataCell>
        </CustomCTableRow>
    )
}

export default PackExtrasRow

const CustomCTableRow = styled(CTableRow)`
    max-height: 54px;
    background-color: ${theme().white};
    border-bottom: 1px solid ${theme().gray200Color};
  
    > td {
      text-transform: capitalize;
      vertical-align: middle;
      font-family: Inter;
      font-size: 14px;
      color: ${theme().gray700Color}
    }

    td:first-child {
      padding-left:15px;
      width:65%;
    }

    td:nth-child(2) {
        width:15%;
      }
  `

const ClickableDataCell = styled(CTableDataCell)`
    cursor: pointer;
`
