import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  overflow: auto;
`

export const TitleLabelBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  gap: 10px;
`

export const Tooltip = styled.div`
  position: relative;
  visibility: visible;
  cursor: pointer;

  &:hover {
    > span {
      visibility: visible
    }
  }
`

export const CustomSpan = styled.span`
  visibility: hidden;
  width: fit-content;
  min-width: 270px;
  background-color: #0E364F;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 10px 5px 0px 5px;
  position: absolute;
  z-index: 3;
  top: 20px;
  left: 5%;
`


export const TooltipMainText = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`