import styled from 'styled-components'
import { CFormInput, CDropdownToggle, CDropdownItem } from '@coreui/react';
import theme from 'ui/theme';


export const Wrapper = styled.div`
 > * {
   font-family: 'Inter';
 }

 > label {
   font-size: 14px;
   color: ${theme().darkColor};
   > span {
     color: ${theme().primary};
   }
 }
`

export const DropdownToggle = styled(CDropdownToggle)`
 background-color:${theme().gray600Color};
 color:${theme().white};
 font-size: 14px;
`

export const DropdownItem = styled(CDropdownItem)`
  font-size:14px;
  &:active{
      background-color:${theme().gray600Color};
  }
`
export const FormInput = styled(CFormInput) <{ isvalid: boolean | undefined }>`
  font-size:14px;
  border-color:${(props) => props.isvalid === false ? theme().danger : '#D8DBE0'};
  &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: ${theme().gray500Color};
      opacity: 1; /* Firefox */
  }
`