/**
 * Fleetfuelling form sagas
 *
 * @author João Dias <jdias@ubiwhere.com>
 *
 *
 */
import { takeLatest, call, putResolve, select } from 'redux-saga/effects'
import { actions, RootState } from 'store/rootSlices'
import { toast } from 'react-toastify'
import * as Check from "validations"
import { fuellingFormValidations } from '../utils'
import { DeleteDocumentSagasPayload } from 'types/misc';
import i18n from 'i18next'
import api from 'api'
import config from 'config';
import { hasPermission } from 'permissions'
import { DateTime } from 'luxon'
import { PayloadAction } from '@reduxjs/toolkit'
import { FuellingListItem } from 'types/fleet/fuel'
const { PERMISSIONS } = config

function* onMountSaga({ payload }: PayloadAction<number>) {
  const { permissions } = yield select((state: RootState) => state.App);

  try {
    yield getCollaboratorsSaga();

    if (payload) {
      yield putResolve(actions.FleetFuellingForm.setEditMode(true));
      yield putResolve(actions.FleetFuellingForm.setHasEditPermission(hasPermission(permissions, PERMISSIONS.FLEET.EDIT_FUEL)))
      yield putResolve(actions.FleetFuellingForm.requestFuelling(payload));
    } else {
      yield putResolve(actions.FleetFuellingForm.setIsTank(false))
      yield putResolve(actions.FleetFuellingForm.setEditMode(false));
      yield putResolve(actions.FleetFuellingForm.resetDocumentsArray())
    }
  } catch (err) {
    toast.warning(i18n.t("toasts.errorMountingScreen"))
  } finally {
    yield putResolve(actions.FleetFuellingForm.setLoading(false));
  }
}

function* requestFuellingSaga({ payload }: PayloadAction<number>) {
  try {
    const data: FuellingListItem = yield call(api.Fleet.GetSingleFuelling, payload);
    yield putResolve(actions.FleetFuellingForm.setFuelling(data));
    yield getDocumentsSaga(payload)
  } catch (error) {
  }
}

function* setFuellingSaga({ payload }: PayloadAction<FuellingListItem>) {
  yield putResolve(actions.FleetFuellingForm.setIsTank(!payload.isVehicleFueled))
  yield putResolve(actions.FleetFuellingForm.setForm(payload));
}

function* onSubmitSaga() {
  const {
    FleetFuellingForm: {
      fuellingForm,
      isEdit,
      fuelling,
      isTank,
    }
  } = yield select((state: RootState) => state);
  const { permissions } = yield select((state: RootState) => state.App)

  yield putResolve(actions.FleetFuellingForm.setSubmitButtonDisabled(true))

  const validation = Check.checkValidation(fuellingForm, fuellingFormValidations);
  if (validation.invalid) {
    yield putResolve(actions.FleetFuellingForm.updateFormOnValidation(validation.form));
    toast.error(i18n.t('toasts.invalidFields'));
    yield putResolve(actions.FleetFuellingForm.setSubmitButtonDisabled(false))
    return false;
  }
  try {
    yield putResolve(actions.FleetFuellingForm.setLoading(true));

    let body = {
      fuel_station: Number.parseInt(fuellingForm.fuelStation.value),
      litres: fuellingForm.litres.value,
      fuel_date: fuellingForm.fuelDate.value !== "" ? DateTime.fromISO(fuellingForm.fuelDate.value).toFormat("yyyy-MM-dd'T'HH:mm:ss") : DateTime.now().toFormat("yyyy-MM-dd'T'HH:mm:ss"),
      ...(hasPermission(permissions, PERMISSIONS.FLEET.EDIT_FUEL_FUELLED_BY) && fuellingForm.fuelledBy.value !== '' && fuellingForm.fuelledBy.value !== undefined ? { fuelled_by: fuellingForm.fuelledBy.value } : null),
      ...(isTank ? { tank: Number.parseInt(fuellingForm.vehicle.value) } : { vehicle: Number.parseInt(fuellingForm.vehicle.value) }),
      ...((fuellingForm.pricePerLitre.value !== '' && fuellingForm.pricePerLitre.value !== undefined) ? { price_per_litre: fuellingForm.pricePerLitre.value } : null),
      ...((fuellingForm.paymentType.value !== '' && fuellingForm.paymentType.value !== undefined) ? { payment_type: fuellingForm.paymentType.value } : null),
      ...((fuellingForm.description.value !== '' && fuellingForm.description.value !== undefined) ? { description: fuellingForm.description.value } : null),
    }

    if (isEdit) {
      const res = yield call(api.Fleet.EditFuelling, body, fuelling.id);
      if (res) yield putResolve(actions.App.navigateTo("/frota/combustivel"));
    } else {
      const res = yield call(api.Fleet.CreateFuelling, body);
      if (res.status === 201) {
        toast.success(i18n.t("toasts.fuellingCreateSuccess"));
        yield putResolve(actions.FleetFuellingForm.createEntryDocument(res.data.id))
        yield putResolve(actions.App.navigateTo("/frota/combustivel"));
      }
    }
  } catch (err) {
    toast.error(i18n.t("toasts.fuellingCreateError"));
  } finally {
    yield putResolve(actions.FleetFuellingForm.setSubmitButtonDisabled(false))
    yield putResolve(actions.FleetFuellingForm.setLoading(false));
  }
}

function* getFuellingLocationsSaga() {
  const { isTank } = yield select((state: RootState) => state.FleetFuellingForm);

  try {
    const res = yield call(api.Fleet.GetFuelLocations, isTank);
    if (res) {
      yield putResolve(actions.Fleetfuelling.setFuellingLocationList(res.data ?? []));
    }
  } catch (err) {
    toast.error("Erro a carregar locais de abastecimento.");
  }
}

function* CreateEntryDocumentSaga({ payload }: PayloadAction<number>) {
  const { documents, isEdit, fuellingForm } = yield select((state: RootState) => state.FleetFuellingForm);

  const validation = Check.checkValidation(fuellingForm, fuellingFormValidations);

  if (!validation.invalid) {
    try {

      documents.forEach(async (doc, idx) => {
        if (doc.new === true) {
          const body = {
            fuel: payload,
            sort: idx + 1,
            file: doc.file
          }
          await api.Fleet.AddFuellingDocument(body)
          toast.success(i18n.t("toasts.fuelDocumentCreated"))
        }
        else {
          if (doc.edited === true) {
            const body = {
              fuel: payload,
              sort: idx + 1,
            }
            await api.Fleet.EditFuellingDocument(doc.id, body)
          }
        }
      })

    } catch (error) {
      toast.warning(i18n.t("toasts.createFuelDocumentError"))
    }
    finally {
      if (isEdit) {
        yield onSubmitSaga()
      }
    }
  }
  else {
    yield putResolve(actions.UserCashMovementForm.updateEntryFormOnValidation(validation.form))
    toast.error(i18n.t("toasts.invalidFields"))
  }

}

function* getDocumentsSaga(payload: number) {

  const fuellingID = Number(payload)

  if (fuellingID) {
    try {
      const documents = yield call(api.Fleet.ListFuellingDocuments, fuellingID)

      if (documents) {
        yield putResolve(actions.FleetFuellingForm.populateDocumentsForm({ documents, fuellingID }))
      }
    } catch (error) {
      toast.error(i18n.t("toasts.getUserStatementPhotosError"))
    }
  }
}

function* deleteDocumentFromArraySaga({ payload }: PayloadAction<DeleteDocumentSagasPayload>) {
  const index = payload.index
  const docsArray = payload.documents


  if (index !== null && docsArray[index].new === false) {
    try {
      const del = yield call(api.Fleet.DeleteFuellingDocument, docsArray[index].id)

      if (del.status === 204 || del.status === 200) {
        toast.success(i18n.t("toasts.deletedPhoto"))
      }

    } catch (error) {
      toast.error(i18n.t("toasts.removePhotoError"))
    }
  }
}

function* getFuellingPaymentTypesSaga() {
  try {
    const res = yield call(api.Fleet.GetFuelPaymentTypes);
    if (res) {
      yield putResolve(actions.Fleetfuelling.setFuelPaymentTypes(res.data ?? []));
    }
  } catch (err) {
    toast.error("Erro a carregar tipos de pagamento de abastecimentos.");
  }
}

function* getPricePerLitreSaga({ payload }: PayloadAction<number>) {
  try {
    const data = yield call(api.Fleet.GetFuellingPricePerLitre, payload);
    yield putResolve(actions.FleetFuellingForm.setPricePerLitre(data));
  } catch (error) {
    toast.error(i18n.t("toasts.fuellingPriceLitreError"));
  }
}

function* getCollaboratorsSaga() {
  
  try {
      const teams = yield call(api.Collaborators.GetTeams)
      const groupFilter = teams.filter(team => team.uid === 'gestao' || team.uid === 'mt').map(team => team.id)

      const collaborators = yield call(api.Collaborators.GetAllCollaborators, undefined, groupFilter);
      yield putResolve(actions.FleetFuellingForm.setCollaborators(collaborators))
  }
  catch (err) {
  }
}

export default function* watcherSignin() {
  yield takeLatest('FleetFuellingForm/requestFuelling', requestFuellingSaga);
  yield takeLatest('FleetFuellingForm/setFuelling', setFuellingSaga);
  yield takeLatest('FleetFuellingForm/onMount', onMountSaga);
  yield takeLatest('FleetFuellingForm/onSubmit', onSubmitSaga);
  //yield takeLatest('Fleetfuelling/onUnmount', onUnmountSaga)
  yield takeLatest('FleetFuellingForm/createEntryDocument', CreateEntryDocumentSaga)
  yield takeLatest('FleetFuellingForm/setIsTank', getFuellingLocationsSaga)
  yield takeLatest('FleetFuellingForm/getFuelPaymentTypes', getFuellingPaymentTypesSaga)
  yield takeLatest('FleetFuellingForm/deleteDocumentFromArray', deleteDocumentFromArraySaga)
  yield takeLatest('FleetFuellingForm/getPricePerLitre', getPricePerLitreSaga)
}


