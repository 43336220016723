/**
 * Historylog scene sagas
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */
import { AnyAction } from 'redux'
import { takeLatest, putResolve, select, call } from 'redux-saga/effects'
import { actions, RootState } from 'store/rootSlices'
import API from 'api'
import { toast } from 'react-toastify';
import i18n from 'i18next'

function* onMountSaga({payload}: AnyAction) {
  yield putResolve(actions.HistoryLog.setLoading(true));

  if (payload) {

    try {
      yield putResolve(actions.HistoryLog.setUserID(payload));
      const entries = yield call(API.Collaborators.GetHistoryLog, payload);

      if (entries) {
        yield putResolve(actions.HistoryLog.setTotal(entries.data.length));
        //Reverse result to show most recent edits
        yield putResolve(actions.HistoryLog.setHistoryLog(entries.data.reverse()));
      }
      else {
        toast.error(i18n.t("toasts.getCollaboratorHistoryLogError"))
      }

      yield putResolve(actions.HistoryLog.setLoading(false));
    } catch (error) {
      toast.error(i18n.t("toasts.getCollaboratorHistoryLogError"))
      yield putResolve(actions.HistoryLog.setLoading(false)); 
    }
  }
}

function* onUnmountSaga({payload}: AnyAction) {
  yield putResolve(actions.HistoryLog.setLoading(true));
  yield putResolve(actions.HistoryLog.setTotal(0));
  yield putResolve(actions.HistoryLog.setHistoryLog([]));
}

function* getHistoryLogSaga() {
  try {
    yield putResolve(actions.HistoryLog.setLoading(true));
    const { userID, searchTerm } = yield select((state: RootState) => state.HistoryLog);

    const entries = yield call(API.Collaborators.GetHistoryLog, userID, searchTerm );
    if (entries) {
      
      yield putResolve(actions.HistoryLog.setTotal(entries.data.length));
      yield putResolve(actions.HistoryLog.setHistoryLog(entries.data));
    }
  } catch (err) {
    toast.error(i18n.t("toast.getHistoryLogError"))
  } finally {
    yield putResolve(actions.HistoryLog.setLoading(false));
  }
}

export default function* watcherSignin() {
  yield takeLatest('Historylog/onMount', onMountSaga)
  yield takeLatest('Historylog/onUnmount', onUnmountSaga)
  yield takeLatest('Historylog/setSearchTerm', getHistoryLogSaga)
}


