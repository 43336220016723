import React from 'react'
import { ActionsCell, ChevronCell } from 'shared/genericStyledComponents'
import { CustomTableRow } from 'shared/genericStyledComponents'
import { CTableDataCell } from '@coreui/react-pro';
import ChevronRight from 'assets/icons/chevron_right';
import { actions } from 'store/rootSlices'
import { useDispatch } from 'react-redux';

type PropTypes = {
  id?: string,
  name: string
  rowActions?: JSX.Element,
  permission: boolean,
  onClick?: Function,
}

const BusinessInteligenceRow = ({ name, id, rowActions, permission }: PropTypes) => {
  const dispatch = useDispatch()

  return (
    <CustomTableRow id={id}>
      <CTableDataCell>{name}</CTableDataCell>
      <ActionsCell nopointer>
        {rowActions}
        <ChevronCell id={`${id}-chevron`}>
        {
            permission &&
            <a href={`/inteligencia-negocio`} onClick={(e) => {
              e.preventDefault()
              dispatch(actions.App.navigateTo(`/inteligencia-negocio/painel/${id}`))
              }}>
              <ChevronRight></ChevronRight>
            </a>
        }
        </ChevronCell>
      </ActionsCell>

    </CustomTableRow>
  )
}

export default BusinessInteligenceRow

