import * as React from "react"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width={10}
            height={5}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M.833.333L5 4.5 9.167.333H.833z" fill={props.fill ? props.fill : "#321FDB"} />
        </svg>
    )
}

export default SvgComponent