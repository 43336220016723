import * as React from "react"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.5 21.5H13.5V19.5H15.5V21.5ZM13.5 14.5H11.5V19.5H13.5V14.5ZM21.5 12.5H19.5V16.5H21.5V12.5ZM19.5 10.5H17.5V12.5H19.5V10.5ZM7.5 12.5H5.5V14.5H7.5V12.5ZM5.5 10.5H3.5V12.5H5.5V10.5ZM12.5 5.5H14.5V3.5H12.5V5.5ZM5 5V8H8V5H5ZM9.5 9.5H3.5V3.5H9.5V9.5ZM5 17V20H8V17H5ZM9.5 21.5H3.5V15.5H9.5V21.5ZM17 5V8H20V5H17ZM21.5 9.5H15.5V3.5H21.5V9.5ZM19.5 19.5V16.5H15.5V18.5H17.5V21.5H21.5V19.5H19.5ZM17.5 12.5H13.5V14.5H17.5V12.5ZM13.5 10.5H7.5V12.5H9.5V14.5H11.5V12.5H13.5V10.5ZM14.5 9.5V7.5H12.5V5.5H10.5V9.5H14.5ZM7.25 5.75H5.75V7.25H7.25V5.75ZM7.25 17.75H5.75V19.25H7.25V17.75ZM19.25 5.75H17.75V7.25H19.25V5.75Z" fill="white" />
        </svg>
    )
}

export default SvgComponent
