import API from 'api'
import { putResolve, call, select } from 'redux-saga/effects'
import { actions, RootState } from 'store/rootSlices'
import { toast } from 'react-toastify'
import i18n from 'i18next';
import { AnyAction } from '@reduxjs/toolkit';

export function* getTripsDetailsSaga({ payload }: AnyAction) {
  const tripId = payload.id

  const {
    tripsDetails
  } = yield select((state: RootState) => state.TripPlannings);

  try {
    const planningInfo = yield call(API.Plannings.GetTripDetails, tripId)

    if(planningInfo) {
      yield putResolve(actions.TripPlannings.setTripsDetails({planningInfo, oldDetails: tripsDetails, id: tripId}))
    }

  } catch (error) {
    toast.error(i18n.t("toasts.planningGetDetailsError"))
    
  }
}


export function* getTripStopDetailsSaga() {

  const {
    openPlanning,
    selectedVehicle,
    openPlanningOutboundStops,
    openPlanningInboundStops
  } = yield select((state: RootState) => state.TripPlannings)
  const {
    allLocals
  } = yield select((state: RootState) => state.TripsConfiguration)

  try {
    //get planning from api
    const planningInfo = yield call(API.Plannings.GetPlanning, openPlanning.id)

    if (planningInfo) {
      yield putResolve(actions.TripPlannings.setOpenPlanning({ planning: planningInfo, locals: allLocals, selectedVehicle: selectedVehicle.value }))
      yield putResolve(actions.TripPlannings.setStopStatus({
        selectedVehicle: selectedVehicle.value,
        planning: planningInfo,
        openPlanningOutboundStops: openPlanningOutboundStops,
        openPlanningInboundStops: openPlanningInboundStops
      }))

      yield putResolve(actions.TripPlannings.setModalLoading(false))
    }
  } catch (error) {
      toast.error(i18n.t("toasts.planningGetInfoError"))
      yield putResolve(actions.TripPlannings.setModalLoading(false))
  }
}