import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions, RootState } from 'store/rootSlices';
import { DateTime } from 'luxon';
//configs
import { convertTimeStringToSeconds } from '../../../../utils';
//styles
import { ControlsContainer, TabsWrapper, Tab, FiltersWrapper, TabsMainWrapper } from './styles';
//icons
import Boarding from 'assets/icons/login';
import Landing from 'assets/icons/logout';
import Map from 'assets/icons/map';
//Components
import FilterComponent from 'shared/components/FilterComponent';
import FilterTime from 'shared/components/FilterTime';
import FilterDatePicker from 'shared/components/FilterDatePicker';

const TableControls = ({ t }) => {
  const {
    unlockInbounds,
    dateFilter,
    activityFilter,
    arrivalFilter,
    departureFilter,
    startTimeFilter,
    endTimeFilter,
    hasTimeFilterChanged,
    outbound,
    inbound,
    selectedBeachTrip,
    disableInboundView,
    directionFilter,
  } = useSelector((state: RootState) => state.BookingForm);

  const {
    setLegInboundView,
    setBeachView,
    setActivityFilter,
    setDepartureFilter,
    setArrivalFilter,
    setDateFilter,
    setBeachDateFilter,
    setTimeFilter,
  } = actions.BookingForm;

  const { allActivities, allLocals } = useSelector((state: RootState) => state.TripsConfiguration);

  const dispatch = useDispatch();

  return (
    <ControlsContainer>
      <TabsMainWrapper>
        <TabsWrapper
          id="bookingFormLegs-tabButton"
          size="sm"
          role="group"
          aria-label="Basic checkbox toggle button group"
        >
          <Tab
            checked={directionFilter === 'outbound'}
            type="radio"
            onClick={() => {
              dispatch(setLegInboundView(false));
            }}
            name="btnradio"
            button={{ color: 'secondary', variant: 'outline' }}
            id="bookings-tab-outbound-legs"
            label={t('tripBookings.outbound')}
            disabled={outbound?.checkedIn || (selectedBeachTrip.beachTripActivity?.id && selectedBeachTrip.beachTripActivity?.id > -1) || disableInboundView}
          />
          <Tab
            checked={directionFilter === 'inbound'}
            type="radio"
            onClick={() => {
              dispatch(setLegInboundView(true));
            }}
            button={{ color: 'secondary', variant: 'outline' }}
            name="btnradio"
            id="bookings-tab-inbound-legs"
            label={t('tripBookings.inbound')}
            disabled={!unlockInbounds || inbound?.checkedIn || directionFilter === 'beach'}
          />
        </TabsWrapper>
        <TabsWrapper
          id="bookingFormBeach-tabButton"
          size="sm"
          role="group"
          aria-label="Basic checkbox toggle button group"
        >
          <Tab
            checked={directionFilter === 'beach'}
            type="radio"
            onClick={() => {
              return dispatch(setBeachView(false));
            }}
            name="btnradio"
            button={{ color: 'secondary', variant: 'outline' }}
            id="bookings-tab-beach-legs"
            label={t('tripBookings.beach')}
            disabled={outbound.tripActivityLeg.id > -1 || inbound.tripActivityLeg.id > -1}
          />
        </TabsWrapper>
      </TabsMainWrapper>
      <FiltersWrapper>
        <FilterDatePicker
          filterName={t('common.today')}
          defaultValue={{ startDay: dateFilter ? dateFilter : new Date() }}
          minDate={DateTime.now().toJSDate()}
          onChange={(e) => {
            directionFilter !== 'beach' ?
              dispatch(setDateFilter({ e: e.startDate, hasTimeFilterChanged }))
              :
              dispatch(setBeachDateFilter({ e: e.startDate, hasTimeFilterChanged }))
          }}
        />
        {directionFilter !== 'beach' && <FilterTime
          id="bookingForm-timeFilter"
          label={t('common.time')}
          onChange={(e) => {
            dispatch(setTimeFilter(e));
          }}
          defaultValue={{
            min: convertTimeStringToSeconds(startTimeFilter),
            max: convertTimeStringToSeconds(endTimeFilter),
          }}
        />}
        {directionFilter !== 'beach' && <FilterComponent
          id="tripBookings-activityFilter"
          filterName={t('tripPlannings.activities')}
          options={allActivities.map((a) => {
            return {
              label: a.shortName,
              value: a.id.toString(),
            };
          })}
          icon={<Map />}
          defaultOption={(() => {
            let activity = allActivities.find((a) => a.id.toString() === activityFilter);
            if (activity) {
              return { label: activity.shortName, value: activity.id.toString() };
            } else {
              return { label: '', value: '' };
            }
          })()}
          onChange={(e) => {
            dispatch(setActivityFilter(e.value));
          }}
        />}
        {directionFilter !== 'beach' &&
          <>
            <FilterComponent
              id="tripBookings-departureFilter"
              filterName={t('tripPlannings.boardings')}
              options={allLocals.map((l) => {
                return {
                  label: l.name,
                  value: l.id.toString(),
                };
              })}
              defaultOption={(() => {
                let local = allLocals.find((a) => a.id.toString() === departureFilter);
                if (local) {
                  return { label: local.name, value: local.id.toString() };
                } else {
                  return { label: '', value: '' };
                }
              })()}
              icon={<Boarding />}
              onChange={(e) => {
                dispatch(setDepartureFilter(e.value));
              }}
            />
            <FilterComponent
              id="tripBookings-arrivalFilter"
              filterName={t('tripPlannings.landings')}
              options={allLocals.map((l) => {
                return {
                  label: l.name,
                  value: l.id.toString(),
                };
              })}
              defaultOption={(() => {
                let local = allLocals.find((a) => a.id.toString() === arrivalFilter);
                if (local) {
                  return { label: local.name, value: local.id.toString() };
                } else {
                  return { label: '', value: '' };
                }
              })()}
              icon={<Landing />}
              onChange={(e) => {
                dispatch(setArrivalFilter(e.value));
              }}
            />
          </>
        }
      </FiltersWrapper>
    </ControlsContainer>
  );
};

export default TableControls;
