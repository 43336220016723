import { createSlice } from '@reduxjs/toolkit'
import type { BiDashboard } from 'types/trips/bi'

interface initialState {
  page: number,
  total: number,
  searchTerm:string,
  loading: boolean,
  biList: BiDashboard[],
}

const initialState : initialState = {
  searchTerm:"",
  total: 0,
  page: 1,
  loading: true,
  biList: []
}

export default createSlice({
  name: 'BusinessInteligence',
  initialState,
  reducers: {
    onMount: () => {},
    onUnmount: () => {
      return initialState
    },
    setSearchTerm: (state, { payload} ) => {
      state.searchTerm = payload
    },
    setTotal: (state, { payload} ) => {
      state.total = payload
    },
    setPage: (state, { payload} ) => {
      state.page = payload
    },
    setLoading:(state, { payload }) => {
      state.loading = payload
    },
    getBiList: () => {},
    setBiList: (state, { payload }) => {
      state.biList = payload
    },
  }
})