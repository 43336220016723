import styled from 'styled-components'
import theme from 'ui/theme'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 114px;
`

export const TextContainer = styled.div`
  font-family: 'Inter';
  font-size: 11px;
  color:${theme().darkColor};
  letter-spacing: 0.1px;
  
  b {
    font-size: 14px;
  }
`

export const GaugeContainer = styled.div<{ percent: number }>`
  display: grid;
  grid-template-columns: ${props => props.percent}% ${props => 100 - props.percent}%;
  height: 4px;
  width: 100%;
  border-radius: 5px;
  overflow: hidden;

  .gauge-value {
    background-color: ${props => {
    if (props.percent < 33) {
      return theme().danger;
    }
    if (props.percent < 66) {
      return theme().warning;
    }
    return theme().success;
  }};
  }

  .gauge-empty {
    background-color: ${theme().gray200Color};
  }
`
