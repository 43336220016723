/**
 * Fleetfuelling scene sagas
 *
 * @author João Dias <jdias@ubiwhere.com>
 *
 *
 */
import { takeLatest, call, putResolve, select } from 'redux-saga/effects'
import { AnyAction } from 'redux'
import API from 'api';
import { actions, RootState } from 'store/rootSlices';
import { FuellingsList } from 'types/fleet/fuel';
import { TanksList } from 'types/fleet/vehicle';
import { toast } from 'react-toastify';
import { Interval } from 'luxon'
// import i18n from 'i18next'
//import { actions } from 'store/rootSlices'

function* onMountSaga({ payload }: AnyAction) {
  try {
    yield getURLParamsSaga(payload);
    yield putResolve(actions.Fleetfuelling.getFuelAndConsumpList());
    yield putResolve(actions.Fleetfuelling.getTanksList());
  } catch (err) {
  }
}

function* onUnmountSaga() {
  yield putResolve(actions.Fleetfuelling.setFuellingsList([]));
  yield putResolve(actions.Fleetfuelling.setFuellingLocationList([]));
  yield putResolve(actions.Fleetfuelling.setLoading(true));
}

function* getFuellingsListSaga() {
  const { fuellingsPage, searchTerm, sort, filter, vehicle, startDate, endDate, fuelStation } = yield select((state: RootState) => state.Fleetfuelling);
  try {
    const res: FuellingsList = yield call(API.Fleet.GetFuellings, fuellingsPage, searchTerm, sort, filter, vehicle ? vehicle : undefined, startDate, endDate, fuelStation);
    if (res) {
      yield putResolve(actions.Fleetfuelling.setFuellingsList(res.results ?? []));
      yield putResolve(actions.Fleetfuelling.setFuellingsTotal(res.count));
    }
  } catch (err) {
  } finally {
    yield putResolve(actions.Fleetfuelling.setLoading(false));
  }
}

function* getConsumptionsListSaga() {
  const { fuellingsPage, searchTerm, sort, filter, vehicle, startDate, endDate } = yield select((state: RootState) => state.Fleetfuelling);
  try {
    const res: any = yield call(API.Fleet.GetConsumptions, fuellingsPage, searchTerm, sort, filter, vehicle ? vehicle : undefined, startDate, endDate);
    if (res) {
      yield putResolve(actions.Fleetfuelling.setConsumptionsList(res.results ?? []));
      yield putResolve(actions.Fleetfuelling.setConsumptionsTotal(res.count));
    }
  } catch (err) {
  } finally {
    yield putResolve(actions.Fleetfuelling.setLoading(false));
  }
}

function* getFuelAndConsumpListSaga() {
  const { fuellingsPage, searchTerm, sort, filter, vehicle, startDate, endDate, fuelStation } = yield select((state: RootState) => state.Fleetfuelling);
  try {
    const fuelRes: FuellingsList = yield call(API.Fleet.GetFuellings, fuellingsPage, searchTerm, sort, filter, vehicle ? vehicle : undefined, startDate, endDate, fuelStation);
    if (fuelRes) {
      yield putResolve(actions.Fleetfuelling.setFuellingsList(fuelRes.results ?? []));
      yield putResolve(actions.Fleetfuelling.setFuellingsTotal(fuelRes.count));
    }

    const consumptionRes: any = yield call(API.Fleet.GetConsumptions, fuellingsPage, searchTerm, sort, filter, vehicle ? vehicle : undefined, startDate, endDate);
    if (consumptionRes) {
      yield putResolve(actions.Fleetfuelling.setConsumptionsList(consumptionRes.results ?? []));
      yield putResolve(actions.Fleetfuelling.setConsumptionsTotal(consumptionRes.count));
    }
  } catch (err) {
  } finally {
    yield putResolve(actions.Fleetfuelling.setLoading(false));
  }
}

function* getTanksListSaga() {
  try {
    const fleetWithTanks: TanksList = yield call(API.Fleet.GetFleetWithTanks);

    if (fleetWithTanks) {
      yield putResolve(actions.Fleetfuelling.setTanks(fleetWithTanks));
    }
  } catch (err) { }
}

function* getFuellingLocationsSaga() {
  try {
    const res = yield call(API.Fleet.GetFuelLocations);
    if (res) {
      yield putResolve(actions.Fleetfuelling.setFuellingLocationList(res.data ?? []));
    }
  } catch (err) {
  }
}

function* getURLParamsSaga(payload: AnyAction) {

  let params = new URLSearchParams(payload.location.search)

  const {
    searchTerm,
    filter,
    startDate,
    endDate,
    hasDateParams
  } = yield select((state: RootState) => state.Fleetfuelling);

  if (params.toString() !== "") {
    yield putResolve(actions.Fleetfuelling.populateFiltersOnMount({
      searchTerm: params.get('search') ?? "",
      filter: params.get('vehicleType') ?? "",
      vehicle: params.get('vehicle') ?? "",
      local: params.get('fuelStation') ?? "",
      startDate: params.get('startDate') ?? new Date().toISOString().slice(0, 10),
      endDate: params.get('endDate') ?? new Date().toISOString().slice(0, 10)
    }))
    yield putResolve(actions.Fleetfuelling.setHasDateParams(!hasDateParams));
  }
  else {
    yield putResolve(actions.Fleetfuelling.setHasDateParams(!hasDateParams));
    params.set('search', searchTerm)
    params.set('vehicleType', filter)
    params.set('startDate', startDate)
    params.set('endDate', endDate)
    payload.replace({ search: (params).toString() })
  }
}

function* getFuellingPaymentTypesSaga() {
  try {
    const res = yield call(API.Fleet.GetFuelPaymentTypes);
    if (res) {
      yield putResolve(actions.Fleetfuelling.setFuelPaymentTypes(res.data ?? []));
    }
  } catch (err) {
    toast.error("Erro a carregar tipos de pagamento de abastecimentos.");
  }
}

function* setDatesIntervalSaga({ payload }: AnyAction) {

  if (payload.e.startDate && payload.e.endDate) {
    let interval = Interval.fromDateTimes(payload.e.startDate, payload.e.endDate);
    const arr = Array.from(days(interval))

    let params = new URLSearchParams(payload.history.location.search);
    payload.e.startDate !== "" ? params.set('startDate', arr[0]?.toISO().slice(0, 10)) : params.delete('startDate')
    payload.e.endDate !== "" ? params.set('endDate', arr[arr.length - 1]?.toISO().slice(0, 10)) : params.delete('endDate')
    payload.history.replace({ search: (params).toString() })

    yield putResolve(actions.Fleetfuelling.setStartDate(arr[0]?.toISO().slice(0, 10)))
    yield putResolve(actions.Fleetfuelling.setEndDate(arr[arr.length - 1]?.toISO().slice(0, 10)))
  }
}

function* days(interval) {
  let cursor = interval.start.startOf("day");

  while (cursor <= interval.end) {
    yield cursor;
    cursor = cursor.plus({ days: 1 });
  }
}

export default function* watcherSignin() {
  yield takeLatest('Fleetfuelling/onMount', onMountSaga)
  yield takeLatest('Fleetfuelling/onUnmount', onUnmountSaga)
  yield takeLatest('Fleetfuelling/getFuelAndConsumpList', getFuelAndConsumpListSaga)
  yield takeLatest('Fleetfuelling/setFuellingsPage', getFuellingsListSaga)
  yield takeLatest('Fleetfuelling/setConsumptionsPage', getConsumptionsListSaga)
  yield takeLatest('Fleetfuelling/getConsumptionsList', getConsumptionsListSaga)
  yield takeLatest('Fleetfuelling/getFuellingLocations', getFuellingLocationsSaga)
  yield takeLatest('Fleetfuelling/getFuelPaymentTypes', getFuellingPaymentTypesSaga)
  yield takeLatest('Fleetfuelling/setSort', getFuelAndConsumpListSaga)
  yield takeLatest('Fleetfuelling/setFilter',  getFuelAndConsumpListSaga)
  yield takeLatest('Fleetfuelling/setVehicle',  getFuelAndConsumpListSaga)
  yield takeLatest('Fleetfuelling/setFuelStation', getFuellingsListSaga)
  yield takeLatest('Fleetfuelling/setDate',  getFuelAndConsumpListSaga)
  yield takeLatest('Fleetfuelling/getFuellingsList', getFuellingsListSaga)
  yield takeLatest('Fleetfuelling/setSearchTerm',  getFuelAndConsumpListSaga)
  yield takeLatest('Fleetfuelling/getURLParams', getURLParamsSaga);
  yield takeLatest('Fleetfuelling/getTanksList', getTanksListSaga)
  yield takeLatest('Fleetfuelling/setDatesInterval', setDatesIntervalSaga)
}


