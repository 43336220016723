/**
 * -----------------------------------------------------------------------------
 * Copyright (C) Ubiwhere, LDA
 * 2012-2018
 *
 *
 * The reproduction, transmission or use of this document or its contents is not
 * permitted without express written authorization. All rights, including rights
 * created by patent grant or registration of a utility model or design, are
 * reserved. Modifications made to this document are restricted to authorized
 * personnel only. Technical specifications and features are binding only when
 * specifically and expressly agreed upon in a written contract.
 * -----------------------------------------------------------------------------
 *
 * Router
 * Add new routes here
 */
// Import page components bellow here
// Keep them organized as the routes object
import Dashboard from 'scenes/Dashboard';
import Settings from 'scenes/Settings';
// WARNING!! Don't erase the nextline as it is necessary for hygen to automatically import your components
// Define imports here
import InvoiceDetails from 'scenes/Invoicedetails'
import UserCashMovementForm from 'scenes/Usercashmovementform'
import UserCashManagementForms from 'scenes/Usercashmanagementforms'
import UserCashManagement from 'scenes/Usercashmanagement'
import CashManagement from 'scenes/Cashmanagement'
import Clockqrreader from 'scenes/Clockqrreader'
import HistoryLog from 'scenes/Historylog'
import UserTimeRecordDetails from 'scenes/Usertimerecorddetails'
import TimeRecord from 'scenes/Timerecord'
import Configurations from 'scenes/Configurations'
import BusinessInteligence from 'scenes/BusinessInteligence';
import BiDashboard from 'scenes/BiDashboard';
import InvoicingRequests from 'scenes/InvoicingRequests'
import InvoicingSummary from 'scenes/InvoicingSummary'
import BookingForm from 'scenes/BookingForm'
import Passengercontrol from 'scenes/Passengercontrol'
import Tripbookings from 'scenes/Tripbookings'
import TripPlanningsForm from 'scenes/TripForms/Tripplanningsform'
import Tripplannings from 'scenes/Tripplannings'
import Entitymemberform from 'scenes/Entitymemberform'
import Entitymemberslist from 'scenes/Entitymemberslist'
import Entityform from 'scenes/Entityform'
import Entities from 'scenes/Entities'
import Customerpurchasehistory from 'scenes/Customerpurchasehistory'
import Customerform from 'scenes/CustomerForm'
import Customers from 'scenes/Customers'
import Fuellocationconfigform from 'scenes/Fuellocationconfigform'
import Documentconfigform from 'scenes/Documentconfigform'
import TripsConfiguration from 'scenes/Tripsconfiguration'
import TripActivityform from 'scenes/TripForms/Tripactivityform';
import Tripserviceform from 'scenes/TripForms/Tripserviceform';
import ChangePassword from 'scenes/Changepassword'
//import Fleetmanagement from 'scenes/Fleetmanagement'
import FleetMaintenanceForm from 'scenes/FleetMaintenanceForm'
import FleetMaintenance from 'scenes/Fleetmaintenance'
import FleetManagementForm from 'scenes/FleetManagementForm'
import Fleetfuelling from 'scenes/Fleetfuelling'
import CollaboratorsManagement from 'scenes/CollaboratorsManagement'
import UserForm from 'scenes/UserForms';
import FuellingForm from 'scenes/FleetFuellingForms';
import FleetComponent from 'scenes/Fleetmanagement'
import Login from 'scenes/Login';
import FleetConfigs from 'scenes/Fleetconfigs';

import config from 'config';
import Tripcategoryform from 'scenes/TripForms/Tripcategoryform';
import Trippassengertypeform from 'scenes/TripForms/Trippassengertypeform';
import Tripextraform from 'scenes/TripForms/Tripextraform';
import Triplocalform from 'scenes/TripForms/Triplocalform';
import Tripcircuitform from 'scenes/TripForms/Tripcircuitform';
import Tripcampaignform from 'scenes/TripForms/Tripcampaignform';
import Tripcampaigncodesform from 'scenes/TripForms/Tripcampaigncodesform'
import Tripcampaigncodes from 'scenes/Tripcampaigncodes';
import { permissions } from 'permissions';


const { ROLES } = config;

// Define routes here
// translation of Routes.name - on each Routes.name, each subpath refers to a translation key
// ex: invoicing has a key (routes.invoicing) and requests has a key (routes.requests), so when changing routes.name verify if the key of each subpath exists
// set permission field manually in each object
// WARNING!! Don't erase the nextline as it is necessary for hygen to automatically import your components
const Routes = [
  {
    exact: true,
    key: 'invoicedetails',
    name: 'invoicing/details',
    path: '/faturacao/detalhes/:id',
    roles: [ROLES.TEAM_MEMBER],
    permission: '',
    component: InvoiceDetails
  },
  {
    exact: true,
    key: 'usercashmovementform',
    name: 'invoicing/cashManagement/',
    path: '/faturacao/gestao-caixa/:uuid/editar-movimento/:id',
    roles: [ROLES.TEAM_MEMBER],
    permission: permissions.INVOICING.CASH_MANAGEMENT.VIEW_BALANCE,
    component: UserCashMovementForm
  },
  {
    exact: true,
    key: 'usercashmovementform',
    name: 'invoicing/cashManagement/',
    path: '/faturacao/gestao-caixa/:uuid/novo-movimento',
    roles: [ROLES.TEAM_MEMBER],
    permission: permissions.INVOICING.CASH_MANAGEMENT.CREATE_BALANCE,
    component: UserCashMovementForm
  },
  {
    exact: true,
    key: 'cashmanagement',
    name: 'invoicing/cashManagement',
    path: '/faturacao/gestao-caixa',
    roles: [ROLES.TEAM_MEMBER],
    permission: permissions.INVOICING.CASH_MANAGEMENT.LIST_STATEMENT,
    component: CashManagement
  },
  {
    exact: true,
    key: 'usercashmanagementforms',
    name: 'invoicing/cashManagement/',
    path: '/faturacao/gestao-caixa/:uuid/editar/:id',
    roles: [ROLES.TEAM_MEMBER],
    permission: permissions.INVOICING.CASH_MANAGEMENT.VIEW_STATEMENT,
    component: UserCashManagementForms
  },
  {
    exact: true,
    key: 'usercashmanagement',
    name: 'invoicing/cashManagement/',
    path: '/faturacao/gestao-caixa/:uuid',
    roles: [ROLES.TEAM_MEMBER],
    permission: permissions.INVOICING.CASH_MANAGEMENT.VIEW_STATEMENT,
    component: UserCashManagement
  },
  {
    exact: true,
    key: 'clockqrreader',
    name: 'Clockqrreader',
    path: '/clock/qrreader',
    roles: [ROLES.GUEST],
    permission: '',
    component: Clockqrreader
  },
  {
    exact: true,
    key: 'historylog',
    name: 'collaborators/users/historyLog',
    path: '/colaboradores/historico-edicoes/:id',
    roles: [ROLES.TEAM_MEMBER],
    permission: '',
    component: HistoryLog
  },
  {
    exact: true,
    key: 'usertimerecorddetails',
    name: 'collaborators/timeRecord/',
    path: '/colaboradores/registo-horas/editar/:id',
    roles: [ROLES.TEAM_MEMBER],
    permission: '',
    component: UserTimeRecordDetails
  },
  {
    exact: true,
    key: 'timerecord',
    name: 'collaborators/timeRecord',
    path: '/colaboradores/registo-horas',
    roles: [ROLES.TEAM_MEMBER],
    permission: '',
    component: TimeRecord
  },
  {
    exact: true,
    key: 'configurations',
    name: 'configurations',
    path: '/configuracoes',
    roles: [ROLES.TEAM_MEMBER],
    permission: permissions.SETTINGS.VIEW_SETTING,
    component: Configurations
  },
  // TODO - Definir os roles e as permissões certos
  { 
    exact: true,
    key: 'bi',
    name: 'businessInteligence',
    path: '/inteligencia-negocio',
    roles: [ROLES.TEAM_MEMBER],
    permission: permissions.SETTINGS.VIEW_SETTING,
    component: BusinessInteligence
  },
  { 
    exact: true,
    key: 'biDashboard',
    name: 'businessInteligence/dashboard',
    path: '/inteligencia-negocio/painel/:id',
    roles: [ROLES.TEAM_MEMBER],
    permission: permissions.SETTINGS.VIEW_SETTING,
    component: BiDashboard
  },
  {
    exact: true,
    key: 'invoicingrequests',
    name: 'invoicing/invoicing',
    path: '/faturacao/pedidos',
    roles: [ROLES.TEAM_MEMBER],
    permission: permissions.INVOICING.LIST_INVOICE,
    component: InvoicingRequests
  },
  {
    exact: true,
    key: 'invoicingsummary',
    name: 'invoicing/summary',
    path: '/faturacao/resumo',
    roles: [ROLES.TEAM_MEMBER],
    permission: permissions.INVOICING.LIST_INVOICE,
    component: InvoicingSummary
  },
  {
    exact: true,
    key: 'passengercontrol',
    name: 'passengerControl',
    path: '/controlo-passageiros',
    roles: [ROLES.TEAM_MEMBER],
    permission: permissions.TRIPS.BOOKINGS.LIST_BOOKING,
    component: Passengercontrol
  },
  {
    exact: true,
    key: 'bookingform',
    name: 'trips/bookings/newBooking',
    path: '/viagens/reservas/nova-reserva',
    roles: [ROLES.TEAM_MEMBER],
    permission: permissions.TRIPS.BOOKINGS.CREATE_BOOKING,
    component: BookingForm
  },
  {
    exact: true,
    key: 'bookingform',
    name: 'trips/bookings/editBooking',
    path: '/viagens/reservas/editar-reserva/:id',
    roles: [ROLES.TEAM_MEMBER],
    permission: permissions.TRIPS.BOOKINGS.VIEW_BOOKING,
    component: BookingForm
  },
  {
    exact: true,
    key: 'tripbookings',
    name: 'trips/bookings',
    path: '/viagens/reservas',
    roles: [ROLES.TEAM_MEMBER],
    permission: permissions.TRIPS.BOOKINGS.LIST_BOOKING,
    component: Tripbookings
  },
  {
    exact: true,
    key: 'tripplanningsform',
    name: 'trips/plannings/newPlanning',
    path: '/viagens/agendamentos/novo-agendamento',
    roles: [ROLES.TEAM_MEMBER],
    permission: permissions.TRIPS.PLANNINGS.CREATE_PLANNING,
    component: TripPlanningsForm
  },
  {
    exact: true,
    key: 'tripplanningsform',
    name: 'trips/plannings/editPlanning',
    path: '/viagens/agendamentos/editar-agendamento/:id',
    roles: [ROLES.TEAM_MEMBER],
    permission: permissions.TRIPS.PLANNINGS.VIEW_PLANNING,
    component: TripPlanningsForm
  },
  {
    exact: true,
    key: 'tripplannings',
    name: 'trips/plannings',
    path: '/viagens/agendamentos',
    roles: [ROLES.TEAM_MEMBER],
    permission: permissions.TRIPS.PLANNINGS.LIST_PLANNING,
    component: Tripplannings
  },
  {
    key: 'entityform',
    name: 'entities/editEntity',
    path: '/entidades/editar-entidade/:id',
    roles: [ROLES.TEAM_MEMBER],
    permission: permissions.USERS.VIEW_PARTNER,
    component: Entityform
  },
  {
    exact: true,
    key: 'entityform',
    name: 'entities/newEntity',
    path: '/entidades/nova-entidade',
    roles: [ROLES.TEAM_MEMBER],
    permission: permissions.USERS.CREATE_PARTNER,
    component: Entityform
  },
  {
    exact: true,
    key: 'entitymemberform',
    name: 'entities/entityMembers/editEntityMember',
    path: '/entidades/:id/editar-membro/:memid',
    roles: [ROLES.TEAM_MEMBER],
    permission: permissions.USERS.VIEW_PARTNER_USER,
    component: Entitymemberform
  },
  {
    exact: true,
    key: 'entitymemberform',
    name: 'entities/entityMembers/newEntityMember',
    path: '/entidades/:id/novo-membro',
    roles: [ROLES.TEAM_MEMBER],
    permission: permissions.USERS.CREATE_PARTNER_USER,
    component: Entitymemberform
  },
  {
    exact: true,
    key: 'entitymemberslist',
    name: 'entities/entityMembers',
    path: '/entidades/:id/membros',
    roles: [ROLES.TEAM_MEMBER],
    permission: permissions.USERS.LIST_PARTNER_USER,
    component: Entitymemberslist
  },
  {
    exact: true,
    key: 'entities',
    name: 'entities',
    path: '/entidades',
    roles: [ROLES.TEAM_MEMBER],
    permission: permissions.USERS.LIST_PARTNER,
    component: Entities
  },
  {
    exact: true,
    key: 'customerpurchasehistory',
    name: 'customers/purchaseHistory',
    path: '/clientes/historico-compras/:id',
    roles: [ROLES.TEAM_MEMBER],
    permission: permissions.USERS.CREATE_PARTNER,
    component: Customerpurchasehistory
  },
  {
    exact: true,
    key: 'customerform',
    name: 'customers/newCustomer',
    path: '/clientes/novo-cliente',
    roles: [ROLES.TEAM_MEMBER],
    permission: permissions.USERS.CREATE_CUSTOMER,
    component: Customerform
  },
  {
    exact: true,
    key: 'customerform',
    name: 'customers/editCustomer',
    path: '/clientes/editar-cliente/:uuid',
    roles: [ROLES.TEAM_MEMBER],
    permission: permissions.USERS.VIEW_CUSTOMER,
    component: Customerform
  },
  {
    exact: true,
    key: 'customers',
    name: 'customers',
    path: '/clientes',
    roles: [ROLES.TEAM_MEMBER],
    permission: permissions.USERS.LIST_CUSTOMER,
    component: Customers
  },
  {
    exact: true,
    key: 'fuellocationconfigform',
    name: 'fleet/fleetSettings/editFuellingStation',
    path: '/frota/configuracoes/editar-local-abastecimento/:id',
    roles: [ROLES.TEAM_MEMBER],
    permission: permissions.FLEET.EDIT_FLEET_SETTINGS,
    component: Fuellocationconfigform
  },
  {
    exact: true,
    key: 'documentconfigform',
    name: 'fleet/fleetSettings/editDocument',
    path: '/frota/configuracoes/editar-documento/:id',
    roles: [ROLES.TEAM_MEMBER],
    permission: permissions.FLEET.EDIT_FLEET_SETTINGS,
    component: Documentconfigform
  },
  {
    exact: true,
    key: 'fuellocationconfigform',
    name: 'fleet/fleetSettings/newFuellingStation',
    path: '/frota/configuracoes/novo-local-abastecimento',
    roles: [ROLES.TEAM_MEMBER],
    permission: permissions.FLEET.EDIT_FLEET_SETTINGS,
    component: Fuellocationconfigform
  },
  {
    exact: true,
    key: 'documentconfigform',
    name: 'fleet/fleetSettings/newDocument',
    path: '/frota/configuracoes/novo-documento',
    roles: [ROLES.TEAM_MEMBER],
    permission: permissions.FLEET.EDIT_FLEET_SETTINGS,
    component: Documentconfigform
  },
  {
    exact: true,
    key: 'tripsconfiguration',
    name: 'trips/settings/newActivity',
    path: '/viagens/configuracoes/nova-atividade',
    roles: [ROLES.TEAM_MEMBER],
    permission: permissions.TRIPS.ACTIVITIES.CREATE_ACTIVITY,
    component: TripActivityform
  },
  {
    exact: true,
    key: 'tripsconfiguration',
    name: 'trips/settings/editActivity',
    path: '/viagens/configuracoes/editar-atividade/:id',
    roles: [ROLES.TEAM_MEMBER],
    permission: "",
    component: TripActivityform
  },
  {
    exact: true,
    key: 'tripsconfiguration',
    name: 'trips/settings/newService',
    path: '/viagens/configuracoes/novo-servico',
    roles: [ROLES.TEAM_MEMBER],
    permission: permissions.TRIPS.SERVICES.CREATE_SERVICE,
    component: Tripserviceform
  },
  {
    exact: true,
    key: 'tripsconfiguration',
    name: 'trips/settings/editService',
    path: '/viagens/configuracoes/editar-servico/:id',
    roles: [ROLES.TEAM_MEMBER],
    permission: permissions.TRIPS.SERVICES.VIEW_SERVICE,
    component: Tripserviceform
  },
  {
    exact: true,
    key: 'tripsconfiguration',
    name: 'trips/settings/newCategory',
    path: '/viagens/configuracoes/nova-categoria',
    roles: [ROLES.TEAM_MEMBER],
    permission: permissions.TRIPS.CATEGORIES.CREATE_CATEGORY,
    component: Tripcategoryform
  },
  {
    exact: true,
    key: 'tripsconfiguration',
    name: 'trips/settings/editCategory',
    path: '/viagens/configuracoes/editar-categoria/:id',
    roles: [ROLES.TEAM_MEMBER],
    permission:"",
    component: Tripcategoryform
  },
  {
    exact: true,
    key: 'tripsconfiguration',
    name: 'trips/settings/newPassengerType',
    path: '/viagens/configuracoes/novo-tipo',
    roles: [ROLES.TEAM_MEMBER],
    permission: permissions.TRIPS.PASSENGER_TYPES.CREATE_PASSENGER_TYPE,
    component: Trippassengertypeform
  },
  {
    exact: true,
    key: 'tripsconfiguration',
    name: 'trips/settings/editPassengerType',
    path: '/viagens/configuracoes/editar-tipo/:id',
    roles: [ROLES.TEAM_MEMBER],
    permission: "",
    component: Trippassengertypeform
  },
  {
    exact: true,
    key: 'tripsconfiguration',
    name: 'trips/settings/newExtra',
    path: '/viagens/configuracoes/novo-extra',
    roles: [ROLES.TEAM_MEMBER],
    permission: permissions.TRIPS.EXTRAS.CREATE_EXTRA,
    component: Tripextraform
  },
  {
    exact: true,
    key: 'tripsconfiguration',
    name: 'trips/settings/editExtra',
    path: '/viagens/configuracoes/editar-extra/:id',
    roles: [ROLES.TEAM_MEMBER],
    permission: permissions.TRIPS.EXTRAS.VIEW_EXTRA,
    component: Tripextraform
  },
  {
    exact: true,
    key: 'tripsconfiguration',
    name: 'trips/settings/newLocal',
    path: '/viagens/configuracoes/novo-local',
    roles: [ROLES.TEAM_MEMBER],
    permission: permissions.TRIPS.STOPS.CREATE_STOP,
    component: Triplocalform
  },
  {
    exact: true,
    key: 'tripsconfiguration',
    name: 'trips/settings/editLocal',
    path: '/viagens/configuracoes/editar-local/:id',
    roles: [ROLES.TEAM_MEMBER],
    permission: "",
    component: Triplocalform
  },
  {
    exact: true,
    key: 'tripsconfiguration',
    name: 'trips/settings/newCircuit',
    path: '/viagens/configuracoes/novo-circuito',
    roles: [ROLES.TEAM_MEMBER],
    permission: permissions.TRIPS.CIRCUITS.CREATE_CIRCUIT,
    component: Tripcircuitform
  },
  {
    exact: true,
    key: 'tripsconfiguration',
    name: 'trips/settings/editCircuit',
    path: '/viagens/configuracoes/editar-circuito/:id',
    roles: [ROLES.TEAM_MEMBER],
    permission: permissions.TRIPS.CIRCUITS.VIEW_CIRCUIT,
    component: Tripcircuitform
  },
  {
    exact: true,
    key: 'tripsconfiguration',
    name: 'trips/settings/newCampaign',
    path: '/viagens/configuracoes/nova-campanha',
    roles: [ROLES.TEAM_MEMBER],
    permission: permissions.TRIPS.CAMPAIGNS.CREATE_CAMPAIGN,
    component: Tripcampaignform
  },
  {
    exact: true,
    key: 'tripsconfiguration',
    name: 'trips/settings/editCampaign',
    path: '/viagens/configuracoes/editar-campanha/:id',
    roles: [ROLES.TEAM_MEMBER],
    permission: permissions.TRIPS.CAMPAIGNS.VIEW_CAMPAIGN,
    component: Tripcampaignform
  },
  {
    exact: true,
    key: 'tripsconfiguration',
    name: 'Viagens/Configurações/Campanhas/ Códigos',
    path: '/viagens/configuracoes/campanhas/codigos/:id/:prefix',
    roles: [ROLES.TEAM_MEMBER],
    permission: 'none',
    component: Tripcampaigncodes
  },
  {
    exact: true,
    key: 'tripcampaigncodesform',
    name: 'Viagens/Configurações/Campanhas/Códigos/ Novo Código',
    path: '/viagens/configuracoes/campanhas/codigos/novo-codigo/:id/:prefix',
    roles: [ROLES.TEAM_MEMBER],
    permission: 'none',
    component: Tripcampaigncodesform
  },
  {
    exact: true,
    key: 'tripcampaigncodesform',
    name: 'Viagens/Configurações/Campanhas/Códigos/ Editar Código',
    path: '/viagens/configuracoes/campanhas/codigos/editar-codigo/:id/:prefix',
    roles: [ROLES.TEAM_MEMBER],
    permission: 'none',
    component: Tripcampaigncodesform
  },
  {
    exact: true,
    key: 'tripsconfiguration',
    name: 'trips/settings',
    path: '/viagens/configuracoes',
    roles: [ROLES.TEAM_MEMBER],
    permission: '',
    component: TripsConfiguration
  },
  {
    exact: true,
    key: 'changepassword',
    name: 'Changepassword',
    path: '/login/mudar-password/:token',
    roles: [ROLES.GUEST],
    permission: '',
    component: ChangePassword
  },
  /*   {
      exact:true,
      key: 'fleetlist',
      name: 'fleet/vehicles',
      path: '/frota/veiculos',
      roles: [ROLES.GUEST],
      component: Fleetmanagement,
    }, */
  {
    exact: true,
    key: 'maintenanceForm',
    name: 'fleet/maintenances/newMaintenance',
    path: '/frota/manutencoes/nova-manutencao',
    roles: [ROLES.TEAM_MEMBER],
    permission: permissions.FLEET.CREATE_MAINTENANCE,
    component: FleetMaintenanceForm
  },
  {
    exact: true,
    key: 'maintenanceForm',
    name: 'fleet/maintenances/editMaintenance',
    path: '/frota/manutencoes/editar-manutencao/:id',
    roles: [ROLES.TEAM_MEMBER],
    permission: permissions.FLEET.EDIT_MAINTENANCE,
    component: FleetMaintenanceForm
  },
  {
    exact: true,
    key: 'fleetmaintenance',
    name: 'fleet/maintenances',
    path: '/frota/manutencoes',
    roles: [ROLES.TEAM_MEMBER],
    permission: permissions.FLEET.LIST_MAINTENANCE,
    component: FleetMaintenance
  },
  {
    exact: true,
    key: 'novoVeiculo',
    name: 'fleet/vehicles/newVehicle',
    path: '/frota/veiculos/novo-veiculo',
    roles: [ROLES.TEAM_MEMBER],
    permission: permissions.FLEET.CREATE_VEHICLE,
    component: FleetManagementForm
  },
  {
    exact: true,
    key: 'editarVeiculo',
    name: 'fleet/vehicles/editVehicle',
    path: '/frota/veiculos/editar-veiculo/:id',
    roles: [ROLES.TEAM_MEMBER],
    permission: permissions.FLEET.VIEW_VEHICLE,
    component: FleetManagementForm
  },
  {
    exact: true,
    key: 'editarVeiculo',
    name: 'fleet/fleetSettings',
    path: '/frota/configuracoes',
    roles: [ROLES.TEAM_MEMBER],
    permission: permissions.FLEET.EDIT_FLEET_SETTINGS,
    component: FleetConfigs
  },
  {
    exact: true,
    key: 'novoUtilizador',
    name: 'collaborators/users/newUser',
    path: '/colaboradores/utilizadores/novo-utilizador',
    roles: [ROLES.TEAM_MEMBER],
    permission: permissions.USERS.CREATE_TEAM_MEMBER,
    component: UserForm,
  },
  {
    exact: true,
    key: 'editarUtilizador',
    name: 'collaborators/users/editUser',
    path: '/colaboradores/utilizadores/editar-utilizador/:uuid',
    roles: [ROLES.TEAM_MEMBER],
    permission: 'view_profile' || permissions.USERS.VIEW_TEAM_MEMBER,
    component: UserForm,
  },
  {
    exact: true,
    key: 'collaboratorsmanagement',
    breadcrumbs: '',
    name: 'collaborators/users',
    path: '/colaboradores/utilizadores',
    roles: [ROLES.TEAM_MEMBER],
    permission: permissions.USERS.LIST_TEAM_MEMBER,
    component: CollaboratorsManagement
  },
  {
    key: 'fleetmanagement',
    name: 'fleet/vehicles',
    path: '/frota/veiculos',
    roles: [ROLES.TEAM_MEMBER],
    permission: permissions.FLEET.LIST_VEHICLE,
    component: FleetComponent
  },
  {
    exact: true,
    key: 'login',
    name: 'Login',
    path: '/login',
    roles: [ROLES.GUEST],
    permission: '',
    component: Login
  },
  {
    exact: true,
    key: 'settings',
    name: 'Settings',
    path: '/settings',
    roles: 'none',
    component: Settings,
  },
  {
    exact: true,
    key: 'dashboard',
    name: 'dashboard',
    path: '/dashboard',
    roles: [ROLES.TEAM_MEMBER],
    permission: '',
    component: Dashboard,
  },
  {
    exact: true,
    key: 'newfuelling',
    name: 'fleet/fuel/newFuelling',
    path: '/frota/combustivel/novo-abastecimento',
    roles: [ROLES.TEAM_MEMBER],
    permission: permissions.FLEET.CREATE_FUEL_TO_VEHICLE,
    component: FuellingForm
  },
  {
    exact: true,
    key: 'editFuelling',
    name: 'fleet/fuel/editFuelling',
    path: '/frota/combustivel/editar-abastecimento/:id',
    roles: [ROLES.TEAM_MEMBER],
    permission: permissions.FLEET.EDIT_FUEL,
    component: FuellingForm
  },
  {
    exact: true,
    key: 'fleetfuelling',
    name: 'fleet/fuel',
    path: '/frota/combustivel',
    roles: [ROLES.TEAM_MEMBER],
    permission: permissions.FLEET.LIST_FUEL,
    component: Fleetfuelling
  },
  //DO NOT USE, FOR STORYBOOK ONLY
  {
    exact: true,
    key: 'example',
    name: 'Example',
    path: '/example',
    roles: [ROLES.GUEST],
    component: Dashboard,
  }
];

// Maps don't work on object convert it to an array
export default Routes;
