/**
 * Triptypepassengerform scene slice
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */
import { createSlice } from '@reduxjs/toolkit'
import { PassengerTypeForm } from 'types/trips/passengerTypes'
import { camelizeKeys } from 'humps'

interface InitialState {
    passengerTypeID:"",
    edit: Boolean,
    passengerTypeForm: PassengerTypeForm,
    hasEditPermission: boolean,
    submitButtonState: boolean,
}

const initialState: InitialState = {
    passengerTypeID: "",
    edit: false,
    hasEditPermission: true,
    submitButtonState: false,
    passengerTypeForm: {
        namePt: {
            value: "",
            valid: true,
            message: ""
        },
        nameEn: {
            value: "",
            valid: true,
            message: ""
        },
        nameEs: {
            value: "",
            valid: true,
            message: ""
        },
        nameFr: {
            value: "",
            valid: true,
            message: ""
        },
        occupySeat:{
            value: true,
            valid: true,
            message: ""
        },
        isDefault:{
            value: true,
            valid: true,
            message: ""
        },
        sort:{
            value: "",
            valid: true,
            message: ""
        },
        shortNamePt: {
            value: "",
            valid: true,
            message: ""
        },
        shortNameEn: {
            value: "",
            valid: true,
            message: ""
        },
        shortNameEs: {
            value: "",
            valid: true,
            message: ""
        },
        shortNameFr: {
            value: "",
            valid: true,
            message: ""
        },
        descriptionPt: {
            value: "",
            valid: true,
            message: ""
        },
        descriptionEn: {
            value: "",
            valid: true,
            message: ""
        },
        descriptionEs: {
            value: "",
            valid: true,
            message: ""
        },
        descriptionFr: {
            value: "",
            valid: true,
            message: ""
        },
        isActive: {
            value: true,
            valid: true,
            message: ""
        }
    }
}

export default createSlice({
    name: 'trippassengertypeform',
    initialState,
    reducers: {
        onMount: (state, { payload }) => {},
        onUnmount: () => { 
            return initialState;
        },
        setAction: (state, { payload }) => {
            state.edit = payload
        },
        setPassengerTypeID: (state, { payload }) => {
            state.passengerTypeID = payload
        },
        setHasEditPermission: (state, { payload }) => {
            state.hasEditPermission = payload
        },
        setSubmitButtonDisabled:(state, { payload }) => {
            state.submitButtonState = payload
        },
        populateForm: (state, { payload }) => {
            const passengerType = camelizeKeys(payload)
            //const passengerType = TripPassengerTypesMock[0]
            const baseForm = { ...initialState.passengerTypeForm }
      
            Object.keys(passengerType).forEach(key => {
              if (key in baseForm) {
                baseForm[key] = {
                  ...baseForm[key],
                  value: passengerType[key],
                }
              }
            })
            state.passengerTypeForm = baseForm;
        },
        onFormChange: (state, { payload }) => {
            state.passengerTypeForm = payload
        },
        onSubmit:(state, {payload}) => {}
    }
})