import { defaultChangePasswordForm, defaultEntityMemberForm } from '../utils'
import {  ChangePasswordForm } from 'types/entity/entityMemberForm'
import { EntityMemberListItem, EntityMemberForm } from 'types/entity/entity';

export interface InitialStateProps {
  uuid: string,
  userAuthExists: boolean,
  entityMemberForm: EntityMemberForm,
  changePasswordForm: ChangePasswordForm,
  changePasswordModal: boolean,
  hasChangedPassword: boolean,
  edit: boolean,
  hasEditPermission: boolean,
  entity: EntityMemberListItem | {},
  loading: boolean,
  showDeleteModal: boolean,
  submitButtonState: boolean
}

export const initialState: InitialStateProps = {
  uuid: "",
  userAuthExists: false,
  entityMemberForm: defaultEntityMemberForm,
  changePasswordForm: defaultChangePasswordForm,
  changePasswordModal: false,
  hasChangedPassword: false,
  edit: false,
  hasEditPermission: true,
  entity: {},
  loading: true,
  showDeleteModal: false,
  submitButtonState: false
}