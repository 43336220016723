/**
 * Consumptionlisttablerow component
 *
 * @author João Dias <jdias@ubiwhere.com>
 *
 *
 */

import React from 'react'
import { DateTime } from "luxon"
import { CTableDataCell } from '@coreui/react-pro'
import config from 'config'
// Types
import { FuelConsumptionObject } from 'types/fleet/fuel'
import { TableRowPropTypes } from 'types/misc'
// Components
import GaugeComponent from 'shared/components/GaugeComponent'
// Styles
import { CustomCTableRow, ActionsCell } from './styles'

type PropTypes = TableRowPropTypes & {
  id?: string,
  consumption: FuelConsumptionObject,
}

const Consumptionlisttablerow = (props: PropTypes) => {
  const {
    actions,
    consumption: {
      vehicle: {
        shortName,
        fuelCapacity,
      },
      vehicleFuelLevel,
      createdAt,
      createdBy,
      litres
    },
  } = props;

  return (
    <CustomCTableRow id={props.id}>
      <CTableDataCell>{shortName}</CTableDataCell>
      <CTableDataCell>{ createdBy ? createdBy?.name : ''}</CTableDataCell>
      <CTableDataCell>{litres}</CTableDataCell>
      <CTableDataCell>
        <GaugeComponent 
          unit="liters" 
          currentValue={vehicleFuelLevel}
          maxValue={Number.parseInt(fuelCapacity)}
        />
      </CTableDataCell>
      <CTableDataCell>{DateTime.fromISO(createdAt).toFormat(config.DATE_FORMAT.DATETIME)}</CTableDataCell>
      <ActionsCell>
        {actions}
      </ActionsCell>
    </CustomCTableRow>
  )
}

export default Consumptionlisttablerow