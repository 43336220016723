import * as Check from 'validations'
import { ExtrasForm, ExtraPhotoForm, PackForm } from 'types/trips/extras'

export const ExtraFormValidations = {
    shortName: [Check.isRequired],
    descriptionPt: [],
    descriptionEn: [],
    descriptionEs: [],
    descriptionFr: [],
    namePt: [Check.isRequired],
    nameEn: [],
    nameEs: [],
    nameFr: [],
    icon: [],
    maxCapacity: [Check.isRequired, Check.isNumber],
    price: [Check.isRequired, Check.isNumber],
    isActive: [],
    isPack: []
}

export const ExtraFormValidationsEdit = {
    shortName: [Check.isRequired],
    descriptionPt: [],
    descriptionEn: [],
    descriptionEs: [],
    descriptionFr: [],
    namePt: [Check.isRequired],
    nameEn: [],
    nameEs: [],
    nameFr: [],
    icon: [],
    maxCapacity: [Check.isRequired, Check.isNumber],
    price: [Check.isRequired, Check.isNumber],
    isActive: [],
    isPack: []
}

export type SubmitSagaPayload = {
    e?: any,
    edit: boolean,
    extraForm: ExtrasForm,
    packForm: PackForm[]
    photos: ExtraPhotoForm[]
}

export type IconSagasPayload = {
    extra: string,
    icon: File | null | string
}

export type PhotosSagasPayload = {
    extra: string,
    photos: ExtraPhotoForm[]
}

export type DeletePhotosSagasPayload = {
    photos: ExtraPhotoForm[],
    e?: any,
    index: number,
    localID: string,
    newPhotosID: number
}

export const calculatePackQuantity = (extraElements: any): number => {
    if (extraElements.length > 0) return Math.min(...extraElements.map(el => Number(el.quantity.value)))
    return 0
}

export const doesPackCapacityExcedeExtraMinimumQuantity = (extras: PackForm[], lotation: number): boolean => {
    return lotation > Math.min(...extras.map(el => Number(el.quantity.value)))
}