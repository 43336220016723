import styled from 'styled-components'
import { CRow, CTable, CTableBody, CTableHead } from '@coreui/react-pro'
import theme from 'ui/theme'


export const Wrapper = styled.div``


export const ButtonRow = styled(CRow)`
  --cui-gutter-y:0rem;
  margin-top:24px;
  display:flex;
  justify-content:center;
`

export const Table = styled(CTable)`
`

export const CustomCTableHead = styled(CTableHead)`
    background-color: ${theme().gray200Color};

    > tr {
      > th {
        color: ${theme().darkColor};
        font-family: 'Inter';
        font-size: 14px;
        font-weight: 700;
      }

      th:last-child{
        width: 5%;
      }

      th:first-child {
        > svg {
          width: 100%;
          height: 24px;
          margin: auto;
          color: ${theme().darkColor};
          fill: ${theme().darkColor};
        }
      }
    }
`


export const CustomCTableBody = styled(CTableBody)`
  border: 1px solid ${theme().gray200Color};
`

export const Text = styled.p`
  margin:0px;
`