import React from 'react'
import { useDispatch } from 'react-redux';
// Styles
import theme from 'ui/theme';
import { Wrapper, ExtrasCounter, ActionsWrapper, ButtonIcon, ExtrasCounterWrapper } from './styles'
// Icons
import Ticket from 'assets/icons/ticket'
import Edit from 'assets/icons/edit'
import Feed from 'assets/icons/feed'
// Store
import { actions } from 'store/rootSlices';

type PropTypes = {
  permission: boolean;
  planning: any;
}

const PadActions = ({ permission, planning }: PropTypes) => {

  const dispatch = useDispatch()

  const _renderLink = () => {
    return permission && (
      <a href={`/viagens/agendamentos/editar-agendamento/${planning.id}`} onClick={(e) => {
        e.preventDefault()
        dispatch(actions.App.navigateTo(`/viagens/agendamentos/editar-agendamento/${planning.id}`))
      }}>
        <Edit />
      </a>
    )
  }

  return (
    <Wrapper>
      <ExtrasCounter>
        {
          planning.activities[0].availableExtras?.map((extra, idx) => {
            return (
              <ExtrasCounterWrapper border={(idx + 1) === planning.activities[0].availableExtras.length ? false : true}>
                {extra.icon ? <img className='colorize-filter' src={extra.icon} style={{ width: '15px', }} alt={`extraIcon${extra.name}`} /> : null} <b>{extra.occupied ?? 0}</b>/<span className="smaller">{extra.maxCapacity}</span>
              </ExtrasCounterWrapper>
            )
          })
        }
      </ExtrasCounter>


      <ActionsWrapper>
        <ButtonIcon
          marginr='0'
          onClick={(e) => { }}>
          {_renderLink()}
        </ButtonIcon>
        <ButtonIcon pointer='auto' marginr='0'><Feed fill={`${theme().secondaryColor}`} /></ButtonIcon>
        <ButtonIcon pointer='auto' marginr='0'><Ticket fill={`${theme().secondaryColor}`} /></ButtonIcon>
      </ActionsWrapper>
    </Wrapper>
  )
}

export default PadActions