import styled from 'styled-components'
import DropdownArrow from 'assets/icons/dropdown_arrow'
import theme from 'ui/theme'


export const Wrapper = styled.div<{ startDate: Date | undefined | null, endDate: Date | undefined | null, showTitle: boolean }>`

  > * {

    //* Here we hide the calendar title if the month and year selectors are available 
    .react-datepicker__current-month.react-datepicker__current-month--hasYearDropdown.react-datepicker__current-month--hasMonthDropdown { 
      display: ${props => props.showTitle ? 'block' : 'none'};
    }

    .react-datepicker-popper, .calendarPopper{
      z-index:111;
    }

    .react-datepicker__input-container {
      display: unset;
    }

    .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
      margin:2px 0px 0px;
      width: 50px;
      padding: 8px 18px;

      border: transparent dashed;
      border-radius: 0.3rem;
      border-width: 1px 0;
    }

    .react-datepicker__day--in-range {
      border-radius: 0;
      padding: 8px 18px;
      background-color:${theme().gray400Color};
    }

    .react-datepicker__day--range-start {
      padding: 8px 18px;
      border-radius: 0.3rem;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      background-color:${theme().calendar};
    }

    .react-datepicker__day--range-end {
      padding: 8px 18px;
      border-radius: 0.3rem;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      background-color:${theme().calendar};
    }

    //* Here we add important so when we open the dropdown the month and year selectors are still visible;
    .react-datepicker__month-read-view,
    .react-datepicker__year-read-view {
      visibility: visible !important;
    }

    .react-datepicker__header {
      background-color: ${theme().white} !important;
      border-bottom: none;

      //* Here we deal with the month and year selection dropdowns  
      .react-datepicker__header__dropdown.react-datepicker__header__dropdown--scroll {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1.5rem;
        font-weight: 600;
        line-height: 0.7rem;
      }

      .react-datepicker__month-read-view--down-arrow,
      .react-datepicker__year-read-view--down-arrow {
        padding: .225rem;
        top: -20%;
      }
    }


    .react-datepicker__day--in-selecting-range {

      &:hover{
        color: white;
        border-radius: 0.3rem;
        background-color:${theme().calendar};
      }

      &:first-child{
        border-left: 1px dashed ${theme().calendar};
      }

      &:last-child{
        border-right: 1px dashed ${theme().calendar};
      }

      &:focus{
        color: white;
        border-radius: 0.3rem;
        background-color:${theme().calendar};
      }

      &:active{
        color: white;
        border-radius: 0.3rem;
        background-color:${theme().calendar};
      }

      background-color: ${theme().gray200Color};
      color: black;
      border-radius: 0px;
      border-width: 1px 0;
      border-color:${theme().calendar};
      border-style: dashed;
      margin: 2px 0px 0px;
    }

    ${({ startDate, endDate }) => endDate && startDate && startDate.getTime() === endDate.getTime()
    ?
    `.react-datepicker__day--in-range {
      border-radius: 0.3rem;
      padding: 8px 18px;
    }`
    :
    ""
  }
    
  }
`

export const CalendarContainer = styled.div`
  display: flex;
  border: 1px solid ${theme().gray300Color};
  border-radius: 10px;
  padding: 3px;
  background: ${theme().bg};
`

export const QuickOptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  background-color: ${theme().white};
  padding: 1px 5px;
  border-right: 1px solid ${theme().gray300Color};
`

export const QuickOptionElement = styled.div<{ selected: boolean }>`
  
  font-size: 14px;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 4px;
  text-align: center;
  background-color: ${({ selected }) => !!selected ? theme().calendar : theme().bg};
  color: ${({ selected }) => !!selected ? theme().white : theme().gray700Color};

  :hover {
    background-color: ${theme().calendar};
    color: ${theme().white};
  }
`

export const ArrowContainer = styled.div`
  margin: 8px;
  display: flex;
  align-items: center;
`

export const CustomDropdownArrow = styled(DropdownArrow) <{ isdefaultselected: boolean }>`
  margin-top: 2px;
`

export const CurrentValueContainer = styled.div<{ isdefaultselected: boolean }>`
  color: ${props => props.isdefaultselected ? theme().gray500Color : theme().primary};
`

export const SelectContainer = styled.div<{ isdefaultselected: boolean, nopointer?: boolean, hasBorder?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: ${props => !props.isdefaultselected && props.hasBorder ? `1px solid ${theme().primary}` : `0`};
  border-radius: 4px;
  padding: 6px 0px 6px 6px;
  font-family: 'Inter';
  font-size: 14px;
  ${(({ nopointer }) => nopointer && nopointer === true ? `cursor: auto;` : `cursor: pointer;`)}

  > svg:first-child {
    z-index: 1;
    fill: ${props => props.isdefaultselected ? theme().gray500Color : theme().primary} !important;
  }

  > :nth-child(2) {
    margin-left: 8px;
    border: 0;
    background: transparent;
  }
`
