import styled from 'styled-components'
import { CButtonGroup, CFormCheck } from '@coreui/react-pro'
import theme from 'ui/theme'

const Wrapper = styled.div`
  width:100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const TabsWrapper = styled(CButtonGroup)`
    display:flex;
    justify-content: flex-start;
    background-color: ${theme().white};
    margin-bottom: 1em;
`

const Tab = styled(CFormCheck)`
    margin:0px;
    border: 1px solid ${theme().secondaryColor};
    padding: 5px 11px !important;
    color: ${theme().secondaryColor};
`

const PrintWrapper = styled.div`
    margin-right: 15px;
    cursor: pointer;
`

const CustomLink = styled.a`
    margin-right: 15px;
`

const DownloadButton = styled.div`
  cursor: pointer;
  margin-right: 10px;
` 

export {
  Wrapper, 
  Tab, 
  TabsWrapper, 
  PrintWrapper, 
  CustomLink, 
  DownloadButton
}