import styled from 'styled-components'


const DownloadButton = styled.div`
  margin-right: 15px;
` 

const CustomLink = styled.a`
  margin-right: 15px;
` 

const DownloadWrapper = styled.div`
  cursor: pointer;
` 

export {
  DownloadButton,
  CustomLink,
  DownloadWrapper
}