/**
 * Fuellocationconfigform scene utils
 *
 * @author João Dias <jdias@ubiwhere.com>
 *
 *
 */

import { FormField } from "types/misc";
import * as Check from "validations";

export type FuelLocationForm = {
    name: FormField<string>;
    address: FormField<string>;
    city: FormField<string>;
    isMain: FormField<boolean>;
    isTank: FormField<boolean>;
    isActive: FormField<boolean>;
    fuelCapacity: FormField<string>;
}

export const defaultFuelLocationForm: FuelLocationForm = {
    name: {
        message: "",
        valid: true,
        value: ""
    },
    address: {
        message: "",
        valid: true,
        value: ""
    },
    city: {
        message: "",
        valid: true,
        value: ""
    },
    fuelCapacity: {
        message: "",
        valid: true,
        value: ""
    },
    isActive: {
        message: "",
        valid: true,
        value: true
    },
    isMain: {
        message: "",
        valid: true,
        value: false
    },
    isTank: {
        message: "",
        valid: true,
        value: false
    },
}

export const fuelLocationConfigValidations = {
    name: [Check.isRequired],
    address: [Check.isRequired],
    city: [Check.isRequired],
    fuelCapacity: [Check.hasValue],
    isActive: [Check.hasValue],
    isMain: [Check.hasValue],
    isTank: [Check.hasValue],
}
