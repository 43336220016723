/**
 * CustomerForm component
 *
 * @author João Flores <jflores@ubiwhere.com>
 *
 *
 */

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions, RootState } from 'store/rootSlices';
import { Link } from 'react-router-dom';
//Components
import { CCol } from '@coreui/react-pro';
import CustomCheckbox from 'shared/components/CustomCheckbox';
import Searchabledropdown from 'shared/components/Searchabledropdown'
import CustomButton from 'shared/components/CustomButton';
import CustomerInfo from './components/CustomerInfo';
// Utils
import { defaultCustomerForm } from '../../utils';
//Styles
import { CustomNewClientRow } from './styles'
import theme from 'ui/theme';

const CustomerForm = ({ t }) => {
  const {
    customer,
    showCustomerInfo,
    searchCustomerResults,
    hasEditPermission,
    bookingWithClient,
    edit,
    customers,
    isCustomersLastPage,
    currentCustomer
  } = useSelector((state: RootState) => state.BookingForm);

  const {
    onCustomerFormChange,
    setShowCustomerInfo,
    setSearchCustomerText,
    setBookingWithClient,
    fetchCustomers,
    setTripInfoForm,
    setSearchCustomerResults,
    populateInvoiceInfo
  } = actions.BookingForm;

  const dispatch = useDispatch();

  const _renderSearchDropdowRow = () => {
    return bookingWithClient && <>
      <CCol>
        <CustomButton
          disabled={!hasEditPermission}
          id="bookingForm-newCustomer-button"
          onClick={() => {
            if(showCustomerInfo) {
              dispatch(onCustomerFormChange({ ...customer, uuid: currentCustomer.uuid }))
            } else {
              dispatch(onCustomerFormChange(defaultCustomerForm))
            }
            dispatch(setShowCustomerInfo(!showCustomerInfo))
          }}
          text={t('tripBookings.newCustomer')}
          color="primary"
          variant="outline"
          buttonStyle={{ borderColor: theme().primary, minWidth: '175px', width: '100%' }}
        />
      </CCol>
      <CCol sm={6}>
        <Searchabledropdown 
          id="bookingForm-searchCustomer"
          placeholder={t('placeholders.customerSearch')}
          initialResults={customers}
          searchResults={searchCustomerResults}
          parseResults={r => { return `${r.name} ${r.nif ? '- ' + r.nif : ''}` }}
          disabled={!hasEditPermission}
          labelHelpText={
            edit ? (
              <Link
                style={{ fontSize: '14px', textDecoration: 'none' }}
                to={`/clientes/editar-cliente/${customer.uuid}`}
                target="_blank"
              >
                {t('tripBookings.clientDetails')}
              </Link>
            ) : (
              <></>
            )
          }
          isClearable
          label={t('common.client')}
          value={currentCustomer.uuid ? currentCustomer : ""}
          isLastPage={isCustomersLastPage}
          isSearchable
          onSearch={term => { 
            if(term !== '') dispatch(setSearchCustomerText(term))
          }}
          onSelectResult={(value) => {
            dispatch(setShowCustomerInfo(false))
            dispatch(onCustomerFormChange({ ...customer, uuid: value }))
            const allCustomers = customers.concat(searchCustomerResults)
            let selectedCustomer = allCustomers.find((cm) => cm.uuid === value)
            dispatch(setTripInfoForm(selectedCustomer))
            dispatch(populateInvoiceInfo(selectedCustomer))
          }}
          onClearResult={() => {
            dispatch(onCustomerFormChange({ ...customer, uuid: "" }))
            dispatch(setSearchCustomerResults([]))
          }}
          onScrollBottom={() => dispatch(fetchCustomers())}
        />
      </CCol>
    </>
  }

  const _renderCustomerInfo = () => {
    return showCustomerInfo && <CustomerInfo t={t}/>
  }

  return (
    <>
      <CustomNewClientRow>
        <CCol>
          <CustomCheckbox
            id="bookingForm-withClient"
            checked={bookingWithClient}
            onClick={(e) => {
              if(bookingWithClient) {
                dispatch(onCustomerFormChange(defaultCustomerForm))
              } else if(!bookingWithClient && currentCustomer.uuid) {
                dispatch(onCustomerFormChange({ ...customer, uuid: currentCustomer.uuid }))
              }
              dispatch(setBookingWithClient(!bookingWithClient));
              dispatch(setShowCustomerInfo(false))
            }}
            text={t('tripBookings.withClient')}
            disabled={!hasEditPermission}
          />
        </CCol>
        { _renderSearchDropdowRow() }
      </CustomNewClientRow>
      { _renderCustomerInfo() }
    </>
  );
};

export default CustomerForm;