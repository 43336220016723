import type { InitialStateProps } from "scenes/BookingForm/logic/initialState"

const ModalsReducers = {
  sendBookingTicket: (state: InitialStateProps, { payload }) => {},
  setConfirmedOverbooking: (state: InitialStateProps, { payload }) => {
    state.confirmedOverbooking = payload;
  },
  setShowOverbookingModal: (state: InitialStateProps, { payload }) => {
    state.showOverbookingModal = payload;
  },
  setConfirmedCancelation: (state, { payload }) => {
    state.confirmedCancelation = payload;
  },
}

export default ModalsReducers