/**
 * Maintenanceform scene utils
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */
import * as Check from 'validations'

export const MaintenanceFormValidationsUnfinished = {
    description: [Check.isRequired],
    vehicleId: [Check.isRequired],
    extraInfo: [Check.isRequired],
    isFinished: [Check.hasValue],
    report: [Check.hasValue],
    isPaid: [Check.hasValue],
}

export const MaintenanceFormValidationsIsFinished = {
    ...MaintenanceFormValidationsUnfinished,
    date: [Check.isRequired],
    supplier: [Check.isRequired],
    invoiceUid: [Check.isRequired],
    cost: [Check.isRequired],
}

export const defaultForm = {
    description: {
        value: "",
        valid: true,
        message: "",
    },
    vehicleId: {
        value: "",
        valid: true,
        message: "",
    },
    extraInfo: {
        value: "",
        valid: true,
        message: "",
    },
    isFinished: {
        value: false,
        valid: true,
        message: "",
    },
    date: {
        value: "",
        valid: true,
        message: "",
    },
    supplier: {
        value: "",
        valid: true,
        message: "",
    },
    invoiceUid: {
        value: "",
        valid: true,
        message: "",
    },
    cost: {
        value: "",
        valid: true,
        message: "",
    },
    report: {
        value: "",
        valid: true,
        message: "",
    },
    isPaid: {
        value: false,
        valid: true,
        message: "",
    }
}

export const defaultMaintenance = {
    cost: "",
    date: "",
    description: "",
    id: -1,
    invoiceUid: "",
    isFinished: false,
    isPaid: false,
    report: "",
    extraInfo: "",
    supplier: "",
    vehicle: {
      createdAt: "",
      id: -1,
      isActive: true,
      isAvailable: true,
      name: "",
      shortName: "",
      updatedAt: "",
      uuid: ""
    },
    createdAt: "",
    createdBy: {
        uuid: "",
        userType: "",
    },
    updatedAt: "",
    updatedBy: {
        uuid: "",
        userType: "",
    },
    documents: [],
    uuid: "",
  }