/**
 * Historylog scene utils
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */

export const parseChangedFields = (diff) => {

    let changedFields = [] as string[]
    let historyLog = [] as any[]

    if (diff?.valuesChanged !== undefined) {
        changedFields = Object.keys(diff.valuesChanged)
        changedFields?.forEach(f => {
            if (f) {
                //changed field example coming from backend: root['role'] -> this regex returns string 'role'
                const field = f.replace(/'/g, "").match(/\[(.*?)\]/)
                
                if (field) {
                    historyLog.push({
                        field: field[1],
                        oldValue: diff.valuesChanged[f]?.oldValue,
                        newValue: diff.valuesChanged[f]?.newValue
                    })
                }
            }


        })

    }

    return historyLog
}