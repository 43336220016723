import * as React from "react"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={props.width ? props.width : 18}
      height={props.height ? props.height : 18}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 9a3 3 0 100-6 3 3 0 100 6zm0-4.5C8.325 4.5 9 5.175 9 6s-.675 1.5-1.5 1.5S6 6.825 6 6s.675-1.5 1.5-1.5zm-4.5 9v-.488c0-.255.12-.495.308-.607A8.167 8.167 0 017.5 11.25c.022 0 .037 0 .06.008a5.164 5.164 0 01.442-1.485A5.507 5.507 0 007.5 9.75c-1.815 0-3.51.502-4.957 1.365-.66.39-1.043 1.125-1.043 1.897V15h6.945a5.35 5.35 0 01-.728-1.5H3zm12.52-1.953c.022.15.043.295.043.453 0 .158-.021.303-.043.453l-.002.02.854.757-.75 1.297-1.087-.367c-.24.203-.51.36-.81.473L13.5 15.75H12l-.225-1.117c-.3-.113-.57-.27-.81-.473l-1.087.367-.75-1.297.855-.758-.003-.02A3.116 3.116 0 019.938 12c0-.158.02-.303.042-.453l.003-.02-.855-.757.75-1.297 1.087.367c.24-.203.51-.36.81-.473L12 8.25h1.5l.225 1.117c.3.113.57.27.81.473l1.088-.367.75 1.297-.856.758.003.02zM11.25 12c0 .825.675 1.5 1.5 1.5s1.5-.675 1.5-1.5-.675-1.5-1.5-1.5-1.5.675-1.5 1.5z"
        fill={props.fill ? props.fill : "#EBEDEF"}
      />
    </svg>
  )
}

export default SvgComponent
