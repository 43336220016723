/**
 * Passengercontrol scene sagas
 *
 * @author ManuelGil <mgil@ubiwhere.com>
 *
 *
 */
//import { AnyAction } from 'redux'
import { call, putResolve, select, takeLatest } from 'redux-saga/effects';
import { RootState } from 'store/rootSlices';
import API from 'api';
import { toast } from 'react-toastify';
import i18n from 'i18next';
import { actions } from 'store/rootSlices';
import { AnyAction, PayloadAction } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';
import { PlanningsList } from 'types/trips/plannings'
import { NewPlanningValidation, SetNewCheckinSagaType, SetNewCheckLocationSagaType } from '../utils'; 
import * as Check from 'validations';


function* onMountSaga({ payload }: AnyAction) {
  try {
    yield putResolve(actions.PassengerControl.getURLParams(payload));
    yield putResolve(actions.PassengerControl.setLoading(true));

    yield getPlanningsSaga();
    yield getCheckinsSaga();
    yield getTotalCheckinsSaga();
  } catch (error) {
  } finally {
    yield putResolve(actions.PassengerControl.setLoading(false));
  }
}

function* getTotalCheckinsSaga() {
  const { startDate } = yield select((state: RootState) => state.PassengerControl);

  try {
    const checkinsCounter = yield call(
      API.Checkins.GetTotalCheckins,
      DateTime.fromJSDate(startDate).toISODate(),
      true
    );

    if (checkinsCounter) {
      yield putResolve(actions.PassengerControl.setCheckinsCounter(checkinsCounter));
    }
  } catch (error) {
    toast.error(i18n.t('toasts.totalCheckinsGetError'));
  }
}

function* getCheckinsSaga() {
  const { page, startDate, boardingFilter, stopFilter, searchTerm, sort } = yield select(
    (state: RootState) => state.PassengerControl
  );
  yield putResolve(actions.PassengerControl.setLoading(true));

  try {
    const checkins = yield call(
      API.Checkins.GetCheckins,
      page,
      DateTime.fromJSDate(startDate).toISODate(),
      sort,
      boardingFilter.value,
      stopFilter.value,
      searchTerm,
      true
    );

    if (checkins) {
      yield putResolve(actions.PassengerControl.setCheckins(checkins.results));
      yield putResolve(actions.PassengerControl.setTotalCheckins(checkins.count));
    }
  } catch (error) {
    toast.error(i18n.t('toasts.checkinsGetError'));
  } finally {
    yield putResolve(actions.PassengerControl.setLoading(false));
  }
}

function* getURLParamsSaga({ payload }: AnyAction) {
  let params = new URLSearchParams(payload.location.search);

  const { searchTerm } = yield select((state: RootState) => state.PassengerControl);

  if (params.toString() !== '') {
    yield putResolve(actions.PassengerControl.setSearchTerm(params.get('search') ?? ''));
  } else {
    params.set('search', searchTerm);
    payload.replace({ search: params.toString() });
  }
}

function* onUnmountSaga({ payload }: AnyAction) {
  yield putResolve(actions.PassengerControl.setCheckins([]));
  yield putResolve(actions.PassengerControl.setTotalCheckins(0));
  yield putResolve(actions.PassengerControl.setLoading(true));
}

function* setNewCheckLocationSaga({ payload }: PayloadAction<SetNewCheckLocationSagaType>) {
  yield putResolve(actions.PassengerControl.setCheckingIn(true))

  const checkId = payload.id;
  if(!checkId) {
    yield putResolve(actions.PassengerControl.setCheckingIn(false))
    return toast.error(i18n.t('toasts.noCheckinError'))
  }
  if(payload.location === payload.checkinId) {
    yield putResolve(actions.PassengerControl.setCheckingIn(false))
    return toast.error(i18n.t('toasts.checkinNeededError')) 
  }
  
  let loadingToast
  let edited = 'none'
  try {
    const finalForm = {
      isOutbound: payload.outbound
    };

    if(payload.checkoutId) {
      finalForm['checkin'] = payload.location
      finalForm['checkout'] = null
      edited = 'checkin'
    } else if( payload.checkinId) {
      finalForm['checkout'] = payload.location
      edited = 'checkout'
    }
    
    loadingToast = toast.loading(i18n.t('toasts.editingCheckLocation'));
    
    const success = yield call(API.Checkins.EditCheckin, checkId, finalForm);
    if (success) {
      yield putResolve(actions.PassengerControl.fetchCheckins({isNewCheckin: false}))
      toast.update(loadingToast, {render: i18n.t('toasts.editCheckLocationSuccess', {check: edited }), type: "success", isLoading: false, closeButton: true, autoClose: 5000});
    }
  } catch (error) {
    if (loadingToast !== undefined) {
      toast.update(loadingToast, {render: i18n.t('toasts.editCheckLocationError'), type: "error", isLoading: false, closeButton: true, autoClose: 5000});
    } else {
      toast.error(i18n.t('toasts.editCheckLocationError'));
    }
  } finally {
    yield putResolve(actions.PassengerControl.setCheckingIn(false))
  }
}

function* getPlanningsSaga() {
  try {
    const {
      planningsPage,
      startDate,
      endDate,
      plannings
    } = yield select((state: RootState) => state.PassengerControl);

    const newPlannings: PlanningsList = yield call(API.Plannings.GetPlannings, planningsPage, false, startDate.toISOString().slice(0, 10), endDate.toISOString().slice(0, 10),)

    if (newPlannings && newPlannings.results.length > 0) {
      yield putResolve(actions.PassengerControl.setPlannings({plannings, newPlannings: newPlannings.results}))

      if(newPlannings.next) {
        yield putResolve(actions.PassengerControl.setPlanningsPage(planningsPage + 1))
      } else {
        yield putResolve(actions.PassengerControl.setIsPlanningsLastPage(true))
      }
    }

  } catch (error) {
    toast.error(i18n.t("toasts.planningsGetError"))
  } 
}

function* toggleTripModalSaga({ payload }: AnyAction) {
  try {
    const currentPlanning = yield call(API.Plannings.GetPlanning, payload.initialPlanningId)

    yield putResolve(actions.PassengerControl.setTripModal({
      ...payload,
      planning: currentPlanning
    }))
    

  } catch (error) {
    toast.error(i18n.t("toasts.getTripPlanningError"))
  } 
}

function* setNewCheckinSaga({ payload }: PayloadAction<SetNewCheckinSagaType>) {  
  yield putResolve(actions.PassengerControl.setCheckingIn(true))
  
  const { tripModal, newPlanningForm } = yield select((state: RootState) => state.PassengerControl);
  let loadingToast

  const newPlanningIsValid = Check.checkValidation(newPlanningForm, NewPlanningValidation)
  if(newPlanningIsValid.invalid) {
    yield putResolve(actions.PassengerControl.onPlanningFormChange(newPlanningIsValid.form))
    yield putResolve(actions.PassengerControl.setCheckingIn(false))
    toast.error(i18n.t('toasts.createCheckinError'));
    return false
  }

  if(!tripModal.lastCheckin.stopOut) {
    yield putResolve(actions.PassengerControl.setCheckingIn(false))
    toast.error(i18n.t('toasts.checkoutFirstError'));
    return false
  }

  try {
    
    const finalForm = {
      isOutbound: payload.outbound,
      checkin: payload.location,
      booking: tripModal.lastCheckin.booking.uuid,
      vehicle: tripModal.vehicle.uuid,
      trip: tripModal.planning.id
    };
    loadingToast = toast.loading(i18n.t(`toasts.creatingCheckin`))
    
    const success = yield call(API.Checkins.CreateCheckin, finalForm);
    if(success) {
      yield putResolve(actions.PassengerControl.fetchCheckins({isNewCheckin: true}))
      toast.update(loadingToast, {render: i18n.t(`toasts.createCheckinSuccess`), type: "success", isLoading: false, closeButton: true, autoClose: 5000});
    }
  } catch (error) {
    if (loadingToast !== undefined) {
      toast.update(loadingToast, {render: i18n.t(`toasts.createCheckinError`), type: "error", isLoading: false, closeButton: true, autoClose: 5000});
    } else {
      toast.error(i18n.t(`toasts.createCheckinError`)) 
    }
  } finally {
    yield putResolve(actions.PassengerControl.setCheckingIn(false))
  }
}

function* fetchCheckinsSaga({ payload }: AnyAction) {
  const { page, startDate, boardingFilter, stopFilter, searchTerm, sort, tripModal } = yield select(
    (state: RootState) => state.PassengerControl
  );

  try {
    const checkins = yield call(
      API.Checkins.GetCheckins,
      page,
      DateTime.fromJSDate(startDate).toISODate(),
      sort,
      boardingFilter.value,
      stopFilter.value,
      searchTerm,
      true
    );
    const checkinsCounter = yield call(
      API.Checkins.GetTotalCheckins,
      DateTime.fromJSDate(startDate).toISODate(),
      true
    );
    if (checkins && checkinsCounter) {
      yield putResolve(actions.PassengerControl.setCheckins(checkins.results));
      yield putResolve(actions.PassengerControl.setTotalCheckins(checkins.count));
      yield putResolve(actions.PassengerControl.setCheckinsCounter(checkinsCounter));

      if(tripModal.isVisible) {
        const newCheckinLocation = checkins.results.find((ch) => ch.booking.id === tripModal.lastCheckin.booking.id)
        let newTripModal = {
          ...tripModal,
          lastCheckin: newCheckinLocation,
        }
        if(payload.isNewCheckin) newTripModal.initialPlanningId = tripModal.planning.id
        yield putResolve(actions.PassengerControl.setTripModal(newTripModal))
      }
    }
  } catch (error) {
    toast.error(i18n.t('toasts.checkinsGetError'));
  }
}

export default function* watcherSignin() {
  yield takeLatest('Passengercontrol/onMount', onMountSaga);
  yield takeLatest('Passengercontrol/onUnmount', onUnmountSaga);
  yield takeLatest('Passengercontrol/setPage', getCheckinsSaga);
  yield takeLatest('Passengercontrol/getCheckins', getTotalCheckinsSaga);
  yield takeLatest('Passengercontrol/getCheckins', getCheckinsSaga);
  yield takeLatest('Passengercontrol/setSearchTerm', getCheckinsSaga);
  yield takeLatest('Passengercontrol/setSort', getCheckinsSaga);
  yield takeLatest('Passengercontrol/getURLParams', getURLParamsSaga);
  yield takeLatest('Passengercontrol/setNewCheckLocation', setNewCheckLocationSaga);
  yield takeLatest('Passengercontrol/getPlannings', getPlanningsSaga);
  yield takeLatest('Passengercontrol/toggleTripModal', toggleTripModalSaga);
  yield takeLatest('Passengercontrol/setNewCheckin', setNewCheckinSaga);
  yield takeLatest('Passengercontrol/fetchCheckins', fetchCheckinsSaga);
}
