import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { CNavGroup, CNavItem, CNavTitle, CBadge } from '@coreui/react-pro'

import DashboardIcon from 'assets/icons/dashboard';
import People from 'assets/icons/people';
import ManageAccounts from 'assets/icons/manage_accounts';
import BusinessCenter from 'assets/icons/business_center';
import DirectionsBoat from 'assets/icons/directions_boat';
import Euro from 'assets/icons/euro';
import Map from 'assets/icons/map';
import PassengerControl from 'assets/icons/passenger_control';
import Settings from 'assets/icons/settings';
import config from 'config'
import styled from 'styled-components'

//import Beach from 'assets/icons/beach';
//import SupportAgent from 'assets/icons/support_agent';

import i18n from 'i18next';
import { hasPermission } from 'permissions';
import { useSelector } from 'react-redux';
import { RootState } from 'store/rootSlices';
const { PERMISSIONS } = config

const CustomNavItem = styled(CNavItem)`
    font-size: .97rem;
`

export const navigation = [
    {
        component: CNavItem,
        name: 'sidebar.dashboard',
        to: '/dashboard',
        icon: <DashboardIcon viewBox="0 0 18 15" className="nav-icon" />,
        permission: ['']
    },
    {
        component: CNavTitle,
        name: 'sidebar.management',
        permission: [PERMISSIONS.USERS.LIST_TEAM_MEMBER,
        PERMISSIONS.USERS.LIST_CUSTOMER,
        PERMISSIONS.USERS.LIST_PARTNER,
        PERMISSIONS.FLEET.LIST_VEHICLE,
        PERMISSIONS.FLEET.VIEW_FUEL_STATION,
        PERMISSIONS.FLEET.LIST_MAINTENANCE,
        PERMISSIONS.FLEET.LIST_FUEL,
        PERMISSIONS.INVOICING.LIST_INVOICE]
    },
    {
        component: CNavGroup,
        name: 'sidebar.collaborators',
        to: '/colaboradores',
        icon: <ManageAccounts viewBox="0 0 18 15" className="nav-icon" />,
        items: [
            /*             {
                            component: CNavItem,
                            name: 'sidebar.schedules',
                            to: '/colaboradores/horarios',
                            permission: [PERMISSIONS.USERS.VIEW_TEAM_MEMBER]
                        }, */
            {
                component: CNavItem,
                name: 'sidebar.timeRegister',
                to: '/colaboradores/registo-horas',
                permission: ['']
            },
            {
                component: CNavItem,
                name: 'sidebar.users',
                to: '/colaboradores/utilizadores',
                permission: [PERMISSIONS.USERS.LIST_TEAM_MEMBER]
            }
        ],
        permission: [PERMISSIONS.USERS.LIST_TEAM_MEMBER]
    },
    {
        component: CNavItem,
        name: 'sidebar.clients',
        to: '/clientes',
        icon: <People viewBox="0 0 18 15" className="nav-icon" />,
        permission: [PERMISSIONS.USERS.LIST_CUSTOMER]
    },
    {
        component: CNavGroup,
        name: 'sidebar.fleet',
        to: '/frota',
        icon: <DirectionsBoat viewBox="0 0 18 15" className="nav-icon" />,
        items: [
            {
                component: CNavItem,
                name: 'sidebar.vehicles',
                to: '/frota/veiculos',
                permission: [PERMISSIONS.FLEET.LIST_VEHICLE]
            },
            {
                component: CNavItem,
                name: 'sidebar.maintenance',
                to: '/frota/manutencoes',
                permission: [PERMISSIONS.FLEET.LIST_MAINTENANCE]
            },
            {
                component: CNavItem,
                name: 'sidebar.fuel',
                to: '/frota/combustivel',
                permission: [PERMISSIONS.FLEET.LIST_FUEL]
            },
            {
                component: CNavItem,
                name: 'sidebar.fleetConfiguration',
                to: '/frota/configuracoes',
                permission: [PERMISSIONS.FLEET.EDIT_FLEET_SETTINGS]
            }
        ],
        permission: [PERMISSIONS.FLEET.LIST_VEHICLE,
        PERMISSIONS.FLEET.EDIT_FLEET_SETTINGS,
        PERMISSIONS.FLEET.LIST_MAINTENANCE,
        PERMISSIONS.FLEET.LIST_FUEL]
    },
    {
        component: CNavItem,
        name: 'sidebar.entities',
        to: '/entidades',
        icon: <BusinessCenter viewBox="0 0 18 16" className="nav-icon" />,
        permission: [PERMISSIONS.USERS.LIST_PARTNER]
    },
    {
        component: CNavGroup,
        name: 'sidebar.invoicing',
        to: '/faturacao',
        icon: <Euro viewBox="0 0 18 17" className="nav-icon" />,
        items: [
            {
                component: CNavItem,
                name: 'sidebar.cashManagement',
                to: '/faturacao/gestao-caixa',
                permission: [PERMISSIONS.INVOICING.CASH_MANAGEMENT.LIST_STATEMENT]
            },
            {
                component: CNavItem,
                name: 'sidebar.invoicingSummary',
                to: '/faturacao/resumo',
                permission: [PERMISSIONS.INVOICING.LIST_INVOICE]
            },
            {
                component: CNavItem,
                name: 'sidebar.invoicing',
                to: '/faturacao/pedidos',
                permission: [PERMISSIONS.INVOICING.LIST_INVOICE]
                /* badge: {
                    color: 'danger',
                    text: '8',
                }, */
            },
        ],
        permission: [PERMISSIONS.INVOICING.LIST_INVOICE]
    },
    {
        component: CNavTitle,
        name: 'sidebar.planning',
        permission: [PERMISSIONS.TRIPS.PLANNINGS.LIST_PLANNING,
        PERMISSIONS.TRIPS.BOOKINGS.LIST_BOOKING,
        PERMISSIONS.TRIPS.EXTRAS.LIST_EXTRA,
        PERMISSIONS.TRIPS.CAMPAIGNS.LIST_CAMPAIGN,
        PERMISSIONS.TRIPS.CIRCUITS.LIST_CIRCUIT,
        PERMISSIONS.TRIPS.SERVICES.LIST_SERVICE,
        PERMISSIONS.TRIPS.BOOKINGS.LIST_BOOKING_CHECKINS]
    },
    {
        component: CNavGroup,
        name: 'sidebar.trips',
        to: '/viagens',
        icon: <Map viewBox="0 0 15 15" className="nav-icon" />,
        items: [
            {
                component: CNavItem,
                name: 'sidebar.appointments',
                to: '/viagens/agendamentos',
                permission: [PERMISSIONS.TRIPS.PLANNINGS.LIST_PLANNING]
            },
            {
                component: CNavItem,
                name: 'sidebar.reservations',
                to: '/viagens/reservas',
                permission: [PERMISSIONS.TRIPS.BOOKINGS.LIST_BOOKING]
            },
            /*             {
                            component: CNavItem,
                            name: 'sidebar.events',
                            to: '/viagens/eventos',
                            permission:['']
                        },
                        {
                            component: CNavItem,
                            name: 'sidebar.rentals',
                            to: '/viagens/alugueres',
                            permission:['']
                        }, */
            {
                component: CustomNavItem,
                name: 'sidebar.tripSettings',
                to: '/viagens/configuracoes',
                permission: [PERMISSIONS.TRIPS.EXTRAS.LIST_EXTRA,
                PERMISSIONS.TRIPS.CAMPAIGNS.LIST_CAMPAIGN,
                PERMISSIONS.TRIPS.CIRCUITS.LIST_CIRCUIT,
                PERMISSIONS.TRIPS.SERVICES.LIST_SERVICE]
            },
        ],
        permission: [PERMISSIONS.TRIPS.PLANNINGS.LIST_PLANNING,
        PERMISSIONS.TRIPS.BOOKINGS.LIST_BOOKING,
        PERMISSIONS.TRIPS.EXTRAS.LIST_EXTRA,
        PERMISSIONS.TRIPS.CAMPAIGNS.LIST_CAMPAIGN,
        PERMISSIONS.TRIPS.CIRCUITS.LIST_CIRCUIT,
        PERMISSIONS.TRIPS.SERVICES.LIST_SERVICE]
    },
    /*     {
            component: CNavItem,
            name: 'sidebar.beach',
            to: '/praia',
            icon: <Beach viewBox="0 0 18 15" className="nav-icon" />,
            permission: ['']
        }, */
    {
        component: CNavItem,
        name: 'sidebar.passengerControl',
        to: '/controlo-passageiros',
        icon: <PassengerControl viewBox="0 0 18 15" className="nav-icon" />,
        permission: [PERMISSIONS.TRIPS.BOOKINGS.EDIT_BOOKING_CHECKINS]
    },
    /*     {
            component: CNavTitle,
            name: 'sidebar.customerSupport',
            permission:['']
        }, */
    /*     {
            component: CNavItem,
            name: 'sidebar.messages',
            to: '/mensagens',
            icon: <SupportAgent viewBox="0 0 18 15" className="nav-icon" />,
            permission: ['']
        }, */
    {
        component: CNavTitle,
        name: 'sidebar.general',
        permission: [PERMISSIONS.SETTINGS.VIEW_SETTING]
    },
    {
        component: CNavItem,
        name: 'sidebar.bi',
        to: '/inteligencia-negocio',
        icon: <Settings viewBox="0 0 18 17" className="nav-icon" />,
        permission: [PERMISSIONS.SETTINGS.VIEW_SETTING]
    },
    {
        component: CNavItem,
        name: 'sidebar.settings',
        to: '/configuracoes',
        icon: <Settings viewBox="0 0 18 17" className="nav-icon" />,
        permission: [PERMISSIONS.SETTINGS.VIEW_SETTING]
    },
]

export const AppSidebarNav = ({ items, onClick }) => {

    const location = useLocation()
    const { permissions } = useSelector((state: RootState) => state.App);

    const navLink = (name, icon, badge?) => {
        return (
            <>
                {icon && icon}
                {name && i18n.t(name)}
                {badge && (
                    <CBadge color={badge.color} className="ms-auto">
                        {badge.text}
                    </CBadge>
                )}
            </>
        )
    }

    const navItem = (item, index) => {
        const { component, name, badge, icon, permission, ...rest } = item
        const Component = component

        let perms = false

        perms = permission.map(p => {
            if (hasPermission(permissions, p)) return true
            return false
        }).reduce((prev, current) => prev || current, false)

        return (
            (perms || permission[0] === '') && <Component
                id={`sidebar-${name}`}
                {...(rest.to && !rest.items && { component: NavLink, activeClassName: 'active' })}
                key={index}
                {...rest}
            >
                {navLink(name, icon, badge)}
            </Component>
        )
    }
    const navGroup = (item, index) => {
        const { component, name, icon, to, items, permission, ...rest } = item
        const Component = component

        let groupPerm = false
        groupPerm = permission.map(p => {
            if (hasPermission(permissions, p)) return true
            return false
        }).reduce((prev, current) => prev || current, false)


        return (
            (groupPerm || permission[0] === '') && <Component
                id={`sidebar-menu-${name}`}
                idx={String(index)}
                key={index}
                toggler={navLink(name, icon)}
                visible={location.pathname.startsWith(to)}
                {...rest}
            >
                {item.items?.map((item, index) =>
                    item.items ? navGroup(item, index) : navItem(item, index),
                )}
            </Component>
        )
    }

    return (
        <div onClick={onClick}>
            {items &&
                items.map((item, index) => (item.items ? navGroup(item, index) : navItem(item, index)))}
        </div>
    )
}