/**
 * Clockqrreader scene sagas
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */
import API from 'api'
import { toast } from 'react-toastify'
import { call, putResolve, takeLatest, select } from 'redux-saga/effects'
import { actions, RootState } from 'store/rootSlices'
import i18n from 'i18next'
import { AnyAction } from '@reduxjs/toolkit'

function* onMountSaga() { }

function* onUnmountSaga() { }

function* ExecuteClockInSaga() {
  const { token, uuid } = yield select((state: RootState) => state.ClockQrReader)

  try {
    const finalForm = {
      teamMember: uuid,
      token: token
    }

    const response = yield call(API.Collaborators.CreateUserClockIn, finalForm)

    if (response) {
      yield putResolve(actions.App.navigateTo('/login'))
      toast.success(i18n.t("toasts.successCreateClockIn"))
    }
  } catch (error) {
    yield putResolve(actions.ClockQrReader.setHasData(false))
    toast.error(i18n.t("toasts.errorCreateClockIn"))
  }
}

function* ExecuteclockOutSaga() {
  const { token, uuid } = yield select((state: RootState) => state.ClockQrReader)

  try {
    const finalForm = {
      teamMember: uuid,
      token: token
    }

    const response = yield call(API.Collaborators.CreateUserClockOut, finalForm)

    if (response) {
      yield putResolve(actions.App.navigateTo('/login'))
      toast.success(i18n.t("toasts.successCreateClockOut"))
    }
  } catch (error) {
    yield putResolve(actions.ClockQrReader.setHasData(false))
    toast.error(i18n.t("toasts.errorCreateClockOut"))
  }
}

function* getClockStatusSaga({ payload }: AnyAction) {

  const QrCodeInfo = payload?.replaceAll('"', '').split(',')

  if (QrCodeInfo) {
    yield putResolve(actions.ClockQrReader.setToken(QrCodeInfo[0]))
    yield putResolve(actions.ClockQrReader.setUserUuid(QrCodeInfo[1]))
    try {
      const status = yield call(API.Collaborators.GetCollaboratorPublicInfo, QrCodeInfo[1])
      if (status) {
        yield putResolve(actions.ClockQrReader.setHasData(true))
        yield putResolve(actions.ClockQrReader.setUserInfo(status))

        if (status.lastClocked !== null) {
          yield putResolve(actions.ClockQrReader.setWorkedHours(status.lastClocked?.clockIn))
        }
      }
    } catch (error) {
      yield putResolve(actions.ClockQrReader.setHasData(false))
      toast.error(i18n.t("toasts.errorGetUserInfo"))
    }
  }
}

export default function* watcherSignin() {
  yield takeLatest('Clockqrreader/onMount', onMountSaga)
  yield takeLatest('Clockqrreader/onUnmount', onUnmountSaga)
  yield takeLatest('Clockqrreader/getClockStatus', getClockStatusSaga)
  yield takeLatest('Clockqrreader/executeClockIn', ExecuteClockInSaga)
  yield takeLatest('Clockqrreader/executeClockOut', ExecuteclockOutSaga)
}


