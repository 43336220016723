/**
 * Fleetmaintenance scene
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { CBadge } from '@coreui/react-pro'
// Styles
import {
  Wrapper,
  ControlsContainer,
  Controls1,
  Controls2,
} from 'shared/genericStyledComponents'
// Store
import { actions, RootState } from 'store/rootSlices'
// Componentes
import Maintenancelisttablerow from './components/Maintenancelisttablerow'
import TabsContainer from 'shared/components/Tabscontainer'
import Maintenancelisttablerowcomplete from './components/Maintenancelisttablerowcomplete'
import Loadingcontainer from 'shared/components/Loadingcontainer'
import SearchBox from 'shared/components/SearchBox'
import FilterComponent from 'shared/components/FilterComponent'
import CustomButton from 'shared/components/CustomButton'
import TableContainer from 'shared/components/TableContainer'
import SorterComponent from 'shared/components/SorterComponent'
// Icons
import Feed from 'assets/icons/feed';
import Map from 'assets/icons/map'
import DirectionsBoat from 'assets/icons/directions_boat'
// Types
import { MaintenanceListSingle } from 'types/fleet/maintenance'
// Utils & Configs
import config from 'config'
import { paginationCurrentPage, paginationElementsCount } from 'utils'
import { hasPermission } from 'permissions'


const FleetMaintenance = ({ t, i18n }) => {

  const {
    searchTerm,
    sort,
    loading,
    requestedPage,
    finishedPage,
    filter,
    totalFinished,
    totalRequested,
    maintenancesFinished,
    maintenancesRequested,
    tabName,
    vehicle,
  } = useSelector((state: RootState) => state.FleetMaintenance);

  const {
    vehicleTypes,
    fleetLongList
  } = useSelector((state: RootState) => state.FleetManagement);

  const {
    onMount,
    onUnmount,
    setSearchTerm,
    setSort,
    setRequestedPage,
    setFinishedPage,
    setFilter,
    setTab,
    fetchFinishedMaintenances,
    fetchRequestedMaintenances,
    setVehicle,
  } = actions.FleetMaintenance

  const { permissions } = useSelector((state: RootState) => state.App)
  const { navigateTo } = actions.App
  const { ELEMENTS_PER_PAGE, PERMISSIONS } = config
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    dispatch(onMount(history))
    return () => {
      dispatch(onUnmount())
    }
  }, [dispatch, onMount, onUnmount, searchTerm, history]);

  const Requested = () => (
    <TableContainer
      count={paginationElementsCount(totalRequested)}
      currentPage={paginationCurrentPage(totalRequested, requestedPage)}
      elementsPerPage={ELEMENTS_PER_PAGE}
      onPageChange={pg => {
        dispatch(setRequestedPage(pg));
        dispatch(fetchRequestedMaintenances())
      }}
      headerElements={[
        <SorterComponent sort={sort} active={sort.field === "vehicle"} id="maintenances-requested-vehicleSorter" filterName={t('maintenances.vehicle')} onChange={(dir) => { dispatch(setSort({ field: "vehicle", direction: dir })) }} />,
        t('maintenances.description'),
        <SorterComponent sort={sort} active={sort.field === "supplier"} id="maintenances-requested-supplierSorter" filterName={t('maintenances.supplier')} onChange={(dir) => { dispatch(setSort({ field: "supplier", direction: dir })) }} />,
        <SorterComponent sort={sort} active={sort.field === "created_at"} id="maintenances-requested-dateSorter" filterName={t('maintenances.requestDate')} onChange={(dir) => { dispatch(setSort({ field: "created_at", direction: dir })) }} />,
        t('common.actions')
      ]}
    >
      {
        maintenancesRequested.length > 0 && maintenancesRequested.map((el: MaintenanceListSingle, idx: number) =>
          <React.Fragment key={`maintenance-requested-list-${idx}`}>
            <Maintenancelisttablerow
              id={`maintenances-requestedTableRow-${idx}`}
              permission={hasPermission(permissions, PERMISSIONS.FLEET.VIEW_MAINTENANCE)}
              onClick={() => {
                if (hasPermission(permissions, PERMISSIONS.FLEET.VIEW_MAINTENANCE)) {
                  dispatch(actions.App.navigateTo(`/frota/manutencoes/editar-manutencao/${el.id}`))
                }
              }}
              maintenance={el}
              actions={
                <React.Fragment>
                  <Feed />
                </React.Fragment>
              }
            />
          </React.Fragment>
        )}
    </TableContainer>
  )

  const Finished = () => (
    <TableContainer
      count={paginationElementsCount(totalFinished)}
      currentPage={paginationCurrentPage(totalFinished, finishedPage)}
      elementsPerPage={ELEMENTS_PER_PAGE}
      onPageChange={(pg) => { dispatch(setFinishedPage(pg)); dispatch(fetchFinishedMaintenances()) }}
      headerElements={[
        <SorterComponent sort={sort} active={sort.field === "vehicle"} id="maintenances-finished-vehicleSorter" filterName={t('maintenances.vehicle')} onChange={(dir) => { dispatch(setSort({ field: "vehicle", direction: dir })) }} />,
        t('maintenances.report'),
        <SorterComponent sort={sort} active={sort.field === "supplier"} id="maintenances-finished-supplierSorter" filterName={t('maintenances.supplier')} onChange={(dir) => { dispatch(setSort({ field: "supplier", direction: dir })) }} />,
        <SorterComponent sort={sort} active={sort.field === "date"} id="maintenances-finished-dateSorter" filterName={t('maintenances.conclusionDate')} onChange={(dir) => { dispatch(setSort({ field: "date", direction: dir })) }} />,
        <SorterComponent sort={sort} active={sort.field === "invoice"} id="maintenances-finished-invoiceSorter" filterName={t('maintenances.invoiceNr')} onChange={(dir) => { dispatch(setSort({ field: "invoice", direction: dir })) }} />,
        t('maintenances.cost'),
        t('common.actions')
      ]}
    >
      {maintenancesFinished.length > 0 && maintenancesFinished.map((el: MaintenanceListSingle, idx: number) =>

        <React.Fragment key={`maintenance-finished-list-${idx}`}>
          <Maintenancelisttablerowcomplete
            id={`maintenances-finished-tableRow-${idx}`}
            permission={hasPermission(permissions, PERMISSIONS.FLEET.VIEW_MAINTENANCE)}
            onClick={() => {
              if (hasPermission(permissions, PERMISSIONS.FLEET.VIEW_MAINTENANCE)) {
                dispatch(actions.App.navigateTo(`/frota/manutencoes/editar-manutencao/${el.id}`))
              }
            }}
            maintenance={el}
            actions={
              <React.Fragment>
                <Feed />
              </React.Fragment>
            }
          />
        </React.Fragment>
      )}
    </TableContainer>
  )

  const content = [
    {
      title: t('maintenances.requested'),
      badge: <CBadge color="warning">{maintenancesRequested.length}</CBadge>,
      content: <Requested key="requested-table" />,
    }, {
      title: t('maintenances.finished'),
      badge: <CBadge color="info">{maintenancesFinished.length}</CBadge>,
      content: <Finished key="finished-table" />
    },
  ]

  return (
    <Wrapper>
      <ControlsContainer>
        <Controls1>
          <SearchBox
            id="maintenances-searchBox"
            defaultValue={searchTerm}
            onSubmit={(term) => {
              let params = new URLSearchParams(history.location.search);
              term !== "" ? params.set('search', term) : params.delete('search')
              history.replace({ search: (params).toString() })
              dispatch(setSearchTerm(term))
            }}
          />

          <FilterComponent
            id="maintenances-vehicleTypeFilter"
            defaultOption={
              {
                label: vehicleTypes.find(ct => ct.id.toString() === filter)?.name as string,
                value: filter
              }
            }
            options={vehicleTypes.map(ct => {
              return {
                label: ct.name,
                value: ct.id.toString()
              }
            })}
            icon={<Map />}
            onChange={(e) => {
              let params = new URLSearchParams(history.location.search);
              e.value !== "" ? params.set('vehicleType', e.value) : params.delete('vehicleType')
              history.replace({ search: (params).toString() })
              dispatch(setFilter(e.value))
            }}
            filterName={t('maintenances.vehicleType')} />

          <FilterComponent
            id="maintenances-vehicleFilter"
            defaultOption={
              {
                label: fleetLongList.find(ct => ct.id.toString() === vehicle)?.name as string,
                value: vehicle ? vehicle : ""
              }
            }
            options={fleetLongList.map(ct => {
              return {
                label: ct.name,
                value: ct.id.toString()
              }
            })}
            icon={<DirectionsBoat />}
            onChange={(e) => {
              let params = new URLSearchParams(history.location.search);
              e.value !== "" ? params.set('vehicle', e.value) : params.delete('vehicle')
              history.replace({ search: (params).toString() })
              dispatch(setVehicle(e.value))
            }}
            filterName={t('maintenances.vehicle')} />
        </Controls1>
        <Controls2>
          {
            hasPermission(permissions, PERMISSIONS.FLEET.CREATE_MAINTENANCE) && <CustomButton id="maintenances-newButton" onClick={() => dispatch(navigateTo('/frota/manutencoes/nova-manutencao'))} text={t('common.new')} icon="add" color="success" buttonStyle={{ color: "#FFFFFF" }} />
          }
        </Controls2>
      </ControlsContainer>
      {
        loading ?
          <Loadingcontainer />
          :
          <TabsContainer id="maintenances-tabsContainer" defaultTab={tabName === i18n.t('maintenances.finished') ? 1 : 0} content={content} onChangeTab={tab => { dispatch(setTab(tab)) }} />
      }
    </Wrapper>
  )
}

export default FleetMaintenance