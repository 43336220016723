import { PayloadAction } from '@reduxjs/toolkit'
import { SetNewCheckLocationProps, SetNewCheckinProps } from 'types/trips/plannings'
import type { InitialStateProps} from '../../../logic/initialState';


const CheckinsModalReducers = {
  setShowCheckinElegibles: (state: InitialStateProps,  { payload }) => {
    state.showCheckinModal = {
      ...state.showCheckinModal,
      showElegibles: payload
    }
  },
  setNewCheckLocation: (state, { payload }: PayloadAction<SetNewCheckLocationProps>) => {},
  setNewCheckin: (state, { payload }: PayloadAction<SetNewCheckinProps>) => {},
  setCheckinSearch: (state: InitialStateProps, { payload }) => {
    state.checkinSearch = payload
  },
  setCheckinModalUnit: (state: InitialStateProps, { payload }) => {
    state.showCheckinModal = {
      ...state.showCheckinModal,
      ...payload
    }
  },
}

export default CheckinsModalReducers