import styled from 'styled-components';
import { CRow } from '@coreui/react-pro';

const CustomNewClientRow = styled(CRow)`
  row-gap: 12px;
  align-items: end;
  margin-top: 20px;
`;

export {
  CustomNewClientRow
}