import type { InitialStateProps } from "../../../logic/initialState"
import { PlanningStopsForm, PlanningVehiclesForm } from 'types/trips/plannings'


const VehiclesReducers = {
  onVehicleLotationChange: (state: InitialStateProps, { payload }) => {
    const vehicles: PlanningVehiclesForm[] = Array.from(payload.vehicles)

    vehicles[payload.idx] = {
      ...vehicles[payload.idx],
      capacity: { value: payload.capacity, valid: true, message: "" }
    }

    state.vehicles = vehicles
  },
  onVehicleSelect: (state: InitialStateProps, { payload }) => {
    const vehicles: PlanningVehiclesForm[] = Array.from(payload.vehicles)

    vehicles[payload.idx] = {
      ...vehicles[payload.idx],
      uuid: { value: payload.vehicle ? payload.vehicle.uuid : "", valid: true, message: "" },
      name: { value: payload.vehicle ? payload.vehicle.name : "", valid: true, message: "" },
      capacity: { value: payload.vehicle ? payload.vehicle.capacity : "", valid: true, message: "" }
    }

    const outS: PlanningStopsForm[] = payload.outboundStops.map((st) => {

      st = {
        ...st,
        stop: {
          ...st.stop,
          details: vehicles.map((v, idx) => {
            if (st.stop.details.find(d => d.vehicle?.uuid === v.uuid.value)) {
              return st.stop.details.find(d => d.vehicle.uuid === v.uuid.value)
            }
            else {
              return {
                vehicle: {
                  uuid: v.uuid.value,
                  name: v.name.value
                },
                activities: payload.services.map((serv) => {
                  return {
                    id: serv.serviceDetails?.id.value,
                    shortName: serv.serviceDetails?.shortName,
                    isOn: { value: false, valid: true, message: "" }
                  }
                })
              }
            }
          })
        }
      }

      return st
    })

    const inS: PlanningStopsForm[] = payload.inboundStops.map((st) => {

      st = {
        ...st,
        stop: {
          ...st.stop,
          details: vehicles.map((v, idx) => {
            if (st.stop.details.find(d => d.vehicle?.uuid === v.uuid)) {
              return st.stop.details.find(d => d.vehicle.uuid === v.uuid.value)
            }
            else {
              return {
                ...st.stop.details[idx],
                vehicle: {
                  uuid: v.uuid.value,
                  name: v.name.value
                },
                activities: payload.services.map((serv) => {
                  return {
                    id: serv.serviceDetails?.id.value,
                    shortName: serv.serviceDetails?.shortName,
                    isOn: { value: false, valid: true, message: "" }
                  }
                })
              }
            }
          })
        }
      }
      return st
    })

    if (vehicles.map(v => Number(v.capacity.value)).reduce((a, b) => a + b, 0) > Number(payload.maxCapacity)) {
      state.newVehicleAdded = true
      state.showShouldUpdateTripCapacityModal = true
    }

    state.outboundStops = outS
    state.inboundStops = inS
    state.vehicles = vehicles
    state.selectedVehicle = vehicles[0].uuid.value
    state.updatedStops = true
    //if changed first vehicle, stops will be updated
  },
  onDeleteVehicle: (state: InitialStateProps, { payload }) => {
    //fake removal - just to check new max capacity
    const fake: PlanningVehiclesForm[] = Array.from(payload.vehicles)
    fake.splice(fake.indexOf(fake[payload.vehiclesArrayIndex]), 1)

    const vehiclesCapacity = fake.map(v => Number(v.capacity.value)).reduce((a, b) => a + b, 0)
    const tripCapacity = Number(payload.maxCapacity)

    if (tripCapacity > vehiclesCapacity) {
      state.removeVehicleID = payload.vehiclesArrayIndex
      state.showConfirmRemoveVehicleModal = true
    }
    else {
      const items: PlanningVehiclesForm[] = Array.from(payload.vehicles)
      items.splice(items.indexOf(items[payload.vehiclesArrayIndex]), 1)

      if (items.length === 0) {
        items.push({
          uuid: { value: "", valid: true, message: "" },
          name: { value: "", valid: true, message: "" },
          capacity: { value: "", valid: true, message: "" }
        })
      }

      state.vehicles = items
    }
  },
  confirmRemoveVehicleModal: (state: InitialStateProps, { payload }) => {
    state.showShouldUpdateTripCapacityModal = true
    state.showConfirmRemoveVehicleModal = false
  },
  updateTripCapacity: (state: InitialStateProps, { payload }) => {
    if (payload.newVehicleAdded === true) {
      const vehiclesCapacity = payload.vehicles.map(v => Number(v.capacity.value)).reduce((a, b) => a + b, 0)

      state.newVehicleAdded = false
      state.maxCapacity = { value: vehiclesCapacity.toString(), valid: true, message: "" }
    }
    else {

      const items: PlanningVehiclesForm[] = Array.from(payload.vehicles)
      items.splice(items.indexOf(items[payload.idx]), 1)

      const vehiclesCapacity = items.map(v => Number(v.capacity.value)).reduce((a, b) => a + b, 0)

      if (items.length === 0) {
        items.push({
          uuid: { value: "", valid: true, message: "" },
          name: { value: "", valid: true, message: "" },
          capacity: { value: "", valid: true, message: "" }
        })
      }

      state.removeVehicleID = ""
      state.vehicles = items
      state.maxCapacity = { value: vehiclesCapacity.toString(), valid: true, message: "" }
    }
  },
  closeConfirmRemoveVehicleModal: (state: InitialStateProps, { payload }) => {
    state.showConfirmRemoveVehicleModal = false
  },
  closeShouldUpdateTripCapacityModal: (state: InitialStateProps, { payload }) => {
    state.showShouldUpdateTripCapacityModal = false
  },
}

export default VehiclesReducers