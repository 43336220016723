/**
 * Dropdownexport component
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */

import React from 'react'
import styled from 'styled-components'
import { CDropdown, CDropdownToggle, CDropdownMenu, CDropdownItem } from '@coreui/react';
import theme from 'ui/theme';
import i18n from "i18next";

interface PropTypes {
    id?: string,
    wrapperStyle?: React.CSSProperties,
    disabled?: boolean,
    required?: boolean,
    dropdown?: boolean,
    options?: Array<Options>,
    label?: string,
    value?: string,
    onClick?: Function,
    placeholder?: string,
}


interface Options {
    value: string,
    prefix: string,
}

const DropdownExport = (props: PropTypes) => {

    return (
        <Wrapper style={props.wrapperStyle}>
            <CDropdown id={props.id} alignment={{ xs: 'end' }}>
                <DropdownToggle color="none">{i18n.t('common.exportBtn')}</DropdownToggle>
                <DropdownMenu>
                    {
                        props.options && props.options.map((opt, idx) => {
                            return <DropdownItem id={`${props.id}Option${idx}`} key={idx} value={opt.prefix} onClick={e => props.onClick ? props.onClick(opt.prefix) : null} > {opt.value} </DropdownItem>
                        })
                    }
                </DropdownMenu>
            </CDropdown>
        </Wrapper>
    )
}

export default DropdownExport

const Wrapper = styled.div`
  > * {
    font-family: 'Inter';
  }

  > label {
    font-size: 14px;
    color: ${theme().darkColor};
    > span {
      color: ${theme().primary};
    }
  }
  display:flex;
  justify-content:flex-end;
  margin-bottom:-30px;
`

const DropdownMenu = styled(CDropdownMenu) `
    padding: 0.1rem 0.1rem; 
    min-width:100%;
    text-align:end;
`
const DropdownItem = styled(CDropdownItem) `
    font-size:14px;
    &:active{
        background-color:${theme().gray600Color};
    }
`

const DropdownToggle = styled(CDropdownToggle) `
    background-color:${theme().bg};
    border-color:${theme().bg};
    color:${theme().gray600Color};
    font-size: 14px;
    &:hover{
        border-color: ${theme().gray200Color};
        background-color:${theme().bg};     
    };
    &.btn{
        &:focus{
        border-color: ${theme().gray200Color};
        background-color:${theme().gray200Color};     
        };
    };
`