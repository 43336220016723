import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { actions, RootState } from 'store/rootSlices'
import { DateTime } from "luxon"
import theme from 'ui/theme'
//configs
import config from 'config';
//styles
import { LegRow, IconTableCell, CustomLink } from '../../styles'
//icons
import ChevronRight from 'assets/icons/chevron_right';
//Components
import { CTableDataCell } from '@coreui/react-pro'
import Loadingcontainer from 'shared/components/Loadingcontainer'
import CustomRadioButton from 'shared/components/CustomRadioButton';
import Beach from 'assets/icons/beach'
import { ExtrasCounterWrapper } from './styles'

const { DATE_FORMAT } = config;

const BeachNewTableContent = () => {
  const {
    selectedBeachTrip,
    tableLoading,
    beachTrips,
  } = useSelector((state: RootState) => state.BookingForm);

  const { setNewBeachTrip } = actions.BookingForm;

  const dispatch = useDispatch();

  if (tableLoading) {
    return <CTableDataCell colSpan={8}><Loadingcontainer /></CTableDataCell>
  }

  return <>
    {
      beachTrips.map((trip, idx) => (
        <LegRow
          key={idx}
          id={`newBooking-beachTripRow-${trip.id}`}
          className={trip.id === selectedBeachTrip?.beachTripActivity?.id ? 'selected' : ''}
          onClick={e => { dispatch(setNewBeachTrip(trip)) }}
        >
          <CTableDataCell style={{ "padding": "1em" }}>
            <CustomRadioButton
              group="legs"
              value={trip.id}
              checked={trip.id === selectedBeachTrip?.beachTripActivity?.id}
            />
          </CTableDataCell>
          <IconTableCell>
            {
              <Beach fill={theme().gray500Color} />
            }
          </IconTableCell>
          <CTableDataCell>{trip.activity.name}</CTableDataCell>
          <CTableDataCell>{DateTime.fromISO(trip.date).toFormat(DATE_FORMAT.DATE)}</CTableDataCell>
          <CTableDataCell onClick={(e) => e.stopPropagation()}>
            <CustomLink
              to={`/viagens/agendamentos/editar-agendamento/${trip?.trip}`}
              target="_blank"
              rel="noopener"
            >
              {
                trip.departure.name
              }
              <ChevronRight className='pointer' fill={theme().gray700Color} />
            </CustomLink>
          </CTableDataCell>
          <CTableDataCell>

            {
              trip.availableExtras?.map(extra => {
                return (
                  <ExtrasCounterWrapper>
                    {extra.icon ? <img src={extra.icon} className='colorize-filter' style={{ width: '12px' }} alt={`extraIcon${extra.name}`} /> : null} <b>{(extra.occupied ?? 0)}</b>/<span className="smaller">{extra.maxCapacity}</span>
                  </ExtrasCounterWrapper>
                )
              })
            }
          </CTableDataCell>
        </LegRow>
      ))
    }
  </>
};

export default BeachNewTableContent