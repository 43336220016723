import * as React from "react"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width={16}
            height={12}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M5.75 7.313C3.995 7.313.5 8.19.5 9.938v1.312H11V9.937c0-1.747-3.495-2.624-5.25-2.624zM2.255 9.75c.63-.435 2.152-.938 3.495-.938s2.865.503 3.495.938h-6.99zM5.75 6a2.628 2.628 0 002.625-2.625A2.628 2.628 0 005.75.75a2.628 2.628 0 00-2.625 2.625A2.628 2.628 0 005.75 6zm0-3.75c.622 0 1.125.502 1.125 1.125S6.372 4.5 5.75 4.5a1.123 1.123 0 01-1.125-1.125c0-.623.503-1.125 1.125-1.125zm5.28 5.107c.87.63 1.47 1.47 1.47 2.58v1.313h3V9.937c0-1.514-2.625-2.377-4.47-2.58zM10.25 6a2.628 2.628 0 002.625-2.625A2.628 2.628 0 0010.25.75c-.405 0-.78.098-1.125.262a4.08 4.08 0 01.75 2.363 4.08 4.08 0 01-.75 2.362c.345.165.72.263 1.125.263z"
                fill={props.fill ? props.fill : "#EBEDEF"}
            />
        </svg>
    )
}

export default SvgComponent
