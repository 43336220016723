import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// Styles
import { actions, RootState } from 'store/rootSlices'
import { 
  Wrapper, 
  ControlsContainer,
} from 'shared/genericStyledComponents' 
import Loadingcontainer from 'shared/components/Loadingcontainer'
import TableContainer from 'shared/components/TableContainer'
import BusinessInteligenceRow from './components/BiRow'
import { paginationCurrentPage, paginationElementsCount } from 'utils'
import config from 'config'
import type { BiDashboard } from 'types/trips/bi'


const BusinessInteligence = ({ t, i18n }) => {

  const { loading, total, page, biList } = useSelector((state: RootState) => state.BusinessInteligence)
  const { onMount, onUnmount, setPage } = actions.BusinessInteligence
  const { ELEMENTS_PER_PAGE } = config

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(onMount())
    return () => {
      dispatch(onUnmount())
    }
  }, [dispatch, onMount, onUnmount]);

  if(loading) {
    return <Loadingcontainer />
  }

  return (
    <Wrapper>
      <ControlsContainer>
      </ControlsContainer>
      <TableContainer
        count={paginationElementsCount(total)}
        currentPage={paginationCurrentPage(total, page)}
        elementsPerPage={ELEMENTS_PER_PAGE}
        onPageChange={pg => { dispatch(setPage(pg)); }}
        headerElements={[
          t('fleet.name'),
          t('common.actions')
        ]}
      >
        {
          biList.map((bi: BiDashboard) => (
            <BusinessInteligenceRow
              key={`businessInteligenceRow-${bi.id}`}
              id={bi.id.toString()}
              permission={true}
              name={bi.name}
            />
          ))
        }
      </TableContainer>
    </Wrapper>
  )
}

export default BusinessInteligence