import React from 'react'
import i18n from 'i18next';
import { useDispatch, useSelector } from 'react-redux'
// Components
import BoatDropdown from 'shared/components/FormComponents/boatDropdown'
import CustomCheckbox from 'shared/components/CustomCheckbox'
// Icons
import Boat from 'assets/icons/boat_icon'
// Store
import { actions, RootState } from 'store/rootSlices';
// Types
import { Vehicles } from 'types/trips/plannings'
// Styles
import { CustomTableHeaderCell } from '../../styles'
import { BoatDropdownWrapper } from './styles'
import { CustomCTableHead } from '../styles'


type PropTypes = React.PropsWithChildren<{
  tripUnits: Vehicles[]
}>

const UnitsHeaderRow = ({ tripUnits }: PropTypes) => {
  const { showCheckinModal } = useSelector((state: RootState) => state.TripPlannings);
  const { 
    setCheckinModalUnit, 
    setShowCheckInModal,
  } = actions.TripPlannings
  const dispatch = useDispatch()

  return (
    <CustomCTableHead>
      <CustomTableHeaderCell colSpan={4}>
        {
          tripUnits.length > 0 && showCheckinModal.openBy !== 'generic' && (
            <BoatDropdownWrapper>
              <BoatDropdown
                  id="checkinModal-unitDropdown"
                  valid={true}
                  value={{ 
                    label: showCheckinModal.vehicle.vehicle.name, 
                    value: showCheckinModal.vehicle.vehicle.uuid, 
                    icon: <Boat fill={showCheckinModal.vehicle.vehicle.color} />
                  }}
                  onChange={e => {
                    let selectedUnit = tripUnits.find((unit) => unit.vehicle.uuid === e.value)
                    dispatch(setCheckinModalUnit({
                      vehicle: selectedUnit, 
                      stop: showCheckinModal.stop.id !== -1 ? showCheckinModal.stop : {id: 0, name: ""}
                    })) 
                  }}
                  options={tripUnits.map((unit: Vehicles) => ({ 
                    label: unit.vehicle.name, 
                    value: unit.vehicle.uuid, 
                    icon: <Boat fill={unit.vehicle.color} /> 
                  }))}
                  disabled={false}
              />
            </BoatDropdownWrapper>
          )
        }
        
      </CustomTableHeaderCell>

      <CustomTableHeaderCell colSpan={14} style={{marginLeft:"auto"}}>
        <CustomCheckbox wrapperStyle={{display: 'flex', justifyContent: 'flex-end', fontSize: '20px', flexGrow: 2 }}
          id='tripPlannings-isModalGeneric'
          checked={showCheckinModal.openBy === 'generic' ? true : false}
          onClick={e => {
            if(showCheckinModal.openBy === 'generic') {
              dispatch(setShowCheckInModal({ ...showCheckinModal, openBy: 'vehicle' }))
            } else {
              dispatch(setShowCheckInModal({ ...showCheckinModal, openBy: 'generic' }))
            }
          }}
          text={i18n.t('tripPlannings.showAllPlaces')}
          switch
        />
      </CustomTableHeaderCell>
    </CustomCTableHead>
  )
}

export default UnitsHeaderRow