import { CircuitForm, CircuitStopForm } from 'types/trips/circuits'
import * as Check from 'validations'

export const CircuitFormValidations = {
    duration: [],
    name: [Check.isRequired],
    isActive:[],
}

export const CircuitFormValidationsEdit = {
    duration: [],
    name: [Check.isRequired],
    isActive:[],
}

export const stopRowValidation = {
    time:[Check.isRequired],
    isActive:[],
}

export type SubmitSagaPayload = {
    circuitForm: CircuitForm,
    edit: boolean,
    inboundStops: CircuitStopForm[],
    outboundStops: CircuitStopForm[]
}