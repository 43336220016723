/**
 * Entitytablerow component
 *
 * @author João Dias <jdias@ubiwhere.com>
 *
 *
 */

import { CTableDataCell } from '@coreui/react-pro';
import React from 'react'
import { CustomTableRow, ActionsCell, ChevronCell } from 'shared/genericStyledComponents'
import { EntityMemberListItem } from 'types/entity/entity'
import ChevronRight from 'assets/icons/chevron_right';
import i18n from "i18next";
import { actions } from 'store/rootSlices'
import { useDispatch } from 'react-redux';

type PropTypes = {
  id?: string,
  entityMember: EntityMemberListItem,
  entityId: string,
  actions: JSX.Element,
  onClick: Function,
  permission: boolean
}

const EntityMembersTableRow = (props: PropTypes) => {

  const dispatch = useDispatch()
  const {
    entityMember: {
      name,
      email,
      isAdmin,
      id
    },
    entityId,
    permission
  } = props;


  return (
    <CustomTableRow>
      <CTableDataCell>{name}</CTableDataCell>
      <CTableDataCell>{isAdmin ? i18n.t('common.yes') : i18n.t('common.no')}</CTableDataCell>
      <CTableDataCell>-----</CTableDataCell>
      <CTableDataCell>{email}</CTableDataCell>
      <ActionsCell>
        {props.actions}
        <ChevronCell id={`${props.id}-chevron`} >
          {
            permission &&
            <a href={`/entidades/${entityId}/editar-membro/${id}`} onClick={(e) => {
              e.preventDefault()
              dispatch(actions.App.navigateTo(`/entidades/${entityId}/editar-membro/${id}`))
            }}>
              <ChevronRight></ChevronRight>
            </a>
          }
        </ChevronCell>
      </ActionsCell>
    </CustomTableRow>
  )
}

export default EntityMembersTableRow