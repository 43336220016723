/**
 * CollaboratorsManagement scene sagas
 *
 * @author João Dias <jdias@ubiwhere.com>
 *
 *
 */
import API from 'api'
import { toast } from 'react-toastify'
import { call, putResolve, takeLatest, select } from 'redux-saga/effects'
import { actions, RootState } from 'store/rootSlices'
import i18n from 'i18next'
import { AnyAction } from '@reduxjs/toolkit'
import { CollaboratorsList, CollaboratorTypesList, CollaboratorTeamsList } from 'types/collaborators/collaborator'

function* onMountSaga({ payload }: AnyAction) {
  yield getURLParamsSaga(payload);

  try {
    const { page, searchTerm, filter, groupFilter, sort } = yield select((state: RootState) => state.CollaboratorsManagement);
    const collaborators: CollaboratorsList = yield call(API.Collaborators.GetCollaborators, page, searchTerm, sort, filter, groupFilter)
    if (collaborators) {
      yield putResolve(actions.CollaboratorsManagement.setTotal(collaborators.count));
      yield putResolve(actions.CollaboratorsManagement.setCollaboratorsList(collaborators.results))
    }
  } catch (error) {
    toast.error(i18n.t("toasts.collaboratorsGetError"))
  } finally {
    yield putResolve(actions.CollaboratorsManagement.setLoading(false));
  }
}

function* fetchCollaboratorsSaga() {
  try {
    yield putResolve(actions.CollaboratorsManagement.setLoading(true));
    const { page, searchTerm, filter, groupFilter, sort } = yield select((state: RootState) => state.CollaboratorsManagement);
    const collaborators: CollaboratorsList = yield call(API.Collaborators.GetCollaborators, page, searchTerm, sort, filter, groupFilter);
    if (collaborators) {
      yield putResolve(actions.CollaboratorsManagement.setTotal(collaborators.count));
      yield putResolve(actions.CollaboratorsManagement.setCollaboratorsList(collaborators.results ?? []));
    }
  } catch (err) {
    toast.error(i18n.t("toasts.collaboratorsGetError"));
  } finally {
    yield putResolve(actions.CollaboratorsManagement.setLoading(false));
  }
}

function* getCollaboratorsListSaga() {
  try {
    const collaborators: CollaboratorsList = yield call(API.Collaborators.GetAllCollaborators)
    if (collaborators) {
      yield putResolve(actions.CollaboratorsManagement.setCollaboratorsLongList(collaborators))
    }
  } catch (error) {
    toast.error(i18n.t("toasts.collaboratorsGetError"))
  } finally {
    yield putResolve(actions.CollaboratorsManagement.setLoading(false));
  }
}

function* getCollaboratorTypesSaga() {
  try {
    const collaboratorTypes: CollaboratorTypesList = yield call(API.Collaborators.GetCollaboratorTypes)
    if (collaboratorTypes) {
      yield putResolve(actions.CollaboratorsManagement.setCollaboratorTypes(collaboratorTypes))
    }
  } catch (error) {
    toast.error(i18n.t("toasts.collaboratorTypesGetError"))
  } finally {
    yield putResolve(actions.CollaboratorsManagement.setLoading(false));
  }
}

function* getCollaboratorTeamsSaga() {
  try {
    const collaboratorTeams: CollaboratorTeamsList = yield call(API.Collaborators.GetTeams)

    if (collaboratorTeams) {
      yield putResolve(actions.CollaboratorsManagement.setTeams(collaboratorTeams))
    }
  } catch (error) {
    toast.error(i18n.t("toasts.collaboratorTeamsGetError"))
  } finally {
    yield putResolve(actions.CollaboratorsManagement.setLoading(false));
  }
}

function* onUnmountSaga() {
  yield putResolve(actions.CollaboratorsManagement.setLoading(true));
  yield putResolve(actions.CollaboratorsManagement.setCollaboratorsList([]));
}

function* getURLParamsSaga(payload: AnyAction) {

  let params = new URLSearchParams(payload.location.search)

  const {
    searchTerm,
    filter,
    groupFilter
  } = yield select((state: RootState) => state.CollaboratorsManagement);

  if (params.toString() !== "") {
    yield putResolve(actions.CollaboratorsManagement.populateFiltersOnMount({
      searchTerm: params.get('search') ?? "",
      filter: params.get('role') ?? "",
      groupFilter: params.get('group') ?? ""
    }))
  }
  else {
    params.set('search', searchTerm)
    params.set('role', filter)
    params.set('group', groupFilter)
    payload.replace({ search: (params).toString() })
  }
}

export default function* watcherSignin() {
  yield takeLatest('CollaboratorsManagement/onMount', onMountSaga)
  yield takeLatest('CollaboratorsManagement/onUnmount', onUnmountSaga)
  yield takeLatest('CollaboratorsManagement/setPage', fetchCollaboratorsSaga);
  yield takeLatest('CollaboratorsManagement/setFilter', fetchCollaboratorsSaga);
  yield takeLatest('CollaboratorsManagement/setGroupFilter', fetchCollaboratorsSaga);
  yield takeLatest('CollaboratorsManagement/setSearchTerm', fetchCollaboratorsSaga);
  yield takeLatest('CollaboratorsManagement/setSort', fetchCollaboratorsSaga);
  yield takeLatest('CollaboratorsManagement/getCollaboratorsList', getCollaboratorsListSaga)
  yield takeLatest('CollaboratorsManagement/getCollaboratorTypes', getCollaboratorTypesSaga)
  yield takeLatest('CollaboratorsManagement/getTeams', getCollaboratorTeamsSaga)
  yield takeLatest('CollaboratorsManagement/getURLParams', getURLParamsSaga);
}


