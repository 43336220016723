import styled from 'styled-components';
import theme from 'ui/theme';

import { NameAndRoleCell } from 'shared/genericStyledComponents'

const Tooltip = styled.div`
  position: relative;
  visibility: visible;
  color: #fff;
  z-index: 1;

  &:hover {
    > span {
      visibility: visible;
    }

    > svg {
      width: 24px !important;
      height: 24px !important;
    }
  }
`

const TooltipWrapper = styled.div`
    display: flex;
    alignItems: center;
    margin-left: 8px;
`

const CustomNameAndRoleCell = styled(NameAndRoleCell)`
  max-width: 150px;

  > div {
    font-family: 'Inter';
    color: ${theme().darkColor};
    display: flex;
    align-items: center;
    
    > div > h5 {
      cursor: pointer;
      font-size: 14px;
      margin: 0;
    }
  
    > div > h6 {
      font-family: 'Inter';
      font-size: 12px;
      margin: 0;
    }
  }

`

const CustomSpan = styled.span`
  visibility: hidden;
  width: 220px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 5px;
  position: absolute;
  z-index: 1;
  top: -60px;
  left: -90px;
  display: flex;
  justify-content: center;
  align-items: center;

  > p {
    margin: 0;
    text-align: center;
  }
`

export {
  Tooltip,
  TooltipWrapper,
  CustomSpan,
  CustomNameAndRoleCell,
}