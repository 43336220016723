/**
 * Cashmanagement scene slice
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */
import { createSlice } from '@reduxjs/toolkit'
import { ApprovedStatement, DisapprovedStatement, StatementsTab } from 'types/invoicing/statements';
import { SortType } from 'types/misc';
import { DateTime } from 'luxon';
import { CashManagementTeamMember } from 'types/invoicing/cashManagement'

interface initialState {
  page: number,
  searchTerm: string,
  tab: StatementsTab,
  loading: boolean,
  showUsersWithNoMovements: boolean
  hasDateParams: boolean,
  startDate: Date,
  groupFilter: string,
  sort: SortType,
  pendingListApprovalStatus: boolean,
  approvedListApprovalStatus: boolean,
  approvedCashManagementList: CashManagementTeamMember[],
  pendingCashManagementList: CashManagementTeamMember[],
  approvedStatements: ApprovedStatement[],
  disapprovedStatements: DisapprovedStatement[]
  cashFundTotal: number,
  incomeTotal: number,
  expensesTotal: number,
  cashTotal: number,
  exportLoading: boolean
}

const now = DateTime.now().toJSDate()
const initialState: initialState = {
  page: 1,
  searchTerm: "",
  tab: StatementsTab.PENDING,
  loading: false,
  showUsersWithNoMovements: false,
  hasDateParams: false,
  startDate: now,
  groupFilter: "",
  sort: {
    field: null,
    direction: null,
  },
  pendingListApprovalStatus: false,
  approvedListApprovalStatus: false,
  approvedCashManagementList: [],
  pendingCashManagementList: [],
  approvedStatements: [],
  disapprovedStatements: [],
  cashFundTotal: 0,
  incomeTotal: 0,
  expensesTotal: 0,
  cashTotal: 0,
  exportLoading: false
}

export default createSlice({
  name: 'Cashmanagement',
  initialState,
  reducers: {
    onMount: (state, { payload }) => { },
    onUnmount: () => { },
    setSort: (state, { payload }) => {
      state.sort = payload;
    },
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
    setExportLoading: (state, { payload }) => {
      state.exportLoading = payload;
    },
    setTab: (state, { payload }) => {
      state.page = 1;
      state.tab = payload
    },
    setSearchTerm: (state, { payload }) => {
      state.page = 1
      state.searchTerm = payload
    },
    setShowUsersWithNoMovements: (state, { payload }) => {
      state.showUsersWithNoMovements = payload.showUsersWithNoMovements
    },
    setStartDate: (state, { payload }) => {
      state.page = 1
      state.startDate = payload
    },
    setGroupFilter: (state, { payload }) => {
      state.page = 1
      state.groupFilter = payload
    },
    getCashManagementLists: () => { },
    setCashManagementLists: (state, { payload }) => {

      state.approvedCashManagementList = payload.filter(element => {
        if (element.statement !== null) {
          if (element.statement.isApproved) {
            return true
          }
        }
        return false
      })
      state.pendingCashManagementList = payload.filter(element => {
        if (element.statement === null) {
          if (state.showUsersWithNoMovements === true) {
            return true
          }
          else {
            return false
          }
        }
        else if (!element.statement?.isApproved) {
          return true
        }
        else {
          return false
        }

      })
    },
    clearApprovedStatements: (state) => {
      state.approvedStatements = []
    },
    clearDisapprovedStatements: (state) => {
      state.disapprovedStatements = []
    },
    getStatementTotals: () => { },
    setStatementTotals: (state, { payload }) => {
      state.incomeTotal = payload.income
      state.expensesTotal = payload.expenses
      state.cashTotal = payload.estimated
    },
    addApprovedStatement: (state, { payload }) => {
      const approvedStatementsCopy: ApprovedStatement[] = Array.from(payload.approvedStatements)
      approvedStatementsCopy.push(payload.id)
      state.approvedStatements = approvedStatementsCopy
    },
    removeApprovedStatement: (state, { payload }) => {
      const approvedStatementsCopy: ApprovedStatement[] = Array.from(payload.approvedStatements)
      state.approvedStatements = approvedStatementsCopy.filter(id => id !== payload.id)
    },
    addDisapprovedStatement: (state, { payload }) => {
      const disapprovedStatementsCopy: DisapprovedStatement[] = Array.from(payload.disapprovedStatements)
      disapprovedStatementsCopy.push(payload.id)
      state.disapprovedStatements = disapprovedStatementsCopy
    },
    removeDisapprovedStatement: (state, { payload }) => {
      const disapprovedStatementsCopy: DisapprovedStatement[] = Array.from(payload.disapprovedStatements)
      state.disapprovedStatements = disapprovedStatementsCopy.filter(id => id !== payload.id)
    },
    changePendingListListStatusAll: (state, { payload }) => {
      const status = payload.status
      const pendingCashManagementListCopy: any[] = Array.from(payload.pendingCashManagementList)

      state.pendingListApprovalStatus = status
      if (status) {
        state.approvedStatements = pendingCashManagementListCopy.filter(pendingList => pendingList.statement !== null && pendingList.statement.isClosed).map(cm => cm.statement?.id)
      }
      else {
        state.approvedStatements = []
      }
    },
    changeApprovedListStatusAll: (state, { payload }) => {
      const status = payload.status
      const approvedCashManagementListCopy: any[] = Array.from(payload.approvedCashManagementList)

      state.approvedListApprovalStatus = status
      if (status) {
        state.disapprovedStatements = approvedCashManagementListCopy.filter(approvedList => approvedList.statement !== null && approvedList.statement.isClosed).map(cm => cm.statement?.id)
      }
      else {
        state.disapprovedStatements = []
      }

    },
    submitApprovedStatements: () => { },
    submitDisapprovedStatements: () => { },
    exportStatements: () => { },
    downloadStatements: (state, { payload }) => {
      const file = payload
      const fileName = `statements-list.xlsx`
      const finalFileName = fileName.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(" ", "");

      const url = URL.createObjectURL(file)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute(
        'download',
        finalFileName
      )
      document.body.appendChild(link);
      link.click()
      link.parentNode?.removeChild(link)
    },
    setHasDateParams: (state, { payload }) => {
      state.hasDateParams = payload
    },
    getURLParams: (state, { payload }) => { },
    populateFiltersOnMount: (state, { payload }) => {
      state.searchTerm = payload.searchTerm
      state.startDate = payload.startDate
      state.groupFilter = payload.groupFilter
    }
  }
})