/**
 * Vehicleform scene slice
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */
import { createSlice } from '@reduxjs/toolkit'
import { Vehicle, VehicleForm, VehicleDocumentsForm } from 'types/fleet/vehicle';
import _ from "lodash";
import * as Check from 'validations'
import { VehicleFormValidationsAvailable, VehicleFormValidationsUnavailable, defaultForm, defaultDocument } from '../utils'

interface InitialState {
    edit: boolean,
    hasEditPermission: boolean,
    submitButtonState: boolean,
    vehicleForm: VehicleForm,
    vehicle: Vehicle,
    loading: boolean,
    deleteModal: boolean,
    documentsForm: Array<VehicleDocumentsForm>,
    deletedDocQueue: Array<VehicleDocumentsForm>
}

const initialState: InitialState = {
    loading: true,
    edit: false,
    hasEditPermission: true,
    submitButtonState: false,
    vehicleForm: _.cloneDeep(defaultForm),
    vehicle: {
        id: 0,
        name: '',
        shortName: '',
        vehicleTypeId: '',
        description: '',
        color: '',
        plateNum: '',
        motor: '',
        vehicleClassId: '',
        fuelCapacity: '',
        fuelTypeId: "",
        capacity: "0",
        location: '',
        nextMaintenance: '',
        nextMaintenanceLocation: '',
        nextMaintenanceText: '',
        isActive: true,
        isAvailable: false,
        unavailabilityStart: '',
        unavailabilityEnd: '',
        unavailabilityText: '',
        documents: []
    },
    deleteModal: false,
    documentsForm: [],
    deletedDocQueue: []
}

export default createSlice({
    name: 'fleetmanagementform',
    initialState,
    reducers: {
        onMount: (state, { payload }) => { },
        onUnmount: (state, { payload }) => { },
        requestVehicle: (state, { payload }) => { },
        deleteVehicle: () => { },
        showDeleteModal: (state, { payload }) => {
            state.deleteModal = payload;
        },
        setEditMode: (state, { payload }) => {
            state.edit = payload;
        },
        setSubmitButtonDisabled:(state, { payload }) => {
            state.submitButtonState = payload
        },
        setHasEditPermission: (state, { payload }) => {
            state.hasEditPermission = payload
        },
        setLoading: (state, { payload }) => {
            state.loading = payload;
        },
        setForm: (state, { payload }) => {
            state.vehicleForm = payload;
        },
        setDocumentForm: (state, { payload }) => {
            state.documentsForm = payload;
        },
        setVehicle: (state, { payload }) => {
            state.vehicle = payload;
        },
        onFormChange: (state, { payload }) => {
            if (state.edit === true) {
                if (state.vehicleForm.isAvailable.value === false) {
                    state.vehicleForm = Check.setAndCheckValidation(state.vehicleForm, payload, VehicleFormValidationsUnavailable)
                } else {
                    state.vehicleForm = Check.setAndCheckValidation(state.vehicleForm, payload, VehicleFormValidationsAvailable)
                }
            }
            else {
                state.vehicleForm = payload //Check.setAndCheckValidation(state.vehicleForm, payload, VehicleFormValidations)
            }
        },
        onSubmit: () => { },
        onSubmitDocuments: () => { },
        onAddDocument: (state) => {
            state.documentsForm = [...state.documentsForm, defaultDocument as VehicleDocumentsForm]
        },
        onDocumentChange: (state, { payload }) => {
            payload.dirty = true;
            if (payload.date || payload.doc) {
                state.documentsForm[payload.idx] = payload
            }
            else {
                state.documentsForm[payload.idx].documentType = payload.id;
                let validation = state.vehicleForm.isAvailable.value ? VehicleFormValidationsAvailable : VehicleFormValidationsUnavailable;
                state.documentsForm[payload.idx].documentType = Check.setAndCheckValidation(state.vehicleForm, payload, validation)
            }
        },
        emptyDeletedQueue: (state) => {
            state.deletedDocQueue = [];
        },
        deleteDocument: (state, { payload }) => {
            const docs = [...state.documentsForm];
            const { idx, doc } = payload;
            docs.splice(idx, 1);
            if (!payload.new) state.deletedDocQueue.push(doc);
            state.documentsForm = docs;
        }
    }
})