/**
 * Fleetmanagement scene slice
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */
import { createSlice } from '@reduxjs/toolkit'
import { /*VehicleListItem,*/ VehicleSetting, VehicleListSingle, TanksList } from 'types/fleet/vehicle';
import { SortType } from 'types/misc';

interface InitialState {
  sort: SortType,
  page: number,
  total: number,
  filter: string,
  fleet: VehicleListSingle[],
  vehicleTypes: VehicleSetting[],
  fuelTypes: VehicleSetting[],
  vehicleClasses: VehicleSetting[],
  documentTypes: VehicleSetting[],
  fleetLongList: VehicleListSingle[],
  // fleetLongList: VehicleListItem[],
  fleetWithTanks: TanksList,
  searchTerm: string,
  loading: boolean,
}

const initialState: InitialState = {
  sort: {
    field: null,
    direction: null,
  },
  page: 1,
  total: 0,
  filter: "",
  fleet: [],
  fleetLongList: [],
  vehicleTypes: [],
  fuelTypes: [],
  vehicleClasses: [],
  documentTypes: [],
  fleetWithTanks: [],
  searchTerm: '',
  loading: true,
}

export default createSlice({
  name: 'fleetmanagement',
  initialState,
  reducers: {
    onMount: (state, { payload }) => { },
    onUnmount: (state) => {
      
    },
    setSort: (state, { payload }) => {
      state.sort = payload;
    },
    setTotal: (state, { payload }) => {
      state.total = payload;
    },
    setPage: (state, { payload }) => {
      state.page = payload;
    },
    getAllFleet: () => { },
    setAllFleet: (state, { payload }) => {
      state.fleetLongList = payload;
    },
    getFleetWithTanks: () => { },
    setFleetWithTanks: (state, { payload }) => {
      state.fleetWithTanks = payload;
    },
    getVehicleTypes: () => { },
    setVehicleTypes: (state, { payload }) => {
      state.vehicleTypes = payload;
    },
    getFuelTypes: () => { },
    setFuelTypes: (state, { payload }) => {
      state.fuelTypes = payload;
    },
    getVehicleClasses: () => { },
    setVehicleClasses: (state, { payload }) => {
      state.vehicleClasses = payload;
    },
    getDocumentTypes: () => { },
    setDocumentTypes: (state, { payload }) => {
      state.documentTypes = payload;
    },
    fetchFleet: () => { },
    setFleet: (state, { payload }) => {
      state.fleet = payload;
    },
    setSearchTerm: (state, { payload }) => {
      state.searchTerm = payload
    },
    setFilter: (state, { payload }) => {
      state.page=1
      state.filter = payload
    },
    getURLParams:(state, payload) => {},
    setLoading: (state, { payload }) => {
      state.loading = payload
    },
    populateFiltersOnMount:(state, { payload }) => {
      state.searchTerm = payload.searchTerm
      state.filter = payload.filter
    }
  }
})