/**
 * Tripcategoryform component
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */

import { CCol, CForm, CRow } from '@coreui/react-pro'
import { useParams } from 'react-router-dom';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { actions, RootState } from 'store/rootSlices'
import { Title, TitleBox, ContainerBox } from 'shared/genericStyledComponents';
import TabsContainer from 'shared/components/Tabscontainer';
import CustomTextInput from 'shared/components/CustomTextInput'
import CustomNumberInput from 'shared/components/CustomNumberInput'

import ContainerDelete from 'shared/components/FormComponents/containerdelete';
import CustomButton from 'shared/components/CustomButton';
import theme from 'ui/theme';
import i18n from 'i18next';
import CustomCheckbox from 'shared/components/CustomCheckbox';
import config from 'config';
import { hasPermission } from 'permissions';
import { Row, ButtonWrapper } from '../styles';


const Tripcategoryform = (props) => {

  const dispatch = useDispatch();
  const { id } = useParams();

  const { onFormChange, onMount, onUnmount, onSubmit } = actions.TripPassengerTypeForm;
  const { edit, passengerTypeForm, hasEditPermission, submitButtonState } = useSelector((state: RootState) => state.TripPassengerTypeForm)
  const { navigateBack } = actions.App;
  const { permissions } = useSelector((state: RootState) => state.App)
  const { PERMISSIONS } = config

  const { totalPassengerTypes } = useSelector((state: RootState) => state.TripsConfiguration)

  useEffect(() => {
    dispatch(onMount(id))

    return () => {
      dispatch(onUnmount())
    }

  }, [dispatch, id, onMount, onUnmount]);

  const handleSubmit = (e) => {
    dispatch(onSubmit({ passengerTypeForm, e, edit }))
  }

  /*   const handleDeleteCollaborator = (e) => {
      dispatch(showDeleteModal(true))
    }
    const handleDeleteCollaboratorConfirm = (e) => {
      dispatch(onDeleteConfirm(uuid))
    } */

  return (
    <div>
      <CForm>
        {/* {edit === true && <DropdownExport id='tripPassengerTypeForm-dropdownExport' dropdown={true} wrapperStyle={{ maxWidth: '100%' }} />} */}
        <CustomCheckbox
          id='tripPassengerTypeForm-isActive'
          disabled={!hasEditPermission}
          wrapperStyle={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '-25px', fontSize: '20px' }}
          checked={passengerTypeForm.isActive.value ? true : false}
          onClick={e => dispatch(onFormChange({ ...passengerTypeForm, isActive: { value: !passengerTypeForm.isActive.value, valid: true, message: "" } }))}
          text={i18n.t('tripPassengerTypeForm.isActive')}
          switch
        />
        <div style={{ width: '100%', paddingBottom: '24px' }}>
          <TitleBox>
            <Title>{i18n.t('tripPassengerTypeForm.passengerTypeTitle')}</Title>
          </TitleBox>
          <TabsContainer id="tripPassengerTypeForm-tabsContainer" onChangeTab={tab => { }} content={[
            {
              title: i18n.t('tripPassengerTypeForm.passenger'),
              content: <ContainerBox>
                <CRow className="g-3">
                  <CCol sm={9}>
                    <CustomTextInput
                      id='tripPassengerTypeForm-namePt'
                      disabled={!hasEditPermission}
                      required={true}
                      valid={passengerTypeForm.namePt.valid}
                      value={passengerTypeForm.namePt.value}
                      helpText={passengerTypeForm.namePt.message}
                      placeholder={i18n.t('placeholders.name')}
                      label={i18n.t('tripPassengerTypeForm.name')}
                      onChange={(e) => dispatch(onFormChange({ ...passengerTypeForm, namePt: { value: e.target.value, message: "", valid: true } }))}
                    />
                  </CCol>

                  <CCol>
                    <CustomTextInput
                      id='tripPassengerTypeForm-shortName'
                      disabled={!hasEditPermission}
                      required={true}
                      valid={passengerTypeForm.shortNamePt.valid}
                      value={passengerTypeForm.shortNamePt.value}
                      helpText={passengerTypeForm.shortNamePt.message}
                      placeholder={i18n.t('placeholders.shortName')}
                      label={i18n.t('tripPassengerTypeForm.shortName')}
                      onChange={(e) => dispatch(onFormChange({ ...passengerTypeForm, shortNamePt: { value: e.target.value, message: "", valid: true } }))}
                    />
                  </CCol>
                </CRow>
                <Row>
                  <CCol sm={9}>
                    <CustomTextInput
                      id='tripPassengerTypeForm-descriptionPt'
                      disabled={!hasEditPermission}
                      required={true}
                      valid={passengerTypeForm.descriptionPt.valid}
                      value={passengerTypeForm.descriptionPt.value}
                      helpText={passengerTypeForm.descriptionPt.message}
                      placeholder={i18n.t('placeholders.description')}
                      label={i18n.t('tripPassengerTypeForm.description')}
                      onChange={(e) => dispatch(onFormChange({ ...passengerTypeForm, descriptionPt: { value: e.target.value, message: "", valid: true } }))}
                    />
                  </CCol>
                  <CCol>
                    <CustomNumberInput
                      id='tripPassengerTypeForm-sort'
                      disabled={!hasEditPermission}
                      required={true}
                      onClickAdd={(e, numberInput) => {
                        dispatch(onFormChange({
                          ...passengerTypeForm,
                          sort: { value: parseInt(numberInput.value, 10) + 1, message: "", valid: true }
                        }));
                      }}
                      onClickSub={(e, numberInput) => {
                        dispatch(onFormChange({
                          ...passengerTypeForm,
                          sort: { value: parseInt(numberInput.value, 10) - 1, message: "", valid: true }
                        }));
                      }}
                      min={1}
                      max={totalPassengerTypes}
                      valid={passengerTypeForm.sort.valid}
                      value={passengerTypeForm.sort.value}
                      helpText={passengerTypeForm.sort.message}
                      placeholder={i18n.t('placeholders.sort')}
                      label={i18n.t('tripPassengerTypeForm.sort')}
                      onChange={(e) => dispatch(onFormChange({ ...passengerTypeForm, sort: { value: e.target.value, message: "", valid: true } }))}
                    />
                  </CCol>
                </Row>
                <Row>
                  <CustomCheckbox
                      id={`tripPassengerTypeForm-occupySeat`}
                      disabled={!hasEditPermission}
                      checked={passengerTypeForm.occupySeat.value === true ? true : false}
                      onClick={e => dispatch(
                        onFormChange(
                          {
                            ...passengerTypeForm,
                            occupySeat: { value: e.target.checked, valid: true, message: "" }

                          }
                        )
                      )}
                      text={i18n.t('tripPassengerTypeForm.occupySeat')}
                    />
                    <CustomCheckbox
                      id={`tripPassengerTypeForm-isDefault`}
                      disabled={!hasEditPermission}
                      checked={passengerTypeForm.isDefault.value === true ? true : false}
                      onClick={e => dispatch(
                        onFormChange(
                          {
                            ...passengerTypeForm,
                            isDefault: { value: e.target.checked, valid: true, message: "" }

                          }
                        )
                      )}
                      text={i18n.t('tripPassengerTypeForm.isDefault')}
                    />
                </Row>
              </ContainerBox>
            },
            {
              title: i18n.t('common.translations'),
              content: <ContainerBox>
                <CRow className="g-3">
                  <CCol sm={8}>
                    <CustomTextInput
                      id='tripPassengerTypeForm-nameEn'
                      disabled={!hasEditPermission}
                      required={false}
                      valid={passengerTypeForm.nameEn.valid}
                      value={passengerTypeForm.nameEn.value}
                      helpText={passengerTypeForm.nameEn.message}
                      placeholder={i18n.t('placeholders.nameEn')}
                      label={i18n.t('tripActivityForm.nameEn')}
                      onChange={(e) => dispatch(onFormChange({ ...passengerTypeForm, nameEn: { value: e.target.value, message: "", valid: true } }))}
                    />
                  </CCol>

                  <CCol>
                    <CustomTextInput
                      id='tripPassengerTypeForm-shortNameEn'
                      disabled={!hasEditPermission}
                      required={false}
                      valid={passengerTypeForm.shortNameEn.valid}
                      value={passengerTypeForm.shortNameEn.value}
                      helpText={passengerTypeForm.shortNameEn.message}
                      placeholder={i18n.t('placeholders.shortNameEn')}
                      label={i18n.t('tripPassengerTypeForm.shortNameEn')}
                      onChange={(e) => dispatch(onFormChange({ ...passengerTypeForm, shortNameEn: { value: e.target.value, message: "", valid: true } }))}
                    />
                  </CCol>
                </CRow>
                <Row>
                  <CustomTextInput
                    id='tripPassengerTypeForm-descriptionEn'
                    disabled={!hasEditPermission}
                    required={true}
                    valid={passengerTypeForm.descriptionEn.valid}
                    value={passengerTypeForm.descriptionEn.value}
                    helpText={passengerTypeForm.descriptionEn.message}
                    placeholder={i18n.t('placeholders.descriptionEn')}
                    label={i18n.t('tripPassengerTypeForm.descriptionEn')}
                    onChange={(e) => dispatch(onFormChange({ ...passengerTypeForm, descriptionEn: { value: e.target.value, message: "", valid: true } }))}
                  />
                </Row>
                <hr style={{marginBottom: "30px"}}></hr>
                <Row>
                  <CCol sm={8}>
                    <CustomTextInput
                      id='tripPassengerTypeForm-nameEs'
                      disabled={!hasEditPermission}
                      required={false}
                      valid={passengerTypeForm.nameEs.valid}
                      value={passengerTypeForm.nameEs.value}
                      helpText={passengerTypeForm.nameEs.message}
                      placeholder={i18n.t('placeholders.nameEs')}
                      label={i18n.t('tripActivityForm.nameEs')}
                      onChange={(e) => dispatch(onFormChange({ ...passengerTypeForm, nameEs: { value: e.target.value, message: "", valid: true } }))}
                    />
                  </CCol>

                  <CCol>
                    <CustomTextInput
                      id='tripPassengerTypeForm-shortNameEs'
                      disabled={!hasEditPermission}
                      required={false}
                      valid={passengerTypeForm.shortNameEs.valid}
                      value={passengerTypeForm.shortNameEs.value}
                      helpText={passengerTypeForm.shortNameEs.message}
                      placeholder={i18n.t('placeholders.shortNameEs')}
                      label={i18n.t('tripPassengerTypeForm.shortNameEs')}
                      onChange={(e) => dispatch(onFormChange({ ...passengerTypeForm, shortNameEs: { value: e.target.value, message: "", valid: true } }))}
                    />
                  </CCol>
                </Row>
                <Row>
                  <CustomTextInput
                    id='tripPassengerTypeForm-descriptionEs'
                    disabled={!hasEditPermission}
                    required={true}
                    valid={passengerTypeForm.descriptionEs.valid}
                    value={passengerTypeForm.descriptionEs.value}
                    helpText={passengerTypeForm.descriptionEs.message}
                    placeholder={i18n.t('placeholders.descriptionEs')}
                    label={i18n.t('tripPassengerTypeForm.descriptionEs')}
                    onChange={(e) => dispatch(onFormChange({ ...passengerTypeForm, descriptionEs: { value: e.target.value, message: "", valid: true } }))}
                  />
                </Row>
                <hr style={{marginBottom: "30px"}}></hr>
                <Row>
                  <CCol sm={8}>
                    <CustomTextInput
                      id='tripPassengerTypeForm-nameFr'
                      disabled={!hasEditPermission}
                      required={false}
                      valid={passengerTypeForm.nameFr.valid}
                      value={passengerTypeForm.nameFr.value}
                      helpText={passengerTypeForm.nameFr.message}
                      placeholder={i18n.t('placeholders.nameFr')}
                      label={i18n.t('tripActivityForm.nameFr')}
                      onChange={(e) => dispatch(onFormChange({ ...passengerTypeForm, nameFr: { value: e.target.value, message: "", valid: true } }))}
                    />
                  </CCol>

                  <CCol>
                    <CustomTextInput
                      id='tripPassengerTypeForm-shortNameFr'
                      disabled={!hasEditPermission}
                      required={false}
                      valid={passengerTypeForm.shortNameFr.valid}
                      value={passengerTypeForm.shortNameFr.value}
                      helpText={passengerTypeForm.shortNameFr.message}
                      placeholder={i18n.t('placeholders.shortNameFr')}
                      label={i18n.t('tripPassengerTypeForm.shortNameFr')}
                      onChange={(e) => dispatch(onFormChange({ ...passengerTypeForm, shortNameFr: { value: e.target.value, message: "", valid: true } }))}
                    />
                  </CCol>
                </Row>
                <Row>
                  <CustomTextInput
                    id='tripPassengerTypeForm-descriptionFr'
                    disabled={!hasEditPermission}
                    required={true}
                    valid={passengerTypeForm.descriptionFr.valid}
                    value={passengerTypeForm.descriptionFr.value}
                    helpText={passengerTypeForm.descriptionFr.message}
                    placeholder={i18n.t('placeholders.descriptionFr')}
                    label={i18n.t('tripPassengerTypeForm.descriptionFr')}
                    onChange={(e) => dispatch(onFormChange({ ...passengerTypeForm, descriptionFr: { value: e.target.value, message: "", valid: true } }))}
                  />
                </Row>
              </ContainerBox>
            }
          ]}/>
        </div>
   
        {
          edit === true && hasPermission(permissions, PERMISSIONS.TRIPS.PASSENGER_TYPES.DELETE_PASSENGER_TYPE) && <ContainerDelete id='tripPassengerTypeForm-delete' title={i18n.t('tripPassengerTypeForm.removePassengerType')} text={<p>{i18n.t('tripPassengerTypeForm.removePassengerTypeText')}<br />{i18n.t('common.deleteLine2')}</p>}></ContainerDelete>
        }
        <ButtonWrapper>
          <CustomButton id='tripPassengerTypeForm-cancel' onClick={() => dispatch(navigateBack())} text={i18n.t('common.cancelBtn')} color="link" buttonStyle={{ color: theme().danger, textDecoration: 'unset' }} />
          {
            edit ?
              hasPermission(permissions, PERMISSIONS.TRIPS.PASSENGER_TYPES.EDIT_PASSENGER_TYPE) &&
              <CustomButton
                id='tripPassengerTypeForm-save'
                disabled={submitButtonState}
                text={i18n.t('common.saveBtn')}
                icon="confirm"
                onClick={e => handleSubmit(e)}
                color="success"
                buttonStyle={{ color: "#FFFFFF" }} />
              :
              hasPermission(permissions, PERMISSIONS.TRIPS.PASSENGER_TYPES.CREATE_PASSENGER_TYPE) &&
              <CustomButton
                id='tripPassengerTypeForm-save'
                disabled={submitButtonState}
                text={i18n.t('common.saveBtn')}
                icon="confirm"
                onClick={e => handleSubmit(e)}
                color="success"
                buttonStyle={{ color: "#FFFFFF" }} />
          }
        </ButtonWrapper>
      </CForm>
    </div>
  )
}

export default Tripcategoryform