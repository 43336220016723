/**
 * Timerecord scene slice
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */
import { createSlice } from '@reduxjs/toolkit'
import { DateTime } from 'luxon';
import { StatementsTab } from 'types/invoicing/statements';
import { SortType } from 'types/misc';
import { TimeRecordsList } from 'types/users/user'


const startOfWeek = DateTime.now().startOf("week").toISODate()
const endOfWeek = DateTime.now().endOf("week").toISODate()

interface InitialState {
  page: number,
  total: number,
  filter: string,
  groupFilter: string,
  searchTerm: string,
  tab: StatementsTab,
  startDate: string,
  endDate: string,
  sort: SortType,
  pendingListApprovalStatus: boolean,
  approvedListApprovalStatus: boolean,
  collabsList: any[],
  toApproveList: string[],
  approvedList: TimeRecordsList,
  approvedListTotal: number,
  toDisapproveList: string[],
  pendingList: TimeRecordsList,
  disapprovedListTotal: number,
  approvedTotal: number,
  pendingTotal: number,
  loading: boolean,
  hasDateParams: boolean,
  exportLoading: boolean
}


const initialState: InitialState = {
  page: 1,
  total: 0,
  filter: "",
  groupFilter: "",
  searchTerm: "",
  tab: StatementsTab.PENDING,
  startDate: startOfWeek,
  endDate: endOfWeek,
  sort: {
    field: null,
    direction: null,
  },
  pendingListApprovalStatus: false,
  approvedListApprovalStatus: false,
  collabsList: [],
  toApproveList: [],
  approvedList: [],
  approvedListTotal: 0,
  toDisapproveList: [],
  pendingList: [],
  disapprovedListTotal: 0,
  approvedTotal: 0,
  pendingTotal: 0,
  loading: false,
  hasDateParams: false,
  exportLoading: false
}

export default createSlice({
  name: 'Timerecord',
  initialState,
  reducers: {
    onMount: (state, { payload }) => { },
    onUnmount: () => { },
    setTotal: (state, { payload }) => {
      state.total = payload;
    },
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
    setExportLoading: (state, { payload }) => {
      state.exportLoading = payload
    },
    setPage: (state, { payload }) => {
      state.page = payload;
    },
    setSort: (state, { payload }) => {
      state.sort = payload;
    },
    setFilter: (state, { payload }) => {
      state.page = 1
      state.filter = payload
    },
    setGroupFilter: (state, { payload }) => {
      state.page = 1
      state.groupFilter = payload
    },
    setSearchTerm: (state, { payload }) => {
      state.page = 1
      state.searchTerm = payload
    },
    setTab: (state, { payload }) => {
      state.page = 1;
      state.tab = payload
    },
    setStartDate: (state, { payload }) => {
      state.startDate = payload
    },
    setEndDate: (state, { payload }) => {
      state.endDate = payload
    },
    setDatesInterval: (state, { payload }) => {
      state.page = 1
    },
    setHasDateParams: (state, { payload }) => {
      state.hasDateParams = payload
    },
    changePendingListTimeRecordStatusAll: (state, { payload }) => {
      const status = payload.status
      const pendingTimeRecordListCopy: any[] = Array.from(payload.pendingList)

      state.pendingListApprovalStatus = status
      if (status && pendingTimeRecordListCopy.length > 0) {
        state.toApproveList = pendingTimeRecordListCopy.map(tm => tm.uuid)
      }
      else {
        state.toApproveList = []
      }
    },
    changeApprovedListTimeRecordStatusAll: (state, { payload }) => {
      const status = payload.status
      const approvedTimeRecordListCopy: any[] = Array.from(payload.approvedList)

      state.approvedListApprovalStatus = status
      if (status && approvedTimeRecordListCopy.length > 0) {
        state.toDisapproveList = approvedTimeRecordListCopy.map(tm => tm.uuid)
      }
      else {
        state.toDisapproveList = []
      }
    },
    getTimeRecordListFile: () => { },
    getLists: () => { },
    clearToAprroveList: (state) => {
      state.toApproveList = []
    },
    clearToDisapproveList: (state) => {
      state.toDisapproveList = []
    },
    addApprove: (state, { payload }) => {
      const toApproveListCopy: any[] = Array.from(payload.toApproveList)
      toApproveListCopy.push(payload.uuid)
      state.toApproveList = toApproveListCopy
    },
    removeApprove: (state, { payload }) => {
      const toApproveListCopy: any[] = Array.from(payload.toApproveList)
      state.toApproveList = toApproveListCopy.filter(uuid => uuid !== payload.uuid)
    },
    addDisapprove: (state, { payload }) => {
      const toDisapproveListCopy: any[] = Array.from(payload.toDisapproveList)
      toDisapproveListCopy.push(payload.uuid)
      state.toDisapproveList = toDisapproveListCopy
    },
    removeDisapprove: (state, { payload }) => {
      const toDisapproveListCopy: any[] = Array.from(payload.toDisapproveList)
      state.toDisapproveList = toDisapproveListCopy.filter(uuid => uuid !== payload.uuid)
    },
    getPendingList: () => { },
    setPendingList: (state, { payload }) => {
      state.pendingList = payload
    },
    setPendingTotal: (state, { payload }) => {
      state.pendingTotal = payload
    },
    getApprovedList: () => { },
    setApprovedList: (state, { payload }) => {
      state.approvedList = payload
    },
    setApprovedTotal: (state, { payload }) => {
      state.approvedTotal = payload
    },
    submitApprovedTimeRecords: () => { },
    submitDisapprovedTimeRecords: () => { },
    getURLParams: (state, { payload }) => { },
    populateFiltersOnMount: (state, { payload }) => {
      state.searchTerm = payload.searchTerm
      state.filter = payload.filter
      state.groupFilter = payload.groupFilter
      state.startDate = payload.startDate
      state.endDate = payload.endDate
    },
    downloadTimeRecordList: (state, { payload }) => {

      const fileName = `timeRecordList.xlsx`
      const finalFileName = fileName.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(" ", "");

      const url = URL.createObjectURL(payload.file)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute(
        'download',
        finalFileName
      )
      document.body.appendChild(link);
      link.click()
      link.parentNode?.removeChild(link)
    },
  }
})

