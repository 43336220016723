import { DateTime  } from 'luxon';
import type { InitialStateProps } from "../../../logic/initialState"
import { PlanningStopsForm } from 'types/trips/plannings'

const CircuitsReducers = {
  getLegs: () => {},
  onVehicleChange: (state: InitialStateProps, { payload }) => {
    state.selectedVehicle = payload.vehicle.uuid.value
  },
  onCircuitSelect: (state, { payload }) => {
    const outbounds: PlanningStopsForm[] = Array.from(payload.outboundStops)
    const inbounds: PlanningStopsForm[] = Array.from(payload.inboundStops)

    const outS: PlanningStopsForm[] = outbounds.map((item) => {
      const outStps: any = Array.from([])

      /* checks which stops the selected circuit has */
      payload.circuit[0]?.outboundStops.forEach((stop) => {
        if (item.stop.id === stop.stop.id) {
          outStps.push(stop)
        }
      })


      if (outStps.length > 0) {
        return {
          time: { value: `${outStps[0].time.split(':')[0]}:${outStps[0].time.split(':')[1]}`, valid: true, message: "" }, // to fix up api inconsistency pass 'hh:mm:ss' to 'hh:mm'
          isEdited: true,
          activities: payload.services.map((ser) => {
            return {
              id: ser.serviceDetails.id.value,
              shortName: ser.serviceDetails.shortName,
              isOn: { value: true, valid: true, message: "" }
            }
          }),
          stop: {
            ...item.stop,
            id: outStps[0].stop.id,
            isActive: outStps[0].stop.isActive,
            isAvailable: outStps[0].stop.isAvailable,
            name: outStps[0].stop.name,
            shortName: outStps[0].stop.shortName,
            details: payload.vehicles[0].name.value !== "" ? payload.vehicles.map((v, idx) => {
              return {
                ...item.stop.details[idx],
                vehicle: {
                  uuid: v.uuid.value,
                  name: v.name
                },
                activities: payload.services.map((ser) => {
                  return {
                    id: ser.serviceDetails.id.value,
                    shortName: ser.serviceDetails.shortName,
                    isOn: { value: true, valid: true, message: "" }
                  }
                })
              }
            })
              :
              [
                {
                  activities: payload.services.map((ser) => {
                    return {
                      id: ser.serviceDetails.id.value,
                      shortName: ser.serviceDetails.shortName,
                      isOn: { value: true, valid: true, message: "" }
                    }
                  })
                }
              ]
          }
        }
      } else {
        return {
          time: { value: "", valid: true, message: "" },
          isEdited: false,
          activities: payload.services.map((ser) => {
            return {
              id: ser.serviceDetails.id.value,
              shortName: ser.serviceDetails.shortName,
              isOn: { value: false, valid: true, message: "" }
            }
          }),
          stop: {
            ...item.stop,
            details: item.stop.details?.map(det => {
              return {
                ...det,
                activities: det.activities?.map(act => {
                  return {
                    ...act,
                    isOn: { value: false, valid: true, message: "" }
                  }
                })
              }
            })
          }
        }
      }
    })


    const inS: PlanningStopsForm[] = inbounds.map((item) => {
      const inStps: any = Array.from([])
      payload.circuit[0]?.inboundStops.forEach((stop) => {
        if (item.stop.id === stop.stop.id) {
          inStps.push(stop)
        }
      })

      if (inStps.length > 0) {

        return {
          time: { value: `${inStps[0].time.split(':')[0]}:${inStps[0].time.split(':')[1]}`, valid: true, message: "" }, // to fix up api inconsistency
          isEdited: true,
          activities: payload.services.map((ser) => {
            return {
              id: ser.serviceDetails.id.value,
              shortName: ser.serviceDetails.shortName,
              isOn: { value: true, valid: true, message: "" }
            }
          }),
          stop: {
            ...item.stop,
            id: inStps[0].stop.id,
            isActive: inStps[0].stop.isActive,
            isAvailable: inStps[0].stop.isAvailable,
            name: inStps[0].stop.name,
            shortName: inStps[0].stop.shortName,
            details: payload.vehicles[0].name.value !== "" ? payload.vehicles.map((v, idx) => {
              return {
                ...item.stop.details[idx],
                vehicle: {
                  uuid: v.uuid.value,
                  name: v.name
                },
                activities: payload.services.map((ser) => {
                  return {
                    id: ser.serviceDetails.id.value,
                    shortName: ser.serviceDetails.shortName,
                    isOn: { value: true, valid: true, message: "" }
                  }
                })
              }
            })
              :
              [
                {
                  activities: payload.services.map((ser) => {
                    return {
                      id: ser.serviceDetails.id.value,
                      shortName: ser.serviceDetails.shortName,
                      isOn: { value: true, valid: true, message: "" }
                    }
                  })
                }
              ]
          }
        }
      } else {
        return {
          time: { value: "", valid: true, message: "" },
          isEdited: false,
          activities: payload.services.map((ser) => {
            return {
              id: ser.serviceDetails.id.value,
              shortName: ser.serviceDetails.shortName,
              isOn: { value: false, valid: true, message: "" }
            }
          }),
          stop: {
            ...item.stop,
            details: item.stop.details?.map(det => {
              return {
                ...det,
                activities: det.activities?.map(act => {
                  return {
                    ...act,
                    isOn: { value: false, valid: true, message: "" }
                  }
                })
              }
            })
          }
        }
      }
    })


    state.planningCircuitDefaultDuration = payload.circuit[0]?.duration
    state.planningCircuit = {
      circuit: { value: payload.circuit[0]?.id.toString(), valid: true, message: "" }
    }

    state.outboundStops = outS
    state.inboundStops = inS

    state.updatedStops = true
    state.updatedCircuit = true
    state.circuitStartHour = ""
  },
  onOutboundsFormChange: (state, { payload }) => {
    const outbounds: PlanningStopsForm[] = Array.from(payload.outboundStops)
    let hasChanged: boolean = true 

    const outS: PlanningStopsForm[] = outbounds.map((st, idx) => {
      if (payload.info.stopId === st.stop.id) {

        //* Checks if this stop is different from what it was in the first load of the screen
        if(payload.info.inputType === "time" && state.edit) {
          //* Here we check if the stop time changed
          hasChanged = state.initialOutboundStops[idx].time.value !== payload.info.e ? true : false
        } 
        else if(payload.info.inputType === "activities" && state.edit) {
          //* Here we check if the stop activity was changed
          if(payload.info.vehicle) {
            let initialDetail = state.initialOutboundStops[idx].stop.details.find((d) => d.vehicle?.uuid === payload.info.vehicle)
            let initialAct = initialDetail?.activities.find((a) => a.id === payload.info.actId)

            hasChanged = payload.info.e !== initialAct?.isOn.value ? true : false
          } else {
            let initialAct = state.initialOutboundStops[idx].activities.find((a) => a.id === payload.info.actId)

            hasChanged = payload.info.e !== initialAct?.isOn.value ? true : false
          }
        }

        //* Here I check if the activity we sent in the payload is on at least in one detail
        //* If so the array of activities of the stop needs to have the activity
        let hasOneOn: boolean = payload.info.e
        let updatedDetails: any[] = []
        if(payload.info.vehicle) {
          updatedDetails = payload.info.details.map((det) => {
            if (det.vehicle?.uuid === payload.info.vehicle) {
              return {
                ...det,
                activities: det.activities.map((act) => {
                  //* update details activities array to the new value;
                  if (payload.info.actId === act.id) {
                    return {
                      ...act,
                      isOn: { value: payload.info.inputType === "activities" ? payload.info.e : act.isOn.value, valid: true, message: "" }
                    }
                  }
                  return act
                })
              }
            } else {
              return det
            }
          })

          hasOneOn = updatedDetails.some((det) => {
            return det.activities.some((act) => act.id === payload.info.actId && act.isOn.value)
          })
        }

        return {
          ...st,
          isEdited: hasChanged,
          time: { value: payload.info.inputType === "time" ? payload.info.e : st.time.value, valid: true, message: "" },
          activities: st.activities.map((act) => {
            //* update stop activities array to the new value;
            if(act.id === payload.info.actId) {
              return {
                ...act,
                isOn: { value: payload.info.inputType === "activities" ? hasOneOn : act.isOn.value, valid: true, message: "" }
              }
            }
            return act
          }),
          stop: {
            ...st.stop,
            details: updatedDetails
          }
        }
      }
      return st

    })

    state.sameCircuitForVehicles = false
    state.outboundStops = outS
    state.updatedStops = hasChanged

  },
  onInboundsFormChange: (state, { payload }) => {
    const inbounds: PlanningStopsForm[] = Array.from(payload.inboundStops)
    let hasChanged: boolean = true 

    const inS: PlanningStopsForm[] = inbounds.map((st, idx) => {
      if (payload.info.stopId === st.stop.id) {

        //* Checks if this stop is different from what it was in the first load of the screen
        if(payload.info.inputType === "time" && state.edit) {
          //* Here we check if the stop time changed
          hasChanged = state.initialInboundStops[idx].time.value !== payload.info.e ? true : false
        } 
        else if(payload.info.inputType === "activities" && state.edit) {
          //* Here we check if the stop tactivity was changed
          if(payload.info.vehicle) {
            let initialDetail = state.initialInboundStops[idx].stop.details.find((d) => d.vehicle?.uuid === payload.info.vehicle)
            let initialAct = initialDetail?.activities.find((a) => a.id === payload.info.actId)

            hasChanged = payload.info.e !== initialAct?.isOn.value ? true : false
          } else {
            let initialAct = state.initialInboundStops[idx].activities.find((a) => a.id === payload.info.actId)

            hasChanged = payload.info.e !== initialAct?.isOn.value ? true : false
          }
        }

        //* Here I check if the activity we sent in the payload is on at least in one detail
        //* If so the array of activities of the stop needs to have the activity
        let hasOneOn: boolean = payload.info.e
        let updatedDetails: any[] = []
        if(payload.info.vehicle) {
          updatedDetails = payload.info.details.map((det) => {
            if (det.vehicle?.uuid === payload.info.vehicle) {
              return {
                ...det,
                activities: det.activities.map((act) => {
                  //* update details activities array to the new value;
                  if (payload.info.actId === act.id) {
                    return {
                      ...act,
                      isOn: { value: payload.info.inputType === "activities" ? payload.info.e : act.isOn.value, valid: true, message: "" }
                    }
                  }
                  return act
                })
              }
            } else {
              return det
            }
          })

          hasOneOn = updatedDetails.some((det) => {
            return det.activities.some((act) => act.id === payload.info.actId && act.isOn.value)
          })
        }


        return {
          ...st,
          time: { value: payload.info.inputType === "time" ? payload.info.e : st.time.value, valid: true, message: "" },
          activities: st.activities.map((act) => {
            if(act.id === payload.info.actId) {
              return {
                ...act,
                isOn: { value: payload.info.inputType === "activities" ? hasOneOn : act.isOn.value, valid: true, message: "" }
              }
            }
            return act
          }),
          isEdited: hasChanged,
          stop: {
            ...st.stop,
            details: updatedDetails
          }
        }
      }
      return st
    })

    state.sameCircuitForVehicles = false
    state.inboundStops = inS
    state.updatedStops = hasChanged
  },
  setSameCircuitForVehicles: (state, { payload }) => {
    const mainCircuitOut = payload.outboundStops.map((outS) => {
      const st = outS.stop.details[0]?.activities
      if (st) {
        return {
          id: outS.stop.id,
          st
        }
      }
      return outS
    })
    const mainCircuitIn = payload.inboundStops.map((inS) => {
      const st = inS.stop.details[0]?.activities
      if (st) {
        return {
          id: inS.stop.id,
          st
        }
      }
      return inS
    })


    state.sameCircuitForVehicles = payload.sameCircuitForVehicles
    state.outboundStops = payload.outboundStops.map((outS) => {
      return {
        ...outS,
        stop: {
          ...outS.stop,
          details: outS.stop.details.map((det) => {
            const intersection = mainCircuitOut.find(st => st.id === outS.stop.id)

            return {
              ...det,
              activities: intersection ? intersection.st : det.activities
            }
          })
        }
      }
    })
    state.inboundStops = payload.inboundStops.map((inS) => {
      return {
        ...inS,
        stop: {
          ...inS.stop,
          details: inS.stop.details.map((det) => {
            const intersection = mainCircuitIn.find(st => st.id === inS.stop.id)
            return {
              ...det,
              activities: intersection ? intersection.st : det.activities
            }
          })
        }
      }
    })

    state.updatedStops = true

  },
  setCircuitStartHour: (state, { payload }) => {
    state.circuitStartHour = payload
  },
  updateCircuitStops: (state, { payload }) => {

    const outbounds: PlanningStopsForm[] = Array.from(payload.outboundStops)
    const inbounds: PlanningStopsForm[] = Array.from(payload.inboundStops)

    const startHour = outbounds.filter(st => st.time.value !== "").map(st => DateTime.fromISO(st.time.value)).reduce((pV, cV) => {
      if (pV < cV) return pV
      return cV
    })


    const outS: PlanningStopsForm[] = outbounds.map((st) => {

      //get time difference
      if (st.time.value !== "") {
        const timeDiff = DateTime.fromISO(st.time.value) - DateTime.fromISO(startHour)
        const newStartHour = DateTime.fromISO(payload.circuitStartHour) + timeDiff

        return {
          ...st,
          time: { value: DateTime.fromMillis(newStartHour).toFormat('HH:mm'), valid: true, message: "" },
        }
      }

      return st
    })


    const inS: PlanningStopsForm[] = inbounds.map((st) => {

      if (st.time.value !== "") {

        //get time difference
        const timeDiff = DateTime.fromISO(st.time.value) - DateTime.fromISO(startHour)
        const newStartHour = DateTime.fromISO(payload.circuitStartHour) + timeDiff

        return {
          ...st,
          time: { value: DateTime.fromMillis(newStartHour).toFormat('HH:mm'), valid: true, message: "" },
        }
      }

      return st
    })

    state.inboundStops = inS
    state.outboundStops = outS
    state.updatedStops = true
  }
}

export default CircuitsReducers