/**
 * Tripcampaigncodesform scene sagas
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */
 import { AnyAction } from 'redux'
 import { takeLatest, putResolve, call, select  } from 'redux-saga/effects'
 import API from 'api'
 import { actions, RootState } from 'store/rootSlices'
 import { toast } from 'react-toastify'
 import * as Check from 'validations';
 import { CampaignCodeFormValidations, CampaignCodeFormValidationsEdit } from '../utils'
 import i18n from 'i18next';

//state
const cmpgnCdID = (state: RootState) => state.TripCampaignCodesForm.campaignCodeID

function* onMountSaga({ payload }: AnyAction) {
  const campaignCodeID = payload.id
  const campaignPrefix = payload.prefix

  if (payload.prefix) {
    
    try {
      //get campaignCode from api
      const campaignCodeInfo = yield call(API.Campaigns.GetCampaignCode, campaignCodeID)

      if (campaignCodeInfo) {
        yield putResolve(actions.TripCampaignCodesForm.setCampaignCodeID(campaignCodeID))
        yield putResolve(actions.TripCampaignCodesForm.setAction(true))
        yield putResolve(actions.TripCampaignCodesForm.populateForm(campaignCodeInfo))
      }
    } catch (error) {
      toast.error(i18n.t("toasts.getCampaignCodeError"));
    }

  } else {
    // creating a new campaignCode
    yield putResolve(actions.TripCampaignCodesForm.setCampaignCodeID(campaignCodeID))
    yield putResolve(actions.TripCampaignCodesForm.setCampaignPrefix(campaignPrefix))
    yield putResolve(actions.TripCampaignCodesForm.setUID(campaignPrefix)) 
    yield putResolve(actions.TripCampaignCodesForm.setAction(false))
  }
}

function* onSubmitSaga({ payload }: AnyAction) {
  yield putResolve(actions.TripCampaignCodesForm.setSubmitButtonState(true))

  const validation = payload.edit === true ?
    Check.checkValidationAlternative(payload.campaignCodeForm, CampaignCodeFormValidationsEdit)
    :
    Check.checkValidationAlternative(payload.campaignCodeForm, CampaignCodeFormValidations)

  if (validation.invalid === true) {
    const updateValidationMessages = Check.setAndCheckValidationAlternative(payload.campaignCodeForm, payload.campaignCodeForm, CampaignCodeFormValidations)
    yield putResolve(actions.TripCampaignCodesForm.onFormChange(updateValidationMessages))
    yield putResolve(actions.TripCampaignCodesForm.setSubmitButtonState(false))
    toast.error(i18n.t("toasts.invalidFields"));
  }
  else {
    
    if (!payload.edit) {
      const campaignCodeID = yield select(cmpgnCdID)

      try {
        const campaign = yield call(API.Campaigns.CreateCampaignCode, campaignCodeID, payload.campaignCodeForm)

        if (campaign) {
          yield putResolve(actions.App.navigateTo('/viagens/configuracoes'))
          toast.success(i18n.t("toasts.createCampaignCodeSuccess"));
        }

      } catch (error) {
        toast.error(i18n.t("toasts.createCampaignCodeError"));
      } finally {
        yield putResolve(actions.TripCampaignCodesForm.setSubmitButtonState(false))
      }
    }
    else {
      const campaignCodeID = yield select(cmpgnCdID)
      
      try {
        const success = yield call(API.Campaigns.EditCampaignCode, campaignCodeID, payload.campaignCodeForm)

        if (success) {
          yield putResolve(actions.App.navigateTo('/viagens/configuracoes'))
          toast.success(i18n.t("toasts.editCampaignCodeSuccess"))
        }
      } catch (error) {
        toast.warning(i18n.t("toasts.editCampaignCodeError"))
      } finally {
        yield putResolve(actions.TripCampaignCodesForm.setSubmitButtonState(false))
      }
    }
  }

}

function* onUnmountSaga() {}

export default function* watcherSignin() {
  yield takeLatest('Tripcampaigncodesform/onMount', onMountSaga)
  yield takeLatest('Tripcampaigncodesform/onUnmount', onUnmountSaga)
  yield takeLatest('Tripcampaigncodesform/onSubmit', onSubmitSaga)
}


