import styled from 'styled-components'
import { CTableDataCell } from '@coreui/react-pro'

export const ActionsCell = styled(CTableDataCell) <{ nopointer?: boolean }>`
  text-align: right;

  > svg {
    ${(({ nopointer }) => nopointer && nopointer === true ? `cursor: auto;` : `cursor: pointer;`)}
    margin-right: 15px;
  }

  .pointer{
    cursor: pointer !important;
  }
`