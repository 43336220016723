/**
 * Fleetconfigs scene slice
 *
 * @author João Dias <jdias@ubiwhere.com>
 *
 *
 */
import { createSlice } from '@reduxjs/toolkit'
import { SortType } from 'types/misc';

type InitialState = {
  sort: SortType,
  searchTerm: string,
  loading: boolean,
  documentsPage: number,
  documentsTotal: number,
  locationsPage: number,
  locationsTotal: number,
  documentsList: Array<any>,
  locationsList: Array<any>
}

const initialState: InitialState = {
  sort: {
    direction: null,
    field: null,
  },
  searchTerm: "",
  loading: true,
  documentsPage: 1,
  documentsTotal: 0,
  locationsPage: 1,
  locationsTotal: 0,
  documentsList: [],
  locationsList: [],
}

export default createSlice({
  name: 'Fleetconfigs',
  initialState,
  reducers: {
    onMount: (state, { payload }) => { },
    onUnmount: (state) => { },
    setSearchTerm: (state, { payload }) => {
      state.searchTerm = payload;
    },
    getURLParams:(state, payload) => {},
    setSort: (state, { payload }) => {
      state.sort = payload;
    },
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
    setDocumentsPage: (state, { payload }) => { state.documentsPage = payload },
    setLocationsPage: (state, { payload }) => { state.locationsPage = payload },
    getDocumentsList: () => { },
    getLocationsList: () => { },
    setDocumentsList: (state, { payload }) => { state.documentsList = payload },
    setLocationsList: (state, { payload }) => { state.locationsList = payload },
    setDocumentsTotal: (state, { payload }) => { state.documentsTotal = payload },
    setLocationsTotal: (state, { payload }) => { state.locationsTotal = payload },
    populateFiltersOnMount:(state, { payload }) => {
      state.searchTerm = payload.searchTerm
    }
  }
})