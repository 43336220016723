import _ from "lodash";
import i18n from "i18next";
import { DateTime } from "luxon"
import { FormField } from "types/misc"


// Checks the validation of the whole form
export const checkValidation = (form, validations) => {
    let clone = _.cloneDeep(form);
    let invalid = false

    for (const key in clone) {
        if (clone.hasOwnProperty(key)) {
            var element = clone[key]
            const validationList = validations[key]

            if (validationList && validationList.length) {
                for (let index = 0; index < validationList.length; index++) {
                    const check = validationList[index]
                    const response = check(element.value, form)

                    if (response.result === 'end') {
                        // put valid true and empty message like default value
                        element.valid = true
                        element.message = ''
                        break
                    }

                    if (!response.result) {
                        element.valid = false
                        element.message = response.message
                        invalid = true
                    } else {
                        element.valid = true
                    }
                }
            }
        }
    }

    return { invalid, form: clone }
}

//checks validation in an array of the same form (ex: documents)
export const checkListValidation = (form, validations) => {
    let clone = _.cloneDeep(form);
    let invalid = false

    clone.forEach((el, idx) => {
        for (const key in el) {
            if (el.hasOwnProperty(key)) {
                var element = el[key]
                const validationList = validations[key]

                if (validationList && validationList.length) {
                    for (let index = 0; index < validationList.length; index++) {
                        const check = validationList[index]
                        const response = check(element.value, form)

                        if (response.result === 'end') {
                            // put valid true and empty message like default value
                            element.valid = true
                            element.message = ''
                            break
                        }

                        if (!response.result) {
                            element.valid = false
                            element.message = response.message
                            invalid = true
                        } else {
                            element.valid = true
                        }
                    }
                }
            }
        }

        clone[idx] = el;
    })

    return { invalid, form: clone }
}

export const checkValidationAlternative = (form, validations) => {
    let clone = { ...form }
    let invalid = false

    Object.keys(clone).forEach((fieldName) => {
        const element = clone[fieldName]
        const validationList = validations[fieldName]

        let settedField = {
            value: element.value,
            message: null,
            valid: true
        }

        if (validationList && validationList.length) {
            for (let index = 0; index < validationList.length; index++) {
                const check = validationList[index]
                const response = check(element.value, form)

                if (response.result === 'end') {
                    // put valid true and empty message like default value
                    settedField.valid = true
                    settedField.message = null
                    break
                }

                if (!response.result) {
                    settedField.valid = false
                    settedField.message = response.message
                    invalid = true
                } else {
                    settedField.valid = true
                }
            }
        }
    })
    return { invalid, form: clone }
}

// Checks the validation of a field
export const setAndCheckValidation = (state, payload, validations) => {
    let newStateChanges = {}
    Object.keys(payload).forEach((fieldName) => {
        const field = payload[fieldName]
        const validationList = validations[fieldName]

        let settedField = {
            value: field.value,
            message: null,
            valid: true
        }

        if (validationList) {
            for (var index = 0; index < validationList.length; index++) {
                const check = validationList[index]
                const response = check(field, state)

                if (response.result === 'end') {
                    break
                }

                if (!response.result) {
                    settedField.valid = false
                    settedField.message = response.message
                    break
                }
            }
        }
        newStateChanges = { ...newStateChanges, [fieldName]: settedField }
    })
    return { ...state, ...newStateChanges }
}

// Checks the validation of a field
export const setAndCheckValidationAlternative = (state, payload, validations) => {
    let newStateChanges = {}
    Object.keys(payload).forEach((fieldName) => {
        const field = payload[fieldName]
        const validationList = validations[fieldName]

        let settedField = {
            value: field.value,
            message: null,
            valid: true
        }

        if (validationList) {
            for (var index = 0; index < validationList.length; index++) {
                const check = validationList[index]
                const response = check(field.value, state)

                if (response.result === 'end') {
                    break
                }

                if (!response.result) {
                    settedField.valid = false
                    settedField.message = response.message
                    break
                }
            }
        }

        newStateChanges = { ...newStateChanges, [fieldName]: settedField }
    })

    return { ...state, ...newStateChanges }
}

type CommissionForm = {
    uuid: string,
    name: string,
    isCustom: boolean,
    commission: FormField<string>
}

// Check commissions validation
export const checkCommissionsValidation = (form: CommissionForm[]) => {
    let invalid = false

    const updatedForm = form.map((el) => {
        if(el.isCustom && el.commission.value === "") {
            invalid = true
            return {
                ...el,
                commission: {
                    ...el.commission,
                    valid: false,
                    message: i18n.t("validations.mandatory")
                }
            }
        } else if (el.isCustom && isNaN(Number.parseFloat(el.commission.value))) {
            invalid = true
            return {
                ...el,
                commission: {
                    ...el.commission,
                    valid: false,
                    message: i18n.t("validations.invalidNumber")
                }
            }
        } else {
            return el
        } 
    })

    return { invalid, form: updatedForm }
}

// Validates options whose value relies on an ID that can't be lower than 0
export const isValidId = (value, state) => {
    if (value < 0 || isNaN(value)) {
        return { result: false, message: i18n.t("validations.invalidOption") }
    }
    return { result: true }
}

// Validates passwords
export const passwordsMatch = (value, state) => {
    if (value !== state.password.value) {
        return { result: false, message: i18n.t("validations.passwordsMatch") }
    }
    return { result: true }
}

// Validates and email
export const Email = (value, state) => {
    const message = i18n.t("validations.invalidEmail")
    if(value) {
        const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        if (!regex.test(value)) {
            return { result: false, message }
        }
    }
    return { result: true }
}

// Validates if a field is set
export const isRequired = (value, state) => {
    const message = i18n.t("validations.mandatory")

    if (!value && value !== false && value !== 0) {
        return { result: false, message }
    }

    return { result: true }
}

export const hasMoreThan3dp = (value, state) => {
    const message = i18n.t("validations.hasMoreThan3dc")

    if (value.includes('.')) {
        const decimalCount = value.split('.')[1]
        if(decimalCount.length > 3) {
            return { result: false, message }
        }
    }

    return { result: true }
}

// Used to validate unrequired fields
export const hasValue = (value, state) => {
    if (!value) {
        return { result: 'end' }
    }
    return { result: 'continue' }
}

export const isRequiredArray = (array) => {
    const message = i18n.t("validations.mandatory")
    if (array == null || !Array.isArray(array) || array.length === 0) {
        return { result: false, message }
    }

    return { result: true }
}

// Validates if its a valid postal code
export const isValidPostalCode = (value, state) => {
    const message = i18n.t("validations.invalidPostalCode")
    const regex = /^\d{4}-\d{3}$/
    if (!regex.test(value)) {
        return { result: false, message }
    }

    return { result: true }
}

// Validates if its a valid phone number
export const isValidPhoneNumber = (value, state) => {
    const message = i18n.t("validations.invalidPhoneNum")
    const regex = /^\d{9}$/
    if (!regex.test(value)) {
        return { result: false, message }
    }

    return { result: true }
}

export const isNumber = (value, state) => {
    const message = i18n.t("validations.invalidNumber")
    if (isNaN(value)) {
        return { result: false, message }
    }

    return { result: true }
}

export const isToLong = (value, state) => {
    const message = i18n.t("validations.invalidLength")
    if (typeof value === "string" && value.length > 255) {
        return { result: false, message }
    }

    return { result: true }
}

export const isInteger = (value, state?) => {
    const message = i18n.t("validations.invalidInteger")

    if (isNaN(value)) {
        return { result: false, message }
    }
    else if (!isNaN(value) && !Number.isInteger(Number(value))) {
        return { result: false, message }
    }
    return { result: true }
}

export const isBiggerThanZero = (value, state) => {
    const message = i18n.t("validations.invalidProfile")
    if (value < 1) {
        return { result: false, message }
    }

    return { result: true }
}

// Validates if its a valid website
export const isValidWebsite = (value, state) => {
    const message = i18n.t("validations.invalidWebsite")
    const regex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/
    if (!regex.test(value)) {
        return { result: false, message }
    }

    return { result: true }
}

export const isPositiveAge = (value, state) => {
    const message = i18n.t("validations.invalidAge")

    if (value <= 0) {
        return { result: false, message }
    } else {
        return { result: true }
    }
}

// validates MAC addresses
export const isMACAddress = (value, state) => {
    const message = `Ìnvalid MAC format`
    const regex = /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/

    const assertion = regex.test(value)
    if (assertion === true) {
        return { result: true }
    } else {
        return { result: false, message }
    }
}

// validates if birth year is in a valid format
export const isValidBirthDate = (value, state) => {
    const message = i18n.t("validations.invalidBirthYear");
    const regex = /^(19|20)\d{2}$/g

    if (!value.format) {
        return { result: false, message }
    }

    if ((!value.isValid || !value.isValid()) && value.length !== 4) {
        return { result: false, message }
    }

    const valueYear = value.format('yyyy')
    const assertion = regex.test(valueYear)

    if (assertion === true) {
        return { result: true }
    } else {
        return { result: false, message }
    }
}

// validates if time format is in hh:mm 
export const isCorrectTimeFormat = (value, state) => {
    const message = i18n.t("validations.invalidTimeFormat");
    const regex = /([0-9]){2}:([0-5][0-9])/g


    const assertion = regex.test(value)
    if (assertion === true) {
        if (value.split(':').length === 2) {
            const values = value.split(':')
            if (isInteger(values[0]).result && (isInteger(values[1]).result && values[1].length === 2 && Number(values[1]) <= 59)) {
                return { result: true, message }
            }
            else {
                return { result: false, message }
            }

        }
    }
    return { result: false, message }
}

export const isValidDate = (value, state) => {
    const message = i18n.t("validations.invalidDate");

    const date = DateTime.fromISO(value)
    if (date.invalid !== null) {
        return { result: false, message }
    }
    else {
        return { result: true, message }
    }

}