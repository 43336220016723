import axios from 'axios'
import endpoints from 'api/endpoints'
import { decamelizeKeys } from 'humps'
import { generateUrl, generateSortString } from 'utils'
import { toast } from 'react-toastify'
import { ActivityFormValidations } from 'scenes/TripForms/Tripactivityform/utils'
import { SortType } from 'types/misc'
/* import { generateUrl } from 'utils'
import { toast } from 'react-toastify'
import i18n from 'i18next' */

const GetActivities = (page: number, searchTerm: string, sort?: SortType) => {
    const searchTermQS = `${searchTerm ? `&search=${searchTerm}` : ''}`;

    let endpoint = `${endpoints.TRIPS.ACTIVITIES.GET_ACTIVITIES}?page=${page}${searchTermQS}`;

    if (sort && sort.field !== null && sort.direction !== null && sort.direction !== "") {
        endpoint += generateSortString(sort);
    }
    return axios.get(endpoint)
        .then(res => res.data)
        .catch(err => {
        })
}

const GetAllActivities = () => {
    return axios.get(endpoints.TRIPS.ACTIVITIES.GET_ALL_ACTIVITIES)
        .then(res => res.data)
        .catch(err => {
        })
}

const GetActivity = (id) => {
    const endpoint = generateUrl(endpoints.TRIPS.ACTIVITIES.GET_ACTIVITY, { id })
    return axios.get(endpoint)
        .then(res => res.data)
        .catch(err => {
        })
}

const CreateActivity = (params) => {
    const token = window.localStorage.getItem('access_token')

    let finalParams = {}
    Object.keys(ActivityFormValidations).forEach(valKey => {
        if (valKey === 'maxCapacity') {
            finalParams[valKey] = Number(params.activityForm[valKey].value)
        } else if (valKey === 'minimumPrice') {
            if (params.activityForm.isPrivate.value) {
                finalParams[valKey] = Number(params.activityForm.minimumPrice.value)
            }
        } else if (valKey === 'includedSeats') {
            if (params.activityForm.isPrivate.value) {
                finalParams[valKey] = Number(params.activityForm.includedSeats.value)
            }
        } else {
            finalParams[valKey] = params.activityForm[valKey].value
        }
    })

    if (params.vehicles) {
        const vehicleUUID: any = Array.from([])
        params.vehicles.forEach((vehicle) => {
            if (vehicle.isOn.value) {
                vehicleUUID.push(vehicle.uuid)
            }
        })
        finalParams['vehicles'] = vehicleUUID
    }

    if (params.circuits) {
        const circuitID: any = Array.from([])
        params.circuits.forEach((circuit) => {
            if (circuit.isOn.value) {
                circuitID.push(circuit.id)
            }
        })
        finalParams['circuits'] = circuitID
    }

    if (params.extras) {
        const extras: any = Array.from([])
        params.extras.forEach((extra) => {

            if (extra.isOn.value) {
                extras.push({ id: extra.id, price: extra.price.value, maxCapacity: extra.maxCapacity.value  })
            }
        })
        finalParams['extras'] = extras
    }

    if (params.passengerTypes) {
        const passengerTypesID: any = Array.from([])
        params.passengerTypes.forEach((passengerType) => {
            if (passengerType.isOn.value) {
                passengerTypesID.push({ id: passengerType.id, price: passengerType.price.value })
            }
        })
        finalParams['passengerTypes'] = passengerTypesID
    }

    const decMLZParams = decamelizeKeys(finalParams)

    return axios.create({
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
        }
    }).post(endpoints.TRIPS.ACTIVITIES.CREATE_ACTIVITY, decMLZParams)
        .then(res => res.data)
        .catch(err => { throw err })
}

const CreateActivityPhoto = (params) => {

    const activityPhotoFormData = new FormData();
    Object.keys(params).forEach(valKey => {
        activityPhotoFormData.append(valKey, params[valKey])
    })

    return axios.post(endpoints.TRIPS.ACTIVITIES.CREATE_ACTIVITY_PHOTO, activityPhotoFormData)
        .then(res => res.data)
        .catch(err => toast.error("Erro ao adicionar foto"))
}



const EditActivity = (id, params) => {
    const token = window.localStorage.getItem('access_token')
    const endpoint = generateUrl(endpoints.TRIPS.ACTIVITIES.EDIT_ACTIVITY, { id })

    let finalParams = {}
    Object.keys(ActivityFormValidations).forEach(valKey => {
        if (valKey === 'maxCapacity') {
            finalParams[valKey] = Number(params.activityForm[valKey].value)
        } else if (valKey === 'minimumPrice') {
            if (params.activityForm.isPrivate.value) {
                finalParams[valKey] = Number(params.activityForm.minimumPrice.value)
            }
        } else if (valKey === 'includedSeats') {
            if (params.activityForm.isPrivate.value) {
                finalParams[valKey] = Number(params.activityForm.includedSeats.value)
            }
        } else {
            finalParams[valKey] = params.activityForm[valKey].value
        }
    })

    if (params.vehicles) {
        const vehicleUUID: any = Array.from([])
        params.vehicles.forEach((vehicle) => {
            if (vehicle.isOn.value) {
                vehicleUUID.push(vehicle.uuid)
            }
        })

        finalParams['vehicles'] = vehicleUUID
    }

    if (params.circuits) {
        const circuitID: any = Array.from([])
        params.circuits.forEach((circuit) => {
            if (circuit.isOn.value) {
                circuitID.push(circuit.id)
            }
        })

        finalParams['circuits'] = circuitID
    }

    if (params.extras) {
        const extras: any = Array.from([])
        params.extras.forEach((extra) => {
            if (extra.isOn.value) {
                extras.push({ id: extra.id, price: extra.price.value, maxCapacity: extra.maxCapacity.value })
            }
        })

        finalParams['extras'] = extras
    }

    if (params.passengerTypes) {
        const passengerTypesID: any = Array.from([])
        params.passengerTypes.forEach((passengerType) => {
            if (passengerType.isOn.value) {
                passengerTypesID.push({ id: passengerType.id, price: passengerType.price.value })
            }
        })

        finalParams['passengerTypes'] = passengerTypesID
    }

    const decMLZParams = decamelizeKeys(finalParams)

    return axios.create({
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
        }
    }).patch(endpoint, decMLZParams)
        .then(res => res.data)
        .catch(err => { throw err })
}

const EditActivityPhoto = (id, params) => {
    const endpoint = generateUrl(endpoints.TRIPS.ACTIVITIES.EDIT_ACTIVITY_PHOTOS, { id })

    const activityPhotoFormData = new FormData();
    Object.keys(params).forEach(valKey => {
        activityPhotoFormData.append(valKey, params[valKey])
    })

    return axios.patch(endpoint, activityPhotoFormData)
        .then(res => res.data)
        .catch(err => toast.error("Erro ao editar foto"))
}

const DeleteStopPhoto = (id: number) => {
    const endpoint = generateUrl(endpoints.TRIPS.ACTIVITIES.DELETE_ACTIVITY_PHOTOS, { id })

    return axios.delete(endpoint)
        .then(res => res)
        .catch(err => {
            throw err
        })
}

export default {
    GetAllActivities,
    GetActivities,
    GetActivity,
    CreateActivity,
    CreateActivityPhoto,
    EditActivity,
    EditActivityPhoto,
    DeleteStopPhoto
}