import styled from 'styled-components'
import { CImage } from '@coreui/react-pro'

export const PhotoDragDiv = styled.div`
  display:flex;
  flex-wrap: wrap;
  padding:12px;
`

export const ImageWrapper = styled.div`
  display:flex;
  flex-direction:column;
  justify-content:center;
  padding-right:10px;
`

export const ImageRemoveWrapper = styled.div`
  width: 20%;
  position:relative;
  top:14px;
  right:-75%;
  display:flex;
  justify-content:flex-end;
  margin-top:1px;
  z-index:1;
`

export const Image = styled(CImage) <{ first: boolean }>`
  display:flex;
  width:100%;
  padding:2px;
  background-image: ${props => props.first ?
    `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='%23321FDBFF' stroke-width='3' stroke-dasharray='2%2c 7' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");`
    :
    0
  };
  border-radius:4px;
  justify-content:flex-end;
  margin-top:-11px;
  min-width:50px;
`

export const PDFUrl = styled.a <{ first: boolean }>`
  display:flex;
  justify-content: center;
  align-items:center;
  width:100%;
  padding:2px;
  width: 100px;
  height: 100px;
  margin-top:-11px;
  background-image: ${props => props.first ?
    `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='%23321FDBFF' stroke-width='3' stroke-dasharray='2%2c 7' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");`
    :
    0
  };
`