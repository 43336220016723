import * as React from "react"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width={props.width || 12} height={props.height || 15} viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.3334 4.83329H3.22002L6.94669 1.10663L6.00002 0.166626L0.666687 5.49996L6.00002 10.8333L6.94002 9.89329L3.22002 6.16663H11.3334V4.83329Z" fill={props.fill || "#4F5D73"} />
        </svg>

    )
}

export default SvgComponent
