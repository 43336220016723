import styled from 'styled-components'
import { CustomTableRow, ActionsCell } from 'shared/genericStyledComponents'
import { CTableDataCell } from '@coreui/react-pro'

export const CustomCTableRow = styled(CustomTableRow)`

    td:first-child {
      padding-left:15px;
      width:10%;
    }
  
    td:nth-child(2){
      width:6%;
    }

    td:nth-child(3){
      width:6%;
    }

    td:nth-child(4){
      width:6%;
      padding:0;
      padding-left:7px;
    }

    td:nth-child(5){
      width:100%;
    }
  `

export const NameCell = styled(CTableDataCell)`
  cursor: pointer;
`

export const CustomActionsCell = styled(ActionsCell)`
  td:nth-child(2){
    width:8%;
  }
`