/**
 * Requesttablerow component
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */

import { CTableRow } from '@coreui/react-pro'
import styled from 'styled-components'
import theme from 'ui/theme'
import { SyncStatus } from 'types/invoicing/invoices'


const CustomCTableRow = styled(CTableRow)`
    max-height: 54px;
    background-color: ${theme().white};
    border-bottom: 1px solid ${theme().gray200Color};

    > td {
        vertical-align: middle;
        font-family: Inter;
        font-size: 14px;
        color: ${theme().gray700Color};
    }

    td:first-child {
        padding-left:15px;
    }
`
const BookingNumber = styled.div`
  display:flex;
  justify-content: flex-start;
  column-gap: 8px;
`
const Text = styled.p<{syncStatus: string}>`
  margin: 0px;
  color: ${props => props.syncStatus === SyncStatus.SUCCESS ? theme().success : props.syncStatus === SyncStatus.FAILED ? theme().danger : theme().warning};
`

const InvoicingTypeLabel = styled.span`
    padding:2px 4px;
    color:${theme().white};
    font-weight:700;
    border-radius:5px;

    &.credit {
        background-color: ${theme().danger};
    }
    &.invoicing {
        background-color: ${theme().success};
    }
`

export {
  CustomCTableRow,
  BookingNumber,
  Text,
  InvoicingTypeLabel
}