/**
 * Tripcategoryform component
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */

import { CCol, CForm, CRow } from '@coreui/react-pro'
import { useParams } from 'react-router-dom';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { actions, RootState } from 'store/rootSlices'

import CustomTextInput from 'shared/components/CustomTextInput'
import SectionContainer from 'shared/components/Sectioncontainer'
import ContainerDelete from 'shared/components/FormComponents/containerdelete';
import CustomButton from 'shared/components/CustomButton';
import theme from 'ui/theme';
import CustomCheckbox from 'shared/components/CustomCheckbox';
import TableContainer from 'shared/components/TableContainer';
import Circuitstoptablerow from './components/circuitstoptablerow';
//import CustomModal from 'shared/components/CustomModal';
import i18n from 'i18next';
import config from 'config';
import { hasPermission } from 'permissions';
import { ButtonWrapper } from '../styles'; 

const Tripcicuitform = (props) => {

  const dispatch = useDispatch();
  const { id } = useParams();

  const { 
    onFormChange, 
    onMount, 
    onUnmount, 
    toogleAllInboundStops, 
    onInboundStopChange, 
    onOutboundStopChange, 
    toogleAllOutboundStops, 
    onSubmit 
  } = actions.TripCircuitForm;
  const { 
    edit, 
    circuitForm, 
    inboundStops, 
    outboundStops, 
    inboundStopsEnabled, 
    outboundStopsEnabled, 
    hasEditPermission,
    submitButtonState
  } = useSelector((state: RootState) => state.TripCircuitForm)
  const { navigateBack } = actions.App;
  const { permissions } = useSelector((state: RootState) => state.App)
  const { PERMISSIONS } = config


  useEffect(() => {
    dispatch(onMount(id))

    return () => {
      dispatch(onUnmount())
    }

  }, [dispatch, id, onMount, onUnmount]);

  const handleSubmit = (e) => {
    dispatch(onSubmit({ circuitForm, inboundStops, outboundStops, edit }))
  }

  return (
    <div>
      <CForm>
        {/* {edit === true && <DropdownExport id='tripCircuitForm-dropdownExport' dropdown={true} wrapperStyle={{ maxWidth: '100%' }} />} */}
        <CustomCheckbox
          id='tripCircuitForm-isActive'
          disabled={!hasEditPermission}
          wrapperStyle={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '-25px', fontSize: '20px' }}
          checked={circuitForm.isActive.value ? true : false}
          onClick={e => dispatch(onFormChange({ ...circuitForm, isActive: { value: !circuitForm.isActive.value, valid: true, message: "" } }))}
          text={i18n.t('tripCircuitForm.isActive')}
          switch
        />
        <SectionContainer title={i18n.t('tripCircuitForm.circuitTitle')} style={{ width: '100%', paddingBottom: '24px' }}>
          <CRow className="g-3">
            <CCol sm={9}>
              <CustomTextInput
                id='tripCircuitForm-name'
                disabled={!hasEditPermission}
                required={true}
                valid={circuitForm.name.valid}
                value={circuitForm.name.value}
                helpText={circuitForm.name.message}
                placeholder={i18n.t('placeholders.name')}
                label={i18n.t('tripCircuitForm.name')}
                onChange={(e) => dispatch(onFormChange({ ...circuitForm, name: { value: e.target.value, message: "", valid: true } }))}
              />
            </CCol>
            <CCol>
              <CustomTextInput
                id='tripCircuitForm-duration'
                required={true}
                disabled={true}
                valid={circuitForm.duration.valid}
                value={circuitForm.duration.value}
                helpText={circuitForm.duration.message}
                placeholder=""
                label={i18n.t('tripCircuitForm.duration')}
                onChange={(e) => dispatch(onFormChange({ ...circuitForm, duration: { value: e.target.value, message: "", valid: true } }))}
              />
            </CCol>
          </CRow>
          <div style={{ width: '100%', paddingTop: '24px' }}>
            <TableContainer
              count={-1}
              elementsPerPage={10}
              currentPage={-1}
              onPageChange={() => { }}
              headerElements={[
                i18n.t('tripCircuitForm.start'),
                i18n.t('tripCircuitForm.hour'),
                <CustomCheckbox
                  disabled={!hasEditPermission}
                  checkStyle={{ width: '40px', height: '20px', marginTop: '5px' }}
                  id='tripCircuitForm-toogleAllOutboundsStops'
                  checked={outboundStopsEnabled}
                  onClick={e => 
                    dispatch(toogleAllOutboundStops({enabled:!outboundStopsEnabled, outboundStops: outboundStops}))
                  }
                  switch
                />
              ]}
            >
              {
                outboundStops?.map((outboundStop, idx) => {
                  return (
                    <React.Fragment key={`circuit-going-${idx}`}>
                      <Circuitstoptablerow
                        onClick={(e,id) => dispatch(onOutboundStopChange({e,id: idx, outboundStops}))}
                        hasEditPermission={hasEditPermission}
                        circuitStop={outboundStop}
                        isInBound={false}
                        id={`tripCircuitForm-outboundStop${idx}`}
                        actions={
                          <React.Fragment>
                            <CustomCheckbox
                              checkStyle={{ width: '40px', height: '20px', marginTop: '5px' }}
                              id={`tripCircuitForm-outboundStop${idx}-checkBox`}
                              disabled={!hasEditPermission ||!outboundStop.stop.isActive}
                              checked={outboundStop.isActive.value}
                              onClick={e => {
                                dispatch(onOutboundStopChange({e,idx, outboundStops}))
                              }}
                              switch
                            />
                          </React.Fragment>
                        }
                      />
                    </React.Fragment>
                  )
                })
              }
            </TableContainer>
            <TableContainer
              count={-1}
              elementsPerPage={10}
              currentPage={-1}
              onPageChange={() => { }}
              headerElements={[
                i18n.t('tripCircuitForm.return'),
                i18n.t('tripCircuitForm.hour'),
                <CustomCheckbox
                  disabled={!hasEditPermission}
                  checkStyle={{ width: '40px', height: '20px', marginTop: '5px' }}
                  id='tripCategoryForm-toogleAllInboundtops'
                  checked={inboundStopsEnabled}
                  onClick={e => {
                    dispatch(toogleAllInboundStops({enabled:!inboundStopsEnabled, inboundStops: inboundStops}))
                  }}
                  switch
                />
              ]}
            >
              {
                inboundStops?.map((inboundStop, idx) => {
                  return (
                    <React.Fragment key={`circuit-inboundStops-${idx}`}>
                      <Circuitstoptablerow
                        onClick={(e,id) => dispatch(onInboundStopChange({e,id: idx, inboundStops}))}
                        hasEditPermission={hasEditPermission}
                        circuitStop={inboundStop}
                        isInBound={true}
                        id={`tripCircuitForm-inboundStop${idx}`}
                        actions={
                          <React.Fragment>
                            <CustomCheckbox
                              checkStyle={{ width: '40px', height: '20px', marginTop: '5px' }}
                              id={`tripCircuitForm-inboundStop${idx}-checkBox`}
                              disabled={!hasEditPermission || !inboundStop.stop.isActive}
                              checked={inboundStop.isActive.value}
                              onClick={e => {
                                dispatch(onInboundStopChange({e,idx, inboundStops}))
                              }}
                              switch
                            />
                          </React.Fragment>
                        }
                      />
                    </React.Fragment>
                  )
                })
              }
            </TableContainer>
          </div>
        </SectionContainer>
        {
          edit === true && hasPermission(permissions, PERMISSIONS.TRIPS.CIRCUITS.DELETE_CIRCUIT) && <ContainerDelete id='tripCircuitForm-delete' title={i18n.t('tripCircuitForm.removeCircuit')} text={<p>{i18n.t('tripCircuitForm.removeCircuitText')}<br />{i18n.t('common.deleteLine2')}</p>}></ContainerDelete>
        }
        <ButtonWrapper>
          <CustomButton id='tripCategoryForm-cancel' onClick={() => dispatch(navigateBack())} text={i18n.t('common.cancelBtn')} color="link" buttonStyle={{ color: theme().danger, textDecoration: 'unset' }} />
          {
            edit ?
              hasPermission(permissions, PERMISSIONS.TRIPS.CIRCUITS.EDIT_CIRCUIT) &&
              <CustomButton
                id='tripCircuitForm-save'
                disabled={submitButtonState}
                text={i18n.t('common.saveBtn')}
                icon="confirm"
                onClick={e => { handleSubmit(e) }}
                color="success"
                buttonStyle={{ color: "#FFFFFF" }} />
              :
              hasPermission(permissions, PERMISSIONS.TRIPS.CIRCUITS.CREATE_CIRCUIT) &&
              <CustomButton
                id='tripCircuitForm-save'
                disabled={submitButtonState}
                text={i18n.t('common.saveBtn')}
                icon="confirm"
                onClick={e => { handleSubmit(e) }}
                color="success"
                buttonStyle={{ color: "#FFFFFF" }} />
          }
        </ButtonWrapper>
      </CForm>
    </div>
  )
}

export default Tripcicuitform
