/**
 * Photoscomponent component
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */

import React, { useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import Remove from 'assets/icons/remove';
import PDF from 'assets/icons/pdf_icon'
import Imagepreviewer from '../Imagepreviewer';
import * as Styles from './styles'

interface PropTypes {
  id?: string
  array?: Array<any>,
  disableDrag?: boolean,
  noSignal?: boolean,
  cantDelete?: boolean,
  onDrag: Function,
  onDelete: Function,
}

const Photoscomponent = (props: PropTypes) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [imgPreviewIdx, setImgPreviewIdx] = useState<number>(0)

  let imgArray: any[] = []
  if(props.array) {
    props.array.forEach((item) => {
      if(item.new && !item.file.name.includes('.pdf')) {
        imgArray.push({file: URL.createObjectURL(item.file), id: item.id})
      } else if(!item.new && !item.file.includes('.pdf')) {
        imgArray.push({file: item.file, id: item.id})
      }
    })
  }

  const handleImgClick = (value) => {
    let imgIdx: number = imgArray?.findIndex((img) => img.id === value.id)
    setIsOpen(true)
    if(imgIdx) {
      setImgPreviewIdx(imgIdx)
    } else {
      setImgPreviewIdx(0)
    }
  }

  return (
    <>
      <DragDropContext onDragEnd={e => props.onDrag(e)}>
        <Droppable droppableId={`${props.id}-photosDroppable`} direction="horizontal">
          {
            (provided) => (
              <Styles.PhotoDragDiv id={`${props.id}-photosDragDiv`} {...provided.droppableProps} ref={provided.innerRef}>
                {
                  props.array?.map((value, index) => {
                    return (
                      <Draggable isDragDisabled={props.disableDrag} key={value.id.toString()} draggableId={`${props.id}-photosDraggable-${value.id.toString()}`} index={index}>
                        {
                          (provided) => (
                            <Styles.ImageWrapper id={`${props.id}-photoWrapper-${value.id.toString()}`} {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef} >
                              {
                                !props.cantDelete && <Styles.ImageRemoveWrapper id={`${props.id}-deletePhoto-${value.id.toString()}`} onClick={e => { props.onDelete(e, index) }}>
                                  <Remove />
                                </Styles.ImageRemoveWrapper>
                              }
                              {
                                !value.new ?
                                  !value.file.includes('.pdf') ?
                                    <div
                                      onClick={() => handleImgClick(value)}
                                    >
                                      <Styles.Image
                                        id={`${props.id}-photo-${value.id.toString()}`}
                                        rounded
                                        first={index === 0 && !props.noSignal ? true : false}
                                        src={value.new === false ? value.file : URL.createObjectURL(value.file)}
                                        width={100}
                                        height={100} 
                                      />
                                    </div>

                                    :
                                    <Styles.PDFUrl
                                      first={index === 0 && !props.noSignal ? true : false}
                                      href={value.new === false ? value.file : URL.createObjectURL(value.file)}
                                      rel="noopener noreferrer"
                                      target="_blank">
                                      <PDF />
                                    </Styles.PDFUrl>
                                  :
                                  !value.file.name.includes('.pdf') ?
                                    <div
                                      onClick={() => handleImgClick(value)}
                                    >
                                      <Styles.Image
                                        id={`${props.id}-photo-${value.id.toString()}`}
                                        rounded
                                        first={index === 0 && !props.noSignal ? true : false}
                                        src={URL.createObjectURL(value.file)}
                                        width={100}
                                        height={100} />
                                    </div>
                                    :
                                    <Styles.PDFUrl
                                      first={index === 0 && !props.noSignal ? true : false}
                                      href={URL.createObjectURL(value.file)}
                                      rel="noopener noreferrer"
                                      target="_blank">
                                      <PDF />
                                    </Styles.PDFUrl>
                              }
                            </Styles.ImageWrapper>
                          )
                        }
                      </Draggable>
                    )
                  })
                }
                {provided.placeholder}
              </Styles.PhotoDragDiv>
            )
          }
        </Droppable>
      </DragDropContext>

      { isOpen && (
        <Imagepreviewer 
          setIsOpen={setIsOpen}
          images={imgArray}
          currentIdx={imgPreviewIdx}
        />
      )}
    </>
  )
}

export default Photoscomponent