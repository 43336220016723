/**
 * Fuellocationconfigform scene sagas
 *
 * @author João Dias <jdias@ubiwhere.com>
 *
 *
 */
import api from 'api';
import { PayloadAction } from '@reduxjs/toolkit';
import { takeLatest, putResolve, call, select } from 'redux-saga/effects'
import { actions, RootState } from 'store/rootSlices'
import { FleetLocation } from 'types/fleet/location';
import { defaultFuelLocationForm, fuelLocationConfigValidations, FuelLocationForm } from '../utils';
import * as Check from "validations";
import { toast } from 'react-toastify';
import i18n from 'i18next';
import config from 'config';
import { hasPermission } from 'permissions'
const { PERMISSIONS } = config

function* onMountSaga({ payload }: PayloadAction<number>) {
  const { permissions } = yield select((state: RootState) => state.App);

  try {
    if (payload) {
      yield putResolve(actions.Fuellocationconfigform.setEditMode(true));
      yield putResolve(actions.Fuellocationconfigform.setHasEditPermission(hasPermission(permissions, PERMISSIONS.FLEET.EDIT_FLEET_SETTINGS)))
      yield putResolve(actions.Fuellocationconfigform.getFuelLocationConfig(payload));
    } else {
      yield putResolve(actions.Fuellocationconfigform.setEditMode(false));
      yield putResolve(actions.Fuellocationconfigform.setForm(defaultFuelLocationForm));
    }
  } finally {
    yield putResolve(actions.Fuellocationconfigform.setLoading(false));
  }
}

function* getFuelLocationConfigSaga({ payload }: PayloadAction<number>) {
  try {
    const res: FleetLocation = yield call(api.Fleet.GetSingleFuelLocation, payload);
    yield putResolve(actions.Fuellocationconfigform.setFuelLocation(res));
  } catch (error) {
    toast.error(i18n.t('toasts.getFuelLocationError'));
  } finally {
    yield putResolve(actions.Fuellocationconfigform.setLoading(false));
  }
}

function* setFuelLocationSaga({ payload }: PayloadAction<FleetLocation>) {
  const form: FuelLocationForm = {
    isActive: {
      value: payload.isActive,
      message: "",
      valid: true,
    },
    isMain: {
      value: payload.isMain,
      message: "",
      valid: true,
    },
    isTank: {
      value: payload.isTank,
      message: "",
      valid: true,
    },
    name: {
      value: payload.name,
      message: "",
      valid: true,
    },
    address: {
      value: payload.address,
      message: "",
      valid: true,
    },
    city: {
      value: payload.city,
      message: "",
      valid: true,
    },
    fuelCapacity: {
      value: payload.fuelCapacity,
      message: "",
      valid: true,
    },
  }

  yield putResolve(actions.Fuellocationconfigform.setForm(form));
}

function* onSubmitSaga() {
  const { fuelLocationForm, edit, fuelLocation } = yield select((state: RootState) => state.Fuellocationconfigform);
  yield putResolve(actions.Fuellocationconfigform.setSubmitButtonDisabled(true))

  const validation = Check.checkValidation(fuelLocationForm, fuelLocationConfigValidations);
  if (validation.invalid) {
    yield putResolve(actions.Fuellocationconfigform.setForm(validation.form));
    toast.error(i18n.t('toasts.invalidFields'));
    yield putResolve(actions.Fuellocationconfigform.setSubmitButtonDisabled(false))
    return false;
  }

  let body = {}

  body = {
    name: fuelLocationForm.name.value,
    address: fuelLocationForm.address.value,
    city: fuelLocationForm.city.value,
    is_main: fuelLocationForm.isMain.value,
    is_tank: fuelLocationForm.isTank.value,
    is_active: fuelLocationForm.isActive.value,
  }

  if (fuelLocationForm.isTank.value === true) {
    body = {
      ...body,
      fuel_capacity: Number(fuelLocationForm.fuelCapacity.value)
    }
  }

  try {
    yield putResolve(actions.Fuellocationconfigform.setLoading(true));
    if (edit) {
      const res = yield call(api.Fleet.EditFuelLocation, body, fuelLocation.id);
      if (res) yield putResolve(actions.App.navigateTo("/frota/configuracoes"));
    } else {
      const res = yield call(api.Fleet.CreateFuelLocation, body);
      if (res.status === 201) {
        yield putResolve(actions.App.navigateTo("/frota/configuracoes"));
      }
    }
  } catch (err) {
  } finally {
    yield putResolve(actions.Fuellocationconfigform.setSubmitButtonDisabled(false))
    yield putResolve(actions.Fuellocationconfigform.setLoading(false));
  }
}

export default function* watcherSignin() {
  yield takeLatest('Fuellocationconfigform/onMount', onMountSaga)
  yield takeLatest('Fuellocationconfigform/getFuelLocationConfig', getFuelLocationConfigSaga)
  yield takeLatest('Fuellocationconfigform/setFuelLocation', setFuelLocationSaga)
  yield takeLatest('Fuellocationconfigform/onSubmit', onSubmitSaga)
}


