import * as React from "react"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width={props.width || "24"} height={props.height || "24"} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_1119_97056)">
                <circle cx="12" cy="11" r="10" fill={props.fill} />
                <path d="M12.5002 6.5V7H11.5002V6.5H12.5002ZM12.0002 10.055L14.6902 10.94L15.8852 11.33L15.3252 13.315C15.0552 13.165 14.8552 12.96 14.7552 12.845L14.0002 11.98L13.2452 12.84C13.0752 13.04 12.6052 13.5 12.0002 13.5C11.3952 13.5 10.9252 13.04 10.7552 12.84L10.0002 11.98L9.24519 12.84C9.14519 12.955 8.94519 13.155 8.67519 13.305L8.11019 11.325L9.31019 10.93L12.0002 10.055ZM13.5002 5.5H10.5002V7H9.00019C8.45019 7 8.00019 7.45 8.00019 8V10.31L7.35519 10.52C7.22519 10.56 7.11519 10.65 7.05519 10.77C6.99519 10.89 6.98019 11.03 7.02519 11.16L7.97519 14.5H8.00019C8.80019 14.5 9.51019 14.06 10.0002 13.5C10.4902 14.06 11.2002 14.5 12.0002 14.5C12.8002 14.5 13.5102 14.06 14.0002 13.5C14.4902 14.06 15.2002 14.5 16.0002 14.5H16.0252L16.9702 11.16C17.0102 11.03 17.0002 10.89 16.9402 10.77C16.8802 10.65 16.7702 10.56 16.6402 10.52L16.0002 10.31V8C16.0002 7.45 15.5502 7 15.0002 7H13.5002V5.5ZM9.00019 9.985V8H15.0002V9.985L12.0002 9L9.00019 9.985ZM14.0002 14.84C13.3902 15.265 12.6952 15.48 12.0002 15.48C11.3052 15.48 10.6102 15.265 10.0002 14.84C9.39019 15.265 8.69519 15.5 8.00019 15.5H7.00019V16.5H8.00019C8.69019 16.5 9.37019 16.325 10.0002 16.005C10.6302 16.325 11.3152 16.49 12.0002 16.49C12.6852 16.49 13.3702 16.33 14.0002 16.005C14.6302 16.33 15.3102 16.5 16.0002 16.5H17.0002V15.5H16.0002C15.3052 15.5 14.6102 15.265 14.0002 14.84Z" fill="white" />
            </g>
            <defs>
                <filter id="filter0_d_1119_97056" x="0" y="0" width="24" height="24" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="1" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.831373 0 0 0 0 0.658824 0 0 0 0 0.917647 0 0 0 0.25 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1119_97056" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1119_97056" result="shape" />
                </filter>
            </defs>
        </svg>


    )
}

export default SvgComponent
