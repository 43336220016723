import { actions, RootState } from 'store/rootSlices'
import { useSelector, useDispatch } from 'react-redux'
import React from 'react'
import SectionContainer from '../../shared/components/Sectioncontainer/index';
import CustomTextInput from '../../shared/components/CustomTextInput/index';
import FormComponent from '../../shared/components/FormComponents/index';
import { CCol, CRow } from '@coreui/react-pro';
import { country_codes } from 'shared/components/FormComponents/utils';
import i18n from "i18next";
import _ from 'lodash'

const ContatoEmergencia = (props) => {
    const dispatch = useDispatch();
    const dbDispatch = _.debounce(dispatch, 300);

    const { onFormChange } = actions.userForm;
    const { userForm, hasEditPermission } = useSelector((state: RootState) => state.UserForm);

    return (
        <SectionContainer required title={i18n.t('userForm.title2')} style={{ width: '100%', paddingBottom: '24px' }}>
            <CRow className="g-3">
                <CCol>
                    <CustomTextInput
                        id='userForm-emergencyContactName'
                        disabled={!hasEditPermission}
                        valid={userForm.emergencyContactName.valid}
                        initialValue={userForm.emergencyContactName.value || ""}
                        helpText={userForm.emergencyContactName.message}
                        placeholder={i18n.t('placeholders.name')}
                        label={i18n.t('userForm.labelName')}
                        onChange={(e) => dbDispatch(onFormChange({ ...userForm, emergencyContactName: {value: e.target.value, message:"", valid:true}}))}
                    />
                </CCol>
                <CCol>
                    <FormComponent 
                        id='userForm-emergencyContactPhone'
                        disabled={!hasEditPermission}
                        valid={userForm.emergencyContactPhone.valid}
                        value={userForm.emergencyContactPhone.value}
                        helpText={userForm.emergencyContactPhone.message}
                        dropdown={false} 
                        label={i18n.t('userForm.labelPhone')} 
                        placeholder="Portugal" 
                        onChange={(e) => dbDispatch(onFormChange({ ...userForm, emergencyContactPhone: {value: e, message:"", valid:true}}))}
                        options={country_codes}
                    />
                </CCol>
            </CRow>
        </SectionContainer>
    )
}

export default ContatoEmergencia
