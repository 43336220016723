/**
 * Usercashmanagement scene slice
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */
import { createSlice } from '@reduxjs/toolkit'
import { Statement, StatementStatus } from 'types/invoicing/statements'
interface initialState {
  userID: string | null,
  userStatementName: string | null,
  loading: boolean,
  entriesPage: number,
  startDate: string,
  searchTerm: string,
  statement: Statement | null,
  statementStatus: StatementStatus,
  statementEntries: any[],
  statementEntriesTotal: number,
  showModal: { show: boolean, id: number },
}

const now = new Date().toISOString().slice(0, 10);
const initialState: initialState = {
  userID: null,
  userStatementName: null,
  loading: false,
  entriesPage: 1,
  startDate: now,
  searchTerm: "",
  statement: null, //create type
  statementStatus: StatementStatus.CLOSED,
  statementEntries: [],
  statementEntriesTotal: 0,
  showModal: { show: false, id: 0 },
}

export default createSlice({
  name: 'Usercashmanagement',
  initialState,
  reducers: {
    onMount: (state, { payload }) => { },
    onUnmount: () => { },
    setLoading: (state, { payload }) => {
      state.loading = payload
    },
    setShowModal: (state, { payload }) => {
      state.showModal = {
        show: payload.show,
        id: payload.id
      }
    },
    closeModal: (state) => {
      state.showModal = {
        show: false,
        id: 0
      }
    },
    setEntriesPage: (state, { payload }) => {
      state.entriesPage = payload
    },
    setSearchTerm: (state, { payload }) => {
      state.entriesPage = 1
      state.searchTerm = payload
    },
    setUserID: (state, { payload }) => {
      state.userID = payload
    },
    setUserStatementName: (state, { payload }) => {
      state.userStatementName = payload
    },
    getStatement: () => { },
    getStatementEntries: () => {
    },
    setStatementEntries: (state, { payload }) => {
      state.statementEntries = payload
    },
    setStatementEntriesTotal: (state, { payload }) => {
      state.statementEntriesTotal = payload
    },
    setStatement: (state, { payload }) => {
      state.statement = payload
    },
    setStatementStatus: (state, { payload }) => {
      const isClosed = payload

      if (isClosed) {
        state.statementStatus = StatementStatus.CLOSED
      }
      else {
        state.statementStatus = StatementStatus.OPEN
      }

    },
    openStatement: () => { },
    closeStatement: () => { },
    getURLParams: (state, { payload }) => { },
    populateFiltersOnMount: (state, { payload }) => {
      state.searchTerm = payload.searchTerm
    },
  }
})