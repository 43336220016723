import styled from 'styled-components'

import { CTableDataCell } from '@coreui/react-pro'
import theme from 'ui/theme'

export const PriceCell = styled(CTableDataCell)`
  font-size: 12px !important;
  b {
    display: flex;
    flex-direction: column;
    font-weight: bold;
    font-size: 14px;
    &.less {
        color: ${theme().danger};
    }
    &.more {
        color: ${theme().success};
    }
  }
`;
