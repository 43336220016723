/**
 * Entityform scene sagas
 *
 * @author João Dias <jdias@ubiwhere.com>
 *
 *
 */
import api from 'api';
import { Entity } from 'types/entity/entity'
import { PayloadAction } from '@reduxjs/toolkit';
import { takeLatest, putResolve, call, select } from 'redux-saga/effects'
import { actions, RootState } from 'store/rootSlices';
import { activityCommissionForm, defaultEntityForm, entityFormValidationsNoTax, entityFormValidationsTax, extraCommissionForm } from '../utils';
import { EntityDocumentsForm, EntityForm } from 'types/entity/entity'
import * as Check from "validations";
import { toast } from 'react-toastify';
import i18n from "i18next";
import API from 'api'
import { hasPermission } from 'permissions'
import config from 'config';
const { PERMISSIONS } = config

function* onMountSaga({ payload }: PayloadAction<number>) {

  const { permissions } = yield select((state: RootState) => state.App);
  let { allActivities, allExtras } = yield select((state: RootState) => state.TripsConfiguration);

  if(allActivities?.length === 0 || allExtras?.length === 0) {
    allActivities = yield call(API.Activities.GetAllActivities)
    allExtras = yield call(API.Extras.GetAllExtras)
  }
  
  if (allActivities && allExtras) {
    yield putResolve(actions.Entityform.setEntityCommissions({ activities: allActivities, extras: allExtras }))
  }
  
  if (payload) {
    yield putResolve(actions.Entityform.setEditMode(true));
    yield putResolve(actions.Entityform.setHasEditPermission(hasPermission(permissions, PERMISSIONS.USERS.EDIT_PARTNER)))
    yield putResolve(actions.Entityform.fetchEntity(payload));
  } else {
    yield putResolve(actions.Entityform.setEditMode(false));
    yield putResolve(actions.Entityform.setForm(defaultEntityForm));
    yield putResolve(actions.Entityform.setLoading(false));
  }
}

function* onUnmountSaga() {
  yield putResolve(actions.Entityform.setForm(defaultEntityForm));
}

function* fetchEntitySaga({ payload }: PayloadAction<number>) {
  yield putResolve(actions.Entityform.setLoading(true));

  try {
    const res: Entity = yield call(api.Entities.GetSingleEntity, payload);
    yield putResolve(actions.Entityform.setEntity(res));
    yield putResolve(actions.Entityform.setBaseUrl(res.affiliate.baseUrl));
  } catch (error) {
    toast.error(i18n.t("toasts.getEntityError"))
  } finally {
    yield putResolve(actions.Entityform.setLoading(false));
  }
}

function* populateFormSaga({ payload }: PayloadAction<Entity>) {
  let form = {};
  const activitiesCommissionForm: Array<activityCommissionForm> = yield select((state: RootState) => state.Entityform.activitiesCommissionForm);
  const extrasCommissionForm: Array<extraCommissionForm> = yield select((state: RootState) => state.Entityform.extrasCommissionForm);

  let docs = payload.documents.map(doc => {
    return {
      dirty: false,
      expiration: {
        value: doc.expiration,
        valid: true,
        message: ""
      },
      url: doc.file,
      fileName: doc.name,
      id: {
        value: doc.id,
        valid: true,
        message: ""
      },
      isActive: {
        value: doc.isActive,
        valid: true,
        message: ""
      }
    }
  });

  try {
    Object.keys(defaultEntityForm).forEach(key => {
      if (key === "partnerType") {
        form[key] = {
          ...defaultEntityForm[key],
          value: payload[key] ? payload[key].id : ""
        }
      } else if (key === "url") {
        form[key] = {
          ...defaultEntityForm[key],
          value: payload['affiliate'] ? payload['affiliate'].url : ""
        }
      }
      else {
        form[key] = {
          ...defaultEntityForm[key],
          value: payload[key]
        }
      }
    });

    yield putResolve(actions.Entityform.setForm(form));
    yield putResolve(actions.Entityform.setDocumentForm(docs));
    yield putResolve(actions.Entityform.populateEntityCommissionsOnMount({ 
      extrasCommissionForm, 
      activitiesCommissionForm, 
      extras: payload.extraCommissions, 
      activities: payload.activityCommissions,
      activityDefaultCommission: payload.activitiesCommissionDefault,
      extrasDefaultCommission: payload.extrasCommissionDefault
    }))
  } catch (err) {
    toast.error(i18n.t("toasts.populateFormError"))
  } finally {
    yield putResolve(actions.Entityform.setLoading(false));
  }
}

function* onSubmitSaga() {
  const form: EntityForm = yield select((state: RootState) => state.Entityform.entityForm);
  const activitiesCommissionForm: Array<activityCommissionForm> = yield select((state: RootState) => state.Entityform.activitiesCommissionForm);
  const extrasCommissionForm: Array<extraCommissionForm> = yield select((state: RootState) => state.Entityform.extrasCommissionForm);
  const isEdit = yield select((state: RootState) => state.Entityform.edit);
  const entity = yield select((state: RootState) => state.Entityform.entity);
  const entityForm =  yield select((state: RootState) => state.Entityform.entityForm);

  yield putResolve(actions.Entityform.setSubmitButtonDisabled(true))
  const activitiesCommissionsValidation = Check.checkCommissionsValidation(activitiesCommissionForm)
  const extrasCommissionsValidation = Check.checkCommissionsValidation(extrasCommissionForm)
  const validations = form.useSpecificTaxDetails.value ? entityFormValidationsTax : entityFormValidationsNoTax;

  const validation = Check.checkValidation(form, validations);

  if (validation.invalid || activitiesCommissionsValidation.invalid || extrasCommissionsValidation.invalid) {
    yield putResolve(actions.Entityform.updateValidationsOnEntityCommissions({ activities: activitiesCommissionsValidation.form, extras: extrasCommissionsValidation.form }))
    yield putResolve(actions.Entityform.setForm(validation.form));
    yield putResolve(actions.Entityform.setSubmitButtonDisabled(false))
    toast.error(i18n.t('toasts.invalidFields'));
    return false;
  }

  const activeActivitiyCommissions = activitiesCommissionForm.filter(act => act.isCustom)
  const activeExtrasCommissions = extrasCommissionForm.filter(ext => ext.isCustom)

  let body = {
    is_active: form.isActive.value,
    print_amount_in_ticket: form.printAmountInTicket.value,
    observations: form.observations.value,
    charge_conditions: form.chargeConditions.value,
    customer_payment_mode: form.customerPaymentMode.value,
    secondary_email: form.secondaryEmail.value,
    secondary_phone: form.secondaryPhone.value,
    short_name: form.shortName.value,
    partner_type: form.partnerType.value !== "" ? form.partnerType?.value : null,
    name: form.name.value,
    phone: form.phone.value,
    email: form.email.value,
    address: form.address.value,
    country: form.country.value,
    postal_code: form.postalCode.value,
    city: form.city.value,
    nif: form.nif.value,
    ...(form.url.value !== "" ? { affiliate: form.url.value } : null),
    activities_commission_default: Number.parseFloat(form.activitiesCommissionDefault.value).toFixed(2),
    extras_commission_default: Number.parseFloat(form.extrasCommissionDefault.value).toFixed(2),
    activity_commissions: activeActivitiyCommissions.map((act: activityCommissionForm) => {
      return {
        activity: act.uuid,
        amount: Number.parseFloat(act.commission.value).toFixed(2)
      }
    }),
    extra_commissions: activeExtrasCommissions.map((ext: extraCommissionForm) => {
      return {
        extra: ext.uuid,
        amount: Number.parseFloat(ext.commission.value).toFixed(2)
      }
    }),

    tax_city: "",
    tax_postal_code: "",
    tax_country: "",
    tax_address: "",
    tax_nif: "",
    tax_name: "",
    use_specific_tax_details: false,
  }

  if (form.useSpecificTaxDetails.value) {
    body = {
      ...body,
      tax_city: form.taxCity.value,
      tax_postal_code: form.taxPostalCode.value,
      tax_country: form.taxCountry.value,
      tax_address: form.taxAddress.value,
      tax_nif: form.taxNif.value,
      tax_name: form.taxName.value,
      use_specific_tax_details: form.useSpecificTaxDetails.value,
    }
  }

  try {
    yield putResolve(actions.Entityform.setLoading(true));
    if (isEdit) {
      const res = yield call(api.Entities.EditEntity, entity.id, body);
      if (res.status === 200) {
        yield sendAvatar(entity.id)
        yield putResolve(actions.Entityform.onSubmitDocuments());
        yield putResolve(actions.App.navigateTo("/entidades"));
      }
    } else {
      const res = yield call(api.Entities.CreateEntity, body);
      if (res.status === 201) {
        yield sendAvatar(res.data.id)
        yield putResolve(actions.Entityform.onSubmitDocuments());
        yield putResolve(actions.App.navigateTo("/entidades"));
      }
    }
  } catch (err) {
    const error: any = err

    if(error.response.data.errors.fields.affiliate) {
      yield putResolve(actions.Entityform.onFormChange({ 
        ...entityForm, 
        url: { ...entityForm.url , message: i18n.t('validations.referralUrlDuplicate'), valid: false } 
      }))
    }
  } finally {
    yield putResolve(actions.Entityform.setSubmitButtonDisabled(false))
    yield putResolve(actions.Entityform.setLoading(false));
  }
}

function* onSubmitDocumentsSaga() {
  const {
    documentsForm,
    edit,
    entity,
    deletedDocQueue
  } = yield select((state: RootState) => state.Entityform);

  try {
    const editedDocuments = documentsForm.filter(el => !el.new && el.dirty);
    const newDocuments = documentsForm.filter(el => el.new);

    if (editedDocuments.length === 0 && newDocuments.length === 0 && deletedDocQueue.length === 0) return;
    //post new docs
    newDocuments.forEach(async (doc: EntityDocumentsForm) => {
      const body = {
        entity: entity.id,
        expiration: doc.expiration.value,
        file: doc.file
      }
      await api.Entities.CreateDocument(body);
    })
    //edit docs
    editedDocuments.forEach(async (doc: EntityDocumentsForm) => {
      const body = {
        expiration: doc.expiration.value,
      }
      await api.Entities.EditDocument(body, doc.id.value);
    })

    //delete docs
    deletedDocQueue.forEach(async (doc: EntityDocumentsForm) => {
      await api.Entities.DeleteDocument(doc.id.value);
    })
    if (!edit) yield putResolve(actions.App.navigateTo("/frota/veiculos"));

  } catch (err) {
  } finally {
    yield putResolve(actions.Entityform.emptyDeletedQueue());

  }
}

function* sendAvatar(payload: PayloadAction<number>) {

  const { entityForm, isAvatarChanged, isAvatarRemoved } = yield select((state: RootState) => state.Entityform)

  try {
    let finalForm = new FormData()
    if (isAvatarRemoved) {
      finalForm.append("avatar", "")
    }
    else if (isAvatarChanged) {
      finalForm.append("avatar", entityForm.avatar.value)
    }
    else {
      return false;
    }
    const resAvatar = yield call(API.Entities.SendAvatar, payload, finalForm)

    if (resAvatar) { }

  } catch (error) {

  }

}

export default function* watcherSignin() {
  yield takeLatest('Entityform/onMount', onMountSaga)
  yield takeLatest('Entityform/onUnmount', onUnmountSaga)
  yield takeLatest('Entityform/fetchEntity', fetchEntitySaga)
  yield takeLatest('Entityform/setEntity', populateFormSaga)
  yield takeLatest('Entityform/onSubmit', onSubmitSaga)
  yield takeLatest('Entityform/onSubmitDocuments', onSubmitDocumentsSaga)
}


