/**
 * Tripsconfiguration scene
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */
import React, { useEffect } from 'react'
import { 
  Wrapper, 
  ControlsContainer
} from 'shared/genericStyledComponents'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { actions, RootState } from 'store/rootSlices'
import theme from 'ui/theme'
import SearchBox from 'shared/components/SearchBox'
import CustomButton from 'shared/components/CustomButton'
import _ from 'lodash'

import Feed from 'assets/icons/feed'
import Print from 'assets/icons/print'
import VpnKey from 'assets/icons/vpn_key'

import TabsContainer from 'shared/components/Tabscontainer'
import TableContainer from 'shared/components/TableContainer'
import SorterComponent from 'shared/components/SorterComponent'
import Activitylisttablerow from './components/Activitylisttablerow'
import { ActivityListItem } from 'types/trips/activity/activity'
import { LocalsListItem } from 'types/trips/locals'
import { PassengerTypeListItem } from 'types/trips/passengerTypes'
import { ExtrasListItem } from 'types/trips/extras'
import { CampaignsListItem } from 'types/trips/campaigns'
import { CircuitsListItem } from 'types/trips/circuits'
import { ServicesListItem } from 'types/trips/services'

import { CategoryListItem } from 'types/trips/categories'
import Categorylisttablerow from './components/Categorylisttablerow'
import Locallisttablerow from './components/Locallisttablerow'
import Passengertypelisttablerow from './components/Passengertypelisttablerow'
import Extralisttablerow from './components/Extralisttablerow'
import Campaignlisttablerow from './components/Campaignlisttablerow'
import Circuitlisttablerow from './components/Circuitlisttablerow'
import Servicelisttablerow from './components/Servicelisttablerow'
import Loadingcontainer from 'shared/components/Loadingcontainer'
import config from 'config'
import { paginationCurrentPage, paginationElementsCount } from 'utils'
import { hasPermission } from 'permissions'

//import config from 'config'

const Tripsconfiguration = ({ t, i18n }) => {

  const { 
    tabName, 
    path, 
    activities, 
    totalActivities, 
    categories, 
    totalCategories, 
    locals, 
    totalLocals, 
    passengerTypes, 
    totalPassengerTypes, 
    extras, 
    totalExtras, 
    campaigns, 
    totalCampaigns, 
    circuits, 
    totalCircuits, 
    services, 
    totalServices, 
    loading, 
    page, 
    hasCreatePermission,
    searchTerm,
    sort,
    tabId
  } = useSelector((state: RootState) => state.TripsConfiguration)

  const { 
    onMount, 
    onUnmount, 
    setTab, 
    setPage, 
    getLocals, 
    getServices, 
    getCircuits, 
    getExtras, 
    getPassengerTypes, 
    getCategories, 
    getActivities, 
    getCampaigns, 
    setSearchTerm, 
    setHasCreatePermission,
    setSort
  } = actions.Tripsconfiguration

  const { navigateTo, /* api_t */ } = actions.App
  const { permissions } = useSelector((state: RootState) => state.App)
  const { ELEMENTS_PER_PAGE, PERMISSIONS } = config

  const history = useHistory()
  const dispatch = useDispatch()
  const dbDispatch = _.debounce(dispatch, 500);

  useEffect(() => {
    dispatch(onMount(history))
    return () => {
      dispatch(onUnmount())
    }
  }, [dispatch, onMount, onUnmount, history]);

  return (
    <Wrapper>
      <ControlsContainer>
        <div>
          <SearchBox
            id="tripsConfig-searchBox"
            defaultValue={searchTerm}
            onSubmit={(term) => {
              let params = new URLSearchParams(history.location.search);
              term !== "" ? params.set('search', term) : params.delete('search')
              history.replace({ search: (params).toString() })
              dbDispatch(setSearchTerm(term))
            }}
          />
        </div>
        <div>
          {
            hasCreatePermission && <CustomButton id="tripsConfig-newButton" onClick={() => dispatch(navigateTo(path))} text={`${tabName}`} icon="add" color="success" buttonStyle={{ color: "#FFFFFF" }} />
          }
        </div>
      </ControlsContainer>
      {
        loading ? <Loadingcontainer /> :
          <TabsContainer id="tripsConfig-tabsContainer" defaultTab={tabId} onChangeTab={tab => {dispatch(setHasCreatePermission(false)); dispatch(setTab(tab))}} content={[
            {
              title: t('tripSettings.activities'),
              content: <TableContainer
                count={paginationElementsCount(totalActivities)}
                currentPage={paginationCurrentPage(totalActivities ,page)}
                elementsPerPage={ELEMENTS_PER_PAGE}
                onPageChange={(pg) => {dispatch(setPage(pg)); dispatch(getActivities()); }}
                headerElements={[
                  "Ab.",
                  <SorterComponent sort={sort} active={sort.field === 'name'} onChange={(dir) => dispatch(setSort({ field: "name", direction: dir }))} id="tripsConfig-activities-nameSorter" filterName={t('common.name')} />,
                  t('tripSettings.activityCategory'),
                  t('tripSettings.activityDuration'),
                  t('tripSettings.commonAvailability'),
                  t('common.actions'),
                ]}
              >
                {activities.map((el: ActivityListItem, idx: number) =>
                  <React.Fragment key={`activ-${idx}`}>
                    <Activitylisttablerow
                      permission={true}
                      id={`tripsConfig-activities-tableRow-${idx}`}
                      onClick={(e) => { dispatch(actions.App.navigateTo(`/viagens/configuracoes/editar-atividade/${el.id}`)) }}
                      activity={el}
                      actions={
                        <React.Fragment>
                          <Feed fill={`${theme().secondaryColor}`}/>
                        </React.Fragment>
                      }
                    />
                  </React.Fragment>
                )}
              </TableContainer>
            },
            {
              title: t('tripSettings.categories'),
              content: <TableContainer
                count={paginationElementsCount(totalCategories)}
                currentPage={paginationCurrentPage(totalCategories ,page)}
                elementsPerPage={ELEMENTS_PER_PAGE}
                onPageChange={(pg) => {dispatch(setPage(pg)); dispatch(getCategories()); }}
                headerElements={[
                  <SorterComponent sort={sort} active={sort.field === 'name'} onChange={(dir) => dispatch(setSort({ field: "name", direction: dir }))} id="tripsConfig-categories-nameSorter" filterName={t('common.name')} />,
                  t('common.description'),
                  t('common.actions'),
                ]}
              >
                {categories.map((el: CategoryListItem, idx: number) =>
                  <React.Fragment key={`cat-${idx}`}>
                    <Categorylisttablerow
                      id={`tripsConfig-categories-tableRow-${idx}`}
                      permission={true}
                      onClick={(e) => dispatch(actions.App.navigateTo(`/viagens/configuracoes/editar-categoria/${el.id}`))}
                      category={el}
                      actions={
                        <React.Fragment>
                          <Feed fill={`${theme().secondaryColor}`}/>
                        </React.Fragment>
                      }
                    />
                  </React.Fragment>
                )}
              </TableContainer>
            },
            {
              title: t('tripSettings.locals'),
              content: <TableContainer
                count={paginationElementsCount(totalLocals)}
                currentPage={paginationCurrentPage(totalLocals ,page)}
                elementsPerPage={ELEMENTS_PER_PAGE}
                onPageChange={(pg) => {dispatch(setPage(pg)); dispatch(getLocals()); }}
                headerElements={[
                  <SorterComponent sort={sort} active={sort.field === 'name'} onChange={(dir) => dispatch(setSort({ field: "name", direction: dir }))} id="tripsConfig-locals-nameSorter" filterName={t('common.name')} />,
                  t('tripSettings.localPlace'),
                  t('tripSettings.localTimeBefore'),
                  t('tripSettings.commonAvailability'),
                  t('common.actions'),
                ]}
              >
                {locals.map((el: LocalsListItem, idx: number) =>
                  <React.Fragment key={`local-${idx}`}>
                    <Locallisttablerow
                      id={`tripsConfig-locals-tableRow-${idx}`}
                      permission={true}
                      onClick={(e) => dispatch(actions.App.navigateTo(`/viagens/configuracoes/editar-local/${el.id}`))}
                      local={el}
                      actions={
                        <React.Fragment>
                          <Feed fill={`${theme().secondaryColor}`}/>
                        </React.Fragment>
                      }
                    />
                  </React.Fragment>
                )}
              </TableContainer>
            },
            {
              title: t('tripSettings.passengerTypes'),
              content: <TableContainer
                count={paginationElementsCount(totalPassengerTypes)}
                currentPage={paginationCurrentPage(totalPassengerTypes ,page)}
                elementsPerPage={ELEMENTS_PER_PAGE}
                onPageChange={(pg) => { dispatch(setPage(pg)); dispatch(getPassengerTypes())}}
                headerElements={[
                  "Ab.",
                  <SorterComponent sort={sort} active={sort.field === 'name'} onChange={(dir) => dispatch(setSort({ field: "name", direction: dir }))} id="tripsConfig-passengers-nameSorter" filterName={t('common.name')} />,
                  t('common.actions'),
                ]}
              >
                {passengerTypes.map((el: PassengerTypeListItem, idx: number) =>
                  <React.Fragment key={`passengerType-${idx}`}>
                    <Passengertypelisttablerow
                      id={`tripsConfig-passengers-tableRow-${idx}`}
                      permission={true}
                      onClick={(e) => dispatch(actions.App.navigateTo(`/viagens/configuracoes/editar-tipo/${el.id}`))}
                      passengerType={el}
                      actions={
                        <React.Fragment>
                          <Feed fill={`${theme().secondaryColor}`}/>
                        </React.Fragment>
                      }
                    />
                  </React.Fragment>
                )}
              </TableContainer>
            },
            {
              title: "Extras",
              content: <TableContainer
                count={paginationElementsCount(totalExtras)}
                currentPage={paginationCurrentPage(totalExtras ,page)}
                elementsPerPage={ELEMENTS_PER_PAGE}
                onPageChange={(pg) => { dispatch(setPage(pg)); dispatch(getExtras())}}
                headerElements={[
                  "Ab.",
                  <SorterComponent sort={sort} active={sort.field === 'name'} onChange={(dir) => dispatch(setSort({ field: "name", direction: dir }))} id="tripsConfig-extras-nameSorter" filterName={t('common.name')} />,
                  t('tripSettings.extraMaxCapacity'),
                  t('tripSettings.extraPrice'),
                  t('tripSettings.commonAvailability'),
                  t('common.actions'),
                ]}
              >
                {extras.map((el: ExtrasListItem, idx: number) =>
                  <React.Fragment key={`extra-${idx}`}>
                    <Extralisttablerow
                      id={`tripsConfig-extras-tableRow-${idx}`}
                      permission={hasPermission(permissions, PERMISSIONS.TRIPS.EXTRAS.VIEW_EXTRA)}
                      onClick={(e) => { 
                        if(hasPermission(permissions, PERMISSIONS.TRIPS.EXTRAS.VIEW_EXTRA)){
                          dispatch(actions.App.navigateTo(`/viagens/configuracoes/editar-extra/${el.id}`)) 
                        }
                      }}
                      extra={el}
                      actions={
                        <React.Fragment>
                          <Feed fill={`${theme().secondaryColor}`}/>
                        </React.Fragment>
                      }
                    />
                  </React.Fragment>
                )}
              </TableContainer>
            },
            {
              title: t('tripSettings.campaigns'),
              content: <TableContainer
                count={paginationElementsCount(totalCampaigns)}
                currentPage={paginationCurrentPage(totalCampaigns ,page)}
                elementsPerPage={ELEMENTS_PER_PAGE}
                onPageChange={(pg) => {dispatch(setPage(pg)); dispatch(getCampaigns()); }}
                headerElements={[
                  <SorterComponent sort={sort} active={sort.field === 'name'} onChange={(dir) => dispatch(setSort({ field: "name", direction: dir }))} id="tripsConfig-campaigns-nameSorter" filterName={t('common.name')} />,
                  t('tripSettings.campaignPrefix'),
                  t('tripSettings.campaignDiscount'),
                  t('tripSettings.campaignExpiration'),
                  t('tripSettings.commonAvailability'),
                  t('common.actions')
                ]}
              >
                {campaigns.map((el: CampaignsListItem, idx: number) =>
                  <React.Fragment key={`campaign-${idx}`}>
                    <Campaignlisttablerow
                      id={`tripsConfig-campaigns-tableRow-${idx}`}
                      permission={hasPermission(permissions, PERMISSIONS.TRIPS.CAMPAIGNS.VIEW_CAMPAIGN)}
                      onClick={(e) => { 
                        if(hasPermission(permissions, PERMISSIONS.TRIPS.CAMPAIGNS.VIEW_CAMPAIGN)){
                          dispatch(actions.App.navigateTo(`/viagens/configuracoes/editar-campanha/${el.id}`)) 
                        }
                      }}
                      campaign={el}
                      actions={
                        <React.Fragment>
                          <Feed fill={`${theme().secondaryColor}`}/>
                          <Print fill={`${theme().secondaryColor}`}/>
                          <div style={{ cursor: 'pointer' }} id={`tripsConfig-campaigns-tableRow-${idx}-codeButton`} onClick={e => dispatch(actions.App.navigateTo(`/viagens/configuracoes/campanhas/codigos/${el.id}/${el.prefix}`))}><VpnKey /></div>
                        </React.Fragment>
                      }
                    />
                  </React.Fragment>
                )}
              </TableContainer>
            },
            {
              title: t('tripSettings.circuits'),
              content: <TableContainer
                count={paginationElementsCount(totalCircuits)}
                currentPage={paginationCurrentPage(totalCircuits ,page)}
                elementsPerPage={ELEMENTS_PER_PAGE}
                onPageChange={(pg) => { dispatch(setPage(pg)); dispatch(getCircuits())}}
                headerElements={[
                  <SorterComponent sort={sort} active={sort.field === 'name'} onChange={(dir) => dispatch(setSort({ field: "name", direction: dir }))} id="tripsConfig-circuits-nameSorter" filterName={t('common.name')} />,
                  t('tripSettings.circuitDuration'),
                  t('tripSettings.commonAvailability'),
                  t('common.actions'),
                ]}
              >
                {circuits.map((el: CircuitsListItem, idx: number) =>
                  <React.Fragment key={`circuit-${idx}`}>
                    <Circuitlisttablerow
                      id={`tripsConfig-circuits-tableRow-${idx}`}
                      permission={hasPermission(permissions, PERMISSIONS.TRIPS.CIRCUITS.VIEW_CIRCUIT)}
                      onClick={(e) => { 
                        if(hasPermission(permissions, PERMISSIONS.TRIPS.CIRCUITS.VIEW_CIRCUIT)){
                          dispatch(actions.App.navigateTo(`/viagens/configuracoes/editar-circuito/${el.id}`)) 
                        }
                      }}
                      circuit={el}
                      actions={
                        <React.Fragment>
                          <Feed fill={`${theme().secondaryColor}`}/>
                        </React.Fragment>
                      }
                    />
                  </React.Fragment>
                )}
              </TableContainer>
            },
            {
              title: t('tripSettings.services'),
              content: <TableContainer
                count={paginationElementsCount(totalServices)}
                currentPage={paginationCurrentPage(totalServices ,page)}
                elementsPerPage={ELEMENTS_PER_PAGE}
                onPageChange={(pg) => { dispatch(setPage(pg)); dispatch(getServices())}}
                headerElements={[
                  <SorterComponent sort={sort} active={sort.field === 'name'} onChange={(dir) => dispatch(setSort({ field: "name", direction: dir }))} id="tripsConfig-services-nameSorter" filterName={t('common.name')} />,
                  <SorterComponent sort={sort} active={sort.field === 'description'} onChange={(dir) => dispatch(setSort({ field: "description", direction: dir }))} id="tripsConfig-services-descriptionSorter" filterName={t('common.description')} />,
                  t('tripSettings.servicePricePerson'),
                  t('tripSettings.servicePriceTotal'),
                  t('tripSettings.commonAvailability'),
                  t('common.actions'),
                ]}
              >
                {services.map((el: ServicesListItem, idx: number) =>
                  <React.Fragment key={`service-${idx}`}>
                    <Servicelisttablerow
                      id={`tripsConfig-services-tableRow-${idx}`}
                      permission={hasPermission(permissions, PERMISSIONS.TRIPS.SERVICES.VIEW_SERVICE)}
                      onClick={(e) => { 
                        if(hasPermission(permissions, PERMISSIONS.TRIPS.SERVICES.VIEW_SERVICE)){
                          dispatch(actions.App.navigateTo(`/viagens/configuracoes/editar-servico/${el.id}`)) 
                        }
                      }}
                      service={el}
                      actions={
                        <React.Fragment>
                          <Feed fill={`${theme().secondaryColor}`}/>
                        </React.Fragment>
                      }
                    />
                  </React.Fragment>
                )}
              </TableContainer>
            },
          ]}
          />
      }

    </Wrapper>
  )
}

export default Tripsconfiguration