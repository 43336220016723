/**
 * Customerform scene utils
 *
 * @author João Dias <jdias@ubiwhere.com>
 *
 *
 */
import * as Check from "validations";
import { CustomerForm, ChangePasswordForm } from "types/customers/customerForm";

export const CustomerFormValidations = {
    name: [Check.isRequired],
    observations: [Check.hasValue],
    phone: [Check.hasValue],
    email: [Check.isRequired, Check.Email],
    address: [],
    country: [],
    avatar: [Check.hasValue],
    city: [],
    nif: [],
    postalCode: [],
    //businessEmail: [Check.hasValue],
    //businessPhone: [Check.hasValue],
    //company: [Check.hasValue],
}

export const CredentialsFormValidations = {
    newPassword: [Check.isRequired],
    confirmPassword: [Check.isRequired],
}

export const defaultCustomerForm: CustomerForm = {
    name: {
        value: "",
        valid: true,
        message: ""
    },
    observations: {
        value: "",
        valid: true,
        message: ""
    },
    phone: {
        value: "",
        valid: true,
        message: ""
    },
    email: {
        value: "",
        valid: true,
        message: ""
    },
    address: {
        value: "",
        valid: true,
        message: ""
    },
    country: {
        value: "",
        valid: true,
        message: ""
    },
    city: {
        value: "",
        valid: true,
        message: ""
    },
    nif: {
        value: "",
        valid: true,
        message: ""
    },
    avatar: {
        value: null,
        valid: true,
        message: ""
    },
    postalCode: {
        value: "",
        valid: true,
        message: ""
    },
    /* company: {
        value: "",
        valid: true,
        message: ""
    }, */
    /* businessPhone: {
        value: "",
        valid: true,
        message: ""
    }, */
    /* businessEmail: {
        value: "",
        valid: true,
        message: ""
    }, */
}

export const defaultChangePasswordForm: ChangePasswordForm = {
    newPassword: {
        value: "",
        message: "",
        valid: true,
    },
    confirmPassword: {
        value: "",
        message: "",
        valid: true,
    }
}