import React  from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions, RootState } from 'store/rootSlices';
import i18n from "i18next";
//components
import CustomTextInput from 'shared/components/CustomTextInput';
import CustomModal from 'shared/components/CustomModal';

type ModalsProps = {
  id: string | undefined;
}

const Modals = ({ id }: ModalsProps) => {

  const {
    showOverbookingModal,
    showCancelationModal,
    hasEditPermission,
    showSendTicketModal,
    sendTicketForm,
    cancelFullBooking,
  } = useSelector((state: RootState) => state.BookingForm);

  const {
    onSubmit,
    sendBookingTicket,
    setConfirmedOverbooking,
    setShowOverbookingModal,
    setConfirmedCancelation,
    setShowCancelationModal,
    setShowSendTicketModal,
    onSendTicketFormChange,
    cancelBooking,
    setCancelFullBooking,
  } = actions.BookingForm;

  const dispatch = useDispatch();

  return (
    <>
      <CustomModal
        id="bookingForm-confirmCancelation"
        warningType="warning"
        warningName={i18n.t('common.warning')}
        visible={showCancelationModal}
        buttonIcon={true}
        footerPosition="space-between"
        textAlign="start"
        width={600}
        buttonName={i18n.t('common.confirm')}
        onclose={(e) => {
          dispatch(setShowCancelationModal(false));
        }}
        onsubmit={(e) => {
          dispatch(setConfirmedCancelation(true));
          if (cancelFullBooking) {
            dispatch(cancelBooking());
            dispatch(setCancelFullBooking(false));
          } else {
            dispatch(onSubmit());
          }
        }}
      >
        <p>{i18n.t('tripBookings.cancelationMessage')}</p>
      </CustomModal>
      <CustomModal
        id="bookingForm-confirmOverbooking"
        warningType="warning"
        warningName={i18n.t('common.warning')}
        visible={showOverbookingModal}
        buttonIcon={true}
        footerPosition="space-between"
        textAlign="start"
        width={600}
        buttonName={i18n.t('common.confirm')}
        onclose={(e) => dispatch(setShowOverbookingModal(false))}
        onsubmit={(e) => {
          dispatch(setConfirmedOverbooking(true));
          dispatch(onSubmit());
        }}
      >
        <p>{i18n.t('tripBookings.overbookingMessage')}</p>
      </CustomModal>
      <CustomModal
        id="bookingForm-ticketSent"
        warningType="message"
        warningName={i18n.t('common.message')}
        visible={showSendTicketModal}
        buttonIcon={true}
        footerPosition="flex-end"
        textAlign="start"
        width={600}
        withForm
        singleButton
        hasValidation
        buttonName={i18n.t('common.continue')}
        onclose={(e) => {
          dispatch(setShowSendTicketModal(false));
        }}
        onsubmit={(e) => {
          dispatch(sendBookingTicket(id));
        }}
      >
        <p>{i18n.t('tripBookings.SendTicket')}:</p>
        <CustomTextInput
          id="bookingForm-sendEmailinput"
          disabled={!hasEditPermission}
          valid={sendTicketForm.email.valid}
          value={sendTicketForm.email.value}
          helpText={sendTicketForm.email.message}
          placeholder={i18n.t('placeholders.email')}
          onChange={(e) => {
            dispatch(
              onSendTicketFormChange({ email: { value: e.target.value, valid: true, message: '' } })
            );
          }}
        />
      </CustomModal>
    </>
  );
};

export default Modals;