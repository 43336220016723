/**
 * Clockqrreader scene slice
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */
import { createSlice } from '@reduxjs/toolkit'
import { DateTime, Interval, Duration } from 'luxon'

interface initialState {
  token: string,
  uuid: string,
  isClockIn: boolean,
  hasData: boolean,
  user: any
  workedHours: string,
}

const initialState = {
  token: "",
  uuid: "",
  isClockIn: true,
  hasData: false,
  workedHours: "",
  user: {} as any
}

export default createSlice({
  name: 'Clockqrreader',
  initialState,
  reducers: {
    onMount: (state, { payload }) => { },
    onUnmount: (state, { payload }) => { },
    setToken: (state, { payload }) => {
      state.token = payload
    },
    setUserUuid: (state, { payload }) => {
      state.uuid = payload
    },
    getClockStatus: (state, { payload }) => {

    },
    setHasData: (state, { payload }) => {
      state.hasData = payload
    },
    setUserInfo: (state, { payload }) => {
      state.user = payload
    },
    setWorkedHours: (state, { payload }) => {
      const lastClocked = DateTime.fromISO(payload);
      const now = DateTime.now()
      const IsoDuration = Interval.fromDateTimes(lastClocked, now).toDuration().toISO()
      const totalHours = Duration.fromISO(IsoDuration).toFormat("hh'h':mm'm'")
      state.workedHours = totalHours
    },
    returnToInitialState: (state) => {
      state.hasData = false
      state.token = ""
      state.uuid = ""
      state.workedHours = ""
      state.isClockIn = false
      state.user = {}
    },
    executeClockIn: () => { },
    executeClockOut: () => { },
  }
})