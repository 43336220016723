import styled from 'styled-components'

//! REmover estes styles do outro file
export const Wrapper = styled.div`
  width: auto;
  max-width: 50%;
  padding-inline: 16px;
  display:flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 10px;
  min-width: 180px;
  flex-wrap:wrap;
`

export const PassengerCounter = styled.div`
  display:flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
`

export const Counter = styled.div<{ margin: string }>`
  display:flex;
  justify-content: flex-start;
  margin-right: ${(props) => props.margin}px;
`

export const ButtonIcon = styled.div<{ marginr?: string, marginl?: string, pointer?: string }>`
  margin-right: ${props => props.marginr ? props.marginr : '0'}px;
  margin-left: ${props => props.marginl ? props.marginl : '0'}px;
  cursor: ${props => props.pointer ? props.pointer : 'pointer'};
`

export const ActionsWrapper = styled.div`
  display:flex;
  justify-content: space-between;
  min-width:140px;
`

export const ExtrasCounter = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  padding-inline: 10px;
`

export const ExtrasCounterWrapper = styled.div<{ border: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  height: 15px;
  font-size: 12px;
  border-right: ${props => props.border ? "1px solid #D8DBE0" : 'none'};
  padding-right: ${props => props.border ? "10px" : '0px'};

  .colorize-filter { filter: brightness(0) saturate(100%) invert(51%) sepia(8%) saturate(988%) hue-rotate(179deg) brightness(82%) contrast(89%); }
`