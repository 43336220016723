import * as icon from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import React from 'react'
import DoubleCheck from "assets/icons/double_check"
import Send from "assets/icons/send"
import Lock from 'assets/icons/lock';
import Refresh from 'assets/icons/refresh';
import theme from 'ui/theme';

export function getIconNameByPropString(propString: string, fill?: string) {
    switch (propString) {
        case 'list': return <CIcon height={15} icon={icon.cilEqualizer} size={"sm"} />;
        case 'confirm': return <CIcon height={15} icon={icon.cilCheckAlt} size={"sm"} />;
        case 'download': return <CIcon height={15} icon={icon.cilDataTransferDown} size={"sm"} />;
        case 'upload': return <CIcon height={15} icon={icon.cilDataTransferUp} size={"sm"} />;
        case 'add': return <CIcon height={15} icon={icon.cilPlus} size={"sm"} />;
        case 'cancel': return <CIcon height={15} icon={icon.cilX} size={"sm"} />;
        case 'trash': return <CIcon height={15} icon={icon.cilTrash} size={"sm"} />;
        case 'euro': return <CIcon height={15} icon={icon.cilEuro} size={"sm"} />;
        case 'warning': return <CIcon height={15} icon={icon.cilWarning} size={"sm"} />;
        case 'lock': return <Lock height={20} fill={fill ? theme()[fill] : null} />
        case 'doubleCheck': return <DoubleCheck fill={fill ? theme()[fill] : null} />
        case 'send': return <Send fill={fill ? theme()[fill] : null} />
        case 'refresh': return <Refresh fill={fill ? theme()[fill] : null} />
    }
}
