import * as React from "react"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width="4" height="16" viewBox="0 0 4 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4 2C4 0.900001 3.1 5.90104e-07 2 3.49691e-07C0.900001 1.09279e-07 4.15258e-07 0.9 1.74846e-07 2C-6.55671e-08 3.1 0.9 4 2 4C3.1 4 4 3.1 4 2ZM4 14C4 12.9 3.1 12 2 12C0.899998 12 -2.20743e-06 12.9 -2.44784e-06 14C-2.68825e-06 15.1 0.899997 16 2 16C3.1 16 4 15.1 4 14ZM4 8C4 6.9 3.1 6 2 6C0.899999 6 -8.96084e-07 6.9 -1.1365e-06 8C-1.37691e-06 9.1 0.899999 10 2 10C3.1 10 4 9.1 4 8Z" fill="#8A93A2" />
        </svg>

    )
}

export default SvgComponent
