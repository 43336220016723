import { UserForm, activityCommissionForm, extraCommissionForm } from 'types/collaborators/collaborator'
import { ChangePasswordForm } from 'types/collaborators/userForm'
import { defaultChangePasswordForm, defaultUserForm } from '../utils'

export interface InitialStateProps {
  uuid: string,
  userForm: UserForm,
  changePasswordForm: ChangePasswordForm,
  edit: boolean,
  loading: boolean,
  isAvatarRemoved: Boolean,
  isAvatarChanged: Boolean,
  user: {
    avatar: string,
    qrcode: string
  },
  deleteModal: boolean,
  changePasswordModal: boolean,
  hasChangedPassword: boolean,
  termsAccepted: boolean,
  errorTerms: string,
  userAuthExists: boolean,
  hasEditPermission: true,
  submitButtonState: boolean,
  hasBackofficeAccess: boolean,
  confirmAllModal: boolean,
  isApplyAllOnActivities: boolean,
  activitiesCommissionForm: Array<activityCommissionForm>,
  extrasCommissionForm: Array<extraCommissionForm>,
  defaultActivitiesCommissions: Array<activityCommissionForm>,
  defaultExtrasCommissions: Array<activityCommissionForm>,
}

export const initialState: InitialStateProps = {
  uuid: "",
  loading: true,
  edit: false,
  hasEditPermission: true,
  submitButtonState: false,
  deleteModal: false,
  changePasswordModal: false,
  hasChangedPassword: false,
  termsAccepted: false,
  errorTerms: "",
  isAvatarChanged: false,
  isAvatarRemoved: false,
  userAuthExists: false,
  hasBackofficeAccess: true,
  confirmAllModal: false,
  isApplyAllOnActivities: true,
  activitiesCommissionForm: [],
  extrasCommissionForm: [],
  defaultActivitiesCommissions: [],
  defaultExtrasCommissions: [],
  user: {
    avatar: "",
    qrcode: "",
  },
  changePasswordForm: defaultChangePasswordForm,
  userForm: defaultUserForm
}