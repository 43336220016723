import * as React from "react"
import { SVGProps } from "react"

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={25}
        height={25}
        fill="none"
        viewBox="0 0 10 13"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M6.413 4.98 5 6.393 3.58 4.98l-.94.94 1.42 1.413-1.413 1.414.94.94L5 8.273l1.413 1.414.94-.94L5.94 7.333 7.353 5.92l-.94-.94Zm.92-4.313L6.667 0H3.333l-.666.667H.333V2h9.334V.667H7.333ZM1 10.667C1 11.4 1.6 12 2.333 12h5.334C8.4 12 9 11.4 9 10.667v-8H1v8ZM2.333 4h5.334v6.667H2.333V4Z"
            fill={props.fill ?? "#E55353"}
        />
    </svg>
)

export default SvgComponent
