/**
 * Tripcampaigncodes scene slice
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */
import { createSlice } from '@reduxjs/toolkit'
import { CampaignCodes } from 'types/trips/configurations'

interface initialState {
  campaignCodeID: string,
  campaignPrefix: string,
  campaignCodes: Array<CampaignCodes>,
}

const initialState: initialState = {
  campaignCodes:[],
  campaignCodeID:"",
  campaignPrefix:"",
}

export default createSlice({
  name: 'Tripcampaigncodes',
  initialState,
  reducers: {
    onMount: (state, { payload }) => {},
    onUnmount: () => {
      return initialState
    },
    setCampaignCodes: (state, { payload }) => {
      state.campaignCodes = payload
    },
    setCampaignCodeID: (state, { payload }) => {
      state.campaignCodeID = payload
    },
    setCampaignPrefix: (state, { payload }) => {
      state.campaignPrefix = payload
    },
  }
})