import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { actions, RootState } from 'store/rootSlices'
import i18n from 'i18next'
//Components
import SorterComponent from 'shared/components/SorterComponent'
import TableContainer from 'shared/components/TableContainer'
import BeachRescheduleTableContent from '../BeachRescheduleTableContent'
import BeachNewTableContent from '../BeachNewTableContent'
import BeachEditTableContent from '../BeachEditTableContent'

type CustomBeachTripsTableProps = {
    isEdit: boolean;
    isReschedule: boolean;
}

const CustomBeachTripsTable = ({ isEdit, isReschedule }: CustomBeachTripsTableProps) => {
    const {
        page,
        totalBeachTrips,
    } = useSelector((state: RootState) => state.BookingForm);

    const {
        setPage,
    } = actions.BookingForm;

    const dispatch = useDispatch();

    const _renderTables = () => {
        return isReschedule ? <BeachRescheduleTableContent /> : <BeachNewTableContent />
    }

    return isEdit && !isReschedule ? (
        <TableContainer
            count={0}
            elementsPerPage={0}
            currentPage={-1}
            onPageChange={() => { }}
            headerElements={[
                <SorterComponent
                    id="beachTrip-activitySorter"
                    filterName={i18n.t('tripPlanningForm.activity')}
                    colSpan={1}
                    active={false}
                    onChange={(dir) => { return }}
                />,
                i18n.t('common.date'),
                i18n.t("tripBookings.local"),
                i18n.t("tripBookings.extras"),
                i18n.t('common.price'),
                i18n.t('common.actions'),
            ]}
        >
            <BeachEditTableContent />
        </TableContainer>
    ) : (
        <TableContainer
            count={totalBeachTrips}
            elementsPerPage={6}
            currentPage={page}
            onPageChange={pg => { dispatch(setPage(pg)); }}
            headerElements={[
                <SorterComponent
                    id="beachTrip-activitySorter"
                    filterName={i18n.t('tripPlanningForm.activity')}
                    colSpan={3}
                    active={false}
                    onChange={(dir) => { return }}
                />,
                i18n.t('common.date'),
                i18n.t("tripBookings.local"),
                i18n.t("tripBookings.availabilityShort"),
            ]}
        >
            {_renderTables()}
        </TableContainer>
    )


};

export default CustomBeachTripsTable