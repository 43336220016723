import axios from 'axios'
import { decamelizeKeys, camelizeKeys  } from 'humps'
import API from 'api'
import { toast } from 'react-toastify'
import { actions } from 'store/rootSlices';
import i18n from 'i18next';

const decamelizeKeysTransformer = function (data) {
  return data && JSON.stringify(decamelizeKeys(data))
}

export const setAuthorizationInterceptor = () => {
  axios.interceptors.request.use(
    (config) => {
      const token = window.localStorage.getItem('access_token')
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
        if (config.headers['Content-Type'] !== 'application/x-www-form-urlencoded') config.headers['Content-Type'] = 'application/json'
      }
      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )
}

const refreshToken = async (error, store) => {
  try {
    const refreshToken = window.localStorage.getItem('refresh_token')
    window.localStorage.removeItem('access_token')

    if (refreshToken) {
      const res = await API.Auth.RefreshToken({ refresh: refreshToken })
      window.localStorage.setItem('access_token', res.access)
      
      delete error.config.headers
      error.config.headers = { Authorization : "Bearer " + res.access}
      error.config.baseUrl = undefined

      toast.info(i18n.t('toasts.refreshToken'))
      return axios.request(error.config)
      
    }
    return Promise.reject(error)

  } catch (error) {
    toast.warning(i18n.t('toasts.tokenExpired'))
    store.dispatch(actions.Login.logout())
  }
} 

// Converts all responses for CamelCase
export const setResponseInterceptor = (history, store) => {

  axios.interceptors.response.use((response) => {    
    response.data = camelizeKeys(response.data)
    return response
  }, async (error) => {
    if (error.response && error.response.status === 401 && history.location.pathname !== '/login') {
      refreshToken(error, store)
    } else if (
      error.response && error.response.status === 500) {
      //store.dispatch(actions.Login.logout())
    } 
  }) 
}

// Converts all requests to under-cased
axios.interceptors.request.use((config) => {
  
  const currentContentType = config.headers['Content-Type']
  const token = window.localStorage.getItem('access_token')

  // Converts URL get params to underscored
  if (config.params) {
    config.params = decamelizeKeys(config.params)
  }
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`
  }
  if (!currentContentType) {
    config.headers['Content-Type'] = 'application/json'
    config.transformRequest = [decamelizeKeysTransformer]
  }

  return config
}, function (error) {
  return Promise.reject(error)
})

export const removeAuthorizationInterceptor = () => {
  axios.interceptors.request.use(
    function (config) {
      delete config.headers.Authorization
      return config
    },
    function (error) {
      return Promise.reject(error)
    }
  )
}

export default axios
