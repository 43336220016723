import styled from 'styled-components'
import { ControlsContainer } from 'shared/genericStyledComponents'
import theme, { devicesMax } from 'ui/theme'

export const Wrapper = styled.div`
`

export const EventWrapper = styled.div`
  p:last-child {
    margin-bottom: 0px;
  }

  display: flex;
  flex-wrap: wrap;
  padding: 16px;
  border-bottom: 1px solid ${theme().gray200Color};

`

export const TableRemoveCell = styled.div`
  > div {
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 35px;
    position: relative;
    width: 75px;
    padding-right:10px;


    &:after{
      content: '';
      position: absolute;
      right: -16px;
      bottom: -16px;
      width: 75px;
      height: calc(100% + 32px);
      
    }

    &.undo {
      padding:0;
      > span {
        font-size:12px;
        position: relative;
        z-index: 99;
        display: flex;
        width: 100%;
        justify-content: center;
        left:15px;
        position:relative;
      }
      &:hover {
        > span {
          color: ${theme().white};
        }

        &:after {
          background-color: ${theme().danger};
        }
      }
    }


  
    &:hover {
      &:after {
        background-color: ${theme().danger}; 
      }

      > svg {
        position: relative;
        z-index: 2;
        cursor: pointer;
         
        > path {
          fill: white !important;            
        }
      }
    } 
  } 
`


export const EventsContainer = styled.div`
  width: 100%;
  height:100%;
  border: 1px solid ${theme().gray200Color};
  box-sizing: border-box;
  border-radius: 4px;
  border-radius: 4px;
  margin-bottom: 24px;
  background-color: ${theme().white};

  div:last-child {
    border-bottom: 0px !important;
  }

`

export const EventTimes = styled.div`
  display:flex;
  justify-content: center;
  align-items:center;
  column-gap: 8px;
`

export const NoEvents = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 8px;
`

export const Event = styled.div<{ unsaved?: boolean, deleted?: boolean }>`
  width: calc(100% - 75px);
  display:flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 24px;
  row-gap: 20px;

  @media ${devicesMax.laptop} {
    flex-wrap: wrap;
  }

  
  ${({ unsaved, deleted }) => unsaved && unsaved === true ? `
    border: 2px dashed ${deleted && deleted === true ? theme().danger : theme().gray300Color};
  `: null}

`

export const ButtonIcon = styled.div<{ marginr?: string, marginl?: string }>`
  margin-right: ${props => props.marginr ? props.marginr : '0'}px !important;
  margin-left: ${props => props.marginl ? props.marginl : '0'}px !important;
  cursor: pointer;
`
export const Text = styled.p<{ color?: string }>`
  margin:0;
  ${({ color }) => color ? `color: ${color};` : ``}

  &.with_left_margin {
    margin-left: 7px !important;
  }

  &.with_pointer {
    cursor: pointer;
  }

  &.smaller {
    font-size: 12px;
    font-style: italic;
    margin-top: 8px;
  }

  &.with_right_margin{
    margin-right: -3px;
  }

  &.over{
    z-index: 1;
  }

`

export const CustomControlsContainer = styled(ControlsContainer) <{ expanded?: boolean }>`
  padding: 12px 16px;
  margin:0px;

  display: flex;
  flex-wrap: wrap;

  > div:nth-child(2) {
    > p:nth-child(1) {
        margin-left: 0px !important;
    }

    > div:nth-child(1) {
      margin-left: 18px !important;
  }
}

`

export const TimePickersWrapper = styled.div`
  min-width: 170px;
  max-width: 200px;
  width: 100%;
`

export const DropdownWrapper = styled.div`
  min-width: 170px;
  max-width: 200px;
  width: 100%;
`

export const DescriptionWrapper = styled.div`
  min-width: 170px;
  width: 100%;
`