import * as React from "react"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg cursor={props.cursor || 'cursor'} width={props.width || 25} height={props.height || 25} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.5 15.5V18.5H6.5V15.5H4.5V18.5C4.5 19.6 5.4 20.5 6.5 20.5H18.5C19.6 20.5 20.5 19.6 20.5 18.5V15.5H18.5ZM17.5 11.5L16.09 10.09L13.5 12.67V4.5H11.5V12.67L8.91 10.09L7.5 11.5L12.5 16.5L17.5 11.5Z" fill={props.fill || '#768192'} />
        </svg>
    )
}

export default SvgComponent
