import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
// Styles
import { actions, RootState } from 'store/rootSlices'
import { 
  Wrapper, 
  ControlsContainer,
} from 'shared/genericStyledComponents' 
import Loadingcontainer from 'shared/components/Loadingcontainer'

const BiDashboard = ({ t, i18n }) => {

  const { loading, dashboard } = useSelector((state: RootState) => state.BiDashboard)
  const { onMount, onUnmount } = actions.BiDashboard
  const { id } = useParams()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(onMount(id))
    return () => {
      dispatch(onUnmount())
    }
  }, [dispatch, onMount, onUnmount, id]);

  if(loading) {
    return <Loadingcontainer />
  }

  if(!dashboard) { 
    return <div>{t('biDashboard.notFound')}</div>
  }

  return (
    <Wrapper>
      <ControlsContainer>
      </ControlsContainer>
      <iframe
        style={{border: "none", backgroundColor: "transparent"}}
        title={dashboard.name}
        src={dashboard.embedUrl}
        width="100%"
        height="750"
      ></iframe>
    </Wrapper>
  )
}

export default BiDashboard
