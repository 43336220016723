/**
 * Maintenancelisttablerow component
 * @author Manuel Gil <mgil@ubiwhere.com>
 */
import React from 'react'
import { CTableDataCell } from '@coreui/react-pro';
import { useDispatch } from 'react-redux';
import { DateTime } from 'luxon';
// Styles
import { CustomCTableRow } from '../styles'
import {
  ActionsCell,
  ChevronCell,
  NameAndRoleCell,
  CustomH5
} from 'shared/genericStyledComponents'
// Types
import { MaintenanceListSingle } from 'types/fleet/maintenance';
// Icons
import ChevronRight from 'assets/icons/chevron_right';
// Store
import { actions } from 'store/rootSlices'

type PropTypes = {
  id?: string,
  maintenance: MaintenanceListSingle,
  actions: JSX.Element,
  onClick: Function,
  permission: boolean
}

const Maintenancelisttablerow = (props: PropTypes) => {

  const dispatch = useDispatch()
  const {
    permission,
    maintenance: {
      description,
      supplier,
      vehicle: {
        name
      },
      /* date, */
      createdAt,
      id
    }
  } = props;

  const parsed_date = DateTime.fromISO(createdAt).setLocale('pt').toLocaleString()

  return (
    <CustomCTableRow id={props.id}>
      <NameAndRoleCell >
        <CustomH5 onClick={() => props.onClick()}>{name}</CustomH5>
      </NameAndRoleCell>
      <CTableDataCell>{description}</CTableDataCell>
      <NameAndRoleCell >
        <CustomH5 onClick={() => props.onClick()}>{supplier}</CustomH5>
      </NameAndRoleCell>
      <CTableDataCell>{parsed_date}</CTableDataCell>
      <ActionsCell>
        {props.actions}
        <ChevronCell id={`${props.id}-chevron`} >
        {
            permission &&
            <a href={`/frota/manutencoes/editar-manutencao/${id}`} onClick={(e) => {
              e.preventDefault()
              dispatch(actions.App.navigateTo(`/frota/manutencoes/editar-manutencao/${id}`))
              }}>
              <ChevronRight></ChevronRight>
            </a>
        }
        </ChevronCell>
      </ActionsCell>
    </CustomCTableRow>
  )
}

export default Maintenancelisttablerow