import styled from 'styled-components'
import { CTableDataCell } from '@coreui/react-pro'
import { CustomTableRow,  ActionsCell } from 'shared/genericStyledComponents'

export const CustomCTableRow = styled(CustomTableRow)`

  td:first-child {
    padding-left:15px;
    width:23%;
  }

  td:nth-child(2){
    width:15%;
  }

  td:nth-child(3){
    width:7%;
  }

  td:nth-child(4){
    width:11%;
  }

  td:nth-child(5){
    width:12%;
    padding:0;
    padding-left:7px;
  }
`

export const CustomActionsCell = styled(ActionsCell)`
  td:nth-child(4){
    width:30%;
  }
`

export const NameCell = styled(CTableDataCell)`
  cursor: pointer;
`