import type { InitialStateProps } from "../../../logic/initialState"
import { CargoTypeForm } from 'types/trips/plannings'

const CargoReducers = {
  addNewCargo: (state: InitialStateProps, { payload }) => {
    const cargo: CargoTypeForm[] = Array.from(payload.cargo)
    if(payload.firstClick === true) {
      cargo.push({
        destination: { value: "", valid: true, message: "" },
        observations: { value: "", valid: true, message: "" },
        recipient: { value: "", valid: true, message: "" },
        //local: "",
      })
    } 

    state.firstCargoClick = true
    state.addCargo = true
    state.cargo = cargo
  },
  onChangeCargo: (state: InitialStateProps, { payload }) => {
    const cargo: CargoTypeForm[] = Array.from(payload.cargo)

    cargo[payload.idx] = {
      cargoType: 1,
      ...payload.form
    }

    state.cargo = cargo
  },
  onDeleteCargo: (state: InitialStateProps, { payload }) => {
    let hasCargo = true
    if(payload.cargo.length === 1) {
      hasCargo = false
      state.firstCargoClick = false
    }
    const items: CargoTypeForm[] = Array.from(payload.cargo)
    if(payload.cargo.length > 1) {
      items.splice(items.indexOf(items[payload.index]), 1)
    }

    state.cargo = items
    state.addCargo = hasCargo
  },

}

export default CargoReducers