import * as React from "react"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width={18}
            height={18}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M14.572 9.735c.03-.24.053-.48.053-.735s-.023-.495-.053-.735l1.583-1.238a.378.378 0 00.09-.48l-1.5-2.594a.375.375 0 00-.457-.165l-1.868.75a5.48 5.48 0 00-1.268-.736l-.284-1.987A.366.366 0 0010.5 1.5h-3a.366.366 0 00-.367.315l-.285 1.988a5.762 5.762 0 00-1.268.734l-1.868-.75a.425.425 0 00-.135-.022.371.371 0 00-.322.188l-1.5 2.595a.37.37 0 00.09.48l1.583 1.237c-.03.24-.053.487-.053.735s.022.495.053.735l-1.583 1.238a.378.378 0 00-.09.48l1.5 2.595a.375.375 0 00.458.165l1.867-.75c.39.3.81.547 1.268.735l.285 1.987c.022.18.18.315.367.315h3a.366.366 0 00.367-.315l.285-1.987a5.762 5.762 0 001.268-.736l1.867.75c.045.015.09.023.135.023a.371.371 0 00.323-.188l1.5-2.595a.378.378 0 00-.09-.48l-1.583-1.237zm-1.484-1.282c.03.232.037.39.037.547 0 .158-.015.322-.037.547l-.105.848.667.525.81.63-.525.907-.953-.382-.78-.315-.675.51c-.322.24-.63.42-.937.548l-.795.322-.12.848L9.525 15h-1.05l-.143-1.012-.12-.848-.794-.322a4.255 4.255 0 01-.923-.533l-.683-.525-.795.322-.952.383-.525-.908.81-.63.668-.524-.106-.848A6.033 6.033 0 014.875 9c0-.15.015-.322.037-.547l.106-.848-.668-.525-.81-.63.525-.907.952.382.78.315.676-.51c.322-.24.63-.42.937-.548l.795-.322.12-.848L8.475 3h1.043l.142 1.013.12.847.795.322c.323.135.623.308.923.533l.682.525.795-.322.953-.383.524.907-.802.638-.668.525.106.848zM9 6a3 3 0 100 6 3 3 0 100-6zm0 4.5c-.825 0-1.5-.675-1.5-1.5S8.175 7.5 9 7.5s1.5.675 1.5 1.5-.675 1.5-1.5 1.5z"
                fill={props.fill ? props.fill : "#EBEDEF"}
            />
        </svg>
    )
}

export default SvgComponent