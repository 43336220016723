import * as Check from 'validations'
import { CampaignForm } from 'types/trips/campaigns'

export const CampaignFormValidations = {
    prefix: [Check.isRequired],
    name: [Check.isRequired],
    discount: [Check.isRequired, Check.isNumber],
    quantity: [Check.isRequired, Check.isNumber],
    validityMonths: [Check.isRequired, Check.isNumber],
    hasMultipleCodes: [Check.isRequired],
    sendEmailAfterNumberReservations:[Check.isRequired, Check.isNumber],
    sendEmailBody:[Check.isRequired],
    isDiscountPercentage:[Check.isRequired],
    /* activityName: [Check.isRequired], */
    isActive: [Check.isRequired],
}

export const CampaignFormValidationsEdit = {
    prefix: [Check.isRequired],
    name: [Check.isRequired],
    discount: [Check.isRequired, Check.isNumber],
    quantity: [Check.isRequired, Check.isNumber],
    validityMonths: [Check.isRequired, Check.isNumber],
    hasMultipleCodes: [Check.isRequired],
    sendEmailAfterNumberReservations:[Check.isRequired, Check.isNumber],
    sendEmailBody:[Check.isRequired],
    isDiscountPercentage:[Check.isRequired],
    /* activityName: [Check.isRequired], */
    isActive: [Check.isRequired],
}

export type SubmitSagaPayload = {
    e?: any,
    edit: boolean,
    campaignActivities: number[],
    campaignForm: CampaignForm
}