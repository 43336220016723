/**
 * PaymentsMade component
 *
 * @author João Flores <jflores@ubiwhere.com>
 *
 *
 */

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions, RootState } from 'store/rootSlices';
import { DateTime } from 'luxon';
import InfoIcon from 'assets/icons/info';
//configs
import config from 'config';
//styles
import { ActionsWrapper } from '../../styles';
import * as Styles from './styles';
//icons
import ReceiptAvailable from 'assets/icons/receipt_available';
import ReceiptError from 'assets/icons/receipt_error';
//Components
import { CTableRow, CTableDataCell } from '@coreui/react-pro';
import TableContainer from 'shared/components/TableContainer';

const { DATE_FORMAT } = config;

const PaymentsMade = ({ t }) => {
  const { payments, bookingUUID } = useSelector((state: RootState) => state.BookingForm);
  const dispatch = useDispatch();

  return (
    <>
      {payments.length > 0 && (
        <Styles.Payments>
          <h6>{t('tripBookings.paymentHistory')}</h6>
          <TableContainer
            count={0}
            elementsPerPage={0}
            currentPage={-1}
            onPageChange={() => {}}
            headerElements={[
              t('tripBookings.service'),
              t('common.date'),
              t('tripBookings.customer'),
              t('tripBookings.paymentMethodShort'),
              t('tripBookings.receivedBy'),
              t('common.price'),
              '',
            ]}
            footer={
              <Styles.PaymentsFooter>
                <CTableRow>
                  <CTableDataCell colSpan={5}>
                    {t('common.total')} <span>{t('common.taxLabel')}</span>
                  </CTableDataCell>
                  <CTableDataCell colSpan={2}>
                    {payments.reduce((prev, curr) => {
                      const totalAmount = prev + (curr.isRefund ? +curr.amount * -1 : +curr.amount);
                      return Number(totalAmount.toFixed(2));
                    }, 0)}
                    €
                  </CTableDataCell>
                </CTableRow>
              </Styles.PaymentsFooter>
            }
          >
            {payments.map((payment, idx) => (
              <Styles.PaymentRow key={idx}>
                <CTableDataCell>{payment.description}</CTableDataCell>
                <CTableDataCell>
                  {DateTime.fromISO(payment.createdAt).toFormat(DATE_FORMAT.DATETIME)}
                </CTableDataCell>
                <Styles.Tooltip>
                  <Styles.TooltipMainText>
                    {payment.customerName}
                    <InfoIcon />
                  </Styles.TooltipMainText>
                  <Styles.CustomSpan>
                    <Styles.TooltipText>
                      <b>{t('common.phone')}:</b>
                      <p>{payment.phone ? payment.phone : ' - '}</p>
                    </Styles.TooltipText>
                    <Styles.TooltipText>
                      <b>{t('common.email')}:</b>
                      <p>{payment.email ? payment.email : ' - '}</p>
                    </Styles.TooltipText>
                    <Styles.TooltipText>
                      <b>{t('common.country')}:</b>
                      <p>{payment.country ? payment.country : ' - '}</p>
                    </Styles.TooltipText>
                    <Styles.TooltipText>
                      <b>{t('common.nif')}:</b>
                      <p>{payment.vat ? payment.vat : ' - '}</p>
                    </Styles.TooltipText>
                  </Styles.CustomSpan>
                </Styles.Tooltip>
                <CTableDataCell>
                  {payment.source?.name} - {payment.type?.name}
                </CTableDataCell>
                <CTableDataCell>{payment.processedBy?.name}</CTableDataCell>
                <Styles.PaymentStateCell className={payment.isRefund ? 'refund' : 'payment'}>
                  {payment.isRefund ? -payment.amount : payment.amount}€
                </Styles.PaymentStateCell>
                <CTableDataCell>
                  <ActionsWrapper
                    onClick={() =>
                      dispatch(actions.App.navigateTo(`/faturacao/pedidos?booking=${bookingUUID}`))
                    }
                  >
                    {payment.invoice ? <ReceiptAvailable /> : <ReceiptError />}
                  </ActionsWrapper>
                </CTableDataCell>
              </Styles.PaymentRow>
            ))}
          </TableContainer>
        </Styles.Payments>
      )}
    </>
  );
};

export default PaymentsMade;
