import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { actions, RootState } from 'store/rootSlices'
import { DateTime } from "luxon"
import theme from 'ui/theme'
//configs
import config from 'config';
//styles
import { LegRow, IconTableCell, CustomLink } from '../../styles'
import { PriceCell } from './styles'
//icons
import SwapHorz from 'assets/icons/swap_horz'
import Loop from 'assets/icons/loop'
import EventSeat from 'assets/icons/event_seat'
import TrendingFlat from 'assets/icons/trending_flat'
import StartTrip from 'assets/icons/start_trip'
import EndTrip from 'assets/icons/end_trip'
import ChevronRight from 'assets/icons/chevron_right';
//Components
import { CTableDataCell } from '@coreui/react-pro'
import Loadingcontainer from 'shared/components/Loadingcontainer'
import CustomRadioButton from 'shared/components/CustomRadioButton';

const { DATE_FORMAT } = config;


const RescheduleTableContent = () => {
  const {
    outbound,
    inbound,
    tripLegs,
    legInboundView,
    bookedOutbound,
    bookedInbound,
    hasEditPermission,
    outboundToCancel,
    inboundToCancel,
    tableLoading
  } = useSelector((state: RootState) => state.BookingForm);

  const { setRescheduleInbound, setRescheduleOutbound } = actions.BookingForm;

  const dispatch = useDispatch();

  const calculatePriceDiff = (legInboundView, leg) => {
    let priceDiff = 0;
    if (legInboundView) {
      priceDiff = (bookedInbound.tripActivityLeg.price - leg.price) * -1;
    }
    else {
      priceDiff = (bookedOutbound.tripActivityLeg.price - leg.price) * -1;
    }

    let cls;
    if (priceDiff > 0) {
      cls = "more";
    }
    else if (priceDiff < 0) {
      cls = "less";
    }

    if (legInboundView) {
      if(inboundToCancel || bookedInbound.isCanceled || bookedInbound.wasCanceled) {
        return <b>{leg.price}€</b>
      }
    }
    else {
    if(outboundToCancel || bookedOutbound.isCanceled || bookedOutbound.wasCanceled) {
        return <b>{leg.price}€</b>
    }
    }

    return (
      <><b className={cls}>{priceDiff}€</b>({leg.price}€)</>
    )
  };

  if(tableLoading) {
    return <CTableDataCell colSpan={8}><Loadingcontainer /></CTableDataCell>
  }

return <>
    {
      tripLegs.map((leg, idx) => (
        <LegRow
          key={idx}
          id={`reschedule-legRow-${leg.id}`}
          className={(() => {
            if (legInboundView) {
              if (inbound.tripActivityLeg.id === leg.id && !inboundToCancel) {
                return 'selected';
              }

              if (bookedInbound.tripActivityLeg.id === leg.id) {
                return 'booked';
              }
            }
            else {
              if (outbound.tripActivityLeg.id === leg.id && !outboundToCancel) {
                return 'selected';
              }

              if (bookedOutbound.tripActivityLeg.id === leg.id) {
                return 'booked';
              }
            }

            return '';
          })()}
          onClick={e => { legInboundView ? dispatch(setRescheduleInbound(leg)) : dispatch(setRescheduleOutbound(leg)) }}
        >
          <CTableDataCell style={{ "padding": "1em" }}>
            <CustomRadioButton
              disabled={!hasEditPermission}
              group="legs"
              value={leg.id}
              checked={legInboundView ? leg.id === inbound.tripActivityLeg.id && !inboundToCancel : leg.id === outbound.tripActivityLeg.id && !outboundToCancel}
            />
          </CTableDataCell>
          <IconTableCell>
            {
              leg.activity.isHopOnHopOff ?
                <Loop />
                :
                leg.departure.isOutbound ?
                  <StartTrip fill={theme().gray500Color} />
                  :
                  <EndTrip fill={theme().gray500Color} />
            }
          </IconTableCell>
          <CTableDataCell>{leg.activity.name}</CTableDataCell>
          <CTableDataCell>{DateTime.fromISO(leg.date).toFormat(DATE_FORMAT.DATE)}</CTableDataCell>
          <CTableDataCell>{DateTime.fromISO(leg.time).toFormat(DATE_FORMAT.TIME)}</CTableDataCell>
          <CTableDataCell onClick={(e) => e.stopPropagation()}>
            <CustomLink
              to={`/viagens/agendamentos/editar-agendamento/${leg?.trip}`}
              target="_blank"
              rel="noopener"
            >
              {
                leg.activity.isHopOnHopOff ?
                  <>{leg.departure.name} <SwapHorz /> {leg.arrival.name}</>
                  :
                  <>{leg.departure.name} <TrendingFlat /> {leg.arrival.name}</>
              }
              <ChevronRight className='pointer' fill={theme().gray700Color} />
            </CustomLink>
          </CTableDataCell>
          <CTableDataCell>
            <EventSeat /> <b>{leg.availableTickets}</b>/<span className="smaller">{leg.maxCapacity}</span>
          </CTableDataCell>
          <PriceCell>{calculatePriceDiff(legInboundView, leg)}</PriceCell>
        </LegRow>
      )
      )
    }
  </>
};

export default RescheduleTableContent