/**
 * Passengercontrollisttablerow component
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */
import { lowerFirst } from 'lodash'
import { CTableDataCell } from '@coreui/react-pro'
import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store/rootSlices'
import { Text } from 'ui/globalStyles'
import StartTrip from 'assets/icons/start_trip'
import { LocalsListItem } from 'types/trips/locals'
import {
  CustomCTableRow,
  Icon,
  BoardingCell,
  CustomText
} from './styles'

type PropTypes = {
  id?: string,
  isBoardingStop: boolean
  stop?: LocalsListItem,
  stopCheckinsSum?: number
  boardingCheckinsCounter?: any
  totalCheckinsCounter?: any,
  onBoardingClick?: Function
}

const Passengercontrollisttablerow = (props: PropTypes) => {

  const { allLocals } = useSelector((state: RootState) => state.TripsConfiguration);
  const {
    isBoardingStop,
    stop,
    boardingCheckinsCounter,
    totalCheckinsCounter,
    onBoardingClick,
  } = props

  return (
    <CustomCTableRow total={isBoardingStop ? false : true} >
      {
        //boardings column
        isBoardingStop ?
          <BoardingCell onClick={onBoardingClick ? (e) => { onBoardingClick(stop?.id, stop?.name) } : (e) => { }}>
            <Icon marginr='20'><StartTrip /></Icon>
            <Text>{stop?.name}</Text>
          </BoardingCell>
          :
          <CTableDataCell>
            <Text>Total</Text>
          </CTableDataCell>
      }
      {
        //stops columns
        allLocals.map(l => {

          //due to the interceptor, checkinsCounter object has its keys decapitalized
          if (boardingCheckinsCounter?.hasOwnProperty(lowerFirst(l.shortName))) {
            return (
              <CTableDataCell>
                {/* {
                  l.id === stop?.id ?
                    <Text>{boardingCheckinsCounter[lowerFirst(l.shortName)]}</Text>
                    :
                    l.isIsland ?
                      <Text>{boardingCheckinsCounter[lowerFirst(l.shortName)]}</Text>
                      :
                      <Text>-</Text>
                } */}
                {
                  <Text>{boardingCheckinsCounter[lowerFirst(l.shortName)]}</Text>
                }
              </CTableDataCell>
            )
          }
          else {
            return (
              <CTableDataCell>
                <CustomText weight='700'>
                  {
                    totalCheckinsCounter && Object.keys(totalCheckinsCounter).map(boardingPlace => {
                      return Object.keys(totalCheckinsCounter[boardingPlace]).filter(stopPlace => {
                        if(lowerFirst(stopPlace) === lowerFirst(l.shortName)) {
                          return true
                        }
                        return false
                      }).map(stopPlace => totalCheckinsCounter[boardingPlace][stopPlace]).reduce((prev, curr) => prev + curr)
                    }).reduce((prev, curr) => prev + curr)
                  }
                </CustomText>
              </CTableDataCell>
            )
          }
        })
      }
      <CTableDataCell>
        {
          //total column
          <CTableDataCell>
            {
              isBoardingStop && boardingCheckinsCounter ?
                <Text>
                  {
                    Object.keys(boardingCheckinsCounter).map(el => boardingCheckinsCounter[el]).reduce((prev, curr) => prev + curr)
                  }
                </Text>
                :
                <CustomText weight='700'>
                  {
                    totalCheckinsCounter && Object.keys(totalCheckinsCounter).map(boardingPlace => {
                      return Object.keys(totalCheckinsCounter[boardingPlace]).map(stopPlace => totalCheckinsCounter[boardingPlace][stopPlace]).reduce((prev, curr) => prev + curr)
                    }).reduce((prev, curr) => prev + curr)
                  }
                </CustomText>
            }
          </CTableDataCell>
        }
      </CTableDataCell>
    </CustomCTableRow>
  )
}

export default Passengercontrollisttablerow