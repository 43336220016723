import * as React from "react"

import theme from "ui/theme"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="17" viewBox="0 0 20 17" fill="none">
        <rect width="20" height="17" rx="4" fill={props.fill ? props.fill : theme().warning} />
        <path d="M6.79736 13L4.5083 5H6.35596L7.68018 10.5586H7.74658L9.20752 5H10.7896L12.2466 10.5703H12.3169L13.6411 5H15.4888L13.1997 13H11.5513L10.0278 7.76953H9.96533L8.4458 13H6.79736Z" fill="white"/>
      </svg>
    )
}

export default SvgComponent