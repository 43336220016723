import React from 'react'
import { Duration } from "luxon"
import { PadWrapper } from './styles'
import { Text } from '../../styles'
import type { InOutBoundStops } from 'types/trips/plannings'

type PropTypes = {
  onClick: () => void;
  outboundsLength: number;
  inboundsLength: number;
  outbound: InOutBoundStops[];
  inbound: InOutBoundStops[];
}

const Pad = ({ onClick, outboundsLength, inboundsLength, outbound, inbound }: PropTypes) => {
  return (
    <PadWrapper onClick={onClick}>
      <Text>{outboundsLength && outboundsLength > 0 ? 
        Duration.fromISOTime(outbound[0]?.time).toFormat('hh:mm')
        : inboundsLength && inboundsLength > 0 ? 
        Duration.fromISOTime(inbound[0]?.time).toFormat('hh:mm') 
        : ""
    }</Text>
    </PadWrapper>
  )
}

export default Pad