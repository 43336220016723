import { BeachService, PlanningServiceForm, PlanningVehiclesForm, ServiceDetails, ServiceTypes, TripService } from 'types/trips/plannings'
import { servicesOptions } from '../../logic/initialState'
import { activityFilter } from '../../utils'
import { ActivityListItem } from 'types/trips/activity/activity'
import i18n from 'i18next'
import { ActivityExtrasForm, ActivityPassengerTypesForm } from 'types/trips/activity/activityForm'
import { CategoryListItem } from 'types/trips/categories'

// util functions for service options
export const canAddSecondaryService = (services: PlanningServiceForm[]) => {
    if (services[0]?.serviceType === ServiceTypes.BEACH) return false
    return true
}

export const AvailableServiceOptions = (services: PlanningServiceForm[]) => {
    if (services.length > 1) {
        return servicesOptions.filter(serv => serv.value === ServiceTypes.ACTIVITY)
    }
    else {
        return servicesOptions
    }
}

export const createServiceTypeDetailsStructure = (service: ServiceTypes): TripService | BeachService => {
    switch (service) {
        case ServiceTypes.ACTIVITY:
            return {
                id: { value: null, valid: true, message: "" },
                generateIslandTrips: false,
                isPrivate: false,
                allowMultipleActivities: false,
                shortName: "",
                duration: "",
                vehicles: [],
                circuits: [],
            } as TripService
        case ServiceTypes.BEACH:
            return {
                id: { value: null, valid: true, message: "" },
                shortName: "",
                duration: "",
                location: ""
            } as BeachService
    }
}

export const createServiceSettingsStructure = (
    service: ServiceTypes,
    settings: ActivityPassengerTypesForm[] | ActivityExtrasForm[]
): { [key: string]: ActivityPassengerTypesForm[] | ActivityExtrasForm[] } => {
    switch (service) {
        case ServiceTypes.ACTIVITY:
            return { passengerTypes: settings ?? [] as ActivityPassengerTypesForm[] }
        case ServiceTypes.BEACH:
            return { extras: settings ?? [] as ActivityExtrasForm[] }
    }
}

export const getServiceInitialSettingsBasedOnType = (
    service: ServiceTypes,
    passengerTypes: ActivityPassengerTypesForm[],
    extras: ActivityExtrasForm[]
): ActivityPassengerTypesForm[] | ActivityExtrasForm[] => {
    switch (service) {
        case ServiceTypes.ACTIVITY:
            return passengerTypes
        case ServiceTypes.BEACH:
            return extras
    }
}

export const AvailableActivitiesOptions = (
    service: PlanningServiceForm,
    services: PlanningServiceForm[],
    allActivities: ActivityListItem[],
    allCategories: CategoryListItem[],
    vehicles: PlanningVehiclesForm[],
) => {
    let options: any[] = []

    switch (service.serviceType) {
        case ServiceTypes.ACTIVITY:
            options = services.length > 1 ?
                activityFilter(allActivities, services, service.serviceDetails?.id.value, vehicles)
                :
                allActivities.filter((act) => !act.isBeach).map((act) => {
                    return ({
                        label: act.name,
                        value: act.id,
                    })
                })
            break;

        case ServiceTypes.BEACH:

            const beachCategories = allCategories.filter(c => c.isBeach === true)

            options = allActivities.filter(act => beachCategories.find(c => c.id === act.category.id) || act.isBeach).map((act) => {
                return ({
                    label: act.name,
                    value: act.id,
                })
            })
            break
        default:
            break;
    }
    return options
}

export const ActivitiesPlaceholder = (service: PlanningServiceForm) => {
    switch (service.serviceType) {
        case ServiceTypes.ACTIVITY:
            return i18n.t('placeholders.activity')
        case ServiceTypes.BEACH:
            return i18n.t('placeholders.beach')
        default:
            break;
    }
}

export const ActivitiesLabel = (service: PlanningServiceForm) => {
    switch (service.serviceType) {
        case ServiceTypes.ACTIVITY:
            return i18n.t('tripPlanningForm.activity')
        case ServiceTypes.BEACH:
            return i18n.t('tripPlanningForm.beach')
        default:
            break;
    }
}

// Type guard function to check if serviceDetails is TripService
export const isTripServiceType = (service: ServiceDetails): service is TripService => {
    if (service) return 'generateTripIslands' in service;
    return false
}

export const isTripService = (service: PlanningServiceForm): boolean => {
    return service.serviceType === ServiceTypes.ACTIVITY
}

export const addGenerateIslandTripsHeader = (service: PlanningServiceForm) => {
    return isTripServiceType(service.serviceDetails) ? service.serviceDetails?.generateIslandTrips ? '' : 'Price For Island' : ''
}

export const checkIfGenerateIslandTrips = (service: PlanningServiceForm) => {
    return isTripServiceType(service.serviceDetails) ? service.serviceDetails?.generateIslandTrips ? true : false : false
}