/**
 * FileUploader component
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */

import React, { useState, useRef, /* useEffect */ } from 'react'
import styled from 'styled-components'
import { CFormLabel, CFormInput, CInputGroup, CButton } from '@coreui/react';
import theme from 'ui/theme';
import { CFormText } from '@coreui/react-pro';
import { toast } from 'react-toastify';
/* import CIcon from '@coreui/icons-react';
import { cilX } from '@coreui/icons'; */

interface PropTypes {
  id?: string,
  className?: string,
  wrapperStyle?: React.CSSProperties,
  required?: boolean,
  label?: string,
  value?: string,
  onChange?: Function,
  disabled?: boolean,
  accept?: string,
  fileSizeLimit?: number, //bytes
  valid?: boolean,
  helpText?: string,
  ref?: React.MutableRefObject<any>
}

const FileUploader = (props: PropTypes) => {

  const [filePath, setFilePath] = useState(props.value ? props.value : "")
  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const handleChange = (e) => {
    if (props.onChange && (e.target.value.toLowerCase().includes(".png") || e.target.value.toLowerCase().includes(".pdf") || e.target.value.toLowerCase().includes(".jpg") || e.target.value.toLowerCase().includes(".jpeg"))) {
      if (props.fileSizeLimit && e.target.files[0].size <= props.fileSizeLimit) {

        props.onChange(e.target.files[0]);
        setFilePath(e.target.value.split("\\").pop());
      }
      else {
        toast.error("file size above the limit")
      }
    }
    else {
      if (e.target.value !== "") {
        toast.error("file type not supported")
      }
    }
  }

  const handleButtonClick = e => {
    if (hiddenFileInput.current !== null) {
      hiddenFileInput.current.click();
    }
  };

  return (
    <Wrapper ref={props.ref} className={props.className} style={props.wrapperStyle}>
      {props.label && <CFormLabel>{props.label}<span>{!!props.required ? "*" : ""}</span></CFormLabel>}
      <InputGroup className="mb-3">
        <FormInput id={`${props.id}-input`} accept={props.accept} disabled={props.disabled === true ? true : false} type="file" ref={hiddenFileInput} aria-label="Upload" onChange={e => handleChange(e)} />
        <FakeInput disabled={props.disabled === true ? true : false} value={filePath} onChange={e => { }}></FakeInput>
        <Button id={`${props.id}-browseButton`} disabled={props.disabled === true ? true : false} color="none" variant="outline" onClick={e => handleButtonClick(e)}>Browse</Button>
      </InputGroup>
      {props.helpText && <HelpText valid={props.valid}>{props.helpText}</HelpText>}
    </Wrapper>
  )
}

export default FileUploader

const Wrapper = styled.div`

  > * {
    font-family: 'Inter';
  }

  > label {
    font-size: 14px;
    color: ${theme().darkColor};
    > span {
      color: ${theme().primary};
    }
  }
  .input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback){
    border-bottom-left-radius:4px;
    border-top-left-radius:4px;
    border-top-right-radius:4px;
    border-bottom-right-radius:4px;
  }
`

const HelpText = styled(CFormText) <{ valid: boolean | undefined }>`
  color: ${(props) => props.valid === false ? theme().danger : '#D8DBE0'};
`

const FormInput = styled(CFormInput)`
  font-size:14px;
  opacity:0;
  z-index:3;
  color:${theme().gray600Color};
  &:focus{
    box-shadow: 0px 0px 0px 0px ${theme().gray200Color};
    border-color:${theme().gray600Color};
  }
  &:disabled{
    opacity:0;
  }
`

const FakeInput = styled.input`
  color:${theme().gray500Color};
  position:absolute;
  width:100%;
  font-size:14px;
  border:1px solid ${theme().gray200Color};
  border-radius:4px;
  border-width:1px;
  border-top-right-radius:16px;
  line-height:1.5;
  padding: 0.375rem 0.75rem;
`

const InputGroup = styled(CInputGroup)`
  flex-wrap:nowrap;
  margin-bottom:0.25rem !important
`

const Button = styled(CButton)`
  font-size:14px;
  color:${theme().gray500Color};
  background-color:${theme().lightColor};
  border:0;
  z-index:0;
  &:hover{
    background-color:${theme().lightColor};
  }
  &:focus{
    background-color:${theme().lightColor};
  }
  &:disabled{
    background-color:${theme().lightColor};
  }
`