import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions, RootState } from 'store/rootSlices';
import { DateTime } from 'luxon';
import i18n from 'i18next'
//configs
import config from 'config';
//styles
import { TableRow, ActionsWrapper } from '../../../../styles';
import theme from 'ui/theme';
import { CustomLink } from '../../styles'
import { EmptyCell } from './styles';
//icons
import Cancel from 'assets/icons/cancel';
import ChevronRight from 'assets/icons/chevron_right';
//Components
import { CTableDataCell } from '@coreui/react-pro';
import { hasPermission } from 'permissions';
// Types
import { BookingLeg } from 'types/trips/bookings';

const { DATE_FORMAT } = config;

const BeachEditTableContent = () => {
    const {
        selectedBeachTrip,
        hasEditPermission,
        bookingState,
        bookedBeachExtras,
        sameExtras
    } = useSelector((state: RootState) => state.BookingForm);

    const { cancelLeg, setLockExtraInputs } = actions.BookingForm;

    const dispatch = useDispatch();

    const { permissions } = useSelector((state: RootState) => state.App);
    const { PERMISSIONS } = config;

    const _renderActions = (leg: BookingLeg) => {
        return hasEditPermission && leg.checkedIn === false && (
            <ActionsWrapper>
                {bookingState !== 'pending' &&
                    sameExtras &&
                    hasPermission(permissions, PERMISSIONS.TRIPS.BOOKINGS.CANCEL_BOOKING) ? (
                    <Cancel
                        onClick={() => {
                            hasEditPermission &&
                                leg.checkedIn === false &&
                                dispatch(cancelLeg({ leg, direction: 'beach' }));
                            hasEditPermission &&
                                leg.checkedIn === false &&
                                dispatch(setLockExtraInputs(true));
                        }}
                    />
                ) : (
                    <Cancel style={{ cursor: 'default', opacity: '0.8' }} onClick={() => { }} />
                )}
            </ActionsWrapper>
        )
    }

    const _renderTripRow = (leg: BookingLeg) => {
        const beachTripNotCanceled = leg.id > 0 && !leg.isCanceled
        const beachTriAndBookingCanceled = leg.isCanceled && bookingState === 'canceled'

        return (beachTripNotCanceled || beachTriAndBookingCanceled) && (
            <TableRow>
                <CTableDataCell>{leg?.beachTripActivity?.activity.name}</CTableDataCell>
                <CTableDataCell>
                    <CustomLink
                        to={`/viagens/agendamentos/editar-agendamento/${leg?.beachTripActivity?.trip}`}
                        target="_blank"
                        rel="noopener"
                    >
                        {leg.beachTripActivity?.departure.name} {' '}
                        <ChevronRight className="pointer" fill={theme().gray700Color} />
                    </CustomLink>
                </CTableDataCell>
                <CTableDataCell>
                    {DateTime.fromISO(leg.beachTripActivity?.date).toFormat(DATE_FORMAT.DATE)}
                </CTableDataCell>
                <CTableDataCell>
                    {bookedBeachExtras.reduce((prev, curr) => {
                        let val = isNaN(+curr.quantity.value) ? 0 : parseInt(curr.quantity.value, 10);

                        return prev + val;
                    }, 0)}
                </CTableDataCell>
                <CTableDataCell>
                    <b>{leg.totalPrice}€</b>
                </CTableDataCell>
                <CTableDataCell>
                    {!beachTriAndBookingCanceled && _renderActions(leg)}
                </CTableDataCell>
            </TableRow>
        )
    }

    const _renderEmptyRow = () => {
        return selectedBeachTrip.isCanceled && bookingState !== 'canceled' && (
            <TableRow>
                <EmptyCell colSpan={8}>{i18n.t('tripBookings.noLegs')}</EmptyCell>
            </TableRow>
        )
    }

    return (
        <>
            {_renderTripRow(selectedBeachTrip)}
            {_renderEmptyRow()}
        </>
    );
};

export default BeachEditTableContent;
