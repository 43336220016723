/**
 * Usercashmovementform scene
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 */
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { CForm, CRow, CCol } from '@coreui/react-pro'
// Store
import { actions, RootState } from 'store/rootSlices'
// Components
import SectionContainer from 'shared/components/Sectioncontainer'
import FileUploader from 'shared/components/FormComponents/fileuploader'
import Photoscomponent from 'shared/components/Photoscomponent'
import CustomTextInput from 'shared/components/CustomTextInput'
import Dropdown from 'shared/components/FormComponents/dropdown'
import CustomButton from 'shared/components/CustomButton'
import Datepicker from 'shared/components/FormComponents/datepickertime'
// Utils & Configs
import { hasPermission } from 'permissions'
import config from 'config'
// Styles
import theme from 'ui/theme'
import { Wrapper, Button, ButtonWrapper, ColButton, Row } from './styles'

const Usercashmovementform = ({ t, i18n }) => {

  const {
    isEdit,
    photos,
    entryForm,
    newPhotosID,
    hasEditPermission,
    entry,
    submitButtonState
  } = useSelector((state: RootState) => state.UserCashMovementForm)

  const {
    statement
  } = useSelector((state: RootState) => state.UserCashManagement)

  const {
    paymentTypes
  } = useSelector((state: RootState) => state.InvoicingSummary)

  const {
    collaboratorsLongList
  } = useSelector((state: RootState) => state.CollaboratorsManagement)

  const {
    permissions
  } = useSelector((state: RootState) => state.App)

  const {
    onMount,
    onUnmount,
    createEntryPhoto,
    addToPhotosArray,
    reOrderPhotosArray,
    deletePhotoFromArray,
    onEntryFormUpdate,
    generateTicket,
    onSubmit,
  } = actions.UserCashMovementForm

  const {
    openStatement,
    closeStatement
  } = actions.UserCashManagement

  const {
    navigateBack,
    navigateTo
  } = actions.App

  const movementTypeOptions = isEdit ?
    [
      { label: i18n.t('cashManagement.supplier'), value: "supplier" },
      { label: i18n.t('cashManagement.toManagement'), value: "to_management" },
      { label: i18n.t('cashManagement.toBooking'), value: "booking" },
      { label: i18n.t('cashManagement.toMaintenance'), value: "maintenance" },
      { label: i18n.t('cashManagement.toFuel'), value: "fuel" },
    ]
    :
    [
      { label: i18n.t('cashManagement.supplier'), value: "supplier" },
      { label: i18n.t('cashManagement.toManagement'), value: "to_management" }
    ]
  
    const refundOption = [
      {label: i18n.t("tripBookings.refund"), value: "refund"}
    ]

  const { PERMISSIONS } = config
  const { uuid, id } = useParams()
  const history = useHistory()
  const dispatch = useDispatch()

  const handleAddPhoto = (e) => {
    dispatch(addToPhotosArray({ photos, e, newPhotosID }))
  }

  useEffect(() => {

    dispatch(onMount({ uuid, id, history }))
    return () => {
      dispatch(onUnmount())
    }
  }, [dispatch, onMount, onUnmount, uuid, id, history]);

  return (
    <Wrapper>
      <CForm>
        <SectionContainer title={isEdit ? i18n.t('cashManagement.editEntry') : i18n.t('cashManagement.newEntry')} style={{ width: '100%', paddingBottom: '24px' }}>
          <CRow>
            <CCol>
              <Dropdown
                disabled
                label={i18n.t('cashManagement.user')}
                placeholder={i18n.t('placeholders.paymentUser')}
                value={entryForm.processedBy.value}
                valid={entryForm.processedBy.valid}
                helpText={entryForm.processedBy.message}
                onChange={(e) => {
                  dispatch(onEntryFormUpdate({ ...entryForm, processedBy: { value: e.target.value, valid: true, message: "" } }))
                }}
                options={collaboratorsLongList.map((cl) => {
                  return {
                    label: cl.name,
                    value: cl.uuid
                  }
                })}
              />
            </CCol>
            <CCol>
              <CustomTextInput
                disabled={statement?.isClosed || !hasEditPermission || entry?.isRefund }
                label={i18n.t('cashManagement.supplier')}
                placeholder={i18n.t('placeholders.supplier')}
                value={entryForm.supplier.value}
                valid={entryForm.supplier.valid}
                helpText={entryForm.supplier.message}
                onChange={(e) => {
                  dispatch(onEntryFormUpdate({ ...entryForm, supplier: { value: e.target.value, valid: true, message: "" } }))
                }}
              />
            </CCol>
            <CCol>
              <Datepicker
                id='cashManagement-movementDate'
                disabled={true}
                valid={entryForm.date.valid}
                value={entryForm.date.value}
                helpText={entryForm.date.message}
                label={i18n.t('cashManagement.movementDate')}
                onChange={(date, e) => { }}
              />
            </CCol>
          </CRow>
          <Row>
            <CCol md={isEdit ? 3 : 4}>
              <Dropdown
                disabled={statement?.isClosed || !hasEditPermission || entry?.isRefund }
                label={i18n.t('cashManagement.movementType')}
                placeholder={i18n.t('placeholders.movementType')}
                // value={entryForm.balanceEntryType.value}
                value={entry && entry.isRefund ? "refund" : entryForm.balanceEntryType.value}
                valid={entryForm.balanceEntryType.valid}
                helpText={entryForm.balanceEntryType.message}
                onChange={(e) => {
                  dispatch(onEntryFormUpdate({ ...entryForm, balanceEntryType: { value: e.target.value, valid: true, message: "" } }))
                }}
                options={entry && entry.isRefund ? refundOption : movementTypeOptions}
              />
            </CCol>
            <CCol md={isEdit ? 3 : 4}>
              <Dropdown
                disabled={statement?.isClosed || !hasEditPermission || entry?.isRefund}
                label={i18n.t('cashManagement.paymentType')}
                placeholder={i18n.t('placeholders.paymentType')}
                value={entryForm.paymentType.value}
                valid={entryForm.paymentType.valid}
                helpText={entryForm.paymentType.message}
                onChange={(e) => {
                  dispatch(onEntryFormUpdate({ ...entryForm, paymentType: { value: e.target.value, valid: true, message: "" } }))
                }}
                options={paymentTypes?.map((pt: any) => {
                  return {
                    label: pt.name,
                    value: pt.uid
                  }
                })}
              />
            </CCol>
            <CCol md={isEdit ? 3 : 4}>
              <CustomTextInput
                disabled={statement?.isClosed || !hasEditPermission || entry?.isRefund}
                label={entry && entry.isRefund ? t("tripBookings.amountRefunded") : i18n.t('cashManagement.amount')}
                placeholder={i18n.t('placeholders.paymentAmount')}
                value={entryForm.amount.value}
                valid={entryForm.amount.valid}
                helpText={entryForm.amount.message}
                onChange={(e) => {
                  dispatch(onEntryFormUpdate({ ...entryForm, amount: { value: +e.target.value, valid: true, message: "" } }))
                }}
                icon='euro'
              />
            </CCol>
            {
              entry?.balanceEntryType?.name === movementTypeOptions.filter(mt => mt.value === 'booking')[0]?.label && isEdit && hasEditPermission ? 
              <ColButton md={3}>
                <Button
                  id='cashManagement-linkToReserve'
                  icon={false}
                  color="none"
                  onClick={e => dispatch(navigateTo(`/viagens/reservas/editar-reserva/${entry?.booking?.uuid}`))}>
                  {i18n.t('cashManagement.linkToReserve')}
                </Button>
              </ColButton>
              :
              isEdit && hasEditPermission && 
                <ColButton md={3}>
                  <Button
                    id='cashManagement-generateTicket'
                    icon={false}
                    color="none"
                    onClick={e => { dispatch(generateTicket()) }}>
                    {i18n.t('cashManagement.generateTicket')}
                  </Button>
                </ColButton>
            }
          </Row>
        </SectionContainer>
        <SectionContainer title={i18n.t('cashManagement.depositReceipt')} style={{ width: '100%', paddingBottom: '24px' }}>
          {hasEditPermission && <FileUploader
            id='cashManagement-uploadPhoto'
            disabled={statement?.isClosed}
            required={false}
            label={'Upload'}
            accept='.png, .jpg, .jpeg, .pdf'
            fileSizeLimit={100000000}
            onChange={(e) => { handleAddPhoto(e) }} />}
          {hasPermission(permissions, PERMISSIONS.INVOICING.CASH_MANAGEMENT.VIEW_BALANCE) && <Photoscomponent
            id='cashManagementPhotos'
            array={photos}
            onDrag={e => dispatch(reOrderPhotosArray({ photos, e }))}
            onDelete={(e, index) => {
              dispatch(deletePhotoFromArray({ photos, index }))
            }}
          />}
        </SectionContainer>
        <SectionContainer title={i18n.t('cashManagement.extras')} style={{ width: '100%', paddingBottom: '24px' }}>
          <CustomTextInput
            disabled={statement?.isClosed || !hasEditPermission}
            label={i18n.t('cashManagement.observations')}
            placeholder={i18n.t('placeholders.observations')}
            value={entryForm.description.value}
            valid={entryForm.description.valid}
            helpText={entryForm.description.message}
            onChange={(e) => {
              dispatch(onEntryFormUpdate({ ...entryForm, description: { value: e.target.value, valid: true, message: "" } }))
            }}
          />
        </SectionContainer>
      </CForm>
      {
        statement && statement.isClosed && hasPermission(permissions, PERMISSIONS.INVOICING.CASH_MANAGEMENT.OPEN_STATEMENT) ?
          <ButtonWrapper closed={false}>
            <CustomButton
              id='timeRecord-discard'
              text={statement?.isClosed ? t('cashManagement.reOpen') : t('cashManagement.close')}
              icon={"lock"}
              onClick={() => {
                statement.isClosed ? dispatch(openStatement()) : dispatch(closeStatement())
              }}
              color={statement?.isClosed ? "danger" : "success"}
              buttonStyle={{ color: theme().white }}
            />
          </ButtonWrapper>
          :
          <ButtonWrapper closed={false}>
            <CustomButton
              id='cashMovement-cancel'
              onClick={() => dispatch(navigateBack())}
              text={i18n.t('common.cancelBtn')}
              color="link"
              buttonStyle={{ color: theme().danger, textDecoration: 'unset' }}
            />
            <CustomButton
              id='cashMovement-save'
              disabled={submitButtonState}
              text={i18n.t('common.saveBtn')}
              icon="confirm"
              onClick={e => {
                //because its a new movement, we can only add photos after the movement is created
                //createEntryPhoto actions executes onSubmit action only if isEdit === true 
                if (isEdit && hasEditPermission) {
                  dispatch(createEntryPhoto())
                }
                else {
                  if (hasPermission(permissions, PERMISSIONS.INVOICING.CASH_MANAGEMENT.CREATE_BALANCE)) {
                    dispatch(onSubmit())
                  }
                }
              }}
              color="success"
              buttonStyle={{ color: "#FFFFFF" }}
            />
          </ButtonWrapper>
      }
    </Wrapper>
  )
}

export default Usercashmovementform