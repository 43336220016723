/**
 * Customers scene slice
 *
 * @author João Dias <jdias@ubiwhere.com>
 *
 *
 */
import { createSlice } from '@reduxjs/toolkit'
import { SortType } from 'types/misc';
import { CustomerListItem } from 'types/customers/customers';

interface InitialState {
  page: number,
  total: number,
  customers: CustomerListItem[],
  searchTerm: string,
  loading: boolean,
  sort: SortType,
  exportLoading: boolean;
}

const initialState: InitialState = {
  page: 1,
  total: 0,
  customers: [],
  searchTerm: '',
  loading: true,
  sort: {
    field: null,
    direction: null
  },
  exportLoading: false
}

export default createSlice({
  name: 'Customers',
  initialState,
  reducers: {
    onMount: (state, { payload }) => { },
    onUnmount: () => { },
    setTotal: (state, { payload }) => {
      state.total = payload;
    },
    setExportLoading: (state, { payload }) => {
      state.exportLoading = payload
    },
    fetchCustomers: () => { },
    setPage: (state, { payload }) => {
      state.page = payload;
    },
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
    setCustomers: (state, { payload }) => {
      state.customers = payload;
    },
    setSearchTerm: (state, { payload }) => {
      state.page = 1
      state.searchTerm = payload
    },
    getURLParams:(state, payload) => {},
    populateFiltersOnMount:(state, { payload }) => {
      state.searchTerm = payload.searchTerm
    },
    setSort: (state, { payload }) => {
      state.sort = payload
    },
    getCustomersFile: () => { },
    downloadCustomerExcel: (state, { payload }) => {
      const file = payload
      const fileName = `customer.xlsx`
      const finalFileName = fileName.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(" ", "");

      const url = URL.createObjectURL(file)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute(
        'download',
        finalFileName
      )
      document.body.appendChild(link);
      link.click()
      link.parentNode?.removeChild(link)
    },
  }
})