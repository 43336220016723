import * as React from "react"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill={props.fill ? props.fill : "#EBEDEF"} xmlns="http://www.w3.org/2000/svg">
            <path d="M25.3332 17.3333H17.3332V25.3333H14.6665V17.3333H6.6665V14.6667H14.6665V6.66667H17.3332V14.6667H25.3332V17.3333Z" fill={props.fill ? props.fill : "#EBEDEF"} />
        </svg>
    )
}

export default SvgComponent

