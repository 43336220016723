import * as React from "react"
import { SVGProps } from "react"

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={20}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M20 6V2a2 2 0 0 0-2-2H2C.9 0 .01.89.01 2v4C1.11 6 2 6.9 2 8a2 2 0 0 1-2 2v4c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2v-4c-1.1 0-2-.9-2-2s.9-2 2-2Zm-2-1.46c-1.19.69-2 1.99-2 3.46s.81 2.77 2 3.46V14H2v-2.54C3.19 10.77 4 9.47 4 8c0-1.48-.8-2.77-1.99-3.46L2 2h16v2.54ZM9 11h2v2H9v-2Zm0-4h2v2H9V7Zm0-4h2v2H9V3Z"
            fill={props.fill ?? "#8A93A2"}
        />
    </svg>
)

export default SvgComponent