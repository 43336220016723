import styled from 'styled-components'
import QrReader from "react-qr-reader";

import theme, { devices, devicesMax } from 'ui/theme';


const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items:center;
  height: calc(100vh - 48px);
  column-gap: 60px;
  padding: 0px 40px;
  
`

const LogoContainer = styled.div`
  display: flex;
  min-width: 350px;
  width: 45%;

  @media ${devicesMax.tablet} {
    align-self: baseline
  }

  @media ${devices.tablet} and ${devicesMax.laptop} {
    align-self: baseline
  }

  > svg {
    width: 100%;
  }
`

const CustomQRReader = styled(QrReader)`
`

const ImageWrapper = styled.div`
  height: 121px;
  width: 121px;

  > * {
    width: inherit;
    height: inherit;
  }

  > img {
    border-radius: 50%;
  }
`

const UserClockInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 16px;
  padding: 40px;
  min-width: 100%;
  background-color: ${theme().white};
`

const UserInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  row-gap: 24px;
  min-width: 340px;
`

const Text = styled.p<{ uppercase?: boolean }>`
  margin:0;
  ${({ uppercase }) => uppercase ? `text-transform: uppercase;` : ``}
  

  &.smaller {
    font-size: 12px;
    color: ${theme().gray500Color};
  }
`
const TextWrappper = styled.div`
  text-align: center;
`

export {
  Wrapper,
  LogoContainer,
  CustomQRReader,
  ImageWrapper,
  UserClockInfo,
  UserInfoWrapper,
  Text,
  TextWrappper,
}