/**
 * App
 * Please write a description
 *
 * @author Carlos Silva <csilva@ubiwhere.com>
 *
 *
 */

import LogRocket from 'logrocket';
import React, { useEffect } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import GlobalStyles from 'ui/globalStyles';
import theme from 'ui/theme';

import Routes from 'routes';

//import AppTools from './AppTools';

import { actions, RootState } from 'store/rootSlices';
import Sidebar from 'scenes/Dashboard/components/Sidebar';
import Header from 'scenes/Dashboard/components/Header';
import BreadcrumbManager from 'scenes/Dashboard/components/BreadcrumbManager';
import Loadingcontainer from 'shared/components/Loadingcontainer';


//import i18n from 'i18next';

function App(props) {
  LogRocket.init('animaris/backoffice');
  const { t, i18n } = props;
  const { user, permissions, isInApp, loading } = useSelector((state: RootState) => state.App);
  const { onMount } = actions.App;

  const dispatch = useDispatch();
  //const history = useHistory();

  useEffect(() => {
    dispatch(onMount());
  }, [dispatch, onMount]);

  if (loading) return <AppWrapper isInApp={isInApp}><Loadingcontainer /></AppWrapper>

  return (
    <ThemeProvider theme={theme}>
      <ToastContainer />
      <GlobalStyles />
      <AppWrapper isInApp={isInApp}>
        <HorizontalWrapper>
          {isInApp && <Sidebar />}
          <VerticalWrapper>
            {isInApp && <Header />}
            <ContentGrid isInApp={isInApp}>
              {isInApp && <BreadcrumbManager t={t} i18n={i18n} />}
              <ContentWrapper >
                <div>
                  <Switch>
                    <Route exact path="/">
                      <Redirect to="/dashboard" />
                    </Route>
                    {Routes.map(
                      (route) => {
                        return route.roles.indexOf(user.userType.uid) !== -1
                          && (permissions?.find((p: any) => p === route.permission) || (route.permission === '') || route.permission === 'view_profile') && (
                            <Route
                              exact={route.exact}
                              key={route.key}
                              path={route.path}
                              render={() => <route.component t={t} i18n={i18n} />}
                            />
                          )
                      }
                    )}
                  </Switch>
                </div>
              </ContentWrapper>
            </ContentGrid>
          </VerticalWrapper>
        </HorizontalWrapper>
      </AppWrapper>
      {/* <AppTools i18n={i18n} t={i18n.t} /> */}
    </ThemeProvider>
  );
}

export default withTranslation()(App);

const AppWrapper = styled.div<{ isInApp: boolean }>`
  display: flex;
  background-color: ${({ theme }) => theme.bg};
  flex-direction: 'row';
  justify-content:center;
  width: 100vw;
  height: 100vh;
  max-height: 100vh;
  overflow: hidden;
`;

const HorizontalWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`

const VerticalWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`

//const ContentGrid = styled.div<{ isInApp: boolean }>`
//  width: 100%;
//  max-width: ${(props) => props.isInApp === true ? 'calc(100vw - 256px)' : '100%'};
//  display: ${(props) => props.isInApp === true ? 'grid' : 'flex'};
//  grid-template-columns: 100%;
//  grid-template-rows: 48px calc(100vh - 103px);
//`

const ContentGrid = styled.div<{ isInApp: boolean }>`
  width: 100%;
  max-width: 100vw;
  overflow: hidden;
  display: ${(props) => props.isInApp === true ? 'grid' : 'flex'};
  grid-template-columns: 100%;
  grid-template-rows: 48px calc(100vh - 103px);
`

const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  max-width: 100vw;
  overflow: hidden;
  display: flex;
  justify-content: center;
  
  > div {
    padding: 24px;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
  }
`
