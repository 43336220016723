/**
 * Tripplannings scene sagas
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */
import API from 'api'
import { takeLatest, putResolve, call, select } from 'redux-saga/effects'
import { actions, RootState } from 'store/rootSlices'
import { toast } from 'react-toastify'
import i18n from 'i18next';
import * as Check from 'validations';
import { createDaysArray, NewVehicleValidation, ReinforcementCapacityValidation, StopsValidation } from '../utils';
import { AnyAction } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';
import { PlanningsList } from 'types/trips/plannings';
import { ActivityListItem } from 'types/trips/activity/activity'
// Other Sagas
import { setNewCheckLocationSaga, setNewCheckinSaga } from '../components/Checkinsmodal/logic/sagas';
import { getTripsDetailsSaga, getTripStopDetailsSaga } from '../components/Planninglisttablerow/logic/sagas';
import { getBeachTripDetailsSaga } from '../components/Beachplanningtablerow/logic/sagas';

function* onMountSaga({ payload }: AnyAction) {
  try {
    yield getURLParamsSaga(payload);
    yield getAllActivities();
    const {
      page,
      selectedDay,
      plannings,
      showEndedExits,
      activityFilter,
      vehicleFilter,
      departureFilter,
      arrivalFilter,
      transportFilter
    } = yield select((state: RootState) => state.TripPlannings);

    const newPlannings: PlanningsList = yield call(API.Plannings.GetPlannings, page, showEndedExits, selectedDay, selectedDay, vehicleFilter, activityFilter, departureFilter, arrivalFilter, transportFilter, false)

    if (newPlannings) {
      yield putResolve(actions.TripPlannings.setPlannings({ plannings, newPlannings: newPlannings.results }))
      yield putResolve(actions.TripPlannings.setTotalPlannings(newPlannings.count))
      if (newPlannings.next) {
        yield putResolve(actions.TripPlannings.setHasNextPage(true))
        yield putResolve(actions.TripPlannings.setPage(page + 1))
        yield putResolve(actions.TripPlannings.setFetchTrips(false))
      }
    }

    const newBeachPlannings: PlanningsList = yield call(API.Plannings.GetPlannings, 1, true, selectedDay, selectedDay, '', '', '', '', '', true)
    if (newBeachPlannings && !activityFilter && !vehicleFilter && !departureFilter && !arrivalFilter) {
      yield putResolve(actions.TripPlannings.setBeachPlannings({ newBeachPlannings: newBeachPlannings.results }))
    }

    yield putResolve(actions.TripPlannings.setBottomLoading(false));
  } catch (error) {
    toast.error(i18n.t("toasts.planningsGetError"))
  } finally {
    yield putResolve(actions.TripPlannings.setDisableScroll(false));
    yield putResolve(actions.TripPlannings.setBottomLoading(false));
  }
}

function* getAllActivities() {
  try {
    const activities: ActivityListItem[] = yield call(API.Activities.GetAllActivities)

    if (activities) {
      yield putResolve(actions.TripPlannings.setActivities(activities))
    }
  } catch (error) {
    toast.error(i18n.t("toasts.activitiesGetError"))
  }
}

function* getPlanningsSaga() {
  try {
    yield putResolve(actions.TripPlannings.setBottomLoading(true));
    yield putResolve(actions.TripPlannings.setDisableScroll(true));

    const {
      page,
      activityFilter,
      vehicleFilter,
      departureFilter,
      arrivalFilter,
      selectedDay,
      plannings,
      showEndedExits,
      transportFilter
    } = yield select((state: RootState) => state.TripPlannings);

    let newPlannings: PlanningsList
    if(activityFilter) {
      newPlannings = yield call(API.Plannings.GetPlannings, page, showEndedExits, selectedDay, selectedDay, vehicleFilter, activityFilter, departureFilter, arrivalFilter, transportFilter)
    } else {
      newPlannings = yield call(API.Plannings.GetPlannings, page, showEndedExits, selectedDay, selectedDay, vehicleFilter, activityFilter, departureFilter, arrivalFilter, transportFilter, false)
    }

    if (newPlannings) {
      yield putResolve(actions.TripPlannings.setPlannings({ plannings, newPlannings: newPlannings.results }))
      yield putResolve(actions.TripPlannings.setTotalPlannings(newPlannings.count))
      if (newPlannings.next) {
        yield putResolve(actions.TripPlannings.setHasNextPage(true))
        yield putResolve(actions.TripPlannings.setPage(page + 1))
        yield putResolve(actions.TripPlannings.setFetchTrips(false))
      }

    }

    if(activityFilter || vehicleFilter || departureFilter || arrivalFilter) { 
      yield putResolve(actions.TripPlannings.setBeachPlannings({ newBeachPlannings: [] }))
    } else if (!activityFilter && !vehicleFilter && !departureFilter && !arrivalFilter) { 
      const newBeachPlannings: PlanningsList = yield call(API.Plannings.GetPlannings, 1, true, selectedDay, selectedDay, '', '', '', '', '', true)
      if (newBeachPlannings) {
        yield putResolve(actions.TripPlannings.setBeachPlannings({ newBeachPlannings: newBeachPlannings.results }))
      }
    }

    yield putResolve(actions.TripPlannings.setBottomLoading(false));
  } catch (error) {
    toast.error(i18n.t("toasts.planningsGetError"))
  } finally {
    yield putResolve(actions.TripPlannings.setDisableScroll(false));
    yield putResolve(actions.TripPlannings.setBottomLoading(false));
  }
}

function* getPlanningsScrollSaga() {
  try {
    yield putResolve(actions.TripPlannings.setScrollLoading(true));
    yield putResolve(actions.TripPlannings.setDisableScroll(true));

    const {
      page,
      activityFilter,
      vehicleFilter,
      departureFilter,
      arrivalFilter,
      selectedDay,
      plannings,
      showEndedExits,
      transportFilter
    } = yield select((state: RootState) => state.TripPlannings);

    const newPlannings: PlanningsList = yield call(API.Plannings.GetPlannings, page, showEndedExits, selectedDay, selectedDay, vehicleFilter, activityFilter, departureFilter, arrivalFilter, transportFilter, false)

    if (newPlannings) {
      yield putResolve(actions.TripPlannings.setPlannings({ plannings, newPlannings: newPlannings.results }))
      yield putResolve(actions.TripPlannings.setTotalPlannings(newPlannings.count))
      if (newPlannings.next) {
        yield putResolve(actions.TripPlannings.setHasNextPage(true))
        yield putResolve(actions.TripPlannings.setPage(page + 1))
        yield putResolve(actions.TripPlannings.setFetchTrips(false))
      }

    }
    yield putResolve(actions.TripPlannings.setScrollLoading(false));
  } catch (error) {
    toast.error(i18n.t("toasts.planningsGetError"))
  } finally {
    yield putResolve(actions.TripPlannings.setDisableScroll(false));
    yield putResolve(actions.TripPlannings.setScrollLoading(false));
    yield putResolve(actions.TripPlannings.setBottomLoading(false));
  }
}


function* getBookingsByTripSaga({ payload }: AnyAction) {

  const {
    showCheckinModal,
    checkinSearch,
    checkinOutboundSelected
  } = yield select((state: RootState) => state.TripPlannings);

  yield putResolve(actions.TripPlannings.setModalLoading(true))

  try {
    let passengers
    if (showCheckinModal.openBy === 'generic') {
      passengers = yield call(API.Plannings.GetBookingsByTrip, showCheckinModal.planningId, checkinSearch, checkinOutboundSelected, "", showCheckinModal.stop.id)
    } else if (showCheckinModal.stop?.id !== -1) {
      passengers = yield call(API.Plannings.GetBookingsByTrip, showCheckinModal.planningId, checkinSearch, checkinOutboundSelected, showCheckinModal.vehicle.vehicle.uuid, showCheckinModal.stop.id)
    } else {
      passengers = yield call(API.Plannings.GetBookingsByTrip, showCheckinModal.planningId, checkinSearch, checkinOutboundSelected, showCheckinModal.vehicle.vehicle.uuid)
    }
    if (passengers) {
      yield putResolve(actions.TripPlannings.setOpenPlanningTripPassengers({ passengers }))
      yield putResolve(actions.TripPlannings.setModalLoading(false))
      yield putResolve(actions.TripPlannings.setModalElegibleLoading(true))
    }

    const eligiblePassengers = yield call(API.Plannings.GetEligibleBookingsByTrip, showCheckinModal.planningId, checkinOutboundSelected)
    if (eligiblePassengers) {
      yield putResolve(actions.TripPlannings.setOpenPlanningTripPassengers({ eligibleBookings: eligiblePassengers }))
      yield putResolve(actions.TripPlannings.setModalElegibleLoading(false))
    }

    // if (passengers && eligiblePassengers) {
    //   yield putResolve(actions.TripPlannings.setOpenPlanningTripPassengers({passengers, eligibleBookings: eligiblePassengers}))
    // } else {
    //   yield putResolve(actions.TripPlannings.setOpenPlanningTripPassengers({passengers}))
    // }
    yield putResolve(actions.TripPlannings.setCheckingIn(false))

  } catch (error) {
    toast.error(i18n.t("toasts.planningPassengersGetError"))
    yield putResolve(actions.TripPlannings.setModalLoading(false))
    yield putResolve(actions.TripPlannings.setModalElegibleLoading(false))
  }
}

function* onSubmitModalSaga() {
  const {
    modalInitialVehicle,
    selectedVehicle,
    isUpdatingCircuit,
    isRemovingVehicle,
    didStopsChange,
  } = yield select((state: RootState) => state.TripPlannings)

  if (!isUpdatingCircuit) {
    yield onSubmitReinforcementSaga()
  }
  else if (!isRemovingVehicle) {

    let hasVehicleChanged = false
    if (modalInitialVehicle.value !== selectedVehicle.value) hasVehicleChanged = true

    if (!didStopsChange) {
      hasVehicleChanged ?
        yield onSubmitReplaceVehicleSaga()
        :
        yield putResolve(actions.TripPlannings.setIsUpdatingCircuit(false))
      yield putResolve(actions.TripPlannings.setIsRemovingVehicle(false))
      yield putResolve(actions.TripPlannings.setDidStopsChange(false))
      yield putResolve(actions.TripPlannings.setShowModal(false))
      yield putResolve(actions.TripPlannings.setModalLoading(true))
      yield putResolve(actions.TripPlannings.clearPlanningsArray())
      yield getPlanningsSaga()
    }
    else {
      hasVehicleChanged ?
        yield onSubmitReplaceVehicleSaga()
        :
        yield onSubmitCircuitUpdateSaga()
    }
  }
  else {
    yield onSubmitRemoveVehicleSaga()
  }
}

function* onSubmitReinforcementSaga() {
  const {
    openPlanning,
    reinforcementCapacity,
    selectedVehicle,
    openPlanningOutboundStops,
    openPlanningInboundStops,
  } = yield select((state: RootState) => state.TripPlannings);

  //validate here!!!

  const reinforcementCapacityValidation = Check.checkValidation({ reinforcementCapacity }, ReinforcementCapacityValidation)
  const vehicleValidation = Check.checkValidation({ selectedVehicle }, NewVehicleValidation)
  const outboundStopsValidation = Check.checkListValidation(openPlanningOutboundStops.filter(s => s.isActive.value === true), StopsValidation)
  const inboundStopsValidation = Check.checkListValidation(openPlanningInboundStops.filter(s => s.isActive.value === true), StopsValidation)

  if (reinforcementCapacityValidation.invalid || vehicleValidation.invalid || outboundStopsValidation.invalid || inboundStopsValidation.invalid) {
    yield putResolve(actions.TripPlannings.onReinforcementCapacityValidation(reinforcementCapacityValidation))
    yield putResolve(actions.TripPlannings.onSelectedVehicleValidation(vehicleValidation))
    yield putResolve(actions.TripPlannings.onOutboundStopsValidation({ outboundStopsValidation, openPlanningOutboundStops }))
    yield putResolve(actions.TripPlannings.onInboundStopsValidation({ inboundStopsValidation, openPlanningInboundStops }))
    toast.error(i18n.t("toasts.invalidFields"))
  }
  else {
    const finalForm = {
      vehicle: selectedVehicle.value,
      stops: openPlanningOutboundStops.filter(stop => stop.isActive.value === true).map((st) => {

        if (st.stop.details.find(det => det.vehicle?.uuid === selectedVehicle.value)) {

          return {
            stop: st.stop.id,
            time: st.time.value,
            isOutbound: true,
            activities: st.stop.details.find(det => det.vehicle.uuid === selectedVehicle.value).activities.filter(act => act.isOn.value === true).map((act) => act.activity.id),
          }
        }
        return false

      }).concat(openPlanningInboundStops.filter(stop => stop.isActive.value === true).map((st) => {

        if (st.stop.details.find(det => det.vehicle?.uuid === selectedVehicle.value)) {
          return {
            stop: st.stop.id,
            time: st.time.value,
            isOutbound: false,
            activities: st.stop.details.find(det => det.vehicle.uuid === selectedVehicle.value).activities.filter(act => act.isOn.value === true).map((act) => act.activity.id),
          }
        }
        return false
      }))
    }

    try {

      const reinforcement = yield call(API.Plannings.AddReinforcement, finalForm, openPlanning.id)

      if (reinforcement) {
        toast.success(i18n.t("toasts.addReinforcementSuccess"));
        yield putResolve(actions.TripPlannings.setIsUpdatingCircuit(false))
        yield putResolve(actions.TripPlannings.setIsRemovingVehicle(false))
        yield putResolve(actions.TripPlannings.setDidStopsChange(false))
        yield putResolve(actions.TripPlannings.setShowModal(false))
        yield putResolve(actions.TripPlannings.setModalLoading(true))
        yield putResolve(actions.TripPlannings.clearPlanningsArray())
        yield getPlanningsSaga()
      }
    } catch (error) {
      toast.error(i18n.t("toasts.addReinforcementError"))
    } finally {

    }
  }
}

function* onSubmitCircuitUpdateSaga() {

  const {
    selectedVehicle,
    openPlanning,
    openPlanningOutboundStops,
    openPlanningInboundStops
  } = yield select((state: RootState) => state.TripPlannings)

  const outboundStopsValidation = Check.checkListValidation(openPlanningOutboundStops.filter(s => s.isActive.value === true), StopsValidation)
  const inboundStopsValidation = Check.checkListValidation(openPlanningInboundStops.filter(s => s.isActive.value === true), StopsValidation)

  if (outboundStopsValidation.invalid || inboundStopsValidation.invalid) {
    yield putResolve(actions.TripPlannings.onOutboundStopsValidation({ outboundStopsValidation, openPlanningOutboundStops }))
    yield putResolve(actions.TripPlannings.onInboundStopsValidation({ inboundStopsValidation, openPlanningInboundStops }))
    toast.error(i18n.t("toasts.invalidFields"))
  }
  else {
    const finalForm = {
      vehicle: selectedVehicle.value,
      stops: openPlanningOutboundStops.filter(stop => stop.isActive.value === true).filter(st => st.stop.details.find(det => det.vehicle?.uuid === selectedVehicle.value).activities.filter(act => act.isOn.value === true).length !== 0).map((st) => {

        return {
          stop: st.stop.id,
          isOutbound: true,
          time: st.time.value,
          ...(
            st.stop.details.find(det => det.vehicle?.uuid === selectedVehicle.value).activities.filter(act => act.isOn.value === true).length !== 0 &&
            {
              activities: st.stop.details.find(det => det.vehicle?.uuid === selectedVehicle.value).activities.filter(act => act.isOn.value === true).map((act) => act.activity.id)
            }
          )
        }

      }).concat(openPlanningInboundStops.filter(stop => stop.isActive.value === true).filter(st => st.stop.details.find(det => det.vehicle?.uuid === selectedVehicle.value).activities.filter(act => act.isOn.value === true).length !== 0).map((st) => {


        return {
          stop: st.stop.id,
          isOutbound: false,
          time: st.time.value,
          ...(
            st.stop.details.find(det => det.vehicle?.uuid === selectedVehicle.value).activities.filter(act => act.isOn.value === true).length !== 0 &&
            {
              activities: st.stop.details.find(det => det.vehicle?.uuid === selectedVehicle.value).activities.filter(act => act.isOn.value === true).map((act) => act.activity.id)
            }
          )
        }

      }))
    }

    try {
      const updatedCircuit = yield call(API.Plannings.ManageVehicle, finalForm, openPlanning.id)

      if (updatedCircuit) {
        toast.success(i18n.t("toasts.updateCircuitSuccess"));
        yield putResolve(actions.TripPlannings.setIsUpdatingCircuit(false))
        yield putResolve(actions.TripPlannings.setIsRemovingVehicle(false))
        yield putResolve(actions.TripPlannings.setDidStopsChange(false))
        yield putResolve(actions.TripPlannings.setShowModal(false))
        yield putResolve(actions.TripPlannings.setModalLoading(true))
        yield putResolve(actions.TripPlannings.clearPlanningsArray())
        yield getPlanningsSaga()
      }
      else {
        toast.error(i18n.t("toasts.updateCircuitError"));
      }

    } catch (error) {
      toast.error(i18n.t("toasts.updateCircuitError"))
    }
  }

}

function* onSubmitRemoveVehicleSaga() {

  const {
    modalInitialVehicle,
    openPlanning
  } = yield select((state: RootState) => state.TripPlannings)

  const finalForm = {
    vehicle: modalInitialVehicle.value
  }

  try {
    const removedVehicle = yield call(API.Plannings.RemoveVehicle, finalForm, openPlanning.id)

    if (removedVehicle) {
      toast.success(i18n.t("toasts.removeVehicleSuccess"));
      yield putResolve(actions.TripPlannings.setIsUpdatingCircuit(false))
      yield putResolve(actions.TripPlannings.setIsRemovingVehicle(false))
      yield putResolve(actions.TripPlannings.setDidStopsChange(false))
      yield putResolve(actions.TripPlannings.setShowModal(false))
      yield putResolve(actions.TripPlannings.setModalLoading(true))
      yield putResolve(actions.TripPlannings.clearPlanningsArray())
      yield getPlanningsSaga()
    }
  } catch (error) {
    toast.error(i18n.t("toasts.removeVehicleError"))
  }
}

function* onSubmitReplaceVehicleSaga() {

  const {
    modalInitialVehicle,
    selectedVehicle,
    openPlanningOutboundStops,
    openPlanningInboundStops,
    openPlanning
  } = yield select((state: RootState) => state.TripPlannings)

  const outboundStopsValidation = Check.checkListValidation(openPlanningOutboundStops.filter(s => s.isActive.value === true), StopsValidation)
  const inboundStopsValidation = Check.checkListValidation(openPlanningInboundStops.filter(s => s.isActive.value === true), StopsValidation)

  if (outboundStopsValidation.invalid || inboundStopsValidation.invalid) {
    yield putResolve(actions.TripPlannings.onOutboundStopsValidation({ outboundStopsValidation, openPlanningOutboundStops }))
    yield putResolve(actions.TripPlannings.onInboundStopsValidation({ inboundStopsValidation, openPlanningInboundStops }))
    toast.error(i18n.t("toasts.invalidFields"))
  }
  else {
    const finalForm = {
      newVehicle: selectedVehicle.value,
      oldVehicle: modalInitialVehicle.value,
      stops: openPlanningOutboundStops.filter(stop => stop.isActive.value === true).filter(st => st.stop.details.find(det => det.vehicle.uuid === selectedVehicle.value).activities.filter(act => act.isOn.value === true).length !== 0).map((st) => {

        return {
          stop: st.stop.id,
          isOutbound: true,
          time: st.time.value,
          ...(
            st.stop.details.find(det => det.vehicle.uuid === selectedVehicle.value).activities.filter(act => act.isOn.value === true).length !== 0 &&
            {
              activities: st.stop.details.find(det => det.vehicle.uuid === selectedVehicle.value).activities.filter(act => act.isOn.value === true).map((act) => act.activity.id)
            }
          )
        }
      }
      ).concat(openPlanningInboundStops.filter(stop => stop.isActive.value === true).filter(st => st.stop.details.find(det => det.vehicle.uuid === selectedVehicle.value).activities.filter(act => act.isOn.value === true).length !== 0).map((st) => {


        return {
          stop: st.stop.id,
          isOutbound: false,
          time: st.time.value,
          ...(
            st.stop.details.find(det => det.vehicle.uuid === selectedVehicle.value).activities.filter(act => act.isOn.value === true).length !== 0 &&
            {
              activities: st.stop.details.find(det => det.vehicle.uuid === selectedVehicle.value).activities.filter(act => act.isOn.value === true).map((act) => act.activity.id)
            }
          )
        }

      }))
    }

    try {

      const replaceVehicle = yield call(API.Plannings.ReplaceVehicle, finalForm, openPlanning.id)

      if (replaceVehicle) {
        yield putResolve(actions.TripPlannings.setIsUpdatingCircuit(false))
        yield putResolve(actions.TripPlannings.setIsRemovingVehicle(false))
        yield putResolve(actions.TripPlannings.setDidStopsChange(false))
        yield putResolve(actions.TripPlannings.setShowModal(false))
        yield putResolve(actions.TripPlannings.setModalLoading(true))
        yield putResolve(actions.TripPlannings.clearPlanningsArray())
        yield getPlanningsSaga()
      }
      else {
        toast.error(i18n.t("toasts.replaceVehicleError"));
      }
    } catch (error) {
      toast.error(i18n.t("toasts.replaceVehicleError"))
    }
  }

}

function* getURLParamsSaga(payload: AnyAction) {

  let params = new URLSearchParams(payload.location.search)

  const {
    hasDateParams,
    activityFilter,
    vehicleFilter,
    departureFilter,
    arrivalFilter,
    startDate,
    endDate,
    showEndedExits,
    selectedDay
  } = yield select((state: RootState) => state.TripPlannings);

  if (params.toString() !== "") {
    yield putResolve(actions.TripPlannings.populateFiltersOnMount({
      activityFilter: params.get('activity') ?? "",
      departureFilter: params.get('departure') ?? "",
      arrivalFilter: params.get('arrival') ?? "",
      vehicleFilter: params.get('vehicle') ?? "",
      showEndedExits: params.get('showFinished') && params.get('showFinished') === "true" ? true : false,
      startDate: params.get('startDate') ?? new Date().toISOString().slice(0, 10),
      endDate: params.get('endDate') ?? new Date().toISOString().slice(0, 10),
      selectedDay: params.get('selectedDay') ?? new Date().toISOString().slice(0, 10),
    }))

    if (params.get('startDate') && params.get('endDate')) {
      const arr = createDaysArray(DateTime.fromISO(params.get('startDate')).toJSDate(), DateTime.fromISO(params.get('endDate')).toJSDate())

      yield putResolve(actions.TripPlannings.setDays(arr))
      yield putResolve(actions.TripPlannings.setHasDateParams(!hasDateParams));
    }

  }
  else {
    //state.hasDateParams has to be different from the last known state.hasDateParams to force a reRender on datepicker
    //this reRender must be done so the datepicker changes its label
    yield putResolve(actions.TripPlannings.setHasDateParams(!hasDateParams));
    params.set('activity', activityFilter)
    params.set('vehicle', vehicleFilter)
    params.set('departure', departureFilter)
    params.set('arrival', arrivalFilter)
    params.set('startDate', startDate)
    params.set('endDate', endDate)
    params.set('selectedDay', selectedDay)
    params.set('showFinished', showEndedExits)
    payload.replace({ search: (params).toString() })
  }
}

function* setDatesIntervalSaga({ payload }: AnyAction) {

  if (payload.e.startDate && payload.e.endDate) {
    const arr = createDaysArray(payload.e.startDate, payload.e.endDate)
    let params = new URLSearchParams(payload.history.location.search);

    if (params.get('startDate') !== arr[0]?.toISO().slice(0, 10) || params.get('endDate') !== arr[arr.length - 1]?.toISO().slice(0, 10)) {
      arr[0].toISO().slice(0, 10) !== "" ? params.set('selectedDay', arr[0]?.toISO().slice(0, 10)) : params.delete('selectedDay')
      payload.e.startDate !== "" ? params.set('startDate', arr[0]?.toISO().slice(0, 10)) : params.delete('startDate')
      payload.e.endDate !== "" ? params.set('endDate', arr[arr.length - 1]?.toISO().slice(0, 10)) : params.delete('endDate')
      payload.history.replace({ search: (params).toString() })

      yield putResolve(actions.TripPlannings.setStartDate(arr[0]?.toISO().slice(0, 10)))
      yield putResolve(actions.TripPlannings.setEndDate(arr[arr.length - 1]?.toISO().slice(0, 10)))
      yield putResolve(actions.TripPlannings.setSelectedDay(arr[0].toISO().slice(0, 10)))
      yield putResolve(actions.TripPlannings.setDays(arr))
    }

  }
}




function* onUnmountSaga() { }

export default function* watcherSignin() {
  yield takeLatest('Tripplannings/onMount', onMountSaga)
  yield takeLatest('Tripplannings/onUnmount', onUnmountSaga)
  yield takeLatest('Tripplannings/onSubmitModal', onSubmitModalSaga)
  yield takeLatest('Tripplannings/getPlannings', getPlanningsSaga)
  yield takeLatest('Tripplannings/getPlanningsScroll', getPlanningsScrollSaga)
  yield takeLatest('Tripplannings/setShowCheckInModal', getBookingsByTripSaga)
  yield takeLatest('Tripplannings/setCheckinModalUnit', getBookingsByTripSaga)
  yield takeLatest('Tripplannings/getTripStopDetails', getTripStopDetailsSaga)
  yield takeLatest('Tripplannings/setCheckinSearch', getBookingsByTripSaga);
  yield takeLatest('Tripplannings/setDatesInterval', setDatesIntervalSaga);
  yield takeLatest('Tripplannings/getTripsDetails', getTripsDetailsSaga)
  yield takeLatest('Tripplannings/getBeachTripDetails', getBeachTripDetailsSaga)
  yield takeLatest('Tripplannings/setShowEndedExits', getPlanningsSaga);
  yield takeLatest('Tripplannings/setActivityFilter', getPlanningsSaga);
  yield takeLatest('Tripplannings/setDepartureFilter', getPlanningsSaga);
  yield takeLatest('Tripplannings/setArrivalFilter', getPlanningsSaga);
  yield takeLatest('Tripplannings/setVehicleFilter', getPlanningsSaga);
  yield takeLatest('Tripplannings/setSelectedDay', getPlanningsSaga);
  yield takeLatest('Tripplannings/onDayChange', getPlanningsSaga);
  yield takeLatest('Tripplannings/getURLParams', getURLParamsSaga);
  yield takeLatest('Tripplannings/setNewCheckin', setNewCheckinSaga)
  yield takeLatest('Tripplannings/setNewCheckLocation', setNewCheckLocationSaga);
}
