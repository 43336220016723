import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions, RootState } from 'store/rootSlices';
//styles
import { Row } from '../../../../styles';
//Components
import { CCol } from '@coreui/react-pro';
import CustomTextInput from 'shared/components/CustomTextInput';
import Dropdown from 'shared/components/FormComponents/dropdown';

const PaymentFormRows = ({ t }) => {
  const {
    enablePayment,
    paymentForm,
    paymentTypes,
    paymentSources,
    collaborators,
    hasEditPermission,
  } = useSelector((state: RootState) => state.BookingForm);

  const { onPaymentFormChange } = actions.BookingForm;

  const dispatch = useDispatch();

  return (
    <>
      <Row>
        <CCol sm={6}>
          <Dropdown
            id="bookingForm-paymentSource"
            valid={paymentForm.source.valid}
            value={paymentForm.source.value}
            helpText={paymentForm.source.message}
            disabled={!hasEditPermission || !enablePayment}
            placeholder={t('placeholders.paymentSource')}
            label={t('tripBookings.paymentSource')}
            onChange={(e) => {
              dispatch(
                onPaymentFormChange({
                  ...paymentForm,
                  source: { value: e.target.value, message: '', valid: true },
                })
              );
            }}
            options={paymentSources.map((s) => {
              if (s.name === 'Website') return { label: s.name, value: 'disabled' };
              return { label: s.name, value: s.uuid };
            })}
          />
        </CCol>
        <CCol sm={6}>
          <Dropdown
            id="bookingForm-paymentType"
            valid={paymentForm.type.valid}
            value={paymentForm.type.value}
            helpText={paymentForm.type.message}
            placeholder={t('placeholders.paymentType')}
            label={
              paymentForm.isRefund
                ? t('tripBookings.refundMethod')
                : t('tripBookings.paymentMethod')
            }
            onChange={(e) => {
              dispatch(
                onPaymentFormChange({
                  ...paymentForm,
                  type: { value: e.target.value, message: '', valid: true },
                })
              );
            }}
            options={paymentTypes.map((s) => {
              return { label: s.name, value: s.uuid };
            })}
            disabled={!hasEditPermission || !enablePayment}
          />
        </CCol>
      </Row>
      <Row>
        <CCol sm={6}>
          <Dropdown
            id="bookingForm-paymentProcessedBy"
            valid={paymentForm.processedBy.valid}
            value={paymentForm.processedBy.value}
            helpText={paymentForm.processedBy.message}
            placeholder={t('placeholders.paymentUser')}
            label={
              paymentForm.isRefund
                ? t('tripBookings.refundedBy')
                : t('tripBookings.receivedBy')
            }
            onChange={(e) => {
              dispatch(
                onPaymentFormChange({
                  ...paymentForm,
                  processedBy: { value: e.target.value, message: '', valid: true },
                })
              );
            }}
            options={collaborators.map((s) => {
              return { label: s.name, value: s.uuid };
            })}
            disabled={!hasEditPermission || !enablePayment}
          />
        </CCol>
        <CCol sm={6}>
          <CustomTextInput
            id="bookingForm-paymentAmount"
            icon="euro"
            valid={paymentForm.amount.valid}
            value={paymentForm.amount.value}
            helpText={paymentForm.amount.message}
            placeholder={t('placeholders.paymentAmount')}
            label={
              paymentForm.isRefund
                ? t('tripBookings.amountRefunded')
                : t('tripBookings.amountReceived')
            }
            disabled={!hasEditPermission || !enablePayment}
            onChange={(e) => {
              if (e.target.value.trim().length && isNaN(+e.target.value)) {
                e.preventDefault();
              } else {
                dispatch(
                  onPaymentFormChange({
                    ...paymentForm,
                    amount: { value: e.target.value.trim(), message: '', valid: true },
                  })
                );
              }
            }}
          />
        </CCol>
      </Row>
    </>
  );
};

export default PaymentFormRows;