export const permissions = {
    FLEET: {
        VIEW_VEHICLE: "view_vehicle",
        CREATE_VEHICLE: "create_vehicle",
        EDIT_VEHICLE: "edit_vehicle",
        DELETE_VEHICLE: "delete_vehicle",
        VIEW_FUEL: "view_fuel",
        EDIT_FUEL: "edit_fuel",
        EDIT_FUEL_FUELLED_BY: "overwrite_fuel_fuelled_by",
        CREATE_FUEL_TO_STATION: "create_fuel_to_station",
        CREATE_FUEL_TO_VEHICLE: "create_fuel_to_vehicle",
        DELETE_FUEL: "delete_vehicle",
        VIEW_FUEL_STATION: "view_fuel_station",
        CREATE_FUEL_STATION: "create_fuel_station",
        EDIT_FUEL_STATION: "edit_fuel_station",
        DELETE_FUEL_STATION: "delete_fuel_station",
        VIEW_MAINTENANCE: "view_maintenance",
        CREATE_MAINTENANCE: "create_maintenance",
        EDIT_MAINTENANCE: "edit_maintenance",
        DELETE_MAINTENANCE: "delete_maintenance",
        LIST_VEHICLE: "list_vehicle",
        LIST_MAINTENANCE: "list_maintenance",
        LIST_FUEL: "list_fuel",
        CREATE_FLEET_SETTINGS: "create_fleet_settings",
        EDIT_FLEET_SETTINGS: "edit_fleet_settings",
        DELETE_FLEET_SETTINGS: "delete_fleet_settings",
        CREATE_VEHICLE_HOURS: "create_vehicle_hours",
    },
    USERS: {
        VIEW_CUSTOMER: "view_customer",
        CREATE_CUSTOMER: "create_customer",
        EDIT_CUSTOMER: "edit_customer",
        DELETE_CUSTOMER: "delete_customer",
        VIEW_TEAM_MEMBER: "view_team_member",
        CREATE_TEAM_MEMBER: "create_team_member",
        EDIT_TEAM_MEMBER: "edit_team_member",
        VIEW_TEAM_MEMBER_CONTRACT: "view_team_member_contract",
        DELETE_TEAM_MEMBER: "delete_team_member",
        VIEW_PARTNER: "view_partner",
        CREATE_PARTNER: "create_partner",
        EDIT_PARTNER: "edit_partner",
        DELETE_PARTNER: "delete_partner",
        VIEW_PARTNER_USER: "view_partner_user",
        CREATE_PARTNER_USER: "create_partner_user",
        EDIT_PARTNER_USER: "edit_partner_user",
        DELETE_PARTNER_USER: "delete_partner_user",
        LIST_CUSTOMER: "list_customer",
        LIST_TEAM_MEMBER: "list_team_member",
        LIST_PARTNER: "list_partner",
        LIST_PARTNER_USER: "list_partner_user",
        TIME_RECORD: {
            LIST_WORKDAY_ENTRY: "list_workday_entry",
            VIEW_WORKDAY_ENTRY: "view_workday_entry",
            CREATE_WORKDAY_ENTRY: "create_workday_entry",
            EDIT_WORKDAY_ENTRY: "edit_workday_entry",
            DELETE_WORKDAY_ENTRY: "delete_workday_entry",
            EXPORT_WORKDAY_ENTRY: "export_workday",
            LIST_WORKDAY: "list_workday",
            VIEW_WORKDAY: "view_workday",
            EXPORT_WORKDAY: "export_workday",
            VIEW_CLOCKINS: "view_clockins",
            CREATE_CLOCKINS: "create_clockins",
            LIST_CLOCKINS: "list_clockins",
        }
    },
    TRIPS: {
        STOPS: {
            CREATE_STOP: "create_stop",
            EDIT_STOP: "edit_stop",
            DELETE_STOP: "delete_stop",
        },
        CIRCUITS: {
            LIST_CIRCUIT: "list_circuit",
            VIEW_CIRCUIT: "view_circuit",
            CREATE_CIRCUIT: "create_circuit",
            EDIT_CIRCUIT: "edit_circuit",
            DELETE_CIRCUIT: "delete_circuit",
        },
        CATEGORIES: {
            CREATE_CATEGORY: "create_category",
            EDIT_CATEGORY: "edit_category",
            DELETE_CATEGORY: "delete_category",
        },
        PASSENGER_TYPES: {
            CREATE_PASSENGER_TYPE: "create_passenger_type",
            EDIT_PASSENGER_TYPE: "edit_passenger_type",
            DELETE_PASSENGER_TYPE: "delete_passenger_type",
        },
        ACTIVITIES: {
            CREATE_ACTIVITY: "create_activity",
            EDIT_ACTIVITY: "edit_activity",
            DELETE_ACTIVITY: "delete_activity",
        },
        EXTRAS: {
            LIST_EXTRA: "list_extra",
            VIEW_EXTRA: "view_extra",
            CREATE_EXTRA: "create_extra",
            EDIT_EXTRA: "edit_extra",
            DELETE_EXTRA: "delete_extra",
        },
        PLANNINGS: {
            LIST_PLANNING: "list_trip",
            VIEW_PLANNING: "view_trip",
            CREATE_PLANNING: "create_trip",
            EDIT_PLANNING: "edit_trip",
            DELETE_PLANNING: "delete_trip",
        },
        BOOKINGS: {
            LIST_BOOKING: "list_booking",
            VIEW_BOOKING: "view_booking",
            CREATE_BOOKING: "create_booking",
            EDIT_BOOKING: "edit_booking",
            DELETE_BOOKING: "delete_booking",
            EDIT_BOOKING_CHECKINS: "edit_booking_checkins",
            LIST_BOOKING_CHECKINS: "list_booking_checkins",
            CANCEL_BOOKING: "cancel_booking"
        },
        SERVICES: {
            LIST_SERVICE: "list_service",
            VIEW_SERVICE: "view_service",
            CREATE_SERVICE: "create_service",
            EDIT_SERVICE: "edit_service",
            DELETE_SERVICE: "delete_service",
        },
        CAMPAIGNS: {
            LIST_CAMPAIGN: "list_campaign",
            VIEW_CAMPAIGN: "view_campaign",
            CREATE_CAMPAIGN: "create_campaign",
            EDIT_CAMPAIGN: "edit_campaign",
            DELETE_CAMPAIGN: "delete_campaign",
        },
        EVENTS: {
            VIEW_EVENT: "view_event",
            CREATE_EVENT: "create_event",
            EDIT_EVENT: "edit_event",
            DELETE_EVENT: "delete_event",
        },
    },
    INVOICING: {
        LIST_INVOICE: "list_invoice",
        VIEW_INVOICE: "view_invoice",
        CREATE_INVOICE: "create_invoice",
        EDIT_INVOICE: "edit_invoice",
        DELETE_INVOICE: "delete_invoice",
        EXPORT_INVOICE: "export_invoice",
        CASH_MANAGEMENT: {
            CLOSE_STATEMENT: "close_statement",
            OPEN_STATEMENT: "open_statement",
            VIEW_STATEMENT: "view_statement",
            LIST_STATEMENT: "list_statement",
            APPROVE_STATEMENT: "approve_statement",
            REJECT_STATEMENT: "reject_statement",
            VIEW_BALANCE: "view_balance",
            CREATE_BALANCE: "create_balance",
            EDIT_BALANCE: "edit_balance",
            LIST_BALANCE: "list_balance"
        }
    },
    SETTINGS: {
        VIEW_SETTING: "view_setting",
        CREATE_SETTING: "create_setting",
        EDIT_SETTING: "edit_setting",
        DELETE_SETTING: "delete_setting",
    }

}

// userPermissions: logged in user permissions
// action: action the user wants to perform on UI
export const hasPermission = (userPermissions, action) => {
    if (userPermissions.find(p => p === action)) return true
    return false
}

export const TEAM_MEMBER_ROLES = {
    ADMINISTRATOR: 'Administrador',
    SUPERVISOR: 'Supervisor',
    SKIPPER: 'Skipper',
    COLABORATOR: 'Colaborador',
}