/**
 * BookingLegs component
 *
 * @author João Flores <jflores@ubiwhere.com>
 *
 *
 */

import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store/rootSlices'
//styles
import { BorderedWrapper } from './styles'
//Components
import CustomLegsTable from './components/CustomLegsTable';
import TableControls from './components/TableControls';
import CustomBeachTripsTable from './components/CustomBeachTripsTable';

const BookingLegs = ({ t }) => {
    const { edit, isReschedule, directionFilter } = useSelector((state: RootState) => state.BookingForm);

    return (
        <>
            {edit && !isReschedule ? (
                directionFilter === 'beach' ?
                    <CustomBeachTripsTable isEdit={true} isReschedule={false} />
                    :
                    <CustomLegsTable isEdit={true} isReschedule={false} />
            ) : (
                <BorderedWrapper>
                    <TableControls t={t} />
                    {
                        directionFilter === 'beach' ?
                            <CustomBeachTripsTable isEdit={false} isReschedule={isReschedule} />
                            :
                            <CustomLegsTable isEdit={isReschedule} isReschedule={isReschedule} />
                    }
                </BorderedWrapper>
            )}
        </>
    )
};

export default BookingLegs