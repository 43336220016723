/**
 * Filter component
 *
 * @author João Dias <jdias@ubiwhere.com>
 *
 *
 */

import React, { useEffect, useState } from 'react'
import theme from 'ui/theme';
import * as Styles from './styles'

type PropTypes = {
  id?: string,
  icon?: JSX.Element,
  filterName: string,
  onChange: Function,
  defaultOption?: { label: string, value: string },
  options: Array<{ label: string, value: string }>,
  maxWidth?: string,
}

const ref = React.createRef<HTMLDivElement>();

const FilterComponent = (props: PropTypes) => {
  const [isOpen, setOpen] = useState<boolean>(false);
  const [current, setCurrent] = useState<{ value: string, label: string }>(props.defaultOption ? props.defaultOption : { value: "", label: props.filterName });
  //const [sortMode, setSortMode] = useState<string>('none');
  let timeOutId: number;

  const isDefaultSelected = current.value === "";

  useEffect(() => {
    if(props.defaultOption) setCurrent(props.defaultOption)
  }, [props.defaultOption]);

  const handleBlur = () => {
    timeOutId = setTimeout(() => { setOpen(false) });
  }

  const handleFocus = () => { clearTimeout(timeOutId) }

  return (
    <Styles.Wrapper maxWidth={props.maxWidth ? props.maxWidth : ''} ref={ref} onFocus={() => handleFocus()} onBlur={() => handleBlur()}>
      <Styles.SelectContainer className='test' id={props.id} tabIndex={0} isDefaultSelected={isDefaultSelected} onClick={() => setOpen(!isOpen)} >
        {props.icon &&
          React.cloneElement(props.icon, { fill: isDefaultSelected ? theme().gray500Color : theme().primary })
        }
        <Styles.CurrentValueContainer>{current.value === "" ? props.filterName : current.label}</Styles.CurrentValueContainer>
        <Styles.ArrowContainer>
          <Styles.CustomDropdownArrow open={isOpen} fill={isDefaultSelected ? theme().gray500Color : theme().primary} isDefaultSelected={isDefaultSelected} />
        </Styles.ArrowContainer>
      </Styles.SelectContainer>
      <Styles.OptionsContainer id={`${props.id}-Options`} open={isOpen} >
        {props.options && [{ value: "", label: "-----" }, ...props.options].map((child, idx) =>
          <Styles.OptionItem id={`${props.id}-Option${idx}`} isSelected={child.value === current.value} key={`${child.label}-${idx}`} onMouseDown={() => { setCurrent(child); props.onChange(child); setOpen(false); }}>
            {child.label}
          </Styles.OptionItem>
        )}
      </Styles.OptionsContainer>
    </Styles.Wrapper>
  )
}

export default FilterComponent