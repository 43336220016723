import axios from 'axios'
import endpoints from 'api/endpoints'


const GetTranslations = () => {
    return axios.get(endpoints.TRANSLATIONS.TRANSLATIONS_URL)
        .then(res => res.data)
        .catch(err => { throw err })
}


export default { GetTranslations }