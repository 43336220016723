import * as React from "react"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width={18}
            height={18}
            fill={props.fill ? props.fill : "#EBEDEF"}
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M9.75 2.25V3h-1.5v-.75h1.5zM9 7.582l4.035 1.328 1.793.585-.84 2.978a3.305 3.305 0 01-.855-.706L12 10.47l-1.133 1.29c-.255.3-.96.99-1.867.99-.908 0-1.612-.69-1.867-.99L6 10.47l-1.133 1.29c-.15.172-.45.473-.854.697l-.848-2.97 1.8-.592L9 7.582zM11.25.75h-4.5V3H4.5C3.675 3 3 3.675 3 4.5v3.465l-.967.315a.755.755 0 00-.495.96l1.424 5.01H3c1.2 0 2.265-.66 3-1.5.735.84 1.8 1.5 3 1.5s2.265-.66 3-1.5c.735.84 1.8 1.5 3 1.5h.037l1.418-5.01a.79.79 0 00-.045-.585.747.747 0 00-.45-.375L15 7.965V4.5c0-.825-.675-1.5-1.5-1.5h-2.25V.75zM4.5 7.478V4.5h9v2.978L9 6 4.5 7.478zM12 14.76a5.239 5.239 0 01-3 .96 5.239 5.239 0 01-3-.96c-.915.638-1.957.99-3 .99H1.5v1.5H3a6.621 6.621 0 003-.742c.945.48 1.973.727 3 .727s2.055-.24 3-.727a6.526 6.526 0 003 .742h1.5v-1.5H15c-1.043 0-2.085-.352-3-.99z"
                fill={props.fill ? props.fill : "#EBEDEF"}
            />
        </svg>
    )
}

export default SvgComponent
