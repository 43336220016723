/**
 * Searchselect component
 *
 * @author João Dias <jdias@ubiwhere.com>
 *
 *
 */

import React from 'react'
import { CFormLabel, CInputGroup } from '@coreui/react-pro';
import SelectSearch, { fuzzySearch } from "react-select-search";
import { HelpText } from 'shared/genericStyledComponents';
import { Wrapper } from './styles';

type PropTypes = {
  id?: string,
  wrapperStyle?: React.CSSProperties,
  required?: boolean,
  options: Array<{
    name: string,
    value: any,
    prefix?: string,
  }>,
  label?: string,
  value?: string,
  onChange: Function,
  placeholder?: string,
  valid?: boolean
  helpText?: string,
  disabled?: boolean,
  defaultOption?: {
    name: string,
    value: any,
    prefix?: string,
  },
}


const Searchselect = (props: PropTypes) => {
  return (
    <Wrapper style={props.wrapperStyle} isvalid={props.valid}>
      {props.label && <CFormLabel>{props.label}<span>{!!props.required ? "*" : ""}</span></CFormLabel>}
      <CInputGroup>
        <SelectSearch
          disabled={props.disabled ? props.disabled : false}
          className={`searchSelect ${props.disabled === true ? `disabled` : ``}`}
          search
          onChange={e => props.onChange(e)}
          options={props.options}
          placeholder={props.placeholder}
          value={props.value}
          filterOptions={fuzzySearch}
        />
      </CInputGroup>
      {props.helpText && <HelpText valid={props.valid}>{props.helpText}</HelpText>}
    </Wrapper>
  )
}

export default Searchselect