import React from 'react'
import { PAXDiv, Text} from '../styles'
import { TableDataCellPassengers } from './styles'
import { PassengerTypeListItem } from 'types/trips/passengerTypes';

type PropTypes = React.PropsWithChildren<{
  reservation: any;
  passengerType: PassengerTypeListItem;
}>

const PassengersCell = ({ reservation, passengerType }: PropTypes) => {
 
  const numOfPAX = reservation.passengerTypes.map((p) => {
    return (
      p.id === passengerType.id ? p.quantity : 0
    )
  })

  return (
    <TableDataCellPassengers 
      colSpan={1}
      style={{ width: '6%' }}
    >
      <PAXDiv>
        <Text weight='400'>{passengerType.shortName}</Text>
        <Text weight='700'>{numOfPAX.reduce((a, b) => a + b, 0)}</Text>
      </PAXDiv>
    </TableDataCellPassengers>
  )
}

export default PassengersCell