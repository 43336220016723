/**
 * Entitymemberform scene utils
 *
 * @author João Dias <jdias@ubiwhere.com>
 *
 *
 */

import { EntityMemberForm } from 'types/entity/entity'
import { ChangePasswordForm } from 'types/entity/entityMemberForm'
import * as Check from "validations"



export const defaultChangePasswordForm: ChangePasswordForm = {
    newPassword: {
        value: "",
        message: "",
        valid: true,
    },
    confirmPassword: {
        value: "",
        message: "",
        valid: true,
    }
}

export const defaultEntityMemberForm: EntityMemberForm = {
    name: {
        value: "",
        valid: true,
        message: "",
    },
    email: {
        value: "",
        valid: true,
        message: "",
    },
    isActive: {
        value: true,
        valid: true,
        message: "",
    },
    isAdmin: {
        value: false,
        valid: true,
        message: "",
    },
}

export const entityMemberValidations = {
    name: [Check.isRequired],
    email: [Check.isRequired],
    isAdmin: [Check.hasValue],
    isPartnerActive: [Check.hasValue],
    sendEmail: [Check.hasValue],
}

export const CredentialsFormValidations = {
    newPassword: [Check.isRequired],
    confirmPassword: [Check.isRequired],
}

