import React  from 'react'
import { Duration } from "luxon"
import { useDispatch } from 'react-redux';
import i18n from 'i18next';
// Styles
import theme from 'ui/theme';
import * as Styles from './styles'
import { Text  } from '../../styles';
// Store
import { actions } from 'store/rootSlices';
// Types
import type { Vehicles, InOutBoundStops } from 'types/trips/plannings';

type PropTypes = {
  vehicles: Vehicles[];
  planningId: number;
  isOutbound: boolean;
  idx: number;
  isLastStop: boolean;
  currentStopDetails: InOutBoundStops;
  stop: InOutBoundStops
}

const PadCircuitStopInfo = (props: PropTypes) => {
  const {
    planningId,
    vehicles,
    isOutbound,
    idx,
    isLastStop,
    currentStopDetails,
    stop
  } = props;

  const { setShowCheckInModal, setCheckinOutboundSelected } = actions.TripPlannings
  const dispatch = useDispatch()



  const _renderCurrentStopIndicator = () => {
    return !isLastStop && (
      <Styles.CurrentStopIndicatorWrap>
        {
          vehicles && vehicles.map((vhc) => {
            const vehicleStopDetails = currentStopDetails?.details?.find((detail) => detail.vehicle?.uuid === vhc.vehicle.uuid)
            if(idx === 0 && vehicleStopDetails?.boarding?.startedAt && !vehicleStopDetails?.boarding?.endedAt) {
              return <Styles.CurrentStopIndicator color={vhc.vehicle.color}></Styles.CurrentStopIndicator>
            } else if (idx !== 0 && vehicleStopDetails?.hasArrived && !vehicleStopDetails?.hasDeparted) {
              return <Styles.CurrentStopIndicator color={vhc.vehicle.color}></Styles.CurrentStopIndicator>
            } 
            return <></>
          })
        }
      </Styles.CurrentStopIndicatorWrap>
    )
  }

  return (
    <Styles.PadCircuitStopInfo>
      { _renderCurrentStopIndicator() }
      <Text style={{ color: `${theme().darkColor}`, textAlign: 'center' }}>{stop.stop.name}</Text>
      <Text style={{ color: `${theme().gray600Color}`, fontSize: '14px', lineHeight: '25px' }}>
        {Duration.fromISOTime(stop.time).toFormat('hh:mm')}
      </Text>

      <div style={{ textAlign: 'center', zIndex: 1 }}>
        <Styles.PassengersCircle 
          onClick={() => {
            dispatch(setCheckinOutboundSelected( isOutbound ? true : false))
            dispatch(setShowCheckInModal({ 
              show: true, 
              planningId, 
              stop: {id: stop.stop.id, name: stop.stop.name}, 
              openBy: 'stop',
              ...(vehicles.length > 0 ? { vehicle: vehicles[0] } : null),
            }))
          }} 
          fill={theme().gray600Color}>
            <Styles.Tooltip>
              <Styles.TooltipIdText>{stop.stop.id}</Styles.TooltipIdText>
              <Styles.CustomSpan width='350px'>
                <Styles.TooltipHoursText>
                  <b>{i18n.t('tripPlannings.leavingStop')}:</b><p>{stop.passengersCheckingOut}</p>
                </Styles.TooltipHoursText>
                <Styles.TooltipHoursText>
                  <b>{i18n.t('tripPlannings.continueTravel')}:</b><p>{stop.passengersPassingThrough}</p>
                </Styles.TooltipHoursText>
                <Styles.TooltipHoursText>
                  <b>{i18n.t('tripPlannings.startTravelInThisStop')}:</b><p>{stop.bookedSeats}</p>
                </Styles.TooltipHoursText>
              </Styles.CustomSpan>
            </Styles.Tooltip>
        </Styles.PassengersCircle>
      </div>
    </Styles.PadCircuitStopInfo>
  )
}

export default PadCircuitStopInfo