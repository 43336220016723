import { DateTime } from 'luxon';
// Types
import {
  TripActivityLeg,
  BookingLeg,
  BookingCustomerForm,
  BookingPassengerTypeForm,
  TripInfoForm,
  BookingPaymentEntry,
  BookingPayment,
  BookingPaymentForm,
  PaymentsSourceList,
  BookingCollaborator,
  BookingInvoicePaymentForm,
  BookingInvoiceRefundForm,
  CancelRescheduleSettings,
  BookingPartner,
  BookingPartnerMember,
  BookingExtrasForm,
} from 'types/trips/bookings';
import { SortType, ItemsList, LanguageListItem } from 'types/misc';
// Types & Configs
import { convertTimeStringToSeconds, defaultCustomerForm, defaultOutbound, defaultInbound, defaultBeach } from '../utils';
import config from 'config';

const { DATE_FORMAT } = config;
const now = DateTime.now();

export interface InitialStateProps {
  bookingID: number;
  bookingUID: string;
  bookingUUID: string;
  bookingState: string;
  tripDate: DateTime | null;
  edit: boolean;
  loading: boolean;
  hasEditPermission: boolean;
  searchCustomerText: string;
  searchCustomerResults: any[];
  showCustomerInfo: boolean;
  customerInfoWithPartner: boolean;
  bookingWithClient: boolean;
  page: number;
  sort: SortType;
  totalLegs: number;
  totalBeachTrips: number;
  activityFilter: string;
  departureFilter: string;
  arrivalFilter: string;
  startTimeFilter: string;
  endTimeFilter: string;
  minTimeFilter: number;
  dateFilter: Date;
  hasTimeFilterChanged: boolean;
  directionFilter: string;
  passengerTypesFilter: number[];
  availabilityFilter: number;
  legInboundView: boolean;
  unlockInbounds: boolean;
  refundPending: boolean;
  tripInfoForm: TripInfoForm;
  customer: BookingCustomerForm;
  currentCustomer: {
    uuid: string,
    name: string,
    nif?: string
  },
  partner: BookingPartner | null;
  partnerMember: BookingPartnerMember | null;
  bookedInbound: BookingLeg;
  bookedOutbound: BookingLeg;
  inbound: BookingLeg;
  outbound: BookingLeg;
  bookedBeachTrip: BookingLeg;
  selectedBeachTrip: BookingLeg
  tripLegs: TripActivityLeg[];
  beachTrips: TripActivityLeg[];
  selectedBeachExtras: BookingExtrasForm[];
  bookedBeachExtras: BookingExtrasForm[];
  selectedPassengerTypes: BookingPassengerTypeForm[];
  bookedPassengerTypes: BookingPassengerTypeForm[];
  entries: BookingPaymentEntry[];
  entriesTotal: number;
  entriesIsRefund: boolean;
  payments: BookingPayment[];
  paymentForm: BookingPaymentForm;
  forcePayment: boolean;
  confirmedOverbooking: boolean;
  showOverbookingModal: boolean;
  allowOverbooking: boolean;
  confirmedCancelation: boolean;
  showCancelationModal: boolean;
  cancelFullBooking: boolean;
  showSendTicketModal: boolean;
  showPaymentForm: boolean;
  paymentTypes: ItemsList;
  paymentSources: PaymentsSourceList;
  collaborators: BookingCollaborator[];
  enablePayment: boolean;
  invoicePaymentForm: BookingInvoicePaymentForm;
  invoiceRefundForm: BookingInvoiceRefundForm;
  isReschedule: boolean;
  rescheduleTax: number;
  tripsCancelRescheduleSettings: CancelRescheduleSettings;
  canCancelLegs: boolean;
  applyFeeToTrips: boolean;
  submitButtonState: boolean;
  disableInboundView: boolean;
  sendTicketForm: any;
  languages: LanguageListItem[];
  outboundCategoryFilter: string | undefined;
  enableInvoice: boolean;
  outboundToCancel: boolean;
  inboundToCancel: boolean;
  beachToCancel: boolean
  canRescheduleLegs: boolean;
  partners: any[];
  searchPartnerResults: any[];
  searchPartnerText: string;
  partnersPage: number;
  isPartnersLastPage: boolean;
  partnerForm: {
    partner: {
      value: string;
      valid: boolean;
      message: string;
    };
  };
  customers: any[];
  customersPage: number;
  isCustomersLastPage: boolean;
  totalPassengers: number,
  bookedTotalPassengers: number,
  initialEntries: BookingPaymentEntry[];
  isFree: boolean,
  createdBy: {
    name: string,
    userType: string,
    uuid: string
    date: string,
  },
  canceledBy: {
    name: string,
    userType: string,
    uuid: string,
    date: string,
  },
  lockPassengerInputs: boolean,
  lockExtraInputs: boolean,
  samePassengers: boolean,
  sameExtras: boolean,
  tableLoading: boolean
}

const initialState: InitialStateProps = {
  bookingID: 0,
  bookingUID: '',
  bookingUUID: '',
  bookingState: '',
  edit: false,
  outboundToCancel: false,
  inboundToCancel: false,
  beachToCancel: false,
  hasEditPermission: true,
  canRescheduleLegs: true,
  cancelFullBooking: false,
  tripDate: null,
  isFree: false,
  tripsCancelRescheduleSettings: {
    timeNoFee: {
      hours: 0,
      minutes: 0,
    },
    timeLimit: {
      hours: 0,
      minutes: 0,
    },
    fee: 0,
  },
  canCancelLegs: true,
  applyFeeToTrips: false,
  totalLegs: 0,
  totalBeachTrips: 0,
  page: 1,
  sort: {
    field: null,
    direction: null,
  },
  activityFilter: '',
  departureFilter: '',
  arrivalFilter: '',
  startTimeFilter: now.toFormat(DATE_FORMAT.TIME),
  endTimeFilter: '23:59:59',
  minTimeFilter: convertTimeStringToSeconds(now.toFormat(DATE_FORMAT.TIME)),
  dateFilter: now.toJSDate(),
  hasTimeFilterChanged: false,
  directionFilter: 'outbound',
  passengerTypesFilter: [],
  availabilityFilter: 0,
  loading: true,
  showCustomerInfo: false,
  customerInfoWithPartner: false,
  bookingWithClient: false,
  legInboundView: false,
  unlockInbounds: true,
  disableInboundView: false,
  refundPending: false,
  tripLegs: [],
  beachTrips: [],
  tripInfoForm: {
    observations: {
      value: '',
      valid: true,
      message: '',
    },
    customerName: {
      value: '',
      valid: true,
      message: '',
    },
    phone: {
      value: '',
      valid: true,
      message: '',
    },
    email: {
      value: '',
      valid: true,
      message: '',
    },
    lang: {
      value: '',
      valid: true,
      message: '',
    },
    nif: {
      value: '',
      valid: true,
      message: '',
    },
    country: {
      value: '',
      valid: true,
      message: '',
    },
  },
  partner: null,
  partnerMember: null,
  customer: defaultCustomerForm,
  sendTicketForm: {
    email: {
      value: '',
      valid: true,
      message: '',
    },
  },
  bookedOutbound: defaultOutbound,
  bookedInbound: defaultInbound,
  outbound: defaultOutbound,
  inbound: defaultInbound,
  bookedBeachTrip: defaultBeach,
  selectedBeachTrip: defaultBeach,
  selectedBeachExtras: [{
    id: -1,
    isDisabled: true,
    price: 0,
    maxCapacity: 0,
    occupySeat: true,
    quantity: {
      value: '0',
      valid: true,
      message: '',
    },
    totalPrice: '-',
  }],
  bookedBeachExtras: [{
    id: -1,
    isDisabled: true,
    price: 0,
    maxCapacity: 0,
    occupySeat: true,
    quantity: {
      value: '0',
      valid: true,
      message: '',
    },
    totalPrice: '-',
  }],
  createdBy: {
    name: "",
    userType: "",
    uuid: "",
    date: "",
  },
  canceledBy: {
    name: "",
    userType: "",
    uuid: "",
    date: "",
  },
  selectedPassengerTypes: [
    {
      id: -1,
      isDisabled: true,
      priceOutbound: 0,
      priceInbound: 0,
      price: 0,
      maxCapacity: 0,
      occupySeat: true,
      totalPriceOutbound: '-',
      totalPriceInbound: '-',
      quantity: {
        value: '0',
        valid: true,
        message: '',
      },
      totalPrice: '-',
    },
  ],
  bookedPassengerTypes: [
    {
      id: -1,
      isDisabled: true,
      priceOutbound: 0,
      priceInbound: 0,
      price: 0,
      maxCapacity: 0,
      totalPriceInbound: '-',
      totalPriceOutbound: '-',
      occupySeat: true,
      quantity: {
        value: '0',
        valid: true,
        message: '',
      },
      totalPrice: '-',
    },
  ],
  entries: [],
  entriesTotal: 0,
  entriesIsRefund: false,
  payments: [],
  paymentForm: {
    amount: {
      value: '0',
      valid: true,
      message: '',
    },
    processedBy: {
      value: '',
      valid: true,
      message: '',
    },
    source: {
      value: '',
      valid: true,
      message: '',
    },
    type: {
      value: '',
      valid: true,
      message: '',
    },
    correction: {
      value: '',
      valid: true,
      message: '',
    },
    isRefund: false,
  },
  forcePayment: false,
  confirmedOverbooking: false,
  showOverbookingModal: false,
  showSendTicketModal: false,
  showPaymentForm: false,
  allowOverbooking: false,
  confirmedCancelation: false,
  showCancelationModal: false,
  paymentTypes: [],
  paymentSources: [],
  collaborators: [],
  enablePayment: false,
  invoicePaymentForm: {
    nif: {
      value: '',
      valid: true,
      message: '',
    },
    email: {
      value: '',
      valid: true,
      message: '',
    },
    phone: {
      value: '',
      valid: true,
      message: '',
    },
    notes: {
      value: '',
      valid: true,
      message: '',
    },
    country: {
      value: '',
      valid: true,
      message: '',
    },
  },
  invoiceRefundForm: {
    invoice: {
      value: '',
      valid: true,
      message: '',
    },
    notes: {
      value: '',
      valid: true,
      message: '',
    },
  },
  isReschedule: false,
  rescheduleTax: 0,
  submitButtonState: false,
  languages: [],
  outboundCategoryFilter: undefined,
  enableInvoice: false,
  searchPartnerResults: [],
  partners: [],
  searchPartnerText: '',
  partnersPage: 1,
  isPartnersLastPage: false,
  partnerForm: {
    partner: {
      value: '',
      valid: true,
      message: '',
    },
  },
  searchCustomerText: '',
  searchCustomerResults: [],
  customers: [],
  customersPage: 1,
  isCustomersLastPage: false,
  currentCustomer: {
    uuid: "",
    name: "",
    nif: ""
  },
  totalPassengers: 0,
  bookedTotalPassengers: 0,
  initialEntries: [],
  lockPassengerInputs: false,
  lockExtraInputs: false,
  samePassengers: true,
  sameExtras: true,
  tableLoading: false
};

export default initialState