import axios from 'axios'
import endpoints from 'api/endpoints'
import { decamelizeKeys } from 'humps'
import { generateUrl } from 'utils'
/* import { generateUrl } from 'utils'
import { toast } from 'react-toastify'
import i18n from 'i18next' */

const GetTaxes = () => {
    return axios.get(endpoints.TRIPS.SETTINGS.TAXES)
        .then(res => res.data)
        .catch(err => {
        })
}

const GetLanguages = () => {
    return axios.get(endpoints.TRIPS.SETTINGS.COUNTRIES)
        .then(res => res.data)
        .catch(err => {
        })
}


//GENERAL CONFIGURATIONS
const GetCancelReschedules = (page: number) => {

    const endpoint = `
        ${endpoints.TRIPS.SETTINGS.CANCEL_RESCHEDULE}?page=${page}
    `;

    return axios.get(endpoint)
        .then(res => res.data)
        .catch(err => {
            throw err
        })
}

const EditCancelReschedules = (id: number, params) => {

    const decMLZParams = decamelizeKeys(params)
    const endpoint = generateUrl(endpoints.TRIPS.SETTINGS.EDIT_CANCEL_RESCHEDULE, { id })

    return axios.patch(endpoint, decMLZParams)
        .then(res => {
            if (res) return res.data
            return -1

        })
        .catch(err => {
            throw err
        })
}

const GetPaymentTypes = () => {

    return axios.get(endpoints.TRIPS.SETTINGS.PAYEMENT_TYPES)
        .then(res => {
            if (res) return res.data
            return -1

        })
        .catch(err => {
            throw err
        })
}

const GetPaymentEntries = () => {

    return axios.get(endpoints.TRIPS.SETTINGS.PAYEMENT_ENTRIES)
        .then(res => {
            if (res) return res.data
            return -1
        })
        .catch(err => {
            throw err
        })
}


export default {
    GetTaxes,
    GetCancelReschedules,
    EditCancelReschedules,
    GetPaymentTypes,
    GetLanguages,
    GetPaymentEntries
}