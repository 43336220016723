/**
 * Table Container component
 *
 * @author João Dias <jdias@ubiwhere.com>
 *
 *
 */

import React, { Fragment } from 'react'
import { CTable, CTableHeaderCell, CSmartPagination, CTableRow } from '@coreui/react-pro';
import i18n from "i18next";
import * as Styles from './styles'

type PropTypes = React.PropsWithChildren<{
  id?: string,
  className?: string,
  headerElements?: Array<string | JSX.Element>,
  count: number,
  currentPage: number,
  elementsPerPage: number,
  onClickHeaderColumn?: any   //must be of type Function
  onPageChange: Function,
  footer?: JSX.Element,
}>

const TableContainer = (props: PropTypes) => {
  const numberOfPages = props.currentPage > -1 ? Math.ceil(props.count / props.elementsPerPage) : -1;

  return (
    <Fragment>
      <Styles.Wrapper id={`${props.id}-wrapper`} className={props.className}>
        <CTable id={props.id} borderless>
          <Styles.CustomCTableHead id={`${props.id}-header`} >
            <CTableRow>
              {props.headerElements && props.headerElements.map((el, idx) =>
                <CTableHeaderCell onClick={props.onClickHeaderColumn ? (e) => { props.onClickHeaderColumn(e) } : (e) => { }} colSpan={el instanceof Object && el.props?.hasOwnProperty('colSpan') ? el.props.colSpan : 1} key={`${el}-${idx}`}>{el}</CTableHeaderCell>
              )}
            </CTableRow>
          </Styles.CustomCTableHead>
          <Styles.CustomCTableBody id={`${props.id}-body`}>
            {
              props.children ?
                Array.isArray(props.children) ?
                  props.children.length > 0 ?
                    props.children
                    :
                    <Styles.CustomCTableRow>
                      <Styles.NoResultsCell colSpan={props.headerElements?.length || 1}> {i18n.t('common.noResults')}</Styles.NoResultsCell>
                    </Styles.CustomCTableRow>
                  :
                  props.children : <Styles.CustomCTableRow><Styles.NoResultsCell colSpan={props.headerElements?.length || 1}> {i18n.t('common.noResults')}</Styles.NoResultsCell></Styles.CustomCTableRow>
            }
          </Styles.CustomCTableBody>
          {props.footer}
        </CTable>
      </Styles.Wrapper>
      {props.currentPage > -1 &&
          <Styles.PagingContainer id={`${props.id}-pagination`}>
            <CSmartPagination activePage={props.currentPage} pages={numberOfPages} onActivePageChange={e => { if (e !== 0) props.onPageChange(e) }} />
          </Styles.PagingContainer>
        }
    </Fragment>
  )
}

export default TableContainer