import styled from 'styled-components'

const ButtonWrapper = styled.div`
  width: 100%;
  display:flex;
  justify-content:flex-end;
  padding-top:22px;
`

const DownloadButton = styled.div`
  cursor: pointer;
` 

export { 
  ButtonWrapper, 
  DownloadButton 
}