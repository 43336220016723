import { CTableDataCell, CTableRow } from '@coreui/react-pro'
import styled from 'styled-components'
import { Text } from 'ui/globalStyles'
import theme from 'ui/theme'
import { ActionsCell } from 'shared/genericStyledComponents'

const CustomCTableRow = styled(CTableRow)`
    max-height: 54px;
    background-color: ${theme().white};
    border-bottom: 1px solid ${theme().gray200Color};

    > td {
        vertical-align: middle;
        font-family: Inter;
        font-size: 14px;
        color: ${theme().gray700Color};
    }

    td:first-child {
      padding-left:15px;
      width:20%;
    }

    td:nth-child(2) {
      width:30%;
    }

    td:nth-child(3) {
      width:10%;
    }

    td:nth-child(5) {
      padding-left: 7px;
    }
`

const CustomText = styled(Text) <{ weight: string }>`
  margin:0;
  font-weight: ${props => props.weight};
`
const BookingNumber = styled.div`
  display:flex;
  justify-content: flex-start;
  column-gap: 8px;
`

const StopStatusText = styled(Text)`
  margin:0;
`
const CustomActionsCell = styled(ActionsCell)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
  align-items: center;
  height: 49px;
`

const TableDataCellPassengers = styled(CTableDataCell)`
  padding:0px !important;
  max-height: 54px;
  width:207px;
`
const TableDataCellStops = styled(CTableDataCell)`
  padding:0px !important;
  max-height: 54px;
`

const PAXDivWrapper = styled.div`
  display:flex;
  flex-direction:row;
  height: 54px;
  color: ${theme().gray700Color} !important;
`

const StopDivWrapper = styled.div`
  display:flex;
  flex-direction:row;
  height: 54px;
  color: ${theme().gray700Color} !important;
`

const PAXDiv = styled.div<{ backgroundcolor?: string }>`
  display:flex;
  justify-content:center;
  padding: 12px;
  flex-basis:36px;
  flex-direction:column;
  color: ${theme().gray700Color} !important;
  background-clip: border-box;
  background-color: ${props => props.backgroundcolor ? props.backgroundcolor : theme().gray100Color} !important;
`

const StopDivChecked = styled(CTableDataCell) <{ start: boolean, exit: boolean, current: boolean, activ: boolean }>`
  height: 54px;
  display:flex;
  flex-basis:54px;
  padding: 12px;
  align-items:center;
  font-weight: 700;
  color: ${props => 
    props.activ ? 
      props.start ? 
        `${theme().success} !important` 
        :
        props.exit ?   
          `${theme().danger} !important` 
          : 
          `${theme().gray700Color} !important`
      :
      `${theme().gray200Color} !important`
  }; 
  background-color: ${props =>
    props.activ ?
      props.start ? 
        `${theme().success10} !important` 
        :
        props.exit ? 
        `${theme().danger10} !important`
        :
        `${theme().white} !important`
      :
      `${theme().white} !important`
  }; 
  font-weight: ${props => props.start || props.exit ? 700 : 400};
  text-align:center;
  cursor: ${props => props.activ ? 'pointer' : 'default'};
`

export {
  CustomCTableRow,
  CustomText,
  BookingNumber,
  StopStatusText,
  CustomActionsCell,
  TableDataCellPassengers,
  TableDataCellStops,
  PAXDivWrapper,
  StopDivWrapper,
  PAXDiv,
  StopDivChecked,
}