import styled from 'styled-components'
import { CTableDataCell, CTableRow } from '@coreui/react-pro'
import theme from 'ui/theme'


const CustomCTableRow = styled(CTableRow)`
  max-height: 54px;
  background-color: ${theme().white};
  border-bottom: 1px solid ${theme().gray200Color};

  > td {
    text-transform: capitalize;
    vertical-align: middle;
    font-family: Inter;
    font-size: 14px;
    color: ${theme().gray700Color}
  }
`

const ActionsCell = styled(CTableDataCell)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 49px;

  > svg {
    cursor: pointer;
    margin-right: 15px;
  }
`
const ChevronCell = styled.div`
  display:flex;
  justify-content: flex-end;
  cursor: pointer;
  width: 45px;
`

export {
  CustomCTableRow,
  ChevronCell,
  ActionsCell,
}