
import React from 'react'
import styled from 'styled-components'
import SectionContainer from '../../shared/components/Sectioncontainer/index'
import CustomTextInput from '../../shared/components/CustomTextInput/index'
import Datepicker from '../../shared/components/FormComponents/datepicker'
import FormComponent from '../../shared/components/FormComponents/index'
import { CCol, CRow } from '@coreui/react-pro'
import CustomCheckbox from 'shared/components/CustomCheckbox'
import { actions, RootState } from 'store/rootSlices'
import { useSelector, useDispatch } from 'react-redux'
import { country_codes } from 'shared/components/FormComponents/utils'
import Dropdown from 'shared/components/FormComponents/dropdown'
import i18n from "i18next";
import Avataruploader from 'shared/components/Avataruploader'
import CustomButton from 'shared/components/CustomButton'
import CustomMultiSelect from 'shared/components/Custommultiselect'
import _ from "lodash"
import User from 'assets/icons/user'
import { AvatarCol } from 'shared/genericStyledComponents'

const DadosPessoais = (props) => {

    const dispatch = useDispatch();
    const dbDispatch = _.debounce(dispatch, 300);

    const { onFormChange, showChangePasswordModal, onRemoveUserAvatar, setIsAvatarChanged, setIsAvatarRemoved } = actions.userForm;
    const { userForm, user, edit, userAuthExists, hasEditPermission } = useSelector((state: RootState) => state.UserForm);
    const { collaboratorTypes, teams } = useSelector((state: RootState) => state.CollaboratorsManagement);
    const { countryCodes } = useSelector((state: RootState) => state.App)

    return (
        <SectionContainer required title={i18n.t('userForm.title1')} style={{ width: '100%', paddingBottom: '24px' }}>
            <FirstRow>
                <AvatarCol sm={1}>
                    <Avataruploader
                        id='userForm-avatar'
                        hasEditPermission={hasEditPermission}
                        qrCode={edit ? user.qrcode : null}
                        defaultAvatar={<User height={80} width={80} viewBox="0 0 17 19" />}
                        value={
                            user.avatar ? user.avatar : userForm.avatar.value ? URL.createObjectURL(userForm.avatar.value) : ""
                        }
                        valid={userForm.avatar.valid}
                        helpText={userForm.avatar.message}
                        label={i18n.t('common.photo')}
                        onChange={(e) => {
                            if (e === "") {
                                dispatch(onRemoveUserAvatar({ ...user, avatar: "" }));
                                dispatch(onFormChange({ ...userForm, avatar: { value: "", message: "", valid: true } }));
                                dispatch(setIsAvatarRemoved(true))
                                dispatch(setIsAvatarChanged(false))
                            } else {
                                dispatch(onFormChange({ ...userForm, avatar: { value: e, message: "", valid: true } }));
                                dispatch(setIsAvatarRemoved(false))
                                dispatch(setIsAvatarChanged(true))
                            }
                        }}
                    />
                </AvatarCol>
                <CCol>
                    <CRow>
                        <CustomTextInput
                            id='userForm-name'
                            disabled={!hasEditPermission}
                            valid={userForm.name.valid}
                            initialValue={userForm.name.value}
                            helpText={userForm.name.message}
                            placeholder={i18n.t('placeholders.name')}
                            label={i18n.t('userForm.labelName')}
                            onChange={(e) => dbDispatch(onFormChange({ ...userForm, name: { value: e.target.value, message: "", valid: true } }))} />
                    </CRow>
                    <Row className="g-3">
                        <CCol>
                            <Dropdown
                                id='userForm-profile'
                                disabled={!hasEditPermission}
                                valid={userForm.role.valid}
                                helpText={userForm.role.message}
                                value={userForm.role.value.toString()}
                                label={i18n.t('userForm.profile')}
                                placeholder={i18n.t('placeholders.profile')}
                                options={
                                    collaboratorTypes.map((colT) => {
                                        return {
                                            label: colT.name,
                                            value: colT.id
                                        }
                                    })
                                }
                                onChange={(e) => {

                                    const value = e.target.value
                                    const label = e.target.options[e.target.selectedIndex].text

                                    dispatch(
                                        onFormChange(
                                            {
                                                ...userForm,
                                                role: { value: value, message: "", valid: true },
                                                roleName: { value: label, message: "", valid: true }
                                            }
                                        )
                                    )
                                }}
                            />

                        </CCol>
                        <CCol>
                            <CustomMultiSelect
                                id='userForm-team'
                                disabled={!hasEditPermission}
                                options={teams}
                                label={i18n.t('userForm.team')}
                                placeholder={i18n.t('placeholders.team')}
                                disabledSearch={true}
                                valid={userForm.groups.valid}
                                helpText={userForm.groups.message}
                                value={userForm.groups.value}
                                onChange={(e) => {
                                    const values = e.map(grp => ({ label: grp.label, value: grp.value }))

                                    dispatch(
                                        onFormChange(
                                            {
                                                ...userForm,
                                                groups: { value: values, message: "", valid: true },
                                            }
                                        )
                                    )
                                }}
                            />
                        </CCol>
                    </Row>
                </CCol>
                <CCol sm={4}>
                    <CRow>
                        <ButtonContainer>
                            <CustomButton
                                id='userForm-accessDataButton'
                                disabled={!edit || !hasEditPermission}
                                iconFirst
                                icon='lock'
                                buttonStyle={{ borderColor: '#321fdb' }}
                                text={userAuthExists ? i18n.t('userForm.changeAccessDataModalTitle') : i18n.t('userForm.defineAccessDataModalTitle')}
                                color="primary"
                                variant="outline"
                                onClick={(e) => { dispatch(showChangePasswordModal(true)) }} />
                        </ButtonContainer>
                    </CRow>
                    <Row>
                        <Datepicker
                            id='userForm-birthdate'
                            disabled={!hasEditPermission}
                            valid={userForm.birth.valid}
                            value={userForm.birth.value}
                            helpText={userForm.birth.message}
                            label={i18n.t('userForm.labelBirthDate')}
                            onChange={(date, e) => {
                                dispatch(onFormChange({ ...userForm, birth: { value: date, message: "", valid: true } }))
                            }}
                        />
                    </Row>
                </CCol>
            </FirstRow>
            <CRow className="g-3">
                <CCol>


                </CCol>
            </CRow>
            <Row className="g-3">
                <CCol>
                    <CustomTextInput
                        id='userForm-address'
                        disabled={!hasEditPermission}
                        valid={userForm.address.valid}
                        initialValue={userForm.address.value || ""}
                        helpText={userForm.address.message}
                        placeholder={i18n.t('placeholders.address')}
                        label={i18n.t('userForm.labelAddress')}
                        onChange={(e) => dbDispatch(onFormChange({ ...userForm, address: { value: e.target.value, message: "", valid: true } }))}
                    />
                </CCol>
            </Row>
            <Row className="g-3">
                <CCol>
                    <Dropdown
                        id='userForm-country'
                        disabled={!hasEditPermission}
                        valid={userForm.country.valid}
                        helpText={userForm.country.message}
                        value={userForm.country.value}
                        label={i18n.t('userForm.labelNationality')}
                        placeholder={i18n.t('placeholders.country')}
                        options={Object.keys(countryCodes).map(key => {
                            return {
                                label: countryCodes[key],
                                value: key
                            }
                        })}
                        onChange={(e) => { dispatch(onFormChange({ ...userForm, country: { value: e.target.value, message: "", valid: true } })) }}
                    />
                </CCol>
                <CCol>
                    <CustomTextInput
                        id='userForm-postalCode'
                        format='####-###'
                        disabled={!hasEditPermission}
                        valid={userForm.postalCode.valid}
                        initialValue={userForm.postalCode.value || ""}
                        helpText={userForm.postalCode.message}
                        placeholder={i18n.t('placeholders.postalCode')}
                        label={i18n.t('userForm.labelPostalCode')}
                        onChange={(e) =>
                            dbDispatch(onFormChange({ ...userForm, postalCode: { value: e.target.value, message: "", valid: true } }))
                        }
                    />
                </CCol>
                <CCol>
                    <CustomTextInput
                        id='userForm-city'
                        disabled={!hasEditPermission}
                        valid={userForm.city.valid}
                        initialValue={userForm.city.value || ""}
                        helpText={userForm.city.message}
                        label={i18n.t('userForm.labelCity')}
                        placeholder={i18n.t('placeholders.city')}
                        onChange={(e) => dbDispatch(onFormChange({ ...userForm, city: { value: e.target.value, message: "", valid: true } }))}
                    />
                </CCol>
            </Row>
            <Row className="g-3">
                <CCol>
                    <FormComponent
                        id='userForm-phone'
                        disabled={!hasEditPermission}
                        valid={userForm.phone.valid}
                        value={userForm.phone.value}
                        helpText={userForm.phone.message}
                        dropdown={false}
                        label={i18n.t('userForm.labelPhone')}
                        placeholder="Portugal"
                        options={country_codes}
                        onChange={(e) => dbDispatch(onFormChange({ ...userForm, phone: { value: e, message: "", valid: true } }))}
                    />
                </CCol>
                <CCol>
                    <CustomTextInput
                        id='userForm-email'
                        disabled={!hasEditPermission}
                        valid={userForm.email.valid}
                        initialValue={userForm.email.value || ""}
                        helpText={userForm.email.message}
                        label={i18n.t('userForm.labelEmail')}
                        placeholder={i18n.t('placeholders.email')}
                        onChange={(e) => dbDispatch(onFormChange({ ...userForm, email: { value: e.target.value, message: "", valid: true } }))}
                    />
                </CCol>
                <CCol>
                    <CustomTextInput
                        id='userForm-qualifications'
                        disabled={!hasEditPermission}
                        valid={userForm.qualifications.valid}
                        initialValue={userForm.qualifications.value || ""}
                        helpText={userForm.qualifications.message}
                        label={i18n.t('userForm.labelLiteraryAbilities')}
                        placeholder={i18n.t('placeholders.literary')}
                        onChange={(e) => dbDispatch(onFormChange({ ...userForm, qualifications: { value: e.target.value, message: "", valid: true } }))}
                    />
                </CCol>
            </Row>
            <Row className="g-3">
                <CCol>
                    <CustomCheckbox
                        id="userForm-showInWebsite"
                        disabled={!hasEditPermission}
                        checked={userForm.showInWebsite.value}
                        onClick={(e) => dbDispatch(onFormChange({ ...userForm, showInWebsite: { value: e.target.checked, message: "", valid: true } }))}
                        text={i18n.t('userForm.labelShowInWebsite')}
                    />

                </CCol>
                <CustomCol>
                    <CustomCheckbox
                        id="userForm-hasBackofficeAccess"
                        disabled={!hasEditPermission}
                        checked={userForm.hasBackofficeAccess.value}
                        onClick={(e) => dbDispatch(onFormChange({ ...userForm, hasBackofficeAccess: { value: e.target.checked, message: "", valid: true } }))}
                        text={i18n.t('userForm.hasBackofficeAccess')}
                    />
                </CustomCol>
            </Row>
        </SectionContainer>
    )
}

export default DadosPessoais

const ButtonContainer = styled.div`
    margin-top: 27px;
    display: flex;
    justify-content: center;

    button {
        width: 100%;
    }
`

const Row = styled(CRow)`
    --cui-gutter-y:0rem;
    margin-top:26px;
`

const FirstRow = styled(CRow)`
  column-gap: 15px;
`

const CustomCol = styled(CCol)`
    display: flex;
    justify-content: flex-end;
`

/* const Button = styled(CButton) <{ valid: boolean }>`
    width:11%;
    line-height:1.35;
    align-self:${props => props.valid === false ? 'center' : 'flex-end'}
` */
