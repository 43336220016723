import * as React from "react"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_1119_97059)">
                <circle cx="12" cy="11" r="10" fill="#8A93A2" />
                <path d="M16 6H8C7.5 6 7 6.45 7 7V8.505C7 8.865 7.215 9.175 7.5 9.35V15C7.5 15.55 8.05 16 8.5 16H15.5C15.95 16 16.5 15.55 16.5 15V9.35C16.785 9.175 17 8.865 17 8.505V7C17 6.45 16.5 6 16 6ZM15.5 15H8.5V9.5H15.5V15ZM16 8.5H8V7H16V8.5Z" fill="white" />
                <path d="M13.5 11H10.5V12H13.5V11Z" fill="white" />
            </g>
            <defs>
                <filter id="filter0_d_1119_97059" x="0" y="0" width="24" height="24" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="1" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.388235 0 0 0 0 0.435294 0 0 0 0 0.513726 0 0 0 0.25 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1119_97059" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1119_97059" result="shape" />
                </filter>
            </defs>
        </svg>
    )
}

export default SvgComponent

