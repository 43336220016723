import { DateTime, Duration } from "luxon";
import i18n from "i18next";

export type QuickOptions = String
/*     `${typeof today}`
    | `${typeof yesterday}`
    | `${typeof thisWeek}`
    | `${typeof lastWeek}`
    | `${typeof lastTwoWeeks}`
    | `${typeof thisMonth}`
    | `${typeof lastMonth}`
    | `${typeof thisYear}`
    | `${typeof lastYear}` */

export const returnOptionDate = (option: QuickOptions): Array<Date> | Array<null> => {
    switch (option) {
        case i18n.t('common.yesterday'):
            const yesterday = DateTime.now().minus(Duration.fromObject({ days: 1 })).toJSDate();
            return [yesterday, yesterday];
        case i18n.t('common.tomorrow'):
            const tomorrow = DateTime.now().plus({ days: 1 }).toJSDate();
            return [tomorrow, tomorrow];
        case i18n.t('common.thisWeek'):
            const firstDayOfWeek = DateTime.now().startOf("week").toJSDate();
            const lastDayOfWeek = DateTime.now().endOf("week").toJSDate();
            return [firstDayOfWeek, lastDayOfWeek];
        case i18n.t('common.nextWeek'):
            const nextWeek = DateTime.now().plus(Duration.fromObject({ weeks: 1 }));
            const firstDayOfNextWeek = nextWeek.startOf("week").toJSDate();
            const lastDayOfNextWeek = nextWeek.endOf("week").toJSDate();
            return [firstDayOfNextWeek, lastDayOfNextWeek];
        case i18n.t('common.lastWeek'):
            const lastWeek = DateTime.now().minus(Duration.fromObject({ weeks: 1 }));
            const firstDayOfLastWeek = lastWeek.startOf("week").toJSDate();
            const lastDayOfLastWeek = lastWeek.endOf("week").toJSDate();
            return [firstDayOfLastWeek, lastDayOfLastWeek];
        case i18n.t('common.lastTwoWeeks'):
            const firstDayTwoWeeksAgo = DateTime.now().minus(Duration.fromObject({ weeks: 2 })).startOf("week").toJSDate();
            const lastDayOneWeekAgo = DateTime.now().minus(Duration.fromObject({ weeks: 1 })).endOf("week").toJSDate();
            return [firstDayTwoWeeksAgo, lastDayOneWeekAgo];
        case i18n.t('common.thisMonth'):
            const firstDayOfMonth = DateTime.now().startOf("month").toJSDate();
            const lastDayOfMonth = DateTime.now().endOf("month").toJSDate();
            return [firstDayOfMonth, lastDayOfMonth];
        case i18n.t('common.lastMonth'):
            const lastMonth = DateTime.now().minus(Duration.fromObject({ months: 1 }));
            const firstDayOfLastMonth = lastMonth.startOf("month").toJSDate();
            const lastDayOfLastMonth = lastMonth.endOf("month").toJSDate();
            return [firstDayOfLastMonth, lastDayOfLastMonth];
        case i18n.t('common.thisYear'):
            const firstDayOfYear = DateTime.now().startOf("year").toJSDate();
            const lastDayOfYear = DateTime.now().endOf("year").toJSDate();
            return [firstDayOfYear, lastDayOfYear];
        case i18n.t('common.lastYear'):
            const firstDayOfLastYear = DateTime.now().startOf("year").minus(Duration.fromObject({ years: 1 })).toJSDate();
            const lastDayOfLastYear = DateTime.now().endOf("year").minus(Duration.fromObject({ years: 1 })).toJSDate();
            return [firstDayOfLastYear, lastDayOfLastYear];
        case i18n.t('common.noDate'):
            return [null, null];
        default:
            const today = DateTime.now().toJSDate();
            return [today, today];
    }
}

export const presetByDate = (startDate, endDate): QuickOptions | null => {

    const start =  startDate?.getTime();
    const end = endDate ? endDate.getTime() : null;

    //NENHUMA DATA
    if(startDate === null && endDate === null) return i18n.t('common.noDate');

    //ONTEM
    const yesterday = DateTime.now().minus(Duration.fromObject({ days: 1 })).startOf("day").toJSDate().getTime();
    if (start === yesterday && end === yesterday) return i18n.t('common.yesterday');

    //AMANHÃ
    const tomorrow = DateTime.now().plus({ days: 1 }).startOf("day").toJSDate().getTime();
    if (start === tomorrow && end === tomorrow) return i18n.t('common.tomorrow');

    //ESTA SEMANA
    const firstDayOfWeek = DateTime.now().startOf("week").startOf("day").toJSDate().getTime();
    const lastDayOfWeek = DateTime.now().endOf("week").startOf("day").toJSDate().getTime();
    if (start === firstDayOfWeek && end === lastDayOfWeek) return i18n.t('common.thisWeek');

    //PRÓXIMA SEMANA
    const nextWeek = DateTime.now().plus(Duration.fromObject({ weeks: 1 }));
    const firstDayOfNextWeek = nextWeek.startOf("week").startOf("day").toJSDate().getTime();
    const lastDayOfNextWeek = nextWeek.endOf("week").startOf("day").toJSDate().getTime();
    if (start === firstDayOfNextWeek && end === lastDayOfNextWeek) return i18n.t('common.nextWeek');

    //SEMANA PASSADA
    const lastWeek = DateTime.now().minus(Duration.fromObject({ weeks: 1 }));
    const firstDayOfLastWeek = lastWeek.startOf("week").startOf("day").toJSDate().getTime();
    const lastDayOfLastWeek = lastWeek.endOf("week").startOf("day").toJSDate().getTime();
    if (start === firstDayOfLastWeek && end === lastDayOfLastWeek) return i18n.t('common.lastWeek');

    //ULTIMAS DUAS SEMANAS
    const firstDayTwoWeeksAgo = DateTime.now().minus(Duration.fromObject({ weeks: 2 })).startOf("week").startOf("day").toJSDate().getTime();
    const lastDayOneWeekAgo = DateTime.now().minus(Duration.fromObject({ weeks: 1 })).endOf("week").startOf("day").toJSDate().getTime();
    if (start === firstDayTwoWeeksAgo && end === lastDayOneWeekAgo) return i18n.t('common.lastTwoWeeks');

    //ESTE MES
    const firstDayOfMonth = DateTime.now().startOf("month").startOf("day").toJSDate().getTime();
    const lastDayOfMonth = DateTime.now().endOf("month").startOf("day").toJSDate().getTime();
    if (start === firstDayOfMonth && end === lastDayOfMonth) return i18n.t('common.thisMonth');

    //MES PASSADO
    const lastMonth = DateTime.now().minus(Duration.fromObject({ months: 1 }));
    const firstDayOfLastMonth = lastMonth.startOf("month").startOf("day").toJSDate().getTime();
    const lastDayOfLastMonth = lastMonth.endOf("month").startOf("day").toJSDate().getTime();
    if (start === firstDayOfLastMonth && end === lastDayOfLastMonth) return i18n.t('common.lastMonth');

    //ESTE ANO
    const firstDayOfYear = DateTime.now().startOf("year").startOf("day").toJSDate().getTime();
    const lastDayOfYear = DateTime.now().endOf("year").startOf("day").toJSDate().getTime();
    if (start === firstDayOfYear && end === lastDayOfYear) return i18n.t('common.thisYear');

    //ANO PASSADO
    const firstDayOfLastYear = DateTime.now().startOf("year").minus(Duration.fromObject({ years: 1 })).startOf("day").toJSDate().getTime();
    const lastDayOfLastYear = DateTime.now().endOf("year").minus(Duration.fromObject({ years: 1 })).startOf("day").toJSDate().getTime();
    if (start === firstDayOfLastYear && end === lastDayOfLastYear) return i18n.t('common.lastYear');

    return null;
}

export const checkIsDefaultSelected = (startDate, endDate, defaultValue): boolean => {
    const startDateConverted = DateTime.fromJSDate(startDate);
    const endDateConverted = DateTime.fromJSDate(endDate);
    const defaultValueStartDayConverted = DateTime.fromJSDate(defaultValue.startDay);
    const defaultValueEndDayConverted = endDate ?
        DateTime.fromJSDate(defaultValue.endDay)
        : defaultValueStartDayConverted;

    return startDateConverted.day === defaultValueStartDayConverted.day && endDate ?
        defaultValueEndDayConverted.day ?
            endDateConverted.day === defaultValueEndDayConverted.day
            :
            endDateConverted.day === defaultValueStartDayConverted.day
        : startDateConverted.day === defaultValueStartDayConverted.day;
}

export const generateLabel = (start, end) => {

    const startFormat = DateTime.fromJSDate(start).toFormat("dd/LL/yyyy");
    const endFormat = DateTime.fromJSDate(end).toFormat("dd/LL/yyyy");

    const preset = presetByDate(start, end);
    if (!!preset) return preset;

    if (startFormat === endFormat) return startFormat;
    if (startFormat && !end) return startFormat;
    if (end && !start) return endFormat;
    return `${startFormat} - ${endFormat}`;
}

export const presetsLists = () => ({
    plannings: [
        i18n.t('common.today'),
        i18n.t('common.tomorrow'),
        i18n.t('common.thisWeek'),
        i18n.t('common.nextWeek'),
        i18n.t('common.yesterday'),
        i18n.t('common.lastWeek')
    ],
    bookings: [
        i18n.t('common.noDate'),
        i18n.t('common.today'),
        i18n.t('common.tomorrow'),
        i18n.t('common.thisWeek'),
        i18n.t('common.nextWeek'),
        i18n.t('common.yesterday'),
        i18n.t('common.lastWeek')
    ],
    invoicingRequests: [
        i18n.t('common.noDate'),
        i18n.t('common.today'),
        i18n.t('common.yesterday'),
        i18n.t('common.lastWeek'),
        i18n.t('common.thisWeek'),
        i18n.t('common.thisMonth'),
        i18n.t('common.thisYear'),
    ],
    invoicingSummary: [
        i18n.t('common.noDate'),
        i18n.t('common.today'),
        i18n.t('common.yesterday'),
        i18n.t('common.lastWeek'),
        i18n.t('common.thisWeek'),
        i18n.t('common.thisMonth'),
        i18n.t('common.thisYear'),
    ],
    timeRecord: [
        i18n.t('common.today'),
        i18n.t('common.yesterday'),
        i18n.t('common.lastWeek'),
        i18n.t('common.thisWeek'),
        i18n.t('common.thisMonth'),
        i18n.t('common.lastMonth'),
    ],
})
