/**
 * Addreinforcementtablerowform component
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */

import React from 'react'
import { CTableDataCell } from '@coreui/react-pro'
import CustomCheckbox from 'shared/components/CustomCheckbox'
import Timepicker from 'shared/components/FormComponents/timepicker'
import { useSelector } from 'react-redux'
import { RootState } from 'store/rootSlices'
import { CustomCTableRow, CheckBoxDataCell } from '../../styles'

type PropTypes = {
  selectedVehicle: string;
  trip: any;
  stop: any;
  isOutbound: boolean;
  onChange: (v: any) => void;
}

const Addreinforcementtablerowform = ({ trip, stop, isOutbound, onChange, selectedVehicle }: PropTypes) => {

  const {
    isUpdatingCircuit
  } = useSelector((state: RootState) => state.TripPlannings)

  return (
    <CustomCTableRow>
      <>
        <CTableDataCell>{stop.stop.name}</CTableDataCell>
        <CTableDataCell>
          <Timepicker
            timeFormat='HH:mm'
            disabled={isOutbound ?
              trip.outbound.find(st => st.stop.id === stop.stop.id && stop.time.value !== '') ? isUpdatingCircuit ? true : false : false
              :
              trip.inbound.find(st => st.stop.id === stop.stop.id && stop.time.value !== '') ? isUpdatingCircuit ? true : false : false}
            onChange={e => onChange({
              e: e,
              inputType: "time",
            })}
            value={
              stop.time.value
            }
            valid={stop.time.valid}
            helpText={stop.time.message}
          />
        </CTableDataCell>
        {
          trip.activities?.map((act, idx) => {
            const hasDetailForVehicle = stop.stop.details.find((detail) => detail?.vehicle?.uuid === selectedVehicle)
            const hasActivity = hasDetailForVehicle?.activities?.find(a => a.activity.id === act.activity.id)

            return hasActivity ? (
              <CheckBoxDataCell key={`addReinforcement-checkBoxCell-idx-${idx}`}>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <CustomCheckbox
                    checkStyle={{ width: '40px', height: '20px', marginTop: '5px' }}
                    id={`act${idx}`}
                    disabled={false}
                    checked={hasActivity.isOn.value}
                    onClick={e => onChange({
                      e: !hasActivity.isOn.value,
                      activityIdx: act.activity.id,
                      inputType: "checkbox",
                    })}
                    switch
                  />
                </div>
              </CheckBoxDataCell>
            ) : (
              <CheckBoxDataCell key={`addReinforcement-checkBoxCell-idx-${idx}`}>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <CustomCheckbox
                    checkStyle={{ width: '40px', height: '20px', marginTop: '5px' }}
                    id={`act${0}`}
                    disabled={false}
                    checked={hasActivity?.isOn.value || false}
                    onClick={e => onChange({
                      e: !hasActivity?.isOn.value || e.target.value,
                      activityIdx: act.activity.id,
                      inputType: "checkbox"
                    })}
                    switch
                  />
                </div>
              </CheckBoxDataCell>
            )
          })
        }
      </>
    </CustomCTableRow>
  )
}

export default Addreinforcementtablerowform