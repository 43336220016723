import styled from 'styled-components'
import DropdownArrow from 'assets/icons/dropdown_arrow';
import theme from 'ui/theme';


export const Wrapper = styled.div<{ maxWidth: string }>`
  max-width:${props => props.maxWidth ? props.maxWidth : "200px"};
  width: fit-content;
  max-height: 23px;
  position: relative;
  z-index: 5;
`

export const ArrowContainer = styled.div`
  margin: 8px 0 8px 8px;
  display: flex;
  align-items: center;
`

export const CustomDropdownArrow = styled(DropdownArrow) <{ isDefaultSelected: boolean, open: boolean }>`
  margin-top: 2px;
`
export const CurrentValueContainer = styled.div``

export const OptionsContainer = styled.div<{ open: boolean }>`
  transition: height 0.2s;
  max-width: 200px;
  width: ${() => {
    const el = document.getElementsByClassName('test') as HTMLCollectionOf<HTMLElement> 
    return el[0]?.offsetWidth+100 
  }}px;
  position: absolute;
  overflow-y: scroll;
  z-index:1;
  height: ${props => props.open ? "200px" : "0px"};
  border-top: 0;
  
  -ms-overflow-style: none;
  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none;
  }
`

export const OptionItem = styled.div<{ isSelected: boolean }>`
  font-family: 'Inter';
  font-size: 14px;
  padding-left: 10px;
  color: ${props => props.isSelected ? theme().white : theme().gray500Color};
  background-color: ${props => props.isSelected ? theme().gray300Color : theme().white};
  z-index: 99;
  cursor: pointer;


  :hover {
    background-color: ${theme().gray900Color};
    color: ${theme().white};
  }
`

export const SelectContainer = styled.div<{ isDefaultSelected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 !important;
  border: 0;
  font-family: 'Inter';
  font-size: 14px;
  cursor: pointer;

  > svg:first-child {
    fill: ${props => props.isDefaultSelected ? theme().gray500Color : theme().primary} !important;
  }

  > :nth-child(1) {
    color: ${props => props.isDefaultSelected ? theme().gray500Color : theme().primary};
  }

  > :nth-child(2) {
    margin-left: 8px;
    color: ${props => props.isDefaultSelected ? theme().gray500Color : theme().primary};
    border: 0;
    background: transparent;
  }
`