import { all } from 'redux-saga/effects';

import appSaga from 'app/logic/sagas';
import dashboardSaga from 'scenes/Dashboard/logic/sagas';
import settingsSaga from 'scenes/Settings/logic/sagas';
// WARNING!! Don't erase the nextline as it is necessary for hygen to automatically import your components
// Define imports here
import invoicedetailsSaga from 'scenes/Invoicedetails/logic/sagas'
import usercashmovementformSaga from 'scenes/Usercashmovementform/logic/sagas'
import usercashmanagementformsSaga from 'scenes/Usercashmanagementforms/logic/sagas'
import usercashmanagementSaga from 'scenes/Usercashmanagement/logic/sagas'
import cashmanagementSaga from 'scenes/Cashmanagement/logic/sagas'
import clockqrreaderSaga from 'scenes/Clockqrreader/logic/sagas'
import historylogSaga from 'scenes/Historylog/logic/sagas'
import usertimerecorddetailsSaga from 'scenes/Usertimerecorddetails/logic/sagas'
import timerecordSaga from 'scenes/Timerecord/logic/sagas'
import configurationsSaga from 'scenes/Configurations/logic/sagas'
import invoicingrequestsSaga from 'scenes/InvoicingRequests/logic/sagas'
import invoicingsummarySaga from 'scenes/InvoicingSummary/logic/sagas'
import bookingformSaga from 'scenes/BookingForm/logic/sagas'
import passengercontrolSaga from 'scenes/Passengercontrol/logic/sagas'
import tripbookingsSaga from 'scenes/Tripbookings/logic/sagas'
import tripplanningsformSaga from 'scenes/TripForms/Tripplanningsform/logic/sagas'
import tripplanningsSaga from 'scenes/Tripplannings/logic/sagas'
import entitymemberformSaga from 'scenes/Entitymemberform/logic/sagas'
import entitymemberslistSaga from 'scenes/Entitymemberslist/logic/sagas'
import entityformSaga from 'scenes/Entityform/logic/sagas'
import entitiesSaga from 'scenes/Entities/logic/sagas'
import customerpurchasehistorySaga from 'scenes/Customerpurchasehistory/logic/sagas'
import tripcampaigncodesSaga from 'scenes/Tripcampaigncodes/logic/sagas'
import customerformSaga from 'scenes/CustomerForm/logic/sagas'
import customersSaga from 'scenes/Customers/logic/sagas'
import fuellocationconfigformSaga from 'scenes/Fuellocationconfigform/logic/sagas'
import documentconfigformSaga from 'scenes/Documentconfigform/logic/sagas'
import fleetconfigsSaga from 'scenes/Fleetconfigs/logic/sagas'
import tripsconfigurationSaga from 'scenes/Tripsconfiguration/logic/sagas'
import tripactivityformSaga from 'scenes/TripForms/Tripactivityform/logic/sagas'
import tripcategoryformSaga from 'scenes/TripForms/Tripcategoryform/logic/sagas'
import triplocalformSaga from 'scenes/TripForms/Triplocalform/logic/sagas'
import trippassengertypeformSaga from 'scenes/TripForms/Trippassengertypeform/logic/sagas'
import tripextraformSaga from 'scenes/TripForms/Tripextraform/logic/sagas'
import tripcampaignformSaga from 'scenes/TripForms/Tripcampaignform/logic/sagas'
import tripcampaigncodesformSaga from 'scenes/TripForms/Tripcampaigncodesform/logic/sagas'
import tripcircuitformSaga from 'scenes/TripForms/Tripcircuitform/logic/sagas'
import tripserviceformSaga from 'scenes/TripForms/Tripserviceform/logic/sagas'
import changepasswordSaga from 'scenes/Changepassword/logic/sagas'
import fleetmaintenanceFormSaga from 'scenes/FleetMaintenanceForm/logic/sagas'
import fleetmaintenanceSaga from 'scenes/Fleetmaintenance/logic/sagas'
import fleetmanagementformSaga from 'scenes/FleetManagementForm/logic/sagas'
import fleetfuellingSaga from 'scenes/Fleetfuelling/logic/sagas'
import userFormSaga from 'scenes/UserForms/logic/sagas'
import collaboratorsmanagementSaga from 'scenes/CollaboratorsManagement/logic/sagas'
import fleetmanagementSaga from 'scenes/Fleetmanagement/logic/sagas'
import fleetFuellingFormSaga from 'scenes/FleetFuellingForms/logic/sagas'
import loginSaga from 'scenes/Login/logic/sagas'
import BusinessInteligence from 'scenes/BusinessInteligence/logic/sagas';
import BiDashboard from 'scenes/BiDashboard/logic/sagas';


export default function* rootSaga() {
  yield all([
    invoicedetailsSaga(),
    usercashmovementformSaga(),
    usercashmanagementformsSaga(),
    usercashmanagementSaga(),
    cashmanagementSaga(),
    clockqrreaderSaga(),
    historylogSaga(),
    usertimerecorddetailsSaga(),
    timerecordSaga(),
    configurationsSaga(),
    invoicingrequestsSaga(),
    invoicingsummarySaga(),
    bookingformSaga(),
    passengercontrolSaga(),
    tripbookingsSaga(),
    BusinessInteligence(),
    BiDashboard(),
    tripplanningsformSaga(),
    tripplanningsSaga(),
    entitymemberformSaga(),
    entitymemberslistSaga(),
    entityformSaga(),
    entitiesSaga(),
    customerpurchasehistorySaga(),
    tripcampaigncodesSaga(),
    customerformSaga(),
    customersSaga(),
    fuellocationconfigformSaga(),
    documentconfigformSaga(),
    fleetconfigsSaga(),
    tripsconfigurationSaga(),
    tripactivityformSaga(),
    tripcategoryformSaga(),
    triplocalformSaga(),
    trippassengertypeformSaga(),
    tripextraformSaga(),
    tripcampaignformSaga(),
    tripcampaigncodesformSaga(),
    tripcircuitformSaga(),
    tripserviceformSaga(),
    changepasswordSaga(),
    fleetmaintenanceFormSaga(),
    fleetmaintenanceSaga(),
    fleetmanagementformSaga(),
    fleetfuellingSaga(),
    fleetFuellingFormSaga(),
    userFormSaga(),
    collaboratorsmanagementSaga(),
    fleetmanagementSaga(),
    loginSaga(),
    appSaga(),
    dashboardSaga(),
    settingsSaga(),
  ]);
}
