import * as React from "react"
import theme from "ui/theme"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width={props.width || 24}
            height={props.height || 24}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M22 6c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6zm-2 0l-8 4.99L4 6h16zm0 12H4V8l8 5 8-5v10z"
                fill={props.fill || theme().gray600Color}
            />
            <circle cx={20} cy={6} r={3} fill={"transparent"} />
        </svg>
    )
}

export default SvgComponent
