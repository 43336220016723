/**
 * Vehiclslisttablerow component
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */

import React from 'react'
import { ActionsCell, ChevronCell, NameAndRoleCell, WarningIcon, WarningTooltip, CustomH5 } from 'shared/genericStyledComponents'
import { CustomCTableRow } from './styles'
import { VehicleListSingle } from 'types/fleet/vehicle';
import { CTableDataCell } from '@coreui/react-pro';
import GaugeComponent from 'shared/components/GaugeComponent';
import theme from 'ui/theme';
import ChevronRight from 'assets/icons/chevron_right';
import * as icon from '@coreui/icons'
import { DateTime, Interval } from 'luxon';
import { actions } from 'store/rootSlices'
import { useDispatch } from 'react-redux';

type PropTypes = {
  id?: string,
  vehicle: VehicleListSingle
  actions: JSX.Element,
  onClick: Function,
  permission: boolean,
}

const VehiclesListTableRow = (props: PropTypes) => {

  const dispatch = useDispatch()
  const {
    permission,
    vehicle: {
      name,
      shortName,
      capacity,
      fuelCapacity,
      isAvailable,
      nextMaintenance,
      fuelLevel,
      id
    }
  } = props;

  const days_to_inspection = Interval.fromDateTimes(DateTime.now(),DateTime.fromISO(nextMaintenance)).length('days') //DateTime.now().diff(DateTime.fromISO(nextMaintenance), 'days').days  

  return (
    <CustomCTableRow id={props.id}>
      <NameAndRoleCell >
        <CustomH5 onClick={() => props.onClick()}>{name}
          {days_to_inspection < 20 ?
            <WarningTooltip placement="bottom" content={`Próxima vistoria: \n ${DateTime.fromISO(nextMaintenance).setLocale('pt').toLocaleString()}`}>
              <WarningIcon color={theme().warning} height={14} icon={icon.cilWarning} size={"sm"} />
            </WarningTooltip>
            : null
          }
        </CustomH5>
      </NameAndRoleCell>
      <CTableDataCell>{shortName}</CTableDataCell>
      <CTableDataCell>{capacity}</CTableDataCell>
      <CTableDataCell>{isAvailable ? "Ativo" : "Inativo"}</CTableDataCell>
      <CTableDataCell><GaugeComponent maxValue={Number.parseInt(fuelCapacity)} currentValue={fuelLevel} unit="liters" /></CTableDataCell>
      <ActionsCell nopointer>
        {props.actions}
        <ChevronCell id={`${props.id}-chevron`}>
        {
            permission &&
            <a href={`/frota/veiculos/editar-veiculo/${id}`} onClick={(e) => {
              e.preventDefault()
              dispatch(actions.App.navigateTo(`/frota/veiculos/editar-veiculo/${id}`))
              }}>
              <ChevronRight></ChevronRight>
            </a>
        }
        </ChevronCell>
      </ActionsCell>

    </CustomCTableRow>
  )
}

export default VehiclesListTableRow
