/**
 * Invoicingsummary scene sagas
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */
import { call, putResolve, select, takeLatest } from 'redux-saga/effects'
import API from 'api'
import { actions, RootState } from 'store/rootSlices'
import { toast } from 'react-toastify';
import i18n from 'i18next';
import { AnyAction } from '@reduxjs/toolkit';
import { Interval } from 'luxon';
import { BalancesList, MovementsList } from 'types/invoicing/invoices';
import { ItemsList } from 'types/misc';

function* onMountSaga({payload}: AnyAction) {
  yield getURLParamsSaga(payload);
}

function* fetchEntriesSaga() {
  try {
    yield putResolve(actions.InvoicingSummary.setLoading(true));
    const { page, startDate, searchTerm, endDate, filter, paymentTypeFilter } = yield select((state: RootState) => state.InvoicingSummary);
    const values: BalancesList = yield call(API.Invoicing.GetEntries, page, filter, undefined, searchTerm, startDate, endDate, paymentTypeFilter);

    if (values) {
      yield putResolve(actions.InvoicingSummary.setTotalEntries(values.entries.count));
      yield putResolve(actions.InvoicingSummary.setEntries(values.entries.results));
      yield putResolve(actions.InvoicingSummary.setCounters(values));
      yield putResolve(actions.InvoicingSummary.setLoading(false));
    }
  } catch (err) {
    toast.error(i18n.t("toasts.entriesGetError"))
  } finally {
    yield putResolve(actions.InvoicingSummary.setLoading(false));
  }
}

function* getEntriesSaga() {
  const { page, sort, filter, paymentTypeFilter, searchTerm, startDate, endDate, movementTypeFilter } = yield select((state: RootState) => state.InvoicingSummary);
  yield putResolve(actions.InvoicingSummary.setLoading(true));

  try {
    const values: BalancesList = yield call(API.Invoicing.GetEntries, page, filter, sort, searchTerm, startDate, endDate, paymentTypeFilter, movementTypeFilter);

    if(values){
      yield putResolve(actions.InvoicingSummary.setTotalEntries(values.entries.count));
      yield putResolve(actions.InvoicingSummary.setEntries(values.entries.results));
      yield putResolve(actions.InvoicingSummary.setCounters(values));
      yield putResolve(actions.InvoicingSummary.setLoading(false));
    }
  } catch (error) {
    toast.error(i18n.t("toasts.entriesGetError"))
    yield putResolve(actions.InvoicingSummary.setLoading(false));
  }
}

function* getPaymentTypesSaga() {

  try {
    const paymentTypes: ItemsList = yield call(API.TripSettings.GetPaymentTypes);

    if(paymentTypes){
      yield putResolve(actions.InvoicingSummary.setPaymentTypes(paymentTypes));
    }
  } catch (error) {
    toast.error(i18n.t("toasts.paymentTypesGetError"))
  }
}

function* getMovementTypesSaga() {

  try {
    const movementTypes: MovementsList = yield call(API.TripSettings.GetPaymentEntries);

    if(movementTypes){
      yield putResolve(actions.InvoicingSummary.setMovementTypes(movementTypes));
    }
  } catch (error) {
    toast.error(i18n.t("toasts.movementTypesGetError"))
  }
}

function* getURLParamsSaga( payload : AnyAction) {
  let params = new URLSearchParams(payload.location.search)

  const {
      hasDateParams,
      searchTerm,
      filter,
      paymentTypeFilter,
      movementTypeFilter,
      startDate,
      endDate
  } = yield select((state: RootState) => state.InvoicingSummary);

  if (params.toString() !== "") {

    yield putResolve(actions.InvoicingSummary.populateFiltersOnMount({
      searchTerm: params.get('search') ?? "",
      filter: params.get('processedBy') ?? "",
      paymentTypeFilter: params.get('paymentType') ?? "",
      movementTypeFilter: params.get('movementType') ?? "",
      startDate: params.get('startDate') ?? new Date().toISOString().slice(0, 10),
      endDate: params.get('endDate') ?? new Date().toISOString().slice(0, 10)
    }))
    yield putResolve(actions.InvoicingSummary.setHasDateParams(!hasDateParams));
  }
  else {
      //state.hasDateParams has to be different from the last known state.hasDateParams to force a reRender on datepicker
      //this reRender must be done so the datepicker changes its label
      yield putResolve(actions.InvoicingSummary.setHasDateParams(!hasDateParams));
      params.set('processedBy', filter)
      params.set('paymentType', paymentTypeFilter)
      params.set('movementType', movementTypeFilter)
      params.set('search', searchTerm)
      params.set('startDate', startDate)
      params.set('endDate', endDate)
      payload.replace({ search: (params).toString() })
  }
}

function* setDatesIntervalSaga({ payload }: AnyAction) {
  
  if(payload.e.startDate && payload.e.endDate){
    let interval = Interval.fromDateTimes(payload.e.startDate, payload.e.endDate);
    const arr = Array.from(days(interval))
    
    let params = new URLSearchParams(payload.history.location.search);
    payload.e.startDate !== "" ? params.set('startDate', arr[0]?.toISO().slice(0,10)) : params.delete('startDate')
    payload.e.endDate !== "" ? params.set('endDate', arr[arr.length-1]?.toISO().slice(0,10)) : params.delete('endDate')
    payload.history.replace({ search: (params).toString() })
  
    yield putResolve(actions.InvoicingSummary.setStartDate(arr[0]?.toISO().slice(0, 10)))
    yield putResolve(actions.InvoicingSummary.setEndDate(arr[arr.length - 1]?.toISO().slice(0, 10)))
  } else {
    let params = new URLSearchParams(payload.history.location.search);
    params.delete('startDate')
    params.delete('endDate')
    payload.history.replace({ search: (params).toString() })

    yield putResolve(actions.InvoicingSummary.setStartDate(""))
    yield putResolve(actions.InvoicingSummary.setEndDate(""))
}
}

function* onUnmountSaga() {
  yield putResolve(actions.InvoicingSummary.setEntries([]));
  yield putResolve(actions.InvoicingSummary.setCounters({
    expenses:"--",
    income:"--",
    total:"--"
  }));
  yield putResolve(actions.InvoicingSummary.setLoading(true));
}

function* days(interval) {
  let cursor = interval.start.startOf("day");

  while (cursor <= interval.end) {
    yield cursor;
    cursor = cursor.plus({ days: 1 });
  }
}

function* exportStatementsSaga() {
  yield putResolve(actions.InvoicingSummary.setExportLoading(true))

  const { startDate, endDate, filter } = yield select((state: RootState) => state.InvoicingSummary);

  try {
    const file = yield call(API.Invoicing.ExportStatements, startDate, endDate, filter)
    if (file) {
      yield putResolve(actions.InvoicingSummary.downloadStatements(file))
      toast.success(i18n.t("toasts.downloadCashManagementListSuccess"))
    }

  } catch (error) {
    toast.error(i18n.t("toasts.downloadCashManagementListError"))
  } finally {
    yield putResolve(actions.InvoicingSummary.setExportLoading(false))
  }
}

export default function* watcherSignin() {
  yield takeLatest('InvoicingSummary/onMount', onMountSaga)
  yield takeLatest('InvoicingSummary/onUnmount', onUnmountSaga)
  yield takeLatest('InvoicingSummary/getPaymentTypes', getPaymentTypesSaga)
  yield takeLatest('InvoicingSummary/getMovementTypes', getMovementTypesSaga)
  yield takeLatest('InvoicingSummary/getEntries', getEntriesSaga)
  yield takeLatest('InvoicingSummary/setSort', getEntriesSaga)
  yield takeLatest('InvoicingSummary/setFilter', getEntriesSaga)
  yield takeLatest('InvoicingSummary/setPaymentTypeFilter', getEntriesSaga)
  yield takeLatest('InvoicingSummary/setPage', getEntriesSaga)
  yield takeLatest('InvoicingSummary/setSearchTerm', getEntriesSaga)
  yield takeLatest('InvoicingSummary/setMovementTypeFilter', getEntriesSaga)
  yield takeLatest('InvoicingSummary/getURLParams', getURLParamsSaga);
  yield takeLatest('InvoicingSummary/setDatesInterval', setDatesIntervalSaga);
  yield takeLatest('InvoicingSummary/exportStatements', exportStatementsSaga)
  yield takeLatest('InvoicingSummary/setSearchTerm', fetchEntriesSaga)
}


