/**
 * PaymentsPending component
 *
 * @author João Flores <jflores@ubiwhere.com>
 *
 *
 */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { actions, RootState } from 'store/rootSlices';
import theme from 'ui/theme';
import { DateTime } from 'luxon';
import config from 'config';
//styles
import { TableRow } from '../../styles';
import { RefundLabel, PaymentStateCell, PendingPaymentsFooter } from './styles';
//icons
import Loop from 'assets/icons/loop';
import StartTrip from 'assets/icons/start_trip';
import EndTrip from 'assets/icons/end_trip';
//Components
import { CTableRow, CTableDataCell } from '@coreui/react-pro';
import CustomTextInput from 'shared/components/CustomTextInput';
import TableContainer from 'shared/components/TableContainer';
// Types
import type { BookingPaymentEntry } from 'types/trips/bookings';
import Beach from 'assets/icons/beach';

const PaymentsPending = ({ t }) => {
  const {
    entriesIsRefund,
    paymentForm,
    entries,
    entriesTotal,
    hasEditPermission,
    isFree
  } = useSelector((state: RootState) => state.BookingForm);

  const { onPaymentFormChange, setEntriesTotal, setEntriesNoValue, setIsFree } = actions.BookingForm;

  const dispatch = useDispatch();

  const _renderTableFooter = () => {
    return (
      <PendingPaymentsFooter>
        <CTableRow className="extra">
          <CTableDataCell>{t('common.correction')}</CTableDataCell>
          <CTableDataCell></CTableDataCell>
          <CTableDataCell style={{ textAlign: 'right' }}>
            <Link
              style={{ fontSize: '14px', textDecoration: 'none' }}
              to="#"
              onClick={() => {
                dispatch(setEntriesNoValue());
                dispatch(setIsFree(!isFree));
              }}
            >
              {t('tripBookings.noValue')}
            </Link>
          </CTableDataCell>
          <CTableDataCell>
            <CustomTextInput
              id="bookingForm-paymentDiscount"
              icon="euro"
              disabled={!hasEditPermission}
              valid={paymentForm.correction.valid}
              value={paymentForm.correction.value}
              helpText={paymentForm.correction.message}
              onChange={(e) => {
                dispatch(
                  onPaymentFormChange({
                    ...paymentForm,
                    correction: { value: e.target.value.trim(), message: '', valid: true },
                  })
                );
                dispatch(setEntriesTotal());
              }}
            />
          </CTableDataCell>
        </CTableRow>
        <CTableRow className={entriesIsRefund ? 'refund' : 'payment'}>
          {entriesIsRefund ? (
            <CTableDataCell colSpan={3}>{t('tripBookings.totalPendingRefund')}</CTableDataCell>
          ) : (
            <CTableDataCell colSpan={3}>
              {t('tripBookings.totalPendingPayment')} <span>{t('common.taxLabel')}</span>
            </CTableDataCell>
          )}
          <CTableDataCell>{Math.abs(entriesTotal)}€</CTableDataCell>
        </CTableRow>
      </PendingPaymentsFooter>
    )
  }

  const _renderEntryIcon = (entry: BookingPaymentEntry) => {
    if (entry.leg?.beachTripActivity) return <Beach fill={theme().darkColor} />
    return entry.leg?.tripActivityLeg?.activity.isHopOnHopOff ? (
      <Loop />
    ) : entry.leg?.isOutbound ? (
      <StartTrip fill={theme().gray500Color} />
    ) : (
      <EndTrip fill={theme().gray500Color} />
    )
  }

  const _renderTableRow = (entry: BookingPaymentEntry, idx: number) => {
    return entry.state === 'pending' && (
      <TableRow key={`paymentMade-row-${idx}`}>
        <CTableDataCell>
          <>
            {_renderEntryIcon(entry)}{' '}
            {entry.isRefund ? <RefundLabel>{t('tripBookings.refund')}</RefundLabel> : ''}{' '}
            {entry.description}
          </>
        </CTableDataCell>
        <CTableDataCell>
          {entry.createdAt &&
            DateTime.fromISO(entry.createdAt).toFormat(config.DATE_FORMAT.DATETIME)}
        </CTableDataCell>
        <PaymentStateCell
          className={
            entry.isRefund
              ? 'refund'
              : entry.canceled || (entry.leg?.isCanceled && !entry.isFee)
                ? 'canceled'
                : 'pending'
          }
        >
          {entry.canceled || (entry.leg?.isCanceled && !entry.isFee)
            ? `(${t('tripBookings.canceled')})`
            : t('tripBookings.pending')}
        </PaymentStateCell>
        <CTableDataCell style={{ width: '10%' }}>{entry.amount}€</CTableDataCell>
      </TableRow>
    )
  }

  return (
    <TableContainer
      count={0}
      elementsPerPage={0}
      currentPage={-1}
      onPageChange={() => { }}
      headerElements={[
        t('tripBookings.awaitingPayment'),
        t('common.createdAt'),
        t('tripBookings.status'),
        t('common.price'),
      ]}
      footer={_renderTableFooter()}
    >
      {entries.map((entry, idx) => _renderTableRow(entry, idx))}
    </TableContainer>
  );
};

export default PaymentsPending;
