/**
 * Checkinsmodal component
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */
import { CForm, CTable, CTableHead } from '@coreui/react-pro'
import React from 'react'
import i18n from 'i18next';
import { useSelector } from 'react-redux'
// Components
import Loadingcontainer from 'shared/components/Loadingcontainer'
import CustomModal from 'shared/components/CustomModal'
import FormContent from './components/FormContent';
// Icons
import Boat from 'assets/icons/boat_icon'
import StartTrip from 'assets/icons/start_trip'
import EndTrip from 'assets/icons/end_trip'
// Store
import { RootState } from 'store/rootSlices';
// Types
import type { Vehicles, InOutBoundStops } from 'types/trips/plannings'
// Styles
import * as Styles from './styles'

type PropTypes = React.PropsWithChildren<{
  showModal: boolean,
  elegibleBookings?: any,
  tripBookings?: any,
  totalBookings?: any,
  planningOutStops?: InOutBoundStops[],
  planningInStops?: InOutBoundStops[],
  onClose: any,
  tripUnits: Vehicles[]
}>

const Checkinsmodal = (props: PropTypes) => {
  const {
    showModal,
    elegibleBookings,
    tripBookings,
    totalBookings,
    planningOutStops,
    planningInStops,
    tripUnits
  } = props

  const { 
    modalLoading, 
    checkinOutboundSelected, 
    showCheckinModal,
  } = useSelector((state: RootState) => state.TripPlannings);


  return (
    <CustomModal
      visible={showModal}
      warningType='message'
      warningName={i18n.t('tripPlannings.passengersList')}
      buttonIcon={false}
      footerPosition='flex-start'
      textAlign='start'
      noButtons={true}
      size="xl"
      modalTitleExtra={
        <>
          {
            showCheckinModal.openBy === 'stop' ? (
              <span>
                - {showCheckinModal.stop.name} {checkinOutboundSelected ? <StartTrip /> : <EndTrip />}
              </span>
            ) : showCheckinModal.openBy === 'vehicle' ? (
              <span>
                <Boat fill={showCheckinModal?.vehicle.vehicle.color} />
                { showCheckinModal?.vehicle.vehicle.name }
                {
                  (showCheckinModal.vehicle.departure && !showCheckinModal.vehicle.arrival) || (showCheckinModal.vehicle.departure && showCheckinModal.vehicle.arrival && showCheckinModal.vehicle.inTransit) 
                    ?
                  ` - ${showCheckinModal.vehicle.departure.stop.name}`
                    :
                  showCheckinModal.vehicle.departure && showCheckinModal.vehicle.arrival && !showCheckinModal.vehicle.inTransit
                    ?
                  ` - ${showCheckinModal.vehicle.arrival.stop.name}`
                    :
                  null
                }
              </span>
            ) : <></>
          }
        </>
      }
      children={
        <CForm>
          { tripBookings || elegibleBookings ?
            <FormContent 
              elegibleBookings={elegibleBookings} 
              tripBookings={tripBookings} 
              totalBookings={totalBookings}
              planningInStops={planningInStops}
              planningOutStops={planningOutStops}
              tripUnits={tripUnits}
            />
          :
          modalLoading ?
            <Loadingcontainer />
            :
              <CTable>
                <CTableHead>
                  <Styles.CustomTableHeaderCell></Styles.CustomTableHeaderCell>
                </CTableHead>
                <Styles.CustomCTableBody expanded={true}>
                  <Styles.CustomCTableRow>
                    <Styles.NoResultsCell>{i18n.t('common.noResults')}</Styles.NoResultsCell>
                  </Styles.CustomCTableRow>
                </Styles.CustomCTableBody>
              </CTable>
          }
          
        </CForm>
      }
      onclose={e => props.onClose(false)}
      onsubmit={e => {/* handleDeleteCollaboratorConfirm(e) */ }} />
  )
}

export default Checkinsmodal