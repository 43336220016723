/**
 * Tripcategoryform component
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */

import { CCol, CForm, CRow, CFormLabel } from '@coreui/react-pro'
import { useParams } from 'react-router-dom';
import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { actions, RootState } from 'store/rootSlices'
import CIcon from '@coreui/icons-react';
import { cilTrash } from '@coreui/icons'
import { toast } from 'react-toastify';
import { Title, TitleBox, ContainerBox } from 'shared/genericStyledComponents';
import TabsContainer from 'shared/components/Tabscontainer';
import CustomTextInput from 'shared/components/CustomTextInput'
import SectionContainer from 'shared/components/Sectioncontainer'
import ContainerDelete from 'shared/components/FormComponents/containerdelete';
import CustomButton from 'shared/components/CustomButton';
import theme from 'ui/theme';
import FileUploader from 'shared/components/FormComponents/fileuploader';
import ExtrasDefinitionSection from './components/extrasDefinitionSection';
import i18n from 'i18next';
import CustomCheckbox from 'shared/components/CustomCheckbox';
import Photoscomponent from 'shared/components/Photoscomponent';
import FileUpload from 'assets/icons/file_upload';
import config from 'config';
import { hasPermission } from 'permissions';
import { Row, ButtonWrapper } from '../styles';
import { ExtraImageInfo, SVGComponentDeleteWrapper, SVGComponentWrapper, SVGWrapper, Button } from './styles';


const Tripextraform = (props) => {

  const dispatch = useDispatch();
  const { id } = useParams();

  const {
    onFormChange,
    onMount,
    onUnmount,
    onSubmit,
    addToPhotosArray,
    reOrderPhotosArray,
    deletePhotoFromArray,
  } = actions.TripExtraForm;
  const {
    edit,
    extraForm,
    packForm,
    extraID,
    photos,
    newPhotosID,
    hasEditPermission,
    submitButtonState,
  } = useSelector((state: RootState) => state.TripExtraForm)
  const { navigateBack } = actions.App;
  const { permissions } = useSelector((state: RootState) => state.App)
  const { PERMISSIONS } = config

  useEffect(() => {
    dispatch(onMount(id))

    return () => {
      dispatch(onUnmount())
    }

  }, [dispatch, id, onMount, onUnmount]);

  const handleSubmit = (e) => {
    dispatch(onSubmit({
      extraForm,
      packForm,
      e,
      edit,
      photos
    }))
  }

  const handleDragPhotos = (e) => {
    dispatch(reOrderPhotosArray({ photos, e }))
  }

  const handleAddPhoto = (e) => {
    dispatch(addToPhotosArray({ photos, e, extraID, newPhotosID }))
  }

  const handleDeletePhoto = (e, index) => {
    dispatch(deletePhotoFromArray({ photos, e, extraID, newPhotosID, index }))
  }

  const svgRef = useRef<HTMLInputElement>(null)

  const handleIconChange = (e) => {
    if (e.target.value.toLowerCase().includes(".svg")) {
      dispatch(onFormChange({ ...extraForm, icon: { value: e.target.files[0], valid: true, message: "" } }))
    }
    else {
      if (e.target.value !== "") {
        toast.error("file type not supported")
      }
    }
  }

  const handleButtonClick = e => {
    if (svgRef.current !== null) {
      svgRef.current.click();
    }
  };

  const handleDeleteSvg = (e) => {
    dispatch(onFormChange({ ...extraForm, icon: { value: null, valid: true, message: "" } }))
  }


  return (
    <div>
      <CForm>
        <CustomCheckbox
          id='tripExtraForm-isActive'
          disabled={!hasEditPermission}
          wrapperStyle={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '-25px', fontSize: '20px' }}
          checked={extraForm.isActive.value ? true : false}
          onClick={e => dispatch(onFormChange({ ...extraForm, isActive: { value: !extraForm.isActive.value, valid: true, message: "" } }))}
          text={i18n.t('tripExtraForm.isActive')}
          switch
        />
        <div style={{ width: '100%', paddingBottom: '24px' }}>
          <TitleBox>
            <Title>{i18n.t('tripExtraForm.extraTitle')}</Title>
          </TitleBox>
          <TabsContainer id="tripExtraForm-tabsContainer" onChangeTab={tab => { }} content={[
            {
              title: i18n.t('tripExtraForm.extra'),
              content: <ContainerBox>
                <CRow className="g-3">
                  <CCol sm={7}>
                    <CustomTextInput
                      id='tripExtraForm-name'
                      required={true}
                      disabled={!hasEditPermission}
                      valid={extraForm.namePt.valid}
                      value={extraForm.namePt.value}
                      helpText={extraForm.namePt.message}
                      placeholder={i18n.t('placeholders.name')}
                      label={i18n.t('tripExtraForm.name')}
                      onChange={(e) => dispatch(onFormChange({ ...extraForm, namePt: { value: e.target.value, message: "", valid: true } }))}
                    />
                  </CCol>
                  <CCol>
                    <CustomTextInput
                      id='tripExtraForm-shortName'
                      disabled={!hasEditPermission}
                      required={true}
                      valid={extraForm.shortName.valid}
                      value={extraForm.shortName.value}
                      helpText={extraForm.shortName.message}
                      placeholder={i18n.t('placeholders.shortName')}
                      label={i18n.t('tripExtraForm.shortName')}
                      onChange={(e) => dispatch(onFormChange({ ...extraForm, shortName: { value: e.target.value, message: "", valid: true } }))}
                    />
                  </CCol>
                  <CCol>
                    <SVGComponentWrapper>
                      <CFormLabel>Icon</CFormLabel>
                      {extraForm.icon.value !== null ?
                        <SVGComponentDeleteWrapper>
                          <SVGWrapper>
                            <img alt='icon' className='colorize-filter' src={typeof extraForm.icon.value !== 'string' ? URL.createObjectURL(extraForm.icon.value) : extraForm.icon.value} />
                          </SVGWrapper>
                          <Button id='tripCategoryForm-deleteIcon' icon={true} color="none" onClick={e => hasEditPermission && handleDeleteSvg(e)}>{i18n.t('common.deleteBtn')}<CIcon height={15} icon={cilTrash} size={"sm"} /></Button>
                        </SVGComponentDeleteWrapper>
                        :
                        <div>
                          <Button id='tripCategoryForm-uploadIcon' icon={false} color="none" onClick={e => hasEditPermission && handleButtonClick(e)}>{i18n.t('tripCategoryForm.uploadIcon')}<FileUpload /></Button>
                          <input style={{ display: "none" }} accept=".svg" ref={svgRef} id="file-upload" type="file" onChange={e => handleIconChange(e)} />
                        </div>
                      }
                    </SVGComponentWrapper>
                  </CCol>
                </CRow>
                {/* Extras definition Section component */}
                <ExtrasDefinitionSection />
                <Row>
                  <CCol>
                    <CustomTextInput
                      id='tripExtraForm-description'
                      disabled={!hasEditPermission}
                      type="textarea"
                      required={false}
                      valid={extraForm.descriptionPt.valid}
                      value={extraForm.descriptionPt.value}
                      helpText={extraForm.descriptionPt.message}
                      placeholder={i18n.t('placeholders.description')}
                      label={i18n.t('tripExtraForm.description')}
                      onChange={(e) => dispatch(onFormChange({ ...extraForm, descriptionPt: { value: e.target.value, message: "", valid: true } }))}
                    />
                  </CCol>
                </Row>
              </ContainerBox>
            },
            {
              title: i18n.t('common.translations'),
              content: <ContainerBox>
                <CRow className="g-3">
                  <CCol sm>
                    <CustomTextInput
                      id='tripExtraForm-nameEn'
                      required={false}
                      disabled={!hasEditPermission}
                      valid={extraForm.nameEn.valid}
                      value={extraForm.nameEn.value}
                      helpText={extraForm.nameEn.message}
                      placeholder={i18n.t('placeholders.nameEn')}
                      label={i18n.t('tripActivityForm.nameEn')}
                      onChange={(e) => dispatch(onFormChange({ ...extraForm, nameEn: { value: e.target.value, message: "", valid: true } }))}
                    />
                  </CCol>
                  <CCol sm={8}>
                    <CustomTextInput
                      id='tripExtraForm-descriptionEn'
                      disabled={!hasEditPermission}
                      type="textarea"
                      required={false}
                      valid={extraForm.descriptionEn.valid}
                      value={extraForm.descriptionEn.value}
                      helpText={extraForm.descriptionEn.message}
                      placeholder={i18n.t('placeholders.descriptionEn')}
                      label={i18n.t('tripActivityForm.descriptionEn')}
                      onChange={(e) => dispatch(onFormChange({ ...extraForm, descriptionEn: { value: e.target.value, message: "", valid: true } }))}
                    />
                  </CCol>
                </CRow>
                <Row>
                  <CCol sm>
                    <CustomTextInput
                      id='tripExtraForm-nameEs'
                      required={false}
                      disabled={!hasEditPermission}
                      valid={extraForm.nameEs.valid}
                      value={extraForm.nameEs.value}
                      helpText={extraForm.nameEs.message}
                      placeholder={i18n.t('placeholders.nameEs')}
                      label={i18n.t('tripActivityForm.nameEs')}
                      onChange={(e) => dispatch(onFormChange({ ...extraForm, nameEs: { value: e.target.value, message: "", valid: true } }))}
                    />
                  </CCol>
                  <CCol sm={8}>
                    <CustomTextInput
                      id='tripExtraForm-descriptionEs'
                      disabled={!hasEditPermission}
                      type="textarea"
                      required={false}
                      valid={extraForm.descriptionEs.valid}
                      value={extraForm.descriptionEs.value}
                      helpText={extraForm.descriptionEs.message}
                      placeholder={i18n.t('placeholders.descriptionEs')}
                      label={i18n.t('tripActivityForm.descriptionEs')}
                      onChange={(e) => dispatch(onFormChange({ ...extraForm, descriptionEs: { value: e.target.value, message: "", valid: true } }))}
                    />
                  </CCol>
                </Row>
                <Row>
                  <CCol sm>
                    <CustomTextInput
                      id='tripExtraForm-nameFr'
                      required={false}
                      disabled={!hasEditPermission}
                      valid={extraForm.nameFr.valid}
                      value={extraForm.nameFr.value}
                      helpText={extraForm.nameFr.message}
                      placeholder={i18n.t('placeholders.nameFr')}
                      label={i18n.t('tripActivityForm.nameFr')}
                      onChange={(e) => dispatch(onFormChange({ ...extraForm, nameFr: { value: e.target.value, message: "", valid: true } }))}
                    />
                  </CCol>
                  <CCol sm={8}>
                    <CustomTextInput
                      id='tripExtraForm-descriptionFr'
                      disabled={!hasEditPermission}
                      type="textarea"
                      required={false}
                      valid={extraForm.descriptionFr.valid}
                      value={extraForm.descriptionFr.value}
                      helpText={extraForm.descriptionFr.message}
                      placeholder={i18n.t('placeholders.descriptionFr')}
                      label={i18n.t('tripActivityForm.descriptionFr')}
                      onChange={(e) => dispatch(onFormChange({ ...extraForm, descriptionFr: { value: e.target.value, message: "", valid: true } }))}
                    />
                  </CCol>
                </Row>
              </ContainerBox>
            }
          ]} />
        </div>
        <SectionContainer title={i18n.t('tripExtraForm.extraPhotosTitle')} style={{ width: '100%', paddingBottom: '24px' }}>
          <CRow className="g-3">
            <CCol>
              <FileUploader
                id='tripExtraForm-uploadPhoto'
                disabled={!hasEditPermission}
                required={false}
                label={i18n.t('tripExtraForm.upload')}
                accept='.png, .jpg, .jpeg'
                fileSizeLimit={100000000}
                onChange={(e) => handleAddPhoto(e)} />
            </CCol>
          </CRow>
          <CRow>
            <Photoscomponent
              id='tripExtraForm'
              array={photos}
              onDrag={e => handleDragPhotos(e)}
              onDelete={(e, index) => handleDeletePhoto(e, index)}
            />
            <ExtraImageInfo>{i18n.t('tripExtraForm.extraPhotoInfo')}</ExtraImageInfo>
          </CRow>
        </SectionContainer>
        {
          edit === true && hasPermission(permissions, PERMISSIONS.TRIPS.EXTRAS.DELETE_EXTRA) && <ContainerDelete id='tripExtraForm-delete' title={i18n.t('tripExtraForm.removeExtra')} text={<p>{i18n.t('tripExtraForm.removeExtraText')}<br />{i18n.t('common.deleteLine2')}</p>}></ContainerDelete>
        }
        <ButtonWrapper>
          <CustomButton id='tripExtraForm-cancel' onClick={() => dispatch(navigateBack())} text={i18n.t('common.cancelBtn')} color="link" buttonStyle={{ color: theme().danger, textDecoration: 'unset' }} />
          {
            edit ?
              hasPermission(permissions, PERMISSIONS.TRIPS.EXTRAS.EDIT_EXTRA) &&
              <CustomButton
                id='tripExtraForm-save'
                disabled={submitButtonState}
                text={i18n.t('common.saveBtn')}
                icon="confirm"
                onClick={e => handleSubmit(e)}
                color="success"
                buttonStyle={{ color: "#FFFFFF" }} />
              :
              hasPermission(permissions, PERMISSIONS.TRIPS.EXTRAS.CREATE_EXTRA) &&
              <CustomButton
                id='tripExtraForm-save'
                disabled={submitButtonState}
                text={i18n.t('common.saveBtn')}
                icon="confirm"
                onClick={e => handleSubmit(e)}
                color="success"
                buttonStyle={{ color: "#FFFFFF" }} />
          }
        </ButtonWrapper>
      </CForm>
    </div>
  )
}

export default Tripextraform