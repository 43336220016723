
import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import { CRow, CCol } from '@coreui/react-pro'
import CustomCheckbox from 'shared/components/CustomCheckbox'
import i18n from "i18next";

import SectionContainer from '../../shared/components/Sectioncontainer/index'
import CustomModal from 'shared/components/CustomModal'
import Entitycommissiontablerow from 'scenes/Entities/components/Entitycommissiontablerow'
import TabsContainer from 'shared/components/Tabscontainer'
import TableContainer from 'shared/components/TableContainer'
import SorterComponent from 'shared/components/SorterComponent'

import { actions, RootState } from 'store/rootSlices'

const Comissions = (props) => {

    const dispatch = useDispatch();
    const { 
      hasEditPermission, 
      userForm, 
      activitiesCommissionForm, 
      extrasCommissionForm,
      confirmAllModal,
      isApplyAllOnActivities ,
      defaultActivitiesCommissions,
      defaultExtrasCommissions
    } = useSelector((state: RootState) => state.UserForm);

    const { 
      onFormChange, 
      setConfirmAllModal,
      applyValueOnAllActivitiesComissions,
      applyValueOnAllExtrasComissions ,
      onActivityCommissionChange,
      onExtraCommissionChange,
      changeDefaultCommissionValues,
      onCommissionChangeToggler
    } = actions.userForm;


    useEffect(() => {

    })

    return (
      <>
        <CustomModal
          id={`entityForm-confirmAllModal`}
          warningType='message'
          visible={confirmAllModal}
          buttonIcon={true}
          footerPosition='center'
          textAlign='center'
          buttonName={i18n.t('common.confirm')}
          children={isApplyAllOnActivities ? <p>{i18n.t('entityForm.confirmAllModalActivities')}</p> : <p>{i18n.t('entityForm.confirmAllModalExtras')}</p>}
          onclose={e => dispatch(setConfirmAllModal({ confirmAll: false, isApplyAllOnActivities }))}
          onsubmit={e => {
            isApplyAllOnActivities === true ?
              dispatch(applyValueOnAllActivitiesComissions({ defaultCommission: userForm.activitiesCommissionDefault.value, activitiesCommissionForm }))
              :
              dispatch(applyValueOnAllExtrasComissions({ defaultCommission: userForm.extrasCommissionDefault.value, extrasCommissionForm }))
          }} 
        />
        <SectionContainer title={i18n.t('entityForm.titleCommissions')} style={{ width: '100%', paddingBottom: '24px' }}>
          <CRow className="g-3">
              <CCol>
                <CustomCheckbox
                    id="userForm-earnsCommissions"
                    disabled={!hasEditPermission}
                    checked={userForm.earnsCommissions.value}
                    onClick={(e) => dispatch(onFormChange({ ...userForm, earnsCommissions: { value: e.target.checked, message: "", valid: true } }))}
                    text={i18n.t('userForm.hasCommissions')}
                />
              </CCol>
          </CRow>
          {
            userForm.earnsCommissions.value && (
              <>
                <Row className="g-3">
                  <CCol>
                    <CustomCheckbox
                        id="userForm-earnsDefaultCommissions"
                        disabled={!hasEditPermission}
                        checked={userForm.earnsDefaultCommissions.value}
                        onClick={(e) => dispatch(onFormChange({ ...userForm, earnsDefaultCommissions: { value: e.target.checked, message: "", valid: true } }))}
                        text={i18n.t('userForm.hasDefaultCommissions')}
                    />
                  </CCol>
                </Row>
                <Row>
                  <TabsContainer 
                    onChangeTab={() => { }} 
                    content={[
                      {
                        title: i18n.t('entityForm.activityCommissions'),
                        content: <CustomCommissionTable key="customCommissionTable1"
                          headerElements={[
                            <SorterComponent id="entityForm-activityCommissions-nameSorter" filterName={i18n.t('entityForm.activities')} onChange={() => { }}></SorterComponent>,
                            <div>{i18n.t('entityForm.isCustom')}</div>,
                            <SorterComponent id="entityForm-activityCommissions-valueSorter" filterName={i18n.t('entityForm.commissionValue')} onChange={() => { }}></SorterComponent>,
                            <div />,
                            <div />
                          ]}
                          count={0}
                          currentPage={-1}
                          elementsPerPage={0}
                          onPageChange={() => { }}>
                          {
                            userForm.earnsDefaultCommissions.value 
                              ? 
                            defaultActivitiesCommissions.map((act, idx) => {
                              return (
                                <Entitycommissiontablerow
                                  id={`entityForm-activityCommission${idx}`}
                                  key={`comissions-activity-${idx}-${act.uuid}`}
                                  hasEditPermission={hasEditPermission && !userForm.earnsDefaultCommissions.value}
                                  onChange={(id, e) => dispatch(onActivityCommissionChange({ idx, e }))}
                                  onChangeToggler={(e) => dispatch(onCommissionChangeToggler({ idx, value: e, isActivity: true, default: '' }))}
                                  commission={{ uuid: act.uuid, name: act.name, isCustom: true, commission: act.commission }}></Entitycommissiontablerow>
                              )
                            }) 
                              : 
                            <>
                              <Entitycommissiontablerow
                                id={`entityForm-activityCommissionDefault`}
                                key={`comissionRow-activity-default`}
                                hasEditPermission={hasEditPermission && !userForm.earnsDefaultCommissions.value}
                                onClickAll={() =>  dispatch(setConfirmAllModal({ confirmAll: true, isApplyAllOnActivities: true }))}
                                onChange={(id, e) => {
                                  dispatch(onFormChange({ ...userForm, activitiesCommissionDefault: { value: e, valid: true, message: "" } }))
                                  dispatch(changeDefaultCommissionValues({ isActivity: true, value: e }))
                                }}
                                isMain
                                commission={{ uuid: "0", name: "Comissão-padrão", isCustom: true, commission: userForm.activitiesCommissionDefault }} 
                              />
                              {
                                activitiesCommissionForm.map((act, idx) => {
                                  return (
                                    <Entitycommissiontablerow
                                      key={`comissionRow-activity-${idx}-${act.uuid}`}
                                      hasEditPermission={hasEditPermission && !userForm.earnsDefaultCommissions.value}
                                      onChange={(id, e) => dispatch(onActivityCommissionChange({ idx, e }))}
                                      onChangeToggler={(e) => dispatch(onCommissionChangeToggler({ idx, value: e, isActivity: true, default: userForm.activitiesCommissionDefault.value }))}
                                      commission={{ uuid: act.uuid, name: act.name, isCustom: act.isCustom, commission: act.commission }}></Entitycommissiontablerow>
                                  )
                                })
                              }
                            
                            </>
                          }
                        </CustomCommissionTable>
                      },
                      {
                        title: i18n.t('entityForm.extrasCommissions'),
                        content: <CustomCommissionTable key="customCommissionTable2"
                          headerElements={[
                            <SorterComponent id="entityForm-extraCommissions-nameSorter" filterName={i18n.t('entityForm.extras')} onChange={() => { }}></SorterComponent>,
                            <div>{i18n.t('entityForm.isCustom')}</div>,
                            <SorterComponent id="entityForm-extraCommissions-nameSorter" filterName={i18n.t('entityForm.commissionValue')} onChange={() => { }}></SorterComponent>,
                            <div />,
                            <div />
                          ]}
                          count={0}
                          currentPage={-1}
                          elementsPerPage={0}
                          onPageChange={() => { }}>
                          {
                            userForm.earnsDefaultCommissions.value
                              ?
                            defaultExtrasCommissions.map((ext, idx) => {
                              return (
                                <Entitycommissiontablerow
                                  key={`comissions-extra-${idx}-${ext.uuid}`}
                                  id={`entityForm-extraCommission${idx}`}
                                  hasEditPermission={hasEditPermission && !userForm.earnsDefaultCommissions.value}
                                  onChange={(id, e) => dispatch(onExtraCommissionChange({ idx, e }))}
                                  onChangeToggler={(e) => dispatch(onCommissionChangeToggler({ idx, value: e, isActivity: false, default: '' }))}
                                  commission={{ uuid: ext.uuid, name: ext.name, isCustom: true, commission: ext.commission }}></Entitycommissiontablerow>
                              )
                            })
                              :
                            <>
                              <Entitycommissiontablerow
                                id={`entityForm-extraCommissionDefault`}
                                key={`comissionRow-extras-default`}
                                hasEditPermission={hasEditPermission && !userForm.earnsDefaultCommissions.value}
                                onClickAll={() => dispatch(setConfirmAllModal({ confirmAll: true, isApplyAllOnActivities: false }))}
                                onChange={(id, e) => {
                                  dispatch(onFormChange({ ...userForm, extrasCommissionDefault: { value: e, valid: true, message: "" } }))
                                  dispatch(changeDefaultCommissionValues({ isActivity: false, value: e }))
                                }}
                                isMain
                                commission={{ uuid: "0", name: "Comissão-padrão", isCustom: true, commission: userForm.extrasCommissionDefault }} 
                              />
                              {
                                extrasCommissionForm.map((ext, idx) => {
                                  return (
                                    <Entitycommissiontablerow
                                      id={`entityForm-extraCommission${idx}`}
                                      key={`comissionRow-extras-${idx}-${ext.uuid}`}
                                      hasEditPermission={hasEditPermission && !userForm.earnsDefaultCommissions.value}
                                      onChange={(id, e) => dispatch(onExtraCommissionChange({ idx, e }))}
                                      onChangeToggler={(e) => dispatch(onCommissionChangeToggler({ idx, value: e, isActivity: false, default: userForm.extrasCommissionDefault.value }))}
                                      commission={{ uuid: ext.uuid, name: ext.name, isCustom: ext.isCustom, commission: ext.commission }}></Entitycommissiontablerow>
                                  )
                                })
                              }
                            </>
                          }
                        </CustomCommissionTable>
                      }
                    ]}
                  />
                </Row>
              </>
            )
          }
        </SectionContainer>
      </>
    )
}

export default Comissions


const CustomCommissionTable = styled(TableContainer)`
   > table {
     > thead {
      > tr {
        th:last-child{
          display: none;
          width: 0%;
        }
     }
   }
`

const Row = styled(CRow)`
    --cui-gutter-y:0rem;
    margin-top:26px;
`