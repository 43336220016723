import * as React from "react"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width="20" height="16" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 0.833374H2C2.18667 0.833374 2.35333 0.906707 2.47333 1.02671L3.86 2.42004L4.39333 1.89337C4.66667 1.63337 5 1.50004 5.33333 1.50004H9.33333C9.66667 1.50004 10 1.63337 10.2733 1.89337L10.94 2.56004C11.2 2.83337 11.3333 3.16671 11.3333 3.50004V12.1667C11.3333 12.5203 11.1929 12.8595 10.9428 13.1095C10.6928 13.3596 10.3536 13.5 10 13.5H3.33333C2.97971 13.5 2.64057 13.3596 2.39052 13.1095C2.14048 12.8595 2 12.5203 2 12.1667V8.16671V7.50004V4.83337C2 4.50004 2.13333 4.16671 2.39333 3.89337L2.92 3.36004L1.72667 2.16671H0V0.833374ZM5.33333 2.83337V4.16671H9.33333V2.83337H5.33333ZM5.60667 6.83337L4.27333 5.50004H3.33333V6.44004L4.66667 7.77337V9.89337L3.33333 11.2267V12.1667H4.27333L5.60667 10.8334H7.72667L9.06 12.1667H10V11.2267L8.66667 9.89337V7.77337L10 6.44004V5.50004H9.06L7.72667 6.83337H5.60667ZM6 8.16671H7.33333V9.50004H6V8.16671Z" fill="#636F83"/>
        </svg>
    )
}

export default SvgComponent
