import styled from 'styled-components'

export const Wrapper = styled.div``

export const ButtonWrapper = styled.div`
  display:flex;
  justify-content:flex-end;
  align-items: center;
  margin-top: 24px;

  > button:nth-child(2) {
      margin-left: 16px;
      height: 33px;
      line-height: 0;
  }

`