/**
 * CustomModal component
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */

import React, { ReactNode, useRef } from 'react'
import * as icon from '@coreui/icons'
import CIcon from '@coreui/icons-react';
import { CForm } from '@coreui/react-pro';
import CustomCheckbox from '../CustomCheckbox/index'
import i18n from "i18next";
import * as Styles from './styles'

interface PropTypes {
    id?: string,
    warningName?: string,
    textAlign: "start" | "center" | "end",
    footerPosition: "flex-start" | "center" | "flex-end" | "space-between",
    children?: ReactNode,
    childrenForm?: ReactNode,
    warningType: "warning" | "message",
    buttonIcon?: boolean,
    buttonName?: string,
    buttonColor?: string
    withComment?: boolean,
    withForm?: boolean,
    withCheckbox?: boolean,
    checkboxText?: string,
    singleButton?: boolean,
    noButtons?: boolean,
    visible: boolean,
    onsubmit: Function,
    onclose: Function,
    width?: number
    size?: "xl" | "lg" | undefined
    hasValidation?: boolean
    modalTitleExtra?: JSX.Element
}

const CustomModal = ({ children, childrenForm, ...props }: PropTypes) => {

    //const [visible, setVisible] = useState(props.visible)
    const modalRef = useRef<any>()

    const handleClose = (e) => {
        if (modalRef && !modalRef.current.contains(e.target)) {
            //setVisible(false)
            props.onclose(e)
        }
    }

    return (
        <div onMouseDown={(e) => handleClose(e)}>
            <Styles.Modal size={props.size} width={props.width} visible={props.visible} >
                <div ref={modalRef}>
                    <Styles.ModalHeader onMouseUp={e => handleClose(e)} warningtype={props.warningType} closeButton={false}>
                        <Styles.ModalTitle warningtype={props.warningType} >
                            {props.warningName}
                            <Styles.TitleExtra>
                                {props.modalTitleExtra && props.modalTitleExtra}
                            </Styles.TitleExtra>
                        </Styles.ModalTitle>
                        <Styles.CIconClose warningtype={props.warningType} onClick={(e) => props.onclose(e)} height={16} icon={icon.cilX} size={"sm"} />
                    </Styles.ModalHeader>
                    <Styles.ModalBody onMouseUp={(e) => handleClose(e)} textalign={props.textAlign} warningtype={props.warningType}>
                        {children}
                        {
                            props.withComment && <Styles.TextArea id={`${props.id}-modalComment`} placeholder="inserir comentário" aria-label="With textarea" />
                        }
                        {
                            props.withCheckbox && <Styles.CheckboxWrapper> <CustomCheckbox id={`${props.id}-modalCheckBox`} text={props.checkboxText} /></Styles.CheckboxWrapper>
                        }
                        {
                            props.withForm &&
                            <CForm>
                                {childrenForm && <Styles.FormWrapper>{childrenForm}</Styles.FormWrapper>}
                                {
                                    props.singleButton && props.singleButton === true ?
                                        <Styles.FormFooter onMouseUp={(e) => !props.hasValidation ? props.onclose(e) : {}} footerposition={props.footerPosition}>
                                            <Styles.ConfirmButtonPrimary id={`${props.id}-confirm`} color={'primary'} onClick={(e) => props.onsubmit(e)}>
                                                {props.buttonName} {props.buttonIcon && <CIcon height={14} icon={icon.cilCheckAlt} size={"sm"} />}
                                            </Styles.ConfirmButtonPrimary>
                                        </Styles.FormFooter>
                                        :
                                        <Styles.FormFooter footerposition={props.footerPosition}>
                                            <Styles.CancelButton id={`${props.id}-cancel`} warningtype={props.warningType} variant="ghost" onClick={(e) => props.onclose(e)}>
                                                {i18n.t('common.cancelBtn')} {props.buttonIcon && <CIcon height={14} icon={icon.cilX} size={"sm"} />}
                                            </Styles.CancelButton>
                                            <Styles.ConfirmButton id={`${props.id}-remove`} warningtype={props.warningType} type="submit" variant="ghost" onClick={(e) => props.onsubmit(e)}>
                                                {props.buttonName ? props.buttonName : i18n.t('common.remove')} {props.buttonIcon && <CIcon height={14} icon={icon.cilCheckAlt} size={"sm"} />}
                                            </Styles.ConfirmButton>
                                        </Styles.FormFooter>
                                }
                            </CForm>
                        }
                    </Styles.ModalBody>
                    {
                        !props.withForm && !props.noButtons &&
                        <Styles.ModalFooter onMouseUp={(e) => props.onclose(e)} footerposition={props.footerPosition} warningtype={props.warningType}>
                            <Styles.CancelButton id={`${props.id}-cancel`} warningtype={props.warningType} variant="ghost" onClick={(e) => props.onclose(e)}>
                                {i18n.t('common.cancelBtn')} {props.buttonIcon && <CIcon height={14} icon={icon.cilX} size={"sm"} />}
                            </Styles.CancelButton>
                            <Styles.ConfirmButton id={`${props.id}-remove`} warningtype={props.warningType} variant="ghost" onClick={(e) => props.onsubmit(e)}>
                                {props.buttonName ? props.buttonName : i18n.t('common.remove')} {props.buttonIcon && <CIcon height={14} icon={icon.cilCheckAlt} size={"sm"} />}
                            </Styles.ConfirmButton>
                        </Styles.ModalFooter>
                    }
                </div>
            </Styles.Modal>
        </div>
    )
}
export default CustomModal