/**
 * Translation Container
 *
 * @author Hugo Fonseca <hfonseca@ubiwhere.com>
 * @author Carlos Silva <csilva@ubiwhere.com>
 */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actions, RootState } from 'store/rootSlices';

import HelpMessage from './message';
import { Wrapper } from './styles';

const Translation = ({ transKey, children }) => {
  const { apiTranslationsEdit } = useSelector((state: RootState) => state.App);

  const { updateApiTranslationKey } = actions.App;

  const dispatch = useDispatch();

  return (
    <Wrapper>
      {children}
      {apiTranslationsEdit && (
        <HelpMessage
          transKey={transKey}
          message={children}
          onUpdateKey={(value) => dispatch(updateApiTranslationKey(value))}
        />
      )}
    </Wrapper>
  );
};

export default Translation;