/**
 * Activitylisttablerow component
 *
 * @author Manuel Gil <mgi@ubiwhere.com>
 *
 *
 */

import React, { useEffect } from 'react'
import styled from 'styled-components'

import { TableRowPropTypes } from 'types/misc'
import theme from 'ui/theme'
import { CTableDataCell, CTableRow } from '@coreui/react-pro'
import CustomTextInput from 'shared/components/CustomTextInput'

type PropTypes = TableRowPropTypes & {
    id?: string,
    generateIslandTrips: boolean,
    passengerType: any
    disabled?: boolean,
}

const Passengertypetablerow = (props: PropTypes) => {

    useEffect(() => {

    }, [props])


    const {
        actions,
        generateIslandTrips,
        passengerType: {
            name,
            price,
            priceForIsland,
            isOn
        }
    } = props

    return (
        <CustomCTableRow>
            <CTableDataCell>{name}</CTableDataCell>
            <CTableDataCell>
                {
                    generateIslandTrips &&
                    <CustomTextInput
                        id={`${props.id}-priceForIsland`}
                        required={false}
                        disabled={isOn?.value ? !props.disabled ? false : true : true}
                        valid={priceForIsland?.valid}
                        value={priceForIsland?.value}
                        helpText={priceForIsland?.message}
                        icon='euro'
                        placeholder="Adicione uma lotação"
                        onChange={(e) => props.onClick(e, 'priceForIsland')}
                    />
                }
            </CTableDataCell>
            <CTableDataCell>
                <CustomTextInput
                    id={`${props.id}-price`}
                    required={false}
                    disabled={isOn?.value ? !props.disabled ? false : true : true}
                    valid={price?.valid}
                    value={price?.value}
                    helpText={price?.message}
                    icon='euro'
                    placeholder="Adicione um preço"
                    wrapperStyle={{ minWidth: '100px' }}
                    onChange={(e) => props.onClick(e, 'price')}
                />
            </CTableDataCell>
            <ActionsCell>
                {actions}
            </ActionsCell>
        </CustomCTableRow>
    )
}
export default Passengertypetablerow

const CustomCTableRow = styled(CTableRow)`
    max-height: 54px;
    background-color: ${theme().white};
    border-bottom: 1px solid ${theme().gray200Color};
  
    > td {
      vertical-align: middle;
      font-family: Inter;
      font-size: 14px;
      color: ${theme().gray700Color}
    }

    td:first-child {
        padding-left:15px;
        width:60%;
      }
  `

const ActionsCell = styled(CTableDataCell)`

  `
