import axios from 'axios'
import endpoints from 'api/endpoints'
import { decamelizeKeys } from 'humps'
import { ExtraFormValidations, ExtraFormValidationsEdit } from 'scenes/TripForms/Tripextraform/utils'
import { generateUrl, generateSortString } from 'utils'
import { toast } from 'react-toastify'
import { SortType } from 'types/misc'

/* import { generateUrl } from 'utils'
import { toast } from 'react-toastify'
import i18n from 'i18next' */

const GetExtras = (page: number, searchTerm: string, sort?: SortType) => {
    const searchTermQS = `${searchTerm ? `&search=${searchTerm}` : ''}`;

    let endpoint = `${endpoints.TRIPS.EXTRAS.GET_EXTRAS}?page=${page}${searchTermQS}`;
    if (sort && sort.field !== null && sort.direction !== null && sort.direction !== "") {
        endpoint += generateSortString(sort);
    }

    return axios.get(endpoint)
        .then(res => res.data)
        .catch(err => {
        })
}

const GetAllExtras = () => {
    return axios.get(endpoints.TRIPS.EXTRAS.GET_ALL_EXTRAS)
        .then(res => res.data)
        .catch(err => {
        })
}

const GetExtra = (id) => {
    const endpoint = generateUrl(endpoints.TRIPS.EXTRAS.GET_EXTRA, { id })
    return axios.get(endpoint)
        .then(res => res.data)
        .catch(err => {
        })
}

const UploadExtraIcon = (file, id) => {  
    const endpoint = generateUrl(endpoints.TRIPS.EXTRAS.UPLOAD_ICON, { id })

    let formData = new FormData()
    formData.append('icon', file ? file : '')

    return axios.post(endpoint, formData)
        .then(res => res.data)
        .catch(err => { throw err })
}

const CreateExtra = (params) => {
    const token = window.localStorage.getItem('access_token')

    let finalParams = {}
    Object.keys(ExtraFormValidations).forEach(valKey => {
        if (valKey === 'maxCapacity') {
            finalParams[valKey] = Number(params[valKey].value)
        } else if (valKey === 'icon') {
            return
        } else {
            finalParams[valKey] = params[valKey].value
        }
    })

    const packContent: any = Array.from([])
    if(params.isPack.value) {
        params.packContent.forEach(({ extra, quantity }) => {
            packContent.push({ extra, quantity })
        })
        finalParams['packContent'] = packContent
    }

    const decMLZParams = decamelizeKeys(finalParams)
    return axios.create({
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
        }
    }).post(endpoints.TRIPS.EXTRAS.CREATE_EXTRA, decMLZParams)
        .then(res => res.data)
        .catch(err => { throw err })
}

const CreateExtraPhoto = (params) => {

    const extraPhotoFormData = new FormData();
    Object.keys(params).forEach(valKey => {
        extraPhotoFormData.append(valKey, params[valKey])
    })

    return axios.post(endpoints.TRIPS.EXTRAS.CREATE_EXTRA_PHOTO, extraPhotoFormData)
        .then(res => res.data)
        .catch(err => toast.error("Erro ao adicionar foto"))
}

const EditExtra = (id, params) => {
    const token = window.localStorage.getItem('access_token')

    const endpoint = generateUrl(endpoints.TRIPS.EXTRAS.EDIT_EXTRA, { id })
    let finalParams = {}
    Object.keys(ExtraFormValidationsEdit).forEach(valKey => {
        if (valKey === 'maxCapacity') {
            finalParams[valKey] = Number(params[valKey].value)
        } else if (valKey === 'icon') {
            return
        } else {
            finalParams[valKey] = params[valKey].value
        }
    })

    const packContent: any = Array.from([])
    params?.packContent?.forEach(({ extra, quantity }) => {
        packContent.push({ extra, quantity })
    })
    finalParams['packContent'] = packContent

    const decMLZParams = decamelizeKeys(finalParams)

    return axios.create({
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
        }
    }).patch(endpoint, decMLZParams)
        .then(res => res.data)
        .catch(err => { throw err })
}

const EditExtraPhoto = (id, params) => {
    const endpoint = generateUrl(endpoints.TRIPS.EXTRAS.EDIT_EXTRA_PHOTOS, { id })

    const extraPhotoFormData = new FormData();
    Object.keys(params).forEach(valKey => {
        extraPhotoFormData.append(valKey, params[valKey])
    })

    return axios.patch(endpoint, extraPhotoFormData)
        .then(res => res.data)
        .catch(err => toast.error("Erro ao editar foto"))
}


export default {
    GetAllExtras,
    GetExtras,
    GetExtra,
    CreateExtra,
    CreateExtraPhoto,
    EditExtra,
    EditExtraPhoto,
    UploadExtraIcon
}