import styled from 'styled-components'
import theme from 'ui/theme'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > * {
    font-family: 'Inter';
  }

  > label {
    font-size: 14px;
    color: ${theme().darkColor};
    > span {
      color: ${theme().primary};
    }
  }
`

export const QRCode = styled.div`
  border-radius: 50%;
  width: 35px;
  height: 35px;
  background-color: ${theme().primary} !important; 
  margin-top: -15px;
  z-index: 1;
  border: 2px solid white;
  align-self: center;
  cursor: pointer;
  display:flex;
  justify-content: center;
  align-items:center;

  > svg {
    width: 100%;
    height: 100%;
  }

`

export const UserWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: inherit;
  height: inherit;

  > * {
    width: inherit;
    height: inherit;
  }
`

export const InteractionWrapper = styled.div`
  position: relative;
  cursor: pointer;
  height: 121px;
  width: 121px;
  border: 1px solid ${theme().gray200Color};
  border-radius: 50%;
  overflow: hidden;

  > img {
    width: inherit;
    height: inherit;
  }
`

export const DeleteButton = styled.div`
  position: absolute;
  width: inherit;
  height: inherit;
  display: flex;
  justify-content: center;
  align-items: center;

  > svg {
    opacity: 0%;
    transition: opacity 0.2s;
  }

  :hover {
    background-color: #FFFFFF70;
    transition: .3s ease;

    > svg {
      opacity: 100%;
    }
  }
`

export const UploadButton = styled.div`
  position: absolute;
  width: inherit;
  height: inherit;
  display: flex;
  justify-content: center;
  align-items: center;

  > svg {
    opacity: 0%;
    transition: opacity 0.2s;
  }

  :hover {
    background-color: #FFFFFF70;
    transition: .3s ease;

    > svg {
      opacity: 100%;
    }
  }
`

export const CustomAnchor = styled.a`
z-index:2
`