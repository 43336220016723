/**
 * Invoicingrequests scene
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import _ from 'lodash'
import { DateTime } from 'luxon'
// Store
import { actions, RootState } from 'store/rootSlices'
// Components
import SearchBox from 'shared/components/SearchBox'
import FilterDatePicker from 'shared/components/FilterDatePicker'
import FilterComponent from 'shared/components/FilterComponent'
import TableContainer from 'shared/components/TableContainer'
import SorterComponent from 'shared/components/SorterComponent'
import Requesttablerow from './components/Requesttablerow'
import Loadingcontainer from 'shared/components/Loadingcontainer'
// Icons
import Receipt from 'assets/icons/receipt'
import FileDownload from 'assets/icons/file_download'
import RefreshIcon from 'assets/icons/refresh'
import Ticket from 'assets/icons/ticket'
import Download from 'assets/icons/file_download';
// Utils & Configs
import config from 'config'
import { paginationCurrentPage, paginationElementsCount } from 'utils'
import { presetsLists } from 'shared/components/FilterDatePicker/utils'
import { hasPermission } from 'permissions'
// Types
import { InvoiceType, SyncStatus, InvoiceListItem } from 'types/invoicing/invoices'
// Styles
import {  DownloadButton, CustomLink, DownloadWrapper } from './styles'
import { ControlsContainer, Controls1, Controls2, CustomWrapper } from 'shared/genericStyledComponents'
import theme from 'ui/theme'

const InvoicingRequests = ({ t, i18n }) => {

  const {
    page,
    invoices,
    totalInvoices,
    loading,
    sort,
    filter,
    syncFilter,
    searchTerm,
    hasDateParams,
    startDate,
    endDate,
    exportLoading,
    syncLoading
  } = useSelector((state: RootState) => state.InvoicingRequests)

  const {
    onMount,
    onUnmount,
    setSearchTerm,
    setPage,
    setSyncFilter,
    setFilter,
    setSort,
    setDatesInterval,
    getInvoices,
    exportInvoices,
    syncInvoice
  } = actions.InvoicingRequests
  //const { api_t } = actions.App

  const { ELEMENTS_PER_PAGE, PERMISSIONS } = config
  const { permissions } = useSelector((state: RootState) => state.App);
  const dispatch = useDispatch()
  const dbDispatch = _.debounce(dispatch, 500);
  const history = useHistory()

  useEffect(() => {

    dispatch(onMount(history))
    return () => {
      dispatch(onUnmount())
    }
  }, [dispatch, onMount, onUnmount, history]);

  return (
    <CustomWrapper>
      <ControlsContainer>
        <Controls1>
          <SearchBox
            id="invoicingRequests-searchBox"
            defaultValue={searchTerm}
            onSubmit={(term) => {
              if(term !== '') {
                let e = {
                    endDate: null,
                    startDate: null
                }
                dispatch(setDatesInterval({ e, history }))
              } else {
                let e = {
                    endDate: new Date(),
                    startDate: new Date()
                }
                dispatch(setDatesInterval({ e, history }))
              }
              let params = new URLSearchParams(history.location.search);
              term !== "" ? params.set('search', term) : params.delete('search')
              history.replace({ search: (params).toString() })
              dbDispatch(setSearchTerm(term))
            }}
          />
          <div style={{ paddingTop: '6px' }}>
            <FilterDatePicker
              showMonthYearDropdown
              presets={presetsLists().invoicingRequests}
              filterName={i18n.t('common.today')}
              range
              noDate={startDate === '' && endDate === '' ? true : false}
              reRender={hasDateParams}
              defaultValue={
                {
                  startDay: startDate ? DateTime.fromISO(startDate).toJSDate() : new Date(),
                  endDay: endDate ? DateTime.fromISO(endDate).toJSDate() : new Date(),
                }
              }
              onChange={(e) => {
                dispatch(setDatesInterval({ e, history }))
                dispatch(getInvoices())
              }}
            />
          </div>
          <FilterComponent
            id='invoicingRequests-typeFilter'
            filterName={i18n.t('invoicingRequests.allTypes')}
            defaultOption={
              Object.keys(InvoiceType).map((key) => {
                return { value: InvoiceType[key], label: InvoiceType[key] }
              }).find(v => v.value === filter)
            }
            options={Object.keys(InvoiceType).map((key) => {
              return { value: InvoiceType[key], label: InvoiceType[key] }
            })}
            icon={<Receipt />}
            onChange={(e) => {
              let params = new URLSearchParams(history.location.search);
              e.value !== "" ? params.set('type', e.value) : params.delete('type')
              history.replace({ search: (params).toString() })
              dispatch(setFilter(e.value))
            }}
          />
          <FilterComponent
            id='invoicingRequests-syncStatusFilter'
            maxWidth='220px'
            filterName={i18n.t('invoicingRequests.syncStatus')}
            defaultOption={
              Object.keys(SyncStatus).map((key) => {
                return { value: SyncStatus[key], label: SyncStatus[key] }
              }).find(v => v.value === syncFilter)
            }
            options={Object.keys(SyncStatus).map((key) => {
              let label = !SyncStatus[key]  ?  '' : t(`invoicingRequests.${SyncStatus[key]}`)
              return { value: SyncStatus[key], label}
            })}
            icon={<Receipt />}
            onChange={(e) => {
              let params = new URLSearchParams(history.location.search);
              e.value !== "" ? params.set('sync', e.value) : params.delete('sync')
              history.replace({ search: (params).toString() })
              dispatch(setSyncFilter(e.value))
            }}
          />
        </Controls1>
        <Controls2>
          {
            hasPermission(permissions, PERMISSIONS.INVOICING.EXPORT_INVOICE) 
              &&
              <DownloadWrapper onClick={() => {
                if(!exportLoading) dispatch(exportInvoices())
              }}>
                  <Download width={30} height={30} />
              </DownloadWrapper>
          }
          
        </Controls2>
      </ControlsContainer>
      {
        loading ?
          <Loadingcontainer />
          :
          <TableContainer
            count={paginationElementsCount(totalInvoices)}
            currentPage={paginationCurrentPage(totalInvoices, page)}
            elementsPerPage={ELEMENTS_PER_PAGE}
            onPageChange={(pg) => { dispatch(setPage(pg)) }}
            headerElements={[
              t('common.reserveNumber'),
              t('common.invoiceNumber'),
              t('common.date'),
              t('invoicingRequests.type'),
              <SorterComponent
                sort={sort}
                active={sort.field === "amount"}
                id="invoicingRequests-valueSorter"
                filterName={t('common.value')}
                onChange={(dir) => { dispatch(setSort({ field: "amount", direction: dir })) }}
              />,
              <SorterComponent
                sort={sort}
                active={sort.field === "vat"}
                id="invoicingRequests-nifSorter"
                filterName={t('invoicingRequests.nif')}
                onChange={(dir) => { dispatch(setSort({ field: "vat", direction: dir })) }}
              />,
              t('invoicingRequests.syncStatus'),
              t('common.actions'),
            ]}
          >
            {
              invoices.map((inv: InvoiceListItem, idx: number) => {
                return (
                  <Requesttablerow
                    id={inv.id}
                    invoice={inv}
                    actions={
                      <React.Fragment>
                        {/* <div onClick={() => {
                          if (!inv.file) {
                            dispatch(clearModals())
                            dispatch(setShowAddInvoiceModal({ show: true, id: inv.id, isCompleted: false }));
                            dispatch(getInvoice({ id: inv.id }))
                          }
                        }}> */}
                          
                        <DownloadButton onClick={() => dispatch(actions.App.navigateTo(`/viagens/reservas/editar-reserva/${inv.booking.uuid}`))}>
                          <Ticket className='pointer'  fill={theme().gray700Color} />
                        </DownloadButton>
                        <DownloadButton 
                          onClick={() => {
                            if(!syncLoading.isLoading && (!syncLoading.id || syncLoading.id !== inv.id)) {
                              return inv.syncStatus !== SyncStatus.SUCCESS ? dispatch(syncInvoice({id: inv.id, history})) : null
                            } 
                          }}
                        >
                          <RefreshIcon 
                            cursor={ (syncLoading.isLoading && syncLoading.id === inv.id) ? 'default' : inv.syncStatus !== SyncStatus.SUCCESS ? 'pointer' : 'default'} 
                            fill={ (syncLoading.isLoading && syncLoading.id === inv.id) ? theme().gray100Color : inv.syncStatus !== SyncStatus.SUCCESS ? theme().gray900Color : theme().gray300Color} 
                          />
                        </DownloadButton>
                        <CustomLink href={inv.syncPdf}>
                          <FileDownload cursor={inv.syncPdf ? 'pointer' : 'default'} fill={inv.syncPdf ? theme().gray900Color : theme().gray300Color} />
                        </CustomLink>

                        {/* </div> */}
                      </React.Fragment>
                    } />
                )
              })
            }
          </TableContainer>

      }
    </CustomWrapper>
  )
}

export default InvoicingRequests