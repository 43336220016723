/**
 * Invoicedetails scene sagas
 *
 * @author Stephane Ribeiro <sgribeiro@ubiwhere.com>
 *
 *
 */
import { takeLatest, putResolve, call, select } from 'redux-saga/effects'
import { AnyAction, PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify'
import i18n from 'i18next';

import { EditInvoiceForm } from 'types/invoicing/statements';
import { Invoice } from 'types/invoicing/invoices';
import { actions, RootState } from 'store/rootSlices'
import * as Check from 'validations';
import { InvoiceDetailsValidationsEdit, SubmitSagaPayload, CheckIfModalSagaPayload } from '../utils'
import API from 'api'
import { hasPermission } from 'permissions'
import config from 'config';
const { PERMISSIONS } = config

const inID = (state: RootState): string => state.InvoiceDetails.invoice.id

function* onMountSaga({ payload }: PayloadAction<number>) {
  yield putResolve(actions.Invoicedetails.setLoading(true))
  const invoiceId = payload
  const { permissions } = yield select((state: RootState) => state.App);

  try {
    const invoice: Invoice = yield call(API.Invoicing.GetInvoice, invoiceId)
    if (invoice) {
      yield putResolve(actions.Invoicedetails.setHasEditPermission(hasPermission(permissions, PERMISSIONS.INVOICING.EDIT_INVOICE)))
      yield putResolve(actions.Invoicedetails.setInvoice(invoice))
      yield putResolve(actions.Invoicedetails.populateForm(invoice))
      yield putResolve(actions.Invoicedetails.setEntries(invoice.entries))
      yield putResolve(actions.Invoicedetails.setBookingUid(invoice.booking.uid))
      yield putResolve(actions.Invoicedetails.setBookingUuid(invoice.booking.uuid))
      yield putResolve(actions.Invoicedetails.setLoading(false))
    }
  } catch (error) {
    toast.error(i18n.t("invoiceDetailsForm.mountErrorSaga"));
  } finally {
    yield putResolve(actions.Invoicedetails.setLoading(false))
  }
}

function* onUnmountSaga() {}

function* onSubmitSaga({ payload }: PayloadAction<SubmitSagaPayload>) {
  yield putResolve(actions.Invoicedetails.setSubmitButtonDisabled(true))
    
    const invoiceID = yield select(inID)
    
    try {
      const finalForm: EditInvoiceForm = { 
        email: payload.invoiceForm.email.value,
        phone: payload.invoiceForm.phone.value,
        vat: payload.invoiceForm.vat.value,
        customerName: payload.invoiceForm.customerName.value,
        country: payload.invoiceForm.country.value
      }

      const success: Invoice = yield call(API.Invoicing.EditInvoiceDetails, invoiceID, finalForm)
      if (success) {
        yield putResolve(actions.Invoicedetails.setInvoice(success))
        yield putResolve(actions.Invoicedetails.toggleEditInvoiceModal(false))
        yield putResolve(actions.App.navigateTo(`/faturacao/detalhes/${invoiceID}`))
        yield putResolve(actions.Invoicedetails.setSubmitButtonDisabled(false))

        toast.success(i18n.t("toasts.invoiceEditSuccess"))
      }
    } catch (error) {
      toast.warning(i18n.t("toasts.invoiceEditError"))
    }

  yield putResolve(actions.Invoicedetails.setSubmitButtonDisabled(false))
}

function* checkIfModalSaga({ payload }: PayloadAction<CheckIfModalSagaPayload>) {
  const initialValues = payload.invoice
  const editedValues = payload.invoiceForm

  const validation = Check.checkValidationAlternative(payload.invoiceForm, InvoiceDetailsValidationsEdit)
  if (validation.invalid === true) {
    const updateValidationMessages = Check.setAndCheckValidationAlternative(payload.invoiceForm, payload.invoiceForm, InvoiceDetailsValidationsEdit)
    yield putResolve(actions.Invoicedetails.onFormChange(updateValidationMessages))
    yield putResolve(actions.Invoicedetails.setSubmitButtonDisabled(false))
    return toast.error(i18n.t("toasts.invalidFields"));
  }
  
  try {
    const formName = editedValues.customerName.value
    const formVAT = editedValues.vat.value

    if (formName !== initialValues.customerName || formVAT !== initialValues.vat ) {
      yield putResolve(actions.Invoicedetails.toggleEditInvoiceModal(true))
    } else {
      yield putResolve(actions.Invoicedetails.onSubmit({invoiceForm:{ ...editedValues}}))
    }
      
  } catch (error) {
    toast.warning(i18n.t("toasts.invoiceEditError"))
  }

}

function* syncInvoiceSaga({ payload }: AnyAction) {
  yield putResolve(actions.Invoicedetails.setSyncLoading(true))
  
  try {
    let invoice = yield call(API.Invoicing.SyncInvoice, payload)

    if(invoice) {
      yield putResolve(actions.Invoicedetails.setInvoice(invoice))
      yield putResolve(actions.Invoicedetails.populateForm(invoice))
      yield putResolve(actions.Invoicedetails.setEntries(invoice.entries))
      yield putResolve(actions.Invoicedetails.setBookingUid(invoice.booking.uid))
      yield putResolve(actions.Invoicedetails.setBookingUuid(invoice.booking.uuid))
      toast.success(i18n.t("toasts.syncRequestSuccess"))
    }

  } catch (error) {
    toast.error(i18n.t("toasts.syncRequestError"))
  } finally {
    yield putResolve(actions.Invoicedetails.setSyncLoading(false))
  }
}

export default function* watcherSignin() {
  yield takeLatest('Invoicedetails/onMount', onMountSaga)
  yield takeLatest('Invoicedetails/onUnmount', onUnmountSaga)
  yield takeLatest('Invoicedetails/onSubmit', onSubmitSaga)
  yield takeLatest('Invoicedetails/checkIfModal', checkIfModalSaga)
  yield takeLatest('Invoicedetails/syncInvoice', syncInvoiceSaga)
}


