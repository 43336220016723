/**
 * Sectioncontainer component
 *
 * @author João Dias <jdias@ubiwhere.com>
 *
 *
 */

import React from 'react'
import { Title, TitleBox, ContainerBox } from 'shared/genericStyledComponents';
import { Wrapper, TitleLabelBox, Tooltip, TooltipMainText, CustomSpan } from './styles';

type PropTypes = React.PropsWithChildren<{
  title?: String,
  underTitle?: JSX.Element,
  titleLabel?: JSX.Element,
  extra?: JSX.Element,
  style?: React.CSSProperties,
  required?: boolean
  className?: string
  titleTooltip?: JSX.Element
}>

const SectionContainer = (props: PropTypes) => {
  return (
    <Wrapper className={props.className} style={props.style}>
      <TitleBox>
        <div>
          <TitleLabelBox>
            {/* <Title>{props.title ? props.title : ""}<span>{!!props.required ? "*" : ""}</span></Title> */}
            <Title>
              {
                props.titleTooltip && props.title ? (
                  <Tooltip>
                    <TooltipMainText>
                      {props.title}
                    </TooltipMainText>
                    <CustomSpan>
                      {props.titleTooltip}
                    </CustomSpan>
                  </Tooltip>
                ) : !props.titleTooltip && props.title ? (
                  props.title
                ) : ""
              
              }
              <span>{!!props.required ? "*" : ""}</span>
            </Title>
            { props.titleLabel && props.titleLabel}
          </TitleLabelBox>
          { props.underTitle && props.underTitle}
        </div>
        <React.Fragment>
          {props.extra ? props.extra : null}
        </React.Fragment>
      </TitleBox>
      <ContainerBox>
        {props.children}
      </ContainerBox>
    </Wrapper>
  )
}

export default SectionContainer