/**
 * Tripbookings scene sagas
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */
import { takeLatest, putResolve, call, select } from 'redux-saga/effects'
import API from 'api'
import { actions, RootState } from 'store/rootSlices'
import { toast } from 'react-toastify'
import i18n from 'i18next';
import { AnyAction } from '@reduxjs/toolkit';
import { Interval } from 'luxon';
import { TripBookingsList } from 'types/trips/bookings';

function* onMountSaga({ payload }: AnyAction) {
    yield getURLParamsSaga(payload);
    yield getBookingsSaga();
    yield fetchPartnersSaga()
}

function* getBookingsSaga() {
    try {
        const { page, sort, hasIssuesFilter, startDate, endDate, activityFilter, departureFilter, arrivalFilter, tripDateView, searchTerm, partnerFilter, statusFilter } = yield select((state: RootState) => state.TripBookings);

        yield putResolve(actions.TripBookings.setLoading(true));

        const bookings: TripBookingsList = yield call(
            API.Bookings.GetBookings,
            page,
            sort,
            tripDateView ? startDate : null,
            tripDateView ? endDate : null,
            !tripDateView ? startDate : null,
            !tripDateView ? endDate : null,
            activityFilter,
            departureFilter,
            arrivalFilter,
            searchTerm,
            partnerFilter,
            statusFilter,
            hasIssuesFilter
        );

        if (bookings) {
            yield putResolve(actions.TripBookings.setBookings(bookings.results))
            yield putResolve(actions.TripBookings.setTotalBookings(bookings.count))
        }
    } catch (error) {
        toast.error(i18n.t("toasts.bookingsGetError"))
    } finally {
        yield putResolve(actions.TripBookings.setLoading(false));
    }
}

function* getBookingTicketSaga({ payload }: AnyAction) {

    const { bookings } = yield select((state: RootState) => state.TripBookings);

    try {
        const ticket = yield call(
            API.Bookings.GetBookingTicket,
            payload.id,
        );

        if (ticket?.pdf !== null) {
            yield putResolve(actions.TripBookings.setBookingTicketPdf({
                id: payload.id,
                bookings,
                ticket: ticket.pdf
            }))

            if (payload.ticketLinkRef.current) {
                payload.ticketLinkRef.current.click()
            }
        }
    } catch (error) {
        toast.error(i18n.t("toasts.bookingTicketGetError"))
    }
}


function* getURLParamsSaga(payload: AnyAction) {

    let params = new URLSearchParams(payload.location.search)

    const {
        hasDateParams,
        activityFilter,
        departureFilter,
        arrivalFilter,
        searchTerm,
        startDate,
        endDate,
        partnerFilter,
        statusFilter,
        hasIssuesFilter
    } = yield select((state: RootState) => state.TripBookings);

    if (params.toString() !== "") {
        yield putResolve(actions.TripBookings.populateFiltersOnMount({
            activityFilter: params.get('activity') ?? "",
            departureFilter: params.get('departure') ?? "",
            hasIssuesFilter: params.get('hasIssues') ?? "",
            arrivalFilter: params.get('arrival') ?? "",
            partnerFilter: params.get('partner') ?? "",
            searchTerm: params.get('search') ?? "",
            statusFilter: params.get('status') ?? "",
            ...(!params.get('search') ? { startDate: params.get('startDate') ?? new Date().toISOString().slice(0, 10) } : { startDate: null }),
            ...(!params.get('search') ? { endDate: params.get('endDate') ?? new Date().toISOString().slice(0, 10) } : { endDate: null }),
        }))
        yield putResolve(actions.TripBookings.setHasDateParams(!hasDateParams));

    }
    else {
        //state.hasDateParams has to be different from the last known state.hasDateParams to force a reRender on datepicker
        //this reRender must be done so the datepicker changes its label
        yield putResolve(actions.TripBookings.setHasDateParams(!hasDateParams));
        params.set('activity', activityFilter)
        params.set('departure', departureFilter)
        params.set('arrival', arrivalFilter)
        params.set('search', searchTerm)
        params.set('startDate', startDate)
        params.set('endDate', endDate)
        params.set('partner', partnerFilter)
        params.set('hasIssues', hasIssuesFilter)
        params.set('status', statusFilter)
        payload.replace({ search: (params).toString() })
    }
}

function* setDatesIntervalSaga({ payload }: AnyAction) {

    if (payload.e.startDate && payload.e.endDate) {
        let interval = Interval.fromDateTimes(payload.e.startDate, payload.e.endDate);
        const arr = Array.from(days(interval))

        let params = new URLSearchParams(payload.history.location.search);
        payload.e.startDate !== "" ? params.set('startDate', arr[0]?.toISO().slice(0, 10)) : params.delete('startDate')
        payload.e.endDate !== "" ? params.set('endDate', arr[arr.length - 1]?.toISO().slice(0, 10)) : params.delete('endDate')
        payload.history.replace({ search: (params).toString() })

        yield putResolve(actions.TripBookings.setStartDate(arr[0]?.toISO().slice(0, 10)))
        yield putResolve(actions.TripBookings.setEndDate(arr[arr.length - 1]?.toISO().slice(0, 10)))
    } else {
        let params = new URLSearchParams(payload.history.location.search);
        params.delete('startDate')
        params.delete('endDate')
        payload.history.replace({ search: (params).toString() })

        yield putResolve(actions.TripBookings.setStartDate(""))
        yield putResolve(actions.TripBookings.setEndDate(""))
    }
}

function* onUnmountSaga() {
    yield putResolve(actions.TripBookings.setLoading(true));
    yield putResolve(actions.TripBookings.setBookings([]))
    yield putResolve(actions.TripBookings.setTotalBookings(0))
}

function* days(interval) {
    let cursor = interval.start.startOf("day");

    while (cursor <= interval.end) {
        yield cursor;
        cursor = cursor.plus({ days: 1 });
    }
}

function* exportBookingsSaga() {
    yield putResolve(actions.TripBookings.setExportLoading(true));

    const { 
        tripDateView, 
        startDate, 
        endDate 
    } = yield select((state: RootState) => state.TripBookings);

    try {
      const file = yield call(API.Bookings.ExportBookings, tripDateView , startDate, endDate )
      
      if (file) {
        yield putResolve(actions.TripBookings.downloadBookings(file))
        toast.success(i18n.t("toasts.downloadBookingsListSuccess"))
      }
  
    } catch (error) {
      toast.error(i18n.t("toasts.downloadBookingsListError"))
    } finally {
        yield putResolve(actions.TripBookings.setExportLoading(false));
    }
  }

function* searchPartnersSaga() {
    try {
        const { searchPartnerText } = yield select((state: RootState) => state.TripBookings);

        let partners = yield call(API.Entities.GetEntities, 1, searchPartnerText, {direction: null,field: null});
        if (partners && partners.results.length > 0) {
            yield putResolve(actions.TripBookings.setSearchPartnerResults(partners.results))
        } else {
            yield putResolve(actions.TripBookings.setPartnerFilter(""))
            yield putResolve(actions.TripBookings.getBookings())
        }
    }
    catch {
        toast.error(i18n.t("toasts.customersGetError"))
    }
}

function* fetchPartnersSaga() {
    try {
        const { searchPartnerText, partnersPage, partners } = yield select((state: RootState) => state.TripBookings);

        let newPartners = yield call(API.Entities.GetEntities, partnersPage, searchPartnerText, {direction: null,field: null});
        if (newPartners && newPartners.results.length > 0) {
            yield putResolve(actions.TripBookings.setPartners({partners, newPartners: newPartners.results}))

            if(newPartners.next) {
                yield putResolve(actions.TripBookings.setPartnersPage(partnersPage + 1))
            } else {
                yield putResolve(actions.TripBookings.setIsPartnersLastPage(true))
            }
        }
    }
    catch {
        toast.error(i18n.t("toasts.customersGetError"))
    }
}

export default function* watcherSignin() {
    yield takeLatest('Tripbookings/onMount', onMountSaga);
    yield takeLatest('Tripbookings/onUnmount', onUnmountSaga);
    yield takeLatest('Tripbookings/getBookings', getBookingsSaga);
    yield takeLatest('Tripbookings/setSort', getBookingsSaga);
    yield takeLatest('Tripbookings/setDatesInterval', setDatesIntervalSaga);
    yield takeLatest('Tripbookings/setActivityFilter', getBookingsSaga);
    yield takeLatest('Tripbookings/setDepartureFilter', getBookingsSaga);
    yield takeLatest('Tripbookings/setStatusFilter', getBookingsSaga);
    yield takeLatest('Tripbookings/setPartnerFilter', getBookingsSaga);
    yield takeLatest('Tripbookings/setArrivalFilter', getBookingsSaga);
    yield takeLatest('Tripbookings/setTripDateView', getBookingsSaga);
    yield takeLatest('Tripbookings/setHasIssuesFilter', getBookingsSaga);
    yield takeLatest('Tripbookings/setSearchTerm', getBookingsSaga);
    yield takeLatest('Tripbookings/setPage', getBookingsSaga);
    yield takeLatest('Tripbookings/getBookingTicket', getBookingTicketSaga);
    yield takeLatest('Tripbookings/getURLParams', getURLParamsSaga);
    yield takeLatest('Tripbookings/exportBookings', exportBookingsSaga)
    yield takeLatest('Tripbookings/setSearchPartnerText', searchPartnersSaga)
    yield takeLatest('Tripbookings/fetchPartners', fetchPartnersSaga)
}


