import * as React from "react"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
            <path d="M12 7.5V3.5H2V21.5H22V7.5H12ZM6 19.5H4V17.5H6V19.5ZM6 15.5H4V13.5H6V15.5ZM6 11.5H4V9.5H6V11.5ZM6 7.5H4V5.5H6V7.5ZM10 19.5H8V17.5H10V19.5ZM10 15.5H8V13.5H10V15.5ZM10 11.5H8V9.5H10V11.5ZM10 7.5H8V5.5H10V7.5ZM20 19.5H12V17.5H14V15.5H12V13.5H14V11.5H12V9.5H20V19.5ZM18 11.5H16V13.5H18V11.5ZM18 15.5H16V17.5H18V15.5Z" fill="#636F83" />
        </svg>

    )
}

export default SvgComponent
