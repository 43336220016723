/**
 * Passengercontrol scene
 *
 * @author ManuelGil <mgil@ubiwhere.com>
 *
 *
 */
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { lowerFirst } from 'lodash'
// Store
import { actions, RootState } from 'store/rootSlices'
// Components
import FilterDatePicker from 'shared/components/FilterDatePicker'
import SearchBox from 'shared/components/SearchBox';
import TableContainer from 'shared/components/TableContainer';
import Passengercontrollisttablerow from './components/Passengercontrollisttablerow';
import Passengerlisttablerow from './components/Passengerlisttablerow';
import CustomCheckbox from 'shared/components/CustomCheckbox';
import FilterComponent from 'shared/components/FilterComponent';
import SorterComponent from 'shared/components/SorterComponent';
import Loadingcontainer from 'shared/components/Loadingcontainer'
import CustomModal from 'shared/components/CustomModal';
import Tripmodal from './components/Tripmodal/index'
// Icons
import Feed from 'assets/icons/feed';
import Boarding from 'assets/icons/login'
import Place from 'assets/icons/place'
// Utils & Configs
import config from 'config'
import { paginationCurrentPage, paginationElementsCount } from 'utils'
// Styles
import theme from 'ui/theme';
import { Controls1, CustomControlsContainer } from 'shared/genericStyledComponents'
import {
  Wrapper,
  Controls2,
  FilterControls,
  Title
} from './styles'

const Passengercontrol = ({ t, i18n }) => {

  const { allLocals } = useSelector((state: RootState) => state.TripsConfiguration);

  const {
    showPassengerTypes,
    boardingFilter,
    stopFilter,
    page,
    startDate,
    loading,
    sort,
    checkins,
    totalCheckins,
    checkinsCounter,
    searchTerm,
    showWarningModal,
    warningModalData,
    tripModal
  } = useSelector((state: RootState) => state.PassengerControl)

  const {
    onMount,
    onUnmount,
    setShowPassengerTypes,
    setBoardingFilter,
    setStopFilter,
    setPage,
    setDate,
    setSort,
    getCheckins,
    setSearchTerm,
    setShowWarningModal,
    setNewCheckLocation,
    setOutboundSelected,
    toggleTripModal
  } = actions.PassengerControl

  const { ELEMENTS_PER_PAGE } = config
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {

    dispatch(onMount(history))
    return () => {
      dispatch(onUnmount())
    }
  }, [dispatch, onMount, onUnmount, history]);

  return (
    <Wrapper>
      <CustomControlsContainer>
        <Controls1>
          <div>
            <SearchBox
              id="tripsConfig-searchBox"
              defaultValue={searchTerm}
              onSubmit={(term) => {
                let params = new URLSearchParams(history.location.search);
                term !== "" ? params.set('search', term) : params.delete('search')
                history.replace({ search: (params).toString() })
                dispatch(setSearchTerm(term))
              }}
            />
          </div>
          <div style={{ paddingTop: '6px' }}>
            <FilterDatePicker filterName={i18n.t('tripPlannings.today')}
              defaultValue={{ startDay: startDate ? startDate : new Date() }}
              showMonthYearDropdown
              onChange={(e) => {
                if (e.startDate) {
                  dispatch(setDate(e.startDate)) //clears bookings array and resets page number
                  dispatch(getCheckins())
                }
              }} />
          </div>
        </Controls1>
      </CustomControlsContainer>
      <Title>{i18n.t('passengerControl.passengerControl')}</Title>
      {
        loading ? <Loadingcontainer /> :
          <TableContainer
            className='pointer'
            count={5}
            elementsPerPage={12}
            currentPage={-1}
            onPageChange={pg => { }}
            onClickHeaderColumn={(stop) => {
              const stopExists = allLocals.find(l => l.shortName === stop.target.innerText)
              if (stopExists) {
                dispatch(setStopFilter({ stop, locals: allLocals, fromTableHeader: true }));
                dispatch(getCheckins())
              }
            }}
            headerElements={[
              i18n.t('passengerControl.boarding')]
              .concat(allLocals.map(pt => { return pt.shortName }))
              .concat(['Total'])}
          >
            {
              allLocals.filter(l => !l.isIsland).map(l => {
                return (
                  <Passengercontrollisttablerow
                    onBoardingClick={(boardingId, boardingName) => { dispatch(setBoardingFilter({ boardingId, boardingName })); dispatch(getCheckins()) }}
                    isBoardingStop={true}
                    stop={l}
                    boardingCheckinsCounter={checkinsCounter?.[lowerFirst(l.shortName)]}
                  />
                )
              })
            }
            <Passengercontrollisttablerow
              isBoardingStop={false}
              totalCheckinsCounter={checkinsCounter}
            />
          </TableContainer>
      }
      <CustomControlsContainer marginbottom={0}>
        <Controls1>
          <div>
            <Title>{i18n.t('passengerControl.passengerList')}</Title>
          </div>
          <FilterControls>
            <FilterComponent
              id='tripPlannings-departureFilter'
              defaultOption={boardingFilter}
              filterName={i18n.t('passengerControl.boardingsFilter')}
              options={allLocals?.filter(l => !l.isIsland).map((l) => {
                return (
                  {
                    label: l.name,
                    value: l.id.toString()
                  }
                )
              })}
              icon={<Boarding />}
              onChange={(e) => {
                dispatch(setBoardingFilter({ boardingId: e.value, boardingName: e.label }));
                dispatch(getCheckins())
              }} />
            <FilterComponent
              id='tripPlannings-departureFilter'
              defaultOption={stopFilter}
              filterName={i18n.t('passengerControl.stopsFilter')}
              options={allLocals?.map((l) => {
                return (
                  {
                    label: l.name,
                    value: l.id.toString()
                  }
                )
              })}
              icon={<Place />}
              onChange={(e) => {
                dispatch(setStopFilter({ stopId: e.value, stopName: e.label, fromTableHeader: false }));
                dispatch(getCheckins())
              }} />
          </FilterControls>
        </Controls1>
        <Controls2>
          <CustomCheckbox
            id='passengerControl-passengerControlTitle'
            wrapperStyle={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '-25px', fontSize: '20px' }}
            checked={showPassengerTypes}
            onClick={e => { dispatch(setShowPassengerTypes(!showPassengerTypes)) }}
            text={i18n.t('passengerControl.showPassengerTypes')}
            switch
          />
        </Controls2>
      </CustomControlsContainer>
      {
        loading ?
          <Loadingcontainer />
          :
          <TableContainer
            count={paginationElementsCount(totalCheckins)}
            currentPage={paginationCurrentPage(totalCheckins, page)}
            elementsPerPage={ELEMENTS_PER_PAGE}
            onPageChange={pg => { dispatch(setPage(pg)) }}
            headerElements={[
              <SorterComponent
                id="passengerControl-numberSorter"
                sort={sort}
                active={sort.field === "uid"}
                filterName={t('passengerControl.number')}
                onChange={(dir) => { dispatch(setSort({ field: "uid", direction: dir })) }}
              />,
              <SorterComponent
                id="passengerControl-customerSorter"
                sort={sort}
                active={sort.field === "customer"}
                filterName={t('passengerControl.customer')}
                onChange={(dir) => { dispatch(setSort({ field: "customer", direction: dir })) }}
              />,
              <SorterComponent
                id="passengerControl-paxSorter"
                filterName={'PAX'}
                onChange={() => { }}
              />,
              i18n.t('passengerControl.currentStop'),
              i18n.t('common.actions')]
            }
          >
            {

              checkins.map((ch, idx) => {
                return (
                  <Passengerlisttablerow
                    id={`passengerControl-passenger${idx}`}
                    checkin={ch}
                    trip={ch} //just for demo purposes
                    onClick={() => { }}
                    actions={
                      <React.Fragment>
                        <Feed fill={`${theme().secondaryColor}`} />
                        {/* <Ticket onClick={(e) => dispatch(navigateTo(`viagens/reservas/editar-reserva/${ch.booking?.id}`))} /> */}
                      </React.Fragment>
                    }
                  />
                )
              })
            }
          </TableContainer>
      }

      <CustomModal
        id='userForm-deleteUserModal'
        visible={showWarningModal}
        warningType='warning'
        warningName='Aviso'
        buttonIcon={false}
        footerPosition='center'
        textAlign='center'
        buttonName={i18n.t('common.confirm')}
        children={<p>Tem a certeza que pretende fazer o checkout em Faro?</p>}
        onclose={e => dispatch(setShowWarningModal(false))}
        onsubmit={e => dispatch(setNewCheckLocation({
          id: warningModalData.id, 
          status: warningModalData.status, 
          location: warningModalData.location,
          outbound: warningModalData.outbound,
          checkinId: warningModalData.checkinId,
          checkoutId: warningModalData.checkoutId
        }))} 
      />

      {
        tripModal.isVisible && (
          <Tripmodal 
            onClose={() => {
              dispatch(toggleTripModal({
                ...tripModal,
                isVisible: false,
              }))
              dispatch(setOutboundSelected(true))
            }}
          />
        )
      }
    </Wrapper>
  )
}

export default Passengercontrol