/**
 * Fleetmaintenance scene slice
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */
import { createSlice } from '@reduxjs/toolkit';
import i18n from 'i18next';
import { MaintenanceListSingle } from 'types/fleet/maintenance';
import { SortType } from 'types/misc';
import { DateTime } from 'luxon';

interface InitialState {
  sort: SortType,
  requestedPage: number,
  finishedPage: number,
  tabName: string,
  totalRequested: number,
  totalFinished: number,
  filter: string,
  vehicle: string,
  date: Date,
  maintenancesRequested: MaintenanceListSingle[],
  maintenancesFinished: MaintenanceListSingle[],
  searchTerm: string,
  loading: boolean,
}

const now = DateTime.now().toJSDate()
const initialState: InitialState = {
  sort: {
    direction: null,
    field: null,
  },
  tabName: '',
  requestedPage: 1,
  finishedPage: 1,
  totalRequested: 0,
  totalFinished: 0,
  filter: "",
  vehicle: "",
  date: now,
  maintenancesRequested: [],
  maintenancesFinished: [],
  searchTerm: '',
  loading: true,
}

export default createSlice({
  name: 'Fleetmaintenance',
  initialState,
  reducers: {
    onMount: (state, { payload }) => {
      state.tabName = i18n.t('maintenances.requested')
    },
    onUnmount: (state) => {
      state.tabName = i18n.t('maintenances.requested')
    },
    setTotalRequested: (state, { payload }) => {
      state.totalRequested = payload;
    },
    setTabName: (state, { payload }) => {
      state.tabName = payload
    },
    setTab: (state, { payload }) => { },
    setTotalFinished: (state, { payload }) => {
      state.totalFinished = payload;
    },
    setSort: (state, { payload }) => {
      state.sort = payload;
    },
    setRequestedPage: (state, { payload }) => {
      state.requestedPage = payload
    },
    setFinishedPage: (state, { payload }) => {
      state.finishedPage = payload
    },
    setFilter: (state, { payload }) => {
      state.finishedPage = 1
      state.requestedPage = 1
      state.filter = payload
    },
    setVehicle: (state, { payload }) => {
      state.finishedPage = 1
      state.requestedPage = 1
      state.vehicle = payload
    },
    setDate: (state, { payload }) => {
      state.finishedPage = 1
      state.requestedPage = 1
      state.date = payload
    },
    getURLParams: (state, payload) => { },
    fetchRequestedMaintenances: () => { },
    fetchFinishedMaintenances: () => { },
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
    setMaintenancesRequested: (state, { payload }) => {
      state.maintenancesRequested = payload;
    },
    setMaintenancesFinished: (state, { payload }) => {
      state.maintenancesFinished = payload;
    },
    setSearchTerm: (state, { payload }) => {
      state.finishedPage = 1
      state.requestedPage = 1
      state.searchTerm = payload
    },
    populateFiltersOnMount: (state, { payload }) => {
      state.searchTerm = payload.searchTerm
      state.filter = payload.filter
      state.vehicle = payload.vehicle
      state.date = payload.date
    }
  }
})