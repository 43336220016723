import React from 'react'
import { useSelector } from 'react-redux'
// Components
import Loadingcontainer from 'shared/components/Loadingcontainer'
import ElegiblesTable from '../ElegiblesTable';
import BookingsTable from '../BookingsTable';
// Store
import { RootState } from 'store/rootSlices';
// Types
import { Vehicles } from 'types/trips/plannings'
// Styles
import { NoResultsCell, CustomCTableRow } from '../../styles'
import { CustomTable } from './styles'

type PropTypes = React.PropsWithChildren<{
  elegibleBookings?: any,
  tripBookings?: any,
  totalBookings?: any,
  planningOutStops?: any,
  planningInStops?: any,
  tripUnits: Vehicles[]
}>

const FormContent = (props: PropTypes) => {
  const {
    elegibleBookings,
    tripBookings,
    totalBookings,
    planningOutStops,
    planningInStops,
    tripUnits
  } = props

  const { modalElegibleLoading } = useSelector((state: RootState) => state.TripPlannings);

  const _renderElegibleTable = () => {
    return elegibleBookings?.length > 0 && (
      <ElegiblesTable
        elegibleBookings={elegibleBookings} 
        planningInStops={planningInStops}
        planningOutStops={planningOutStops}
      />
    )
  }

  const _renderLoader = () => {
    return modalElegibleLoading ? (
      <CustomTable expanded={true}>
        <CustomCTableRow><NoResultsCell colSpan={16} ><Loadingcontainer /></NoResultsCell></CustomCTableRow>
      </CustomTable>
    ) : <></>
  }

  return (
    <div>
      <BookingsTable
        tripBookings={tripBookings}
        totalBookings={totalBookings}
        planningOutStops={planningOutStops}
        planningInStops={planningInStops}
        tripUnits={tripUnits}
      />
      { _renderElegibleTable() }
      { _renderLoader() }
    </div>
  )
}

export default FormContent