/**
 * Changepassword scene utils
 *
 * @author Manuel Gil <mgil@ubiwhere.pt>
 *
 *
 */
 import * as Check from 'validations'

 export const changePasswordValidations = {
    change_password: [Check.isRequired],
    confirm_password: [Check.isRequired]
}