import * as React from "react"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={15}
      height={9}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M.833 7.333h13.334v1.334H.833V7.333zm.767-2.7l.567-.986.566.986.867-.5-.567-.986h1.134v-1H3.033l.567-.98-.867-.5-.566.98L1.6.667l-.867.5.567.98H.167v1H1.3l-.567.986.867.5zm4.467-.5l.866.5.567-.986.567.986.866-.5-.566-.986H9.5v-1H8.367l.566-.98-.866-.5-.567.98-.567-.98-.866.5.566.98H5.5v1h1.133l-.566.986zm8.766-1.986H13.7l.567-.98-.867-.5-.567.98-.566-.98-.867.5.567.98h-1.134v1h1.134l-.567.986.867.5.566-.986.567.986.867-.5-.567-.986h1.133v-1z"
        fill="#636F83"
      />
    </svg>
  )
}

export default SvgComponent
