/**
 * Planninglisttablerow component
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
// Styles
import * as Styles from './styles'
// Components
import Addvehiclemodal from '../Addvehiclemodal';
import Checkinsmodal from '../Checkinsmodal';
import Pad from './components/Pad';
import PaddingCircuit from './components/PadCircuit';
import PadActions from './components/PadActions';
import Units from './components/Units';
import PadStops from './components/PadStops';
// Store
import { actions, RootState } from 'store/rootSlices';
// Types
import { PlanningListItem } from 'types/trips/plannings';

type PropTypes = {
  propActions: JSX.Element,
  planning: PlanningListItem,
  permission: boolean
}

const Planninglisttablerow = ({ permission, planning, propActions }: PropTypes) => {

  const {
    activities: [
        mainActivity,
      ],
    vehicles,
    outbound,
    inbound,
    id: planningId
  } = planning;

  const [showDetails, setShowDetails] = useState(false)

  const {
    showModal,
    showCheckinModal,
    selectedVehicle,
    openPlanningTripPassengers,
    openPlanningElegiblePassengers,
    openPlanningTotalBookings,
    tripsDetails
  } = useSelector((state: RootState) => state.TripPlannings)
  const {
    setShowModal,
    closeCheckInModal,
    setModalLoading,
    clearOpenPlanningTripPassengers,
    setIsUpdatingCircuit,
    setModalInitialVehicle,
    setDidStopsChange,
    setIsRemovingVehicle,
    getTripsDetails,
  } = actions.TripPlannings
  const dispatch = useDispatch()
  
  const handleShowDetails = () => {
    if(!showDetails && tripsDetails.every((detail) => detail.id !== planningId)) {
      dispatch(getTripsDetails({id: planningId}))
    } 
    setShowDetails(!showDetails)
  }

  const outboundsLength = outbound ? outbound.length : 0
  const inboundsLength = inbound ? inbound.length : 0
  
  useEffect(() => {
  }, [showDetails])

  const _renderCheckinsModal = () => {
    return showCheckinModal.show && showCheckinModal.planningId === planningId && showCheckinModal.openBy === 'stop' && (
      <Checkinsmodal
        showModal={showCheckinModal.show}
        tripBookings={openPlanningTripPassengers}
        elegibleBookings={openPlanningElegiblePassengers}
        totalBookings={openPlanningTotalBookings}
        planningOutStops={outbound}
        planningInStops={inbound}
        tripUnits={vehicles}
        onClose={e => {
          dispatch(closeCheckInModal())
          dispatch(setModalLoading(true))
          dispatch(clearOpenPlanningTripPassengers())
        }}
      />
    )
  }

  const _renderAddVehicleModal = () => {
    return  showModal.show && showModal.planningId === planningId && (
      <Addvehiclemodal
        selectedVehicle={selectedVehicle.value}
        onClose={e => {
          dispatch(setDidStopsChange(false))
          dispatch(setIsRemovingVehicle(false))
          dispatch(setIsUpdatingCircuit(false))
          dispatch(setModalInitialVehicle(""))
          dispatch(setShowModal({ show: false, planningId }))
        }}
      />
    )
  }

  return (
    <Styles.TableRow activitycolor={mainActivity?.activity.color}>
      <Styles.Info>
        <Styles.Summary>
          <Pad 
            onClick={handleShowDetails}
            outbound={outbound}
            inbound={inbound}
            outboundsLength={outboundsLength}
            inboundsLength={inboundsLength}
          />
          <PaddingCircuit 
            onClick={handleShowDetails}
            planning={planning}
            outboundsLength={outboundsLength}
            inboundsLength={inboundsLength}
          />
          <PadActions 
            planning={planning}
            permission={permission}
            onClick={handleShowDetails}
            showDetails={showDetails}
            setShowDetails={setShowDetails}
          />
        </Styles.Summary>
        {
          showDetails &&
          <Styles.Details noinbounds={inboundsLength === 0} >
            <Styles.Stops>
              {
                outboundsLength > 0 && (
                  <PadStops 
                    isOutbound={true}
                    stopsLength={outboundsLength}
                    vehicles={planning.vehicles}
                    planningId={planningId}
                    direction={planning.outbound}
                    showDetails={showDetails}
                  />
                )
              }
              {
                inboundsLength > 0 && (
                  <PadStops 
                    isOutbound={false}
                    stopsLength={inboundsLength}
                    vehicles={planning.vehicles}
                    planningId={planningId}
                    direction={planning.inbound}
                    showDetails={showDetails}
                  />
                )
              }

            </Styles.Stops>
            <Units planning={planning} />
          </Styles.Details>
        }
      </Styles.Info>
      { _renderCheckinsModal() }
      { _renderAddVehicleModal() }
    </Styles.TableRow>
  )
}

export default Planninglisttablerow