import * as React from "react"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width="20" height="20" viewBox="-2 2 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.0001 10.4998V12.4998H4.00008V10.4998H2.66675V12.4998C2.66675 13.2332 3.26675 13.8332 4.00008 13.8332H12.0001C12.7334 13.8332 13.3334 13.2332 13.3334 12.4998V10.4998H12.0001ZM4.66675 6.49984L5.60675 7.43984L7.33341 5.71984V11.1665H8.66675V5.71984L10.3934 7.43984L11.3334 6.49984L8.00008 3.1665L4.66675 6.49984Z" fill="white" />
        </svg>

    )
}

export default SvgComponent
