/**
 * Login scene sagas
 *
 * @author João Dias <jdias@ubiwhere.com>
 *
 *
 */
//import { takeLatest } from 'redux-saga/effects'

import { call, putResolve, select, takeLatest } from "redux-saga/effects";
import API from 'api'
import { actions, RootState } from 'store/rootSlices'
import * as Check from 'validations'
import { loginValidations, parseJwt } from "../utils";
import { DEFAULT_USER } from "app/utils";
import { AccessTokens, Credentials, UserItem } from "types/users/user";
import i18n from "i18next";
import { AnyAction } from "redux";

//state
const credentials = (state: RootState) => state.Login.credentials

function* onMountSaga() {
}

function* onUnmountSaga() {
}

function* onSubmitSaga() {

  yield putResolve(actions.Login.setSubmitButtonDisabled(true))

  const params: Credentials = yield select(credentials)
  const fieldsValidation: Credentials = Check.setAndCheckValidationAlternative(params, params, loginValidations)
  const validation = Check.checkValidationAlternative(params, loginValidations)

  if (validation.invalid) {
    yield putResolve(actions.Login.onFormChange(fieldsValidation))
    yield putResolve(actions.Login.onError(i18n.t("validations.invalidCredentials")))
    yield putResolve(actions.Login.setSubmitButtonDisabled(false))
    return
  }

  try {
    const tokens: AccessTokens = yield call(API.Auth.Login, { username: params.username.value, password: params.password.value, user_type: "team_member"})

    if (tokens) {

      const JWT = tokens.access
      const decodedJWT = JWT ? parseJwt(JWT) : null

      if (decodedJWT.extra_fields.user_type === 'team_member' && decodedJWT.extra_fields.has_backoffice_access) {

        window.localStorage.setItem('access_token', tokens.access)
        window.localStorage.setItem('refresh_token', tokens.refresh)
        yield putResolve(actions.Login.setIsLoggedIn(true))
        yield putResolve(actions.Login.onError(""))

        try {
          const user: UserItem = yield call(API.Auth.GetUser, { uuid: decodedJWT.extra_fields.uuid, userType: 'team_member'}) //must be uuid 

          //if is a collaborator and if is the first login
          if (user && user.userType.uid === 'team_member' && user.hasAcceptedTerms === false) {
            yield putResolve(actions.Login.showTermsAndConditions(true))
          } else {
            //if hasn't changed password
            if (decodedJWT.extra_fields.force_password_change === true) {
              yield putResolve(actions.App.navigateTo(`/login/mudar-password/${" "}`))
              yield putResolve(actions.Login.setSubmitButtonDisabled(false))
              yield putResolve(actions.ChangePassword.showEmail(false))
              yield putResolve(actions.ChangePassword.showChangePassword(true))
            }
            else {
              yield putResolve(actions.App.setPermissions(decodedJWT.extra_fields.permissions))
              yield putResolve(actions.Login.onLogin({
                user,
                role: decodedJWT.extra_fields.role
              }))
            }
          }
        } catch (error) {
          yield putResolve(actions.Login.onError(i18n.t("login.userNotFound")))
          yield putResolve(actions.Login.setSubmitButtonDisabled(false))
        }
      }
      else {
        yield putResolve(actions.Login.setIsLoggedIn(false))
        yield putResolve(actions.App.setUser(DEFAULT_USER))
        yield putResolve(actions.App.setIsInApp(false))
        yield putResolve(actions.Login.onError(i18n.t(!decodedJWT.extra_fields.has_backoffice_access ? `login.noBackofficeAccess` : `login.noPermissionToLogin`)))
        yield putResolve(actions.Login.setSubmitButtonDisabled(false))
      }
    }
  } catch (error) {
    yield putResolve(actions.Login.onError(i18n.t("login.couldNotLogin")))
    yield putResolve(actions.Login.setSubmitButtonDisabled(false))
  }
}

function* onLoginSaga({ payload }: AnyAction) {

  yield putResolve(actions.App.setUser({
    email: payload.user.email,
    uuid: payload.user.uuid,
    role: payload.role,
    userType: {
      id: payload.user.userType.id,
      uid: payload.user.userType.uid
    },
    token: payload.user.token
  }));

  yield putResolve(actions.App.setIsInApp(true))
  //populate header
  try {
    yield putResolve(actions.App.updateUserRole(payload.user))
  } catch (error) {

  }
  yield putResolve(actions.App.loadAppInfo());
  yield putResolve(actions.App.navigateTo('/'));
}

function* logoutSaga() {
  window.localStorage.removeItem('access_token')
  window.localStorage.removeItem('refresh_token')
  yield putResolve(actions.Login.setIsLoggedIn(false))
  yield putResolve(actions.ChangePassword.showEmail(true))
  yield putResolve(actions.ChangePassword.showChangePassword(false))
  yield putResolve(actions.App.setUser(DEFAULT_USER))
  yield putResolve(actions.App.setIsInApp(false))
  yield putResolve(actions.Login.setSubmitButtonDisabled(false))
  yield putResolve(actions.App.navigateTo('/login'))
}

function* onSubmitTermsAndConditionsSaga() {
  const JWT = window.localStorage.getItem('access_token')
  const decodedJWT = JWT ? parseJwt(JWT) : null

  try {
    const terms = yield call(API.Auth.AcceptTermsCorrect, decodedJWT.extra_fields.uuid)
    if (terms.status === 200) {
      yield putResolve(actions.Login.showTermsAndConditions(false))
      yield putResolve(actions.ChangePassword.showEmail(false))
      yield putResolve(actions.ChangePassword.showChangePassword(true))
      yield putResolve(actions.App.navigateTo(`/login/mudar-password/${" "}`))
    }
  } catch (error) {
    yield putResolve(actions.Login.onError("Repita o Login"))
  }
}

export default function* watcherSignin() {
  yield takeLatest('Login/onMount', onMountSaga)
  yield takeLatest('Login/onUnmount', onUnmountSaga)
  yield takeLatest('Login/onSubmit', onSubmitSaga)
  yield takeLatest('Login/logout', logoutSaga)
  yield takeLatest('Login/onSubmitTermsAndConditions', onSubmitTermsAndConditionsSaga)
  yield takeLatest('Login/onLogin', onLoginSaga)
}


