/**
 * Usercashmanagementforms scene
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 */
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { DateTime } from 'luxon'
// Store
import { actions, RootState } from 'store/rootSlices'
// Types
import { StatementStatus } from 'types/invoicing/statements'
// Components
import TableContainer from 'shared/components/TableContainer'
import Usercashinfo from './components/Usercashinfo'
import CustomButton from 'shared/components/CustomButton'
import Photoscomponent from 'shared/components/Photoscomponent'
import FileUploader from 'shared/components/FormComponents/fileuploader'
import SectionContainer from 'shared/components/Sectioncontainer'
// Icons
import Calendar from 'assets/icons/calendar'
import Back from 'assets/icons/arrow_back'
// Utils & Configs
import config from 'config'
import { hasPermission } from 'permissions'
// Styles
import theme from 'ui/theme'
import { Wrapper, StatementInfoWrapper, Title, DateInfo, ButtonWrapper} from './styles'

const Usercashmanagementforms = ({ t, i18n }) => {

  const {
    photos,
    loading,
    newPhotosID,
    submitButtonState
  } = useSelector((state: RootState) => state.UserCashManagementForms)

  const {
    statement,
    userID,
    userStatementName,
    statementStatus
  } = useSelector((state: RootState) => state.UserCashManagement)

  const {
    startDate
  } = useSelector((state: RootState) => state.CashManagement)

  const {
    permissions
  } = useSelector((state: RootState) => state.App)

  const { PERMISSIONS } = config

  const {
    onMount,
    onUnmount,
    addToPhotosArray,
    reOrderPhotosArray,
    deletePhotoFromArray
  } = actions.UserCashManagementForms

  const {
    openStatement,
  } = actions.UserCashManagement

  const {
    createStatementPhotos,
  } = actions.UserCashManagementForms


  const { navigateBack } = actions.App

  const { uuid, id } = useParams()
  const dispatch = useDispatch()

  useEffect(() => {

    dispatch(onMount({ uuid, id }))
    return () => {
      dispatch(onUnmount())
    }
  }, [dispatch, onMount, onUnmount, uuid, id]);

  const handleAddPhoto = (e) => {
    dispatch(addToPhotosArray({ photos, e, newPhotosID, userID }))
  }
  
  return (
    <Wrapper>
      <StatementInfoWrapper>
        <Title>
          <span onClick={() => { dispatch(navigateBack()) }}>
            <Back width={25} />
          </span>{i18n.t('cashManagement.userStatement', { user: userStatementName })}
        </Title>
        <DateInfo><span><Calendar /></span>{DateTime.fromJSDate(startDate).toFormat(config.DATE_FORMAT.DATE)}</DateInfo>
      </StatementInfoWrapper>
      <TableContainer
        id={`collaboratorsManagement-table`}
        count={0}
        currentPage={-1}
        elementsPerPage={1}
        onPageChange={pg => { /* dispatch(setPage(pg)) */ }}
        headerElements={[
          i18n.t('cashManagement.valueType'),
          i18n.t('common.value'),
        ]}
      >
        {!loading && statement && hasPermission(permissions, PERMISSIONS.INVOICING.CASH_MANAGEMENT.VIEW_STATEMENT) && <Usercashinfo t={t} statement={statement ?? undefined} cashClosed={false}></Usercashinfo>}
      </TableContainer>
      {
        statement && <ButtonWrapper>
          <CustomButton
            id='cashManagement-discard'
            text={statementStatus === StatementStatus.CLOSED ? t('cashManagement.reOpen') : t('cashManagement.close')}
            disabled={submitButtonState}
            icon={"lock"}
            onClick={() => {
              statementStatus === StatementStatus.CLOSED ?
                hasPermission(permissions, PERMISSIONS.INVOICING.CASH_MANAGEMENT.OPEN_STATEMENT) && dispatch(openStatement())
                :
                hasPermission(permissions, PERMISSIONS.INVOICING.CASH_MANAGEMENT.CLOSE_STATEMENT) && dispatch(createStatementPhotos())
            }}
            color={statementStatus === StatementStatus.CLOSED ? "danger" : "success"}
            buttonStyle={{ color: theme().white }}
          />
        </ButtonWrapper>
      }
      {hasPermission(permissions, PERMISSIONS.INVOICING.CASH_MANAGEMENT.OPEN_STATEMENT) && <SectionContainer title={i18n.t('cashManagement.depositReceipt')} style={{ width: '100%', paddingBottom: '24px' }}>
        {
          statement && !statement.isClosed && hasPermission(permissions, PERMISSIONS.INVOICING.CASH_MANAGEMENT.OPEN_STATEMENT) && <FileUploader
            id='cashManagement-uploadPhoto'
            required={false}
            label={i18n.t('cashManagement.upload')}
            accept='.png, .jpg, .jpeg, .pdf'
            fileSizeLimit={100000000}
            onChange={(e) => handleAddPhoto(e)} />
        }
        <Photoscomponent
          id='cashManagementPhotos'
          array={photos}
          disableDrag={statement?.isClosed}
          onDrag={e => dispatch(reOrderPhotosArray({ photos, e }))}
          onDelete={(e, index) => {
            dispatch(deletePhotoFromArray({ photos, index }))
          }}
        />
      </SectionContainer>}
    </Wrapper>
  )
}

export default Usercashmanagementforms