/**
 * PassengerTypes component
 *
 * @author João Flores <jflores@ubiwhere.com>
 *
 *
 */

import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { actions, RootState } from 'store/rootSlices'
//styles
import { TableRow } from '../../styles'
//Components
import TableContainer from 'shared/components/TableContainer'
import { CTableDataCell } from '@coreui/react-pro'
import CustomTextInput from 'shared/components/CustomTextInput'
import CustomNumberInput from 'shared/components/CustomNumberInput'
// Types
import { BookingPassengerTypeForm } from 'types/trips/bookings';
import { PassengerTypeListItem } from 'types/trips/passengerTypes'

const PassengerTypes = ({ t, passengerTypes }) => {
    const {
        selectedPassengerTypes,
        totalPassengers,
        edit,
        lockPassengerInputs,
        outbound,
        inbound
    } = useSelector((state: RootState) => state.BookingForm);
    // console.log("🚀 ~ PassengerTypes ~ selectedPassengerTypes:", selectedPassengerTypes)

    const {
        setSelectedPassengerTypes,
        setEditPassengerTypes,
        setShowCancelationModal,
        setCancelFullBooking
    } = actions.BookingForm;

    const dispatch = useDispatch();

    const _renderPrivateRow = () =>  {
        const hasPrivateOutbound = outbound.tripActivityLeg.id > 0 && outbound.tripActivityLeg.activity.isPrivate;
        const hasPrivateInbound = inbound.tripActivityLeg.id > 0 && inbound.tripActivityLeg.activity.isPrivate;

        if(!hasPrivateInbound && !hasPrivateOutbound) return null
        
        const getDescription = (name: string, seats: string) => `${name} - ${t('tripBookings.includedSeats')}(${seats})`;
        
        let description: string = ''
        if(hasPrivateInbound && hasPrivateOutbound) { 
          description = `
            ${outbound.tripActivityLeg.activity.name} / 
            ${inbound.tripActivityLeg.activity.name} - 
            ${t('tripBookings.includedSeats')}(
            ${outbound.tripActivityLeg.includedSeats} /
            ${inbound.tripActivityLeg.includedSeats})`
        } else if (hasPrivateOutbound) {
          description = getDescription(outbound.tripActivityLeg.activity.name, outbound.tripActivityLeg.includedSeats)
        } else if (hasPrivateInbound) { 
          description = getDescription(inbound.tripActivityLeg.activity.name, inbound.tripActivityLeg.includedSeats)
        } else {
          description = t('tripBookings.includedSeats')
        }
    
        const minimumOutboundPrice = hasPrivateOutbound ? outbound.tripActivityLeg.minimumPrice : '-';
        const minimumInboundPrice = hasPrivateInbound ? inbound.tripActivityLeg.minimumPrice : '-';
    
        return (
          <TableRow>
            <CTableDataCell style={{fontWeight: "600", width: "45%"}}>{description}</CTableDataCell>
            <CTableDataCell style={{width: "25%"}}></CTableDataCell>
            <CTableDataCell style={{ width: '15%'}}>
              <CustomTextInput icon="euro" disabled={true} value={minimumOutboundPrice} />
            </CTableDataCell>
            <CTableDataCell style={{ width: '15%'}}>
              <CustomTextInput icon="euro" disabled={true} value={minimumInboundPrice} />
            </CTableDataCell>
          </TableRow>
        )
    }

    const handleSelectPassengerType = (id: number, value: number) => { 
        dispatch(setSelectedPassengerTypes({
            id,
            value,
            outbound,
            inbound
        }));
    }

    const handleEditPassengerType = (id: number, value: number) => { 
        dispatch(setEditPassengerTypes({
            id,
            value,
            outbound,
            inbound
        }));
    }

    const handleCancelFullBooking = () => { 
        dispatch(setCancelFullBooking(true));
        dispatch(setShowCancelationModal(true))
    }

    return (
        <TableContainer
            count={0}
            elementsPerPage={0}
            currentPage={-1}
            onPageChange={() => { }}
            headerElements={[
                t('tripSettings.passengerTypes'),
                t('common.quantity'),
                t('tripBookings.priceOutbound'),
                t('tripBookings.priceInbound'),
            ]}
        >
            { _renderPrivateRow() }
            {passengerTypes.map((passengerType: PassengerTypeListItem, idx) => {
                const selected: BookingPassengerTypeForm | undefined = selectedPassengerTypes.find(p => p.id === passengerType.id);
                if (!selected || selected.isDisabled) return null;

                return (
                    <TableRow key={`passenger-type-${idx}`}>
                        <CTableDataCell style={{ width: "45%" }}>{passengerType.name}</CTableDataCell>
                        <CTableDataCell style={{ width: "25%" }}>
                            {
                                edit ? (
                                    <CustomNumberInput
                                        id={`passenger-type-quantity-${idx}`}
                                        value={selected?.quantity.value || ""}
                                        valid={selected ? selected.quantity.valid : true}
                                        onChange={e => {
                                            let newTotalValue: number = totalPassengers
                                            if(e.target.value === '0') {
                                                newTotalValue = totalPassengers - Number(selected?.quantity.value)
                                            }
                                            if(newTotalValue > 0) {
                                                handleEditPassengerType(passengerType.id, e.target.value.trim().length ? parseInt(e.target.value, 10) : 0)
                                            } else {
                                                handleCancelFullBooking()
                                            }
                                            
                                        }}
                                        onClickAdd={(e, numberInput) => handleEditPassengerType(passengerType.id, parseInt(numberInput.value, 10) + 1)}
                                        onClickSub={(e, numberInput) => {
                                            if(totalPassengers - 1 > 0) {
                                                handleEditPassengerType(passengerType.id, parseInt(numberInput.value, 10) - 1)
                                            } else {
                                                handleCancelFullBooking()
                                            }
                                        }}
                                        disabled={!selected || selected.isDisabled || lockPassengerInputs}
                                        min={0}
                                        max={selected?.maxCapacity}
                                    />
                                ) : (
                                    <CustomNumberInput
                                        id={`passenger-type-quantity-${idx}`}
                                        value={selected?.quantity.value || ""}
                                        valid={selected ? selected.quantity.valid : true}
                                        onChange={e => handleSelectPassengerType(passengerType.id, e.target.value.trim().length ? parseInt(e.target.value, 10) : 0)}
                                        onClickAdd={(e, numberInput) => handleSelectPassengerType(passengerType.id, parseInt(numberInput.value, 10) + 1)}
                                        onClickSub={(e, numberInput) =>  handleSelectPassengerType(passengerType.id, parseInt(numberInput.value, 10) - 1)}
                                        disabled={!selected || selected.isDisabled || lockPassengerInputs}
                                        min={0}
                                        max={selected?.maxCapacity}
                                    />
                                )
                            }
                            
                        </CTableDataCell>
                        <CTableDataCell style={{ width: "15%"}}>
                            <CustomTextInput
                                icon="euro"
                                disabled={true}
                                value={selected?.totalPriceOutbound || "-"}
                            />
                        </CTableDataCell>
                        <CTableDataCell style={{ width: "15%"}}>
                            <CustomTextInput
                                icon="euro"
                                disabled={true}
                                value={selected?.totalPriceInbound || "-"}
                            />
                        </CTableDataCell>
                    </TableRow>
                )
            })}
        </TableContainer>
    )
}

export default PassengerTypes