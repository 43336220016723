import { putResolve, call, select } from 'redux-saga/effects'
import { toast } from 'react-toastify';
import API from 'api'
import { actions, RootState } from 'store/rootSlices'
import i18n from 'i18next';
import { PlannedLegs } from 'types/trips/plannings';


export function* getLegsSaga() {
  yield putResolve(actions.TripPlanningsForm.setLoadingLegs(true))
  yield putResolve(actions.TripPlanningsForm.setShowLegsModal(true))

  const {
    planningID
  } = yield select((state: RootState) => state.TripPlanningsForm);

  try {
    const legs: PlannedLegs = yield call(API.Plannings.GetPlanningLegs, planningID)
    yield putResolve(actions.TripPlanningsForm.setPlanningLegsForm(legs))
    
  } catch (error) {
    toast.error(i18n.t("toasts.planningLegsGetError"));
  } finally {
    yield putResolve(actions.TripPlanningsForm.setLoadingLegs(false))
  }
}