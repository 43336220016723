import styled from 'styled-components'
import { CFormCheck } from '@coreui/react-pro';
import theme from 'ui/theme';

export const CustomCFormCheck = styled(CFormCheck)`
  > label {
    font-family: 'Inter';
    font-size: 14px;
    color: ${theme().gray500Color};
    cursor:pointer;
  }
`