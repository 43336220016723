/**
 * BookingForm styles
 *
 * @author João Flores <jflores@ubiwhere.com>
 *
 *
 */

import styled from 'styled-components';
import { CTableRow, CRow } from '@coreui/react-pro';

import theme from 'ui/theme';

import SectionContainer from 'shared/components/Sectioncontainer';

const TableRow = styled(CTableRow)`
  > td {
    vertical-align: middle;
    color: ${theme().darkColor};
    border-bottom: solid 1px ${theme().gray200Color};
    font-size: 14px;

    > .smaller {
      font-size: 11px;
    }
  }
`;

const ActionsWrapper = styled.span`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  > svg {
    cursor: pointer;
    margin-right: 15px;
  }
`;

const Row = styled(CRow)`
  --cui-gutter-y: 0rem;
  margin-top: 26px;
`;

const TooltipText = styled.span`
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  line-height: 0.5rem;
  gap: 5px;
`;

const CustomSection = styled(SectionContainer)`
  padding-bottom: 24px;
  align-items: center;
  overflow: visible !important;
`;

const UnderTitle = styled.p`
  margin: 0;
  font-family: 'Inter';
  font-style: normal;
  font-size: 14px !important;
  color: ${theme().darkColor};
  margin-top: -10px;

  > a {
    color: ${theme().darkColor};
    font-weight: 600;
    text-decoration: none;

    &:hover {
      color: ${theme().gray600Color};
    }
  }
`;

const BookingStateLabel = styled.span`
  padding: 2px 4px;
  color: ${theme().white};
  font-weight: 700;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 600;

  &.to_refund {
    background-color: ${theme().warning};
  }
  &.to_pay {
    background-color: ${theme().warning};
  }
  &.canceled {
    background-color: ${theme().darkColor};
  }
  &.pending {
    background-color: ${theme().warning};
  }
  &.paid {
    background-color: ${theme().success};
  }
  &.refunded {
    background-color: ${theme().danger};
  }
`;

const ExtraInfo = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
`;

const TitleExtras = styled.div`
  > * {
    margin-left: 35px;
  }
`;

export {
  TableRow,
  ActionsWrapper,
  Row,
  TooltipText,
  CustomSection,
  UnderTitle,
  BookingStateLabel,
  ExtraInfo,
  TitleExtras,
};
