import styled from 'styled-components'
import { CustomTableRow } from 'shared/genericStyledComponents'

export const UserCashInfoTableRow = styled(CustomTableRow) <{ summary?: boolean }>`
  height: 54px;
  ${({ summary }) => summary ? `background-color: #F5F7FA;` : null}
  
  td:last-child{
    width: 17%;
  }
`