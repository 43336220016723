/**
 * Customers scene sagas
 *
 * @author João Dias <jdias@ubiwhere.com>
 *
 *
 */
import api from 'api';
import { call, putResolve, select, takeLatest } from 'redux-saga/effects'
import { actions, RootState } from 'store/rootSlices'
import { AnyAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify'
import i18n from 'i18next';
import { CustomersList } from 'types/customers/customers';


function* onMountSaga({payload}: AnyAction) {
  yield getURLParamsSaga(payload);
  yield putResolve(actions.Customers.fetchCustomers());
}

function* onUnmountSaga() { 
  yield putResolve(actions.Customers.setCustomers([]));
  yield putResolve(actions.Customers.setTotal(0));
  yield putResolve(actions.Customers.setLoading(true));
}

function* fetchCustomersSaga() {
  try {
    const { page, searchTerm, sort } = yield select((state: RootState) => state.Customers);
    yield putResolve(actions.Customers.setLoading(true));
    const res: CustomersList = yield call(api.Customers.GetCustomers, searchTerm, sort, page);
    yield putResolve(actions.Customers.setCustomers(res.results ?? []));
    yield putResolve(actions.Customers.setTotal(res.count));
  } catch (err) {
    //console.log(err);
    toast.error(i18n.t("toasts.getCustomersError"))
  } finally {
    yield putResolve(actions.Customers.setLoading(false));
  }
}

function* getURLParamsSaga( payload : AnyAction) {

  let params = new URLSearchParams(payload.location.search)

  const {
      searchTerm,
  } = yield select((state: RootState) => state.Customers);

  if (params.toString() !== "") {
    yield putResolve(actions.Customers.populateFiltersOnMount({
      searchTerm: params.get('search') ?? ""
    }))
  }
  else {
      params.set('search', searchTerm)
      payload.replace({ search: (params).toString() })
  }
}

function* getCustomersFileSaga() {
  yield putResolve(actions.Customers.setExportLoading(true))

  try {
    const file = yield call(api.Customers.ExportExcel)

    if (file) {
      yield putResolve(actions.Customers.downloadCustomerExcel(file))
      toast.success(i18n.t("toasts.downloadCustomerExcelSuccess"))
    }

  } catch (error) {
    toast.error(i18n.t("toasts.downloadCustomerExcelError"))
  } finally {
    yield putResolve(actions.Customers.setExportLoading(false))
  }
}

export default function* watcherSignin() {
  yield takeLatest('Customers/onMount', onMountSaga)
  yield takeLatest('Customers/onUnmount', onUnmountSaga)
  yield takeLatest('Customers/fetchCustomers', fetchCustomersSaga)
  yield takeLatest('Customers/setPage', fetchCustomersSaga)
  yield takeLatest('Customers/setSearchTerm', fetchCustomersSaga)
  yield takeLatest('Customers/setSort', fetchCustomersSaga)
  yield takeLatest('Customers/getURLParams', getURLParamsSaga);
  yield takeLatest('Customers/getCustomersFile', getCustomersFileSaga)
}


