/**
 * Login scene slice
 *
 * @author João Dias <jdias@ubiwhere.com>
 *
 *
 */
import { createSlice } from '@reduxjs/toolkit'
import { Credentials } from 'types/users/user'

interface initialState {
  isLoggedIn: boolean,
  showTermsAndConditions: boolean,
  termsAccepted: boolean,
  error: string,
  credentials: Credentials,
  submitButtonState: boolean
}

const initialState : initialState = {
  isLoggedIn: false,
  submitButtonState: false,
  showTermsAndConditions:false,
  termsAccepted: false,
  error:'',
  credentials: {
    username:{
      value:'',
      valid:false,
      message:''
    },
    password:{
      value:'',
      valid:false,
      message:'',
    }
  }
}

export default createSlice({
  name: 'Login',
  initialState,
  reducers: {
    onMount: () => {},
    onUnmount: () => {},
    onFormChange: (state, { payload }) => {
      state.error = ''
      state.credentials = payload
    },
    setSubmitButtonDisabled: (state, { payload }) => {
      state.submitButtonState = payload
    },
    onSubmit: () => {},
    onError: (state, { payload }) => {
      state.error = payload
    },
    onLogin:(state, { payload }) => {},
    setIsLoggedIn:(state, { payload }) => {
      state.isLoggedIn = payload
    },
    showTermsAndConditions:(state, { payload }) => {
      state.showTermsAndConditions = payload
    },
    setTermsAndConditions: (state, { payload }) => {
      state.termsAccepted = payload
    },
    onSubmitTermsAndConditions: () => {},
    logout: () => {},
    
  }
})