import * as React from "react"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="41"
            height="40"
            viewBox="0 0 41 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path 
                d="M20.5 0C9.46 0 0.5 8.96 0.5 20C0.5 31.04 9.46 40 20.5 40C31.54 40 40.5 31.04 40.5 20C40.5 8.96 31.54 0 20.5 0ZM20.5 36C11.68 36 4.5 28.82 4.5 20C4.5 11.18 11.68 4 20.5 4C29.32 4 36.5 11.18 36.5 20C36.5 28.82 29.32 36 20.5 36ZM29.68 11.16L16.5 24.34L11.32 19.18L8.5 22L16.5 30L32.5 14L29.68 11.16Z" 
                fill={props.fill ? props.fill : "#2EB85C"}
            />
        </svg>
    )
}

export default SvgComponent

