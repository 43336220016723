import styled from 'styled-components'
import theme, { devicesMax } from 'ui/theme';


export const AvailabilityInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 7px;
  height: 100%;
  padding-left: 20px;
  border-left : 1px solid #D8DBE0;

  @media ${devicesMax.laptop} {
    border-left: none;
    padding-left: 0px;
    height: 60%;
  }
`

export const ActivityLabel = styled.div`
  margin-right: 20px;
  background-color: ${theme().primary};
  color:${theme().white};
  border-radius: 4px;
  font-size: 11px;
  padding: 0px 5px;
`

export const PadCircuit = styled.div`
  width: 70%;
  display:flex;
  justify-content: space-between;
  align-items: center;
  padding-left:29px;
  padding-right:24px;
  cursor: pointer;
  border-right: 1px solid #D8DBE0;
`

export const PadCircuitInfo = styled.div`
  display:flex;
  justify-content:flex-start;
  flex-wrap: wrap;
  align-items:center;
  cursor:pointer;
  row-gap: 5px;
`

export const PadCircuitRightInfo = styled.div`
  display:flex;
  justify-content:flex-end;
  align-items:center;
  row-gap: 5px;
  gap: 20px;
  height: 100%;

  @media ${devicesMax.laptop} {
    flex-direction: column;
    align-items: flex-end;
  }
`

export const UnitsInfo = styled.div`
  display:flex;
  justify-content:flex-end;
  min-width: 135px;
`

export const TitleActivity = styled.p`
  margin: 0px;
  margin-right: 20px;
  margin-top: 2px;
  color: ${theme().darkColor}
`

export const TitleCircuit = styled.p`
  margin: 0px;
  margin-right: 20px;
  margin-top: 2px;
  color: ${theme().gray600Color};
  font-size: 14px;
`
