/**
 * Tripplannings scene slice
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */
import { createSlice } from '@reduxjs/toolkit'
import { PlanningListItem } from 'types/trips/plannings'
import initialState from './initialState';
// Other Reducers
import AddVehicleReducers from '../components/Addvehiclemodal/logic/reducers';
import CheckinsModalReducers from '../components/Checkinsmodal/logic/reducers';
import PlanningListTableRowReducers from '../components/Planninglisttablerow/logic/reducers';
import BeachPlanningListTableRowReducers from '../components/Beachplanningtablerow/logic/reducers';

export default createSlice({
  name: 'Tripplannings',
  initialState,
  reducers: {
    ...AddVehicleReducers,
    ...CheckinsModalReducers,
    ...PlanningListTableRowReducers,
    ...BeachPlanningListTableRowReducers,
    onMount: (state, { payload }) => { },
    onUnmount: (state, { payload }) => {
      state.page = 1
      state.totalPlannings = 0
      state.loading = false
      state.bottomLoading = true
      state.modalLoading = true
      state.showModal = {
        planningId: 0,
        show: false
      }
      state.showCheckinModal = {
        ...initialState.showCheckinModal
      }
      state.openPlanningTripPassengers = null
      state.openPlanningElegiblePassengers = null
      state.openPlanningTotalBookings = null
      state.plannings = []
      state.openPlanningOutboundStops = []
      state.openPlanningInboundStops = []
      state.fetchTrips = false
      state.hasNextPage = false
      state.disableScroll = false
    },
    setPage: (state, { payload }) => {
      state.page = payload
    },
    setCheckingIn: (state, { payload }) => {
      state.checkingIn = payload
    },
    setActivities: (state, { payload }) => {
      state.activities = payload
    },
    setLoading: (state, { payload }) => {
      state.loading = payload
    },
    setScrollLoading: (state, { payload }) => {
      state.scrollLoading = payload
    },
    getURLParams: (state, payload) => { },
    setBottomLoading: (state, { payload }) => {
      state.bottomLoading = payload
    },
    setModalLoading: (state, { payload }) => {
      state.modalLoading = payload
    },
    setModalElegibleLoading: (state, { payload }) => {
      state.modalElegibleLoading = payload
    },
    setDisableScroll: (state, { payload }) => {
      state.disableScroll = payload
    },
    setSelectedDay: (state, { payload }) => {
      state.selectedDay = payload
    },
    onDayChange: (state, { payload }) => {
      state.page = 1
      state.fetchTrips = false
      state.hasNextPage = false
      state.plannings = []
      state.selectedDay = payload.day.date

      let params = new URLSearchParams(payload.history.location.search);
      payload.selectedDay !== "" ? params.set('selectedDay', payload.day.date) : params.delete('selectedDay')
      payload.history.replace({ search: (params).toString() })

    },
    getPlannings: () => { },
    getPlanningsScroll: () => { },
    setPlannings: (state, { payload }) => {
      const plannings: PlanningListItem[] = Array.from(payload.plannings)
      payload.newPlannings.forEach((pl) => {
        plannings.push(pl)
      })
      state.plannings = plannings;
    },
    setBeachPlannings: (state, { payload }) => {
      state.beachPlannings = payload.newBeachPlannings;
    },
    setTotalPlannings: (state, { payload }) => {
      state.totalPlannings = payload;
    },
    setFetchTrips: (state, { payload }) => {
      state.fetchTrips = payload
    },
    setHasNextPage: (state, { payload }) => {
      state.hasNextPage = payload
    },
    setActivityFilter: (state, { payload }) => {
      state.plannings = []
      state.page = 1
      state.activityFilter = payload
    },
    setVehicleFilter: (state, { payload }) => {
      state.plannings = []
      state.page = 1
      state.vehicleFilter = payload
    },
    setDepartureFilter: (state, { payload }) => {
      state.plannings = []
      state.page = 1
      state.departureFilter = payload
    },
    setArrivalFilter: (state, { payload }) => {
      state.plannings = []
      state.page = 1
      state.arrivalFilter = payload
    },
    setHasDateParams: (state, { payload }) => {
      state.hasDateParams = payload
    },
    setStartDate: (state, { payload }) => {
      state.startDate = payload
    },
    setEndDate: (state, { payload }) => {
      state.endDate = payload
    },
    setDays: (state, { payload }) => {
      state.days = payload.map((d, idx) => {
        return ({
          date: d.toISO().slice(0, 10),
          active: idx === 0 ? true : false
        })
      })
    },
    clearPlanningsArray: (state) => {
      state.page = 1
      state.plannings = []
    },
    setDatesInterval: (state, { payload }) => {
      state.plannings = []
      state.page = 1
    },
    setShowCheckInModal: (state, { payload }) => {
      state.showCheckinModal = {
        ...initialState.showCheckinModal,
        ...payload
      }
    },
    setCheckinOutboundSelected: (state, { payload }) => {
      state.checkinOutboundSelected = payload
    },
    setShowEndedExits: (state, { payload }) => {
      state.plannings = []
      state.page = 1
      state.showEndedExits = payload;
    },
    setOpenPlanningTripPassengers: (state, { payload }) => {
      if (payload.eligibleBookings) {
        state.openPlanningElegiblePassengers = payload.eligibleBookings
      }
      if (payload.passengers) {
        state.openPlanningTripPassengers = payload.passengers.tripBookings
        state.openPlanningTotalBookings = payload.passengers.totalPassengers
      }
      // state.openPlanningTripPassengers = payload.passengers.tripBookings
      // state.openPlanningTotalBookings = payload.passengers.totalPassengers
    },
    setStopStatus: (state, { payload }) => {
      if (payload) {
        const selectedVehicle = payload.selectedVehicle
        const outboundTripStops = payload.planning.outbound
        const inboundTripStops = payload.planning.inbound

        const outboundS = payload.openPlanningOutboundStops
        const inboundS = payload.openPlanningInboundStops
        const planningActivities = payload.planning.activities

        const outS = outboundS.map((stop) => {
          if (outboundTripStops.find(st => st.stop.id === stop.stop.id)?.details.find(a => a.vehicle?.uuid === selectedVehicle)) {
            const time = outboundTripStops.find(st => st.stop.id === stop.stop.id).time
            const details = outboundTripStops.find(st => st.stop.id === stop.stop.id).details

            return {
              ...stop,
              time: { value: time, valid: true, message: "" },
              isActive: { value: true, valid: true, message: "" },
              stop: {
                ...stop.stop,
                details: details.map(det => {
                  return {
                    ...det,
                    activities: planningActivities.map(act => {
                      if (det.activities.find(a => a.activity.id === act.activity.id)) {
                        return {
                          ...act,
                          isOn: { value: true, valid: true, message: "" }
                        }
                      }
                      return {
                        ...act,
                        isOn: { value: false, valid: true, message: "" }
                      }
                    })
                  }
                })
              }
            }
          }
          else {
            //when we want to assign a new vehicle(a reinforcement).
            //Only openPlanningOutboundStops has the info to assign to the new vehicle, so we get that info from this array
            return stop
          }
        })

        const inS = inboundS.map((stop) => {
          if (inboundTripStops.find(st => st.stop.id === stop.stop.id)?.details.find(a => a.vehicle?.uuid === selectedVehicle)) {
            const time = inboundTripStops.find(st => st.stop.id === stop.stop.id).time
            const details = inboundTripStops.find(st => st.stop.id === stop.stop.id).details

            return {
              ...stop,
              time: { value: time, valid: true, message: "" },
              isActive: { value: true, valid: true, message: "" },
              stop: {
                ...stop.stop,
                details: details.map(det => {
                  return {
                    ...det,
                    activities: planningActivities.map(act => {
                      if (det.activities.find(a => a.activity.id === act.activity.id)) {
                        return {
                          ...act,
                          isOn: { value: true, valid: true, message: "" }
                        }
                      }
                      return {
                        ...act,
                        isOn: { value: false, valid: true, message: "" }
                      }
                    })
                  }
                })
              }
            }
          }
          else {
            //when we want to assign a new vehicle(a reinforcement).
            //Only openPlanningInboundStops has the info to assign to the new vehicle, so we get that info from this array
            return stop
          }
        })
        state.openPlanningOutboundStops = outS
        state.openPlanningInboundStops = inS
      }
    },
    onReinforcementCapacityValidation: (state, { payload }) => {
      state.reinforcementCapacity = payload.form.reinforcementCapacity
    },
    onSelectedVehicleValidation: (state, { payload }) => {
      state.selectedVehicle = payload.form.selectedVehicle
    },
    onOutboundStopsValidation: (state, { payload }) => {
      state.openPlanningOutboundStops = payload.openPlanningOutboundStops.map(st => {
        if (payload.outboundStopsValidation.form.find(s => s.stop.id === st.stop.id)) {
          return payload.outboundStopsValidation.form.find(s => s.stop.id === st.stop.id)
        }
        return st
      })
    },
    onInboundStopsValidation: (state, { payload }) => {
      state.openPlanningInboundStops = payload.openPlanningInboundStops.map(st => {
        if (payload.inboundStopsValidation.form.find(s => s.stop.id === st.stop.id)) {
          return payload.inboundStopsValidation.form.find(s => s.stop.id === st.stop.id)
        }
        return st
      })
    },
    onSubmitReinforcement: () => { },
    onSubmitCircuitUpdate: () => { },
    onSubmitRemoveVehicle: () => { },
    onSubmitReplaceVehicle: () => { },
    populateFiltersOnMount: (state, { payload }) => {
      state.startDate = payload.startDate
      state.endDate = payload.endDate
      state.activityFilter = payload.activityFilter
      state.vehicleFilter = payload.vehicleFilter
      state.departureFilter = payload.departureFilter
      state.arrivalFilter = payload.arrivalFilter
      state.selectedDay = payload.selectedDay
      state.showEndedExits = payload.showEndedExits
    },
    setTripsDetails: (state, { payload }) => {
      const details: any = Array.from(payload.oldDetails)

      const vehiclesBoarding = payload.planningInfo?.outbound?.concat(payload.planningInfo?.inbound)
        .flatMap(ob => ob.details)
        .filter(detail => detail.boarding?.startedAt && !detail.boarding?.endedAt);

      details.push({
        id: payload.id,
        outbound: payload.planningInfo.outbound,
        inbound: payload.planningInfo.inbound,
        boardings: vehiclesBoarding
      })

      state.tripsDetails = details
    }
  }
})

