import React from 'react'
// Styles
import theme from 'ui/theme';
import * as Styles from './styles'
import { Icon } from '../../styles';
import Beach from 'assets/icons/beach'
// Icons
import StatusLabel from 'assets/icons/status_label'
import PartnerStatusLabel from 'assets/icons/partner_status_label'
import WebsiteStatusLabel from 'assets/icons/website_status_label'

// Types 
import type { PlanningListItem } from 'types/trips/plannings';

type PropTypes = {
  planning: PlanningListItem,
}

const PaddingCircuit = ({ planning }: PropTypes) => {
  const _renderAvailabilityInfo = () => {
    return (planning.availableForTeamMembers || planning.availableForAgents || planning.availableForPublic) && (
      <Styles.AvailabilityInfo>
        {planning.availableForTeamMembers && <StatusLabel fill={theme().gray400Color} />}
        {planning.availableForAgents && <PartnerStatusLabel fill={theme().gray400Color} />}
        {planning.availableForPublic && <WebsiteStatusLabel fill={theme().gray400Color} />}
      </Styles.AvailabilityInfo>
    )
  }

  const _renderActivityLabel = () => {
    return planning.activities.find(act => act.activity.isHopOnHopOff) && (
      <Styles.ActivityLabel>{planning.activities.find(act => act.activity.isHopOnHopOff)?.activity.shortName}</Styles.ActivityLabel>
    )
  }


  return (
    <Styles.PadCircuit>
      <Styles.PadCircuitInfo>
        <Icon marginr='20'>
          <Beach fill={theme().darkColor} />
        </Icon>
        <Styles.TitleActivity>{planning.activities[0]?.activity.name}</Styles.TitleActivity>
        {_renderActivityLabel()}
      </Styles.PadCircuitInfo>
      <Styles.PadCircuitRightInfo>
        {_renderAvailabilityInfo()}
      </Styles.PadCircuitRightInfo>
    </Styles.PadCircuit>
  )
}

export default PaddingCircuit