/**
 * Sidebar component
 *
 * @author João Dias <jdias@ubiwhere.com>
 *
 *
 */

import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import SimpleBar from 'simplebar-react'
import { CSidebar, CSidebarNav } from '@coreui/react-pro'
// Utils
import { AppSidebarNav, navigation } from './utils'
//icons
import AnimarisLogoMasterHorizontal from 'assets/icons/logo/animarisLogoMasterHorizontalInvertido'
import tourismLogo from 'assets/icons/logo/turismo-portugal.svg'
// Store
import { RootState, actions } from 'store/rootSlices'
// Styles
import { Wrapper, CustomBrand, LogoWrapper } from './styles'
import '@coreui/coreui/dist/css/coreui.min.css'
import 'simplebar/dist/simplebar.min.css'



const Sidebar = (props) => {
  const { t } = useTranslation()
  const { setUnfoldability } = actions.Dashboard
  const { isSidebarOpen } = useSelector((state: RootState) => state.Dashboard)
  const dispatch = useDispatch()

  return (
    <Wrapper open={isSidebarOpen}>
      <CSidebar
        id="sidebar"
        visible={isSidebarOpen}
        onVisibleChange={(visible) => {
          dispatch(setUnfoldability(visible))
        }}
      >
        <CustomBrand >
          <AnimarisLogoMasterHorizontal width={170} />
        </CustomBrand>
        <CSidebarNav>
          <SimpleBar>
            <AppSidebarNav
              items={navigation}
              onClick={() => { }} />
          </SimpleBar>
        </CSidebarNav>

        <LogoWrapper>
          <p>{t('common.finance')}</p>
          <img src={tourismLogo} alt="Portugal Tourism Logo" />
        </LogoWrapper>
        
      </CSidebar>
    </Wrapper>
  )
}

export default Sidebar