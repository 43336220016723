import * as Check from 'validations';

import {
    PlanningDatesValidation,
    PlanningServicePassengerTypesValidation,
    PlanningServiceValidation,
    PlanningVehiclesValidation, /* PlanningStopsValidation */
    PlanningCargoValidation,
    PlanningCircuitValidation,
    PlanningCargoValidationNoCargo,
    PlanningStopsValidation,
    PlaninngMaxCapacityValidation,
    PlaninngMinimumPriceValidation,
    PlaninngIncludedSeatsValidation
} from '../utils';
import { putResolve, call, select } from 'redux-saga/effects'
import { actions, RootState } from 'store/rootSlices'
import { toast } from 'react-toastify';
import i18n from 'i18next';
import API from 'api'

export function* prepareBeachActivity() {

    const {
        updatedActivities,
        edit,
        editDate,
        dateType,               // doesnt need validation
        rules,                  // dont need validation
        dates,
        services,
        beachLocation
    } = yield select((state: RootState) => state.TripPlanningsForm);

    //* Validations
    const datesValidation = Check.checkListValidation(dates, PlanningDatesValidation)
    /* const servicesValidation = Check.checkListValidation(services.map((serv) => {
        return {
            activity: serv.serviceDetails.id
        }
    }), PlanningServiceValidation) */

    if (datesValidation.invalid /* || servicesValidation.invalid */) {

        yield putResolve(actions.TripPlanningsForm.onDateValidations(datesValidation))
        /* yield putResolve(actions.TripPlanningsForm.onServiceValidations({ servicesValidation, servicesPassengerTypesValidation, services })) */

        toast.error(i18n.t("toasts.invalidFields"))
        yield putResolve(actions.TripPlanningsForm.setSubmitButtonDisabled(false))
    } else {

        const finalForm = {
            tripType: "beach",
            maxCapacity: 1,
            allowOverbooking: rules.allowOverbooking,
            availableForAgents: rules.availableForAgents,
            availableForPublic: rules.availableForPublic,
            availableForTeamMembers: rules.availableForTeamMembers,
            dateType: dateType,
            location: beachLocation.value,
            dates: edit ? [editDate] : dates.map((d) => d.date.value), //editFirstDate ? [editDate] : dates.map((d) => d.date.value),
            ...(
                updatedActivities &&
                {
                    activities: services.map((serv) => {
                        return {
                            activity: serv.serviceDetails.id.value,
                            duration: serv.serviceDetails.duration,
                            extras: serv.extras.filter(extra => extra.isOn.value === true).map((extra) => {
                                return {
                                    extra: extra.id,
                                    maxCapacity: extra.maxCapacity.value,
                                    price: extra.price.value
                                }
                            })
                        }
                    })
                }
            ),
        }

        yield prepareCommonSteps(finalForm)
    }
}

export function* prepareGenericActivity() {

    const {
        addCargo,
        updatedActivities,
        updatedCircuit,
        edit,
        editDate,
        dateType,               // doesnt need validation
        rules,                  // dont need validation
        maxCapacity,
        planningCircuit,
        vehicles,
        dates,
        cargo,
        outboundStops,
        inboundStops,
        services,
        minimumPrice,
        includedSeats
    } = yield select((state: RootState) => state.TripPlanningsForm);

    //* Validations
    const datesValidation = Check.checkListValidation(dates, PlanningDatesValidation)
    const vehiclesValidation = Check.checkListValidation(vehicles, PlanningVehiclesValidation)
    const maxCapacityValidation = Check.checkValidation({ maxCapacity: maxCapacity }, PlaninngMaxCapacityValidation)
    const servicesValidation = Check.checkListValidation(services.map((serv) => {
        return {
            activity: serv.serviceDetails.id
        }
    }), PlanningServiceValidation)
    const servicesPassengerTypesValidation = services.map(serv => {
        const val = Check.checkListValidation(serv.passengerTypes.filter(pt => pt.isOn.value === true).map(pt => {
            return {
                id: { value: pt.id, valid: true, message: "" },
                priceForIsland: pt.priceForIsland,
                price: pt.price
            }
        }), PlanningServicePassengerTypesValidation)
        return val
    })
    const circuitValidation = Check.checkValidation(planningCircuit, PlanningCircuitValidation)
    const outboundStopsValidaton = outboundStops.map(st => {
        const val = Check.checkListValidation(st.stop.details.map(det => {

            return {
                time: st.time,
                uuid: { value: det.vehicle?.uuid, valid: true, message: "" }
            }
        }), PlanningStopsValidation)

        //this is not the correct object structure (is corrected on 'OnStopsValidation' method)
        //this returns info from the original stop(...st) and the fields that were validated(val) 
        return {
            ...st,
            val
        }
    })
    const inboundStopsValidaton = inboundStops.map(st => {
        const val = Check.checkListValidation(st.stop.details.map(det => {
            return {
                time: st.time,
                uuid: { value: det.vehicle?.uuid, valid: true, message: "" }
            }
        }), PlanningStopsValidation)

        //this is not the correct object structure (is corrected on 'OnStopsValidation' method)
        //this returns info from the original stop(..st) and the fields that were validated(val) 
        return {
            ...st,
            val
        }
    })
    const cargoValidation = Check.checkListValidation(cargo, addCargo ? PlanningCargoValidation : PlanningCargoValidationNoCargo)

    const minimumPriceValidation = Check.checkValidation({ minimumPrice }, PlaninngMinimumPriceValidation)
    const includedSeatsValidation = Check.checkValidation({ includedSeats }, PlaninngIncludedSeatsValidation)


    //* Check if the first service is private
    const isPrivateService = services[0].serviceDetails.isPrivate

    //* Check the invalid field of all shared validations (validation for all type of activities)
    let isFormInvalid: boolean = (
        datesValidation.invalid ||
        vehiclesValidation.invalid ||
        servicesValidation.invalid ||
        maxCapacityValidation.invalid ||
        servicesPassengerTypesValidation.invalid ||
        circuitValidation.invalid ||
        cargoValidation.invalid ||
        outboundStopsValidaton.find(v => v.invalid === true) ||
        inboundStopsValidaton.find(v => v.invalid === true)
    )

    //* If the first service is private, we add the private service validations
    if(isPrivateService) { 
        isFormInvalid = isFormInvalid || minimumPriceValidation.invalid || includedSeatsValidation.invalid
    }


    if (isFormInvalid) {

        if(isPrivateService){ 
            yield putResolve(actions.TripPlanningsForm.onMinimumPriceValidation(minimumPriceValidation))
            yield putResolve(actions.TripPlanningsForm.onIncludedSeatsValidation(includedSeatsValidation))
        }

        yield putResolve(actions.TripPlanningsForm.onDateValidations(datesValidation))
        yield putResolve(actions.TripPlanningsForm.onVehicleValidations(vehiclesValidation))
        yield putResolve(actions.TripPlanningsForm.onMaxCapacityValidation(maxCapacityValidation))
        yield putResolve(actions.TripPlanningsForm.onServiceValidations({ servicesValidation, servicesPassengerTypesValidation, services }))
        yield putResolve(actions.TripPlanningsForm.onCircuitValidations(circuitValidation))
        yield putResolve(actions.TripPlanningsForm.onStopsValidations({ outboundStops, inboundStops, outboundStopsValidaton, inboundStopsValidaton }))
        yield putResolve(actions.TripPlanningsForm.onCargoValidations(cargoValidation))
        toast.error(i18n.t("toasts.invalidFields"))
        yield putResolve(actions.TripPlanningsForm.setSubmitButtonDisabled(false))
    }
    else {

        //** Here we define the stops that will be submited in the finalForm
        let toSubmitStops = outboundStops.filter(stop => stop.isEdited).map((s) => {

            //* We send all activities ON for the current iterated stop;
            //* We only send details array if planning has vehicles;
            return {
                stop: s.stop.id,
                time: s.time.value,
                isOutbound: true,
                activities: s.activities.filter((act) => act.isOn.value).map((act) => act.id),
                ...(
                    vehicles.filter(v => v.uuid.value !== "").length > 0 ? {
                        details: s.stop.details.map((det) => {
                            return {
                                vehicle: det.vehicle.uuid,
                                activities: det.activities.filter((act) => act.isOn.value).map((act) => act.id)
                            }
                        })
                    } : null
                )
            }

        }).concat(inboundStops.filter(stop => stop.isEdited).map((s) => {

            return {
                stop: s.stop.id,
                time: s.time.value,
                isOutbound: false,
                activities: s.activities.filter((act) => act.isOn.value).map((act) => act.id),
                ...(
                    vehicles.filter(v => v.uuid.value !== "").length > 0 ? {
                        details: s.stop.details.map((det) => {
                            return {
                                vehicle: det.vehicle.uuid,
                                activities: det.activities.filter((act) => act.isOn.value).map((act) => act.id)
                            }
                        })
                    } : null
                )
            }
        }))

        const finalForm = {
            tripType: "activity",
            maxCapacity: Number(maxCapacity.value),
            allowOverbooking: rules.allowOverbooking,
            availableForAgents: rules.availableForAgents,
            availableForPublic: rules.availableForPublic,
            availableForTeamMembers: rules.availableForTeamMembers,
            dateType: dateType,
            ...(isPrivateService && { minimumPrice: Number(minimumPrice.value) }),
            ...(isPrivateService && { includedSeats: Number(includedSeats.value) }),
            ...(updatedCircuit && { circuit: Number(planningCircuit.circuit.value) }),
            ...(vehicles.filter(v => v.uuid.value !== "").length > 0 && {
                vehicles: vehicles.filter(v => v.uuid.value !== "").map((vhc) => {
                    return vhc.uuid.value
                })
            }),
            dates: edit ? [editDate] : dates.map((d) => d.date.value), //editFirstDate ? [editDate] : dates.map((d) => d.date.value),
            cargo: addCargo ? cargo.map((c) => {
                return {
                    description: c.observations.value,
                    destination: c.destination.value,
                    receiver: c.recipient.value
                }
            }) : [],
            ...(
                updatedActivities &&
                {
                    activities: services.map((serv) => {
                        return {
                            activity: serv.serviceDetails.id.value,
                            duration: serv.serviceDetails.duration,
                            passengerTypes: serv.passengerTypes.filter(pt => pt.isOn.value === true).map((pt) => {
                                return {
                                    passengerType: pt.id,
                                    price: pt.price.value.replace(",", "."),
                                }
                            })
                        }
                    })
                }
            ),
            ...(toSubmitStops.length ? { stops: toSubmitStops } : null)
        }

        yield prepareCommonSteps(finalForm)
    }

}

function* prepareCommonSteps(finalForm: any) {

    const {
        planningID,
        edit,
        editFirstDate,
    } = yield select((state: RootState) => state.TripPlanningsForm);

    try {
        if (!edit) {
            const planning = yield call(API.Plannings.CreatePlanning, finalForm)

            if (planning) {
                yield putResolve(actions.App.navigateTo('/viagens/agendamentos'))
            }
        }
        else {

            const finalFormEdit = editFirstDate ? { ...finalForm, updateRelatedTrips: 0 } : { ...finalForm, updateRelatedTrips: 1 }
            const planning = yield call(API.Plannings.EditPlanning, finalFormEdit, planningID)


            if (Array.isArray(planning) && planning.length) {
                yield putResolve(actions.TripPlanningsForm.populateSuspendedTripsArray(planning))
                yield putResolve(actions.TripPlanningsForm.setShowSuspendedTripsModal(true))
            }
            else if (planning.is_overbooked === true) {
                yield putResolve(actions.TripPlanningsForm.setShowIsOverbookedModal(planning.is_overbooked))
            }
            else {
                yield putResolve(actions.App.navigateTo('/viagens/agendamentos'))
            }
        }
    } catch (error) {
        yield putResolve(actions.TripPlanningsForm.setSubmitButtonDisabled(false))
    } finally {

    }
}