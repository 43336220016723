import type { InitialStateProps } from "scenes/BookingForm/logic/initialState"
import { BookingPassengerTypeForm, BookingPaymentEntry } from 'types/trips/bookings';
// Utils
import {
  addFeeEntrie,
  addEntrie,
  getEntriesTotalWithoutDiscount,
  updateEntriesIsRefund,
  updateEntriesTotal,
  getCorrectionValue,
  getTripTotalAmount,
  updateEntryAmount
} from 'scenes/BookingForm/logic/utils'
import {
  updatePrivatePassengerPrices,
  sortPassengerTypesByPrice,
  resetPassengersTotalLegPrice,
  getTotalPassengersQty,
  checkIfSelectedPassAreSameAsBookedPass,
  getTotalPassengerLegPrice,
  getNewPassengerTypes
} from '../utils'

const PassengerTypesReducers = {
  resetSelectedPassengerTypes: (state: InitialStateProps, { payload }) => {
    state.selectedPassengerTypes = payload;
  },
  populateSelectedPassengerTypes: (state: InitialStateProps, { payload }) => {
    if (state.edit) {
      const hasOutbound = payload.outboundTrip && payload.outboundTrip.tripActivityLeg;
      const hasInbound = payload.inboundTrip && payload.inboundTrip.tripActivityLeg;

      const isOutboundPrivate = hasOutbound && payload.outboundTrip.tripActivityLeg.activity.isPrivate;
      const isInboundPrivate = hasInbound && payload.inboundTrip.tripActivityLeg.activity.isPrivate;

      //* Loop trough all the passenger types
      const selectedPTypes: BookingPassengerTypeForm[] = state.selectedPassengerTypes.map((c) => {
        //* Check if this book has the current iterated passenger type
        let bookingPType = payload.passengerTypes.find((p) => p.id === c.id);
        if (bookingPType) {

          let newPType: BookingPassengerTypeForm = {
            ...c,
            price: +bookingPType.price,
            quantity: { 
              ...c.quantity,
              value: bookingPType.quantity.toString()
            },
            isDisabled: false,
          };

          //* Here we check if there is an outbound trip and if so we update the outbound price and outbound total price
          //* with the price of the passenger type in the activity leg
          if(hasOutbound) {
            const activityPassenger = payload.outboundTrip.tripActivityLeg.activity.passengerTypes.find((p) => p.id === c.id);
            newPType.priceOutbound = +activityPassenger.price
            newPType.totalPriceOutbound = (bookingPType.quantity * activityPassenger.price).toString()
          } else {
            newPType.priceOutbound = 0
            newPType.totalPriceOutbound = '0'
          }

          //* Here we check if there is an inbound trip and if so we update the inbound price and inbound total price
          //* with the price of the passenger type in the activity leg
          if(hasInbound) {
            const activityPassenger = payload.inboundTrip.tripActivityLeg.activity.passengerTypes.find((p) => p.id === c.id);
            newPType.priceInbound = +activityPassenger.price
            newPType.totalPriceInbound = (bookingPType.quantity * activityPassenger.price).toString()
          } else {
            newPType.priceInbound = 0
            newPType.totalPriceInbound = '0'
          }

          newPType.totalPrice = (Number(newPType.totalPriceOutbound) + Number(newPType.totalPriceInbound)).toString()

          return newPType;
        } else {
          return { ...c, quantity: { ...c.quantity } };
        }
      });

      let totalPassengers = getTotalPassengersQty(selectedPTypes)

      //* Here we handle the outbound private trip if there is one
      if(isOutboundPrivate) {
        // Sort passenger types by price in descending order
        sortPassengerTypesByPrice(selectedPTypes, true)

        // Calculate included seats
        const includedSeats = payload.outboundTrip.tripActivityLeg.includedSeats;

        if(totalPassengers > includedSeats) {
          updatePrivatePassengerPrices(selectedPTypes, includedSeats, true)
        } else {
          resetPassengersTotalLegPrice(selectedPTypes, true)
        }
      }

      //* Here we handle the inbound private trip if there is one
      if(isInboundPrivate) {
        // Sort passenger types by price in descending order
        sortPassengerTypesByPrice(selectedPTypes, false)
  
        // Calculate included seats
        const includedSeats = payload.inboundTrip.tripActivityLeg.includedSeats;
  
        if(totalPassengers > includedSeats) {
          updatePrivatePassengerPrices(selectedPTypes, includedSeats, false)
        } else {
          resetPassengersTotalLegPrice(selectedPTypes, false)
        }
      }

      state.bookedPassengerTypes = selectedPTypes
      state.selectedPassengerTypes = selectedPTypes;
      state.totalPassengers = totalPassengers
      state.bookedTotalPassengers = totalPassengers
    }
  },
  setEditPassengerTypes: (state: InitialStateProps, { payload }) => {
    const hasOutbound = payload.outbound.tripActivityLeg.id > 0;
    const hasInbound = payload.inbound.tripActivityLeg.id > 0;
  
    const isOutboundPrivate = hasOutbound && payload.outbound.tripActivityLeg.activity.isPrivate;
    const isInboundPrivate = hasInbound && payload.inbound.tripActivityLeg.activity.isPrivate;

    //* Calculate new passengers and new total passengers
    const newPTypes: BookingPassengerTypeForm[] = getNewPassengerTypes(state.selectedPassengerTypes, payload.id, payload.value, state.allowOverbooking)

    let totalPassengers = getTotalPassengersQty(newPTypes)

    //* Here we handle the outbound private trip if there is one
    if(isOutboundPrivate) {
      sortPassengerTypesByPrice(newPTypes, true)
  
      const includedSeats = payload.outbound.tripActivityLeg.includedSeats;

      if(totalPassengers > includedSeats) {
        updatePrivatePassengerPrices(newPTypes, includedSeats, true)
      } else {
        resetPassengersTotalLegPrice(newPTypes, true)
      }
    }

    //* Here we handle the inbound private trip if there is one
    if(isInboundPrivate) {
      sortPassengerTypesByPrice(newPTypes, false)

      const includedSeats = payload.inbound.tripActivityLeg.includedSeats;

      if(totalPassengers > includedSeats) {
        updatePrivatePassengerPrices(newPTypes, includedSeats, false)
      } else {
        resetPassengersTotalLegPrice(newPTypes, false)
      }
    }

    state.selectedPassengerTypes = newPTypes;
    state.totalPassengers = totalPassengers

    //* Checks if new passengers are the same as the booked
    let samePassengers = checkIfSelectedPassAreSameAsBookedPass(state.bookedPassengerTypes, newPTypes)
    state.samePassengers = samePassengers

    let entries: BookingPaymentEntry[] = state.entries

    //* Apply taxes if new total passagenrs is less than before and if there where no taxes already
    if (totalPassengers < state.bookedTotalPassengers) {
      if (state.outbound.id > 0) {
        let hasOutboundFeeEntrie = entries.some((e) => e.isFee && e.leg?.isOutbound)
        if (!hasOutboundFeeEntrie) {
          entries = addFeeEntrie(true, true, entries, state.outbound, state.applyFeeToTrips, state.tripsCancelRescheduleSettings)
        }
      }

      if (state.inbound.id > 0) {
        let hasInboundFeeEntrie = entries.some((e) => e.isFee && !e.leg?.isOutbound)
        if (!hasInboundFeeEntrie) {
          entries = addFeeEntrie(false, true, entries, state.inbound, state.applyFeeToTrips, state.tripsCancelRescheduleSettings)
        }
      }
    } else {
      if (state.outbound.id > 0) {
        entries = entries.filter((e) => !(e.isFee && e.leg?.isOutbound && e.isBecausePassenger))
      }
      if (state.inbound.id > 0) {
        entries = entries.filter((e) => !(e.isFee && !e.leg?.isOutbound && e.isBecausePassenger))
      }
    }

    //* Here I calculate the diference between the bookedPassengerTypes and the new value
    let calculatedPassengerTypes: BookingPassengerTypeForm[] = []
    calculatedPassengerTypes = state.selectedPassengerTypes.map((passenger, idx) => {
      if (passenger.quantity.value === state.bookedPassengerTypes[idx].quantity.value) {
        // If the number of passengers didn´t change the amount added will be 0;
        return {
          ...passenger,
          quantity: { ...passenger.quantity, value: "0" }
        }
      } else {
        return {
          ...passenger,
          quantity: { 
            ...passenger.quantity, 
            value: (Number(passenger.quantity.value) - Number(state.bookedPassengerTypes[idx].quantity.value)).toString() 
          }
        }
      }
    })

    //* Variables needed bellow
    let calOutboundAmount = 0
    let calInboundAmount = 0

    calOutboundAmount = +(
      getTripTotalAmount(calculatedPassengerTypes, 'outbound') - calOutboundAmount
    ).toFixed(2);

    calInboundAmount = +(
      getTripTotalAmount(calculatedPassengerTypes, 'inbound') - calInboundAmount
    ).toFixed(2);


    if(isOutboundPrivate) {
      let selectedPassengersTotalOutbound: number = getTotalPassengerLegPrice(state.selectedPassengerTypes, true)

      let bookedPassengersTotalOutbound: number = getTotalPassengerLegPrice(state.bookedPassengerTypes, true)

      if(selectedPassengersTotalOutbound === bookedPassengersTotalOutbound) { 
        calOutboundAmount = 0
      } else {
        calOutboundAmount = calOutboundAmount = selectedPassengersTotalOutbound - bookedPassengersTotalOutbound
      }
    }

    if(isInboundPrivate) {
      let selectedPassengersTotalInbound: number = getTotalPassengerLegPrice(state.selectedPassengerTypes, false)

      let bookedPassengersTotalInbound: number = getTotalPassengerLegPrice(state.bookedPassengerTypes, false)

      if(selectedPassengersTotalInbound === bookedPassengersTotalInbound) { 
        calInboundAmount = 0
      } else {
        calInboundAmount = calInboundAmount = selectedPassengersTotalInbound - bookedPassengersTotalInbound
      }
    }

    if (!samePassengers) {
      if (state.outbound.tripActivityLeg.id > 0) {
        //* Remove all pending refund entries that don´t have an id (it means they were still not saved in the DB)
        entries = entries.filter((e) => !(e.leg?.isOutbound && e.state === 'pending' && !e.id && !e.isFee))

        //* add new pending entrie
        entries.push(addEntrie(true, true, state.outbound, calOutboundAmount));
      }

      if (state.inbound.tripActivityLeg.id > 0) {
        //* Remove all pending refund entries that don´t have an id (it means they were still not saved in the DB)
        entries = entries.filter((e) => !(!e.leg?.isOutbound && e.state === 'pending' && !e.isFee && !e.id))

        //* add new pending entrie
        entries.push(addEntrie(true, true, state.inbound, calInboundAmount));
      }

    } else {
      entries = state.initialEntries
    }

    state.entries = entries;

    state.paymentForm.correction.value = getCorrectionValue(state.entries, state.paymentForm, state.isFree, true);
    state.entriesTotal = Math.abs(
      +updateEntriesTotal(state.entries, state.paymentForm, true).toFixed(2)
    );
    state.showPaymentForm = getEntriesTotalWithoutDiscount(state.entries, true) !== 0;
    state.entriesIsRefund = updateEntriesIsRefund(
      +updateEntriesTotal(state.entries, state.paymentForm, true).toFixed(2)
    );
    state.paymentForm.isRefund = updateEntriesIsRefund(
      +updateEntriesTotal(state.entries, state.paymentForm, true).toFixed(2)
    );
  },
  setSelectedPassengerTypes: (state: InitialStateProps, { payload }) => {
    const hasOutbound = payload.outbound.tripActivityLeg.id > 0;
    const hasInbound = payload.inbound.tripActivityLeg.id > 0;
  
    const isOutboundPrivate = hasOutbound && payload.outbound.tripActivityLeg.activity.isPrivate;
    const isInboundPrivate = hasInbound && payload.inbound.tripActivityLeg.activity.isPrivate;

    const newPTypes: BookingPassengerTypeForm[] = getNewPassengerTypes(state.selectedPassengerTypes, payload.id, payload.value, state.allowOverbooking)

    let totalPassengers = getTotalPassengersQty(newPTypes)

    if(isOutboundPrivate) {
      // Sort passenger types by price in descending order
      sortPassengerTypesByPrice(newPTypes, true)
  
      // Calculate included seats
      const includedSeats = payload.outbound.tripActivityLeg.includedSeats;

      if(totalPassengers > includedSeats) {
        updatePrivatePassengerPrices(newPTypes, includedSeats, true)
      } else {
        resetPassengersTotalLegPrice(newPTypes, true)
      }
    }

    if(isInboundPrivate) {
      // Sort passenger types by price in descending order
      sortPassengerTypesByPrice(newPTypes, false)

      // Calculate included seats
      const includedSeats = payload.inbound.tripActivityLeg.includedSeats;

      if(totalPassengers > includedSeats) {
        updatePrivatePassengerPrices(newPTypes, includedSeats, false)
      } else {
        resetPassengersTotalLegPrice(newPTypes, false)
      }
    }

    state.selectedPassengerTypes = newPTypes;
    state.totalPassengers = totalPassengers
    state.entries = updateEntryAmount(
      state.outbound,
      state.inbound,
      state.selectedPassengerTypes,
      state.entries
    );
    state.paymentForm.correction.value = getCorrectionValue(state.entries, state.paymentForm, state.isFree, true);
    state.entriesTotal = +updateEntriesTotal(state.entries, state.paymentForm).toFixed(2);
    state.showPaymentForm = getEntriesTotalWithoutDiscount(state.entries) !== 0;
    state.entriesIsRefund = updateEntriesIsRefund(state.entriesTotal);
    state.paymentForm.isRefund = updateEntriesIsRefund(state.entriesTotal);
  },
}

export default PassengerTypesReducers