/**
 * Tripcampaigncodes scene sagas
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */
 import { AnyAction } from 'redux'
 import API from 'api'
 import { takeLatest, putResolve, call } from 'redux-saga/effects'
import { actions } from 'store/rootSlices'
import { toast } from 'react-toastify'
import i18n from 'i18next';

function* onMountSaga({ payload }: AnyAction) {

  if (payload.location.pathname.includes('/viagens/configuracoes/campanhas/codigos')) {

    const campaignID = payload.location.pathname.split("/")[5]
    const campaignPrefix = payload.location.pathname.split("/")[6]

    try {
      const campaignCodes = yield call(API.Campaigns.GetCampaignCodes,campaignID) 
      
      if (campaignCodes){
        yield putResolve(actions.TripCampaignCodes.setCampaignCodeID(campaignID))
        yield putResolve(actions.TripCampaignCodes.setCampaignPrefix(campaignPrefix))
        yield putResolve(actions.TripCampaignCodes.setCampaignCodes(campaignCodes))
      }
    } catch (error) {
      toast.error(i18n.t("toasts.campaignCodesGetError"))
    }
  }
  
}

function* onUnmountSaga() {}

export default function* watcherSignin() {
  yield takeLatest('Tripcampaigncodes/onMount', onMountSaga)
  yield takeLatest('Tripcampaigncodes/onUnmount', onUnmountSaga)
}


