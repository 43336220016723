/**
 * Tripplanningsform scene slice
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */
import { createSlice } from '@reduxjs/toolkit'
import { CargoTypeForm, PlanningDatesForm, PlanningRulesForm, PlanningServiceForm, PlanningStopsForm, PlanningVehiclesForm, ServiceTypes } from 'types/trips/plannings'
import { DateTime } from 'luxon';
import config from 'config'
import { ActivityExtrasForm, ActivityPassengerTypesForm } from 'types/trips/activity/activityForm';
import initialState from './initialState';
// Other Reducers
import CargoReducers from '../components/CargosRows/logic/reducers';
import CircuitsReducers from '../components/CircuitsRows/logic/reducers'
import ServicesReducers from '../components/ServicesRows/logic/reducers';
import VehiclesReducers from '../components/VehiclesRows/logic/reducers';
import { ExtrasListItem } from 'types/trips/extras';

export default createSlice({
  name: 'Tripplanningsform',
  initialState,
  reducers: {
    ...CargoReducers,
    ...CircuitsReducers,
    ...ServicesReducers,
    ...VehiclesReducers,
    onMount: (state, { payload }) => { },
    onUnmount: () => {
      return initialState
    },
    onSubmit: () => { },
    setPlanningLegsForm: (state, { payload }) => {
      let planningLegsForm = payload?.map((act) => {
        return ({
          activity: { ...act.activity },
          legs: act.legs.map((leg) => {
            return {
              ...leg,
              isActive: {
                value: leg.isActive,
                valid: true,
                message: ""
              }
            }
          })
        })
      })
      state.planningLegsForm = planningLegsForm
    },
    onPlanningLegsFormChange: (state, { payload }) => {
      let selectedAc = state.planningLegsForm[payload.actIdx]

      selectedAc.legs[payload.legIdx] = {
        ...selectedAc.legs[payload.legIdx],
        isActive: {
          ...selectedAc.legs[payload.legIdx].isActive,
          value: !selectedAc.legs[payload.legIdx].isActive.value
        }
      }

      state.planningLegsForm[payload.actIdx] = selectedAc
    },
    onPlanningLegsFormSave: () => { },
    setPlanningID: (state, { payload }) => {
      state.planningID = payload
    },
    setEdit: (state, { payload }) => {
      state.edit = payload
    },
    setDeletePlanning: (state, { payload }) => { },
    setLoading: (state, { payload }) => {
      state.loading = payload
    },
    setDeleteButtonState: (state, { payload }) => {
      state.deleteButtonState = payload
    },
    setHasEditPermission: (state, { payload }) => {
      state.hasEditPermission = payload
    },
    setSubmitButtonDisabled: (state, { payload }) => {
      state.submitButtonState = payload
    },
    setShowBookingsModal: (state, { payload }) => {
      state.showBookingsModal = payload
    },
    setShowIsOverbookedModal: (state, { payload }) => {
      state.showIsOverbookedModal = payload
    },
    setAffectedBookings: (state, { payload }) => {
      state.affectedBookings = payload
    },
    setShowLegsModal: (state, { payload }) => {
      state.showLegsModal = payload
    },
    setLoadingLegs: (state, { payload }) => {
      state.loadingLegs = payload
    },
    setEditDate: (state, { payload }) => {
      if (payload.editDate) {
        state.editDate = payload.editDate
      }
      state.editFirstDate = payload.editFirstDate
    },
    setPassengerTypesInfo: (state, { payload }) => {
      const passengerTypes: ActivityPassengerTypesForm[] = Array.from([])

      payload.forEach((passengerType) => {

        passengerTypes.push({
          id: passengerType.id,
          name: passengerType.name,
          shortName: passengerType.shortName,
          isActive: passengerType.isActive,
          priceForIsland: { value: "0", valid: true, message: "" },
          price: { value: "0", valid: true, message: "" },
          isOn: { value: true, valid: true, message: "" }
        })
      })
      state.passengerTypes = passengerTypes
    },
    setExtrasInfo: (state, { payload }) => {
      const extras: ActivityExtrasForm[] = Array.from([])

      payload.forEach((extra: ExtrasListItem) => {
        //if there are no items or the extra is not active, we dont need to add the extras to the planning conf.
        //const hasItems = extra.maxCapacity > 0
        const isActive = extra.isActive

        if (isActive /* && hasItems */) {
          extras.push({
            id: extra.id,
            name: extra.name,
            extraMaxCapacity: String(extra.maxCapacity),
            maxCapacity: { value: extra.maxCapacity ? String(extra.maxCapacity) : '0', valid: true, message: "" },
            isActive: { value: extra.isActive, valid: true, message: "" },
            price: { value: "0", valid: true, message: "" },
            isOn: { value: extra.isActive ? true : false, valid: true, message: "" }
          })
        }
      })
      state.extras = extras
    },
    setStopsInfo: (state, { payload }) => {
      const stops: PlanningStopsForm[] = Array.from([])

      payload.forEach((stop) => {
        stops.push({
          time: { value: "", valid: true, message: "" },
          isEdited: false,
          activities: [],
          stop: {
            id: stop.id,
            isActive: stop.isActive,
            isAvailable: stop.isAvailable,
            name: stop.name,
            shortName: stop.shortName,
            details: []
          },
        })
      })

      const reversedStops = Array.from(stops).reverse()
      state.outboundStops = stops
      state.inboundStops = reversedStops
      state.initialOutboundStops = stops
      state.initialInboundStops = reversedStops
    },
    addVehicle: (state, { payload }) => {
      const vehicles: PlanningVehiclesForm[] = Array.from(payload.vehicles)
      vehicles.push({
        uuid: { value: "", valid: true, message: "" },
        name: { value: "", valid: true, message: "" },
        capacity: { value: "", valid: true, message: "" }
      })

      state.vehicles = vehicles
    },
    onRulesChange: (state, { payload }) => {
      const rules: PlanningRulesForm = payload
      state.rules = rules
    },
    onDateValidations: (state, { payload }) => {
      const validatedDates: PlanningDatesForm[] = Array.from(payload.form)
      state.dates = validatedDates
    },
    onVehicleValidations: (state, { payload }) => {
      const validatedVehicles: PlanningVehiclesForm[] = Array.from(payload.form)
      state.vehicles = validatedVehicles
    },
    onServiceValidations: (state, { payload }) => {
      const updated: PlanningServiceForm[] = payload.services.map((serv, servIdx) => {
        return {
          ...serv,
          serviceDetails: {
            ...serv.serviceDetails,
            id: payload.servicesValidation.form[servIdx].activity
          },
          passengerTypes: serv.passengerTypes.map((pt, idx) => {
            if (payload.servicesPassengerTypesValidation[servIdx].form.find(p => p.id.value === pt.id)) {
              const p = payload.servicesPassengerTypesValidation[servIdx].form.find(p => p.id.value === pt.id)
              return {
                ...pt,
                price: p.price
              }
            }
            return pt
          })
        }
      })

      state.services = updated
    },
    onServicesPassengerTypesValidations: (state, { payload }) => {

      const updated: PlanningServiceForm[] = payload.services.map((serv, servIdx) => {
        return {
          ...serv,
          passengerTypes: serv.passengerTypes.map((pt, idx) => {
            if (payload.servicesPassengerTypesValidation[servIdx].form.find(p => p.id.value === pt.id)) {
              const p = payload.servicesPassengerTypesValidation[servIdx].form.find(p => p.id.value === pt.id)
              return {
                ...pt,
                priveForIsland: p.priceForIsland,
                price: p.price
              }
            }
            return pt
          })
        }
      })

      state.services = updated
    },
    onCircuitValidations: (state, { payload }) => {
      state.planningCircuit = payload.form
    },
    onStopsValidations: (state, { payload }) => {

      //complete this
      const outboundsUpdated: PlanningStopsForm[] = payload.outboundStops.map((st, stIdx) => {

        if (payload.outboundStopsValidaton[stIdx]) {
          const time = payload.outboundStopsValidaton[stIdx].time
          return {
            ...st,
            time: time
          }
        }

        return st
      })

      const inboundsUpdated: PlanningStopsForm[] = payload.inboundStops.map((st, stIdx) => {
        if (payload.inboundStopsValidaton[stIdx]) {
          const time = payload.inboundStopsValidaton[stIdx].time
          return {
            ...st,
            time: time
          }
        }

        return st
      })

      state.outboundStops = outboundsUpdated
      state.inboundStops = inboundsUpdated
    },
    onCargoValidations: (state, { payload }) => {
      const validatedCargo: CargoTypeForm[] = Array.from(payload.form)
      state.cargo = validatedCargo
    },
    onMaxCapacityValidation: (state, { payload }) => {
      state.maxCapacity = payload.form.maxCapacity
    },
    onMinimumPriceValidation: (state, { payload }) => { 
      state.minimumPrice = payload.form.minimumPrice
    },
    onIncludedSeatsValidation: (state, { payload }) => {
      state.includedSeats = payload.form.includedSeats
    },
    //POPULATE FORM ON EDIT REDUCERS
    populateCargo: (state, { payload }) => {
      const cargo: CargoTypeForm[] = Array.from([])

      payload.cargo.forEach((c, idx) => {
        cargo.push({
          destination: { value: c.destination, valid: true, message: "" },
          observations: { value: c.description, valid: true, message: "" },
          recipient: { value: c.receiver, valid: true, message: "" }
        })
      })

      if (cargo.length === 0) {
        cargo.push({
          destination: { value: "", valid: true, message: "" },
          observations: { value: "", valid: true, message: "" },
          recipient: { value: "", valid: true, message: "" }
        })
      }

      state.addCargo = payload.cargo.length > 0 ? true : false
      state.cargo = cargo
    },
    populateRules: (state, { payload }) => {
      const rules: PlanningRulesForm = {
        allowOverbooking: payload.allowOverbooking,
        availableForAgents: payload.availableForAgents,
        availableForPublic: payload.availableForPublic,
        availableForTeamMembers: payload.availableForTeamMembers
      }

      state.rules = rules
    },
    populateVehicles: (state, { payload }) => {
      const vehicles: PlanningVehiclesForm[] = Array.from([])

      payload.vehicles.forEach((v, idx) => {
        vehicles.push({
          uuid: { value: v.vehicle.uuid, valid: true, message: "" },
          name: { value: v.vehicle.name, valid: true, message: "" },
          capacity: { value: v.vehicle.capacity, valid: true, message: "" }
        })
      })

      if (payload.vehicles.length === 0) {
        vehicles.push({
          uuid: { value: "", valid: true, message: "" },
          name: { value: "", valid: true, message: "" },
          capacity: { value: "", valid: true, message: "" }
        })
      }

      //selects first vehicle from array by default
      state.selectedVehicle = vehicles.length > 0 ? vehicles[0].uuid.value : ""
      state.vehicles = vehicles
    },
    populateServices: (state, { payload }) => {
      const services: PlanningServiceForm[] = Array.from([])
      const serviceType = payload.planningInfo.parent.tripType
      const activities = payload.activities


      payload.planningInfo.activities.forEach((act) => {
        const activityTemplatePassengerTypes = activities.find(a => a.id === act.activity.id)?.passengerTypes;
        const activityTemplateExtras = activities.find(a => a.id === act.activity.id)?.extras;

        let tripActivityPassengerTypes: ActivityPassengerTypesForm[] = [];
        tripActivityPassengerTypes = act.passengerTypes.map(passengerT => {
          return ({
            id: passengerT.passengerType.id,
            name: passengerT.passengerType.name,
            shortName: passengerT.passengerType.shortName,
            isActive: true,
            priceForIsland: { value: passengerT.priceForIsland, valid: true, message: "" },
            price: { value: passengerT.price, valid: true, message: "" },
            isOn: { value: true, valid: true, message: "" }
          })
        });

        let tripActivityExtras: ActivityExtrasForm[] = [];
        tripActivityExtras = act.extras.map(planningExtra => {
          const actExtraMaxCapacity = activityTemplateExtras.find(a => a.id === planningExtra.extra.id)?.maxCapacity;
          return ({
            id: planningExtra.extra.id,
            name: planningExtra.extra.name,
            extraMaxCapacity: String(actExtraMaxCapacity),
            maxCapacity: { value: String(planningExtra.maxCapacity), valid: true, message: "" },
            isActive: { value: planningExtra.extra.isActive, valid: true, message: "" },
            price: { value: planningExtra.price, valid: true, message: "" },
            isOn: { value: true, valid: true, message: "" }
          })
        });

        // checka se existem passenger types que estão na actividade, mas
        // não estao a ser usados nesta saida, temos de mostrar mas !isOn
        activityTemplatePassengerTypes.forEach(pType => {
          if (!tripActivityPassengerTypes.find(p => p.id === pType.id)) {
            tripActivityPassengerTypes.push({
              id: pType.id,
              name: pType.name,
              shortName: pType.shortName,
              isActive: true,
              priceForIsland: { value: pType.priceForIsland, valid: true, message: "" },
              price: { value: pType.price, valid: true, message: "" },
              isOn: { value: false, valid: true, message: "" }
            });
          }
        });

        // checka se existem extras que estão na actividade, mas
        // não estao a ser usados nesta saida, temos de mostrar mas !isOn
        activityTemplateExtras.forEach(activityExtra => {
          if (!tripActivityExtras.find(extra => extra.id === activityExtra.id)) {
            tripActivityExtras.push({
              id: activityExtra.id,
              name: activityExtra.name,
              extraMaxCapacity: String(activityExtra.maxCapacity),
              maxCapacity: { value: String(activityExtra.maxCapacity), valid: true, message: "" },
              isActive: { value: activityExtra.isActive, valid: true, message: "" },
              price: { value: activityExtra.price, valid: true, message: "" },
              isOn: { value: false, valid: true, message: "" }
            });
          }
        });

        //* Here goes the fields that are common to both activities and beaches
        const commonServiceDetails = {
          id: { value: act.activity.id, valid: true, message: "" },
          shortName: act.activity.shortName,
          duration: act.duration,
          isPrivate: act.activity.isPrivate,
          allowMultipleActivities: act.activity.allowMultipleActivities,
        };

        services.push({
          serviceType: serviceType === "activity" ? ServiceTypes.ACTIVITY : ServiceTypes.BEACH,
          ...(serviceType === "activity" ? { passengerTypes: tripActivityPassengerTypes } : { extras: tripActivityExtras }),
          serviceDetails: serviceType === "activity" ? {
            ...commonServiceDetails,
            generateIslandTrips: act.generateIslandTrips,
            vehicles: payload.activities ? activities.find(a => a.id === act.activity.id).vehicles : [],
            circuits: payload.activities ? activities.find(a => a.id === act.activity.id).circuits : [],
          } :
            {
              ...commonServiceDetails,
              location: payload.planningInfo.location,
            }
        });
      });

      if (serviceType === "beach") {
        state.beachLocation = { value: payload.planningInfo.location, valid: true, message: "" };
      }

      state.maxCapacity = { value: payload.planningInfo.maxCapacity, valid: true, message: "" };
      state.services = services;
    },
    populateCircuit: (state, { payload }) => {
      if (payload.circuit !== null) {
        state.planningCircuit = { circuit: { value: payload.circuit.id.toString(), valid: true, message: "" } }
        if (payload.outbound[0]?.time) {
          state.circuitStartHour = DateTime.fromISO(payload.outbound[0]?.time).toFormat('HH:00')
        }
        state.planningCircuitDefaultDuration = payload.circuit.duration
      }
    },
    populateStops: (state, { payload }) => {
      const outbounds: PlanningStopsForm[] = Array.from(payload.outboundStops)
      const inbounds: PlanningStopsForm[] = Array.from(payload.inboundStops)

      //* Map over all outbound stops
      const outS: PlanningStopsForm[] = outbounds.map((item) => {
        if (payload.planningInfo.outbound.find(st => st.stop.id === item.stop.id)) {
          //* If current stop being iterated exists in planning store it in intersection var
          const currentStop = payload.planningInfo.outbound.find(st => st.stop.id === item.stop.id)

          return {
            time: { value: currentStop.time, valid: true, message: "" },
            isEdited: false,
            activities: payload.planningInfo.activities.map((act) => { //* Map over planning activities
              if (currentStop.activities.find((a) => a.activity.id === act.activity.id)) {
                //* if currentStop has this activity on activities array it means activity is passing ont the stop
                return {
                  id: act.activity.id,
                  shortName: act.activity.shortName,
                  isOn: { value: true, valid: true, message: "" }
                }
              } else {
                //* if currentStop doesn't have this activity on activities array it means activity its not passing ont the stop
                return {
                  id: act.activity.id,
                  shortName: act.activity.shortName,
                  isOn: { value: false, valid: true, message: "" }
                }
              }
            }),
            stop: {
              ...item.stop,
              id: currentStop.stop.id,
              isActive: currentStop.stop.isActive,
              isAvailable: currentStop.stop.isAvailable,
              name: currentStop.stop.name,
              shortName: currentStop.stop.shortName,
              details: payload.planningInfo.vehicles.length !== 0 //* Checks if the planning has a vehicle
                ? (
                  payload.planningInfo.vehicles.map((vhc, idx) => { //* Maps over the vehicles

                    //* Checks if stop had a detail with current iterated vehicle and stores it
                    const detail = currentStop.details.find(d => d.vehicle?.uuid === vhc.vehicle.uuid)
                    return {
                      vehicle: {
                        uuid: vhc.vehicle.uuid,
                        name: vhc.vehicle.name
                      },
                      activities: payload.planningInfo.activities.map((a) => { //* Map over activities available for planning
                        if (detail) {
                          if (detail.activities.find(act => act.activity.id === a.activity.id)) {
                            //* If current detail has an activity equal to the one being iterated it means its active
                            const act = detail.activities.find(act => act.activity.id === a.activity.id)
                            return {
                              id: act.activity.id,
                              shortName: act.activity.shortName,
                              isOn: { value: true, valid: true, message: "" }
                            }
                          }
                          else {
                            //* If current detail does not have an activity equal to the one being iterated it means its not active
                            return {
                              id: a.activity.id,
                              shortName: a.activity.shortName,
                              isOn: { value: false, valid: true, message: "" }
                            }
                          }
                        }
                        else {
                          //* If current detail does not have an activity equal to the one being iterated it means its not active
                          return {
                            id: a.activity.id,
                            shortName: a.activity.shortName,
                            isOn: { value: false, valid: true, message: "" }
                          }
                        }
                      })
                    }
                  })
                )
                : (
                  []
                )
            }
          }
        }
        else {
          item = {
            time: { value: "", valid: true, message: "" },
            isEdited: false,
            activities: payload.planningInfo.activities.map((act) => { //* Map over planning activities
              return {
                id: act.activity.id,
                shortName: act.activity.shortName,
                isOn: { value: false, valid: true, message: "" }
              }
            }),
            stop: {
              ...item.stop,
              details: payload.planningInfo.vehicles.map((vhc, idx) => { //* Maps over the vehicles
                return {
                  ...item.stop.details[idx],
                  vehicle: {
                    uuid: vhc.vehicle.uuid,
                    name: vhc.vehicle.name
                  },
                  activities: payload.planningInfo.activities.map((a) => {
                    return {
                      id: a.activity.id,
                      shortName: a.activity.shortName,
                      isOn: { value: false, valid: true, message: "" }
                    }
                  })
                }
              })
            }
          }
          return item
        }
      })

      const inS: PlanningStopsForm[] = inbounds.map((item) => {
        if (payload.planningInfo.inbound.find(st => st.stop.id === item.stop.id)) {
          //* If current stop being iterated exists in planning store it in intersection var
          const currentStop = payload.planningInfo.inbound.find(st => st.stop.id === item.stop.id)

          return {
            time: { value: currentStop.time, valid: true, message: "" },
            isEdited: false,
            activities: payload.planningInfo.activities.map((act) => { //* Map over planning activities
              if (currentStop.activities.find((a) => a.activity.id === act.activity.id)) {
                //* if currentStop has this activity on activities array it means activity is passing ont the stop
                return {
                  id: act.activity.id,
                  shortName: act.activity.shortName,
                  isOn: { value: true, valid: true, message: "" }
                }
              } else {
                //* if currentStop doesn't have this activity on activities array it means activity its not passing ont the stop
                return {
                  id: act.activity.id,
                  shortName: act.activity.shortName,
                  isOn: { value: false, valid: true, message: "" }
                }
              }
            }),
            stop: {
              ...item.stop,
              id: currentStop.stop.id,
              isActive: currentStop.stop.isActive,
              isAvailable: currentStop.stop.isAvailable,
              name: currentStop.stop.name,
              shortName: currentStop.stop.shortName,
              details: payload.planningInfo.vehicles.length !== 0 //* Checks if the planning has a vehicle
                ? (
                  payload.planningInfo.vehicles.map((vhc, idx) => { //* Maps over the vehicles

                    //* Checks if stop had a detail with current iterated vehicle and stores it
                    const detail = currentStop.details.find(d => d.vehicle?.uuid === vhc.vehicle.uuid)
                    return {
                      vehicle: {
                        uuid: vhc.vehicle.uuid,
                        name: vhc.vehicle.name
                      },
                      activities: payload.planningInfo.activities.map((a) => { //* Map over activities available for planning
                        if (detail) {
                          if (detail.activities.find(act => act.activity.id === a.activity.id)) {
                            //* If current detail has an activity equal to the one being iterated it means its active
                            const act = detail.activities.find(act => act.activity.id === a.activity.id)
                            return {
                              id: act.activity.id,
                              shortName: act.activity.shortName,
                              isOn: { value: true, valid: true, message: "" }
                            }
                          }
                          else {
                            //* If current detail does not have an activity equal to the one being iterated it means its not active
                            return {
                              id: a.activity.id,
                              shortName: a.activity.shortName,
                              isOn: { value: false, valid: true, message: "" }
                            }
                          }
                        }
                        else {
                          //* If current detail does not have an activity equal to the one being iterated it means its not active
                          return {
                            id: a.activity.id,
                            shortName: a.activity.shortName,
                            isOn: { value: false, valid: true, message: "" }
                          }
                        }
                      })
                    }
                  })
                )
                : (
                  [
                    {
                      vehicle: {
                        uuid: '',
                        name: ''
                      },
                      activities: payload.planningInfo.activities.map((a) => {
                        return {
                          id: a.activity.id,
                          shortName: a.activity.shortName,
                          isOn: { value: true, valid: true, message: "" }
                        }
                      })
                    }
                  ]
                )
            }
          }
        }
        else {
          item = {
            time: { value: "", valid: true, message: "" },
            isEdited: false,
            activities: payload.planningInfo.activities.map((act) => { //* Map over planning activities
              return {
                id: act.activity.id,
                shortName: act.activity.shortName,
                isOn: { value: false, valid: true, message: "" }
              }
            }),
            stop: {
              ...item.stop,
              details: payload.planningInfo.vehicles.map((vhc, idx) => { //* Maps over the vehicles
                return {
                  ...item.stop.details[idx],
                  vehicle: {
                    uuid: vhc.vehicle.uuid,
                    name: vhc.vehicle.name
                  },
                  activities: payload.planningInfo.activities.map((a) => {
                    return {
                      id: a.activity.id,
                      shortName: a.activity.shortName,
                      isOn: { value: false, valid: true, message: "" }
                    }
                  })
                }
              })
            }
          }
          return item
        }
      })

      state.inboundStops = inS
      state.outboundStops = outS
      state.initialInboundStops = inS
      state.initialOutboundStops = outS
    },
    populateDates: (state, { payload }) => {

      const dates: PlanningDatesForm[] = Array.from([])
      payload.parent.dates.forEach(d => {
        dates.push({
          date: {
            value: d,
            valid: true,
            message: ""
          }
        })
      })

      state.dateType = payload.parent.dateType
      state.dates = dates
    },
    populateMinimumPrice: (state, { payload }) => { 
      state.minimumPrice = { value: payload, valid: true, message: "" }
    },
    populateIncludedSeats: (state, { payload }) => {
      state.includedSeats = { value: payload, valid: true, message: "" }
    },
    setShowDeleteModal: (state, { payload }) => {
      if (payload !== false) {
        state.editFirstDate = true
      }
      state.showDeleteModal = payload
    },
    populateSuspendedTripsArray: (state, { payload }) => {
      state.suspendedTrips = payload.map(susT => {
        const date: any = Object.keys(susT)

        return {
          date: DateTime.fromISO(date).toFormat(config.DATE_FORMAT.DATE),
          tripID: susT[date].data.trip_id
        }
      })
    },
    setBookingsNumber: (state, { payload }) => {
      state.bookingsNumber = payload
    },
  }
})

