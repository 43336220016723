/**
 * Clockqrreader scene
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { CAvatar, CImage } from '@coreui/react-pro';
// State
import { actions, RootState } from 'store/rootSlices'
// Components & Icons
import CustomButton from 'shared/components/CustomButton';
import AnimarisLogoMasterHorizontal from 'assets/icons/logo/animarisLogoMasterHorizontal';
import User from 'assets/icons/user';
// Styles
import theme from 'ui/theme';
import * as Styles from './styles'

const Clockqrreader = ({ t, i18n }) => {

  const {
    hasData,
    user,
    workedHours
  } = useSelector((state: RootState) => state.ClockQrReader)

  const {
    onMount,
    onUnmount,
    getClockStatus,
    executeClockIn,
    executeClockOut,
    returnToInitialState
  } = actions.ClockQrReader

  const dispatch = useDispatch()

  useEffect(() => {

  }, [dispatch, onMount, onUnmount]);

  return (
    <Styles.Wrapper>
      {!hasData ?
        <>
          <Styles.CustomQRReader
            facingMode={"environment"}
            delay={1000}
            onError={() => {
              toast.error(i18n.t("toasts.qrCodeScanError"))
            }}
            onScan={(scanData) => {
              dispatch(getClockStatus(scanData))
            }}
            style={{ width: "50%", minWidth: '350px' }}
          />
          <Styles.LogoContainer>
            <AnimarisLogoMasterHorizontal />
          </Styles.LogoContainer>
        </>
        :
        <Styles.UserInfoWrapper>
          <Styles.UserClockInfo>
            <Styles.ImageWrapper>
              {user && user.avatar ?
                <CImage src={user.avatar} alt="profile"></CImage>
                :
                <CAvatar color='secondary'>
                  <User height={80} width={80} viewBox="0 0 17 19" />
                </CAvatar>
              }
            </Styles.ImageWrapper>
            <Styles.TextWrappper>
              <Styles.Text className='smaller' uppercase>
                {
                  user && user.lastClocked !== null ?
                    user.lastClocked?.clockOut === null ?
                      i18n.t("login.goodbye")
                      :
                      i18n.t("login.welcome")
                    :
                    i18n.t("login.welcome")
                }
              </Styles.Text>
              <Styles.Text>
                {user && user?.name}
              </Styles.Text>
              {user && user.lastClocked !== null ?
                user.lastClocked?.clockOut === null ?
                  <Styles.Text className='smaller' uppercase>
                    {i18n.t("login.workedHours", { hours: workedHours })}
                  </Styles.Text>
                  :
                  null
                :
                null
              }

            </Styles.TextWrappper>
            <CustomButton
              buttonStyle={{ color: "#FFFFFF", width: '100%' }}
              text={
                user.lastClocked !== null ?
                  user.lastClocked?.clockOut === null ?
                    i18n.t("login.confirmExit")
                    :
                    i18n.t("login.confirmEntrance")
                  :
                  i18n.t("login.confirmEntrance")
              }
              color={
                user.lastClocked !== null ?
                  user.lastClocked?.clockOut === null ?
                    'danger'
                    :
                    'success'
                  :
                  'success'
              }
              onClick={() => {
                user.lastClocked !== null ?
                  user.lastClocked?.clockOut === null ?
                    dispatch(executeClockOut())
                    :
                    dispatch(executeClockIn())
                  :
                  dispatch(executeClockIn())
              }}
            />
          </Styles.UserClockInfo>
          <CustomButton
            buttonStyle={{ width: '100%', background: `${theme().primary10}`, color: `${theme().primary}` }}
            text={i18n.t("common.back")}
            color={'primary'}
            variant='ghost'
            onClick={() => {
              dispatch(returnToInitialState())
            }}
          />
        </Styles.UserInfoWrapper>
      }
    </Styles.Wrapper>
  )
}

export default Clockqrreader