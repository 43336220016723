import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

  > svg {
    width: 50%;
  }
`;

export {
  Wrapper
}