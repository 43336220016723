import type { InitialStateProps} from '../../../logic/initialState';


const AddVehicleReducers = {
  onModalVehicleChange: (state: InitialStateProps, { payload }) => {
    const outboundTripStops = payload.planning.outbound
    const inboundTripStops = payload.planning.inbound
    const planningActivities = payload.planning.activities
    const outboundS = payload.openPlanningOutboundStops
    const inboundS = payload.openPlanningInboundStops

    //this condition is used to check if the user unnasigned the current vehicle or if didn't selected one yet

    state.selectedVehicle = {
      value: payload.vehicle.uuid,
      valid: true,
      message: ""
    }
    state.reinforcementCapacity = {
      // value: payload.reinforcement ? payload.vehicle.capacity : payload.planning.vehicles.find(v => v.vehicle.uuid === payload.vehicle.uuid)?.vehicle.capacity,
      value: payload.vehicle.capacity,
      valid: true,
      message: ""
    }

    const outS = outboundS.map((stop) => {
      const isCurrentStopPlanned = outboundTripStops.find(st => st.stop.id === stop.stop.id)
      if (isCurrentStopPlanned) {
        const time = isCurrentStopPlanned.time
        const details: any[] = Array.from(isCurrentStopPlanned.details)

        if (payload.reinforcement) {
          details.push({
            ...details[0],
            vehicle: {
              capacity: payload.vehicle.capacity,
              isAvailable: true,
              name: payload.vehicle.name,
              shortName: payload.vehicle.shortName,
              uuid: payload.vehicle.uuid,
            },
          })
        }

        return {
          ...stop,
          time: { value: time, valid: true, message: "" },
          isActive: { value: true, valid: true, message: "" },
          stop: {
            ...stop.stop,
            //if there is no vehicle assigned to the planning, 'details' array reaches here with an empty 'activities' field, 
            //so we cover that assigning the planning activities to the 'activities' field
            details: details.map(det => ({
              ...det,
              vehicle: { uuid: payload.vehicle.uuid },
              activities: planningActivities.map(act => {

                if (det.activities?.find(a => act.activity.id === a.activity.id)) {
                  return {
                    ...act,
                    isOn: { value: true, valid: true, message: "" }
                  }
                }
                return {
                  ...act,
                  isOn: { value: !state.isUpdatingCircuit ? true : false, valid: true, message: "" }
                }
              })
            }))
          }
        }
      }
      return {
        ...stop,
        stop: {
          ...stop.stop,
          details: stop.stop.details.map(d => {
            return {
              ...d,
              vehicle: { uuid: payload.vehicle.uuid },
              activities: planningActivities.map(act => {
                return {
                  ...act,
                  isOn: { value: false, valid: true, message: "" }
                }
              })
            }
          })
        }
      }
    })


    const inS = inboundS.map((stop) => {
      const isCurrentStopPlanned = inboundTripStops.find(st => st.stop.id === stop.stop.id)
      if (isCurrentStopPlanned) {
        const time = isCurrentStopPlanned.time
        const details: any[] = Array.from(isCurrentStopPlanned.details)

        if (payload.reinforcement) {
          details.push({
            ...details[0],
            vehicle: {
              capacity: payload.vehicle.capacity,
              isAvailable: true,
              name: payload.vehicle.name,
              shortName: payload.vehicle.shortName,
              uuid: payload.vehicle.uuid,
            },
          })
        }

        return {
          ...stop,
          time: { value: time, valid: true, message: "" },
          isActive: { value: true, valid: true, message: "" },
          stop: {
            ...stop.stop,
            //if there is no vehicle assigned to the planning, 'details' array is reaches here with an empty 'activities' field, 
            //so we cover that assigning the planning activities to the 'activities' field
            details: details.map(det => ({
              ...det,
              vehicle: { uuid: payload.vehicle.uuid },
              activities: planningActivities.map(act => {
                if (det.activities?.find(a => act.activity.id === a.activity.id)) {
                  return {
                    ...act,
                    isOn: { value: true, valid: true, message: "" }
                  }
                }
                return {
                  ...act,
                  isOn: { value: !state.isUpdatingCircuit ? true : false, valid: true, message: "" }
                }
              })
            }))
          }
        }
      }
      return {
        ...stop,
        stop: {
          ...stop.stop,
          details: stop.stop.details.map(d => {
            return {
              ...d,
              vehicle: { uuid: payload.vehicle.uuid },
              activities: planningActivities.map(act => {
                return {
                  ...act,
                  isOn: { value: false, valid: true, message: "" }
                }
              })
            }
          })
        }
      }
    })

    state.openPlanningOutboundStops = outS
    state.openPlanningInboundStops = inS
    state.didStopsChange = false
    state.isRemovingVehicle = false
    
  },
  onModalVehicleRemove: (state: InitialStateProps, { payload }) => {
    const outboundS = payload.openPlanningOutboundStops
    const planning = payload.planning

    if (state.isUpdatingCircuit) {
      state.isRemovingVehicle = true
    }

    //reset vehicle stops status 
    const stops: any = Array.from([])
    const planningActivities = planning.activities

    outboundS.forEach((st) => {
      stops.push({
        time: { value: "", valid: true, message: "" },
        isActive: { value: false, valid: true, message: "" },
        stop: {
          id: st.stop.id,
          isActive: st.stop.isActive,
          isAvailable: st.stop.isAvailable,
          name: st.stop.name,
          shortName: st.stop.shortName,
          details: [{
            maxCapacity: { value: "", valid: true, message: "" },
            vehicle: { uuid: "" },
            activities: planningActivities.map(act => {
              return {
                ...act,
                isOn: { value: false, valid: true, message: "" }
              }
            })
          }]
        },
      })
    })

    const reversedStops: any = Array.from(stops).reverse()
    state.openPlanningOutboundStops = stops
    state.openPlanningInboundStops = reversedStops

    state.selectedVehicle = {
      value: "",
      valid: true,
      message: "",
    }
    state.reinforcementCapacity = {
      value: "",
      valid: true,
      message: ""
    }
  },
  onModalReinforcementCapacityChange: (state: InitialStateProps, { payload }) => {
    state.reinforcementCapacity = payload
  },
  onReinforcementStopsFormChange: (state: InitialStateProps, { payload }) => {
    const isOutbounds = payload.isOutbounds
    const stops = payload.stops
    const stopIdx = payload.idx
    const activityIdx = payload.e.activityIdx
    const selectedVehicle = payload.selectedVehicle
    const value = payload.e.e
    const inputType = payload.e.inputType
    const planningActivities = payload.openPlanning.activities

    if (isOutbounds) {
      const outS = stops.map((st, idx) => {

        if (idx === stopIdx) {
          if (inputType === 'time') {
            return {
              ...st,
              time: {
                ...st.time,
                value: value,
              }
            }
          }

          let details = Array.from(st.stop.details.map((det) => {
            if (det?.vehicle?.uuid === selectedVehicle) {
              return {
                ...det,
                activities: det.activities.map((act, idx) => {
                  if (act.activity.id === activityIdx) {
                    return {
                      ...act,
                      isOn: { value: value, valid: true, message: "" }
                    }
                  }
                  return act
                })
              }
            }
            return {
              ...det,
              vehicle: { uuid: selectedVehicle },
              activities: det.activities.map((act, idx) => {
                if (act.activity.id === activityIdx) {
                  return {
                    ...act,
                    isOn: { value: value, valid: true, message: "" }
                  }
                }
                return act
              })
            }
          }))

          return {
            ...st,
            isActive: { value: true, valid: true, message: "" },
            stop: {
              ...st.stop,
              details: details.length > 0 ?
                details
                :
                [{
                  vehicle: { uuid: selectedVehicle },
                  activities: planningActivities.map((act, idx) => {
                    if (act.activity.id === activityIdx) {
                      return {
                        ...act,
                        isOn: { value: value, valid: true, message: "" }
                      }
                    }
                    return {
                      ...act,
                      isOn: { value: false, valid: true, message: "" }
                    }
                  })
                }]
            }
          }
        }
        return st
      })
      state.openPlanningOutboundStops = outS
    }
    else {
      const inS = stops.map((st, idx) => {
        if (idx === stopIdx) {
          if (inputType === 'time') {
            return {
              ...st,
              time: {
                ...st.time,
                value: value,
              }
            }
          }

          let details = Array.from(st.stop.details.map((det) => {
            if (det?.vehicle?.uuid === selectedVehicle) {
              return {
                ...det,
                activities: det.activities.map((act, idx) => {
                  if (act.activity.id === activityIdx) {
                    return {
                      ...act,
                      isOn: { value: value, valid: true, message: "" }
                    }
                  }
                  return act
                })
              }
            }
            return {
              ...det,
              vehicle: { uuid: selectedVehicle },
              activities: det.activities.map((act, idx) => {
                if (act.activity.id === activityIdx) {
                  return {
                    ...act,
                    isOn: { value: value, valid: true, message: "" }
                  }
                }
                return act
              })
            }
          }))

          return {
            ...st,
            isActive: { value: true, valid: true, message: "" },
            stop: {
              ...st.stop,
              details: details.length > 0 ?
                details
                :
                [{
                  vehicle: { uuid: selectedVehicle },
                  activities: planningActivities.map((act, idx) => {
                    if (act.activity.id === activityIdx) {
                      return {
                        ...act,
                        isOn: { value: value, valid: true, message: "" }
                      }
                    }
                    return {
                      ...act,
                      isOn: { value: false, valid: true, message: "" }
                    }
                  })
                }]
            }
          }

        }
        return st
      })
      state.openPlanningInboundStops = inS
    }

    state.didStopsChange = true
  },
  onSubmitModal: () => { },
}

export default AddVehicleReducers