/**
 * Usercashmanagement scene sagas
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */
import API from 'api'
import { AnyAction } from 'redux'
import { call, putResolve, select, takeLatest } from 'redux-saga/effects'
import { actions, RootState } from 'store/rootSlices'
import { toast } from 'react-toastify'
import i18n from 'i18next';
import { DateTime } from 'luxon'

//import { actions } from 'store/rootSlices'

function* onMountSaga({ payload }: AnyAction) {
  const userID = payload.uuid
  const history = payload.history

  yield getURLParamsSaga(history);

  if (userID !== null) {
    yield putResolve(actions.UserCashManagement.setUserID(userID))
    yield getStatementSaga()
    const { userStatementName } = yield select((state: RootState) => state.UserCashManagement);
    yield putResolve(actions.Dashboard.setDynamicBreadcrumb({
      path: `invoicing/cashManagement/${userStatementName !== null ? userStatementName : ""}`,
      name: userStatementName !== null ? userStatementName : "",
    }))
  }

}

function* getStatementSaga() {

  const { startDate, page } = yield select((state: RootState) => state.CashManagement);
  const { userID, searchTerm } = yield select((state: RootState) => state.UserCashManagement);

  yield putResolve(actions.UserCashManagement.setLoading(true))
  try {
    const statementInfo = yield call(API.Invoicing.GetStatements,
      page,
      searchTerm,  //searchTerm
      undefined,  //group
      DateTime.fromJSDate(startDate).toISODate(),
      DateTime.fromJSDate(startDate).toISODate(),
      userID
    )

    if (statementInfo) {


      // we need to set the statement form here because we might want to close it or reopen it on this screen,
      // and the close/open api call requires the form 
      if (statementInfo.teamMembers && statementInfo.teamMembers[0].statement !== null) {
        yield putResolve(actions.UserCashManagement.setUserStatementName(statementInfo.teamMembers[0]?.name))
        yield putResolve(actions.UserCashManagement.setStatementStatus(statementInfo.teamMembers[0].statement.isClosed))
        yield putResolve(actions.UserCashManagement.setStatement(statementInfo.teamMembers[0].statement))
        yield putResolve(actions.UserCashManagement.setStatementEntries(statementInfo.teamMembers[0].entries ?? []))
        yield putResolve(actions.UserCashManagementForms.setStatementForm(statementInfo.teamMembers[0].statement))
      }
      else {
        yield putResolve(actions.UserCashManagement.setStatementStatus(false))
        yield putResolve(actions.UserCashManagement.setStatement(null))
        yield putResolve(actions.UserCashManagement.setStatementEntries([]))
      }
    }
  } catch (error) {
    toast.error(i18n.t("toasts.getUserStatementError"))
  }
  finally {
    yield putResolve(actions.UserCashManagement.setLoading(false))
  }
}

function* openStatementSaga() {
  yield putResolve(actions.UserCashManagementForms.setSubmitButtonDisabled(true))
  const { statement } = yield select((state: RootState) => state.UserCashManagement);

  try {
    const open = yield call(API.Invoicing.OpenStatment, statement.id)

    if (open) {
      yield putResolve(actions.UserCashManagement.setStatementStatus(open.isClosed))
      yield putResolve(actions.UserCashManagement.setStatement(open))
      yield putResolve(actions.UserCashManagementForms.setStatementForm(open))
      yield putResolve(actions.UserCashManagementForms.setSubmitButtonDisabled(false))
    }
  } catch (error) {
    yield putResolve(actions.UserCashManagementForms.setSubmitButtonDisabled(false))
    toast.error(i18n.t("toasts.openUserStatementError"))
  }
}

function* getEntryPhotoSaga() {

  const { showModal } = yield select((state: RootState) => state.UserCashManagement)

  try {
    const photos = yield call(API.Invoicing.ListEntryPhotos, showModal.id)

    if (photos) {
      yield putResolve(actions.UserCashManagementForms.populatePhotosForm({ photos, movementID: showModal.id }))
    }
  } catch (error) {
    toast.error(i18n.t("toasts.getMovementFileError"))
  }
}

function* getURLParamsSaga(payload: AnyAction) {
  let params = new URLSearchParams(payload.location.search)

  const {
    searchTerm,
  } = yield select((state: RootState) => state.UserCashManagement);

  const {
    startDate,
  } = yield select((state: RootState) => state.CashManagement);

  if (params.toString() !== "") {
    yield putResolve(actions.UserCashManagement.populateFiltersOnMount({
      searchTerm: params.get('search') ?? "",
    }))

    yield putResolve(actions.CashManagement.populateFiltersOnMount({
      searchTerm: "",
      startDate: DateTime.fromISO(params.get('date')).toJSDate() ?? DateTime.now().toJSDate(),
      groupFilter: "",
    }))
  }
  else {
    params.set('search', searchTerm)
    params.set('date', DateTime.fromJSDate(startDate).toISODate())
    payload.replace({ search: (params).toString() })
  }
}

function* onUnmountSaga() {
  yield putResolve(actions.Dashboard.setDynamicBreadcrumb(null))
}

export default function* watcherSignin() {
  yield takeLatest('Usercashmanagement/onMount', onMountSaga)
  yield takeLatest('Usercashmanagement/onUnmount', onUnmountSaga)
  yield takeLatest('Usercashmanagement/setSearchTerm', getStatementSaga)
  yield takeLatest('Usercashmanagement/getStatement', getStatementSaga)
  yield takeLatest('Usercashmanagement/openStatement', openStatementSaga)
  yield takeLatest('Usercashmanagement/setShowModal', getEntryPhotoSaga)
  yield takeLatest('Usercashmanagement/getURLParams', getURLParamsSaga)
  //yield takeLatest('Usercashmanagement/getStatementHistory', getStatementHistorySaga)
}


