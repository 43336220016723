import React from 'react'
import i18n from 'i18next'
import { useDispatch, useSelector } from 'react-redux'
import { actions, RootState } from 'store/rootSlices'
import { CCol } from '@coreui/react-pro'
import { PlanningServiceForm, ServiceTypes } from 'types/trips/plannings';
import Dropdown from 'shared/components/FormComponents/dropdown'
import CustomTextInput from 'shared/components/CustomTextInput'
import Trash from 'assets/icons/trash'
import { Row } from '../../../../styles';
import {
  ActivitiesLabel,
  ActivitiesPlaceholder,
  AvailableActivitiesOptions,
  addGenerateIslandTripsHeader,
  AvailableServiceOptions,
  checkIfGenerateIslandTrips,
  getServiceInitialSettingsBasedOnType,
  isTripService
} from '../utils'
import TableContainer from 'shared/components/TableContainer'
import CustomCheckbox from 'shared/components/CustomCheckbox'
import Passengertypetablerow from '../../../../Tripactivityform/components/passengertypetablerow'
import PlanningExtraTableRow from './PlanningExtraTableRow'

interface ServiceSectionProps { 
  service: PlanningServiceForm
  serviceIdx: number
}

const ServiceSection = ({ service, serviceIdx }: ServiceSectionProps) => {

  const {
    services,
    maxCapacity,
    beachLocation,
    vehicles,
    outboundStops,
    passengerTypes,
    extras,
    inboundStops,
    hasEditPermission,
    passengerTypesSwitch,
    minimumPrice,
    includedSeats,
    extrasSwitch
  } = useSelector((state: RootState) => state.TripPlanningsForm)
  const { allActivities, allLocals, allCategories } = useSelector((state: RootState) => state.TripsConfiguration)

  const {
    onSelectActivity,
    changeServiceType,
    onServiceVehicleCapacityFormChange,
    onServiceBeachLocationFormChange,
    onPassengerTypesFormChange,
    onExtrasFormChange,
    toogleAllPassengerTypes,
    toogleAllExtras,
    onDeleteService,
    onServiceMinimumPriceFormChange,
    onServiceIncludedSeatsFormChange
  } = actions.TripPlanningsForm

  const dispatch = useDispatch()

  //* This gets displayed when the service is not the first one
  const _renderServiceDropdown = () => {
    return serviceIdx !== 0 && (
      <Row>
        <CCol>
          <Dropdown
            required={true}
            id={`tripPlanningForm-serviceType${serviceIdx}`}
            valid={true}
            disabled={!hasEditPermission}
            value={service.serviceType.toString() || Number(0).toString()}
            helpText={''}
            label={i18n.t('tripPlanningForm.serviceTitle')}
            placeholder={i18n.t('placeholders.serviceType')}
            onChange={(e) => {
              dispatch(changeServiceType({
                services: services,
                idx: serviceIdx,
                serviceType: Number(e.target.value) as ServiceTypes,
                settings: getServiceInitialSettingsBasedOnType(Number(e.target.value) as ServiceTypes, passengerTypes, extras),
              }))
            }}
            options={AvailableServiceOptions(services) as any[]}
          />
        </CCol>
        <CCol sm={1} style={{ width: "5%", display: "flex", justifyContent: "space-around", alignItems: "flex-start", marginTop: "35px" }}>
          <Trash style={{ cursor: "pointer" }} onClick={(e) => hasEditPermission && dispatch(onDeleteService({ services: services, index: serviceIdx }))} />
        </CCol>
      </Row>
    )
  }

  //* This gets displayed when the service is a Activity
  const _renderLotationInput = () => { 
    return isTripService(service) && (
      <Row>
        <CustomTextInput
          required={true}
          disabled={!hasEditPermission}
          id='tripPlanningForm-maxCapacity'
          valid={maxCapacity.valid}
          value={maxCapacity.value}
          helpText={maxCapacity.message}
          placeholder={i18n.t('placeholders.lotation')}
          label={i18n.t('tripPlanningForm.lotation')}
          onChange={(e) => dispatch(onServiceVehicleCapacityFormChange({ e: e.target.value }))}
        />
      </Row>
    )
  }

  const _renderPassengerTypeSection = () => { 
    return isTripService(service) && (
      <Row>
        <TableContainer
          id={`tripPlanningForm-service${serviceIdx}`}
          count={21}
          elementsPerPage={21}
          currentPage={-1}
          onPageChange={() => { }}
          headerElements={[
            i18n.t('tripActivityForm.passengerType'),
            addGenerateIslandTripsHeader(service),
            i18n.t('tripActivityForm.passengerPrice'),
            <CustomCheckbox
              disabled={!hasEditPermission}
              checkStyle={{ width: '40px', height: '20px', marginTop: '5px' }}
              id={`tripPlanningForm-service${serviceIdx}-allPassengerTypes`}
              checked={passengerTypesSwitch}
              onClick={e => { dispatch(toogleAllPassengerTypes({ passengerTypes: service.passengerTypes, passengerTypesSwitch: !passengerTypesSwitch, service, serviceIdx })) }}
              switch
            />
          ]}
        >
          {service?.passengerTypes?.map((el: any, idx: number) => {
            return (
              <React.Fragment key={`passenger-type-${idx}`}>
                <Passengertypetablerow
                  disabled={!hasEditPermission}
                  onClick={(e, input) => { dispatch(onPassengerTypesFormChange({ e, el, inputName: input, services, serviceIdx })) }}
                  generateIslandTrips={checkIfGenerateIslandTrips(service)} // check later from activity info
                  passengerType={el}
                  actions={
                    <React.Fragment>
                      <CustomCheckbox
                        disabled={!hasEditPermission || !el.isActive}
                        checkStyle={{ width: '40px', height: '20px', marginTop: '5px' }}
                        id={`tripPlanningForm-service${serviceIdx}-passengerType${idx}`}
                        checked={el.isOn && el.isOn.value ? true : false}
                        onClick={e => { dispatch(onPassengerTypesFormChange({ e, el, inputName: 'none', services, serviceIdx })) }}
                        switch
                      />
                    </React.Fragment>
                  }
                />
              </React.Fragment>
            )
          })}
        </TableContainer>
      </Row>
    )
  }

  //* This gets displayed when the service is a Beach
  const _renderBeachLocationDropdown = () => { 
    return !isTripService(service) && (
      <Row>
        <CCol >
          <Dropdown key={serviceIdx}
            required={true}
            disabled={!hasEditPermission}
            id={`tripPlanningForm-serviceBeachLocation${serviceIdx}`}
            valid={beachLocation.valid}
            value={beachLocation.value}
            helpText={beachLocation.message}
            options={allLocals.filter(local => local.isBeach).map((local) => {
              return {
                label: local.name,
                value: local.id,
              }
            })}
            placeholder={i18n.t('tripPlanningForm.location')}
            label={i18n.t('tripPlanningForm.location')}
            onChange={(e) => dispatch(onServiceBeachLocationFormChange({ e: e.target.value }))}
          />
        </CCol>
      </Row>
    )
  }

  const _renderExtrasSection = () => { 
    return !isTripService(service) && ( 
      <Row>
        <TableContainer
          id={`tripPlanningForm-service${serviceIdx}`}
          count={21}
          elementsPerPage={21}
          currentPage={-1}
          onPageChange={() => { }}
          headerElements={[
            i18n.t('tripPlanningForm.extra'),
            'availability',
            i18n.t('tripPlanningForm.maxCapacity'),
            i18n.t('tripPlanningForm.extraPrice'),
            <CustomCheckbox
              disabled={!hasEditPermission}
              checkStyle={{ width: '40px', height: '20px', marginTop: '5px' }}
              id={`tripPlanningForm-service${serviceIdx}-allPassengerTypes`}
              checked={passengerTypesSwitch}
              onClick={e => { dispatch(toogleAllExtras({ extras: service.extras, extrasSwitch: !extrasSwitch, service, serviceIdx })) }}
              switch
            />
          ]}
        >
          {service?.extras?.map((el: any, idx: number) => {
            return (
              <React.Fragment key={`extra-${idx}`}>
                <PlanningExtraTableRow
                  onClick={(e, input) => { dispatch(onExtrasFormChange({ e, el, inputName: input, services, serviceIdx })) }}
                  extra={el}
                  serviceIdx={serviceIdx}
                  services={services}
                  actions={
                    <React.Fragment>
                      <CustomCheckbox
                        disabled={!hasEditPermission || !el.isActive.value}
                        checkStyle={{ width: '40px', height: '20px', marginTop: '5px' }}
                        id={`tripPlanningForm-service${serviceIdx}-extra${idx}`}
                        checked={el.isOn && el.isOn.value ? true : false}
                        onClick={e => { return dispatch(onExtrasFormChange({ e, el, inputName: 'none', services, serviceIdx })) }}
                        switch
                      />
                    </React.Fragment>
                  }
                />
              </React.Fragment>
            )
          })}
        </TableContainer>
      </Row>
    )
  }

  //* This gets displayed when the service is a Activity and the activity is Private
  const _renderPrivateActivitySection = () => { 
    return isTripService(service) && service.serviceDetails.isPrivate && (
      <Row className="g-3">
        <CCol>
          <CustomTextInput
            id='tripPlanningForm-minimumPrice'
            disabled={!hasEditPermission}
            required={true}
            valid={minimumPrice.valid}
            value={minimumPrice.value}
            helpText={minimumPrice.message}
            placeholder={i18n.t('placeholders.minimumPrice')}
            label={i18n.t('tripActivityForm.minimumPrice')}
            onChange={(e) => dispatch(onServiceMinimumPriceFormChange({ e: e.target.value }))}
          />
        </CCol>
        <CCol>
          <CustomTextInput
            id='tripPlanningForm-includedSeats'
            disabled={!hasEditPermission}
            required={true}
            valid={includedSeats.valid}
            value={includedSeats.value}
            helpText={includedSeats.message}
            placeholder={i18n.t('placeholders.includedSeats')}
            label={i18n.t('tripActivityForm.includedSeats')}
            onChange={(e) => dispatch(onServiceIncludedSeatsFormChange({ e: e.target.value }))}
          />
        </CCol>
      </Row>
    )
  }

  return (
    <div>
      { _renderServiceDropdown() }
      { _renderLotationInput() }
      { _renderBeachLocationDropdown() }

      {service.serviceType &&
        <>
          <Row >
            <CCol >
              <Dropdown key={serviceIdx}
                required={true}
                disabled={!hasEditPermission}
                id={`tripPlanningForm-service${serviceIdx}`}
                valid={service.serviceDetails?.id.valid}
                value={service.serviceDetails?.id.value}
                helpText={service.serviceDetails?.id.message}
                options={AvailableActivitiesOptions(service, services, allActivities, allCategories, vehicles)}
                placeholder={ActivitiesPlaceholder(service)}
                label={ActivitiesLabel(service)}
                onChange={(e) => {
                  dispatch(onSelectActivity({
                    idx: serviceIdx,
                    serviceInfo: allActivities.find(obj => obj.id === Number(e.target.value)),
                    services: services,
                    ...(isTripService(service) ? {
                      outboundStops: outboundStops,
                      inboundStops: inboundStops
                    } : null)
                  }))
                }
                }
              />
            </CCol>
          </Row>
          { _renderPrivateActivitySection() }
          { _renderPassengerTypeSection() }
          { _renderExtrasSection() }
          { isTripService(service) && <hr style={{ marginLeft: '-25px', marginRight: '-25px', marginTop: 10 }} /> }
        </>
      }
    </div>
  )
}

export default ServiceSection