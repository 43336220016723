import * as React from "react"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg 
            width="18" 
            height="18" 
            viewBox="0 0 18 19" 
            fill={props.fill ? props.fill : "#EBEDEF"} 
            xmlns="http://www.w3.org/2000/svg">
            <path 
                d="M14 5.5L12.59 6.91L14.17 8.5H6V10.5H14.17L12.59 12.08L14 13.5L18 9.5L14 5.5ZM2 2.5H9V0.5H2C0.9 0.5 0 1.4 0 2.5V16.5C0 17.6 0.9 18.5 2 18.5H9V16.5H2V2.5Z" 
                fill={props.fill ? props.fill : "#EBEDEF"} 
            />
        </svg>
    )
}

export default SvgComponent
