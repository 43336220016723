/**
 * Cashmanagementtotals component
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */

import { CCol, CRow, CWidgetStatsA } from '@coreui/react-pro'
import React from 'react'
import { DisplaysContainer, TextH5, Text } from './styles'

interface PropTypes {
  incomeTotal: number,
  expensesTotal: number,
  cashTotal: number,
  t: any
}

const Cashmanagementtotals = ({ incomeTotal, expensesTotal, cashTotal, t }: PropTypes) => {
  return (
    <DisplaysContainer>
      <CRow>
        <CCol xs={4}>
          <CWidgetStatsA
            className="mb-4 display padding title-text pos"
            color="white"
            value={
              <TextH5>{incomeTotal} €</TextH5>
            }
            title={
              <Text>{t('cashManagement.income')}</Text>
            }
          />
        </CCol>
        <CCol xs={4}>
          <CWidgetStatsA
            className="mb-4 display padding title-text pos"
            color="white"
            value={
              <TextH5>{expensesTotal} €</TextH5>
            }
            title={
              <Text>{t('cashManagement.expenses')}</Text>
            }
          />
        </CCol>
        <CCol xs={4}>
          <CWidgetStatsA
            className="mb-4 padding pos"
            color="primary"
            value={
              <TextH5>{cashTotal} €</TextH5>
            }
            title={
              <Text>{t('cashManagement.balance')}</Text>
            }
          />
        </CCol>
      </CRow>
    </DisplaysContainer>
  )
}

export default Cashmanagementtotals