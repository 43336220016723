/**
 * Avataruploader component
 *
 * @author João Dias <jdias@ubiwhere.com>
 *
 *
 */

import { CAvatar, CFormLabel, CImage } from '@coreui/react-pro'
import React, { useEffect, useRef } from 'react'
import theme from 'ui/theme'
import Trash from 'assets/icons/trash';
import Add from 'assets/icons/add_avatar';
import QRCodeImage from 'assets/icons/qr_code_alternative';
import { toast } from 'react-toastify';
import * as Styles from './styles'
import { HelpText } from 'shared/genericStyledComponents';

type PropTypes = {
  id?: string,
  label: string,
  hasEditPermission: boolean,
  required?: boolean,
  onChange: Function,
  helpText: string,
  valid?: boolean,
  value: string,
  defaultAvatar: JSX.Element
  cantUpload?: boolean,
  qrCode?: string | null
}


const Avataruploader = (props: PropTypes) => {

  const ref = useRef<HTMLInputElement>(null)

  const handleButtonClick = e => {
    if (ref.current !== null) {
      ref.current.click();
    }
  };

  const handleChange = (e) => {
    if (e.target.value.toLowerCase().includes(".png") || e.target.value.toLowerCase().includes(".jpg") || e.target.value.toLowerCase().includes(".jpeg")) {
      props.onChange(e.target.files[0])
    }
    else {
      if (e.target.value !== "") {
        toast.error("file type not supported")
      }
    }
  }

  const handleDeleteAvatar = (e) => {
    props.onChange("")
  }

  useEffect(() => {

  }, [props.value])

  return (
    <Styles.Wrapper>
      {props.label && <CFormLabel>{props.label}<span>{!!props.required ? "*" : ""}</span></CFormLabel>}
      <Styles.InteractionWrapper>
        {props.value ?
          <Styles.UserWrapper>
            <CImage src={props.value} alt="profile"></CImage>
            <Styles.DeleteButton id="avatarDeleteButton" onClick={(e) => props.hasEditPermission && handleDeleteAvatar(e)}>
              <Trash width={30} height={30}></Trash>
            </Styles.DeleteButton>
          </Styles.UserWrapper>
          :
          <Styles.UserWrapper>
            <CAvatar color='secondary'>
              {props.defaultAvatar}
            </CAvatar>
            {!props.cantUpload &&
              <Styles.UploadButton id="avatarUploadButton" onClick={(e) => props.hasEditPermission && handleButtonClick(e)}>
                <Add fill={theme().gray200Color} width={30} height={30}></Add>
              </Styles.UploadButton>
            }
          </Styles.UserWrapper>
        }
        <input style={{ display: "none" }} accept=".jpg,.png,.jpeg" ref={ref} id="file-upload" type="file" onChange={e => props.hasEditPermission && handleChange(e)} />
      </Styles.InteractionWrapper>
      {
        props.qrCode &&
        <Styles.QRCode>
          <Styles.CustomAnchor id='userForm-qrcode' target="_blank" rel="noopener noreferrer" href={props.qrCode}>
            <QRCodeImage />
          </Styles.CustomAnchor >
        </Styles.QRCode>
      }

      {props.helpText && <HelpText valid={props.valid}>{props.helpText}</HelpText>}
    </Styles.Wrapper>
  )
}

export default Avataruploader