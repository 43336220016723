/**
 * Timerecord scene sagas
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */
import { AnyAction } from '@reduxjs/toolkit'
import { call, putResolve, takeLatest, select } from 'redux-saga/effects'
import { actions, RootState } from 'store/rootSlices'
import API from 'api'
import i18n from 'i18next'
import { toast } from 'react-toastify'
import { Interval, DateTime } from 'luxon';
import { TimeRecordsList } from 'types/users/user'

function* onMountSaga({ payload }: AnyAction) {
  yield getURLParamsSaga(payload);
  yield getListSaga()
  yield putResolve(actions.TimeRecord.setLoading(false));
}

function* getListSaga() {
  try {
    const { searchTerm, filter, startDate, endDate, groupFilter, sort } = yield select((state: RootState) => state.TimeRecord);

    let list: TimeRecordsList = yield call(API.Collaborators.GetTimeRecordList, searchTerm, sort, filter, startDate, endDate, groupFilter);
    const pendingList: TimeRecordsList = list.filter(cl => !cl.isApproved)
    const approvedList: TimeRecordsList = list.filter(cl => cl.isApproved)

    if (list) {
      yield putResolve(actions.TimeRecord.setPendingTotal(pendingList.length))
      yield putResolve(actions.TimeRecord.setPendingList(pendingList ?? []));
      yield putResolve(actions.TimeRecord.setApprovedTotal(approvedList.length))
      yield putResolve(actions.TimeRecord.setApprovedList(approvedList ?? []));
    }
  } catch (err) {
    toast.error(i18n.t("toasts.collaboratorsGetError"));
  } finally {
  }
}

function* fetchCollaboratorstSaga() {
  yield getListSaga()
}

function* submitApprovedTimeRecordsSaga() {
  const {
    toApproveList,
    startDate,
    endDate,
  } = yield select((state: RootState) => state.TimeRecord)
  
  const finalForm = {
    approve: true,
    startDate: startDate,
    endDate: endDate,
    teamMembers: toApproveList
  }

  try {
    const TimeRecords = yield call(API.Collaborators.ApproveTimeRecords, finalForm)
    if (TimeRecords) {
      yield putResolve(actions.TimeRecord.clearToAprroveList())
      yield getListSaga()
      toast.success(i18n.t("toasts.userTimeRecordApproved"));
    } else {
      toast.error(i18n.t("toasts.userTimeRecordApprovalError"));
    }
  } catch (error) {
    const err: any = error
    if(err.response.status === 400) {
      console.log(err.response.data.errors)

      if(err.response?.data?.errors?.other.includes('unapproved_workday_before_start_date')){
        toast.error(i18n.t("toasts.approveOtherWeeksError", {collaborator: err.response.data.errors.fields.team_member, week: err.response.data.errors.fields.date }));
      } else {
        toast.error(i18n.t("toasts.userTimeRecordApprovalError"));
      }      
    }
    if(err.response.status === 500) {
      toast.error(i18n.t("toasts.userTimeRecordApprovalError"));
    }
  }
}

function* submitDisapprovedTimeRecordsSaga() {
  const {
    toDisapproveList,
    startDate,
    endDate,
  } = yield select((state: RootState) => state.TimeRecord)

  const finalForm = {
    approve: false,
    startDate: startDate,
    endDate: endDate,
    teamMembers: toDisapproveList
  }

  try {
    const TimeRecords = yield call(API.Collaborators.DisapproveTimeRecords, finalForm)

    if (TimeRecords) {
      yield putResolve(actions.TimeRecord.clearToDisapproveList())
      yield getListSaga()
      toast.success(i18n.t("toasts.userTimeRecordDisapproved"));
    } else {
      toast.error(i18n.t("toasts.userTimeRecordDisapprovalError"));
    }
  } catch (error) {
    toast.error(i18n.t("toasts.userTimeRecordDisapprovalError"));
  }
}

function* getTimeRecordListFileSaga() {
  yield putResolve(actions.TimeRecord.setExportLoading(true))

  const { startDate, endDate, filter, groupFilter, searchTerm } = yield select((state: RootState) => state.TimeRecord);

  try {

    const file = yield call(API.Collaborators.ExportTimeRecordList, startDate, endDate, filter, groupFilter, searchTerm)

    if (file) {
      yield putResolve(actions.TimeRecord.downloadTimeRecordList({ file }))
      toast.success(i18n.t("toasts.downloadTimeRecordSuccess"))
    }

  } catch (error) {
    toast.error(i18n.t("toasts.downloadTimeRecordError"))
  } finally {
    yield putResolve(actions.TimeRecord.setExportLoading(false))
  }
}

function* setDatesIntervalSaga({ payload }: AnyAction) {

  if (payload.e.startDate && payload.e.endDate) {
    let interval = Interval.fromDateTimes(payload.e.startDate, payload.e.endDate);
    const arr = Array.from(days(interval))

    let params = new URLSearchParams(payload.history.location.search);
    payload.e.startDate !== "" ? params.set('startDate', arr[0]?.toISO().slice(0, 10)) : params.delete('startDate')
    payload.e.endDate !== "" ? params.set('endDate', arr[arr.length - 1]?.toISO().slice(0, 10)) : params.delete('endDate')
    payload.history.replace({ search: (params).toString() })

    yield putResolve(actions.TimeRecord.setStartDate(arr[0]?.toISO().slice(0, 10)))
    yield putResolve(actions.TimeRecord.setEndDate(arr[arr.length - 1]?.toISO().slice(0, 10)))
  }
}

function* getURLParamsSaga(payload: AnyAction) {

  let params = new URLSearchParams(payload.location.search)

  const {
    hasDateParams,
    searchTerm,
    filter,
    groupFilter,
    startDate,
    endDate
  } = yield select((state: RootState) => state.TimeRecord);

  if (params.toString() !== "") {

    yield putResolve(actions.TimeRecord.populateFiltersOnMount({
      searchTerm: params.get('search') ?? "",
      filter: params.get('role') ?? "",
      groupFilter: params.get('group') ?? "",
      startDate: params.get('startDate') ?? DateTime.now().startOf("week").toJSDate(),
      endDate: params.get('endDate') ?? DateTime.now().endOf("week").toJSDate()
    }))
    yield putResolve(actions.TimeRecord.setHasDateParams(!hasDateParams));

  }
  else {
    //state.hasDateParams has to be different from the last known state.hasDateParams to force a reRender on datepicker
    //this reRender must be done so the datepicker changes its label
    yield putResolve(actions.TimeRecord.setHasDateParams(!hasDateParams));
    params.set('role', filter)
    params.set('group', groupFilter)
    params.set('search', searchTerm)
    params.set('startDate', startDate)
    params.set('endDate', endDate)
    payload.replace({ search: (params).toString() })
  }
}

function* onUnmountSaga() {
  yield putResolve(actions.TimeRecord.setPendingTotal(0))
  yield putResolve(actions.TimeRecord.setPendingList([]));
  yield putResolve(actions.TimeRecord.setApprovedTotal(0))
  yield putResolve(actions.TimeRecord.setApprovedList([]));
  yield putResolve(actions.TimeRecord.setLoading(true));
 }

function* days(interval) {
  let cursor = interval.start.startOf("day");

  while (cursor <= interval.end) {
    yield cursor;
    cursor = cursor.plus({ days: 1 });
  }
}

export default function* watcherSignin() {
  yield takeLatest('Timerecord/onMount', onMountSaga)
  yield takeLatest('Timerecord/onUnmount', onUnmountSaga)
  yield takeLatest('Timerecord/getTimeRecordListFile', getTimeRecordListFileSaga);
  yield takeLatest('Timerecord/setFilter', fetchCollaboratorstSaga);
  yield takeLatest('Timerecord/setGroupFilter', fetchCollaboratorstSaga);
  yield takeLatest('Timerecord/setDatesInterval', setDatesIntervalSaga);
  yield takeLatest('Timerecord/getLists', fetchCollaboratorstSaga);
  yield takeLatest('Timerecord/setSearchTerm', fetchCollaboratorstSaga);
  yield takeLatest('Timerecord/setSort', fetchCollaboratorstSaga);
  yield takeLatest('Timerecord/getURLParams', getURLParamsSaga);
  yield takeLatest('Timerecord/submitApprovedTimeRecords', submitApprovedTimeRecordsSaga)
  yield takeLatest('Timerecord/submitDisapprovedTimeRecords', submitDisapprovedTimeRecordsSaga)
}


