import * as Check from 'validations'
import { PassengerTypeForm } from 'types/trips/passengerTypes'

export const PassengerTypeValidations = {
    shortNamePt: [Check.isRequired],
    shortNameEn: [],
    shortNameEs: [],
    shortNameFr: [],
    namePt: [Check.isRequired],
    nameEn: [],
    nameEs: [],
    nameFr: [],
    descriptionPt: [Check.isRequired],
    descriptionEn: [],
    descriptionEs: [],
    descriptionFr: [],
    occupySeat: [Check.isRequired],
    isActive:[],
    isDefault: [],
    sort: [Check.isRequired],
}

export type SubmitSagaPayload  = {
    e?: any,
    edit: boolean,
    passengerTypeForm: PassengerTypeForm
}