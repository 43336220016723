/**
 * Entitymemberform scene sagas
 *
 * @author João Dias <jdias@ubiwhere.com>
 *
 *
 */
import { takeLatest, putResolve, call, select } from 'redux-saga/effects'
import { history } from 'utils';
import { actions, RootState } from 'store/rootSlices'
import { 
  defaultChangePasswordForm, 
  defaultEntityMemberForm, 
  entityMemberValidations, 
  CredentialsFormValidations
} from '../utils';
import {
  OnMountSagaPayload, 
  OnChangePasswordSaveSagaPayload,
  OnCreateUserAuthSagaPayload,
} from 'types/entity/entityMemberForm';
import { PayloadAction } from '@reduxjs/toolkit';
import { EntityMemberListItem, Entity } from 'types/entity/entity';
import * as Check from "validations";
import api from 'api';
import { toast } from 'react-toastify';
import i18n from "i18next";
import API from 'api'
import config from 'config';
import { hasPermission } from 'permissions'
const { PERMISSIONS } = config

function* onMountSaga({ payload }: PayloadAction<OnMountSagaPayload>) {
  const { permissions } = yield select((state: RootState) => state.App);

  const res: Entity = yield call(api.Entities.GetSingleEntity, payload.id);

  if (payload && payload.memid) {
    yield putResolve(actions.Entitymemberform.setEditMode(true));
    yield putResolve(actions.Entitymemberform.setHasEditPermission(hasPermission(permissions, PERMISSIONS.USERS.EDIT_PARTNER_USER)))
    yield putResolve(actions.Entitymemberform.fetchEntityMember(payload.memid));
    yield putResolve(actions.Dashboard.setDynamicBreadcrumb({
      path: `entities/${res?.name}/editEntityMember`,
      name: res?.name,
    }))

  } else {
    yield putResolve(actions.Entitymemberform.setEditMode(false));
    yield putResolve(actions.Entitymemberform.setForm(defaultEntityMemberForm));
    yield putResolve(actions.Entitymemberform.setLoading(false));
    yield putResolve(actions.Dashboard.setDynamicBreadcrumb({
      path: `entities/${res?.name}/newEntityMember`,
      name: res?.name,
    }))
  }
}

function* onUnmountSaga() { 
  yield putResolve(actions.Dashboard.setDynamicBreadcrumb(null))
}

function* getUserAuthSaga({ payload }: PayloadAction<string>) {
  try {
    const userAuthInfo = yield call(API.Auth.GetUser, {uuid: payload, userType: 'partner'})

    if (userAuthInfo.username !== null) {
      yield putResolve(actions.Entitymemberform.setUserAuthExists(true))
    }
    else {
      yield putResolve(actions.Entitymemberform.setUserAuthExists(false))
    }

  } catch (error) {
    yield putResolve(actions.Customerform.setUserAuthExists(false))
  }
}

function* fetchEntityMemberSaga({ payload }: PayloadAction<number>) {
  try {
    const res: EntityMemberListItem = yield call(api.Entities.GetSingleEntityMember, payload);
    yield putResolve(actions.Entitymemberform.setEntityMember(res));
    yield putResolve(actions.Entitymemberform.setEntityMemberID(res.uuid));
    yield putResolve(actions.Entitymemberform.getUserAuth(res.uuid))
  } catch (error) {
    toast.error(i18n.t('toasts.getEntityError'));
  } finally {
    yield putResolve(actions.Entitymemberform.setLoading(false));
  }
}

function* populateFormSaga({ payload }: PayloadAction<EntityMemberListItem>) {
  let form = {};
  try {
    Object.keys(defaultEntityMemberForm).forEach(key => {
      if (key === 'isPartnerActive') {
        form[key] = {
          ...defaultEntityMemberForm[key],
          value: payload.partner.isActive
        }
      } else {
        form[key] = {
          ...defaultEntityMemberForm[key],
          value: payload[key]
        }
      }
    });
  } finally {
    yield putResolve(actions.Entitymemberform.setForm(form));
  }
}


function* onSubmitSaga() {
  const { uuid, entityMemberForm, edit } = yield select((state: RootState) => state.Entitymemberform);

  yield putResolve(actions.Entitymemberform.setSubmitButtonDisabled(true))

  const validation = Check.checkValidation(entityMemberForm, entityMemberValidations);

  if (validation.invalid) {
    yield putResolve(actions.Entityform.setForm(validation.form));
    yield putResolve(actions.Entitymemberform.setSubmitButtonDisabled(false))
    toast.error(i18n.t('toasts.invalidFields'));
    return false;
  }

  let body = {
    name: entityMemberForm.name.value,
    email: entityMemberForm.email.value,
    partner: history.location.pathname.split("/")[2],
    is_admin: entityMemberForm.isAdmin.value,
    is_active: entityMemberForm.isActive.value,
  }

  try {
    yield putResolve(actions.Entitymemberform.setLoading(true));
    if (edit) {
      const res = yield call(api.Entities.EditEntityMember, uuid, body);
      if (res) {
        toast.success(i18n.t('toasts.editEntityMemberSuccess'));
        yield putResolve(actions.App.navigateBack());
      }
    } else {
      const res = yield call(api.Entities.CreateEntityMember, body);
      if (res.status === 201) {
        toast.success(i18n.t('toasts.createEntityMemberSuccess'));
        yield putResolve(actions.App.navigateBack());
      }
    }
  } catch (error) {
    const err: any = error
    if(err.response.status === 400) {

      if(err.response?.data?.errors?.fields?.email === 'not_unique'){
        toast.error(i18n.t("toasts.duplicateEmailUser"))
      } else {
        edit ? toast.error(i18n.t('toasts.editEntityMemberError')) :  toast.error(i18n.t('toasts.createEntityMemberError'));
      }      
    }
    if(err.response.status === 500) {
      edit ? toast.error(i18n.t('toasts.editEntityMemberError')) :  toast.error(i18n.t('toasts.createEntityMemberError'));
    }
  } finally {
    yield putResolve(actions.Entitymemberform.setSubmitButtonDisabled(false))
    yield putResolve(actions.Entitymemberform.setLoading(false));
  }
}

function* onChangePasswordSaveSaga({ payload }: PayloadAction<OnChangePasswordSaveSagaPayload>) {

  const { uuid } = yield select((state: RootState) => state.Entitymemberform);

  const newPassword = payload.changePasswordForm.newPassword.value
  const confirmPassword = payload.changePasswordForm.confirmPassword.value

  //object for validation
  const accessData = {
    confirmPassword: payload.changePasswordForm.confirmPassword,
    newPassword: payload.changePasswordForm.newPassword,
  }

  //validate form
  const validation = Check.checkValidation(accessData, CredentialsFormValidations)

  if (validation.invalid) {
    yield putResolve(actions.Entitymemberform.onChangePasswordFormChange({ 
      newPassword: validation.form.newPassword,
      confirmPassword: validation.form.confirmPassword
    }))
    toast.error(i18n.t("toasts.invalidFields"))
    return false;
  }

  if (newPassword !== confirmPassword) {
    return toast.error(i18n.t("toasts.passwordsDoNotMatch"))
  }

  //save new password on userForm
  yield putResolve(actions.Entitymemberform.showChangePasswordModal(false))

  if (confirmPassword !== "") {
    yield putResolve(actions.Entitymemberform.createUserAuth({
      password: confirmPassword,
      userType: 'partner',
      uuid: uuid
    }))
  }



}

function* onReSendEmailSaga() {
  const { entityMemberForm } = yield select((state: RootState) => state.Entitymemberform);

  try {
    const status = yield call(API.Auth.SendEmail, {
      userType: 'partner',
      email: entityMemberForm.email.value
    })

    if (status) {
      toast.info(i18n.t("toasts.emailSent"))
    }
  } catch (error) {
    toast.warning(i18n.t("toasts.errorSendingEmail"));
  }

}

// function* changePasswordSubmitSaga(payload: AnyAction) {
//   /* try {
//     const res = yield call(API.Auth.SendEmail, payload.username)

//     if(res){
//       try {
//         const change = yield call(API.Auth.resetPassword, res.token, { password: payload.password })
//         if(change.status === 200){
//           toast.info(i18n.t("toasts.passwordChangeSuccess1"))
//         }
//       } catch (error) {
//         toast.error(i18n.t("toasts.passwordChangeError"))
//       }
//     }
//   } catch (error) {
//     toast.error(i18n.t("toasts.passwordChangeError"))
//   }   */
// }

function* onCreateUserAuthSaga({ payload }: PayloadAction<OnCreateUserAuthSagaPayload>) {

  try {
    const res = yield call(API.Auth.CreateUser, payload)

    if (res) {
      toast.success(i18n.t("toasts.credentialsCreated"))
      yield putResolve(actions.Entitymemberform.setUserAuthExists(true))
      yield putResolve(actions.Entitymemberform.onChangePasswordFormChange(defaultChangePasswordForm))
    }
  } catch (error) {
    toast.error(i18n.t("toasts.credentialsCreationError"));
  }
}


export default function* watcherSignin() {
  yield takeLatest('Entitymemberform/onMount', onMountSaga)
  yield takeLatest('Entitymemberform/onUnmount', onUnmountSaga)
  yield takeLatest('Entitymemberform/fetchEntityMember', fetchEntityMemberSaga)
  yield takeLatest('Entitymemberform/getUserAuth', getUserAuthSaga)
  yield takeLatest('Entitymemberform/setEntityMember', populateFormSaga)
  yield takeLatest('Entitymemberform/onSubmit', onSubmitSaga)
  yield takeLatest('Entitymemberform/onChangePasswordSave', onChangePasswordSaveSaga)
  // yield takeLatest('Entitymemberform/changePasswordSubmit', changePasswordSubmitSaga)
  yield takeLatest('Entitymemberform/onReSendEmail', onReSendEmailSaga)
  yield takeLatest('Entitymemberform/createUserAuth', onCreateUserAuthSaga)
}


