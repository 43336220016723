/**
 * Custombutton component
 *
 * @author João Dias <jdias@ubiwhere.com>
 *
 *
 */

import React from 'react'
//import styled from 'styled-components'
import { CButton } from '@coreui/react-pro';
import { getIconNameByPropString } from './utils';

interface PropTypes {
  id?: string,
  buttonStyle?: React.CSSProperties,
  color?: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'dark' | 'light' | string,
  text?: string | React.ReactFragment,
  variant?: 'outline' | 'ghost',
  iconFirst?: boolean,
  icon?: 'list' | 'confirm' | 'cancel' | 'add' | 'download' | 'upload' | 'doubleCheck' | 'lock' | 'send' | 'refresh',
  onClick?: Function,
  disabled?: boolean,
}

const CustomButton = (props: PropTypes) => {
  return (
    <CButton
      id={props.id}
      style={props.buttonStyle}
      color={props.color || 'primary'}
      variant={props.variant || undefined}
      onClick={e => {
        if (props.onClick) props.onClick(e)
      }}
      disabled={props.disabled}
    >
      {props.iconFirst && props.icon && getIconNameByPropString(props.icon, props.color)}
      &nbsp;
      {props.text ? props.text : ''}
      &nbsp;
      {!props.iconFirst && props.icon && getIconNameByPropString(props.icon, props.color)}
    </CButton>
  )
}

export default CustomButton
