import * as React from "react"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width="24" height="25" viewBox="0 0 16 25" fill={props.fill ? props.fill : "#EBEDEF"} xmlns="http://www.w3.org/2000/svg">
            <path d="M12 12.5C10.9 12.5 10 11.6 10 10.5C10 9.4 10.9 8.5 12 8.5C13.1 8.5 14 9.4 14 10.5C14 11.6 13.1 12.5 12 12.5ZM18 10.7C18 7.07 15.35 4.5 12 4.5C8.65 4.5 6 7.07 6 10.7C6 13.04 7.95 16.14 12 19.84C16.05 16.14 18 13.04 18 10.7ZM12 2.5C16.2 2.5 20 5.72 20 10.7C20 14.02 17.33 17.95 12 22.5C6.67 17.95 4 14.02 4 10.7C4 5.72 7.8 2.5 12 2.5Z" fill={props.fill ? props.fill : "#EBEDEF"} />
        </svg>
    )
}

export default SvgComponent
