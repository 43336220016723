/**
 * Maintenanceform scene
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */
import React, { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { actions, RootState } from 'store/rootSlices'
import { CCol, CForm, CRow } from '@coreui/react-pro'
import SectionContainer from 'shared/components/Sectioncontainer'
import CustomTextInput from 'shared/components/CustomTextInput'
import Dropdown from 'shared/components/FormComponents/dropdown'
import Datepicker from 'shared/components/FormComponents/datepicker'
import theme from 'ui/theme'
import CustomCheckbox from 'shared/components/CustomCheckbox'
import CustomButton from 'shared/components/CustomButton'
import Loadingcontainer from 'shared/components/Loadingcontainer'
import FileUploader from 'shared/components/FormComponents/fileuploader'
import Photoscomponent from 'shared/components/Photoscomponent'
import CustomModal from 'shared/components/CustomModal'
import i18n from 'i18next'
import { hasPermission } from 'permissions'
import config from 'config'
import { Wrapper, Row, ButtonWrapper } from './styles'


const Maintenanceform = () => {
  const { fleetLongList } = useSelector((state: RootState) => state.FleetManagement)
  const { 
    maintenanceForm, 
    loading, 
    deleteModal, 
    isEdit, 
    hasEditPermission,
    submitButtonState,
    documents,
    newDocumentsID,
    maintenance
  } = useSelector((state: RootState) => state.FleetMaintenanceForm)
  const { 
    onMount, 
    onUnmount, 
    onFormChange, 
    onSubmit, 
    showDeleteModal, 
    deleteMaintenance ,
    addToDocumentsArray,
    createEntryDocument,
    deleteDocumentsFromArray,
    reOrderDocsArray
  } = actions.FleetMaintenanceForm
  const { PERMISSIONS } = config
  const { permissions } = useSelector((state: RootState) => state.App)
  const { navigateBack } = actions.App

  const dispatch = useDispatch()
  const { id } = useParams()

  useEffect(() => {
    dispatch(onMount(id))
    return () => {
      //dispatch(onUnmount())
    }
  }, [dispatch, onMount, onUnmount, id]);

  const handleAddDocument = (e) => {
    dispatch(addToDocumentsArray({ documents, e, newDocumentsID}))
  }

  if (loading) return (
    <Loadingcontainer />
  )

  return (
    <Wrapper>
      <CForm>
        <SectionContainer title={i18n.t('maintenanceForm.title1')} style={{ paddingBottom: '24px' }}>
          <CRow className="g-3">
            <CCol >
              <CustomTextInput
                id='maintenanceForm-description'
                disabled={!hasEditPermission}
                required
                valid={maintenanceForm.description.valid}
                value={maintenanceForm.description.value}
                helpText={maintenanceForm.description.message}
                placeholder={i18n.t('placeholders.description')}
                label={i18n.t('maintenanceForm.description')}
                onChange={(e) => { dispatch(onFormChange({ ...maintenanceForm, description: { value: e.target.value, valid: true, message: "" } })) }}
              />
            </CCol>
          </CRow>
          <Row className="g-3">
            <CCol>
              <Dropdown
                id='maintenanceForm-vehicleId'
                disabled={!hasEditPermission}
                required
                valid={maintenanceForm.vehicleId.valid}
                value={maintenanceForm.vehicleId.value}
                label={i18n.t('maintenanceForm.vehicle')}
                placeholder={i18n.t('placeholders.vehicle')}
                options={fleetLongList.map(el => {
                  return {
                    label: el.name,
                    value: el.id,
                  }
                })}
                onChange={(e) => { dispatch(onFormChange({ ...maintenanceForm, vehicleId: { value: e.target.value, valid: true, message: "" } })) }}
              />
            </CCol>
          </Row>
          <Row className="g-3">
            <CCol>
              <CustomTextInput
                id='maintenanceForm-extraInfo'
                disabled={!hasEditPermission}
                required
                valid={maintenanceForm.extraInfo.valid}
                value={maintenanceForm.extraInfo.value}
                helpText={maintenanceForm.extraInfo.message}
                placeholder={i18n.t('placeholders.observations')}
                label={i18n.t('maintenanceForm.observations')}
                onChange={(e) => { dispatch(onFormChange({ ...maintenanceForm, extraInfo: { value: e.target.value, valid: true, message: "" } })) }}
              />
            </CCol>
          </Row>
        </SectionContainer>

        <SectionContainer title={i18n.t('cashManagement.depositReceipt')} style={{ width: '100%', paddingBottom: '24px' }}>
          {
            hasEditPermission 
              && 
            <FileUploader
              id='fleet-maintenanceUploader'
              disabled={false}
              required={false}
              label={'Upload'}
              accept='.pdf'
              fileSizeLimit={100000000}
              onChange={(e) => { handleAddDocument(e) }} 
            />
          }
          {
            hasPermission(permissions, PERMISSIONS.FLEET.EDIT_MAINTENANCE)
              &&
            <Photoscomponent
              id='cashManagementPhotos'
              array={documents}
              onDrag={e => dispatch(reOrderDocsArray({ documents, e }))}
              onDelete={(e, index) => dispatch(deleteDocumentsFromArray({ documents, index }))}
            />
          }
        </SectionContainer>


        <SectionContainer title={i18n.t('maintenanceForm.title2')} style={{ paddingBottom: '24px' }}>
          <CRow className="g-3">
            <CCol >
              <CustomCheckbox
                id='maintenanceForm-isFinished'
                disabled={!hasEditPermission}
                wrapperStyle={{ fontSize: '20px' }}
                text={i18n.t('maintenanceForm.maintenanceDone')}
                onClick={(e) => { dispatch(onFormChange({ ...maintenanceForm, isFinished: { value: !maintenanceForm.isFinished.value, valid: true, message: "" } })) }}
                checked={maintenanceForm.isFinished.value ? true : false}
                switch />
            </CCol>
          </CRow>
          <Row className="g-3">
            <CCol>
              <Datepicker
                id='maintenanceForm-date'
                required
                valid={maintenanceForm.date.valid}
                disabled={!hasEditPermission || !maintenanceForm.isFinished.value}
                value={maintenanceForm.date.value}
                helpText={maintenanceForm.date.message}
                label={i18n.t('maintenanceForm.serviceDate')}
                onChange={(date, e) => {
                  dispatch(onFormChange({ ...maintenanceForm, date: { value: date, valid: true, message: "" } }))
                }}
              />
            </CCol>
            <CCol>
              <CustomTextInput
                id='maintenanceForm-supplier'
                required
                disabled={!hasEditPermission || !maintenanceForm.isFinished.value}
                valid={maintenanceForm.supplier.valid}
                value={maintenanceForm.supplier.value}
                helpText={maintenanceForm.supplier.message}
                label={i18n.t('maintenanceForm.supplier')}
                placeholder={i18n.t('placeholders.supplier')}
                onChange={(e) => { dispatch(onFormChange({ ...maintenanceForm, supplier: { value: e.target.value, valid: true, message: "" } })) }}
              />
            </CCol>
          </Row>
          <Row className="g-3">
            <CCol>
              <CustomTextInput
                id='maintenanceForm-invoiceUid'
                required
                disabled={!hasEditPermission || !maintenanceForm.isFinished.value}
                valid={maintenanceForm.invoiceUid.valid}
                value={maintenanceForm.invoiceUid.value}
                helpText={maintenanceForm.invoiceUid.message}
                label={i18n.t('maintenanceForm.invoiceNr')}
                placeholder={i18n.t('placeholders.number')}
                onChange={(e) => { dispatch(onFormChange({ ...maintenanceForm, invoiceUid: { value: e.target.value, valid: true, message: "" } })) }}
              />
            </CCol>
            <CCol>
              <CustomTextInput
                id='maintenanceForm-cost'
                required
                disabled={!hasEditPermission || !maintenanceForm.isFinished.value}
                valid={maintenanceForm.cost.valid}
                value={maintenanceForm.cost.value}
                helpText={maintenanceForm.cost.message}
                icon="euro"
                label={i18n.t('maintenanceForm.cost')}
                placeholder={i18n.t('maintenanceForm.cost')}
                onChange={(e) => { dispatch(onFormChange({ ...maintenanceForm, cost: { value: e.target.value, valid: true, message: "" } })) }}
              />
            </CCol>
          </Row>
          <Row className="g-3">
            <CCol>
              <CustomTextInput
                id='maintenanceForm-report'
                disabled={!hasEditPermission || !maintenanceForm.isFinished.value}
                valid={maintenanceForm.report.valid}
                value={maintenanceForm.report.value}
                helpText={maintenanceForm.report.message}
                label={i18n.t('maintenanceForm.report')}
                placeholder={i18n.t('placeholders.report')}
                onChange={(e) => { dispatch(onFormChange({ ...maintenanceForm, report: { value: e.target.value, valid: true, message: "" } })) }}
              />
            </CCol>
          </Row>
          <Row className="g-3">
            <CCol>
              <CustomCheckbox
                id='maintenanceForm-isPaid'
                checked={maintenanceForm.isPaid.value}
                disabled={!hasEditPermission || !maintenanceForm.isFinished.value}
                text={i18n.t('maintenanceForm.paymentMade')}
                onClick={(e) => { dispatch(onFormChange({ ...maintenanceForm, isPaid: { value: !maintenanceForm.isPaid.value, valid: true, message: "" } })) }} />
            </CCol>
          </Row>
        </SectionContainer>
        {/* edit && hasPermission(permissions.fleet, PERMISSIONS.FLEET.DELETE_MAINTENANCE) && <SectionContainerDelete
          id='maintenanceForm-delete'
          title={i18n.t('maintenanceForm.deleteMaintenanceTitle')}
          text={<p>{i18n.t('maintenanceForm.deleteMaintenance1')}<br />{i18n.t('common.deleteLine2')}</p>}
          onClick={e => dispatch(showDeleteModal(true))} />
        */}
        <ButtonWrapper>
          <CustomButton id='maintenanceForm-cancel' onClick={() => dispatch(navigateBack())} text={i18n.t('common.cancelBtn')} color="link" buttonStyle={{ color: theme().danger, textDecoration: 'unset' }} />
          {
            isEdit ? 
            hasPermission(permissions, PERMISSIONS.FLEET.EDIT_MAINTENANCE) && 
              <CustomButton
                id='maintenanceForm-save'
                disabled={submitButtonState}
                onClick={() => dispatch(createEntryDocument(maintenance.id))}
                text={i18n.t('common.saveBtn')}
                icon="confirm"
                color="success"
                buttonStyle={{ color: "#FFFFFF" }} />
              :
              hasPermission(permissions, PERMISSIONS.FLEET.CREATE_MAINTENANCE) &&
              <CustomButton
                id='maintenanceForm-save'
                disabled={submitButtonState}
                onClick={() => dispatch(onSubmit())}
                text={i18n.t('common.saveBtn')}
                icon="confirm"
                color="success"
                buttonStyle={{ color: "#FFFFFF" }} />
          }
          
        </ButtonWrapper>
      </CForm>
      {
        hasPermission(permissions, PERMISSIONS.FLEET.DELETE_MAINTENANCE) && <CustomModal
          id='maintenanceForm-deleteMaintenanceModal'
          visible={deleteModal}
          warningType='warning'
          warningName='Aviso'
          buttonIcon={false}
          footerPosition='center'
          textAlign='center'
          children={<p>{i18n.t('maintenanceForm.deleteMaintenance1')}</p>}
          onclose={e => dispatch(showDeleteModal(false))}
          onsubmit={e => {
            dispatch(deleteMaintenance());
          }} />
      }
    </Wrapper>
  )
}

export default Maintenanceform