/**
 * Usercashinfo component
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */

import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { actions, RootState } from 'store/rootSlices'
import { CTableDataCell } from '@coreui/react-pro'
import { ColoredDataCellText } from 'shared/genericStyledComponents'
import { Statement } from 'types/invoicing/statements'
import CustomTextInput from 'shared/components/CustomTextInput'
import theme from 'ui/theme'
import { UserCashInfoTableRow } from './styles'

interface PropTypes {
  t: any,
  cashClosed: boolean,
  statement: Statement
}

const Usercashinfo = ({ t, cashClosed, statement }: PropTypes) => {

  const { statementForm, hasEditPermission } = useSelector((state: RootState) => state.UserCashManagementForms)
  const { onStatementFormUpdate } = actions.UserCashManagementForms

  const dispatch = useDispatch()

  return (
    <>
      <UserCashInfoTableRow>
        <CTableDataCell>{t('cashManagement.cashFund')}</CTableDataCell>
        <CTableDataCell>
          {
            cashClosed ?
              <CTableDataCell>{statement.cashFund}</CTableDataCell>
              :
              <CustomTextInput
                icon='euro'
                disabled={statement.isClosed || !hasEditPermission}
                value={statementForm.cashFund.value}
                valid={statementForm.cashFund.valid}
                helpText={statementForm.cashFund.message}
                onChange={(e) => {
                  dispatch(onStatementFormUpdate({ ...statementForm, cashFund: { value: e.target.value, valid: true, message: "" } }))
                }}
              />
          }
        </CTableDataCell>
      </UserCashInfoTableRow>
      <UserCashInfoTableRow>
        <CTableDataCell>{t('cashManagement.income')}</CTableDataCell>
        <CTableDataCell>
          {
            cashClosed ?
              <ColoredDataCellText noPadding color={theme().success}>{statement.income}</ColoredDataCellText>
              :
              <CustomTextInput
                icon='euro'
                disabled={true}
                value={statementForm.income.value}
                valid={statementForm.income.valid}
                helpText={statementForm.income.message}
                onChange={(e) => {
                  dispatch(onStatementFormUpdate({ ...statementForm, income: { value: e.target.value, valid: true, message: "" } }))
                }}
              />
          }
        </CTableDataCell>
      </UserCashInfoTableRow>
      <UserCashInfoTableRow>
        <CTableDataCell>{t('cashManagement.expenses')}</CTableDataCell>
        <CTableDataCell>
          {
            cashClosed ?
              <ColoredDataCellText noPadding color={theme().danger}>{statement.expenses}</ColoredDataCellText>
              :
              <CustomTextInput
                icon='euro'
                disabled={true}
                value={statementForm.expenses.value}
                valid={statementForm.expenses.valid}
                helpText={statementForm.expenses.message}
                onChange={(e) => {
                  dispatch(onStatementFormUpdate({ ...statementForm, expenses: { value: e.target.value, valid: true, message: "" } }))
                }}
              />
          }
        </CTableDataCell>
      </UserCashInfoTableRow>
      <UserCashInfoTableRow>
        <CTableDataCell>{t('cashManagement.bankDeposit')}</CTableDataCell>
        <CTableDataCell>
          {
            cashClosed ?
              <CTableDataCell>{statement.bankDeposit}</CTableDataCell>
              :
              <CustomTextInput
                icon='euro'
                disabled={statement.isClosed || !hasEditPermission}
                value={statementForm.bankDeposit.value}
                valid={statementForm.bankDeposit.valid}
                helpText={statementForm.bankDeposit.message}
                onChange={(e) => {
                  dispatch(onStatementFormUpdate({ ...statementForm, bankDeposit: { value: e.target.value, valid: true, message: "" } }))
                }}
              />
          }
        </CTableDataCell>
      </UserCashInfoTableRow>
      <UserCashInfoTableRow>
        <CTableDataCell>{t('cashManagement.multibancoSales')}</CTableDataCell>
        <CTableDataCell>
          {
            cashClosed ?
              <CTableDataCell>{statement.multibancoSales}</CTableDataCell>
              :
              <CustomTextInput
                icon='euro'
                disabled={statement.isClosed || !hasEditPermission}
                value={statementForm.multibancoSales.value}
                valid={statementForm.multibancoSales.valid}
                helpText={statementForm.multibancoSales.message}
                onChange={(e) => {
                  dispatch(onStatementFormUpdate({ ...statementForm, multibancoSales: { value: e.target.value, valid: true, message: "" } }))
                }}
              />
          }
        </CTableDataCell>
      </UserCashInfoTableRow>
      <UserCashInfoTableRow>
        <CTableDataCell>{t('cashManagement.notDeposited')}</CTableDataCell>
        <CTableDataCell>
          {
            cashClosed ?
              <CTableDataCell>{statement.notDeposited}</CTableDataCell>
              :
              <CustomTextInput
                icon='euro'
                disabled={statement.isClosed || !hasEditPermission}
                value={statementForm.notDeposited.value}
                valid={statementForm.notDeposited.valid}
                helpText={statementForm.notDeposited.message}
                onChange={(e) => {
                  dispatch(onStatementFormUpdate({ ...statementForm, notDeposited: { value: e.target.value, valid: true, message: "" } }))
                }}
              />
          }
        </CTableDataCell>
      </UserCashInfoTableRow>
      <UserCashInfoTableRow>
        <CTableDataCell>{t('cashManagement.estimated')}</CTableDataCell>
        <CTableDataCell>
          {
            cashClosed ?
              <CTableDataCell>{statement.estimated}</CTableDataCell>
              :
              <CustomTextInput
                disabled={true}
                icon='euro'
                value={statementForm.estimated.value}
                valid={statementForm.estimated.valid}
                helpText={statementForm.estimated.message}
                onChange={(e) => {
                  dispatch(onStatementFormUpdate({ ...statementForm, estimated: { value: e.target.value, valid: true, message: "" } }))
                }}
              />
          }
        </CTableDataCell>
      </UserCashInfoTableRow>
      <UserCashInfoTableRow summary>
        <CTableDataCell><strong>{t('cashManagement.cashRegisted')}</strong></CTableDataCell>
        <CTableDataCell><strong>{statement.registeredIncome}</strong></CTableDataCell>
      </UserCashInfoTableRow>
    </>
  )
}

export default Usercashinfo