import * as React from "react"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.66667 3.66659H6.33334V6.33325H3.66667V7.66659H6.33334V10.3333H7.66667V7.66659H10.3333V6.33325H7.66667V3.66659ZM7 0.333252C3.32 0.333252 0.333336 3.31992 0.333336 6.99992C0.333336 10.6799 3.32 13.6666 7 13.6666C10.68 13.6666 13.6667 10.6799 13.6667 6.99992C13.6667 3.31992 10.68 0.333252 7 0.333252ZM7 12.3333C4.06 12.3333 1.66667 9.93992 1.66667 6.99992C1.66667 4.05992 4.06 1.66659 7 1.66659C9.94 1.66659 12.3333 4.05992 12.3333 6.99992C12.3333 9.93992 9.94 12.3333 7 12.3333Z" fill="#321FDB"/>
        </svg>
    )
}

export default SvgComponent

