import styled from 'styled-components'
import { CTable, CTableBody, CTableRow, CTableDataCell } from '@coreui/react-pro';
import theme from 'ui/theme';

export const Wrapper = styled.div`
   width: 100%;
   overflow: scroll;
   &::-webkit-scrollbar {
    display: none;
   }
   
   > div {
     border-radius: 5px 5px 0 0;
   }
 `

export const Table = styled(CTable)`
  border-collapse: separate;
  border-spacing: 0 7px;
  height: 100%;
`

export const CustomCTableBody = styled(CTableBody)`
  min-height: calc(100% - 20px);

  & tr:last-child {
    margin-bottom: 100px;
}
`

export const CustomCTableRow = styled(CTableRow)`
  height: 50vh;
  display: flex;
  justify-content: center;
`

export const NoResultsCell = styled(CTableDataCell)`
  height: 100%;
  display: flex;
  align-items: center; 
  padding: 15px 15px;
  color: ${theme().gray500Color} !important;
`