import styled from 'styled-components'
import { CTableDataCell } from '@coreui/react-pro';
import theme from 'ui/theme';


const BookingStateLabel = styled.span`
    padding:2px 4px;
    color:${theme().white};
    font-weight:700;
    border-radius:5px;

    &.to_refund {
        background-color: ${theme().warning};
    }
    &.to_pay {
        background-color: ${theme().warning};
    }
    &.canceled {
        background-color: ${theme().darkColor};
    }
    &.pending {
        background-color: ${theme().warning};
    }
    &.paid {
        background-color: ${theme().success};
    }
    &.refunded {
        background-color: ${theme().danger};
    }
`

const ClickableTableDataCell = styled(CTableDataCell)`
  cursor: pointer
`

const BookingNumber = styled.div`
  display:flex;
  justify-content: flex-start;
  column-gap: 8px;
`

const ServiceInfo = styled.div`
  display:flex;
  justify-content: space-between;
  column-gap: 8px;
`

export {
  BookingStateLabel,
  ClickableTableDataCell,
  BookingNumber,
  ServiceInfo,
}