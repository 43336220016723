/**
 * Fleetfuelling scene slice
 *
 * @author João Dias <jdias@ubiwhere.com>
 *
 *
 */
import { createSlice } from '@reduxjs/toolkit'
import { SortType } from 'types/misc'
import { FuellingListItem } from 'types/fleet/fuel'
import { TanksList } from 'types/fleet/vehicle';

type InitialState = {
  sort: SortType,
  consumptionsPage: number,
  searchTerm: string,
  consumptionsTotal: number,
  fuellingsPage: number,
  fuellingsTotal: number,
  filter: string,
  vehicle: string,
  fuelStation: string,
  startDate: string,
  endDate: string,
  hasDateParams: boolean,
  consumptionsList: Array<any>,
  fuellingsList: FuellingListItem[],
  fuelLocationsList: Array<any>
  loading: boolean,
  tanks: TanksList,
  fuelPaymentTypes: any[],
}

const now = new Date().toISOString().slice(0, 10)
const initialState: InitialState = {
  sort: {
    direction: null,
    field: null,
  },
  consumptionsPage: 1,
  searchTerm: "",
  consumptionsTotal: 0,
  fuellingsPage: 1,
  fuellingsTotal: 0,
  filter: "",
  hasDateParams: false,
  startDate: now,
  endDate: now,
  vehicle: "",
  fuelStation: "",
  consumptionsList: [],
  fuellingsList: [],
  fuelLocationsList: [],
  loading: true,
  tanks: [],
  fuelPaymentTypes: [],
}

export default createSlice({
  name: 'Fleetfuelling',
  initialState,
  reducers: {
    onMount: (state, { payload }) => { },
    onUnmount: (state) => {
      return {
        ...initialState,
        fuelPaymentTypes: state.fuelPaymentTypes
      }
    },
    setSearchTerm: (state, { payload }) => {
      state.searchTerm = payload;
    },
    getURLParams: (state, payload) => { },
    setSort: (state, { payload }) => {
      state.sort = payload;
    },
    setConsumptionsPage: (state, { payload }) => { state.consumptionsPage = payload },
    setFuellingsPage: (state, { payload }) => { state.fuellingsPage = payload },
    setFuellingsTotal: (state, { payload }) => { state.fuellingsTotal = payload },
    setConsumptionsTotal: (state, { payload }) => { state.consumptionsTotal = payload },
    getConsumptionsList: () => { },
    getFuelAndConsumpList: () => { },
    setConsumptionsList: (state, { payload }) => {
      state.consumptionsList = payload;
    },
    setFilter: (state, { payload }) => {
      state.fuellingsPage = 1
      state.consumptionsPage = 1
      state.filter = payload
    },
    setStartDate: (state, { payload }) => {
      state.fuellingsPage = 1
      state.consumptionsPage = 1
      state.startDate = payload
    },
    setEndDate: (state, { payload }) => {
      state.fuellingsPage = 1
      state.consumptionsPage = 1
      state.endDate = payload
    },
    setHasDateParams: (state, { payload }) => {
      state.hasDateParams = payload
    },
    setDatesInterval: (state, { payload }) => {
      state.fuellingsList = []
      state.consumptionsList = []
      state.fuellingsPage = 1
      state.consumptionsPage = 1
    },
    setVehicle: (state, { payload }) => {
      state.fuellingsPage = 1
      state.consumptionsPage = 1
      state.vehicle = payload
    },
    setFuelStation: (state, { payload }) => {
      state.fuellingsPage = 1
      state.consumptionsPage = 1
      state.fuelStation = payload
    },
    getFuellingsList: () => { },
    setFuellingsList: (state, { payload }) => {
      state.fuellingsList = payload;
    },
    getFuellingLocations: () => { },
    setFuellingLocationList: (state, { payload }) => {
      state.fuelLocationsList = payload;
    },
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
    populateFiltersOnMount: (state, { payload }) => {
      state.searchTerm = payload.searchTerm
      state.filter = payload.filter
      state.vehicle = payload.vehicle
      state.startDate = payload.startDate
      state.endDate = payload.endDate
    },
    getTanksList: () => { },
    setTanks: (state, { payload }) => {
      state.tanks = payload;
    },
    getFuelPaymentTypes: () => { },
    setFuelPaymentTypes: (state, { payload }) => {
      state.fuelPaymentTypes = payload
    },
  }
})
