/**
 * Activitylisttablerow component
 *
 * @author Manuel Gil <mgi@ubiwhere.com>
 *
 *
 */

import React from 'react'
import { ActionsCell, AvailabilityTableDataCell, Abrev } from 'shared/genericStyledComponents'
import { ActivityListItem } from 'types/trips/activity/activity'
import { TableRowPropTypes } from 'types/misc'
import { CTableDataCell } from '@coreui/react-pro'
import ChevronRight from 'assets/icons/chevron_right'
import { actions } from 'store/rootSlices'
import { useDispatch } from 'react-redux';
import { CustomChevronCell } from '../../styles'
import { CustomCTableRow } from './styles'

type PropTypes = TableRowPropTypes & {
  id?: string,
  activity: ActivityListItem,
  permission: boolean
}

const Activitylisttablerow = (props: PropTypes) => {

  const dispatch = useDispatch()
  const {
    permission,
    activity: {
      shortName,
      name,
      category,
      duration,
      isActive,
      color,
      id
    }
  } = props

  return (
    <CustomCTableRow id={props.id}>
      <Abrev color={color} id={`${props.id}-shortName`} onClick={() => props.onClick()} >{shortName}</Abrev>
      <CTableDataCell id={`${props.id}-name`} onClick={() => props.onClick()}>{name}</CTableDataCell>
      <CTableDataCell>{category?.name}</CTableDataCell>
      <CTableDataCell>{duration.split(':')[0] +"h:" +duration.split(':')[1]+"m"}</CTableDataCell>
      <CTableDataCell>
        <AvailabilityTableDataCell isactive={isActive}>{isActive ? "Disponível" : "Indisponível"}</AvailabilityTableDataCell>
      </CTableDataCell>
      <ActionsCell nopointer>
        {props.actions}
        <CustomChevronCell id={`${props.id}-chevron`} >
          {
            permission &&
            <a href={`/viagens/configuracoes/editar-atividade/${id}`} onClick={(e) => {
              e.preventDefault()
              dispatch(actions.App.navigateTo(`/viagens/configuracoes/editar-atividade/${id}`))
              }}>
              <ChevronRight></ChevronRight>
            </a>
          }
        </CustomChevronCell>
      </ActionsCell>
    </CustomCTableRow>
  )
}

export default Activitylisttablerow