
import { createSlice } from '@reduxjs/toolkit'
import type { BiDashboard } from 'types/trips/bi'

interface initialState {
  loading: boolean,
  dashboard: BiDashboard | null,
}

const initialState : initialState = {
  loading: false,
  dashboard: null
}

export default createSlice({
  name: 'BiDashboard',
  initialState,
  reducers: {
    onMount: (state, { payload }) => {},
    onUnmount: () => {
      return initialState
    },
    setLoading:(state, { payload }) => {
      state.loading = payload
    },
    getDashboard: (state, { payload }) => {},
    setDashboard: (state, { payload }) => {
      state.dashboard = payload
    },
  }
})