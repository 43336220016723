import styled from 'styled-components'
import theme from "ui/theme"

export const Wrapper = styled.div`
  width: 100%;
`

export const TabRow = styled.div`
  display: flex;
  height: 45px;
  overflow: hidden;
  overflow-x: auto;

  div:last-child {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items:center;
    overflow:auto;

    > p {
      margin:0;
      font-family: 'Inter';
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      text-align: right;
      color: ${theme().gray600Color};
    }

    -ms-overflow-style: none;
    scrollbar-width: none;

    ::-webkit-scrollbar {
      display: none;
    }
  }


`

export const TabObject = styled.div<{ isSelected: boolean }>`
  min-width: 175px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 17px;
  font-family: 'Inter';
  font-size: 14px;
  color: ${props => props.isSelected ? theme().darkColor : theme().primary};
  padding: 0px 24px;
  background-color:${props => props.isSelected ? theme().gray200Color : "transparent"};
  border-radius: 4px 4px 0 0;
  cursor: pointer;
  ${props => props.isSelected ?
    "padding-bottom: 4px; margin-bottom: -4px;"
    : ""
  }

  > span {
    line-height: initial;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 0;
    width: 22px;
    margin-left: 8px;
  }
`

export const ContentContainer = styled.div``
