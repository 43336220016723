//import { TripActivityMock } from 'mocks/TripActivityMock'
import { AnyAction } from 'redux'
import { takeLatest, putResolve, call, select  } from 'redux-saga/effects'
import API from 'api'
import { actions, RootState } from 'store/rootSlices'
import { toast } from 'react-toastify'
import * as Check from 'validations';
import { ServiceFormValidations, ServiceFormValidationsEdit } from '../utils'
import i18n from 'i18next';
import { hasPermission } from 'permissions'
import config from 'config'

//state
const { PERMISSIONS } = config
const servID = (state: RootState) => state.TripServiceForm.serviceID

function* onMountSaga({ payload }: AnyAction) {
  const { permissions } = yield select((state: RootState) => state.App);

  if (payload) {

    yield putResolve(actions.TripServiceForm.setHasEditPermission(hasPermission(permissions, PERMISSIONS.TRIPS.SERVICES.EDIT_SERVICE)))
    const serviceID = payload

    try {
      //get service from api
      const serviceInfo = yield call(API.Services.GetService, serviceID)

      if (serviceInfo) {
        yield putResolve(actions.TripServiceForm.setServiceID(serviceID))
        yield putResolve(actions.TripServiceForm.setAction(true))
        yield putResolve(actions.TripServiceForm.populateForm(serviceInfo))
      } else {
        toast.error(i18n.t("toasts.getTripServiceError"));
      }
    } catch (error) {
    }

  } else {
    // creating a new Service
    yield putResolve(actions.TripServiceForm.setAction(false))
  }
}

function* onSubmitSaga({ payload }: AnyAction) {

  yield putResolve(actions.TripServiceForm.setSubmitButtonDisabled(true))

  const validation = payload.edit === true ?
    Check.checkValidationAlternative(payload.serviceForm, ServiceFormValidationsEdit)
    :
    Check.checkValidationAlternative(payload.serviceForm, ServiceFormValidations)

  if (validation.invalid === true) {
    const updateValidationMessages = Check.setAndCheckValidationAlternative(payload.serviceForm, payload.serviceForm, ServiceFormValidations)
    yield putResolve(actions.TripServiceForm.onFormChange(updateValidationMessages))
    yield putResolve(actions.TripServiceForm.setSubmitButtonDisabled(false))
    toast.error(i18n.t("toasts.invalidFields"));
  }
  else {
    
    if (!payload.edit) {
      try {
        const finalForm = { 
          ...payload.serviceForm,
          price: { value : Number.parseFloat(payload.serviceForm.price.value).toFixed(2), valid:true, message:""}
        }

        const service = yield call(API.Services.CreateService, finalForm)

        if (service) {
          yield putResolve(actions.App.navigateTo('/viagens/configuracoes'))
          toast.success(i18n.t("toasts.createServiceSuccess"));
        }

      } catch (error) {
        toast.error(i18n.t("toasts.createServiceError"));
      }
    }
    else {
      const serviceID = yield select(servID)
      
      try {
        const finalForm = { 
          ...payload.serviceForm,
          price: { value : Number.parseFloat(payload.serviceForm.price.value).toFixed(2), valid:true, message:""}
        }
        
        const success = yield call(API.Services.EditService, serviceID, finalForm)

        if (success) {
          yield putResolve(actions.App.navigateTo('/viagens/configuracoes'))
          toast.success(i18n.t("toasts.editServiceSuccess"))
        }
      } catch (error) {
        toast.warning(i18n.t("toasts.editServiceError"))
      }
    }
  }

  yield putResolve(actions.TripServiceForm.setSubmitButtonDisabled(false))
}

function* onUnmountSaga() {}


export default function* watcherSignin() {
  yield takeLatest('tripserviceform/onMount', onMountSaga)
  yield takeLatest('tripserviceform/onUnmount', onUnmountSaga)
  yield takeLatest('tripserviceform/onSubmit', onSubmitSaga)
}


