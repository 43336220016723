import * as React from "react"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
      <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M19 14.5V6.5C19 5.4 18.1 4.5 17 4.5H3C1.9 4.5 1 5.4 1 6.5V14.5C1 15.6 1.9 16.5 3 16.5H17C18.1 16.5 19 15.6 19 14.5ZM17 14.5H3V6.5H17V14.5ZM10 7.5C8.34 7.5 7 8.84 7 10.5C7 12.16 8.34 13.5 10 13.5C11.66 13.5 13 12.16 13 10.5C13 8.84 11.66 7.5 10 7.5ZM23 7.5V18.5C23 19.6 22.1 20.5 21 20.5H4C4 19.5 4 19.6 4 18.5H21V7.5C22.1 7.5 22 7.5 23 7.5Z" fill={props.fill ? props.fill : "#EBEDEF"} />
      </svg>
  )
}

export default SvgComponent
