import TableContainer from 'shared/components/TableContainer'
import styled from 'styled-components'
import { CRow } from '@coreui/react-pro'

export const Wrapper = styled.div``


export const CustomCommissionTable = styled(TableContainer)`
  > table {
    > thead {
    > tr {
      th:last-child{
        display: none;
        width: 0%;
      }
    }
  }
`

export const Row = styled(CRow)`
  --cui-gutter-y:0rem;
  margin-top:26px;
`

export const FirstRow = styled(CRow)`
  column-gap: 15px;
`

export const ButtonWrapper = styled.div`
  display:flex;
  justify-content:flex-end;
  align-items: center;
  margin-top: 24px;

  > button:nth-child(2) {
      margin-left: 16px;
      height: 33px;
      line-height: 0;
  }

`

export const RadioButtonRow = styled(CRow)`
  height: 38px;
  display: flex;
  align-items: center;

  > * {
    width: unset;
  }
`

export const AfiliateRow = styled.div<{isSidebarOpen: boolean}>`
  display: flex;
  align-items: end;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (max-width: ${({ isSidebarOpen}) => isSidebarOpen ? '1400px' : '1150px'}) {
    flex-direction: column;
    gap: 1rem;
    align-items: start;
  }
`

export const ReferralCool = styled.div<{isSidebarOpen: boolean}>`
  width: 60%;

  @media (max-width: ${({ isSidebarOpen}) => isSidebarOpen ? '1400px' : '1150px'}) {
    width: 100%;
  }
`

export const QrCodeCool = styled.div<{isSidebarOpen: boolean}>`
  width: 40%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  max-height: 65px;

  @media (max-width: ${({ isSidebarOpen}) => isSidebarOpen ? '1400px' : '1150px'}) {
    width: 100%;
  }
`