/**
 * Maintenanceform scene sagas
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */
import api from 'api';
import { putResolve, takeLatest, call, select } from 'redux-saga/effects'
import { defaultForm, MaintenanceFormValidationsIsFinished, MaintenanceFormValidationsUnfinished } from '../utils'
import * as Check from "validations"
import i18n from 'i18next';
import { PayloadAction } from '@reduxjs/toolkit';
import { MaintenanceListItem } from 'types/fleet/maintenance'
import { DeleteDocumentSagasPayload } from 'types/misc';
import { actions, RootState } from 'store/rootSlices'
import { toast } from 'react-toastify';
import config from 'config';
import { hasPermission } from 'permissions'
const { PERMISSIONS } = config

function* onMountFleetMaintenanceForm({ payload }: PayloadAction<number>) {
    const { permissions } = yield select((state: RootState) => state.App);

    try {
        //const fleet = yield call(api.Fleet.GetAllFleet);
        //yield putResolve(actions.FleetMaintenanceForm.setFleet(fleet));
        if (payload) {
            yield putResolve(actions.FleetMaintenanceForm.setEditMode(true));
            yield putResolve(actions.Documentconfigform.setHasEditPermission(hasPermission(permissions, PERMISSIONS.FLEET.EDIT_MAINTENANCE)))
            yield putResolve(actions.FleetMaintenanceForm.requestMaintenance(payload));
        } else {
            yield putResolve(actions.FleetMaintenanceForm.setEditMode(false));
            yield putResolve(actions.FleetMaintenanceForm.setForm(defaultForm));
            yield putResolve(actions.FleetMaintenanceForm.resetDocumentsArray())
        }
    } catch (err) {
      toast.success(i18n.t("toasts.errorMountingScreen"))
    } finally {
        yield putResolve(actions.FleetMaintenanceForm.setLoading(false));
    }
}

function* requestMaintenanceSaga({ payload }: PayloadAction<number>) {
    try {
        const data: MaintenanceListItem = yield call(api.Fleet.GetMaintenance, payload);
        yield putResolve(actions.FleetMaintenanceForm.setMaintenance(data));
        yield getDocumentsSaga(payload)
    } catch (error) {
    }
}

function* onUnmountSaga() { }

function* setMaintenanceSaga({ payload }: PayloadAction<MaintenanceListItem>) {
    const form = { ...defaultForm }
    try {
        Object.keys(form).forEach((key) => {
            if (payload[key]) {
                form[key] = {
                    ...form[key],
                    value: payload[key]
                }
            }
        })
        form["vehicleId"] = {
            ...form["vehicleId"],
            value: payload.vehicle.id.toString()
        }
    } catch (err) {
      toast.success(i18n.t("toasts.setMaintenanceError"))
    }
    yield putResolve(actions.FleetMaintenanceForm.setForm(form));
}

function* onSubmitSaga() {
    const {
        FleetMaintenanceForm: {
            maintenanceForm,
            isEdit,
            maintenance
        }
    } = yield select((state: RootState) => state);
    yield putResolve(actions.FleetMaintenanceForm.setSubmitButtonDisabled(true))

    const val = maintenanceForm.isFinished.value ? MaintenanceFormValidationsIsFinished : MaintenanceFormValidationsUnfinished;
    const validation = Check.checkValidation(maintenanceForm, val);
    if (validation.invalid) {
        yield putResolve(actions.FleetMaintenanceForm.setForm(validation.form));
        toast.error(i18n.t("toasts.invalidFields"));
        yield putResolve(actions.FleetMaintenanceForm.setSubmitButtonDisabled(false))
        return false;
    }
    try {
        yield putResolve(actions.FleetMaintenanceForm.setLoading(true));
        const body = {
            description: maintenanceForm.description.value,
            extra_info: maintenanceForm.extraInfo.value,
            is_finished: maintenanceForm.isFinished.value ?? false,
            date: maintenanceForm.date.value,
            supplier: maintenanceForm.supplier.value,
            invoice_uid: maintenanceForm.invoiceUid.value,
            cost: maintenanceForm.cost.value,
            report: maintenanceForm.report.value,
            is_paid: maintenanceForm.isPaid.value,
            vehicle: maintenanceForm.vehicleId.value
        }
        if (isEdit) {
            const res = yield call(api.Fleet.EditMaintenance, body, maintenance.id);
            if(res) yield putResolve(actions.App.navigateTo("/frota/manutencoes"));
        } else {
            const res = yield call(api.Fleet.CreateMaintenance, body);
            if (res.status === 201) {
                yield putResolve(actions.FleetMaintenanceForm.createEntryDocument(res.data.id));
                yield putResolve(actions.App.navigateTo("/frota/manutencoes"));
            }
        }
    } catch (err) {
    } finally {
        yield putResolve(actions.FleetMaintenanceForm.setSubmitButtonDisabled(false))
        yield putResolve(actions.FleetMaintenanceForm.setLoading(false));
    }
}

function* deleteMaintenanceSaga() {
    const {
        FleetMaintenanceForm: {
            maintenance
        }
    } = yield select((state: RootState) => state);
    try {
        const res = yield call(api.Fleet.DeleteMaintenance, maintenance.id);
        if (res.status === 201) {
            yield putResolve(actions.App.navigateTo("/frota/manutencoes"));
        }
    } catch (error) {
    }
}

function* CreateEntryDocumentSaga( { payload }: PayloadAction<number> ) {
    const { documents, isEdit, maintenanceForm } = yield select((state: RootState) => state.FleetMaintenanceForm);
  
    const val = maintenanceForm.isFinished.value ? MaintenanceFormValidationsIsFinished : MaintenanceFormValidationsUnfinished;
    const validation = Check.checkValidation(maintenanceForm, val);
    if (!validation.invalid) {
      try {
  
        documents.forEach(async (doc, idx) => {
          if (doc.new === true) {
            const body = {
              maintenance: payload,
              sort: idx + 1,
              file: doc.file
            }
            await api.Fleet.AddMaintenanceDocument(body)
            toast.success(i18n.t("toasts.maintenanceDocCreated"))
          }
          else {
            if (doc.edited === true) {
              const body = {
                maintenance: payload,
                sort: idx + 1,
              }
              await api.Fleet.EditMaintenanceDocument(doc.id, body)
            }
          }
        })
  
      } catch (error) {
        toast.warning(i18n.t("toasts.createMaintenanceDocError"))
      }
      finally {
        if (isEdit) {
            yield onSubmitSaga()
        }
      }
    }
    else {
      yield putResolve(actions.UserCashMovementForm.updateEntryFormOnValidation(validation.form))
      toast.error(i18n.t("toasts.invalidFields"))
    }
  
  }


function* getDocumentsSaga(payload: number) {
  const movementID = Number(payload)

  if (movementID) {
      try {
      const documents = yield call(api.Fleet.ListMaintenanceDocuments, movementID)

      if (documents) {
          yield putResolve(actions.FleetMaintenanceForm.populateDocumentsForm({ documents, movementID }))
      }
      } catch (error) {
          toast.error(i18n.t("toasts.getUserStatementPhotosError"))
      }
  }
}

function* deleteDocumentsFromArraySaga({ payload }: PayloadAction<DeleteDocumentSagasPayload>) {
    const index = payload.index
    const docsArray = payload.documents
  
  
    if (index !== null && docsArray[index].new === false) {
      try {
        const del = yield call(api.Fleet.DeleteMaintenanceDocument, docsArray[index].id)
  
        if (del.status === 204) {
          toast.success(i18n.t("toasts.deletedPhoto"))
        }
  
      } catch (error) {
        toast.error(i18n.t("toasts.removePhotoError"))
      }
    }
}


export default function* watcherSignin() {
    yield takeLatest('fleetMaintenanceForm/requestMaintenance', requestMaintenanceSaga)
    yield takeLatest('fleetMaintenanceForm/onMount', onMountFleetMaintenanceForm)
    yield takeLatest('fleetMaintenanceForm/onUnmount', onUnmountSaga)
    yield takeLatest('fleetMaintenanceForm/setMaintenance', setMaintenanceSaga);
    yield takeLatest('fleetMaintenanceForm/onSubmit', onSubmitSaga);
    yield takeLatest('fleetMaintenanceForm/deleteMaintenance', deleteMaintenanceSaga);
    yield takeLatest('fleetMaintenanceForm/createEntryDocument', CreateEntryDocumentSaga);
    yield takeLatest('fleetMaintenanceForm/deleteDocumentsFromArray', deleteDocumentsFromArraySaga)


}


