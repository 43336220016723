import * as React from "react";
import theme from "ui/theme";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width={18}
            height={18}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M11.25 13.875a4.86 4.86 0 01-4.32-2.625h4.32l.75-1.5H6.435a4.976 4.976 0 01-.06-.75c0-.255.022-.502.06-.75h4.815l.75-1.5H6.93a4.869 4.869 0 017.492-1.447l1.328-1.328a6.716 6.716 0 00-4.5-1.725c-2.94 0-5.43 1.883-6.36 4.5H2.25l-.75 1.5h3.045a6.196 6.196 0 000 1.5H2.25l-.75 1.5h3.39c.93 2.617 3.42 4.5 6.36 4.5a6.702 6.702 0 004.5-1.725l-1.335-1.328a4.804 4.804 0 01-3.165 1.178z"
                fill={props.fill ? props.fill : theme().gray100Color}
            />
        </svg>
    )
}

export default SvgComponent
