import * as React from "react"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width={props?.width} height={props?.height} viewBox={props?.viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16 2.5V4.5H12V2.5H16ZM6 2.5V8.5H2V2.5H6ZM16 10.5V16.5H12V10.5H16ZM6 14.5V16.5H2V14.5H6ZM18 0.5H10V6.5H18V0.5ZM8 0.5H0V10.5H8V0.5ZM18 8.5H10V18.5H18V8.5ZM8 12.5H0V18.5H8V12.5Z" fill={props.fill ? props.fill : "#EBEDEF"}/>
        </svg>

    )
}

export default SvgComponent
