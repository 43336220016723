import { CButtonGroup, CFormCheck } from '@coreui/react-pro'
import styled from 'styled-components'
import theme from 'ui/theme'

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 16px;
  margin-bottom: 5px;
  z-index: 0;

  > div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
`

export const DaysButton = styled(CButtonGroup)`
  margin-left:5px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-right: 30px;
  background-color: ${theme().white};
  color: ${theme().secondaryColor};
`

export const Day = styled(CFormCheck)`
  margin:0px;
  border: 1px solid ${theme().secondaryColor};
  padding: 5px 11px !important;
`

export const TextLink = styled.p`
  margin:0px;
  cursor: pointer;
  color: ${theme().primary};
`