/**
 * Cashmovementrow component
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */

import { CTableDataCell } from '@coreui/react-pro'
import React from 'react'
import { ActionsCell, ChevronCell, ColoredDataCellText, CustomTableRow } from 'shared/genericStyledComponents'
import ChevronRight from 'assets/icons/chevron_right';
import { useDispatch, useSelector } from 'react-redux';
import { actions, RootState } from 'store/rootSlices'
import theme from 'ui/theme';
import { TableRowPropTypes } from 'types/misc';
import { DateTime } from 'luxon'
import i18n from "i18next"

type PropTypes = TableRowPropTypes & {
  id?: number,
  entry?: unknown,
  permission: boolean,
}

const Cashmovementrow = (props) => {

  const dispatch = useDispatch()
  const {
    userID,
  } = useSelector((state: RootState) => state.UserCashManagement)

  let movementTradution
  switch (props.entry.balanceEntryType) {
    case 'supplier':
      movementTradution = i18n.t(`cashManagement.toSupplier`)
      break;
    case 'to_management':
      movementTradution = i18n.t(`cashManagement.toManagement`)
      break;
    case 'booking':
      movementTradution = i18n.t(`cashManagement.toBooking`)
      break;
    case 'fuel':
      movementTradution = i18n.t(`cashManagement.toFuel`)
      break;
    case 'maintenance':
      movementTradution = i18n.t(`cashManagement.toMaintenance`)
      break;
    default:
      movementTradution = i18n.t('invoicingSummary.movementType')
  }

  return (
    <CustomTableRow>
      <CTableDataCell>
        {DateTime.fromISO(props.entry.date).toFormat("dd/MM/yyyy HH:mm ")}
      </CTableDataCell>
      <CTableDataCell>
        {movementTradution}
      </CTableDataCell>
      <CTableDataCell>
        {props.entry.paymentType}
      </CTableDataCell>
      <ColoredDataCellText color={props.entry.amount !== '0.00' && (props.entry.isExpense || props.entry.isRefund) ? theme().danger : theme().success}>
        { props.entry.amount !== '0.00' && (props.entry.isExpense || props.entry.isRefund) ?
          `-${props.entry.amount}€`
          :
          `${props.entry.amount}€`
        }
      </ColoredDataCellText>
      <ActionsCell>
        {props.actions}
        <ChevronCell id={`${props.id}-chevron`}>
          {
            <a href={`/faturacao/gestao-caixa/${userID}/editar-movimento/${props.entry.id}`} onClick={(e) => {
              e.preventDefault()
              dispatch(actions.App.navigateTo(`/faturacao/gestao-caixa/${userID}/editar-movimento/${props.entry.id}`))
            }}>
              <ChevronRight></ChevronRight>
            </a>
          }
        </ChevronCell>
      </ActionsCell>
    </CustomTableRow>
  )
}

export default Cashmovementrow
