import * as React from "react"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M13.207 4H4C2.9 4 2.01 4.89 2.01 6V10C3.11 10 4 10.9 4 12C4 13.1 3.11 14 2 14V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V14C20.9 14 20 13.1 20 12C20 11.5572 20.1459 11.1467 20.3919 10.8144C19.7358 10.942 19.062 11 18.5 11C18.3761 11 18.2531 10.9959 18.1312 10.9878C18.0456 11.3117 18 11.6511 18 12C18 13.47 18.81 14.77 20 15.46V18H4V15.46C5.19 14.77 6 13.47 6 12C6 10.52 5.2 9.23 4.01 8.54L4 6H13.0224C13.0076 5.83532 13 5.66854 13 5.5C13 4.97999 13.0722 4.47683 13.207 4ZM13 15H11V17H13V15ZM13 11H11V13H13V11ZM13 7H11V9H13V7Z" fill="#9DA5B1" />
            <path d="M17.3538 7.09273L15.7611 5.50002L15.2188 6.03856L17.3538 8.17363L21.9372 3.5903L21.3986 3.05176L17.3538 7.09273Z" fill="#9DA5B1" stroke="#9DA5B1" />
        </svg>


    )
}

export default SvgComponent
