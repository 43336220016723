/**
 * Vehicleform scene
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */
import { CCol, CForm, CRow } from '@coreui/react-pro'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import CustomButton from 'shared/components/CustomButton'
import CustomCheckbox from 'shared/components/CustomCheckbox'
import CustomModal from 'shared/components/CustomModal'
import CustomRadioButton from 'shared/components/CustomRadioButton'
import CustomRadioButtonGroup from 'shared/components/CustomRadioButtonGroup'
import CustomTextInput from 'shared/components/CustomTextInput'
import Dropdown from 'shared/components/FormComponents/dropdown'
import ColorPicker from 'shared/components/FormComponents/colorpicker'
import SectionContainerDelete from 'shared/components/FormComponents/containerdelete'
import Datepicker from 'shared/components/FormComponents/datepicker'
import NewDocumentButton from 'shared/components/FormComponents/newdocumentbutton'
import Loadingcontainer from 'shared/components/Loadingcontainer'
import SectionContainer from 'shared/components/Sectioncontainer'
import { actions, RootState } from 'store/rootSlices'
import { VehicleDocumentsForm } from 'types/fleet/vehicle'
import theme from 'ui/theme'
import i18n from 'i18next'
import VehicleDocumentrow from 'shared/components/VehicleDocumentrow'
import config from 'config'
import { hasPermission } from 'permissions'
import { Wrapper, Row, RowRadios, ButtonWrapper} from './styles'

const FleetManagementForm = () => {

  const dispatch = useDispatch()
  const { id } = useParams()
  const { PERMISSIONS } = config
  const { permissions } = useSelector((state: RootState) => state.App)
  const { 
    fuelTypes, 
    vehicleClasses, 
    vehicleTypes 
  } = useSelector((state: RootState) => state.FleetManagement);
  const { 
    vehicleForm, 
    documentsForm, 
    loading, 
    edit, 
    vehicle, 
    deleteModal, 
    hasEditPermission,
    submitButtonState
  } = useSelector((state: RootState) => state.FleetManagementForm);
  const { 
    onMount, 
    onFormChange, 
    onAddDocument, 
    onSubmit, 
    showDeleteModal, 
    deleteVehicle 
  } = actions.FleetManagementForm
  const { navigateBack } = actions.App

  useEffect(() => {
    dispatch(onMount(id))
    return () => {
      //dispatch(onUnmount())
    }
  }, [dispatch, id, onMount]);

  const handleAdd = (el) => {
    dispatch(onAddDocument())
  }

  if (loading) return (
    <Wrapper>
      <Loadingcontainer />
    </Wrapper>
  )

  return (
    <Wrapper>
      <CForm>
        <CustomCheckbox
          id='fleetManagementForm-isVehicleActive'
          disabled={!hasEditPermission}
          wrapperStyle={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '-25px', fontSize: '20px' }}
          checked={vehicleForm.isAvailable.value ? true : false}
          onClick={e => dispatch(onFormChange({ ...vehicleForm, is_available: { value: true, valid: true, message: "" } }))}
          text={i18n.t('fleetForm.activeQuestion')} switch />
        <SectionContainer title={!edit ? i18n.t('fleetForm.title1') : vehicle.name} style={{ width: '100%', paddingBottom: '24px' }}>
          <CRow className="g-3">
            <CCol sm={8}>
              <CustomTextInput
                id='fleetManagementForm-name'
                disabled={!hasEditPermission}
                required={true}
                value={vehicleForm.name.value}
                valid={vehicleForm.name.valid}
                helpText={vehicleForm.name.message}
                placeholder={i18n.t('placeholders.name')}
                label={i18n.t('common.name')}
                onChange={e => dispatch(onFormChange({ ...vehicleForm, name: { value: e.target.value, valid: true, message: "" } }))}
              />
            </CCol>
            <CCol sm>
              <Dropdown
                id='fleetManagementForm-vehicleTypeId'
                disabled={!hasEditPermission}
                required
                valid={vehicleForm.vehicleTypeId.valid}
                helpText={vehicleForm.vehicleTypeId.message} 
                placeholder={i18n.t('placeholders.vehicleType')}
                value={vehicleForm.vehicleTypeId.value}
                options={vehicleTypes.map(typ => {
                  return {
                    label: typ.name,
                    value: typ.id
                  }
                })}
                label={i18n.t('fleetForm.vehicleType')}
                onChange={e => {
                  dispatch(onFormChange({ ...vehicleForm, vehicleTypeId: { value: e.target.value, valid: true, message: "" } }))
                }}
              />
            </CCol>
          </CRow>
          <Row className="g-3">
            <CCol sm={8}>
              <CustomTextInput
                id='fleetManagementForm-description'
                disabled={!hasEditPermission}
                value={vehicleForm.description.value || ''}
                valid={vehicleForm.description.valid}
                helpText={vehicleForm.description.message}
                placeholder={i18n.t('placeholders.description')}
                label={i18n.t('fleetForm.description')}
                onChange={e => dispatch(onFormChange({ ...vehicleForm, description: { value: e.target.value, valid: true, message: "" } }))}
              />
            </CCol>
            <CCol sm>
              <ColorPicker
                id='fleetManagementForm-color'
                disabled={!hasEditPermission}
                required
                value={vehicleForm.color.value}
                valid={vehicleForm.color.valid}
                helpText={vehicleForm.color.message}
                label={i18n.t('fleetForm.vehicleColor')}
                onChange={e => {
                  dispatch(onFormChange({ ...vehicleForm, color: { value: e.currentTarget.value, valid: true, message: "" } }))
                }}
              />
            </CCol>
          </Row>
        </SectionContainer>
        <SectionContainer title={i18n.t('fleetForm.title2')} style={{ width: '100%', paddingBottom: '24px' }}>
          <CRow className="g-3">
            <CCol>
              <CustomTextInput
                id='fleetManagementForm-shortName'
                disabled={!hasEditPermission}
                required={true}
                value={vehicleForm.shortName.value || ''}
                valid={vehicleForm.shortName.valid}
                helpText={vehicleForm.shortName.message}
                placeholder={i18n.t('placeholders.abbreviation')}
                label={i18n.t('fleetForm.abbreviation')}
                onChange={e => dispatch(onFormChange({ ...vehicleForm, shortName: { value: e.target.value, valid: true, message: "" } }))}
              />
            </CCol>
            <CCol>
              <CustomTextInput
                id='fleetManagementForm-plateNum'
                disabled={!hasEditPermission}
                required={true}
                value={vehicleForm.plateNum.value || ''}
                valid={vehicleForm.plateNum.valid}
                helpText={vehicleForm.plateNum.message}
                placeholder={i18n.t('fleetForm.plateNum')}
                label={i18n.t('fleetForm.plateNum')}
                onChange={e => dispatch(onFormChange({ ...vehicleForm, plateNum: { value: e.target.value, valid: true, message: "" } }))}
              />
            </CCol>
          </CRow>
          <Row className="g-3">
            <CCol>
              <CustomTextInput
                id='fleetManagementForm-motor'
                disabled={!hasEditPermission}
                required={true}
                valid={vehicleForm.motor.valid}
                value={vehicleForm.motor.value || ''}
                helpText={vehicleForm.motor.message}
                placeholder={i18n.t('fleetForm.engine')}
                label={i18n.t('fleetForm.engine')}
                onChange={e => dispatch(onFormChange({ ...vehicleForm, motor: { value: e.target.value, valid: true, message: "" } }))}
              />
            </CCol>
            <CCol>
              <Dropdown
                id='fleetManagementForm-vehicleClassId'
                disabled={!hasEditPermission}
                required={true}
                placeholder={i18n.t('placeholders.select')}
                valid={vehicleForm.vehicleClassId.valid}
                helpText={vehicleForm.vehicleClassId.message}
                value={vehicleForm.vehicleClassId.value || ''}
                options={vehicleClasses.map(cls => {
                  return {
                    label: cls.name,
                    value: cls.id
                  }
                })}
                label={i18n.t('fleetForm.class')}
                onChange={e => dispatch(onFormChange({ ...vehicleForm, vehicleClassId: { value: e.target.value, valid: true, message: "" } }))}
              />
            </CCol>
          </Row>
          <Row className="g-3">
            <CCol>
              <Dropdown
                id='fleetManagementForm-fuelTypeId'
                disabled={!hasEditPermission}
                required={true}
                placeholder={i18n.t('placeholders.select')}
                valid={vehicleForm.fuelTypeId.valid}
                value={vehicleForm.fuelTypeId.value || ''}
                helpText={vehicleForm.vehicleTypeId.message}
                options={
                  fuelTypes.map(fuel => {
                  return {
                    label: fuel.name,
                    value: fuel.id,
                  }
                  })}
                label={i18n.t('fleetForm.fuel')}
                onChange={e => dispatch(onFormChange({ ...vehicleForm, fuelTypeId: { value: e.target.value, valid: true, message: "" } }))}
              />
            </CCol>
            <CCol>
              <CustomTextInput
                id='fleetManagementForm-fuelCapacity'
                disabled={!hasEditPermission}
                required={true}
                valid={vehicleForm.fuelCapacity.valid}
                value={(vehicleForm.fuelCapacity.value && vehicleForm.fuelCapacity.value.toString()) || ''}
                helpText={vehicleForm.fuelCapacity.message}
                placeholder={i18n.t('placeholders.liters')}
                label={i18n.t('fleetForm.fuelCapacity')}
                onChange={e => dispatch(onFormChange({ ...vehicleForm, fuelCapacity: { value: parseInt(e.target.value), valid: true, message: "" } }))}
              />
            </CCol>
          </Row>
          <Row className="g-3">
            <CCol>
              <CustomTextInput
                id='fleetManagementForm-capacity'
                disabled={!hasEditPermission}
                valid={vehicleForm.capacity.valid}
                value={vehicleForm.capacity.value}
                helpText={vehicleForm.capacity.message}
                required={true}
                placeholder={i18n.t('placeholders.capacity')}
                label={i18n.t('fleetForm.capacity')}
                onChange={e => dispatch(onFormChange({ ...vehicleForm, capacity: { value: parseInt(e.target.value), valid: true, message: "" } }))}
              />
            </CCol>
            <CCol>
              <CustomTextInput
                id='fleetManagementForm-location'
                disabled={!hasEditPermission}
                required={true}
                valid={vehicleForm.location.valid}
                value={vehicleForm.location.value || ''}
                helpText={vehicleForm.location.message}
                placeholder={i18n.t('placeholders.location')}
                label={i18n.t('fleetForm.location')}
                onChange={e => dispatch(onFormChange({ ...vehicleForm, location: { value: e.target.value, valid: true, message: "" } }))}
              />
            </CCol>
          </Row>
        </SectionContainer>
        <SectionContainer title={i18n.t('fleetForm.title3')} style={{ width: '100%', paddingBottom: '24px' }}>
          <CRow className="g-3">
            <CCol sm>
              <Datepicker
                id='fleetManagementForm-nextMaintenance'
                disabled={!hasEditPermission}
                valid={vehicleForm.nextMaintenance.valid}
                value={vehicleForm.nextMaintenance.value || ''}
                helpText={vehicleForm.nextMaintenance.message}
                label={i18n.t('fleetForm.nextInspection')}
                onChange={(date, e) => dispatch(onFormChange({ ...vehicleForm, nextMaintenance: { value: date, valid: true, message: "" } }))}
              />
            </CCol>
            <CCol sm={8}>
              <CustomTextInput
                id='fleetManagementForm-nextMaintenanceLocation'
                disabled={!hasEditPermission}
                valid={vehicleForm.nextMaintenanceLocation.valid}
                value={vehicleForm.nextMaintenanceLocation.value || ''}
                helpText={vehicleForm.nextMaintenanceLocation.message}
                placeholder={i18n.t('placeholders.location')}
                label={i18n.t('fleetForm.location')}
                onChange={e => dispatch(onFormChange({ ...vehicleForm, nextMaintenanceLocation: { value: e.target.value, valid: true, message: "" } }))}
              />
            </CCol>
          </CRow>
        </SectionContainer>
        <SectionContainer title={i18n.t('fleetForm.title4')} style={{ width: '100%', paddingBottom: '24px' }}>
          {documentsForm && documentsForm.length > 0 && documentsForm.map((el: VehicleDocumentsForm, idx: number) => {
              return (
                <VehicleDocumentrow hasEditPermission={hasEditPermission} document={el} id={`fleetManagementForm-document${idx}`} idx={idx} key={`Document${el.documentType}${idx}`} />
              )
            })
          }
          <NewDocumentButton id='fleetManagementForm-addDocumentButton' addComponent={() => hasEditPermission && handleAdd('el')} wrapperStyle={{ maxWidth: '100%' }} />

        </SectionContainer>
        <SectionContainer title={i18n.t('fleetForm.title5')} style={{ width: '100%', paddingBottom: '24px' }}>
          <RowRadios className="g-3">
            <CCol sm={8}>
              <CustomRadioButtonGroup role="group4">
                <CustomRadioButton
                  group="group4"
                  disabled={!hasEditPermission}
                  text={i18n.t('fleetForm.available')}
                  value="disponível"
                  checked={vehicleForm.isAvailable.value ? true : false}
                  onClick={e => dispatch(onFormChange({ ...vehicleForm, isAvailable: { value: true, valid: true, message: "" } }))} />
                <CustomRadioButton
                  group="group4"
                  disabled={!hasEditPermission}
                  text={i18n.t('fleetForm.unavailable')}
                  value="indisponivel"
                  checked={!vehicleForm.isAvailable.value ? true : false}
                  onClick={e => dispatch(onFormChange({ ...vehicleForm, isAvailable: { value: false, valid: true, message: "" } }))} />
              </CustomRadioButtonGroup>
            </CCol>
          </RowRadios>
          <Row className="g-3">
            <CCol sm>
              <Datepicker
                id='fleetManagementForm-unavailabilityStart'
                disabled={!hasEditPermission || vehicleForm.isAvailable.value ? true : false}
                valid={vehicleForm.unavailabilityStart.valid}
                value={vehicleForm.unavailabilityStart.value || ''}
                helpText={vehicleForm.unavailabilityStart.message}
                required={true}
                label={i18n.t('fleetForm.unavailabilityStart')}
                onChange={(date, e) => dispatch(onFormChange({ ...vehicleForm, unavailabilityStart: { value: date, valid: true, message: "" } }))}
              />
            </CCol>
            <CCol sm>
              <Datepicker
                id='fleetManagementForm-unavailabilityEnd'
                disabled={!hasEditPermission || vehicleForm.isAvailable.value ? true : false}
                valid={vehicleForm.unavailabilityEnd.valid}
                value={vehicleForm.unavailabilityEnd.value || ''}
                helpText={vehicleForm.unavailabilityEnd.message}
                required={true}
                label={i18n.t('fleetForm.unavailabilityEnd')}
                onChange={(date, e) => dispatch(onFormChange({ ...vehicleForm, unavailabilityEnd: { value: date, valid: true, message: "" } }))}
              />
            </CCol>
          </Row>
          <Row className="g-3">
            <CCol sm>
              <CustomTextInput
                id='fleetManagementForm-unavailabilityText'
                disabled={!hasEditPermission || vehicleForm.isAvailable.value ? true : false}
                required={true}
                valid={vehicleForm.unavailabilityText.valid}
                value={vehicleForm.unavailabilityText.value || ''}
                helpText={vehicleForm.unavailabilityText.message}
                placeholder={i18n.t('fleetForm.unavailabilityReason')}
                label={i18n.t('fleetForm.unavailabilityReason')}
                onChange={e => dispatch(onFormChange({ ...vehicleForm, unavailabilityText: { value: e.target.value, valid: true, message: "" } }))}
              />
            </CCol>
          </Row>
        </SectionContainer>
        {edit && hasPermission(permissions, PERMISSIONS.FLEET.DELETE_VEHICLE) &&<SectionContainerDelete
          id='fleetManagementForm-delete'
          title={i18n.t('fleetForm.deleteVehicleTitle')}
          text={<p>{i18n.t('fleetForm.deleteVehicle1')}<br />{i18n.t('common.deleteLine2')}</p>}
          onClick={e => dispatch(showDeleteModal(true))} />
        }
        <ButtonWrapper>
          <CustomButton
            id='fleetManagementForm-cancel'
            onClick={() => dispatch(navigateBack())}
            text={i18n.t('common.cancelBtn')}
            color="link"
            buttonStyle={{ color: theme().danger, textDecoration: 'unset' }} />
          {
            edit ? 
              hasPermission(permissions, PERMISSIONS.FLEET.EDIT_VEHICLE) &&
              <CustomButton
                id='fleetManagementForm-save'
                disabled={submitButtonState}
                onClick={() => dispatch(onSubmit())}
                text={i18n.t('common.saveBtn')}
                icon="confirm"
                color="success"
                buttonStyle={{ color: "#FFFFFF" }} />
              :
              hasPermission(permissions, PERMISSIONS.FLEET.CREATE_VEHICLE) &&
              <CustomButton
                id='fleetManagementForm-save'
                disabled={submitButtonState}
                onClick={() => dispatch(onSubmit())}
                text={i18n.t('common.saveBtn')}
                icon="confirm"
                color="success"
                buttonStyle={{ color: "#FFFFFF" }} />
          }
        </ButtonWrapper>
      </CForm>
      {
          hasPermission(permissions, PERMISSIONS.FLEET.DELETE_VEHICLE) &&  <CustomModal
          id='fleetManagementForm-deleteVehicleModal'
          visible={deleteModal}
          warningType='warning'
          warningName='Aviso'
          buttonIcon={false}
          footerPosition='center'
          textAlign='center'
          children={<p>{i18n.t('fleetForm.deleteVehicle3')}&nbsp;{vehicle.name}?</p>}
          onclose={e => dispatch(showDeleteModal(false))}
          onsubmit={e => dispatch(deleteVehicle())} />
      }

    </Wrapper>
  )
}

export default FleetManagementForm