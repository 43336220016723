/**
 * Changepassword scene
 *
 * @author Manuel Gil <mgil@ubiwhere.pt>
 *
 *
 */
import React, { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
// Store
import { actions, RootState } from 'store/rootSlices'
// Components
import CustomButton from 'shared/components/CustomButton'
import CustomTextInput from 'shared/components/CustomTextInput'
// Icons
import AnimarisLogoMasterHorizontal from 'assets/icons/logo/animarisLogoMasterHorizontal';
import CheckCircle from 'assets/icons/check_circle'
import ArrowBack from 'assets/icons/arrow_back'
// Styles
import { Wrapper, SubmitError, LogoContainer, Back, LoginForm, EmailSent } from './styles'

const Changepassword = ({ t }) => {

  const {
    showEmailComponent,
    email,
    showChangePasswordComponent,
    passwordForm,
    error,
    emailSent
  } = useSelector((state: RootState) => state.ChangePassword)

  const {
    onMount,
    onUnmount,
    setEmail,
    onChangePasswordForm,
    onSubmitEmail,
    onSubmitPassword
  } = actions.ChangePassword

  const { navigateTo } = actions.App

  const dispatch = useDispatch()

  useEffect(() => {

    dispatch(onMount())
    return () => {
      dispatch(onUnmount())
    }
  }, [dispatch, onMount, onUnmount]);

  const handleSendEmail = () => {
    dispatch(onSubmitEmail())
  }

  const handleGoToLogin = () => {
    dispatch(navigateTo('/login'))
  }

  const handleChangePassword = () => {
    dispatch(onSubmitPassword())
  }

  return (
    <Wrapper>
      <LogoContainer>
        <AnimarisLogoMasterHorizontal />
      </LogoContainer>
      {
        showEmailComponent && !emailSent ?
          <LoginForm>
            <Back id="changePassword-goBack" onClick={e => handleGoToLogin()}>
              <ArrowBack/>
              <h2>{t('common.back')}</h2>
            </Back>
            <h1>{t('login.passwordReset')}</h1>
            <h2>{t('login.passwordResetText')}</h2>
            <CustomTextInput
              id="changePassword-loginEmail"
              placeholder={t('placeholders.loginEmail')}
              icon="email"
              value={email.value}
              helpText={email.message || ''}
              onChange={e => dispatch(setEmail({ value: e.target.value, message: '', valid: true }))}
            />
            {error !== '' ? <SubmitError>{error}</SubmitError> : null}
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '0' }}>
              <CustomButton id="changePassword-confirmButton" text={t('common.confirm')} onClick={e => handleSendEmail()} />
            </div>
          </LoginForm>
          :null
      }
      {
        showEmailComponent && emailSent ?
          <EmailSent>
            <CheckCircle/>
            <h1>{t('login.instructionsSent')}</h1>
            <h2>{t('login.instructionsSentText')}</h2>
            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '0' }}>
              <CustomButton id="changePassword-loginButton" text={t('common.login')} color='primary' variant='outline' onClick={e => handleGoToLogin()} />
            </div>
          </EmailSent>
          :
          null
      }
      {
        showChangePasswordComponent ?
        <LoginForm>
            <h1>{t('login.changePassword')}</h1>
            <h2>{t('login.changePasswordSubtitle')}</h2>
            <CustomTextInput
              placeholder={t('placeholders.newPassword')}
              type="password"
              value={passwordForm.changePassword.value}
              helpText={passwordForm.changePassword.message}
              onChange={e => dispatch(onChangePasswordForm({ ...passwordForm, changePassword: { value: e.target.value, valid: true, message: '' } }))}
            />
            <CustomTextInput
              type="password"
              placeholder={t('placeholders.confirmPassword')}
              value={passwordForm.confirmPassword.value}
              helpText={passwordForm.confirmPassword.message}
              onChange={e => dispatch(onChangePasswordForm({ ...passwordForm, confirmPassword: { value: e.target.value, valid: true, message: '' } }))}
            />
            {error !== '' ? <SubmitError>{error}</SubmitError> : null}
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '0' }}>
              <CustomButton id="newPassword-confirmButton" text={t('common.confirm')} onClick={e => handleChangePassword()} />
            </div>
          </LoginForm>:null
      }
    </Wrapper>

  )
}

export default Changepassword