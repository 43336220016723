/**
 * Usercashmanagementforms scene slice
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */
import { createSlice } from '@reduxjs/toolkit'
import { /* Statement, */ StatementForm } from 'types/invoicing/statements'


interface initialState {
  statementID: number | null,
  newPhotosID: number,
  loading: boolean,
  hasEditPermission: boolean,
  submitButtonState: boolean,
  statementForm: StatementForm,
  photos: any,
}

const initialState: initialState = {
  statementID: null,
  loading: false,
  hasEditPermission: false,
  submitButtonState: false,
  newPhotosID: 99,
  statementForm: {
    cashFund: {
      value: "",
      valid: true,
      message: ""
    },
    income: {
      value: "",
      valid: true,
      message: ""
    },
    expenses: {
      value: "",
      valid: true,
      message: ""
    },
    bankDeposit: {
      value: "",
      valid: true,
      message: ""
    },
    multibancoSales: {
      value: "",
      valid: true,
      message: ""
    },
    notDeposited: {
      value: "",
      valid: true,
      message: ""
    },
    estimated: {
      value: "",
      valid: true,
      message: ""
    },
  },
  photos: [] as any
}




export default createSlice({
  name: 'Usercashmanagementforms',
  initialState,
  reducers: {
    onMount: (state, { payload }) => { },
    onUnmount: () => { },
    setLoading: (state, { payload }) => {
      state.loading = payload
    },
    setSubmitButtonDisabled: (state, { payload }) => {
      state.submitButtonState = payload
    },
    setStatementID: (state, { payload }) => {
      state.statementID = payload
    },
    setHasEditPermission: (state, { payload }) => {
      state.hasEditPermission = payload
    },
    getStatementPhotos: () => { },
    setStatementForm: (state, { payload }) => {
      const baseForm = { ...initialState.statementForm }
      const statement = payload

      Object.keys(statement).forEach(key => {
        if (key in baseForm) {
          baseForm[key] = {
            ...baseForm[key],
            value: statement[key],
          }
        }
      })

      state.statementForm = baseForm
    },
    onStatementFormUpdate: (state, { payload }) => {
      state.statementForm = payload
    },
    updateStatementFormOnValidation: (state, { payload }) => {
      state.statementForm = payload
    },
    openStatement: () => { },
    closeStatementWithPhotos: () => { },
    createStatementPhotos: () => { },
    addToPhotosArray: (state, { payload }) => {
      const photo = payload.e
      const photos: any[] = Array.from(payload.photos)

      photos.push({
        id: payload.newPhotosID + 1,
        balanceEntry: null,
        file: photo,
        new: true,
        edited: false,
        sort: photos.length + 1
      })
      state.newPhotosID = payload.newPhotosID + 1
      state.photos = photos
    },
    deletePhotoFromArray: (state, { payload }) => {
      const items: any[] = Array.from(payload.photos)

      items.splice(items.indexOf(items[payload.index]), 1)

      const deletedPhoto = items.map((photo, idx) => {
        if (photo.sort !== idx + 1) {
          return {
            ...photo,
            edited: true,
            sort: idx + 1
          }
        }
        else {
          return {
            ...photo,
            sort: idx + 1
          }
        }
      })

      state.photos = deletedPhoto
    },
    reOrderPhotosArray: (state, { payload }) => {
      if (!payload.e.destination) return;
      const items: any[] = Array.from(payload.photos)


      const [reOrderedItem] = items.splice(payload.e.source.index, 1);
      items.splice(payload.e.destination.index, 0, reOrderedItem)
      const sorted = items.map((photo, idx) => {
        return {
          ...photo,
          sort: idx + 1,
          edited: true,
        }
      })

      state.photos = sorted
    },
    populatePhotosForm: (state, { payload }) => {
      const photos: any[] = Array.from([])

      payload.photos.forEach((photo) => {
        photos.push({
          balanceEntry: payload.movementID,
          id: photo.id,
          file: photo.file,
          edited: false,
          new: false,
          sort: photo.sort
        })
      })

      state.photos = photos.sort((a, b) => { return a.sort - b.sort })
    },
  }
})