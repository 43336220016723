/**
 * Servicelisttablerow component
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */

import React from 'react'
import { ActionsCell, AvailabilityTableDataCell } from 'shared/genericStyledComponents'
import { TableRowPropTypes } from 'types/misc'
import { CTableDataCell } from '@coreui/react-pro'
import ChevronRight from 'assets/icons/chevron_right'
import { ServicesListItem } from 'types/trips/services'
import { actions } from 'store/rootSlices'
import { useDispatch } from 'react-redux';
import { CustomChevronCell } from '../../styles'
import { CustomCTableRow, NameCell } from './styles'

type PropTypes = TableRowPropTypes & {
  id?: string,
  service: ServicesListItem,
  permission: boolean
}

const Servicelisttablerow = (props: PropTypes) => {

  const dispatch = useDispatch()
  const {
    permission,
    service: {
      name,
      description,
      isPricePerPerson,
      price,
      isActive,
      id
    }
  } = props

  return (
    <CustomCTableRow id={props.id}>
      <NameCell id={`${props.id}-name`} onClick={() => props.onClick()} >{name}</NameCell>
      <CTableDataCell>{description}</CTableDataCell>
      <CTableDataCell>{isPricePerPerson && price !== "-" ? `${price}€` : "-"}</CTableDataCell>
      <CTableDataCell>{!isPricePerPerson && price !== "-" ? `${price}€` : "-"}</CTableDataCell>
      <CTableDataCell>
        <AvailabilityTableDataCell isactive={isActive}>{isActive ? "Disponível" : "Indisponível"}</AvailabilityTableDataCell>
      </CTableDataCell>
      <ActionsCell nopointer>
        {props.actions}
        <CustomChevronCell id={`${props.id}-chevron`} >
        {
            permission &&
            <a href={`/viagens/configuracoes/editar-servico/${id}`} onClick={(e) => {
              e.preventDefault()
              dispatch(actions.App.navigateTo(`/viagens/configuracoes/editar-servico/${id}`))
              }}>
              <ChevronRight></ChevronRight>
            </a>
        }
        </CustomChevronCell>
      </ActionsCell>
    </CustomCTableRow>
  )
}

export default Servicelisttablerow