import styled from 'styled-components'
import { CRow } from '@coreui/react-pro'
import theme from 'ui/theme';


export const RulesRow = styled(CRow)`
  --cui-gutter-y:0rem;
  padding-bottom:24px;

  &:last-child {
    padding-bottom: 0px;
  }
`

export const ActivityImageInfo = styled.p`
 color: ${theme().gray500Color}
`