import * as React from "react"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg 
            width="18" 
            height="18" 
            viewBox="0 0 20 19" 
            fill={props.fill ? props.fill : "#EBEDEF"}  
            xmlns="http://www.w3.org/2000/svg">
            <path 
                d="M9 4.5L7.6 5.9L10.2 8.5H0V10.5H10.2L7.6 13.1L9 14.5L14 9.5L9 4.5ZM18 16.5H10V18.5H18C19.1 18.5 20 17.6 20 16.5V2.5C20 1.4 19.1 0.5 18 0.5H10V2.5H18V16.5Z" 
                fill={props.fill ? props.fill : "#EBEDEF"}  
            />
        </svg>
    )
}

export default SvgComponent
