/**
 * Serviceconfigurationrow component
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */

import React, { useEffect } from 'react'
import { CTableDataCell } from '@coreui/react-pro'
import CustomTextInput from 'shared/components/CustomTextInput'
import CustomCheckbox from 'shared/components/CustomCheckbox'
import { useDispatch, useSelector } from 'react-redux'
import { actions, RootState } from 'store/rootSlices'
import { CustomCTableRow } from './styles'

const Serviceconfigurationrow = (props) => {

  const dispatch = useDispatch()

  const {
    configurations
  } = useSelector((state: RootState) => state.Configurations)

  const {
    onChangeFormRow
  } = actions.Configurations

  const {
    id,
    configuration,
    disabled,
  } = props

  useEffect(() => {

  }, [configuration]);


  return (
    <CustomCTableRow id={`configuration-${configuration.service}`}>
      <CTableDataCell>{configuration.service}</CTableDataCell>
      <CTableDataCell>
        <CustomTextInput
          id={`${id}-code-article`}
          required={false}
          disabled={disabled}
          valid={configuration.invoiceArticle?.valid}
          value={configuration.invoiceArticle?.value}
          helpText={configuration.invoiceArticle?.message}
          onChange={(e) => {
            dispatch(onChangeFormRow({conf: {...configurations[id],  invoiceArticle: {value : e.target.value, valid: true, message: ""}}, id}))
          }}
        />
      </CTableDataCell>

      <CTableDataCell>
        <CustomTextInput
          id={`${id}-fee`}
          required={false}
          disabled={disabled}
          valid={configuration.fee?.valid}
          value={configuration.fee?.value}
          helpText={configuration.fee?.message}
          icon='percent'
          onChange={(e) => {
            dispatch(onChangeFormRow({conf: {...configurations[id],  fee: {value : e.target.value, valid: true, message: ""}}, id}))
          }}
        />
      </CTableDataCell>
      <CTableDataCell>
        <CustomTextInput
          id={`${id}-time`}
          required={false}
          disabled={disabled}
          valid={configuration.timeNoFee?.valid}
          value={configuration.timeNoFee?.value}
          helpText={configuration.timeNoFee?.message}
          placeholder='hh:mm'
          onChange={(e) => {
            dispatch(onChangeFormRow({conf: {...configurations[id],  timeNoFee: {value : e.target.value, valid: true, message: ""}}, id}))
          }}
        />
      </CTableDataCell>
      <CTableDataCell>
        <CustomTextInput
          id={`${id}-reschedule`}
          required={false}
          disabled={disabled}
          valid={configuration.timeLimit?.valid}
          value={configuration.timeLimit?.value}
          helpText={configuration.timeLimit?.message}
          placeholder='hh:mm'
          onChange={(e) => {
            dispatch(onChangeFormRow({conf: {...configurations[id],  timeLimit: {value : e.target.value, valid: true, message: ""}}, id}))
          }}
        />
      </CTableDataCell>
      <CTableDataCell>
        <CustomTextInput
          id={`${id}-cancellation-limit`}
          required={false}
          disabled={disabled}
          valid={configuration.cancellationLimit?.valid}
          value={configuration.cancellationLimit?.value}
          helpText={configuration.cancellationLimit?.message}
          onChange={(e) => {
            dispatch(onChangeFormRow({conf: {...configurations[id],  cancellationLimit: {value : e.target.value, valid: true, message: ""}}, id}))
          }}
        />
      </CTableDataCell>
      <CTableDataCell>
        <CustomCheckbox
          checkStyle={{ width: '40px', height: '20px', marginTop: '5px' }}
          id={`configuration${id}-switch`}
          checked={configuration.isActive?.value}
          disabled={disabled}
          switch
          onClick={(e) => {
            dispatch(onChangeFormRow({conf:{...configurations[id], isActive: {value : !configuration.isActive?.value, valid: true, message: ""}}, id}))
          }}
        />
      </CTableDataCell>
    </CustomCTableRow>
  )
}

export default Serviceconfigurationrow