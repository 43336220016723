/**
 * Dropdowndiscountcomponent component
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */

import React, { useEffect, useRef, useState } from 'react'
import { CFormLabel, CInputGroup, CDropdown, CDropdownMenu } from '@coreui/react';
import * as Styles from './styles'
import { HelpText } from 'shared/genericStyledComponents';

interface PropTypes {
  wrapperStyle?: React.CSSProperties,
  required?: boolean,
  label?: string
  isPercentage: boolean,
  onChange: Function,
  onOptionChange: Function,
  placeholder?: string,
  value?: string,
  valid?: boolean
  helpText?: string,
  disabled?: boolean,
}

const Dropdowndiscountcomponent = (props: PropTypes) => {

  //states for menu dropdown
  const [currentOption, setCurrentOption] = useState(props.isPercentage && props.isPercentage===true ? true : false )

  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {

    if(props.value){
      setCurrentOption(props.isPercentage)
    }

  }, [props.value, props.isPercentage])

  // handlers for the menu dropdown
  const handleOptionChange = (opt) => {
    setCurrentOption(opt.target.text === '%' ? true : false)
    
    if (props.onOptionChange !== null) {
      props.onOptionChange(opt.target.text === '%' ? true : false)
    }
  }

  const handleInputChange = e => {
    if (props.onChange !== null) {
      props.onChange(e)
    }
  }

  return (
    <Styles.Wrapper style={props.wrapperStyle}>
      {props.label && <CFormLabel>{props.label}<span>{!!props.required ? "*" : ""}</span></CFormLabel>}
      <CInputGroup>
        <Styles.FormInput
          isvalid={props.valid}
          ref={inputRef}
          disabled={props.disabled === true ? true : false}
          required style={{ fontSize: 14 }}
          value={props.value && props.value}
          placeholder={props.placeholder}
          onChange={e => handleInputChange(e)} />
        <CDropdown variant="input-group">
          <Styles.DropdownToggle color="secondary" variant="outline">
            {currentOption ? '%' : '€'}
          </Styles.DropdownToggle>
          <CDropdownMenu style={{ maxHeight: '100vh', top: '35px' }} >
            <Styles.DropdownItem key={1} onClick={(e) => handleOptionChange(e)}>
              %
            </Styles.DropdownItem>
            <Styles.DropdownItem key={2} onClick={(e) => handleOptionChange(e)}>
              €
            </Styles.DropdownItem>
          </CDropdownMenu>
        </CDropdown>
      </CInputGroup>
      {props.helpText && <HelpText valid={props.valid}>{props.helpText}</HelpText>}
    </Styles.Wrapper>
  )
}

export default Dropdowndiscountcomponent