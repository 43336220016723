import axios from 'axios'
import { decamelizeKeys  } from 'humps'
import endpoints from 'api/endpoints'
import { generateSortString, generateUrl } from 'utils'
import { toast } from 'react-toastify'
import i18n from 'i18next';
import { SortType } from 'types/misc'

const GetBooking = (id: number) =>{
    const endpoint = generateUrl(endpoints.TRIPS.BOOKINGS.GET_BOOKING, { id });

    return axios.get(endpoint)
    .then(res => res.data)
    .catch(err => {
        toast.error(i18n.t("toasts.bookingGetError"))
    })
};

const GetBookings = (
    page: number,
    sort: SortType,
    tripDateAfter?: string,
    tripDateBefore?: string,
    createdDateAfter?: string,
    createdDateBefore?: string,
    activity?: string,
    departure?: string,
    arrival?: string,
    searchTerm?: string,
    partner?: string,
    status?: string,
    hasIssues?: string
    ) => {

    const searchQS = `${searchTerm ? `&search=${searchTerm}` : ''}`;
    const activityQS = `${activity ? `&activity=${activity}`: ''}`;
    const hasIssuesQS = `${hasIssues ? `&has_issues=${hasIssues}`: ''}`;
    const departureQS = `${departure ? `&departure=${departure}`: ''}`;
    const arrivalQS = `${arrival ? `&arrival=${arrival}`: ''}`;
    const partnerQS = `${partner ? `&partner=${partner}`: ''}`;
    const statusQS = `${status ? `&state=${status}`: ''}`;
    const tripDateQS = `${tripDateAfter && tripDateBefore ? `&trip_date_before=${tripDateBefore}&trip_date_after=${tripDateAfter}` : ''}`;
    const createdDateQS = `${createdDateAfter && createdDateBefore ? `&created_at_before=${createdDateBefore}&created_at_after=${createdDateAfter}` : ''}`;
    const sortQS = sort.field && sort.direction ? generateSortString(sort) : ''

    const endpoint = `
        ${endpoints.TRIPS.BOOKINGS.GET_BOOKINGS}?page=${page}${searchQS}${sortQS}${activityQS}${departureQS}${arrivalQS}${tripDateQS}${createdDateQS}${partnerQS}${statusQS}${hasIssuesQS}
    `;

    return axios.get(endpoint)
    .then(res => res.data)
    .catch(err => {
        toast.error(i18n.t("toasts.bookingsGetError"))
    })
};

const CreateBooking = (params) => {
    const token = window.localStorage.getItem('access_token')
    const decMLZParams = decamelizeKeys(params)

    return axios.create({
        headers:{
            'Authorization': 'Bearer ' + token, 
            'Content-Type': 'application/json',
        }
    }).post(endpoints.TRIPS.BOOKINGS.CREATE_BOOKING, decMLZParams)
        .then(res => res.data)
        .catch(err => { throw err })
}

const EditBooking = (params, id) => {
    const token = window.localStorage.getItem('access_token')
    const decMLZParams = decamelizeKeys(params)
    const endpoint = generateUrl(endpoints.TRIPS.BOOKINGS.EDIT_BOOKING, {id})

    return axios.create({
        headers:{
            'Authorization': 'Bearer ' + token, 
            'Content-Type': 'application/json',
        }
    }).patch(endpoint, decMLZParams)
        .then(res => res.data)
        .catch(err => { throw err })
}

const GetPaymentTypes = () => {
    return axios.get(endpoints.TRIPS.BOOKINGS.GET_PAYMENT_TYPES)
    .then(res => res.data)
    .catch(err => {
        toast.error(i18n.t("toasts.bookingGetPaymentTypesError"))
    })
};

const GetPaymentSources = () => {
    return axios.get(endpoints.TRIPS.BOOKINGS.GET_PAYMENT_SOURCES)
    .then(res => res.data)
    .catch(err => {
        toast.error(i18n.t("toasts.bookingGetPaymentSourcesError"))
    })
};

const SendBookingTicket = (id: number, email: string) => {
    const endpoint = generateUrl(endpoints.TRIPS.BOOKINGS.SEND_BOOKING_TICKET, {id})
    
    return axios.post(endpoint, {email:email})
    .then(res => toast.success(i18n.t("toasts.bookingGetTicketSuccess")))
    .catch(err => {
        toast.error(i18n.t("toasts.bookingSendTicketError"))
    })
};

const GetBookingTicket = (id: number) => {
    const endpoint = generateUrl(endpoints.TRIPS.BOOKINGS.GET_BOOKING_TICKET, {id})
    return axios.get(endpoint)
    .then(res => res.data)
    .catch(err => {
        toast.error(i18n.t("toasts.bookingGetTicketError"))
    })
};

const GetCancelRescheduleSettings = () => {
    return axios.get(endpoints.TRIPS.BOOKINGS.GET_CANCEL_RESCHEDULE_SETTINGS)
    .then(res => res.data)
    .catch(err => {
        console.log(err);
    })
};

const ExportBookings = (tripDateView: boolean, startDate: string, endDate: string) => {

    const datesQS = `${tripDateView ? 
        `?trip_date_before=${endDate}&trip_date_after=${startDate}` 
        : 
        `?created_at_before=${endDate}&created_at_after=${startDate}`}`;

    const endpoint = `${endpoints.TRIPS.BOOKINGS.EXPORT_BOOKINGS}${datesQS}`;
    const token = window.localStorage.getItem("access_token")

    return fetch(endpoint, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        }
    })
    .then(res => res.blob())
    .catch(err => { throw err })
}

const CancelBooking = (uuid) => {
    const endpoint = generateUrl(endpoints.TRIPS.BOOKINGS.CANCEL_BOOKING, { uuid });

    return axios.post(endpoint)
    .then(res => res.data)
    .catch(err => {
        toast.error(i18n.t("toasts.bookingGetError"))
    })
}

export default {
    GetBookings,
    GetBooking,
    GetBookingTicket,
    CreateBooking,
    EditBooking,
    GetPaymentTypes,
    GetPaymentSources,
    SendBookingTicket,
    GetCancelRescheduleSettings,
    ExportBookings,
    CancelBooking
}
