/**
 * Cashmanagement scene sagas
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */
import { AnyAction } from 'redux'
import { takeLatest, putResolve, select, call } from 'redux-saga/effects'
import { actions, RootState } from 'store/rootSlices'
import API from 'api'
import i18n from 'i18next'
import { toast } from 'react-toastify'
import { DateTime } from 'luxon'
import { CashManagementList } from 'types/invoicing/cashManagement'

//import { actions } from 'store/rootSlices'

function* onMountSaga({ payload }: AnyAction) {
  const history = payload

  yield getURLParamsSaga(history);
  yield putResolve(actions.CashManagement.setLoading(true));
  yield getCashManagementListsSaga()
  yield putResolve(actions.CashManagement.setLoading(false));
}


function* getCashManagementListsSaga() {
  yield putResolve(actions.CashManagement.setLoading(true));
  try {
    const {
      page,
      searchTerm,
      startDate,
      groupFilter
    } = yield select((state: RootState) => state.CashManagement);

    const cashManagementList: CashManagementList = yield call(API.Invoicing.GetStatements, page, searchTerm, groupFilter, DateTime.fromJSDate(startDate).toISODate(), DateTime.fromJSDate(startDate).toISODate());

    if (cashManagementList) {
      yield putResolve(actions.CashManagement.setCashManagementLists(cashManagementList.teamMembers ?? []));
      yield putResolve(actions.CashManagement.setStatementTotals(cashManagementList.totals))
    }
  } catch (err) {
    toast.error(i18n.t("toasts.getUserStatementError"));
  } finally {
    yield putResolve(actions.CashManagement.setLoading(false));
  }
}

function* exportStatementsSaga() {
  yield putResolve(actions.CashManagement.setExportLoading(true))

  const { startDate } = yield select((state: RootState) => state.CashManagement);

  try {
    const file = yield call(API.Invoicing.ExportStatements, DateTime.fromJSDate(startDate).toISODate(), DateTime.fromJSDate(startDate).toISODate())
    if (file.size > 0) {
      yield putResolve(actions.CashManagement.downloadStatements(file))
      toast.success(i18n.t("toasts.downloadCashManagementListSuccess"))
    }
    else {
      toast.error(i18n.t("toasts.downloadCashManagementListError"))
    }
  } catch (error) {
    toast.error(i18n.t("toasts.downloadCashManagementListError"))
  } finally {
    yield putResolve(actions.CashManagement.setExportLoading(false))
  }
}

function* getStatementTotalsSaga() {

  const { startDate } = yield select((state: RootState) => state.CashManagement);

  try {
    const totals = yield call(API.Invoicing.GetStatementsTotals, DateTime.fromJSDate(startDate).toISODate(), DateTime.fromJSDate(startDate).toISODate())

    if (totals) {
      yield putResolve(actions.CashManagement.setStatementTotals(totals.data))
    }
  } catch (error) {
    toast.error(i18n.t("toasts.StatementTotalsGetError"));
  }
}

function* submitApprovedStatementsSaga() {
  const { approvedStatements, startDate } = yield select((state: RootState) => state.CashManagement)

  const finalForm = {
    startDate: DateTime.fromJSDate(startDate).toISODate(),
    statements: approvedStatements
  }

  try {
    const statements = yield call(API.Invoicing.ApproveStatements, finalForm)

    if (statements) {
      yield putResolve(actions.CashManagement.clearApprovedStatements())
      yield putResolve(actions.CashManagement.clearDisapprovedStatements())
      yield getCashManagementListsSaga()
      toast.success(i18n.t("toasts.userStatementsApprovalSuccess"));
    }
  } catch (error) {
    toast.error(i18n.t("toasts.userStatementsApprovalError"));
  }
}

function* submitDisapprovedStatementsSaga() {
  const { disapprovedStatements, startDate } = yield select((state: RootState) => state.CashManagement)

  const finalForm = {
    startDate: DateTime.fromJSDate(startDate).toISODate(),
    statements: disapprovedStatements
  }

  try {
    const statements = yield call(API.Invoicing.DisapproveStatements, finalForm)

    if (statements) {
      yield putResolve(actions.CashManagement.clearApprovedStatements())
      yield putResolve(actions.CashManagement.clearDisapprovedStatements())
      yield getCashManagementListsSaga()
      toast.success(i18n.t("toasts.userStatementsDisapprovalSuccess"));
    }
  } catch (error) {
    toast.error(i18n.t("toasts.userStatementsDisapprovalError"));
  }
}

function* getURLParamsSaga(payload: AnyAction) {
  let params = new URLSearchParams(payload.location.search)

  const {
    searchTerm,
    groupFilter,
    startDate
  } = yield select((state: RootState) => state.CashManagement);

  if (params.toString() !== "") {
    yield putResolve(actions.CashManagement.populateFiltersOnMount({
      searchTerm: params.get('search') ?? "",
      startDate: DateTime.fromISO(params.get('date')).toJSDate() ?? DateTime.now().toJSDate(),
      groupFilter: params.get('group') ?? "",
    }))
  }
  else {
    params.set('search', searchTerm)
    params.set('group', groupFilter)
    params.set('date', DateTime.fromJSDate(startDate).toISODate())
    payload.replace({ search: (params).toString() })
  }
}

function* onUnmountSaga() {
  yield putResolve(actions.CashManagement.setCashManagementLists([]));
  yield putResolve(actions.CashManagement.setStatementTotals({
    income: 0,
    expenses: 0,
    estimated: 0
  }))
  yield putResolve(actions.CashManagement.setLoading(true));

}

export default function* watcherSignin() {
  yield takeLatest('Cashmanagement/getCashManagementList', getCashManagementListsSaga)
  yield takeLatest('Cashmanagement/getStatementTotals', getStatementTotalsSaga)
  yield takeLatest('Cashmanagement/submitApprovedStatements', submitApprovedStatementsSaga)
  yield takeLatest('Cashmanagement/submitDisapprovedStatements', submitDisapprovedStatementsSaga)
  yield takeLatest('Cashmanagement/exportStatements', exportStatementsSaga)
  yield takeLatest('Cashmanagement/setSearchTerm', getCashManagementListsSaga)
  yield takeLatest('Cashmanagement/setStartDate', getCashManagementListsSaga)
  yield takeLatest('Cashmanagement/setSort', getCashManagementListsSaga)
  yield takeLatest('Cashmanagement/setGroupFilter', getCashManagementListsSaga)
  yield takeLatest('Cashmanagement/setShowUsersWithNoMovements', getCashManagementListsSaga)
  yield takeLatest('Cashmanagement/onMount', onMountSaga)
  yield takeLatest('Cashmanagement/onUnmount', onUnmountSaga)
  yield takeLatest('Cashmanagement/getURLParams', getURLParamsSaga)
}


