/**
 * Usercashmanagementrow component
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */

import React from 'react'
import theme from 'ui/theme'
import ChevronRight from 'assets/icons/chevron_right'
import { CTableDataCell } from '@coreui/react-pro'
import { ChevronCell, CustomTableRow, ColoredDataCellText } from 'shared/genericStyledComponents'
import { actions, RootState } from 'store/rootSlices'
import { useDispatch, useSelector } from 'react-redux'
import { TableRowPropTypes } from 'types/misc'


type PropTypes = TableRowPropTypes & {
  id?: number,
  statement?: any,
  name?: string,
  permission: boolean,
}


const Usercashmanagementrow = (props) => {

  const {
    userID,
  } = useSelector((state: RootState) => state.UserCashManagement)

  const {
    name,
    statement: {
      id,
      income,
      expenses,
      multibancoSales,
      bankDeposit,
      notDeposited,
      estimated,
      total,
      balance
    }
  } = props

  const dispatch = useDispatch()

  return (
    <CustomTableRow id={`booking-`}>
      <CTableDataCell>
        {name}
      </CTableDataCell>
      <ColoredDataCellText color={theme().success}>
        {income}€
      </ColoredDataCellText>
      <ColoredDataCellText color={theme().danger}>
        {expenses}€
      </ColoredDataCellText>
      <CTableDataCell>
        {multibancoSales}€
      </CTableDataCell>
      <CTableDataCell>
        {bankDeposit}€
      </CTableDataCell>
      <CTableDataCell>
        {notDeposited}€
      </CTableDataCell>
      <CTableDataCell >
        {total}€
      </CTableDataCell>
      <ColoredDataCellText color={estimated > 0 ? theme().success : theme().danger}>
        {estimated}€
      </ColoredDataCellText>
      <ColoredDataCellText color={balance > 0 ? theme().success : theme().danger} trigger>
        {balance}€
      </ColoredDataCellText>
      <CTableDataCell>
        {props.actions}
        <ChevronCell id={`${props.id}-chevron`}>
          {
            props.statement && <a href={`/faturacao/gestao-caixa/${userID}/editar/${id}`}
              onClick={(e) => {
                e.preventDefault()
                dispatch(actions.App.navigateTo(`/faturacao/gestao-caixa/${userID}/editar/${id}`))
              }}>
              <ChevronRight></ChevronRight>
            </a>
          }
        </ChevronCell>
      </CTableDataCell>
    </CustomTableRow>
  )
}

export default Usercashmanagementrow

