/**
 * Entityform scene utils
 *
 * @author João Dias <jdias@ubiwhere.com>
 *
 *
 */

import { FormField } from "types/misc"
import { EntityDocumentsForm, EntityForm } from "types/entity/entity"
import * as Check from "validations"


export const defaultDocument: EntityDocumentsForm = {
    expiration: {
        value: "",
        valid: true,
        message: ""
    },
    fileName: "",
    url: "",
    file: {
        value: null,
        valid: true,
        message: ""
    },
    id: {
        value: -1,
        valid: true,
        message: ""
    },
    isActive: {
        value: true,
        valid: true,
        message: ""
    },
    dirty: false,
    new: true,
}

export const entityFormValidationsTax = {
    isActive: [Check.hasValue],
    printAmountInTicket: [Check.hasValue],
    observations: [Check.hasValue],
    customerPaymentMode: [Check.hasValue],
    secondaryEmail: [Check.hasValue],
    secondaryPhone: [Check.hasValue],
    taxCity: [Check.isRequired],
    taxPostalCode: [Check.isRequired],
    taxCountry: [Check.isRequired],
    taxAddress: [Check.isRequired],
    taxNif: [Check.isRequired],
    taxName: [Check.isRequired],
    useSpecificTaxDetails: [Check.hasValue],
    shortName: [Check.isRequired],
    name: [Check.isRequired],
    phone: [Check.isRequired],
    email: [Check.isRequired],
    address: [Check.hasValue],
    country: [Check.isRequired],
    postalCode: [Check.isRequired],
    city: [Check.isRequired],
    nif: [Check.isRequired],
    type: [],
    url: [],
    activitiesCommissionDefault: [Check.isRequired, Check.isNumber],
    extrasCommissionDefault: [Check.isRequired, Check.isNumber],
}


export const entityFormValidationsNoTax = {
    isActive: [Check.hasValue],
    printAmountInTicket: [Check.hasValue],
    observations: [Check.hasValue],
    customerPaymentMode: [Check.hasValue],
    secondaryEmail: [Check.hasValue],
    secondaryPhone: [Check.hasValue],
    taxCity: [Check.hasValue],
    taxPostalCode: [Check.hasValue],
    taxCountry: [Check.hasValue],
    taxAddress: [Check.hasValue],
    taxNif: [Check.hasValue],
    taxName: [Check.hasValue],
    useSpecificTaxDetails: [Check.hasValue],
    shortName: [Check.isRequired],
    name: [Check.isRequired],
    phone: [Check.isRequired],
    email: [Check.isRequired],
    address: [Check.hasValue],
    country: [Check.isRequired],
    postalCode: [Check.isRequired],
    city: [Check.isRequired],
    nif: [Check.isRequired],
    type: [],
    url: [],
    activitiesCommissionDefault: [Check.isRequired, Check.isNumber],
    extrasCommissionDefault: [Check.isRequired, Check.isNumber],
}

export const EntityCommissionsValidations = {
    commission: [],
}


export const defaultEntityForm: EntityForm = {
    avatar: {
        message: "",
        valid: true,
        value: null
    },
    address: {
        message: "",
        valid: true,
        value: ""
    },
    city: {
        message: "",
        valid: true,
        value: ""
    },
    chargeConditions: {
        message: "",
        valid: true,
        value: ""
    },
    country: {
        message: "",
        valid: true,
        value: ""
    },
    customerPaymentMode: {
        message: "",
        valid: true,
        value: ""
    },
    email: {
        message: "",
        valid: true,
        value: ""
    },
    isActive: {
        message: "",
        valid: true,
        value: true
    },
    name: {
        message: "",
        valid: true,
        value: ""
    },
    nif: {
        message: "",
        valid: true,
        value: ""
    },
    observations: {
        message: "",
        valid: true,
        value: ""
    },
    phone: {
        message: "",
        valid: true,
        value: ""
    },
    postalCode: {
        message: "",
        valid: true,
        value: ""
    },
    printAmountInTicket: {
        message: "",
        valid: true,
        value: true
    },
    secondaryEmail: {
        message: "",
        valid: true,
        value: ""
    },
    secondaryPhone: {
        message: "",
        valid: true,
        value: ""
    },
    shortName: {
        message: "",
        valid: true,
        value: ""
    },
    taxAddress: {
        message: "",
        valid: true,
        value: ""
    },
    taxCity: {
        message: "",
        valid: true,
        value: ""
    },
    taxCountry: {
        message: "",
        valid: true,
        value: ""
    },
    taxName: {
        message: "",
        valid: true,
        value: ""
    },
    taxNif: {
        message: "",
        valid: true,
        value: ""
    },
    taxPostalCode: {
        message: "",
        valid: true,
        value: ""
    },
    useSpecificTaxDetails: {
        message: "",
        valid: true,
        value: true
    },
    partnerType: {
        message: "",
        valid: true,
        value: ""
    },
    url: {
        message: "",
        valid: true,
        value: ""
    },
    activitiesCommissionDefault: {
        message: "",
        valid: true,
        value: "0"
    },
    extrasCommissionDefault: {
        message: "",
        valid: true,
        value: "0"
    }
}


export type activityCommissionForm = {
    uuid: string,
    name: string,
    isCustom: boolean,
    commission: FormField<string>
}

export type extraCommissionForm = {
    uuid: string,
    name: string,
    isCustom: boolean,
    commission: FormField<string>
}