import styled from 'styled-components'
import { CTableDataCell, CTableFoot } from '@coreui/react-pro'

import { TableRow } from '../../styles'
import theme from 'ui/theme'

const Tooltip = styled(CTableDataCell)`
  position: relative;
  visibility: visible;
  color: #fff;

  &:hover {
    > span {
      visibility: visible
    }
  }
`

const CustomSpan = styled.span`
  visibility: hidden;
  width: fit-content;
  min-width: 200px;
  background-color: #0E364F;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 10px 5px 0px 5px;
  position: absolute;
  z-index: 3;
  top: -25px;
  left: 55%;
`

const TooltipText = styled.span`
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  line-height: .5rem;
  gap: 5px;
`

const TooltipMainText = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`

const Payments = styled.div`
    h6 {
        margin-bottom: 8px;
        font-family: 'Inter';
        font-style: normal;
        font-size: 19px;
        color: ${theme().darkColor};
    }
`;

const PaymentRow = styled(TableRow)`
    background-color: ${theme().white};
`;

const PaymentStateCell = styled(CTableDataCell)`
    &.refund {
        color: ${theme().danger};
    }
    &.payment {
        color: ${theme().success};
    }
    &.pending {
        color: ${theme().warning};
    }
`;

const PaymentsFooter = styled(CTableFoot)`
    tr {
        background-color: ${theme().offWhite};
        td {
            font-size: 14px;
            font-weight: 700;
            span {
                font-weight: 400;
            }
        }
    }
`;


export {
  Tooltip,
  CustomSpan,
  TooltipText,
  TooltipMainText,
  Payments,
  PaymentRow,
  PaymentStateCell,
  PaymentsFooter,
}