import { decamelizeKeys } from 'humps';
import axios from 'axios'
import endpoints from 'api/endpoints'
import { generateSortString, generateUrl } from 'utils'
import { toast } from 'react-toastify'
import i18n from 'i18next'
import { SortType } from 'types/misc'


const GetFuellingPricePerLitre = (id: number) => {
    const endpoint = generateUrl(endpoints.FLEET.FUEL_PRICE_LITRE, { id })

    return axios.get(endpoint)
        .then(res => res.data)
        .catch(err => {
            throw err;
        })
}


const ListFuellingDocuments = (fuel: number) => {
    const fuelQS = `fuel=${fuel}`
    const endpoint = `${endpoints.FLEET.LIST_FUEL_DOCS}?${fuelQS}`;

    return axios.get(endpoint)
        .then(res => res.data)
        .catch(err => {
            throw err;
        })
}

const GetFuellingDocument = (id: number) => {
    const endpoint = generateUrl(endpoints.FLEET.GET_FUEL_DOC, { id })

    return axios.get(endpoint)
        .then(res => res.data)
        .catch(err => {
            throw err;
        })
}

const AddFuellingDocument = (params) => {

    const decMLZParams = decamelizeKeys(params)
    const entryDocFormData = new FormData();

    Object.keys(decMLZParams).forEach(valKey => {
        if (valKey === "file") {
            entryDocFormData.append(valKey, params[valKey])
        }
        else {
            entryDocFormData.append(valKey, decMLZParams[valKey])
        }
    })

    return axios.post(endpoints.FLEET.CREATE_FUEL_DOC, entryDocFormData)
        .then(res => res.data)
        .catch(err => {
            throw err
        })
}

const EditFuellingDocument = (id: number, params) => {
    const endpoint = generateUrl(endpoints.FLEET.EDIT_FUEL_DOC, { id })

    const entryDocFormData = new FormData();
    Object.keys(params).forEach(valKey => {
        entryDocFormData.append(valKey, params[valKey])
    })

    return axios.patch(endpoint, entryDocFormData)
        .then(res => res.data)
        .catch(err => {
            throw err
        })
}

const DeleteFuellingDocument = (id: number) => {
    const endpoint = generateUrl(endpoints.FLEET.DELETE_FUEL_DOC, { id })

    return axios.delete(endpoint)
        .then(res => res)
        .catch(err => {
            throw err
        })
}



const ListMaintenanceDocuments = (maintenance: number) => {
    const maintenanceQS = `maintenance=${maintenance}`
    const endpoint = `${endpoints.FLEET.LIST_MAINTENANCE_DOCS}?${maintenanceQS}`;

    return axios.get(endpoint)
        .then(res => res.data)
        .catch(err => {
            throw err;
        })
}

const GetMaintenanceDocument = (id: number) => {
    const endpoint = generateUrl(endpoints.FLEET.GET_MAINTENANCE_DOC, { id })

    return axios.get(endpoint)
        .then(res => res.data)
        .catch(err => {
            throw err;
        })
}

const AddMaintenanceDocument = (params) => {

    const decMLZParams = decamelizeKeys(params)
    const entryDocFormData = new FormData();

    Object.keys(decMLZParams).forEach(valKey => {
        if (valKey === "file") {
            entryDocFormData.append(valKey, params[valKey])
        }
        else {
            entryDocFormData.append(valKey, decMLZParams[valKey])
        }
    })

    return axios.post(endpoints.FLEET.CREATE_MAINTENANCE_DOC, entryDocFormData)
        .then(res => res.data)
        .catch(err => {
            throw err
        })
}

const EditMaintenanceDocument = (id: number, params) => {

    const endpoint = generateUrl(endpoints.FLEET.EDIT_MAINTENANCE_DOC, { id })

    const entryDocFormData = new FormData();
    Object.keys(params).forEach(valKey => {
        entryDocFormData.append(valKey, params[valKey])
    })

    return axios.patch(endpoint, entryDocFormData)
        .then(res => res.data)
        .catch(err => {
            throw err
        })
}

const DeleteMaintenanceDocument = (id: number) => {
    const endpoint = generateUrl(endpoints.FLEET.DELETE_MAINTENANCE_DOC, { id })

    return axios.delete(endpoint)
        .then(res => res)
        .catch(err => {
            throw err
        })
}


const GetAllFleet = () => {
    return axios.get(endpoints.FLEET.FLEET_URL)
        .then(res => res.data)
        .catch(err => {
            i18n.t("toasts.fleetGetError");
        })
}

const GetFleet = (page: number, searchTerm: string, sort: SortType, vehicleType?: string) => {

    const vehicleTypeQS = `${vehicleType ? `&vehicle_type=${vehicleType}` : ''}`;

    let endpoint = `${endpoints.FLEET.FLEET_URL}?page=${page}${vehicleTypeQS}`;
    if (searchTerm && searchTerm !== "") {
        endpoint += `&search=${searchTerm}`;
    }
    if (sort.field !== null && sort.direction !== null && sort.direction !== "") {
        endpoint += generateSortString(sort);
    }
    return axios.get(endpoint)
        .then(res => res.data)
        .catch(err => {
            i18n.t("toasts.fleetGetError")
        })
}

const GetVehicle = (id) => {
    const endpoint = generateUrl(endpoints.FLEET.VEHICLE_URL, { id })
    return axios.get(endpoint)
        .then(res => res.data)
        .catch(err => {
            i18n.t("toasts.fleetGetError")
        })
}

const EditVehicle = (form, id) => {
    const formData = new FormData();
    Object.keys(form).forEach(key => {
        if (form[key] !== null) formData.append(key, form[key])
    });
    const endpoint = generateUrl(endpoints.FLEET.VEHICLE_URL, { id })
    return axios.patch(endpoint, formData)
        .then(res => {
            toast.success(i18n.t("toasts.vehicleEditSuccess"));
            return res;
        })
        .catch(err => {
            toast.info(i18n.t("toasts.vehicleEditError"));
        })
}

const CreateVehicle = (form) => {
    const formData = new FormData();
    Object.keys(form).forEach(key => {
        if (form[key] !== null && form[key] !== undefined) formData.append(key, form[key])
    });
    return axios.post(endpoints.FLEET.FLEET_URL, formData)
        .then(res => {
            toast.success(i18n.t("toasts.vehicleCreateSuccess"));
            return res;
        })
        .catch(err => {
            toast.error(i18n.t("toasts.vehicleCreateError"));
        })
}

const DeleteVehicle = id => {
    const endpoint = generateUrl(endpoints.FLEET.VEHICLE_URL, { id });
    return axios.delete(endpoint)
        .then(res => {
            toast.info(i18n.t("toasts.vehicleDeleteSuccess"));
            return res;
        })
        .catch(err => {
            toast.error(i18n.t("toasts.vehicleDeleteError"));
        });
}

const GetAllMaintenances = (page: number, searchTerm: string, sort: SortType, isFinished: boolean, vehicleType?: string, vehicle?: string) => {

    const vehicleTypeQS = `${vehicleType ? `&vehicle_type=${vehicleType}` : ''}`;
    const isFinishedQS = `&is_finished=${isFinished}`;
    const vehicleQS = `${vehicle ? `&vehicle=${vehicle}` : ''}`;
    let endpoint = `${endpoints.FLEET.MAINTENANCES_URL}?page=${page}${vehicleTypeQS}${isFinishedQS}${vehicleQS}`;
    if (searchTerm && searchTerm !== "") {
        endpoint += `&search=${searchTerm}`;
    }
    if (sort.field !== null && sort.direction !== null && sort.direction !== "") {
        endpoint += generateSortString(sort);
    }
    return axios.get(endpoint)
        .then(res => res.data)
        .catch(err => {
            toast.error(i18n.t("toasts.maintenancesGetError"))
        })
}

const GetMaintenance = (id) => {
    const endpoint = generateUrl(endpoints.FLEET.SINGLE_MAINTENANCE_URL, { id })
    return axios.get(endpoint)
        .then(res => res.data)
        .catch(err => {
            toast.error(i18n.t("toasts.maintenanceGetError"))
        })
}

const CreateMaintenance = (form) => {
    const formData = new FormData();
    Object.keys(form).forEach(key => {
        if (form[key] !== null && form[key] !== undefined && form[key] !== "") formData.append(key, form[key])
    });
    return axios.post(endpoints.FLEET.MAINTENANCES_URL, formData)
        .then(res => {
            toast.success(i18n.t("toasts.maintenanceCreateSuccess"));
            return res;
        })
        .catch(err => {
            toast.error(i18n.t("toasts.maintenanceCreateError"));
        })
}

const EditMaintenance = (form, id) => {
    const formData = new FormData();
    Object.keys(form).forEach(key => {
        if (form[key] !== null) formData.append(key, form[key])
    });
    const endpoint = generateUrl(endpoints.FLEET.SINGLE_MAINTENANCE_URL, { id })
    return axios.patch(endpoint, formData)
        .then(res => {
            toast.success(i18n.t("toasts.maintenanceEditSuccess"));
            return res;
        })
        .catch(err => {
            toast.error(i18n.t("toasts.maintenanceEditError"));
        })
}

const DeleteMaintenance = id => {
    const endpoint = generateUrl(endpoints.FLEET.SINGLE_MAINTENANCE_URL, { id });
    return axios.delete(endpoint)
        .then(res => {
            toast.info("Manutenção eliminada com sucesso.");
            return res;
        })
        .catch(err => {
            toast.error("Erro a eliminar manutenção.");
        });
}

const GetFuellings = (page: number, searchTerm: string, sort: SortType, vehicleType?: string, vehicle?: string, startDate?: string, endDate?: string, fuelStation?: string) => {

    const vehicleTypeQS = `${vehicleType ? `&vehicle_type=${vehicleType}` : ''}`;
    const vehicleQS = `${vehicle ? `&vehicle=${vehicle}` : ''}`;
    const fuelStationQS = `${fuelStation ? `&fuel_station=${fuelStation}` : ''}`;
    const dateAfterQS = `${startDate ? `&fuel_date_after=${startDate}` : ''}`;
    const dateBeforeQS = `${endDate ? `&fuel_date_before=${endDate}` : ''}`;

    let endpoint = `${endpoints.FLEET.FUEL_URL}?page=${page}${vehicleTypeQS}${vehicleQS}${dateAfterQS}${dateBeforeQS}${fuelStationQS}`;
    if (searchTerm && searchTerm !== "") {
        endpoint += `&search=${searchTerm}`;
    }
    if (sort.field !== null && sort.direction !== null && sort.direction !== "") {
        endpoint += generateSortString(sort);
    }
    return axios.get(endpoint)
        .then(res => res.data)
        .catch(err => { toast.error(i18n.t("toasts.fuellingsGetError")); })
}

const GetConsumptions = (page: number, searchTerm: string, sort: SortType, vehicleType?: string, vehicle?: string, startDate?: string, endDate?: string) => {
    const vehicleTypeQS = `${vehicleType ? `&vehicle_type=${vehicleType}` : ''}`;
    const vehicleQS = `${vehicle ? `&vehicle=${vehicle}` : ''}`;
    const dateAfterQS = `${startDate ? `&created_at_after=${startDate}` : ''}`;
    const dateBeforeQS = `${endDate ? `&created_at_before=${endDate}` : ''}`;

    let endpoint = `${endpoints.FLEET.COMSUMPTIONS_LIST}?page=${page}${vehicleTypeQS}${vehicleQS}${dateAfterQS}${dateBeforeQS}`;
    if (searchTerm && searchTerm !== "") {
        endpoint += `&search=${searchTerm}`;
    }
    if (sort.field !== null && sort.direction !== null && sort.direction !== "") {
        endpoint += generateSortString(sort);
    }
    return axios.get(endpoint)
        .then(res => res.data)
        .catch(err => { toast.error(i18n.t("toasts.consumptionsGetError")); })
}

const GetSingleFuelling = (id: number) => {
    const endpoint = generateUrl(endpoints.FLEET.SINGLE_FUELLING_URL, { id });
    return axios.get(endpoint)
        .then(res => res.data)
        .catch(err => { toast.error(i18n.t("toasts.fuellingGetError")); })
}

const CreateFuelling = (form) => {
    const formData = new FormData();
    Object.keys(form).forEach(key => {
        if (form[key] !== null && form[key] !== undefined) formData.append(key, form[key])
    });
    return axios.post(endpoints.FLEET.FUEL_URL, formData)
        .then(res => {
            return res;
        })
        .catch(err => {
            throw err
        })
}

const EditFuelling = (form, id) => {
    const formData = new FormData();
    Object.keys(form).forEach(key => {
        if (form[key] !== null) formData.append(key, form[key])
    });
    const endpoint = generateUrl(endpoints.FLEET.SINGLE_FUELLING_URL, { id })
    return axios.patch(endpoint, formData)
        .then(res => {
            if (res.status === 200) {
                toast.success(i18n.t("toasts.fuellingEditSuccess"));
            }
            return res;
        })
        .catch(err => {
            toast.error(i18n.t("toasts.fuellingEditError"));
        })
}

const GetVehicleTypes = () => {
    return axios.get(endpoints.FLEET.VEHICLE_TYPE_SETTINGS_URL)
        .then(res => res)
        .catch(err => {
            toast.error(i18n.t("toasts.vehicleTypesGetError"));
        })
}

const GetVehicleClasses = () => {
    return axios.get(endpoints.FLEET.CLASS_SETTINGS_URL)
        .then(res => res)
        .catch(err => {
            toast.error(i18n.t("toasts.vehicleClassesGetError"));
        })
}

const GetFuelTypes = () => {
    return axios.get(endpoints.FLEET.FUEL_TYPE_SETTINGS_URL)
        .then(res => res)
        .catch(err => {
            toast.error(i18n.t("toasts.fuelTypesGetError"));
        })
}

const GetFuelLocations = (isTank?: boolean) => {

    const isTankQS = `${isTank ? `is_tank=${!isTank}` : ''}`;
    let endpoint = `${endpoints.FLEET.FUEL_LOCATIONS_URL}?${isTankQS}`

    return axios.get(endpoint)
        .then(res => res)
        .catch(err => { toast.error(i18n.t("toasts.fuelLocationsGetError")); })
}

const GetFuelLocationsPaginated = (page: number, searchTerm: string, sort: SortType) => {
    let endpoint = `${endpoints.FLEET.FUEL_LOCATIONS_URL}?page=${page}`;
    if (searchTerm && searchTerm !== "") {
        endpoint += `&search=${searchTerm}`;
    }
    if (sort.field !== null && sort.direction !== null && sort.direction !== "") {
        endpoint += generateSortString(sort);
    }

    return axios.get(endpoint)
        .then(res => res)
        .catch(err => {
            throw err
        })
}

const GetSingleFuelLocation = (id: number) => {
    const endpoint = generateUrl(endpoints.FLEET.SINGLE_FUEL_LOCATION_URL, { id });
    return axios.get(endpoint)
        .then(res => res.data)
        .catch(err => { })
}

const GetDocumentTypes = () => {
    return axios.get(endpoints.FLEET.DOCUMENT_SETTINGS_URL)
        .then(res => res)
        .catch(err => {
            throw err
        })
}

const GetFuelPaymentTypes = () => {
    return axios.get(endpoints.FLEET.FUEL_PAYMENT_TYPES)
        .then(res => res)
        .catch(err => {
            throw err
        })
}

const GetDocumentTypesPaginated = (page: number, searchTerm: string, sort: SortType) => {
    let endpoint = `${endpoints.FLEET.DOCUMENT_SETTINGS_URL}?page=${page}`;
    if (searchTerm && searchTerm !== "") {
        endpoint += `&search=${searchTerm}`;
    }
    if (sort.field !== null && sort.direction !== null && sort.direction !== "") {
        endpoint += generateSortString(sort);
    }

    return axios.get(endpoint)
        .then(res => res)
        .catch(err => {
            toast.error(i18n.t("toasts.documentTypesGetError"));
        })
}

const GetSingleDocumentType = (id) => {
    const endpoint = generateUrl(endpoints.FLEET.SINGLE_DOCUMENT_SETTINGS_URL, { id })
    return axios.get(endpoint)
        .then(res => res)
        .catch(err => {
            toast.error(i18n.t("toasts.documentTypesGetError"));
        })
}

const CreateDocumentType = (name) => {
    return axios.post(endpoints.FLEET.DOCUMENT_SETTINGS_URL, name)
        .then(res => {
            toast.success(i18n.t("toasts.documentCreateSuccess"));
            return res;
        })
        .catch(err => {
            toast.error(i18n.t("toasts.documentCreateError"));
        })
}

const EditDocumentType = (form, id) => {
    const endpoint = generateUrl(endpoints.FLEET.SINGLE_DOCUMENT_SETTINGS_URL, { id })
    return axios.patch(endpoint, form)
        .then(res => {
            toast.success(i18n.t("toasts.documentEditSuccess"));
            return res;
        }).catch(err => {
            toast.error(i18n.t("toasts.documentEditError"));
        })
}

const DeleteDocumentType = (id) => {
    const endpoint = generateUrl(endpoints.FLEET.SINGLE_DOCUMENT_SETTINGS_URL, { id })
    return axios.delete(endpoint)
        .then(res => res)
        .catch(err => {
            toast.error(i18n.t("toasts.documentTypesDeleteError"));
        })
}

const CreateDocument = (body) => {
    const decMLZParams = decamelizeKeys(body)
    const formData = new FormData();

    Object.keys(decMLZParams).forEach((key) => {
        if (key === "file") {
            formData.append(key, body[key].value)
        }
        else {
            formData.append(key, decMLZParams[key])
        }
    })

    return axios.post(endpoints.FLEET.FLEET_DOCUMENTS_URL, formData)
        .then(res => {
            toast.success(i18n.t("toasts.documentCreateSuccess"));
            return res;
        })
        .catch(err => {
            toast.error(i18n.t("toasts.documentCreateError"));
        })
}

const EditDocument = (body, id) => {
    const decMLZParams = decamelizeKeys(body)
    const formData = new FormData();

    Object.keys(decMLZParams).forEach((key) => {
        if (key === "file") {
            formData.append(key, body[key].value)
        }
        else {
            formData.append(key, decMLZParams[key])
        }
    })

    const endpoint = generateUrl(endpoints.FLEET.SINGLE_DOCUMENT_URL, { id });

    return axios.patch(endpoint, formData)
        .then(res => {
            toast.info(i18n.t("toasts.documentEditSuccess"));
            return res;
        })
        .catch(err => {
            toast.error(i18n.t("toasts.documentEditError"));
        })
}

const DeleteDocument = (id) => {
    const endpoint = generateUrl(endpoints.FLEET.SINGLE_DOCUMENT_URL, { id });

    return axios.delete(endpoint)
        .then(res => {
            toast.info(i18n.t("toasts.documentDeleteSuccess"));
            return res;
        })
        .catch(err => {
            toast.error(i18n.t("toasts.documentDeleteError"));
        })
}

const CreateFuelLocation = (body) => {
    return axios.post(endpoints.FLEET.FUEL_LOCATIONS_URL, body)
        .then(res => {
            toast.info(i18n.t("toasts.fuelLocationCreateSuccess"));
            return res;
        }).catch(err => {
            toast.error(i18n.t("toasts.fuelLocationCreateError"));
        })
}

const EditFuelLocation = (body, id) => {
    const endpoint = generateUrl(endpoints.FLEET.SINGLE_FUEL_LOCATION_URL, { id });
    return axios.patch(endpoint, body)
        .then(res => {
            toast.info(i18n.t("toasts.fuelLocationEditSuccess"));
            return res;
        }).catch(err => {
            toast.error(i18n.t("toasts.fuelLocationEditError"));
        })
}

const GetFleetWithTanks = async () => {
    try {
        //get vehicles that are fuel tanks
        let endpoint = `${endpoints.FLEET.FUEL_LOCATIONS_URL}?is_tank=${true}`

        const tanks = await axios.get(endpoint);
        //const fleet = await axios.get(endpoints.FLEET.FLEET_URL);

        return [...tanks.data/* , ...fleet.data */];
    } catch {
        toast.error(i18n.t("toasts.fleetGetError"));
    }
}

export default {
    GetFuellingPricePerLitre,
    GetFleet,
    GetVehicle,
    GetAllMaintenances,
    GetFleetWithTanks,
    GetMaintenance,
    GetVehicleTypes,
    GetVehicleClasses,
    GetFuelTypes,
    GetFuelLocations,
    GetFuelPaymentTypes,
    GetDocumentTypes,
    EditVehicle,
    CreateVehicle,
    DeleteVehicle,
    GetAllFleet,
    CreateMaintenance,
    EditMaintenance,
    DeleteMaintenance,
    GetFuellings,
    GetSingleFuelling,
    CreateFuelling,
    EditFuelling,
    CreateDocumentType,
    GetSingleDocumentType,
    GetDocumentTypesPaginated,
    EditDocumentType,
    DeleteDocumentType,
    CreateDocument,
    EditDocument,
    DeleteDocument,
    GetFuelLocationsPaginated,
    GetSingleFuelLocation,
    CreateFuelLocation,
    EditFuelLocation,
    ListMaintenanceDocuments,
    GetMaintenanceDocument,
    EditMaintenanceDocument,
    AddMaintenanceDocument,
    DeleteMaintenanceDocument,
    ListFuellingDocuments,
    GetFuellingDocument,
    AddFuellingDocument,
    EditFuellingDocument,
    DeleteFuellingDocument,
    GetConsumptions
}