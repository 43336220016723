import styled from 'styled-components';

export const Tooltip = styled.div`
  position: relative;
  visibility: visible;
  color: #fff;

  &:hover {
    > span {
      visibility: visible
    }
  }
`

export const TooltipIdText = styled.p`
  margin: auto; 
  color: #000000 !important;
`

export const CustomSpan = styled.span<{ width?: string }>`
  visibility: hidden;
  width: ${({ width }) => width ? width: 'fit-content'};
  min-width: 200px;
  background-color: #0E364F;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 3;
  top: 0px;
  left: 20px;
`

export const TooltipHoursText = styled.span`
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 12px;
`

export const CurrentStopIndicatorWrap = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
`

export const CurrentStopIndicator = styled.div<{ color: string }>`
  width: 8px;
  aspect-ratio: 1;
  border-radius: 50px;
  background-color: ${props => props.color};
`

export const PadCircuitStopInfo = styled.div`
  display:flex;
  flex-wrap: wrap;
  align-items:center;
  flex-direction:column;
  justify-content:flex-end;
  height:100%;
`

export const PassengersCircle = styled.div <{ fill: string }>`
  border: 1px solid ${props => props.fill};
  border-radius: 50%;
  background:#FFFFFF;
  font-size: 11px;
  color: ${props => props.fill};
  width: 22px;
  height: 22px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
`