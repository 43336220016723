import styled from 'styled-components';
import theme from 'ui/theme';

export const Tooltip = styled.div`
  position: relative;
  visibility: visible;
  color: #fff;

  &:hover {
    > span {
      visibility: visible
    }
  }
`

export const TooltipIdText = styled.p`
  margin: auto; 
  color: #000000 !important;
`

export const CustomSpan = styled.span<{ width?: string }>`
  visibility: hidden;
  width: ${({ width }) => width ? width: 'fit-content'};
  min-width: 200px;
  background-color: #0E364F;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 3;
  top: 0px;
  left: 20px;
`

export const TooltipHoursInfo = styled.div`
  padding-inline: 10px;
  padding-top: 5px;
  width: 100%;
`

export const TooltipHoursText = styled.span`
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 12px;
`

export const CurrentStopIndicatorWrap = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
`

export const CurrentStopIndicator = styled.div<{ color: string }>`
  width: 8px;
  aspect-ratio: 1;
  border-radius: 50px;
  background-color: ${props => props.color};
`

export const UnitsIndicatorWrap = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
`

export const CheckIndicator = styled.div`
  padding-left: 5px;
`

export const PadCircuitDetails = styled.div`
  width: 100%;
  display:flex;
  justify-content: space-between;
  align-items: center;
  padding-left:29px;
  padding-right:24px;
  position:relative;
`

export const PadCircuitDetailStop = styled.div`
  display:flex;
  align-items:center;
  justify-content:flex-end;
  min-width:50px;
  height:100%;
  width: fit-content;
`

export const PadCircuitStopInfo = styled.div`
  display:flex;
  flex-wrap: wrap;
  align-items:center;
  flex-direction:column;
  justify-content:flex-end;
  height:100%;
`

export const Line = styled.div`
  width: calc(100% - 100px);
  position: absolute;
  left: 50px;
  top: calc(100% - 10px);
  border-top: 1px solid ${theme().gray600Color};
  z-index:0;
`

export const Icon = styled.div <{ marginr: string }>`
  display: flex;
  flex-direction: column;
  margin-right: ${props => props.marginr}px;
`

export const PadDetailsHour = styled.div`
  width: 5%;
  padding-top:10px;
  padding-bottom:10px;
`

export const PadCircuitDetailBetweenStopsCurrent = styled.div`
  display:flex;
  align-self:flex-end;
  align-items:center;
  flex-direction:column;
  height:100%;
  justify-content:space-between;
`

export const PadStops = styled.div`
  display: flex;
`

export const PassengersCircle = styled.div <{ fill: string }>`
  border: 1px solid ${props => props.fill};
  border-radius: 50%;
  background:#FFFFFF;
  font-size: 11px;
  color: ${props => props.fill};
  width: 22px;
  height: 22px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
`

export const Separator = styled.hr`
  width: 98%;
  margin-top: 32px;
  margin-bottom: 28px;
`