import styled from 'styled-components'
import theme from 'ui/theme'

export const Title = styled.h6`
  font-size: 19px;
  font-weight:500;
  color:${theme().darkColor}
`

export const ButtonWrapper = styled.div`
  width: 100%;
  display:flex;
  justify-content:space-between;
  padding-top:22px;

  > button {
    > svg {
      height: 17px !important;
      margin-left: 4px;

      > path {
        fill: ${theme().white} !important;
      }
    }
  }
`

export const DateInfo = styled.p`
  color:${theme().gray600Color};
  margin:0;

  > span {
    margin-right:12px;
  }
`