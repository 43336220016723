import { CRow } from '@coreui/react-pro'
import styled from 'styled-components'


export const Wrapper = styled.div`
  width: 100%;
`

export const Row = styled(CRow)`
  --cui-gutter-y:0rem;
  margin-top:26px;
`
export const RowRadios = styled(CRow)`
  --cui-gutter-y:0rem;
  margin-top:-15px;
`

export const ButtonWrapper = styled.div`
  display:flex;
  justify-content:flex-end;
  padding-top:32px;
`