/**
 * Tripplanningsform scene
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom';
import { actions, RootState } from 'store/rootSlices'
import { CForm } from '@coreui/react-pro'
import { hasPermission } from 'permissions';
import ContainerDelete from 'shared/components/FormComponents/containerdelete';
import CustomButton from 'shared/components/CustomButton'
import theme from 'ui/theme'
import CustomCheckbox from 'shared/components/CustomCheckbox'
import Loadingcontainer from 'shared/components/Loadingcontainer'
import config from 'config';
import { ButtonWrapper } from '../styles';
import * as Styles from './styles'
import ServicesRows from './components/ServicesRows'
import VehicleRows from './components/VehiclesRows';
import CircuitsRows from './components/CircuitsRows';
import RulesRows from './components/RulesRows';
import CargosRows from './components/CargosRows';
import Modals from './components/Modals';
import { ServiceTypes } from 'types/trips/plannings';


const Tripplanningsform = ({ t, i18n }) => {

  const {
    edit,
    loading,
    dateType,
    rules,
    services,
    hasEditPermission,
    submitButtonState,
    deleteButtonState,
  } = useSelector((state: RootState) => state.TripPlanningsForm)
  const {
    onMount,
    onUnmount,
    onRulesChange,
    onSubmit,
    setShowEditDatesModal,
    setShowDeleteModal,
    setDeleteButtonState,
  } = actions.TripPlanningsForm
  const { navigateBack } = actions.App
  const { permissions } = useSelector((state: RootState) => state.App)
  const { PERMISSIONS } = config

  const dispatch = useDispatch()
  const { id } = useParams();

  useEffect(() => {

    dispatch(onMount(id))
    return () => {
      dispatch(onUnmount())
    }
  }, [dispatch, onMount, onUnmount, id]);


  if (loading) return (
    <Styles.Wrapper>
      <Loadingcontainer />
    </Styles.Wrapper>
  )

  const _renderDeleteContainer = () => {
    return edit === true && hasPermission(permissions, PERMISSIONS.TRIPS.PLANNINGS.DELETE_PLANNING) && (
      <ContainerDelete
        onClick={() => {
          if (!deleteButtonState) {
            dispatch(setShowDeleteModal(true))
            dispatch(setDeleteButtonState(true))
          }
        }}
        id='tripPlanningForm-deletePlanning'
        title={i18n.t('tripPlanningForm.removePlanning')}
        text={<p>{i18n.t('tripPlanningForm.removePlanningText')}<br />{i18n.t('common.deleteLine2')}</p>}
      >

      </ContainerDelete>
    )
  }

  const _renderCheckbox = () => { 
    return services[0]?.serviceType === ServiceTypes.ACTIVITY && (
      <CustomCheckbox
        id='tripPlanningForm-allowOverbooking'
        disabled={!hasEditPermission}
        wrapperStyle={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '-25px', fontSize: '20px' }}
        checked={rules.allowOverbooking}
        onClick={e => dispatch(onRulesChange({ ...rules, allowOverbooking: !rules.allowOverbooking }))}
        text={i18n.t('tripPlanningForm.allowOverbooking')}
        switch
      />
    )
  }

  const _renderVehiclesAndCircuits = () => { 
    return services[0]?.serviceType === ServiceTypes.ACTIVITY && (
      <>
        <VehicleRows />
        <CircuitsRows />
      </>
    )
  }

  const _renderCargos = () => services[0]?.serviceType === ServiceTypes.ACTIVITY && <CargosRows />

  return (
    <div>
      <CForm>
        { _renderCheckbox() }
        <ServicesRows />
        { _renderVehiclesAndCircuits() }
        <RulesRows />
        { _renderCargos() }
        {_renderDeleteContainer()}
        <ButtonWrapper>
          <CustomButton id='tripPlanningForm-cancel' onClick={() => dispatch(navigateBack())} text={i18n.t('common.cancelBtn')} color="link" buttonStyle={{ color: theme().danger, textDecoration: 'unset' }} />
          {
            edit ?
              hasPermission(permissions, PERMISSIONS.TRIPS.PLANNINGS.EDIT_PLANNING) && <CustomButton id='tripPlanningForm-save' text={i18n.t('common.saveBtn')} icon="confirm" onClick={e => edit && dateType !== 'single_date' ? dispatch(setShowEditDatesModal(true)) : dispatch(onSubmit())} color="success" buttonStyle={{ color: "#FFFFFF" }} />
              :
              hasPermission(permissions, PERMISSIONS.TRIPS.PLANNINGS.CREATE_PLANNING) && <CustomButton id='tripPlanningForm-save' disabled={submitButtonState} text={i18n.t('common.saveBtn')} icon="confirm" onClick={e => edit && dateType !== 'single_date' ? dispatch(setShowEditDatesModal(true)) : dispatch(onSubmit())} color="success" buttonStyle={{ color: "#FFFFFF" }} />
          }

        </ButtonWrapper>

        <Modals id={id} />
      </CForm>
    </div>
  )
}

export default Tripplanningsform