/**
 * Entriestablerow component
 *
 * @author Manuel GIl <mgil@ubiwhere.com>
 *
 *
 */

import { CTableDataCell } from '@coreui/react-pro'
import React from 'react'
import { DateTime } from 'luxon';
import config  from 'config';
import i18n from 'i18next'
import { BalanceListItem  } from "types/invoicing/invoices"
import { CustomCTableRow, ActionsCell, AmountNumber, InvoicingTypeLabel } from './styles'

type PropTypes = {
  key?: string,
  id?: string,
  entry: BalanceListItem,
  onClick: Function
}

const Entriestablerow = (props: PropTypes) => {
  // console.log('Entries in table: ', props.entry)
  const {
    amount,
    state,
    // description,
    // type,
    date,
    processedBy,
    isRefund,
    isExpense,
    balanceEntryType,
    paymentType
  } = props.entry;
  
  let movementTradution
  switch (balanceEntryType?.uid) {
    case 'supplier':
      movementTradution = i18n.t(`cashManagement.toSupplier`)
      break;
    case 'to_management':
      movementTradution = i18n.t(`cashManagement.toManagement`)
      break;
    case 'booking':
      movementTradution = i18n.t(`cashManagement.toBooking`)
      break;
    case 'fuel':
      movementTradution = i18n.t(`cashManagement.toFuel`)
      break;
    case 'maintenance':
      movementTradution = i18n.t(`cashManagement.toMaintenance`)
      break;
    default:
      movementTradution = i18n.t('invoicingSummary.movementType')
  }
  

  let paymentTradution
  switch (paymentType?.uid) {
    case 'voucher':
      paymentTradution = i18n.t(`cashManagement.inVoucher`)
      break;
    case 'dinheiro':
      paymentTradution = i18n.t(`cashManagement.inMoney`)
      break;
    case 'cartao-de-credito':
      paymentTradution = i18n.t(`cashManagement.inCard`)
      break;
    case 'restaurante':
      paymentTradution = i18n.t('cashManagement.inRestaurant')
      break;
    default:
      paymentTradution = 'N/A'
  }

  return (
    <CustomCTableRow id={`entry-${props.entry.id}`}>
      <CTableDataCell>
            {date && DateTime.fromISO(date).toFormat(config.DATE_FORMAT.DATETIME)}
      </CTableDataCell>
      <CTableDataCell>
            { isRefund ? i18n.t(`cashManagement.isRefund`) : movementTradution}
      </CTableDataCell>
      <CTableDataCell>
            {processedBy?.name}
      </CTableDataCell>
      <CTableDataCell>
            {paymentTradution}
      </CTableDataCell>
      <CTableDataCell>
            {isRefund || isExpense ? 
            <AmountNumber positive={false}> -{amount}€ </AmountNumber>
            :
            <AmountNumber positive={true}> +{amount}€ </AmountNumber>
            }
      </CTableDataCell>
      <ActionsCell>
        <InvoicingTypeLabel className={isRefund ? 'refund' : state === 'pending' ? "pending" : "paid"}>{ isRefund ? i18n.t('cashManagement.refunded') :  state === 'pending' ? i18n.t('common.pending') : i18n.t('common.paid')}</InvoicingTypeLabel>
        {/* <ChevronCell id={`${props.id}-chevron`} onClick={() => props.onClick()}><ChevronRight></ChevronRight></ChevronCell> */}
      </ActionsCell>
    </CustomCTableRow>
  )
}

export default Entriestablerow