import React from 'react';
import { useDispatch } from 'react-redux';
import { actions } from 'store/rootSlices';
import { Wrapper, Text, Button } from './styles'

const Settings = (props) => {
  const { navigateBack } = actions.App;
  const dispatch = useDispatch();

  return (
    <Wrapper>
      <Text>SETTINGS</Text>
      <Button
        onClick={() => {
          dispatch(navigateBack());
        }}
      >
        NAVIGATE BACK
      </Button>
    </Wrapper>
  );
};

export default Settings;