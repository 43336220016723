/**
 * Fleetconfigs scene sagas
 *
 * @author João Dias <jdias@ubiwhere.com>
 *
 *
 */
import { call, putResolve, select, takeLatest } from 'redux-saga/effects'
import { actions, RootState } from 'store/rootSlices'
import api from 'api';
import { toast } from 'react-toastify';
import i18n from 'i18next'
import config from 'config';
import { hasPermission } from 'permissions';
import { AnyAction } from '@reduxjs/toolkit';

const { PERMISSIONS } = config

function* onMountSaga({payload}: AnyAction) {

  yield getURLParamsSaga(payload);
  //Checking all permissions on onMountSaga because this screen has many endpoints with different permissions. 
  //Checking individual permissions on get methods
  const { permissions } = yield select((state: RootState) => state.App);


  //setting default permission for createButton
  if(hasPermission(permissions, PERMISSIONS.FLEET.EDIT_VEHICLE)){
    //yield putResolve(actions.Tripsconfiguration.setHasCreatePermission(true))
  }

  try {
    yield putResolve(actions.Fleetconfigs.getDocumentsList());
    yield putResolve(actions.Fleetconfigs.getLocationsList());
  } catch (error) {
  }
}

function* onUnmountSaga() {
  yield putResolve(actions.Fleetconfigs.setDocumentsList([]));
  yield putResolve(actions.Fleetconfigs.setLocationsList([]));
  yield putResolve(actions.Fleetconfigs.setLoading(true));
}

function* getFuelLocationsListSaga() {
  const { locationsPage, searchTerm, sort } = yield select((state: RootState) => state.Fleetconfigs);
  const { permissions } = yield select((state: RootState) => state.App);

  try {
    if(hasPermission(permissions, PERMISSIONS.FLEET.LIST_FUEL)){
      const res = yield call(api.Fleet.GetFuelLocationsPaginated, locationsPage, searchTerm, sort);
      yield putResolve(actions.Fleetconfigs.setLocationsList(res.data.results));
      yield putResolve(actions.Fleetconfigs.setLocationsTotal(res.data.count));
    }
  } catch (error) {
    toast.error(i18n.t("toasts.fuelLocationsGetError"));
  } finally {
    yield putResolve(actions.Fleetconfigs.setLoading(false));
  }
}

function* getDocumentsListSaga() {
  const { documentsPage, searchTerm, sort } = yield select((state: RootState) => state.Fleetconfigs);
  const { permissions } = yield select((state: RootState) => state.App);

  try {
    if (hasPermission(permissions, PERMISSIONS.FLEET.VIEW_VEHICLE)) {
      const res = yield call(api.Fleet.GetDocumentTypesPaginated, documentsPage, searchTerm, sort);
      yield putResolve(actions.Fleetconfigs.setDocumentsTotal(res.data.count));
      yield putResolve(actions.Fleetconfigs.setDocumentsList(res.data.results));
    }
  } catch (error) {
    toast.error(i18n.t("toasts.documentTypesGetError"));
  } finally {
    yield putResolve(actions.Fleetconfigs.setLoading(false));
  }
}

function* getURLParamsSaga(payload : AnyAction) {

  let params = new URLSearchParams(payload.location.search)

  const {
      searchTerm,
  } = yield select((state: RootState) => state.Fleetconfigs);

  if (params.toString() !== "") {
    yield putResolve(actions.Fleetconfigs.populateFiltersOnMount({
      searchTerm: params.get('search') ?? ""
    }))
  }
  else {
      params.set('search', searchTerm)
      payload.replace({ search: (params).toString() })
  }
}

export default function* watcherSignin() {
  yield takeLatest('Fleetconfigs/onMount', onMountSaga)
  yield takeLatest('Fleetconfigs/onUnmount', onUnmountSaga)
  yield takeLatest('Fleetconfigs/setSearchTerm', getDocumentsListSaga)
  yield takeLatest('Fleetconfigs/setSearchTerm', getFuelLocationsListSaga)
  yield takeLatest('Fleetconfigs/setSort', getDocumentsListSaga)
  yield takeLatest('Fleetconfigs/setSort', getFuelLocationsListSaga)
  yield takeLatest('Fleetconfigs/getDocumentsList', getDocumentsListSaga)
  yield takeLatest('Fleetconfigs/getLocationsList', getFuelLocationsListSaga)
  yield takeLatest('Fleetconfigs/setDocumentsPage', getDocumentsListSaga)
  yield takeLatest('Fleetconfigs/setLocationsPage', getFuelLocationsListSaga)
  yield takeLatest('Fleetconfigs/getURLParams', getURLParamsSaga);
}


