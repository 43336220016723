export const country_codes = [
    {
    "value": "Portugal",
    "prefix": "+351",
        "label": "PT"
    },
    {
    "value": "Afghanistan",
    "prefix": "+93",
        "label": "AF"
    },
    {
    "value": "Aland Islands",
    "prefix": "+358",
        "label": "AX"
    },
    {
    "value": "Albania",
    "prefix": "+355",
        "label": "AL"
    },
    {
    "value": "Algeria",
    "prefix": "+213",
        "label": "DZ"
    },
    {
    "value": "AmericanSamoa",
    "prefix": "+1684",
        "label": "AS"
    },
    {
    "value": "Andorra",
    "prefix": "+376",
        "label": "AD"
    },
    {
    "value": "Angola",
    "prefix": "+244",
        "label": "AO"
    },
    {
    "value": "Anguilla",
    "prefix": "+1264",
        label: "AI"
    },
    {
    "value": "Antarctica",
    "prefix": "+672",
        label: "AQ"
    },
    {
    "value": "Antigua and Barbuda",
    "prefix": "+1268",
        label: "AG"
    },
    {
    "value": "Argentina",
    "prefix": "+54",
        label: "AR"
    },
    {
    "value": "Armenia",
    "prefix": "+374",
        label: "AM"
    },
    {
    "value": "Aruba",
    "prefix": "+297",
        label: "AW"
    },
    {
    "value": "Australia",
    "prefix": "+61",
        label: "AU"
    },
    {
    "value": "Austria",
    "prefix": "+43",
        label: "AT"
    },
    {
    "value": "Azerbaijan",
    "prefix": "+994",
        label: "AZ"
    },
    {
    "value": "Bahamas",
    "prefix": "+1242",
        label: "BS"
    },
    {
    "value": "Bahrain",
    "prefix": "+973",
        label: "BH"
    },
    {
    "value": "Bangladesh",
    "prefix": "+880",
        label: "BD"
    },
    {
    "value": "Barbados",
    "prefix": "+1246",
        label: "BB"
    },
    {
    "value": "Belarus",
    "prefix": "+375",
        label: "BY"
    },
    {
    "value": "Belgium",
    "prefix": "+32",
        label: "BE"
    },
    {
    "value": "Belize",
    "prefix": "+501",
        label: "BZ"
    },
    {
    "value": "Benin",
    "prefix": "+229",
        label: "BJ"
    },
    {
    "value": "Bermuda",
    "prefix": "+1441",
        label: "BM"
    },
    {
    "value": "Bhutan",
    "prefix": "+975",
        label: "BT"
    },
    {
    "value": "Bolivia, Plurinational State of",
    "prefix": "+591",
        label: "BO"
    },
    {
    "value": "Bosnia and Herzegovina",
    "prefix": "+387",
        label: "BA"
    },
    {
    "value": "Botswana",
    "prefix": "+267",
        label: "BW"
    },
    {
    "value": "Brazil",
    "prefix": "+55",
        label: "BR"
    },
    {
    "value": "British Indian Ocean Territory",
    "prefix": "+246",
        label: "IO"
    },
    {
    "value": "Brunei Darussalam",
    "prefix": "+673",
        label: "BN"
    },
    {
    "value": "Bulgaria",
    "prefix": "+359",
        label: "BG"
    },
    {
    "value": "Burkina Faso",
    "prefix": "+226",
        label: "BF"
    },
    {
    "value": "Burundi",
    "prefix": "+257",
        label: "BI"
    },
    {
    "value": "Cambodia",
    "prefix": "+855",
        label: "KH"
    },
    {
    "value": "Cameroon",
    "prefix": "+237",
        label: "CM"
    },
    {
    "value": "Canada",
    "prefix": "+1",
        label: "CA"
    },
    {
    "value": "Cape Verde",
    "prefix": "+238",
        label: "CV"
    },
    {
    "value": "Cayman Islands",
    "prefix": "+ 345",
        label: "KY"
    },
    {
    "value": "Central African Republic",
    "prefix": "+236",
        label: "CF"
    },
    {
    "value": "Chad",
    "prefix": "+235",
        label: "TD"
    },
    {
    "value": "Chile",
    "prefix": "+56",
        label: "CL"
    },
    {
    "value": "China",
    "prefix": "+86",
        label: "CN"
    },
    {
    "value": "Christmas Island",
    "prefix": "+61",
        label: "CX"
    },
    {
    "value": "Cocos (Keeling) Islands",
    "prefix": "+61",
        label: "CC"
    },
    {
    "value": "Colombia",
    "prefix": "+57",
        label: "CO"
    },
    {
    "value": "Comoros",
    "prefix": "+269",
        label: "KM"
    },
    {
    "value": "Congo",
    "prefix": "+242",
        label: "CG"
    },
    {
    "value": "Congo, The Democratic Republic of the Congo",
    "prefix": "+243",
        label: "CD"
    },
    {
    "value": "Cook Islands",
    "prefix": "+682",
        label: "CK"
    },
    {
    "value": "Costa Rica",
    "prefix": "+506",
        label: "CR"
    },
    {
    "value": "Cote d'Ivoire",
    "prefix": "+225",
        label: "CI"
    },
    {
    "value": "Croatia",
    "prefix": "+385",
        label: "HR"
    },
    {
    "value": "Cuba",
    "prefix": "+53",
        label: "CU"
    },
    {
    "value": "Cyprus",
    "prefix": "+357",
        label: "CY"
    },
    {
    "value": "Czech Republic",
    "prefix": "+420",
        label: "CZ"
    },
    {
    "value": "Denmark",
    "prefix": "+45",
        label: "DK"
    },
    {
    "value": "Djibouti",
    "prefix": "+253",
        label: "DJ"
    },
    {
    "value": "Dominica",
    "prefix": "+1767",
        label: "DM"
    },
    {
    "value": "Dominican Republic",
    "prefix": "+1849",
        label: "DO"
    },
    {
    "value": "Ecuador",
    "prefix": "+593",
        label: "EC"
    },
    {
    "value": "Egypt",
    "prefix": "+20",
        label: "EG"
    },
    {
    "value": "El Salvador",
    "prefix": "+503",
        label: "SV"
    },
    {
    "value": "Equatorial Guinea",
    "prefix": "+240",
        label: "GQ"
    },
    {
    "value": "Eritrea",
    "prefix": "+291",
        label: "ER"
    },
    {
    "value": "Estonia",
    "prefix": "+372",
        label: "EE"
    },
    {
    "value": "Ethiopia",
    "prefix": "+251",
        label: "ET"
    },
    {
    "value": "Falkland Islands (Malvinas)",
    "prefix": "+500",
        label: "FK"
    },
    {
    "value": "Faroe Islands",
    "prefix": "+298",
        label: "FO"
    },
    {
    "value": "Fiji",
    "prefix": "+679",
        label: "FJ"
    },
    {
    "value": "Finland",
    "prefix": "+358",
        label: "FI"
    },
    {
    "value": "France",
    "prefix": "+33",
        label: "FR"
    },
    {
    "value": "French Guiana",
    "prefix": "+594",
        label: "GF"
    },
    {
    "value": "French Polynesia",
    "prefix": "+689",
        label: "PF"
    },
    {
    "value": "Gabon",
    "prefix": "+241",
        label: "GA"
    },
    {
    "value": "Gambia",
    "prefix": "+220",
        label: "GM"
    },
    {
    "value": "Georgia",
    "prefix": "+995",
        label: "GE"
    },
    {
    "value": "Germany",
    "prefix": "+49",
        label: "DE"
    },
    {
    "value": "Ghana",
    "prefix": "+233",
        label: "GH"
    },
    {
    "value": "Gibraltar",
    "prefix": "+350",
        label: "GI"
    },
    {
    "value": "Greece",
    "prefix": "+30",
        label: "GR"
    },
    {
    "value": "Greenland",
    "prefix": "+299",
        label: "GL"
    },
    {
    "value": "Grenada",
    "prefix": "+1473",
        label: "GD"
    },
    {
    "value": "Guadeloupe",
    "prefix": "+590",
        label: "GP"
    },
    {
    "value": "Guam",
    "prefix": "+1671",
        label: "GU"
    },
    {
    "value": "Guatemala",
    "prefix": "+502",
        label: "GT"
    },
    {
    "value": "Guernsey",
    "prefix": "+44",
        label: "GG"
    },
    {
    "value": "Guinea",
    "prefix": "+224",
        label: "GN"
    },
    {
    "value": "Guinea-Bissau",
    "prefix": "+245",
        label: "GW"
    },
    {
    "value": "Guyana",
    "prefix": "+595",
        label: "GY"
    },
    {
    "value": "Haiti",
    "prefix": "+509",
        label: "HT"
    },
    {
    "value": "Holy See (Vatican City State)",
    "prefix": "+379",
        label: "VA"
    },
    {
    "value": "Honduras",
    "prefix": "+504",
        label: "HN"
    },
    {
    "value": "Hong Kong",
    "prefix": "+852",
        label: "HK"
    },
    {
    "value": "Hungary",
    "prefix": "+36",
        label: "HU"
    },
    {
    "value": "Iceland",
    "prefix": "+354",
        label: "IS"
    },
    {
    "value": "India",
    "prefix": "+91",
        label: "IN"
    },
    {
    "value": "Indonesia",
    "prefix": "+62",
        label: "ID"
    },
    {
    "value": "Iran, Islamic Republic of Persian Gulf",
    "prefix": "+98",
        label: "IR"
    },
    {
    "value": "Iraq",
    "prefix": "+964",
        label: "IQ"
    },
    {
    "value": "Ireland",
    "prefix": "+353",
        label: "IE"
    },
    {
    "value": "Isle of Man",
    "prefix": "+44",
        label: "IM"
    },
    {
    "value": "Israel",
    "prefix": "+972",
        label: "IL"
    },
    {
    "value": "Italy",
    "prefix": "+39",
        label: "IT"
    },
    {
    "value": "Jamaica",
    "prefix": "+1876",
        label: "JM"
    },
    {
    "value": "Japan",
    "prefix": "+81",
        label: "JP"
    },
    {
    "value": "Jersey",
    "prefix": "+44",
        label: "JE"
    },
    {
    "value": "Jordan",
    "prefix": "+962",
        label: "JO"
    },
    {
    "value": "Kazakhstan",
    "prefix": "+77",
        label: "KZ"
    },
    {
    "value": "Kenya",
    "prefix": "+254",
        label: "KE"
    },
    {
    "value": "Kiribati",
    "prefix": "+686",
        label: "KI"
    },
    {
    "value": "Korea, Democratic People's Republic of Korea",
    "prefix": "+850",
        label: "KP"
    },
    {
    "value": "Korea, Republic of South Korea",
    "prefix": "+82",
        label: "KR"
    },
    {
    "value": "Kuwait",
    "prefix": "+965",
        label: "KW"
    },
    {
    "value": "Kyrgyzstan",
    "prefix": "+996",
        label: "KG"
    },
    {
    "value": "Laos",
    "prefix": "+856",
        label: "LA"
    },
    {
    "value": "Latvia",
    "prefix": "+371",
        label: "LV"
    },
    {
    "value": "Lebanon",
    "prefix": "+961",
        label: "LB"
    },
    {
    "value": "Lesotho",
    "prefix": "+266",
        label: "LS"
    },
    {
    "value": "Liberia",
    "prefix": "+231",
        label: "LR"
    },
    {
    "value": "Libyan Arab Jamahiriya",
    "prefix": "+218",
        label: "LY"
    },
    {
    "value": "Liechtenstein",
    "prefix": "+423",
        label: "LI"
    },
    {
    "value": "Lithuania",
    "prefix": "+370",
        label: "LT"
    },
    {
    "value": "Luxembourg",
    "prefix": "+352",
        label: "LU"
    },
    {
    "value": "Macao",
    "prefix": "+853",
        label: "MO"
    },
    {
    "value": "Macedonia",
    "prefix": "+389",
        label: "MK"
    },
    {
    "value": "Madagascar",
    "prefix": "+261",
        label: "MG"
    },
    {
    "value": "Malawi",
    "prefix": "+265",
        label: "MW"
    },
    {
    "value": "Malaysia",
    "prefix": "+60",
        label: "MY"
    },
    {
    "value": "Maldives",
    "prefix": "+960",
        label: "MV"
    },
    {
    "value": "Mali",
    "prefix": "+223",
        label: "ML"
    },
    {
    "value": "Malta",
    "prefix": "+356",
        label: "MT"
    },
    {
    "value": "Marshall Islands",
    "prefix": "+692",
        label: "MH"
    },
    {
    "value": "Martinique",
    "prefix": "+596",
        label: "MQ"
    },
    {
    "value": "Mauritania",
    "prefix": "+222",
        label: "MR"
    },
    {
    "value": "Mauritius",
    "prefix": "+230",
        label: "MU"
    },
    {
    "value": "Mayotte",
    "prefix": "+262",
        label: "YT"
    },
    {
    "value": "Mexico",
    "prefix": "+52",
        label: "MX"
    },
    {
    "value": "Micronesia, Federated States of Micronesia",
    "prefix": "+691",
        label: "FM"
    },
    {
    "value": "Moldova",
    "prefix": "+373",
        label: "MD"
    },
    {
    "value": "Monaco",
    "prefix": "+377",
        label: "MC"
    },
    {
    "value": "Mongolia",
    "prefix": "+976",
        label: "MN"
    },
    {
    "value": "Montenegro",
    "prefix": "+382",
        label: "ME"
    },
    {
    "value": "Montserrat",
    "prefix": "+1664",
        label: "MS"
    },
    {
    "value": "Morocco",
    "prefix": "+212",
        label: "MA"
    },
    {
    "value": "Mozambique",
    "prefix": "+258",
        label: "MZ"
    },
    {
    "value": "Myanmar",
    "prefix": "+95",
        label: "MM"
    },
    {
    "value": "Namibia",
    "prefix": "+264",
        label: "NA"
    },
    {
    "value": "Nauru",
    "prefix": "+674",
        label: "NR"
    },
    {
    "value": "Nepal",
    "prefix": "+977",
        label: "NP"
    },
    {
    "value": "Netherlands",
    "prefix": "+31",
        label: "NL"
    },
    {
    "value": "Netherlands Antilles",
    "prefix": "+599",
        label: "AN"
    },
    {
    "value": "New Caledonia",
    "prefix": "+687",
        label: "NC"
    },
    {
    "value": "New Zealand",
    "prefix": "+64",
        label: "NZ"
    },
    {
    "value": "Nicaragua",
    "prefix": "+505",
        label: "NI"
    },
    {
    "value": "Niger",
    "prefix": "+227",
        label: "NE"
    },
    {
    "value": "Nigeria",
    "prefix": "+234",
        label: "NG"
    },
    {
    "value": "Niue",
    "prefix": "+683",
        label: "NU"
    },
    {
    "value": "Norfolk Island",
    "prefix": "+672",
        label: "NF"
    },
    {
    "value": "Northern Mariana Islands",
    "prefix": "+1670",
        label: "MP"
    },
    {
    "value": "Norway",
    "prefix": "+47",
        label: "NO"
    },
    {
    "value": "Oman",
    "prefix": "+968",
        label: "OM"
    },
    {
    "value": "Pakistan",
    "prefix": "+92",
        label: "PK"
    },
    {
    "value": "Palau",
    "prefix": "+680",
        label: "PW"
    },
    {
    "value": "Palestinian Territory, Occupied",
    "prefix": "+970",
        label: "PS"
    },
    {
    "value": "Panama",
    "prefix": "+507",
        label: "PA"
    },
    {
    "value": "Papua New Guinea",
    "prefix": "+675",
        label: "PG"
    },
    {
    "value": "Paraguay",
    "prefix": "+595",
        label: "PY"
    },
    {
    "value": "Peru",
    "prefix": "+51",
        label: "PE"
    },
    {
    "value": "Philippines",
    "prefix": "+63",
        label: "PH"
    },
    {
    "value": "Pitcairn",
    "prefix": "+872",
        label: "PN"
    },
    {
    "value": "Poland",
    "prefix": "+48",
        label: "PL"
    },
    {
    "value": "Puerto Rico",
    "prefix": "+1939",
        label: "PR"
    },
    {
    "value": "Qatar",
    "prefix": "+974",
        label: "QA"
    },
    {
    "value": "Romania",
    "prefix": "+40",
        label: "RO"
    },
    {
    "value": "Russia",
    "prefix": "+7",
        label: "RU"
    },
    {
    "value": "Rwanda",
    "prefix": "+250",
        label: "RW"
    },
    {
    "value": "Reunion",
    "prefix": "+262",
        label: "RE"
    },
    {
    "value": "Saint Barthelemy",
    "prefix": "+590",
        label: "BL"
    },
    {
    "value": "Saint Helena, Ascension and Tristan Da Cunha",
    "prefix": "+290",
        label: "SH"
    },
    {
    "value": "Saint Kitts and Nevis",
    "prefix": "+1869",
        label: "KN"
    },
    {
    "value": "Saint Lucia",
    "prefix": "+1758",
        label: "LC"
    },
    {
    "value": "Saint Martin",
    "prefix": "+590",
        label: "MF"
    },
    {
    "value": "Saint Pierre and Miquelon",
    "prefix": "+508",
        label: "PM"
    },
    {
    "value": "Saint Vincent and the Grenadines",
    "prefix": "+1784",
        label: "VC"
    },
    {
    "value": "Samoa",
    "prefix": "+685",
        label: "WS"
    },
    {
    "value": "San Marino",
    "prefix": "+378",
        label: "SM"
    },
    {
    "value": "Sao Tome and Principe",
    "prefix": "+239",
        label: "ST"
    },
    {
    "value": "Saudi Arabia",
    "prefix": "+966",
        label: "SA"
    },
    {
    "value": "Senegal",
    "prefix": "+221",
        label: "SN"
    },
    {
    "value": "Serbia",
    "prefix": "+381",
        label: "RS"
    },
    {
    "value": "Seychelles",
    "prefix": "+248",
        label: "SC"
    },
    {
    "value": "Sierra Leone",
    "prefix": "+232",
        label: "SL"
    },
    {
    "value": "Singapore",
    "prefix": "+65",
        label: "SG"
    },
    {
    "value": "Slovakia",
    "prefix": "+421",
        label: "SK"
    },
    {
    "value": "Slovenia",
    "prefix": "+386",
        label: "SI"
    },
    {
    "value": "Solomon Islands",
    "prefix": "+677",
        label: "SB"
    },
    {
    "value": "Somalia",
    "prefix": "+252",
        label: "SO"
    },
    {
    "value": "South Africa",
    "prefix": "+27",
        label: "ZA"
    },
    {
    "value": "South Sudan",
    "prefix": "+211",
        label: "SS"
    },
    {
    "value": "South Georgia and the South Sandwich Islands",
    "prefix": "+500",
        label: "GS"
    },
    {
    "value": "Spain",
    "prefix": "+34",
        label: "ES"
    },
    {
    "value": "Sri Lanka",
    "prefix": "+94",
        label: "LK"
    },
    {
    "value": "Sudan",
    "prefix": "+249",
        label: "SD"
    },
    {
    "value": "Surivalue",
    "prefix": "+597",
        label: "SR"
    },
    {
    "value": "Svalbard and Jan Mayen",
    "prefix": "+47",
        label: "SJ"
    },
    {
    "value": "Swaziland",
    "prefix": "+268",
        label: "SZ"
    },
    {
    "value": "Sweden",
    "prefix": "+46",
        label: "SE"
    },
    {
    "value": "Switzerland",
    "prefix": "+41",
        label: "CH"
    },
    {
    "value": "Syrian Arab Republic",
    "prefix": "+963",
        label: "SY"
    },
    {
    "value": "Taiwan",
    "prefix": "+886",
        label: "TW"
    },
    {
    "value": "Tajikistan",
    "prefix": "+992",
        label: "TJ"
    },
    {
    "value": "Tanzania, United Republic of Tanzania",
    "prefix": "+255",
        label: "TZ"
    },
    {
    "value": "Thailand",
    "prefix": "+66",
        label: "TH"
    },
    {
    "value": "Timor-Leste",
    "prefix": "+670",
        label: "TL"
    },
    {
    "value": "Togo",
    "prefix": "+228",
        label: "TG"
    },
    {
    "value": "Tokelau",
    "prefix": "+690",
        label: "TK"
    },
    {
    "value": "Tonga",
    "prefix": "+676",
        label: "TO"
    },
    {
    "value": "Trinidad and Tobago",
    "prefix": "+1868",
        label: "TT"
    },
    {
    "value": "Tunisia",
    "prefix": "+216",
        label: "TN"
    },
    {
    "value": "Turkey",
    "prefix": "+90",
        label: "TR"
    },
    {
    "value": "Turkmenistan",
    "prefix": "+993",
        label: "TM"
    },
    {
    "value": "Turks and Caicos Islands",
    "prefix": "+1649",
        label: "TC"
    },
    {
    "value": "Tuvalu",
    "prefix": "+688",
        label: "TV"
    },
    {
    "value": "Uganda",
    "prefix": "+256",
        label: "UG"
    },
    {
    "value": "Ukraine",
    "prefix": "+380",
        label: "UA"
    },
    {
    "value": "United Arab Emirates",
    "prefix": "+971",
        label: "AE"
    },
    {
    "value": "United Kingdom",
    "prefix": "+44",
        label: "GB"
    },
    {
    "value": "United States",
    "prefix": "+1",
        label: "US"
    },
    {
    "value": "Uruguay",
    "prefix": "+598",
        label: "UY"
    },
    {
    "value": "Uzbekistan",
    "prefix": "+998",
        label: "UZ"
    },
    {
    "value": "Vanuatu",
    "prefix": "+678",
        label: "VU"
    },
    {
    "value": "Venezuela, Bolivarian Republic of Venezuela",
    "prefix": "+58",
        label: "VE"
    },
    {
    "value": "Vietnam",
    "prefix": "+84",
        label: "VN"
    },
    {
    "value": "Virgin Islands, British",
    "prefix": "+1284",
        label: "VG"
    },
    {
    "value": "Virgin Islands, U.S.",
    "prefix": "+1340",
        label: "VI"
    },
    {
    "value": "Wallis and Futuna",
    "prefix": "+681",
        label: "WF"
    },
    {
    "value": "Yemen",
    "prefix": "+967",
        label: "YE"
    },
    {
    "value": "Zambia",
    "prefix": "+260",
        label: "ZM"
    },
    {
    "value": "Zimbabwe",
    "prefix": "+263",
        label: "ZW"
    }
    ]