import axios from 'axios'
import endpoints from 'api/endpoints'
import { decamelizeKeys } from 'humps'
import { generateUrl, generateSortString } from 'utils'
import { toast } from 'react-toastify'
import i18n from 'i18next';
import { SortType } from 'types/misc'

const GetAllPlannings = () => {
    return axios.get(endpoints.TRIPS.PLANNINGS.GET_PLANNINGS)
        .then(res => res.data)
        .catch(err => { throw err })
}

const GetPlannings = (page: number | string, showEndedExits: boolean, dateAfter?: string, dateBefore?: string, vehicle?: string, activity?: string, departure?: string, arrival?: string, isTransport?: string, isBeach?: boolean) => {
    const isTransportQS = isTransport ? `&show_transport=${isTransport}` : ``
    const arrivalQS = arrival ? `&arrival=${arrival}` : ``
    const departureQS = departure ? `&departure=${departure}` : ``
    const activityQS = activity ? `&activity=${activity}` : ``
    const vehicleQS = vehicle ? `&vehicle=${vehicle}` : ``
    const beachQs = isBeach !== undefined ? `&is_beach=${isBeach}` : '' 
    const dateBeforeQS = `&date_before=${dateBefore}`
    const dateAfterQS = `&date_after=${dateAfter}`
    const showEndedExitsQS = `&show_finished=${showEndedExits}`

    const endpoint = `${endpoints.TRIPS.PLANNINGS.GET_PLANNINGS}?sort_by=start_time&page_size=${10}&page=${page}${showEndedExitsQS}${dateAfterQS}${dateBeforeQS}${vehicleQS}${activityQS}${departureQS}${arrivalQS}${isTransportQS}${beachQs}`
    return axios.get(endpoint)
        .then(res => res.data)
        .catch(err => { throw err })
}

const GetPlanning = (id) => {
    const endpoint = generateUrl(endpoints.TRIPS.PLANNINGS.GET_PLANNING, { id })
    return axios.get(endpoint)
        .then(res => res.data)
        .catch(err => { throw err })
}

const GetTripDetails = (id) => {
    const endpoint = generateUrl(endpoints.TRIPS.PLANNINGS.GET_TRIP_DETAILS, { id })
    return axios.get(endpoint)
        .then(res => res.data)
        .catch(err => { throw err })
}

const GetBookingsByTrip = (tripId: number, search: string, isOutbound: boolean, vehicle?: string, stop?: number) => {

    const tripQS = `?trip=${tripId}`
    const searchQS = `&search=${search}`
    const vehicleQS = vehicle ? `&vehicle=${vehicle}` : ``
    const stopQS = stop ? `&stop=${stop}` : ``
    const isOutboundQs = `&is_outbound=${isOutbound}`


    const endpoint = `${endpoints.TRIPS.PLANNINGS.GET_BOOKINGS_BY_TRIP}${tripQS}${searchQS}${isOutboundQs}${vehicleQS}${stopQS}`

    return axios.get(endpoint)
        .then(res => res.data)
        .catch(err => { throw err })
}

const GetEligibleBookingsByTrip = (tripId: number, isOutbound) => {

    const tripQS = `?trip=${tripId}`
    const outboundQS = `&is_outbound=${isOutbound}`
    const endpoint = `${endpoints.TRIPS.PLANNINGS.GET_ELIGIBLE_BOOKINGS_BY_TRIP}${tripQS}${outboundQS}`

    return axios.get(endpoint)
        .then(res => res.data)
        .catch(err => { throw err })
}

const CreatePlanning = async (params) => {
    const token = window.localStorage.getItem('access_token')
    const decMLZParams = decamelizeKeys(params)

    // use toast promise when call takes to long  
    return await toast.promise(
        axios.create({
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
            }
        }).post(endpoints.TRIPS.PLANNINGS.CREATE_PLANNING, decMLZParams)
            .then(res => res.data)
            .catch(err => { throw err }),
        {
            pending: i18n.t("toasts.creatingPlanning"),
            success: i18n.t("toasts.createPlanningSuccess"),
            error: i18n.t("toasts.createPlanningError")
        }
    )
}

const EditPlanning = async (params, id) => {
    const token = window.localStorage.getItem('access_token')
    const decMLZParams = decamelizeKeys(params)

    const endpoint = generateUrl(endpoints.TRIPS.PLANNINGS.EDIT_PLANNING, { id })

    // use toast promise when call takes to long  
    return await toast.promise(
        axios.create({
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
            }
        }).patch(endpoint, decMLZParams)
            .then(res => res.data)
            .catch(err => { throw err }),
        {
            pending: i18n.t("tripPlanningForm.updatingPlanning"),
            success: i18n.t("toasts.editPlanningSuccess"),
            error: i18n.t("toasts.editPlanningError")
        }
    )
}

const DeletePlanning = async (id, params) => {
    const token = window.localStorage.getItem('access_token')
    const decMLZParams = decamelizeKeys(params)
    const endpoint = generateUrl(endpoints.TRIPS.PLANNINGS.DELETE_PLANNING, { id })

    return axios.create({
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
        }
    }).delete(endpoint, { data: decMLZParams })
        .then(res => res.data)
        .catch(err => { throw err })
}

const AddReinforcement = (params, id) => {
    const token = window.localStorage.getItem('access_token')
    const endpoint = generateUrl(endpoints.TRIPS.PLANNINGS.ADD_REINFORCEMENT, { id })
    const decMLZParams = decamelizeKeys(params)

    return axios.create({
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
        }
    }).post(endpoint, decMLZParams)
        .then(res => res)
        .catch(err => { throw err })
}

const ManageVehicle = (params, id: string) => {
    const token = window.localStorage.getItem('access_token')
    const endpoint = generateUrl(endpoints.TRIPS.PLANNINGS.MANAGE_VEHICLE, { id })
    const decMLZParams = decamelizeKeys(params)

    return axios.create({
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
        }
    }).post(endpoint, decMLZParams)
        .then(res => res)
        .catch(err => { throw err })
}

const RemoveVehicle = (params, id: string) => {
    const token = window.localStorage.getItem('access_token')
    const endpoint = generateUrl(endpoints.TRIPS.PLANNINGS.REMOVE_VEHICLE, { id })
    const decMLZParams = decamelizeKeys(params)

    return axios.create({
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
        }
    }).post(endpoint, decMLZParams)
        .then(res => res)
        .catch(err => { throw err })
}

const ReplaceVehicle = (params, id: string) => {
    const token = window.localStorage.getItem('access_token')
    const endpoint = generateUrl(endpoints.TRIPS.PLANNINGS.REPLACE_VEHICLE, { id })
    const decMLZParams = decamelizeKeys(params)

    return axios.create({
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
        }
    }).post(endpoint, decMLZParams)
        .then(res => res)
        .catch(err => { throw err })
}

const GetAllLegs = (
    page: number,
    showFinished: boolean,
    sort: SortType,
    pageSize: number,
    dateAfter?: string,
    dateBefore?: string,
    timeAfter?: string,
    timeBefore?: string,
    activity?: string,
    departure?: string,
    arrival?: string,
    direction?: string,
    passengerTypes?: [],
    availability?: number,
    outboundCategoryFilter?: number,
    isBeach?: boolean
) => {

    const activityQS = `${activity ? `&activity=${activity}` : ''}`;
    const categoryQS = `${outboundCategoryFilter ? `&category=${outboundCategoryFilter}` : ''}`;
    const departureQS = `${departure ? `&departure=${departure}` : ''}`;
    const arrivalQS = `${arrival ? `&arrival=${arrival}` : ''}`;
    const dateQS = `${dateAfter && dateBefore ? `&date_before=${dateBefore}&date_after=${dateAfter}` : ''}`;
    const timeQS = `${timeAfter && timeBefore ? `&time_before=${timeBefore}&time_after=${timeAfter}` : ''}`;
    const directionQS = `${direction ? `&direction=${direction}` : ''}`;
    const pageSizeQS = `${pageSize ? `&page_size=${pageSize}` : ''}`;
    const sortQS = sort.field && sort.direction ? generateSortString(sort) : ''
    const availabilityQS = `${availability && availability > 0 ? `&available_tickets=${availability}` : ''}`;
    const pTypesQS = `${passengerTypes && passengerTypes.length > 0 ? '&passenger_type=' + passengerTypes.join('&passenger_type=') : ''}`;
    const isBeachQS = `${isBeach ? `&is_beach=${isBeach}` : ''}`;

    const endpoint = `
        ${endpoints.TRIPS.PLANNINGS.GET_LEGS}?page=${page}&show_finished=${showFinished}${pageSizeQS}${sortQS}${activityQS}${departureQS}${arrivalQS}${dateQS}${timeQS}${directionQS}${availabilityQS}${pTypesQS}${categoryQS}${isBeachQS}
    `;


    return axios.get(endpoint)
        .then(res => res.data)
        .catch(err => {
            toast.error(i18n.t("toasts.legsGetError"))
        });
};

const GetBeachTrip = (
    page: number,
    showFinished: boolean,
    sort: SortType,
    pageSize: number,
    dateAfter?: string,
    dateBefore?: string,
    activity?: string,
    availability?: number,
    isBeach?: boolean
) => {

    const activityQS = `${activity ? `&activity=${activity}` : ''}`;
    const dateQS = `${dateAfter && dateBefore ? `&date_before=${dateBefore}&date_after=${dateAfter}` : ''}`;
    const pageSizeQS = `${pageSize ? `&page_size=${pageSize}` : ''}`;
    const sortQS = sort.field && sort.direction ? generateSortString(sort) : ''
    const availabilityQS = `${availability && availability > 0 ? `&available_tickets=${availability}` : ''}`;
    const isBeachQS = `${isBeach ? `&is_beach=${isBeach}` : ''}`;

    const endpoint = `
        ${endpoints.TRIPS.PLANNINGS.GET_LEGS}?page=${page}&show_finished=${showFinished}${pageSizeQS}${sortQS}${activityQS}${dateQS}${availabilityQS}${isBeachQS}
    `;


    return axios.get(endpoint)
        .then(res => res.data)
        .catch(err => {
            toast.error(i18n.t("toasts.beachTripGetError"))
        });
};

const GetPlanningLegs = (id) => {
    const endpoint = generateUrl(endpoints.TRIPS.PLANNINGS.GET_PLANNING_LEGS, { id })

    return axios.get(endpoint)
        .then(res => res.data)
        .catch(err => {
            toast.error(i18n.t("toasts.planningLegsGetError"))
        });
};

const EditPlanningLegs = (id, params) => {
    const token = window.localStorage.getItem('access_token')
    const endpoint = generateUrl(endpoints.TRIPS.PLANNINGS.EDIT_PLANNING_LEGS, { id })
    const decMLZParams = decamelizeKeys(params)

    return axios.create({
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
        }
    }).post(endpoint, decMLZParams)
        .then(res => res)
        .catch(err => { throw err })
}

export default {
    GetAllPlannings,
    GetPlannings,
    GetPlanning,
    GetTripDetails,
    GetBookingsByTrip,
    CreatePlanning,
    AddReinforcement,
    EditPlanning,
    GetAllLegs,
    GetBeachTrip,
    ManageVehicle,
    RemoveVehicle,
    ReplaceVehicle,
    GetEligibleBookingsByTrip,
    DeletePlanning,
    GetPlanningLegs,
    EditPlanningLegs
}
