import axios from 'axios'
import endpoints from 'api/endpoints'
import { decamelizeKeys  } from 'humps'
import { PassengerTypeValidations } from 'scenes/TripForms/Trippassengertypeform/utils'
import { generateUrl, generateSortString } from 'utils'
import { SortType } from 'types/misc'
/* import { generateUrl } from 'utils'
import { toast } from 'react-toastify'
import i18n from 'i18next' */

const GetPassengerTypes = (page: number, searchTerm: string, sort?: SortType) => {
    const searchTermQS = `${searchTerm ? `&search=${searchTerm}` : ''}`;
    
    let endpoint = `${endpoints.TRIPS.PASSENGER_TYPES.GET_PASSENGER_TYPES}?page=${page}${searchTermQS}`;
    
    if (sort && sort.field !== null && sort.direction !== null && sort.direction !== "") {
        endpoint += generateSortString(sort);
    }
    
    return axios.get(endpoint)
        .then(res => res.data)
        .catch(err => {
        })
}

const GetAllPassengerTypes = () => {
    return axios.get(endpoints.TRIPS.PASSENGER_TYPES.GET_ALL_PASSENGER_TYPES)
        .then(res => res.data)
        .catch(err => {
            throw err
        })
}

const GetPassengerType = (id) => {
    const endpoint = generateUrl(endpoints.TRIPS.PASSENGER_TYPES.GET_PASSENGER_TYPE, { id })
    return axios.get(endpoint)
        .then(res => res.data)
        .catch(err => {
        })
}


const CreatePassengerType = (params) => {
    const decMLZParams = decamelizeKeys(params)
    
    const passengerTypeFormData = new FormData();
    Object.keys(decamelizeKeys(PassengerTypeValidations)).forEach(valKey => {
        //console.log(valKey, decMLZParams[valKey].value);
        passengerTypeFormData.append(valKey, decMLZParams[valKey].value)
    })
    return axios.post(endpoints.TRIPS.PASSENGER_TYPES.CREATE_PASSENGER_TYPE, passengerTypeFormData)
        .then(res => res.data)
        .catch(err => { throw err })
}



const EditPassengerType = (id, params) => {
    const decMLZParams = decamelizeKeys(params)
    const endpoint = generateUrl(endpoints.TRIPS.PASSENGER_TYPES.EDIT_PASSENGER_TYPE, { id })
    
    const passengerTyepFormData = new FormData();
    Object.keys(decamelizeKeys(PassengerTypeValidations)).forEach(valKey => {
        passengerTyepFormData.append(valKey, decMLZParams[valKey].value)
    })

    return axios.patch(endpoint, passengerTyepFormData)
        .then(res => res.data)
        .catch(err => { throw err })  
}


export default {
    GetAllPassengerTypes,
    GetPassengerTypes,
    GetPassengerType,
    CreatePassengerType,
    EditPassengerType,
}