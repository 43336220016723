/**
 * Configurations scene utils
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */

 import * as Check from 'validations';

 export const ConfigurationsValidation = {
    fee: [Check.isRequired, Check.isNumber],
    timeNoFee: [Check.isRequired, Check.isCorrectTimeFormat],
    timeLimit: [Check.isRequired, Check.isCorrectTimeFormat]
}

export const DefaultCommissionsValidations = {
    commission: [],
}
