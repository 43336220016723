import { CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle } from '@coreui/react-pro'
import React from 'react'
import i18n from 'i18next';
import { useSelector } from 'react-redux'
// Components
import Loadingcontainer from 'shared/components/Loadingcontainer'
import UnitsHeaderRow from '../UnitsHeaderRow';
import StopsHeaderRow from '../StopsHeaderRow';
import PassengersCell from '../PassengersCell';
import StopsCell from '../StopsCell';
// Icons
import Phone from 'assets/icons/phone'
import MoreVert from 'assets/icons/more_vert'
import NoPayement from 'assets/icons/no_payement'
// Store
import { RootState } from 'store/rootSlices';
// Types
import { Vehicles } from 'types/trips/plannings'
// Styles
import theme from 'ui/theme'
import { NoResultsCell, CustomCTableBody, CustomCTableRow } from '../../styles'
import { Table } from './styles'
import { TableDataCell, Tooltip, Text, TooltipWrapper, Icon, CustomSpan, BookingInfo, PAXDiv } from '../styles'



type PropTypes = React.PropsWithChildren<{
  tripBookings?: any,
  totalBookings?: any,
  planningOutStops?: any,
  planningInStops?: any,
  tripUnits: Vehicles[],
}>

const BookingsTable = (props: PropTypes) => {
  const {
    tripBookings,
    totalBookings,
    planningOutStops,
    planningInStops,
    tripUnits
  } = props

  const { allPassengerTypes, allLocals } = useSelector((state: RootState) => state.TripsConfiguration)
  const { 
    modalLoading, 
    checkinOutboundSelected,
  } = useSelector((state: RootState) => state.TripPlannings);

  const _renderLoader = () => {
    return modalLoading ? (
      <CustomCTableRow><NoResultsCell colSpan={16} ><Loadingcontainer /></NoResultsCell></CustomCTableRow>
    ) : <></>
  }

  const _renderNoResults = () => {
    return tripBookings?.length <= 0 && (
      <CustomCTableRow><NoResultsCell colSpan={10} >{i18n.t('common.noResults')}</NoResultsCell></CustomCTableRow>
    )
  }

  const _renderContent = () => {
    return tripBookings?.length > 0 && tripBookings?.map((res, idx) => (
      <CustomCTableRow key={`custom-bookings-table-row-idx-${idx}`}>
        <TableDataCell
          colSpan={1}
          style={{ width: '10%', fontWeight: '700' }}
          color={`${res.outboundTrip?.tripActivityLeg.activity.color}`}
        >
          {
            `${res.outboundTrip?.tripActivityLeg ? res.outboundTrip?.tripActivityLeg.activity.shortName : ``}
              ${res.inboundTrip?.tripActivityLeg ? ` + ${res.inboundTrip?.tripActivityLeg.activity.shortName}` : ``}`
          }
        </TableDataCell>
        <TableDataCell
          colSpan={1} 
          style={{ width: '11%' }}
          color={`${res.hasPendingEntries ? theme().danger : null}`}
        >
          {res.uid}
        </TableDataCell>
        <TableDataCell
          colSpan={1} 
          style={{ width: '4%' }}
          color={`${res.hasPendingEntries ? theme().danger : null}`}
        >
          <Tooltip>
            <Icon marginr='5'>
              <Phone fill={`${res.hasPendingEntries ? theme().danger : theme().gray500Color}`}  />
            </Icon>
            {
              res.customer?.email && <CustomSpan>{res.customer?.email}</CustomSpan>
            }
          </Tooltip>
        </TableDataCell>
        <TableDataCell 
          colSpan={1}
          style={{ width: '20%' }}
          color={`${res.hasPendingEntries ? theme().danger : null}`}
        >
          <BookingInfo>
            <Text weight='400'>{ res.customerName }</Text>
            <TooltipWrapper>
              {
                res.hasPendingEntries && <Tooltip>
                  <NoPayement />
                  <CustomSpan>{i18n.t('tripPlannings.noPayment')}</CustomSpan>
                </Tooltip>
              }
            </TooltipWrapper>
          </BookingInfo>
        </TableDataCell>
        <TableDataCell
          colSpan={1} 
          style={{ width: '6%' }}
        >
          <PAXDiv>
            <Text weight='400'>PAX</Text>
            <Text weight='700'>{res.seats}</Text>
          </PAXDiv>
        </TableDataCell>
        {
          allPassengerTypes?.map((pt, idx) => (
            <PassengersCell 
              key={`custom-bookings-table-row-passenger-cell-idx-${idx}`}
              reservation={res}
              passengerType={pt}
            />
          ))
        }
        {
          allLocals.map((st, idx) => (
            <StopsCell 
              key={`bookings-table-stops-cell-idx-${idx}-id-${st.id}`}
              outbound={checkinOutboundSelected}
              stop={st}
              planningOutStops={planningOutStops}
              planningInStops={planningInStops}
              reservation={res}
              isElegible={false}
            />
          ))
        }
        <TableDataCell style={{ width: '5%' }} colSpan={1}>
          <CDropdown>
            <CDropdownToggle color='noColor' caret={false}><Icon marginr='5'><MoreVert fill={theme().gray500Color} /></Icon></CDropdownToggle>
            <CDropdownMenu>
              <CDropdownItem rel="noopener noreferrer" target="_blank" href={`/viagens/reservas/editar-reserva/${res.uuid}`}>{i18n.t('tripPlannings.checkBooking')}</CDropdownItem>
            </CDropdownMenu>
          </CDropdown>
        </TableDataCell>
      </CustomCTableRow>
    ))
  }

  return (
    <Table>
      <UnitsHeaderRow tripUnits={tripUnits} />
        
      <StopsHeaderRow tripBookings={tripBookings} totalBookings={totalBookings} />

      <CustomCTableBody expanded={true}>
        { _renderContent() }
        { _renderNoResults() } 
        { _renderLoader() }
      </CustomCTableBody>
    </Table>
  )
}

export default BookingsTable