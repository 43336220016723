import styled from 'styled-components'
import { CustomTableRow } from 'shared/genericStyledComponents'
import theme from 'ui/theme';

const CustomCTableRow = styled(CustomTableRow) <{ overlay: boolean }>`
  > td {
    .badge {
      color: ${theme().white};
    }

    color: ${props => props.overlay ? theme().gray200Color : theme().gray700Color};
    > * {
      color: ${props => props.overlay ? theme().gray200Color : theme().gray700Color};
    }
  }
`

const Text = styled.p`
  margin: 0;
`

export {
  Text,
  CustomCTableRow
}