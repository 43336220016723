/**
 * Maintenanceform scene slice
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */
import { createSlice } from '@reduxjs/toolkit'
import { MaintenanceForm, MaintenanceListItem } from 'types/fleet/maintenance'
import * as Check from 'validations';
import { VehicleListItem } from 'types/fleet/vehicle'
import { defaultForm, MaintenanceFormValidationsIsFinished, MaintenanceFormValidationsUnfinished, defaultMaintenance } from '../utils'

interface InitialState {
    isEdit: boolean,
    loading: boolean,
    deleteModal: boolean,
    maintenanceForm: MaintenanceForm,
    maintenance: MaintenanceListItem,
    fleet: VehicleListItem[],
    documents: any[],
    newDocumentsID: number,
    hasEditPermission: boolean,
    submitButtonState: boolean
}

const initialState: InitialState = {
    loading: true,
    isEdit: false,
    deleteModal: false,
    maintenanceForm: defaultForm,
    documents: [],
    newDocumentsID: 99,
    maintenance: defaultMaintenance,
    fleet:[],
    hasEditPermission: true,
    submitButtonState: false
}

export default createSlice({
    name: 'fleetMaintenanceForm',
    initialState,
    reducers: {
        onMount: (state, { payload }) => { },
        onUnmount: (state, { payload }) => { },
        showDeleteModal: (state, { payload }) => { state.deleteModal = payload },
        setEditMode: (state, { payload }) => {
            state.isEdit = payload;
        },
        requestMaintenance: (state, { payload }) => { },
        setLoading: (state, { payload }) => {
            state.loading = payload
        },
        setHasEditPermission: (state, { payload }) => {
            state.hasEditPermission = payload
        },
        setSubmitButtonDisabled:(state, { payload }) => {
            state.submitButtonState = payload
          },
        setMaintenance: (state, { payload }) => {
            state.maintenance = payload;
        },
        setFleet: (state, { payload }) => {
            state.fleet = payload
        },
        setForm: (state, { payload }) => {
            state.maintenanceForm = payload;
        },
        onSubmit: () => { },
        deleteMaintenance: () => { },
        onFormChange: (state, { payload }) => {
            const val = state.maintenanceForm.isFinished.value ? MaintenanceFormValidationsIsFinished : MaintenanceFormValidationsUnfinished;
            state.maintenanceForm = Check.setAndCheckValidation(state.maintenanceForm, payload, val);
        },
        createEntryDocument: (state, { payload }) => {},
        addToDocumentsArray: (state, { payload }) => {
            const document = payload.e
            const documents: any[] = Array.from(payload.documents)
      
            documents.push({
              id: payload.newDocumentsID + 1,
              movement: null,
              file: document,
              new: true,
              edited: false,
              sort: documents.length + 1
            })
      
            state.newDocumentsID = payload.newDocumentsID + 1
            state.documents = documents
        },
        deleteDocumentsFromArray: (state, { payload }) => {
            const items: any[] = Array.from(payload.documents)
      
            items.splice(items.indexOf(items[payload.index]), 1)
      
            const deletedDocument = items.map((doc, idx) => {
              if (doc.sort !== idx + 1) {
                return {
                  ...doc,
                  edited: true,
                  sort: idx + 1
                }
              }
              else {
                return {
                  ...doc,
                  sort: idx + 1
                }
              }
            })
      
            state.documents = deletedDocument
        },
        populateDocumentsForm: (state, { payload }) => {
          const documents: any[] = Array.from([])
    
          payload.documents.forEach((doc) => {
              documents.push({
              statement: payload.movementID,
              id: doc.id,
              file: doc.file,
              edited: false,
              new: false,
              sort: doc.sort
            })
          })
    
          state.documents = documents.sort((a, b) => { return a.sort - b.sort })
        },
        reOrderDocsArray: (state, { payload }) => {
          if (!payload.e.destination) return;
          const items: any[] = Array.from(payload.documents)
    
    
          const [reOrderedItem] = items.splice(payload.e.source.index, 1);
          items.splice(payload.e.destination.index, 0, reOrderedItem)
          const sorted = items.map((doc, idx) => {
            return {
              ...doc,
              sort: idx + 1,
              edited: true,
            }
          })
    
          state.documents = sorted
        },
        resetDocumentsArray: (state) => {
          state.documents = []
        }
    }
})