import styled from 'styled-components'
import { CFormLabel, CFormTextarea } from '@coreui/react-pro';
import theme from 'ui/theme';

interface InlineContainerProps {
  icon?: boolean,
  btnIcon?: boolean,
  valid?: boolean,
  edited?: boolean,
  isCustom?: boolean
}

export const CustomFormLabel = styled(CFormLabel)`
  display: flex;
  justify-content: space-between;
`

export const CustomPercentIcon = styled.h6`
  margin-bottom: 0;
  font-weight: bold;
`

export const CustomText = styled.h6`
  margin-bottom: 0;
`

export const Wrapper = styled.div`
  > * {
    font-family: 'Inter';
  }

  > label {
    font-size: 14px;
    color: ${theme().darkColor};
    > span {
      color: ${theme().primary};
    }
  }

  .form-text {
    color: ${theme().danger};
  } 
`

export const InlineContainer = styled.div<InlineContainerProps>`
  display: flex;

  > * {
    height: 35px;
  }

  ${({ isCustom }) => isCustom && `
    @media (max-width: 700px) {
      flex-wrap: wrap;
    }
  `}

  > input {
    font-size: 14px;
    border: ${({ valid, edited }) => valid === false ?
    `1px solid ${theme().danger}`
    : edited === true ?
      `2px dashed ${theme().gray200Color}`
      :
      `1px solid #D8DBE0`} 
    !important;
    border-radius: '.25rem';
    min-width: 65px;

    ${({ isCustom }) => isCustom && `
      width: 35%;
    `}

    ${(props) => 
      props.icon  && 'border-radius: 0 .25rem .25rem 0;'
    }

    ${(props) => 
      props.btnIcon  && 'border-radius:  .25rem 0 0 .25rem;'
    }

    ${(props) => 
      (props.btnIcon && props.icon)  && 'border-radius:  0;'
    }
  }
`

export const IconContainer = styled.div<{isCustom?: boolean}>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  background-color: ${theme().lightColor};
  border: 1px solid #D8DBE0;
  border-right: unset;
  border-radius: .25rem 0 0 .25rem;

  ${({ isCustom }) => isCustom && `
    justify-content: flex-start;
    min-width: 370px;
    overflow: hidden;
    width: 65%;

    @media (max-width: 450px) {
      min-width: 100%;
      padding-block: 1rem;
      height: 65px;
    }
  `}
`

export const BtnIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  background-color: ${theme().lightColor};
  border: 1px solid #D8DBE0;
  border-left: unset;
  border-radius: 0 .25rem .25rem 0;
  cursor: pointer;

  &:hover {
    background-color: ${theme().gray300Color};
  }
`

export const PasswordToggleContainer = styled.div`
  width: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  right: 20px;
  cursor: pointer;

  > svg {
    position: absolute;
  }
`

export const FormTextarea = styled(CFormTextarea) <{ isvalid: boolean | undefined }>`
  min-height: 150px !important;
  font-size: 14px;
  border: 1px solid ${(props) => props.isvalid === false ? theme().danger : '#D8DBE0'};
  border-radius:'0 .25rem .25rem 0';
`