/**
 * Usertimerecorddetails scene slice
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */
import { createSlice } from '@reduxjs/toolkit'
import { DateTime, Duration } from 'luxon';
import * as Check from 'validations';
import { TimeRecordClockInValidations, TimeRecordValidations, TimeRecordClockOutValidations, TimeRecordAbsenseValidations, TimeRecordMealValidations } from '../utils'
import { toast } from 'react-toastify'
import i18n from 'i18next'
import { NewTimeRecord } from 'types/collaborators/collaborator';

type User = {
  [key: string]: any;
}

type Days = {
  [key: string]: any;
}

type Event = {
  date: string,
  expand: boolean,
  event: any
}

type Entries = {
  date: string,
  entries: any[]
}

interface InitialState {
  loading: boolean,
  submitButtonState: boolean,
  hasEditPermission: boolean,
  hasDateParams: boolean,
  submitLoading: boolean,
  eventsLoading: boolean,
  timeRecordFile: any,
  days: Days,
  workdays: any[],
  originalDays: Days,
  startDate: string,
  endDate: string,
  user: User,
  newTimeRecord: NewTimeRecord,
  editedTimeRecords: any[],
  addedTimeRecords: any[],
  deletedTimeRecords: any[],
  addedTimeRecordsCount: number,
  graphTab: string,
  commissions: any[],
  exportLoading: boolean

}

const initialState: InitialState = {
  loading: true,
  submitButtonState: false,
  hasEditPermission: true,
  hasDateParams: false,
  submitLoading: false,
  eventsLoading: false,
  exportLoading: false,
  timeRecordFile: null,
  days: [
    {
      date: new Date().toISOString().slice(0, 10),
      events: [],
      expand: true
    }
  ],
  workdays: [],
  originalDays: [
    {
      date: new Date().toISOString().slice(0, 10),
      events: [],
      expand: true
    }
  ],
  startDate: new Date().toISOString().slice(0, 10),
  endDate: new Date().toISOString().slice(0, 10),
  user: {},
  newTimeRecord: {
    day: {
      value: "",
      message: "",
      valid: true,
    },
    entryType: {
      value: "",
      message: "",
      valid: true,
    },
    startTimeRecord: {
      value: "",
      message: "",
      valid: true,
    },
    endTimeRecord: {
      value: "",
      message: "",
      valid: true,
    },
    unitUuid: {
      value: "",
      message: "",
      valid: true,
    },
    description: {
      value: "",
      message: "",
      valid: true,
    },
    absenseType: {
      value: "",
      message: "",
      valid: true,
    },
  },
  editedTimeRecords: [],
  addedTimeRecords: [],
  deletedTimeRecords: [],
  addedTimeRecordsCount: 0,
  graphTab: 'hours',
  commissions: []
}

export default createSlice({
  name: 'Usertimerecorddetails',
  initialState,
  reducers: {
    onMount: (state, { payload }) => { },
    onUnmount: (state) => {
      state.days = initialState.days
      state.originalDays = initialState.originalDays
      state.newTimeRecord = initialState.newTimeRecord
      state.workdays = []
      state.editedTimeRecords = []
      state.addedTimeRecords = []
      state.loading = true
      state.submitLoading = false
      state.eventsLoading = false
      state.timeRecordFile = null
    },
    setGraphTab: (state, { payload }) => {
      state.graphTab = payload
    },
    getCommissions: (state, { payload }) => { },
    setCommissions: (state, { payload }) => {
      state.commissions = payload
    },
    getUser: () => { },
    setUser: (state, { payload }) => {
      state.user = payload
    },
    setSubmitButtonState: (state, { payload }) => {
      state.submitButtonState = payload
    },
    setHasEditPermission: (state, { payload }) => {
      state.hasEditPermission = payload
    },
    setLoading: (state, { payload }) => {
      state.loading = payload
    },
    setExportLoading: (state, { payload }) => {
      state.exportLoading = payload
    },
    setSubmitLoading: (state, { payload }) => {
      state.submitLoading = payload
    },
    setEventsLoading: (state, { payload }) => {
      state.eventsLoading = payload
    },
    setExpand: (state, { payload }) => {
      state.days[payload.idx] = {
        ...payload.day,
        expand: payload.expand
      }

      state.originalDays[payload.idx] = {
        ...payload.day,
        expand: payload.expand
      }
    },
    onNewTimeRecordChange: (state, { payload }) => { 
      state.newTimeRecord = payload
    },
    applyNewTimeRecord: (state, { payload }) => {
      switch (payload.newTimeRecord.entryType.value) {
        case "clock_in":
          const TimeRecordClockInValidation = Check.checkValidation(payload.newTimeRecord, TimeRecordClockInValidations)
          if (TimeRecordClockInValidation.invalid) {
            state.newTimeRecord = TimeRecordClockInValidation.form
            toast.error(i18n.t("toasts.invalidFields"))
          }
          else {
            const addedTimeRecordsCopy: any[] = Array.from(payload.addedTimeRecords)
            addedTimeRecordsCopy.push(payload.newTimeRecord)

            state.addedTimeRecords = addedTimeRecordsCopy
            state.addedTimeRecordsCount = addedTimeRecordsCopy.length
            state.newTimeRecord = initialState.newTimeRecord
            toast.info(i18n.t("toasts.userTimeRecordAdded"))

            let newEvents: Event[] = Array.from([])
            const startTime = Duration.fromISOTime(payload.newTimeRecord.startTimeRecord.value).toObject()


            newEvents.push({
              date: payload.newTimeRecord.day.value,
              expand: true,
              event: {
                entryType: payload.newTimeRecord.entryType.value,
                start: {
                  value: DateTime.fromISO(payload.newTimeRecord.day.value).plus(startTime).toISO(),
                  valid: true,
                  message: ''
                },
                end: {
                  value: payload.newTimeRecord.endTimeRecord.value,
                  valid: true,
                  message: ''
                },
                vehicle: null,
                absenseType: null,
                description: payload.newTimeRecord.description.value,
                isCreatedManually: false,
                unsaved: true
              }
            })

            const updatedArray = updateArrayOnApply(payload.days, newEvents)
            state.days = updatedArray
          }
          break;
        case "clock_out":
          const TimeRecordClockOutValidation = Check.checkValidation(payload.newTimeRecord, TimeRecordClockOutValidations)
          if (TimeRecordClockOutValidation.invalid) {
            state.newTimeRecord = TimeRecordClockOutValidation.form
            toast.error(i18n.t("toasts.invalidFields"))
          }
          else {
            const addedTimeRecordsCopy: any[] = Array.from(payload.addedTimeRecords)
            addedTimeRecordsCopy.push(payload.newTimeRecord)

            state.addedTimeRecords = addedTimeRecordsCopy
            state.addedTimeRecordsCount = addedTimeRecordsCopy.length
            state.newTimeRecord = initialState.newTimeRecord
            toast.info(i18n.t("toasts.userTimeRecordAdded"))

            let newEvents: Event[] = Array.from([])
            const startTime = Duration.fromISOTime(payload.newTimeRecord.startTimeRecord.value).toObject()

            newEvents.push({
              date: payload.newTimeRecord.day.value,
              expand: true,
              event: {
                entryType: payload.newTimeRecord.entryType.value,
                start: {
                  value: DateTime.fromISO(payload.newTimeRecord.day.value).plus(startTime).toISO(),
                  valid: true,
                  message: ''
                },
                end: {
                  value: payload.newTimeRecord.endTimeRecord.value,
                  valid: true,
                  message: ''
                },
                vehicle: null,
                absenseType: null,
                description: payload.newTimeRecord.description.value,
                isCreatedManually: false,
                unsaved: true
              }
            })

            const updatedArray = updateArrayOnApply(payload.days, newEvents)
            state.days = updatedArray
          }
          break;
        case "other":
          const TimeRecordValidation = Check.checkValidation(payload.newTimeRecord, TimeRecordValidations)
          if (TimeRecordValidation.invalid) {
            state.newTimeRecord = TimeRecordValidation.form
            toast.error(i18n.t("toasts.invalidFields"))
          }
          else {
            const endTime = Duration.fromISOTime(payload.newTimeRecord.endTimeRecord.value).toObject()
            const startTime = Duration.fromISOTime(payload.newTimeRecord.startTimeRecord.value).toObject()

            if (DateTime.now().plus(endTime).toMillis() < DateTime.now().plus(startTime).toMillis()) {
              state.newTimeRecord = {
                ...TimeRecordValidation.form,
                endTimeRecord: {
                  value: TimeRecordValidation.form.endTimeRecord.value,
                  valid: false,
                  message: i18n.t("validations.endTimeMustBeBigger")
                }
              }

            }
            else {

              const addedTimeRecordsCopy: any[] = Array.from(payload.addedTimeRecords)
              addedTimeRecordsCopy.push(payload.newTimeRecord)

              state.addedTimeRecords = addedTimeRecordsCopy
              state.addedTimeRecordsCount = addedTimeRecordsCopy.length
              state.newTimeRecord = initialState.newTimeRecord
              toast.info(i18n.t("toasts.userTimeRecordAdded"))


              let newEvents: Event[] = Array.from([])
              const startTime = Duration.fromISOTime(payload.newTimeRecord.startTimeRecord.value).toObject()

              newEvents.push({
                date: payload.newTimeRecord.day.value,
                expand: true,
                event: {
                  entryType: payload.newTimeRecord.entryType.value,
                  start: {
                    value: DateTime.fromISO(payload.newTimeRecord.day.value).plus(startTime).toISO(),
                    valid: true,
                    message: ''
                  },
                  end: payload.newTimeRecord.endTimeRecord.value !== "" ? {
                    value: payload.newTimeRecord.endTimeRecord.value,
                    valid: true,
                    message: ''
                  } : null,
                  vehicle: payload.newTimeRecord.unitUuid.value !== "" ? {
                    uuid: payload.newTimeRecord.unitUuid.value
                  } : null,
                  description: payload.newTimeRecord.description.value,
                  absenseType: null,
                  isCreatedManually: false,
                  unsaved: true
                }
              })

              const updatedArray = updateArrayOnApply(payload.days, newEvents)
              state.days = updatedArray
            }
          }
          break;
        case "meal":
        case "absense":
          const TimeRecordMealAbsenseValidation = payload.newTimeRecord.entryType.value === 'meal' ?
           Check.checkValidation(payload.newTimeRecord, TimeRecordMealValidations) :
           Check.checkValidation(payload.newTimeRecord, TimeRecordAbsenseValidations)
          
          if (TimeRecordMealAbsenseValidation.invalid) {
            state.newTimeRecord = TimeRecordMealAbsenseValidation.form
            toast.error(i18n.t("toasts.invalidFields"))
          }
          else {
            const addedTimeRecordsCopy: any[] = Array.from(payload.addedTimeRecords)
            addedTimeRecordsCopy.push(payload.newTimeRecord)

            state.addedTimeRecords = addedTimeRecordsCopy
            state.addedTimeRecordsCount = addedTimeRecordsCopy.length
            state.newTimeRecord = initialState.newTimeRecord
            toast.info(i18n.t("toasts.userTimeRecordAdded"))

            let newEvents: Event[] = Array.from([])


            newEvents.push({
              date: payload.newTimeRecord.day.value,
              expand: true,
              event: {
                entryType: payload.newTimeRecord.entryType.value,
                start: {
                  value: payload.newTimeRecord.startTimeRecord.value,
                  valid: true,
                  message: ''
                },
                end: {
                  value: payload.newTimeRecord.endTimeRecord.value,
                  valid: true,
                  message: ''
                },
                vehicle: null,
                absenseType: payload.newTimeRecord.absenseType.value,
                description: payload.newTimeRecord.description.value,
                isCreatedManually: false,
                unsaved: true
              }
            })

            const updatedArray = updateArrayOnApply(payload.days, newEvents)
            state.days = updatedArray
          }
          break;
        default:
          const TimeRecordVal = Check.checkValidation(payload.newTimeRecord, TimeRecordValidations)
          if (TimeRecordVal.invalid) {
            state.newTimeRecord = TimeRecordVal.form
            toast.error(i18n.t("toasts.invalidFields"))
          }
          break;
      }
    },
    onEditTimeRecord: (state, { payload }) => {

      state.days = state.days.map((d) => {
        if (d.date === payload.day) {
          const events = d.events.map((tm) => {
            if (tm.id === payload.id) {
              switch (payload.editType) {
                case "start":
                  return {
                    ...tm,
                    start: { value: payload.value, valid: true, message: '' },
                    edited: true
                  }
                case "end":
                  return {
                    ...tm,
                    end: { value: payload.value, valid: true, message: '' },
                    edited: true
                  }
                case "description":
                  return {
                    ...tm,
                    description: payload.value,
                    edited: true
                  }
                case "vehicle":
                  return {
                    ...tm,
                    vehicle: { uuid: payload.value },
                    edited: true
                  }
                case "absenseType":
                  return {
                    ...tm,
                    absenseType: payload.value,
                    edited: true
                  }
                default:
                  break;
              }
            }
            return tm
          })

          return {
            ...d,
            events
          }
        }
        return d
      })

      let newTimeRecord = true
      state.editedTimeRecords = payload.editedTimeRecords.map((editedTm) => {

        if (editedTm.id === payload.id) {
          newTimeRecord = false
          switch (payload.editType) {
            case "start":
              return {
                ...editedTm,
                start: payload.value
              }
            case "end":
              return {
                ...editedTm,
                end: payload.value
              }
            case "description":
              return {
                ...editedTm,
                description: payload.value
              }
            case "vehicle":
              return {
                ...editedTm,
                vehicle: { uuid: payload.value },
                edited: true
              }
            case "absenseType":
              return {
                ...editedTm,
                absenseType: payload.value,
                edited: true
              }
            default:
              break;
          }
        }
        return editedTm
      })

      if (newTimeRecord) {
        const editedTimeRecordsAux: any = Array.from(payload.editedTimeRecords)

        switch (payload.editType) {
          case "start":
            editedTimeRecordsAux.push({
              day: payload.day,
              id: payload.event.id,
              entryType: payload.event.entryType,
              start: payload.value
            })
            break;
          case "end":
            editedTimeRecordsAux.push({
              day: payload.day,
              id: payload.event.id,
              entryType: payload.event.entryType,
              end: payload.value
            })
            break;
          case "description":
            editedTimeRecordsAux.push({
              day: payload.day,
              id: payload.event.id,
              entryType: payload.event.entryType,
              description: payload.value
            })
            break;
          case "vehicle":
            editedTimeRecordsAux.push({
              day: payload.day,
              id: payload.event.id,
              entryType: payload.event.entryType,
              vehicle: { uuid: payload.value },
            })
            break;
          case "absenseType":
            editedTimeRecordsAux.push({
              day: payload.day,
              id: payload.event.id,
              entryType: payload.event.entryType,
              absenseType: payload.value
            })
            break;
          default:
            break;
        }

        state.editedTimeRecords = editedTimeRecordsAux
      }
    },
    onEditTimeRecordsValidations: (state, { payload }) => {

      // state.days.events arrays are never populated with possible 'invalid' field that came from validation process before
      const updatedDays = payload.days.map((d) => {
        if (payload.editedEntries.find(entry => entry.date === d.day)) {
          const entries = payload.editedEntries.filter(entry => entry.date === d.day)

          return {
            ...d,
            events: d.events.map((event) => {
              if (entries.find(e => e.id === event.id)) {
                const e = entries.find(e => e.id === event.id)

                return {
                  ...event,
                  end: e.invalid ? {
                    value: e.end,
                    valid: false,
                    message: i18n.t("validations.endTimeMustBeBigger")
                  } : {
                    value: e.end,
                    valid: true,
                    message: ''
                  }
                }
              }
              return event
            })
          }
        }
        return d
      })
      state.days = updatedDays
    },
    submitClockIn: (state, { payload }) => { },
    submitClockOut: (state, { payload }) => { },
    onSubmitTimeRecords: () => { },
    onDeleteTimeRecordEntry: (state, { payload }) => {
      const deletedTimeRecordsCopy: any[] = Array.from(payload.deletedTimeRecords)
      deletedTimeRecordsCopy.push(payload.event)

      state.deletedTimeRecords = deletedTimeRecordsCopy
      state.days = payload.days.map((d, idx) => {
        return {
          ...d,
          events: d.events.map((e) => {
            if (e.id === payload.event.id) {
              return {
                ...e,
                deleted: true,
                unsaved: true
              }
            }
            return e
          })
        }
      })
    },
    deleteTimeRecordEntry: (state, { payload }) => {

    },
    undoDelete: (state, { payload }) => {
      state.deletedTimeRecords = payload.deletedTimeRecords.filter(d => d.id !== payload.event.id)
      state.days = payload.days.map(d => {
        return {
          ...d,
          events: d.events.map(e => {
            if (e.id === payload.event.id) {
              return {
                ...e,
                deleted: false,
                unsaved: false
              }
            }
            return e
          })
        }
      })
    },

    onUpdateValidations: (state, { payload }) => {
    },
    clearAddedTimeRecords: (state) => {
      state.addedTimeRecords = []
      state.days = state.originalDays
    },
    clearEditedTimeRecords: (state) => {
      state.editedTimeRecords = []
      state.days = state.originalDays
    },
    clearDeletedTimeRecords: (state) => {
      state.deletedTimeRecords = []
      state.days = state.originalDays
    },

    clearNewTimeRecord: (state) => {
      state.newTimeRecord = initialState.newTimeRecord
    },
    getWorkdays: (state, { payload }) => { },
    setWorkdays: (state, { payload }) => {
      state.workdays = payload
    },
    getUserTimeRecord: (state, { payload }) => { },
    getUserTimeRecordDay: (state, { payload }) => { },
    setUserTimeRecord: (state, { payload }) => {

      state.days = payload.days.map((d, idx) => {
        if (payload.timeRecord.find(tm => tm.workday.date === d.date)) {
          return ({
            ...d,
            expand: true,
            events: payload.timeRecord.filter(tm => tm.workday.date === d.date).map((tm, id) => {
              return {
                ...tm,
                start: { value: tm.start, valid: true, message: '' },
                end: { value: tm.end, valid: true, message: '' }
              }
            })
          })
        }
        else if (payload.deletedID) {
          return {
            ...d,
            events: d.events.filter(e => e.id !== payload.deletedID)
          }
        }
        return d
      })

      state.originalDays = payload.days.map((d, idx) => {
        if (payload.timeRecord.find(tm => tm.workday.date === d.date)) {
          return ({
            ...d,
            expand: true,
            events: payload.timeRecord.filter(tm => tm.workday.date === d.date).map((tm, id) => {
              return {
                ...tm,
                start: { value: tm.start, valid: true, message: '' },
                end: { value: tm.end, valid: true, message: '' }
              }
            })
          })
        }
        else if (payload.deletedID) {
          return {
            ...d,
            events: d.events.filter(e => e.id !== payload.deletedID)
          }
        }
        return d
      })


    },
    updateWorkdays: (state, { payload }) => {

      state.workdays = payload.workdays.map((w) => {
        if (payload.timeRecord.find(tm => tm.workday.date === w.date)) {
          const updatedWorkday = payload.timeRecord.find(tm => tm.workday.date === w.date)

          return {
            ...w,
            timeWorking: updatedWorkday.workday.timeWorking
          }
        }
        return w
      })
    },
    updateEditedUserTimeRecords: (state, { payload }) => {
      let editedDays: Entries[] = Array.from([])

      payload.response.forEach((res) => {
        if (payload.days.find(d => d.date === res.date)) {
          editedDays.push({
            date: res.date,
            entries: res.entries.map((entry, idx) => {
              return {
                ...entry,
                start: { value: entry.start, valid: true, message: '' },
                end: { value: entry.end, valid: true, message: '' }
              }
            })
          })
        }
      })

      const updatedDays = payload.days.map((d, idx) => {

        if (editedDays.find(e => e.date === d.date)) {
          const editedEntries: any[] = Array.from(editedDays).filter(ed => d.date === ed.date)

          return {
            ...d,
            events: editedEntries[0].entries.sort((a, b) => {
              if (DateTime.fromISO(a.start.value).toMillis() > DateTime.fromISO(b.start.value).toMillis()) {
                return -1
              }
              else if (DateTime.fromISO(a.start.value).toMillis() < DateTime.fromISO(b.start.value).toMillis()) {
                return 1
              }
              else {
                return 0;
              }
            })
          }
        }
        return d

      })

      state.days = updatedDays
      state.originalDays = updatedDays

    },
    getTimeRecordFile: (state, { payload }) => { },
    downloadTimeRecord: (state, { payload }) => {

      const fileName = `${payload.userName}-timeRecord.xlsx`
      const finalFileName = fileName.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(" ", "");

      const url = URL.createObjectURL(payload.file)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute(
        'download',
        finalFileName
      )
      document.body.appendChild(link);
      link.click()
      link.parentNode?.removeChild(link)
    },
    getURLParams: (state, { payload }) => { },
    populateFiltersOnMount: (state, { payload }) => {
      state.startDate = payload.startDate
      state.endDate = payload.endDate
    },
    setDatesInterval: (state, { payload }) => {
    },
    setStartDate: (state, { payload }) => {
      state.startDate = payload
    },
    setEndDate: (state, { payload }) => {
      state.endDate = payload
    },
    setHasDateParams: (state, { payload }) => {
      state.hasDateParams = payload
    },
    setDays: (state, { payload }) => {
      state.days = payload
    },
    mapErrors: (state, { payload }) => {

    }
  }
})

function updateArrayOnApply(days, newEvents) {
  return days.map((d, idx) => {
    if (newEvents.filter(e => e.date === d.date)) {
      const updatedEvents: any[] = Array.from(d.events)
      const eventsToAdd = newEvents.filter(e => e.date === d.date)

      if (eventsToAdd.length > 0) {
        eventsToAdd.forEach(element => {
          updatedEvents.push(element.event)
        });
      }

      return {
        ...d,
        expand: eventsToAdd.length > 0 || d.expand ? true : false,
        events: updatedEvents.sort((a, b) => {
          if (DateTime.fromISO(a.start.value).toMillis() > DateTime.fromISO(b.start.value).toMillis()) {
            return -1
          }
          else if (DateTime.fromISO(a.start.value).toMillis() < DateTime.fromISO(b.start.value).toMillis()) {
            return 1
          }
          else {
            return 0;
          }
        })
      }
    }
    return d

  })
}