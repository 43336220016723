/**
 * Edittimerecord component
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */
import { CTableDataCell, CTableHeaderCell } from '@coreui/react-pro'
import React, { useEffect, useRef, useState } from 'react'
import { DateTime } from 'luxon'
import { useDispatch, useSelector } from 'react-redux'
// Components
import CustomTextInput from 'shared/components/CustomTextInput'
import Timepicker from 'shared/components/FormComponents/timepicker'
import TableContainer from 'shared/components/TableContainer'
import TabsContainer from 'shared/components/Tabscontainer'
import { CustomTableRow, } from 'shared/genericStyledComponents'
import Dropdown from 'shared/components/FormComponents/dropdown'
// Store
import { actions, RootState } from 'store/rootSlices'
// Icons
import Arrow from 'assets/icons/arrow_forward'
// Utils
import { paginationCurrentPage, paginationElementsCount } from 'utils'
import { allAbsenseTypes } from 'scenes/Usertimerecorddetails/utils'
// Styles
import { Wrapper, EventTimes, Button, StickyTableContainer} from './styles'

const Edittimerecord = ({ t, i18n }) => {
  const [isSticky, setIsSticky] = useState<boolean>(false)
  const elementRef = useRef<HTMLDivElement>(null);

  const {
    onNewTimeRecordChange,
    applyNewTimeRecord
  } = actions.UserTimeRecordDetails

  const {
    days,
    newTimeRecord,
    addedTimeRecords,
    workdays
  } = useSelector((state: RootState) => state.UserTimeRecordDetails)

  const {
    fleetLongList
  } = useSelector((state: RootState) => state.FleetManagement)

  const dispatch = useDispatch()

  // Checks if the table is in the viewport or not
  useEffect(() => {
    const tempRef = elementRef.current

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (!entry.isIntersecting) {
            // Element has left the viewport
            setIsSticky(true)
          } else {
            setIsSticky(false)
          }
        });
      },
      { threshold: 0 }
    );
    

    if (tempRef) {
      observer.observe(tempRef);
    }

    return () => {
      if (tempRef) {
        observer.unobserve(tempRef);
      }
    };
  }, []);

  const _renderDynamicDropdown = () => {
    return newTimeRecord.entryType.value === 'absense' ? (
      <Dropdown
        id='newUserTimeRecord-absenseType'
        disabled={false}
        value={newTimeRecord.absenseType.value || ""}
        valid={newTimeRecord.absenseType.valid}
        helpText={newTimeRecord.absenseType.message}
        placeholder={i18n.t('placeholders.absenseType')}
        options={ allAbsenseTypes(i18n) }
        onChange={(e) => {
          dispatch(onNewTimeRecordChange({ ...newTimeRecord, absenseType: { value: e.target.value, valid: true, message: "" } }))
        }}
      />
    ) : (
      <Dropdown
        id='newUserTimeRecord-vehicle'
        disabled={newTimeRecord.entryType.value === "clock_in" || newTimeRecord.entryType.value === "clock_out" || newTimeRecord.entryType.value === "meal" || newTimeRecord.entryType.value === "absense"}
        value={newTimeRecord.unitUuid.value || ""}
        valid={newTimeRecord.unitUuid.valid}
        helpText={newTimeRecord.unitUuid.message}
        placeholder={i18n.t('placeholders.vessel')}
        options={
          fleetLongList.map((v, idx) => {
            return {
              label: v.name, value: v.uuid
            }
          })
        }
        onChange={(e) => {
          dispatch(onNewTimeRecordChange({ ...newTimeRecord, unitUuid: { value: e.target.value, valid: true, message: "" } }))
        }}
      />
    )
  }

  return (
    <>
      <Wrapper sticky={isSticky} ref={elementRef}>
        <TabsContainer
          message={t("timeRecord.editTimeRecordInfo")}
          id='timeRecord-tabsContainer'
          onChangeTab={() => { }}
          content={[
            {
              title: t("timeRecord.timeRecord"),
              content:
                <TableContainer
                  key={`timeRecord.addTimeRecord-tableRow`}
                  count={paginationElementsCount(0)}
                  currentPage={paginationCurrentPage(0, -1)}
                  elementsPerPage={1}
                  onPageChange={pg => { }}
                  headerElements={[
                    <CTableHeaderCell colSpan={6}>{t("timeRecord.addTimeRecord")}</CTableHeaderCell>,
                  ]}
                >
                  <CustomTableRow>
                    <CTableDataCell style={{ textAlign: 'center', minWidth: '140px' }} colSpan={1}>
                      <Dropdown
                        id='newUserTimeRecord-day'
                        disabled={false}
                        value={newTimeRecord.day.value}
                        valid={newTimeRecord.day.valid}
                        helpText={newTimeRecord.day.message}
                        placeholder={i18n.t('placeholders.date')}
                        options={
                          days.map((d, idx) => {
                            const workday = workdays.find(w => w.date === d.date)

                            if (!workday?.isApproved) {
                              return {
                                label: `${DateTime.fromISO(d.date).toFormat("dd/LL/yyyy")}`, value: d.date
                              }
                            }
                            return null
                          })
                        }
                        onChange={(e) => {
                          dispatch(onNewTimeRecordChange({ ...newTimeRecord, day: { value: e.target.value, valid: true, message: "" } }))
                        }}
                      />
                    </CTableDataCell>
                    <CTableDataCell style={{ minWidth: '140px' }} colSpan={1}>
                      <Dropdown
                        id='newUserTimeRecord-entryType'
                        disabled={false}
                        value={newTimeRecord.entryType.value || ""}
                        valid={newTimeRecord.entryType.valid}
                        helpText={newTimeRecord.entryType.message}
                        placeholder={i18n.t('placeholders.entryType')}
                        options={
                          [
                            { label: t("timeRecord.itsClockIn"), value: "clock_in" },
                            { label: t("timeRecord.itsClockOut"), value: "clock_out" },
                            { label: t("timeRecord.meal"), value: "meal" },
                            { label: t("timeRecord.absense"), value: "absense" },
                            { label: t("timeRecord.other"), value: "other" },
                          ]
                        }
                        onChange={(e) => {
                          if (e.target.value !== "other" || e.target.value !== "meal" || e.target.value !== 'absense') {
                            dispatch(onNewTimeRecordChange({
                              ...newTimeRecord,
                              entryType: { value: e.target.value, valid: true, message: "" },
                              unitUuid: { value: "", valid: true, message: "" },
                              startTimeRecord: { value: "", valid: true, message: "" },
                              endTimeRecord: { value: "", valid: true, message: "" },
                            }))
                          }
                          else {
                            dispatch(onNewTimeRecordChange({ ...newTimeRecord, entryType: { value: e.target.value, valid: true, message: "" } }))
                          }
                        }}
                      />
                    </CTableDataCell>
                    <CTableDataCell style={{ minWidth: '170px' }} colSpan={1}>
                      <EventTimes>
                        <Timepicker
                          id={`newUserTimeRecord-start`}
                          disabled={newTimeRecord.entryType.value === "clock_out" || newTimeRecord.entryType.value === "meal" || newTimeRecord.entryType.value === "absense"}
                          onChange={(e) => {
                            dispatch(onNewTimeRecordChange({ ...newTimeRecord, startTimeRecord: { value: e, valid: true, message: "" } }))
                          }}
                          valid={newTimeRecord.startTimeRecord.valid}
                          value={newTimeRecord.startTimeRecord.value}
                          helpText={newTimeRecord.startTimeRecord.message}
                          timeFormat="HH:mm"
                        />
                        <Arrow width={50} />
                        <Timepicker
                          id={`newUserTimeRecord-end`}
                          disabled={newTimeRecord.entryType.value === "clock_in" || newTimeRecord.entryType.value === "meal" || newTimeRecord.entryType.value === "absense"}
                          onChange={(e) => {
                            dispatch(onNewTimeRecordChange({ ...newTimeRecord, endTimeRecord: { value: e, valid: true, message: "" } }))
                          }}
                          valid={newTimeRecord.endTimeRecord.valid}
                          value={newTimeRecord.endTimeRecord.value}
                          helpText={newTimeRecord.endTimeRecord.message}
                          timeFormat="HH:mm"
                        />
                      </EventTimes>
                    </CTableDataCell>

                    <CTableDataCell style={{ minWidth: '140px' }} colSpan={1}>
                      { _renderDynamicDropdown() }
                    </CTableDataCell>
                    <CTableDataCell style={{ minWidth: '250px' }} colSpan={1}>
                      <CustomTextInput
                        id='newUserTimeRecord-description'
                        disabled={false}
                        valid={newTimeRecord.description.valid}
                        value={newTimeRecord.description.value}
                        helpText={newTimeRecord.description.message}
                        placeholder={i18n.t('placeholders.description')}
                        onChange={(e) => {
                          dispatch(onNewTimeRecordChange({ ...newTimeRecord, description: { value: e.target.value, valid: true, message: "" } }))
                        }} />
                    </CTableDataCell>
                    <CTableDataCell style={{ textAlign: 'center', minWidth: '90px' }} colSpan={1}>
                      <Button id='tripPlanningForm-applyStartHour' color="primary"
                        disabled={false}
                        onClick={(e) => {
                          dispatch(applyNewTimeRecord({ newTimeRecord, addedTimeRecords, days }))
                        }}
                        style={{ width: '90%' }}
                      >
                        {i18n.t('tripPlanningForm.applyStartHour')}
                      </Button>
                    </CTableDataCell>
                  </CustomTableRow>
                </TableContainer>
            }/* ,
            {
              title: t("timeRecord.holidays"),
              content:
                <TableContainer
                  count={paginationElementsCount(0)}
                  currentPage={paginationCurrentPage(0, 1)}
                  elementsPerPage={1}
                  onPageChange={() => { }}
                  headerElements={[
                    t("timeRecord.addHolidays"),
                  ]}
                >
                </TableContainer>
            },
            {
              title: t("timeRecord.hourBank"),
              content:
                <TableContainer
                  count={paginationElementsCount(0)}
                  currentPage={paginationCurrentPage(0, 1)}
                  elementsPerPage={1}
                  onPageChange={() => { }}
                  headerElements={[
                    '...',
                  ]}
                >
                </TableContainer>
            } */
          ]}
        />
      </Wrapper>
      {
        isSticky && (
          <StickyTableContainer
            sticky={isSticky}
            count={paginationElementsCount(0)}
            currentPage={paginationCurrentPage(0, -1)}
            elementsPerPage={1}
            onPageChange={pg => { }}
            id='timeRecord-stickyContainer'
          >
            <CustomTableRow>
              <CTableDataCell style={{ textAlign: 'center', minWidth: '140px' }} colSpan={1}>
                <Dropdown
                  id='newUserTimeRecord-day'
                  disabled={false}
                  value={newTimeRecord.day.value}
                  valid={newTimeRecord.day.valid}
                  helpText={newTimeRecord.day.message}
                  placeholder={i18n.t('placeholders.date')}
                  options={
                    days.map((d, idx) => {
                      const workday = workdays.find(w => w.date === d.date)

                      if (!workday?.isApproved) {
                        return {
                          label: `${DateTime.fromISO(d.date).toFormat("dd/LL/yyyy")}`, value: d.date
                        }
                      }
                      return null
                    })
                  }
                  onChange={(e) => {
                    dispatch(onNewTimeRecordChange({ ...newTimeRecord, day: { value: e.target.value, valid: true, message: "" } }))
                  }}
                />
              </CTableDataCell>
              <CTableDataCell style={{ minWidth: '140px' }} colSpan={1}>
                <Dropdown
                  id='newUserTimeRecord-entryType'
                  disabled={false}
                  value={newTimeRecord.entryType.value || ""}
                  valid={newTimeRecord.entryType.valid}
                  helpText={newTimeRecord.entryType.message}
                  placeholder={i18n.t('placeholders.entryType')}
                  options={
                    [
                      { label: "Clock In", value: "clock_in" },
                      { label: "Clock Out", value: "clock_out" },
                      { label: "Meal", value: "meal" },
                      { label: "Absense", value: "absense" },
                      { label: "Other", value: "other" },
                    ]
                  }
                  onChange={(e) => {
                    if (e.target.value !== "other" || e.target.value !== "meal" || e.target.value !== 'absense') {
                      dispatch(onNewTimeRecordChange({
                        ...newTimeRecord,
                        entryType: { value: e.target.value, valid: true, message: "" },
                        unitUuid: { value: "", valid: true, message: "" },
                        startTimeRecord: { value: "", valid: true, message: "" },
                        endTimeRecord: { value: "", valid: true, message: "" },
                      }))
                    }
                    else {
                      dispatch(onNewTimeRecordChange({ ...newTimeRecord, entryType: { value: e.target.value, valid: true, message: "" } }))
                    }
                  }}
                />
              </CTableDataCell>
              <CTableDataCell style={{ minWidth: '150px' }} colSpan={1}>
                <EventTimes>
                  <Timepicker
                    id={`newUserTimeRecord-start`}
                    disabled={newTimeRecord.entryType.value === "clock_out" || newTimeRecord.entryType.value === "meal" || newTimeRecord.entryType.value === "absense"}
                    onChange={(e) => {
                      dispatch(onNewTimeRecordChange({ ...newTimeRecord, startTimeRecord: { value: e, valid: true, message: "" } }))
                    }}
                    valid={newTimeRecord.startTimeRecord.valid}
                    value={newTimeRecord.startTimeRecord.value}
                    helpText={newTimeRecord.startTimeRecord.message}
                    timeFormat="HH:mm"
                  />
                  <Arrow width={50} />
                  <Timepicker
                    id={`newUserTimeRecord-end`}
                    disabled={newTimeRecord.entryType.value === "clock_in" || newTimeRecord.entryType.value === "meal" || newTimeRecord.entryType.value === "absense"}
                    onChange={(e) => {
                      dispatch(onNewTimeRecordChange({ ...newTimeRecord, endTimeRecord: { value: e, valid: true, message: "" } }))
                    }}
                    valid={newTimeRecord.endTimeRecord.valid}
                    value={newTimeRecord.endTimeRecord.value}
                    helpText={newTimeRecord.endTimeRecord.message}
                    timeFormat="HH:mm"
                  />
                </EventTimes>
              </CTableDataCell>

              <CTableDataCell style={{ minWidth: '140px' }} colSpan={1}>
                <Dropdown
                  id='newUserTimeRecord-vehicle'
                  disabled={newTimeRecord.entryType.value === "clock_in" || newTimeRecord.entryType.value === "clock_out" || newTimeRecord.entryType.value === "meal" || newTimeRecord.entryType.value === "absense"}
                  value={newTimeRecord.unitUuid.value || ""}
                  valid={newTimeRecord.unitUuid.valid}
                  helpText={newTimeRecord.unitUuid.message}
                  placeholder={i18n.t('placeholders.vessel')}
                  options={
                    fleetLongList.map((v, idx) => {
                      return {
                        label: v.name, value: v.uuid
                      }
                    })
                  }
                  onChange={(e) => {
                    dispatch(onNewTimeRecordChange({ ...newTimeRecord, unitUuid: { value: e.target.value, valid: true, message: "" } }))
                  }}
                />
              </CTableDataCell>
              <CTableDataCell style={{ minWidth: '250px' }} colSpan={1}>
                <CustomTextInput
                  id='newUserTimeRecord-description'
                  disabled={false}
                  valid={newTimeRecord.description.valid}
                  value={newTimeRecord.description.value}
                  helpText={newTimeRecord.description.message}
                  placeholder={i18n.t('placeholders.description')}
                  onChange={(e) => {
                    dispatch(onNewTimeRecordChange({ ...newTimeRecord, description: { value: e.target.value, valid: true, message: "" } }))
                  }} />
              </CTableDataCell>
              <CTableDataCell style={{ textAlign: 'center', minWidth: '110px' }} colSpan={1}>
                <Button id='tripPlanningForm-applyStartHour' color="primary"
                  disabled={false}
                  onClick={(e) => {
                    dispatch(applyNewTimeRecord({ newTimeRecord, addedTimeRecords, days }))
                  }}
                  style={{ width: '90%' }}
                >
                  {i18n.t('tripPlanningForm.applyStartHour')}
                </Button>
              </CTableDataCell>
            </CustomTableRow>
          </StickyTableContainer>
        )
      }
    </>
  )
}

export default Edittimerecord