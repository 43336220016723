import styled from 'styled-components';
import SectionContainer from 'shared/components/Sectioncontainer';
import { CButtonGroup, CFormCheck } from '@coreui/react-pro';
import theme, { devicesMax } from 'ui/theme';
import { ControlsContainer } from 'shared/genericStyledComponents';
import { AreaChart, ResponsiveContainer } from 'recharts';

export const ResponsiveCContainer = styled(ResponsiveContainer)`
  max-height: 270px;
`;

export const AreaCChart = styled(AreaChart)`
  overflow: hidden;
`;

export const Wrapper = styled.div`
  margin-bottom: 80px;
  width: 100%;
`;

export const UserSummary = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  column-gap: 16px;
  margin-bottom: 50px;
`;

export const UserMainInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 16px;
  padding: 18px;
`;

export const GraphSectionContainer = styled(SectionContainer)`
  min-width: 350px;
  flex-grow: 1;
  width: 69%;

  > div {
    padding: 0px;
  }

  @media (min-width: 1630px) {
    height: 440px;
  }

  @media (max-width: 1500px) {
    height: fit-content;
  }
`;

export const CustomSectionContainer = styled(SectionContainer)`
  min-width: 350px;
  flex-grow: 1;

  > div {
    padding: 0px;
  }
`;

export const CustomControlContainer = styled(ControlsContainer)`
  > div {
    padding: 16px;
  }
`;

export const AvatarWrapper = styled.div`
  height: 80px;
  width: 80px;
  border: 1px solid ${theme().gray200Color};
  border-radius: 50%;
  overflow: hidden;

  > img {
    width: inherit;
    height: inherit;
  }
`;

export const ContactInfo = styled.div`
  padding: 18px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const ContractInfo = styled.div`
  padding: 18px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const LineInfo = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  > span {
    display: flex;
  }
`;

export const Separator = styled.hr`
  margin: 0;
`;

export const CustomUserTitle = styled.h1`
  font-size: 19px;
  font-weight: 500;
  line-height: 23px;
  font-family: Inter;
  color: ${theme().gray700Color};
  margin: 0;
`;

export const Text = styled.p<{ color: string; alignment?: string }>`
  margin: 0;
  padding-bottom: 8px;
  color: ${({ color }) => color};
  text-align: ${({ alignment }) => alignment};
`;
export const SmallText = styled.p<{ color: string }>`
  font-size: 11px;
  line-height: 13px;
  margin: 0;
  padding-top: 8px;
  color: ${({ color }) => color};
`;

export const DaysButton = styled(CButtonGroup)`
  margin-left: 5px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-right: 30px;
  background-color: ${theme().white};
  color: ${theme().secondaryColor};
`;

export const Day = styled(CFormCheck)`
  margin: 0px;
  border: 1px solid ${theme().secondaryColor};
  padding: 5px 11px !important;
`;

export const DownloadButton = styled.div`
  cursor: pointer;
`;

export const SaveTimeRecords = styled.div<{ sidebar: boolean }>`
  height: 65px;
  z-index: 1;
  position: fixed;
  bottom: 0;
  margin-left: -24px;
  width: calc(100% - ${({ sidebar }) => (sidebar === true ? `268px` : `0px`)});
  padding: 8px 16px;
  background-color: ${theme().white};
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${theme().gray200Color};

  @media ${devicesMax.tablet} {
    font-size: 12px;
  }
`;
