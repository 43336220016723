/**
 * Documentconfigform scene
 *
 * @author João Dias <jdias@ubiwhere.com>
 *
 *
 */
import React, { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { actions, RootState } from 'store/rootSlices'
import SectionContainer from 'shared/components/Sectioncontainer'
import { CCol, CForm, CRow } from '@coreui/react-pro'
import CustomTextInput from 'shared/components/CustomTextInput'
import CustomButton from 'shared/components/CustomButton'
import theme from 'ui/theme'
import Loadingcontainer from 'shared/components/Loadingcontainer'
import CustomCheckbox from 'shared/components/CustomCheckbox'
import { hasPermission } from 'permissions'
import config from 'config'
import { Wrapper, ButtonWrapper } from './styles'

const Documentconfigform = ({ t, i18n }) => {

  const { documentConfigForm, loading, hasEditPermission, submitButtonState } = useSelector((state: RootState) => state.Documentconfigform)
  const { PERMISSIONS } = config
  const { permissions } = useSelector((state: RootState) => state.App)
  const { onMount, onUnmount, onFormChange, onSubmit } = actions.Documentconfigform
  const { navigateBack } = actions.App

  const dispatch = useDispatch()
  const { id } = useParams()

  useEffect(() => {

    dispatch(onMount(id))
    return () => {
      dispatch(onUnmount())
    }
  }, [dispatch, onMount, onUnmount, id]);

  if (loading) {
    return <Wrapper>
      <Loadingcontainer />
    </Wrapper>
  }

  return (
    <Wrapper>
      <CustomCheckbox
        id='documentConfigForm-isActive'
        disabled={!hasEditPermission}
        wrapperStyle={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '-25px', fontSize: '20px' }}
        checked={documentConfigForm.isActive.value ? true : false}
        onClick={e => dispatch(onFormChange({ ...documentConfigForm, isActive: { value: !documentConfigForm.isActive.value, valid: true, message: "" } }))}
        text={i18n.t('tripServiceForm.isActive')}
        switch
      />
      <CForm>
        <SectionContainer title={t('documentConfigs.title1')}>
          <CRow>
            <CCol>
              <CustomTextInput
                id='documentConfigForm-name'
                disabled={!hasEditPermission}
                value={documentConfigForm.name.value || ''}
                valid={documentConfigForm.name.valid}
                helpText={documentConfigForm.name.message}
                required
                label={i18n.t('common.name')}
                placeholder={i18n.t('placeholders.name')}
                onChange={(e) => dispatch(onFormChange({ ...documentConfigForm, name: { value: e.target.value, valid: true, message: "" } }))}
              />
            </CCol>
          </CRow>
        </SectionContainer>
        <ButtonWrapper>
          <CustomButton id='documentConfigForm-cancel' onClick={() => dispatch(navigateBack())} text={i18n.t('common.cancelBtn')} color="link" buttonStyle={{ color: theme().danger, textDecoration: 'unset' }} />
          {
            hasPermission(permissions, PERMISSIONS.FLEET.EDIT_FLEET_SETTINGS) &&
            <CustomButton
              id='documentConfigForm-save'
              disabled={submitButtonState}
              onClick={() => dispatch(onSubmit())}
              text={i18n.t('common.saveBtn')}
              icon="confirm"
              color="success"
              buttonStyle={{ color: "#FFFFFF" }} />
          }
        </ButtonWrapper>
      </CForm>
    </Wrapper>
  )
}

export default Documentconfigform
