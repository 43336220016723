import React from 'react'
import i18n from 'i18next'
import { useDispatch, useSelector } from 'react-redux'
import { actions, RootState } from 'store/rootSlices'
import { CCol } from '@coreui/react-pro'
import { CargoTypeForm } from 'types/trips/plannings';
import SectionContainer from 'shared/components/Sectioncontainer'
import CustomButton from 'shared/components/CustomButton'
import CustomTextInput from 'shared/components/CustomTextInput'
import Trash from 'assets/icons/trash'
import { Row } from '../../../styles';
import { ButtonRow } from '../../styles'


const CargosRows = () => {

  const {
    addCargo,
    cargo,
    hasEditPermission,
    firstCargoClick,
  } = useSelector((state: RootState) => state.TripPlanningsForm)
  const {
    addNewCargo,
    onChangeCargo,
    onDeleteCargo,
  } = actions.TripPlanningsForm

  const dispatch = useDispatch()

  return (
    <SectionContainer title={i18n.t('tripPlanningForm.cargoTitle')} style={{ width: '100%', paddingBottom: '24px' }}>
      {
        addCargo && cargo.map((carg: CargoTypeForm, idx) => {
          return (
            <>
              <Row>
                <CCol>
                  <CustomTextInput
                    disabled={!hasEditPermission}
                    id={`tripPlanningForm-cargoObservation${idx}`}
                    required={true}
                    valid={carg.observations.valid}
                    value={carg.observations.value}
                    helpText={carg.observations.message}
                    placeholder={i18n.t('placeholders.description')}
                    label={i18n.t('common.description')}
                    onChange={(e) => dispatch(onChangeCargo({ form: { ...carg, observations: { value: e.target.value, message: "", valid: true } }, idx, cargo }))}
                  />
                </CCol>
                <CCol sm={1} style={{ width: "5%", display: "flex", justifyContent: "space-around", alignItems: "flex-start", marginTop: "35px" }}>
                  <Trash style={{ cursor: "pointer" }} onClick={(e) => hasEditPermission && dispatch(onDeleteCargo({ cargo: cargo, index: idx, firstClick: firstCargoClick }))} />
                </CCol>
              </Row>
              <Row>
                <CCol>
                  <CustomTextInput
                    disabled={!hasEditPermission}
                    id={`tripPlanningForm-cargoRecipient${idx}`}
                    required={true}
                    valid={carg.recipient.valid}
                    value={carg.recipient.value}
                    helpText={carg.recipient.message}
                    placeholder={i18n.t('placeholders.recipient')}
                    label={i18n.t('tripPlanningForm.recipient')}
                    onChange={(e) => dispatch(onChangeCargo({ form: { ...carg, recipient: { value: e.target.value, message: "", valid: true } }, idx, cargo }))}
                  />
                </CCol>
                <CCol>
                  <CustomTextInput
                    disabled={!hasEditPermission}
                    id={`tripPlanningForm-cargoDestination${idx}`}
                    required={true}
                    valid={carg.destination.valid}
                    value={carg.destination.value}
                    helpText={carg.destination.message}
                    placeholder={i18n.t('placeholders.location')}
                    label={i18n.t('tripPlanningForm.location')}
                    onChange={(e) => dispatch(onChangeCargo({ form: { ...carg, destination: { value: e.target.value, message: "", valid: true } }, idx, cargo }))}
                  />
                </CCol>
              </Row>
              <hr style={{ marginLeft: '-25px', marginRight: '-25px', marginTop: 35 }} />
            </>
          )
        })
      }
      <ButtonRow>
        <CustomButton
          id={'tripPlanningForm-addCargoButton'}
          variant='outline'
          color='primary'
          text={i18n.t('tripPlanningForm.associateNewCargo')}
          icon='add'
          iconFirst
          buttonStyle={{ width: '20%', minWidth: '95px' }}
          onClick={e => hasEditPermission && dispatch(addNewCargo({ cargo: cargo, firstClick: firstCargoClick }))} />
      </ButtonRow>
    </SectionContainer>
  )
}

export default CargosRows