/**
 * Entities scene
 *
 * @author João Dias <jdias@ubiwhere.com>
 *
 *
 */
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
// Store
import { actions, RootState } from 'store/rootSlices'
// Componentes
import Entitytablerow from './components/Entitytablerow'
import SearchBox from 'shared/components/SearchBox'
import CustomButton from 'shared/components/CustomButton'
import Loadingcontainer from 'shared/components/Loadingcontainer'
import TableContainer from 'shared/components/TableContainer'
import SorterComponent from 'shared/components/SorterComponent'
// Styles
import { Wrapper, Controls1, Controls2, ControlsContainer } from 'shared/genericStyledComponents'
// import { DownloadButton } from './styles'
// Icons
// import Download from 'assets/icons/file_download';
import Feed from 'assets/icons/feed';
import Users from 'assets/icons/users';
import Business from 'assets/icons/business';
import Ticket from 'assets/icons/ticket'
// Types
import { EntityListItem } from 'types/entity/entity'
// Utils & Configs
import config from 'config'
import { paginationCurrentPage, paginationElementsCount } from 'utils'
import { hasPermission } from 'permissions'

//import config from 'config'

const Entities = ({ t, i18n }) => {
  const { sort, loading, page, total, entities, searchTerm } = useSelector((state: RootState) => state.Entities)
  const { onMount, onUnmount, setSearchTerm, setPage, setSort } = actions.Entities
  const { navigateTo } = actions.App
  const { ELEMENTS_PER_PAGE, PERMISSIONS } = config
  const { permissions } = useSelector((state: RootState) => state.App)
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {

    dispatch(onMount(history))
    return () => {
      dispatch(onUnmount())
    }
  }, [dispatch, onMount, onUnmount, history]);

  return (
    <Wrapper>
      <ControlsContainer>
        <Controls1>
          <SearchBox
            id="entities-searchBox"
            defaultValue={searchTerm}
            onSubmit={(term) => {
              let params = new URLSearchParams(history.location.search);
              term !== "" ? params.set('search', term) : params.delete('search')
              history.replace({ search: (params).toString() })
              dispatch(setSearchTerm(term))
            }}
          />
        </Controls1>
        <Controls2>
          {/* Change the permissions when they exist */}
          {/* {hasPermission(permissions, PERMISSIONS.FLEET.CREATE_MAINTENANCE) &&
            <DownloadButton onClick={() => {
              // if(!exportLoading) 
            }}>
              <Download width={30} height={30} />
            </DownloadButton>
          } */}
          {
            hasPermission(permissions, PERMISSIONS.USERS.CREATE_PARTNER) && <CustomButton id="entities-newButton" text={t('common.new')} icon="add" color="success" buttonStyle={{ color: "#FFFFFF" }} onClick={() => dispatch(navigateTo('/entidades/nova-entidade'))} />
          }
        </Controls2>
      </ControlsContainer>
      {loading ? <Loadingcontainer /> : <TableContainer
        count={paginationElementsCount(total)}
        currentPage={paginationCurrentPage(total, page)}
        elementsPerPage={ELEMENTS_PER_PAGE}
        onPageChange={pg => { dispatch(setPage(pg)); }}
        headerElements={[
          <Business />,
          <SorterComponent sort={sort} active={sort.field === "name"} id="entities-nameSorter" filterName={t('common.name')} onChange={(dir) => { dispatch(setSort({ field: "name", direction: dir })) }} />,
          <SorterComponent sort={sort} active={sort.field === "nif"} id="entities-nifSorter" filterName={t('common.nif')} onChange={(dir) => { dispatch(setSort({ field: "nif", direction: dir })) }} />,
          <SorterComponent sort={sort} active={sort.field === "phone"} id="entities-phoneSorter" filterName={t('common.phone')} onChange={(dir) => { dispatch(setSort({ field: "phone", direction: dir })) }} />,
          t('common.email'),
          <SorterComponent sort={sort} active={sort.field === "partner_type"} id="entities-typeSorter" filterName={t('entities.type')} onChange={(dir) => { dispatch(setSort({ field: "partner_type", direction: dir })) }} />,
          t('common.actions')
        ]}
      >
        {entities.map((el: EntityListItem, idx: number) =>
          <React.Fragment key={`entity-list-${idx}`}>
            <Entitytablerow
              id={`entities-tableRow-${idx}`}
              permission={hasPermission(permissions, PERMISSIONS.USERS.VIEW_PARTNER)}
              onClick={() => {
                if (hasPermission(permissions, PERMISSIONS.USERS.VIEW_PARTNER)) {
                  dispatch(actions.App.navigateTo(`/entidades/editar-entidade/${el.id}`))
                }
              }}
              entity={el}
              actions={
                <React.Fragment>
                  <Ticket onClick={() => dispatch(actions.App.navigateTo(`/viagens/reservas?partner=${el.uuid}`))} />
                  <Feed />
                  {
                    hasPermission(permissions, PERMISSIONS.USERS.VIEW_PARTNER_USER) &&
                    <Users
                      id="entities-userLink"
                      onClick={() => {
                        if (hasPermission(permissions, PERMISSIONS.USERS.VIEW_PARTNER_USER)) {
                          dispatch(actions.App.navigateTo(`/entidades/${el.id}/membros`))
                        }
                      }
                      }
                    />
                  }
                </React.Fragment>
              }
            />
          </React.Fragment>
        )}
      </TableContainer>}
    </Wrapper>
  )
}

export default Entities