import { CButton } from '@coreui/react-pro'
import styled from 'styled-components'
import theme, { devicesMax } from 'ui/theme'

export const ComponentsWrapper = styled.div`
  width:100%;
  display:flex;
  flex-direction:row;
  column-gap:24px;
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 250px;
  height: 35px;
`

export const WrapperTank = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap:wrap;
  height: 35px;
  align-self: flex-end;

  @media ${devicesMax.mobileM} {
      margin-bottom: 40px !important;
      flex-wrap: nowrap;
      flex-direction: column;
  }
`

export const GaugeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content:space-between;
  height:100%;
  border-top-right-radius:4px;
  border-bottom-right-radius:4px;
  background-color: ${theme().white};
`

export const ButtonLabel = styled(CButton)`
  display:flex;
  align-items: center;
  height:100%;
  border-top-right-radius:0px;
  border-bottom-right-radius:0px;
  border-color:${theme().gray200Color};
  color:${theme().darkColor};
  background-color: ${theme().gray200Color};
  font-size:14px;
  
  &:focus{
      box-shadow: 0px 0px 0px 0px;
  }
`
export const TextContainer = styled.div`
  font-family: 'Inter';
  font-size: 11px;
  color:${theme().darkColor};
  letter-spacing: 0.1px;
  padding-top:6%;
  padding-left:12px;
  padding-right:12px;


  b {
    font-size: 14px;
  }
`

export const GaugeContainer = styled.div<{ percent: number }>`
  display: grid;
  grid-template-columns: ${props => props.percent}% ${props => 100 - props.percent}%;
  height: 4px;
  width: 100%;
  overflow: hidden;

  .gauge-value {
    background-color: ${props => {
        if (props.percent < 33) {
            return theme().danger;
        }
        if (props.percent < 66) {
            return theme().warning;
        }
        return theme().success;
    }};
  }

  .gauge-empty {
    background-color: ${theme().gray200Color};
  }
`