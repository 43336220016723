/**
 * Tripplannings scene utils
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */

import * as Check from 'validations';
import { DateTime } from 'luxon';
import { Interval } from 'luxon';

export const PlanningStopsValidation = {
  uuid: [],
  maxCapacity: [Check.isRequired, Check.isNumber],
  //time: [Check.isRequired, Check.isNumber]
}

export const ReinforcementCapacityValidation = {
  reinforcementCapacity: [Check.isRequired, Check.isNumber],
}

export const NewVehicleValidation = {
  selectedVehicle: [Check.isRequired],
}

export const StopsValidation = {
  time: [Check.isCorrectTimeFormat],
}

export const UnitsListColors = [
  '#D4A8EA',
  '#EEBB36',
  '#ABDC85'
]

export const createDaysArray = (startDate, endDate) => {
  let interval = Interval.fromDateTimes(startDate, endDate);
  return Array.from(days(interval)).slice(0, 8)
}

function* days(interval) {
  let cursor = interval.start.startOf("day");

  while (cursor <= interval.end) {
    yield cursor;
    cursor = cursor.plus({ days: 1 });
  }
}

export const sortTrips = (locals, planningStops, isCheckinOutboundSelected) => {
  const sortedTrips = [...locals]?.sort((a, b) => {
    if (isCheckinOutboundSelected) {
      const elA = planningStops.find(plStop => {
        if (plStop.isOutbound && plStop.stop.id === a.id) return true
        return false
      })

      const elB = planningStops.find(plStop => {
        if (plStop.isOutbound && plStop.stop.id === b.id) return true
        return false
      })


      if (elA === undefined || elB === undefined) return 0
      if (DateTime.fromISO(elA.time).toMillis() < DateTime.fromISO(elB.time).toMillis()) {
        return -1
      }
      return 1
    }
    else {
      const elA = planningStops.find(plStop => {
        if (!plStop.isOutbound && plStop.stop.id === a.id) return true
        return false
      })

      const elB = planningStops.find(plStop => {
        if (!plStop.isOutbound && plStop.stop.id === b.id) return true
        return false
      })

      if (elA === undefined || elB === undefined) return 0
      if (DateTime.fromISO(elA.time).toMillis() < DateTime.fromISO(elB.time).toMillis()) {
        return -1
      }
      return 1
    }

  })

  return sortedTrips
}