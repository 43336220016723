/**
 * Campaigncodeslisttablerow component
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */
import React from 'react'
import { TableRowPropTypes } from 'types/misc'
import { CTableDataCell } from '@coreui/react-pro'
import ChevronRight from 'assets/icons/chevron_right'
import { CampaignCodes } from 'types/trips/configurations'
import CustomCheckbox from 'shared/components/CustomCheckbox'
import { CustomCTableRow, ActionsCell, ChevronCell } from './styles'

type PropTypes = TableRowPropTypes & {
  id?: string,
  campaignCode: CampaignCodes
}

const Campaigncodelisttablerow = (props: PropTypes) => {

  const {
    actions,
    campaignCode: {
      uid,
      destinationEmail,
      usedAt,
      expireAt,
      isActive
    }
  } = props

  return (
    <CustomCTableRow id={props.id}>
      <CTableDataCell>{uid}</CTableDataCell>
      <CTableDataCell>{destinationEmail}</CTableDataCell>
      <CTableDataCell>{usedAt}</CTableDataCell>
      <CTableDataCell>{expireAt}</CTableDataCell>
      <CTableDataCell>
        <CustomCheckbox
          checkStyle={{ width: '40px', height: '20px', marginTop: '5px' }}
          id={`state`}
          disabled={false}
          checked={isActive}
          onClick={e => {
            
          }}
          switch
        />
      </CTableDataCell>
      <ActionsCell>
        {actions}
        <ChevronCell id={`${props.id}-chevron`} onClick={(e) => props.onClick(e)}><ChevronRight></ChevronRight></ChevronCell>
      </ActionsCell>
    </CustomCTableRow>
  )
}

export default Campaigncodelisttablerow