/**
 * Customerpurchasehistory scene slice
 *
 * @author João Dias <jdias@ubiwhere.com>
 *
 *
 */
import { createSlice } from '@reduxjs/toolkit'
import { SortType } from 'types/misc'
import { TripBookingListItem } from 'types/trips/bookings'

interface InitialState {
  bookings: TripBookingListItem[],
  loading: boolean,
  total: number,
  page: number,
  pageSize: number,
  customerUUID: string,
  searchTerm: string,
  sort: SortType
}

const initialState: InitialState = {
  bookings: [],
  loading: false,
  total:0,
  page:1,
  pageSize:6,
  customerUUID: "",
  searchTerm:"",
  sort: {
    field: null,
    direction: null,
  },
}

export default createSlice({
  name: 'Customerpurchasehistory',
  initialState,
  reducers: {
    onMount: (state,  { payload }) => { },
    onUnmount: (state) => { 
      state.page = 1
      state.total = 0
    },
    setSort: (state, { payload }) => {
      state.sort = payload;
    },
    setLoading: (state, {payload}) => {
      state.loading = payload
    },
    setTotal:(state, {payload}) => {
      state.total = payload
    },
    setPage:(state, {payload}) => {
      state.page = payload
    },
    setCostumerUUID: (state, {payload}) => {
      state.customerUUID = payload
    },
    setBookings: (state, {payload}) => {
      state.bookings = payload
    },
    setSearchTerm:(state, {payload}) => {
      state.page = 1
      state.searchTerm = payload
    }
  }
})