import { takeLatest, putResolve, call } from 'redux-saga/effects'
import { PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import i18n from 'i18next';
import { actions } from 'store/rootSlices'
import API from 'api';
import type { BiDashboard } from 'types/trips/bi'


function* onMountSaga({ payload }: PayloadAction<string>) {
  if (payload) {
    yield putResolve(actions.BiDashboard.getDashboard(payload));

  }
}

function* getDashboardSaga({ payload }: PayloadAction<string>) {
  yield putResolve(actions.BiDashboard.setLoading(true))

  try {
    const dashboard: BiDashboard = yield call(API.Bi.GetDashboard, payload);

    if (dashboard) {
      yield putResolve(actions.BiDashboard.setDashboard(dashboard))
    }
  } catch (error) {
    toast.error(i18n.t("toasts.getDashboardError"))
  } finally {
    yield putResolve(actions.BiDashboard.setLoading(false))
  }
}

export default function* watcherSignin() {
  yield takeLatest('BiDashboard/onMount', onMountSaga)
  yield takeLatest('BiDashboard/getDashboard', getDashboardSaga)

}


