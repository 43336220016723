/**
 * Passengercontrol scene slice
 *
 * @author ManuelGil <mgil@ubiwhere.com>
 *
 *
 */
import { createSlice } from '@reduxjs/toolkit'
import { DateTime } from 'luxon';
import { SortType } from 'types/misc';
import { PlanningListItem, NewPlanningForm, Vehicle } from 'types/trips/plannings'
import { initialTripForm } from '../utils';

interface initialState {
  loading: boolean,
  tableLoading: boolean,
  page: number,
  showPassengerTypes: boolean,
  boardingFilter: { label: string, value: string },
  stopFilter: { label: string, value: string },
  startDate: Date,
  endDate: Date,
  searchTerm: string,
  sort: SortType
  checkinsCounter: any
  checkins: any[]
  totalCheckins: number,
  showWarningModal: boolean,
  warningModalData: {
    id: number | null, 
    status: string, 
    location: number | null,
    outbound: boolean,
    checkinId: number | null,
    checkoutId: number | null
  },
  plannings: PlanningListItem[],
  planningsPage: number,
  isPlanningsLastPage: boolean,
  newPlanningForm: NewPlanningForm,
  planningModalLoading: boolean,
  outboundSelected: boolean,
  checkingIn: boolean,
  tripModal: {
    isVisible: boolean,
    vehicle: Vehicle | null,
    initialPlanningId: number,
    planning: any,
    lastCheckin: any
  }
}

const now = DateTime.now().toJSDate()

const initialState: initialState = {
  loading: false,
  tableLoading: false,
  page: 1,
  showPassengerTypes: false,
  sort: {
    field: null,
    direction: null,
  },
  boardingFilter: { label: "", value: "" },
  stopFilter: { label: "", value: "" },
  startDate: now,
  endDate: now,
  searchTerm: "",
  checkinsCounter: null,
  checkins: [],
  totalCheckins: 0,
  showWarningModal: false,
  warningModalData: {
    id: null, 
    status: "", 
    location: null,
    outbound: false,
    checkinId: null,
    checkoutId: null
  },
  plannings: [],
  planningsPage: 1,
  isPlanningsLastPage: false,
  newPlanningForm: initialTripForm,
  planningModalLoading: false,
  outboundSelected: true,
  checkingIn: false,
  tripModal: {
    isVisible: false,
    vehicle: {
      capacity: -1,
      color: "",
      createdAt: "",
      isAvaiable: true,
      name: "",
      shortName: "",
      updatedAt: "",
      uuid: "",
      vehicleClass: "",
      vehicleType: ""
    },
    initialPlanningId: -1,
    planning: {},
    lastCheckin: {}
  }
}



export default createSlice({
  name: 'Passengercontrol',
  initialState,
  reducers: {    
    onMount: (state, { payload }) => { },
    onUnmount: () => {
      return initialState;
    },
    setShowWarningModal: (state, { payload }) => {
      state.showWarningModal = payload
    },
    setWarningModalData: (state, { payload }) => {
      state.warningModalData = payload
    },
    setLoading: (state, { payload }) => {
      state.loading = payload
    },
    setTableLoading: (state, { payload }) => {
      state.loading = payload
    },
    setPage: (state, { payload }) => {
      state.page = payload
    },
    setSort: (state, { payload }) => {
      state.sort = payload;
    },
    getURLParams:(state, {payload}) => {},
    setSearchTerm: (state, { payload }) => {
      state.page = 1
      state.checkins = []
      state.searchTerm = payload
      state.boardingFilter = { label: "", value: "" }
      state.stopFilter = { label: "", value: "" }
    },
    setDate: (state, { payload }) => {
      state.page = 1
      state.checkins = []
      state.startDate = payload
      state.boardingFilter = { label: "", value: "" }
      state.stopFilter = { label: "", value: "" }
    },
    setBoardingFilter: (state, { payload }) => {
      state.boardingFilter = { label: payload.boardingName, value: payload.boardingId.toString() }
    },
    setStopFilter: (state, { payload }) => {
      if (payload.fromTableHeader) {
        const stopShortName = payload.stop.target.innerText;
        const locals = payload.locals
        const stop = locals.find(l => l.shortName === stopShortName)
        if (stop) state.stopFilter = { label: stop.name, value: stop.id.toString() }
      }
      else {
        state.stopFilter = { label: payload.stopName, value: payload.stopId.toString() }
      }
    },
    setShowPassengerTypes: (state, { payload }) => {
      state.showPassengerTypes = payload
    },
    setCheckinsCounter: (state, { payload }) => {
      state.checkinsCounter = payload
    },
    getCheckins: () => { },
    setTotalCheckins: (state, { payload }) => {
      state.totalCheckins = payload
    },
    setCheckins: (state, { payload }) => {
      state.checkins = payload
    },
    setNewCheckLocation: (state, { payload }) => {},
    getPlannings: () => {},
    setPlannings: (state, { payload }) => {
      const planningsWithUnits = payload.newPlannings.filter((pl) => pl.vehicles.length > 0)
      state.plannings = [...payload.plannings, ...planningsWithUnits];
    },
    setPlanningsPage: (state, { payload }) => {
      state.planningsPage = payload;
    },
    setIsPlanningsLastPage: (state, { payload }) => {
      state.isPlanningsLastPage = payload
    },
    onPlanningFormChange: (state, { payload }) => {
        state.newPlanningForm = payload;
    },
    setPlanningModalLoading: (state, { payload }) => {
      state.planningModalLoading = payload
    },
    toggleTripModal: (state, { payload}) => {},
    setTripModal: (state, { payload }) => {
      state.tripModal = payload
    },
    setOutboundSelected: (state, { payload }) => {
      state.outboundSelected = payload
    },
    setNewCheckin: (state, { payload }) => {},
    setCheckingIn: (state, { payload }) => {
      state.checkingIn = payload
    },
    fetchCheckins: (state, { payload }) => {}
  }
})