/**
 * Tripcampaigncodes scene
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom';
import { actions, RootState } from 'store/rootSlices'
import TableContainer from 'shared/components/TableContainer'
import CustomButton from 'shared/components/CustomButton'
import DropdownExport from 'shared/components/FormComponents/dropdownexport'
import SearchBox from 'shared/components/SearchBox'
import { CampaignCodes } from 'types/trips/configurations'
import Campaigncodelisttablerow from './components/Campaigncodeslisttablerow'
import { CustomControlsContainer } from 'shared/genericStyledComponents'
import { Wrapper } from './styles'

import Feed from 'assets/icons/feed'
import Print from 'assets/icons/print'

//import config from 'config'

const Tripcampaigncodes = ({ t, i18n }) => {

  const { campaignCodes, campaignCodeID, campaignPrefix } = useSelector((state: RootState) => state.TripCampaignCodes)

  const { onMount, onUnmount } = actions.TripCampaignCodes
  const { navigateTo, /* api_t */ } = actions.App

  const dispatch = useDispatch()
  const history = useHistory();

  useEffect(() => {

    dispatch(onMount(history))
    return () => {
      dispatch(onUnmount())
    }
  }, [dispatch, onMount, onUnmount, history]);

  return (
    <Wrapper>
      <CustomControlsContainer>
        <div>
          <SearchBox id="tripCampaignCodes-searchBox" onSubmit={() => { }} />
        </div>
        <div>
          <DropdownExport id="tripCampaignCodes-dropdownExport" wrapperStyle={{ marginBottom: 0 }} />
          <CustomButton id="tripCampaignCodes-newButton" onClick={() => dispatch(navigateTo(`/viagens/configuracoes/campanhas/codigos/novo-codigo/${campaignCodeID}/${campaignPrefix}`))} text={`Gerar Código`} icon="add" color="success" buttonStyle={{ color: "#FFFFFF" }} />
        </div>
      </CustomControlsContainer>
      <TableContainer
        count={21}
        elementsPerPage={10}
        currentPage={1}
        onPageChange={() => { }}
        headerElements={[
          "Code",
          'Sent Email',
          'Used At',
          'Expiration',
          t('tripSettings.commonAvailability'),
          t('common.actions'),
        ]}
      >
        {campaignCodes.map((el: CampaignCodes, idx: number) =>
          <React.Fragment key={`activ-${idx}`}>
            <Campaigncodelisttablerow
              id={`tripCampaignCodes-tablerow-${idx}`}
              onClick={(e) => { dispatch(actions.App.navigateTo(`/viagens/configuracoes/campanhas/codigos/editar-codigo/${campaignCodeID}/${campaignPrefix}`)) }}
              campaignCode={el}
              actions={
                <React.Fragment>
                  <Feed />
                  <Print />
                </React.Fragment>
              }
            />
          </React.Fragment>
        )}
      </TableContainer>
    </Wrapper>
  )
}

export default Tripcampaigncodes