/**
 * Customerstablerow component
 *
 * @author João Dias <jdias@ubiwhere.com>
 *
 *
 */

import { CAvatar, CTableDataCell } from '@coreui/react-pro';
import React from 'react'
import {
  CustomTableRow,
  ActionsCell,
  ChevronCell,
  ImageCell,
  NameAndRoleCell
} from 'shared/genericStyledComponents'

import { CustomerListItem } from 'types/customers/customers'
import ChevronRight from 'assets/icons/chevron_right';
import User from 'assets/icons/user';
import { useSelector } from 'react-redux';
import { RootState } from 'store/rootSlices';
import { actions } from 'store/rootSlices'
import { useDispatch } from 'react-redux';

interface PropTypes {
  id?: string,
  customer: CustomerListItem;
  onClick: Function,
  actions: JSX.Element,
  permission: boolean
}

const Customerstablerow = (props: PropTypes) => {
  const {
    permission,
    customer: {
      name,
      avatar,
      phone,
      email,
      country,
      uuid
    }
  } = props;

  const { countryCodes } = useSelector((state: RootState) => state.App)
  const dispatch = useDispatch()

  return (
    <CustomTableRow id={props.id} >
      <ImageCell><div onClick={() => props.onClick()}>
        {avatar ?
          <CAvatar color='secondary' src={avatar}></CAvatar>
          :
          <CAvatar color='secondary'><User /></CAvatar>
        }
      </div>
      </ImageCell>
      <NameAndRoleCell><h5 onClick={() => props.onClick()}>{name}</h5></NameAndRoleCell>
      <CTableDataCell>{phone}</CTableDataCell>
      <CTableDataCell>{email}</CTableDataCell>
      <CTableDataCell>{countryCodes[country]}</CTableDataCell>
      <ActionsCell nopointer>
        {props.actions}
        <ChevronCell id={`${props.id}-chevron`}>
          {
            permission &&
            <a href={`/clientes/editar-cliente/${uuid}`} onClick={(e) => {
              e.preventDefault()
              dispatch(actions.App.navigateTo(`/clientes/editar-cliente/${uuid}}`))
            }}>
              <ChevronRight></ChevronRight>
            </a>
          }
        </ChevronCell>
      </ActionsCell>
    </CustomTableRow>
  )
}

export default Customerstablerow

