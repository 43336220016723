import i18n from 'i18next';
import { BookingLeg, BookingPaymentEntry, CancelRescheduleSettings } from 'types/trips/bookings';
import { addFeeEntrie } from 'scenes/BookingForm/logic/utils';

export const getCanceledEntries = (isOutbound: boolean, bookedEntries: BookingPaymentEntry[], isBeach?: true) => {
  let canceledEntries: BookingPaymentEntry[] = []
  if (isOutbound) canceledEntries = bookedEntries.filter((e) => e.leg?.isOutbound && e.state === 'pending' && !e.isFee && e.leg?.isCanceled)
  if (!isOutbound) canceledEntries = bookedEntries.filter((e) => !e.leg?.isOutbound && e.state === 'pending' && !e.isFee && e.leg?.isCanceled)
  if (isBeach) canceledEntries = bookedEntries.filter((e) => e.leg?.beachTripActivity && e.state === 'pending' && !e.isFee && e.leg?.isCanceled)
  return canceledEntries
}

export const ifHopOnHopOffAddFee = (entries: BookingPaymentEntry[], bookedInbound: BookingLeg, applyFeeToTrips: boolean, tripsCancelRescheduleSettings: CancelRescheduleSettings, initialEntries: BookingPaymentEntry[]) => {
  let hasInPendingEntries = entries.some((e) => !e.leg?.isOutbound && e.state === 'pending')
  let hasInPaidEntries = entries.some((e) => !e.leg?.isOutbound && e.state === 'paid' && !e.isFee)

  if (hasInPendingEntries) {
    entries = entries.filter((e) => !(!e.leg?.isOutbound && e.state === 'pending'))

    //* Concat booked inbound fee entries if there where any
    entries = entries.concat(getBookedFeeEntries(false, initialEntries))
  }

  if (hasInPaidEntries) {
    let amount = getPaidEntriesTotal(false, entries)
    entries.push({ //* Here we add refund inbound entrie
      id: 0,
      state: 'pending',
      leg: { ...bookedInbound },
      amount: +amount,
      isRefund: true,
      isFee: false,
      description: `${bookedInbound.tripActivityLeg.activity.name} - ${i18n.t('tripBookings.accountSettlement')}`,
      canceled: false,
      isBecausePassenger: false
    });
  }

  if (bookedInbound.id > 0) {
    //* Here we add fee if necessary
    entries = addFeeEntrie(false, false, entries, bookedInbound, applyFeeToTrips, tripsCancelRescheduleSettings)
  }

  return entries
}

export const getPaidEntriesTotal = (isOutbound: boolean, entries: BookingPaymentEntry[], isBeach?: true) => {
  let filteredEntries: BookingPaymentEntry[] = []
  if (isOutbound) filteredEntries = entries.filter((e) => e.leg?.isOutbound && e.state === 'paid' && !e.isFee)
  if (!isOutbound) filteredEntries = entries.filter((e) => !e.leg?.isOutbound && e.state === 'paid' && !e.isFee)
  if (isBeach) filteredEntries = filteredEntries.filter((e) => e.leg?.beachTripActivity && e.state === 'paid' && !e.isFee)

  return filteredEntries.reduce((sum, e) => Number(sum) + Number(e.amount), 0)
}

export const getBookedFeeEntries = (isOutbound: boolean, bookedEntries: BookingPaymentEntry[]) => {
  let feeEntries: BookingPaymentEntry[] = []
  if (isOutbound) feeEntries = bookedEntries.filter((e) => e.leg?.isOutbound && e.state === 'pending' && e.isFee)
  if (!isOutbound) feeEntries = bookedEntries.filter((e) => !e.leg?.isOutbound && e.state === 'pending' && e.isFee)
  //we might need to add beach fee entries

  return feeEntries
}