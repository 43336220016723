import API from 'api'
import { putResolve, call, select } from 'redux-saga/effects'
import { actions, RootState } from 'store/rootSlices'
import { toast } from 'react-toastify'
import i18n from 'i18next';
import { AnyAction } from '@reduxjs/toolkit';

export function* getBeachTripDetailsSaga({ payload }: AnyAction) {
  const tripId = payload.id

  const {
    tripsDetails
  } = yield select((state: RootState) => state.TripPlannings);

  try {
    const planningInfo = yield call(API.Plannings.GetTripDetails, tripId)

    if (planningInfo) {
      yield putResolve(actions.TripPlannings.setTripsDetails({ planningInfo, oldDetails: tripsDetails, id: tripId }))
    }

  } catch (error) {
    toast.error(i18n.t("toasts.planningGetDetailsError"))

  }
}
