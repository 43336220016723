/**
 * Entitytablerow component
 *
 * @author João Dias <jdias@ubiwhere.com>
 *
 *
 */

import { CAvatar, CTableDataCell } from '@coreui/react-pro';
import React from 'react'
import ChevronRight from 'assets/icons/chevron_right';
import Business from 'assets/icons/business';
import { CustomTableRow, ActionsCell, ChevronCell, ImageCell } from 'shared/genericStyledComponents'
import { actions } from 'store/rootSlices'
import { useDispatch } from 'react-redux';
import { EntityListItem } from 'types/entity/entity'



type PropTypes = {
  id?: string,
  entity: EntityListItem,
  actions: JSX.Element,
  onClick: Function,
  permission: boolean
}

const Entitytablerow = (props: PropTypes) => {

  const dispatch = useDispatch()
  const {
    entity: {
      avatar,
      name,
      nif,
      phone,
      email,
      partnerType,
      id
    },
    permission
  } = props;


  return (
    <CustomTableRow id={props.id}>
      <ImageCell>
        <div onClick={() => props.onClick()}>
          {avatar ?
            <CAvatar color='secondary' src={avatar}></CAvatar>
            :
            <CAvatar color='secondary'><Business /></CAvatar>
          }
        </div>
      </ImageCell>
      <CTableDataCell>{name}</CTableDataCell>
      <CTableDataCell>{nif}</CTableDataCell>
      <CTableDataCell>{phone}</CTableDataCell>
      <CTableDataCell>{email}</CTableDataCell>
      <CTableDataCell>{partnerType?.name}</CTableDataCell>
      <ActionsCell>
        {props.actions}
        <ChevronCell id={`${props.id}-chevron`}>
          {
            permission &&
            <a href={`/entidades/editar-entidade/${id}`} onClick={(e) => {
              e.preventDefault()
              dispatch(actions.App.navigateTo(`/entidades/editar-entidade/${id}`))
            }}>
              <ChevronRight></ChevronRight>
            </a>
          }
        </ChevronCell>
      </ActionsCell>
    </CustomTableRow>
  )
}

export default Entitytablerow