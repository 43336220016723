/**
 * Sorter component
 *
 * @author João Dias <jdias@ubiwhere.com>
 *
 *
 */

import React, { useEffect, useState } from 'react'
import SorterArrow from 'assets/icons/sorter_arrow';
import { SortType } from 'types/misc';
import { Wrapper, SelectContainer, CurrentValueContainer, ArrowContainer} from './styles'

type PropTypes = {
  id?: string,
  sort?: SortType,
  filterName: string,
  onChange: Function,
  className?: string,
  active?: boolean,
  colSpan?: number,
  icon?: JSX.Element,
}

const ref = React.createRef<HTMLDivElement>();

const SorterComponent = (props: PropTypes) => {
  const [current, setCurrent] = useState<"" | "desc" | "asc">("");

  const isDefaultSelected = current === "";

  useEffect(() => {
    if (!props.active) {
      setCurrent("");
    } else {
      setCurrent(props.sort?.direction ?? "");
    }
  }, [props.active, props.sort])

  return (
    <Wrapper className={props.className} ref={ref} hasIcon={props.icon ? true : false}>
      <SelectContainer id={props.id} isDefaultSelected={isDefaultSelected} hasIcon={props.icon ? true : false} onClick={() => {
        let next: "" | "desc" | "asc" = "";
        switch (current) {
          case "": next = "asc"; break;
          case "asc": next = "desc"; break;
          default: next = "";
        };
        setCurrent(next);
        props.onChange(next);
      }}>
        {
          props.icon ? props.icon : (
            <>
              <CurrentValueContainer>{props.filterName}</CurrentValueContainer>
              <ArrowContainer current={current}>
                <SorterArrow />
              </ArrowContainer>
            </>
          )
        
        }
      </SelectContainer>
    </Wrapper>
  )
}

export default SorterComponent