import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { actions, RootState } from 'store/rootSlices'
import i18n from 'i18next'
//Components
import SorterComponent from 'shared/components/SorterComponent'
import TableContainer from 'shared/components/TableContainer'
import NewTableContent from '../NewTableContent'
import RescheduleTableContent from '../RescheduleTableContent'
import EditTableContent from '../EditTableContent'

type CustomTableProps = {
  isEdit: boolean;
  isReschedule: boolean;
}

const CustomLegsTable = ({ isEdit, isReschedule }: CustomTableProps) => {
  const {
    page,
    sort,
    totalLegs,
  } = useSelector((state: RootState) => state.BookingForm);

  const {
    setPage,
    setSort,
  } = actions.BookingForm;

  const dispatch = useDispatch();

  const _renderTables = () => {
    return isReschedule ? <RescheduleTableContent /> : <NewTableContent />
  }

  return isEdit && !isReschedule ? (
    <TableContainer
      count={0}
      elementsPerPage={0}
      currentPage={-1}
      onPageChange={() => { }}
      headerElements={[
        i18n.t('tripPlanningForm.activity'),
        i18n.t('common.date'),
        i18n.t('tripBookings.departureHour'),
        i18n.t('tripBookings.trip'),
        i18n.t('tripBookings.passengers'),
        i18n.t('tripBookings.checkin'),
        i18n.t('common.price'),
        i18n.t('common.actions'),
      ]}
    >   
      <EditTableContent />
    </TableContainer>
  ) : (
    <TableContainer
      count={totalLegs}
      elementsPerPage={6}
      currentPage={page}
      onPageChange={pg => { dispatch(setPage(pg)); }}
      headerElements={[
        <SorterComponent
          id="tripLegs-activitySorter"
          filterName={ i18n.t('tripPlanningForm.activity')}
          colSpan={3}
          active={sort.field === "activity"}
          onChange={(dir) => { dispatch(setSort({ field: "activity", direction: dir })) }}
        />,
        <SorterComponent
          id="tripLegs-dateSorter"
          filterName={ i18n.t('common.date')}
          active={sort.field === "date"}
          onChange={(dir) => { dispatch(setSort({ field: "date", direction: dir })) }}
        />,
        <SorterComponent
          id="tripLegs-timeSorter"
          filterName={ i18n.t("tripBookings.departureHour")}
          active={sort.field === "time"}
          onChange={(dir) => { dispatch(setSort({ field: "time", direction: dir })) }}
        />,
        i18n.t("tripBookings.trip"),
        i18n.t("tripBookings.availabilityShort"),
        i18n.t("common.price"),
      ]}
    >
      { _renderTables() }
    </TableContainer>
  )
};

export default CustomLegsTable