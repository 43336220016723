/**
 * Configurations scene
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'

// Styles
import theme from 'ui/theme'
import { 
  Wrapper, 
  ControlsContainer,
  Controls1
} from 'shared/genericStyledComponents' 
import { Text, SmallSpan, ButtonWrapper, CustomCommissionTable, CurrentValueContainer } from './styles'
// Store
import { actions, RootState } from 'store/rootSlices'
// Componentes
import SearchBox from 'shared/components/SearchBox'
import TabsContainer from 'shared/components/Tabscontainer'
import TableContainer from 'shared/components/TableContainer'
import Serviceconfigurationrow from './components/Serviceconfigurationrow'
import CustomButton from 'shared/components/CustomButton'
import { paginationElementsCount, paginationCurrentPage } from 'utils'
import Loadingcontainer from 'shared/components/Loadingcontainer'
import Entitycommissiontablerow from 'scenes/Entities/components/Entitycommissiontablerow'
import SectionContainer from '../../shared/components/Sectioncontainer/index'
import CustomModal from 'shared/components/CustomModal'


const Configurations = ({ t, i18n }) => {

  const { 
    configurations, 
    loading,
    activitiesForm,
    extrasForm,
    activitiesCommissionsDefault,
    extrasCommissionsDefault,
    confirmAllModal,
    isApplyAllOnActivities,
    comissionsChanged,
    hasEditPermissions
  } = useSelector((state: RootState) => state.Configurations)
  
  const { 
    onMount, 
    onUnmount, 
    setSearchTerm, 
    onSubmit, 
    setConfirmAllModal,
    onActivityCommissionChange,
    onExtraCommissionChange,
    onActivitiesDefaultChange,
    onExtrasDefaultChange,
    applyValueOnAllActivitiesComissions,
    applyValueOnAllExtrasComissions,
    onSubmitCommissions,
    changeDefaultCommissionValues,
    onCommissionChangeToggler
  } = actions.Configurations
  //const { api_t } = actions.App

  const dispatch = useDispatch()
  const dbDispatch = _.debounce(dispatch, 500);

  useEffect(() => {

    dispatch(onMount())
    return () => {
      dispatch(onUnmount())
    }
  }, [dispatch, onMount, onUnmount]);

  const handleSubmit = () => {
    if(configurations.length > 0 && comissionsChanged) {
      dispatch(onSubmit(configurations))
      dispatch(onSubmitCommissions())
    } else if(configurations.length > 0) {
      dispatch(onSubmit(configurations))
    } else if(comissionsChanged) {
      dispatch(onSubmitCommissions())
    }
    return {}
  }

  return (
    <Wrapper>
      <ControlsContainer>
        <Controls1>
          <SearchBox
            id="invoicingRequests-searchBox"
            onSubmit={(term) => { dbDispatch(setSearchTerm(term)) }}
          />
        </Controls1>
      </ControlsContainer>
      {
        loading ?
          <Loadingcontainer />
          :
          <>
            <CustomModal
              id={`entityForm-confirmAllModal`}
              warningType='message'
              visible={confirmAllModal}
              buttonIcon={true}
              footerPosition='center'
              textAlign='center'
              buttonName={i18n.t('common.confirm')}
              children={isApplyAllOnActivities ? <p>{i18n.t('entityForm.confirmAllModalActivities')}</p> : <p>{i18n.t('entityForm.confirmAllModalExtras')}</p>}
              onclose={e => dispatch(setConfirmAllModal({ confirmAll: false, isApplyAllOnActivities }))}
              onsubmit={e => {
                isApplyAllOnActivities === true ?
                  dispatch(applyValueOnAllActivitiesComissions({ defaultCommission: activitiesCommissionsDefault, activitiesForm }))
                  :
                  dispatch(applyValueOnAllExtrasComissions({ defaultCommission: extrasCommissionsDefault, extrasForm }))
              }} 
            />
            <SectionContainer title={t('configurations.cancellations')} style={{marginBottom: "30px"}}>
              <TabsContainer id="invoicingRequests-tabsContainer" onChangeTab={tab => {}} content={[
                {
                  title: t('configurations.cancellations'),
                  content: <TableContainer
                    count={paginationElementsCount(0)}
                    currentPage={paginationCurrentPage(0, 1)}
                    elementsPerPage={-1}
                    onPageChange={(pg) => {}}
                    headerElements={[
                      t('configurations.services'),
                      t('configurations.invoiceArticle'),
                      t('configurations.chargedFee'),
                      <Text>{t('configurations.time')}<SmallSpan>{` ${t('configurations.hours')}`}</SmallSpan></Text>,
                      <Text>{t('configurations.reschedule')}<SmallSpan>{` ${t('configurations.hours')}`}</SmallSpan></Text>,
                      t('configurations.cancellationLimit'),
                      '',
                    ]}
                  >
                    {
                      configurations.map((conf, idx: number) => {
                        return (
                          <Serviceconfigurationrow
                            id={idx}
                            configuration={conf}
                          />
                        )
                      })
                    }
                  </TableContainer>
                },
              ]} />
            </SectionContainer>
            <SectionContainer title={t('configurations.commissions')}>
              <TabsContainer 
                id="configurations-commissionsTabs" 
                onChangeTab={() => { }} 
                content={[
                  {
                    title: t('configurations.activitiesCommissions'),
                    content: <CustomCommissionTable key="customCommissionTable1"
                      headerElements={[
                        <CurrentValueContainer>{i18n.t('entityForm.activities')}</CurrentValueContainer>,
                        <div></div>,
                        <CurrentValueContainer>{i18n.t('entityForm.commissionValue')}</CurrentValueContainer>,
                        <div />,
                        <div />
                      ]}
                      count={0}
                      currentPage={-1}
                      elementsPerPage={0}
                      onPageChange={() => { }}>
                      <Entitycommissiontablerow
                        id={`entityForm-activityCommissionDefault`}
                        hasEditPermission={hasEditPermissions}
                        onClickAll={() => dispatch(setConfirmAllModal({ confirmAll: true, isApplyAllOnActivities: true }))}
                        onChange={(id, e) => {
                          dispatch(onActivitiesDefaultChange(e))
                          dispatch(changeDefaultCommissionValues({ isActivity: true, value: e }))
                        }}
                        isMain
                        commission={{ uuid: "0", name: "Comissão-padrão", isCustom: true, commission: {value: activitiesCommissionsDefault, valid: true, message: ""} }} 
                      />
                      {activitiesForm.map((act, idx: number) => {
                        return (
                          <Entitycommissiontablerow
                            id={`entityForm-activityCommission${idx}`}
                            key={`entityForm-activityCommission${idx}`}
                            onChange={(id, e) => dispatch(onActivityCommissionChange({ idx, e }))}
                            onChangeToggler={(e) => dispatch(onCommissionChangeToggler({ idx, value: e, isActivity: true, default: activitiesCommissionsDefault }))}
                            commission={act}
                            hasEditPermission={hasEditPermissions}
                            showCheckbox={false}
                          />
                        )
                      })}
                    </CustomCommissionTable>
                  },
                  {
                    title: i18n.t('entityForm.extrasCommissions'),
                    content: <CustomCommissionTable key="customCommissionTable2"
                      headerElements={[
                        <CurrentValueContainer>{i18n.t('entityForm.extras')}</CurrentValueContainer>,
                        <div></div>,
                        <CurrentValueContainer>{i18n.t('entityForm.commissionValue')}</CurrentValueContainer>,
                        <div />,
                        <div />
                      ]}
                      count={0}
                      currentPage={-1}
                      elementsPerPage={0}
                      onPageChange={() => { }}>
                      <Entitycommissiontablerow
                        id={`entityForm-extraCommissionDefault`}
                        hasEditPermission={hasEditPermissions}
                        onClickAll={() => dispatch(setConfirmAllModal({ confirmAll: true, isApplyAllOnActivities: false }))}
                        onChange={(id, e) => {
                          dispatch(onExtrasDefaultChange(e))
                          dispatch(changeDefaultCommissionValues({ isActivity: false, value: e }))
                        }}
                        isMain
                        commission={{ uuid: "0", name: "Comissão-padrão", isCustom: true, commission: {value: extrasCommissionsDefault, valid: true, message: ""} }} />
                      {extrasForm.map((ext, idx: number) => {
                        return (
                          <Entitycommissiontablerow
                            key={`entityForm-extraCommission${idx}`}
                            commission={ext}
                            hasEditPermission={hasEditPermissions}
                            id={`entityForm-extraCommission${idx}`}
                            onChange={(id, e) => dispatch(onExtraCommissionChange({ idx, e }))}
                            showCheckbox={false}
                          />
                        )
                      })}
                    </CustomCommissionTable>
                  }
                ]}
              />
            </SectionContainer>
            
            <ButtonWrapper>
              <CustomButton id='configurations-cancel' onClick={() => dispatch(onUnmount())} text={i18n.t('common.discardChanges')} color="link" buttonStyle={{ color: theme().danger, textDecoration: 'unset' }} />
              <CustomButton id='configurations-save' text={i18n.t('common.saveBtn')} icon="confirm" onClick={handleSubmit} color="success" buttonStyle={{ color: "#FFFFFF" }} />
            </ButtonWrapper>
          </>
      }
    </Wrapper>
  )
}

export default Configurations