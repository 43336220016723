import styled from 'styled-components'
import { CTableDataCell, CTableFoot } from '@coreui/react-pro'
import theme from 'ui/theme'

const PaymentStateCell = styled(CTableDataCell)`
    &.refund {
        color: ${theme().danger};
    }
    &.payment {
        color: ${theme().success};
    }
    &.pending {
        color: ${theme().warning};
    }
    &.canceled {
        color: ${theme().gray500Color};
    }
`;

const RefundLabel = styled.span`
    padding:2px 4px;
    color:${theme().white};
    font-weight:400;
    font-size: 12px;
    border-radius:5px;
    background-color: ${theme().danger};
`;

const PendingPaymentsFooter = styled(CTableFoot)`
  tr {
      background-color: ${theme().success10};
      border: solid 1px ${theme().gray200Color};

      &.extra {
          background-color: ${theme().primary10};
          td {
              color:${theme().primary};
              font-weight: normal;
          }
      }
      &.refund {
          background-color: ${theme().danger10};
          td {
              color:${theme().danger};
          }
      }

      td {
          color:${theme().success};
          font-size: 14px;
          font-weight: 700;
          span {
              font-weight: 400;
          }
      }
  }
`;

export {
  RefundLabel,
  PaymentStateCell,
  PendingPaymentsFooter,
}