/**
 * Vehicleform scene utils
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */
import { VehicleDocumentsForm, VehicleForm } from 'types/fleet/vehicle'
import * as Check from 'validations'

export const VehicleFormValidationsAvailable = {
    name: [Check.isRequired],
    vehicleTypeId: [Check.isRequired],
    description: [Check.hasValue],
    color: [Check.isRequired],
    shortName: [Check.isRequired],
    plateNum: [Check.isRequired],
    motor: [Check.isRequired],
    vehicleClassId: [Check.isRequired],
    fuelCapacity: [Check.isRequired],
    fuelTypeId: [Check.isRequired],
    capacity: [Check.isRequired],
    location: [Check.isRequired],
    nextMaintenance: [],
    documents: [Check.hasValue],
    nextMaintenanceLocation: [],
    isAvailable: [Check.isRequired],
}

export const VehicleFormValidationsUnavailable = {
    ...VehicleFormValidationsAvailable,
    unavailabilityStart: [Check.isRequired],
    unavailabilityEnd: [Check.isRequired],
    unavailabilityText: [Check.isRequired],
}


export const DocumentFormValidations = {
    documentType: [Check.isRequired, Check.isValidId],
    expiration: [],
    file: [Check.hasValue, Check.isRequired],
}


export const defaultDocument: VehicleDocumentsForm = {
    documentType: {
        value: -1,
        valid: true,
        message: ""
    },
    expiration: {
        value: "",
        valid: true,
        message: ""
    },
    fileName: "",
    url: "",
    file: {
        value: null,
        valid: true,
        message: ""
    },
    id: {
        value: -1,
        valid: true,
        message: ""
    },
    isActive: {
        value: true,
        valid: true,
        message: ""
    },
    dirty: false,
    new: true,
}

export const defaultForm: VehicleForm = {
    name: {
        value: "",
        valid: true,
        message: "",
    },
    vehicleTypeId: {
        value: "",
        valid: true,
        message: "",
    },
    description: {
        value: "",
        valid: true,
        message: "",
    },
    color: {
        value: "",
        valid: true,
        message: "",
    },
    shortName: {
        value: "",
        valid: true,
        message: "",
    },
    plateNum: {
        value: "",
        valid: true,
        message: "",
    },
    motor: {
        value: "",
        valid: true,
        message: "",
    },
    vehicleClassId: {
        value: "",
        valid: true,
        message: "",
    },
    fuelTypeId: {
        value: "",
        valid: true,
        message: "",
    },
    fuelCapacity: {
        value: "",
        valid: true,
        message: "",
    },
    capacity: {
        value: "",
        valid: true,
        message: "",
    },
    location: {
        value: "",
        valid: true,
        message: "",
    },
    nextMaintenance: {
        value: "",
        valid: true,
        message: "",
    },
    nextMaintenanceLocation: {
        value: "",
        valid: true,
        message: "",
    },
    isActive: {
        value: true,
        valid: true,
        message: "",
    },
    isAvailable: {
        value: true,
        valid: true,
        message: "",
    },
    unavailabilityStart: {
        value: "",
        valid: true,
        message: "",
    },
    unavailabilityEnd: {
        value: "",
        valid: true,
        message: "",
    },
    unavailabilityText: {
        value: "",
        valid: true,
        message: "",
    }
}