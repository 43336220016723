//import { TripActivityMock } from 'mocks/TripActivityMock'
import { PayloadAction } from '@reduxjs/toolkit'
import { takeLatest, putResolve, call, select } from 'redux-saga/effects'
import API from 'api'
import { actions, RootState } from 'store/rootSlices'
import { toast } from 'react-toastify';
import * as Check from 'validations';
import { LocalFormValidations, LocalFormValidationsEdit /*, LocalFormValidationsEditUnavailable, LocalFormValidationsUnavailable */ } from '../utils'
import i18n from 'i18next';
import config from 'config';
import { hasPermission } from 'permissions'
import { SubmitSagaPayload, PhotosSagasPayload, DeletePhotosSagasPayload } from '../utils'
const { PERMISSIONS } = config

//state
const lclID = (state: RootState) => state.TripLocalForm.localID

function* onMountSaga({ payload }: PayloadAction<number>) {
  const { permissions } = yield select((state: RootState) => state.App);

  if (payload) {

    //yield putResolve(actions.TripLocalForm.setAction(true))
    //yield putResolve(actions.TripLocalForm.populateForm(""))
    const localID = payload

    try {
      //get local from api
      const localInfo = yield call(API.Locals.GetStop, localID)

      if (localInfo) {
        yield putResolve(actions.TripLocalForm.setLocalID(localID))
        yield putResolve(actions.TripLocalForm.setAction(true))
        yield putResolve(actions.TripLocalForm.setHasEditPermission(hasPermission(permissions, PERMISSIONS.TRIPS.STOPS.EDIT_STOP)))
        yield putResolve(actions.TripLocalForm.populateForm(localInfo))
        yield putResolve(actions.TripLocalForm.populatePhotosForm({ photos: localInfo.photos, localID }))
      } else {
        toast.error(i18n.t("toasts.getTripLocalError"));
      }
    } catch (error) {
    }

  } else {
    // creating a new local
    yield putResolve(actions.TripLocalForm.setAction(false))
  }
}

function* onSubmitSaga({ payload }: PayloadAction<SubmitSagaPayload>) {

  yield putResolve(actions.TripLocalForm.setSubmitButtonDisabled(true))
    ;
  let localAvailable = payload.localForm.isActive.value ? true : false;
  let validation: any = null

  if (localAvailable === true) {
    validation = payload.edit === true ? Check.checkValidationAlternative(payload.localForm, LocalFormValidationsEdit) : Check.checkValidationAlternative(payload.localForm, LocalFormValidations)
  }


  if (validation && validation.invalid === true) {
    let updateValidationMessages: any = null
    if (localAvailable) {
      updateValidationMessages = Check.setAndCheckValidationAlternative(payload.localForm, payload.localForm, LocalFormValidations)
    }

    yield putResolve(actions.TripLocalForm.onFormChange(updateValidationMessages))
    yield putResolve(actions.TripLocalForm.setSubmitButtonDisabled(false))
    toast.error(i18n.t("toasts.invalidFields"));
  }
  else {

    if (!payload.edit) {
      try {
        const finalForm = {
          ...payload.localForm,
          latitude: { value: Number.parseFloat(payload.localForm.latitude.value).toFixed(6), valid: true, message: "" },
          longitude: { value: Number.parseFloat(payload.localForm.longitude.value).toFixed(6), valid: true, message: "" }
        }
        const local = yield call(API.Locals.CreateStop, finalForm)

        if (local) {
          yield putResolve(actions.TripLocalForm.createPhoto({ photos: payload.photos, stop: local.id }))
          yield putResolve(actions.App.navigateTo('/viagens/configuracoes'))
          yield putResolve(actions.Tripsconfiguration.setMakeApiCals(true))
          toast.success(i18n.t("toasts.createLocalSuccess"));
        }

      } catch (error) {
        toast.error(i18n.t("toasts.createLocalError"));
      }
    }
    else {
      const localID = yield select(lclID)

      try {
        const finalForm = {
          ...payload.localForm,
          latitude: { value: Number.parseFloat(payload.localForm.latitude.value).toFixed(6), valid: true, message: "" },
          longitude: { value: Number.parseFloat(payload.localForm.longitude.value).toFixed(6), valid: true, message: "" }
        }
        const success = yield call(API.Locals.EditStop, localID, finalForm)

        if (success) {
          yield putResolve(actions.TripLocalForm.editPhotoArray({ photos: payload.photos, stop: localID }))
          yield putResolve(actions.App.navigateTo('/viagens/configuracoes'))
          toast.success(i18n.t("toasts.editLocalSuccess"))
        }
      } catch (error) {
        toast.warning(i18n.t("toasts.editLocalError"))
      }
    }
  }

  yield putResolve(actions.TripLocalForm.setSubmitButtonDisabled(false))
}

function onCreatePhotoSaga({ payload }: PayloadAction<PhotosSagasPayload>) {
  try {

    payload.photos.forEach(async (photo, idx) => {
      const body = {
        stop: payload.stop,
        sort: idx + 1,
        file: photo.file
      }
      await API.Locals.CreateStopPhoto(body)
    })

  } catch (error) {
    toast.warning("Erro a adicionar foto do local");
  }
}

function onEditPhotoArraySaga({ payload }: PayloadAction<PhotosSagasPayload>) {
  try {

    payload.photos.forEach(async (photo, idx) => {
      if (photo.new === true) {
        const body = {
          stop: payload.stop,
          sort: idx + 1,
          file: photo.file
        }
        await API.Locals.CreateStopPhoto(body)
      }
      else {
        if (photo.edited === true) {
          const body = {
            stop: payload.stop,
            sort: idx + 1,
          }
          await API.Locals.EditStopPhoto(photo.id, body)
        }
      }
    })

  } catch (error) {
    toast.warning("Erro editar fotos do local");
  }
}

function* deletePhotoSaga({ payload }: PayloadAction<DeletePhotosSagasPayload>) {
  const index = payload.index
  const photosArray = payload.photos

  if (index !== null && photosArray[index].new === false) {
    try {
      const del = yield call(API.Locals.DeleteStopPhoto, photosArray[index].id)

      if (del.status === 204) {
        toast.success(i18n.t("toasts.deletedPhoto"))
      }

    } catch (error) {
      toast.error(i18n.t("toasts.removePhotoError"))
    }
  }
}

function* onUnmountSaga() { }


export default function* watcherSignin() {
  yield takeLatest('triplocalform/onMount', onMountSaga)
  yield takeLatest('triplocalform/onUnmount', onUnmountSaga)
  yield takeLatest('triplocalform/onSubmit', onSubmitSaga)
  yield takeLatest('triplocalform/createPhoto', onCreatePhotoSaga)
  yield takeLatest('triplocalform/editPhotoArray', onEditPhotoArraySaga)
  yield takeLatest('triplocalform/deletePhotoFromArray', deletePhotoSaga)
}


