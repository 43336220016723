import { CButton } from '@coreui/react-pro'
import styled from 'styled-components'
import TableContainer from 'shared/components/TableContainer'

export const Wrapper = styled.div<{sticky: boolean}>`
  margin-bottom: ${({sticky}) => sticky ? '0px' : '41px'};
`

export const EventTimes = styled.div`
  display:flex;
  align-items:center;
`

export const Button = styled(CButton)`
  line-height: 1.35;
  height: 53%;
  align-self: flex-end;
`

export const StickyTableContainer = styled(TableContainer)<{sticky: boolean}>`
  position: sticky;
  top: -25px;
  height: 80px;
  margin-top: -10px;
  z-index: 21;
  box-shadow: 2px 2px 2px #00000025;

  > table {
    height: 100%;
  }
`