import {
  CargoTypeForm,
  PlanningBeachLocationForm,
  PlanningCircuitForm,
  PlanningDatesForm,
  PlanningMaxCapacityForm,
  PlanningMinimumPrice,
  PlanningIncludedSeats,
  PlanningRulesForm,
  PlanningServiceForm,
  PlanningStopsForm,
  PlanningVehiclesForm,
  ServiceTypes
} from 'types/trips/plannings'
import { ActivityExtrasForm, ActivityPassengerTypesForm } from 'types/trips/activity/activityForm';
import { PlannedLegsForm } from 'types/trips/plannings';

export interface InitialStateProps {
  planningID: string,
  edit: boolean,
  loading: boolean,
  loadingLegs: boolean,
  hasEditPermission: boolean,
  dateType: string,
  editDate: string,
  editFirstDate: boolean,
  passengerTypesSwitch: boolean,
  passengerTypes: ActivityPassengerTypesForm[],
  extras: ActivityExtrasForm[],
  extrasSwitch: boolean,
  inboundStops: PlanningStopsForm[],
  outboundStops: PlanningStopsForm[],
  initialInboundStops: PlanningStopsForm[],
  initialOutboundStops: PlanningStopsForm[],
  selectedVehicle: string,
  removeVehicleID: string,
  sameCircuitForVehicles: boolean,
  newVehicleAdded: boolean,
  showEditDatesModal: boolean,
  showDeleteModal: boolean,
  showLegsModal: boolean,
  showBookingsModal: boolean,
  showConfirmRemoveVehicleModal: boolean,
  showShouldUpdateTripCapacityModal: boolean,
  showIsOverbookedModal: boolean,
  addCargo: boolean,
  dates: PlanningDatesForm[],
  services: PlanningServiceForm[],
  maxCapacity: PlanningMaxCapacityForm,
  minimumPrice: PlanningMinimumPrice,
  includedSeats: PlanningIncludedSeats,
  beachLocation: PlanningBeachLocationForm,
  vehicles: PlanningVehiclesForm[],
  planningCircuit: PlanningCircuitForm,
  planningCircuitDefaultDuration: string,
  rules: PlanningRulesForm,
  cargo: CargoTypeForm[],
  firstCargoClick: boolean,
  updatedStops: boolean,
  updatedActivities: boolean,
  updatedCircuit: boolean,
  showSuspendedTripsModal: boolean,
  suspendedTrips: any[],
  bookingsNumber: number,
  circuitStartHour: string,
  submitButtonState: boolean,
  deleteButtonState: boolean,
  planningLegsForm: PlannedLegsForm[],
  affectedBookings: {
    id: number,
    uid: string
  }[]
}

export const servicesOptions = [
  { label: `Atividades`, value: ServiceTypes.ACTIVITY },
  { label: `Praia`, value: ServiceTypes.BEACH },
]

const initialState: InitialStateProps = {
  planningID: "",
  edit: false,
  loading: true,
  loadingLegs: true,
  hasEditPermission: true,
  passengerTypesSwitch: true,
  passengerTypes: [],
  extras: [],
  extrasSwitch: true,
  inboundStops: [],
  outboundStops: [],
  initialInboundStops: [],
  initialOutboundStops: [],
  selectedVehicle: "",
  removeVehicleID: "",
  sameCircuitForVehicles: false,
  newVehicleAdded: false,
  showIsOverbookedModal: false,
  showShouldUpdateTripCapacityModal: false,
  showEditDatesModal: false,
  showDeleteModal: false,
  showBookingsModal: false,
  showLegsModal: false,
  showConfirmRemoveVehicleModal: false,
  planningLegsForm: [],
  addCargo: false,
  firstCargoClick: false,
  dateType: "single_date",
  maxCapacity: { value: "", valid: true, message: "" },
  minimumPrice: { value: "", valid: true, message: "" },
  includedSeats: { value: "", valid: true, message: "" },
  beachLocation: { value: "", valid: true, message: "" },
  editFirstDate: true,                                                 //is set to true on setShowEditDatesModal
  editDate: "",
  planningCircuitDefaultDuration: "",
  dates: [
    {
      date: { value: "", valid: true, message: "" }
    }
  ],
  services: [],
  vehicles: [
    {
      uuid: { value: "", valid: true, message: "" },
      name: { value: "", valid: true, message: "" },
      capacity: { value: "", valid: true, message: "" }
    }
  ],
  planningCircuit: {
    circuit: { value: "", valid: true, message: "" }
  },
  rules: {
    allowOverbooking: false,
    availableForAgents: true,
    availableForPublic: true,
    availableForTeamMembers: true
  },
  cargo: [
    {
      destination: { value: "", valid: true, message: "" },
      observations: { value: "", valid: true, message: "" },
      recipient: { value: "", valid: true, message: "" }
    }
  ],
  updatedStops: false,
  updatedActivities: false,
  updatedCircuit: false,
  suspendedTrips: [],
  showSuspendedTripsModal: false,
  bookingsNumber: 0,
  circuitStartHour: "",
  submitButtonState: false,
  deleteButtonState: false,
  affectedBookings: []
}

export default initialState