import styled from 'styled-components'
import theme from 'ui/theme'
import {  CustomTableRow } from 'shared/genericStyledComponents'

const CustomCTableRow = styled(CustomTableRow) <{ ismain: boolean }>`
  background-color: ${({ ismain }) => ismain ? theme().offWhiteDark : theme().white};

  > td {
    font-weight: ${({ ismain }) => ismain ? 'bold' : 'initial'};
  }
`

export {
  CustomCTableRow
}