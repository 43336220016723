import styled from 'styled-components'
import theme from 'ui/theme'

export const Wrapper = styled.div`
  display: flex;
  height: 33px;
  min-width: 120px;
  max-width: 220px;
  margin-left: -10px;

  > svg {
    position: relative;
    left: 24px;
    top: 11px;
  }

  > input {
    padding-left: 32px;
    border-radius: 50px;
    border-color: ${theme().gray200Color};
  }
`