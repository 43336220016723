import { defaultCustomerForm, defaultChangePasswordForm } from '../utils'
import { CustomerForm, ChangePasswordForm } from 'types/customers/customerForm'

export interface InitialStateProps {
  customer: {
    avatar: string,
    uuid: string
  },
  customerForm: CustomerForm,
  edit: boolean,
  loading: boolean,
  hasEditPermission: boolean,
  isAvatarRemoved: boolean,
  isAvatarChanged: boolean,
  showDeleteModal: boolean,
  submitButtonState: boolean,
  showChangePasswordModal: boolean;
  userAuthExists: boolean;
  changePasswordForm: ChangePasswordForm;
}

export const initialState: InitialStateProps = {
  customer: {
    avatar: "",
    uuid: ""
  },
  customerForm: defaultCustomerForm,
  edit: false,
  loading: true,
  hasEditPermission: true,
  isAvatarRemoved:false,
  isAvatarChanged:false,
  showDeleteModal:false,
  submitButtonState: false,
  showChangePasswordModal: false,
  userAuthExists: false,
  changePasswordForm: defaultChangePasswordForm
}