/**
 * UserForm component
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */

import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import theme from 'ui/theme';
import ContainerDelete from '../../shared/components/FormComponents/containerdelete';
import { CForm } from '@coreui/react-pro';
import CustomCheckbox from '../../shared/components/CustomCheckbox/index';
import DadosPessoais from './dadosPessoais';
import ContatoEmergencia from './contatoEmergencia';
//import DropdownExport from '../../FormComponents/dropdownexport';
//import DadosAcesso from './dadosAcesso'
import DadosFiscais from './dadosFiscais'
import SituacaoFamiliar from './situacaoFamiliar'
import CondicoesContratuais from './condicoesContratuais'
import DadosBancarios from './dadosBancarios'
import Comissions from './comissions'

import { actions, RootState } from 'store/rootSlices';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash'

import CustomButton from 'shared/components/CustomButton';
import CustomModal from 'shared/components/CustomModal';
import i18n from "i18next";
import Loadingcontainer from 'shared/components/Loadingcontainer';
import Accessdatamodal from 'shared/components/Accessdatamodal';
import { hasPermission } from 'permissions';
import config from 'config';

interface PropTypes {
    action?: string,
}


const UserForm = ({ action }: PropTypes) => {

    const dispatch = useDispatch();
    const dbDispatch = _.debounce(dispatch, 300);
    const history = useHistory();
    const { uuid } = useParams()
    const { PERMISSIONS } = config
    const { permissions } = useSelector((state: RootState) => state.App)
    const { navigateBack } = actions.App
    const {
        onMount,
        onUnmount,
        onSubmit,
        showDeleteModal,
        showChangePasswordModal,
        onDeleteConfirm,
        setAcceptedTerms,
        changeUserAuthStatus,
        onChangePasswordFormChange,
        onChangePasswordSave,
        onReSendEmail
    } = actions.userForm;

    const {
        edit,
        deleteModal,
        changePasswordModal,
        userForm,
        termsAccepted,
        errorTerms,
        changePasswordForm,
        loading,
        isAvatarChanged,
        isAvatarRemoved,
        userAuthExists,
        hasEditPermission,
        submitButtonState,
    } = useSelector((state: RootState) => state.UserForm);

    useEffect(() => {
        dispatch(onMount(uuid))

        return () => {
            dispatch(onUnmount())
        }

    }, [dispatch, history, onMount, onUnmount, uuid]);

    const handleSubmit = (e) => {
        dispatch(onSubmit({ userForm, e, termsAccepted, edit, uuid, isAvatarChanged, isAvatarRemoved }))
    }

    const handleSubmitNewPassword = (e) => {
        dispatch(onChangePasswordSave({ changePasswordForm }))
    }

    const handleReSendEmail = (e) => {
        dispatch(onReSendEmail())
    }

    const handleDeleteCollaborator = (e) => {
        dispatch(showDeleteModal(true))
    }
    const handleDeleteCollaboratorConfirm = (e) => {
        dispatch(onDeleteConfirm(uuid))
    }

    if (loading) return <Loadingcontainer />

    return (
        <div>
            <CForm onSubmit={(e) => handleSubmit(e)}>
                <ControlsContainer >
                    {/* {edit === true && <DropdownExport id='userForm-dropdownExport'  dropdown={true} wrapperStyle={{ maxWidth: '100%' }} />} */}
                    <CustomCheckbox
                        id='userForm-isActive'
                        disabled={!hasEditPermission}
                        wrapperStyle={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginBottom: '-30px', fontSize: '20px' }}
                        checked={userForm.isActive.value}
                        onClick={e => dispatch(changeUserAuthStatus(!userForm.isActive.value))}
                        text={i18n.t('tripCircuitForm.isActive')}
                        switch
                    />
                </ControlsContainer>
                <DadosPessoais />
                <ContatoEmergencia />
                {/* <DadosAcesso /> */}
                {
                    <Accessdatamodal
                        id='userForm-accessDataModal'
                        userAuthExists={userAuthExists}
                        // username={userForm.username}
                        newPassword={changePasswordForm.newPassword}
                        confirmPassword={changePasswordForm.confirmPassword}
                        visible={changePasswordModal}
                        showChangePasswordModal={e => dispatch(showChangePasswordModal(e))}
                        handleReSendEmail={e => handleReSendEmail(e)}
                        handleSubmit={e => handleSubmitNewPassword(e)}
                        // onChangeUsername={e => dbDispatch(onFormChange({ ...userForm, username: { value: e.target.value, message: "", valid: true } }))}
                        onChangeNewPassword={e => dbDispatch(onChangePasswordFormChange({ ...changePasswordForm, newPassword: { value: e.target.value, message: "", valid: true } }))}
                        onChangeConfirmPassword={e => dbDispatch(onChangePasswordFormChange({ ...changePasswordForm, confirmPassword: { value: e.target.value, message: "", valid: true } }))}
                    />
                }
                <DadosFiscais />
                <SituacaoFamiliar />
                {/* TODO: Change the condition on row below to id */}
                {hasPermission(permissions, PERMISSIONS.USERS.VIEW_TEAM_MEMBER_CONTRACT) && <CondicoesContratuais />}
                <DadosBancarios />
                <Comissions />
                {
                    edit === true && hasPermission(permissions, PERMISSIONS.USERS.DELETE_TEAM_MEMBER) && <ContainerDelete
                        id='userForm-delete'
                        text={<p>{i18n.t('userForm.deleteUserText')}</p>}
                        onClick={e => handleDeleteCollaborator(e)} title={i18n.t('userForm.deleteUser')} />
                }
                {
                    edit === false &&
                    <CheckboxWrapper>
                        {errorTerms !== "" && <Terms >{errorTerms}</Terms>}
                        <CustomCheckbox checked={termsAccepted} id='userForm-acceptTerms' onClick={e => dispatch(setAcceptedTerms(!termsAccepted))} text={i18n.t('userForm.deleteUserCheckbox')} />
                    </CheckboxWrapper>
                }
                <ButtonWrapper>
                    <CustomButton id='userForm-cancel' onClick={() => dispatch(navigateBack())} text={i18n.t('common.cancelBtn')} color="link" buttonStyle={{ color: theme().danger, textDecoration: 'unset' }} />
                    {
                        edit ?
                            hasPermission(permissions, PERMISSIONS.USERS.EDIT_TEAM_MEMBER) && <CustomButton id='userForm-save' disabled={submitButtonState} text={i18n.t('common.saveBtn')} icon="confirm" onClick={e => handleSubmit(e)} color="success" buttonStyle={{ color: "#FFFFFF" }} />
                            :
                            hasPermission(permissions, PERMISSIONS.USERS.CREATE_TEAM_MEMBER) && <CustomButton id='userForm-save' disabled={submitButtonState} text={i18n.t('common.saveBtn')} icon="confirm" onClick={e => handleSubmit(e)} color="success" buttonStyle={{ color: "#FFFFFF" }} />
                    }
                </ButtonWrapper>

            </CForm>
            {
                hasPermission(permissions, PERMISSIONS.USERS.DELETE_TEAM_MEMBER) && <CustomModal
                    id='userForm-deleteUserModal'
                    visible={deleteModal}
                    warningType='warning'
                    warningName='Aviso'
                    buttonIcon={false}
                    footerPosition='center'
                    textAlign='center'
                    children={<p>Tem a certeza que pretende remover {userForm.name.value}?</p>}
                    onclose={e => dispatch(showDeleteModal(false))}
                    onsubmit={e => handleDeleteCollaboratorConfirm(e)} />
            }
        </div>
    )
}

export default UserForm

const CheckboxWrapper = styled.div``

const ControlsContainer = styled.div`
    display:flex;
    justify-content: flex-end;
`

const ButtonWrapper = styled.div`
    display:flex;
    justify-content:flex-end;
    padding-top:32px;
`

const Terms = styled.div`
    color:${theme().danger};
`

