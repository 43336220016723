/**
 * Historylog scene slice
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */
import { createSlice } from '@reduxjs/toolkit'
import { SortType } from 'types/misc';

interface InitialState {
  historyLog: [],
  loading: boolean,
  total: number,
  page: number,
  pageSize: number,
  userID: string,
  searchTerm: string,
  sort: SortType
}

const initialState: InitialState = {
  historyLog: [],
  loading: false,
  total:0,
  page:1,
  pageSize:6,
  userID: "",
  searchTerm:"",
  sort: {
    field: null,
    direction: null,
  },
}

export default createSlice({
  name: 'Historylog',
  initialState,
  reducers: {
    onMount: (state, {payload}) => {
      return initialState
    },
    onUnmount: () => {},
    setSort: (state, { payload }) => {
      state.sort = payload;
    },
    setLoading: (state, {payload}) => {
      state.loading = payload
    },
    setTotal:(state, {payload}) => {
      state.total = payload
    },
    setPage:(state, {payload}) => {
      state.page = payload
    },
    setUserID: (state, {payload}) => {
      state.userID = payload
    },
    setHistoryLog: (state, {payload}) => {
      state.historyLog = payload
    },
    setSearchTerm:(state, {payload}) => {
      state.page = 1
      state.searchTerm = payload
    }
  }
})