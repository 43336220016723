import * as React from "react"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={15}
      height={14}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.5.3A6.67 6.67 0 00.833 6.967 6.67 6.67 0 007.5 13.633h3.333V12.3H7.5c-2.893 0-5.333-2.44-5.333-5.333 0-2.894 2.44-5.334 5.333-5.334 2.893 0 5.333 2.44 5.333 5.334v.953c0 .527-.473 1.047-1 1.047-.526 0-1-.52-1-1.047v-.953a3.335 3.335 0 00-6.666 0A3.335 3.335 0 009.86 9.32c.433.593 1.18.98 1.973.98 1.314 0 2.334-1.067 2.334-2.38v-.953A6.669 6.669 0 007.5.3zm0 8.667c-1.107 0-2-.894-2-2 0-1.107.893-2 2-2s2 .893 2 2c0 1.106-.893 2-2 2z"
        fill="#636F83"
      />
    </svg>
  )
}

export default SvgComponent