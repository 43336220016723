/**
 * Custom checkbox component
 *
 * @author João Dias <jdias@ubiwhere.com>
 *
 *
 */

import React, { /* useEffect */ } from 'react'
import { CFormCheck, CFormSwitch } from '@coreui/react-pro';
import { Wrapper } from './styles';
interface PropTypes {
  id: string,
  className?: string
  wrapperStyle?: React.CSSProperties,
  checkStyle?: React.CSSProperties,
  text?: string,
  onClick?: Function,
  disabled?: boolean,
  switch?: boolean,
  inline?: boolean,
  checked?:boolean,
  value?: boolean
}
const CustomCheckbox = (props: PropTypes) => {

  return (
    <Wrapper className={props.className} style={props.wrapperStyle}>
      {props.switch
        ?
        <CFormSwitch
          // checked={props.checked}
          id={props.id}
          style={props.checkStyle}
          label={props.text}
          disabled={props.disabled}
          onChange={e => props.onClick ? props.onClick(e) : null}
          checked={props.checked && props.checked === true ? true : false}
        />
        :
        <CFormCheck
          id={props.id}
          style={props.checkStyle}
          label={props.text}
          disabled={props.disabled}
          inline={props.inline}
          onChange={e => props.onClick ? props.onClick(e) : null}
          checked={props.checked && props.checked===true ? true : false}
        />}
    </Wrapper>
  )
}

export default CustomCheckbox
