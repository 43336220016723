/**
 * Usercashmovementform scene slice
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */

//import { Statement } from '@babel/types'
import { createSlice } from '@reduxjs/toolkit'
import { EntryForm, StatementItem } from 'types/invoicing/statements'

interface initialState {
  isEdit: boolean,
  loading: boolean,
  entryID: number | null,
  hasEditPermission: boolean,
  submitButtonState: boolean,
  entry: StatementItem | null,
  entryForm: EntryForm,
  photos: any[]
  newPhotosID: number,
}



const initialState: initialState = {
  isEdit: false,
  loading: false,
  entryID: null,
  entry: null,
  hasEditPermission: false,
  submitButtonState: false,
  entryForm: {
    amount: {
      value: "",
      valid: true,
      message: ""
    },
    description: {
      value: "",
      valid: true,
      message: ""
    },
    balanceEntryType: {
      value: "",
      valid: true,
      message: ""
    },
    paymentType: {
      value: "",
      valid: true,
      message: ""
    },
    processedBy: {
      value: "",
      valid: true,
      message: ""
    },
    supplier: {
      value: "",
      valid: true,
      message: ""
    },
    date: {
      value: "",
      valid: true,
      message: ""
    }
  },
  photos: [],
  newPhotosID: 99,
}

export default createSlice({
  name: 'Usercashmovementform',
  initialState,
  reducers: {
    onMount: (state, { payload }) => { },
    onUnmount: () => {
      return initialState
    },
    setAction: (state, { payload }) => {
      state.isEdit = payload
    },
    setSubmitButtonState: (state, { payload }) => {
      state.submitButtonState = payload
    },
    setEntry: (state, { payload }) => {
      state.entry = payload
    },
    setEntryID: (state, { payload }) => {
      state.entryID = payload
    },
    setHasEditPermission: (state, { payload }) => {
      state.hasEditPermission = payload
    },
    setMovementDate: (state, { payload }) => {
      state.entryForm['date'] = {
        ...state.entryForm['date'],
        value: payload
      }
    },
    setProcessedByOnMount: (state, { payload }) => {
      state.entryForm['processedBy'] = {
        ...state.entryForm['processedBy'],
        value: payload
      }
    },
    getEntry: () => { },
    onSubmit: () => { },
    generateTicket: () => { },
    populateForm: (state, { payload }) => {
      const entry = payload
      const baseForm = { ...initialState.entryForm }

      //info about entry type, payment type and processed by come inside different objects,
      //and thats why this for each has so many conditionals
      Object.keys(entry).forEach((key) => {
        if (key in baseForm) {

          if (key === "balanceEntryType") {
            baseForm[key] = {
              ...baseForm[key],
              value: entry[key]?.uid
            }
          }
          else if (key === "paymentType") {
            baseForm[key] = {
              ...baseForm[key],
              value: entry[key]?.uid
            }
          }
          else if (key === "processedBy") {
            baseForm[key] = {
              ...baseForm[key],
              value: entry[key]?.uuid
            }
          }
          else {
            baseForm[key] = {
              ...baseForm[key],
              value: entry[key]
            }
          }
        }
      })

      state.entryForm = baseForm
    },
    onEntryFormUpdate: (state, { payload }) => {
      state.entryForm = payload
    },
    updateEntryFormOnValidation: (state, { payload }) => {
      state.entryForm = payload
    },
    createEntryPhoto: () => { },
    downloadTicket: (state, { payload }) => {
      const url = payload.file.url
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('target', "_blank")
      link.setAttribute('rel', "noreferrer noopener")
      document.body.appendChild(link);
      link.click()
      link.parentNode?.removeChild(link)
    },
    addToPhotosArray: (state, { payload }) => {
      const photo = payload.e
      const photos: any[] = Array.from(payload.photos)

      photos.push({
        id: payload.newPhotosID + 1,
        movement: null,
        file: photo,
        new: true,
        edited: false,
        sort: photos.length + 1
      })

      state.newPhotosID = payload.newPhotosID + 1
      state.photos = photos
    },
    deletePhotoFromArray: (state, { payload }) => {
      const items: any[] = Array.from(payload.photos)

      items.splice(items.indexOf(items[payload.index]), 1)

      const deletedPhoto = items.map((photo, idx) => {
        if (photo.sort !== idx + 1) {
          return {
            ...photo,
            edited: true,
            sort: idx + 1
          }
        }
        else {
          return {
            ...photo,
            sort: idx + 1
          }
        }
      })

      state.photos = deletedPhoto
    },
    reOrderPhotosArray: (state, { payload }) => {
      if (!payload.e.destination) return;
      const items: any[] = Array.from(payload.photos)


      const [reOrderedItem] = items.splice(payload.e.source.index, 1);
      items.splice(payload.e.destination.index, 0, reOrderedItem)
      const sorted = items.map((photo, idx) => {
        return {
          ...photo,
          sort: idx + 1,
          edited: true,
        }
      })

      state.photos = sorted
    },
    populatePhotosForm: (state, { payload }) => {
      const photos: any[] = Array.from([])

      payload.photos.forEach((photo) => {
        photos.push({
          movement: payload.movementID,
          id: photo.id,
          file: photo.file,
          edited: false,
          new: false,
          sort: photo.sort
        })
      })

      state.photos = photos.sort((a, b) => { return a.sort - b.sort })
    },
  }
})