import React from 'react'
import i18n from 'i18next'
import { useDispatch, useSelector } from 'react-redux'
import { actions, RootState } from 'store/rootSlices'
import { CCol, CRow } from '@coreui/react-pro'
import { PlanningDatesForm, PlanningServiceForm, ServiceTypes } from 'types/trips/plannings';
import { toast } from 'react-toastify';
import { DateTime } from 'luxon'
import SectionContainer from 'shared/components/Sectioncontainer'
import CustomButton from 'shared/components/CustomButton'
import CustomRadioButtonGroup from 'shared/components/CustomRadioButtonGroup'
import CustomRadioButton from 'shared/components/CustomRadioButton'
import Datepicker from 'shared/components/FormComponents/datepicker'
import Dropdown from 'shared/components/FormComponents/dropdown'
import Trash from 'assets/icons/trash'
import Edit from 'assets/icons/edit';
import CustomModal from 'shared/components/CustomModal'
import config from 'config';
import { Row } from '../../../styles';
import { WarningCol, BoldText, EditWarning } from './styles'
import { Text, ButtonRow } from '../../styles'
import { AvailableServiceOptions, getServiceInitialSettingsBasedOnType, isTripService } from './utils'
import ServiceSection from './components/ServiceSection'


const ServicesRows = () => {

  const {
    edit,
    showEditDatesModal,
    services,
    passengerTypes,
    extras,
    dateType,
    dates,
    editDate,
    editFirstDate,
    showSuspendedTripsModal,
    suspendedTrips,
    hasEditPermission,
  } = useSelector((state: RootState) => state.TripPlanningsForm)

  const {
    addPrincipalService,
    addSecondaryServices,
    onDateTypeChange,
    addNewDate,
    onDateChange,
    onDeleteService,
    onDeleteDate,
    onSubmit,
    setShowEditDatesModal,
    setEditDate,
    setShowSuspendedTripsModal,
  } = actions.TripPlanningsForm
  const { navigateTo } = actions.App

  const dispatch = useDispatch()

  const _renderServices = () => { 
    return services.map((service: PlanningServiceForm, serviceIdx) => {
      return <ServiceSection service={service} serviceIdx={serviceIdx} />
    })
  }

  const _renderAddServiceButton = () => { 
    return (services.length > 0 && isTripService(services[0]) && services[0].serviceDetails?.allowMultipleActivities) && (
      <ButtonRow>
        <CustomButton
          disabled={!hasEditPermission}
          id={'tripPlanningForm-addServiceButton'}
          variant='outline'
          color='primary'
          text={i18n.t('tripPlanningForm.associateNewService')}
          icon='add'
          iconFirst
          buttonStyle={{ width: '20%', minWidth: '105px' }}
          onClick={e => {
            dispatch(addSecondaryServices({
              services: services,
              passengerTypes: passengerTypes
            }))
          }} />
      </ButtonRow>
    )
  }

  return (
    <SectionContainer title={i18n.t('tripPlanningForm.serviceTitle')} style={{ width: '100%', paddingBottom: '24px' }}>
      <CRow>
        <CCol sm={7}>
          <CustomRadioButtonGroup role="group1" helpText={''} valid={true}>
            <CustomRadioButton disabled={!hasEditPermission || edit ? true : false} group="group1" text={i18n.t('tripPlanningForm.uniqueDate')} value="single_date"
              checked={dateType === "single_date" ? true : false}
              onClick={e => { dispatch(onDateTypeChange({ dateType: "single_date", dates })) }} />
            <CustomRadioButton disabled={!hasEditPermission || edit ? true : false} group="group1" text={i18n.t('tripPlanningForm.dateInterval')} value="date_interval"
              checked={dateType === "date_interval" ? true : false}
              onClick={e => { dispatch(onDateTypeChange({ dateType: "date_interval", dates })) }} />
            <CustomRadioButton disabled={!hasEditPermission || edit ? true : false} group="group1" text={i18n.t('tripPlanningForm.irregularDates')} value="multiple_dates"
              checked={dateType === "multiple_dates" ? true : false}
              onClick={e => { dispatch(onDateTypeChange({ dateType: "multiple_dates", dates })) }} />
          </CustomRadioButtonGroup>
        </CCol>
        {
          edit &&
          <WarningCol>
            <EditWarning>
              <Edit viewBox='3 2 24 24' />{i18n.t('tripPlanningForm.editionModeText')}&nbsp;<BoldText>{DateTime.fromISO(editDate).toFormat(config.DATE_FORMAT.DATE)}</BoldText>
            </EditWarning>
          </WarningCol>
        }
        {
          <CustomModal
            id={`tripPlanningForm-confirmDates`}
            warningType='message'
            warningName={i18n.t('common.warning')}
            visible={showEditDatesModal}
            buttonIcon={true}
            footerPosition='space-between'
            textAlign='start'
            width={600}
            buttonName={i18n.t('common.confirm')}
            children={
              <div>
                <Text>{i18n.t('tripPlanningForm.changeEditDatesText1')}</Text>
                <p>{i18n.t('tripPlanningForm.changeEditDatesText2')}</p>
                <CustomRadioButton group="group1" text={`${i18n.t('tripPlanningForm.changeEditDatesOption1')} ${DateTime.fromISO(editDate).toFormat(config.DATE_FORMAT.DATE)}`} value={true}
                  checked={editFirstDate ? true : false}
                  onClick={e => { dispatch(setEditDate({ editDate: editDate, editFirstDate: true })) }} />
                <CustomRadioButton group="group1" text={i18n.t('tripPlanningForm.changeEditDatesOption2')} value={false}
                  checked={!editFirstDate ? true : false}
                  onClick={e => { dispatch(setEditDate({ editFirstDate: false })) }} />
              </div>
            }
            onclose={e => dispatch(setShowEditDatesModal(false))}
            onsubmit={e => dispatch(onSubmit())} />
        }
        {
          <CustomModal
            id={`tripPlanningForm-suspendedTrips`}
            warningType='message'
            warningName={i18n.t('common.warning')}
            visible={showSuspendedTripsModal}
            singleButton
            withForm
            footerPosition='space-between'
            textAlign='start'
            width={600}
            buttonName={i18n.t('common.continue')}
            children={
              <div>
                <p>{i18n.t('tripPlanningForm.suspendedTripsText1')}</p>
                {
                  suspendedTrips.map(susT => {
                    return (<p><a href={`/viagens/agendamentos/editar-agendamento/${susT.tripID}`} > - {susT.date}</a></p>)
                  })
                }
                <p>{i18n.t('tripPlanningForm.suspendedTripsText2')}</p>
              </div>
            }
            onclose={e => {
              dispatch(setShowSuspendedTripsModal(false))
              toast.success(i18n.t("toasts.editPlanningSuccess"));
              dispatch(navigateTo('/viagens/agendamentos'))
            }}
            onsubmit={e => {
              dispatch(setShowSuspendedTripsModal(false))
              toast.success(i18n.t("toasts.editPlanningSuccess"));
              dispatch(navigateTo('/viagens/agendamentos'))
            }} />
        }
      </CRow>
      <Row className="g-3">
        {
          dateType === "single_date" ? <CCol>
            <Datepicker
              id='tripPlanningForm-singleDate'
              disabled={!hasEditPermission || edit ? true : false}
              valid={dates[0]?.date.valid}
              value={dates[0]?.date.value}
              helpText={dates[0]?.date.message}
              required={true}
              label={i18n.t('common.date')}
              onChange={(date, e) => { dispatch(onDateChange({ form: { ...dates[0], date: { value: date, message: "", valid: true } }, idx: 0, dates, dateType: dateType })) }}
            />

          </CCol>
            :
            dateType === "date_interval" ? <><CCol>
              <Datepicker
                id='tripPlanningForm-singleDate0'
                disabled={!hasEditPermission || edit ? true : false}
                valid={dates[0]?.date.valid}
                value={dates[0]?.date.value}
                helpText={dates[0]?.date.message}
                required={true}
                label={i18n.t('tripPlanningForm.startDate')}
                onChange={(date, e) => { dispatch(onDateChange({ form: { ...dates[0], date: { value: date, message: "", valid: true } }, idx: 0, dates, dateType: dateType })) }}
              />
            </CCol>
              <CCol>
                <Datepicker
                  id='tripPlanningForm-singleDate1'
                  disabled={!hasEditPermission || edit ? true : false}
                  valid={dates[dates.length - 1]?.date.valid}
                  value={dates[dates.length - 1]?.date.value || ""}
                  helpText={dates[dates.length - 1]?.date.message || ""}
                  required={true}
                  label={i18n.t('tripPlanningForm.endDate')}
                  onChange={(date, e) => { dispatch(onDateChange({ form: { ...dates[dates.length - 1], date: { value: date, message: "", valid: true } }, idx: dates.length - 1, dates, dateType: dateType })) }}
                />
              </CCol>
            </>
              :
              <>
                {
                  dates.map((d: PlanningDatesForm, idx) => {
                    return (
                      <div style={idx > 0 ? { marginTop: '16px' } : {}}>
                        <Row>
                          <CCol>
                            <Datepicker
                              id={`tripPlanningForm-singleDate${idx}`}
                              disabled={!hasEditPermission || edit ? true : false}
                              valid={d.date.valid || true}
                              value={d.date.value || ''}
                              helpText={d.date.message || ''}
                              required={true}
                              label={i18n.t('common.date')}
                              onChange={(date, e) => { dispatch(onDateChange({ form: { ...d, date: { value: date, message: "", valid: true } }, idx: idx, dates })) }}
                            />
                          </CCol>
                          {
                            !edit && idx > 0 && <CCol sm={1} style={{ width: "5%", display: "flex", justifyContent: "space-around", alignItems: "flex-start", marginTop: "35px" }}>
                              <Trash style={{ cursor: "pointer" }} onClick={(e) => dispatch(onDeleteDate({ dates: dates, index: idx }))} />
                            </CCol>
                          }
                        </Row>
                      </div>
                    )
                  })
                }
                {
                  dateType === "multiple_dates" && dates.length > 0 && !edit &&
                  <ButtonRow>
                    <CustomButton disabled={edit ? true : false} id='tripPlanningForm-addDateButton' variant='outline' color='primary' text={i18n.t('tripPlanningForm.addNewDate')} icon='add' iconFirst buttonStyle={{ width: '20%' }}
                      onClick={e => dispatch(addNewDate({ dates: dates }))} />
                  </ButtonRow>
                }
              </>
        }
      </Row>
      <Row>
        <CCol>
          <Dropdown
            required={true}
            id='tripPlanningForm-serviceType0'
            valid={true}
            disabled={!hasEditPermission}
            value={services[0]?.serviceType.toString() || ''}
            helpText={''}
            label={i18n.t('tripPlanningForm.serviceTitle')}
            placeholder={i18n.t('placeholders.serviceType')}
            onChange={(e) => {
              dispatch(addPrincipalService({
                services: services,
                serviceType: Number(e.target.value) as ServiceTypes,
                settings: getServiceInitialSettingsBasedOnType(Number(e.target.value) as ServiceTypes, passengerTypes, extras),
              }))
            }}
            options={AvailableServiceOptions(services) as any[]}
          />
        </CCol>
        {
          services.length > 1 && (
            <CCol sm={1} style={{ width: "5%", display: "flex", justifyContent: "space-around", alignItems: "flex-start", marginTop: "35px" }}>
              <Trash style={{ cursor: "pointer" }} onClick={(e) => hasEditPermission && dispatch(onDeleteService({ services: services, index: 0 }))} />
            </CCol>
          )
        }
      </Row>
      { _renderServices() }
      { _renderAddServiceButton() }
    </SectionContainer>
  )
}

export default ServicesRows