// import { PayloadAction } from '@reduxjs/toolkit'
import type { InitialStateProps} from '../../../logic/initialState';
import initialState from '../../../logic/initialState';

const PlanningListTableRowReducers = {
  closeCheckInModal: (state: InitialStateProps) => {
    state.showCheckinModal = initialState.showCheckinModal
    state.checkinOutboundSelected = true
  },
  clearOpenPlanningTripPassengers: (state: InitialStateProps) => {
    state.openPlanningElegiblePassengers = null
    state.openPlanningTripPassengers = null
    state.openPlanningTotalBookings = null
  },
  setIsUpdatingCircuit: (state: InitialStateProps, { payload }) => {
    state.isUpdatingCircuit = payload
  },
  setModalInitialVehicle: (state: InitialStateProps, { payload }) => {
    state.modalInitialVehicle = {
      value: payload,
      valid: true,
      message: ""
    }
  },
  setDidStopsChange: (state: InitialStateProps, { payload }) => {
    state.didStopsChange = payload
  },
  setIsRemovingVehicle: (state: InitialStateProps, { payload }) => {
    state.isRemovingVehicle = payload
  },
  getTripsDetails: (state, { payload} ) => {},
  setOpenPlanning: (state: InitialStateProps, { payload }) => {
    const stops: any = Array.from([])
    const planningActivities = payload.planning.activities

    payload.locals.forEach((stop) => {

      stops.push({
        time: { value: "", valid: true, message: "" },
        isActive: { value: false, valid: true, message: "" },
        stop: {
          id: stop.id,
          isActive: stop.isActive,
          isAvailable: stop.isAvailable,
          name: stop.name,
          shortName: stop.shortName,
          details: [{
            maxCapacity: { value: "", valid: true, message: "" },
            vehicle: { uuid: "" },
            activities: planningActivities.map(act => {
              return {
                ...act,
                isOn: { value: false, valid: true, message: "" }
              }
            })
          }]
        },
      })
    })

    const reversedStops: any = Array.from(stops).reverse()
    state.openPlanningOutboundStops = stops
    state.openPlanningInboundStops = reversedStops
    state.openPlanning = payload.planning
  },
  setShowModal: (state: InitialStateProps, { payload }) => {
    state.showModal = payload
  },
  setSelectedVehicle: (state: InitialStateProps, { payload }) => {
    if (payload) {
      state.selectedVehicle = {
        value: payload.vehicle,
        valid: true,
        message: ""
      }

      state.reinforcementCapacity = {
        value: payload.planning.vehicles.find(v => v.vehicle.uuid === payload.vehicle)?.vehicle.capacity,
        valid: true,
        message: ""
      }
    }
  },
  getTripStopDetails: () => { },
}

export default PlanningListTableRowReducers