import i18n from 'i18next';
import * as Check from 'validations';
import {
  BookingLeg,
  BookingPassengerTypeForm,
  BookingPaymentEntry,
  BookingPaymentForm,
  CancelRescheduleSettings,
  BookingPaymentEntrySaga,
  BookingExtrasForm,
} from 'types/trips/bookings';
import {  BookingPaymentValidation } from '../utils';
import { sortPassengerTypesByPrice } from '../components/PassengerTypes/utils';


export const addFeeEntrie = (
  isOutbound: boolean,
  becausePassenger: boolean,
  entries: BookingPaymentEntry[],
  bookedLeg: BookingLeg,
  applyFeeToTrips: boolean,
  tripsCancelRescheduleSettings: CancelRescheduleSettings,
  cancelLegTotal?: number
) => {

  if (applyFeeToTrips && tripsCancelRescheduleSettings.fee) {
    let amount: number
    let description: string

    if (cancelLegTotal) {
      amount = +(cancelLegTotal * (tripsCancelRescheduleSettings.fee / 100)).toFixed(2)
      description = `${i18n.t('tripBookings.cancelTax')} (${tripsCancelRescheduleSettings.fee}%) ${bookedLeg.tripActivityLeg.activity.name}`
    } else {
      amount = +(+bookedLeg.totalPrice * (tripsCancelRescheduleSettings.fee / 100)).toFixed(2)
      description = isOutbound ? i18n.t('tripBookings.outboundTax') : i18n.t('tripBookings.inboundTax')
    }

    if (amount !== 0) {
      entries.push({
        id: 0,
        state: 'pending',
        leg: { ...bookedLeg },
        amount,
        isRefund: false,
        isFee: true,
        description,
        canceled: false,
        isBecausePassenger: becausePassenger
      });
    }
  }

  return entries
}

//* This function adds a new normal or private leg entry
export const addNewPrivateOrNormalEntrie = (leg: BookingLeg, passengers: BookingPassengerTypeForm[], entries: BookingPaymentEntry[]) => { 
  const includedSeats = +leg.tripActivityLeg.includedSeats
  const minimumPrice = +leg.tripActivityLeg.minimumPrice

  if(leg.tripActivityLeg.activity.isPrivate) { 
    const passengersAmount: number = +getPrivateTripTotalAmount(passengers, leg.isOutbound, includedSeats)
    entries.push(addEntrie(false, false, leg, minimumPrice + passengersAmount))
  } else {
    entries.push(addEntrie(false, false, leg, +getTripTotalAmount(passengers, leg.isOutbound ? 'outbound' : 'inbound').toFixed(2)))
  }
}

//* This function calculates the total amount of a rescheduled leg
export const getRescheduledLegTotalAmount = (leg: BookingLeg, passengers: BookingPassengerTypeForm[], bookedTotalPrice: number) => { 
  const includedSeats = +leg.tripActivityLeg.includedSeats
  const minimumPrice = +leg.tripActivityLeg.minimumPrice
  let amount = 0

  if(leg.tripActivityLeg.activity.isPrivate) { 
    const passengersAmount = getPrivateTripTotalAmount(passengers, leg.isOutbound, includedSeats)
    amount = +((minimumPrice + passengersAmount) - bookedTotalPrice).toFixed(2)
  } else {
    amount = +getTripTotalAmount(passengers, leg.isOutbound ? 'outbound' : 'inbound').toFixed(2) - bookedTotalPrice
  }

  return amount;
}

//* This function calculates the total amount of a new leg
export const getLegTotalAmount = (leg: BookingLeg, passengers: BookingPassengerTypeForm[]) => { 
  const includedSeats = +leg.tripActivityLeg.includedSeats
  const minimumPrice = +leg.tripActivityLeg.minimumPrice
  let amount = 0

  if(leg.tripActivityLeg.activity.isPrivate) { 
    const passengersAmount = getPrivateTripTotalAmount(passengers, leg.isOutbound, includedSeats)
    amount = +(minimumPrice + passengersAmount).toFixed(2)
  } else {
    amount = +getTripTotalAmount(passengers, leg.isOutbound ? 'outbound' : 'inbound').toFixed(2)
  }

  return amount;
}

export const addBeachEntrie = (
  becausePassenger: boolean,
  isSettlement: boolean,
  beachTrip: BookingLeg,
  amount: number,
  isCanceled?: boolean
) => {
  let finalAmount: number
  let isRefund: boolean
  let description: string

  if (isSettlement) {
    finalAmount = amount < 0 ? amount * -1 : amount
    isRefund = amount < 0
    description = `${beachTrip?.beachTripActivity?.activity.name} - ${i18n.t(
      'tripBookings.accountSettlement'
    )}`
  } else {
    finalAmount = amount
    isRefund = false
    description = beachTrip?.beachTripActivity?.activity.name ?? '-'
  }

  if (isCanceled) {
    isRefund = true
  }

  return {
    id: 0,
    state: 'pending',
    leg: { ...beachTrip },
    amount: finalAmount,
    isRefund,
    isFee: false,
    description,
    canceled: false,
    isBecausePassenger: becausePassenger
  }
}

export const addEntrie = (
  becausePassenger: boolean,
  isSettlement: boolean,
  leg: BookingLeg,
  amount: number,
  isCanceled?: boolean
) => {

  let finalAmount: number
  let isRefund: boolean
  let description: string

  if (isSettlement) {
    finalAmount = amount < 0 ? amount * -1 : amount
    isRefund = amount < 0
    description = `${leg.tripActivityLeg.activity.name} - ${i18n.t(
      'tripBookings.accountSettlement'
    )}`
  } else {
    finalAmount = amount
    isRefund = false
    description = leg.tripActivityLeg.activity.name
  }

  if (isCanceled) {
    isRefund = true
  }

  return {
    id: 0,
    state: 'pending',
    leg: { ...leg },
    amount: finalAmount,
    isRefund,
    isFee: false,
    description,
    canceled: false,
    isBecausePassenger: becausePassenger
  }
}

export const getEntriesTotalWithoutDiscount = (entries: BookingPaymentEntry[], becausePassengers?: boolean) => {

  if (becausePassengers) {
    return entries
      .filter((e) => e.state === 'pending' && !e.canceled)
      .reduce((prev, curr) => {
        let val = +curr.amount;
        return prev + (curr.isRefund ? val * -1 : val);
      }, 0);
  } else {
    return entries
      .filter((e) => e.state === 'pending')
      .reduce((prev, curr) => {
        let val = +curr.amount;
        return prev + (curr.isRefund ? val * -1 : val);
      }, 0);
  }

};

export const updateEntriesTotal = (entries: BookingPaymentEntry[], paymentForm: BookingPaymentForm, becausePassengers?: boolean) => {
  let total = getEntriesTotalWithoutDiscount(entries, becausePassengers);
  return total + Number(paymentForm.correction.value);
};

export const updateEntriesIsRefund = (entriesTotal: number) => {
  return entriesTotal < 0;
};

export const updateEntryAmount = (
  outboundLeg: BookingLeg,
  inboundLeg: BookingLeg,
  passengerTypes: BookingPassengerTypeForm[],
  entries: BookingPaymentEntry[]
) => {
  let updatedEntries = entries.map((e) => {
    if (inboundLeg && e.leg?.tripActivityLeg.id === inboundLeg.tripActivityLeg.id) {
      //* Check if the outbound leg is private
      if(inboundLeg.tripActivityLeg.activity.isPrivate) {
        const minimumPrice = Number(inboundLeg.tripActivityLeg.minimumPrice);
        const includedSeats = Number(inboundLeg.tripActivityLeg.includedSeats);
        const totalAmount = getPrivateTripTotalAmount(passengerTypes, false, includedSeats);
        return { ...e, amount: +(totalAmount + minimumPrice).toFixed(2) };
      }
      return { ...e, amount: +getTripTotalAmount(passengerTypes, 'inbound').toFixed(2) };
     
    }

    if (outboundLeg && e.leg?.tripActivityLeg.id === outboundLeg.tripActivityLeg.id) {
      //* Check if the inbound leg is private
      if(outboundLeg.tripActivityLeg.activity.isPrivate) {
        const minimumPrice = Number(outboundLeg.tripActivityLeg.minimumPrice);
        const includedSeats = Number(outboundLeg.tripActivityLeg.includedSeats);
        const totalAmount = getPrivateTripTotalAmount(passengerTypes, true, includedSeats);
        return { ...e, amount: +(totalAmount + minimumPrice).toFixed(2) };
      }
      return { ...e, amount: +getTripTotalAmount(passengerTypes, 'outbound').toFixed(2) };   
    }

    return { ...e };
  });

  return updatedEntries;
};

export const updateBeachEntryAmount = (
  beachTripLeg: BookingLeg,
  extras: BookingExtrasForm[],
  entries: BookingPaymentEntry[]
) => {
  let updatedEntries = entries.map((e) => {

    if (beachTripLeg && e.leg?.beachTripActivity?.id === beachTripLeg.beachTripActivity?.id) {
      return { ...e, amount: +getBeachTripTotalAmount(extras).toFixed(2) };
    }

    return { ...e };
  });

  return updatedEntries;
};

//* This calculates a trip leg total amount in the case it's a private leg
export const getPrivateTripTotalAmount = (passengerTypes: BookingPassengerTypeForm[], isOutbound: boolean, includedSeats: number) => {

  // Sort the passenger types by price in descending order
  const sortedPassengerTypes: BookingPassengerTypeForm[] = passengerTypes
  sortPassengerTypesByPrice(sortedPassengerTypes, isOutbound)

  // Calculate the total amount considering the included seats
  let totalAmount = 0;
  let remainingIncludedSeats = includedSeats;

  for (const passenger of sortedPassengerTypes) {
    if (passenger.isDisabled) continue

    let qty = +passenger.quantity.value;
    qty = isNaN(qty) ? 0 : qty;

    const price = isOutbound ? passenger.priceOutbound : passenger.priceInbound;

    // Deduct the included seats first
    const chargeableQty = Math.max(qty - remainingIncludedSeats, 0);
    remainingIncludedSeats -= Math.min(qty, remainingIncludedSeats);

    totalAmount += chargeableQty * price;
  }

  return totalAmount;
};

//* This calculate a trip leg total amount
export const getTripTotalAmount = (passengerTypes: BookingPassengerTypeForm[], direction: String) => {
  return passengerTypes.reduce((prev, curr) => {
    if (curr.isDisabled) {
      return prev;
    } else {
      let qty = +curr.quantity.value;
      qty = isNaN(qty) ? 0 : qty;

      let val = (direction === 'outbound' ? curr.priceOutbound : curr.priceInbound) * qty;
      return prev + val;
    }
  }, 0);
};

export const getBeachTripTotalAmount = (extras: BookingExtrasForm[]) => {
  return extras.reduce((prev, curr) => {
    if (curr.isDisabled) {
      return prev;
    } else {
      let qty = +curr.quantity.value;
      qty = isNaN(qty) ? 0 : qty;

      let val = curr.price * qty;
      return prev + val;
    }
  }, 0);
};

export const getCorrectionValue = (entries: BookingPaymentEntry[], paymentForm: BookingPaymentForm, isFree: boolean, becausePassengers?: boolean) => {
  if (isFree) {
    return (-getEntriesTotalWithoutDiscount(entries, becausePassengers)).toFixed(2);
  }
  return paymentForm.correction.value;
}


export const updateExtrasTypes = (beachTrip, currentExtras, allowOverbooking) => {
  //* Here we get an array of extras id's from the beach activity
  let activeExtras: any = [];

  if (beachTrip?.id > 0) {
    activeExtras = beachTrip.availableExtras?.map((el) => el.id)
  }

  let newExtras = currentExtras.map((c) => {
    if (activeExtras.includes(c.id)) {

      let extra;
      if (beachTrip.id > 0) {
        extra = beachTrip.availableExtras.find((p) => p.id === c.id);
      }

      let newExtra = {
        ...c,
        quantity: { ...c.quantity, value: c.quantity.value !== '-' ? c.quantity.value : '0' },
        totalPrice: c.totalPrice,
      };

      if (extra) {
        newExtra.price = Number(extra.price)
        newExtra.maxCapacity = extra.maxCapacity - extra.occupied //Math.min((inboundLeg.availableTickets + bookedPassengers), (outboundLeg.availableTickets + bookedPassengers));
      } else {
        newExtra.price = +extra.price;
        newExtra.maxCapacity = extra.maxCapacity //inboundLeg.availableTickets + bookedPassengers;
      }
      newExtra.totalPrice = +(newExtra.price * newExtra.quantity.value).toFixed(2);
      newExtra.isDisabled = false;

      return newExtra;
    } else {
      return {
        ...c,
        isDisabled: true,
        quantity: { ...c.quantity, valid: true },
        totalPrice: c.price,
      };
    }
  });

  return newExtras;
};

export const parsePendingEntries = (entries: BookingPaymentEntry[], isBeach: boolean): BookingPaymentEntrySaga[] => {
  //only send new entries, without ID
  if (isBeach) {
    return entries
      .filter((e) => (e.state === 'pending' && e.id === 0) || (e.state === 'pending' && e.canceled))
      .map((entry) => {
        return {
          state: 'pending',
          leg: entry.leg?.beachTripActivity?.id,
          description: entry.description,
          amount: entry.amount,
          isRefund: entry.isRefund,
          isFee: entry.isFee,
        };
      });
  }
  else {
    return entries
      .filter((e) => (e.state === 'pending' && e.id === 0) || (e.state === 'pending' && e.canceled))
      .map((entry) => {
        return {
          state: 'pending',
          leg: entry.leg?.tripActivityLeg.id,
          description: entry.description,
          amount: entry.amount,
          isRefund: entry.isRefund,
          isFee: entry.isFee,
        };
      });
  }
};

export const parsePaymentEntries = (entries: BookingPaymentEntry[], isBeach: boolean): BookingPaymentEntrySaga[] => {
  if (isBeach) {
    return entries
      .filter((e) => e.state === 'pending' && !e.canceled)
      .map((entry) => {
        if (entry.id && entry.id > 0) {
          return {
            id: entry.id,
            state: 'pending',
            leg: entry.leg?.beachTripActivity?.id,
            description: entry.description,
            amount: entry.amount,
            isRefund: entry.isRefund,
            isFee: entry.isFee,
          };
        } else {
          return {
            state: 'pending',
            leg: entry.leg?.beachTripActivity?.id,
            description: entry.description,
            amount: entry.amount,
            isRefund: entry.isRefund,
            isFee: entry.isFee,
          };
        }
      });
  }
  else {
    return entries
      .filter((e) => e.state === 'pending' && !e.canceled)
      .map((entry) => {
        if (entry.id && entry.id > 0) {
          return {
            id: entry.id,
            state: 'pending',
            leg: entry.leg?.tripActivityLeg.id,
            description: entry.description,
            amount: entry.amount,
            isRefund: entry.isRefund,
            isFee: entry.isFee,
          };
        } else {
          return {
            state: 'pending',
            leg: entry.leg?.tripActivityLeg.id,
            description: entry.description,
            amount: entry.amount,
            isRefund: entry.isRefund,
            isFee: entry.isFee,
          };
        }
      });
  }
};

export const validatePayment = (total: number, paymentForm: BookingPaymentForm) => {
  const validatePayment = Check.checkValidation(paymentForm, BookingPaymentValidation);

  let val = +paymentForm.amount.value;
  let foundErr = false;
  if (total !== 0 && total !== val) {
    foundErr = true;
    validatePayment.form.amount.valid = false;
    validatePayment.form.amount.message = i18n.t('tripBookings.incorrectAmount');
  } else {
    validatePayment.form.amount.valid = true;
  }

  if (foundErr || validatePayment.invalid) {
    return { invalid: true, form: validatePayment.form };
  }

  return { invalid: false, form: validatePayment.form };
};

export const checkIsBookingCanceled = (
  outbound: BookingLeg,
  inbound: BookingLeg,
  selectedBeachTrip: BookingLeg,
  outboundToCancel: boolean,
  inboundToCancel: boolean,
  beachToCancel: boolean,
  confirmedCancelation: boolean
) => {
  if (
    outbound.id > 0 &&
    outbound.tripActivityLeg.id > 0 &&
    outboundToCancel &&
    inbound.id > 0 &&
    inbound.tripActivityLeg.id > 0 &&
    inboundToCancel &&
    !confirmedCancelation
  ) {
    return true;
  } else if (
    outbound.id > 0 &&
    outbound.tripActivityLeg.id > 0 &&
    outboundToCancel &&
    inbound.id < 0 &&
    inbound.tripActivityLeg.id < 0 &&
    !confirmedCancelation
  ) {
    return true;
  } else if (
    outbound.id < 0 &&
    outbound.tripActivityLeg.id < 0 &&
    inbound.id > 0 &&
    inbound.tripActivityLeg.id > 0 &&
    inboundToCancel &&
    !confirmedCancelation
  ) {
    return true;
  }
  else if (
    selectedBeachTrip.id > 0 &&
    selectedBeachTrip.beachTripActivity?.id &&
    selectedBeachTrip.beachTripActivity?.id > 0 &&
    beachToCancel &&
    !confirmedCancelation
  ) {
    return true;
  }
  return false;
};