/**
 * Customerpurchasehistory scene
 *
 * @author João Dias <jdias@ubiwhere.com>
 *
 *
 */
import React, { useEffect } from 'react'
import { Wrapper, ControlsContainer, Controls1, Controls2 } from 'shared/genericStyledComponents'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import { actions, RootState } from 'store/rootSlices'
import SearchBox from 'shared/components/SearchBox'
import DropdownExport from 'shared/components/FormComponents/dropdownexport'
import TableContainer from 'shared/components/TableContainer'
import SorterComponent from 'shared/components/SorterComponent'
import config from 'config'
import Customerpurchaserow from './components/Customerpurchaserow'
import Loadingcontainer from 'shared/components/Loadingcontainer'
import { paginationCurrentPage, paginationElementsCount } from 'utils'


const Customerpurchasehistory = ({ t }) => {

  const { bookings, loading, total, page, sort } = useSelector((state: RootState) => state.Customerpurchasehistory)
  const { onMount, onUnmount, setPage, setSort, setSearchTerm } = actions.Customerpurchasehistory
  const { navigateTo } = actions.App
  const { id } = useParams()

  const { ELEMENTS_PER_PAGE } = config
  const dispatch = useDispatch()
  const dbDispatch = _.debounce(dispatch, 500);

  useEffect(() => {
    dispatch(onMount(id))
    return () => {
      dispatch(onUnmount())
    }
  }, [dispatch, onMount, onUnmount, id]);

  return (
    <Wrapper>
      <ControlsContainer>
        <Controls1>
          <SearchBox id="customerPurchaseHistory-searchBox" onSubmit={(term) => { dbDispatch(setSearchTerm(term)) }} />
        </Controls1>
        <Controls2>
          <DropdownExport
            id="customerPurchaseHistory-dropdownExport"
            dropdown={true}
            wrapperStyle={{ maxWidth: '100%', height: '100%', marginBottom: 0 }}
            options={[{ value: "json", prefix: "1" }, { value: "csv", prefix: "2" }]}
          />
        </Controls2>
      </ControlsContainer>
      {loading ?
        <Loadingcontainer />
        :
        <TableContainer
          id={`customerPurchaseHistory-table`}
          count={paginationElementsCount(total)}
          currentPage={paginationCurrentPage(total, page)}
          elementsPerPage={ELEMENTS_PER_PAGE}
          onPageChange={pg => { dispatch(setPage(pg)) }}
          headerElements={[
            <SorterComponent
                sort={sort}
                id="tripBookings-numberSorter"
                filterName={t('tripBookings.bookingNumber')}
                active={sort.field === "uid"}
                onChange={(dir) => { dispatch(setSort({ field: "uid", direction: dir })) }}
            />,
            <SorterComponent
                    sort={sort}
                    id="tripBookings-dateSorter"
                    filterName={t('common.date')}
                    active={sort.field === "trip_date"}
                    onChange={(dir) => { dispatch(setSort({ field: "trip_date", direction: dir })) }}
            />,
            t('tripBookings.service'),
            'PAX',
            t('tripBookings.value'),
            t('tripBookings.status'),
            t('common.actions'),
          ]}
        >
          {bookings.map((el, idx) => {
            return <Customerpurchaserow
              key={`booking-${idx}`}
              booking={el}
              onClick={(id) => dispatch(navigateTo(`/viagens/reservas/editar-reserva/${id}`))}></Customerpurchaserow>
          })}
        </TableContainer>
      }
    </Wrapper>
  )
}

export default Customerpurchasehistory
