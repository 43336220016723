import * as React from "react"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={16}
      height={11}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8 1.5c2.527 0 4.78 1.42 5.88 3.667A6.513 6.513 0 018 8.833a6.513 6.513 0 01-5.88-3.666A6.513 6.513 0 018 1.5zM8 .167c-3.333 0-6.18 2.073-7.333 5 1.153 2.926 4 5 7.333 5s6.18-2.074 7.333-5c-1.153-2.927-4-5-7.333-5zM8 3.5a1.667 1.667 0 11-.001 3.335A1.667 1.667 0 018 3.5zm0-1.333c-1.653 0-3 1.346-3 3 0 1.653 1.347 3 3 3s3-1.347 3-3c0-1.654-1.347-3-3-3z"
        fill="#636F83"
      />
    </svg>
  )
}

export default SvgComponent
