/**
 * TripCategoryform scene slice
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */
import { createSlice } from '@reduxjs/toolkit'
import { CampaignForm } from 'types/trips/campaigns'
import { camelizeKeys } from 'humps'
//import { ServiceFormValidations } from '../utils'
//import * as Check from "validations"
//import { CampaignFormValidations } from '../utils'


interface InitialState {
    campaignID: string,
    edit: Boolean,
    submitButtonState: boolean,
    campaignForm: CampaignForm,
    campaignActivities: any,
    hasEditPermission: boolean,
}

const initialState: InitialState = {
    campaignID:"",
    edit: false,
    submitButtonState: false,
    hasEditPermission: true,
    campaignForm: {
        name: {
            value: "",
            valid: true,
            message: ""
        },
        sendEmailBody: {
            value: "",
            valid: true,
            message: ""
        },
        sendEmailAfterNumberReservations: {
            value: "",
            valid: true,
            message: ""
        },
        activityName:{
            value: "",
            valid: true,
            message: ""
        },
        hasMultipleCodes: {
            value: false,
            valid: true,
            message: ""
        },
        discount:{
            value: "",
            valid: true,
            message: ""
        },
        isDiscountPercentage: {
            value: true,
            valid: true,
            message: ""
        },
        prefix:{
            value: "",
            valid: true,
            message: ""
        },
        quantity:{
            value: "",
            valid: true,
            message: ""
        },
        validityMonths:{
            value: "",
            valid: true,
            message: ""
        },
        isActive:{
            value: true,
            valid: true,
            message: ""
        },
    },
    campaignActivities:[]
}

export default createSlice({
    name: 'tripcampaignform',
    initialState,
    reducers: {
        onMount: (state, { payload }) => {},
        onUnmount: () => { 
            return initialState;
        },
        setAction: (state, { payload }) => {
            state.edit = payload
        },
        setSubmitButtonState: (state, { payload }) => {
            state.submitButtonState = payload
        },
        setCampaignID: (state, { payload }) => {
            state.campaignID = payload
        },
        setHasEditPermission: (state, { payload }) => {
            state.hasEditPermission = payload
        },
        populateForm: (state, { payload }) => {
            const campaign = camelizeKeys(payload)
            //const campaign = TripCampaignsMock[0]
            const baseForm = { ...initialState.campaignForm }
      
            Object.keys(campaign).forEach(key => {
              if (key in baseForm) {
                baseForm[key] = {
                  ...baseForm[key],
                  value: campaign[key],
                }
              }
            })
            
            state.campaignForm = baseForm;
        },
        populateActivities:(state, { payload }) => {
            const acts = payload.map(act => {
                return act.id
            })
            state.campaignActivities = acts
            
        },
        onFormChange: (state, { payload }) => {
            state.campaignForm = payload //Check.setAndCheckValidation(state.categoryForm, payload, CategoryFormValidations)
        },
        onActivitiesSelectChange:(state, { payload }) => {
            state.campaignActivities = payload
        },
        onSubmit: (state, { payload }) => { }
        
    }
})