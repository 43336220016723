import styled from 'styled-components'
import theme from 'ui/theme';

export const Wrapper = styled.div<{ hasIcon: boolean }>`
  max-width: 185px;
  width: ${props => props.hasIcon ? "30px" : "fit-content"};
`

export const ArrowContainer = styled.div<{ current: "" | "desc" | "asc" }>`
  margin-left: 5px;
  padding: 3px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  background-color: transparent;
  transition: background-color 0.5s, opacity 0.5s;
  opacity: ${props => props.current === "desc" || props.current === "asc" ? "initial" : "0"};
  transform:  ${props => props.current === "desc" ? "rotate(180deg)" : "none"};

  :hover {
      background-color: ${theme().gray300Color};:hover {
        opacity: initial;
  }
`

export const CurrentValueContainer = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  color: ${theme().darkColor};
`

export const SelectContainer = styled.div<{ isDefaultSelected: boolean, hasIcon: boolean }>`
  display: flex;
  align-items: center;
  justify-content:  ${props => props.hasIcon ? "flex-end" : "flex-start"};
  padding: 0 !important;
  border: 0;
  font-family: 'Inter';
  font-size: 14px;
  cursor: pointer;

  > svg {
    overflow: visible;
  }
`
