import styled from 'styled-components'
import TableContainer from 'shared/components/TableContainer'

const FuellingsTable = styled(TableContainer)`

`

const ConsumptionTable = styled(TableContainer)`
  > div {
   > table {
    > thead {
        >tr {
          > th:first-child {
            width: 45%;
          }

          > th:nth-child(2) {
            width: 20%;
          }
        }
      }
    }
  }
`

export {
  FuellingsTable,
  ConsumptionTable
}