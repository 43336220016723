/**
 * Addvehiclemodal component
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */
import CIcon from '@coreui/icons-react'
import { CCol, CForm, CRow, CTableHeaderCell, CTableRow } from '@coreui/react-pro'
import * as icon from '@coreui/icons'
import React, { useEffect } from 'react'
import CustomModal from 'shared/components/CustomModal'
import CustomTextInput from 'shared/components/CustomTextInput'
import Dropdown from 'shared/components/FormComponents/dropdown'
import { useDispatch, useSelector } from 'react-redux'
import { actions, RootState } from 'store/rootSlices'
import Addreinforcementtablerowform from '../Addreinforcementtablerowform'
import i18n from 'i18next';
import { vehicleReinforcementFilter } from 'scenes/TripForms/Tripplanningsform/utils'
import Loadingcontainer from 'shared/components/Loadingcontainer'
import {
  Wrapper,
  Row,
  ConfirmButtonPrimary,
  CancelButtonPrimary,
  FormFooter,
  Table,
  CustomCTableBody,
  CustomCTableHead
} from './styles'


const Addvehiclemodal = (props) => {

  const { fleetLongList } = useSelector((state: RootState) => state.FleetManagement)
  const {
    selectedVehicle,
    reinforcementCapacity,
    openPlanningOutboundStops,
    openPlanningInboundStops,
    showModal,
    modalLoading,
    openPlanning,
    isUpdatingCircuit,
    modalInitialVehicle,
    activities
  } = useSelector((state: RootState) => state.TripPlannings)
  const {
    onModalVehicleChange,
    onModalVehicleRemove,
    onModalReinforcementCapacityChange,
    onReinforcementStopsFormChange,
    onSubmitModal,
    setModalLoading,
  } = actions.TripPlannings

  const dispatch = useDispatch()

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openPlanning])

  const handleChange = (e) => {
    const newVehicle = fleetLongList.find((v: any) => v.uuid === e.target.value)
    if (newVehicle) {
      dispatch(onModalVehicleChange({
        vehicle: fleetLongList.find((v: any) => v.uuid === e.target.value),
        planning: openPlanning,
        openPlanningOutboundStops,
        openPlanningInboundStops,
        reinforcement: isUpdatingCircuit ? false : openPlanning.vehicles.find(v => v.vehicle.uuid === e.target.value) ? false : true
      }))
    } else {
      dispatch(onModalVehicleRemove({ openPlanningOutboundStops, planning: openPlanning }))
    }
  }

  return (
    <CustomModal
      visible={showModal.show}
      warningType='message'
      warningName={isUpdatingCircuit ? i18n.t('tripPlannings.editVehicleCircuit') : i18n.t('tripPlannings.assignVehicle')}
      buttonIcon={false}
      footerPosition='flex-start'
      textAlign='start'
      noButtons={true}
      size="lg"
      children={
        <CForm>
          <CRow className="g-3">
            <CCol sm={8}>
              <Dropdown
                required={true}
                valid={selectedVehicle.valid}
                helpText={selectedVehicle.message}
                value={selectedVehicle.value}
                disabled={false}
                label={isUpdatingCircuit ? i18n.t('tripPlannings.vehicle') : i18n.t('tripPlannings.selectNewVehicle')}
                placeholder={isUpdatingCircuit ? i18n.t('tripPlannings.unnasign') : i18n.t('placeholders.newVessel')}
                options={vehicleReinforcementFilter(fleetLongList, activities, openPlanning, isUpdatingCircuit, modalInitialVehicle.value)}
                onChange={handleChange}
              />
            </CCol>
            <CCol>
              <CustomTextInput
                required={true}
                valid={reinforcementCapacity.valid}
                helpText={reinforcementCapacity.message}
                value={reinforcementCapacity.value}
                disabled
                label={i18n.t('tripPlannings.lotation')}
                placeholder={i18n.t('placeholders.lotation')}
                onChange={(e) => dispatch(onModalReinforcementCapacityChange({ value: e.target.value, message: "", valid: true }))}
              />
            </CCol>
          </CRow>
          <Row>
            {
              modalLoading ?
                <Loadingcontainer />
                :
                <Wrapper>
                  <Table borderless>
                    <CustomCTableHead>
                      <CTableRow>
                        <CTableHeaderCell>{i18n.t('tripPlannings.outbounds')}</CTableHeaderCell>
                        <CTableHeaderCell>{i18n.t('tripPlannings.hour')}</CTableHeaderCell>
                        {
                          openPlanning?.activities.map((act, idx) => {
                            return (
                              <CTableHeaderCell key={`table-header-outbound-${idx}`}>{act.activity.shortName}</CTableHeaderCell>
                            )
                          })
                        }
                      </CTableRow>
                    </CustomCTableHead>
                    <CustomCTableBody>
                      {
                        openPlanningOutboundStops.map((local, idx) => {
                          return (
                            <React.Fragment key={`circuit-going-${idx}`}>
                              <Addreinforcementtablerowform
                                onChange={(e) => dispatch(onReinforcementStopsFormChange({
                                  e,
                                  stops: openPlanningOutboundStops,
                                  isOutbounds: true,
                                  idx,
                                  selectedVehicle: selectedVehicle.value,
                                  openPlanning
                                }))}
                                selectedVehicle={selectedVehicle.value}
                                stop={local}
                                trip={openPlanning}
                                isOutbound={true}
                              />
                            </React.Fragment>
                          )
                        })
                      }
                    </CustomCTableBody>
                  </Table>
                  <Table borderless>
                    <CustomCTableHead>
                      <CTableRow>
                        <CTableHeaderCell>{i18n.t('tripPlannings.inbounds')}</CTableHeaderCell>
                        <CTableHeaderCell>{i18n.t('tripPlannings.hour')}</CTableHeaderCell>
                        {
                          openPlanning?.activities.map((act, idx) => {
                            return (
                              <CTableHeaderCell key={`table-header-inbound-${idx}`}>{act.activity.shortName}</CTableHeaderCell>
                            )
                          })
                        }
                      </CTableRow>
                    </CustomCTableHead>
                    <CustomCTableBody>
                      {
                        openPlanningInboundStops.map((local, idx) => {
                          return (
                            <React.Fragment key={`circuit-returning-${idx}`}>
                              <Addreinforcementtablerowform
                                onChange={(e) => dispatch(onReinforcementStopsFormChange({
                                  e,
                                  stops: openPlanningInboundStops,
                                  isOutbounds: false,
                                  idx,
                                  selectedVehicle: selectedVehicle.value,
                                  openPlanning
                                }))}
                                selectedVehicle={selectedVehicle.value}
                                stop={local}
                                trip={openPlanning}
                                isOutbound={false}
                              />
                            </React.Fragment>
                          )
                        })
                      }
                    </CustomCTableBody>
                  </Table>
                </Wrapper>
            }
          </Row>
          <FormFooter footerposition='flex-end' onMouseUp={(e) => { }} >
            <CancelButtonPrimary color='danger' variant='outline' onClick={(e) => { props.onClose(false); dispatch(setModalLoading(true)) }}>
              {i18n.t('common.discardChanges')}
            </CancelButtonPrimary>
            <ConfirmButtonPrimary color='success' onClick={(e) => {
              dispatch(onSubmitModal())
            }
            }>
              {i18n.t('common.confirm')} <CIcon height={14} icon={icon.cilCheckAlt} size={"sm"} />
            </ConfirmButtonPrimary>
          </FormFooter>
        </CForm>
      }
      onclose={e => { props.onClose(false); dispatch(setModalLoading(true)) }}
      onsubmit={e => {/* handleDeleteCollaboratorConfirm(e)  */ }} />
  )
}

export default Addvehiclemodal