/**
 * Bookinglisttablerow component
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */
import React from 'react'
import { DateTime } from "luxon"
import i18n from 'i18next'
import { useSelector, useDispatch } from 'react-redux'
import { camelize } from 'humps'
import { CTableDataCell } from '@coreui/react-pro'
// Store
import { RootState, actions } from 'store/rootSlices'
// Types
import { TableRowPropTypes } from 'types/misc'
import { TripBookingListItem } from 'types/trips/bookings'
// Icons
import StatusLabel from 'assets/icons/status_label'
import PartnerStatusLabel from 'assets/icons/partner_status_label'
import WebsiteStatusLabel from 'assets/icons/website_status_label'
import Info from 'assets/icons/info'
import ChevronRight from 'assets/icons/chevron_right';
// Utils & Configs
import { hasPermission } from 'permissions';
import config from 'config';
// Styles
import theme from 'ui/theme'
import { ActionsCell } from 'shared/genericStyledComponents';
import {
    CustomCTableRow,
    BookingStateLabel,
    ClickableTableDataCell,
    BookingInfo,
    BookingNumber,
    ServiceInfo,
    Tooltip,
    CustomSpan
} from './styles'


const { DATE_FORMAT } = config;

type PropTypes = TableRowPropTypes & {
    id?: string,
    booking: TripBookingListItem,
    actions: JSX.Element,
}

const Bookinglisttablerow = (props: PropTypes) => {
    const { tripDateView } = useSelector((state: RootState) => state.TripBookings);
    const { permissions } = useSelector((state: RootState) => state.App)

    const { PERMISSIONS } = config
    const dispatch = useDispatch()

    const {
        booking: {
            seats,
            outboundTrip,
            inboundTrip,
            customerName,
            extrasSum
        }
    } = props
    
    return (
        <CustomCTableRow id={`booking-${props.booking.id}`}>
            <ClickableTableDataCell onClick={() => props.onClick()}>
                <BookingNumber>
                    {  
                        props.booking.partner ? <PartnerStatusLabel/> :  props.booking.source === 'website' ? <WebsiteStatusLabel/> : <StatusLabel />
                    }
                    {props.booking.uid}
                </BookingNumber>
            </ClickableTableDataCell>
            {tripDateView ?
                <CTableDataCell>{DateTime.fromISO(props.booking.tripDate).toFormat(DATE_FORMAT.DATETIME)}</CTableDataCell>
                :
                <CTableDataCell>{DateTime.fromISO(props.booking.createdAt).toFormat(DATE_FORMAT.DATETIME)}</CTableDataCell>
            }
            <CTableDataCell>
                <BookingInfo>
                    { customerName }
                    {props.booking.tripObservations && (
                        <Tooltip>
                            <Info fill={"#D4A8EA"} />
                            <CustomSpan>{props.booking.tripObservations}</CustomSpan>
                        </Tooltip>
                    )}
                </BookingInfo>
            </CTableDataCell>
            <CTableDataCell>
                <ServiceInfo>
                    {
                        outboundTrip.tripActivityLeg && inboundTrip.tripActivityLeg
                            ?
                        outboundTrip.tripActivityLeg?.activity.name === inboundTrip?.tripActivityLeg?.activity.name
                            ?
                        outboundTrip.tripActivityLeg?.activity.name
                            :
                        outboundTrip.tripActivityLeg?.activity.name + ' - ' + inboundTrip?.tripActivityLeg?.activity.name
                            :
                        outboundTrip.tripActivityLeg
                            ?
                        outboundTrip.tripActivityLeg?.activity.name
                            :
                        inboundTrip?.tripActivityLeg?.activity.name
                    }
                </ServiceInfo>
            </CTableDataCell>
            <CTableDataCell>
                {
                    seats ?? 0
                }
            </CTableDataCell>
            <CTableDataCell>
                {
                    extrasSum ?? 0
                }
            </CTableDataCell>
            <CTableDataCell>€{props.booking.total}</CTableDataCell>
            <CTableDataCell>
                <BookingStateLabel
                    className={props.booking.state}>
                    {
                        i18n.t(`tripBookings.${camelize(props.booking.state === 'paid' || props.booking.state === 'refunded' ? props.booking.state + 'F' : props.booking.state)}`)
                    }
                </BookingStateLabel>
            </CTableDataCell>
            <ActionsCell nopointer>
                {props.actions}
                {
                    hasPermission(permissions, PERMISSIONS.TRIPS.BOOKINGS.VIEW_BOOKING) 
                        &&
                    <a href={`/viagens/reservas/editar-reserva/${props.booking.id}`} onClick={(e) => {
                        e.preventDefault()
                        dispatch(actions.App.navigateTo(`/viagens/reservas/editar-reserva/${props.booking.id}`))
                    }}>
                        <ChevronRight className='pointer' fill={theme().gray500Color} />
                    </a>
                }
            </ActionsCell>
        </CustomCTableRow>
    )
}

export default Bookinglisttablerow