import React from 'react'
import i18n from 'i18next'
import { useDispatch, useSelector } from 'react-redux'
import { actions, RootState } from 'store/rootSlices'
import { CTableHeaderCell, CTableRow, CTableDataCell } from '@coreui/react-pro'
import { DateTime } from 'luxon'
import { hasPermission } from 'permissions';
import ChevronRight from 'assets/icons/chevron_right';
import theme from 'ui/theme'
import CustomCheckbox from 'shared/components/CustomCheckbox'
import CustomRadioButton from 'shared/components/CustomRadioButton'
import Loadingcontainer from 'shared/components/Loadingcontainer'
import CustomModal from 'shared/components/CustomModal'
import config from 'config';
import { Table, CustomCTableBody, CustomCTableHead, Text } from '../../styles'
import { ActionsCell } from './styles'


type ModalsProps = {
  id: string
}

const Modals = ({ id }: ModalsProps) => {

  const {
    showDeleteModal,
    showIsOverbookedModal,
    editDate,
    editFirstDate,
    showBookingsModal,
    affectedBookings,
    showLegsModal,
    loadingLegs,
    planningLegsForm
  } = useSelector((state: RootState) => state.TripPlanningsForm)
  const {
    setEditDate,
    setDeletePlanning,
    setShowDeleteModal,
    setShowBookingsModal,
    setShowIsOverbookedModal,
    setDeleteButtonState,
    setShowLegsModal,
    onPlanningLegsFormChange,
    onPlanningLegsFormSave
  } = actions.TripPlanningsForm
  const { navigateTo } = actions.App
  const { permissions } = useSelector((state: RootState) => state.App)
  const { PERMISSIONS } = config

  const dispatch = useDispatch()

  return (
    <>
      <CustomModal
        id={`tripPlanningForm-deleteModal`}
        warningType='message'
        warningName={i18n.t('common.warning')}
        visible={showDeleteModal}
        buttonIcon={true}
        footerPosition='space-between'
        textAlign='start'
        width={600}
        buttonName={i18n.t('common.confirm')}
        children={
          <div>
            <Text>{i18n.t('tripPlanningForm.deleteModalText')}</Text>
            <p>{i18n.t('tripPlanningForm.changeEditDatesText2')}</p>
            <CustomRadioButton
              group="group1"
              text={`${i18n.t('tripPlanningForm.changeEditDatesOption1')} ${DateTime.fromISO(editDate).toFormat(config.DATE_FORMAT.DATE)}`}
              value={true}
              checked={editFirstDate ? true : false}
              onClick={e => { dispatch(setEditDate({ editDate: editDate, editFirstDate: true })) }}
            />
            <CustomRadioButton group="group1" text={i18n.t('tripPlanningForm.changeEditDatesOption2')} value={false}
              checked={!editFirstDate ? true : false}
              onClick={e => { dispatch(setEditDate({ editFirstDate: false })) }}
            />
          </div>
        }
        onclose={e => {
          dispatch(setShowDeleteModal(false))
          dispatch(setDeleteButtonState(false))
        }}
        onsubmit={e => dispatch(setDeletePlanning({ id }))}
      />
      <CustomModal
        withForm
        singleButton
        id={`tripPlanningForm-isOverbooked`}
        warningType='message'
        warningName={i18n.t('common.warning')}
        visible={showIsOverbookedModal}
        buttonIcon={true}
        buttonName={i18n.t('common.continue')}
        footerPosition='flex-end'
        textAlign='start'
        size={'lg'}
        children={
          <div>
            <Text>{`${i18n.t('tripPlanningForm.isOverbookedModalText')} ${DateTime.fromISO(editDate).toFormat(config.DATE_FORMAT.DATE)}`}</Text>
          </div>
        }
        onclose={e => {
          dispatch(setShowIsOverbookedModal(false))
        }}
        onsubmit={e => dispatch(setShowIsOverbookedModal(false))}
      />
      <CustomModal
        id={`tripPlanningForm-bookingsModal`}
        warningType='message'
        noButtons={true}
        warningName={i18n.t('tripPlanningForm.affectedBookings')}
        visible={showBookingsModal}
        buttonIcon={true}
        footerPosition='space-between'
        textAlign='start'
        width={600}
        buttonName={i18n.t('common.confirm')}
        children={
          affectedBookings ? (
            <Table>
              <CustomCTableHead>
                <CTableRow>
                  <CTableHeaderCell>{i18n.t('tripBookings.booking')}</CTableHeaderCell>
                  <CTableHeaderCell>{i18n.t('common.actions')}</CTableHeaderCell>
                </CTableRow>
              </CustomCTableHead>
              <CustomCTableBody>
                {
                  affectedBookings?.map((booking, idx) => {
                    return (
                      <CTableRow key={`affected-booking-${idx}`}>
                        <CTableDataCell>
                          {booking.uid}
                        </CTableDataCell>
                        <ActionsCell nopointer>
                          {
                            hasPermission(permissions, PERMISSIONS.TRIPS.BOOKINGS.VIEW_BOOKING)
                            &&
                            <a href={`/viagens/reservas/editar-reserva/${booking.id}`} target="_blank" rel="noopener noreferrer">
                              <ChevronRight className='pointer' fill={theme().gray500Color} />
                            </a>
                          }
                        </ActionsCell>
                      </CTableRow>
                    )
                  })
                }
              </CustomCTableBody>
            </Table>
          ) : i18n.t('tripPlanningForm.noAffectedBookings')

        }
        onclose={e => {
          dispatch(setShowBookingsModal(false))
          dispatch(navigateTo('/viagens/agendamentos'))
        }}
        onsubmit={e => { }}
      />
      <CustomModal
        id={`tripPlanningForm-legsModal`}
        warningType='message'
        noButtons={false}
        warningName={i18n.t('tripPlanningForm.planningLegs')}
        visible={showLegsModal}
        buttonIcon={true}
        footerPosition='space-between'
        textAlign='start'
        size='xl'
        buttonName={i18n.t('common.saveBtn')}
        children={
          loadingLegs ? <Loadingcontainer /> : (
            <>
              {
                planningLegsForm.map((act, actIdx) => {
                  return (
                    <>
                      <h6>{act.activity.name}</h6>
                      <Table>
                        <CustomCTableHead>
                          <CTableRow>
                            <CTableHeaderCell>{i18n.t('tripPlanningForm.direction')}</CTableHeaderCell>
                            <CTableHeaderCell>{i18n.t('tripPlanningForm.departure')}</CTableHeaderCell>
                            <CTableHeaderCell>{i18n.t('tripPlanningForm.arrival')}</CTableHeaderCell>
                            <CTableHeaderCell>{i18n.t('common.actions')}</CTableHeaderCell>
                          </CTableRow>
                        </CustomCTableHead>
                        <CustomCTableBody>
                          {
                            act.legs.length > 0 ? act.legs?.map((leg, legIdx) => {
                              return (
                                <CTableRow key={`leg-${leg.departure.name}-${leg.arrival.name}-${legIdx}`}>
                                  <CTableDataCell>{leg.isOutbound ? i18n.t('tripBookings.outbound') : i18n.t('tripBookings.inbound')}</CTableDataCell>
                                  <CTableDataCell>{leg.departure.name} - {DateTime.fromISO(leg.departure.time).toFormat(config.DATE_FORMAT.TIME)}</CTableDataCell>
                                  <CTableDataCell>{leg.arrival.name}</CTableDataCell>
                                  <ActionsCell nopointer>
                                    <CTableDataCell key={`tripPlanningsForm-legRow`}>
                                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <CustomCheckbox
                                          checkStyle={{ width: '40px', height: '20px', marginTop: '5px' }}
                                          id={`act${0}`}
                                          disabled={false}
                                          checked={leg.isActive.value}
                                          onClick={() => dispatch(onPlanningLegsFormChange({ actIdx, legIdx }))}
                                          switch
                                        />
                                      </div>
                                    </CTableDataCell>
                                  </ActionsCell>
                                </CTableRow>
                              )
                            }) : <CTableRow><CTableDataCell colSpan={4}>{i18n.t('tripPlanningForm.noLegs')}</CTableDataCell></CTableRow>
                          }
                        </CustomCTableBody>
                      </Table>
                    </>
                  )
                })
              }
              <div>
                <p>{i18n.t('tripPlanningForm.changeEditDatesText2')}</p>
                <CustomRadioButton
                  group="group1"
                  text={`${i18n.t('tripPlanningForm.changeEditDatesOption1')} ${DateTime.fromISO(editDate).toFormat(config.DATE_FORMAT.DATE)}`}
                  value={true}
                  checked={editFirstDate ? true : false}
                  onClick={e => { dispatch(setEditDate({ editDate: editDate, editFirstDate: true })) }}
                />
                <CustomRadioButton group="group1" text={i18n.t('tripPlanningForm.changeEditDatesOption2')} value={false}
                  checked={!editFirstDate ? true : false}
                  onClick={e => { dispatch(setEditDate({ editFirstDate: false })) }}
                />
              </div>
            </>
          )
        }
        onclose={e => {
          dispatch(setShowLegsModal(false))
        }}
        onsubmit={e => dispatch(onPlanningLegsFormSave())}
      />
    </>
  )
}

export default Modals