import axios from 'axios'
import endpoints from 'api/endpoints'
import { decamelizeKeys  } from 'humps'
import { LocalFormValidations, LocalFormValidationsEdit } from 'scenes/TripForms/Triplocalform/utils'
import { generateUrl, generateSortString } from 'utils'
import { toast } from 'react-toastify'
import { SortType } from 'types/misc'

// import { toast } from 'react-toastify'
// import i18n from 'i18next'

const GetStops = (page: number, searchTerm: string, sort?: SortType) => {
    const searchTermQS = `${searchTerm ? `&search=${searchTerm}` : ''}`;
    let endpoint = `${endpoints.TRIPS.LOCALS.GET_STOPS}?page=${page}${searchTermQS}`;
    
    if (sort && sort.field !== null && sort.direction !== null && sort.direction !== "") {
        endpoint += generateSortString(sort);
    }

    return axios.get(endpoint)
        .then(res => res.data)
        .catch(err => {
        })
}

const GetAllStops = () => {
    return axios.get(endpoints.TRIPS.LOCALS.GET_ALL_STOPS)
        .then(res => res.data)
        .catch(err => {
            throw err
        })
}

const GetStop = (id) => {
    const endpoint = generateUrl(endpoints.TRIPS.LOCALS.GET_STOP, { id })
    return axios.get(endpoint)
        .then(res => res.data)
        .catch(err => {
        })
}

const CreateStop = (params) => {
    const decMLZParams = decamelizeKeys(params)
    
    const stopTypeFormData = new FormData();
    //! Use this code in case we need to use isAvailable block again
    // Object.keys(decMLZParams.is_available.value ? decamelizeKeys(LocalFormValidations) : decamelizeKeys(LocalFormValidationsUnavailable)).forEach(valKey => {
    //     stopTypeFormData.append(valKey, decMLZParams[valKey].value)
    // })
    Object.keys(decamelizeKeys(LocalFormValidations)).forEach(valKey => {
        stopTypeFormData.append(valKey, decMLZParams[valKey].value)
    })
    return axios.post(endpoints.TRIPS.LOCALS.CREATE_STOP, stopTypeFormData)
        .then(res => res.data)
        .catch(err => { throw err }) 
}

const CreateStopPhoto = (params) => {
    
    const stopPhotoFormData = new FormData();
    Object.keys(params).forEach(valKey => {
        stopPhotoFormData.append(valKey, params[valKey])
    })
    
    return axios.post(endpoints.TRIPS.LOCALS.CREATE_STOP_PHOTO, stopPhotoFormData)
        .then(res => res.data)
        .catch(err=> toast.error("Erro ao adicionar foto"))   
}


const EditStop = (id, params) => {
    const decMLZParams = decamelizeKeys(params)
    const endpoint = generateUrl(endpoints.TRIPS.LOCALS.EDIT_STOP, { id })
    
    const stopFormData = new FormData();
    //! Use this code in case we need to use isAvailable block again
    // Object.keys(decMLZParams.is_available.value ? decamelizeKeys(LocalFormValidationsEdit) : decamelizeKeys(LocalFormValidationsEditUnavailable)).forEach(valKey => {
    //     stopFormData.append(valKey, decMLZParams[valKey].value)     
    // })
    Object.keys(decamelizeKeys(LocalFormValidationsEdit)).forEach(valKey => {
        stopFormData.append(valKey, decMLZParams[valKey].value)     
    })
    
    return axios.patch(endpoint, stopFormData)
        .then(res => res.data)
        .catch(err => { throw err })  
}

const EditStopPhoto = (id, params) => {
    const endpoint = generateUrl(endpoints.TRIPS.LOCALS.EDIT_STOP_PHOTOS, { id })
    
    const stopPhotoFormData = new FormData();
    Object.keys(params).forEach(valKey => {
        stopPhotoFormData.append(valKey, params[valKey])
    })
    
    return axios.patch(endpoint, stopPhotoFormData)
        .then(res => res.data)
        .catch(err=> toast.error("Erro ao editar foto")) 
}

const DeleteStopPhoto = (id: number) => {
    const endpoint = generateUrl(endpoints.TRIPS.LOCALS.DELETE_STOP_PHOTOS, { id })

    return axios.delete(endpoint)
        .then(res => res)
        .catch(err => {
            throw err
        })
}

export default {
    GetAllStops,
    GetStops,
    GetStop,
    CreateStop,
    CreateStopPhoto,
    EditStop,
    EditStopPhoto,
    DeleteStopPhoto
}