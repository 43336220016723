/**
 * Maintenancelisttablerowcomplete component
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */

import React from 'react'
import {
  ActionsCell,
  ChevronCell,
  NameAndRoleCell,
  CustomH5
} from 'shared/genericStyledComponents'
import { MaintenanceListSingle } from 'types/fleet/maintenance';
import { CustomCTableRow } from '../styles'
import { CTableDataCell } from '@coreui/react-pro';
import ChevronRight from 'assets/icons/chevron_right';
import { DateTime } from 'luxon';
import { actions } from 'store/rootSlices'
import { useDispatch } from 'react-redux';
 
 type PropTypes = {
   id?: string,
   maintenance: MaintenanceListSingle,
   actions: JSX.Element,
   onClick: Function,
   permission: boolean
 }
 

const Maintenancelisttablerowcomplete = (props: PropTypes) => {

  const dispatch = useDispatch()
  const {
    permission,
    maintenance: {
      report,
      supplier,
      vehicle: {
        name
      },
      invoiceUid,
      cost,
      date,
      id
    }
  } = props;

  const parsed_date = DateTime.fromISO(date).setLocale('pt').toLocaleString()

  return (
    <CustomCTableRow id={props.id}>
      <NameAndRoleCell>
        <CustomH5 onClick={() => props.onClick()}>{name}</CustomH5>
      </NameAndRoleCell>
      <CTableDataCell>{report}</CTableDataCell>
      <CTableDataCell>{supplier}</CTableDataCell>
      <CTableDataCell>{parsed_date}</CTableDataCell>
      <CTableDataCell>{invoiceUid}</CTableDataCell>
      <CTableDataCell>{cost}</CTableDataCell>
      <ActionsCell>
        {props.actions}
        <ChevronCell id={`${props.id}-chevron`}>
        {
            permission &&
            <a href={`/frota/manutencoes/editar-manutencao/${id}`} onClick={(e) => {
              e.preventDefault()
              dispatch(actions.App.navigateTo(`/frota/manutencoes/editar-manutencao/${id}`))
              }}>
              <ChevronRight></ChevronRight>
            </a>
        }
        </ChevronCell>
      </ActionsCell>
    </CustomCTableRow>
  )
}

export default Maintenancelisttablerowcomplete