import * as React from "react"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width={24}
            height={24}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.5 4.062V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.64 5.36 6 7.92 6 11v5l-2 2v1h16v-1l-2-2v-5c0-.378-.025-.748-.073-1.108a4.01 4.01 0 01-2.043-.05c.077.37.116.758.116 1.158v6H8v-6c0-2.48 1.51-4.5 4-4.5.372 0 .723.045 1.05.13a4.029 4.029 0 01.45-2.568zM14 20c0 1.1-.9 2-2 2s-2-.9-2-2h4z"
            />
            <circle cx={17} cy={6} r={3} fill={props.fill} />
        </svg>
    )
}

export default SvgComponent
