import React from 'react'
import i18n from 'i18next'
import { useDispatch, useSelector } from 'react-redux'
import { actions, RootState } from 'store/rootSlices'
import { CCol, CRow, CTableHeaderCell, CTableRow } from '@coreui/react-pro'
import { circuitsIntersectionFilter } from '../../utils';
import { PlanningStopsForm } from 'types/trips/plannings';
import SectionContainer from 'shared/components/Sectioncontainer'
import CustomCheckbox from 'shared/components/CustomCheckbox'
import Dropdown from 'shared/components/FormComponents/dropdown'
import CustomTextInput from 'shared/components/CustomTextInput'
import Addvehicletablerowform from 'scenes/Tripplannings/components/Addvehicletablerowform'
import Timepicker from 'shared/components/FormComponents/timepicker';
import * as Styles from './styles'
import {Table, CustomCTableBody, CustomCTableHead} from '../../styles'

const CircuitsRows = () => {

  const {
    edit,
    sameCircuitForVehicles,
    services,
    vehicles,
    selectedVehicle,
    planningCircuit,
    outboundStops,
    inboundStops,
    planningCircuitDefaultDuration,
    circuitStartHour,
    hasEditPermission,
  } = useSelector((state: RootState) => state.TripPlanningsForm)
  const {  allCircuits } = useSelector((state: RootState) => state.TripsConfiguration)
  const {
    onVehicleChange,
    onCircuitSelect,
    onOutboundsFormChange,
    onInboundsFormChange,
    setSameCircuitForVehicles,
    setCircuitStartHour,
    updateCircuitStops,
    getLegs,
  } = actions.TripPlanningsForm

  const dispatch = useDispatch()

  return (
    <SectionContainer 
      title={i18n.t('tripPlanningForm.circuitTitle')} 
      style={{ width: '100%', paddingBottom: '24px' }}
      extra={
        edit ? (
          <Styles.CustomBtn 
            onClick={(e) => {
              e.preventDefault()
              dispatch(getLegs())
            }}
          >
            {i18n.t('tripPlanningForm.seeLegs')}
          </Styles.CustomBtn>
        ) : <></>
      }
    >
      <Styles.Units>
        <Styles.VehiclesButton role="group" aria-label="Basic checkbox toggle button group" >
          {
            vehicles.map((vhc, idx) => {
              return (
                <Styles.Vehicle
                  type='radio'
                  id={`tripPlanningForm-vehicle${idx}Button`}
                  button={{ color: 'secondary', variant: 'outline' }}
                  name="btnradio"
                  checked={vhc.uuid?.value === selectedVehicle ? true : false}
                  color='secondary'
                  label={vhc.name?.value}
                  onClick={e => dispatch(onVehicleChange({ outboundStops: outboundStops, inboundStops: inboundStops, vehicle: vhc }))}
                />
              )
            })
          }
        </Styles.VehiclesButton>
        {
          vehicles.length > 1 && <CustomCheckbox
            id='SameCircuit'
            wrapperStyle={{ display: 'flex', justifyContent: 'flex-end', alignItems: "center", fontSize: '20px' }}
            //disabled={true} // for now
            checked={sameCircuitForVehicles}
            onClick={e => dispatch(setSameCircuitForVehicles({ sameCircuitForVehicles: !sameCircuitForVehicles, outboundStops, inboundStops }))} //assumes the circuit of the first vehicle
            text={i18n.t('tripPlanningForm.sameCircuit')}
            switch
          />
        }
      </Styles.Units>

      <CRow className="g-3">
        <CCol sm={5}>
          <Dropdown
            required
            disabled={!hasEditPermission || edit}
            id={'tripPlanningForm-circuit'}
            placeholder={i18n.t('placeholders.circuit')}
            options={circuitsIntersectionFilter(allCircuits, services)}
            value={planningCircuit.circuit.value}
            valid={planningCircuit.circuit.valid}
            helpText={planningCircuit.circuit.message}
            label={i18n.t('tripPlanningForm.circuitTitle')}
            onChange={(e) => {
              dispatch(onCircuitSelect({
                outboundStops: outboundStops,
                inboundStops: inboundStops,
                services: services,
                vehicles: vehicles,
                e,
                circuit: allCircuits.filter((item: any) => item.id.toString() === e.target.value)
              }))
            }}
          />
        </CCol>
        <CCol>
          <CustomTextInput
            required={true}
            id={'tripPlanningForm-duration'}
            valid={true}
            disabled={true}
            value={planningCircuitDefaultDuration}
            helpText={""}
            placeholder={i18n.t('placeholders.duration1')}
            label={i18n.t('tripPlanningForm.duration')}
            onChange={(e) => { }}
          />
        </CCol>
        <Styles.GenerateColumnButton>
          <Timepicker
            disabled={!hasEditPermission}
            label={i18n.t('tripPlanningForm.startHour')}
            wrapperStyle={{ width: '75%', alignSelf: 'flex-start', minWidth: '70px' }}
            timeInterval={60}
            timeFormat={'HH:mm'}
            value={circuitStartHour}
            onChange={(e) => { dispatch(setCircuitStartHour(e)) }}
          />
          <Styles.Button id='tripPlanningForm-applyStartHour' color="primary"
            disabled={!hasEditPermission || circuitStartHour === "" || planningCircuit.circuit.value === ""}
            onClick={(e) => dispatch(updateCircuitStops({ circuitStartHour, outboundStops, inboundStops }))}>
            {i18n.t('tripPlanningForm.applyStartHour')}
          </Styles.Button>
        </Styles.GenerateColumnButton>
      </CRow>
      <div style={{ width: '100%', paddingTop: '24px' }}>
        <Table borderless>
          <CustomCTableHead>
            <CTableRow>
              <CTableHeaderCell>{i18n.t('tripPlannings.outbounds')}</CTableHeaderCell>
              <CTableHeaderCell>{i18n.t('tripPlannings.hour')}</CTableHeaderCell>
              {
                services?.map((ser, idx) => {
                  return (
                    <CTableHeaderCell>{ser.serviceDetails?.shortName}</CTableHeaderCell>
                  )
                })
              }
            </CTableRow>
          </CustomCTableHead>
          <CustomCTableBody>
            {
              outboundStops?.map((stop: PlanningStopsForm, idx) => {
                return (
                  <React.Fragment key={`circuit-going-${idx}`}>
                    <Addvehicletablerowform
                      disabled={!hasEditPermission}
                      onClick={(e, id) => { }}
                      onChange={(e) => dispatch(onOutboundsFormChange({ info: e, outboundStops }))}
                      stop={stop}
                      vehicle={selectedVehicle}
                      services={services}
                      isInBound={false}
                      id={`tripPlanningForm-outBoundStop${idx}`}
                      actions={[]}
                    />
                  </React.Fragment>
                )
              })
            }
          </CustomCTableBody>
        </Table>
        <Table borderless>
          <CustomCTableHead>
            <CTableRow>
              <CTableHeaderCell>{i18n.t('tripPlannings.inbounds')}</CTableHeaderCell>
              <CTableHeaderCell>{i18n.t('tripPlannings.hour')}</CTableHeaderCell>
              {
                services?.map((ser, idx) => {
                  return (
                    <CTableHeaderCell>{ser.serviceDetails?.shortName}</CTableHeaderCell>
                  )
                })
              }
            </CTableRow>
          </CustomCTableHead>
          <CustomCTableBody>
            {
              inboundStops?.map((stop: PlanningStopsForm, idx) => {
                return (
                  <React.Fragment key={`circuit-returning-${idx}`}>
                    <Addvehicletablerowform
                      disabled={!hasEditPermission}
                      onClick={(e, id) => { }}
                      onChange={(e) => dispatch(onInboundsFormChange({ info: e, inboundStops }))}
                      stop={stop}
                      vehicle={selectedVehicle}
                      services={services}
                      isInBound={false}
                      id={`tripPlanningForm-inBoundStop${idx}`}
                      actions={[]}
                    />
                  </React.Fragment>
                )
              })
            }
          </CustomCTableBody>
        </Table>
      </div>
    </SectionContainer>
  )
}

export default CircuitsRows