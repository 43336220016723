import { call, putResolve, select } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { actions, RootState } from 'store/rootSlices';
import API from 'api';
import { toast } from 'react-toastify';
import i18n from 'i18next';
import * as Check from 'validations';
import { CustomerEmailValidation } from './utils';

export function* sendBookingTicketSaga({ payload }: PayloadAction<number>) {
  const { sendTicketForm } = yield select((state: RootState) => state.BookingForm);

  const emailValidation = Check.checkValidation(
    { email: sendTicketForm.email },
    CustomerEmailValidation
  );

  if (emailValidation.invalid) {
    yield putResolve(
      actions.BookingForm.onSendTicketFormChange({ email: emailValidation.form.email })
    );
  } else {
    try {
      yield call(API.Bookings.SendBookingTicket, payload, sendTicketForm.email.value);
      yield putResolve(actions.BookingForm.setShowSendTicketModal(false));
    } catch {
      toast.error(i18n.t('toasts.bookingSendTicketError'));
    }
  }
}