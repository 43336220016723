/**
 * Document row component
 *
 * @author João Dias <jdias@ubiwhere.com>
 *
 *
 */

import { CCol, CFormLabel, CRow } from '@coreui/react-pro'
import React, { useState } from 'react'
import i18n from "i18next"
import { useDispatch, useSelector } from 'react-redux'
import Datepicker from '../FormComponents/datepicker'
import FileUploader from '../FormComponents/fileuploader'
import { actions, RootState } from 'store/rootSlices'
import Trash from "assets/icons/trash"
import CustomModal from '../CustomModal'
import { EntityDocumentsForm } from 'types/entity/entity'
import { Wrapper, FormLabelContainer } from './styles'

interface PropTypes {
  id?: string,
  idx: number,
  document: EntityDocumentsForm,
  hasEditPermission: boolean,
}

const EntityDocumentrow = (props: PropTypes) => {
  const [deleteModal, setShowDeleteModal] = useState(false);


  const { document, idx } = props;
  const dispatch = useDispatch();

  const { documentsForm } = useSelector((state: RootState) => state.Entityform);
  const { onDocumentChange, deleteDocument } = actions.Entityform;

  return (
    <Wrapper>
      <CustomModal
        id={`${props.id}-deleteDocumentModal`}
        visible={deleteModal}
        warningType='warning'
        warningName='Aviso'
        buttonIcon={false}
        footerPosition='center'
        textAlign='center'
        children={<p>{i18n.t('fleetForm.deleteVehicleDocument')}?</p>}
        onclose={e => setShowDeleteModal(false)}
        onsubmit={e => dispatch(deleteDocument({ doc: document, idx: props.idx }))} />
      <CRow className="g-3">
        <CCol sm>
          <Datepicker
            id={`${props.id}-expiration`}
            disabled={!props.hasEditPermission} 
            value={document.expiration?.value}
            valid={document.expiration.valid}
            helpText={document.expiration?.message}
            required={true}
            label={i18n.t('fleetForm.expiration')}
            onChange={(date, e) => dispatch(onDocumentChange({ ...documentsForm[idx], expiration: { value: date, message: "", valid: true }, idx: idx, date: true }))}
          />
        </CCol>
        <CCol sm>
          {document.new ?
            <FileUploader
              id={`${props.id}-uploadDocument`}
              disabled={!props.hasEditPermission} 
              required={true}
              value={document.file?.value}
              valid={document.file.valid}
              helpText={document.file?.message}
              accept='.pdf'
              fileSizeLimit={10000000}
              label={i18n.t('fleetForm.file')}
              onChange={e => {
                dispatch(onDocumentChange({ ...documentsForm[idx], file: { value: e, message: "", valid: true }, idx: idx, doc: true }))
              }}
            />
            :
            <FormLabelContainer>
              <CFormLabel>{i18n.t('fleetForm.file')}</CFormLabel>
              {document && document.url && document.fileName && <a href={document.url} rel="noopener noreferrer" target="_blank">{document.fileName}</a>}
            </FormLabelContainer>}
        </CCol>
        <CCol sm={1} style={{ width: "5%", display: "flex", justifyContent: "space-around", alignItems: "flex-start", marginTop: "50px" }}>
          <Trash id={`${props.id}-deleteDocument`} style={{ cursor: "pointer" }} onClick={() => props.hasEditPermission && setShowDeleteModal(true)} />
        </CCol>
        <hr />
      </CRow>
    </Wrapper>
  )
}

export default EntityDocumentrow