/**
 * TripExtraorm scene slice
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */
import { createSlice } from '@reduxjs/toolkit'
//import { TripExtrasMock } from 'mocks/TripActivityMock'
import { ExtraPhotoForm, ExtrasForm, ExtrasListItem, PackForm } from 'types/trips/extras'
import { camelizeKeys } from 'humps'
import { calculatePackQuantity } from '../utils'


interface InitialState {
    extraID: string,
    edit: Boolean,
    extraForm: ExtrasForm,
    packForm: PackForm[],
    photos: ExtraPhotoForm[]
    newPhotosID: number,
    hasEditPermission: true,
    submitButtonState: boolean
}

const initialState: InitialState = {
    extraID: "",
    edit: false,
    hasEditPermission: true,
    submitButtonState: false,
    photos: [],
    newPhotosID: 99,
    extraForm: {
        icon:{
            value: null,
            valid: true,
            message: ""
        },
        namePt: {
            value: "",
            valid: true,
            message: ""
        },
        nameEn: {
            value: "",
            valid: true,
            message: ""
        },
        nameEs: {
            value: "",
            valid: true,
            message: ""
        },
        nameFr: {
            value: "",
            valid: true,
            message: ""
        },
        shortName: {
            value: "",
            valid: true,
            message: ""
        },
        descriptionPt: {
            value: "",
            valid: true,
            message: ""
        },
        descriptionEn: {
            value: "",
            valid: true,
            message: ""
        },
        descriptionEs: {
            value: "",
            valid: true,
            message: ""
        },
        descriptionFr: {
            value: "",
            valid: true,
            message: ""
        },
        maxCapacity: {
            value: "",
            valid: true,
            message: ""
        },
        price: {
            value: "",
            valid: true,
            message: ""
        },
        isActive: {
            value: true,
            valid: true,
            message: ""
        },
        isPack: {
            value: false,
            valid: true,
            message: ""
        },
    },
    packForm: []
}

export default createSlice({
    name: 'tripextraform',
    initialState,
    reducers: {
        onMount: (state, { payload }) => { },
        onUnmount: () => {
            return initialState;
        },
        setAction: (state, { payload }) => {
            state.edit = payload
        },
        setHasEditPermission: (state, { payload }) => {
            state.hasEditPermission = payload
        },
        setSubmitButtonDisabled: (state, { payload }) => {
            state.submitButtonState = payload
        },
        setExtraID: (state, { payload }) => {
            state.extraID = payload
        },
        populateForm: (state, { payload }) => {
            const extra = camelizeKeys(payload)

            const baseForm = { ...initialState.extraForm }
            const basePackForm = { ...initialState.packForm }

            Object.keys(extra).forEach(key => {
                if (key in baseForm) {
                    baseForm[key] = {
                        ...baseForm[key],
                        value: extra[key],
                    }
                }

                //populate packs if they exist
                if (key === 'packContent') {

                    const packArray: PackForm[] = extra[key].map(el => {
                        return {
                            id: el.extra.id,
                            name: el.extra.name,
                            quantity: { value: String(el.quantity), valid: true, message: "" },
                            isSelected: { value: true, valid: true, message: "" },
                        }
                    })
                    basePackForm['packForm'] = packArray
                }
            })


            state.extraForm = baseForm;
            state.packForm = basePackForm['packForm']
        },
        onToggleExtra: (state, { payload }) => {
            const extraPayload: ExtrasListItem = payload.extra
            const setMaxCapacity = payload.setMax ? true : false
            let newExtra = true

            //check which extra is being toggled
            const newPack = state.packForm.map(extra => {

                if (extra.id === extraPayload.id) {
                    newExtra = false

                    if (extra.isSelected.value === true && setMaxCapacity === false) {
                        return undefined // this works like a flag 'to be removed' when updating the state
                    }
                    else {
                        const quantity = setMaxCapacity ? String(extraPayload.maxCapacity) : extra.quantity.value
                        const isSelected = setMaxCapacity ? true : !extra.isSelected.value

                        return {
                            ...extra,
                            quantity: { value: quantity, valid: true, message: "" },
                            isSelected: { value: isSelected, valid: true, message: "" }
                        }
                    }
                }
                return extra
            }).filter(ext => ext !== undefined)

            //check if a new Extra was toggled
            if (newExtra) {
                const quantity = setMaxCapacity ? String(extraPayload.maxCapacity) : '1'

                newPack.push({
                    id: extraPayload.id,
                    name: extraPayload.name,
                    quantity: { value: quantity, valid: true, message: "" },
                    isSelected: { value: true, valid: true, message: "" }
                })
            }


            //updated the state finally
            if (calculatePackQuantity(newPack) < Number(state.extraForm.maxCapacity.value) || Number(state.extraForm.maxCapacity.value) === 0) {
                state.extraForm.maxCapacity = { ...state.extraForm.maxCapacity, value: String(calculatePackQuantity(newPack)) }
            }
            state.packForm = newPack as PackForm[]

        },
        onChangeExtraQuantity: (state, { payload }) => {
            const extraPayload = payload

            //check which extra is being toggled
            const newPack: PackForm[] = state.packForm.map(extra => {

                if (extra.id === extraPayload.extraId) {

                    return {
                        ...extra,
                        quantity: {
                            value: extraPayload.value === 0 ? String(0) : String(extraPayload.value),
                            valid: true,
                            message: ""
                        }
                    }
                }
                return extra
            })

            //updated the state finally
            // The number of packs is limited by the element with the smallest quantity
            if (calculatePackQuantity(newPack) < Number(state.extraForm.maxCapacity.value) || Number(state.extraForm.maxCapacity.value) === 0) {
                state.extraForm.maxCapacity = { ...state.extraForm.maxCapacity, value: String(calculatePackQuantity(newPack)) }
            }
            state.packForm = newPack
        },
        populatePhotosForm: (state, { payload }) => {
            const photos: ExtraPhotoForm[] = Array.from([])

            payload.photos.forEach((photo) => {
                photos.push({
                    id: photo.id,
                    extra: payload.extraID,
                    file: photo.file,
                    sort: photo.sort,
                    edited: false,
                    new: false,
                })
            })

            state.photos = photos.sort((a, b) => { return a.sort - b.sort })
        },
        addToPhotosArray: (state, { payload }) => {
            const photo = payload.e
            const photos: ExtraPhotoForm[] = Array.from(payload.photos)

            photos.push({
                id: payload.newPhotosID + 1,
                extra: Number(payload.extraID),
                file: photo,
                sort: photos.length + 1,
                edited: false,
                new: true
            })
            state.newPhotosID = payload.newPhotosID + 1
            state.photos = photos
        },
        deletePhotoFromArray: (state, { payload }) => {
            const items: ExtraPhotoForm[] = Array.from(payload.photos)

            items.splice(items.indexOf(items[payload.index]), 1)

            const deletedPhoto = items.map((photo, idx) => {
                if (photo.sort !== idx + 1) {
                    return {
                        ...photo,
                        edited: true,
                        sort: idx + 1
                    }
                }
                else {
                    return {
                        ...photo,
                        sort: idx + 1
                    }
                }
            })

            state.photos = deletedPhoto

        },
        reOrderPhotosArray: (state, { payload }) => {

            if (!payload.e.destination) return;
            const items: ExtraPhotoForm[] = Array.from(payload.photos)

            const [reOrderedItem] = items.splice(payload.e.source.index, 1);
            items.splice(payload.e.destination.index, 0, reOrderedItem)
            const sorted = items.map((photo, idx) => {
                return {
                    ...photo,
                    sort: idx + 1,
                    edited: true,
                }
            })

            state.photos = sorted
        },
        onFormChange: (state, { payload }) => {
            state.extraForm = payload //Check.setAndCheckValidation(state.categoryForm, payload, CategoryFormValidations)
        },
        onSubmit: (state, { payload }) => { },
        createPhoto: (state, { payload }) => { },
        editPhotoArray: (state, { payload }) => { },
        createIcon: (state, { payload }) => { },
    }
})