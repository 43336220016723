/**
 * Sectioncontainer component
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */

import React from 'react'
import * as icon from '@coreui/icons'
import styled from 'styled-components'
import theme from 'ui/theme';
import { CButton } from '@coreui/react';
import CIcon from '@coreui/icons-react';
import i18n from "i18next";

type PropTypes = React.PropsWithChildren<{
  id?: string,
  title?: String,
  text: JSX.Element,
  style?: React.CSSProperties,
  onClick?: Function,
}>

const SectionContainerDelete = (props: PropTypes) => {
  return (
    <Wrapper style={props.style}>
      <TitleBox>
        <Title>{props.title ? props.title : ""}</Title>
      </TitleBox>
      <ContainerBox>
        {props.text}
        <Button id={props.id} color="none" onClick={e => props.onClick ? props.onClick(e) : null}>{i18n.t('common.deleteBtn')}<CIcon height={15} icon={icon.cilTrash} size={"sm"} /></Button>
      </ContainerBox>
    </Wrapper>
  )
}

export default SectionContainerDelete

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
`

const Button = styled(CButton)`
    background-color:${theme().white};
    border-color: ${theme().danger};
    color:${theme().danger};
    height:50%;
    align-self:center;
    box-shadow: 0 0 0 0;
    &:hover{
      color:${theme().white};
      background-color: ${theme().danger};
      border-color:${theme().danger};
    }
    &:focus{
      box-shadow: 0 0 0 0;
      color:${theme().white};
      background-color: ${theme().danger};
      border-color:${theme().white};
    }
`

const TitleBox = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
`

const Title = styled.h6`
    margin: 0;
    font-family: 'Inter';
    font-style: normal;
    font-size: 19px !important;
    color: ${theme().danger};
`

const ContainerBox = styled.div`
    display:flex;
    flex-wrap:wrap;
    flex-direction:row;
    justify-content:space-between;
    width: 100%;
    color:${theme().danger};
    padding: 25px;
    border: 1px solid ${theme().danger};
    box-sizing: border-box;
    border-radius: 4px;
    background-color: ${theme().white}
`
