import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions, RootState } from 'store/rootSlices';
import theme from 'ui/theme';
import i18n from "i18next";
//styles
import * as Styles from './styles';
//components
import CustomButton from 'shared/components/CustomButton';
import config from 'config';
import { hasPermission } from 'permissions';

const ButtonsRow = () => {

  const {
    edit,
    submitButtonState,
    canRescheduleLegs,
  } = useSelector((state: RootState) => state.BookingForm);

  const {
    onSubmit,
    setShowCancelationModal,
    setCancelFullBooking,
  } = actions.BookingForm;

  const { permissions } = useSelector((state: RootState) => state.App);

  const { navigateBack } = actions.App;

  const dispatch = useDispatch();
  const { PERMISSIONS } = config;

  const _renderCancelButton = () => {
    return edit && canRescheduleLegs && hasPermission(permissions, PERMISSIONS.TRIPS.BOOKINGS.CANCEL_BOOKING) && (
      <CustomButton
        id="bookingForm-cancel"
        onClick={() => {
          dispatch(setCancelFullBooking(true));
          dispatch(setShowCancelationModal(true));
        }}
        text={i18n.t('tripBookings.cancelBooking')}
        color="danger"
        buttonStyle={{
          color: theme().white,
          textDecoration: 'unset',
          marginRight: '10px',
        }}
      />
    )
  }

  const _renderSaveButton = () => {
    const content = (
      <CustomButton
        id="bookingForm-save"
        disabled={submitButtonState}
        onClick={() => dispatch(onSubmit())}
        text={i18n.t('common.saveBtn')}
        icon="confirm"
        color="success"
        buttonStyle={{ color: theme().white }}
      />
    )

    return edit
    ? hasPermission(permissions, PERMISSIONS.TRIPS.BOOKINGS.EDIT_BOOKING) && content
    : hasPermission(permissions, PERMISSIONS.TRIPS.BOOKINGS.CREATE_BOOKING) && content
  }

  return (
    <Styles.ButtonWrapper>
      <CustomButton
        id="bookingForm-goBack"
        onClick={() => dispatch(navigateBack())}
        text={i18n.t('common.back')}
        color="link"
        buttonStyle={{ color: theme().primary, textDecoration: 'unset' }}
      />
      <div>
        { _renderCancelButton() }
        { _renderSaveButton() }
      </div>
    </Styles.ButtonWrapper>
  );
};

export default ButtonsRow;