/**
 * Documentconfigform scene utils
 *
 * @author João Dias <jdias@ubiwhere.com>
 *
 *
 */

import { FormField, CreatedBy, UpdatedBy } from "types/misc"
import * as Check from "validations"

export type DocumentConfigForm = {
    isActive: FormField<boolean>;
    name: FormField<string>;
}

export const defaultDocumentConfigForm: DocumentConfigForm = {
    isActive: {
        value: true,
        message: "",
        valid: true,
    },
    name: {
        value: "",
        message: "",
        valid: true,
    }

}

export const documentConfigFormValidations = {
    isActive: [Check.isRequired],
    name: [Check.isRequired]
}

export type SetDocumentSagaPayload = {
    createdAt: string,
    createdBy: CreatedBy,
    id: number,
    isActive: boolean,
    name: string,
    updatedAt: string,
    updatedBy: UpdatedBy
}