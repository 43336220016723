/**
 * TripExtraorm scene slice
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */
import { createSlice } from '@reduxjs/toolkit'
//import { TripLocalsMock } from 'mocks/TripActivityMock'
import { LocalForm, LocalPhotoForm } from 'types/trips/locals'
import { camelizeKeys } from 'humps'


interface InitialState {
    localID: string,
    edit: Boolean,
    localForm: LocalForm
    photos: LocalPhotoForm[]
    newPhotosID: number,
    hasEditPermission: boolean,
    submitButtonState: boolean
}

const initialState: InitialState = {
    localID: "",
    edit: false,
    hasEditPermission: true,
    submitButtonState: false,
    photos: [],
    newPhotosID: 99,
    localForm: {
        name: {
            value: "",
            valid: true,
            message: ""
        },
        shortName: {
            value: "",
            valid: true,
            message: ""
        },
        description: {
            value: "",
            valid: true,
            message: ""
        },
        address: {
            value: "",
            valid: true,
            message: ""
        },
        phone: {
            value: "",
            valid: true,
            message: ""
        },
        postalCode: {
            value: "",
            valid: true,
            message: ""
        },
        city: {
            value: "",
            valid: true,
            message: ""
        },
        country: {
            value: "",
            valid: true,
            message: ""
        },
        latitude: {
            value: "",
            valid: true,
            message: ""
        },
        longitude: {
            value: "",
            valid: true,
            message: ""
        },
        timeBefore: {
            value: "",
            valid: true,
            message: ""
        },
        isIsland: {
            value: false,
            valid: true,
            message: ""
        },
        isBeach: {
            value: false,
            valid: true,
            message: ""
        },
        isActive: {
            value: true,
            valid: true,
            message: ""
        }
    }
}

export default createSlice({
    name: 'triplocalform',
    initialState,
    reducers: {
        onMount: (state, { payload }) => { },
        onUnmount: () => {
            return initialState
        },
        setAction: (state, { payload }) => {
            state.edit = payload
        },
        setHasEditPermission: (state, { payload }) => {
            state.hasEditPermission = payload
        },
        setSubmitButtonDisabled: (state, { payload }) => {
            state.submitButtonState = payload
        },
        setLocalID: (state, { payload }) => {
            state.localID = payload
        },
        populateForm: (state, { payload }) => {
            const local = camelizeKeys(payload)
            // local = TripLocalsMock[0]
            const baseForm = { ...initialState.localForm }

            Object.keys(local).forEach(key => {
                if (key in baseForm) {
                    baseForm[key] = {
                        ...baseForm[key],
                        value: local[key],
                    }
                }
            })

            state.localForm = baseForm;
        },
        populatePhotosForm: (state, { payload }) => {
            const photos: LocalPhotoForm[] = Array.from([])

            payload.photos.forEach((photo) => {
                photos.push({
                    id: photo.id,
                    stop: photo.localID,
                    file: photo.file,
                    sort: photo.sort,
                    edited: false,
                    new: false,
                })
            })

            state.photos = photos.sort((a, b) => { return a.sort - b.sort })
        },
        addToPhotosArray: (state, { payload }) => {
            const photo = payload.e
            const photos: LocalPhotoForm[] = Array.from(payload.photos)

            photos.push({
                id: payload.newPhotosID + 1,
                stop: Number(payload.localID),
                file: photo,
                sort: photos.length + 1,
                edited: false,
                new: true
            })

            state.newPhotosID = payload.newPhotosID + 1
            state.photos = photos
        },
        deletePhotoFromArray: (state, { payload }) => {
            const items: LocalPhotoForm[] = Array.from(payload.photos)

            items.splice(items.indexOf(items[payload.index]), 1)

            const deletedPhoto = items.map((photo, idx) => {
                if (photo.sort !== idx + 1) {
                    return {
                        ...photo,
                        edited: true,
                        sort: idx + 1
                    }
                }
                else {
                    return {
                        ...photo,
                        sort: idx + 1
                    }
                }
            })

            state.photos = deletedPhoto

        },
        reOrderPhotosArray: (state, { payload }) => {
            if (!payload.e.destination) return;
            const items: LocalPhotoForm[] = Array.from(payload.photos)

            const [reOrderedItem] = items.splice(payload.e.source.index, 1);
            items.splice(payload.e.destination.index, 0, reOrderedItem)
            const sorted = items.map((photo, idx) => {
                return {
                    ...photo,
                    sort: idx + 1,
                    edited: true,
                }
            })
            state.photos = sorted
        },
        onFormChange: (state, { payload }) => {
            state.localForm = payload
        },
        onSubmit: (state, { payload }) => { },
        createPhoto: (state, { payload }) => { },
        editPhotoArray: (state, { payload }) => { },
    }
})