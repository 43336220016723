/**
 * App sagas
 * Please write a description
 *
 * @author Carlos Silva <csilva@ubiwhere.com>
 *
 *
 */

import { call, takeLatest, putResolve, select } from 'redux-saga/effects';
import { history } from 'utils';
import API from 'api'
import { actions, RootState } from 'store/rootSlices';
import { parseJwt } from 'scenes/Login/utils';
//import { User } from 'types/users/user';
//import { removeAuthorizationInterceptor } from "interceptors";
//import pt from 'translations'
import i18n from 'i18next';
import routes from 'translations/routes.json'

import { createRoutesTranslationBundles, createTranslationBundles, /* DEFAULT_USER */ } from 'app/utils';
import { toast } from 'react-toastify';
import config from 'config';
import Routes from 'routes';
import { hasPermission } from 'permissions';

function* onMountSaga() {

  const JWToken = window.localStorage.getItem('access_token')

  if (JWToken) {
    const parsedJWT = parseJwt(JWToken)
    try {
      const user = yield call(API.Auth.GetUser, {uuid: parsedJWT.extra_fields.uuid, userType: 'team_member'})

      if (user) {
        if (history.location.pathname.includes(`/login/mudar-password/`) && parsedJWT.extra_fields.force_password_change === true) {
          yield putResolve(actions.ChangePassword.showEmail(false))
          yield putResolve(actions.ChangePassword.showChangePassword(true))
          yield putResolve(actions.App.navigateTo(`/login/mudar-password/${" "}`))
        }
        else if (history.location.pathname === '/login') {
          if (user.userType.uid === 'team_member' && user.hasAcceptedTerms === false) {
            yield putResolve(actions.Login.showTermsAndConditions(true))
            yield putResolve(actions.Login.setIsLoggedIn(true))
            yield putResolve(actions.App.navigateTo('/login'))
          } else {
            if (user.userType.uid === 'team_member' && parsedJWT.extra_fields.force_password_change === true) {
              yield putResolve(actions.Login.logout())
            } else {
              if (user.userType.uid === 'team_member') {
                yield putResolve(actions.App.setPermissions(parsedJWT.extra_fields.permissions))
                yield putResolve(actions.Login.onLogin({
                  user,
                  role: parsedJWT.extra_fields.role
                }));
              }
              else {
                yield putResolve(actions.Login.logout())
              }
            }
          }
        } else {
          if (user.hasAcceptedTerms === false || parsedJWT.extra_fields.force_password_change === true) {
            yield putResolve(actions.App.navigateTo('/login'))
          }
          else {
            let pathExists = false
            Routes.forEach(r => {
              //TODO: review this replacement and the condition below
              let path = (r.path).toString().replace('/:id', '')

              if (history.location.pathname.includes(path)) {
                pathExists = true
              }
            });

            if (pathExists) {
              yield putResolve(actions.App.setPermissions(parsedJWT.extra_fields.permissions))
              yield putResolve(actions.App.setUser({
                email: user.email,
                uuid: user.uuid,
                role: parsedJWT.extra_fields.role,
                userType: {
                  id: user.userType.id,
                  uid: user.userType.uid
                },
                token: user.token
              }));
              yield putResolve(actions.App.setIsInApp(true))
              //get user Info            
              try {
                yield putResolve(actions.App.updateUserRole(user))
              } catch (error) {
              } finally {
                //LOAD APP INFO
                yield putResolve(actions.App.loadAppInfo())
              }
            }
            else {
              yield putResolve(actions.Login.logout())
            }

          }
        }
      }
      else {
        yield putResolve(actions.Login.logout())
      }
    } catch (error) {
      yield putResolve(actions.Login.logout())
    }
  }
  else {
    //condition to check if url has token
    if (
      (history.location.pathname.includes('/login/mudar-password/') && history.location.pathname.split('/')[3] !== "") ||
      history.location.pathname.includes('/clock/qrreader')
    ) {
      yield putResolve(actions.App.navigateTo(history.location.pathname))
    } else {
      yield putResolve(actions.App.navigateTo('/login'))
    }
  }
}

function* navigateToSaga({ payload }: ReturnType<any>) {
  yield call(history.push, payload);
}

function* navigateBackSaga() {
  yield call(history.goBack);
}

function* toggleApiTranslationsEditSaga() {
  const { App } = yield select((state) => state);
  yield putResolve(actions.App.setApiTranslationsEdit(!App.apiTranslationsEdit));
}

function* updateApiTranslationKeySaga({ payload }: ReturnType<any>) {
  yield putResolve(actions.App.setApiTranslations({ API_TRANSLATION_TEST: payload }));
}

function* updateUserRoleSaga({ payload }: ReturnType<any>) {
  //yield putResolve(actions.App.setUser({...payload.user, role: payload.target.value} ))

  const userType = payload.userType.uid
  const userUuid = payload.uuid

  switch (userType) {
    case config.ROLES.TEAM_MEMBER:
      //get collaborator from api
      try {
        const collabInfo = yield call(API.Collaborators.GetCollaborator, userUuid)
        if (collabInfo) {
          yield putResolve(actions.App.setUserInfo({
            avatar: collabInfo.avatar,
            name: collabInfo.name,
            profile: collabInfo.role.name
          }))
        }
      } catch (error) {

      }
      break;
    case config.ROLES.PARTNER:
      try {
        const partnerInfo = yield call(API.Entities.GetSingleEntityMember, userUuid)
        if (partnerInfo) {
          yield putResolve(actions.App.setUserInfo({
            name: partnerInfo.name,
            profile: partnerInfo.partner.name
          }))
        }
      } catch (error) {

      }
      break;
    case config.ROLES.CUSTOMER:
      try {
        const customerInfo = yield call(API.Entities.GetSingleEntityMember, userUuid)
        if (customerInfo) {
          yield putResolve(actions.App.setUserInfo({
            name: customerInfo.name,
          }))
        }
      } catch (error) {

      }
      break;
    default:
      break;
  }
}

function* toogleTranslationSaga({ payload }: ReturnType<any>) {
  const { App } = yield select((state) => state);

  if (App.translation === true) {
    payload.changeLanguage('pt')
    yield putResolve(actions.App.setTranslation(false));
  }
  else {
    payload.changeLanguage('en')
    yield putResolve(actions.App.setTranslation(true));
  }
}

function* getTranslationsSaga() {
  try {
    const res = yield call(API.Translations.GetTranslations);
    if (res) {
      //yield putResolve(actions.App.setApiTranslations(res ?? []));
      let translations = [] as any[];
      res.forEach((translation) => {
        translations.push(translation);
      });

      const translationBundles = createTranslationBundles(translations);
      Object.keys(translationBundles).forEach((language) => {
        i18n.addResourceBundle(
          language,
          'translation',
          translationBundles[language],
          true,
          true
        );
      });
      i18n.changeLanguage(i18n.language);
    }

    //adding routes namespace
    const routeTranslationBundles = createRoutesTranslationBundles(routes);
    i18n.addResourceBundle(
      'pt',
      'routes',
      routeTranslationBundles,
      true,
      true
    );

  } catch (err) {
    toast.error("Error loading translations");
  } finally {
    yield putResolve(actions.App.unsetLoading());
  }
}

function* getCountryCodesSaga() {
  const orderedCountries = yield call(API.TripSettings.GetLanguages)
  yield putResolve(actions.App.setCountryCodes(orderedCountries));
}

//function to get the lists of certain kinds of app data that come from the API
function* loadAppInfoSaga() {

  const { permissions } = yield select((state: RootState) => state.App);

  //COUNTRY CODES
  yield putResolve(actions.App.getCountryCodes());
  //FLEET
  if (hasPermission(permissions, config.PERMISSIONS.FLEET.LIST_VEHICLE)) yield putResolve(actions.FleetManagement.getVehicleTypes());
  if (hasPermission(permissions, config.PERMISSIONS.FLEET.LIST_VEHICLE)) yield putResolve(actions.FleetManagement.getVehicleClasses());
  if (hasPermission(permissions, config.PERMISSIONS.FLEET.LIST_FUEL)) yield putResolve(actions.FleetManagement.getFuelTypes());
  if (hasPermission(permissions, config.PERMISSIONS.FLEET.LIST_VEHICLE)) yield putResolve(actions.FleetManagement.getDocumentTypes());
  if (hasPermission(permissions, config.PERMISSIONS.FLEET.LIST_VEHICLE)) yield putResolve(actions.FleetManagement.getAllFleet());
  if (hasPermission(permissions, config.PERMISSIONS.FLEET.LIST_VEHICLE)) yield putResolve(actions.FleetManagement.getFleetWithTanks());
  if (hasPermission(permissions, config.PERMISSIONS.FLEET.LIST_FUEL)) yield putResolve(actions.Fleetfuelling.getFuellingLocations());
  if (hasPermission(permissions, config.PERMISSIONS.FLEET.LIST_FUEL)) yield putResolve(actions.Fleetfuelling.getFuelPaymentTypes());
  //COLLABORATORS
  if (hasPermission(permissions, config.PERMISSIONS.USERS.LIST_TEAM_MEMBER)) yield putResolve(actions.CollaboratorsManagement.getCollaboratorTypes())
  if (hasPermission(permissions, config.PERMISSIONS.USERS.LIST_TEAM_MEMBER)) yield putResolve(actions.CollaboratorsManagement.getTeams())
  if (hasPermission(permissions, config.PERMISSIONS.USERS.LIST_TEAM_MEMBER)) yield putResolve(actions.CollaboratorsManagement.getCollaboratorsList())
  //TRIPS
  yield putResolve(actions.Tripsconfiguration.getAllCategories())
  yield putResolve(actions.Tripsconfiguration.getAllLocals())
  yield putResolve(actions.Tripsconfiguration.getAllActivities())
  yield putResolve(actions.Tripsconfiguration.getAllPassengerTypes())
  if (hasPermission(permissions, config.PERMISSIONS.TRIPS.CIRCUITS.LIST_CIRCUIT)) yield putResolve(actions.Tripsconfiguration.getAllCircuits())
  if (hasPermission(permissions, config.PERMISSIONS.TRIPS.EXTRAS.LIST_EXTRA)) yield putResolve(actions.Tripsconfiguration.getAllExtras())
  //SETTINGS
  yield putResolve(actions.InvoicingSummary.getPaymentTypes())
  yield putResolve(actions.InvoicingSummary.getMovementTypes())
  //ENTITIES
  if (hasPermission(permissions, config.PERMISSIONS.USERS.LIST_PARTNER)) yield putResolve(actions.Entities.getEntityTypes())
}

export default function* watcherSignin() {
  yield takeLatest('App/onMount', onMountSaga);
  yield takeLatest('App/navigateTo', navigateToSaga);
  yield takeLatest('App/navigateBack', navigateBackSaga);
  yield takeLatest('App/toggleApiTranslationsEdit', toggleApiTranslationsEditSaga);
  yield takeLatest('App/updateApiTranslationKey', updateApiTranslationKeySaga);
  yield takeLatest('App/updateUserRole', updateUserRoleSaga);
  yield takeLatest('App/toggleTranslation', toogleTranslationSaga);
  yield takeLatest('App/loadAppInfo', loadAppInfoSaga);
  yield takeLatest('App/getApiTranslations', getTranslationsSaga);
  yield takeLatest('App/getCountryCodes', getCountryCodesSaga);
}
