/**
 * Invoicingrequests scene slice
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */
import { createSlice } from '@reduxjs/toolkit'
import { InvoiceType, SyncStatus, InvoiceListItem } from 'types/invoicing/invoices';
import { SortType } from 'types/misc';

const now = new Date().toISOString().slice(0, 10);

interface InitialState {
  page: number,
  searchTerm: string,
  filter: InvoiceType,
  syncFilter: SyncStatus,
  bookingFilter: string,
  hasDateParams: boolean,
  startDate: string,
  endDate: string,
  loading: boolean,
  exportLoading: boolean,
  syncLoading: {
    id: number | null;
    isLoading: boolean;
  },
  sort: SortType,
  hasNewFile: boolean,
  invoices: InvoiceListItem[],
  totalInvoices: number,
}

const initialState: InitialState = {
  page: 1,
  searchTerm: "",
  filter: InvoiceType.NONE,
  syncFilter: SyncStatus.NONE,
  bookingFilter: "",
  hasDateParams: false,
  startDate: now,
  endDate: now,
  loading: true,
  exportLoading: false,
  syncLoading: {
    id: null,
    isLoading: false
  },
  sort: {
    field: null,
    direction: null,
  },
  hasNewFile: false,
  invoices: [],
  totalInvoices: 0,
}

export default createSlice({
  name: 'InvoicingRequests',
  initialState,
  reducers: {
    onMount: (state, { payload }) => { },
    onUnmount: () => { 
      return initialState
    },
    getInvoices: () => { },
    setInvoices: (state, { payload }) => {
      state.invoices = payload
    },
    setInvoicesTotal: (state, { payload }) => {
      state.totalInvoices = payload
    },
    setPage: (state, { payload }) => {
      state.page = payload;
    },
    setFilter: (state, { payload }) => {
      state.page = 1;
      state.filter = payload
    },
    setSyncFilter: (state, { payload }) => {
      state.syncFilter = payload
      state.page = 1
    },
    getURLParams: (state, payload) => { },
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
    setSyncLoading: (state, { payload }) => { 
      state.syncLoading = payload
    },
    setExportLoading: (state, { payload }) => {
      state.exportLoading = payload;
    },
    setSearchTerm: (state, { payload }) => {
      state.page = 1
      state.searchTerm = payload
    },
    setSort: (state, { payload }) => {
      state.sort = payload;
    },
    setDatesInterval: (state, { payload }) => {
      state.page = 1
    },
    setHasNewFile: (state, { payload }) => {
      state.hasNewFile = payload;
    },
    /*     removeRequestOnCompletion:(state, {payload}) => {
          const requests : never[] = Array.from(payload.requestedInvoices)
    
          if(payload.state === 'completed'){
            state.requestedInvoices = requests.filter((r: any )=> r.id !== payload.id)
          }
        }, */

    setHasDateParams: (state, { payload }) => {
      state.hasDateParams = payload
    },
    setStartDate: (state, { payload }) => {
      state.startDate = payload
    },
    setEndDate: (state, { payload }) => {
      state.endDate = payload
    },
    populateFiltersOnMount: (state, { payload }) => {
      state.searchTerm = payload.searchTerm
      state.filter = payload.filter
      state.syncFilter = payload.syncFilter
      state.startDate = payload.startDate
      state.endDate = payload.endDate
      state.bookingFilter = payload.bookingFilter
    },
    downloadInvoiceFile: (state, { payload }) => {

      const fileName = `invoice.xlsx`
      const finalFileName = fileName.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(" ", "");

      const url = URL.createObjectURL(payload.file)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute(
        'download',
        finalFileName
      )
      document.body.appendChild(link);
      link.click()
      link.parentNode?.removeChild(link)
    },
    exportInvoices: () => { },
    downloadInvoices: (state, { payload }) => {
      const file = payload
      const fileName = `invoices-list.xlsx`
      const finalFileName = fileName.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(" ", "");

      const url = URL.createObjectURL(file)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute(
        'download',
        finalFileName
      )
      document.body.appendChild(link);
      link.click()
      link.parentNode?.removeChild(link)
    },
    syncInvoice: (state, { payload }) => {},
    updateSynchedInvoice: (state, { payload }) => {
      const newInvoices = state.invoices.map((invoice) => {
        if( invoice.id === payload.id) {
          return payload
        }
        return invoice
      })

      state.invoices = newInvoices
    },
    removeInvoice: (state, { payload }) => {
      const newInvoices = state.invoices.filter((inv) => inv.id !== payload)
      state.invoices = newInvoices
    },
  }
})
