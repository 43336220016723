/**
 * Imagepreviewer component
 *
 * @author Stephane Ribeiro <sgribeiro@ubiwhere.com>
 *
 *
 */

import React, { useState } from 'react'
import Remove from 'assets/icons/cancel';
import ArrowBack from 'assets/icons/arrow_back';
import ArrowForward from 'assets/icons/arrow_forward';
import theme from 'ui/theme';
import { Overlay, OverlayArrowLeft, OverlayArrowRight, OverlayClose, OverlayImg} from './styles'

type ImageType = {
  file: string,
  id: number
}

interface PropTypes {
  images: Array<ImageType>,
  setIsOpen: Function,
  currentIdx: number,
}

const Imagepreviewer = (props: PropTypes) => {
  const [imgPreviewIdx, setImgPreviewIdx] = useState<number>(props.currentIdx)

  const handleClick = (e) => {
    if (e.target.classList.contains("dismiss")) {
      props.setIsOpen(false);
    }
  };

  const handelRotationRight = () => {
    if(props.images) {
      const totalLength = props.images.length;
      if (imgPreviewIdx + 1 >= totalLength) {
        setImgPreviewIdx(0);
        return;
      }
      const newIndex = imgPreviewIdx + 1;
      setImgPreviewIdx(newIndex);
    }
  };

  const handelRotationLeft = () => {
    if(props.images) {
      const totalLength = props.images.length;
      if (imgPreviewIdx === 0) {
        setImgPreviewIdx(totalLength - 1);
        return;
      }
      const newIndex = imgPreviewIdx - 1;
      setImgPreviewIdx(newIndex);
    }
  };

  return (
    <Overlay className="dismiss" onClick={handleClick}>
        <OverlayImg src={props.images[imgPreviewIdx].file} alt="bigger pic" />
        
        <OverlayClose className="dismiss" onClick={handleClick}>
          <Remove className="dismiss" width="35px" height="35px" fill={theme().offWhite} />
        </OverlayClose>
        {
          props.images && props.images.length > 1 && (
            <>
              <OverlayArrowLeft onClick={handelRotationLeft}>
                <ArrowBack width="40px" height="40px" fill={theme().offWhite} />
              </OverlayArrowLeft>
              <OverlayArrowRight onClick={handelRotationRight}>
                <ArrowForward width="40px" height="40px" fill={theme().offWhite} />
              </OverlayArrowRight>
            </>
          )
        }
      </Overlay>
  )
}

export default Imagepreviewer