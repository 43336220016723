
import React from 'react'
import styled from 'styled-components'
import SectionContainer from '../../shared/components/Sectioncontainer/index';
import CustomTextInput from '../../shared/components/CustomTextInput/index';
import CustomRadioButtonGroup from '../../shared/components/CustomRadioButtonGroup';
import CustomRadioButton from '../../shared/components/CustomRadioButton';
import { CCol, CRow } from '@coreui/react-pro';
import Datepicker from '../../shared/components/FormComponents/datepicker';

import { actions, RootState } from 'store/rootSlices';
import { useSelector, useDispatch } from 'react-redux';
import i18n from 'i18next';
import _ from 'lodash'

const CondicoesContratuais = (props) => {

    const dispatch = useDispatch();
    const dbDispatch = _.debounce(dispatch, 300);

    const { onFormChange } = actions.userForm;
    const { userForm, hasEditPermission } = useSelector((state: RootState) => state.UserForm);

    return (
        <SectionContainer required title={i18n.t('userForm.title6')} style={{ width: '100%', paddingBottom: '24px' }}>
            <CRow className="g-3">
                <CCol>
                    <CustomTextInput
                        id='userForm-professionalCategory'
                        disabled={!hasEditPermission}
                        valid={userForm.professionalCategory.valid}
                        initialValue={userForm.professionalCategory.value || ''}
                        helpText={userForm.professionalCategory.message}
                        placeholder={i18n.t('placeholders.professionalCategory')}
                        label={i18n.t('userForm.professionalCategory')}
                        onChange={(e) => dbDispatch(onFormChange({ ...userForm, professionalCategory: { value: e.target.value, message: "", valid: true } }))}
                    />
                </CCol>
                <CCol>
                    <Datepicker
                        id='userForm-contractHireDate'
                        disabled={!hasEditPermission}
                        valid={userForm.contractHireDate.valid}
                        value={userForm.contractHireDate.value}
                        helpText={userForm.contractHireDate.message}
                        label={i18n.t('userForm.admissionDate')}
                        onChange={(date, e) => dispatch(onFormChange({ ...userForm, contractHireDate: { value: date, message: "", valid: true } }))}
                    />
                </CCol>
                <CCol>
                    <Datepicker
                        id='userForm-contractEndDate'
                        disabled={!hasEditPermission}
                        valid={userForm.contractEndDate.valid}
                        value={userForm.contractEndDate.value}
                        helpText={userForm.contractEndDate.message}
                        label={i18n.t('userForm.contractEndDate')}
                        onChange={(date, e) => dispatch(onFormChange({ ...userForm, contractEndDate: { value: date, message: "", valid: true } }))}
                    />
                </CCol>
            </CRow>
            <Row className="g-3">
                <CCol>
                    <CustomTextInput
                        id='userForm-contractType'
                        disabled={!hasEditPermission}
                        valid={userForm.contractType.valid}
                        initialValue={userForm.contractType.value || ''}
                        helpText={userForm.contractType.message}
                        label={i18n.t('userForm.contractType')}
                        placeholder={i18n.t('userForm.contractType')}
                        onChange={(e) => dbDispatch(onFormChange({ ...userForm, contractType: { value: e.target.value, message: "", valid: true } }))}
                    />
                </CCol>
                <CCol>
                    <CustomTextInput
                        id='userForm-contractBaseSalary'
                        disabled={!hasEditPermission}
                        valid={userForm.contractBaseSalary.valid}
                        initialValue={userForm.contractBaseSalary.value || ''}
                        helpText={userForm.contractBaseSalary.message}
                        icon="euro"
                        label={i18n.t('userForm.baseRate')}
                        placeholder="00.00"
                        onChange={(e) => dbDispatch(onFormChange({ ...userForm, contractBaseSalary: { value: e.target.value, message: "", valid: true } }))}
                    />
                </CCol>
                <CCol>
                    <CustomTextInput
                        id='userForm-contractHourlyRate'
                        disabled={!hasEditPermission}
                        valid={userForm.contractHourlyRate.valid}
                        initialValue={userForm.contractHourlyRate.value || ''}
                        helpText={userForm.contractHourlyRate.message}
                        icon="euro"
                        label={i18n.t('userForm.hourlyRate')}
                        placeholder="00.00"
                        onChange={(e) => dbDispatch(onFormChange({ ...userForm, contractHourlyRate: { value: e.target.value, message: "", valid: true } }))}
                    />
                </CCol>
                <CCol>
                    <CustomTextInput
                        id='userForm-contractWeeklyHours'
                        disabled={!hasEditPermission}
                        valid={userForm.contractWeeklyHours.valid}
                        initialValue={userForm.contractWeeklyHours.value || ''}
                        helpText={userForm.contractWeeklyHours.message}
                        label={i18n.t('userForm.weeklyHours')}
                        placeholder="0"
                        onChange={(e) => dbDispatch(onFormChange({ ...userForm, contractWeeklyHours: { value: e.target.value, message: "", valid: true } }))}
                    />
                </CCol>
                <CCol>
                    <CustomTextInput
                        id='userForm-contractMonthlyHours'
                        disabled={!hasEditPermission}
                        valid={userForm.contractMonthlyHours.valid}
                        initialValue={userForm.contractMonthlyHours.value || ''}
                        helpText={userForm.contractMonthlyHours.message}
                        label={i18n.t('userForm.monthlyHours')}
                        placeholder="0"
                        onChange={(e) => dbDispatch(onFormChange({ ...userForm, contractMonthlyHours: { value: e.target.value, message: "", valid: true } }))}
                    />
                </CCol>
            </Row>
            <Row className="g-3">
                <CCol>
                    <CustomRadioButtonGroup title={i18n.t('userForm.foodSubsidy')} role="group3" helpText={userForm.contractMealAllowanceType.message} valid={userForm.contractMealAllowanceType.valid}>
                        <CustomRadioButton group="group3" text={i18n.t('userForm.mealCard')} value="card"
                            checked={userForm.contractMealAllowanceType.value === 'card' ? true : false}
                            disabled={!hasEditPermission}
                            onClick={e => dispatch(onFormChange({ ...userForm, contractMealAllowanceType: { value: e.target.value, message: "", valid: true } }))} />
                        <CustomRadioButton group="group3" text={i18n.t('userForm.money')} value="cash"
                            checked={userForm.contractMealAllowanceType.value === 'cash' ? true : false}
                            disabled={!hasEditPermission}
                            onClick={e => dispatch(onFormChange({ ...userForm, contractMealAllowanceType: { value: e.target.value, message: "", valid: true } }))} />
                    </CustomRadioButtonGroup>
                </CCol>
                <CCol>
                    <CustomTextInput
                        id='userForm-contractMealAllowanceAmount'
                        disabled={!hasEditPermission}
                        valid={userForm.contractMealAllowanceAmount.valid}
                        initialValue={userForm.contractMealAllowanceAmount.value || ''}
                        helpText={userForm.contractMealAllowanceAmount.message}
                        icon="euro"
                        label={i18n.t('userForm.dailyRate')}
                        placeholder="00.00"
                        onChange={(e) => dbDispatch(onFormChange({ ...userForm, contractMealAllowanceAmount: { value: e.target.value, message: "", valid: true } }))}
                    />
                </CCol>
            </Row>
            <Row className="g-3">
                <CCol>
                    <CustomRadioButtonGroup title={i18n.t('userForm.holidaySubsidy')} role="group4" helpText={userForm.contractHolidayPay.message} valid={userForm.contractHolidayPay.valid}>
                        <CustomRadioButton group="group4" text={i18n.t('userForm.holidaySubsidy1')} value="whole"
                            checked={userForm.contractHolidayPay.value === 'whole' ? true : false}
                            disabled={!hasEditPermission}
                            onClick={e => dispatch(onFormChange({ ...userForm, contractHolidayPay: { value: e.target.value, message: "", valid: true } }))} />
                        <CustomRadioButton group="group4" text={i18n.t('userForm.holidaySubsidy2')} value="duodecimos"
                            checked={userForm.contractHolidayPay.value === 'duodecimos' ? true : false}
                            disabled={!hasEditPermission}
                            onClick={e => dispatch(onFormChange({ ...userForm, contractHolidayPay: { value: e.target.value, message: "", valid: true } }))} />
                    </CustomRadioButtonGroup>
                </CCol>
                <CCol>
                    <CustomTextInput
                        id='userForm-contractOtherAllowances'
                        disabled={!hasEditPermission}
                        valid={userForm.contractOtherAllowances.valid}
                        initialValue={userForm.contractOtherAllowances.value || ''}
                        helpText={userForm.contractOtherAllowances.message}
                        placeholder={i18n.t('placeholders.otherSubsidies')}
                        label={i18n.t('userForm.otherSubsidies')}
                        onChange={(e) => dbDispatch(onFormChange({ ...userForm, contractOtherAllowances: { value: e.target.value, message: "", valid: true } }))}
                    />
                </CCol>
                <CCol>
                    <CustomRadioButtonGroup title={i18n.t('userForm.weeklyDaysOff')} role="group5" helpText={userForm.contractWeeklyDaysOff.message} valid={userForm.contractWeeklyDaysOff.valid}>
                        <CustomRadioButton group="group5" text={i18n.t('userForm.weeklyDaysOff1')} value={1}
                            checked={userForm.contractWeeklyDaysOff.value === 1 ? true : false}
                            disabled={!hasEditPermission}
                            onClick={e => dispatch(onFormChange({ ...userForm, contractWeeklyDaysOff: { value: Number(e.target.value), message: "", valid: true } }))} />
                        <CustomRadioButton group="group5" text={i18n.t('userForm.weeklyDaysOff2')} value={2}
                            checked={userForm.contractWeeklyDaysOff.value === 2 ? true : false}
                            disabled={!hasEditPermission}
                            onClick={e => dispatch(onFormChange({ ...userForm, contractWeeklyDaysOff: { value: Number(e.target.value), message: "", valid: true } }))} />
                    </CustomRadioButtonGroup>
                </CCol>
            </Row>
        </SectionContainer>
    )
}

export default CondicoesContratuais


const Row = styled(CRow)`
    --cui-gutter-y:0rem;
    margin-top:26px;
`
