import { CTable } from '@coreui/react-pro'
import styled from 'styled-components'
import theme from 'ui/theme'

export const CustomTable = styled(CTable)<{expanded: boolean}>`
  margin-top: 0px;
  margin-bottom: ${props => props.expanded ? '16px' : '0'};
`

export const ElegibleTitle = styled.h5`
  width: 100%;
  font-weight: 600;
  padding-block: 5px !important;
  font-size: 1.1rem;
  color: ${theme().gray900Color} !important;
`

export const ExpandIconWrapper = styled.span`
  width: fit-content;
`