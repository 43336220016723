
import React from 'react'
import styled from 'styled-components'
import SectionContainer from '../../shared/components/Sectioncontainer/index'
import CustomTextInput from '../../shared/components/CustomTextInput/index'
import { CCol, CRow } from '@coreui/react-pro'
import Datepicker from '../../shared/components/FormComponents/datepicker'

import { actions, RootState } from 'store/rootSlices'
import { useSelector, useDispatch } from 'react-redux'
import i18n from "i18next";
import _ from 'lodash'


const DadosFiscais = (props) => {

    const dispatch = useDispatch()
    const dbDispatch = _.debounce(dispatch, 300);

    const { onFormChange } = actions.userForm;
    const { userForm, hasEditPermission } = useSelector((state: RootState) => state.UserForm);

    return (
        <SectionContainer title={i18n.t('userForm.title4')} style={{ width: '100%', paddingBottom: '24px' }}>
            <CRow className="g-3">
                <CCol>
                    <CustomTextInput
                        id='userForm-ccNumber'
                        disabled={!hasEditPermission}
                        valid={userForm.ccNumber.valid}
                        initialValue={userForm.ccNumber.value || ""}
                        helpText={userForm.ccNumber.message}
                        placeholder={i18n.t('placeholders.number')}
                        label={i18n.t('userForm.idCard')}
                        onChange={(e) => dbDispatch(onFormChange({ ...userForm, ccNumber: { value: e.target.value, message: "", valid: true } }))}
                    />
                </CCol>
                <CCol>
                    <Datepicker
                        id='userForm-ccValidUntil'
                        disabled={!hasEditPermission}
                        valid={userForm.ccValidUntil.valid}
                        value={userForm.ccValidUntil.value}
                        helpText={userForm.ccValidUntil.message}
                        label={i18n.t('userForm.expirationDate')}
                        onChange={(date, e) => dispatch(onFormChange({ ...userForm, ccValidUntil: { value: date, message: "", valid: true } }))}
                    />
                </CCol>
            </CRow>
            <Row className="g-3">
                <CCol>
                    <CustomTextInput
                        id='userForm-residencePermit'
                        disabled={!hasEditPermission}
                        valid={userForm.residencePermit.valid}
                        initialValue={userForm.residencePermit.value || ""}
                        helpText={userForm.residencePermit.message}
                        placeholder={i18n.t('placeholders.title')}
                        label={i18n.t('userForm.residencyTitle')}
                        onChange={(e) => dbDispatch(onFormChange({ ...userForm, residencePermit: { value: e.target.value, message: "", valid: true } }))}
                    />
                </CCol>
                <CCol>
                    <Datepicker
                        id='userForm-residencePermitUntil'
                        disabled={!hasEditPermission}
                        valid={userForm.residencePermitUntil.valid}
                        value={userForm.residencePermitUntil.value}
                        helpText={userForm.residencePermitUntil.message}
                        label={i18n.t('userForm.expirationDate')}
                        onChange={(date, e) => dispatch(onFormChange({ ...userForm, residencePermitUntil: { value: date, message: "", valid: true } }))}
                    />
                </CCol>
            </Row>
            <Row className="g-3">
                <CCol>
                    <CustomTextInput
                        id='userForm-nif'
                        disabled={!hasEditPermission}
                        valid={userForm.nif.valid}
                        initialValue={userForm.nif.value || ""}
                        helpText={userForm.nif.message}
                        placeholder={i18n.t('placeholders.number')}
                        label={i18n.t('userForm.nif')}
                        onChange={(e) => dbDispatch(onFormChange({ ...userForm, nif: { value: e.target.value, message: "", valid: true } }))}
                    />
                </CCol>
                <CCol>
                    <CustomTextInput
                        id='userForm-socialSecurity'
                        disabled={!hasEditPermission}
                        valid={userForm.socialSecurity.valid}
                        initialValue={userForm.socialSecurity.value || ""}
                        helpText={userForm.socialSecurity.message}
                        placeholder={i18n.t('placeholders.number')}
                        label={i18n.t('userForm.ssn')}
                        onChange={(e) => dbDispatch(onFormChange({ ...userForm, socialSecurity: { value: e.target.value, message: "", valid: true } }))}
                    />
                </CCol>
            </Row>
        </SectionContainer>
    )
}

export default DadosFiscais


const Row = styled(CRow)`
    --cui-gutter-y:0rem;
    margin-top:26px;
`