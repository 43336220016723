/**
 * Usercashmovementform scene utils
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */

import * as Check from 'validations'

export const EntryFormValidations = {
    amount: [Check.isRequired, Check.isNumber],
    description: [],
    balanceEntryType: [Check.isRequired],
    paymentType: [Check.isRequired],
    processedBy: [Check.isRequired],
    supplier: [Check.isRequired],
}