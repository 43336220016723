import styled from 'styled-components'
import CustomCheckbox from 'shared/components/CustomCheckbox'
import theme from 'ui/theme'
import { CRow } from '@coreui/react-pro'


export const Wrapper = styled.div``

export const FuelLocationCheckbox = styled(CustomCheckbox)`
  > div {
    label {
      font-size: 14px;
      color: ${theme().darkColor};
    }
  }
`

export const CheckboxContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 15px;

  > div {
    :first-child {
      margin-right: 40px;
    }
  }
`

export const Row = styled(CRow)`
  --cui-gutter-y:0rem;
  margin-top:26px;
`

export const ButtonWrapper = styled.div`
  display:flex;
  justify-content:flex-end;
  align-items: center;
  margin-top: 24px;

  > button:nth-child(2) {
      margin-left: 16px;
      height: 33px;
      line-height: 0;
  }

`