/**
 * Entityform scene slice
 *
 * @author João Dias <jdias@ubiwhere.com>
 *
 *
 */
import { createSlice } from '@reduxjs/toolkit'
import { Entity } from 'types/entity/entity'
import { activityCommissionForm, defaultDocument, defaultEntityForm, extraCommissionForm } from '../utils'
import {  EntityDocumentsForm, EntityForm } from 'types/entity/entity' 

interface InitialState {
  entityForm: EntityForm,
  documentsForm: Array<EntityDocumentsForm>,
  activitiesCommissionForm: Array<activityCommissionForm>,
  extrasCommissionForm: Array<extraCommissionForm>,
  edit: boolean,
  hasEditPermission: boolean,
  entity: Entity | null,
  loading: boolean,
  confirmAllModal: boolean,
  deletedDocQueue: Array<EntityDocumentsForm>,
  isApplyAllOnActivities: boolean,
  isAvatarRemoved: boolean,
  isAvatarChanged: boolean,
  submitButtonState: boolean,
  baseUrl: string
}

const initialState: InitialState = {
  entityForm: defaultEntityForm,
  baseUrl: '',
  documentsForm: [],
  activitiesCommissionForm: [],
  extrasCommissionForm: [],
  edit: false,
  hasEditPermission: true,
  entity: null,
  loading: true,
  confirmAllModal: false,
  deletedDocQueue: [],
  isApplyAllOnActivities: true,
  isAvatarRemoved: false,
  isAvatarChanged: false,
  submitButtonState: false
}

export default createSlice({
  name: 'Entityform',
  initialState,
  reducers: {
    onMount: (state, { payload }) => { },
    onUnmount: () => {
      return initialState
    },
    fetchEntity: (state, { payload }) => { },
    setEditMode: (state, { payload }) => {
      state.edit = payload;
    },
    setBaseUrl: (state, { payload }) => {
      state.baseUrl = payload
    },
    setEntity: (state, { payload }) => {
      state.entity = payload;
    },
    setHasEditPermission: (state, { payload }) => {
      state.hasEditPermission = payload
    },
    setSubmitButtonDisabled: (state, { payload }) => {
      state.submitButtonState = payload
    },
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
    setConfirmAllModal: (state, { payload }) => {
      state.confirmAllModal = payload.confirmAll;
      state.isApplyAllOnActivities = payload.isApplyAllOnActivities
    },
    setForm: (state, { payload }) => {
      state.entityForm = payload;
    },
    onFormChange: (state, { payload }) => {
      state.entityForm = payload;
    },
    onAddDocument: (state) => {
      state.documentsForm = [...state.documentsForm, defaultDocument as EntityDocumentsForm]
    },
    onDocumentChange: (state, { payload }) => {
      payload.dirty = true;
      state.documentsForm[payload.idx] = payload
    },
    // Commission actions
    setEntityCommissions: (state, { payload }) => {
      state.activitiesCommissionForm = payload.activities?.map((act) => {
        return ({
          uuid: act.uuid,
          name: act.name,
          isCustom: false,
          commission: {
            value: "",
            valid: true,
            message: ""
          }
        })
      });
      state.extrasCommissionForm = payload.extras?.map((ext) => {
        return ({
          uuid: ext.uuid,
          name: ext.name,
          isCustom: false,
          commission: {
            value: "",
            valid: true,
            message: ""
          }
        })
      });
    },
    updateValidationsOnEntityCommissions: (state, { payload }) => {
      state.activitiesCommissionForm = payload.activities?.map((act) => {
        return ({
          uuid: act.uuid,
          name: act.name,
          isCustom:act.isCustom,
          commission: act.commission
        })
      });
      state.extrasCommissionForm = payload.extras?.map((ext) => {
        return ({
          uuid: ext.uuid,
          name: ext.name,
          isCustom:ext.isCustom,
          commission: ext.commission
        })
      });
    },
    populateEntityCommissionsOnMount: (state, { payload }) => {
      state.activitiesCommissionForm = payload.activitiesCommissionForm?.map((act) => {

        if (payload.activities.find(a => a.activity.uuid === act.uuid)) {
          const a = payload.activities.find(a => a.activity.uuid === act.uuid)
          return ({
            ...act,
            isCustom: true,
            commission: {
              value: a.amount,
              valid: true,
              message: ""
            }
          })
        } else {
          return {
            ...act,
            isCustom: false,
            commission: {
              ...act.commission,
              value: payload.activityDefaultCommission
            }
          }
        }
      });

      state.extrasCommissionForm = payload.extrasCommissionForm?.map((ext) => {

        if (payload.extras.find(e => e.extra.uuid === ext.uuid)) {
          const e = payload.extras.find(e => e.extra.uuid === ext.uuid)
          return ({
            ...ext,
            isCustom: true,
            commission: {
              value: e.amount,
              valid: true,
              message: ""
            }
          })
        } else {
          return {
            ...ext,
            isCustom: false,
            commission: {
              ...ext.commission,
              value: payload.extrasDefaultCommission
            }
          }
        }
      });

    },
    onActivityCommissionChange: (state, { payload }) => {
      state.activitiesCommissionForm[payload.idx] = {
        ...state.activitiesCommissionForm[payload.idx],
        commission: { value: payload.e, valid: true, message: "" }
      }
    },
    onCommissionChangeToggler: (state, { payload }) => {
      if(payload.isActivity) {
        state.activitiesCommissionForm[payload.idx] = {
          ...state.activitiesCommissionForm[payload.idx],
          isCustom: payload.value,
          commission: { value: payload.default, valid: true, message: "" }
        }
      } else {
        state.extrasCommissionForm[payload.idx] = {
          ...state.extrasCommissionForm[payload.idx],
          isCustom: payload.value,
          commission: { value: payload.default, valid: true, message: "" }
        }
      }
      
    },
    onExtraCommissionChange: (state, { payload }) => {
      state.extrasCommissionForm[payload.idx] = {
        ...state.extrasCommissionForm[payload.idx],
        commission: { value: payload.e, valid: true, message: "" }
      }
    },
    applyDefaultOnAllActivitiesComissions: (state, { payload }) => {
      const activitiesCommissionForm: Array<activityCommissionForm> = Array.from(payload.activitiesCommissionForm)
      state.activitiesCommissionForm = activitiesCommissionForm.map((act) => {
        return {
          ...act,
          isCustom: false,
          commission: {
            ...act.commission,
            value: payload.defaultCommission
          }
        }
      })
    },
    applyDefaultOnAllExtrasComissions: (state, { payload }) => {
      const extrasCommissionForm: Array<extraCommissionForm> = Array.from(payload.extrasCommissionForm)

      state.extrasCommissionForm = extrasCommissionForm.map((ext) => {
        return {
          ...ext,
          isCustom: false,
          commission: {
            ...ext.commission,
            value: payload.defaultCommission
          }
        }
      })
    },
    changeDefaultCommissionValues: (state, { payload }) => { 
      if(payload.isActivity) {
        state.activitiesCommissionForm = state.activitiesCommissionForm.map((act) => {
          if(act.isCustom) return act
          return {
            ...act,
            commission: {
              ...act.commission,
              value: payload.value
            }
          }
        })
      } else {
        state.extrasCommissionForm = state.extrasCommissionForm.map((ext) => {
          if(ext.isCustom) return ext
          return {
            ...ext,
            commission: {
              ...ext.commission,
              value: payload.value
            }
          }
        })
      }
    },
    emptyDeletedQueue: (state) => {
      state.deletedDocQueue = [];
    },
    deleteDocument: (state, { payload }) => {
      const docs = [...state.documentsForm];
      const { idx, doc } = payload;
      docs.splice(idx, 1);
      if (!payload.new) state.deletedDocQueue.push(doc);
      state.documentsForm = docs;
    },
    downloadQRCode: (state, { payload }) => {
      const url = payload
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('target', "_blank")
      link.setAttribute('rel', "noreferrer noopener")
      document.body.appendChild(link);
      link.click()
      link.parentNode?.removeChild(link)
    },
    setDocumentForm: (state, { payload }) => {
      state.documentsForm = payload;
    },
    onRemoveEntityAvatar: (state, { payload }) => {
      state.entity = payload
    },
    setIsAvatarChanged: (state, { payload }) => {
      state.isAvatarChanged = payload
    },
    setIsAvatarRemoved: (state, { payload }) => {
      state.isAvatarRemoved = payload
    },
    onSubmit: () => { },
    onSubmitDocuments: () => { },
  }
})