import { PayloadAction } from '@reduxjs/toolkit'
import { takeLatest, putResolve, call, select } from 'redux-saga/effects'
import API from 'api'
import { actions, RootState } from 'store/rootSlices'
import { toast } from 'react-toastify';
import i18n from 'i18next';
import {
  SubmitSagaPayload,
  PhotosSagasPayload,
  DeletePhotoSagaPayload
} from 'types/trips/activity/activityForm'
import config from 'config';
import { hasPermission } from 'permissions'
import { prepareBeachActivity, prepareGenericActivity } from './utils';
const { PERMISSIONS } = config

const crcts = (state: RootState) => state.TripActivityForm.circuits
const pssngrTyp = (state: RootState) => state.TripActivityForm.passengerTypes
const extr = (state: RootState) => state.TripActivityForm.extras
const vhcls = (state: RootState) => state.TripActivityForm.vehicles
const vehiclesInfo = (state: RootState) => state.FleetManagement.fleetLongList

function* onMountSaga({ payload }: PayloadAction<number>) {
  const { permissions } = yield select((state: RootState) => state.App);

  try {
    const taxes = yield call(API.TripSettings.GetTaxes)
    let circuitsInfo = yield select((state: RootState) => state.TripsConfiguration.circuits)
    let passengerTypesInfo = yield select((state: RootState) => state.TripsConfiguration.passengerTypes)
    let ExtrasInfo = yield select((state: RootState) => state.TripsConfiguration.extras)

    if (ExtrasInfo.length === 0 || circuitsInfo.length === 0 || passengerTypesInfo.length === 0) {
      circuitsInfo = yield call(API.Circuits.GetAllCircuits)
      passengerTypesInfo = yield call(API.PassengerTypes.GetAllPassengerTypes)
      ExtrasInfo = yield call(API.Extras.GetAllExtras)
    }

    if (circuitsInfo && passengerTypesInfo && ExtrasInfo) {
      const vehicles = yield select(vehiclesInfo)

      //yield putResolve(actions.Tripsconfiguration.setCategories(CategoriesInfo))
      yield putResolve(actions.TripActivityForm.setVehiclesInfo(vehicles))
      yield putResolve(actions.TripActivityForm.setPassengerTypesInfo(passengerTypesInfo))
      yield putResolve(actions.TripActivityForm.setCircuitsInfo(circuitsInfo))
      yield putResolve(actions.TripActivityForm.setExtrasInfo(ExtrasInfo))
      yield putResolve(actions.TripActivityForm.setTaxes(taxes))
      yield putResolve(actions.TripActivityForm.setAction(false))
    }

  } catch (error) {
    yield putResolve(actions.TripActivityForm.setAction(false))
    toast.error("Erro a carregar informação sobre atividades");
  }


  if (payload) {

    const { allCategories } = yield select((state: RootState) => state.TripsConfiguration);
    const activityID = payload

    try {
      //get activity from api
      const activityInfo = yield call(API.Activities.GetActivity, activityID)

      if (activityInfo) {
        const circuits = yield select(crcts)
        const passengerTypes = yield select(pssngrTyp)
        const extras = yield select(extr)
        const vehicles = yield select(vhcls)

        yield putResolve(actions.TripActivityForm.setActivityID(activityID))
        yield putResolve(actions.TripActivityForm.setAction(true))
        yield putResolve(actions.TripActivityForm.setHasEditPermission(hasPermission(permissions, PERMISSIONS.TRIPS.ACTIVITIES.EDIT_ACTIVITY)))
        yield putResolve(actions.TripActivityForm.populateActivityInfo({ activityInfo, allCategories }))
        yield putResolve(actions.TripActivityForm.populateVehiclesInfo({ activityVehicles: activityInfo.vehicles, allVehicles: vehicles }))
        yield putResolve(actions.TripActivityForm.populateExtrasInfo({ activityExtras: activityInfo.extras, allExtras: extras }))
        yield putResolve(actions.TripActivityForm.populateCircuitsInfo({ activityCircuits: activityInfo.circuits, allCircuits: circuits }))
        yield putResolve(actions.TripActivityForm.populatePassengerTypesInfo({ activityPassengerTypes: activityInfo.passengerTypes, allPassengerTypes: passengerTypes }))
        yield putResolve(actions.TripActivityForm.populatePhotosForm({ photos: activityInfo.photos, activityID }))
      }
    } catch (error) {
      toast.error("Erro a carregar informação sobre atividade");
    }
  } else {
    // creating a new activity
    yield putResolve(actions.TripActivityForm.setAction(false))
  }
}

function* onSubmitSaga({ payload }: PayloadAction<SubmitSagaPayload>) {

  yield putResolve(actions.TripActivityForm.setSubmitButtonDisabled(true))
  const activityHasBeachRule = payload.hasBeachRule

  if (activityHasBeachRule) {
    yield prepareBeachActivity(payload)
  }
  else {
    yield prepareGenericActivity(payload)
  }

  yield putResolve(actions.Tripsconfiguration.getAllActivities())
  yield putResolve(actions.TripActivityForm.setSubmitButtonDisabled(false))
}

function onCreatePhotoSaga({ payload }: PayloadAction<PhotosSagasPayload>) {
  try {
    payload.photos.forEach(async (photo, idx) => {

      const body = {
        activity: payload.activity,
        sort: idx + 1,
        file: photo.file
      }

      await API.Activities.CreateActivityPhoto(body)
    })

  } catch (error) {
    toast.warning("Erro a adicionar foto da atividade");
  }
}

function onEditPhotoArraySaga({ payload }: PayloadAction<PhotosSagasPayload>) {
  try {
    payload.photos.forEach(async (photo, idx) => {
      if (photo.new === true) {
        const body = {
          activity: payload.activity,
          sort: idx + 1,
          file: photo.file
        }
        await API.Activities.CreateActivityPhoto(body)
      }
      else {
        if (photo.edited === true) {
          const body = {
            activity: payload.activity,
            sort: idx + 1,
          }
          await API.Activities.EditActivityPhoto(photo.id, body)
        }
      }
    })

  } catch (error) {
    toast.warning("Erro editar fotos da atividade");
  }
}

function* deletePhotoSaga({ payload }: PayloadAction<DeletePhotoSagaPayload>) {
  const index = payload.index
  const photosArray = payload.photos

  if (index !== null && photosArray[index].new === false) {
    try {
      const del = yield call(API.Activities.DeleteStopPhoto, photosArray[index].id)

      if (del.status === 200) {
        toast.success(i18n.t("toasts.deletedPhoto"))
      }

    } catch (error) {
      toast.error(i18n.t("toasts.removePhotoError"))
    }
  }
}

function* onUnmountSaga() { }


export default function* watcherSignin() {
  yield takeLatest('tripactivityform/onMount', onMountSaga)
  yield takeLatest('tripactivityform/onUnmount', onUnmountSaga)
  yield takeLatest('tripactivityform/onSubmit', onSubmitSaga)
  yield takeLatest('tripactivityform/createPhoto', onCreatePhotoSaga)
  yield takeLatest('tripactivityform/editPhotoArray', onEditPhotoArraySaga)
  yield takeLatest('tripactivityform/deletePhotoFromArray', deletePhotoSaga)
}


