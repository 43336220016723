/**
 * Datescontainer component
 *
 * @author Stephane Ribeiro <sgribeiro@ubiwhere.com>
 *
 *
 */
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import i18n from 'i18next';
import { useHistory } from 'react-router-dom'
import { Wrapper, DaysButton, Day, TextLink } from './styles'
import CustomCheckbox from 'shared/components/CustomCheckbox'
import { actions, RootState } from 'store/rootSlices'
import { DateTime } from 'luxon';
// Utils & Configs
import config from 'config'
import { hasPermission } from 'permissions'

const Datescontainer = () => {
  const { showEndedExits, days, selectedDay } = useSelector((state: RootState) => state.TripPlannings)
  const { permissions } = useSelector((state: RootState) => state.App)
  const { setShowEndedExits, onDayChange } = actions.TripPlannings
  const { setDate } = actions.PassengerControl
  const { PERMISSIONS } = config
  const dispatch = useDispatch()
  const history = useHistory()

  const _renderTextLink = () => {
    return hasPermission(permissions, PERMISSIONS.TRIPS.BOOKINGS.EDIT_BOOKING_CHECKINS) && (
      <TextLink 
        id='tripPlannings-passengerControl' 
        onClick={(e) => { dispatch(actions.App.navigateTo(`/controlo-passageiros`)) }}
      >
        {i18n.t('tripPlannings.passengerControl')}
      </TextLink>
    )
  }

  return (
    <Wrapper>
      <DaysButton id='tripPlannings-daysButton' role="group" aria-label="Basic checkbox toggle button group" >
        {
          days.map((d, idx) => {
            return (
              <Day
                type='radio'
                checked={d.date === selectedDay ? true : false}
                onClick={e => {
                  dispatch(onDayChange({ day: d, history }))
                  dispatch(setDate(DateTime.fromFormat(d.date, "yyyy-LL-dd").toJSDate()))
                }}
                button={{ color: 'secondary', variant: 'outline' }}
                name="btnradio"
                key={`day${idx}`}
                id={`tripPlannings-day${idx}`}
                color='secondary'
                label={DateTime.fromFormat(d.date, "yyyy-LL-dd").toFormat("dd/LL/yyyy")}
              />
            )
          })
        }
      </DaysButton>
      <div>
        { _renderTextLink() }
      </div>
      <CustomCheckbox wrapperStyle={{display: 'flex', justifyContent: 'flex-end', fontSize: '20px', flexGrow: 2 }}
        id='tripPlannings-isFinished'
        checked={showEndedExits}
        onClick={e => {
          let params = new URLSearchParams(history.location.search);
          e.value !== "" ? params.set('showFinished', (!showEndedExits).toString()) : params.delete('showFinished')
          history.replace({ search: (params).toString() })
          dispatch(setShowEndedExits(!showEndedExits))
        }}
        text={i18n.t('tripPlannings.showFinished')}
        switch
      />
    </Wrapper>
  )
}

export default Datescontainer