import CIcon from '@coreui/icons-react'
import { CCol, CTableDataCell, CTableRow, CTooltip, CFormText } from '@coreui/react-pro'
import styled from 'styled-components'
import theme from 'ui/theme'


const TitleBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`

const Title = styled.h6`
  margin: 0;
  font-family: 'Inter';
  font-style: normal;
  font-size: 19px !important;
  color: ${theme().darkColor};
`

const ContainerBox = styled.div`
  width: 100%;
  padding: 25px;
  height:100%;
  border: 1px solid ${theme().gray200Color};
  box-sizing: border-box;
  border-radius: 4px;
  background-color: ${theme().white}
`

const CustomWrapper = styled.div`
  width:100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .display  {
    color: ${theme().gray900Color} !important;
  }

  .padding {
    padding-bottom: 1rem !important
  }

  .title-text p{
    color: ${theme().gray500Color} !important;
  }

  .pos{
    position: unset;
  }
`

const CustomControls1 = styled.div`
  width:70%
  display:flex;
  justify-content: flex-start;
`
const CustomControls2 = styled.div`
  display:flex;
  justify-content:flex-end;
`

const CustomControlsContainer = styled.div<{ marginbottom?: number }>`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${props => props.marginbottom !== undefined ? props.marginbottom : '24'}px;

  > div {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  > div:nth-child(1) {
    > * {
      margin-right: 25px;
    }
  }

  > div:nth-child(2) {
    > * {
      margin-left: 18px;
    },
  }
`

// List Screens 
const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
`
const ControlsContainer = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
    row-gap: 10px;

    > div {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap:wrap;
        row-gap: 10px;
    }
    
    > div:nth-child(1) {
        > * {
            margin-right: 18px;
        }
    }
    
    > div:nth-child(2) {
        > * {
            margin-left: 0px;
        }

        div:first-child {
          margin-left: 0px;
        }
    }

    
`

const CustomTableRow = styled(CTableRow)`
    max-height: 54px;
    background-color: ${theme().white};
    border-bottom: 1px solid ${theme().gray200Color};

    > td {
        vertical-align: middle;
        font-family: Inter;
        font-size: 14px;
        color: ${theme().gray700Color}
    }
`

const ActionsCell = styled(CTableDataCell) <{ nopointer?: boolean }>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 49px;

  > svg {
    ${(({ nopointer }) => nopointer && nopointer === true ? `cursor: auto;` : `cursor: pointer;`)}
    margin-right: 15px;
  }

  .pointer{
    cursor: pointer !important;
  }
`

const ChevronCell = styled.div`
  display:flex;
  justify-content: flex-end;
  cursor: pointer;
  width: 45px;
`

const ImageCell = styled(CTableDataCell)`
  > div {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    .avatar-img {
      width: 100%;
      height:100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }
`
const NameAndRoleCell = styled(CTableDataCell)`

  > * {
    font-family: 'Inter';
    color: ${theme().darkColor}
  }

  > h5 {
    cursor: pointer;
    font-size: 14px;
    margin: 0;
  }

  > h6 {
    font-family: 'Inter';
    font-size: 12px;
    margin: 0;
  }
`

const Controls1 = styled.div`
    display:flex;
    justify-content: flex-start;
`
const Controls2 = styled.div`
    display:flex;
    justify-content:space-between;
`

const WarningIcon = styled(CIcon) <{ color: string }>`
    color:${props => props.color} !important;
    vertical-align: -0.1rem;
    margin-right: 8.6px;
`
const WarningTooltip = styled(CTooltip)`
    .tooltip-inner{
        margin-top:5px;
        font-size:11px;
        white-space: pre-line;
        background:${theme().gray200Color} !important;
        color:${theme().darkColor};
    }    
`

const AvailabilityTableDataCell = styled.p<{ isactive: boolean }>`
  width:50%;
  margin-top:15px;
  background-color:${props => props.isactive ? theme().success : theme().danger};
  color:white;
  font-weight:700;
  text-align:center;
  border-radius:5px;
`

const NameCell = styled(CTableDataCell)`
  cursor: pointer;
`

const ColoredDataCellText = styled(CTableDataCell) <{ color: string, trigger?: boolean, noPadding?: boolean }>`
    color:${props => props.color} !important;
    font-weight: ${({ trigger }) => trigger && trigger === true ? '700' : '400'};
    letter-spacing: ${({ trigger }) => trigger && trigger === true ? '0.1px' : '0px'};
    padding-left:${({ noPadding }) => noPadding ? '0px' : '12px'};
    cursor: pointer;
`


const Abrev = styled(CTableDataCell) <{ color: string }>`
  color:${props => props.color} !important;
  font-weight:700;
  padding-left:12px;
  cursor: pointer;
`

const AvatarCol = styled(CCol)`
  &.col-sm-1{
    min-width: fit-content;
  }

  &.no_padding {
    padding-right: 0px !important;
  }
`

const DownloadButton = styled.div`
  cursor: pointer;
  margin-right: 10px;
` 

const CustomH5 = styled.h5`
  display:flex;
  justify-content:space-between;
`

const HelpText = styled(CFormText) <{ valid: boolean | undefined }>`
 color: ${(props) => props.valid === false ? theme().danger : '#D8DBE0'};
`

export {
  CustomControlsContainer,
  CustomControls1,
  CustomControls2,
  CustomWrapper,
  Wrapper,
  ControlsContainer,
  CustomTableRow,
  ActionsCell,
  ChevronCell,
  ImageCell,
  NameAndRoleCell,
  Controls1,
  Controls2,
  WarningIcon,
  WarningTooltip,
  AvailabilityTableDataCell,
  NameCell,
  Abrev,
  ColoredDataCellText,
  AvatarCol,
  Title,
  TitleBox,
  ContainerBox,
  DownloadButton,
  CustomH5,
  HelpText
}