/**
 * Timerecordevents component
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */
import React, { } from 'react'
import { Trans } from 'react-i18next'
import { DateTime } from 'luxon'
import { useDispatch, useSelector } from 'react-redux'
// Styles
import { Controls1, Controls2 } from 'shared/genericStyledComponents'
import theme from 'ui/theme'
import * as Styles from './styles'
// Store
import { actions, RootState } from 'store/rootSlices'
// Icons
import Expand from 'assets/icons/expand_more'
import Collapse from 'assets/icons/expand_less'
import Arrow from 'assets/icons/arrow_forward'
import Trash from 'assets/icons/trash'
// Components
import Dropdown from 'shared/components/FormComponents/dropdown'
import Timepicker from 'shared/components/FormComponents/timepicker'
import Loadingcontainer from 'shared/components/Loadingcontainer'
import CustomTextInput from 'shared/components/CustomTextInput'
// Utils & Configs
import { convertMinutesToHours, allAbsenseTypes } from 'scenes/Usertimerecorddetails/utils'
import { hasPermission } from 'permissions'
import config from 'config'

const Timerecordevents = ({ t, i18n }) => {

  const {
    setExpand,
    submitClockOut,
    submitClockIn,
    onDeleteTimeRecordEntry,
    undoDelete,
    onEditTimeRecord
  } = actions.UserTimeRecordDetails

  const {
    days,
    user,
    eventsLoading,
    editedTimeRecords,
    deletedTimeRecords,
    workdays,
  } = useSelector((state: RootState) => state.UserTimeRecordDetails)

  const { fleetLongList } = useSelector((state: RootState) => state.FleetManagement)
  const { collaboratorsLongList } = useSelector((state: RootState) => state.CollaboratorsManagement)
  const { permissions } = useSelector((state: RootState) => state.App);

  const {
    PERMISSIONS
  } = config

  const dispatch = useDispatch()

  const _renderDynamicDropdown = (event, workday, d) => {
    return event.entryType === 'absense' ? (
      <Dropdown
        id='timeRecordEntry-absenseType'
        disabled={event.deleted || !event.isCreatedManually || workday?.approved}
        edited={editedTimeRecords.find(tm => tm.id === event.id)?.absenseType ? true : false}
        value={event.absenseType || ""}
        placeholder={i18n.t('placeholders.absenseType')}
        options={allAbsenseTypes(i18n)}
        onChange={(e) => {
          dispatch(onEditTimeRecord({
            id: event.id,
            value: e.target.value,
            editType: "absenseType",
            event,
            day: d.date,
            editedTimeRecords
          }))
        }}
      />
    ) : (
      <Dropdown
        id='timeRecordEntry-unit'
        edited={editedTimeRecords.find(tm => tm.id === event.id)?.vehicle ? true : false}
        disabled={event.deleted || (event.entryType !== "other" || event.unsaved) || workday?.approved}
        value={event.vehicle?.uuid || ""}
        placeholder={i18n.t('placeholders.select')}
        options={
          fleetLongList.map((v, idx) => {
            return {
              label: v.name, value: v.uuid
            }
          })
        }
        onChange={(e) => {
          dispatch(onEditTimeRecord({
            id: event.id,
            value: e.target.value,
            editType: "vehicle",
            event,
            day: d.date,
            editedTimeRecords
          }))
        }}
      />
    )
  }


  return (
    <Styles.Wrapper>
      {
        days.map((d, daysIdx) => {

          const workday = workdays.find(w => w.date === d.date)

          return (
            <Styles.EventsContainer key={`event-container-${daysIdx}`}>
              <Styles.CustomControlsContainer expanded={d.expand} onClick={e => dispatch(setExpand({ expand: !d.expand, day: d, idx: daysIdx }))}>
                <Controls1>
                  <Styles.Text
                    className='with_pointer'
                  >
                    {
                      DateTime.fromISO(d.date).toFormat("dd/LL/yyyy") === DateTime.now().toFormat("dd/LL/yyyy") && `${i18n.t('timeRecord.today')} · `
                    }
                    <strong>
                      {
                        `${DateTime.fromISO(d.date).toFormat("dd/LL/yyyy")}`
                      }
                    </strong>
                  </Styles.Text>
                  {
                    d.date === DateTime.now().toISODate().toString() ?

                      !workday?.approved ?
                        user.lastClocked && user.lastClocked.hasFinished === false ?
                          <>
                            <Styles.Text color={`${theme().gray200Color}`}>
                              |
                            </Styles.Text>
                            {
                              hasPermission(permissions, PERMISSIONS.USERS.TIME_RECORD.CREATE_CLOCKINS) &&
                              !d.events.find(d => d.unsaved || d.edited) && <Styles.Text
                                className='with_left_margin with_pointer over'
                                color={theme().danger}
                                onClick={(e) => {
                                  dispatch(submitClockOut(d.date))
                                }}
                              >
                                {i18n.t('timeRecord.clockOut')}
                              </Styles.Text>
                            }
                          </>
                          :
                          <>
                            <Styles.Text color={`${theme().gray200Color}`}>
                              |
                            </Styles.Text>
                            {
                              hasPermission(permissions, PERMISSIONS.USERS.TIME_RECORD.CREATE_CLOCKINS) &&
                              !d.events.find(d => d.unsaved || d.edited) && <Styles.Text
                                className='with_left_margin with_pointer over'
                                color={theme().success}
                                onClick={(e) => {
                                  dispatch(submitClockIn(d.date))
                                }}
                              >
                                {i18n.t('timeRecord.clockIn')}
                              </Styles.Text>
                            }
                          </>
                        :
                        null
                      :
                      null
                  }
                </Controls1>
                <Controls2>
                  <Styles.Text className='with_right_margin' color={theme().gray500Color}>
                    {
                      workday?.vehicles?.map((vhc, idx) => {
                        if (idx === workday?.vehicles.length - 1)
                          return (<span>{vhc.name}<strong>{` ${convertMinutesToHours(vhc.timeOnboard)}  `}</strong>|</span>)
                        return (<span>{vhc.name}<strong>{` ${convertMinutesToHours(vhc.timeOnboard)} · `}</strong></span>)
                      })
                    }
                  </Styles.Text>
                  <Styles.Text className='with_left_margin'>
                    <Trans i18nKey={t('timeRecord.totalIntervalHours', {
                      onBoard: workday?.timeOnboard > 0 ? convertMinutesToHours(workday?.timeOnboard) : 0,
                      total: workday?.timeWorking > 0 ? convertMinutesToHours(workday?.timeWorking) : 0,
                    })} />
                  </Styles.Text>
                  <Styles.ButtonIcon id={`-toogleAction`} marginl='12' onClick={e => dispatch(setExpand({ expand: !d.expand, day: d, idx: daysIdx }))}>
                    {d.expand ? <Collapse /> : <Expand />}
                  </Styles.ButtonIcon>
                </Controls2>
              </Styles.CustomControlsContainer>
              {
                eventsLoading ?
                  <Loadingcontainer />
                  :
                  d.events.length > 0 ?
                    d.events.map((event, eventsIdx) => {
                      let placeholder = i18n.t('placeholders.description')
                      if(!event.description) {
                        switch (event.entryType) {
                          case "clock_in":
                            placeholder = i18n.t('placeholders.clockIn')
                            break;
                          case "clock_out":
                            placeholder = i18n.t('placeholders.clockOut')
                            break;
                          case "meal":
                            placeholder = i18n.t('placeholders.meal')
                            break;
                          case "absense":
                            placeholder = i18n.t('placeholders.absense')
                            break;
                          case "other":
                            placeholder = i18n.t('placeholders.other')
                            break;
                          default:
                            placeholder = i18n.t('placeholders.description')
                            break;
                        }
                      }

                      const editor: any = collaboratorsLongList.find((c: any) => c.uuid === event.changes?.user.uuid)

                      return (
                        d.expand ? <Styles.EventWrapper key={`timeRecord-event-${editor?.uuid}`}>
                          <Styles.Event unsaved={event.unsaved} deleted={event.deleted}>
                            <Styles.TimePickersWrapper>
                              <Styles.EventTimes>
                                <Timepicker
                                  id={`timeRecordEntry-time1`}
                                  disabled={!event.canEdit || event.deleted || (event.entryType === "clock_out" || event.entryType === "assignment_out" || event.entryType === "meal" || event.entryType === 'absense') || workday?.approved}
                                  edited={editedTimeRecords.find(tm => tm.id === event.id)?.start ? true : false}
                                  valid={event.start.valid}
                                  helpText={event.start.message}
                                  value={
                                    event.start.value && (event.entryType !== "clock_out" && event.entryType !== "assignment_out") ?
                                      DateTime.fromISO(event.start.value).toFormat('HH:mm')
                                      :
                                      null
                                  }
                                  onChange={(e) => {
                                    dispatch(onEditTimeRecord({
                                      id: event.id,
                                      value: e,
                                      editType: "start",
                                      event,
                                      day: d.date,
                                      editedTimeRecords
                                    }))
                                  }}
                                  timeFormat="HH:mm"
                                />
                                <Arrow width={50} />
                                <Timepicker
                                  id={`timeRecordEntry-time2`}
                                  disabled={!event.canEdit || event.deleted || (event.entryType === "clock_in" || event.entryType === "assignment_in" || event.entryType === "meal" || event.entryType === 'absense') || workday?.approved}
                                  edited={
                                    (event.entryType === "clock_out" || event.entryType === "assignment_out") && event.start.value ?
                                      editedTimeRecords.find(tm => tm.id === event.id)?.start ? true : false
                                      :
                                      editedTimeRecords.find(tm => tm.id === event.id)?.end ? true : false
                                  }
                                  valid={event.end.valid}
                                  helpText={event.end.message}
                                  value={event.end.value ?
                                    DateTime.fromISO(event.end.value).toFormat('HH:mm')
                                    :
                                    (event.entryType === "clock_out" || event.entryType === "assignment_out") && event.start.value ?
                                      DateTime.fromISO(event.start.value).toFormat('HH:mm')
                                      :
                                      null
                                  }
                                  onChange={(e) => {
                                    dispatch(onEditTimeRecord({
                                      id: event.id,
                                      value: e,
                                      editType: (event.entryType === "clock_out" || event.entryType === "assignment_out") ? "start" : "end",
                                      event,
                                      day: d.date,
                                      editedTimeRecords
                                    }))
                                  }}
                                  timeFormat="HH:mm"
                                />
                              </Styles.EventTimes>
                            </Styles.TimePickersWrapper>
                            <Styles.DropdownWrapper>
                              { _renderDynamicDropdown(event, workday, d)}
                            </Styles.DropdownWrapper>
                            <Styles.DescriptionWrapper>
                              <CustomTextInput
                                edited={editedTimeRecords.find(tm => tm.id === event.id)?.description ? true : false}
                                id='timeRecordEntry-description'
                                disabled={event.deleted || !event.isCreatedManually || workday?.approved}
                                value={event.description}
                                placeholder={placeholder}
                                onChange={(e) => {
                                  dispatch(onEditTimeRecord({
                                    id: event.id,
                                    value: e.target.value,
                                    editType: "description",
                                    event,
                                    day: d.date,
                                    editedTimeRecords
                                  }))
                                }} />
                            </Styles.DescriptionWrapper>
                          </Styles.Event>
                          <Styles.TableRemoveCell style={{ width: `75px`, textAlign: 'center', color: event.end ? theme().gray500Color : theme().success }}>
                            {
                              (event.isCreatedManually && !event.unsaved && !event.edited && !workday?.approved) ?
                                <div onClick={(e) => {
                                  dispatch(onDeleteTimeRecordEntry({
                                    id: event.id,
                                    event: event,
                                    deletedTimeRecords: deletedTimeRecords,
                                    days: days
                                  }))
                                }}>
                                  <Trash id={`timeRecordEntry-deleteEntry${eventsIdx}`} />
                                </div>
                                :
                                event.deleted && event.deleted === true ?
                                  <div className='undo' onClick={() => {
                                    dispatch(undoDelete({
                                      event: event,
                                      deletedTimeRecords: deletedTimeRecords,
                                      days: days
                                    }))
                                  }}>
                                    <span>{i18n.t('timeRecord.undo')}</span>
                                  </div>
                                  :
                                  null

                            }
                          </Styles.TableRemoveCell>
                          {
                            !event.isCreatedManually ?
                              event.unsaved && event.unsaved === true ?
                                <Styles.Text
                                  className='smaller'
                                  color={theme().gray500Color}
                                >
                                  {i18n.t('timeRecord.unsaved')}
                                </Styles.Text>
                                :
                                <Styles.Text
                                  className='smaller'
                                  color={theme().gray500Color}
                                >
                                  {i18n.t('timeRecord.automaticallyGenerated')}
                                  {event.location !== null ? ` ${i18n.t('timeRecord.location')} ${event.location?.name}` : ``}
                                  {event.wasChanged === true ? ` - ${i18n.t('timeRecord.editedBy')} ${editor?.name}` : ``}
                                  {event.wasChanged === true ? ` - ${i18n.t('timeRecord.edited')} ${Object.keys(event.changes)[1]} 
                                  from ${DateTime.fromISO(event.changes[Object.keys(event.changes)[1]]).toFormat('HH:mm')} 
                                  to ${DateTime.fromISO(event.start.value).toFormat('HH:mm')}` : ``
                                  }

                                </Styles.Text>
                              :
                              event.unsaved && event.unsaved === true ?
                                <Styles.Text
                                  className='smaller'
                                  color={event.deleted && event.deleted === true ? theme().danger : theme().gray500Color}
                                >
                                  {i18n.t('timeRecord.unsaved')}
                                </Styles.Text>
                                :
                                null

                          }
                        </Styles.EventWrapper>
                          : null
                      )
                    })
                    :
                    d.expand && <Styles.NoEvents>
                      <Styles.Text color={theme().gray300Color}>{i18n.t('timeRecord.noEntries')}</Styles.Text>
                    </Styles.NoEvents>

              }
            </Styles.EventsContainer>
          )
        })
      }
    </Styles.Wrapper>
  )
}

export default Timerecordevents