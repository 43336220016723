/**
 * Datepicker component
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */

import React, { forwardRef, useEffect, useState } from 'react'
import { DateTime } from "luxon"
import styled from 'styled-components'
import { CFormLabel, CFormText } from '@coreui/react';
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"
import "./datepicker.css";
import theme from 'ui/theme';
import { PatternFormat } from 'react-number-format';

interface PropTypes {
  id?: string,
  wrapperStyle?: React.CSSProperties,
  required?: boolean,
  label?: string,
  value?: DateTime,
  onChange?: Function,
  defaultValue?: string,
  disabled?: boolean,
  valid?: boolean
  helpText?: string,
}

const Datepicker = (props: PropTypes) => {

  const [startDate, setStartDate] = useState<DateTime | null>(props.value ? props.value : null);

  //useEffectHere to parse the date correctly
  useEffect(() => {
    if (props.value && props.value !== "") setStartDate(DateTime.fromFormat(props.value, "yyyy-LL-dd"))
    if (props.value === "") setStartDate(null);
  }, [props.value])

  const handleDate = (date, e) => {


    if (props.onChange != null && isNaN(date) === false) {

      if (date !== null) {
        const luxonDate = DateTime.fromJSDate(date);
        if (luxonDate.isValid) {
          setStartDate(luxonDate);
          props.onChange(DateTime.fromJSDate(date).toFormat("yyyy-LL-dd"));
        }
      }
      else {
        setStartDate(null);
        props.onChange("")
      }

    }
  }

  const CustomInput = forwardRef((props: any, ref) => {
    return (
      <PatternFormat
        disabled={props.disabled}
        format='##/##/####'
        mask="_"
        getInputRef={ref}
        value={props.value}
        placeholder={props.placeholder}
        onChange={props.onChange}
        onClick={props.onClick}
      />
    )
  });


  return (
    <Wrapper style={props.wrapperStyle} valid={props.valid}>
      {props.label && <CFormLabel>{props.label}<span>{!!props.required ? "*" : ""}</span></CFormLabel>}
      <DatePicker
        id={props.id}
        customInput={
          <CustomInput />
        }
        disabled={props.disabled === true ? true : false}
        selected={startDate ? startDate.toJSDate ? startDate.toJSDate() : DateTime.fromFormat(startDate, "yyyy-LL-dd").toJSDate() : null}
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        dateFormat={"dd/MM/yyyy"}
        placeholderText={"dd/mm/aaaa"}
        onChange={(date, e) => {
          handleDate(date, e)
        }}
      />
      {props.helpText && <HelpText valid={props.valid}>{props.helpText}</HelpText>}
    </Wrapper>
  )
}

export default Datepicker

const Wrapper = styled.div<{ valid: boolean | undefined }>`
  .react-datepicker__tab-loop .react-datepicker-popper {
      z-index: 5;
    }
  }

  input[inputmode="numeric"] {
    font-size: 14px;
    width: 100%;
    color:${theme().gray900Color};
    border-color:${'#D8DBE0'};
    line-height:1.5;
    border-style: solid;
    border-width: 1px;
    border-radius: 4px;
    border-color:${(props) => props.valid === false ? theme().danger : '#D8DBE0'};
    padding: 0.375rem 0.75rem;
    background-position:97%;
    background-repeat:no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg width='14' height='15' viewBox='0 0 14 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.3335 1.50001H11.6668V0.166672H10.3335V1.50001H3.66683V0.166672H2.3335V1.50001H1.66683C0.933496 1.50001 0.333496 2.1 0.333496 2.83334V13.5C0.333496 14.2333 0.933496 14.8333 1.66683 14.8333H12.3335C13.0668 14.8333 13.6668 14.2333 13.6668 13.5V2.83334C13.6668 2.1 13.0668 1.50001 12.3335 1.50001ZM12.3335 13.5H1.66683V6.16667H12.3335V13.5ZM12.3335 4.83334H1.66683V2.83334H12.3335V4.83334Z' fill='%23636F83'/%3E%3C/svg%3E%0A");
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    .react-datepicker__triangle{
      left: -40px !important;
    };
    &:focus{
      border-color: #321fdb50;
      outline-width: 0.1px;
      outline-color: #321fdb50;
      outline-style: solid;
      outline-offset: 0px;
      box-shadow: 0 0 1px 5px #321fdb50;
    };
    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: ${theme().gray500Color};
        opacity: 1; /* Firefox */
    };
    &:disabled{
      background-color:#d8dbe0;
    };
  }

  > * {
    font-family: 'Inter';
  }

  > label {
    font-size: 14px;
    color: ${theme().darkColor};
    > span {
      color: ${theme().primary};
    }
  }
`

const HelpText = styled(CFormText) <{ valid: boolean | undefined }>`
  color: ${(props) => props.valid === false ? theme().danger : '#D8DBE0'};
`
