/**
 * Entitymemberslist scene
 *
 * @author João Dias <jdias@ubiwhere.com>
 *
 *
 */
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
// Utils & Configs
import { history, paginationCurrentPage, paginationElementsCount } from 'utils';
import config from 'config';
import { hasPermission } from 'permissions';
// Store
import { actions, RootState } from 'store/rootSlices'
// Components
import CustomButton from 'shared/components/CustomButton'
import Loadingcontainer from 'shared/components/Loadingcontainer'
import SearchBox from 'shared/components/SearchBox'
import SorterComponent from 'shared/components/SorterComponent'
import TableContainer from 'shared/components/TableContainer'
import EntityMembersTableRow from './Entitymemberstablerow'
//Icons
// import Download from 'assets/icons/file_download';
import Feed from 'assets/icons/feed';
// Types
import { EntityMemberListItem } from 'types/entity/entity'
// Styles
// import { DownloadButton } from './styles'
import { Wrapper, Controls1, Controls2, ControlsContainer } from 'shared/genericStyledComponents'

//import config from 'config'

const Entitymemberslist = ({ t, i18n }) => {

  const entityId = history.location.pathname.split("/")[2];
  const { loading, sort, page, total, members, searchTerm } = useSelector((state: RootState) => state.Entitymemberslist)
  const { onMount, onUnmount, setSearchTerm, setPage, setSort } = actions.Entitymemberslist
  const { navigateTo } = actions.App
  const { permissions } = useSelector((state: RootState) => state.App)
  const { ELEMENTS_PER_PAGE, PERMISSIONS } = config
  const hist = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(onMount({hist, id: entityId}))
    return () => {
      dispatch(onUnmount())
    }
  }, [dispatch, onMount, onUnmount, hist, entityId]);

  return (
    <Wrapper>
      <ControlsContainer>
        <Controls1>
          <SearchBox
            id="entityMembers-searchBox"
            defaultValue={searchTerm}
            onSubmit={(term) => {
              let params = new URLSearchParams(history.location.search);
              term !== "" ? params.set('search', term) : params.delete('search')
              history.replace({ search: (params).toString() })
              dispatch(setSearchTerm(term))
            }}
          />
        </Controls1>
        <Controls2>
          {/* {hasPermission(permissions, PERMISSIONS.FLEET.CREATE_MAINTENANCE) &&
            <DownloadButton onClick={() => {
              // if(!exportLoading) 
            }}>
              <Download width={30} height={30} />
            </DownloadButton>
          } */}
          {
            hasPermission(permissions, PERMISSIONS.USERS.CREATE_PARTNER_USER) && <CustomButton id="entityMembers-newButton" text={t('common.new')} icon="add" color="success" buttonStyle={{ color: "#FFFFFF" }} onClick={() => dispatch(navigateTo(`/entidades/${entityId}/novo-membro`))} />
          }
        </Controls2>
      </ControlsContainer>
      {
        loading ?
          <Loadingcontainer />
          :
          <TableContainer
            count={paginationElementsCount(total)}
            currentPage={paginationCurrentPage(total, page)}
            elementsPerPage={ELEMENTS_PER_PAGE}
            onPageChange={pg => { dispatch(setPage(pg)); }}
            headerElements={[
              <SorterComponent sort={sort} active={sort.field === "name"} id="entityMembers-nameSorter" filterName={t('common.name')} onChange={(dir) => { dispatch(setSort({ field: "name", direction: dir })) }} />,
              <SorterComponent sort={sort} active={sort.field === "is_admin"} id="entityMembers-adminSorter" filterName={t('entities.administrator')} onChange={(dir) => { dispatch(setSort({ field: "is_admin", direction: dir })) }} />,
              t('common.status'),
              t('common.email'),
              t('common.actions')
            ]}
          >
            {members.map((el: EntityMemberListItem, idx: number) =>
              <React.Fragment key={`entity-list-${idx}`}>
                <EntityMembersTableRow
                  id={`entityMembers-tableRow-${idx}`}
                  permission={hasPermission(permissions, PERMISSIONS.USERS.VIEW_PARTNER_USER)}
                  onClick={() => {
                    if (hasPermission(permissions, PERMISSIONS.USERS.VIEW_PARTNER_USER)) {
                      dispatch(actions.App.navigateTo(`/entidades/${entityId}/editar-membro/${el.id}`))
                    }
                  }}
                  entityMember={el}
                  entityId={entityId}
                  actions={
                    <React.Fragment>
                      <Feed />
                    </React.Fragment>
                  }
                />
              </React.Fragment>
            )}
          </TableContainer>

      }
    </Wrapper>
  )
}

export default Entitymemberslist