/**
 * Searchbox component
 *
 * @author João Dias <jdias@ubiwhere.com>
 *
 *
 */

import React, { useEffect, useState, useCallback } from 'react'
import SearchIcon from 'assets/icons/search'
import { CFormInput } from '@coreui/react-pro';
import _ from "lodash";
import i18n from "i18next";
import { Wrapper } from './styles';

interface PropTypes {
  id?: string,
  onSubmit: Function
  defaultValue?: string
}

const SearchBox = (props: PropTypes) => {
  const [searchTerm, setSearchTerm] = useState(props.defaultValue || '');

  const handleDebouncedChange = useCallback(
    _.debounce((value: string) => {
      props.onSubmit(value);
    }, 800),
    [props.onSubmit]
  );

  // Handle the input change
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchTerm(value);

    // Immediately trigger submission if the input is cleared
    if (value === '') {
      props.onSubmit(value);
    } else {
      handleDebouncedChange(value);
    }
  };

  useEffect(() => {
    setSearchTerm(props.defaultValue || '');
  }, [props.defaultValue]);


  return (
    <Wrapper>
      <SearchIcon />
      <CFormInput
        id={props.id}
        value={searchTerm}
        onChange={handleChange}
        placeholder={i18n.t('common.searchBox')}
      />
    </Wrapper>
  )
}

export default SearchBox