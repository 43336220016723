/**
 * Entitymemberslist scene slice
 *
 * @author João Dias <jdias@ubiwhere.com>
 *
 *
 */
import { createSlice } from '@reduxjs/toolkit'
import { SortType } from 'types/misc'
import { EntityMemberListItem } from 'types/entity/entity'

type InitialState = {
  sort: SortType,
  page: number,
  total: number,
  members: EntityMemberListItem[],
  searchTerm: string,
  loading: boolean
}

const initialState: InitialState = {
  sort: {
    direction: null,
    field: null,
  },
  page: 1,
  total: 0,
  members: [],
  searchTerm: '',
  loading: true,
}

export default createSlice({
  name: 'Entitymemberslist',
  initialState,
  reducers: {
    onMount: (state, { payload }) => { },
    onUnmount: () => { },
    setTotal: (state, { payload }) => {
      state.total = payload;
    },
    fetchEntityMembers: (state, { payload }) => { },
    setPage: (state, { payload }) => {
      state.page = payload;
    },
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
    setSort: (state, { payload }) => {
      state.sort = payload;
    },
    setEntityMembers: (state, { payload }) => {
      state.members = payload;
    },
    setSearchTerm: (state, { payload }) => {
      state.searchTerm = payload
    },
    getURLParams:(state, payload) => {},
    populateFiltersOnMount:(state, { payload }) => {
      state.searchTerm = payload.searchTerm
    }
  }
})