/**
 * Categorylisttablerow component
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */

 import React from 'react'
 import { ActionsCell } from 'shared/genericStyledComponents'
 import { TableRowPropTypes } from 'types/misc'
 import { CTableDataCell } from '@coreui/react-pro'
 import ChevronRight from 'assets/icons/chevron_right'
 import { CategoryListItem } from 'types/trips/categories'
import { actions } from 'store/rootSlices'
import { useDispatch } from 'react-redux';
import { CustomChevronCell } from '../../styles'
import { CustomCTableRow, NameCell } from './styles'
 
 type PropTypes = TableRowPropTypes & {
   id?: string,
   category: CategoryListItem,
   permission: boolean,
 }
 
const Categorylisttablerow = (props: PropTypes) => {

  const dispatch = useDispatch()
  const {
    permission,
    category: {
      name,
      description,
      id
    }
  } = props

  return (
    <CustomCTableRow id={props.id}>
      <NameCell id={`${props.id}-name`} onClick={() => props.onClick()} >{name}</NameCell>
      <CTableDataCell>{description}</CTableDataCell>
      <ActionsCell nopointer>
        {props.actions}
        <CustomChevronCell id={`${props.id}-chevron`}>
          {
            permission &&
            <a href={`/viagens/configuracoes/editar-categoria/${id}`} onClick={(e) => {
              e.preventDefault()
              dispatch(actions.App.navigateTo(`/viagens/configuracoes/editar-categoria/${id}`))
              }}>
              <ChevronRight></ChevronRight>
            </a>
          }
        </CustomChevronCell>
      </ActionsCell>
    </CustomCTableRow>
  )
}
 
 export default Categorylisttablerow
 

