/**
 * Configurations scene slice
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */
import { createSlice } from '@reduxjs/toolkit'
import { activityCommissionForm, extraCommissionForm } from 'types/collaborators/collaborator'

interface initialState {
  page: number,
  total: number,
  searchTerm:string,
  loading: boolean,
  configurations: any[]
  activitiesForm: Array<activityCommissionForm>,
  extrasForm: Array<extraCommissionForm>
  confirmAllModal: boolean,
  isApplyAllOnActivities: boolean,
  activitiesCommissionsDefault: string,
  extrasCommissionsDefault: string,
  submitButton: boolean,
  comissionsChanged: boolean,
  hasEditPermissions: boolean,
}

const initialState : initialState = {
  searchTerm:"",
  total:0,
  page:1,
  loading: true,
  configurations:[],
  activitiesForm: [],
  extrasForm: [],
  confirmAllModal: false,
  isApplyAllOnActivities: true,
  activitiesCommissionsDefault: "0",
  extrasCommissionsDefault: "0",
  submitButton: false,
  comissionsChanged: false,
  hasEditPermissions: false
}

export default createSlice({
  name: 'Configurations',
  initialState,
  reducers: {
    onMount: () => {},
    onUnmount: () => {
      return initialState
    },
    setHasEditPermissions: (state, { payload }) => {
      state.hasEditPermissions = payload
    },
    setSubmitButtonDisabled: (state, {payload }) => {
      state.submitButton = payload
    },
    getCancelReschedules: () => {},
    getCommissions: () => {},
    setSearchTerm: (state, { payload} ) => {
      state.searchTerm = payload
    },
    setTotal: (state, { payload} ) => {
      state.total = payload
    },
    setPage: (state, { payload} ) => {
      state.page = payload
    },
    setLoading:(state, { payload }) => {
      state.loading = payload
    },
    setConfigurations: (state, { payload} ) => {
      state.configurations = payload.map(conf => {
        const timeLimitHours = conf.timeLimit.hours < 10 ? `0${conf.timeLimit.hours}`: conf.timeLimit.hours
        const timeNoFeeHours = conf.timeNoFee.hours < 10 ? `0${conf.timeNoFee.hours}`: conf.timeNoFee.hours
        const timeLimitMinutes = conf.timeLimit.minutes < 10 ? `0${conf.timeLimit.minutes}`: conf.timeLimit.minutes
        const timeNoFeeMinutes = conf.timeNoFee.minutes < 10 ? `0${conf.timeNoFee.minutes}`: conf.timeNoFee.minutes
        const cancellationLimitHours = conf.cancellationLimit.hours < 10 ? `0${conf.cancellationLimit.hours}`: conf.cancellationLimit.hours
        const cancellationLimitMinutes = conf.cancellationLimit.minutes < 10 ? `0${conf.cancellationLimit.minutes}`: conf.cancellationLimit.minutes

        const configurations = {
          id: conf.id,
          service: conf.service,
          fee: {value: conf.fee, valid: true, message:""},
          isActive: {value: conf.isActive, valid: true, message:""},
          timeLimit:{ value: `${timeLimitHours}:${timeLimitMinutes}`, valid: true, message:"" },
          timeNoFee:{ value: `${timeNoFeeHours}:${timeNoFeeMinutes}`, valid: true, message:"" },
          invoiceArticle: {value: conf.invoiceArticle, valid: true, message:""},
          cancellationLimit: {value: `${cancellationLimitHours}:${cancellationLimitMinutes}`, valid: true, message:""},
        }

        return configurations
      })
    },
    updateCancelReschedule: (state, { payload }) => {},
    onSubmit:(state, { payload }) => {},
    onSubmitCommissions:() => {},
    onChangeFormRow: (state, { payload} ) => {
      const data = payload.conf
      const ConfigurationIDX = payload.id

      state.configurations[ConfigurationIDX] = data
    },
    onUpdateValidations: (state, { payload }) => {
      state.configurations = payload
    },
    setConfirmAllModal: (state, { payload }) => {
      state.confirmAllModal = payload.confirmAll;
      state.isApplyAllOnActivities = payload.isApplyAllOnActivities
    },
    onActivitiesDefaultChange: (state, { payload }) => {
      state.activitiesCommissionsDefault = payload
    },
    onExtrasDefaultChange: (state, { payload }) => {
      state.extrasCommissionsDefault = payload
    },
    setCommissionsChanged: (state, { payload }) => {
      state.comissionsChanged = payload
    },
    updateValidationsOnEntityCommissions: (state, { payload }) => {
      state.activitiesForm = payload.activities?.map((act) => {
        return ({
          uuid: act.uuid,
          name: act.name,
          isCustom: act.isCustom,
          commission: act.commission
        })
      });
      state.extrasForm = payload.extras?.map((ext) => {
        return ({
          uuid: ext.uuid,
          name: ext.name,
          isCustom: ext.isCustom,
          commission: ext.commission
        })
      });
    },
    populateDefaultCommissionsOnMount: (state, { payload }) => {
      state.activitiesForm = payload.activitiesCommissionForm?.map((act) => {

        if (payload.activities.find(a => a.activity.uuid === act.uuid)) {
          const a = payload.activities.find(a => a.activity.uuid === act.uuid)
          return ({
            ...act,
            isCustom: true,
            commission: {
              value: a.amount,
              valid: true,
              message: ""
            }
          })
        } else return act
      });

      state.extrasForm = payload.extrasCommissionForm?.map((ext) => {

        if (payload.extras.find(e => e.extra.uuid === ext.uuid)) {
          const e = payload.extras.find(e => e.extra.uuid === ext.uuid)
          return ({
            ...ext,
            isCustom: true,
            commission: {
              value: e.amount,
              valid: true,
              message: ""
            }
          })
        } else return ext
      });

    },
    setDefaultCommissions: (state, { payload }) => {
      state.activitiesForm = payload.activities?.map((act) => {
        return ({
          uuid: act.uuid,
          name: act.name,
          isCustom: true,
          commission: {
            value: "",
            valid: true,
            message: ""
          }
        })
      });
      state.extrasForm = payload.extras?.map((ext) => {
        return ({
          uuid: ext.uuid,
          name: ext.name,
          isCustom: true,
          commission: {
            value: "",
            valid: true,
            message: ""
          }
        })
      });
    },
    onActivityCommissionChange: (state, { payload }) => {
      state.activitiesForm[payload.idx] = {
        ...state.activitiesForm[payload.idx],
        commission: { value: payload.e, valid: true, message: "" }
      }
      state.comissionsChanged = true
    },
    onCommissionChangeToggler: (state, { payload }) => {
      if(payload.isActivity) {
        state.activitiesForm[payload.idx] = {
          ...state.activitiesForm[payload.idx],
          isCustom: payload.value,
          commission: { value: payload.default, valid: true, message: "" }
        }
      } else {
        state.extrasForm[payload.idx] = {
          ...state.extrasForm[payload.idx],
          isCustom: payload.value,
          commission: { value: payload.default, valid: true, message: "" }
        }
      }
      state.comissionsChanged = true
    },
    onExtraCommissionChange: (state, { payload }) => {
      state.extrasForm[payload.idx] = {
        ...state.extrasForm[payload.idx],
        commission: { value: payload.e, valid: true, message: "" }
      }
      state.comissionsChanged = true
    },
    changeDefaultCommissionValues: (state, { payload }) => { 
      if(payload.isActivity) {
        state.activitiesForm = state.activitiesForm.map((act) => {
          if(act.isCustom) return act
          return {
            ...act,
            commission: {
              ...act.commission,
              value: payload.value
            }
          }
        })
      } else {
        state.extrasForm = state.extrasForm.map((ext) => {
          if(ext.isCustom) return ext
          return {
            ...ext,
            commission: {
              ...ext.commission,
              value: payload.value
            }
          }
        })
      }
      state.comissionsChanged = true
    },
    applyValueOnAllActivitiesComissions: (state, { payload }) => {
      const activitiesCommissionForm: Array<activityCommissionForm> = Array.from(payload.activitiesForm)

      state.activitiesForm = activitiesCommissionForm.map((act) => {
        return {
          ...act,
          isCustom: false,
          commission: {
            ...act.commission,
            value: payload.defaultCommission
          }
        }
      })
      state.comissionsChanged = true
    },
    applyValueOnAllExtrasComissions: (state, { payload }) => {
      const extrasCommissionForm: Array<extraCommissionForm> = Array.from(payload.extrasForm)

      state.extrasForm = extrasCommissionForm.map((ext) => {
        return {
          ...ext,
          isCustom: false,
          commission: {
            ...ext.commission,
            value: payload.defaultCommission
          }
        }
      })
      state.comissionsChanged = true
    },
  }
})