import styled from 'styled-components'
import {  CCol, CRow } from '@coreui/react-pro';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  > form {
      width: 90%;
  }
`

export const ButtonWrapper = styled.div`
  display:flex;
  justify-content:flex-end;
  align-items: center;
  margin-top: 24px;

  > button:nth-child(2) {
      margin-left: 16px;
      height: 33px;
      line-height: 0;
  }
`

export const Row = styled(CRow)`
  --cui-gutter-y:0rem;
  margin-top:26px;
`

export const CustomColumn = styled(CCol)`
  flex-grow: 0.5;
`