import React from 'react'
import i18n from 'i18next'
import { useDispatch, useSelector } from 'react-redux'
import { actions, RootState } from 'store/rootSlices'
import { CCol } from '@coreui/react-pro'
import { vehiclesIntersectionFilter } from '../../utils';
import { PlanningVehiclesForm } from 'types/trips/plannings';
import SectionContainer from 'shared/components/Sectioncontainer'
import Dropdown from 'shared/components/FormComponents/dropdown'
import CustomTextInput from 'shared/components/CustomTextInput'
import NewDocumentButton from 'shared/components/FormComponents/newdocumentbutton'
import Trash from 'assets/icons/trash'
import CustomModal from 'shared/components/CustomModal'
import { VehicleRow } from './styles'
import { Text } from '../../styles';

const VehicleRows = () => {

  const {
    showShouldUpdateTripCapacityModal,
    showConfirmRemoveVehicleModal,
    newVehicleAdded,
    services,
    maxCapacity,
    vehicles,
    outboundStops,
    inboundStops,
    removeVehicleID,
    hasEditPermission,
  } = useSelector((state: RootState) => state.TripPlanningsForm)
  const { fleetLongList } = useSelector((state: RootState) => state.FleetManagement)
  const {
    addVehicle,
    onVehicleLotationChange,
    onVehicleSelect,
    onDeleteVehicle,
    confirmRemoveVehicleModal,
    updateTripCapacity,
    closeConfirmRemoveVehicleModal,
    closeShouldUpdateTripCapacityModal,
  } = actions.TripPlanningsForm

  const dispatch = useDispatch()

  const handleAdd = (el) => {
    dispatch(addVehicle({ vehicles }))
  }

  return (
    <SectionContainer title={i18n.t('tripPlanningForm.vehicleTitle')} style={{ width: '100%', paddingBottom: '24px' }}>
      {vehicles && vehicles.length > 0 && vehicles.map((el: PlanningVehiclesForm, idx: number) => {
        return (
          <VehicleRow>
            <CCol sm={8}>
              <Dropdown
                disabled={!hasEditPermission}
                id={`tripPlanningForm-vehicle${idx}`}
                placeholder={i18n.t('placeholders.vessel')}
                options={vehiclesIntersectionFilter(fleetLongList, services, vehicles, el.uuid.value)}
                value={el.uuid.value}
                valid={el.uuid.valid}
                helpText={el.uuid.message}
                label={i18n.t('tripPlanningForm.vehicle')}
                onChange={(e) =>
                  dispatch(
                    onVehicleSelect({
                      vehicles: vehicles,
                      outboundStops: outboundStops,
                      inboundStops: inboundStops,
                      idx,
                      services: services,
                      vehicle: fleetLongList.find((obj: any) => obj.uuid === e.target.value),
                      maxCapacity: maxCapacity.value
                    })
                  )
                }
              />
            </CCol>
            <CCol sm>
              <CustomTextInput
                id={`tripPlanningForm-vehicle${idx}Capacity`}
                disabled
                valid={el.capacity.valid}
                value={el.capacity.value}
                helpText={el.capacity.message}
                placeholder={i18n.t('placeholders.lotation')}
                label={i18n.t('tripPlanningForm.lotation')}
                onChange={(e) => { dispatch(onVehicleLotationChange({ vehicles: vehicles, capacity: e.target.value, idx })) }}
              />
            </CCol>
            <CCol id={`tripPlanningForm-deleteVehicle${idx}Wrapper`} sm={1} style={{ display: "flex", justifyContent: "space-around", alignItems: "flex-start", marginTop: "35px" }}>
              <Trash id={`tripPlanningForm-deleteVehicle${idx}`} style={{ cursor: "pointer" }}
                onClick={(e) =>
                  hasEditPermission && dispatch(onDeleteVehicle({ vehicles: vehicles, vehiclesArrayIndex: idx, maxCapacity: maxCapacity.value }))
                } />

            </CCol>
            {
              Number(removeVehicleID) === idx && <CustomModal
                id={`tripPlanningForm-changedVehiclesCapacity`}
                warningType='message'
                warningName={i18n.t('common.warning')}
                visible={showConfirmRemoveVehicleModal}
                buttonIcon={true}
                footerPosition='space-between'
                textAlign='start'
                buttonName={i18n.t('common.confirm')}
                width={600}
                children={
                  <div>
                    <Text>{i18n.t('tripPlanningForm.deleteVehicleModal')}</Text>
                  </div>
                }
                onclose={e => dispatch(closeConfirmRemoveVehicleModal(false))}
                onsubmit={e => dispatch(confirmRemoveVehicleModal({ vehicles: vehicles, removeVehicleID }))} />
            }
            {
              Number(removeVehicleID) === idx && <CustomModal
                id={`triplanningForm-updateVehiclesCapacity`}
                warningType='message'
                warningName={i18n.t('common.warning')}
                visible={showShouldUpdateTripCapacityModal}
                buttonIcon={true}
                footerPosition='space-between'
                textAlign='start'
                buttonName={i18n.t('common.confirm')}
                width={600}
                children={
                  <div>
                    {
                      newVehicleAdded ?
                        <div>
                          <Text>{i18n.t('tripPlanningForm.newVehicleAdded1')} {vehicles[vehicles.length - 1].capacity.value} {i18n.t('tripPlanningForm.seats')}.</Text>
                          <Text>{i18n.t('tripPlanningForm.newVehicleAdded2')} {vehicles.map(v => Number(v.capacity.value)).reduce((a, b) => a + b, 0)}?</Text>
                        </div>
                        :
                        <Text>{i18n.t('tripPlanningForm.deletedVehicleModal')} {vehicles.filter((v, idx) => idx !== Number(removeVehicleID)).map(v => Number(v.capacity.value)).reduce((a, b) => a + b, 0)}?</Text>

                    }
                  </div>
                }
                onclose={e => dispatch(closeShouldUpdateTripCapacityModal(false))}
                onsubmit={e => {
                  dispatch(updateTripCapacity({ vehicles, newVehicleAdded: newVehicleAdded, idx }))
                }} />
            }
          </VehicleRow>
        )
      })
      }
      <NewDocumentButton id={'tripPlanningForm-newVehicleButton'} addComponent={() => handleAdd('el')} wrapperStyle={{ maxWidth: '100%' }} />
    </SectionContainer>
  )
}

export default VehicleRows