import styled from 'styled-components'
import { CButton } from '@coreui/react-pro'
import theme from 'ui/theme';

export const ExtraImageInfo = styled.p`
  color: ${theme().gray500Color}
`


export const SVGComponentWrapper = styled.div`
  > * {
    font-family: 'Inter';
  }

  > label {
    font-size: 14px;
    color: ${theme().darkColor};
    > span {
      color: ${theme().primary};
    }
  }

  .form-text {
    color: ${theme().danger};
  } 
`

export const SVGWrapper = styled.div`
  border: 1px solid ${theme().gray200Color};
  border-radius: 4px;
  height: 35px;
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;

  >  img {
    width: 100%;
    height: 35px;  
  }

  .colorize-filter { filter: brightness(0) saturate(100%) invert(51%) sepia(8%) saturate(988%) hue-rotate(179deg) brightness(82%) contrast(89%); }
`
export const SVGComponentDeleteWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Button = styled(CButton) <{ icon: boolean }>`
  background-color:${props => props.icon ? theme().white : theme().primary};
  border-color: ${props => props.icon ? theme().danger : theme().primary};
  color:${props => props.icon ? theme().danger : theme().white};
  width:${props => props.icon ? '75%' : '100%'};
  line-height:1.3;
  height:50%;
  align-self:center;
  box-shadow: 0 0 0 0;
  &:hover{
    color:${props => props.icon ? theme().white : theme().primary};
    background-color: ${props => props.icon ? theme().danger : theme().white};
    border-color:${props => props.icon ? theme().danger : theme().primary};
  }
  &:focus{
    box-shadow: 0 0 0 0;
    color:${props => props.icon ? theme().white : theme().white};
    background-color: ${props => props.icon ? theme().danger : theme().primary};
    border-color:${props => props.icon ? theme().white : theme().primary};
  }
`

export const ExtraInfo = styled.div`
  display:flex;
  justify-content: flex-start;
  column-gap: 8px;
`

export const Tooltip = styled.div`
  position: relative;
  visibility: visible;
  color: #fff;

  &:hover {
    > span {
      visibility: visible
    }
  }
`

export const CustomSpan = styled.span`
  visibility: hidden;
  width: max-content;
  max-width: 340px;
  display:flex;
  flex-wrap:wrap;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 105%;
  left: -20px;

`