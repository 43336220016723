/**
 * Custommultiselect component
 *
 * @author Stephane Ribeiro <sgribeiro@ubiwhere.com>
 *
 *
 */
import React, { useState } from 'react'
import { MultiSelect } from "react-multi-select-component";
import { CFormLabel } from '@coreui/react';
import { Wrapper, CustomHelpText } from './styles'

interface PropTypes {
  id: string,
  onChange: Function,
  disabled?: boolean,
  options: any[]
  label: string,
  placeholder: string,
  disabledSearch?: boolean,
  required?: boolean,
  valid?: boolean
  helpText?: string,
  value: any[],
  edited?: boolean,
  hasSelectAll?: boolean
}

const CustomMultiSelect = (props: PropTypes) => {
  const {
    id,
    onChange,
    disabled,
    options,
    label,
    placeholder,
    disabledSearch,
    required,
    valid,
    helpText,
    value,
    edited,
    hasSelectAll
  } = props

  const [selected, setSelected] = useState(value.map(grp => ({ label: grp.label, value: grp.value })))


  const handleChange = (e) => {
    setSelected(e)
    onChange(e)
  }

  return (
    <Wrapper
      id={id}
      isvalid={valid}
      edited={edited}
    >
      {label && <CFormLabel>{label}<span>{!!required ? "*" : ""}</span></CFormLabel>}
      <MultiSelect
        className='custom-multiSelect'
        disabled={disabled ? disabled : false}
        labelledBy={placeholder.toString()}
        value={selected}
        disableSearch={disabledSearch}
        hasSelectAll={hasSelectAll}
        onChange={e => handleChange(e)}
        options={
          options.map((grp) => {
            return {
              label: grp.name,
              value: grp.id
            }
          })
        }

      />
      {helpText && <CustomHelpText valid={valid}>{helpText}</CustomHelpText>}

    </Wrapper>
  )
}

export default CustomMultiSelect