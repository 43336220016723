/**
 * Entities scene slice
 *
 * @author João Dias <jdias@ubiwhere.com>
 *
 *
 */
import { createSlice } from '@reduxjs/toolkit'
import { SortType, ItemsList } from 'types/misc'
import { EntityListItem } from 'types/entity/entity';


type InitialState = {
  sort: SortType,
  page: number,
  total: number,
  entities: EntityListItem[],
  entityTypes: ItemsList,
  searchTerm: string,
  loading: boolean
}

const initialState: InitialState = {
  sort: {
    direction: null,
    field: null
  },
  page: 1,
  total: 0,
  entities: [],
  entityTypes: [{
    id: 0,
    name: "",
    uid: "",
    uuid: "",
    createdAt: "",
    updatedAt: "",
  }],
  searchTerm: '',
  loading: true,
}

export default createSlice({
  name: 'Entities',
  initialState,
  reducers: {
    onMount: (state, { payload }) => { },
    onUnmount: () => {
      //return initialState
    },
    setTotal: (state, { payload }) => {
      state.total = payload;
    },
    fetchEntities: () => { },
    setPage: (state, { payload }) => {
      state.page = payload;
    },
    setSort: (state, { payload }) => {
      state.sort = payload;
    },
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
    getEntityTypes: () => { },
    setEntityTypes: (state, { payload }) => {
      state.entityTypes = payload;
    },
    setEntities: (state, { payload }) => {
      state.entities = payload;
    },
    setSearchTerm: (state, { payload }) => {
      state.page = 1
      state.searchTerm = payload
    },
    getURLParams: (state, payload) => { },
    populateFiltersOnMount: (state, { payload }) => {
      state.searchTerm = payload.searchTerm
    }
  }
})