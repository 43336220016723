/**
 * Fleetconfigs scene
 *
 * @author João Dias <jdias@ubiwhere.com>
 *
 *
 */
import React, { useEffect } from 'react'
import {
  Wrapper,
  ControlsContainer,
} from 'shared/genericStyledComponents'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { actions, RootState } from 'store/rootSlices'
import TableContainer from 'shared/components/TableContainer'
import SearchBox from 'shared/components/SearchBox'
import CustomButton from 'shared/components/CustomButton'
import TabsContainer from 'shared/components/Tabscontainer'
import SorterComponent from 'shared/components/SorterComponent'
import { DocumentsType } from 'types/fleet/vehicle'
import Documentlisttablerow from './components/Documentlisttablerow'

import Feed from "assets/icons/feed";
import Locationstablerow from './components/Locationstablerow'
import Loadingcontainer from 'shared/components/Loadingcontainer'
import { paginationCurrentPage, paginationElementsCount } from 'utils'
import config from 'config'
import { hasPermission } from 'permissions'

//import config from 'config'

const Fleetconfigs = ({ t }) => {

  const [currentPage, setCurrentPage] = React.useState(t('fleetConfigs.documents'));

  const { sort, documentsPage, documentsTotal, loading, documentsList, locationsPage, locationsTotal, locationsList, searchTerm } = useSelector((state: RootState) => state.Fleetconfigs)

  const { onMount, onUnmount, setSearchTerm, setDocumentsPage, setLocationsPage, setSort } = actions.Fleetconfigs
  const { navigateTo } = actions.App
  const { permissions } = useSelector((state: RootState) => state.App)
  const { ELEMENTS_PER_PAGE, PERMISSIONS } = config
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    dispatch(onMount(history))
    return () => {
      dispatch(onUnmount())
    }
  }, [dispatch, onMount, onUnmount, history]);

  return (
    <Wrapper>
      <ControlsContainer>
        <div>
          <SearchBox
            id="fleetConfigs-searchBox"
            defaultValue={searchTerm}
            onSubmit={(term) => {
              let params = new URLSearchParams(history.location.search);
              term !== "" ? params.set('search', term) : params.delete('search')
              history.replace({ search: (params).toString() })
              dispatch(setSearchTerm(term))
            }}
          />
        </div>
        <div>
          {
            currentPage === t('fleetConfigs.documents') && hasPermission(permissions, PERMISSIONS.FLEET.CREATE_FLEET_SETTINGS) && <CustomButton
              id="fleetConfigs-newButton"
              onClick={() => { dispatch(navigateTo('/frota/configuracoes/novo-documento')) }}
              text={t('common.new')}
              icon="add"
              color="success"
              buttonStyle={{ color: "#FFFFFF" }} />
          }
          {
            currentPage !== t('fleetConfigs.documents') && hasPermission(permissions, PERMISSIONS.FLEET.CREATE_FLEET_SETTINGS) && <CustomButton
              id="fleetConfigs-newButton"
              onClick={() => { dispatch(navigateTo('/frota/configuracoes/novo-local-abastecimento')); }}
              text={t('common.new')}
              icon="add"
              color="success"
              buttonStyle={{ color: "#FFFFFF" }} />
          }
        </div>
      </ControlsContainer>
      {
        loading ?
          <Loadingcontainer />
          :
          <TabsContainer
            id="fleetConfigs-tabs"
            onChangeTab={t => {
              setCurrentPage(t)
              dispatch(setSort({ direction: null, field: null }))
            }}
            content={[
              {
                title: t('fleetConfigs.documents'),
                content: <TableContainer
                  count={paginationElementsCount(documentsTotal)}
                  currentPage={paginationCurrentPage(documentsTotal, documentsPage)}
                  elementsPerPage={ELEMENTS_PER_PAGE}
                  onPageChange={pg => {
                    dispatch(setDocumentsPage(pg));
                  }}
                  headerElements={[
                    <SorterComponent sort={sort} active={sort.field === "name"} id="fleetConfigs-documents-nameSorter" filterName={t('common.name')} onChange={(dir) => { dispatch(setSort({ field: "name", direction: dir })) }} />,
                    t('common.active'),
                    t('common.actions'),
                  ]}
                >
                  {loading ?
                    <td colSpan={3}><Loadingcontainer /></td>
                    :
                    documentsList.length > 0 && documentsList.map((el: DocumentsType, idx: number) =>
                      <React.Fragment key={`colab-list-${idx}`}>
                        <Documentlisttablerow
                          id={`fleetConfigs-documentsTableRow-${idx}`}
                          permission={hasPermission(permissions, PERMISSIONS.FLEET.EDIT_FLEET_SETTINGS)}
                          onClick={() => {
                            if (hasPermission(permissions, PERMISSIONS.FLEET.EDIT_FLEET_SETTINGS)) {
                              dispatch(navigateTo(`/frota/configuracoes/editar-documento/${el.id}`));
                            }
                          }}
                          document={el}
                          actions={
                            <React.Fragment>
                              <Feed />
                            </React.Fragment>
                          }
                        />
                      </React.Fragment>
                    )}
                </TableContainer>
              },
              {
                title: t('fleetConfigs.locations'),
                content: <TableContainer
                  count={locationsTotal}
                  elementsPerPage={15}
                  currentPage={locationsPage}
                  onPageChange={pg => {
                    dispatch(setLocationsPage(pg));
                  }}
                  headerElements={[
                    <SorterComponent sort={sort} active={sort.field === "name"} id="fleetConfigs-documents-nameSorter" filterName={t('common.name')} onChange={(dir) => { dispatch(setSort({ field: "name", direction: dir })) }} />,
                    t('common.active'),
                    t('common.actions'),
                  ]}
                >
                  {loading ?
                    <td colSpan={3}><Loadingcontainer /></td>
                    :
                    locationsList.length > 0 && locationsList.map((el: { id: number }, idx: number) =>
                      <React.Fragment key={`colab-list-${idx}`}>
                        <Locationstablerow
                          id={`fleetConfigs-documentsTableRow-${idx}`}
                          permission={hasPermission(permissions, PERMISSIONS.FLEET.VIEW_FUEL)}
                          onClick={() => {
                            if (hasPermission(permissions, PERMISSIONS.FLEET.VIEW_FUEL)) {
                              dispatch(navigateTo(`/frota/configuracoes/editar-local-abastecimento/${el.id}`))
                            }
                          }
                          }
                          location={el}
                          actions={
                            <React.Fragment>
                              <Feed />
                            </React.Fragment>
                          }
                        />
                      </React.Fragment>
                    )}
                </TableContainer>
              }
            ]}
          />
      }
    </Wrapper>
  )
}

export default Fleetconfigs