import {
  CTableBody, 
  CTableDataCell,
  CTableHeaderCell, 
  CTableRow,
} from '@coreui/react-pro'
import styled from 'styled-components'
import theme from 'ui/theme'

export const CustomTableHeaderCell = styled(CTableHeaderCell)`
  color: ${theme().darkColor};
  padding: 0px 0px 5px;
` 

export const CustomCTableBody = styled(CTableBody)<{expanded: boolean}>`
  display: ${props => props.expanded ? 'table-row-group' : ' none'};
`

export const CustomCTableRow = styled(CTableRow)`
  max-height: 54px;
  background-color: ${theme().white};
  border-left: 0px !important;

  > td {
    vertical-align: middle;
    font-family: Inter;
    font-size: 14px;
    border-top: 1px solid ${theme().gray200Color};
    color: ${theme().gray700Color}
  }

  td:first-child {
    border-left:0px;
    padding-left:15px;
  }

  td:last-child {
    border-right:0px;
  }
`

export const NoResultsCell = styled(CTableDataCell)`
  text-align: center;
  padding: 15px 15px;
  color: ${theme().gray500Color} !important;
`