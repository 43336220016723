/**
 * Tripform component
 *
 * @author Stephane Ribeiro <sgribeiro@ubiwhere.com>
 *
 *
 */

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions, RootState } from 'store/rootSlices';
//configs
import { country_codes } from 'shared/components/FormComponents/utils';
//Components
import { CCol } from '@coreui/react-pro';
import CustomTextInput from 'shared/components/CustomTextInput';
import FormComponent from 'shared/components/FormComponents';
import Dropdown from 'shared/components/FormComponents/dropdown';
import { Row } from '../../styles';

const TripForm = ({ t }) => {
  const { hasEditPermission, invoicePaymentForm, tripInfoForm } = useSelector(
    (state: RootState) => state.BookingForm
  );

  const { onTripInfoFormChange, onInvoicePaymentFormChange } = actions.BookingForm;

  const dispatch = useDispatch();

  const { countryCodes } = useSelector((state: RootState) => state.App);

  const langs = [
    { label: t('common.portuguese'), value: 'portuguese' },
    { label: t('common.english'), value: 'english' },
    { label: t('common.spanish'), value: 'spanish' },
    { label: t('common.french'), value: 'french' },
    { label: t('common.italian'), value: 'italian' },
    { label: t('common.german'), value: 'german' },
  ];

  return (
    <>
      <Row>
        <CCol sm={6}>
          <CustomTextInput
            required={true}
            id="bookingForm-tripInfoFormName"
            valid={tripInfoForm.customerName.valid}
            value={tripInfoForm.customerName.value}
            disabled={!hasEditPermission}
            helpText={tripInfoForm.customerName.message}
            placeholder={t('placeholders.name')}
            label={t('common.name')}
            onChange={(e) => {
              dispatch(
                onTripInfoFormChange({
                  ...tripInfoForm,
                  customerName: { value: e.target.value, message: '', valid: true },
                })
              );
            }}
          />
        </CCol>
        <CCol sm={6}>
          <CustomTextInput
            id="bookingForm-tripInfoFormNif"
            valid={tripInfoForm.nif.valid}
            value={tripInfoForm.nif.value}
            disabled={!hasEditPermission}
            helpText={tripInfoForm.nif.message}
            placeholder={t('placeholders.nif')}
            label={t('common.nif')}
            onChange={(e) => {
              dispatch(
                onTripInfoFormChange({
                  ...tripInfoForm,
                  nif: { value: e.target.value, message: '', valid: true },
                })
              );
              dispatch(
                onInvoicePaymentFormChange({
                  ...invoicePaymentForm,
                  nif: { value: e.target.value, message: '', valid: true },
                })
              );
            }}
          />
        </CCol>
      </Row>
      <Row>
        <CCol sm={6}>
          <CustomTextInput
            required={false}
            id="bookingForm-tripInfoFormEmail"
            valid={tripInfoForm.email.valid}
            value={tripInfoForm.email.value}
            disabled={!hasEditPermission}
            helpText={tripInfoForm.email.message}
            placeholder={t('placeholders.email')}
            label={t('common.email')}
            onChange={(e) => {
              dispatch(
                onTripInfoFormChange({
                  ...tripInfoForm,
                  email: { value: e.target.value, message: '', valid: true },
                })
              );
              dispatch(
                onInvoicePaymentFormChange({
                  ...invoicePaymentForm,
                  email: { value: e.target.value, message: '', valid: true },
                })
              );
            }}
          />
        </CCol>
        <CCol sm={6}>
          <FormComponent
            id="bookingForm-tripInfoFormPhone"
            valid={tripInfoForm.phone.valid}
            value={tripInfoForm.phone.value}
            helpText={tripInfoForm.phone.message}
            disabled={!hasEditPermission}
            dropdown={false}
            label={t('common.phone')}
            placeholder="Portugal"
            options={country_codes}
            onChange={(e) => {
              dispatch(
                onTripInfoFormChange({
                  ...tripInfoForm,
                  phone: { value: e, message: '', valid: true },
                })
              );
              dispatch(
                onInvoicePaymentFormChange({
                  ...invoicePaymentForm,
                  phone: { value: e, message: '', valid: true },
                })
              );
            }}
          />
        </CCol>
      </Row>
      <Row>
        <CCol sm={6}>
          <Dropdown
            id="bookingForm-tripInfoFormCountry"
            required={false}
            valid={tripInfoForm.country.valid}
            value={tripInfoForm.country.value}
            disabled={!hasEditPermission}
            helpText={tripInfoForm.country.message}
            placeholder={t('placeholders.country')}
            label={t('customerForm.country')}
            onChange={(e) => {
              dispatch(
                onTripInfoFormChange({
                  ...tripInfoForm,
                  country: { value: e.target.value, message: '', valid: true },
                })
              );
              dispatch(
                onInvoicePaymentFormChange({
                  ...invoicePaymentForm,
                  country: { value: e.target.value, message: '', valid: true },
                })
              );
            }}
            options={Object.keys(countryCodes).map((key) => {
              return {
                label: countryCodes[key],
                value: key,
              };
            })}
          />
        </CCol>
        <CCol sm={6}>
          <Dropdown
            id="bookingForm-tripInfoFormLanguage"
            disabled={!hasEditPermission}
            valid={tripInfoForm.lang.valid}
            value={tripInfoForm.lang.value}
            helpText={tripInfoForm.lang.message}
            placeholder={t('placeholders.language')}
            label={t('common.language')}
            onChange={(e) => {
              dispatch(
                onTripInfoFormChange({
                  ...tripInfoForm,
                  lang: { value: e.target.value, message: '', valid: true },
                })
              );
            }}
            options={langs}
          />
        </CCol>
      </Row>
      <Row>
        <CCol sm={12}>
          <CustomTextInput
            id="bookingForm-tripInfoFormObservations"
            valid={tripInfoForm.observations.valid}
            value={tripInfoForm.observations.value}
            disabled={!hasEditPermission}
            helpText={tripInfoForm.observations.message}
            placeholder={t('placeholders.observations')}
            label={t('common.observations')}
            onChange={(e) => {
              dispatch(
                onTripInfoFormChange({
                  ...tripInfoForm,
                  observations: { value: e.target.value, message: '', valid: true },
                })
              );
            }}
          />
        </CCol>
      </Row>
    </>
  );
};

export default TripForm;
