/**
 * Entityform scene
 *
 * @author João Dias <jdias@ubiwhere.com>
 *
 *
 */
import React, { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { actions, RootState } from 'store/rootSlices'
import { CCol, CForm, CRow } from '@coreui/react-pro'
import SectionContainer from 'shared/components/Sectioncontainer'
import CustomTextInput from 'shared/components/CustomTextInput'
import theme from 'ui/theme'
import { toast } from 'react-toastify'
import CustomButton from 'shared/components/CustomButton'
import Dropdown from 'shared/components/FormComponents/dropdown'
import CustomCheckbox from 'shared/components/CustomCheckbox'
import FormComponent from 'shared/components/FormComponents'
import { country_codes } from 'shared/components/FormComponents/utils'
import CustomRadioButton from 'shared/components/CustomRadioButton'
import Entitycommissiontablerow from 'scenes/Entities/components/Entitycommissiontablerow'
import SorterComponent from 'shared/components/SorterComponent'
import TabsContainer from 'shared/components/Tabscontainer'
import CustomModal from 'shared/components/CustomModal'
import Loadingcontainer from 'shared/components/Loadingcontainer'
import _ from 'lodash'
import EntityDocumentrow from 'shared/components/EntityDocumentrow'
import NewDocumentButton from 'shared/components/FormComponents/newdocumentbutton'
import Avataruploader from 'shared/components/Avataruploader'
import Business from 'assets/icons/business';
import config from 'config'
import { hasPermission } from 'permissions'
import { AvatarCol } from 'shared/genericStyledComponents'
import { Wrapper, ButtonWrapper, CustomCommissionTable, RadioButtonRow, Row, FirstRow, ReferralCool, QrCodeCool, AfiliateRow } from './styles'
import Copy from 'assets/icons/copy'

const Entityform = ({ t, i18n }) => {

  const { entityTypes } = useSelector((state: RootState) => state.Entities)
  const { isSidebarOpen } = useSelector((state: RootState) => state.Dashboard)
  const { countryCodes, permissions } = useSelector((state: RootState) => state.App)
  const { PERMISSIONS } = config
  const {
    entityForm,
    documentsForm,
    confirmAllModal,
    loading,
    entity,
    extrasCommissionForm,
    activitiesCommissionForm,
    isApplyAllOnActivities,
    edit,
    hasEditPermission,
    submitButtonState,
    baseUrl
  } = useSelector((state: RootState) => state.Entityform)
  const {
    onMount,
    onUnmount,
    onFormChange,
    onSubmit,
    setConfirmAllModal,
    onAddDocument,
    onRemoveEntityAvatar,
    setIsAvatarChanged,
    setIsAvatarRemoved,
    onActivityCommissionChange,
    onCommissionChangeToggler,
    onExtraCommissionChange,
    applyDefaultOnAllActivitiesComissions,
    applyDefaultOnAllExtrasComissions,
    changeDefaultCommissionValues,
    downloadQRCode
  } = actions.Entityform
  const { navigateBack } = actions.App

  const dispatch = useDispatch();
  const { id } = useParams()
  const dbDispatch = _.debounce(dispatch, 300);

  useEffect(() => {
    dispatch(onMount(id))
    return () => {
      dispatch(onUnmount())
    }
  }, [dispatch, onMount, onUnmount, id]);

  if (loading) {
    return <Wrapper><Loadingcontainer /></Wrapper>
  }
  
  return (
    <Wrapper>
      <CustomModal
        id={`entityForm-confirmAllModal`}
        warningType='message'
        visible={confirmAllModal}
        buttonIcon={true}
        footerPosition='center'
        textAlign='center'
        buttonName={i18n.t('common.confirm')}
        children={isApplyAllOnActivities ? <p>{i18n.t('entityForm.confirmAllModalActivities')}</p> : <p>{i18n.t('entityForm.confirmAllModalExtras')}</p>}
        onclose={e => dispatch(setConfirmAllModal({ confirmAll: false, isApplyAllOnActivities }))}
        onsubmit={e => {
          isApplyAllOnActivities === true ?
            dispatch(applyDefaultOnAllActivitiesComissions({ activitiesCommissionForm, defaultCommission: entityForm.activitiesCommissionDefault.value}))
            :
            dispatch(applyDefaultOnAllExtrasComissions({ extrasCommissionForm, defaultCommission: entityForm.extrasCommissionDefault.value}))
        }} />
      <CustomCheckbox
        id='entityForm-isPartnerActive'
        disabled={!hasEditPermission}
        wrapperStyle={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '-25px', fontSize: '20px' }}
        checked={entityForm.isActive.value ? true : false}
        onClick={e => dispatch(onFormChange({ ...entityForm, isActive: { value: !entityForm.isActive.value, valid: true, message: "" } }))}
        text={i18n.t('tripServiceForm.isActive')}
        switch
      />
      <CForm>
        <SectionContainer title={i18n.t('entityForm.title1')} style={{ width: '100%', paddingBottom: '24px' }}>
          <FirstRow>
            <AvatarCol sm={1}>
              <Avataruploader
                id='entityForm-avatar'
                hasEditPermission={hasEditPermission}
                qrCode={null}
                defaultAvatar={<Business height={60} width={60} />}
                value={
                  entity && entity.avatar ? entity.avatar
                    : entityForm.avatar.value ? URL.createObjectURL(entityForm.avatar.value) : ""
                }
                valid={entityForm.avatar.valid}
                helpText={entityForm.avatar.message}
                label={i18n.t('common.photo')}
                onChange={(e) => {
                  if (e === "") {
                    dispatch(onRemoveEntityAvatar({ ...entity, avatar: "" }));
                    dispatch(onFormChange({ ...entityForm, avatar: { value: "", message: "", valid: true } }));
                    dispatch(setIsAvatarRemoved(true))
                    dispatch(setIsAvatarChanged(false))
                  } else {
                    dispatch(onFormChange({ ...entityForm, avatar: { value: e, message: "", valid: true } }));
                    dispatch(setIsAvatarRemoved(false))
                    dispatch(setIsAvatarChanged(true))
                  }
                }}
              />
            </AvatarCol>
            <CCol>
              <CRow>
                <CustomTextInput
                  id='entityForm-name'
                  disabled={!hasEditPermission}
                  required
                  valid={entityForm.name.valid}
                  initialValue={entityForm.name.value || ""}
                  helpText={entityForm.name.message}
                  placeholder={i18n.t('placeholders.name')}
                  label={i18n.t('common.name')}
                  onChange={(e) => dbDispatch(onFormChange({ ...entityForm, name: { value: e.target.value, message: "", valid: true } }))} />

              </CRow>
              <Row>
                <CCol>
                  <CustomTextInput
                    id='entityForm-shortName'
                    disabled={!hasEditPermission}
                    required
                    valid={entityForm.shortName.valid}
                    initialValue={entityForm.shortName.value || ""}
                    helpText={entityForm.shortName.message}
                    placeholder={i18n.t('placeholders.shortName')}
                    label={i18n.t('entityForm.shortName')}
                    onChange={(e) => dbDispatch(onFormChange({ ...entityForm, shortName: { value: e.target.value, message: "", valid: true } }))} />

                </CCol>
                <CCol>
                  <Dropdown
                    id='entityForm-partnerType'
                    disabled={!hasEditPermission}
                    required
                    valid={entityForm.partnerType.valid}
                    value={entityForm.partnerType.value.toString()}
                    helpText={entityForm.partnerType.message}
                    placeholder={i18n.t('entityForm.agentPlaceholder')}
                    label={i18n.t('entityForm.type')}
                    onChange={(e) => dispatch(onFormChange({ ...entityForm, partnerType: { value: e.target.value, message: "", valid: true } }))}
                    options={entityTypes.map(el => {
                      return {
                        label: el.name,
                        value: el.id
                      }
                    })}
                  />
                </CCol>
              </Row>
            </CCol>
          </FirstRow>
          <Row className="g-3">
            <CCol sm={8}>
              <CustomTextInput
                id='entityForm-address'
                disabled={!hasEditPermission}
                valid={entityForm.address.valid}
                initialValue={entityForm.address.value || ""}
                helpText={entityForm.address.message}
                placeholder={i18n.t('placeholders.address')}
                label={i18n.t('entityForm.address')}
                onChange={(e) => {
                  dbDispatch(onFormChange({ ...entityForm, address: { value: e.target.value, message: "", valid: true } }));
                }} />
            </CCol>
            <CCol sm>
              <CustomTextInput
                id='entityForm-nif'
                disabled={!hasEditPermission}
                required
                valid={entityForm.nif.valid}
                initialValue={entityForm.nif.value || ""}
                helpText={entityForm.nif.message}
                placeholder={i18n.t('placeholders.nif')}
                label={i18n.t('common.nif')}
                onChange={(e) => dbDispatch(onFormChange({ ...entityForm, nif: { value: e.target.value, message: "", valid: true } }))} />
            </CCol>
          </Row>
          <Row className="g-3">
            <CCol sm>
              <Dropdown
                id='entityForm-country'
                disabled={!hasEditPermission}
                required={true}
                valid={entityForm.country.valid}
                value={entityForm.country.value || ""}
                helpText={entityForm.country.message}
                placeholder={i18n.t('placeholders.country')}
                label={i18n.t('entityForm.country')}
                onChange={(e) => dispatch(onFormChange({ ...entityForm, country: { value: e.target.value, message: "", valid: true } }))}
                options={Object.keys(countryCodes).map(key => {
                  return {
                    label: countryCodes[key],
                    value: key
                  }
                })}
              /></CCol>
            <CCol sm>
              <CustomTextInput
                id='entityForm-city'
                disabled={!hasEditPermission}
                required
                valid={entityForm.city.valid}
                initialValue={entityForm.city.value || ""}
                helpText={entityForm.city.message}
                placeholder={i18n.t('placeholders.city')}
                label={i18n.t('entityForm.city')}
                onChange={(e) => dbDispatch(onFormChange({ ...entityForm, city: { value: e.target.value, message: "", valid: true } }))} />
            </CCol>
            <CCol sm>
              <CustomTextInput
                id='entityForm-postalCode'
                disabled={!hasEditPermission}
                required
                valid={entityForm.postalCode.valid}
                initialValue={entityForm.postalCode.value || ""}
                helpText={entityForm.postalCode.message}
                placeholder={i18n.t('placeholders.postalCode')}
                label={i18n.t('entityForm.postalCode')}
                onChange={(e) => dbDispatch(onFormChange({ ...entityForm, postalCode: { value: e.target.value, message: "", valid: true } }))} />
            </CCol>
          </Row>
        </SectionContainer>
        <SectionContainer title={i18n.t('entityForm.title2')} style={{ width: '100%', paddingBottom: '24px' }}>
          <CRow>
            <CustomCheckbox
              id='entityForm-useSpecificTaxDetails'
              disabled={!hasEditPermission}
              checked={entityForm.useSpecificTaxDetails.value ? true : false}
              onClick={e => dispatch(onFormChange({ ...entityForm, useSpecificTaxDetails: { value: !entityForm.useSpecificTaxDetails.value, valid: true, message: "" } }))}
              text={i18n.t('entityForm.useSpecificTaxDetails')}
              switch
            />
          </CRow>
          <CRow>
            <CustomTextInput
              id='entityForm-taxName'
              required
              valid={entityForm.taxName.valid}
              initialValue={entityForm.taxName.value || ""}
              helpText={entityForm.taxName.message}
              disabled={!hasEditPermission || !entityForm.useSpecificTaxDetails.value}
              placeholder={i18n.t('placeholders.name')}
              label={i18n.t('common.name')}
              onChange={(e) => dbDispatch(onFormChange({ ...entityForm, taxName: { value: e.target.value, message: "", valid: true } }))} />
          </CRow>
          <Row className="g-3">
            <CCol sm={8}>
              <CustomTextInput
                id='entityForm-taxAddress'
                required
                valid={entityForm.taxAddress.valid}
                initialValue={entityForm.taxAddress.value || ""}
                helpText={entityForm.taxAddress.message}
                disabled={!hasEditPermission || !entityForm.useSpecificTaxDetails.value}
                placeholder={i18n.t('placeholders.address')}
                label={i18n.t('entityForm.address')}
                onChange={(e) => dbDispatch(onFormChange({ ...entityForm, taxAddress: { value: e.target.value, message: "", valid: true } }))} />
            </CCol>
            <CCol sm>
              <CustomTextInput
                id='entityForm-taxNif'
                required
                valid={entityForm.taxNif.valid}
                initialValue={entityForm.taxNif.value || ""}
                helpText={entityForm.taxNif.message}
                disabled={!hasEditPermission || !entityForm.useSpecificTaxDetails.value}
                placeholder={i18n.t('placeholders.nif')}
                label={i18n.t('common.nif')}
                onChange={(e) => dbDispatch(onFormChange({ ...entityForm, taxNif: { value: e.target.value, message: "", valid: true } }))} />
            </CCol>
          </Row>
          <Row className="g-3">
            <CCol sm>
              <Dropdown
                id='entityForm-taxCountry'
                required={true}
                valid={entityForm.taxCountry.valid}
                value={entityForm.taxCountry.value || ""}
                helpText={entityForm.taxCountry.message}
                disabled={!hasEditPermission || !entityForm.useSpecificTaxDetails.value}
                placeholder={i18n.t('placeholders.country')}
                label={i18n.t('entityForm.country')}
                onChange={(e) => dispatch(onFormChange({ ...entityForm, taxCountry: { value: e.target.value, message: "", valid: true } }))}
                options={Object.keys(countryCodes).map(key => {
                  return {
                    label: countryCodes[key],
                    value: key
                  }
                })}
              /></CCol>
            <CCol sm>
              <CustomTextInput
                id='entityForm-taxCity'
                required
                valid={entityForm.taxCity.valid}
                initialValue={entityForm.taxCity.value || ""}
                helpText={entityForm.taxCity.message}
                disabled={!hasEditPermission || !entityForm.useSpecificTaxDetails.value}
                placeholder={i18n.t('placeholders.city')}
                label={i18n.t('entityForm.city')}
                onChange={(e) => dbDispatch(onFormChange({ ...entityForm, taxCity: { value: e.target.value, message: "", valid: true } }))} />
            </CCol>
            <CCol sm>
              <CustomTextInput
                id='entityForm-taxPostalCode'
                required
                valid={entityForm.taxPostalCode.valid}
                initialValue={entityForm.taxPostalCode.value || ""}
                helpText={entityForm.taxPostalCode.message}
                disabled={!hasEditPermission || !entityForm.useSpecificTaxDetails.value}
                placeholder={i18n.t('placeholders.postalCode')}
                label={i18n.t('entityForm.postalCode')}
                onChange={(e) => dbDispatch(onFormChange({ ...entityForm, taxPostalCode: { value: e.target.value, message: "", valid: true } }))} />
            </CCol>
          </Row>
        </SectionContainer>

        <SectionContainer title={i18n.t('entityForm.conditions')} style={{ width: '100%', paddingBottom: '24px' }}>
          <CRow>
            <CustomTextInput
              id='entityForm-chargeConditions'
              disabled={!hasEditPermission}
              required
              type="textarea"
              valid={entityForm.chargeConditions.valid}
              initialValue={entityForm.chargeConditions.value || ""}
              helpText={entityForm.chargeConditions.message}
              label={i18n.t('entityForm.conditions')}
              onChange={(e) => dbDispatch(onFormChange({ ...entityForm, chargeConditions: { value: e.target.value, message: "", valid: true } }))} />

          </CRow>
        </SectionContainer>

        <SectionContainer title={i18n.t('fleetForm.title4')} style={{ width: '100%', paddingBottom: '24px' }}>
          {documentsForm && documentsForm.length > 0 && documentsForm.map((el, idx: number) => {
            return (
              <EntityDocumentrow hasEditPermission={hasEditPermission} document={el} id={`entityForm-document${idx}`} idx={idx} key={`Document${el.id}${idx}`} />
            )
          })
          }
          <NewDocumentButton id={`entityForm-addDocumentButton`} addComponent={() => dispatch(onAddDocument())} wrapperStyle={{ maxWidth: '100%' }} />

        </SectionContainer>

        <SectionContainer title={i18n.t('entityForm.title3')} style={{ width: '100%', paddingBottom: '24px' }}>
          <CRow>
            <CCol>
              <CustomTextInput
                id='entityForm-email'
                disabled={!hasEditPermission}
                required
                valid={entityForm.email.valid}
                initialValue={entityForm.email.value || ""}
                helpText={entityForm.email.message}
                label={i18n.t('common.email')}
                placeholder={i18n.t('placeholders.email')}
                onChange={(e) => dbDispatch(onFormChange({ ...entityForm, email: { value: e.target.value, message: "", valid: true } }))}
              />
            </CCol>
            <CCol>
              <FormComponent
                id='entityForm-phone'
                required
                disabled={!hasEditPermission}
                valid={entityForm.phone.valid}
                value={entityForm.phone.value || ""}
                helpText={entityForm.phone.message}
                dropdown={false}
                label={i18n.t('common.phone')}
                placeholder="Portugal"
                options={country_codes}
                onChange={(e) => dispatch(onFormChange({ ...entityForm, phone: { value: e, message: "", valid: true } }))}
              />
            </CCol>
          </CRow>
          <Row className="g-3">
            <CCol>
              <CustomTextInput
                id='entityForm-secondaryEmail'
                disabled={!hasEditPermission}
                valid={entityForm.secondaryEmail.valid}
                initialValue={entityForm.secondaryEmail.value || ""}
                helpText={entityForm.secondaryEmail.message}
                label={i18n.t('entityForm.email2')}
                placeholder={i18n.t('placeholders.email')}
                onChange={(e) => dbDispatch(onFormChange({ ...entityForm, secondaryEmail: { value: e.target.value, message: "", valid: true } }))}
              />
            </CCol>
            <CCol>
              <FormComponent
                id='entityForm-secondaryPhone'
                disabled={!hasEditPermission}
                valid={entityForm.secondaryPhone.valid}
                value={entityForm.secondaryPhone.value || ""}
                helpText={entityForm.secondaryPhone.message}
                dropdown={false}
                label={i18n.t('entityForm.phone2')}
                placeholder="Portugal"
                options={country_codes}
                onChange={(e) => dispatch(onFormChange({ ...entityForm, secondaryPhone: { value: e, message: "", valid: true } }))}
              />
            </CCol>
          </Row>
        </SectionContainer>
        <SectionContainer title={i18n.t('entityForm.title4')} style={{ width: '100%', paddingBottom: '24px' }}>
          <CRow>
            <CCol sm={8}>
              <RadioButtonRow id='entityForm-payementMethod'>
                <CustomRadioButton
                  value="full"
                  disabled={!hasEditPermission}
                  group='paymentMethod'
                  text={i18n.t('entityForm.opt1')}
                  checked={entityForm.customerPaymentMode.value === "full"}
                  onClick={e => dispatch(onFormChange({ ...entityForm, customerPaymentMode: { value: "full", valid: true, message: "" } }))}
                />
                <CustomRadioButton
                  value={"checkin"}
                  disabled={!hasEditPermission}
                  group='paymentMethod'
                  text={i18n.t('entityForm.opt2')}
                  checked={entityForm.customerPaymentMode.value === "checkin"}
                  onClick={e => dispatch(onFormChange({ ...entityForm, customerPaymentMode: { value: "checkin", valid: true, message: "" } }))}
                />
                <CustomRadioButton
                  value={"deposit"}
                  disabled={!hasEditPermission}
                  group='paymentMethod'
                  text={i18n.t('entityForm.opt3')}
                  checked={entityForm.customerPaymentMode.value === "deposit"}
                  onClick={e => dispatch(onFormChange({ ...entityForm, customerPaymentMode: { value: "deposit", valid: true, message: "" } }))}
                />
              </RadioButtonRow>
            </CCol>
          </CRow>
        </SectionContainer>
        {/* TODO - Fazer as alterações */}
        <SectionContainer title={i18n.t('entityForm.titleCommissions')} style={{ width: '100%', paddingBottom: '24px' }}>
          <CRow>
            <TabsContainer onChangeTab={() => { }} content={[
              {
                title: t('entityForm.activityCommissions'),
                content: <CustomCommissionTable key="customCommissionTable1"
                  headerElements={[
                    <SorterComponent id="entityForm-activityCommissions-nameSorter" filterName={t('entityForm.activities')} onChange={() => { }}></SorterComponent>,
                    <div>{t('entityForm.isCustom')}</div>,
                    <SorterComponent id="entityForm-activityCommissions-valueSorter" filterName={t('entityForm.commissionValue')} onChange={() => { }}></SorterComponent>,
                    <div />,
                    <div />
                  ]}
                  count={0}
                  currentPage={-1}
                  elementsPerPage={0}
                  onPageChange={() => { }}>
                  <Entitycommissiontablerow
                    id={`entityForm-activityCommissionDefault`}
                    hasEditPermission={hasEditPermission}
                    onClickAll={() => dispatch(setConfirmAllModal({ confirmAll: true, isApplyAllOnActivities: true }))}
                    onChange={(id, e) => {
                      dispatch(onFormChange({ ...entityForm, activitiesCommissionDefault: { value: e, valid: true, message: "" } }))
                      dispatch(changeDefaultCommissionValues({ isActivity: true, value: e }))
                    }}
                    isMain
                    commission={{ uuid: "0", name: "Comissão-padrão", commission: entityForm.activitiesCommissionDefault, isCustom: true }} 
                  />
                  {activitiesCommissionForm.map((act, idx) => {
                    return (
                      <Entitycommissiontablerow
                        id={`entityForm-activityCommission${idx}`}
                        hasEditPermission={hasEditPermission}
                        onChange={(id, e) => dispatch(onActivityCommissionChange({ idx, e }))}
                        onChangeToggler={(e) => dispatch(onCommissionChangeToggler({ idx, value: e, isActivity: true, default: entityForm.activitiesCommissionDefault.value }))}
                        commission={{ uuid: act.uuid, name: act.name, commission: act.commission, isCustom: act.isCustom }}></Entitycommissiontablerow>
                    )
                  })}
                </CustomCommissionTable>
              },
              {
                title: t('entityForm.extrasCommissions'),
                content: <CustomCommissionTable key="customCommissionTable2"
                  headerElements={[
                    <SorterComponent id="entityForm-extraCommissions-nameSorter" filterName={t('entityForm.extras')} onChange={() => { }}></SorterComponent>,
                    <div>{t('entityForm.isCustom')}</div>,                
                    <SorterComponent id="entityForm-extraCommissions-nameSorter" filterName={t('entityForm.commissionValue')} onChange={() => { }}></SorterComponent>,
                    <div />,
                    <div />
                  ]}
                  count={0}
                  currentPage={-1}
                  elementsPerPage={0}
                  onPageChange={() => { }}>
                  <Entitycommissiontablerow
                    id={`entityForm-extraCommissionDefault`}
                    hasEditPermission={hasEditPermission}
                    onClickAll={() => dispatch(setConfirmAllModal({ confirmAll: true, isApplyAllOnActivities: false }))}
                    onChange={(id, e) => {
                      dispatch(onFormChange({ ...entityForm, extrasCommissionDefault: { value: e, valid: true, message: "" } }))
                      dispatch(changeDefaultCommissionValues({ isActivity: false, value: e }))
                    }}
                    isMain
                    commission={{ uuid: "0", name: "Comissão-padrão", commission: entityForm.extrasCommissionDefault, isCustom: true }} />
                  {extrasCommissionForm.map((ext, idx) => {
                    return (
                      <Entitycommissiontablerow
                        id={`entityForm-extraCommission${idx}`}
                        hasEditPermission={hasEditPermission}
                        onChange={(id, e) => dispatch(onExtraCommissionChange({ idx, e }))}
                        onChangeToggler={(e) => dispatch(onCommissionChangeToggler({ idx, value: e, isActivity: false, default: entityForm.extrasCommissionDefault.value }))}
                        commission={{ uuid: ext.uuid, name: ext.name, commission: ext.commission, isCustom: ext.isCustom }}></Entitycommissiontablerow>
                    )
                  })}
                </CustomCommissionTable>
              }
            ]}
            />
          </CRow>
        </SectionContainer>
        
        <SectionContainer title={i18n.t('entityForm.title5')} style={{ width: '100%', paddingBottom: '24px' }}>
          <AfiliateRow isSidebarOpen={isSidebarOpen}>
            <ReferralCool isSidebarOpen={isSidebarOpen}>
              <CustomTextInput
                id='entityForm-url'
                disabled={!hasEditPermission}
                valid={entityForm.url.valid}
                initialValue={entityForm.url.value || ""}
                helpText={entityForm.url.message}
                icon={"customText"}
                blockedText={baseUrl}
                label={i18n.t('entityForm.url')}
                placeholder="slug"
                buttonIcon={
                  <Copy
                    onClick={() => {
                      navigator.clipboard.writeText(`${baseUrl}${entityForm.url.value}`)
                      toast.success(i18n.t("toasts.coppiedReferral"))
                    }} 
                  />
                }
                onChange={(e) => dbDispatch(onFormChange({ ...entityForm, url: { value: e.target.value, message: "", valid: true } }))}
              />
            </ReferralCool>
            <QrCodeCool isSidebarOpen={isSidebarOpen}>
              <CustomButton onClick={() => {
                dispatch(downloadQRCode(entity?.affiliate?.qrcode))
              }}
                id='entityForm-downloadQRCode'
                variant='outline'
                icon='download'
                text={i18n.t('entityForm.downloadQRCode')}></CustomButton>
            </QrCodeCool>
          </AfiliateRow>
        </SectionContainer>
        
        <SectionContainer title={i18n.t('entityForm.title6')} style={{ width: '100%', paddingBottom: '24px' }}>
          <CRow>
            <CCol>
              <CustomCheckbox
                checked={entityForm.printAmountInTicket.value ? true : false}
                disabled={!hasEditPermission}
                onClick={e => dispatch(onFormChange({ ...entityForm, printAmountInTicket: { value: !entityForm.printAmountInTicket.value, valid: true, message: "" } }))}
                id='entityForm-printValuesInTickets'
                text={i18n.t('entityForm.printValuesInTickets')}></CustomCheckbox>
            </CCol>
          </CRow>
        </SectionContainer>
        <SectionContainer title={i18n.t('entityForm.title7')} style={{ width: '100%', paddingBottom: '24px' }}>
          <CRow>
            <CCol>
              <CustomTextInput
                id='entityForm-observations'
                disabled={!hasEditPermission}
                valid={entityForm.observations.valid}
                initialValue={entityForm.observations.value || ""}
                helpText={entityForm.observations.message}
                label={i18n.t('entityForm.observations')}
                placeholder={i18n.t('placeholders.observations')}
                onChange={(e) => dbDispatch(onFormChange({ ...entityForm, observations: { value: e.target.value, message: "", valid: true } }))}
              />
            </CCol>
          </CRow>
        </SectionContainer>
        <ButtonWrapper>
          <CustomButton id='entityForm-cancel' onClick={() => dispatch(navigateBack())} text={i18n.t('common.cancelBtn')} color="link" buttonStyle={{ color: theme().danger, textDecoration: 'unset' }} />
          {
            edit ?
              hasPermission(permissions, PERMISSIONS.USERS.EDIT_PARTNER) &&
              <CustomButton
                id='entityForm-save'
                disabled={submitButtonState}
                onClick={() => dispatch(onSubmit())}
                text={i18n.t('common.saveBtn')}
                icon="confirm"
                color="success"
                buttonStyle={{ color: "#FFFFFF" }} />
              :
              hasPermission(permissions, PERMISSIONS.USERS.CREATE_PARTNER) &&
              <CustomButton
                id='entityForm-save'
                disabled={submitButtonState}
                onClick={() => dispatch(onSubmit())}
                text={i18n.t('common.saveBtn')}
                icon="confirm"
                color="success"
                buttonStyle={{ color: "#FFFFFF" }} />
          }
        </ButtonWrapper>
      </CForm>
    </Wrapper>
  )
}

export default Entityform