/**
 * Invoicedetails scene slice
 *
 * @author Stephane Ribeiro <sgribeiro@ubiwhere.com>
 *
 *
 */

import { createSlice } from '@reduxjs/toolkit'
import { camelizeKeys } from 'humps'
import { InvoiceDetailsForm, InvoiceDetail } from 'types/invoicing/invoices'

interface Entries {
  amount: string,
  description: string,
  id: number,
  isRefund: boolean,
  article: boolean
}

interface InitialState {
  loading: boolean,
  syncLoading: boolean,
  hasEditPermission: boolean,
  submitButtonState: boolean,
  showEditInvoiceModal: boolean,
  entries: Entries[],
  bookingUid: string,
  bookingUuid: string,
  invoice: InvoiceDetail,
  invoiceForm: InvoiceDetailsForm

}

const initialState: InitialState = {
  loading: true,
  syncLoading: false,
  hasEditPermission: false,
  submitButtonState: false,
  showEditInvoiceModal: false,
  entries: [],
  bookingUid: "",
  bookingUuid: "",
  invoice: {
    id: "",
    email: "",
    customerName: "",
    country: "",
    phone: "",
    vat: "",
    syncDate: "",
    syncId: null,
    syncPdf: "",
    syncStatus: "",
    syncUid: "",
    amount: ""
  },
  invoiceForm: {
    customerName: {
      value: "",
      valid: true,
      message: ""
    },
    email: {
      value: "",
      valid: true,
      message: ""
    },
    country: {
      value: "",
      valid: true,
      message: ""
    },
    phone: {
      value: "",
      valid: true,
      message: ""
    },
    vat: {
      value: "",
      valid: true,
      message: ""
    }
  }
}

export default createSlice({
  name: 'Invoicedetails',
  initialState,
  reducers: {
    onMount: (state, { payload }) => {},
    onUnmount: () => {
      return initialState;
    },
    setLoading: (state, { payload }) => {
      state.loading = payload
    },
    setSyncLoading: (state, { payload }) => { 
      state.syncLoading = payload
    },
    setHasEditPermission: (state, { payload }) => {
      state.hasEditPermission = payload
    },
    onFormChange: (state, { payload }) => {
      state.invoiceForm = payload
    },
    setBookingUid: (state, { payload }) => {
      state.bookingUid = payload
    },
    setBookingUuid: (state, { payload }) => {
      state.bookingUuid = payload
    },
    populateForm: (state, { payload }) => {
      const invoice = camelizeKeys(payload)
  
      const baseForm = { 
        ...initialState.invoiceForm, 
      }

      Object.keys(invoice).forEach(key => {
        if (key in baseForm) {
          baseForm[key] = {
            ...baseForm[key],
            value: invoice[key],
          }
        }
      })
      state.invoiceForm = baseForm;
    },
    onSubmit: (state, { payload }) => { },
    setSubmitButtonDisabled:(state, { payload }) => {
      state.submitButtonState = payload
    },
    toggleEditInvoiceModal: (state, { payload }) => {
      state.showEditInvoiceModal = payload
    },
    setEntries: (state, { payload }) => {
      state.entries = payload
    },
    setInvoice: (state, { payload }) => {
      const invoice = camelizeKeys(payload)

      let baseInvoice = { ...initialState.invoice }

      Object.keys(invoice).forEach(key => {
        if (key in baseInvoice) {
          baseInvoice[key] = invoice[key]
        }
      })

      state.invoice = baseInvoice;
    },
    checkIfModal: (state, { payload }) => {},
    cancelEditForm: (state, { payload }) => {
      const baseForm = { 
        ...initialState.invoiceForm, 
      }

      Object.keys(payload).forEach(key => {
        if (key in baseForm) {
          baseForm[key] = {
            ...baseForm[key],
            value: payload[key],
          }
        }
      })
      state.invoiceForm = baseForm;
      state.showEditInvoiceModal = false
    },
    syncInvoice: (state, { payload }) => {}
  }
})