/**
 * Planningstablecontainer component
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */

import React, { useRef } from 'react'
import Loadingcontainer from 'shared/components/Loadingcontainer';
import i18n from "i18next";
import { useDispatch, useSelector } from 'react-redux'
import { actions, RootState } from 'store/rootSlices'
import * as Styles from './styles'

type PropTypes = React.PropsWithChildren<{
  id?: string,
  className?: string,
  /* count: number,
  currentPage: number,
  elementsPerPage: number,
  onPageChange: Function, */
  bottomLoading?: boolean
}>

const PlanningsTableContainer = (props: PropTypes) => {
  const {
    fetchTrips,
    disableScroll,
  } = useSelector((state: RootState) => state.TripPlannings)
  const {
    setFetchTrips,
  } = actions.TripPlannings
  const dispatch = useDispatch()
  const listInnerRef = useRef<HTMLDivElement>(null);

  const handleScroll = (e) => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;

      if (scrollHeight + scrollTop >= clientHeight) {
        if (fetchTrips === false && disableScroll === false) {
          dispatch(setFetchTrips(true))
        }
      }
    }
  }

  //const numberOfPages = Math.ceil(props.count / props.elementsPerPage);

  return (
    <Styles.Wrapper ref={listInnerRef} onScroll={e => handleScroll(e)} id={`${props.id}-wrapper`} className={props.className}  >
      <Styles.Table id={`${props.id}-table`} borderless>
        <Styles.CustomCTableBody id={`${props.id}-tableBody`}>
          {
            props.children ?
              Array.isArray(props.children) && props.bottomLoading === false  ?
                props.children.length > 0  ?
                  props.children
                  :
                  <Styles.CustomCTableRow>
                    <Styles.NoResultsCell> {i18n.t('common.noResults')}</Styles.NoResultsCell>
                  </Styles.CustomCTableRow>
                :
                null : null
          }
        </Styles.CustomCTableBody>
        {
        props.bottomLoading && <Loadingcontainer />
        }
      </Styles.Table>
    </Styles.Wrapper>
  )
}

export default PlanningsTableContainer