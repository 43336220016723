import styled from 'styled-components'
import theme, { devicesMax } from 'ui/theme';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20vh;
`

const SubmitError = styled.h2`
  color:${theme().danger}
`

const Back = styled.div`
  display:flex;
  margin-bottom:0px !important;
  > h2 {
    cursor:pointer;
    margin-left:5px;
    font-weight:500;
    font-size: 14px;
  }

  > svg {
    cursor:pointer
  }
`

const LogoContainer = styled.div`
  width: 380px;
  height: 67px;
  margin-bottom: 73px;
  
  @media ${devicesMax.mobileL} {
    width: 100%;
    margin-bottom: 40px;
  }
`

const LoginForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 44px;

  font-family: 'Inter';
  color: ${theme().gray900Color};

  background: ${theme().white};
  border: 1px solid ${theme().gray200Color};
  box-sizing: border-box;
  border-radius: 4px;

  @media ${devicesMax.mobileM} {
   padding: 15px;
  }

  > * {
    width: 372px;
    margin-bottom: 20px;

    @media ${devicesMax.startTablet} {
      width: 100%;
    }
  }

  > h1 {
    font-size: 33px;
    line-height: 40px;
    margin-bottom: 8px;
  }

  > h2 {
    font-size: 14px;
    line-height: 17px;
  }

  > .login-buttonWrapper {
    @media ${devicesMax.startTablet} {
      flex-wrap: wrap;

      > * {
        width: 100%;
      }
    }
  }
  
  > div #login-proceedButton {
      @media ${devicesMax.startTablet} {
        width: 100%;
      }
  }
`

const BottomLogoContainer = styled.div`
  width: 150px;
  margin-top: 73px;
  text-align: center;

  > img {
    width: 100%;
  }
  
  @media ${devicesMax.mobileL} {
    margin-top: 40px;
  }
`

export {
  Wrapper,
  SubmitError,
  Back,
  LoginForm,
  LogoContainer,
  BottomLogoContainer
}