/**
 * Customradiobuttongroup compone nt
 *
 * @author João Dias <jdias@ubiwhere.com>
 *
 *
 */

import React from 'react'
import { Wrapper, ButtonGroup } from './styles'
import { HelpText } from 'shared/genericStyledComponents'

type PropTypes = React.PropsWithChildren<{
  title?: string,
  role?: string,
  valid?: boolean
  helpText?: string,
  required?: boolean
}>

const CustomRadioButtonGroup = (props: PropTypes) => {
  return (
    <Wrapper title={props.title ? props.title: undefined}>
      <h5>{props.title ? props.title : ''}<span>{!!props.required ? "*" : ""}</span></h5>
      <ButtonGroup role={props.role}>
        {props.children}
      </ButtonGroup>
      {props.helpText && <HelpText valid={props.valid}>{props.helpText}</HelpText>}
    </Wrapper>
  )
}

export default CustomRadioButtonGroup