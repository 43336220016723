import styled, { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
    body {
        font-family: "Inter";
    }
`
/*
    html {
        font-size: 10px;
    }
    body {
        font: var(--font-base);
    }

    .text-center {
        text-align: center;
    }

    :root {
        --font-stack-main: "Inter", serif;
        --font-base-size: 1.6rem;
        --base-line-height: 1.6;
        --font-base: var(--font-base-size)/var(--base-line-height) var(--font-stack-main);
    }
 * */

export const Text = styled.p`
  margin:0px;
`
