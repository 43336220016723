import * as React from "react"
import theme from "ui/theme";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg 
            width={17}
            height={17}
            viewBox="0 0 17 17" 
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path 
                d="M13.1248 9.16683H9.12484V13.1668H7.7915V9.16683H3.7915V7.8335H7.7915V3.8335H9.12484V7.8335H13.1248V9.16683Z" 
                fill={props.fill ? props.fill : theme().darkColor}
            />
        </svg>
    )
}

export default SvgComponent
