import { UserForm } from 'types/collaborators/collaborator'
import { ChangePasswordForm } from 'types/collaborators/userForm'
import * as Check from 'validations'

export const UserFormValidations = {
    avatar: [],
    name: [Check.isRequired],
    role: [Check.isRequired, Check.isBiggerThanZero],
    groups: [Check.isRequired, Check.isBiggerThanZero],
    birth: [Check.isRequired],
    address: [Check.isRequired],
    country: [Check.isRequired],
    postalCode: [Check.isRequired, Check.isValidPostalCode],
    city: [Check.isRequired],
    phone: [Check.isRequired],
    email: [Check.isRequired, Check.Email],
    qualifications: [Check.isRequired],
    emergencyContactName: [Check.isRequired],
    emergencyContactPhone: [Check.isRequired],
    contractHireDate: [Check.isRequired],
    contractEndDate: [],
    ccNumber: [],
    ccValidUntil: [],
    residencePermit: [],
    residencePermitUntil: [],
    nif: [],
    socialSecurity: [],
    maritalStatus: [Check.isRequired],
    numberOfDependents: [Check.isRequired, Check.isNumber],
    professionalCategory: [Check.isRequired],
    contractType: [Check.isRequired],
    contractBaseSalary: [Check.isRequired],
    contractHourlyRate: [Check.isRequired, Check.isNumber],
    contractWeeklyHours: [Check.isNumber],
    contractMonthlyHours: [Check.isNumber],
    contractMealAllowanceType: [Check.isRequired],
    contractMealAllowanceAmount: [Check.isRequired, Check.isNumber],
    contractHolidayPay: [Check.isRequired],
    contractOtherAllowances: [Check.isRequired],
    contractWeeklyDaysOff: [Check.isRequired],
    bankNumber: [],
    bankName: [],
    showInWebsite: [],
    isActive: [],
    hasBackofficeAccess: [Check.isRequired],
    activitiesCommissionDefault: [Check.isRequired, Check.isNumber],
    extrasCommissionDefault: [Check.isRequired, Check.isNumber],
    earnsCommissions: [],
    earnsDefaultCommissions: []
}

export const UserFormValidationsEdit = {
    avatar: [],
    name: [Check.isRequired],
    role: [Check.isRequired, Check.isBiggerThanZero],
    groups: [Check.isRequired, Check.isBiggerThanZero],
    birth: [Check.isRequired],
    address: [Check.isRequired],
    country: [Check.isRequired],
    postalCode: [Check.isRequired, Check.isValidPostalCode],
    city: [Check.isRequired],
    phone: [Check.isRequired],
    email: [Check.isRequired, Check.Email],
    qualifications: [Check.isRequired],
    emergencyContactName: [Check.isRequired],
    emergencyContactPhone: [Check.isRequired],
    contractHireDate: [],
    contractEndDate: [],
    ccNumber: [],
    ccValidUntil: [],
    residencePermit: [],
    residencePermitUntil: [],
    nif: [],
    socialSecurity: [],
    maritalStatus: [Check.isRequired],
    numberOfDependents: [Check.isRequired, Check.isNumber],
    professionalCategory: [],
    contractType: [],
    contractBaseSalary: [],
    contractHourlyRate: [Check.isNumber],
    contractWeeklyHours: [Check.isNumber],
    contractMonthlyHours: [Check.isNumber],
    contractMealAllowanceType: [],
    contractMealAllowanceAmount: [Check.isNumber],
    contractHolidayPay: [],
    contractOtherAllowances: [],
    contractWeeklyDaysOff: [],
    bankNumber: [],
    bankName: [],
    showInWebsite: [],
    isActive: [],
    hasBackofficeAccess: [Check.isRequired],
    activitiesCommissionDefault: [Check.isRequired, Check.isNumber],
    extrasCommissionDefault: [Check.isRequired, Check.isNumber],
    earnsCommissions: [],
    earnsDefaultCommissions: []
}

export const CredentialsFormValidations = {
    newPassword: [Check.isRequired],
    confirmPassword: [Check.isRequired],
}

export const defaultChangePasswordForm: ChangePasswordForm = {
    newPassword: {
        value: "",
        message: "",
        valid: true,
    },
    confirmPassword: {
        value: "",
        message: "",
        valid: true,
    }
}

export const defaultUserForm: UserForm = {
    hasBackofficeAccess: {
      value: true,
      message: "",
      valid: true,
    },
    role: {
      value: 0,
      message: "",
      valid: true,
    },
    roleName: {
      value: "",
      message: "",
      valid: true,
    },
    groups: {
      value: [],
      message: "",
      valid: true,
    },
    avatar: {
      value: null,
      message: "",
      valid: true,
    },
    address: {
      value: "",
      message: "",
      valid: true,
    },
    contractHireDate: {
      value: "",
      message: "",
      valid: true,
    },
    contractEndDate: {
      value: "",
      message: "",
      valid: true,
    },
    bankName: {
      value: "",
      message: "",
      valid: true,
    },
    contractBaseSalary: {
      value: "",
      message: "",
      valid: true,
    },
    birth: {
      value: "",
      message: "",
      valid: true,
    },
    ccValidUntil: {
      value: "",
      message: "",
      valid: true,
    },
    ccNumber: {
      value: "",
      message: "",
      valid: true,
    },
    city: {
      value: "",
      message: "",
      valid: true,
    },
    maritalStatus: {
      value: "",
      message: "",
      valid: true,
    },
    contractType: {
      value: "",
      message: "",
      valid: true,
    },
    contractDailyRate: {
      value: "",
      message: "",
      valid: true,
    },
    numberOfDependents: {
      value: "",
      message: "",
      valid: true,
    },
    qualifications: {
      value: "",
      message: "",
      valid: true,
    },
    email: {
      value: "",
      message: "",
      valid: true,
    },
    emergencyContactName: {
      value: "",
      message: "",
      valid: true,
    },
    emergencyContactPhone: {
      value: "",
      message: "",
      valid: true,
    },
    nif: {
      value: "",
      message: "",
      valid: true,
    },
    contractMealAllowanceType: {
      value: "",
      message: "",
      valid: true,
    },
    contractMealAllowanceAmount: {
      value: "",
      message: "",
      valid: true,
    },
    contractHolidayPay: {
      value: "",
      message: "",
      valid: true,
    },
    contractHourlyRate: {
      value: "",
      message: "",
      valid: true,
    },
    contractMonthlyHours: {
      value: "",
      message: "",
      valid: true,
    },
    contractWeeklyHours: {
      value: "",
      message: "",
      valid: true,
    },
    bankNumber: {
      value: "",
      message: "",
      valid: true,
    },
    name: {
      value: "",
      message: "",
      valid: true,
    },
    country: {
      value: "",
      message: "",
      valid: true,
    },
    contractOtherAllowances: {
      value: "",
      message: "",
      valid: true,
    },
    password: {
      value: "",
      message: "",
      valid: true,
    },
    phone: {
      value: "",
      message: "",
      valid: true,
    },
    postalCode: {
      value: "",
      message: "",
      valid: true,
    },
    professionalCategory: {
      value: "",
      message: "",
      valid: true,
    },
    residencePermitUntil: {
      value: "",
      message: "",
      valid: true,
    },
    residencePermit: {
      value: "",
      message: "",
      valid: true,
    },
    socialSecurity: {
      value: "",
      message: "",
      valid: true,
    },
    username: {
      value: "",
      message: "",
      valid: true,
    },
    contractWeeklyDaysOff: {
      value: null,
      message: "",
      valid: true,
    },
    showInWebsite: {
      value: false,
      message: "",
      valid: true,
    },
    isActive: {
      value: true,
      message: "",
      valid: true,
    },
    activitiesCommissionDefault: {
      value: "0",
      message: "",
      valid: true,
    },
    extrasCommissionDefault: {
      value: "0",
      message: "",
      valid: true,
    },
    earnsCommissions: {
      value: false,
      message: "",
      valid: true
    },
    earnsDefaultCommissions: {
      value: false,
      message: "",
      valid: true
    }
  }