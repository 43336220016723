import Auth from 'api/calls/auth'
import Collaborators from 'api/calls/collaborators'
import Fleet from 'api/calls/fleet'
import Translations from './calls/translations'
import Activities from './calls/TripConfigurations/activities'
import Services from './calls/TripConfigurations/services'
import Categories from './calls/TripConfigurations/categories'
import Circuits from './calls/TripConfigurations/circuits'
import Campaigns from './calls/TripConfigurations/campaigns'
import Customers from './calls/customers';
import Entities from './calls/entities';
import Extras from './calls/TripConfigurations/extras'
import PassengerTypes from './calls/TripConfigurations/passengertypes'
import Locals from './calls/TripConfigurations/locals'
import Plannings from './calls/plannings'
import Bookings from './calls/bookings'
import Checkins from './calls/checkins'
import Invoicing from './calls/invoicing'
import TripSettings from './calls/TripConfigurations/settings'
import Bi from './calls/TripConfigurations/bi'

export { default as ENDPOINTS } from './endpoints'

export default {
    Auth,
    Collaborators,
    Customers,
    Fleet,
    Translations,
    Activities,
    Services,
    Categories,
    Circuits,
    Campaigns,
    Extras,
    PassengerTypes,
    Locals,
    Entities,
    Plannings,
    Bookings,
    Checkins,
    Invoicing,
    TripSettings,
    Bi
}
