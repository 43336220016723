import styled from 'styled-components'
import { CRow } from '@coreui/react-pro'


export const Wrapper = styled.div``

export const Row = styled(CRow)`
    --cui-gutter-y:0rem;
    margin-top:26px;
`
export const FirstRow = styled(CRow)`
  column-gap: 15px;
`

export const ButtonWrapper = styled.div`
  display:flex;
  justify-content:flex-end;
  align-items: center;
  margin-top: 24px;

  > button:nth-child(2) {
    margin-left: 16px;
    height: 33px;
    line-height: 0;
  }
`

export const AccessButtonWrapper = styled.div`
  margin-top: 28px;

  > button:nth-child(1) {
    width: 100%;
  }
`