import * as React from "react";
import theme from "ui/theme";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M14.6667 8.00016L12 5.3335V7.3335H2V8.66683H12V10.6668L14.6667 8.00016Z"
                fill={props.fill ? props.fill : theme().darkColor}
            />
        </svg>
    )
}

export default SvgComponent
