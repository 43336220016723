import axios from 'axios'
import endpoints from 'api/endpoints'
import { generateSortString, generateUrl } from 'utils'
import { decamelizeKeys, camelizeKeys } from 'humps'
import { toast } from 'react-toastify'
import i18n from 'i18next'
import { SortType } from 'types/misc'


const GetPartnerTypes = () => {
    return axios.get(endpoints.ENTITIES.ENTITY_TYPE_URL)
        .then(res => res.data)
        .catch(err => { throw err })
}

const GetEntities = (page: number, searchTerm: string, sort: SortType) => {
    let endpoint = `${endpoints.ENTITIES.ENTITIES_URL}?page=${page}`;
    if (searchTerm && searchTerm !== "") {
        endpoint += `&search=${searchTerm}`;
    }
    if (sort.field !== null && sort.direction !== null && sort.direction !== "") {
        endpoint += generateSortString(sort);
    }
    return axios.get(endpoint)
        .then(res => res.data)
        .catch(err => { throw err })
}

const GetSingleEntity = (id) => {
    const endpoint = generateUrl(endpoints.ENTITIES.SINGLE_ENTITY_URL, { id })
    return axios.get(endpoint)
        .then(res => res.data)
        .catch(err => { throw err })
}

const CreateEntity = (body) => {
    const decMLZParams = decamelizeKeys(body)
    const token = window.localStorage.getItem("access_token")

    return axios.create({
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
        }
    }).post(endpoints.ENTITIES.ENTITIES_URL, decMLZParams)
        .then(res => {
            if (res.status === 201) toast.success(i18n.t('toasts.createEntitySuccess'));
            return res;
        }).catch(err => {
            const error: any = err
            if(error.response.data.errors.fields.affiliate) {
                toast.error(i18n.t('validations.referralUrlDuplicate'));
            } else {
                toast.error(i18n.t('toasts.editEntityError'));
            }
            throw err
        })
}

const SendAvatar = (id, params) => {

    const endpoint = generateUrl(endpoints.ENTITIES.SINGLE_ENTITY_URL, { id })
    return axios.patch(endpoint, params)
        .then(res => res.data)
        .catch(err => { throw err })

}

const EditEntity = (id, body) => {
    const decMLZParams = decamelizeKeys(body)
    const token = window.localStorage.getItem("access_token")
    const endpoint = generateUrl(endpoints.ENTITIES.SINGLE_ENTITY_URL, { id })

    return axios.create({
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
        }
    }).patch(endpoint, decMLZParams)
        .then(res => {
            toast.success(i18n.t('toasts.editEntitySuccess'));
            return res;
        }).catch(err => {
            const error: any = err
            if(error.response.data.errors.fields.affiliate) {
                toast.error(i18n.t('validations.referralUrlDuplicate'));
            } else {
                toast.error(i18n.t('toasts.editEntityError'));
            }
            throw err;
        });
}

const DeleteEntity = (id) => {
    const endpoint = generateUrl(endpoints.ENTITIES.SINGLE_ENTITY_URL, { id })
    return axios.delete(endpoint)
        .then(res => {
            toast.info(i18n.t('toasts.deleteEntitySuccess'));
            return res.data;
        }).catch(err => {
            toast.error(i18n.t('toasts.deleteEntityError'));
            throw err;
        })
}

const GetEntityMembers = (entity, page: number, searchTerm: string, sort: SortType) => {
    let endpoint = `${endpoints.ENTITIES.MEMBERS_URL}?partner=${entity}&page=${page}`;
    if (searchTerm && searchTerm !== "") {
        endpoint += `&search=${searchTerm}`;
    }
    if (sort.field !== null && sort.direction !== null && sort.direction !== "") {
        endpoint += generateSortString(sort);
    }

    return axios.get(endpoint)
        .then(res => res.data)
        .catch(err => { throw err })
}

const GetSingleEntityMember = (id) => {
    const endpoint = generateUrl(endpoints.ENTITIES.SINGLE_MEMBER_URL, { id })
    return axios.get(endpoint)
        .then(res => res.data)
        .catch(err => { throw err })
}

const CreateEntityMember = (body) => {
    const token = window.localStorage.getItem('access_token')

    return axios.create({
            headers:{
                'Authorization': 'Bearer ' + token, 
                'Content-Type': 'application/json',
            }
        }).post(endpoints.ENTITIES.MEMBERS_URL, body)
            .then(res => {
                return camelizeKeys(res)
            })
            .catch(err => {
                throw err 
            })
}

const EditEntityMember = (id, body) => {
    const token = window.localStorage.getItem('access_token')
    const endpoint = generateUrl(endpoints.ENTITIES.SINGLE_MEMBER_URL, { id })

    return axios.create({
        headers:{
            'Authorization': 'Bearer ' + token, 
            'Content-Type': 'application/json',
        }
    }).patch(endpoint, body)
        .then(res => {
            return camelizeKeys(res.data)
        })
        .catch(err => {
            throw err 
        })
}


const CreateDocument = (body) => {
    const formData = new FormData();
    formData.append("partner", body.entity);
    formData.append("expiration", body.expiration);
    formData.append("file", body.file.value);

    return axios.post(endpoints.ENTITIES.ENTITY_DOCUMENTS_URL, formData)
        .then(res => {
            toast.success(i18n.t("toasts.documentCreateSuccess"));
            return res;
        })
        .catch(err => {
            toast.error(i18n.t("toasts.documentCreateError"));
        })
}

const EditDocument = (body, id) => {
    const formData = new FormData();
    formData.append("partner", body.entity);
    formData.append("expiration", body.expiration);

    const endpoint = generateUrl(endpoints.ENTITIES.SINGLE_ENTITY_DOCUMENT_URL, { id });

    return axios.patch(endpoint, formData)
        .then(res => {
            toast.success(i18n.t("toasts.documentEditSuccess"));
            return res;
        })
        .catch(err => {
            toast.error(i18n.t("toasts.documentEditError"));
        })
}

const DeleteDocument = (id) => {
    const endpoint = generateUrl(endpoints.ENTITIES.SINGLE_ENTITY_DOCUMENT_URL, { id });

    return axios.delete(endpoint)
        .then(res => {
            toast.info(i18n.t("toasts.documentDeleteSuccess"));
            return res;
        })
        .catch(err => {
            toast.error(i18n.t("toasts.documentDeleteError"));
        })
}

export default {
    CreateEntity,
    DeleteEntity,
    EditEntity,
    GetEntities,
    GetPartnerTypes,
    GetSingleEntity,
    GetEntityMembers,
    GetSingleEntityMember,
    CreateEntityMember,
    EditEntityMember,
    CreateDocument,
    EditDocument,
    DeleteDocument,
    SendAvatar
}