import axios from 'axios'
import endpoints from 'api/endpoints'
import { decamelizeKeys  } from 'humps'
import { generateUrl, generateSortString } from 'utils'
import { CampaignFormValidationsEdit } from 'scenes/TripForms/Tripcampaignform/utils'
import { CampaignCodeFormValidations, CampaignCodeFormValidationsEdit } from 'scenes/TripForms/Tripcampaigncodesform/utils'
import { SortType } from 'types/misc'

/* import { generateUrl } from 'utils'
import { toast } from 'react-toastify'
import i18n from 'i18next' */

const GetCampaigns = (page: number, searchTerm: string, sort?: SortType) => {
    const searchTermQS = `${searchTerm ? `&search=${searchTerm}` : ''}`;

    let endpoint = `${endpoints.TRIPS.CAMPAIGNS.GET_CAMPAIGNS}?page=${page}${searchTermQS}`;
    
    if (sort && sort.field !== null && sort.direction !== null && sort.direction !== "") {
        endpoint += generateSortString(sort);
    }
    
    return axios.get(endpoint)
        .then(res => res.data)
        .catch(err => { throw err })
}

const GetAllCampaigns = () => {
    return axios.get(endpoints.TRIPS.CAMPAIGNS.GET_ALL_CAMPAIGNS)
        .then(res => res.data)
        .catch(err => { throw err })
}

const GetCampaignCodes = (id) => {
    const endpoint = generateUrl(endpoints.TRIPS.CAMPAIGNS.GET_CAMPAIGN_CODES, { id })
    return axios.get(endpoint)
        .then(res => res.data)
        .catch(err => { throw err })
}

const GetCampaignCode = (id) => {
    const endpoint = generateUrl(endpoints.TRIPS.CAMPAIGNS.GET_CAMPAIGN_CODE, { id })
    return axios.get(endpoint)
        .then(res => res.data)
        .catch(err => { throw err })
}

const GetCampaign = (id) => {
    const endpoint = generateUrl(endpoints.TRIPS.CAMPAIGNS.GET_CAMPAIGN, { id })
    return axios.get(endpoint)
        .then(res => res.data)
        .catch(err => { throw err })
}

const CreateCampaign = (params) => {
    const token = window.localStorage.getItem('access_token')

    const finalParams = {
        ...params,
        codes:[],
    } 

    const decMLZParams = decamelizeKeys(finalParams)

    return axios.create({
        headers:{
            'Authorization': 'Bearer ' + token, 
            'Content-Type': 'application/json',
        }
    }).post(endpoints.TRIPS.CAMPAIGNS.CREATE_CAMPAIGN, decMLZParams)
        .then(res => res.data)
        .catch(err => {throw err})
}

const CreateCampaignCode = (id,params) => {
    const decMLZParams = decamelizeKeys(params)
    
    const campaignCodeFormData = new FormData();
    campaignCodeFormData.append('campaign', id)
    Object.keys(decamelizeKeys(CampaignCodeFormValidations)).forEach(valKey => {
        //console.log(valKey, decMLZParams[valKey].value);
        campaignCodeFormData.append(valKey, decMLZParams[valKey].value)
    })
    return axios.post(endpoints.TRIPS.CAMPAIGNS.CREATE_CAMPAIGN_CODE, campaignCodeFormData)
        .then(res => res.data)
        .catch(err => { throw err }) 
}


const EditCampaign = (id, params) => {
    const decMLZParams = decamelizeKeys(params)
    const endpoint = generateUrl(endpoints.TRIPS.CAMPAIGNS.EDIT_CAMPAIGN, { id })
    
    const campaignFormData = new FormData();
    Object.keys(decamelizeKeys(CampaignFormValidationsEdit)).forEach(valKey => {
        campaignFormData.append(valKey, decMLZParams[valKey].value)     
    })
    
    return axios.patch(endpoint, campaignFormData)
        .then(res => res.data)
        .catch(err => { throw err })  
}

const EditCampaignCode = (id, params) => {
    const decMLZParams = decamelizeKeys(params)
    const endpoint = generateUrl(endpoints.TRIPS.CAMPAIGNS.UPDATE_CAMPAIGN_CODE, { id })

    const campaignCodeFormData = new FormData();
    Object.keys(decamelizeKeys(CampaignCodeFormValidationsEdit)).forEach(valKey => {
        campaignCodeFormData.append(valKey, decMLZParams[valKey].value)     
    })
    
    return axios.patch(endpoint, campaignCodeFormData)
        .then(res => res.data)
        .catch(err => { throw err })  
}

export default {
    GetAllCampaigns,
    GetCampaigns,
    GetCampaign,
    GetCampaignCodes,
    GetCampaignCode,
    CreateCampaign,
    CreateCampaignCode,
    EditCampaign,
    EditCampaignCode
}