import { FormField, ItemType } from "types/misc"

export type StatementItem = {
    amount: string,
    date: string,
    description: string,
    hasReceipt: boolean,
    id: number,
    isExpense: boolean,
    isRefund: boolean,
    relatedTo: string,
    supplier: any, //Alterar
    balanceEntryType: {
        id: number,
        name: string,
        uid: string,
        showInAdd: boolean,
    },
    booking: {
        id: number,
        uid: string,
        uuid: string,
        isCanceled: boolean,
        customer: {
            country: string,
            createdAt: string,
            email: string,
            id: number,
            lang: string,
            name: string,
            nif: string,
            updatedAt: string,
            uuid: string,
        },
    },
    paymentType: ItemType,
    processedBy: {
        createdAt: string,
        updatedAt: string,
        uuid: string,
        id: number,
        name: string,
        groups: ItemType[],
    },
    source: {
        allowPartner: boolean,
        createdAt: string,
        id: number,
        name: string,
        uid: string,
        updatedAt: string,
        uuid: string,
    },
}

export type Statement = {
    id: number,
    date: string,
    teamMember: any, //check users types
    isClosed: boolean,
    isApproved: boolean,
    approvedBy: any // check users types
    cashFund: string,
    bankDeposit: string,
    multibancoSales: string,
    notDeposited: string,
    estimated: string,
    income: string,
    expenses: string,
    registeredIncome: string,
    total: string,
    balance: string,
}

export type ApprovedStatement = string

export type DisapprovedStatement = string

export type Entry = {
    id: number,
    relatedTo: string,
    entryType: any,
    description: string,
    date: string,
    amount: string,
    isRefund: boolean,
    isExpense: boolean,
    paymentType: any,
    processedBy: any,
    hasReceipt: boolean
}

export type StatementForm = {
    cashFund: FormField<string>,
    income: FormField<string>,
    expenses: FormField<string>,
    bankDeposit: FormField<string>,
    multibancoSales: FormField<string>,
    notDeposited: FormField<string>,
    estimated: FormField<string>,
}

export type EntryForm = {
    amount: FormField<string>,
    description: FormField<string>,
    balanceEntryType: FormField<string>,
    paymentType: FormField<string>,
    processedBy: FormField<string>,
    supplier: FormField<string>,
    date: FormField<string>,
}

export type EditInvoiceForm = {
    email: any;
    phone: any;
    vat: any;
    customerName: any;
    country: any;
}

export enum StatementStatus { "OPEN", "CLOSED" }
export enum StatementsTab { "PENDING", "APPROVED" }