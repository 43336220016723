import styled from 'styled-components'
import { CSidebarBrand } from '@coreui/react-pro'
import theme, { devicesMax } from 'ui/theme'

const CustomBrand = styled(CSidebarBrand)`
  max-height: 56px;
  overflow: hidden;

  > svg {
    min-width: 170px;
  }

  @media ${devicesMax.tablet}{

    justify-content: flex-start;
     padding-left: 15px;    
  }
`

const LogoWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 1rem;

  > img {
    width: 50%;
  }

  > p {
    color: ${theme().gray300Color};
  }
`

const Wrapper = styled.div <{ open?: boolean }>`
  max-width: 268px;

  > * {
    overflow: hidden;
  }

  .sidebar {
    height: 100vh;
  }

  .sidebar-nav {
    max-height: 95%;
    -ms-overflow-style: none; 
    scrollbar-width: none;

    ::-webkit-scrollbar {
      display: none;
    }
  }

  .simplebar-content-wrapper {
    overflow: hidden scroll !important;
  }

  .simplebar-content {
    overflow: hidden;
  }

  .sidebar.sidebar-fixed {
    position: initial;
  }
  
  > * {
    background-color: ${theme().gray900Color} !important;
  }
  
  li {
    margin-bottom: 4px !important;
  }
`

export {
  Wrapper,
  CustomBrand,
  LogoWrapper
}