import production from './production'
import development from './development'
import local from './local'
import { permissions } from 'permissions';

const config = process.env.REACT_APP_STAGE === 'production'
  ? production
  : process.env.REACT_APP_STAGE === 'local' ? local : development;

export default {
  // Add common config values here
  COMMON_CONFIG: 'DEFAULT_COMMON_CONFIG',
  ROLES: {
    GUEST: 'guest',
    TEAM_MEMBER: 'team_member',
    PARTNER: 'partner',
    CUSTOMER: 'customer',
  },
  DATE_FORMAT: {
      DATETIME: 'dd/LL/yyyy HH:mm',
      DATE: 'dd/LL/yyyy',
      TIME: 'HH:mm',
  },
  ELEMENTS_PER_PAGE: 12,
  PERMISSIONS: permissions,
  ...process.env,
  ...config
};
