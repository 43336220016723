/**
 * Maintenanceform scene slice
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { ActivityCircuitForm, ActivityExtrasForm, ActivityPhotoForm, ActivityVehicleForm } from 'types/trips/activity/activityForm'
import { camelizeKeys } from 'humps'
import { initialState } from './initialState'


export default createSlice({
    name: 'tripactivityform',
    initialState,
    reducers: {
        onMount: (state, { payload }) => { },
        onUnmount: () => {
            return initialState;
        },
        setAction: (state, { payload }) => {
            state.edit = payload
        },
        setActivityID: (state, { payload }) => {
            state.activityID = payload
        },
        setVehiclesInfo: (state, { payload }) => {
            const vehicles: ActivityVehicleForm[] = Array.from([])

            payload.forEach((vehicle) => {

                vehicles.push({
                    id: vehicle.id,
                    uuid: vehicle.uuid,
                    name: vehicle.name,
                    capacity: vehicle.capacity,
                    shortName: vehicle.shortName,
                    isAvailable: vehicle.isAvailable,
                    isOn: { value: true, valid: true, message: "" }
                })
            })
            state.vehicles = vehicles
        },
        setTaxes: (state, { payload }) => {
            state.taxes = payload
        },
        setHasEditPermission: (state, { payload }) => {
            state.hasEditPermission = payload
        },
        setSubmitButtonDisabled: (state, { payload }) => {
            state.submitButtonState = payload
        },
        setExtrasInfo: (state, { payload }) => {
            const extras: ActivityExtrasForm[] = Array.from([])

            payload.forEach((extra) => {
                extras.push({
                    id: extra.id,
                    name: extra.name,
                    extraMaxCapacity: String(extra.maxCapacity),
                    maxCapacity: { value: String(extra.maxCapacity), valid: true, message: "" },
                    price: { value: String(extra.price), valid: true, message: "" },
                    isActive: { value: extra.isActive, valid: true, message: "" },
                    isOn: { value: extra.isActive && extra.maxCapacity > 0 ? true : false, valid: true, message: "" }
                })
            })

            state.extras = extras
        },
        setCircuitsInfo: (state, { payload }) => {
            const circuits: ActivityCircuitForm[] = Array.from([])

            payload.forEach((circuit) => {
                circuits.push({
                    id: circuit.id,
                    name: circuit.name,
                    duration: circuit.duration,
                    isAvailable: circuit.isActive,
                    isOn: { value: true, valid: true, message: "" }
                })
            })
            state.circuits = circuits
        },
        setPassengerTypesInfo: (state, { payload }) => {
            const passengerTypes: any[] = Array.from([])

            payload.forEach((passengerType) => {

                passengerTypes.push({
                    id: passengerType.id,
                    name: passengerType.name,
                    shortName: passengerType.shortName,
                    price: { value: "", valid: true, message: "" },
                    priceForIsland: { value: "", valid: true, message: "" },
                    isActive: passengerType.isActive,
                    isOn: { value: false, valid: true, message: "" }
                })
            })
            state.passengerTypes = passengerTypes
        },
        populateActivityInfo: (state, { payload }) => {

            const activity = camelizeKeys(payload.activityInfo)
            const isBeachCategory = payload.allCategories.find(cat => cat.id === activity.category.id)?.isBeach ?? false
            const baseForm = { ...initialState.activityForm }

            Object.keys(activity).forEach(key => {
                if (key in baseForm) {

                    if (key === "tax") {
                        baseForm[key] = {
                            ...baseForm[key],
                            value: activity[key]['id'],
                        }
                    }
                    else if (key === 'category') {
                        baseForm[key] = {
                            ...baseForm[key],
                            value: activity[key]['id'],
                        }
                    }
                    else {
                        baseForm[key] = {
                            ...baseForm[key],
                            value: activity[key],
                        }
                    }
                }
            })

            //we must check if the activity has a category with rule 'is Beach' in order to correctly show the form
            state.hasBeachRule = activity.isBeach || isBeachCategory
            state.activityForm = baseForm;
        },
        populateVehiclesInfo: (state, { payload }) => {

            //const category = TripCategoriesMock[0]
            const allVehicles = camelizeKeys(payload.allVehicles)
            const activityVehicles = camelizeKeys(payload.activityVehicles)

            const updatedArray = allVehicles.map((vehicle) => {
                const vhcl: ActivityVehicleForm[] = Array.from([])

                activityVehicles.forEach((e) => {

                    if (e.name === vehicle.name) {
                        vhcl.push({
                            uuid: vehicle.uuid,
                            capacity: vehicle.capacity,
                            shortName: vehicle.shortName,
                            id: vehicle.id,
                            name: vehicle.name,
                            isAvailable: vehicle.isAvailable,
                            isOn: { value: true, valid: true, message: "" }
                        })
                    }
                })

                if (vhcl.length > 0) {
                    return vhcl[0]
                } else {

                    return {
                        ...vehicle,
                        isOn: { value: false, valid: true, message: "" }
                    }
                }
            })
            state.vehicles = updatedArray
        },
        populateExtrasInfo: (state, { payload }) => {
            const allExtras = camelizeKeys(payload.allExtras)
            const activityExtras = camelizeKeys(payload.activityExtras)

            const updatedArray = allExtras.map((extra) => {
                const ext: ActivityExtrasForm[] = Array.from([])

                activityExtras.forEach((e) => {

                    if (e.id === extra.id) {
                        ext.push({
                            id: e.id,
                            extraMaxCapacity: extra.extraMaxCapacity,
                            name: e.name,
                            maxCapacity: { value: String(e.maxCapacity) ?? '0', valid: true, message: "" },
                            price: { value: e.price, valid: true, message: "" },
                            isActive: { value: e.isActive, valid: true, message: "" },
                            isOn: { value: true, valid: true, message: "" }
                        })
                    }
                })

                if (ext.length > 0) {
                    return ext[0]
                } else {
                    return {
                        ...extra,
                        isOn: { value: false, valid: true, message: "" }
                    }
                }
            })
            state.extras = updatedArray
        },
        populateCircuitsInfo: (state, { payload }) => {
            const allCircuits = camelizeKeys(payload.allCircuits)
            const activityCircuits = camelizeKeys(payload.activityCircuits)

            const updatedArray = allCircuits.map((circuit) => {
                const cr: ActivityCircuitForm[] = Array.from([])

                activityCircuits.forEach((c) => {
                    if (c.id === circuit.id) {
                        cr.push({
                            id: c.id,
                            name: c.name,
                            duration: c.duration,
                            isAvailable: c.isActive,
                            isOn: { value: true, valid: true, message: "" }
                        })
                    }
                })

                if (cr.length > 0) {
                    return cr[0]
                } else {
                    return {
                        ...circuit,
                        isOn: { value: false, valid: true, message: "" }
                    }
                }
            })
            state.circuits = updatedArray

        },
        populatePassengerTypesInfo: (state, { payload }) => {
            const allPassengerTypes = camelizeKeys(payload.allPassengerTypes)
            const activityPassengerTypes = camelizeKeys(payload.activityPassengerTypes)

            const updatedArray = allPassengerTypes.map((passengerType) => {
                const pt: any[] = Array.from([])

                activityPassengerTypes.forEach((p) => {
                    if (p.id === passengerType.id) {
                        pt.push({
                            id: p.id,
                            name: p.name,
                            shortName: "",
                            priceForIsland: { value: p.priceForIsland, valid: true, message: "" },
                            price: { value: p.price, valid: true, message: "" },
                            isActive: passengerType.isActive,
                            isOn: { value: passengerType.isActive, valid: true, message: "" }
                        })
                    }
                })

                if (pt.length > 0) {
                    return pt[0]
                }
                else {
                    if (passengerType.isActive) {
                        return {
                            ...passengerType,
                            isOn: { value: false, valid: true, message: "" }
                        }
                    }
                    return undefined
                }
            })

            state.passengerTypes = updatedArray.filter(x => x !== undefined)

        },
        onFormChange: (state, { payload }) => {
            state.activityForm = payload
        },
        onFormChangeCategoryOrActivityBeachRule: (state, { payload }) => {
            state.activityForm = payload.form
            state.hasBeachRule = payload.hasBeachRule
        },
        onVehiclesFormChange: (state, { payload }) => {
            if (payload.el.hasOwnProperty("id")) {
                const updatedArray = payload.vehicles.map((vehicle, idx) => {
                    if (vehicle.id === payload.el.id) {
                        return {
                            id: vehicle.id,
                            uuid: vehicle.uuid,
                            name: vehicle.name,
                            shortName: vehicle.shortName,
                            capacity: vehicle.capacity,
                            isAvailable: vehicle.isAvailable,
                            isOn: { value: !vehicle.isOn.value, valid: true, message: "" }
                        }
                    } else {
                        return vehicle
                    }

                })
                state.vehicles = updatedArray
            }
        },
        onCircuitsFormChange: (state, { payload }) => {
            if (payload.el.hasOwnProperty("id")) {
                const updatedArray = payload.circuits.map((circuit, idx) => {
                    if (circuit.id === payload.el.id) {
                        return {
                            id: circuit.id,
                            name: circuit.name,
                            duration: circuit.duration,
                            isAvailable: circuit.isAvailable,
                            isOn: { value: !circuit.isOn.value, valid: true, message: "" }
                        }
                    } else {
                        return circuit
                    }

                })
                state.circuits = updatedArray
            }
        },
        onPassengerTypesFormChange: (state, { payload }) => {
            //validate here

            if (payload.el.hasOwnProperty("id")) {
                const updatedArray = payload.passengerTypes.map((pt, idx) => {

                    if (pt.id === payload.el.id) {
                        return {
                            ...pt,
                            id: pt.id,
                            name: pt.name,
                            price: { value: payload.inputName === 'price' ? payload.e.target.value : pt.price.value, valid: true, message: "" },
                            priceForIsland: { value: payload.inputName === 'priceForIsland' ? payload.e.target.value : pt.priceForIsland.value, valid: true, message: "" },
                            isOn: { value: payload.inputName === 'none' ? !pt.isOn.value : pt.isOn.value, valid: true, message: "" }
                        }
                    } else {
                        return pt
                    }

                })
                state.passengerTypes = updatedArray
            }
        },
        onExtrasFormChange: (state, { payload }) => {
            //validate here

            if (payload.el.hasOwnProperty("id")) {
                const updatedArray = payload.extras.map((extra, idx) => {
                    if (extra.id === payload.el.id) {
                        return {
                            ...extra,

                            price: { value: payload.price ? payload.e.target.value : extra.price.value, valid: true, message: "" },
                            isOn: { value: payload.price ? extra.isOn.value : !extra.isOn.value, valid: true, message: "" }
                        }
                    } else {
                        return extra
                    }

                })
                state.extras = updatedArray
            }
        },
        onChangeExtraMaxCapacity: (state, { payload }: PayloadAction<{
            extraId: number,
            value: number
        }>) => {


            const updatedArray = state.extras.map((extra, idx) => {
                if (extra.id === payload.extraId) {
                    return {
                        ...extra,
                        maxCapacity: {
                            value: String(payload.value),
                            valid: true,
                            message: ""
                        }
                    }
                } else {
                    return extra
                }
            })

            state.extras = updatedArray
        },
        populatePhotosForm: (state, { payload }) => {
            const photos: ActivityPhotoForm[] = Array.from([])

            payload.photos.forEach((photo) => {
                photos.push({
                    id: photo.id,
                    activity: photo.activityID,
                    file: photo.file,
                    sort: photo.sort,
                    edited: false,
                    new: false,
                })
            })

            state.photos = photos.sort((a, b) => { return a.sort - b.sort })
        },
        addToPhotosArray: (state, { payload }) => {
            const photo = payload.e
            const photos: ActivityPhotoForm[] = Array.from(payload.photos)

            photos.push({
                id: payload.newPhotosID + 1,
                activity: Number(payload.activityID),
                file: photo,
                sort: photos.length + 1,
                edited: false,
                new: true
            })

            state.newPhotosID = payload.newPhotosID + 1
            state.photos = photos
        },
        deletePhotoFromArray: (state, { payload }) => {
            const items: ActivityPhotoForm[] = Array.from(payload.photos)

            items.splice(items.indexOf(items[payload.index]), 1)

            const deletedPhoto = items.map((photo, idx) => {
                if (photo.sort !== idx + 1) {
                    return {
                        ...photo,
                        edited: true,
                        sort: idx + 1
                    }
                }
                else {
                    return {
                        ...photo,
                        sort: idx + 1
                    }
                }
            })

            state.photos = deletedPhoto

        },
        reOrderPhotosArray: (state, { payload }) => {
            if (!payload.e.destination) return;
            const items: ActivityPhotoForm[] = Array.from(payload.photos)


            const [reOrderedItem] = items.splice(payload.e.source.index, 1);
            items.splice(payload.e.destination.index, 0, reOrderedItem)
            const sorted = items.map((photo, idx) => {
                return {
                    ...photo,
                    sort: idx + 1,
                    edited: true,
                }
            })

            state.photos = sorted
        },
        toogleAllVehicles: (state, { payload }) => {
            state.vehiclesEnabled = payload.enabled
            state.vehicles = payload.vehicles.map((vehicle) => {
                return {
                    ...vehicle,
                    isOn: { value: payload.enabled, valid: true, message: "" },
                }
            })
        },
        toogleAllCircuits: (state, { payload }) => {
            state.circuitsEnabled = payload.enabled
            state.circuits = payload.circuits.map((circuit) => {
                return {
                    ...circuit,
                    isOn: { value: payload.enabled, valid: true, message: "" },
                }
            })
        },
        toogleAllPassengerTypes: (state, { payload }) => {
            state.passengerTypesEnabled = payload.enabled
            state.passengerTypes = payload.passengerTypes.map((pt) => {
                return {
                    ...pt,
                    isOn: { value: payload.enabled, valid: true, message: "" },
                }
            })
        },
        toogleAllExtras: (state, { payload }) => {
            state.extrasEnabled = payload.enabled
            state.extras = payload.extras.map((extra: ActivityExtrasForm) => {
                const canToogle = extra.isActive.value && Number(extra.maxCapacity.value) > 0

                return {
                    ...extra,
                    isOn: { value: canToogle ? payload.enabled : false, valid: true, message: "" },
                }
            })
        },
        onExtrasValidation: (state, { payload }) => {

            const updated = payload.extras.map((ext, extIdx) => {
                if (payload.validationExtras.form.find(e => e.id === ext.id)) {
                    return payload.validationExtras.form.find(e => e.id === ext.id)
                }
                return ext
            })

            state.extras = updated

        },
        onPassengerTypesValidation: (state, { payload }) => {

            const updated = payload.passengerTypes.map((pt, ptIdx) => {
                if (payload.validationPassengerTypes.form.find(p => p.id === pt.id)) {
                    return payload.validationPassengerTypes.form.find(e => e.id === pt.id)
                }
                return pt
            })

            state.passengerTypes = updated

        },
        onSubmit: (state, { payload }) => { },
        createPhoto: (state, { payload }) => { },
        editPhotoArray: (state, { payload }) => { },
    }
})