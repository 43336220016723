/**
 * Requesttablerow component
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */

import { CTableDataCell } from '@coreui/react-pro'
import React from 'react'
import theme from 'ui/theme'
import ChevronRight from 'assets/icons/chevron_right';
import { DateTime } from "luxon"
import config from 'config';
import i18n from 'i18next'
import { actions  } from 'store/rootSlices'
import { useDispatch } from 'react-redux'
import { InvoiceListItem } from 'types/invoicing/invoices'
import { ActionsCell } from 'shared/genericStyledComponents';
import { 
  CustomCTableRow,
  BookingNumber,
  Text,
  InvoicingTypeLabel } from './styles'

const { DATE_FORMAT } = config;

type PropsType = {
  id: number,
  invoice: InvoiceListItem,
  actions?: {} | any
}

const Requesttablerow = (props: PropsType) => {

  const {
    invoice: {
      id,
      booking,
      createdAt,
      amount,
      vat,
      isRefund,
      syncStatus,
      syncUid
    },
    // isCompleted,
  } = props

  const dispatch = useDispatch()

  return (
    <CustomCTableRow id={`booking-`}>

      <CTableDataCell>
        <BookingNumber>
          {booking.uid}
        </BookingNumber>
      </CTableDataCell>
      <CTableDataCell>
        {syncUid ? syncUid : 'N/A'}
      </CTableDataCell>
      <CTableDataCell>{DateTime.fromISO(createdAt).toFormat(DATE_FORMAT.DATE)}</CTableDataCell>
      <CTableDataCell>
        <InvoicingTypeLabel className={isRefund ? "credit" : "invoicing"}>{isRefund ? i18n.t('invoicingRequests.credit') : i18n.t('invoicingRequests.invoice')}</InvoicingTypeLabel>
      </CTableDataCell>
      <CTableDataCell>€{amount}</CTableDataCell>
      <CTableDataCell>
        {vat}
      </CTableDataCell>
      <CTableDataCell>
        <Text syncStatus={syncStatus}>
          {i18n.t(`invoicingRequests.${syncStatus}`)}
        </Text>
      </CTableDataCell>
      <ActionsCell nopointer >
        {props.actions}
        <ChevronRight className='pointer' fill={theme().gray500Color} onClick={() => {
            dispatch(actions.App.navigateTo(`/faturacao/detalhes/${id}`))
        }}/>
      </ActionsCell>
    </CustomCTableRow>
  )
}

export default Requesttablerow