/**
 * Locationstablerow component
 *
 * @author João Dias <jdias@ubiwhere.com>
 *
 *
 */

import { CTableDataCell } from '@coreui/react-pro';
import React from 'react'
import { 
  CustomTableRow, 
  ActionsCell,
  ChevronCell,
} from 'shared/genericStyledComponents'
import ChevronRight from 'assets/icons/chevron_right'
import i18n from 'i18next';
import { actions } from 'store/rootSlices'
import { useDispatch } from 'react-redux';

interface PropTypes {
  id?: string,
  actions: JSX.Element,
  location: any //TODO: extract type,
  onClick: Function,
  permission: boolean
}

const Locationstablerow = (props: PropTypes) => {
  const dispatch = useDispatch()
  const {
    permission,
    location: {
      name,
      isActive,
      id
    }
  } = props;

  return (
    <CustomTableRow>
      <CTableDataCell>{name}</CTableDataCell>
      <CTableDataCell>{isActive ? i18n.t('common.active') : i18n.t('common.inactive')}</CTableDataCell>
      <ActionsCell>
        {props.actions}
        <ChevronCell id={`${props.id}-chevron`}>
        {
            permission &&
            <a href={`/frota/configuracoes/editar-local-abastecimento/${id}`} onClick={(e) => {
              e.preventDefault()
              dispatch(actions.App.navigateTo(`/frota/configuracoes/editar-local-abastecimento/${id}`))
              }}>
              <ChevronRight></ChevronRight>
            </a>
          }
        </ChevronCell>
      </ActionsCell>
    </CustomTableRow>
  )
}

export default Locationstablerow
