/**
 * Passengerlisttablerow component
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */

import { CTableDataCell } from '@coreui/react-pro'
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
// Styles
import { Text } from 'ui/globalStyles'
import * as Styles from './styles'
// Types & Utils
import { TableRowPropTypes } from 'types/misc'
import { SetNewCheckLocationSagaType } from '../../utils'; 
// Store
import { RootState, actions } from 'store/rootSlices'
// Icons
import Ticket from 'assets/icons/ticket'
import ChevronRight from 'assets/icons/chevron_right';
// Components
import Loadingcontainer from 'shared/components/Loadingcontainer'


type PropTypes = TableRowPropTypes & {
  id?: string,
  checkin: any,
  trip: any, // just for demo purposes
  actions: JSX.Element,
}

const Passengerlisttablerow = (props: PropTypes) => {
  const dispatch = useDispatch()
  const { allPassengerTypes, allLocals } = useSelector((state: RootState) => state.TripsConfiguration);
  const { showPassengerTypes, tableLoading, tripModal, checkingIn } = useSelector((state: RootState) => state.PassengerControl);

  const {
    setNewCheckLocation,
    setShowWarningModal,
    setWarningModalData,
    toggleTripModal,
  } = actions.PassengerControl

  const handleClick = (data: SetNewCheckLocationSagaType) => {
    if(allLocals.some((l) => l.id === data.location && l.shortName === 'FAR') && !data?.checkoutId) {
      dispatch(setShowWarningModal(true))
      dispatch(setWarningModalData({
        id: data.id, 
        location: data.location,
        outbound: data.outbound,
        checkinId: data.checkinId,
        checkoutId: data.checkoutId
      }))
    } else {
      dispatch(setNewCheckLocation({
        id: data.id, 
        location: data.location,
        outbound: data.outbound,
        checkinId: data.checkinId,
        checkoutId: data.checkoutId
      }))
    }
  }

  return (
    <Styles.CustomCTableRow >
     {
      tableLoading ? <Loadingcontainer /> :
      <>
        <CTableDataCell>
          <Styles.BookingNumber>
            {/* <StatusLabel /> */}
            <Text>{ props.checkin?.booking?.uid }</Text>
          </Styles.BookingNumber>
        </CTableDataCell>
        <CTableDataCell>
          <Text>{ props.checkin?.booking?.customerName }</Text>
        </CTableDataCell>
        <Styles.TableDataCellPassengers>
          <Styles.PAXDivWrapper>
            <Styles.PAXDiv backgroundcolor='#ffffff'>
              {
                showPassengerTypes && <Styles.CustomText weight='400'>Total</Styles.CustomText>
              }
              <Styles.CustomText weight={showPassengerTypes ? '700' : '400'}>   
              {
                  props.checkin?.booking?.passengerTypes?.reduce((prev, curr) => {
                      if(curr.occupySeat) {
                          return prev + curr.quantity;
                      }
                      else {
                          return prev;
                      }
                  }, 0) 
              }
              </Styles.CustomText>
            </Styles.PAXDiv>
            {
              showPassengerTypes && allPassengerTypes.map((pt, idx) => {
                const numOfPax = props.checkin?.booking?.passengerTypes?.find(p => p.id.toString() === pt.id.toString())                            

                return (
                  <Styles.PAXDiv>
                    <Styles.CustomText weight='400'>{pt.shortName}</Styles.CustomText>
                    <Styles.CustomText weight='700'>{numOfPax ? numOfPax.quantity : 0}</Styles.CustomText>
                  </Styles.PAXDiv>
                )
              })
            }
          </Styles.PAXDivWrapper>
        </Styles.TableDataCellPassengers>
        <Styles.TableDataCellStops>
          <Styles.StopDivWrapper>
            {
              allLocals.map((l, idx) => {

                return (
                  <Styles.StopDivChecked
                    key={`tabbleStopCell-${l.name}-${idx}`}
                    start={props.checkin?.stopIn?.stop.id === l.id ? true : false}
                    exit={props.checkin?.stopOut?.stop.id === l.id ? true : false}
                    current={false}
                    activ={true}
                    onClick={e => {
                      if(!checkingIn) {
                        handleClick({
                          id: props.trip.booking?.lastCheckIn?.id, 
                          location: l.id,
                          outbound: props.trip.booking?.outbound !== null ? true : false,
                          checkinId: props.checkin?.stopIn?.stop.id ? props.checkin?.stopIn?.stop.id : null,
                          checkoutId: props.checkin?.stopOut?.stop.id ? props.checkin?.stopOut?.stop.id : null,
                        })
                      }
                    }}
                  >
                    <Styles.StopStatusText>{l.shortName}</Styles.StopStatusText>
                  </Styles.StopDivChecked>
                )
              })
            }
          </Styles.StopDivWrapper>
        </Styles.TableDataCellStops>
        <Styles.CustomActionsCell nopointer >
          {props.actions}
          <a href={`viagens/reservas/editar-reserva/${props.checkin.booking?.id}`} onClick={(e) => {
            e.preventDefault()
            dispatch(actions.App.navigateTo(`viagens/reservas/editar-reserva/${props.checkin.booking?.id}`))
          }}>
            <Ticket />
          </a>
          <ChevronRight 
            style={{cursor: "pointer"}} 
            onClick={() => {
              dispatch(toggleTripModal({
                ...tripModal,
                isVisible: true,
                vehicle: props.trip.booking.lastCheckIn.vehicle,
                initialPlanningId: props.trip.booking.lastCheckIn.trip,
                lastCheckin: props.checkin
              }))
            }} 
          />
        </Styles.CustomActionsCell>
      </>
      }
    </Styles.CustomCTableRow>
  )
}

export default Passengerlisttablerow