/**
 * Maintenanceform scene slice
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */
import { createSlice } from '@reduxjs/toolkit'
import { ServiceForm } from 'types/trips/services'
import { camelizeKeys } from 'humps'
//import { TripServicesMock } from 'mocks/TripActivityMock'


interface InitialState {
    serviceID: string,
    edit: Boolean,
    hasEditPermission: boolean,
    serviceForm: ServiceForm
    submitButtonState: boolean
}

const initialState: InitialState = {
    serviceID: "",
    edit: false,
    hasEditPermission: true,
    submitButtonState: false,
    serviceForm: {
        name: {
            value: "",
            valid: true,
            message: ""
        },
        description: {
            value: "",
            valid: true,
            message: ""
        },
        price: {
            value: "",
            valid: true,
            message: ""
        },
        isPricePerPerson: {
            value: true,
            valid: true,
            message: ""
        },
        isActive: {
            value: true,
            valid: true,
            message: ""
        },
    }
}

export default createSlice({
    name: 'tripserviceform',
    initialState,
    reducers: {
        onMount: (state, { payload }) => {},
        onUnmount: () => { 
            return initialState;
        },
        setAction: (state, { payload }) => {
            state.edit = payload
        },
        setHasEditPermission: (state, { payload }) => {
            state.hasEditPermission = payload
        },
        setSubmitButtonDisabled:(state, { payload }) => {
            state.submitButtonState = payload
        },
        setServiceID: (state, { payload }) => {
            state.serviceID = payload
        },
        populateForm: (state, { payload }) => {
            const service = camelizeKeys(payload)
            
            //const service = TripServicesMock[0]
            const baseForm = { ...initialState.serviceForm }
      
            Object.keys(service).forEach(key => {
              if (key in baseForm) {
                baseForm[key] = {
                  ...baseForm[key],
                  value: service[key],
                }
              }
            })
            state.serviceForm = baseForm;
        },
        onFormChange: (state, { payload }) => {
            state.serviceForm = payload //Check.setAndCheckValidation(state.serviceForm, payload, ServiceFormValidations)
        },
        onSubmit: (state, { payload }) => { }
    }
})