import theme from 'ui/theme';
import styled from 'styled-components'


export const Wrapper = styled.div<{ isvalid: boolean | undefined }>`
  option {
    font-family: inherit;
  } 
  
  > label {
    font-size: 14px;
    color: ${theme().darkColor};
    > span {
      color: ${theme().primary};
    }
  }

  .is-disabled {
    background-color: ${theme().gray200Color};
  }

  .searchSelect {
    width: 100%;
    
    .searchSelect__select {
      position: absolute;
      width: 100%;
      background-color: #fff;
      border: 1px solid ${theme().gray200Color};
      border-radius: 4px;
      z-index: 1;

      button {
        width: 100%;
        padding: 8px 16px;
        background: none;
        border: none;
        color:${theme().gray700Color};
        text-align: left;

        :hover {
          color: ${theme().white};
          background-color: ${theme().gray900Color};
        }
      }

      ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
      }
    }

    .searchSelect__value {
      
      .searchSelect__input {
        background-image: url("data:image/svg+xml,%3Csvg width='8' height='13' viewBox='0 0 8 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.99994 2.38667L6.11328 4.5L7.05328 3.56L3.99994 0.5L0.939941 3.56L1.88661 4.5L3.99994 2.38667ZM3.99994 10.6133L1.88661 8.5L0.946608 9.44L3.99994 12.5L7.05994 9.44L6.11328 8.5L3.99994 10.6133Z' fill='%23636F83'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position-x: 98%;
        background-position-y: 50%;
        width: 100%;
        height: 35px;
        padding: .375rem 2.25rem .375rem .75rem;
        border: 1px solid ${(props) => props.isvalid === false ? theme().danger : '#D8DBE0'};
        border-radius: 4px;
        color: ${theme().gray700Color};
        font-size: 14px;

        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        
        .react-datepicker__triangle{
          left: -40px !important;
        };

        &:focus{
          border-color: #321fdb50;
          outline-width: 0.1px;
          outline-color: #321fdb50;
          outline-style: solid;
          outline-offset: 0px;
          box-shadow: 0 0 1px 5px #321fdb50;
        };
      }
    }
  }
`