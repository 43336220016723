import styled from 'styled-components'
import theme from 'ui/theme'
import { CTableRow, CTableDataCell } from '@coreui/react-pro'

const CustomCTableRow = styled(CTableRow)`
   max-height: 54px;
   background-color: ${theme().white};
   border-bottom: 1px solid ${theme().gray200Color};
   
   > td {
     vertical-align: middle;
     font-family: Inter;
     font-size: 14px;
     color: ${theme().gray700Color}
   }
 
   td:first-child {
       padding-left:15px;
       width:40%;
   }
 `

const CheckBoxDataCell = styled(CTableDataCell)``

const Wrapper = styled.div` 
  width:100%;
  height:100%;
  overflow-y: auto;
  scrollbar-width: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  &::-webkit-scrollbar {
    width: 0px;
  }
`

const Title = styled.h6`
  font-size: 19px;
  font-weight:500;
  color:${theme().darkColor}
`

export {
  Wrapper,
  Title,
  CustomCTableRow,
  CheckBoxDataCell
}