import axios from 'axios'
import { decamelizeKeys  } from 'humps'
import endpoints from 'api/endpoints'
import { generateSortString, generateUrl } from 'utils'
import { toast } from 'react-toastify'
import i18n from 'i18next';
import { SortType } from 'types/misc';


const EditCheckin = async (id: number, params) => {
    const decMLZParams = decamelizeKeys(params)
    const endpoint = generateUrl(endpoints.TRIPS.CHECK_INS.EDIT_CHECKIN, { id })

    return axios.patch(endpoint, decMLZParams)
        .then(res => res.data)
        .catch(err => { throw err })
}

const DeleteCheckin = async (id: number) => {
    const endpoint = generateUrl(endpoints.TRIPS.CHECK_INS.DELETE_CHECKIN, { id })

    return axios.delete(endpoint)
        .then(res => res.data)
        .catch(err => { throw err })
}

const CreateCheckin = async (params) => {
    const decMLZParams = decamelizeKeys(params)
    const endpoint = `${endpoints.TRIPS.CHECK_INS.CREATE_CHECKIN}`

    return axios.post(endpoint, decMLZParams)
        .then(res => res.data)
        .catch(err => { throw err })
}

const GetTotalCheckins = (date: string, checkedIn?: boolean) => {

    const dateQS = `${date ? `date=${date}` : ''}`;
    const checkedInQS = `${checkedIn ? `&checked_in=${checkedIn}` : ''}`;
    const endpoint = `
        ${endpoints.TRIPS.CHECK_INS.GET_TOTAL_CHECKINS}?${dateQS}${checkedInQS}
    `;
    
    return axios.get(endpoint)
    .then(res => res.data)
    .catch(err => {
        toast.error(i18n.t("toasts.totalCheckinsGetError"))
    })
};

const GetCheckins = (page: number, date: string, sort: SortType, stopIn: string, stopOut: string, searchTerm?: string, checkedIn?: boolean )  =>{

    const dateQS = `${date ? `&date=${date}` : ''}`;
    const stopInQS = `${stopIn ? `&stop_in=${stopIn}`: ''}`;
    const stopOutQS = `${stopOut ? `&stop_out=${stopOut}`: ''}`;
    const searchQS = `${searchTerm ? `&search=${searchTerm}` : ''}`;
    const checkedInQS = `${checkedIn ? `&checked_in=${checkedIn}` : ''}`;
    const sortQS = sort.field && sort.direction ? generateSortString(sort) : ''
    
    const endpoint = `
        ${endpoints.TRIPS.CHECK_INS.GET_CHECKINS}?page=${page}${dateQS}${stopInQS}${stopOutQS}${searchQS}${sortQS}${checkedInQS}
    `;

    return axios.get(endpoint)
    .then(res => res.data)
    .catch(err => {
        toast.error(i18n.t("toasts.checkinsGetError"))
    })
};


export default {
    GetTotalCheckins,
    GetCheckins,
    EditCheckin,
    CreateCheckin,
    DeleteCheckin
}
