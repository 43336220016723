/**
 * CollaboratorsManagement scene slice
 *
 * @author João Dias <jdias@ubiwhere.com>
 *
 *
 */
import { createSlice } from '@reduxjs/toolkit'
import { CollaboratorListItem, CollaboratorTypeListItem, CollaboratorTeamListItem } from 'types/collaborators/collaborator';
import { SortType } from 'types/misc';

interface initialState {
  page: number,
  sort: SortType,
  total: number,
  collaboratorsList: CollaboratorListItem[],
  collaboratorsLongList: CollaboratorListItem[],
  filter: string,
  groupFilter: string,
  searchTerm: string,
  loading: boolean,
  collaboratorTypes: CollaboratorTypeListItem[],
  teams: CollaboratorTeamListItem[]
}

const initialState: initialState = {
  page: 1,
  sort: {
    field: null,
    direction: null,
  },
  total: 0,
  collaboratorsList: [],
  collaboratorsLongList: [],
  filter: "",
  groupFilter: "",
  searchTerm: '',
  loading: true,
  collaboratorTypes: [],
  teams: []
}

export default createSlice({
  name: 'CollaboratorsManagement',
  initialState,
  reducers: {
    onMount: (state, { payload }) => { },
    onUnmount: () => { },
    setSort: (state, { payload }) => {
      state.sort = payload;
    },
    setTotal: (state, { payload }) => {
      state.total = payload;
    },
    setPage: (state, { payload }) => {
      state.page = payload;
    },
    getCollaboratorsList: () => { },
    setCollaboratorsList: (state, { payload }) => {
      state.collaboratorsList = payload
    },
    setCollaboratorsLongList: (state, { payload }) => {
      state.collaboratorsLongList = payload
    },
    setFilter: (state, { payload }) => {
      state.page = 1
      state.filter = payload
    },
    setGroupFilter: (state, { payload }) => {
      state.page = 1
      state.groupFilter = payload
    },
    getCollaboratorTypes: () => { },
    setCollaboratorTypes: (state, { payload }) => {
      state.collaboratorTypes = payload
    },
    getTeams: () => { },
    setTeams: (state, { payload }) => {
      state.teams = payload
    },
    setSearchTerm: (state, { payload }) => {
      state.page = 1
      state.searchTerm = payload
    },
    getURLParams: (state, payload) => { },
    setLoading: (state, { payload }) => {
      state.loading = payload
    },
    populateFiltersOnMount: (state, { payload }) => {
      state.searchTerm = payload.searchTerm
      state.filter = payload.filter
      state.groupFilter = payload.groupFilter
    },
  }
})