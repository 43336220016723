import * as Check from 'validations'

export const ServiceFormValidations = {
    price:[Check.isRequired, Check.isNumber],
    isPricePerPerson:[Check.isRequired],
    description: [Check.isRequired],
    name: [Check.isRequired],
    isActive:[]
}

export const ServiceFormValidationsEdit = {
    price:[Check.isRequired, Check.isNumber],
    isPricePerPerson:[Check.isRequired],
    description: [Check.isRequired],
    name: [Check.isRequired],
    isActive:[]
}