/**
 * NewDocumentButton component
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */

import React from 'react'
import styled from 'styled-components'
import { CFormLabel } from '@coreui/react';
import theme from 'ui/theme';
import { CButton } from '@coreui/react-pro';
import AddDocument from 'assets/icons/add_document'

interface PropTypes {
    id?: string,
    wrapperStyle?: React.CSSProperties,
    required?: boolean,
    label?: string,
    value?: string,
    onChange?: Function,
    disabled?: boolean,
    sectionRef?: any, // should be more specific
    addComponent?: Function
}

const NewDocumentButton = (props: PropTypes) => {

    const handleButtonClick = () => {
        if(props.addComponent != null){
            props.addComponent()
        }
    }

    return (
        <Wrapper style={props.wrapperStyle}>
            {props.label && <CFormLabel>{props.label}<span>{!!props.required ? "*" : ""}</span></CFormLabel>}
            <Button id={props.id} variant="outline" disabled={props.disabled === true ? true : false} onClick={e => handleButtonClick()}>
                <AddDocument />  
            </Button>
        </Wrapper>
    )
}

export default NewDocumentButton

const Wrapper = styled.div`
 
   > * {
     font-family: 'Inter';
   }
 
   > label {
     font-size: 14px;
     color: ${theme().darkColor};
     > span {
       color: ${theme().primary};
     }
   }
 `

const Button = styled(CButton)`
   display:flex;
   justify-content:center;
   align-items:center;
   border: 0.5px dashed #321FDB;
   border-radius:0px;
   background-color: #F4F2FF;
   width:100%;
   height:54px;
   &:hover{
    background-color: #e1dbff;  
   }
   &:focus{
    box-shadow: 0 0 1px 5px #321fdb50;
    background-color: #F4F2FF;  
   }
`