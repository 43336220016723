import styled from 'styled-components'
import { CForm, CRow, CTableDataCell, CButtonGroup, CFormCheck } from '@coreui/react-pro'
import theme from 'ui/theme'

const CustomForm = styled(CForm)`
  height: 300px;
`

const Row = styled(CRow)`
  margin-top: 15px;
`

const TableDataCellStops = styled(CTableDataCell) <{ start: boolean, exit: boolean, current: boolean, activ: boolean }>`
  height: 54px;
  color: ${props => 
    props.activ ? 
      props.start ? 
        `${theme().success} !important` 
        :
        props.exit ?   
          `${theme().danger} !important` 
          : 
          `${theme().gray700Color} !important`
      :
      `${theme().gray200Color} !important`
  }; 
  background-color: ${props =>
    props.activ ?
      props.start ? 
        `${theme().success10} !important` 
        :
        props.exit ? 
        `${theme().danger10} !important`
        :
        `${theme().white} !important`
      :
      `${theme().white} !important`
  }; 
  font-weight: ${props => props.start || props.exit ? 700 : 400};
  text-align:center;
  cursor: ${props => props.activ ? 'pointer' : 'default'};
  border-right: 1px solid ${theme().gray300Color};

  &.first-cell {
    border-left: 1px solid ${theme().gray300Color};
  }
`

const StopsRow = styled(CRow)<{stopsLength: number}>`
  width: 100%;
  margin: 30px auto 0 auto;
  padding-block: 1px;
  display: grid;
  grid-template-columns:${({stopsLength}) => `repeat(${stopsLength}, 1fr)`};
  background-color: ${theme().gray300Color};
`

const TabsWrapper = styled(CButtonGroup)`
  display:flex;
  justify-content: flex-start;
  background-color: ${theme().white};

  .btn-check:checked+.btn {
    background-color: ${theme().gray300Color};
  }

  .btn {
    &:hover {
      background-color: ${theme().gray300Color};
    }
  }
`

const Tab = styled(CFormCheck)`
  margin:0px;
  border: 1px solid ${theme().secondaryColor};
  padding: 5px 11px !important;
  color: ${theme().secondaryColor};
`

const TabsCustomSpan = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 5px;
`

const GreenFlag = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: ${theme().success};
`

export {
  CustomForm,
  Row,
  TableDataCellStops,
  StopsRow,
  TabsWrapper,
  Tab,
  TabsCustomSpan,
  GreenFlag
}