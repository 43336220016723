/**
 * Tripsconfiguration scene sagas
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */
import API from 'api'
import { takeLatest, putResolve, call, select } from 'redux-saga/effects'
import { actions, RootState } from 'store/rootSlices'
import { toast } from 'react-toastify'
import { AnyAction, PayloadAction } from '@reduxjs/toolkit';
import i18n from 'i18next';
import config from 'config'
import { hasPermission } from 'permissions'
import { ActivitiesList, ActivityListItem } from 'types/trips/activity/activity'
import { CategoryList } from 'types/trips/categories'
import { LocalsList } from 'types/trips/locals'
import { PassengerTypeList } from 'types/trips/passengerTypes'
import { ExtrasList } from 'types/trips/extras'
import { CampaignsList } from 'types/trips/campaigns'
import { CircuitsList } from 'types/trips/circuits'
import { ServicesList } from 'types/trips/services'




function* onMountSaga({payload}: AnyAction) {

  yield getURLParamsSaga(payload);
  //Checking all permissions on onMountSaga because this screen has many endpoints with different permissions. 
  //Checking individual permissions on get methods
  const { permissions } = yield select((state: RootState) => state.App);
  const { PERMISSIONS } = config

  //setting default permission for createButton
  if(hasPermission(permissions, PERMISSIONS.TRIPS.ACTIVITIES.CREATE_ACTIVITY)){
    yield putResolve(actions.Tripsconfiguration.setHasCreatePermission(true))
  }

  try {
    const { activities, categories, locals, passengerTypes, extras, circuits, makeApiCals } = yield select((state: RootState) => state.TripsConfiguration);

    if(activities.length === 0 || categories.length === 0 || locals.length === 0 || passengerTypes.length === 0 || extras.length === 0 || circuits.length === 0 || makeApiCals === true ) {
      yield putResolve(actions.Tripsconfiguration.getActivities())
      yield putResolve(actions.Tripsconfiguration.getCategories())
      yield putResolve(actions.Tripsconfiguration.getLocals())
      yield putResolve(actions.Tripsconfiguration.getPassengerTypes())
      yield putResolve(actions.Tripsconfiguration.getExtras())
      yield putResolve(actions.Tripsconfiguration.getCircuits())
      yield putResolve(actions.Tripsconfiguration.setMakeApiCals(false))
    }
    yield putResolve(actions.Tripsconfiguration.getServices())
    yield putResolve(actions.Tripsconfiguration.getCampaigns())

  } catch (error) {
    toast.error(i18n.t("toasts.tripSettingsGetError"))
  } finally {
    yield putResolve(actions.Tripsconfiguration.setLoading(false));
  }
}

function* getActivitiesSaga() {
  yield putResolve(actions.Tripsconfiguration.setLoading(true))

  try {
    const { page, searchTerm, sort } = yield select((state: RootState) => state.TripsConfiguration);

    const activities: ActivitiesList = yield call(API.Activities.GetActivities, page, searchTerm, sort)
  
      if (activities) {
        yield putResolve(actions.Tripsconfiguration.setActivities(activities.results))
        yield putResolve(actions.Tripsconfiguration.setTotalActivities(activities.count))
      }
  } catch (error) {
    toast.error(i18n.t("toasts.activitiesGetError"))
  } finally {
    yield putResolve(actions.Tripsconfiguration.setLoading(false))
  }
}

function* getAllActivitiesSaga() {

  try {
    const activities: ActivityListItem[] = yield call(API.Activities.GetAllActivities)
  
      if (activities) {
        yield putResolve(actions.Tripsconfiguration.setAllActivities(activities))
      }
  } catch (error) {
    toast.error(i18n.t("toasts.activitiesGetError"))
  }
}


function* getCategoriesSaga() {
  yield putResolve(actions.Tripsconfiguration.setLoading(true))

  try {
    const { page, searchTerm, sort } = yield select((state: RootState) => state.TripsConfiguration);

    const categories: CategoryList = yield call(API.Categories.GetCategories, page, searchTerm, sort)

      if (categories) {
        yield putResolve(actions.Tripsconfiguration.setCategories(categories.results))
        yield putResolve(actions.Tripsconfiguration.setTotalCategories(categories.count))
      }
  } catch (error) {
    toast.error(i18n.t("toasts.categoriesGetError"))
  } finally {
    yield putResolve(actions.Tripsconfiguration.setLoading(false))
  }
}

function* getAllCategoriesSaga() {

  try {
    const categories = yield call(API.Categories.GetAllCategories)

    if (categories) {
      yield putResolve(actions.Tripsconfiguration.setAllCategories(categories))
    }
    
  } catch (error) {
    toast.error(i18n.t("toasts.categoriesGetError"))
  }
}

function* getLocalsSaga() {
  yield putResolve(actions.Tripsconfiguration.setLoading(true))

  try {
    const { page, searchTerm, sort } = yield select((state: RootState) => state.TripsConfiguration);
    const locals: LocalsList = yield call(API.Locals.GetStops, page, searchTerm, sort)
  
    if (locals) {
      yield putResolve(actions.Tripsconfiguration.setLocals(locals.results))
      yield putResolve(actions.Tripsconfiguration.setTotalLocals(locals.count))
    }
  } catch (error) {
    toast.error(i18n.t("toasts.localsGetError"))
  } finally {
    yield putResolve(actions.Tripsconfiguration.setLoading(false))
  }
}

function* getAllLocalsSaga() {

  try {
    const locals: LocalsList = yield call(API.Locals.GetAllStops)
  
    if (locals) {
      yield putResolve(actions.Tripsconfiguration.setAllLocals(locals))
    }
  } catch (error) {
    toast.error(i18n.t("toasts.localsGetError"))
  }
}

function* getPassengerTypesSaga() {
  yield putResolve(actions.Tripsconfiguration.setLoading(true))

  try {
    const { page, searchTerm, sort } = yield select((state: RootState) => state.TripsConfiguration);

    const passengerTypes: PassengerTypeList = yield call(API.PassengerTypes.GetPassengerTypes, page, searchTerm, sort)
  
      if (passengerTypes) {
        yield putResolve(actions.Tripsconfiguration.setPassengerTypes(passengerTypes.results))
        yield putResolve(actions.Tripsconfiguration.setTotalPassengerTypes(passengerTypes.count))
      }
  } catch (error) {
    toast.error(i18n.t("toasts.passengerTypesGetError"))
  } finally {
    yield putResolve(actions.Tripsconfiguration.setLoading(false))
  }
}

function* getAllPassengerTypesSaga() {

  try {

    const passengerTypes: PassengerTypeList = yield call(API.PassengerTypes.GetAllPassengerTypes)
  
      if (passengerTypes) {
        yield putResolve(actions.Tripsconfiguration.setAllPassengerTypes(passengerTypes))
      }
  } catch (error) {
    toast.error(i18n.t("toasts.passengerTypesGetError"))
  }
}

function* getExtrasSaga() {
  yield putResolve(actions.Tripsconfiguration.setLoading(true))
  const { permissions } = yield select((state: RootState) => state.App);
  const { PERMISSIONS } = config

  try {
    const { page, searchTerm, sort } = yield select((state: RootState) => state.TripsConfiguration);

    if(hasPermission(permissions, PERMISSIONS.TRIPS.EXTRAS.VIEW_EXTRA)){
      const extras: ExtrasList = yield call(API.Extras.GetExtras, page, searchTerm, sort)
  
      if (extras) {
        yield putResolve(actions.Tripsconfiguration.setExtras(extras.results))
        yield putResolve(actions.Tripsconfiguration.setTotalExtras(extras.count))
      }
    }
  } catch (error) {
    toast.error(i18n.t("toasts.ExtrasGetError"))
  } finally {
    yield putResolve(actions.Tripsconfiguration.setLoading(false))
  }
}

function* getAllExtrasSaga() {
  const { permissions } = yield select((state: RootState) => state.App);
  const { PERMISSIONS } = config

  try {

    if(hasPermission(permissions, PERMISSIONS.TRIPS.EXTRAS.VIEW_EXTRA)){
      const extras: ExtrasList = yield call(API.Extras.GetAllExtras)
  
      if (extras) {
        yield putResolve(actions.Tripsconfiguration.setAllExtras(extras))
      }
    }
  } catch (error) {
    toast.error(i18n.t("toasts.ExtrasGetError"))
  }
}

function* getCampaignsSaga() {
  yield putResolve(actions.Tripsconfiguration.setLoading(true))
  const { permissions } = yield select((state: RootState) => state.App);
  const { PERMISSIONS } = config

  try {
    const { page, searchTerm, sort } = yield select((state: RootState) => state.TripsConfiguration);

    if(hasPermission(permissions, PERMISSIONS.TRIPS.CAMPAIGNS.VIEW_CAMPAIGN)){
      const campaigns: CampaignsList = yield call(API.Campaigns.GetCampaigns, page, searchTerm, sort)
  
      if (campaigns) {
        yield putResolve(actions.Tripsconfiguration.setCampaigns(campaigns.results))
        yield putResolve(actions.Tripsconfiguration.setTotalCampaigns(campaigns.count))
      }
    }
  } catch (error) {
    toast.error(i18n.t("toasts.CampaignsGetError"))
  } finally {
    yield putResolve(actions.Tripsconfiguration.setLoading(false))
  }
}

function* getCircuitsSaga() {
  yield putResolve(actions.Tripsconfiguration.setLoading(true))

  const { permissions } = yield select((state: RootState) => state.App);
  const { PERMISSIONS } = config

  try {
    const { page, searchTerm, sort } = yield select((state: RootState) => state.TripsConfiguration);

    if(hasPermission(permissions, PERMISSIONS.TRIPS.CIRCUITS.VIEW_CIRCUIT)){
      const circuits: CircuitsList = yield call(API.Circuits.GetCircuits, page, searchTerm, sort)
  
      if (circuits) {
        yield putResolve(actions.Tripsconfiguration.setCircuits(circuits.results))
        yield putResolve(actions.Tripsconfiguration.setTotalCircuits(circuits.count))
      }
    }
  } catch (error) {
    toast.error(i18n.t("toasts.CircuitsGetError"))
  } finally {
    yield putResolve(actions.Tripsconfiguration.setLoading(false))
  }
}

function* getAllCircuitsSaga() {
  const { permissions } = yield select((state: RootState) => state.App);
  const { PERMISSIONS } = config

  try {

    if(hasPermission(permissions, PERMISSIONS.TRIPS.CIRCUITS.VIEW_CIRCUIT)){
      const circuits: CircuitsList = yield call(API.Circuits.GetAllCircuits)
  
      if (circuits) {
        yield putResolve(actions.Tripsconfiguration.setAllCircuits(circuits))
      }
    }
  } catch (error) {
    toast.error(i18n.t("toasts.CircuitsGetError"))
  }
}

function* getServicesSaga() {
  yield putResolve(actions.Tripsconfiguration.setLoading(true))
  const { permissions } = yield select((state: RootState) => state.App);
  const { PERMISSIONS } = config

  try {
    const { page, searchTerm, sort } = yield select((state: RootState) => state.TripsConfiguration);

    if(hasPermission(permissions, PERMISSIONS.TRIPS.SERVICES.VIEW_SERVICE)){
      const services: ServicesList = yield call(API.Services.GetServices, page, searchTerm, sort)
  
      if (services) {
        yield putResolve(actions.Tripsconfiguration.setServices(services.results))
        yield putResolve(actions.Tripsconfiguration.setTotalServices(services.count))
      }
    }
  } catch (error) {
    toast.error(i18n.t("toasts.ServicesGetError"))
  } finally {
    yield putResolve(actions.Tripsconfiguration.setLoading(false))
  }
}

function* SortSearchTermSaga({ payload }: AnyAction) {

  try {
    const { tabName } = yield select((state: RootState) => state.TripsConfiguration);

    switch (tabName) {
      case i18n.t('tripSettings.activityButton'):
        yield putResolve(actions.Tripsconfiguration.getActivities())
        break;

      case i18n.t('tripSettings.categoryButton'):
        yield putResolve(actions.Tripsconfiguration.getCategories())
        break;

      case i18n.t('tripSettings.localButton'):
        yield putResolve(actions.Tripsconfiguration.getLocals())
        break;

      case i18n.t('tripSettings.passengerTypeButton'):
        yield putResolve(actions.Tripsconfiguration.getPassengerTypes())
        break;

      case i18n.t('tripSettings.extraButton'):
        yield putResolve(actions.Tripsconfiguration.getExtras())
        break;

      case i18n.t('tripSettings.campaignButton'):
        yield putResolve(actions.Tripsconfiguration.getCampaigns())
        break;

      case i18n.t('tripSettings.circuitButton'):
        yield putResolve(actions.Tripsconfiguration.getCircuits())
        break;

      case i18n.t('tripSettings.serviceButton'):
        yield putResolve(actions.Tripsconfiguration.getServices())
        break;

      default:
        break;
    }
  } catch (error) {
    toast.error(i18n.t("toasts.ServicesGetError"))
  }
}

function* setTabSaga({ payload }: PayloadAction<string>) {

  const { permissions } = yield select((state: RootState) => state.App);
  const { PERMISSIONS } = config

  switch (payload) {
    case i18n.t('tripSettings.activities'):
      if(hasPermission(permissions, PERMISSIONS.TRIPS.ACTIVITIES.CREATE_ACTIVITY)){
        yield putResolve(actions.Tripsconfiguration.setHasCreatePermission(true))
      }
      yield putResolve(actions.Tripsconfiguration.setTabName(i18n.t('tripSettings.activityButton')))
      yield putResolve(actions.Tripsconfiguration.setPath('/viagens/configuracoes/nova-atividade'))
      yield putResolve(actions.Tripsconfiguration.setPage(1))
      yield putResolve(actions.Tripsconfiguration.setTabId(0))
      //yield putResolve(actions.Tripsconfiguration.getActivities())
      break;
    case i18n.t('tripSettings.categories'):
      if(hasPermission(permissions, PERMISSIONS.TRIPS.CATEGORIES.CREATE_CATEGORY)){
        yield putResolve(actions.Tripsconfiguration.setHasCreatePermission(true))
      }
      yield putResolve(actions.Tripsconfiguration.setTabName(i18n.t('tripSettings.categoryButton')))
      yield putResolve(actions.Tripsconfiguration.setPath('/viagens/configuracoes/nova-categoria'))
      yield putResolve(actions.Tripsconfiguration.setPage(1))
      yield putResolve(actions.Tripsconfiguration.setTabId(1))
      //yield putResolve(actions.Tripsconfiguration.getCategories())
      break;
    case i18n.t('tripSettings.locals'):
      if(hasPermission(permissions, PERMISSIONS.TRIPS.STOPS.CREATE_STOP)){
        yield putResolve(actions.Tripsconfiguration.setHasCreatePermission(true))
      }
      yield putResolve(actions.Tripsconfiguration.setTabName(i18n.t('tripSettings.localButton')))
      yield putResolve(actions.Tripsconfiguration.setPath('/viagens/configuracoes/novo-local'))
      yield putResolve(actions.Tripsconfiguration.setPage(1))
      yield putResolve(actions.Tripsconfiguration.setTabId(2))
      //yield putResolve(actions.Tripsconfiguration.getLocals())
      break;
    case i18n.t('tripSettings.passengerTypes'):
      if(hasPermission(permissions, PERMISSIONS.TRIPS.PASSENGER_TYPES.CREATE_PASSENGER_TYPE)){
        yield putResolve(actions.Tripsconfiguration.setHasCreatePermission(true))
      }
      yield putResolve(actions.Tripsconfiguration.setTabName(i18n.t('tripSettings.passengerTypeButton')))
      yield putResolve(actions.Tripsconfiguration.setPath('/viagens/configuracoes/novo-tipo'))
      yield putResolve(actions.Tripsconfiguration.setPage(1))
      yield putResolve(actions.Tripsconfiguration.setTabId(3))
      //yield putResolve(actions.Tripsconfiguration.getPassengerTypes())
      break;
    case i18n.t('tripSettings.extras'):
      if(hasPermission(permissions, PERMISSIONS.TRIPS.EXTRAS.CREATE_EXTRA)){
        yield putResolve(actions.Tripsconfiguration.setHasCreatePermission(true))
      }
      yield putResolve(actions.Tripsconfiguration.setTabName(i18n.t('tripSettings.extraButton')))
      yield putResolve(actions.Tripsconfiguration.setPath('/viagens/configuracoes/novo-extra'))
      yield putResolve(actions.Tripsconfiguration.setPage(1))
      yield putResolve(actions.Tripsconfiguration.setTabId(4))
      //yield putResolve(actions.Tripsconfiguration.getExtras())
      break;
    case i18n.t('tripSettings.campaigns'):
      if(hasPermission(permissions, PERMISSIONS.TRIPS.CAMPAIGNS.CREATE_CAMPAIGN)){
        yield putResolve(actions.Tripsconfiguration.setHasCreatePermission(true))
      }
      yield putResolve(actions.Tripsconfiguration.setTabName(i18n.t('tripSettings.campaignButton')))
      yield putResolve(actions.Tripsconfiguration.setPath('/viagens/configuracoes/nova-campanha'))
      yield putResolve(actions.Tripsconfiguration.setPage(1))
      yield putResolve(actions.Tripsconfiguration.setTabId(5))
     // yield putResolve(actions.Tripsconfiguration.getCampaigns())
      break;
    case i18n.t('tripSettings.circuits'):
      if(hasPermission(permissions, PERMISSIONS.TRIPS.CIRCUITS.CREATE_CIRCUIT)){
        yield putResolve(actions.Tripsconfiguration.setHasCreatePermission(true))
      }
      yield putResolve(actions.Tripsconfiguration.setTabName(i18n.t('tripSettings.circuitButton')))
      yield putResolve(actions.Tripsconfiguration.setPath('/viagens/configuracoes/novo-circuito'))
      yield putResolve(actions.Tripsconfiguration.setPage(1))
      yield putResolve(actions.Tripsconfiguration.setTabId(6))
      //yield putResolve(actions.Tripsconfiguration.getCircuits())
      break;
    case i18n.t('tripSettings.services'):
      if(hasPermission(permissions, PERMISSIONS.TRIPS.SERVICES.VIEW_SERVICE)){
        yield putResolve(actions.Tripsconfiguration.setHasCreatePermission(true))
      }
      yield putResolve(actions.Tripsconfiguration.setTabName(i18n.t('tripSettings.serviceButton')))
      yield putResolve(actions.Tripsconfiguration.setPath('/viagens/configuracoes/novo-servico'))
      yield putResolve(actions.Tripsconfiguration.setPage(1))
      yield putResolve(actions.Tripsconfiguration.setTabId(7))
      //yield putResolve(actions.Tripsconfiguration.getServices())
      break;
    default:
      break;
  }
}

function* getURLParamsSaga( payload : AnyAction) {

  let params = new URLSearchParams(payload.location.search)

  const {
      searchTerm,
  } = yield select((state: RootState) => state.TripsConfiguration);

  if (params.toString() !== "") {
    yield putResolve(actions.Tripsconfiguration.populateFiltersOnMount({
      searchTerm: params.get('search') ?? ""
    }));
  }
  else {
      params.set('search', searchTerm)
      payload.replace({ search: (params).toString() })
  }
}

function* onUnmountSaga() { }


export default function* watcherSignin() {
  yield takeLatest('Tripsconfiguration/onMount', onMountSaga)
  yield takeLatest('Tripsconfiguration/onUnmount', onUnmountSaga)
  yield takeLatest('Tripsconfiguration/getActivities', getActivitiesSaga)
  yield takeLatest('Tripsconfiguration/getAllActivities', getAllActivitiesSaga)
  yield takeLatest('Tripsconfiguration/getCategories', getCategoriesSaga)
  yield takeLatest('Tripsconfiguration/getAllCategories', getAllCategoriesSaga)
  yield takeLatest('Tripsconfiguration/getPassengerTypes', getPassengerTypesSaga)
  yield takeLatest('Tripsconfiguration/getAllPassengerTypes', getAllPassengerTypesSaga)
  yield takeLatest('Tripsconfiguration/getLocals', getLocalsSaga)
  yield takeLatest('Tripsconfiguration/getAllLocals', getAllLocalsSaga)
  yield takeLatest('Tripsconfiguration/getExtras', getExtrasSaga)
  yield takeLatest('Tripsconfiguration/getAllExtras', getAllExtrasSaga)
  yield takeLatest('Tripsconfiguration/getCampaigns', getCampaignsSaga)
  yield takeLatest('Tripsconfiguration/getCircuits', getCircuitsSaga)
  yield takeLatest('Tripsconfiguration/getAllCircuits', getAllCircuitsSaga)
  yield takeLatest('Tripsconfiguration/getServices', getServicesSaga)
  yield takeLatest('Tripsconfiguration/setSearchTerm', SortSearchTermSaga);
  yield takeLatest('Tripsconfiguration/setSort', SortSearchTermSaga);
  yield takeLatest('Tripsconfiguration/setTab', setTabSaga);
  yield takeLatest('Tripsconfiguration/getURLParams', getURLParamsSaga);
}


