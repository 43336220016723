import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import i18n from 'i18next';
// Styles
import theme from 'ui/theme';
import { Wrapper, UnitCapacity, UnitCircuit, UnitContainer, UnitDetails, UnitName } from './styles'
import { Icon, Text, TextCounter, BoardingLabel } from '../../styles';
// Icons
import Boat from 'assets/icons/boat_icon'
import TicketCheckin from 'assets/icons/ticket_checkin'
import Edit from 'assets/icons/edit'
// Components
import CustomButton from 'shared/components/CustomButton';
import Checkinsmodal from '../../../Checkinsmodal/index';
// Store
import { actions, RootState } from 'store/rootSlices';
// Utils & Configs
import config from 'config';
import { hasPermission } from 'permissions';
import type { PlanningListItem} from 'types/trips/plannings';

type PropTypes = {
  planning: PlanningListItem,
}

const Units = ({ planning }: PropTypes) => {
  const { allLocals } = useSelector((state: RootState) => state.TripsConfiguration)
  const {
    showCheckinModal,
    selectedVehicle,
    openPlanningTripPassengers,
    openPlanningElegiblePassengers,
    openPlanningTotalBookings,
    tripsDetails
  } = useSelector((state: RootState) => state.TripPlannings)
  const {
    setOpenPlanning,
    setShowModal,
    setShowCheckInModal,
    closeCheckInModal,
    setModalLoading,
    setSelectedVehicle,
    clearOpenPlanningTripPassengers,
    getTripStopDetails,
    setIsUpdatingCircuit,
    setModalInitialVehicle,
    setCheckinOutboundSelected
  } = actions.TripPlannings
  const { permissions } = useSelector((state: RootState) => state.App)
  const dispatch = useDispatch()
  const { PERMISSIONS } = config
  

  let currentTrip = tripsDetails.find(item => item.id === planning.id)

  const _renderUnitCircuit = ({ vehicle }) => {
    return hasPermission(permissions, PERMISSIONS.TRIPS.PLANNINGS.EDIT_PLANNING) && (
      <UnitCircuit onClick={e => {
        dispatch(setIsUpdatingCircuit(true))
        dispatch(setModalInitialVehicle(vehicle.uuid))
        dispatch(setSelectedVehicle({ vehicle: vehicle.uuid, planning: planning }))
        dispatch(setShowModal({ show: true, planningId: planning.id }))
        dispatch(setOpenPlanning({ planning: planning, locals: allLocals, selectedVehicle: selectedVehicle.value }))
        dispatch(getTripStopDetails())
      }}>
        <Edit />
      </UnitCircuit>
    )
  }

  const _renderCheckinsModal = () => {
    return showCheckinModal.show && showCheckinModal.planningId === planning.id && showCheckinModal.openBy !== 'stop' && (
      <Checkinsmodal
        showModal={showCheckinModal.show}
        tripBookings={openPlanningTripPassengers}
        elegibleBookings={openPlanningElegiblePassengers}
        totalBookings={openPlanningTotalBookings}
        planningOutStops={ planning.outbound}
        planningInStops={ planning.inbound}
        tripUnits={ planning.vehicles}
        onClose={e => {
          dispatch(closeCheckInModal())
          dispatch(setModalLoading(true))
          dispatch(clearOpenPlanningTripPassengers())
        }}
      />
    )
  }

  const _renderAddReinforcementBtn = () => {
    return hasPermission(permissions, PERMISSIONS.TRIPS.PLANNINGS.EDIT_PLANNING) && (
      <CustomButton
        text={i18n.t('tripPlannings.addReinforcement')}
        icon="add"
        color="secondary"
        buttonStyle={{ border: '0px', backgroundColor: `${theme().gray200Color}`, width: '100%', color: '#636F83', height: '33px', fontSize: '14px' }}
        onClick={e => {
          dispatch(setSelectedVehicle({ vehicle: "", planning: planning }))
          dispatch(setShowModal({ show: true, planningId: planning.id }))
          dispatch(setOpenPlanning({ planning: planning, locals: allLocals, selectedVehicle: selectedVehicle.value }))
          dispatch(getTripStopDetails())
        }} />
    )
  }

  return (
    <Wrapper>
      {
        planning.vehicles &&  planning.vehicles.map((vhc, idx) => {
          return (
            <div key={`plannings-list-table-row-units-idx-${idx}`}>
              <UnitContainer>
                <UnitDetails
                  onClick={e => {
                    dispatch(setCheckinOutboundSelected(true))
                    dispatch(setShowCheckInModal({ show: true, planningId: planning.id, vehicle: vhc, stop: {id: -1, name: ""}, openBy: 'vehicle' }))
                  }
                  }>
                  <UnitName>
                    <Icon marginr='5'><Boat fill={vhc.vehicle.color} /></Icon>
                    <Text style={{ fontSize: '14px', marginTop: '2px' }} >{vhc.vehicle.name}</Text>
                  </UnitName>
                  {
                    currentTrip?.boardings.length > 0 && currentTrip.boardings.map(b => {
                      if(b.vehicle.uuid === vhc.vehicle.uuid) {
                        return <BoardingLabel>{ i18n.t('tripPlannings.boarding')}</BoardingLabel>
                      }
                      return <></>
                    })
                    
                  }
                  <UnitCapacity>
                    <Icon marginr='5'><TicketCheckin /></Icon>
                    <TextCounter><b>{ planning.seatsCheckedIn}</b></TextCounter>
                  </UnitCapacity>
                </UnitDetails>
                { _renderUnitCircuit(vhc) }
              </UnitContainer>
              { _renderCheckinsModal() }
            </div>
          )
        })
      }
      { _renderAddReinforcementBtn() }
    </Wrapper>
  )
}

export default Units