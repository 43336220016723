import { CTableDataCell, CTableRow } from '@coreui/react-pro'
import styled from 'styled-components'
import theme from 'ui/theme'
import { Text } from 'ui/globalStyles'

const CustomCTableRow = styled(CTableRow) <{ total: boolean }>`
    max-height: 54px;
    background-color: ${props => props.total === true ? theme().offWhite : theme().white};
    border-bottom: 1px solid ${theme().gray200Color};

    > td {
        vertical-align: middle;
        font-family: Inter;
        font-size: 14px;
        color: ${theme().gray700Color};
    }

    td:not(:first-child):not(:lastchild){
      width:5%;
    }

    td:first-child {
      padding-left:15px;
    }

    td:last-child {
      width:10%;
    }
`

const Icon = styled.div <{ marginr: string }>`
  margin-right: ${props => props.marginr}px;
`

const BoardingCell = styled(CTableDataCell)`
  display: flex;
  cursor: pointer;
`
const CustomText = styled(Text) <{ weight: string }>`
  margin:0;
  font-weight: ${props => props.weight};
`

export {
  CustomCTableRow,
  Icon,
  BoardingCell,
  CustomText
}