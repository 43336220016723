/**
 * PaymentForm component
 *
 * @author João Flores <jflores@ubiwhere.com>
 *
 *
 */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions, RootState } from 'store/rootSlices';
//styles
import { Row } from '../../styles';
//Components
import { CCol } from '@coreui/react-pro';
import CustomCheckbox from 'shared/components/CustomCheckbox';
import InvoiceFormRows from './components/InvoiceFormRows';
import PaymentFormRows from './components/PaymentFormRows';

const PaymentForm = ({ t }) => {
  const {
    enablePayment,
    paymentForm,
    forcePayment,
    hasEditPermission,
    bookingState,
    isFree,
  } = useSelector((state: RootState) => state.BookingForm);

  const {
    setEnablePayment,
    setEntriesNoValue,
    setIsFree,
  } = actions.BookingForm;

  const dispatch = useDispatch();

  const _renderForm = () => {
    return !isFree && <>
        <PaymentFormRows t={t} />
        <InvoiceFormRows t={t} />
    </>
  }

  return (
    <>
      {bookingState !== 'pending' && (
        <>
          <Row>
            {!isFree && (
              <CCol>
                <CustomCheckbox
                  id="bookingForm-enablePayment"
                  checked={!enablePayment}
                  onClick={(e) => {
                    dispatch(setEnablePayment(!enablePayment));
                  }}
                  text={
                    paymentForm.isRefund
                      ? t('tripBookings.refundLater')
                      : t('tripBookings.payLater')
                  }
                  disabled={!hasEditPermission || forcePayment}
                />
              </CCol>
            )}
          </Row>
          <Row>
            {!paymentForm.isRefund && (
              <CCol>
                <CustomCheckbox
                  id="bookingForm-setValue"
                  checked={isFree}
                  onClick={(e) => {
                    dispatch(setEntriesNoValue());
                    dispatch(setIsFree(!isFree));
                  }}
                  text={t('tripBookings.free')}
                  disabled={!hasEditPermission || forcePayment}
                />
              </CCol>
            )}
          </Row>
          { _renderForm() }
        </>
      )}
    </>
  );
};

export default PaymentForm;