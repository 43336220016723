/**
 * Custom radio button component
 *
 * @author João Dias <jdias@ubiwhere.com>
 *
 *
 */

import React from 'react'
import { ButtonObject } from '@coreui/react/dist/components/form/CFormCheck';
import { CustomCFormCheck } from './styles'
interface PropTypes {
  checkStyle?: React.CSSProperties,
  text?: string,
  onClick?: Function,
  disabled?: boolean,
  group: string,
  value?: any,
  inline?: boolean,
  button?: ButtonObject | undefined;
  checked?: boolean
}

const CustomRadioButton = (props: PropTypes) => {

  return (
    <CustomCFormCheck
      button={props.button}
      type="radio"
      id={props.text}
      style={props.checkStyle}
      label={props.text}
      name={props.group}
      disabled={props.disabled}
      inline={props.inline}
      value={!!props.value ? props.value : null}
      onChange={e => props.onClick ? props.onClick(e) : null}
      onClick={e => props.onClick ? props.onClick(e) : null}
      checked={props.checked && props.checked === true ? true : false}
    />
  )
}

export default CustomRadioButton
