import styled from 'styled-components'
import { CustomTableRow, ActionsCell } from 'shared/genericStyledComponents'
import { CTableDataCell } from '@coreui/react-pro'

export const CustomCTableRow = styled(CustomTableRow)`

    td:first-child {
      padding-left:15px;
      width:15%;
    }
  
    td:nth-child(2){
      width:5%;
    }

    td:nth-child(3){
      width:4%;
      padding:0;
      padding-left:7px;
    }
  `

export const CustomActionsCell = styled(ActionsCell)`
  td:nth-child(2){
    width:8%;
  }
`

export const NameCell = styled(CTableDataCell)`
  cursor: pointer;
`