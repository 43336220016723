/**
 * Usertimerecorddetails scene sagas
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */
import { AnyAction, PayloadAction } from '@reduxjs/toolkit'
import { call, putResolve, takeLatest, select } from 'redux-saga/effects'
import { actions, RootState } from 'store/rootSlices'
import API from 'api'
import { toast } from 'react-toastify'
import i18n from 'i18next'
import { DateTime, Duration, Interval } from 'luxon'
import { days } from 'utils'

function* onMountSaga({ payload }: AnyAction) {

  if (payload) {

    yield putResolve(actions.UserTimeRecordDetails.getURLParams(payload.history));
    const userID = payload.id

    try {
      const userInfo = yield call(API.Collaborators.GetCollaborator, userID)

      if (userInfo) {
        yield putResolve(actions.UserTimeRecordDetails.setUser(userInfo))
        yield putResolve(actions.Dashboard.setDynamicBreadcrumb({
          path: `collaborators/timeRecord/edit/${userInfo.name}`,
          name: userInfo.name,
        }))
      }

    } catch (error) {
      toast.error(i18n.t("toasts.getUserTimeRecordError"))
    } finally {
      yield putResolve(actions.UserTimeRecordDetails.setLoading(false))
    }
  }

}

function* getUserSaga() {
  const { user } = yield select((state: RootState) => state.UserTimeRecordDetails);

  try {
    const userInfo = yield call(API.Collaborators.GetCollaborator, user.uuid)

    if (userInfo) {
      yield putResolve(actions.UserTimeRecordDetails.setUser(userInfo))
    }

  } catch (error) {
    toast.error(i18n.t("toasts.getUserInfoError"))
  }
}

function* getWorkdaysSaga({ payload }: PayloadAction<string>) {
  const { startDate, endDate } = yield select((state: RootState) => state.TimeRecord);

  if (payload) {
    try {
      const workdays = yield call(API.Collaborators.GetWorkdays, startDate, endDate, payload)

      if (workdays) {
        yield putResolve(actions.UserTimeRecordDetails.setWorkdays(workdays))
      }

    } catch (error) {
      toast.error(i18n.t("toasts.getWorkdaysError"))
    }
  }
}

function* getTimeRecordFileSaga({ payload }: PayloadAction<string>) {
  yield putResolve(actions.UserTimeRecordDetails.setExportLoading(true))

  const { startDate, endDate } = yield select((state: RootState) => state.TimeRecord);
  const { user } = yield select((state: RootState) => state.UserTimeRecordDetails);

  if (payload) {
    try {

      const file = yield call(API.Collaborators.ExportTimeRecord, startDate, endDate, payload)

      if (file) {
        yield putResolve(actions.UserTimeRecordDetails.downloadTimeRecord({ file, userName: user.name }))
        toast.success(i18n.t("toasts.downloadTimeRecordSuccess"))
      }

    } catch (error) {
      toast.error(i18n.t("toasts.downloadTimeRecordError"))
    } finally {
      yield putResolve(actions.UserTimeRecordDetails.setExportLoading(false))
    }
  }
}

function* getUserTimeRecordSaga({ payload }: AnyAction) {

  const { startDate, endDate } = yield select((state: RootState) => state.TimeRecord);
  const { days } = yield select((state: RootState) => state.UserTimeRecordDetails);
  yield putResolve(actions.UserTimeRecordDetails.setEventsLoading(true))

  if (payload.user) {

    try {
      const timeRecord = yield call(API.Collaborators.GetUserTimeRecord, startDate, endDate, payload.user)

      if (timeRecord) {
        yield putResolve(actions.UserTimeRecordDetails.setUserTimeRecord({
          timeRecord,
          days,
          ...(payload.deletedID ? { deletedID: payload.deletedID } : null)
        }))

      }

    } catch (error) {
      toast.error(i18n.t("toasts.getUserTimeRecordError"))
    } finally {
      yield putResolve(actions.UserTimeRecordDetails.setEventsLoading(false))
    }

  }
}

function* getUserTimeRecordDaySaga({ payload }: AnyAction) {

  const { days, user, workdays } = yield select((state: RootState) => state.UserTimeRecordDetails);
  const date = payload


  if (payload) {
    try {
      const timeRecord = yield call(API.Collaborators.GetUserTimeRecord, date, date, user.uuid)

      if (timeRecord) {
        yield putResolve(actions.UserTimeRecordDetails.setUserTimeRecord({
          timeRecord,
          days
        }))

        //update timeWorking on workdays
        yield putResolve(actions.UserTimeRecordDetails.updateWorkdays({ timeRecord, workdays }))
      }

    } catch (error) {
      toast.error(i18n.t("toasts.getUserTimeRecordError"))
    } finally {
      yield putResolve(actions.UserTimeRecordDetails.setEventsLoading(false))
    }

  }
}

function* onSubmitTimeRecordsSaga() {
  const { addedTimeRecords, editedTimeRecords, days, workdays, user, deletedTimeRecords } = yield select((state: RootState) => state.UserTimeRecordDetails);

  yield putResolve(actions.UserTimeRecordDetails.setSubmitLoading(true))
  yield putResolve(actions.UserTimeRecordDetails.setSubmitButtonState(true))

  const newEntries = addedTimeRecords.map((tm) => {
    const startDateDuration = Duration.fromISOTime(tm.startTimeRecord.value).toObject();
    const startDate = DateTime.fromISO(tm.day.value).plus(startDateDuration)

    const endDateDuration = Duration.fromISOTime(tm.endTimeRecord.value).toObject();
    const endDate = DateTime.fromISO(tm.day.value).plus(endDateDuration)

    return {
      /* teamMember: user.uuid, */
      entryType: tm.entryType.value,
      date: tm.day.value,
      ...(tm.unitUuid.value !== "" && tm.entryType.value === "other" ? { vehicle: tm.unitUuid.value } : null),
      ...((tm.entryType.value === "meal" || tm.entryType.value === "absense") ? null
          :
        (tm.entryType.value === "clock_in" || tm.entryType.value === "other") 
          ?
        { start: startDate.toISO({ includeOffset: false, suppressMilliseconds: true }) }
          :
        { start: endDate.toISO({ includeOffset: false, suppressMilliseconds: true }) }
      ),
      ...(tm.entryType.value !== "other" || tm.entryType.value === "meal" || tm.entryType.value === "absense" ? null : { end: endDate.toISO({ includeOffset: false, suppressMilliseconds: true }) }),
      ...(tm.description.value !== "" ? { description: tm.description.value } : null),
      ...((tm.entryType.value === "absense" && tm.absenseType.value !== "" )? { absenseType: tm.absenseType.value } : null)
    }
  })

  const editedEntries = editedTimeRecords.map((tm) => {
    let entryIsInvalid = false;
    if (tm.entryType === 'other') {
      if (days.find(d => d.date === tm.day)?.events.find(e => e.id === tm.id)) {

        const register = days.find(d => d.date === tm.day)?.events.find(e => e.id === tm.id)
        let startTime = DateTime.fromISO(register.start.value).toObject()
        let endTime = DateTime.fromISO(register.end.value).toObject()

        startTime = {
          hours: startTime.hour,
          minutes: startTime.minute
        }

        endTime = {
          hours: endTime.hour,
          minutes: endTime.minute
        }

        if (DateTime.now().plus(endTime).toMillis() <= DateTime.now().plus(startTime).toMillis()) {
          entryIsInvalid = true;
        }
      }
    }

    let startDate = null as any
    let endDate = null as any

    if (tm.start) {
      const startDateDuration = Duration.fromISOTime(tm.start).toObject();
      startDate = DateTime.fromISO(tm.day).plus(startDateDuration)
    }

    if (tm.end) {
      const endDateDuration = Duration.fromISOTime(tm.end).toObject();
      endDate = DateTime.fromISO(tm.day).plus(endDateDuration)
    }

    return {
      id: tm.id,
      ...(entryIsInvalid ? { invalid: entryIsInvalid } : null),
      ...(startDate !== null ? { start: startDate.toISO({ includeOffset: false, suppressMilliseconds: true }) } : null),
      ...(endDate !== null ? { end: endDate.toISO({ includeOffset: false, suppressMilliseconds: true }), } : null),
      ...(tm.vehicle !== undefined ? { vehicle: tm.vehicle.uuid } : null),
      ...(tm.description !== undefined ? { description: tm.description } : null),
      ...(tm.absenseType !== undefined ? { absenseType: tm.absenseType } : null)
    }
  })

  const entriesToDelete = deletedTimeRecords.map(d => {
    return {
      id: d.id,
      delete: true
    }
  })


  if (!editedEntries.find(entry => entry.invalid)) {

    try {
      const response = yield call(API.Collaborators.EditTimeRecordEntries, {
        teamMember: user.uuid,
        entries: newEntries.concat(editedEntries).concat(entriesToDelete)
      })

      if (response) {
        toast.success(i18n.t("toasts.timeRecordEventSuccessfullyEdited"))
        yield putResolve(actions.UserTimeRecordDetails.clearEditedTimeRecords())
        yield putResolve(actions.UserTimeRecordDetails.clearAddedTimeRecords())
        yield putResolve(actions.UserTimeRecordDetails.clearDeletedTimeRecords())
        yield putResolve(actions.UserTimeRecordDetails.getWorkdays(user.uuid))
        yield putResolve(actions.UserTimeRecordDetails.updateEditedUserTimeRecords({ response: response.data, days, workdays }))
        yield putResolve(actions.UserTimeRecordDetails.setSubmitButtonState(false))
      }

    } catch (error) {
      yield putResolve(actions.UserTimeRecordDetails.setSubmitButtonState(false))
      const err: any = error
      if (err.response.status === 400) {
        toast.error(i18n.t(`timeRecord.${err.response.data.errors.other[0]}`))
      }
      if (err.response.status === 500) {
        toast.error(i18n.t(`timeRecord.timeRecordUpdateError`))
      }
    }
  }
  else {
    yield putResolve(actions.UserTimeRecordDetails.setSubmitButtonState(false))
    toast.error(i18n.t("toasts.invalidFields"))
    yield putResolve(actions.UserTimeRecordDetails.onEditTimeRecordsValidations({ editedEntries, days }))
  }

}

function* submitClockInSaga({ payload }: PayloadAction<string>) {

  const { user } = yield select((state: RootState) => state.UserTimeRecordDetails);

  const finalForm = {
    teamMember: user.uuid,
  }

  try {
    const response = yield call(API.Collaborators.CreateUserClockIn, finalForm)

    if (response) {
      toast.success(i18n.t("toasts.successCreateClockIn"))
      yield putResolve(actions.UserTimeRecordDetails.getUserTimeRecordDay(payload))
      yield putResolve(actions.UserTimeRecordDetails.getUser())
    }

  } catch (error) {
    toast.error(i18n.t("toasts.errorCreateClockIn"))
  } finally {
    yield putResolve(actions.UserTimeRecordDetails.setEventsLoading(false))
  }

}

function* submitClockOutSaga({ payload }: PayloadAction<string>) {

  const { user } = yield select((state: RootState) => state.UserTimeRecordDetails);

  const finalForm = {
    teamMember: user.uuid,
  }

  try {

    const response = yield call(API.Collaborators.CreateUserClockOut, finalForm)

    if (response) {
      toast.success(i18n.t("toasts.successCreateClockOut"))
      yield putResolve(actions.UserTimeRecordDetails.getUserTimeRecordDay(payload))
      yield putResolve(actions.UserTimeRecordDetails.getUser())
    }

  } catch (error) {
    toast.error(i18n.t("toasts.errorCreateClockOut"))
  } finally {
    yield putResolve(actions.UserTimeRecordDetails.setEventsLoading(false))
  }
}

function* deleteTimeRecordEntrySaga({ payload }: AnyAction) {

  const { user } = yield select((state: RootState) => state.UserTimeRecordDetails);

  if (payload) {
    try {

      const res = yield call(API.Collaborators.DeleteTimeRecordEntry, payload)

      if (res.status === 200) {
        toast.success(i18n.t("toasts.deleteTimeRecordEntrySuccess"))
        yield putResolve(actions.UserTimeRecordDetails.clearEditedTimeRecords())
        yield putResolve(actions.UserTimeRecordDetails.getWorkdays(user.uuid))
        yield putResolve(actions.UserTimeRecordDetails.getUserTimeRecord({
          user: user.uuid,
          deletedID: payload
        }))
      }


    } catch (error) {
      toast.error(i18n.t("toasts.errorDeletingTimeRecordEntry"))
    }
  }
}

function* getURLParamsSaga({ payload }: AnyAction) {

  let params = new URLSearchParams(payload.location.search)

  const {
    hasDateParams,
  } = yield select((state: RootState) => state.UserTimeRecordDetails);

  const {
    startDate,
    endDate
  } = yield select((state: RootState) => state.TimeRecord);

  if (params.toString() !== "") {
    yield putResolve(actions.UserTimeRecordDetails.populateFiltersOnMount({
      startDate: params.get('startDate') ?? DateTime.now().toJSDate(),
      endDate: params.get('endDate') ?? DateTime.now().toJSDate()
    }))

    yield putResolve(actions.UserTimeRecordDetails.setHasDateParams(!hasDateParams));
  }
  else {
    //state.hasDateParams has to be different from the last known state.hasDateParams to force a reRender on datepicker
    //this reRender must be done so the datepicker changes its label
    yield putResolve(actions.UserTimeRecordDetails.setHasDateParams(!hasDateParams));
    params.set('startDate', startDate)
    params.set('endDate', endDate)
    payload.replace({ search: (params).toString() })
  }
}

function* setDatesIntervalSaga({ payload }: AnyAction) {

  if (payload.e.startDate && payload.e.endDate) {
    let interval = Interval.fromDateTimes(payload.e.startDate, payload.e.endDate);
    const arr = Array.from(days(interval))

    let params = new URLSearchParams(payload.history.location.search);
    payload.e.startDate !== "" ? params.set('startDate', arr[0]?.toISO().slice(0, 10)) : params.delete('startDate')
    payload.e.endDate !== "" ? params.set('endDate', arr[arr.length - 1]?.toISO().slice(0, 10)) : params.delete('endDate')
    payload.history.replace({ search: (params).toString() })

    //actions to update workdays, days and entries based on the new dates
    yield putResolve(actions.TimeRecord.setStartDate(arr[0]?.toISO().slice(0, 10)))
    yield putResolve(actions.TimeRecord.setEndDate(arr[arr.length - 1]?.toISO().slice(0, 10)))
    yield putResolve(actions.UserTimeRecordDetails.setDays(arr.reverse().map((d, idx) => {
      return ({
        date: d.toISO().slice(0, 10),
        events: [],
        expand: idx === 0 ? true : false
      })
    })))
    yield putResolve(actions.UserTimeRecordDetails.getWorkdays(payload.userId))
    yield putResolve(actions.UserTimeRecordDetails.getCommissions(payload.userId))
    yield putResolve(actions.UserTimeRecordDetails.getUserTimeRecord({ user: payload.userId }))
  }
}

function* getCommissionsSaga({ payload }: AnyAction) {
  const { startDate, endDate } = yield select((state: RootState) => state.TimeRecord);

  if (payload) {
    try {
      const commissions = yield call(API.Collaborators.GetCollaboratorCommissions, payload, startDate, endDate)

      if (commissions) {
        yield putResolve(actions.UserTimeRecordDetails.setCommissions(commissions))
      }

    } catch (error) {
      toast.error(i18n.t("toasts.getCommissionsError"))
    }
  }
}

function* onUnmountSaga() {
  yield putResolve(actions.Dashboard.setDynamicBreadcrumb(null))
}

export default function* watcherSignin() {
  yield takeLatest('Usertimerecorddetails/onMount', onMountSaga)
  yield takeLatest('Usertimerecorddetails/onUnmount', onUnmountSaga)
  yield takeLatest('Usertimerecorddetails/getURLParams', getURLParamsSaga);
  yield takeLatest('Usertimerecorddetails/getUser', getUserSaga)
  yield takeLatest('Usertimerecorddetails/getWorkdays', getWorkdaysSaga)
  yield takeLatest('Usertimerecorddetails/getUserTimeRecord', getUserTimeRecordSaga)
  yield takeLatest('Usertimerecorddetails/getUserTimeRecordDay', getUserTimeRecordDaySaga)
  yield takeLatest('Usertimerecorddetails/getTimeRecordFile', getTimeRecordFileSaga)
  yield takeLatest('Usertimerecorddetails/onSubmitTimeRecords', onSubmitTimeRecordsSaga)
  yield takeLatest('Usertimerecorddetails/submitClockIn', submitClockInSaga)
  yield takeLatest('Usertimerecorddetails/submitClockOut', submitClockOutSaga)
  yield takeLatest('Usertimerecorddetails/deleteTimeRecordEntry', deleteTimeRecordEntrySaga)
  yield takeLatest('Usertimerecorddetails/setDatesInterval', setDatesIntervalSaga);
  yield takeLatest('Usertimerecorddetails/getCommissions', getCommissionsSaga);

}


