/**
 * Passengertypelisttablerow component
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */
import React from 'react'
import { ActionsCell, Abrev } from 'shared/genericStyledComponents'
import { TableRowPropTypes } from 'types/misc'
import theme from 'ui/theme'
import { CTableDataCell } from '@coreui/react-pro'
import ChevronRight from 'assets/icons/chevron_right'
import { PassengerTypeListItem } from 'types/trips/passengerTypes'
import { actions } from 'store/rootSlices'
import { useDispatch } from 'react-redux';
import { CustomChevronCell } from '../../styles'
import { CustomCTableRow } from './styles'


type PropTypes = TableRowPropTypes & {
  id?: string,
  passengerType: PassengerTypeListItem,
  permission: boolean
}

const Passengertypelisttablerow = (props: PropTypes) => {

  const dispatch = useDispatch()
  const {
    permission,
    passengerType: {
      name,
      shortName,
      id
    }
  } = props

  return (
    <CustomCTableRow id={props.id}>
      <Abrev color={theme().gray200Color} id={`${props.id}-shortName`} onClick={() => props.onClick()}>{shortName}</Abrev>
      <CTableDataCell>{name}</CTableDataCell>
      <ActionsCell nopointer>
        {props.actions}
        <CustomChevronCell id={`${props.id}-chevron`} >
        {
            permission &&
            <a href={`/viagens/configuracoes/editar-tipo/${id}`} onClick={(e) => {
              e.preventDefault()
              dispatch(actions.App.navigateTo(`/viagens/configuracoes/editar-tipo/${id}`))
              }}>
              <ChevronRight></ChevronRight>
            </a>
        }
        </CustomChevronCell>
      </ActionsCell>
    </CustomCTableRow>
  )
}

export default Passengertypelisttablerow