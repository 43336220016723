/**
 * Tripcampaigncodesform scene utils
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */
import * as Check from 'validations'

export const CampaignCodeFormValidations = {
    isActive: [Check.isRequired],
    expireAt: [Check.isRequired],
    discount: [],
    isDiscountPercentage: [],
    destinationName: [Check.isRequired],
    destinationEmail: [Check.isRequired],
    destinationBody: [Check.isRequired],
}

export const CampaignCodeFormValidationsEdit = {
    isActive: [Check.isRequired],
    expireAt: [Check.isRequired],
    discount: [],
    isDiscountPercentage: [],
    destinationName: [Check.isRequired],
    destinationEmail: [Check.isRequired],
    destinationBody: [Check.isRequired],
}
