import { call, putResolve, select } from 'redux-saga/effects';
import { actions, RootState } from 'store/rootSlices';
import API from 'api';
import { toast } from 'react-toastify';
import i18n from 'i18next';


export function* searchPartnersSaga() {
  try {
    const { searchPartnerText, partnerForm } = yield select((state: RootState) => state.BookingForm);

    let partners = yield call(API.Entities.GetEntities, 1, searchPartnerText, {
      direction: null,
      field: null,
    });
    if (partners && partners.results.length > 0) {
      yield putResolve(actions.BookingForm.setSearchPartnerResults(partners.results));
    } else {
      yield putResolve(
        actions.BookingForm.onPartnerFormChange({
          ...partnerForm,
          partner: { value: '', message: '', valid: true },
        })
      );
    }
  } catch {
    toast.error(i18n.t('toasts.customersGetError'));
  }
}

export function* fetchPartnersSaga() {
  try {
    const { searchPartnerText, partnersPage, partners } = yield select(
      (state: RootState) => state.BookingForm
    );

    let newPartners = yield call(API.Entities.GetEntities, partnersPage, searchPartnerText, {
      direction: null,
      field: null,
    });
    if (newPartners && newPartners.results.length > 0) {
      yield putResolve(
        actions.BookingForm.setPartners({ partners, newPartners: newPartners.results })
      );

      if (newPartners.next) {
        yield putResolve(actions.BookingForm.setPartnersPage(partnersPage + 1));
      } else {
        yield putResolve(actions.BookingForm.setIsPartnersLastPage(true));
      }
    }
  } catch {
    toast.error(i18n.t('toasts.customersGetError'));
  }
}
