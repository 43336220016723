import * as React from "react"
import theme from "ui/theme";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M2.6735 4.52L7.68016 6.66667L2.66683 6L2.6735 4.52ZM7.6735 10.3333L2.66683 12.48V11L7.6735 10.3333ZM1.34016 2.5L1.3335 7.16667L11.3335 8.5L1.3335 9.83333L1.34016 14.5L15.3335 8.5L1.34016 2.5Z"
                fill={props.fill ? props.fill : theme().primary}
            />
        </svg>
    )
}

export default SvgComponent;
