import styled from 'styled-components'
import { CCol } from '@coreui/react-pro'
import theme from 'ui/theme'


export const BoldText = styled.p`
  margin:0px;
  font-weight: 700;
`

export const WarningCol = styled(CCol)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

export const EditWarning = styled.div`
  width: 70%;
  text-align:center;
  display:flex;
  justify-content:center;
  align-items: center;
  flex-wrap:wrap;
  padding: 10px;
  border-radius: 4px;
  background-color: ${theme().warning};
`
