/**
 * FilterTime component
 *
 * @author João Flores <jflores@ubiwhere.com>
 *
 *
 */

import React, { useState, useEffect } from 'react'
import Schedule from 'assets/icons/schedule'
import theme from 'ui/theme'
import InputRange from 'react-input-range';
import "react-input-range/lib/css/index.css";
import * as Styles from './styles'

interface PropTypes {
    id?: string,
    label: string,
    onChange: Function,
    defaultValue?: { min: number, max: number },
    min?: number,
    max?: number,
    step?: number,
    allowSameValues?: boolean,
}

const ref = React.createRef<HTMLDivElement>();

const FilterTime = (props: PropTypes) => {
    const parseLabel = value => {
        if(isNaN(Number(value))) {
            let minH = Math.floor(value.min / 3600),
                minM = Math.floor(value.min % 3600 / 60),
                maxH = Math.floor(value.max / 3600),
                maxM = Math.floor(value.max % 3600 / 60);

            return `${minH < 10 ? `0${minH}` : minH}:${minM < 10 ? `0${minM}` : minM} - ${maxH < 10 ? `0${maxH}` : maxH}:${maxM < 10 ? `0${maxM}` : maxM}`;
        }
        else {
            let h = Math.floor(value / 3600),
                m = Math.floor(value % 3600 / 60);

            return `${h < 10 ? `0${h}` : h}:${m < 10 ? `0${m}` : m}`
        }
    };

    const checkMinMaxOverflow = value => {
        if(isNaN(Number(value))) {
            let val = {
                min: value.min < min ? min : value.min,
                max: value.max > max ? max : value.max,
            };
            setValue(val);
        }
        else {
            setValue(value < min ? min : (value > max ? max : value));
        }
    };

    const parseReturnValue = value => {
        if(isNaN(Number(value))) {
            return {start: `${parseLabel(value.min)}:00`, end: `${parseLabel(value.max)}:59`};
        }
        else {
            return {start: `${parseLabel(value)}:00`, end: `${parseLabel(value)}:59`};
        }
    };

    let timeOutId: number;
    const handleBlur = () => {
        timeOutId = setTimeout(() => { setOpen(false) });
    }
    const handleFocus = () => { clearTimeout(timeOutId) };
    const min = props.min || 0;
    const max = props.max || 86340;

    const [isOpen, setOpen] = useState<boolean>(false);
    const [currentLabel, setCurrentLabel] = useState("");
    const [value, setValue] = useState<number | { min: number, max: number }>({min: 0, max:1});

    const isDefaultSelected = currentLabel === props.label;

    useEffect(() => {
        if(props.defaultValue && !isNaN(props.defaultValue.min) && !isNaN(props.defaultValue.max)) {
            setCurrentLabel(parseLabel(props.defaultValue));
            setValue(props.defaultValue);
        }
        else {
            setCurrentLabel(props.label);
            setValue({min: min, max: max});
        }
    }, [props.defaultValue, props.label, min, max]);

    return (
        <Styles.Wrapper ref={ref} onFocus={() => handleFocus()} onBlur={() => handleBlur()}>
            <Styles.SelectContainer id={props.id} tabIndex={0} isdefaultselected={isDefaultSelected} onClick={() => setOpen(!isOpen)} >
                {React.cloneElement(<Schedule />, { fill: isDefaultSelected ? theme().gray500Color : theme().primary })}
                <Styles.CurrentValueContainer>{currentLabel}</Styles.CurrentValueContainer>
                <Styles.ArrowContainer>
                    <Styles.CustomDropdownArrow open={isOpen} fill={isDefaultSelected ? theme().gray500Color : theme().primary} isdefaultselected={isDefaultSelected} />
                </Styles.ArrowContainer>
            </Styles.SelectContainer>
            <Styles.RangeContainer open={isOpen}>
                <InputRange
                    maxValue={max}
                    minValue={min}
                    step={props.step || 900}
                    formatLabel={value => parseLabel(value)}
                    onChangeComplete={value => {
                        setCurrentLabel(`${parseLabel(value)}`);
                        props.onChange(parseReturnValue(value));
                    }}
                    onChange={value => {
                        checkMinMaxOverflow(value);
                    }}
                    value={value}
                    allowSameValues={true}
                />
            </Styles.RangeContainer>
        </Styles.Wrapper>
    )
}

export default FilterTime;