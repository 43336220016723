import axios from 'axios'
import endpoints from 'api/endpoints'
import { decamelizeKeys  } from 'humps'
import { generateUrl, generateSortString } from 'utils'
import { SortType } from 'types/misc'

/* import { generateUrl } from 'utils'
import { toast } from 'react-toastify'
import i18n from 'i18next' */

const GetCircuits = (page: number, searchTerm: string, sort?: SortType) => {
    const searchTermQS = `${searchTerm ? `&search=${searchTerm}` : ''}`;

    let endpoint = `${endpoints.TRIPS.CIRCUITS.GET_CIRCUITS}?page=${page}${searchTermQS}`;
    
    if (sort && sort.field !== null && sort.direction !== null && sort.direction !== "") {
        endpoint += generateSortString(sort);
    }
    return axios.get(endpoint)
        .then(res => res.data)
        .catch(err => {
        })
}

const GetAllCircuits = () => {
    return axios.get(endpoints.TRIPS.CIRCUITS.GET_ALL_CIRCUITS)
        .then(res => res.data)
        .catch(err => {
        })
}

const GetCircuit = (id) => {
    const endpoint = generateUrl(endpoints.TRIPS.CIRCUITS.GET_CIRCUIT, { id })
    return axios.get(endpoint)
        .then(res => res.data)
        .catch(err => {
        })
}

const CreateCircuit = (params) => {
    const token = window.localStorage.getItem('access_token')

    const finalParams = {
        name: params.circuitForm.name.value,
        outboundStops: params.outboundStops.map((outboundStop) => {
            return {
                id: outboundStop.stop.id,
                time: outboundStop.time.value,
            }
        }).filter(item => item.time !== ''),
        inboundStops: params.inboundStops.map((inboundStop) => {
            return {
                id: inboundStop.stop.id,
                time: inboundStop.time.value
            }
        }).filter(item => item.time !== ''),
    } 
    const decMLZParams = decamelizeKeys(finalParams)
    

    return axios.create({
        headers:{
            'Authorization': 'Bearer ' + token, 
            'Content-Type': 'application/json',
        }
    }).post(endpoints.TRIPS.CIRCUITS.CREATE_CIRCUIT, decMLZParams)
        .then(res => res.data)
        .catch(err => {throw err})
}

const EditCircuit = (id, params) => {
    const endpoint = generateUrl(endpoints.TRIPS.CIRCUITS.EDIT_CIRCUIT, { id })
    const token = window.localStorage.getItem('access_token')

    const finalParams = {
        id: Number(id),
        name: params.circuitForm.name.value,
        isActive: params.circuitForm.isActive.value,
        inboundStops: params.inboundStops.map((inboundStop) => {
            return {
                id: inboundStop.stop.id,
                time: inboundStop.time.value,
                isActive: inboundStop.isActive.value  
            }
        }).filter(item => item.time !== ''),
        outboundStops: params.outboundStops.map((outboundStop) => {
            return {
                id: outboundStop.stop.id,
                time: outboundStop.time.value,
                isActive: outboundStop.isActive.value  
            }
        }).filter(item => item.time !== '')
    }

    const decMLZParams = decamelizeKeys(finalParams)
    
    return axios.create({

        headers:{
            'Authorization': 'Bearer ' + token, 
            'Content-Type': 'application/json',
        }
    }).patch(endpoint, decMLZParams)
        .then(res => res.data)
        .catch(err => {throw err})
}

export default {
    GetAllCircuits,
    GetCircuits,
    GetCircuit,
    CreateCircuit,
    EditCircuit,
}