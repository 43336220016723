import config from "config";

// switch (process.env.NODE_ENV) {
// 	case 'mock':
// 		API_URL = '/api'
// 		break
// }

const {
  API_INVOICING_URL,
  API_TRIPS_URL,
  API_AUTH_URL,
  API_FLEET_URL,
  API_USERS_URL,
  API_TRANS_URL,
} = config;

export default {
  AUTH: {
    LOGIN: `${API_AUTH_URL}`,
    ACCEPT_TERMS: `${API_AUTH_URL}/users/{uuid}`, // to reset password on modal - change name later
    ACCEPT_TERMS_CORRECT: `${API_AUTH_URL}/users/{uuid}/accept_terms`,
    SEND_EMAIL: `${API_AUTH_URL}/users/reset`,
    RESET_PASSWORD: `${API_AUTH_URL}/users/reset/{token}`,
    REFRESH_TOKEN: `${API_AUTH_URL}/refresh`,
    GET_USER: `${API_AUTH_URL}/users/{uuid}`,
    GET_USERS: `${API_AUTH_URL}/users`,
    EDIT_USER: `${API_AUTH_URL}/users/{id}`,
    CREATE_USER: `${API_AUTH_URL}/users`,
    DELETE_USER: `${API_AUTH_URL}/users/{uuid}`,
  },
  COLLABORATORS: {
    TEAMS: `${API_USERS_URL}/team_members/settings/groups`,
    GET_COLLABORATOR_PUBLIC_INFO: `${API_USERS_URL}/team_members/{uuid}/public`,
    GET_COLLABORATOR: `${API_USERS_URL}/team_members/{uuid}`,
    GET_COLLABORATORS: `${API_USERS_URL}/team_members`,
    CREATE_COLLABORATOR: `${API_USERS_URL}/team_members`,
    DELETE_COLLABORATOR: `${API_USERS_URL}/team_members/{uuid}`,
    EDIT_COLLABORATOR: `${API_USERS_URL}/team_members/{uuid}`,
    GET_COLLABORATOR_TYPES: `${API_USERS_URL}/team_members/settings/roles`,
    GET_HISTORY_LOG: `${API_USERS_URL}/team_members/{id}/history`,
    COLLABORATOR_COMMISSIONS_LIST: `${API_USERS_URL}/team_members/{uuid}/commissions`,
    GET_COLLABORATOR_DEFAULT_COMMISSIONS: `${API_USERS_URL}/team_members/settings/commissions`,
    EDIT_COLLABORATOR_DEFAULT_COMMISSIONS: `${API_USERS_URL}/team_members/settings/commissions/edit-commissions`,
    TIME_RECORD: {
      TIME_RECORD_LIST: `${API_USERS_URL}/workdays`,
      TIME_RECORDS: `${API_USERS_URL}/workdays/summary`,
      TIME_RECORDS_EXPORT: `${API_USERS_URL}/workdays/export`,
      EXPORT_USER_TIME_RECORD: `${API_USERS_URL}/workdays-detail/export`,
      GET_USER_TIME_RECORD_ENTRIES: `${API_USERS_URL}/workdays-detail`,
      USER_CLOCK_STATUS: `${API_USERS_URL}/clock/{id}`,
      USER_TIME_RECORD_CLOCK_IN: `${API_USERS_URL}/clock/clockin`,
      USER_TIME_RECORD_CLOCK_OUT: `${API_USERS_URL}/clock/clockout`,
      BULK_EDIT_USER_TIME_RECORD_ENTRIES: `${API_USERS_URL}/workdays-detail/bulk_update`,
      DELETE_USER_TIME_RECORD_ENTRY: `${API_USERS_URL}/workdays-detail/{id}`,
      APPROVE_TIME_RECORDS: `${API_USERS_URL}/workdays/approve_or_reject`,
      DISAPPROVE_TIME_RECORDS: `${API_USERS_URL}/workdays/approve_or_reject`,
    }
  },
  FLEET: {
    FLEET_URL: `${API_FLEET_URL}/vehicles`,
    VEHICLE_URL: `${API_FLEET_URL}/vehicles/{id}`,
    FUEL_URL: `${API_FLEET_URL}/fuel`,
    SINGLE_FUELLING_URL: `${API_FLEET_URL}/fuel/{id}`,
    MAINTENANCES_URL: `${API_FLEET_URL}/maintenances`,
    SINGLE_MAINTENANCE_URL: `${API_FLEET_URL}/maintenances/{id}`,
    DOCUMENT_SETTINGS_URL: `${API_FLEET_URL}/vehicles/settings/document`,
    SINGLE_DOCUMENT_SETTINGS_URL: `${API_FLEET_URL}/vehicles/settings/document/{id}`,
    VEHICLE_TYPE_SETTINGS_URL: `${API_FLEET_URL}/vehicles/settings/type`,
    CLASS_SETTINGS_URL: `${API_FLEET_URL}/vehicles/settings/class`,
    FUEL_TYPE_SETTINGS_URL: `${API_FLEET_URL}/fuel/settings/type`,
    FUEL_LOCATIONS_URL: `${API_FLEET_URL}/fuel/stations`,
    SINGLE_FUEL_LOCATION_URL: `${API_FLEET_URL}/fuel/stations/{id}`,
    FUEL_PAYMENT_TYPES: `${API_FLEET_URL}/fuel/payment-types`,
    FLEET_DOCUMENTS_URL: `${API_FLEET_URL}/vehicles/documents`,
    SINGLE_DOCUMENT_URL: `${API_FLEET_URL}/vehicles/documents/{id}`,
    LIST_MAINTENANCE_DOCS: `${API_FLEET_URL}/maintenances/documents`,
    GET_MAINTENANCE_DOC: `${API_FLEET_URL}/maintenances/documents/{id}`,
    CREATE_MAINTENANCE_DOC: `${API_FLEET_URL}/maintenances/documents`,
    EDIT_MAINTENANCE_DOC: `${API_FLEET_URL}/maintenances/documents/{id}`,
    DELETE_MAINTENANCE_DOC: `${API_FLEET_URL}/maintenances/documents/{id}`,
    LIST_FUEL_DOCS: `${API_FLEET_URL}/fuel/documents`,
    GET_FUEL_DOC: `${API_FLEET_URL}/fuel/documents/{id}`,
    CREATE_FUEL_DOC: `${API_FLEET_URL}/fuel/documents`,
    EDIT_FUEL_DOC: `${API_FLEET_URL}/fuel/documents/{id}`,
    DELETE_FUEL_DOC: `${API_FLEET_URL}/fuel/documents/{id}`,
    FUEL_PRICE_LITRE: `${API_FLEET_URL}/fuel/stations/{id}/price-litre`,
    COMSUMPTIONS_LIST: `${API_FLEET_URL}/fuel/consumptions`,
  },
  INVOICING: {
    EXPORT_INVOICES: `${API_INVOICING_URL}/invoices/export`,
    GET_INVOICES: `${API_INVOICING_URL}/invoices`,
    CREATE_INVOICE: `${API_INVOICING_URL}/invoices`,
    EDIT_INVOICE: `${API_INVOICING_URL}/invoices/{id}`,
    GET_INVOICE: `${API_INVOICING_URL}/invoices/{id}`,
    GET_ENTRIES: `${API_INVOICING_URL}/balance`,
    GET_ENTRY: `${API_INVOICING_URL}/balance/{id}`,
    CREATE_ENTRY: `${API_INVOICING_URL}/balance`,
    EDIT_ENTRY: `${API_INVOICING_URL}/balance/{id}`,
    LIST_ENTRY_PHOTOS: `${API_INVOICING_URL}/balance/photos`,
    GET_ENTRY_PHOTOS: `${API_INVOICING_URL}/balance/photos/{id}`,
    CREATE_ENTRY_PHOTO: `${API_INVOICING_URL}/balance/photos`,
    EDIT_ENTRY_PHOTO: `${API_INVOICING_URL}/balance/photos/{id}`,
    DELETE_ENTRY_PHOTO: `${API_INVOICING_URL}/balance/photos/{id}`,
    PRINT_TICKET: `${API_INVOICING_URL}/balance/{id}/print-ticket`,
    OBTAIN_TICKET: `${API_INVOICING_URL}/balance/{id}/ticket`,
    EXPORT_STATEMENTS_LIST: `${API_INVOICING_URL}/balance/export`,
    SYNC_INVOICE: `${API_INVOICING_URL}/invoices/{id}/sync-invoice`,
    CASH_MANAGEMENT: {
      LIST_STATEMENTS: `${API_INVOICING_URL}/statements`,
      VIEW_STATEMENT: `${API_INVOICING_URL}/statements/{id}`,
      OPEN_STATEMENT: `${API_INVOICING_URL}/statements/{id}/open-statement`,
      CLOSE_STATEMENT: `${API_INVOICING_URL}/statements/{id}/close-statement`,
      APPROVE_STATEMENTS: `${API_INVOICING_URL}/statements/approve-statements`,
      DISAPPROVE_STATEMENTS: `${API_INVOICING_URL}/statements/reject-statements`,
      STATEMENT_TOTALS: `${API_INVOICING_URL}/statements/totals`,
      EXPORT_STATEMENTS: `${API_INVOICING_URL}/statements/export`,
      LIST_STATEMENT_PHOTOS: `${API_INVOICING_URL}/statements/photos`,
      CREATE_STATEMENT_PHOTO: `${API_INVOICING_URL}/statements/photos`,
      GET_STATEMENT_PHOTO: `${API_INVOICING_URL}/statements/photos/{id}`,
      EDIT_STATEMENT_PHOTO: `${API_INVOICING_URL}/statements/photos/{id}`,
      DELETE_STATEMENT_PHOTO: `${API_INVOICING_URL}/statements/photos/{id}`,
      STATEMENT_HISTORY: `${API_INVOICING_URL}/statements/{id}/history`,
      INCOME_EXPENSES: `${API_INVOICING_URL}/balance/income-expenses`,
    },
  },
  TRIPS: {
    SETTINGS: {
      CANCEL_RESCHEDULE: `${API_TRIPS_URL}/settings/cancel_reschedule`,
      EDIT_CANCEL_RESCHEDULE: `${API_TRIPS_URL}/settings/cancel_reschedule/{id}`,
      TAXES: `${API_TRIPS_URL}/settings/tax`,
      CARGO_TYPES: `${API_TRIPS_URL}/settings/cargo_type`,
      PAYEMENT_TYPES: `${API_INVOICING_URL}/settings/payment_type`,
      PAYEMENT_SOURCES: `${API_INVOICING_URL}/settings/payement_source`,
      PAYEMENT_ENTRIES: `${API_INVOICING_URL}/settings/balance_entry_type`,
      COUNTRIES: `${API_TRIPS_URL}/settings/countries`,
    },
    PLANNINGS: {
      GET_PLANNINGS: `${API_TRIPS_URL}/planning`,
      GET_ALL_PLANNINGS: `${API_TRIPS_URL}/services`,
      GET_BOOKINGS_BY_TRIP: `${API_TRIPS_URL}/bookings_by_trip`,
      GET_ELIGIBLE_BOOKINGS_BY_TRIP: `${API_TRIPS_URL}/bookings_by_trip/eligible-bookings`,
      CREATE_PLANNING: `${API_TRIPS_URL}/planning`,
      EDIT_PLANNING: `${API_TRIPS_URL}/planning/{id}`,
      DELETE_PLANNING: `${API_TRIPS_URL}/planning/{id}`,
      GET_PLANNING: `${API_TRIPS_URL}/planning/{id}`,
      GET_TRIP_DETAILS: `${API_TRIPS_URL}/planning/{id}/trip-details`,
      ADD_REINFORCEMENT: `${API_TRIPS_URL}/planning/{id}/add_reinforcement`,
      GET_LEGS: `${API_TRIPS_URL}/planning/legs`,
      MANAGE_VEHICLE: `${API_TRIPS_URL}/planning/{id}/manage-vehicle`,
      REMOVE_VEHICLE: `${API_TRIPS_URL}/planning/{id}/remove-vehicle`,
      REPLACE_VEHICLE: `${API_TRIPS_URL}/planning/{id}/replace-vehicle`,
      GET_PLANNING_LEGS: `${API_TRIPS_URL}/planning/{id}/legs`,
      EDIT_PLANNING_LEGS: `${API_TRIPS_URL}/planning/{id}/update_legs`
    },
    BOOKINGS: {
      GET_BOOKINGS: `${API_TRIPS_URL}/bookings`,
      CREATE_BOOKING: `${API_TRIPS_URL}/bookings`,
      EDIT_BOOKING: `${API_TRIPS_URL}/bookings/{id}`,
      GET_BOOKING: `${API_TRIPS_URL}/bookings/{id}`,
      GET_BOOKING_TICKET: `${API_TRIPS_URL}/bookings/{id}/ticket`,
      GET_PAYMENT_SOURCES: `${API_INVOICING_URL}/settings/payment_source`,
      GET_PAYMENT_TYPES: `${API_INVOICING_URL}/settings/payment_type`,
      SEND_BOOKING_TICKET: `${API_TRIPS_URL}/bookings/{id}/resend-ticket`,
      GET_CANCEL_RESCHEDULE_SETTINGS: `${API_TRIPS_URL}/settings/cancel_reschedule`,
      EXPORT_BOOKINGS: `${API_TRIPS_URL}/bookings/export`,
      CANCEL_BOOKING: `${API_TRIPS_URL}/bookings/{uuid}/cancel`
    },
    CHECK_INS: {
      GET_CHECKINS: `${API_TRIPS_URL}/passenger-control`,
      GET_TOTAL_CHECKINS: `${API_TRIPS_URL}/passenger-control/total-checkins`,
      EDIT_CHECKIN: `${API_TRIPS_URL}/checkin/{id}`,
      CREATE_CHECKIN: `${API_TRIPS_URL}/checkin`,
      DELETE_CHECKIN: `${API_TRIPS_URL}/checkin/{id}`
    },
    SERVICES: {
      GET_ALL_SERVICES: `${API_TRIPS_URL}/services`,
      GET_SERVICES: `${API_TRIPS_URL}/services`,
      GET_SERVICE: `${API_TRIPS_URL}/services/{id}`,
      CREATE_SERVICE: `${API_TRIPS_URL}/services`,
      EDIT_SERVICE: `${API_TRIPS_URL}/services/{id}`,
    },
    CIRCUITS: {
      GET_ALL_CIRCUITS: `${API_TRIPS_URL}/circuits`,
      GET_CIRCUITS: `${API_TRIPS_URL}/circuits`,
      GET_CIRCUIT: `${API_TRIPS_URL}/circuits/{id}`,
      CREATE_CIRCUIT: `${API_TRIPS_URL}/circuits`,
      EDIT_CIRCUIT: `${API_TRIPS_URL}/circuits/{id}`,
    },
    CATEGORIES: {
      GET_ALL_CATEGORIES: `${API_TRIPS_URL}/categories`,
      GET_CATEGORIES: `${API_TRIPS_URL}/categories`,
      GET_CATEGORY: `${API_TRIPS_URL}/categories/{id}`,
      CREATE_CATEGORY: `${API_TRIPS_URL}/categories`,
      EDIT_CATEGORY: `${API_TRIPS_URL}/categories/{id}`,
    },
    CAMPAIGNS: {
      GET_CAMPAIGNS: `${API_TRIPS_URL}/campaigns`,
      GET_ALL_CAMPAIGNS: `${API_TRIPS_URL}/campaigns`,
      GET_CAMPAIGN: `${API_TRIPS_URL}/campaigns/{id}`,
      GET_CAMPAIGN_CODES: `${API_TRIPS_URL}/campaigns/codes?campaign={id}`,
      GET_CAMPAIGN_CODE: `${API_TRIPS_URL}/campaigns/codes/{id}`,
      CREATE_CAMPAIGN: `${API_TRIPS_URL}/campaigns`,
      CREATE_CAMPAIGN_CODE: `${API_TRIPS_URL}/campaigns/codes`,
      EDIT_CAMPAIGN: `${API_TRIPS_URL}/campaigns/{id}`,
      UPDATE_CAMPAIGN_CODE: `${API_TRIPS_URL}/campaigns/codes/{id}`,
    },
    EXTRAS: {
      GET_ALL_EXTRAS: `${API_TRIPS_URL}/extras`,
      GET_EXTRAS: `${API_TRIPS_URL}/extras`,
      GET_EXTRA: `${API_TRIPS_URL}/extras/{id}`,
      CREATE_EXTRA: `${API_TRIPS_URL}/extras`,
      CREATE_EXTRA_PHOTO: `${API_TRIPS_URL}/extras/photos`,
      EDIT_EXTRA: `${API_TRIPS_URL}/extras/{id}`,
      EDIT_EXTRA_PHOTOS: `${API_TRIPS_URL}/extras/photos/{id}`,
      UPLOAD_ICON: `${API_TRIPS_URL}/extras/{id}/icon`,
    },
    PASSENGER_TYPES: {
      GET_ALL_PASSENGER_TYPES: `${API_TRIPS_URL}/passenger_types`,
      GET_PASSENGER_TYPES: `${API_TRIPS_URL}/passenger_types`,
      GET_PASSENGER_TYPE: `${API_TRIPS_URL}/passenger_types/{id}`,
      CREATE_PASSENGER_TYPE: `${API_TRIPS_URL}/passenger_types`,
      EDIT_PASSENGER_TYPE: `${API_TRIPS_URL}/passenger_types/{id}`,
    },
    LOCALS: {
      GET_ALL_STOPS: `${API_TRIPS_URL}/stops`,
      GET_STOPS: `${API_TRIPS_URL}/stops`,
      GET_STOP: `${API_TRIPS_URL}/stops/{id}`,
      GET_STOP_PHOTOS: `${API_TRIPS_URL}/stops/photos?stop={i}`,
      CREATE_STOP: `${API_TRIPS_URL}/stops`,
      CREATE_STOP_PHOTO: `${API_TRIPS_URL}/stops/photos`,
      EDIT_STOP: `${API_TRIPS_URL}/stops/{id}`,
      EDIT_STOP_PHOTOS: `${API_TRIPS_URL}/stops/photos/{id}`,
      DELETE_STOP_PHOTOS: `${API_TRIPS_URL}/stops/photos/{id}`
    },
    ACTIVITIES: {
      GET_ALL_ACTIVITIES: `${API_TRIPS_URL}/activities`,
      GET_ACTIVITIES: `${API_TRIPS_URL}/activities`,
      GET_ACTIVITY: `${API_TRIPS_URL}/activities/{id}`,
      CREATE_ACTIVITY: `${API_TRIPS_URL}/activities`,
      CREATE_ACTIVITY_PHOTO: `${API_TRIPS_URL}/activities/photos`,
      EDIT_ACTIVITY: `${API_TRIPS_URL}/activities/{id}`,
      EDIT_ACTIVITY_PHOTOS: `${API_TRIPS_URL}/activities/photos/{id}`,
      DELETE_ACTIVITY_PHOTOS: `${API_TRIPS_URL}/activities/photos/{id}`
    },
    BI: {
      GET_ALL_DASHBOARDS: `${API_TRIPS_URL}/bi/dashboards`,
      GET_DASHBOARD: `${API_TRIPS_URL}/bi/dashboards/{id}`,
    }
  },
  TRANSLATIONS: {
    TRANSLATIONS_URL: `${API_TRANS_URL}`,
  },
  CUSTOMERS: {
    CUSTOMERS_URL: `${API_USERS_URL}/customers`,
    SINGLE_CUSTOMER_URL: `${API_USERS_URL}/customers/{id}`,
    EXPORT_EXCEL: `${API_USERS_URL}/customers/export`
  },
  ENTITIES: {
    ENTITIES_URL: `${API_USERS_URL}/partners`,
    SINGLE_ENTITY_URL: `${API_USERS_URL}/partners/{id}`,
    ENTITY_TYPE_URL: `${API_USERS_URL}/partners/settings/types`,
    MEMBERS_URL: `${API_USERS_URL}/partners/members`,
    SINGLE_MEMBER_URL: `${API_USERS_URL}/partners/members/{id}`,
    ENTITY_DOCUMENTS_URL: `${API_USERS_URL}/partners/documents`,
    SINGLE_ENTITY_DOCUMENT_URL: `${API_USERS_URL}/partners/documents/{id}`
  },
}
