/**
 * Campaignlisttablerow component
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */

import React from 'react'
import { AvailabilityTableDataCell } from 'shared/genericStyledComponents'
import { TableRowPropTypes } from 'types/misc'
import { CTableDataCell } from '@coreui/react-pro'
import ChevronRight from 'assets/icons/chevron_right'
import { CampaignsListItem } from 'types/trips/campaigns'
import { actions } from 'store/rootSlices'
import { useDispatch } from 'react-redux';
import { CustomChevronCell } from '../../styles'
import { CustomCTableRow, NameCell, CustomActionsCell } from './styles'

type PropTypes = TableRowPropTypes & {
  id?: string,
  campaign: CampaignsListItem
  permission: boolean
}

const Campaignlisttablerow = (props: PropTypes) => {

  const dispatch = useDispatch()
  const {
    permission,
    campaign: {
      name,
      prefix,
      discount,
      isDiscountPercentage,
      validityMonths,
      isActive,
      id
    }
  } = props

  return (
    <CustomCTableRow id={props.id}>
      <NameCell id={`${props.id}-name`} onClick={() => props.onClick()} >{name}</NameCell>
      <CTableDataCell>{prefix}</CTableDataCell>
      <CTableDataCell>{discount}{isDiscountPercentage ? '%' : '€'}</CTableDataCell>
      <CTableDataCell>{validityMonths} months</CTableDataCell>
      <CTableDataCell>
        <AvailabilityTableDataCell isactive={isActive}>{isActive ? "Disponível" : "Indisponível"}</AvailabilityTableDataCell>
      </CTableDataCell>
      <CustomActionsCell nopointer>
        {props.actions}
        <CustomChevronCell id={`${props.id}-chevron`} >
          {
            permission &&
            <a href={`/viagens/configuracoes/editar-campanha/${id}`} onClick={(e) => {
              e.preventDefault()
              dispatch(actions.App.navigateTo(`/viagens/configuracoes/editar-campanha/${id}`))
              }}>
              <ChevronRight></ChevronRight>
            </a>
          }
        </CustomChevronCell>
      </CustomActionsCell>
    </CustomCTableRow>
  )
}

export default Campaignlisttablerow