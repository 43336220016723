/**
 * Tripactivityform component
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */

import { CCol, CForm, CRow } from '@coreui/react-pro'
import { useParams } from 'react-router-dom';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Title, TitleBox, ContainerBox } from 'shared/genericStyledComponents';
import CustomCheckbox from 'shared/components/CustomCheckbox'
import CustomTextInput from 'shared/components/CustomTextInput'
import ColorPicker from 'shared/components/FormComponents/colorpicker'
import SectionContainer from 'shared/components/Sectioncontainer'
import { actions, RootState } from 'store/rootSlices'
import TableContainer from 'shared/components/TableContainer';
import TabsContainer from 'shared/components/Tabscontainer';
import ContainerDelete from 'shared/components/FormComponents/containerdelete';
import FileUploader from 'shared/components/FormComponents/fileuploader';

import { ActivityCircuitForm, ActivityVehicleForm, ActivityPassengerTypesForm, ActivityExtrasForm } from 'types/trips/activity/activityForm';
import Vesseltablerow from './components/vesseltablerow';
import Circuittablerow from './components/circuittablerow';
import Passengertypetablerow from './components/passengertypetablerow';
import Extratablerow from './components/extratablerow';
import CustomButton from 'shared/components/CustomButton';
import theme from 'ui/theme';
import i18n from 'i18next';
import Dropdown from 'shared/components/FormComponents/dropdown';
import Timepicker from 'shared/components/FormComponents/timepicker';
import Photoscomponent from 'shared/components/Photoscomponent';
import config from 'config';
import { hasPermission } from 'permissions';
import { Row, ButtonWrapper } from '../styles';
import { RulesRow, ActivityImageInfo } from './styles'
import { TabContentType } from 'types/misc';

const Tripactivityform = (props) => {

  const dispatch = useDispatch();
  const { id } = useParams()
  const { permissions } = useSelector((state: RootState) => state.App)
  const { PERMISSIONS } = config

  const {
    onFormChange,
    onFormChangeCategoryOrActivityBeachRule,
    onMount,
    onUnmount,
    onExtrasFormChange,
    onCircuitsFormChange,
    onPassengerTypesFormChange,
    onVehiclesFormChange,
    onSubmit,
    addToPhotosArray,
    reOrderPhotosArray,
    deletePhotoFromArray,
    toogleAllVehicles,
    toogleAllCircuits,
    toogleAllPassengerTypes,
    toogleAllExtras
  } = actions.TripActivityForm;
  const {
    edit,
    activityForm,
    vehicles,
    extras,
    taxes,
    circuits,
    passengerTypes,
    photos,
    activityID,
    newPhotosID,
    vehiclesEnabled,
    circuitsEnabled,
    extrasEnabled,
    passengerTypesEnabled,
    hasEditPermission,
    submitButtonState,
    hasBeachRule,
  } = useSelector((state: RootState) => state.TripActivityForm)

  const { allCategories } = useSelector((state: RootState) => state.TripsConfiguration)
  const { navigateBack } = actions.App;

  useEffect(() => {
    dispatch(onMount(id))

    return () => {
      dispatch(onUnmount())
    }

  }, [dispatch, onMount, onUnmount, id]);

  const handleSubmit = (e) => {
    dispatch(onSubmit({ activityForm, extras, circuits, passengerTypes, vehicles, edit, photos, hasBeachRule }))
  }

  const handleDragPhotos = (e) => {
    dispatch(reOrderPhotosArray({ photos, e }))
  }

  const handleAddPhoto = (e) => {
    dispatch(addToPhotosArray({ photos, e, activityID, newPhotosID }))
  }

  const handleDeletePhoto = (e, index) => {
    dispatch(deletePhotoFromArray({ photos, e, activityID, newPhotosID, index }))
  }

  const checkIfCategoryHasBeachRule = (e) => {
    const selectedCategoryHasRuleBeach = allCategories.find(cat => cat.id === Number(e.target.value))?.isBeach ?? false
    const activityHasRuleBeach = activityForm.isBeach.value

    dispatch(
      onFormChangeCategoryOrActivityBeachRule({
        form: {
          ...activityForm,
          category: { value: Number(e.target.value), message: "", valid: true }
        },
        hasBeachRule: selectedCategoryHasRuleBeach || activityHasRuleBeach
      })
    )
  }

  const checkIfActivityHasBeachRule = (e) => {
    const activityHasRuleBeach = e.target.checked
    const selectedCategoryHasRuleBeach = allCategories.find(cat => cat.id === Number(activityForm.category.value))?.isBeach ?? false

    dispatch(
      onFormChangeCategoryOrActivityBeachRule({
        form: {
          ...activityForm,
          isBeach: { value: activityHasRuleBeach, valid: true, message: "" },
          isPrivate: { value: false, valid: true, message: "" },
          isHopOnHopOff: { value: false, valid: true, message: "" },
        },
        hasBeachRule: activityHasRuleBeach || selectedCategoryHasRuleBeach
      })
    )
  }

  const _renderPrivateSpecificRow = () => {
    return activityForm.isPrivate.value && (
      <Row className="g-3">
        <CCol>
          <CustomTextInput
            id='tripActivityForm-minimumPrice'
            disabled={!hasEditPermission}
            required={true}
            valid={activityForm.minimumPrice.valid}
            value={activityForm.minimumPrice.value}
            helpText={activityForm.minimumPrice.message}
            placeholder={i18n.t('placeholders.minimumPrice')}
            label={i18n.t('tripActivityForm.minimumPrice')}
            onChange={(e) => dispatch(onFormChange({ ...activityForm, minimumPrice: { value: e.target.value, message: "", valid: true } }))}
          />
        </CCol>
        <CCol>
          <CustomTextInput
            id='tripActivityForm-includedSeats'
            disabled={!hasEditPermission}
            required={true}
            valid={activityForm.includedSeats.valid}
            value={activityForm.includedSeats.value}
            helpText={activityForm.includedSeats.message}
            placeholder={i18n.t('placeholders.includedSeats')}
            label={i18n.t('tripActivityForm.includedSeats')}
            onChange={(e) => dispatch(onFormChange({ ...activityForm, includedSeats: { value: e.target.value, message: "", valid: true } }))}
          />
        </CCol>
      </Row>
    )
  }

  const activitySections: TabContentType[] = [
    {
      title: i18n.t('tripActivityForm.vessel'),
      disabled: hasBeachRule,
      content: <TableContainer
        count={21}
        elementsPerPage={21}
        currentPage={-1}
        onPageChange={() => { }}
        headerElements={[
          i18n.t('tripActivityForm.vessel'),
          i18n.t('tripActivityForm.availability'),
          i18n.t('tripActivityForm.maxCapacity'),
          <CustomCheckbox
            checkStyle={{ width: '40px', height: '20px', marginTop: '5px' }}
            disabled={!hasEditPermission}
            id='tripActivityForm-toogleAllVehicles'
            checked={vehiclesEnabled}
            onClick={e =>
              dispatch(toogleAllVehicles({ enabled: !vehiclesEnabled, vehicles: vehicles }))
            }
            switch
          />
        ]}
      >
        {vehicles.map((el: ActivityVehicleForm, idx: number) =>
          <React.Fragment key={`activity-vessel-${idx}`}>
            <Vesseltablerow
              id={`tripActivityForm-vehicle${idx}`}
              onClick={() => { }}
              vessel={el}
              actions={
                <React.Fragment>
                  <CustomCheckbox
                    checkStyle={{ width: '40px', height: '20px', marginTop: '5px' }}
                    id={`tripActivityForm-vehicle${idx}-checkBox`}
                    checked={el.isOn && el.isOn.value ? true : false}
                    disabled={!hasEditPermission || !el.isAvailable}
                    onClick={e => dispatch(onVehiclesFormChange({ e, el, vehicles }))}
                    switch
                  />
                </React.Fragment>
              }
            />
          </React.Fragment>
        )}
      </TableContainer>
    },
    {
      title: i18n.t('tripActivityForm.circuit'),
      disabled: hasBeachRule,
      content: <TableContainer
        count={21}
        elementsPerPage={21}
        currentPage={-1}
        onPageChange={() => { }}
        headerElements={[
          i18n.t('tripActivityForm.circuits'),
          i18n.t('tripActivityForm.availability'),
          i18n.t('tripActivityForm.duration'),
          <CustomCheckbox
            checkStyle={{ width: '40px', height: '20px', marginTop: '5px' }}
            id='tripActivityForm-toogleAllCircuits'
            disabled={!hasEditPermission}
            checked={circuitsEnabled}
            onClick={e =>
              dispatch(toogleAllCircuits({ enabled: !circuitsEnabled, circuits: circuits }))
            }
            switch
          />
        ]}
      >
        {circuits.map((el: ActivityCircuitForm, idx: number) =>
          <React.Fragment key={`activity-circuit-${idx}`}>
            <Circuittablerow
              id={`tripActivityForm-circuit${idx}`}
              onClick={() => { }}
              circuit={el}
              actions={
                <React.Fragment>
                  <CustomCheckbox
                    checkStyle={{ width: '40px', height: '20px', marginTop: '5px' }}
                    id={`tripActivityForm-circuit${idx}-checkBox`}
                    disabled={!hasEditPermission || !el.isAvailable}
                    checked={el.isOn && el.isOn.value ? true : false}
                    onClick={e => dispatch(onCircuitsFormChange({ e, el, circuits }))}
                    switch
                  />
                </React.Fragment>
              }
            />
          </React.Fragment>
        )}
      </TableContainer>
    },
    {
      title: i18n.t('tripActivityForm.passengerTypes'),
      disabled: hasBeachRule,
      content: <TableContainer
        count={21}
        elementsPerPage={21}
        currentPage={-1}
        onPageChange={() => { }}
        headerElements={[
          i18n.t('tripActivityForm.passengerType'),
          activityForm.generateIslandTrips.value ? 'Price For Island' : '',
          i18n.t('tripActivityForm.passengerPrice'),
          <CustomCheckbox
            checkStyle={{ width: '40px', height: '20px', marginTop: '5px' }}
            id='tripActivityForm-toogleAllPassengerTypes'
            disabled={!hasEditPermission}
            checked={passengerTypesEnabled}
            onClick={e =>
              dispatch(toogleAllPassengerTypes({ enabled: !passengerTypesEnabled, passengerTypes: passengerTypes }))
            }
            switch
          />
        ]}
      >
        {passengerTypes.map((el: ActivityPassengerTypesForm, idx: number) =>
          <React.Fragment key={`passenger-type-${idx}`}>
            <Passengertypetablerow
              id={`tripActivityForm-passengerType${idx}`}
              disabled={!hasEditPermission}
              onClick={(e, input) => dispatch(onPassengerTypesFormChange({ e, el, passengerTypes, inputName: input }))}
              generateIslandTrips={activityForm.generateIslandTrips.value}
              passengerType={el}
              actions={
                <React.Fragment>
                  <CustomCheckbox
                    checkStyle={{ width: '40px', height: '20px', marginTop: '5px' }}
                    id={`tripActivityForm-passengerType${idx}-checkBox`}
                    disabled={!hasEditPermission || !el.isActive}
                    checked={el.isOn && el.isOn.value ? true : false}
                    onClick={e => dispatch(onPassengerTypesFormChange({ e, el, passengerTypes, inputName: 'none' }))}
                    switch
                  />
                </React.Fragment>
              }
            />
          </React.Fragment>
        )}
      </TableContainer>
    },
    {
      title: "Extras",
      disabled: false,
      content: <TableContainer
        count={21}
        elementsPerPage={21}
        currentPage={-1}
        onPageChange={() => { }}
        headerElements={[
          i18n.t('tripActivityForm.extras'),
          i18n.t('tripActivityForm.availability'),
          i18n.t('tripActivityForm.maxCapacity'),
          /*          i18n.t('tripActivityForm.quantity'),*/
          i18n.t('tripActivityForm.extrasPrice'),
          <CustomCheckbox
            checkStyle={{ width: '40px', height: '20px', marginTop: '5px' }}
            id='tripActivityForm-toogleAllExtras'
            disabled={!hasEditPermission}
            checked={extrasEnabled}
            onClick={e =>
              dispatch(toogleAllExtras({ enabled: !extrasEnabled, extras: extras }))
            }
            switch
          />
        ]}
      >
        {extras.map((el: ActivityExtrasForm, idx: number) =>
          <React.Fragment key={`extra-${idx}`}>
            <Extratablerow
              id={`tripActivityForm-extra${idx}`}
              onClick={(e) => dispatch(onExtrasFormChange({ e, el, extras, price: true }))}
              extra={el}
              actions={
                <React.Fragment>
                  <CustomCheckbox
                    checkStyle={{ width: '40px', height: '20px', marginTop: '5px' }}
                    id={`tripActivityForm-extra${idx}-checkBox`}
                    disabled={!hasEditPermission || !el.isActive.value /* || Number(el.maxCapacity.value) === 0 */}
                    checked={el.isOn && el.isOn.value ? true : false}
                    onClick={e => dispatch(onExtrasFormChange({ e, el, extras, price: false }))}
                    switch
                  />
                </React.Fragment>
              }
            />
          </React.Fragment>
        )}
      </TableContainer>
    }
  ]

  return (
    <div>
      <CForm>
        <CustomCheckbox
          id='tripActivityForm-isActive'
          disabled={!hasEditPermission}
          wrapperStyle={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '-25px', fontSize: '20px' }}
          checked={activityForm.isActive.value ? true : false}
          onClick={e => dispatch(onFormChange({ ...activityForm, isActive: { value: e.target.checked, valid: true, message: "" } }))}
          text={i18n.t('tripActivityForm.isActive')}
          switch
        />
        <div style={{ width: '100%', paddingBottom: '24px' }}>
          <TitleBox>
            <Title>{i18n.t('tripActivityForm.activityTitle')}</Title>
          </TitleBox>
          <TabsContainer id="tripActivityForm-tabsDetailsContainer" onChangeTab={tab => { }} content={[
            {
              title: i18n.t('tripActivityForm.activity'),
              content: <ContainerBox>
                <CRow className="g-3">
                  <CCol>
                    <CustomTextInput
                      id='tripActivityForm-name'
                      disabled={!hasEditPermission}
                      required={true}
                      valid={activityForm.namePt.valid}
                      value={activityForm.namePt.value}
                      helpText={activityForm.namePt.message}
                      placeholder={i18n.t('placeholders.name')}
                      label={i18n.t('tripActivityForm.name')}
                      onChange={(e) => dispatch(onFormChange({ ...activityForm, namePt: { value: e.target.value, message: "", valid: true } }))} />
                  </CCol>
                  <CCol>
                    <CustomTextInput
                      id='tripActivityForm-shortName'
                      disabled={!hasEditPermission}
                      required={true}
                      valid={activityForm.shortName.valid}
                      value={activityForm.shortName.value}
                      helpText={activityForm.shortName.message}
                      placeholder={i18n.t('placeholders.shortName')}
                      label={i18n.t('tripActivityForm.shortname')}
                      onChange={(e) => dispatch(onFormChange({ ...activityForm, shortName: { value: e.target.value, message: "", valid: true } }))} />
                  </CCol>
                  <CCol>
                    <CustomTextInput
                      id='tripActivityForm-getYourGuideCode'
                      disabled={!hasEditPermission}
                      required={false}
                      valid={activityForm.getyourguideCode.valid}
                      value={activityForm.getyourguideCode.value}
                      helpText={activityForm.getyourguideCode.message}
                      placeholder={i18n.t('placeholders.addGetYourGuideCode')}
                      label={i18n.t('tripActivityForm.getYourGuideCode')}
                      onChange={(e) => dispatch(onFormChange({ ...activityForm, getyourguideCode: { value: e.target.value, message: "", valid: true } }))} />
                  </CCol>
                </CRow>
                <Row className="g-3">
                  <CCol>
                    <CustomTextInput
                      id='tripActivityForm-articleCode'
                      disabled={!hasEditPermission}
                      required={true}
                      valid={activityForm.invoiceArticle.valid}
                      value={activityForm.invoiceArticle.value}
                      helpText={activityForm.invoiceArticle.message}
                      placeholder={i18n.t('placeholders.articleCode')}
                      label={i18n.t('tripActivityForm.articleCode')}
                      onChange={(e) => dispatch(onFormChange({ ...activityForm, invoiceArticle: { value: e.target.value, message: "", valid: true } }))}
                    />
                  </CCol>
                  <CCol>
                    <CustomTextInput
                      id='tripActivityForm-maxCapacity'
                      disabled={!hasEditPermission}
                      required={true}
                      valid={activityForm.maxCapacity.valid}
                      value={activityForm.maxCapacity.value}
                      helpText={activityForm.maxCapacity.message}
                      placeholder={i18n.t('placeholders.maxCapacity')}
                      label={i18n.t('tripActivityForm.maxCapacity')}
                      onChange={(e) => dispatch(onFormChange({ ...activityForm, maxCapacity: { value: e.target.value, message: "", valid: true } }))}
                    />
                  </CCol>
                  <CCol>
                    <Timepicker
                      timeFormat='HH:mm:ss'
                      required
                      disabled={!hasEditPermission}
                      id='tripActivityForm-duration'
                      onChange={(e) => dispatch(onFormChange({ ...activityForm, duration: { value: e, message: "", valid: true } }))}
                      value={activityForm.duration.value}
                      valid={activityForm.duration.valid}
                      helpText={activityForm.duration.message}
                      label={i18n.t('tripActivityForm.duration')}
                    />
                  </CCol>
                </Row>
                <Row className="g-3">
                  <CCol>
                    <Dropdown
                      id='tripActivityForm-category'
                      disabled={!hasEditPermission}
                      required={true}
                      valid={activityForm.category.valid}
                      helpText={activityForm.category.message}
                      value={activityForm.category.value}
                      placeholder={i18n.t('tripActivityForm.category')}
                      label={i18n.t('tripActivityForm.category')}
                      options={allCategories.map(cat => {
                        return {
                          label: cat.name,
                          value: cat.id
                        }
                      })}
                      onChange={e => checkIfCategoryHasBeachRule(e)}
                    />
                  </CCol>
                  <CCol>
                    <Dropdown
                      id='tripActivityForm-tax'
                      disabled={!hasEditPermission}
                      required={true}
                      valid={activityForm.tax.valid}
                      helpText={activityForm.tax.message}
                      value={activityForm.tax.value}
                      placeholder={i18n.t('placeholders.taxType')}
                      label={i18n.t('tripActivityForm.activityTax')}
                      options={taxes?.map(tax => {
                        return {
                          label: tax.name,
                          value: tax.id
                        }
                      })}
                      onChange={(e) => dispatch(onFormChange({ ...activityForm, tax: { value: Number(e.target.value), message: "", valid: true } }))}
                    />
                  </CCol>
                  <CCol>
                    <ColorPicker
                      id='tripActivityForm-color'
                      disabled={!hasEditPermission}
                      required={true}
                      value={activityForm.color.value}
                      valid={activityForm.color.valid}
                      helpText={activityForm.color.message}
                      label={i18n.t('tripActivityForm.activityColor')}
                      onChange={(e) => dispatch(onFormChange({ ...activityForm, color: { value: e.target.value, message: "", valid: true } }))}
                    />
                  </CCol>
                </Row>
                <Row className="g-3">
                  <CCol>
                    <CustomTextInput
                      id='tripActivityForm-description'
                      disabled={!hasEditPermission}
                      required={false}
                      valid={activityForm.descriptionPt.valid}
                      value={activityForm.descriptionPt.value}
                      helpText={activityForm.descriptionPt.message}
                      placeholder={i18n.t('placeholders.description')}
                      label={i18n.t('tripActivityForm.description')}
                      onChange={(e) => dispatch(onFormChange({ ...activityForm, descriptionPt: { value: e.target.value, message: "", valid: true } }))}
                    />
                  </CCol>
                </Row>

                { _renderPrivateSpecificRow() }
              </ContainerBox>
            },
            {
              title: i18n.t('common.translations'),
              content: <ContainerBox>
                <CRow className="g-3">
                  <CCol sm>
                    <CustomTextInput
                      id='tripActivityForm-nameEn'
                      disabled={!hasEditPermission}
                      required={false}
                      valid={activityForm.nameEn.valid}
                      value={activityForm.nameEn.value}
                      helpText={activityForm.nameEn.message}
                      placeholder={i18n.t('placeholders.nameEn')}
                      label={i18n.t('tripActivityForm.nameEn')}
                      onChange={(e) => dispatch(onFormChange({ ...activityForm, nameEn: { value: e.target.value, message: "", valid: true } }))} />
                  </CCol>
                  <CCol sm={8}>
                    <CustomTextInput
                      id='tripActivityForm-description'
                      disabled={!hasEditPermission}
                      required={false}
                      valid={activityForm.descriptionEn.valid}
                      value={activityForm.descriptionEn.value}
                      helpText={activityForm.descriptionEn.message}
                      placeholder={i18n.t('placeholders.descriptionEn')}
                      label={i18n.t('tripActivityForm.descriptionEn')}
                      onChange={(e) => dispatch(onFormChange({ ...activityForm, descriptionEn: { value: e.target.value, message: "", valid: true } }))}
                    />
                  </CCol>
                </CRow>
                <Row className="g-3">
                  <CCol sm>
                    <CustomTextInput
                      id='tripActivityForm-nameEs'
                      disabled={!hasEditPermission}
                      required={false}
                      valid={activityForm.nameEs.valid}
                      value={activityForm.nameEs.value}
                      helpText={activityForm.nameEs.message}
                      placeholder={i18n.t('placeholders.nameEs')}
                      label={i18n.t('tripActivityForm.nameEs')}
                      onChange={(e) => dispatch(onFormChange({ ...activityForm, nameEs: { value: e.target.value, message: "", valid: true } }))} />
                  </CCol>
                  <CCol sm={8}>
                    <CustomTextInput
                      id='tripActivityForm-descriptionEs'
                      disabled={!hasEditPermission}
                      required={false}
                      valid={activityForm.descriptionEs.valid}
                      value={activityForm.descriptionEs.value}
                      helpText={activityForm.descriptionEs.message}
                      placeholder={i18n.t('placeholders.descriptionEs')}
                      label={i18n.t('tripActivityForm.descriptionEs')}
                      onChange={(e) => dispatch(onFormChange({ ...activityForm, descriptionEs: { value: e.target.value, message: "", valid: true } }))}
                    />
                  </CCol>
                </Row>
                <Row className="g-3">
                  <CCol sm>
                    <CustomTextInput
                      id='tripActivityForm-nameFr'
                      disabled={!hasEditPermission}
                      required={false}
                      valid={activityForm.nameFr.valid}
                      value={activityForm.nameFr.value}
                      helpText={activityForm.nameFr.message}
                      placeholder={i18n.t('placeholders.nameFr')}
                      label={i18n.t('tripActivityForm.nameFr')}
                      onChange={(e) => dispatch(onFormChange({ ...activityForm, nameFr: { value: e.target.value, message: "", valid: true } }))} />
                  </CCol>
                  <CCol sm={8}>
                    <CustomTextInput
                      id='tripActivityForm-descriptionFr'
                      disabled={!hasEditPermission}
                      required={false}
                      valid={activityForm.descriptionFr.valid}
                      value={activityForm.descriptionFr.value}
                      helpText={activityForm.descriptionFr.message}
                      placeholder={i18n.t('placeholders.descriptionFr')}
                      label={i18n.t('tripActivityForm.descriptionFr')}
                      onChange={(e) => dispatch(onFormChange({ ...activityForm, descriptionFr: { value: e.target.value, message: "", valid: true } }))}
                    />
                  </CCol>
                </Row>
              </ContainerBox>
            },
          ]} />
        </div>
        <div style={{ width: '100%', paddingBottom: '24px', paddingTop: '24px' }}>
          <TabsContainer
            id="tripActivityForm-tabsContainer"
            onChangeTab={tab => { }}
            content={activitySections.filter((act: TabContentType) => act?.disabled !== true)}
          />
        </div>
        <SectionContainer title={i18n.t('tripActivityForm.activityPhotosTitle')} style={{ width: '100%', paddingBottom: '24px' }}>
          <CRow className="g-3">
            <CCol>
              <FileUploader
                id='tripActivityForm-uploadPhoto'
                disabled={!hasEditPermission}
                required={false}
                valid={true}
                value={""}
                helpText={""}
                label={i18n.t('tripActivityForm.upload')}
                accept='.png, .jpg, .jpeg'
                fileSizeLimit={100000000}
                onChange={(e) => handleAddPhoto(e)} />
            </CCol>
          </CRow>
          <CRow>
            <Photoscomponent
              id='tripActivityForm'
              array={photos}
              onDrag={e => handleDragPhotos(e)}
              onDelete={(e, index) => handleDeletePhoto(e, index)}
            />
            <ActivityImageInfo>{i18n.t('tripActivityForm.activityPhotoInfo')}</ActivityImageInfo>
          </CRow>
        </SectionContainer>
        <SectionContainer title={i18n.t('tripCategoryForm.rulesTitle')} style={{ width: '100%', paddingBottom: '24px' }}>
          <RulesRow>
            <CCol>
              <CustomCheckbox
                id='tripActivityForm-allowDiscounts'
                disabled={!hasEditPermission}
                checked={activityForm.allowDiscounts.value === true ? true : false}
                onClick={e => dispatch(onFormChange({ ...activityForm, allowDiscounts: { value: e.target.checked, valid: true, message: "" } }))}
                text={i18n.t('tripCategoryForm.applyDiscounts')}
              />
            </CCol>
            <CCol>
              <CustomCheckbox
                id='tripActivityForm-allowComissions'
                disabled={!hasEditPermission}
                checked={activityForm.allowCommissions.value === true ? true : false}
                onClick={e => dispatch(onFormChange({ ...activityForm, allowCommissions: { value: e.target.checked, valid: true, message: "" } }))}
                text={i18n.t('tripCategoryForm.applyComissions')}
              />
            </CCol>
            <CCol>
              <CustomCheckbox
                id={`tripCategoryForm-allowMultipleActivities`}
                disabled={!hasEditPermission}
                checked={activityForm.allowMultipleActivities.value === true ? true : false}
                onClick={e => dispatch(onFormChange({ ...activityForm, allowMultipleActivities: { value: e.target.checked, valid: true, message: "" } }))}
                text={i18n.t('tripCategoryForm.addToOtherActivities')}
              />
            </CCol>
          </RulesRow>
          <RulesRow>
            <CCol>
              <CustomCheckbox
                id={`tripCategoryForm-generateIslandTrips`}
                disabled={!hasEditPermission}
                checked={activityForm.generateIslandTrips.value === true ? true : false}
                onClick={e => dispatch(onFormChange({
                  ...activityForm,
                  generateIslandTrips: { value: e.target.checked, valid: true, message: "" },
                  isHopOnHopOff: { value: false, valid: true, message: "" },
                  isDirect: { value: true, valid: true, message: "" },

                }))}
                text={i18n.t('tripCategoryForm.generateIslandTrips')}
              />
            </CCol>
            <CCol>
              <CustomCheckbox
                id={`tripCategoryForm-generateMiddleTrips`}
                disabled={!hasEditPermission}
                checked={activityForm.generateMiddleTrips.value === true ? true : false}
                onClick={e => dispatch(onFormChange({
                  ...activityForm,
                  generateMiddleTrips: { value: e.target.checked, valid: true, message: "" },
                  isHopOnHopOff: { value: false, valid: true, message: "" },
                  isDirect: { value: true, valid: true, message: "" },
                }))}
                text={i18n.t('tripCategoryForm.generateMiddleTrips')}
              />
            </CCol>
            <CCol>
              <CustomCheckbox
                id={`tripCategoryForm-isHopOnHopOff`}
                disabled={!hasEditPermission}
                checked={activityForm.isHopOnHopOff.value === true ? true : false}
                onClick={e => dispatch(onFormChange({
                  ...activityForm,
                  isHopOnHopOff: { value: e.target.checked, valid: true, message: "" },
                  isDirect: { value: !e.target.checked, valid: true, message: "" },
                  generateIslandTrips: { value: false, valid: true, message: "" },
                  generateMiddleTrips: { value: false, valid: true, message: "" },
                  isBeach: { value: false, valid: true, message: "" },
                  isPrivate: { value: false, valid: true, message: "" },
                }))}
                text={i18n.t('tripCategoryForm.isHopOnHopOff')}
              />
            </CCol>
          </RulesRow>
          <RulesRow>
            <CCol>
              <CustomCheckbox
                id={`tripActivityForm-isBeach`}
                disabled={!hasEditPermission}
                checked={activityForm.isBeach.value === true ? true : false}
                onClick={e => checkIfActivityHasBeachRule(e)}
                text={i18n.t('tripActivityForm.isBeach')}
              />
            </CCol>
            <CCol>
              <CustomCheckbox
                id={`tripActivityForm-allowOnlyOneBooking`}
                disabled={!hasEditPermission}
                checked={activityForm.allowOnlyOneBooking.value === true ? true : false}
                onClick={e => dispatch(onFormChange({
                  ...activityForm,
                  allowOnlyOneBooking: { 
                    value: activityForm.isPrivate.value ? true : e.target.checked, 
                    valid: true, 
                    message: "" 
                  },
                }))}
                text={i18n.t('tripActivityForm.allowOnlyOneBooking')}
              />
            </CCol>
            <CCol>
              <CustomCheckbox
                id={`tripActivityForm-isPrivate`}
                disabled={!hasEditPermission}
                checked={activityForm.isPrivate.value}
                onClick={e => dispatch(onFormChange({
                  ...activityForm,
                  isPrivate: { value: e.target.checked, valid: true, message: "" },
                  isBeach: { value: false, valid: true, message: "" },
                  generateIslandTrips: { value: false, valid: true, message: "" },
                  allowMultipleActivities: { value: false, valid: true, message: "" },
                  generateMiddleTrips: { value: false, valid: true, message: "" },
                  isHopOnHopOff: { value: false, valid: true, message: "" },
                  ...(!e.target.checked && { minimumPrice: { value: "", valid: true, message: "" } }),
                  ...(!e.target.checked && { includedSeats: { value: "", valid: true, message: "" } }),
                  ...(e.target.checked && { allowOnlyOneBooking: { value: true, valid: true, message: "" } }),
                }))}
                text={i18n.t('tripActivityForm.isPrivate')}
              />
            </CCol>
          </RulesRow>
        </SectionContainer>
        {
          edit === true && hasPermission(permissions, PERMISSIONS.TRIPS.ACTIVITIES.DELETE_ACTIVITY) && <ContainerDelete id='tripActivityForm-delete' title={i18n.t('tripActivityForm.removeActivity')} text={<p>{i18n.t('tripActivityForm.removeActivityText')}<br />{i18n.t('common.deleteLine2')}</p>}></ContainerDelete>
        }

        <ButtonWrapper>
          <CustomButton id='tripActivityForm-cancel' onClick={() => dispatch(navigateBack())} text={i18n.t('common.cancelBtn')} color="link" buttonStyle={{ color: theme().danger, textDecoration: 'unset' }} />
          {
            edit ?
              hasPermission(permissions, PERMISSIONS.TRIPS.ACTIVITIES.EDIT_ACTIVITY) &&
              <CustomButton
                id='tripActivityForm-save'
                disabled={submitButtonState}
                text={i18n.t('common.saveBtn')}
                icon="confirm"
                onClick={e => handleSubmit(e)}
                color="success"
                buttonStyle={{ color: "#FFFFFF" }} />
              :
              hasPermission(permissions, PERMISSIONS.TRIPS.ACTIVITIES.CREATE_ACTIVITY) &&
              <CustomButton
                id='tripActivityForm-save'
                disabled={submitButtonState}
                text={i18n.t('common.saveBtn')}
                icon="confirm"
                onClick={e => handleSubmit(e)}
                color="success"
                buttonStyle={{ color: "#FFFFFF" }} />
          }
        </ButtonWrapper>
      </CForm>
    </div>
  )
}

export default Tripactivityform
