/**
 * Documentconfigform scene slice
 *
 * @author João Dias <jdias@ubiwhere.com>
 *
 *
 */
import { createSlice } from '@reduxjs/toolkit'
import { defaultDocumentConfigForm, DocumentConfigForm, documentConfigFormValidations } from '../utils'
import * as Check from "validations"
//import { DocumentsType } from 'types/fleet/vehicle'

type State = {
  document: object,
  edit: boolean,
  loading: boolean,
  documentConfigForm: DocumentConfigForm,
  hasEditPermission: boolean,
  submitButtonState: boolean
}

const initialState: State = {
  document: {},
  edit: false,
  loading: true,
  documentConfigForm: defaultDocumentConfigForm,
  hasEditPermission: true,
  submitButtonState: false
}

export default createSlice({
  name: 'Documentconfigform',
  initialState,
  reducers: {
    onMount: (state, { payload }) => { },
    onUnmount: () => { },
    onSubmit: () => { },
    setEditMode: (state, { payload }) => {
      state.edit = payload;
    },
    setForm: (state, { payload }) => {
      state.documentConfigForm = payload;
    },
    setDocument: (state, { payload }) => {
      state.document = payload;
    },
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
    setHasEditPermission: (state, { payload }) => {
      state.hasEditPermission = payload
    },
    setSubmitButtonDisabled:(state, { payload }) => {
      state.submitButtonState = payload
    },
    getDocumentConfig: (state, { payload }) => { },
    onFormChange: (state, { payload }) => {
      state.documentConfigForm = Check.setAndCheckValidation(state.documentConfigForm, payload, documentConfigFormValidations)
    }
  }
})