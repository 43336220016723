/**
 * Dropdown component
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */

import React from 'react'
import styled from 'styled-components'
import { CFormLabel, CInputGroup, CFormSelect } from '@coreui/react';
import theme from 'ui/theme';
import { CFormText } from '@coreui/react-pro';

interface PropTypes {
    id?: string,
    wrapperStyle?: React.CSSProperties,
    required?: boolean,
    options: Array<{
        label: string,
        value: any,
        prefix?: string,
    }>,
    label?: string,
    value?: string,
    onChange: Function,
    placeholder?: string,
    valid?: boolean
    helpText?: string,
    disabled?: boolean,
    edited?: boolean,
    defaultOption?: {
        label: string,
        value: any,
        prefix?: string,
    },
}

const Dropdown = (props: PropTypes) => {

    return (
        <Wrapper style={props.wrapperStyle} >
            {props.label && <CFormLabel>{props.label}<span>{!!props.required ? "*" : ""}</span></CFormLabel>}
            <CInputGroup id={`${props.id}DropdownWrapper`}>
                {
                    <FormSelect
                        id={`${props.id}Dropdown`}
                        edited={props.edited}
                        value={props.value}
                        isvalid={props.valid}
                        required={props.required}
                        onChange={e => props.onChange ? props.onChange(e) : null}
                        disabled={props.disabled === true ? true : false}
                    >
                        {<option id={`${props.id}CurrentOption`} value=""> {props.placeholder} </option>}
                        {
                            props.options != null && props.options.map((opt, idx) => {
                                
                                if(opt.value === 'disabled') {
                                    return (
                                        <DisabledOption id={`${props.id}Option${idx}`} disabled key={idx} >
                                            {opt.label}
                                        </DisabledOption>
                                    )
                                }
                                return (
                                    <option id={`${props.id}Option${idx}`} value={opt.value} key={idx} >
                                        {opt.label}
                                    </option>
                                )
                            })
                        }
                    </FormSelect>
                }
            </CInputGroup>
            {props.helpText && <HelpText valid={props.valid}>{props.helpText}</HelpText>}
        </Wrapper>
    )
}

export default Dropdown

const DisabledOption = styled.option`
    color:  ${theme().gray400Color};
    background: ${theme().gray100Color};
`

const Wrapper = styled.div<{ edited?: boolean }>`
   option {
     font-family: inherit;
   }
 
   > label {
     font-size: 14px;
     color: ${theme().darkColor};
     > span {
       color: ${theme().primary};
     }
   }


 `

const HelpText = styled(CFormText) <{ valid: boolean | undefined }>`
   color: ${(props) => props.valid === false ? theme().danger : '#D8DBE0'};
   text-align: left;
 `
const FormSelect = styled(CFormSelect) <{ isvalid: boolean | undefined, edited?: boolean }>`
     width:'100%';
     border: ${({ isvalid, edited }) => isvalid === false ?
        `1px solid ${theme().danger}`
        : edited === true ?
            `2px dashed ${theme().gray200Color}`
            :
            `1px solid #D8DBE0`} !important; 
     color:${theme().gray600Color};
     font-size:14px;
     background-image: url("data:image/svg+xml,%3Csvg width='8' height='13' viewBox='0 0 8 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.99994 2.38667L6.11328 4.5L7.05328 3.56L3.99994 0.5L0.939941 3.56L1.88661 4.5L3.99994 2.38667ZM3.99994 10.6133L1.88661 8.5L0.946608 9.44L3.99994 12.5L7.05994 9.44L6.11328 8.5L3.99994 10.6133Z' fill='%23636F83'/%3E%3C/svg%3E");
     &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
         color: ${theme().gray500Color};
         opacity: 1; /* Firefox */
     }
 `
