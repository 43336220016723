/**
 * Tripserviceform component
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */

import { CCol, CForm, CRow } from '@coreui/react-pro'
import { useParams } from 'react-router-dom';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { actions, RootState } from 'store/rootSlices'

import CustomCheckbox from 'shared/components/CustomCheckbox'
import CustomTextInput from 'shared/components/CustomTextInput'
import SectionContainer from 'shared/components/Sectioncontainer'
import ContainerDelete from 'shared/components/FormComponents/containerdelete';
import CustomRadioButtonGroup from 'shared/components/CustomRadioButtonGroup';
import CustomRadioButton from 'shared/components/CustomRadioButton';
import CustomButton from 'shared/components/CustomButton';
import theme from 'ui/theme';
import i18n from 'i18next';
import config from 'config';
import { hasPermission } from 'permissions';
import { Row, ButtonWrapper } from '../styles';



const Tripserviceform = (props) => {

  const dispatch = useDispatch();
  const { id } = useParams()

  const { onFormChange, onMount, onUnmount, onSubmit } = actions.TripServiceForm;
  const { edit, serviceForm, hasEditPermission, submitButtonState } = useSelector((state: RootState) => state.TripServiceForm)
  const { navigateBack } = actions.App;
  const { permissions } = useSelector((state: RootState) => state.App)
  const { PERMISSIONS } = config

  useEffect(() => {
    dispatch(onMount(id))

    return () => {
      dispatch(onUnmount())
    }

  }, [dispatch, id, onMount, onUnmount]);

  const handleSubmit = (e) => {
    dispatch(onSubmit({ serviceForm, e, edit }))
  }

  /*   const handleDeleteCollaborator = (e) => {
      dispatch(showDeleteModal(true))
    }
    const handleDeleteCollaboratorConfirm = (e) => {
      dispatch(onDeleteConfirm(uuid))
    } */

  return (
    <div>
      <CForm>
        {/* {edit === true && <DropdownExport id='tripServiceForm-dropdownExport'   dropdown={true} wrapperStyle={{ maxWidth: '100%' }} />} */}
        <CustomCheckbox
          disabled={!hasEditPermission}
          id='tripServiceForm-isActive'
          wrapperStyle={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '-25px', fontSize: '20px' }}
          checked={serviceForm.isActive.value ? true : false}
          onClick={e => dispatch(onFormChange({ ...serviceForm, isActive: { value: !serviceForm.isActive.value, valid: true, message: "" } }))}
          text={i18n.t('tripServiceForm.isActive')}
          switch
        />
        <SectionContainer title={i18n.t('tripServiceForm.titleService')} style={{ width: '100%', paddingBottom: '24px' }}>
          <CRow className="g-3">
            <CCol>
              <CustomTextInput
                disabled={!hasEditPermission}
                id='tripServiceForm-name'
                required={true}
                valid={serviceForm.name.valid}
                value={serviceForm.name.value}
                helpText={serviceForm.name.message}
                placeholder={i18n.t('placeholders.name')}
                label={i18n.t('tripServiceForm.name')}
                onChange={(e) => dispatch(onFormChange({ ...serviceForm, name: { value: e.target.value, message: "", valid: true } }))}
              />
            </CCol>
          </CRow>
          <Row>
            <CCol sm={7}>
              <CustomTextInput
                disabled={!hasEditPermission}
                id='tripServiceForm-description'
                required={true}
                valid={serviceForm.description.valid}
                value={serviceForm.description.value}
                helpText={serviceForm.description.message}
                placeholder={i18n.t('placeholders.description')}
                label={i18n.t('tripServiceForm.description')}
                onChange={(e) => dispatch(onFormChange({ ...serviceForm, description: { value: e.target.value, message: "", valid: true } }))}
              />
            </CCol>
            <CCol>
              <CustomRadioButtonGroup required={true} title={i18n.t('tripServiceForm.price')} role="group1" helpText={serviceForm.isPricePerPerson.message} valid={serviceForm.isPricePerPerson.valid}>
                <CustomRadioButton disabled={!hasEditPermission} group="group1" text={i18n.t('tripServiceForm.pricePerson')} value="person"
                  checked={serviceForm.isPricePerPerson.value === true ? true : false}
                  onClick={e => dispatch(onFormChange({ ...serviceForm, isPricePerPerson: { value: true, message: "", valid: true } }))} />
                <CustomRadioButton disabled={!hasEditPermission} group="group1" text={i18n.t('tripServiceForm.priceTotal')} value="total"
                  checked={serviceForm.isPricePerPerson.value === false ? true : false}
                  onClick={e => dispatch(onFormChange({ ...serviceForm, isPricePerPerson: { value: false, message: "", valid: true } }))} />
              </CustomRadioButtonGroup>
            </CCol>
            <CCol>
              <CustomTextInput
                disabled={!hasEditPermission}
                id='tripServiceForm-price'
                wrapperStyle={{marginTop:'6px'}}
                icon='euro'
                valid={serviceForm.price.valid}
                value={serviceForm.price.value}
                helpText={serviceForm.price.message}
                placeholder="00,00"
                label=' '
                onChange={(e) => dispatch(onFormChange({ ...serviceForm, price: { value: e.target.value, message: "", valid: true } }))}
              />
            </CCol>
          </Row>
        </SectionContainer>
        {
          edit === true && hasPermission(permissions, PERMISSIONS.TRIPS.SERVICES.DELETE_SERVICE) && <ContainerDelete id='tripServiceForm-delete' title={i18n.t('tripServiceForm.removeService')} text={<p>{i18n.t('tripServiceForm.removeServiceText')}<br />{i18n.t('common.deleteLine2')}</p>}></ContainerDelete>
        }
        <ButtonWrapper>
          <CustomButton id='tripServiceForm-cancel' onClick={() => dispatch(navigateBack())} text={i18n.t('common.cancelBtn')} color="link" buttonStyle={{ color: theme().danger, textDecoration: 'unset' }} />
          {
            edit ?
              hasPermission(permissions, PERMISSIONS.TRIPS.SERVICES.EDIT_SERVICE) &&
              <CustomButton
                id='tripServiceForm-save'
                disabled={submitButtonState}
                text={i18n.t('common.saveBtn')}
                icon="confirm"
                onClick={e => handleSubmit(e)}
                color="success"
                buttonStyle={{ color: "#FFFFFF" }} />
              :
              hasPermission(permissions, PERMISSIONS.TRIPS.SERVICES.CREATE_SERVICE) &&
              <CustomButton
                id='tripServiceForm-save'
                disabled={submitButtonState}
                text={i18n.t('common.saveBtn')}
                icon="confirm"
                onClick={e => handleSubmit(e)}
                color="success"
                buttonStyle={{ color: "#FFFFFF" }} />
          }
        </ButtonWrapper>
      </CForm>
    </div>
  )
}

export default Tripserviceform