import { CButton, CRow } from '@coreui/react-pro';
import styled from 'styled-components'
import theme from 'ui/theme';

export const Wrapper = styled.div`
  overflow-x: hidden;
`

export const Text = styled.p`
  margin:0;
  font-family: 'Inter';
`

export const CancelButton = styled(CButton) <{ warningtype: "warning" | "message" }> `
  color: ${(props) => props.warningtype === "warning" ? theme().darkColor : theme().danger};
  border-color: ${(props) => props.warningtype === "warning" ? theme().gray200Color : theme().danger};
  background-color: ${(props) => props.warningtype === "warning" ? theme().gray200Color : theme().white};
  font-size:14px;
  box-shadow:0px 0px;
  &:hover {
      border-color:${(props) => props.warningtype === "warning" ? theme().darkColor : theme().danger};
      background-color: ${(props) => props.warningtype === "warning" ? theme().darkColor : theme().danger};
  }
  &:focus{
      box-shadow:0px 0px;
      border-color:${(props) => props.warningtype === "warning" ? theme().darkColor : theme().danger};
      background-color: ${(props) => props.warningtype === "warning" ? theme().darkColor : theme().danger};
  }
`
export const ConfirmButton = styled(CButton) <{ warningtype: "warning" | "message" }> `
  color:${theme().white};
  background-color: ${(props) => props.warningtype === "warning" ? theme().danger : '#2EB85C'};
  font-size:14px;
  box-shadow:0px 0px;
  &:hover {
      border-color:${(props) => props.warningtype === "warning" ? theme().danger : '#2EB85C'};
      background-color: ${(props) => props.warningtype === "warning" ? theme().danger : '#2EB85C'};
  }
  &:focus{
      box-shadow:0px 0px;
      border-color:${(props) => props.warningtype === "warning" ? theme().danger : '#2EB85C'};
      background-color: ${(props) => props.warningtype === "warning" ? theme().danger : '#2EB85C'};
  }
`

export const Row = styled(CRow)`
  --cui-gutter-y:0rem;
  margin-top:20px;
`

export const ConfirmButtonPrimary = styled(CButton)`
  color:${theme().white};
  font-size:14px;
  box-shadow:0px 0px;
`

export const FormFooter = styled.div<{ footerposition: "flex-start" | "center" | "flex-end" }>`
  display:flex;
  justify-content:${(props) => props.footerposition};
  column-gap:10px;
  padding-top:10px;
  padding-bottom:16px;
`