import axios from 'axios'
import endpoints from 'api/endpoints'
import { decamelizeKeys  } from 'humps'
import { CategoryFormValidations, CategoryFormValidationsEdit } from 'scenes/TripForms/Tripcategoryform/utils'
import { generateUrl, generateSortString } from 'utils'
import { SortType } from 'types/misc'
/* import { generateUrl } from 'utils'
import { toast } from 'react-toastify'
import i18n from 'i18next' */

const GetCategories = (page: number, searchTerm: string, sort?: SortType) => {
    const searchTermQS = `${searchTerm ? `&search=${searchTerm}` : ''}`;
    let endpoint = `${endpoints.TRIPS.CATEGORIES.GET_CATEGORIES}?page=${page}${searchTermQS}`;

    if (sort && sort.field !== null && sort.direction !== null && sort.direction !== "") {
        endpoint += generateSortString(sort);
    }

    return axios.get(endpoint)
        .then(res => res.data)
        .catch(err => {
        })
}

const GetAllCategories = () => {
    return axios.get(endpoints.TRIPS.CATEGORIES.GET_ALL_CATEGORIES)
        .then(res => res.data)
        .catch(err => {
        })
}

const GetCategory = (id) => {
    const endpoint = generateUrl(endpoints.TRIPS.CATEGORIES.GET_CATEGORY, { id })
    return axios.get(endpoint)
        .then(res => res.data)
        .catch(err => {
        })
}


const CreateCategory = (params) => { 
    const decMLZParams = decamelizeKeys(params)

    const categoryFormData = new FormData();
    Object.keys(decamelizeKeys(CategoryFormValidations)).forEach(valKey => {
        if(valKey === "icon"){
            if(params[valKey].value) {
                categoryFormData.append(valKey, params[valKey].value)
            } else {
                categoryFormData.append(valKey, "")
            }
        }
        else{
            categoryFormData.append(valKey, decMLZParams[valKey].value)
        }
        
    })
    return axios.post(endpoints.TRIPS.CATEGORIES.CREATE_CATEGORY, categoryFormData)
        .then(res => res.data)
        .catch(err => { throw err })
}

const EditCategory = (id, params, isIconRemoved, isIconChanged) => {
    const decMLZParams = decamelizeKeys(params)
    const endpoint = generateUrl(endpoints.TRIPS.CATEGORIES.EDIT_CATEGORY, { id })
    
    const categoryFormData = new FormData();
    Object.keys(decamelizeKeys(CategoryFormValidationsEdit)).forEach(valKey => {
        if(valKey === "icon"){
            if(isIconRemoved){
                categoryFormData.append(valKey, "")
            }
            else if(isIconChanged){
                categoryFormData.append(valKey, params[valKey].value) 
            }        
        }
        else {
            categoryFormData.append(valKey, decMLZParams[valKey].value)
        }
    })
    
    return axios.patch(endpoint, categoryFormData)
        .then(res => res.data)
        .catch(err => { throw err })  
}

export default {
    GetAllCategories,
    GetCategories,
    GetCategory,
    CreateCategory,
    EditCategory,
}