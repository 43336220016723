import React from 'react'
import { PadWrapper } from './styles'
import { Text } from '../../styles'

type PropTypes = {
  location: string;
  mainColor: string;
}

const Pad = ({ location, mainColor }: PropTypes) => {
  return (
    <PadWrapper activitycolor={mainColor}>
      <Text>{location}</Text>
    </PadWrapper>
  )
}

export default Pad