import axios from 'axios'
import endpoints from 'api/endpoints'
import { generateUrl, generateSortString } from 'utils'
import { toast } from 'react-toastify'
import i18n from 'i18next'
import { SortType } from 'types/misc';
import { camelizeKeys } from 'humps'


const SearchBookingCustomers = (searchTerm: string ) => {
    const endpoint = `${endpoints.CUSTOMERS.CUSTOMERS_URL}?booking_customer_search&search=${searchTerm}`
    return axios.get(endpoint)
        .then(res => res.data)
        .catch(err => { throw err })
}

const GetCustomers = (searchTerm: string, sort: SortType, page: number ) => {
    const searchTermQS = `${searchTerm ? `&search=${searchTerm}` : ''}`;
    let endpoint = `${endpoints.CUSTOMERS.CUSTOMERS_URL}?page=${page}${searchTermQS}`

    if (sort.field !== null && sort.direction !== null && sort.direction !== "") {
        endpoint += generateSortString(sort);
    }

    return axios.get(endpoint)
        .then(res => res.data)
        .catch(err => { throw err })
}

const GetSingleCustomer = (id) => {
    const endpoint = generateUrl(endpoints.CUSTOMERS.SINGLE_CUSTOMER_URL, { id })
    return axios.get(endpoint)
        .then(res => {
            return res ? res.data : null
        })
        .catch(err => { throw err })
}

const CreateCustomer = (body) => {
    const formData = new FormData();
    const token = window.localStorage.getItem('access_token')


    Object.keys(body).forEach(key => {
        if (!!body[key]) formData.append(key, body[key]);
    })

    return axios.create({
        headers:{
            'Authorization': 'Bearer ' + token, 
            'Content-Type': 'application/json',
        }
    }).post(endpoints.CUSTOMERS.CUSTOMERS_URL, formData)
        .then(res => {
            return camelizeKeys(res)
        })
        .catch(err => {
            throw err 
        })
}


const EditCustomer = (id, body, isAvatarChanged, isAvatarRemoved) => {
    const formData = new FormData();
    const token = window.localStorage.getItem('access_token')

    Object.keys(body).forEach(key => {
        if(key==="avatar"){
            if(isAvatarRemoved){
                formData.append(key, "")
            }
            else if(isAvatarChanged){
                formData.append(key, body[key])
            }
        }

        if (!!body[key] && key !== "avatar") formData.append(key, body[key]);
    })

    const endpoint = generateUrl(endpoints.CUSTOMERS.SINGLE_CUSTOMER_URL, { id })

    return axios.create({
        headers:{
            'Authorization': 'Bearer ' + token, 
            'Content-Type': 'application/json',
        }
    }).patch(endpoint, formData)
        .then(res => {
            return camelizeKeys(res.data)
        })
        .catch(err => {
            throw err 
        })
}

const DeleteCustomer = (id) => {
    const endpoint = generateUrl(endpoints.CUSTOMERS.SINGLE_CUSTOMER_URL, { id })
    return axios.delete(endpoint, id)
        .then(res => {
            toast.info(i18n.t('toasts.deleteCustomerSuccess'));
            return res.data;
        }).catch(err => {
            toast.error(i18n.t('toasts.deleteCustomerError'));
            throw err;
        })
}

const GetBookingsByCustomer = (
    page: number,
    sort: SortType,
    customer: string,
    searchTerm?: string
    ) => {

    const customerQS = `${customer ? `&customer=${customer}` : ''}`;
    const sortQS = sort.field && sort.direction ? generateSortString(sort) : ''
    const searchQS = `${searchTerm ? `&search=${searchTerm}` : ''}`;

    const endpoint = `
        ${endpoints.TRIPS.BOOKINGS.GET_BOOKINGS}?page=${page}${sortQS}${customerQS}${searchQS}
    `;

    return axios.get(endpoint)
    .then(res => res.data)
    .catch(err => {
        toast.error(i18n.t("toasts.bookingsGetError"))
    })
};

const ExportExcel = () => {
    const token = window.localStorage.getItem("access_token")

    return fetch(endpoints.CUSTOMERS.EXPORT_EXCEL, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        }
    })
        .then(res => res.blob())
        .catch(err => { throw err })
}

export default {
    CreateCustomer,
    DeleteCustomer,
    EditCustomer,
    GetCustomers,
    GetSingleCustomer,
    SearchBookingCustomers,
    GetBookingsByCustomer,
    ExportExcel
}
