/**
 * Usertimerecordtablerow component
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */

import React from 'react'
import {
  CustomTableRow,
  ActionsCell,
  ChevronCell,
  ImageCell,
  NameAndRoleCell,
  ColoredDataCellText,
  WarningTooltip,
  WarningIcon
} from 'shared/genericStyledComponents'
import ChevronRight from 'assets/icons/chevron_right';
import * as icon from '@coreui/icons'
import User from 'assets/icons/user';
import theme from 'ui/theme';
import { useDispatch, useSelector } from 'react-redux';
import { convertMinutesToHoursAlternate } from 'scenes/Usertimerecorddetails/utils';
import { CAvatar, CTableDataCell } from '@coreui/react-pro'
import { actions, RootState } from 'store/rootSlices'
import { DateTime } from 'luxon'
import CustomCheckbox from 'shared/components/CustomCheckbox';
import { StatementsTab } from 'types/invoicing/statements'

const Usertimerecordtablerow = (props) => {

  const dispatch = useDispatch()

  const {
    startDate,
    endDate,
    tab,
    toApproveList,
    toDisapproveList
  } = useSelector((state: RootState) => state.TimeRecord);

  const {
    addApprove,
    addDisapprove,
    removeApprove,
    removeDisapprove
  } = actions.TimeRecord

  const WEEK_INTERVAL_DURATION = 0.8571428571428571
  let end = DateTime.fromISO(endDate)
  let start = DateTime.fromISO(startDate)

  return (
    <CustomTableRow>
      <ImageCell>
        <div onClick={() => props.onClick()}>
          {props.user?.avatar ?
            <CAvatar color='secondary' src={props.user.avatar} status={props.user.lastClocked && props.user.lastClocked.hasFinished === false ? `success` : `danger`} ></CAvatar>
            :
            <CAvatar color='secondary' status={props.user.lastClocked && props.user.lastClocked.hasFinished === false ? `success` : `danger`} ><User /></CAvatar>
          }
        </div>
      </ImageCell>
      <NameAndRoleCell><h5 onClick={() => props.onClick()}>{props.user?.name}</h5><h6>{props.user?.professionalCategory}</h6></NameAndRoleCell>
      <CTableDataCell>{end?.diff(start, 'weeks').values.weeks !== WEEK_INTERVAL_DURATION ? '-' : `${props.user?.contractWeeklyHours}h`}</CTableDataCell>
      <CTableDataCell>{convertMinutesToHoursAlternate(props.user?.timeWorking)}</CTableDataCell>
      <CTableDataCell>{convertMinutesToHoursAlternate(props.user?.timeOnboard)}</CTableDataCell>
      <ColoredDataCellText
        trigger={Math.abs(0) > 10}
        color={Math.abs(0) > 30 ?
          `${theme().danger}`
          :
          Math.abs(0) > 10 ?
            `${theme().warning}`
            :
            `${theme().gray500Color}`
        }
      >
        {
          Math.abs(0) > 10 && <WarningTooltip placement="bottom" content={'settle hours'}>
            <WarningIcon
              color={Math.abs(0) > 30 ?
                `${theme().danger}`
                :
                Math.abs(0) > 10 ?
                  `${theme().warning}`
                  :
                  `${theme().gray500Color}`
              } height={14} icon={icon.cilWarning} size={"sm"} />
          </WarningTooltip>
        }
      </ColoredDataCellText>
      <CTableDataCell>
        <CustomCheckbox
          id={`timeRecord-checkUser-${props.user.id}`}
          checked={tab === StatementsTab.PENDING ?
            toApproveList.find(uuid => uuid === props.user.uuid) ? true : false
            :
            toDisapproveList.find(uuid => uuid === props.user.uuid) ? true : false
          }
          onClick={(e) => {
            const newStatus = tab === StatementsTab.PENDING ?
              toApproveList.find(uuid => uuid === props.user.uuid) ? false : true
              :
              toDisapproveList.find(uuid => uuid === props.user.uuid) ? false : true


            tab === StatementsTab.PENDING ?
              newStatus === true ? dispatch(addApprove({ uuid: props.user.uuid, toApproveList })) : dispatch(removeApprove({ uuid: props.user.uuid, toApproveList }))
              :
              newStatus === true ? dispatch(addDisapprove({ uuid: props.user.uuid, toDisapproveList })) : dispatch(removeDisapprove({ uuid: props.user.uuid, toDisapproveList }))
          }}
        />
      </CTableDataCell>
      <ActionsCell>
        {props.actions}
        <ChevronCell id={`props.id-chevron`}>
          {
            props.permission && <a href={`/colaboradores/registo-horas/editar/${props.user?.uuid}`} onClick={(e) => {
              e.preventDefault()
              dispatch(actions.App.navigateTo(`/colaboradores/registo-horas/editar/${props.user?.uuid}`))
            }}>
              <ChevronRight></ChevronRight>
            </a>
          }
        </ChevronCell>
      </ActionsCell>
    </CustomTableRow>
  )
}

export default Usertimerecordtablerow