import { CBreadcrumbItem } from '@coreui/react-pro'
import styled from 'styled-components'

import theme from 'ui/theme';


const Wrapper = styled.div`
  display: flex;
  align-items: center; 
  justify-content: space-between;
  min-height: 48px;
  max-height: 48px;
  padding-left: 24px;
  background-color: ${theme().white};
  border-bottom: 1px solid ${theme().gray200Color};
  z-index: 21;
`

const CustomBreadcrumbItem = styled(CBreadcrumbItem)`
  > a {
    color: ${props => props.active ? theme().gray600Color : theme().blue} !important;
    text-decoration: none;
    font-family: Inter;
  }
`

const CustomComponentContainer = styled.div``

export {
  CustomBreadcrumbItem,
  CustomComponentContainer,
  Wrapper,
}