/**
 * Tripbookings scene slice
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */
import { createSlice } from '@reduxjs/toolkit'
import { TripBookingListItem } from 'types/trips/bookings'
import { SortType } from 'types/misc';

interface initialState {
    page: 1,
    sort: SortType,
    totalBookings: number,
    loading: false,
    exportLoading: boolean,
    hasDateParams:boolean,
    startDate: string,
    endDate: string,
    activityFilter: string,
    departureFilter: string,
    arrivalFilter: string,
    partnerFilter: string,
    statusFilter: string,
    searchTerm: string,
    showModal: boolean,
    bookings: TripBookingListItem[],
    tripDateView: boolean,
    ticketPdf: string | null ,
    partners: any[],
    searchPartnerResults: any[],
    searchPartnerText: string,
    partnersPage: number,
    isPartnersLastPage: boolean,
    hasIssuesFilter: boolean
}

const now = new Date().toISOString().slice(0, 10);

const initialState: initialState = {
    page: 1,
    sort: {
        field: null,
        direction: null,
    },
    totalBookings: 0,
    loading: false,
    exportLoading: false,
    tripDateView: false,
    showModal: false,
    activityFilter:"",
    departureFilter: "",
    arrivalFilter: "",
    statusFilter: "",
    partnerFilter: "",
    searchTerm: "",
    hasDateParams:false,
    startDate: now,
    endDate: now,
    bookings: [],
    ticketPdf: null,
    searchPartnerResults: [],
    partners: [],
    searchPartnerText: "",
    partnersPage: 1,
    isPartnersLastPage: false,
    hasIssuesFilter: false
}

export default createSlice({
    name: 'Tripbookings',
    initialState,
    reducers: {
        onMount: (state, { payload }) => {},
        onUnmount: (state) => {
            state.partnerFilter = ""
            state.searchPartnerResults = []
            state.partners = []
            state.partnersPage = 1
            state.isPartnersLastPage = false
        },
        setHasIssuesFilter: (state, { payload }) => {
            state.bookings = []
            state.page = 1
            state.hasIssuesFilter = payload
        },
        setPage: (state, { payload }) => {
            state.page = payload;
        },
        setPartnersPage: (state, {payload}) => {
            state.partnersPage = payload
        },
        setIsPartnersLastPage: (state, { payload }) => {
            state.isPartnersLastPage = payload
        },
        fetchPartners: () => {},
        setSort: (state, { payload }) => {
            state.sort = payload;
        },
        setLoading: (state, { payload }) => {
            state.loading = payload;
        },
        setExportLoading: (state, { payload }) => {
            state.exportLoading = payload;
        },
        getBookings: () => {},
        setBookings: (state, { payload }) => {
            state.bookings = payload;
        },
        setPartners: (state, { payload }) => {
            state.partners = [...payload.partners, ...payload.newPartners];
        },
        setSearchPartnerResults: (state, { payload }) => {
            state.searchPartnerResults = payload;
        },
        setSearchPartnerText: (state, { payload }) => {
            state.searchPartnerText = payload;
        },
        getBookingTicket: (state, { payload }) => {},
        setBookingTicketPdf: (state, { payload }) => {

            state.bookings = payload.bookings.map(( b: TripBookingListItem) => {
                if(payload.id === b.id){
                    return {
                        ...b,
                        ticket: payload.ticket
                    }
                }
                else return b
                
            })
            //state.ticketPdf = payload
        },
        setTotalBookings: (state, { payload }) => {
            state.totalBookings = payload;
        },
        setTripDateView: (state, { payload }) => {
            state.bookings = []
            state.page = 1
            state.tripDateView = payload;
        },
        setActivityFilter: (state, { payload }) => {
            state.bookings = []
            state.page = 1
            state.activityFilter= payload
        },
        setDepartureFilter: (state, { payload }) => {
            state.bookings = []
            state.page = 1
            state.departureFilter= payload
        },
        setStatusFilter: (state, { payload }) => {
            state.bookings = []
            state.page = 1
            state.statusFilter= payload
        },
        setArrivalFilter: (state, { payload }) => {
            state.bookings = []
            state.page = 1
            state.arrivalFilter= payload
        },
        getURLParams:(state, payload) => {},
        setDatesInterval: (state, { payload }) => {
            state.bookings = []
            state.page = 1
        },
        setPartnerFilter: (state, { payload }) => {
            state.partnerFilter = payload
        },
        setSearchTerm: (state, { payload }) => {
            state.bookings = []
            state.page = 1
            state.searchTerm = payload;
        },
        setHasDateParams: (state, { payload }) => {
            state.hasDateParams = payload
        },
        setStartDate: (state, { payload }) => {
            state.startDate = payload
        },
        setEndDate: (state, { payload }) => {
            state.endDate = payload
        },
        populateFiltersOnMount:(state, { payload }) => {
            state.activityFilter = payload.activityFilter
            state.departureFilter = payload.departureFilter
            state.arrivalFilter = payload.arrivalFilter
            state.searchTerm = payload.searchTerm
            state.startDate = payload.startDate
            state.endDate = payload.endDate
            state.partnerFilter = payload.partnerFilter
            state.hasIssuesFilter = payload.hasIssuesFilter
        },
        exportBookings: () => { },
        downloadBookings: (state, { payload }) => {
            const file = payload
            const fileName = `bookings-list.xlsx`
            const finalFileName = fileName.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(" ", "");

            const url = URL.createObjectURL(file)
            const link = document.createElement('a')
            link.href = url
            link.setAttribute(
                'download',
                finalFileName
            )
            document.body.appendChild(link);
            link.click()
            link.parentNode?.removeChild(link)
        },
    }
})
