import styled from 'styled-components'
import { CustomTableRow } from 'shared/genericStyledComponents'

export const CustomCTableRow = styled(CustomTableRow)`

  td:first-child {
    padding-left:15px;
    width:1%;
  }

  td:nth-child(2){
    width:25%;
  }
`
