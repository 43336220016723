/**
 * Configurations scene sagas
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */
import { takeLatest, putResolve, call, select, all } from 'redux-saga/effects'
import API from 'api'
import { actions, RootState } from 'store/rootSlices'
import { toast } from 'react-toastify'
import i18n from 'i18next';
import { DateTime } from "luxon"
import * as Check from 'validations';
import { AnyAction } from 'redux';
//import { ConfigurationsValidation, DefaultCommissionsValidations  } from '../utils';
import { ConfigurationsValidation } from '../utils';
import { CancelReschedulesList, DefaultCommissions } from 'types/config/config';
import { activityCommissionForm, extraCommissionForm } from 'types/collaborators/collaborator'
import config from 'config';
import { hasPermission } from 'permissions'
const { PERMISSIONS } = config


function* onMountSaga() {
  const { permissions } = yield select((state: RootState) => state.App);
  yield putResolve(actions.Configurations.setHasEditPermissions(hasPermission(permissions, PERMISSIONS.SETTINGS.EDIT_SETTING)))
  yield putResolve(actions.Configurations.getCancelReschedules())
  yield putResolve(actions.Configurations.getCommissions())
}

function* getCommissionsSaga() {
  let { allAtivities, allExtras } = yield select((state: RootState) => state.TripsConfiguration);

  if(!allAtivities?.length || !allExtras?.length) {
    allAtivities = yield call(API.Activities.GetAllActivities)
    allExtras = yield call(API.Extras.GetAllExtras)
  }
  
  if (allAtivities && allExtras) {
    yield putResolve(actions.Configurations.setDefaultCommissions({ activities: allAtivities, extras: allExtras }))
  }

  const activitiesCommissionForm: Array<activityCommissionForm> = yield select((state: RootState) => state.Configurations.activitiesForm);
  const extrasCommissionForm: Array<extraCommissionForm> = yield select((state: RootState) => state.Configurations.extrasForm);
  try {
    const commissions: DefaultCommissions = yield call(API.Collaborators.GetDefaultCommissions);

    if(commissions) {
      yield putResolve(actions.Configurations.populateDefaultCommissionsOnMount({ 
        extrasCommissionForm,
        activitiesCommissionForm, 
        extras: commissions.extras, 
        activities: commissions.activities
      }))
    }
  } catch (error) {
    toast.error(i18n.t("toasts.getDefaultCommissionsError"))
  }finally {
    yield putResolve(actions.Configurations.setLoading(false));
  } 
}

function* getCancelReschedulesSaga() {
  const { page } = yield select((state: RootState) => state.Configurations);

  try {
    const cancelRes: CancelReschedulesList = yield call(API.TripSettings.GetCancelReschedules, page);

    if(cancelRes) {
      yield putResolve(actions.Configurations.setConfigurations(cancelRes.results))
      yield putResolve(actions.Configurations.setTotal(cancelRes.count))
    }
    
  } catch (error) {
    toast.error(i18n.t("toasts.configurationsGetError"))
  }finally {
    yield putResolve(actions.Configurations.setLoading(false));
  } 
}

function* onSubmitCommissionsSaga() {
  yield putResolve(actions.Configurations.setSubmitButtonDisabled(true))
  const activitiesCommissionForm: Array<activityCommissionForm> = yield select((state: RootState) => state.Configurations.activitiesForm);
  const extrasCommissionForm: Array<extraCommissionForm> = yield select((state: RootState) => state.Configurations.extrasForm);

  const activitiesCommissionsValidation = Check.checkCommissionsValidation(activitiesCommissionForm)
  const extrasCommissionsValidation = Check.checkCommissionsValidation(extrasCommissionForm)

  if (activitiesCommissionsValidation.invalid || extrasCommissionsValidation.invalid) {
    yield putResolve(actions.Configurations.updateValidationsOnEntityCommissions({ activities: activitiesCommissionsValidation.form, extras: extrasCommissionsValidation.form }))
    toast.error(i18n.t("toasts.invalidFields"));
    yield putResolve(actions.Configurations.setSubmitButtonDisabled(false))
  } else {
    const activeActivitiyCommissions = activitiesCommissionForm.filter(act => act.isCustom)
    const activeExtrasCommissions = extrasCommissionForm.filter(ext => ext.isCustom)

    let finalForm = {
      activities: activeActivitiyCommissions.map((act: activityCommissionForm) => {
        return {
          activity: act.uuid,
          amount: Number.parseFloat(act.commission.value).toFixed(2)
        }
      }),
      extras: activeExtrasCommissions.map((act: activityCommissionForm) => {
        return {
          extra: act.uuid,
          amount: Number.parseFloat(act.commission.value).toFixed(2)
        }
      })
    }

    try {
      const success = yield call(API.Collaborators.EditDefaultCommissions, finalForm)

      if (success) {
        yield putResolve(actions.Configurations.setSubmitButtonDisabled(false))
        yield putResolve(actions.Configurations.setCommissionsChanged(false))
        toast.success(i18n.t("toasts.defaultCommissionsEditSuccess"))
      }
    } catch (error) {
      toast.error(i18n.t("toasts.defaultCommissionsEditError"))
    }
    yield putResolve(actions.Configurations.setSubmitButtonDisabled(false))
  }
}


function* onSubmitSaga({ payload }: AnyAction) {
  yield putResolve(actions.Configurations.setSubmitButtonDisabled(true))
  const {
    configurations
  } = yield select((state: RootState) => state.Configurations);

  const configValidation = Check.checkListValidation(configurations, ConfigurationsValidation)

  if(configValidation.invalid){
    yield putResolve(actions.Configurations.onUpdateValidations(configValidation.form))
    yield putResolve(actions.Configurations.setSubmitButtonDisabled(false))
    toast.error(i18n.t("toasts.invalidFields"))
  }
  else{
    const configResponse = yield all(
      configurations.map(conf => {
        const finalForm = {
          updatedAt: DateTime.now().toISO(),
          fee: conf.fee.value,
          isActive: conf.isActive.value,
          timeNoFee: conf.timeNoFee.value,
          timeLimit: conf.timeLimit.value,
          invoiceArticle: conf.invoiceArticle.value,
          cancellationLimit: conf.cancellationLimit.value,
        }

        return call(API.TripSettings.EditCancelReschedules, conf.id, finalForm)        
      })
    )
    if(configResponse.includes(-1)){
      toast.error(i18n.t("toasts.configurationsEditError"))
    }
    else{
      toast.success(i18n.t("toasts.configurationsEditSuccess"))
    }
    yield putResolve(actions.Configurations.setSubmitButtonDisabled(false))
  }
}




export default function* watcherSignin() {
  yield takeLatest('Configurations/onMount', onMountSaga)
  yield takeLatest('Configurations/getCancelReschedules', getCancelReschedulesSaga)
  yield takeLatest('Configurations/getCommissions', getCommissionsSaga)
  yield takeLatest('Configurations/onSubmitCommissions', onSubmitCommissionsSaga)
  yield takeLatest('Configurations/onSubmit', onSubmitSaga)
}


