import React from 'react'
import { useDispatch } from 'react-redux';
// Styles
import theme from 'ui/theme';
import { Wrapper, PassengerCounter, Counter, ActionsWrapper, ButtonIcon } from './styles'
import { Icon, TextCounter } from '../../styles';
// Icons
import StartTrip from 'assets/icons/start_trip'
import EndTrip from 'assets/icons/end_trip'
import Ticket from 'assets/icons/ticket'
import TicketCheckin from 'assets/icons/ticket_checkin'
import Edit from 'assets/icons/edit'
import Feed from 'assets/icons/feed'
import Expand from 'assets/icons/expand_more'
import Collapse from 'assets/icons/expand_less'
import Danger from 'assets/icons/danger_amber'
// Store
import { actions } from 'store/rootSlices';

type PropTypes = {
  onClick: () => void;
  permission: boolean;
  planning: any;
  showDetails: boolean;
  setShowDetails: (value: boolean) => void;
}

const PadActions = ({ onClick, permission, planning, showDetails, setShowDetails }: PropTypes) => {

  const { setShowCheckInModal, setCheckinOutboundSelected } = actions.TripPlannings
  const dispatch = useDispatch()

  const _renderLink = () => {
    return permission && (
      <a href={`/viagens/agendamentos/editar-agendamento/${planning.id}`} onClick={(e) => {
        e.preventDefault()
        dispatch(actions.App.navigateTo(`/viagens/agendamentos/editar-agendamento/${planning.id}`))
      }}>
        <Edit />
      </a>
    )
  }
 
  const _renderCounter = (isOutbound: boolean, ) => {
    const isFull = isOutbound
    ? planning.outboundSeats >= planning.maxCapacity
    : planning.inboundSeats >= planning.maxCapacity;

    return (
      <Counter 
        margin='0' 
        onClick={e => {
          setShowDetails(true)
          dispatch(setCheckinOutboundSelected(isOutbound ? true : false))
          dispatch(setShowCheckInModal({ 
            show: true, 
            planningId: planning.id, 
            stop: {id: -1, name: ""}, 
            openBy: 'generic',
            ...(planning.vehicles.length > 0 ? { vehicle: planning.vehicles[0] } : null),
          }))
        }}  
      >
        {
          isOutbound ? (
            <Icon marginr='0'>{isFull ? <Danger /> : <StartTrip fill={theme().gray500Color} />}</Icon>
          ) : (
            <Icon marginr='0'>{isFull ? <Danger /> : <EndTrip fill={theme().gray500Color} />}</Icon>
          )
        }
        <TextCounter><span><b>{ isOutbound ? planning.outboundSeats : planning.inboundSeats}</b>/{planning.maxCapacity}</span></TextCounter>
      </Counter>
    )
  }

  return (
    <Wrapper>
      <PassengerCounter style={{cursor: "pointer"}}>
        { _renderCounter(true) }
        { _renderCounter(false) }
      </PassengerCounter>

      <PassengerCounter>
        <Counter style={{cursor: "pointer"}} onClick={onClick} margin='0'>
          <Icon marginr='5'><TicketCheckin /></Icon>
          {<TextCounter><span><b>{planning.outboundSeatsCheckedIn}</b>/{planning.outboundSeats}</span></TextCounter>}
        </Counter>
        <Counter style={{cursor: "pointer"}} onClick={onClick} margin='0'>
          <Icon marginr='5'><TicketCheckin /></Icon>
          {<TextCounter><span><b>{planning.inboundSeatsCheckedIn}</b>/{planning.inboundSeats}</span></TextCounter>}
        </Counter>
      </PassengerCounter>

      <ActionsWrapper>
        <ButtonIcon
          marginr='0'
          onClick={(e) => { }}>
          { _renderLink() }
        </ButtonIcon>
        <ButtonIcon pointer='auto' marginr='0'><Feed fill={`${theme().secondaryColor}`} /></ButtonIcon>
        <ButtonIcon pointer='auto' marginr='0'><Ticket fill={`${theme().secondaryColor}`} /></ButtonIcon>
        <ButtonIcon marginl='0' onClick={onClick}>
          {showDetails ? <Collapse /> : <Expand />}
        </ButtonIcon>
      </ActionsWrapper>
    </Wrapper>
  )
}

export default PadActions