/**
 * BookingForm scene
 *
 * @author João Flores <jflores@ubiwhere.com>
 *
 *
 */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions, RootState } from 'store/rootSlices';
import theme from 'ui/theme';
import { Link, useParams } from 'react-router-dom';
import { camelize } from 'humps';
import { CForm, CCol } from '@coreui/react-pro';
//styles
import * as Styles from './styles';
//components
import Modals from './components/Modals';
import ButtonsRow from './components/ButtonsRow';
import SectionContainer from 'shared/components/Sectioncontainer';
import CustomTextInput from 'shared/components/CustomTextInput';
import CustomButton from 'shared/components/CustomButton';
import Loadingcontainer from 'shared/components/Loadingcontainer';
import BookingLegs from './components/BookingLegs';
import PassengerTypes from './components/PassengerTypes';
import PaymentsMade from './components/PaymentsMade';
import PaymentsPending from './components/PaymentsPending';
import PaymentForm from './components/PaymentForm';
import CustomerForm from './components/CustomerForm';
import Partnerform from './components/Partnerform';
import TripForm from './components/Tripform';
import ExtrasTypes from './components/ExtrasTypes';

const BookingForm = ({ t, i18n }) => {
  const { id } = useParams();

  const {
    bookingUID,
    bookingState,
    edit,
    loading,
    tripInfoForm,
    hasEditPermission,
    partner,
    partnerMember,
    showPaymentForm,
    canRescheduleLegs,
    createdBy,
    canceledBy,
    samePassengers,
    sameExtras,
    directionFilter,
  } = useSelector((state: RootState) => state.BookingForm);

  const {
    onMount,
    onUnmount,
    resetSelectedPassengerTypes,
    resetSelectedExtras,
    onTripInfoFormChange,
    setIsReschedule,
    setShowSendTicketModal,
    onSendTicketFormChange,
    setLockPassengerInputs,
    setLockExtraInputs,
    setIsBeachReschedule,
    setLegInboundView
  } = actions.BookingForm;

  const dispatch = useDispatch();

  const { allPassengerTypes, allExtras } = useSelector((state: RootState) => state.TripsConfiguration);

  //TODO: "this is so not right" (Bono)
  useEffect(() => {
    dispatch(onMount(id));

    dispatch(
      resetSelectedPassengerTypes(
        allPassengerTypes.map((p) => {
          return {
            id: p.id,
            isDisabled: true,
            price: 0,
            occupySeat: p.occupySeat,
            quantity: {
              value: '0',
              valid: true,
              message: '',
            },
            totalPrice: '-',
          };
        })
      )
    );

    dispatch(
      resetSelectedExtras(
        allExtras.map((p) => {
          return {
            id: p.id,
            isDisabled: true,
            price: 0,
            occupySeat: p.occupied,
            quantity: {
              value: '0',
              valid: true,
              message: '',
            },
            totalPrice: '-',
          };
        })
      )
    );

    return () => {
      dispatch(onUnmount());
    };
  }, [
    id,
    dispatch,
    onMount,
    onUnmount,
    resetSelectedPassengerTypes,
    allPassengerTypes,
    allExtras,
    resetSelectedExtras
  ]);

  if (loading) {
    return <Loadingcontainer />;
  }

  const _renderPassengerTypes = () => { 
    return directionFilter === 'beach' ? <ExtrasTypes t={t} extrasTypes={allExtras} /> : <PassengerTypes t={t} passengerTypes={allPassengerTypes} />
  }

  return (
    <>
      <CForm>
        <Styles.CustomSection
          title={
            edit
              ? `${t('tripBookings.booking')} ${bookingUID}`
              : t('tripBookings.customerInfoTitle')
          }
          underTitle={
            edit && partner && partnerMember ? (
              <Styles.UnderTitle>
                {t('entities.entity')}:{' '}
                <Link to={`/entidades/editar-entidade/${partner.uuid}`} target="_blank">
                  {partner.name}
                </Link>{' '}
                - {t('common.partner')}:{' '}
                <Link
                  to={`/entidades/${partner.uuid}/editar-membro/${partnerMember.uuid}`}
                  target="_blank"
                >
                  {partnerMember.name}
                </Link>
              </Styles.UnderTitle>
            ) : edit && partner ? (
              <Styles.UnderTitle>
                {t('entities.entity')}:{' '}
                <Link to={`/entidades/editar-entidade/${partner.uuid}`} target="_blank">
                  {partner.name}
                </Link>
              </Styles.UnderTitle>
            ) : (
              <></>
            )
          }
          titleLabel={
            edit ? (
              <Styles.BookingStateLabel className={bookingState}>
                {i18n.t(
                  `tripBookings.${camelize(
                    bookingState === 'paid' || bookingState === 'refunded'
                      ? bookingState + 'F'
                      : bookingState
                  )}`
                )}
              </Styles.BookingStateLabel>
            ) : (
              <></>
            )
          }
          extra={
            edit ? (
              <Styles.ExtraInfo>
                <CustomButton
                  disabled={!hasEditPermission || bookingState === 'pending'}
                  id="bookingForm-sendTicket"
                  onClick={() => {
                    dispatch(setShowSendTicketModal(true));
                    if (tripInfoForm.email.value !== null) {
                      dispatch(
                        onSendTicketFormChange({
                          email: { value: tripInfoForm.email.value, valid: true, message: '' },
                        })
                      );
                    }
                  }}
                  text={t('tripBookings.sendTicket')}
                  color="primary"
                  variant="outline"
                  buttonStyle={{ borderColor: theme().primary }}
                  icon="send"
                />
              </Styles.ExtraInfo>
            ) : (
              <></>
            )
          }
          titleTooltip={
            <>
              {
                createdBy.name && (
                  <Styles.TooltipText><b>{t("tripBookings.createdBy")}:</b><p>{createdBy.name} - {createdBy.date}</p></Styles.TooltipText>
                )
              }
              {
                canceledBy.name && (
                  <Styles.TooltipText><b>{t("tripBookings.canceledBy")}:</b><p>{canceledBy.name} - {canceledBy.date}</p></Styles.TooltipText>
                )
              }
            </>
          }
        >
          <Partnerform t={t} />
          <CustomerForm t={t} />
        </Styles.CustomSection>

        <SectionContainer
          title={t('tripBookings.tripInfo')}
          style={{ paddingBottom: '24px', alignItems: 'center' }}
        >
          <TripForm t={t} />
        </SectionContainer>

        <SectionContainer
          title={t('tripBookings.tripInfoTitle')}
          style={{ paddingBottom: '24px' }}
          extra={
            <Styles.TitleExtras>
              <Link
                style={{ fontSize: '14px', textDecoration: 'none' }}
                to="/viagens/reservas"
                target="_blank"
              >
                {t('tripBookings.viewBookings')}
              </Link>
              {edit && canRescheduleLegs && (
                <CustomButton
                  disabled={!hasEditPermission || !canRescheduleLegs || bookingState === 'pending' || !samePassengers || !sameExtras}
                  id="bookingForm-rescheduleBooking"
                  onClick={() => {
                    if (directionFilter === 'beach') {
                      dispatch(setIsBeachReschedule(true));
                      dispatch(setLockExtraInputs(true))
                    } else {
                      dispatch(setIsReschedule(true));
                      dispatch(setLockPassengerInputs(true))
                      dispatch(setLegInboundView(directionFilter === 'inbound'))
                    }
                  }}
                  text={directionFilter === 'beach' ? t('tripBookings.reschedule') : t('tripBookings.rescheduleTrip')}
                  color="primary"
                  variant="outline"
                  buttonStyle={{ borderColor: theme().primary }}
                  icon="refresh"
                />
              )}
            </Styles.TitleExtras>
          }
        >
          <BookingLegs t={t} />
          { _renderPassengerTypes() }
          <Styles.Row>
            <CCol sm={12}>
              <CustomTextInput
                id="bookingForm-tripObservations"
                disabled={!hasEditPermission}
                valid={tripInfoForm.observations.valid}
                value={tripInfoForm.observations.value}
                helpText={tripInfoForm.observations.message}
                placeholder={t('placeholders.observations')}
                label={t('common.observations')}
                onChange={(e) => {
                  dispatch(
                    onTripInfoFormChange({
                      ...tripInfoForm,
                      observations: { value: e.target.value, message: '', valid: true },
                    })
                  );
                }}
              />
            </CCol>
          </Styles.Row>
        </SectionContainer>

        <PaymentsMade t={t} />

        {showPaymentForm && (
          <SectionContainer
            title={t('tripBookings.pendingPayments')}
            style={{ paddingBottom: '24px' }}
          >
            <PaymentsPending t={t} />
            <PaymentForm t={t} />
          </SectionContainer>
        )}

        <ButtonsRow />
      </CForm>
      <Modals id={id} />
    </>
  );
};

export default BookingForm;