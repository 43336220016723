import styled from 'styled-components'
import theme from 'ui/theme';

export const Wrapper = styled.div`
  background-color: #FFFFFF00;
  padding:12px;
  width: 350px;
`
export const UnitContainer = styled.div`
  display: flex;
  width: 100%;
  height: 40px;
  padding-bottom: 8px;
`

export const UnitDetails = styled.div`
  display: flex;
  width: 80%;
  padding: 0px 20px;
  border: 1px solid ${theme().gray200Color};
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  margin-right: 4px;
  cursor: pointer; 
`
export const UnitName = styled.div`
  display:flex;
  align-Items:center
`

export const UnitCapacity = styled.div`
  display:flex;
`

export const UnitCircuit = styled.div`
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 20%;
  border: 1px solid ${theme().gray200Color};
  border-radius: 4px;
`