import * as Check from "validations";
import { FormField } from "types/misc";

export const fuellingFormValidations = {
    vehicle: [Check.isRequired],
    fuelStation: [Check.isRequired],
    fuelDate: [],
    litres: [Check.isRequired, Check.isNumber],
    fuelledBy: [],
    pricePerLitre: [Check.hasMoreThan3dp]
}

export type FuellingForm = {
    vehicle: FormField<string>,
    fuelStation: FormField<string>,
    paymentType: FormField<string>,
    fuelDate: FormField<string>,
    litres: FormField<string>,
    pricePerLitre: FormField<string>,
    description: FormField<string>,
    fuelledBy: FormField<string>,
}

export const fuellingFormDefault: FuellingForm =
{
    vehicle: {
        value: "",
        message: "",
        valid: true,
    },
    fuelStation: {
        value: "",
        message: "",
        valid: true,
    },
    paymentType: {
        value: "",
        message: "",
        valid: true,
    },
    fuelDate: {
        value: "",
        message: "",
        valid: true,
    },
    litres: {
        value: "",
        message: "",
        valid: true,
    },
    pricePerLitre: {
        value: "",
        message: "",
        valid: true,
    },
    description: {
        value: "",
        message: "",
        valid: true,
    },
    fuelledBy: {
        value: "",
        message: "",
        valid: true,
    }
}
