/**
 * Activitylisttablerow component
 *
 * @author Manuel Gil <mgi@ubiwhere.com>
 *
 *
 */

import React, { useEffect } from 'react'
import styled from 'styled-components'

import { ActivityVehicleForm } from 'types/trips/activity/activityForm'
import { TableRowPropTypes } from 'types/misc'
import theme from 'ui/theme'
import { CTableDataCell, CTableRow } from '@coreui/react-pro'

type PropTypes = TableRowPropTypes & {
  id?: string,
  vessel: ActivityVehicleForm
}

const Vesseltablerow = (props: PropTypes) => {

  useEffect(() => {

  }, [props])


  const {
    actions,
    vessel: {
      name,
      isAvailable,
      capacity,
    }
  } = props

  return (
    <CustomCTableRow>
      <CTableDataCell>{name}</CTableDataCell>
      <CTableDataCell>
        <AvailabilityTableDataCell isavailable={isAvailable}>{isAvailable ? "Disponível" : "Indisponível"}</AvailabilityTableDataCell>
      </CTableDataCell>
      <CTableDataCell>{capacity}</CTableDataCell>
      <ActionsCell>
        {actions}
      </ActionsCell>
    </CustomCTableRow>
  )
}

export default Vesseltablerow

const CustomCTableRow = styled(CTableRow)`
    max-height: 54px;
    background-color: ${theme().white};
    border-bottom: 1px solid ${theme().gray200Color};
  
    > td {
      text-transform: capitalize;
      vertical-align: middle;
      font-family: Inter;
      font-size: 14px;
      color: ${theme().gray700Color}
    }

    td:first-child {
      padding-left:15px;
      width:60%;
    }
  `

const AvailabilityTableDataCell = styled.p<{ isavailable: boolean }>`
     width:65%;
     margin-top:15px;
     background-color:${props => props.isavailable ? theme().success : theme().danger};
     color:white;
     font-weight:700;
     text-align:center;
     border-radius:5px;
 `

const ActionsCell = styled(CTableDataCell)`

  `
