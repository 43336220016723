/**
 * BookingForm scene sagas
 *
 * @author João Flores <jflores@ubiwhere.com>
 *
 *
 */
import { takeLatest, call, putResolve, select } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { actions, RootState } from 'store/rootSlices';
import API from 'api';
import { toast } from 'react-toastify';
import i18n from 'i18next';
import * as Check from 'validations';
import config from 'config';
import { DateTime } from 'luxon';
import { ItemsList, LanguageListItem } from 'types/misc';
import {
  BookingPaymentSaga,
  PaymentsSourceList,
  BookingFreePaymentSaga
} from 'types/trips/bookings';
import {
  BookingCustomerValidations,
  BookingInvoiceValidation,
  TripInfoValidations,
  PartnerValidation,
} from '../utils';
import { parsePaymentEntries, parsePendingEntries, validatePayment, checkIsBookingCanceled } from './utils'
import { validatePassengerTypes } from '../components/PassengerTypes/utils';
import { hasPermission } from 'permissions';
// Other Actions
import { searchCustomerSaga, fetchCustomersSaga } from '../components/CustomerForm/logic/sagas'
import { sendBookingTicketSaga } from '../components/Modals/logic/sagas'
import { searchPartnersSaga, fetchPartnersSaga } from '../components/Partnerform/logic/sagas'

const { PERMISSIONS } = config;

function* onMountSaga({ payload }: PayloadAction<number>) {
  const { permissions } = yield select((state: RootState) => state.App);

  try {
    yield fetchPartnersSaga();
    yield fetchCustomersSaga();
    if (payload) {
      yield putResolve(
        actions.BookingForm.setHasEditPermission(
          hasPermission(permissions, PERMISSIONS.TRIPS.BOOKINGS.EDIT_BOOKING)
        )
      );
      yield putResolve(actions.BookingForm.setEditMode(true));
      yield putResolve(actions.BookingForm.getBooking(payload));
      yield putResolve(actions.BookingForm.getCancelRescheduleSettings());
    } else {
      yield putResolve(actions.BookingForm.setEditMode(false));
      yield getPlanningLegsSaga();
    }

    yield getPaymentTypesSaga();
    yield getLangsSaga();
    yield getPaymentSourcesSaga();
    yield getCollaboratorsSaga();
  } catch (err) {
    toast.error(i18n.t('toasts.legsGetError'));
  } finally {
    yield putResolve(actions.BookingForm.setLoading(false));
  }
}

function* getCollaboratorsSaga() {
  const { teams } = yield select((state: RootState) => state.CollaboratorsManagement);

  try {
    const groupFilter = teams
      .filter((team) => team.uid === 'gestao' || team.uid === 'mt')
      .map((team) => team.id);

    const collaborators = yield call(API.Collaborators.GetAllCollaborators, undefined, groupFilter);
    yield putResolve(actions.BookingForm.setCollaborators(collaborators));
  } catch (err) { }
}

function* getPaymentSourcesSaga() {
  try {
    const paymentSources: PaymentsSourceList = yield call(API.Bookings.GetPaymentSources);
    yield putResolve(actions.BookingForm.setPaymentSources(paymentSources));
  } catch (err) { }
}

function* getPaymentTypesSaga() {
  try {
    let { paymentTypes }: { paymentTypes: ItemsList } = yield select(
      (state: RootState) => state.InvoicingSummary
    );

    if (paymentTypes.length === 0) {
      paymentTypes = yield call(API.Bookings.GetPaymentTypes);
    }

    yield putResolve(actions.BookingForm.setPaymentTypes(paymentTypes));
  } catch (err) { }
}

function* getLangsSaga() {
  try {
    const languages: LanguageListItem[] = yield call(API.TripSettings.GetLanguages);
    yield putResolve(actions.BookingForm.setLanguages(languages));
  } catch (err) { }
}

function* getBookingSaga({ payload }: PayloadAction<number>) {
  try {
    const booking = yield call(API.Bookings.GetBooking, payload);
    let customer;
    if (booking.customer) {
      yield putResolve(actions.BookingForm.setCurrentCustomer(booking.customer));
      customer = yield call(API.Customers.GetSingleCustomer, booking.customer.uuid);
      if (customer) {
        yield putResolve(actions.BookingForm.setBookingWithClient(true));
        yield putResolve(actions.BookingForm.setExistingCustomer(customer));
      }
    }

    //TODO: we must check here if is beach booking or trip booking
    //(we can do that by the outboundTrip.tripActivityLeg.availableExtras.length > 0)

    const entries = booking.entries;

    yield putResolve(actions.BookingForm.setBookingID(booking.id));
    yield putResolve(actions.BookingForm.setBookingUID(booking.uid));
    yield putResolve(actions.BookingForm.setBookingUUID(booking.uuid));
    yield putResolve(actions.BookingForm.setBookingState(booking.state));
    yield putResolve(actions.BookingForm.setTripDate(booking.tripDate));
    yield putResolve(actions.BookingForm.setInboundDate(booking.inboundTrip));

    // there is only one of these next two (extras or passenger type)
    yield putResolve(actions.BookingForm.populateSelectedPassengerTypes(booking));
    yield putResolve(actions.BookingForm.populateSelectedExtras(booking.extras));

    yield putResolve(actions.BookingForm.setInbound(booking.inboundTrip));
    yield putResolve(actions.BookingForm.setOutbound(booking.outboundTrip));
    yield putResolve(actions.BookingForm.setEntries({ entries, isCanceled: booking.isCanceled }));
    yield putResolve(actions.BookingForm.setPayments(booking.payments));
    yield putResolve(
      actions.BookingForm.setTripInfoForm({
        observations: booking.tripObservations,
        customerName: booking.customerName,
        email: booking.email,
        nif: booking.vat,
        phone: booking.phone,
        lang: booking.lang,
        country: booking.country ? booking.country : '',
      })
    );
    yield putResolve(
      actions.BookingForm.setShowPaymentForm(
        entries.some((e) => e.state === 'pending') &&
        entries
          .filter((e) => e.state === 'pending')
          .reduce((prev, curr) => {
            return +curr.amount + prev;
          }, 0) !== 0
      )
    );
    if (booking.createdBy) {
      yield putResolve(actions.BookingForm.setCreatedBy(booking.createdBy));
      yield putResolve(actions.BookingForm.setCreatedTime(booking.createdAt));
    }
    if (booking.canceledBy) {
      yield putResolve(actions.BookingForm.setCanceledBy(booking.canceledBy));
      yield putResolve(actions.BookingForm.setCanceledTime(booking.canceledAt));
    }
    if (booking.isCanceled) {
      yield putResolve(actions.BookingForm.setCanRescheduleLegs(false));
    }
    if (booking.partner) {
      yield putResolve(actions.BookingForm.setPartner(booking.partner));
      yield putResolve(actions.BookingForm.populatePartnerForm(booking.partner.uuid));
      yield putResolve(actions.BookingForm.setCustomerInfoWithPartner(true));
    }
    if (booking.partnerMember) {
      yield putResolve(actions.BookingForm.setPartnerMember(booking.partnerMember));
    }
    if (booking.entries?.some((e) => e.state === 'pending' && e.isRefund)) {
      yield putResolve(actions.BookingForm.setRefundPending(true));
    }

    if (booking.outboundTrip) {
      if (booking.outboundTrip.tripActivityLeg.availableExtras.length > 0) {
        yield putResolve(actions.BookingForm.setDirectionFilter('beach'));
      }
      else {
        yield putResolve(actions.BookingForm.setDirectionFilter('outbound'));
      }
    } else {
      yield putResolve(actions.BookingForm.setDirectionFilter('inbound'));
    }
  } catch (error) {
    console.log(error);
  } finally {
    yield putResolve(actions.Customerform.setLoading(false));
  }
}

function* getBeachPlanningsSaga() {
  try {
    yield putResolve(actions.BookingForm.setTableLoading(true));
    const {
      page,
      sort,
      dateFilter,
      activityFilter,
      availabilityFilter,
    } = yield select((state: RootState) => state.BookingForm);

    const parsedDate = DateTime.fromJSDate(dateFilter).toFormat('yyyy-LL-dd');

    const beachTrip = yield call(
      API.Plannings.GetBeachTrip,
      page,
      true,
      sort,
      6,
      parsedDate,
      parsedDate,
      activityFilter,
      availabilityFilter,
      true
    );

    if (beachTrip) {
      yield putResolve(actions.BookingForm.setBeachTrips(beachTrip.results));
      yield putResolve(actions.BookingForm.setTotalBeachTrips(beachTrip.count));
    }
  } catch (error) {
    toast.error(i18n.t('toasts.beachTripsGetError'));
  } finally {
    yield putResolve(actions.BookingForm.setTableLoading(false));
  }
}

function* getPlanningLegsSaga() {
  try {
    yield putResolve(actions.BookingForm.setTableLoading(true));
    const {
      page,
      sort,
      dateFilter,
      startTimeFilter,
      endTimeFilter,
      activityFilter,
      departureFilter,
      arrivalFilter,
      directionFilter,
      passengerTypesFilter,
      availabilityFilter,
      outboundCategoryFilter,
    } = yield select((state: RootState) => state.BookingForm);

    const parsedDate = DateTime.fromJSDate(dateFilter).toFormat('yyyy-LL-dd');

    const legs = yield call(
      API.Plannings.GetAllLegs,
      page,
      true, //showFinished
      sort,
      6,
      parsedDate,
      parsedDate,
      startTimeFilter,
      endTimeFilter,
      activityFilter,
      departureFilter,
      arrivalFilter,
      directionFilter,
      passengerTypesFilter,
      availabilityFilter,
      outboundCategoryFilter
    );

    if (legs) {
      yield putResolve(actions.BookingForm.setTripLegs(legs.results));
      yield putResolve(actions.BookingForm.setTotalLegs(legs.count));
    }
  } catch (error) {
    toast.error(i18n.t('toasts.legsGetError'));
  } finally {
    yield putResolve(actions.BookingForm.setTableLoading(false));
  }
}

function* getCancelRescheduleSettingsSaga() {
  try {
    const settings = yield call(API.Bookings.GetCancelRescheduleSettings);
    let tripsSettings = settings.find((s) => s.service === 'trips' && s.isActive);
    if (tripsSettings) {
      yield putResolve(actions.BookingForm.setTripsCancelRescheduleSettings(tripsSettings));
    }
  } catch (err) {
    console.log(err);
  }
}

function* onSubmitSaga() {
  const {
    edit,
    bookingID,
    customer,
    outbound,
    bookedOutbound,
    inbound,
    bookedInbound,
    bookedBeachTrip,
    selectedBeachTrip,
    selectedPassengerTypes,
    selectedBeachExtras,
    confirmedOverbooking,
    confirmedCancelation,
    paymentForm,
    invoicePaymentForm,
    entries,
    entriesTotal,
    enablePayment,
    showPaymentForm,
    isReschedule,
    enableInvoice,
    refundPending,
    outboundToCancel,
    inboundToCancel,
    beachToCancel,
    partnerForm,
    tripInfoForm,
    bookingWithClient,
    totalPassengers,
    bookedTotalPassengers,
    isFree
  } = yield select((state: RootState) => state.BookingForm);

  yield putResolve(actions.BookingForm.setSubmitButtonDisabled(true));

  interface BookingData {
    lang: string;
    customer?: string | null;
    tripObservations: string;
    customerName: string;
    email?: string;
    vat?: string;
    phone?: string;
    outboundTrip?: {
      id?: number;
      tripActivityLeg?: number;
      isCanceled?: boolean;
      wasRescheduled?: boolean;
    };
    inboundTrip?: {
      id?: number;
      tripActivityLeg?: number;
      isCanceled?: boolean;
      wasRescheduled?: boolean;
    };
    passengerTypes?: any[];
    extras?: any[]
    payments?: BookingPaymentSaga[] | BookingFreePaymentSaga[];
    entries?: any[];
    createTicket?: boolean;
    partner?: string;
    country?: string;
  }

  const validateTripInfo = Check.checkValidation(tripInfoForm, TripInfoValidations);
  if (validateTripInfo.invalid) {
    yield putResolve(actions.BookingForm.onTripInfoFormChange(validateTripInfo.form));
    yield putResolve(actions.BookingForm.setSubmitButtonDisabled(false));
    toast.error(i18n.t('toasts.bookingTripInfoError'));
    return false;
  }

  let bookingData: BookingData = {
    lang: tripInfoForm.lang.value,
    tripObservations: tripInfoForm.observations.value,
    email: tripInfoForm.email.value,
    country: tripInfoForm.country.value,
    phone: tripInfoForm.phone.value,
    vat: tripInfoForm.nif.value,
    customerName: tripInfoForm.customerName.value,
    customer: null
  };

  //new customer, create them first
  let customerUUID: string;
  if (bookingWithClient) {
    if (!customer.uuid) {
      const validateCustomer = Check.checkValidation(customer, BookingCustomerValidations);

      if (validateCustomer.invalid) {
        yield putResolve(actions.BookingForm.onCustomerFormChange(validateCustomer.form));
        yield putResolve(actions.BookingForm.setSubmitButtonDisabled(false));
        toast.error(i18n.t('toasts.bookingCreateCustomerError'));
        return false;
      }

      let customerData = {
        name: customer.name.value,
        observations: customer.observations.value,
        phone: customer.phone.value,
        email: customer.email.value,
        country: customer.country.value,
        nif: customer.nif.value,
        lang: customer.lang.value,
      };

      try {
        const newCustomer = yield call(API.Customers.CreateCustomer, customerData);

        customerUUID = newCustomer.data.uuid;
      } catch (err) {
        yield putResolve(actions.BookingForm.setSubmitButtonDisabled(false));
        return false;
      }
    } else {
      customerUUID = customer.uuid;
    }
    bookingData.customer = customerUUID;
  }

  if (edit) {
    //show confirm cancelation modal
    const showModal = checkIsBookingCanceled(
      outbound,
      inbound,
      selectedBeachTrip,
      outboundToCancel,
      inboundToCancel,
      beachToCancel,
      confirmedCancelation
    );

    if (showModal) {
      yield putResolve(actions.BookingForm.setSubmitButtonDisabled(false));
      yield putResolve(actions.BookingForm.setShowCancelationModal(true));
      return false;
    }

    //partner
    let partnerValidation = Check.checkValidation(partnerForm, PartnerValidation);
    if (partnerValidation.invalid) {
      yield putResolve(actions.BookingForm.onPartnerFormChange(partnerValidation.form));
      yield putResolve(actions.BookingForm.setSubmitButtonDisabled(false));
      toast.error(i18n.t('toasts.bookingPartnerEditError'));
      return false;
    }
    bookingData.partner = partnerForm.partner.value;

    if (isReschedule) {
      // reschduling beach trip
      // when rescheduling a beach trip the oubound can never be canceled
      // and a new outbound leg can never be created(we use the same leg but with the flag 'wasReschedule') 
      if (
        selectedBeachTrip.beachTripActivity.id > 0 &&
        selectedBeachTrip.beachTripActivity.id !== bookedBeachTrip.beachTripActivity.id &&
        !bookedBeachTrip.wasCanceled
      ) {

        bookingData.outboundTrip = {
          id: bookedBeachTrip.id,
          wasRescheduled: true,
          tripActivityLeg: selectedBeachTrip.beachTripActivity.id,
        };
      }
      //changed from HOHO to direct
      else if (!outbound.tripActivityLeg.activity.isHopOnHopOff && bookedOutbound.tripActivityLeg.activity.isHopOnHopOff) {

        bookingData.outboundTrip = {
          id: bookedOutbound.id,
          wasRescheduled: true,
          tripActivityLeg: outbound.tripActivityLeg.id,
        };

        //inbound was selected as well
        if (inbound.tripActivityLeg.id > 0) {
          bookingData.inboundTrip = {
            tripActivityLeg: inbound.tripActivityLeg.id,
          };
        }
      }
      //changed from direct to HOHO
      else if (outbound.tripActivityLeg.activity.isHopOnHopOff && !bookedOutbound.tripActivityLeg.activity.isHopOnHopOff) {
        if (bookedOutbound.tripActivityLeg.id < 0) {
          bookingData.outboundTrip = {
            tripActivityLeg: outbound.tripActivityLeg.id,
          };
        } else {
          bookingData.outboundTrip = {
            id: bookedOutbound.id,
            wasRescheduled: true,
            tripActivityLeg: outbound.tripActivityLeg.id,
          };
        }

        if (bookedInbound.id > 0) {
          bookingData.inboundTrip = {
            id: bookedInbound.id,
            tripActivityLeg: bookedInbound.tripActivityLeg.id,
            isCanceled: true,
          };
        }

      } else {
        if (
          outbound.tripActivityLeg.id > 0 &&
          outbound.tripActivityLeg.id !== bookedOutbound.tripActivityLeg.id &&
          !bookedOutbound.wasCanceled
        ) {
          bookingData.outboundTrip = {
            id: bookedOutbound.id,
            wasRescheduled: true,
            tripActivityLeg: outbound.tripActivityLeg.id,
          };
        } else if (
          outbound.tripActivityLeg.id > 0 &&
          outbound.tripActivityLeg.id === bookedOutbound.tripActivityLeg.id &&
          outboundToCancel
        ) {
          bookingData.outboundTrip = {
            id: outbound.id,
            isCanceled: true,
          };
        } else if (
          bookedOutbound.id < 0 &&
          outbound.tripActivityLeg.id > 0 &&
          outbound.tripActivityLeg.id !== bookedOutbound.tripActivityLeg.id
        ) {
          // Created new outbound leg
          bookingData.outboundTrip = {
            tripActivityLeg: outbound.tripActivityLeg.id,
          };
        }

        if (
          bookedInbound.id > 0 &&
          inbound.tripActivityLeg.id > 0 &&
          inbound.tripActivityLeg.id !== bookedInbound.tripActivityLeg.id &&
          !bookedInbound.wasCanceled
        ) {
          bookingData.inboundTrip = {
            id: bookedInbound.id,
            wasRescheduled: true,
            tripActivityLeg: inbound.tripActivityLeg.id,
          };
        } else if (
          bookedInbound.id > 0 &&
          inbound.tripActivityLeg.id > 0 &&
          inbound.tripActivityLeg.id === bookedInbound.tripActivityLeg.id &&
          inboundToCancel
        ) {
          bookingData.inboundTrip = {
            id: inbound.id,
            isCanceled: true,
          };
        } else if (
          bookedInbound.id < 0 &&
          inbound.tripActivityLeg.id > 0 &&
          inbound.tripActivityLeg.id !== bookedInbound.tripActivityLeg.id
        ) {
          // Created new inbound leg
          bookingData.inboundTrip = {
            tripActivityLeg: inbound.tripActivityLeg.id,
          };
        }
      }
    } else {
      //check for canceled legs
      if (outbound.id > 0 && outbound.isCanceled && !refundPending) {
        bookingData.outboundTrip = {
          id: outbound.id,
          isCanceled: true,
        };
      }

      if (inbound.id > 0 && inbound.isCanceled && !refundPending) {
        bookingData.inboundTrip = {
          id: inbound.id,
          isCanceled: true,
        };
      }

      if (selectedBeachTrip.id > 0 && selectedBeachTrip.isCanceled && !refundPending) {
        bookingData.outboundTrip = {
          id: selectedBeachTrip.id,
          isCanceled: true,
        };
      }
    }

    let allowsOverbooking = false
    let isOverbooked = false;

    if (outbound.tripActivityLeg.id > 0 && inbound.tripActivityLeg.id > 0) {
      allowsOverbooking =
        outbound.tripActivityLeg.allowOverbooking && inbound.tripActivityLeg.allowOverbooking;
      isOverbooked =
        totalPassengers >
        Math.min(
          inbound.tripActivityLeg.availableTickets + bookedTotalPassengers,
          outbound.tripActivityLeg.availableTickets + bookedTotalPassengers
        );
    } else if (outbound.tripActivityLeg.id > 0) {
      allowsOverbooking = outbound.tripActivityLeg.allowOverbooking;
      isOverbooked = totalPassengers > outbound.tripActivityLeg.availableTickets + bookedTotalPassengers;
    } else if (inbound.tripActivityLeg.id > 0) {
      allowsOverbooking = inbound.tripActivityLeg.allowOverbooking;
      isOverbooked = totalPassengers > inbound.tripActivityLeg.availableTickets + bookedTotalPassengers;
    }

    //Passenger Types
    if (selectedBeachTrip.beachTripActivity.id === -1) {
      let validatedPTypes = validatePassengerTypes(
        selectedPassengerTypes,
        totalPassengers,
        allowsOverbooking,
        bookedTotalPassengers
      );
      if (validatedPTypes.invalid) {
        yield putResolve(actions.BookingForm.setSelectedPassengerTypes(validatedPTypes.form));
        toast.error(i18n.t('toasts.bookingPassengerTypesError'));
        yield putResolve(actions.BookingForm.setSubmitButtonDisabled(false));
        return false;
      }

      let passengerTypes = selectedPassengerTypes
        .filter((p) => !p.isDisabled)
        .map((p) => {
          return { id: p.id, quantity: p.quantity.value };
        });

      bookingData.passengerTypes = passengerTypes;
    }

    //Extras
    //we only need to add extras when the booking is of type beach
    if (selectedBeachTrip.beachTripActivity.id > 0) {
      let extras = selectedBeachExtras
        .filter((p) => !p.isDisabled)
        .map((p) => {
          return { extra: p.id, quantity: Number(p.quantity.value) };
        });

      bookingData.extras = extras

    }

    if (isOverbooked && !confirmedOverbooking) {
      //show confirm overbooking modal
      yield putResolve(actions.BookingForm.setShowOverbookingModal(true));
      yield putResolve(actions.BookingForm.setSubmitButtonDisabled(false));
      return false;
    }

    //payments
    if (enablePayment && showPaymentForm && !isFree) {
      const parsedEntries = parsePaymentEntries(entries, selectedBeachTrip.beachTripActivity.id > 0);

      let validatedPayment = validatePayment(entriesTotal, paymentForm);
      if (validatedPayment.invalid) {
        yield putResolve(actions.BookingForm.setPaymentForm(validatedPayment.form));
        yield putResolve(actions.BookingForm.setSubmitButtonDisabled(false));
        toast.error(i18n.t('toasts.bookingPaymentError'));
        return false;
      }

      let paymentData: BookingPaymentSaga = {
        amount: paymentForm.amount.value,
        processedBy: paymentForm.processedBy.value,
        source: paymentForm.source.value,
        type: paymentForm.type.value,
        correction: paymentForm.correction.value || 0,
        isRefund: paymentForm.isRefund,
        entries: parsedEntries,
        customerName: customer.name.value,
        vat: invoicePaymentForm.nif.value,
        ...(enableInvoice
          ? {
            email: invoicePaymentForm.email.value,
            phone: invoicePaymentForm.phone.value,
            country: invoicePaymentForm.country.value,
          }
          : null),
      };

      if (enableInvoice && enablePayment) {
        const validatedInvoice = Check.checkValidation(
          invoicePaymentForm,
          BookingInvoiceValidation
        );
        if (validatedInvoice.invalid) {
          yield putResolve(actions.BookingForm.setInvoiceForm(validatedInvoice.form));
          yield putResolve(actions.BookingForm.setSubmitButtonDisabled(false));
          toast.error(i18n.t('toasts.bookingInvoiceError'));
          return false;
        }
      }

      bookingData.payments = [paymentData];
    } else if (isFree) {
      const parsedEntries = parsePaymentEntries(entries, selectedBeachTrip.beachTripActivity.id > 0);
      let paymentData: BookingFreePaymentSaga = {
        amount: 0,
        correction: paymentForm.correction.value || 0,
        isRefund: paymentForm.isRefund,
        entries: parsedEntries,
        customerName: customer.name.value,
        vat: invoicePaymentForm.nif.value,
        ...(enableInvoice
          ? {
            email: invoicePaymentForm.email.value,
            phone: invoicePaymentForm.phone.value,
            country: invoicePaymentForm.country.value,
          }
          : null),
      };

      bookingData.payments = [paymentData]
    } else {
      const parsedEntries = parsePendingEntries(entries, selectedBeachTrip.beachTripActivity.id > 0);
      bookingData.entries = parsedEntries;
    }

    try {
      const booking = yield call(API.Bookings.EditBooking, bookingData, bookingID);

      if (booking) {
        toast.success(i18n.t('toasts.bookingEditSuccess'));
        yield putResolve(actions.App.navigateTo('/viagens/reservas'));
      }
    } catch (err) {
      toast.error(i18n.t('toasts.bookingEditError'));
    }
  } else {
    bookingData.createTicket = true

    //partner
    if (partnerForm.partner.value) {
      let partnerValidation = Check.checkValidation(partnerForm, PartnerValidation);
      if (partnerValidation.invalid) {
        yield putResolve(actions.BookingForm.onPartnerFormChange(partnerValidation.form));
        yield putResolve(actions.BookingForm.setSubmitButtonDisabled(false));
        toast.error(i18n.t('toasts.bookingPartnerAddingError'));
        return false;
      }
      bookingData.partner = partnerForm.partner.value;
    }

    let allowsOverbooking = false,
      isOverbooked = false;

    //trips 
    if (selectedBeachTrip.beachTripActivity.id > 0) {
      bookingData.outboundTrip = selectedBeachTrip.beachTripActivity.id
    } else if (outbound.tripActivityLeg.id > 0 && inbound.tripActivityLeg.id > 0) {
      bookingData.outboundTrip = outbound.tripActivityLeg.id;
      bookingData.inboundTrip = inbound.tripActivityLeg.id;
      allowsOverbooking =
        outbound.tripActivityLeg.allowOverbooking && inbound.tripActivityLeg.allowOverbooking;
      isOverbooked =
        totalPassengers >
        Math.min(
          inbound.tripActivityLeg.availableTickets,
          outbound.tripActivityLeg.availableTickets
        );
    } else if (outbound.tripActivityLeg.id > 0) {
      bookingData.outboundTrip = outbound.tripActivityLeg.id;
      allowsOverbooking = outbound.tripActivityLeg.allowOverbooking;
      isOverbooked = totalPassengers > outbound.tripActivityLeg.availableTickets;
    } else if (inbound.tripActivityLeg.id > 0) {
      bookingData.inboundTrip = inbound.tripActivityLeg.id;
      allowsOverbooking = inbound.tripActivityLeg.allowOverbooking;
      isOverbooked = totalPassengers > inbound.tripActivityLeg.availableTickets;
    }

    //Passenger Types
    //we only need to add passenger types when the booking is not of type beach
    if (selectedBeachTrip.beachTripActivity.id === -1) {
      let validatedPTypes = validatePassengerTypes(
        selectedPassengerTypes,
        totalPassengers,
        allowsOverbooking,
        0
      );
      if (validatedPTypes.invalid) {
        yield putResolve(actions.BookingForm.setSelectedPassengerTypes(validatedPTypes.form));
        toast.error(i18n.t('toasts.bookingPassengerTypesError'));
        yield putResolve(actions.BookingForm.setSubmitButtonDisabled(false));
        return false;
      }


      let passengerTypes = selectedPassengerTypes
        .filter((p) => !p.isDisabled)
        .map((p) => {
          return { id: p.id, quantity: p.quantity.value };
        });

      bookingData.passengerTypes = passengerTypes;
    }

    //Extras
    //we only need to add extras when the booking is of type beach
    if (selectedBeachTrip.beachTripActivity.id > 0) {
      let extras = selectedBeachExtras
        .filter((p) => !p.isDisabled)
        .map((p) => {
          return { extra: p.id, quantity: Number(p.quantity.value) };
        });

      bookingData.extras = extras

    }

    if (isOverbooked && !confirmedOverbooking) {
      //show confirm overbooking modal
      yield putResolve(actions.BookingForm.setShowOverbookingModal(true));
      yield putResolve(actions.BookingForm.setSubmitButtonDisabled(false));
      return false;
    }

    //payments && invoices
    if (enablePayment && showPaymentForm && !isFree) {
      const parsedEntries = parsePaymentEntries(entries, selectedBeachTrip.beachTripActivity.id > 0);

      let validatedPayment = validatePayment(entriesTotal, paymentForm);
      if (validatedPayment.invalid) {
        yield putResolve(actions.BookingForm.setPaymentForm(validatedPayment.form));
        yield putResolve(actions.BookingForm.setSubmitButtonDisabled(false));
        toast.error(i18n.t('toasts.bookingPaymentError'));
        return false;
      }

      if (enableInvoice && enablePayment) {
        const validatedInvoice = Check.checkValidation(
          invoicePaymentForm,
          BookingInvoiceValidation
        );
        if (validatedInvoice.invalid) {
          yield putResolve(actions.BookingForm.setInvoiceForm(validatedInvoice.form));
          yield putResolve(actions.BookingForm.setSubmitButtonDisabled(false));
          toast.error(i18n.t('toasts.bookingInvoiceError'));
          return false;
        }
      }

      let paymentData: BookingPaymentSaga = {
        amount: paymentForm.amount.value,
        processedBy: paymentForm.processedBy.value,
        source: paymentForm.source.value,
        type: paymentForm.type.value,
        correction: paymentForm.correction.value || 0,
        isRefund: paymentForm.isRefund,
        entries: parsedEntries,
        ...(enablePayment
          ? {
            customerName: tripInfoForm.customerName.value,
            vat: invoicePaymentForm.nif.value,
          }
          : null),
        ...(enableInvoice
          ? {
            email: invoicePaymentForm.email.value,
            phone: invoicePaymentForm.phone.value,
            country: invoicePaymentForm.country.value,
          }
          : null),
      };

      bookingData.payments = [paymentData];
    } else if (isFree) {
      const parsedEntries = parsePaymentEntries(entries, selectedBeachTrip.beachTripActivity.id > 0);

      let paymentData: BookingFreePaymentSaga = {
        amount: 0,
        correction: paymentForm.correction.value || 0,
        isRefund: paymentForm.isRefund,
        entries: parsedEntries,
        customerName: customer.name.value,
        vat: invoicePaymentForm.nif.value,
        ...(enableInvoice
          ? {
            email: invoicePaymentForm.email.value,
            phone: invoicePaymentForm.phone.value,
            country: invoicePaymentForm.country.value,
          }
          : null),
      };

      bookingData.payments = [paymentData]
    } else {
      const parsedEntries = parsePendingEntries(entries, selectedBeachTrip.beachTripActivity.id > 0);
      bookingData.entries = parsedEntries;
    }

    try {
      const booking = yield call(API.Bookings.CreateBooking, bookingData);

      if (booking) {
        toast.success(i18n.t('toasts.bookingCreateSuccess'));
        yield putResolve(actions.App.navigateTo('/viagens/reservas'));
      }
    } catch (err) {
      const error: any = err

      if (error.response.status === 400 && error.response.data.errors.fields.outbound_trip === "no_tickets_available") {
        toast.error(i18n.t(`toasts.noTicketsAvailable`))
        // Here we fetch the legs again to update the extras counters
        yield putResolve(actions.BookingForm.setBeachView({}));
      } else {
        toast.error(i18n.t('toasts.bookingCreateError'));
      }

      yield putResolve(actions.BookingForm.setConfirmedOverbooking(false));
      yield putResolve(actions.BookingForm.setSubmitButtonDisabled(false));
    }
  }
  yield putResolve(actions.BookingForm.setSubmitButtonDisabled(false));
}

function* cancelBookingSaga() {
  const { bookingUUID } = yield select((state: RootState) => state.BookingForm);
  try {
    const success = yield call(API.Bookings.CancelBooking, bookingUUID);
    if (success) {
      yield putResolve(actions.App.navigateTo('/viagens/reservas'));
      toast.success(i18n.t('toasts.bookingCanceledSuccess'));
    }
  } catch (error) {
    toast.error(i18n.t('toasts.bookingCanceledError'));
  }
}

export default function* watcherSignin() {
  yield takeLatest('BookingForm/onMount', onMountSaga);
  yield takeLatest('BookingForm/setPage', getPlanningLegsSaga);
  yield takeLatest('BookingForm/setSort', getPlanningLegsSaga);
  yield takeLatest('BookingForm/setActivityFilter', getPlanningLegsSaga);
  yield takeLatest('BookingForm/setBeachDateFilter', getBeachPlanningsSaga);
  yield takeLatest('BookingForm/setDateFilter', getPlanningLegsSaga);
  yield takeLatest('BookingForm/setTimeFilter', getPlanningLegsSaga);
  yield takeLatest('BookingForm/setDepartureFilter', getPlanningLegsSaga);
  yield takeLatest('BookingForm/setArrivalFilter', getPlanningLegsSaga);
  yield takeLatest('BookingForm/setLegInboundView', getPlanningLegsSaga);
  yield takeLatest('BookingForm/setBeachView', getBeachPlanningsSaga);
  yield takeLatest('BookingForm/setIsBeachReschedule', getBeachPlanningsSaga);
  yield takeLatest('BookingForm/setIsReschedule', getPlanningLegsSaga);
  yield takeLatest('BookingForm/setSearchCustomerText', searchCustomerSaga);
  yield takeLatest('BookingForm/sendBookingTicket', sendBookingTicketSaga);
  yield takeLatest('BookingForm/getBooking', getBookingSaga);
  yield takeLatest('BookingForm/getCancelRescheduleSettings', getCancelRescheduleSettingsSaga);
  yield takeLatest('BookingForm/onSubmit', onSubmitSaga);
  yield takeLatest('BookingForm/setSearchPartnerText', searchPartnersSaga);
  yield takeLatest('BookingForm/fetchPartners', fetchPartnersSaga);
  yield takeLatest('BookingForm/fetchCustomers', fetchCustomersSaga);
  yield takeLatest('BookingForm/cancelBooking', cancelBookingSaga);
}
