import { PayloadAction } from '@reduxjs/toolkit'
import { takeLatest, putResolve, call, select } from 'redux-saga/effects'
import API from 'api'
import { actions, RootState } from 'store/rootSlices'
import { toast } from 'react-toastify';
import * as Check from 'validations';
import { ExtraFormValidationsEdit, ExtraFormValidations, SubmitSagaPayload, IconSagasPayload, PhotosSagasPayload, DeletePhotosSagasPayload } from '../utils'
import i18n from 'i18next';
import config from 'config';
import { hasPermission } from 'permissions'
const { PERMISSIONS } = config

//state
const extID = (state: RootState) => state.TripExtraForm.extraID

function* onMountSaga({ payload }: PayloadAction<number>) {
  const { permissions } = yield select((state: RootState) => state.App);

  if (payload) {

    const extraID = payload

    try {
      //get extra from api
      const extraInfo = yield call(API.Extras.GetExtra, extraID)

      if (extraInfo) {
        yield putResolve(actions.TripExtraForm.setExtraID(extraID))
        yield putResolve(actions.TripExtraForm.setAction(true))
        yield putResolve(actions.Documentconfigform.setHasEditPermission(hasPermission(permissions, PERMISSIONS.TRIPS.EXTRAS.EDIT_EXTRA)))
        yield putResolve(actions.TripExtraForm.populateForm(extraInfo))
        yield putResolve(actions.TripExtraForm.populatePhotosForm({ photos: extraInfo.photos, extraID }))
      }
      else {
        toast.error(i18n.t("toasts.getTripExtraError"));
      }
    } catch (error) {
    }

  } else {
    // creating a new extra
    yield putResolve(actions.TripExtraForm.setAction(false))
  }
}

function* onSubmitSaga({ payload }: PayloadAction<SubmitSagaPayload>) {
  yield putResolve(actions.TripExtraForm.setSubmitButtonDisabled(true))
  const validation = payload.edit === true ?
    Check.checkValidationAlternative(payload.extraForm, ExtraFormValidationsEdit)
    :
    Check.checkValidationAlternative(payload.extraForm, ExtraFormValidations)
  
  if (validation.invalid) {
    const updateValidationMessages = Check.setAndCheckValidationAlternative(payload.extraForm, payload.extraForm, payload.edit === true ? ExtraFormValidationsEdit : ExtraFormValidations)
    yield putResolve(actions.TripExtraForm.onFormChange(updateValidationMessages))
    yield putResolve(actions.TripExtraForm.setSubmitButtonDisabled(false))
    toast.error(i18n.t("toasts.invalidFields"));
  }
  else {
    const isPack = payload.extraForm.isPack.value
    const pack = payload.packForm

    const finalForm = {
      ...payload.extraForm,
      price: { value: Number.parseFloat(payload.extraForm.price.value).toFixed(2), valid: true, message: "" },
      maxCapacity: payload.extraForm.maxCapacity,
      ...(isPack ? {
        packContent: pack.map(ext => ({
          extra: ext.id,
          quantity: Number(ext.quantity.value)
        }))
      } : null)
    };

    if (!payload.edit) {
      try {
        const extra = yield call(API.Extras.CreateExtra, finalForm)

        if (extra) {
          yield putResolve(actions.TripExtraForm.createIcon({ icon: payload.extraForm.icon.value, extra: extra.id }))
          yield putResolve(actions.TripExtraForm.createPhoto({ photos: payload.photos, extra: extra.id }))
          yield putResolve(actions.App.navigateTo('/viagens/configuracoes'))
          yield putResolve(actions.Tripsconfiguration.setMakeApiCals(true))
          toast.success(i18n.t("toasts.createExtraSuccess"));
        }

      } catch (error) {
        toast.error(i18n.t("toasts.createExtraError"));
      }
    }
    else {
      const extraID = yield select(extID)

      try {
        const success = yield call(API.Extras.EditExtra, extraID, finalForm)

        if (success) {
          if (payload.extraForm.icon.value instanceof File) {
          }
          yield putResolve(actions.TripExtraForm.createIcon({ icon: payload.extraForm.icon.value, extra: extraID }))
          yield putResolve(actions.TripExtraForm.editPhotoArray({ photos: payload.photos, extra: extraID }))
          yield putResolve(actions.App.navigateTo('/viagens/configuracoes'))
          toast.success(i18n.t("toasts.editExtraSuccess"))
        }
      } catch (error) {
        toast.warning(i18n.t("toasts.editExtraError"))
      }
    }
  }

  yield putResolve(actions.Tripsconfiguration.getAllExtras())
  yield putResolve(actions.TripExtraForm.setSubmitButtonDisabled(false))
}

function* onCreateIconSaga({ payload }: PayloadAction<IconSagasPayload>) {
  try {
    if (payload.icon instanceof File || payload.icon === null) {
      yield call(API.Extras.UploadExtraIcon, payload.icon, payload.extra)
    }
  } catch (error) {
    toast.warning(i18n.t("toasts.extrasIconError"));
  }
}


function onCreatePhotoSaga({ payload }: PayloadAction<PhotosSagasPayload>) {
  try {

    payload.photos.forEach(async (photo, idx) => {
      const body = {
        extra: payload.extra,
        sort: idx + 1,
        file: photo.file
      }

      await API.Extras.CreateExtraPhoto(body)
    })

  } catch (error) {
    toast.warning("Erro a adicionar foto do extra");
  }
}

function onEditPhotoArraySaga({ payload }: PayloadAction<PhotosSagasPayload>) {
  try {

    payload.photos.forEach(async (photo, idx) => {
      if (photo.new === true) {
        const body = {
          extra: payload.extra,
          sort: idx + 1,
          file: photo.file
        }
        await API.Extras.CreateExtraPhoto(body)
      }
      else {
        if (photo.edited === true) {
          const body = {
            extra: payload.extra,
            sort: idx + 1,
          }
          await API.Extras.EditExtraPhoto(photo.id, body)
        }
      }
    })

  } catch (error) {
    toast.warning("Erro editar fotos do local");
  }
}

function* deletePhotoSaga({ payload }: PayloadAction<DeletePhotosSagasPayload>) {
  const index = payload.index
  const photosArray = payload.photos

  if (index !== null && photosArray[index].new === false) {
    try {
      const del = yield call(API.Locals.DeleteStopPhoto, photosArray[index].id)

      if (del.status === 204) {
        toast.success(i18n.t("toasts.deletedPhoto"))
      }

    } catch (error) {
      toast.error(i18n.t("toasts.removePhotoError"))
    }
  }
}

function* onUnmountSaga() { }


export default function* watcherSignin() {
  yield takeLatest('tripextraform/onMount', onMountSaga)
  yield takeLatest('tripextraform/onUnmount', onUnmountSaga)
  yield takeLatest('tripextraform/onSubmit', onSubmitSaga)
  yield takeLatest('tripextraform/createPhoto', onCreatePhotoSaga)
  yield takeLatest('tripextraform/createIcon', onCreateIconSaga)
  yield takeLatest('tripextraform/editPhotoArray', onEditPhotoArraySaga)
  yield takeLatest('tripextraform/deletePhotoFromArray', deletePhotoSaga)
}
