import styled from 'styled-components'
import DropdownArrow from 'assets/icons/dropdown_arrow'
import theme from 'ui/theme'
import "react-input-range/lib/css/index.css";

export const Wrapper = styled.div`
  max-width: 180px;
  width: fit-content;
  max-height: 23px;
  position: relative;
`

export const ArrowContainer = styled.div`
  margin: 8px 0 8px 8px;
  display: flex;
  align-items: center;
`

export const CustomDropdownArrow = styled(DropdownArrow) <{ isdefaultselected: boolean, open: boolean }>`
  margin-top: 2px;
`
export const CurrentValueContainer = styled.div``

export const RangeContainer = styled.div<{ open: boolean }>`
  transition: all 0.2s;
  background-color: ${theme().white};
  position: absolute;
  z-index: 10;
  opacity: ${props => props.open ? 1 : 0};
  display: ${props => props.open ? "block" : "none"};
  padding: 30px;

  .input-range {
      width: 200px;
      transition: opacity 0.2s;
      opacity: ${props => props.open ? 1 : 0};
      display: ${props => props.open ? "block" : "none"};
  }
`

export const SelectContainer = styled.div<{ isdefaultselected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 !important;
  border: 0;
  font-family: 'Inter';
  font-size: 14px;
  cursor: pointer;

  > svg:first-child {
      fill: ${props => props.isdefaultselected ? theme().gray500Color : theme().primary} !important;
  }

  > :nth-child(2) {
      margin-left: 8px;
      color: ${props => props.isdefaultselected ? theme().gray500Color : theme().primary};
      border: 0;
      background: transparent;
  }
`
