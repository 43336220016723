import * as React from "react"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width={18}
            height={18}
            fill={props.fill ? props.fill : "#EBEDEF"}
            color={props.color ? props.color : "#EBEDEF"}
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M15 5.25h-3v-1.5l-1.5-1.5h-3L6 3.75v1.5H3c-.825 0-1.5.675-1.5 1.5v3.75c0 .563.3 1.035.75 1.297v2.453c0 .832.667 1.5 1.5 1.5h10.5c.832 0 1.5-.668 1.5-1.5v-2.46a1.51 1.51 0 00.75-1.29V6.75c0-.825-.675-1.5-1.5-1.5zm-7.5-1.5h3v1.5h-3v-1.5zm-4.5 3h12v3.75h-3.75V8.25h-4.5v2.25H3V6.75zm6.75 4.5h-1.5v-1.5h1.5v1.5zm4.5 3H3.75V12h3v.75h4.5V12h3v2.25z"
                fill={props.fill ? props.fill : "#EBEDEF"}
            />
        </svg>
    )
}

export default SvgComponent
