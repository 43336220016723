import * as React from "react"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width={24}
            height={24}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M9 16.59L10.41 18l6-6-6-6L9 7.41 13.58 12 9 16.59z"
                fill={props.fill ? props.fill : "#9DA5B1"}
            />
        </svg>
    )
}

export default SvgComponent
