import axios from 'axios'
import endpoints from 'api/endpoints'
import { generateUrl } from 'utils'
import { decamelizeKeys } from 'humps'


const Login = (params) => { 
    return axios.post(endpoints.AUTH.LOGIN, params)
    .then(res => res.data)
    .catch(err => { throw err })
}

const AcceptTerms = (uuid,params) => { //to reset password on modal
    //const token = window.localStorage.getItem("access_token")
    const endpoint = generateUrl(endpoints.AUTH.ACCEPT_TERMS,{uuid})

    const formData = new FormData();
    formData.append('password' ,params.password)

    return axios.patch(endpoint, formData)
        .then(res => res)
        .catch(err => { throw err })
}

const GetUser = (data) => {
    const userTypeQS = `${data.userType ? `user_type=${data.userType}` : ''}`;

    const endpoint = `${generateUrl(endpoints.AUTH.GET_USER, { uuid: data.uuid })}?${userTypeQS}`
    return axios.get(endpoint)
    .then(res => res.data)
    .catch(err => { throw err })
}

const AcceptTermsCorrect = (uuid) => {
    const endpoint = generateUrl(endpoints.AUTH.ACCEPT_TERMS_CORRECT, { uuid })
    return axios.post(endpoint)
    .then(res => res)
    .catch(err => {throw err})
}


const SendEmail = (data) => {
    return axios.create({}).post(endpoints.AUTH.SEND_EMAIL, { email: data.email, user_type: data.userType})
    .then(res => res.data)
    .catch(err => { throw err })
}

const resetPassword = (token, params) => {
    const endpoint = generateUrl(endpoints.AUTH.RESET_PASSWORD, { token })
    const formData = new FormData();
    formData.append('password',params.password)
    //formData.append('token', params.token)
    return axios.create({}).put(endpoint, formData)
        .then(res => res)
    .catch(err => { throw err })
}

const RefreshToken = (params) => {
    return axios.create({}).post(endpoints.AUTH.REFRESH_TOKEN, params)
        .then(res => res.data)
        .catch(err => { throw err })
}

const CreateUser = (params) => {

    const decMLZParams = decamelizeKeys(params)
    return axios.post(endpoints.AUTH.CREATE_USER, decMLZParams)
        .then(res => {
            return res.data
        })
        .catch(err => {
            throw err 
        })
}

const editUser = (id,params) => {
    const endpoint = generateUrl(endpoints.AUTH.EDIT_USER, { id })
    const decMLZParams = decamelizeKeys(params)

    return axios.patch(endpoint, decMLZParams)
        .then(res => res.data)
        .catch(err => { throw err })
}


export default {
    Login,
    AcceptTerms,
    AcceptTermsCorrect,
    GetUser,
    SendEmail,
    resetPassword,
    RefreshToken,
    CreateUser,
    editUser
}