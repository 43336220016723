import React from 'react'
import i18n from 'i18next'
import { useDispatch, useSelector } from 'react-redux'
import { actions, RootState } from 'store/rootSlices'
import { CRow } from '@coreui/react-pro'
import SectionContainer from 'shared/components/Sectioncontainer'
import CustomCheckbox from 'shared/components/CustomCheckbox'

const RulesRows = () => {

  const { rules, hasEditPermission } = useSelector((state: RootState) => state.TripPlanningsForm)
  const { onRulesChange } = actions.TripPlanningsForm

  const dispatch = useDispatch()
 

  return (
    <SectionContainer title={i18n.t('tripPlanningForm.rulesTitle')} style={{ width: '100%', paddingBottom: '24px', display: 'flex' }}>
      <CRow>
        <CustomCheckbox
          id={'tripPlanningForm-availableForAgents'}
          disabled={!hasEditPermission}
          wrapperStyle={{ width: '270px' }}
          checked={rules.availableForAgents}
          onClick={e => dispatch(onRulesChange({ ...rules, availableForAgents: !rules.availableForAgents }))}
          text={i18n.t('tripPlanningForm.availableForAgents')}
        />
        <CustomCheckbox
          id={'tripPlanningForm-availableForPublic'}
          disabled={!hasEditPermission}
          wrapperStyle={{ width: '270px' }}
          checked={rules.availableForPublic}
          onClick={e => dispatch(onRulesChange({ ...rules, availableForPublic: !rules.availableForPublic }))}
          text={i18n.t('tripPlanningForm.availableForPublic')}
        />
        <CustomCheckbox
          id={'tripPlanningForm-availableForCollabs'}
          disabled={!hasEditPermission}
          wrapperStyle={{ width: '290px' }}
          checked={rules.availableForTeamMembers}
          onClick={e => dispatch(onRulesChange({ ...rules, availableForTeamMembers: !rules.availableForTeamMembers }))}
          text={i18n.t('tripPlanningForm.availableForCollabs')}
        />
      </CRow>
    </SectionContainer>
  )
}

export default RulesRows