import React  from 'react'
import { Duration, DateTime } from "luxon"
import { useSelector } from 'react-redux';
import i18n from 'i18next';
// Styles
import theme from 'ui/theme';
import * as Styles from './styles'
import { Text, Icon } from '../../styles';
// Icons
import StartTrip from 'assets/icons/start_trip'
import EndTrip from 'assets/icons/end_trip'
import Boat from 'assets/icons/boat_icon'
import CheckCircle from 'assets/icons/check_circle'
// Components
import PadCircuitStopInfo from '../PadCircuitStopInfo';
// Store
import { RootState } from 'store/rootSlices';
// Types
import type { InOutBoundStops, Vehicles } from 'types/trips/plannings';

type PropTypes = {
  showDetails: boolean;
  vehicles: Vehicles[];
  planningId: number;
  isOutbound: boolean;

  direction: InOutBoundStops[];
  stopsLength: number;
}

const PadStops = ({ planningId, vehicles, showDetails, isOutbound, direction, stopsLength}: PropTypes) => {
  const { tripsDetails } = useSelector((state: RootState) => state.TripPlannings)

  const _renderPadDetailsHour = () => {
    const outTime = stopsLength > 0 && Duration.fromISOTime(direction[0]?.time).toFormat('hh:mm')
    const inTime = Duration.fromISOTime(direction[0]?.time)?.toFormat('hh:mm')
    return (
      <>
        <Styles.PadDetailsHour>
          <Text style={{ color: `${theme().gray600Color}` }}>
            { isOutbound ? outTime : inTime}
          </Text>
        </Styles.PadDetailsHour>
        <div style={{ width: '5%', paddingTop: '7px', textAlign: 'end', paddingLeft: '35px' }}>{isOutbound ? <StartTrip /> : <EndTrip />}</div>
      </>
    )
  }

  const _renderSeparator = () => {
    return isOutbound && <Styles.Separator />
  }
  
  const _renderLastStopCheckIndicatorTooltip = (hasOneArrived: boolean, currentStopDetails) => {
    return hasOneArrived && (
      <Styles.Tooltip>
        <CheckCircle width="15" height="14" viewBox="0 0 41 40" style={{cursor: "pointer"}}/>
        <Styles.CustomSpan>
          {
            currentStopDetails && currentStopDetails.details.map((d) => {
              if(d.hasArrived) {
                return (
                  <Styles.TooltipHoursInfo>
                    <span style={{display: "flex"}}>
                      <Icon marginr='5'><Boat fill={d.vehicle.color} /></Icon>
                      <p>{d.vehicle.name}</p>
                    </span>
                    <Styles.TooltipHoursText><b>{i18n.t('tripPlannings.arrival')}</b><p>{DateTime.fromISO(d.arrivedAt).toFormat('HH:mm')}</p></Styles.TooltipHoursText>
                  </Styles.TooltipHoursInfo>
                )
              } 
              return <></>
            })
          }
        </Styles.CustomSpan>
      </Styles.Tooltip>
    )
  }

  const _renderCheckIndicatorTooltip = (idx: number, hasOneBoarded: boolean, hasOneArrived: boolean, currentStopDetails) => {
    return ((idx === 0 && hasOneBoarded) || (idx > 0 && hasOneArrived)) && (
      <Styles.Tooltip>
        <CheckCircle width="15" height="14" viewBox="0 0 41 40" style={{cursor: "pointer"}}/>
        <Styles.CustomSpan>
          {
            currentStopDetails && currentStopDetails.details.map((d, index) => {
              if( idx === 0 ? d.boarding?.startedAt : d.hasArrived) {
                return (
                  <Styles.TooltipHoursInfo key={`toolTip-hour-info-idx-${index}`}>
                    <span style={{display: "flex"}}>
                      <Styles.Icon marginr='5'><Boat fill={d.vehicle.color} /></Styles.Icon>
                      <p>{d.vehicle.name}</p>
                    </span>

                    { 
                      idx === 0 && hasOneBoarded && (
                        <Styles.TooltipHoursText><b>{i18n.t('tripPlannings.startedBoarding')}</b><p>{DateTime.fromISO(d.boarding.startedAt).toFormat('HH:mm')}</p></Styles.TooltipHoursText>
                      )
                    }
                    {
                      idx > 0 && hasOneArrived && (
                        <>
                          <Styles.TooltipHoursText><b>{i18n.t('tripPlannings.arrival')}</b><p>{DateTime.fromISO(d.arrivedAt).toFormat('HH:mm')}</p></Styles.TooltipHoursText>
                          {
                            d.boarding?.startedAt && <Styles.TooltipHoursText><b>{i18n.t('tripPlannings.startedBoarding')}</b><p>{DateTime.fromISO(d.boarding.startedAt).toFormat('HH:mm')}</p></Styles.TooltipHoursText>
                          }
                        </>
                      )
                    }
                    
                    {
                      d.hasDeparted && <Styles.TooltipHoursText><b>{i18n.t('tripPlannings.departure')}</b><p>{DateTime.fromISO(d.departedAt).toFormat('HH:mm')}</p></Styles.TooltipHoursText>
                    }
                  </Styles.TooltipHoursInfo>
                )
              }
              return <></>
            })
          }
        </Styles.CustomSpan>
      </Styles.Tooltip>
    )
  }

  return (
    <>
      <Styles.PadStops>
        { _renderPadDetailsHour() }
        <Styles.PadCircuitDetails>
          {
            direction.map((st, idx) => {
              let currentStopDetails = isOutbound ?
                tripsDetails?.find((t) => t.id === planningId)?.outbound.find((o) => o.stop.id === st.stop.id)
                :
                tripsDetails?.find((t) => t.id === planningId)?.inbound.find((o) => o.stop.id === st.stop.id)
              let hasOneArrived = currentStopDetails?.details.some((d) => d.hasArrived)
              let hasOneBoarded = currentStopDetails?.details.some((d) => d.boarding?.startedAt)

              //condition to check if it's the last leg stop (if is the last stop, there is no next stop styledComponent) 
              if (stopsLength === idx + 1) {
                return (
                  <Styles.PadCircuitDetailStop>
                    <div style={{display: "flex", alignItems: "center", gap: "5px"}}>
                      <PadCircuitStopInfo 
                        planningId={planningId}
                        vehicles={vehicles}
                        isOutbound={isOutbound}
                        idx={idx}
                        isLastStop={true}
                        currentStopDetails={currentStopDetails}
                        stop={st}
                      />
                      <Styles.CheckIndicator>
                        {_renderLastStopCheckIndicatorTooltip(hasOneArrived, currentStopDetails) }
                      </Styles.CheckIndicator>
                    </div>
                  </Styles.PadCircuitDetailStop>
                )
              }
              else {
                return (
                  <>
                    <Styles.PadCircuitDetailStop>
                      <PadCircuitStopInfo 
                        planningId={planningId}
                        vehicles={vehicles}
                        isOutbound={isOutbound}
                        idx={idx}
                        isLastStop={false}
                        currentStopDetails={currentStopDetails}
                        stop={st}
                      />
                      <Styles.CheckIndicator>
                        { _renderCheckIndicatorTooltip(idx, hasOneBoarded, hasOneArrived, currentStopDetails) }
                      </Styles.CheckIndicator>
                    </Styles.PadCircuitDetailStop>

                    <Styles.PadCircuitDetailBetweenStopsCurrent>
                      <Styles.UnitsIndicatorWrap>
                          {
                            vehicles && vehicles.map((vhc) => {
                              const condition = isOutbound ? !vhc.outboundCompleted : vhc.outboundCompleted
                              if(vhc.inTransit && condition && vhc.departure?.stop.id === st.stop.id) {
                                return <Styles.Icon marginr='5'><Boat fill={vhc.vehicle.color} /></Styles.Icon>
                              } 
                              return <></>
                            })
                          }
                      </Styles.UnitsIndicatorWrap>
                    </Styles.PadCircuitDetailBetweenStopsCurrent>
                  </>
                )
              }
            })
          }
          {
            showDetails && <Styles.Line />
          }
        </Styles.PadCircuitDetails>
      </Styles.PadStops>
      { _renderSeparator() }
    </>
  )
}

export default PadStops