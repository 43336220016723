/**
 * Customerpurchasehistory scene sagas
 *
 * @author João Dias <jdias@ubiwhere.com>
 *
 *
 */
import { takeLatest, putResolve, call, select } from 'redux-saga/effects'
import { PayloadAction } from '@reduxjs/toolkit';
import { actions, RootState } from 'store/rootSlices'
import i18n from "i18next";
import API from 'api'
import { toast } from 'react-toastify';
import { TripBookingsList } from 'types/trips/bookings';


function* onMountSaga({ payload }: PayloadAction<string>) {
  yield putResolve(actions.Customerpurchasehistory.setLoading(true));

  if (payload) {
    yield putResolve(actions.Customerpurchasehistory.setCostumerUUID(payload));
    yield getBookingsSaga()

  } else {
    yield putResolve(actions.Customerpurchasehistory.setLoading(false));
  }

}

function* getBookingsSaga() {
  const { page, sort, searchTerm, customerUUID } = yield select((state: RootState) => state.Customerpurchasehistory);
  yield putResolve(actions.Customerpurchasehistory.setLoading(true));

  try {
    const bookings: TripBookingsList = yield call( API.Customers.GetBookingsByCustomer, page, sort, customerUUID, searchTerm);

    if(bookings){
      yield putResolve(actions.Customerpurchasehistory.setBookings(bookings.results));
      yield putResolve(actions.Customerpurchasehistory.setTotal(bookings.count))
      yield putResolve(actions.Customerpurchasehistory.setLoading(false));
    }
  } catch (error) {
    toast.error(i18n.t("toasts.entriesGetError"))
    yield putResolve(actions.Customerpurchasehistory.setLoading(false));
  }
}


function* onUnmountSaga() { 
  yield putResolve(actions.Customerpurchasehistory.setBookings([]));
  yield putResolve(actions.Customerpurchasehistory.setLoading(true));
}

export default function* watcherSignin() {
  yield takeLatest('Customerpurchasehistory/onMount', onMountSaga)
  yield takeLatest('Customerpurchasehistory/onUnmount', onUnmountSaga)
  yield takeLatest('Customerpurchasehistory/setPage', getBookingsSaga)
  yield takeLatest('Customerpurchasehistory/setSearchTerm', getBookingsSaga)
  yield takeLatest('Customerpurchasehistory/setSort', getBookingsSaga)
}


