/**
 * Invoicingsummary scene
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { CCol, CWidgetStatsA } from '@coreui/react-pro'
import { CRow } from '@coreui/react'
import _ from 'lodash'
import { DateTime } from 'luxon'
// Store
import { actions, RootState } from 'store/rootSlices'
// Icons
import ManageAccounts from 'assets/icons/manage_accounts'
import Payments from 'assets/icons/payments'
import MovementsIcon from 'assets/icons/movements_icon'
import Download from 'assets/icons/file_download';
// Components
import SearchBox from 'shared/components/SearchBox'
import FilterDatePicker from 'shared/components/FilterDatePicker'
import FilterComponent from 'shared/components/FilterComponent'
import TableContainer from 'shared/components/TableContainer'
import SorterComponent from 'shared/components/SorterComponent'
import Entriestablerow from './components/Entriestablerow'
import Loadingcontainer from 'shared/components/Loadingcontainer'
// Utils & Congigs
import config from 'config'
import { paginationCurrentPage, paginationElementsCount } from 'utils'
import { presetsLists } from 'shared/components/FilterDatePicker/utils'
// Types
import { BalanceListItem  } from "types/invoicing/invoices"
// Styles
import { ControlsContainer, CustomWrapper, Controls1, Controls2 } from 'shared/genericStyledComponents'
import { DisplaysContainer, Text, TextH5, DownloadButton } from './styles'

const InvoicingSummary = ({ t, i18n }) => {

  const {
    totalEntries,
    page,
    loading,
    entries,
    counters,
    sort,
    paymentTypes,
    filter,
    paymentTypeFilter,
    searchTerm,
    startDate,
    endDate,
    hasDateParams,
    movementTypeFilter,
    movementTypes,
    exportLoading
  } = useSelector((state: RootState) => state.InvoicingSummary)
  
  const {
    onMount,
    onUnmount,
    setPage,
    setSearchTerm,
    setFilter,
    setPaymentTypeFilter,
    setDatesInterval,
    setSort,
    getEntries,
    exportStatements,
    setMovementTypeFilter
  } = actions.InvoicingSummary
  const { collaboratorsLongList } = useSelector((state: RootState) => state.CollaboratorsManagement)
  //const { api_t } = actions.App

  const { ELEMENTS_PER_PAGE } = config
  const dispatch = useDispatch()
  const dbDispatch = _.debounce(dispatch, 500);
  const history = useHistory()


  useEffect(() => {

    dispatch(onMount(history))
    return () => {
      dispatch(onUnmount())
    }
  }, [dispatch, onMount, onUnmount, history]);


  return (
    <CustomWrapper>
      <ControlsContainer>
        <Controls1>
          <SearchBox
            id="invoicingSummary-searchBox"
            defaultValue={searchTerm}
            onSubmit={(term) => {
              if(term !== '') {
                let e = {
                    endDate: null,
                    startDate: null
                }
                dispatch(setDatesInterval({ e, history }))
              } else {
                let e = {
                    endDate: new Date(),
                    startDate: new Date()
                }
                dispatch(setDatesInterval({ e, history }))
              }
              let params = new URLSearchParams(history.location.search);
              term !== "" ? params.set('search', term) : params.delete('search')
              history.replace({ search: (params).toString() })
              dbDispatch(setSearchTerm(term))
            }}
          />
          <FilterDatePicker
            showMonthYearDropdown
            presets={presetsLists().invoicingSummary}
            filterName={i18n.t('common.today')}
            range
            reRender={hasDateParams}
            noDate={startDate === '' && endDate === '' ? true : false}
            defaultValue={
              {
                startDay: startDate ? DateTime.fromISO(startDate).toJSDate() : new Date(),
                endDay: endDate ? DateTime.fromISO(endDate).toJSDate() : new Date(),
              }
            }
            onChange={(e) => {
              dispatch(setDatesInterval({ e, history }))
              dispatch(getEntries())
            }}
          />
          <FilterComponent
            id="invoicingSummary-collaboratorFilter"
            icon={<ManageAccounts
              width={20}
              height={20}
              viewBox={"0 0 18 18"} />}
            defaultOption={
              {
                label: collaboratorsLongList.find(ct => ct.uuid === filter)?.name as string,
                value: filter
              }
            }
            onChange={(e) => {
              let params = new URLSearchParams(history.location.search);
              e.value !== "" ? params.set('processedBy', e.value) : params.delete('processedBy')
              history.replace({ search: (params).toString() })
              dispatch(setFilter(e.value))
            }}
            filterName={t('users.collaborator')} options={
              collaboratorsLongList.map(ct => {
                return {
                  label: ct.name,
                  value: ct.uuid.toString()
                }
              })
            } />
          <FilterComponent
            id="invoicingSummary-paymentType"
            icon={<Payments
              width={20}
              height={20}
              viewBox={"0 0 18 18"} />
            }
            defaultOption={{
              label: paymentTypes?.find(ct => ct['uid'] === paymentTypeFilter)?.name as string,
              value: paymentTypeFilter
            }}
            onChange={(e) => {
              let params = new URLSearchParams(history.location.search);
              e.value !== "" ? params.set('paymentType', e.value) : params.delete('paymentType')
              history.replace({ search: (params).toString() })
              dispatch(setPaymentTypeFilter(e.value))
            }}
            filterName={t('invoicingSummary.paymentType')} options={
              paymentTypes?.map((pt: any) => {
                return {
                  label: pt.name,
                  value: pt.uid.toString()
                }
              })
            } />
          <FilterComponent
            id="invoicingSummary-movementType"
            maxWidth='250px'
            icon={<MovementsIcon
              width={18}
              height={18}
              viewBox={"0 0 18 18"} />
            }
            defaultOption={{
              label: movementTypes?.find(mt => mt['uid'] === movementTypeFilter)?.name as string,
              value: movementTypeFilter
            }}
            onChange={(e) => {
              let params = new URLSearchParams(history.location.search);
              e.value !== "" ? params.set('movementType', e.value) : params.delete('movementType')
              history.replace({ search: (params).toString() })
              dispatch(setMovementTypeFilter(e.value))
            }}
            filterName={t('invoicingSummary.movementType')} options={
              movementTypes?.map((mt: any) => {
                return {
                  label: mt.name,
                  value: mt.uid.toString()
                }
              })
            } />
        </Controls1>
        <Controls2>
          <DownloadButton onClick={() => {
            if(!exportLoading) dispatch(exportStatements())
          }}>
              <Download width={30} height={30} />
          </DownloadButton>
        </Controls2>
      </ControlsContainer>
      <DisplaysContainer>
        <CRow>
          <CCol xs={4}>
            <CWidgetStatsA
              className="mb-4 display padding title-text pos"
              color="white"
              value={
                <TextH5>{counters?.income} €</TextH5>
              }
              title={
                <Text>{t('invoicingSummary.revenue')}</Text>
              }
            />
          </CCol>
          <CCol xs={4}>
            <CWidgetStatsA
              className="mb-4 display padding title-text pos"
              color="white"
              value={
                <TextH5>{counters?.expenses} €</TextH5>
              }
              title={
                <Text>{t('invoicingSummary.expenses')}</Text>
              }
            />
          </CCol>
          <CCol xs={4}>
            <CWidgetStatsA
              className="mb-4 padding pos"
              color="primary"
              value={
                <TextH5>{counters?.total} €</TextH5>
              }
              title={
                <Text>Total</Text>
              }
            />
          </CCol>
        </CRow>
      </DisplaysContainer>
      {
        loading ?
          <Loadingcontainer />
          :
          <TableContainer
            count={paginationElementsCount(totalEntries)}
            currentPage={paginationCurrentPage(totalEntries, page)}
            elementsPerPage={ELEMENTS_PER_PAGE}
            onPageChange={(pg) => { dispatch(setPage(pg)) }}
            headerElements={[
              <SorterComponent
                sort={sort}
                active={sort.field === "date"}
                id="invoicingSummary-dateSorter"
                filterName={t('invoicingSummary.date')}
                onChange={(dir) => { dispatch(setSort({ field: "date", direction: dir })) }}
              />,
              <SorterComponent
                sort={sort}
                active={sort.field === "description"}
                id="invoicingSummary-movementType"
                filterName={t('invoicingSummary.movementType')}
                onChange={(dir) => { dispatch(setSort({ field: "description", direction: dir })) }}
              />,
              t('invoicingSummary.processedBy'),
              <SorterComponent
                sort={sort}
                active={sort.field === "payment"}
                id="InvoicingSummary-paymentType"
                filterName={t('invoicingSummary.paymentType')}
                onChange={(dir) => { dispatch(setSort({ field: "payment", direction: dir })) }}
              />,
              <SorterComponent
                sort={sort}
                active={sort.field === "amount"}
                id="invoicingSummary-value"
                filterName={t('invoicingSummary.value')}
                onChange={(dir) => { dispatch(setSort({ field: "amount", direction: dir })) }}
              />,
              t('common.state'),
            ]}
          >
            {
              entries.map((e: BalanceListItem, idx: number) => {
                return <Entriestablerow
                  key={`entry-${idx}`}
                  id={`entry-tableRow-${idx}`}
                  entry={e}
                  onClick={() => { }}
                />
              })
            }
          </TableContainer>
      }
    </CustomWrapper>
  )
}

export default InvoicingSummary