/**
 * App utils
 * Please write a description
 *
 * @author Carlos Silva <csilva@ubiwhere.com>
 *
 *
 */

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
//import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import countries from "i18n-iso-countries";

import translations from 'translations';

import { createBrowserHistory } from 'history';
import { actions } from 'store/rootSlices';
import { SortType } from 'types/misc';

export const history = createBrowserHistory({
  /* pass a configuration object here if needed */
});

export const initI18N = () => {
  i18n
    //.use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      lng:'pt',
      fallbackLng: 'pt',
      resources: translations,
      debug: true,
      /*backend: {
        // for all available options read the backend's repository readme file
        loadPath: '/locales/{{lng}}/{{ns}}.json'
      },
      */
    });

  countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
  countries.registerLocale(require("i18n-iso-countries/langs/pt.json"));

  return i18n;
};

/**
 * Transforms url with {} tags based on object
 * @param {*} url
 * @param {*} obj
 */

export const generateUrl = (url, obj) => {
	let newUrl = url

	for (var key in obj) {    
		if (Object.prototype.hasOwnProperty.call(obj, key)) {
			const value = obj[key]
			newUrl = newUrl.replace(`{${key}}`, value)
		}
	}

	return newUrl
}

export const generateSortString = (sort: SortType) => {
  let string = '&ordering=';
  if (sort.direction === "desc") string += "-";
  string += sort.field;

  return string;
}

export const checkIfLoggedIn = () => {
  const JWT = window.localStorage.getItem('access_token')
  
  if(!JWT){
    actions.App.navigateTo('/login')
    return
  }
}

export function* days(interval) {
  if(interval.s && interval.e){
    let cursor = interval?.start.startOf("day");
  
    while (cursor <= interval.end) {
      yield cursor;
      cursor = cursor.plus({ days: 1 });
    }
  }
}

export const paginationElementsCount = (total: number) => {
  // total at 1 disables next pages
  if(total === 0) return 1; 
  return total 
}

export const paginationCurrentPage = (total: number, page: number) => {
  //current page at -1 removes pagination component (remove component if total is 0)
  if(total === 0) return -1; 
  return page 
}

export const convertMinutesToHours = (value : number) => {
  const num = value;
  const hours = (num / 60);
  const rhours = Math.floor(hours);
  const minutes = (hours - rhours) * 60;
  const rminutes = Math.round(minutes);
  
  return rhours + "h " + rminutes + "m";
}