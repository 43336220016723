import styled from 'styled-components'
import { CTableBody, CTableHead, CTableRow, CTableDataCell } from '@coreui/react-pro';
import theme from 'ui/theme';

export const Wrapper = styled.div`
  width: 100%;
  overflow-x: auto;
  
  ::-webkit-scrollbar {
    display: none;
  }
  > div {
    border-radius: 5px 5px 0 0;
  }
`

export const CustomCTableHead = styled(CTableHead)`
    background-color: ${theme().gray200Color};

    > tr {
      > th {
        color: ${theme().darkColor};
        font-family: 'Inter';
        font-size: 14px;
        font-weight: 700;
      }

      th:last-child{
        width: 5%;
      }

      th:first-child {
        > svg {
          width: 100%;
          height: 24px;
          margin: auto;
          color: ${theme().darkColor};
          fill: ${theme().darkColor};
        }
      }
    }
`

export const CustomCTableBody = styled(CTableBody)`
  border: 1px solid ${theme().gray200Color};
`

export const CustomCTableRow = styled(CTableRow)`
  max-height: 54px;
  background-color: ${theme().white};
  border-bottom: 1px solid ${theme().gray200Color};
`

export const NoResultsCell = styled(CTableDataCell)`
  text-align: center;
  padding: 15px 15px;
  color: ${theme().gray500Color} !important;
`

export const PagingContainer = styled.div`
    cursor: pointer;
    align-self: start;
`
