import styled from 'styled-components'
import { CAvatar, CDropdownItem, CDropdownMenu, CHeader, CHeaderNav } from '@coreui/react-pro'
import theme from 'ui/theme';
import NotificationUnread from 'assets/icons/notif_unread'
import Mail from 'assets/icons/mail'

const Wrapper = styled.div`
  min-height: 56px;
  width:100%;
`
const HeaderNav = styled(CHeaderNav)`
  align-items:center;
`
const Notification = styled(NotificationUnread)`
  fill:${theme().gray600Color};
  &:hover{
    fill:${theme().gray900Color};
  }  
`
const Mails = styled(Mail)`
  fill:${theme().gray600Color};
  &:hover{
    fill:${theme().gray900Color};
  }  
`
const UserInformation = styled.div`
  display:flex;
  justify-content:space-between

`

const DropdownMenu = styled(CDropdownMenu)`
    margin-top:0px;
    margin-left:7px;
`

const DropdownItem = styled(CDropdownItem)`
    font-size:14px;
    &:active{
        background-color:${theme().gray600Color};
    }
`

const CustomHeader = styled(CHeader)`
  width:100%;
  position: unset;
  display: flex;
  flex-wrap:nowrap;
  padding: 0;
  padding-bottom: 0;
  padding-left: 20px;
  min-height: 56px;
  max-height: 56px;
  justify-content: space-between;
  
  > * {
    height: 100%;
  }

  > ul {
    margin-top: 0;
    margin-right: 5px;

    > li {
      margin-right: 15px;
      margin-bottom: 0;
    }
  }
`
const ProfileInfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 5px;
  margin-right: 25px;
  cursor:pointer;

  > img {
    height: 32px;
    width: 32px;
    border-radius: 50%; 
  }

  > li {
    margin-bottom: 0;
  }

  > div {
    display: flex;
    flex-direction: column;
    font-family: 'Inter';
    color: ${theme().gray600Color};

    > p {
      margin: 0;
    }

    p:nth-child(1) {
      font-size: 14px;
    }
    
    p:nth-child(2) {
      font-weight: lighter;
      font-size: 12px;
    }
  }
`

const CustomAvatar = styled(CAvatar)`
  .avatar-img {
    width: 100%;
    height:100%;
    object-fit: cover;
    border-radius: 50%;
  }
`

const HamburguerWrapper = styled.div`
  cursor: pointer;
`

export {
  Wrapper,
  HeaderNav,
  Notification,
  Mails,
  UserInformation,
  DropdownMenu,
  DropdownItem,
  ProfileInfoContainer,
  CustomAvatar,
  HamburguerWrapper,
  CustomHeader
}