import styled from 'styled-components'
import { CButton, CButtonGroup, CCol,  CFormCheck } from '@coreui/react-pro'
import theme from 'ui/theme'


export const Button = styled(CButton)`
  line-height: 1.35;
  height: 53%;
  align-self: flex-end;
`

export const GenerateColumnButton = styled(CCol)`
  display:flex;
  justify-content: flex-end;
  column-gap: 15px;
`

export const VehiclesButton = styled(CButtonGroup)`
  display: flex;
  align-items: center;
  margin-right: 30px;
  color: ${theme().secondaryColor};
`

export const Vehicle = styled(CFormCheck)`
  margin:0px;
  border: 1px solid ${theme().secondaryColor};
  padding: 5px 11px !important;
`

export const CustomBtn = styled.button`
  background: none;
  border: none;
  color: ${theme().primary};
`

export const Units = styled.div`
  display:flex;
  justify-content: space-between;
  padding-bottom: 20px;
  flex-wrap: wrap;
`