import styled from 'styled-components'
import theme from 'ui/theme';
import { HelpText } from 'shared/genericStyledComponents';

export const Wrapper = styled.div <{ isvalid: boolean | undefined, edited?: boolean, isOpen?: boolean }>`

  > label {
    font-size: 14px;
    color: ${theme().darkColor};
  > span {
    color: ${theme().primary};
    }
  }

  >.custom-multiSelect {
    width:'100%';
    color:${theme().gray600Color};
    font-size: 14px;

    >.dropdown-container {
      border-color:${'#D8DBE0'};
      border-style: solid;
      border-width: 1px;
      border-radius: 4px;
      border-color:${(props) => props.isvalid === false ? theme().danger : '#D8DBE0'};
      transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
      height: 35px;

      &:focus-within {
        border-color: #321fdb50;
        outline-width: 0.1px;
        outline-color: #321fdb50;
        outline-style: solid;
        outline-offset: 0px;
        box-shadow: 0 0 1px 5px #321fdb50;
      };
     
      &:disabled{
        background-color:#d8dbe0;
      };

      >.dropdown-heading {
        height: 35px;

        >.dropdown-heading-value {
          padding-bottom: 2px;
        }
      }
    }
  }

`
export const CustomHelpText = styled(HelpText) <{ valid: boolean | undefined }>`
  text-align: left;
`