/**
 * Historylog scene
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { actions, RootState } from 'store/rootSlices'
import { Controls1, Controls2, ControlsContainer, Wrapper } from 'shared/genericStyledComponents'
import SearchBox from 'shared/components/SearchBox'
import Loadingcontainer from 'shared/components/Loadingcontainer'
import TableContainer from 'shared/components/TableContainer'
import Historylogrow from './components/Historylogrow'
import _ from 'lodash'
//import DropdownExport from 'shared/components/FormComponents/dropdownexport'

const Historylog = ({ t, i18n }) => {

  const { loading, total, historyLog } = useSelector((state: RootState) => state.HistoryLog)
  const { onMount, onUnmount, setPage, setSearchTerm } = actions.HistoryLog
  const { id } = useParams()
  const dispatch = useDispatch()
  const dbDispatch = _.debounce(dispatch, 500);


  useEffect(() => {

    dispatch(onMount(id))
    return () => {
      dispatch(onUnmount())
    }
  }, [dispatch, onMount, onUnmount, id]);

  return (
    <Wrapper>
      <ControlsContainer>
        <Controls1>
          <SearchBox id="historyLog-searchBox" onSubmit={(term) => { dbDispatch(setSearchTerm(term)) }} />
        </Controls1>
        <Controls2>
{/*           <DropdownExport
            id="historyLog-dropdownExport"
            dropdown={true}
            wrapperStyle={{ maxWidth: '100%', height: '100%', marginBottom: 0 }}
            options={[{ value: "json", prefix: "1" }, { value: "csv", prefix: "2" }]}
          /> */}
        </Controls2>
      </ControlsContainer>
      {loading ?
        <Loadingcontainer />
        :
        <TableContainer
          id={`historyLog-table`}
          count={total}
          currentPage={-1}
          elementsPerPage={-1}
          onPageChange={pg => { dispatch(setPage(pg)) }}
          headerElements={[
            t('historyLog.date'),
            t('historyLog.hour'),
            t('historyLog.editedWho'),
            t('historyLog.description'),
            t('historyLog.editedBy'),
            ""
          ]}
        >
          {
            historyLog?.map((el, idx) => {
              return (
                <Historylogrow
                  key={`historyLog-${idx}`}
                  i18n={i18n}
                  t={t}  
                  log={el} 
                />
              )
            })
          } 
        </TableContainer>
      }
    </Wrapper>
  )
}

export default Historylog
