import styled from 'styled-components'
import theme from 'ui/theme'
import TableContainer from 'shared/components/TableContainer'

const Text = styled.p`
  margin:0
`
const SmallSpan = styled.span`
  font-size:10px;
  font-weight: 400
`
const ButtonWrapper = styled.div`
  width: 100%;
  display:flex;
  justify-content:flex-end;
  padding-top:22px;
`

const CustomCommissionTable = styled(TableContainer)`
   > table {
     > thead {
      > tr {
        th:last-child{
          display: none;
          width: 0%;
        }
     }
   }
`

const CurrentValueContainer = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  color: ${theme().darkColor};
`

export {
  Text,
  SmallSpan,
  ButtonWrapper,
  CustomCommissionTable,
  CurrentValueContainer,
}