import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
// Store
import { actions, RootState } from 'store/rootSlices'
// Components
import Accessdatamodal from 'shared/components/Accessdatamodal'
import CustomModal from 'shared/components/CustomModal'
// Config
import { hasPermission } from 'permissions'
import config from 'config'


const CustomerformModals = () => {

  const { permissions } = useSelector((state: RootState) => state.App)
  const { PERMISSIONS } = config
  const { 
    customerForm, 
    showDeleteModal, 
    userAuthExists,
    changePasswordForm,
    showChangePasswordModal,
  } = useSelector((state: RootState) => state.Customerform)
  const { 
    setShowDeleteModal ,
    setShowChangePasswordModal,
    onChangePasswordFormChange,
    onChangePasswordSave,
    onReSendEmail
  } = actions.Customerform

  const dispatch = useDispatch()

  const handleDeleteCustomerConfirm = (e) => {
    //dispatch(onDeleteConfirm(uuid))
  }

  const _renderDeleteModal = () => {
    return hasPermission(permissions, PERMISSIONS.USERS.DELETE_CUSTOMER) && (
      <CustomModal
        id='customerForm-deleteUserModal'
        visible={showDeleteModal}
        warningType='warning'
        warningName='Aviso'
        buttonIcon={false}
        footerPosition='center'
        textAlign='center'
        children={<p>Tem a certeza que pretende remover {customerForm.name.value}?</p>}
        onclose={e => dispatch(setShowDeleteModal(false))}
        onsubmit={e => handleDeleteCustomerConfirm(e)} />
    )
  }

  return (
    <>
      { _renderDeleteModal() }
      <Accessdatamodal
        id='customerForm-accessDataModal'
        userAuthExists={userAuthExists}
        newPassword={changePasswordForm.newPassword}
        confirmPassword={changePasswordForm.confirmPassword}
        visible={showChangePasswordModal}
        showChangePasswordModal={e => dispatch(setShowChangePasswordModal(e))}

        handleReSendEmail={e => dispatch(onReSendEmail())}
        handleSubmit={e => dispatch(onChangePasswordSave({ changePasswordForm }))}

        onChangeNewPassword={e => dispatch(onChangePasswordFormChange({ ...changePasswordForm, newPassword: { value: e.target.value, message: "", valid: true } }))}
        onChangeConfirmPassword={e => dispatch(onChangePasswordFormChange({ ...changePasswordForm, confirmPassword: { value: e.target.value, message: "", valid: true } }))}
      />
    </>
  )
}

export default CustomerformModals