import styled from 'styled-components'
import theme from 'ui/theme';

export const Wrapper = styled.div`
  > div {
    z-index:999;
    > label {
      font-family: 'Inter';
      font-size: 16px;
      color: ${theme().gray500Color};
      cursor:pointer;
    }
  }

  &.negative_margin{
    padding-top: 20px;
    width: 50%;
  }
`