import styled from 'styled-components'
import { CustomTableRow } from 'shared/genericStyledComponents'


export const CustomCTableRow = styled(CustomTableRow)`
  td:first-child {
    padding-left:15px;
    width:1%;
  }

  td:nth-child(2){
    width:12%;
  }

  td:nth-child(3){
    width:5%;
  }

  td:nth-child(4){
    width:5%;
  }

  td:nth-child(5) {
    width:5%;
    padding:0;
    padding-left: 7px;
  }
 `