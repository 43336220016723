import { PayloadAction } from '@reduxjs/toolkit'
import { takeLatest, putResolve, call, select  } from 'redux-saga/effects'
import API from 'api'
import { actions, RootState } from 'store/rootSlices'
import { toast } from 'react-toastify'
import * as Check from 'validations';
import { PassengerTypeValidations, SubmitSagaPayload } from '../utils'
import i18n from 'i18next';
import config from 'config';
import { hasPermission } from 'permissions'
const { PERMISSIONS } = config

//state
const passngTypID = (state: RootState) => state.TripPassengerTypeForm.passengerTypeID

function* onMountSaga({ payload }: PayloadAction<number>) {

  if (payload) {
    
    const { permissions } = yield select((state: RootState) => state.App);
    // yield putResolve(actions.TripPassengerTypeForm.setAction(true))
    // yield putResolve(actions.TripPassengerTypeForm.populateForm(""))

    const PassengerTypeID = payload

    try {
      //get passenger type from api
      const passengerType = yield call(API.PassengerTypes.GetPassengerType, PassengerTypeID)

      if (passengerType) {
        yield putResolve(actions.TripPassengerTypeForm.setPassengerTypeID(PassengerTypeID))
        yield putResolve(actions.TripPassengerTypeForm.setAction(true))
        yield putResolve(actions.TripPassengerTypeForm.setHasEditPermission(hasPermission(permissions, PERMISSIONS.TRIPS.PASSENGER_TYPES.EDIT_PASSENGER_TYPE)))
        yield putResolve(actions.TripPassengerTypeForm.populateForm(passengerType))
      } else {
        toast.error(i18n.t("toasts.getTripPassengerTypeError"));
      }
    } catch (error) {
    } 

  } else {
    // creating a new passenger type
    yield putResolve(actions.TripPassengerTypeForm.setAction(false))
  }
}

function* onSubmitSaga({ payload }: PayloadAction<SubmitSagaPayload>) {

  yield putResolve(actions.TripPassengerTypeForm.setSubmitButtonDisabled(true))

  const validation = payload.edit === true ?
    Check.checkValidationAlternative(payload.passengerTypeForm, PassengerTypeValidations)
    :
    Check.checkValidationAlternative(payload.passengerTypeForm, PassengerTypeValidations)

  if (validation.invalid === true) {
    const updateValidationMessages = Check.setAndCheckValidationAlternative(payload.passengerTypeForm, payload.passengerTypeForm, PassengerTypeValidations)
    yield putResolve(actions.TripPassengerTypeForm.onFormChange(updateValidationMessages))
    yield putResolve(actions.TripPassengerTypeForm.setSubmitButtonDisabled(false))
    toast.error(i18n.t("toasts.invalidFields"));
  }
  else {
    
    if (!payload.edit) {
      try {
        const finalForm = { ...payload.passengerTypeForm }
        
        const category = yield call(API.PassengerTypes.CreatePassengerType, finalForm)

        if (category) {
          yield putResolve(actions.App.navigateTo('/viagens/configuracoes'))
          yield putResolve(actions.Tripsconfiguration.setMakeApiCals(true))
          toast.success(i18n.t("toasts.createPassengerTypeSuccess"));
        }

      } catch (error) {
        toast.error(i18n.t("toasts.createPassengerTypeError"));
      }
    }
    else {
      const passengerTypeID = yield select(passngTypID)
      
      try {
        const success = yield call(API.PassengerTypes.EditPassengerType, passengerTypeID, payload.passengerTypeForm)

        if (success) {
          yield putResolve(actions.App.navigateTo('/viagens/configuracoes'))
          toast.success(i18n.t("toasts.editPassengerTypeSuccess"))
        }
      } catch (error) {
        toast.warning(i18n.t("toasts.editPassengerTypeError"))
      }
    }
  }

  yield putResolve(actions.TripPassengerTypeForm.setSubmitButtonDisabled(false))
}

function* onUnmountSaga() {}


export default function* watcherSignin() {
  yield takeLatest('trippassengertypeform/onMount', onMountSaga)
  yield takeLatest('trippassengertypeform/onUnmount', onUnmountSaga)
  yield takeLatest('trippassengertypeform/onSubmit', onSubmitSaga)
}


