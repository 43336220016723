/**
 * TripCategoryform scene slice
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */
import { createSlice } from '@reduxjs/toolkit'
import { CategoryForm } from 'types/trips/categories'
import { camelizeKeys } from 'humps'


interface InitialState {
    categoryID: string,
    edit: Boolean,
    isIconRemoved: Boolean, //only relevant on edit
    isIconChanged: Boolean, //only relevant on edit
    categoryForm: CategoryForm
    hasEditPermission: boolean,
    submitButtonState: boolean,
}

const initialState: InitialState = {
    categoryID: "",
    edit: false,
    hasEditPermission: true,
    submitButtonState: false,
    isIconRemoved: false,
    isIconChanged: false,
    categoryForm: {
        icon:{
            value: null,
            valid: true,
            message: ""
        },
        namePt: {
            value: "",
            valid: true,
            message: ""
        },
        nameEn: {
            value: "",
            valid: true,
            message: ""
        },
        nameEs: {
            value: "",
            valid: true,
            message: ""
        },
        nameFr: {
            value: "",
            valid: true,
            message: ""
        },
        descriptionPt: {
            value: "",
            valid: true,
            message: ""
        },
        descriptionEn: {
            value: "",
            valid: true,
            message: ""
        },
        descriptionEs: {
            value: "",
            valid: true,
            message: ""
        },
        descriptionFr: {
            value: "",
            valid: true,
            message: ""
        },
        isActive: {
            value: true,
            valid: true,
            message: ""
        },
        allowDiscounts: {
            value: false,
            valid: true,
            message: ""
        },
        allowComissions: {
            value: false,
            valid: true,
            message: ""
        },
        allowMultipleActivities: {
            value: false,
            valid: true,
            message: ""
        },
        generateIslandTrips: { value: false, valid: true, message: "" },
        generateMiddleTrips: { value: false, valid: true, message: "" },
        isHopOnHopOff: { value: false, valid: true, message: ""},
        isDirect: { value: true, valid: true, message: ""},
        isTransport: { value: false, valid: true, message: ""},
        isBeach: { value: false, valid: true, message: ""},
        isPrivate: { value: false, valid: true, message: ""},
        allowOnlyOneBooking: { value: false, valid: true, message: ""},
        showInDigitalsignage: { value: false, valid: true, message: ""},
    }
}

export default createSlice({
    name: 'tripcategoryform',
    initialState,
    reducers: {
        onMount: (state, { payload }) => { },
        onUnmount: () => { 
            return initialState
        },
        setAction: (state, { payload }) => {
            state.edit = payload
        },
        setHasEditPermission: (state, { payload }) => {
            state.hasEditPermission = payload
        },
        setCategoryID: (state, { payload }) => {
            state.categoryID = payload
        },
        setIsIconRemoved: (state, { payload }) => {
            state.isIconRemoved = payload
        },
        setIsIconChanged: (state, { payload }) => {
            state.isIconChanged = payload
        },
        setSubmitButtonDisabled:(state, { payload }) => {
            state.submitButtonState = payload
        },
        populateForm: (state, { payload }) => {

            //const category = TripCategoriesMock[0]
            const category = camelizeKeys(payload)
            const baseForm = { ...initialState.categoryForm }

            Object.keys(category).forEach(key => {
                if (key in baseForm) {
                    baseForm[key] = {
                        ...baseForm[key],
                        value: category[key],
                    }
                }
            })
            state.categoryForm = baseForm;
        },
        onFormChange: (state, { payload }) => {
            state.categoryForm = payload    
        },
        onSubmit: (state, { payload }) => { }
    }
})