import * as React from "react"
import { SVGProps } from "react"

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={18}
        height={21}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M16 2.5h-1v-2h-2v2H5v-2H3v2H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14c1.1 0 2-.9 2-2v-14c0-1.1-.9-2-2-2Zm0 16H2v-10h14v10Zm0-12H2v-2h14v2Zm-12 4h5v5H4v-5Z"
            fill={props.fill ? props.fill : "#8A93A2"}
        />
    </svg>
)

export default SvgComponent
