import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { actions, RootState } from 'store/rootSlices'
import { DateTime } from "luxon"
import theme from 'ui/theme'
//configs
import config from 'config';
//styles
import { LegRow, IconTableCell, CustomLink } from '../../styles'
//icons
import SwapHorz from 'assets/icons/swap_horz'
import Loop from 'assets/icons/loop'
import EventSeat from 'assets/icons/event_seat'
import TrendingFlat from 'assets/icons/trending_flat'
import StartTrip from 'assets/icons/start_trip'
import EndTrip from 'assets/icons/end_trip'
import ChevronRight from 'assets/icons/chevron_right';
//Components
import { CTableDataCell } from '@coreui/react-pro'
import Loadingcontainer from 'shared/components/Loadingcontainer'
import CustomRadioButton from 'shared/components/CustomRadioButton';

const { DATE_FORMAT } = config;

const NewTableContent = () => {
  const {
    outbound,
    inbound,
    tripLegs,
    legInboundView,
    tableLoading
  } = useSelector((state: RootState) => state.BookingForm);

  const { setNewOutbound, setNewInbound } = actions.BookingForm;

  const dispatch = useDispatch();

  if(tableLoading) {
    return <CTableDataCell colSpan={8}><Loadingcontainer /></CTableDataCell>
  }

  return  <>
    {
      tripLegs.map((leg, idx) => (
        <LegRow
          key={idx}
          id={`newBooking-legRow-${leg.id}`}
          className={legInboundView && inbound.tripActivityLeg.id === leg.id ? 'selected' : !legInboundView && outbound.tripActivityLeg.id === leg.id ? 'selected' : ''}
          onClick={e => { legInboundView ? dispatch(setNewInbound(leg)) : dispatch(setNewOutbound(leg)) }}
        >
          <CTableDataCell style={{ "padding": "1em" }}>
            <CustomRadioButton
              group="legs"
              value={leg.id}
              checked={legInboundView ? leg.id === inbound.tripActivityLeg.id : leg.id === outbound.tripActivityLeg.id}
            />
          </CTableDataCell>
          <IconTableCell>
            {
              leg.activity.isHopOnHopOff ?
                <Loop />
                :
                leg.departure.isOutbound ?
                  <StartTrip fill={theme().gray500Color} />
                  :
                  <EndTrip fill={theme().gray500Color} />
            }
          </IconTableCell>
          <CTableDataCell>{leg.activity.name}</CTableDataCell>
          <CTableDataCell>{DateTime.fromISO(leg.date).toFormat(DATE_FORMAT.DATE)}</CTableDataCell>
          <CTableDataCell>{DateTime.fromISO(leg.time).toFormat(DATE_FORMAT.TIME)}</CTableDataCell>
          <CTableDataCell onClick={(e) => e.stopPropagation()}>
            <CustomLink
                to={`/viagens/agendamentos/editar-agendamento/${leg?.trip}`}
                target="_blank"
                rel="noopener"
            >
              {
                leg.activity.isHopOnHopOff ?
                  <>{leg.departure.name} <SwapHorz /> {leg.arrival.name}</>
                  :
                  <>{leg.departure.name} <TrendingFlat /> {leg.arrival.name}</>
              }
              <ChevronRight className='pointer' fill={theme().gray700Color} />
            </CustomLink>
          </CTableDataCell>
          <CTableDataCell>
            <EventSeat /> <b>{leg.availableTickets}</b>/<span className="smaller">{leg.maxCapacity}</span>
          </CTableDataCell>
          <CTableDataCell><b>{leg.price}€</b></CTableDataCell>
        </LegRow>
      ))
    }
  </>
};

export default NewTableContent