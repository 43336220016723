/**
 * Login scene
 *
 * @author João Dias <jdias@ubiwhere.com>
 *
 *
 */
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { actions, RootState } from 'store/rootSlices'
import AnimarisLogoMasterHorizontal from 'assets/icons/logo/animarisLogoMasterHorizontal';
import tourismLogo from 'assets/icons/logo/turismo-portugal.svg'
import ArrowBack from 'assets/icons/arrow_back'
import CustomTextInput from 'shared/components/CustomTextInput';
import CustomButton from 'shared/components/CustomButton';
import CustomCheckbox from 'shared/components/CustomCheckbox';
import { Wrapper, SubmitError, Back, LoginForm, LogoContainer, BottomLogoContainer } from './styles'
//import config from 'config'

const Login = ({ t }) => {

  const {
    credentials,
    error,
    isLoggedIn,
    showTermsAndConditions,
    submitButtonState,
    termsAccepted,
  } = useSelector((state: RootState) => state.Login)

  const {
    onMount,
    onUnmount,
    onFormChange,
    onSubmit,
    onSubmitTermsAndConditions,
    setTermsAndConditions,
    logout
  } = actions.Login

  const { navigateTo } = actions.App

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(onMount());
    return () => {
      dispatch(onUnmount())
    }

  }, [dispatch, onMount, onUnmount]);

  const handleForgotPassword = () => {
    dispatch(navigateTo(`/login/mudar-password/${" "}`))
  }

  const ShowTermsAndConditions = () => {

    const handleProceed = () => {
      if (termsAccepted === true) {
        dispatch(onSubmitTermsAndConditions())
      }
    }

    return (
      <LoginForm>
        <Back onClick={e => dispatch(logout())}>
          <ArrowBack />
          <h2>{t('login.back')}</h2>
        </Back>
        <h1>{t('login.termsAndConditions')}</h1>
        <h2>{t('login.termsConditionsSubtitle')}</h2>
        <CustomCheckbox
          id="termsAccepted"
          checked={termsAccepted}
          onClick={e => dispatch(setTermsAndConditions(!termsAccepted))}
          text={t('login.loginTermsText')} />
        {error !== '' ? <SubmitError>{error}</SubmitError> : null}
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '0' }}>
          <CustomButton
            id="login-proceedButton"
            disabled={!termsAccepted}
            text={t('common.continue')}
            onClick={e => handleProceed()} />
        </div>
      </LoginForm>

    )
  }

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      dispatch(onSubmit())
    }
  }

  return (
    <Wrapper onKeyDown={e => handleKeyPress(e)}>
      <LogoContainer>
        <AnimarisLogoMasterHorizontal />
      </LogoContainer>
      {
        !isLoggedIn ?
          <LoginForm>
            <h1>{t('login.title')}</h1>
            <h2>{t('login.subtitle')}</h2>
            <CustomTextInput
              id="login-loginEmail"
              placeholder={t('placeholders.loginEmail')}
              icon="email"
              helpText={credentials.username.message}
              onChange={e => {
                dispatch(onFormChange({ ...credentials, username: { value: e.target.value, valid: true, message: '' } }))
              }}
            />
            <CustomTextInput
              id="login-loginPassword"
              type="password"
              placeholder={t('placeholders.password')}
              helpText={credentials.password.message}
              onChange={e => {
                dispatch(onFormChange({ ...credentials, password: { value: e.target.value, valid: true, message: '' } }))
              }}
            />
            {error !== '' ? <SubmitError>{error}</SubmitError> : null}
            <div className='login-buttonWrapper' style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '0' }}>
              <CustomButton id="login-loginButton" disabled={submitButtonState} text="login" onClick={e => dispatch(onSubmit())} />
              <CustomButton id="login-forgotPasswordButton" text={t('login.forgotPassword')} buttonStyle={{ textDecoration: 'unset' }} color="link" onClick={e => handleForgotPassword()} />
            </div>
            <div style={{ paddingTop: '15px', marginBottom: '0px' }}>
              <a href='/clock/qrreader'>{t('login.clocks')}</a>
            </div>
          </LoginForm>
          : null
      }
      {
        isLoggedIn && showTermsAndConditions ?
          <ShowTermsAndConditions /> : null
      }
      <BottomLogoContainer>
        <p>{t('common.finance')}</p>
        <img src={tourismLogo} alt='Portugal Tourism Logo' />
      </BottomLogoContainer>
    </Wrapper>
  )
}

export default Login