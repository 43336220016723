/**
 * Entitymemberslist scene sagas
 *
 * @author João Dias <jdias@ubiwhere.com>
 *
 *
 */
import api from 'api';
import { PayloadAction } from '@reduxjs/toolkit';
import { takeLatest, putResolve, /*select,*/ call, select } from 'redux-saga/effects'
import { AnyAction } from '@reduxjs/toolkit';
import { actions, RootState, /*RootState*/ } from 'store/rootSlices'
import { toast } from 'react-toastify'
import i18n from 'i18next';
import { EntityMemberList, Entity } from 'types/entity/entity';

function* onMountSaga({payload}: AnyAction) {
  try {
    yield getURLParamsSaga(payload.hist);
    yield putResolve(actions.Entitymemberslist.fetchEntityMembers(payload.id));
    const res: Entity = yield call(api.Entities.GetSingleEntity, payload.id);
    yield putResolve(actions.Dashboard.setDynamicBreadcrumb({
      path: `entities/${res?.name}/entityMembers`,
      name: res?.name,
    }))
  } catch (err) {
  } 
}

function* onUnmountSaga() {
  yield putResolve(actions.Entitymemberslist.setEntityMembers([])); 
  yield putResolve(actions.Dashboard.setDynamicBreadcrumb(null))
  yield putResolve(actions.Entitymemberslist.setLoading(true));
}

function* fetchMembersSaga({payload}: PayloadAction<number>) {
  const { page, searchTerm, sort } = yield select((state: RootState) => state.Entitymemberslist);
  try {
    yield putResolve(actions.Entitymemberslist.setLoading(true))
    const res: EntityMemberList = yield call(api.Entities.GetEntityMembers, payload, page, searchTerm, sort);

    yield putResolve(actions.Entitymemberslist.setEntityMembers(res.results ?? []));
    yield putResolve(actions.Entitymemberslist.setTotal(res.count));
  } catch (err) {
    toast.error(i18n.t("toasts.getEntityError"))
  } finally {
    yield putResolve(actions.Entitymemberslist.setLoading(false));
  }
}

function* getURLParamsSaga( payload : AnyAction) {

  let params = new URLSearchParams(payload.location.search)

  const {
      searchTerm,
  } = yield select((state: RootState) => state.Entitymemberslist);

  if (params.toString() !== "") {
    yield putResolve(actions.Entitymemberslist.populateFiltersOnMount({
      searchTerm: params.get('search') ?? ""
    }))
  }
  else {
      params.set('search', searchTerm)
      payload.replace({ search: (params).toString() })
  }
}

export default function* watcherSignin() {
  yield takeLatest('Entitymemberslist/onMount', onMountSaga)
  yield takeLatest('Entitymemberslist/onUnmount', onUnmountSaga)
  yield takeLatest('Entitymemberslist/fetchEntityMembers', fetchMembersSaga)
  yield takeLatest('Entitymemberslist/setPage', fetchMembersSaga)
  yield takeLatest('Entitymemberslist/setSearchTerm', fetchMembersSaga)
  yield takeLatest('Entitymemberslist/setSort', fetchMembersSaga)
  yield takeLatest('Entitymemberslist/getURLParams', getURLParamsSaga);
}


