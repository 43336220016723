/**
 * Triplocalform component
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */
import { CCol, CForm, CRow } from '@coreui/react-pro'
import { useParams } from 'react-router-dom';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { actions, RootState } from 'store/rootSlices'

import CustomTextInput from 'shared/components/CustomTextInput'
import SectionContainer from 'shared/components/Sectioncontainer'
import ContainerDelete from 'shared/components/FormComponents/containerdelete';
import CustomButton from 'shared/components/CustomButton';
import theme from 'ui/theme';
import CustomCheckbox from 'shared/components/CustomCheckbox';
import FileUploader from 'shared/components/FormComponents/fileuploader';
import Dropdown from 'shared/components/FormComponents/dropdown';
import FormComponent from 'shared/components/FormComponents';
import { country_codes } from 'shared/components/FormComponents/utils';
import i18n from 'i18next';
import Photoscomponent from 'shared/components/Photoscomponent';
import config from 'config';
import { hasPermission } from 'permissions';
import { Row, ButtonWrapper } from '../styles';
import { LocalImageInfo } from './styles';

const Tripextraform = (props) => {

  const dispatch = useDispatch();
  const { id } = useParams();

  const {
    onFormChange,
    onMount,
    onUnmount,
    onSubmit,
    reOrderPhotosArray,
    addToPhotosArray,
    deletePhotoFromArray
  } = actions.TripLocalForm;
  const {
    edit,
    localForm,
    photos,
    newPhotosID,
    localID,
    hasEditPermission,
    submitButtonState
  } = useSelector((state: RootState) => state.TripLocalForm)
  const { countryCodes, permissions } = useSelector((state: RootState) => state.App)
  const { navigateBack } = actions.App;
  const { PERMISSIONS } = config

  useEffect(() => {
    dispatch(onMount(id))

    return () => {
      dispatch(onUnmount())
    }

  }, [dispatch, id, onMount, onUnmount]);

  const handleSubmit = (e) => {
    dispatch(onSubmit({ localForm, photos, e, edit }))
  }

  const handleDragPhotos = (e) => {
    dispatch(reOrderPhotosArray({ photos, e }))
  }

  const handleAddPhoto = (e) => {
    dispatch(addToPhotosArray({ photos, e, localID, newPhotosID }))
  }

  const handleDeletePhoto = (e, index) => {
    dispatch(deletePhotoFromArray({ photos, e, localID, newPhotosID, index }))
  }


  return (
    <div>
      <CForm>
        <CustomCheckbox
          id='tripLocalForm-isActive'
          disabled={!hasEditPermission}
          wrapperStyle={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '-25px', fontSize: '20px' }}
          checked={localForm.isActive.value ? true : false}
          onClick={e => dispatch(onFormChange({ ...localForm, isActive: { value: !localForm.isActive.value, valid: true, message: "" } }))}
          text={i18n.t('tripLocalForm.isActive')}
          switch
        />
        <SectionContainer title="Dados do Local" style={{ width: '100%', paddingBottom: '24px' }}>
          <CRow className="g-3">
            <CCol sm={9}>
              <CustomTextInput
                id='tripLocalForm-name'
                disabled={!hasEditPermission}
                required={true}
                valid={localForm.name.valid}
                value={localForm.name.value}
                helpText={localForm.name.message}
                placeholder={i18n.t('placeholders.name')}
                label={i18n.t('tripLocalForm.name')}
                onChange={(e) => dispatch(onFormChange({ ...localForm, name: { value: e.target.value, message: "", valid: true } }))}
              />
            </CCol>
            <CCol>
              <CustomTextInput
                id='tripLocalForm-shortName'
                disabled={!hasEditPermission}
                required={true}
                valid={localForm.shortName.valid}
                value={localForm.shortName.value}
                helpText={localForm.shortName.message}
                placeholder={i18n.t('placeholders.shortName')}
                label={i18n.t('tripLocalForm.shortname')}
                onChange={(e) => dispatch(onFormChange({ ...localForm, shortName: { value: e.target.value, message: "", valid: true } }))}
              />
            </CCol>
          </CRow>
          <Row>
            <CCol>
              <CustomTextInput
                id='tripLocalForm-address'
                disabled={!hasEditPermission}
                required={false}
                valid={localForm.address.valid}
                value={localForm.address.value}
                helpText={localForm.address.message}
                placeholder={i18n.t('placeholders.address')}
                label={i18n.t('tripLocalForm.address')}
                onChange={(e) => dispatch(onFormChange({ ...localForm, address: { value: e.target.value, message: "", valid: true } }))}
              />
            </CCol>
            <CCol>
              <CustomTextInput
                id='tripLocalForm-postalCode'
                disabled={!hasEditPermission}
                required={false}
                valid={localForm.postalCode.valid}
                value={localForm.postalCode.value}
                helpText={localForm.postalCode.message}
                placeholder={i18n.t('placeholders.postalCode')}
                label={i18n.t('tripLocalForm.postalCode')}
                onChange={(e) => dispatch(onFormChange({ ...localForm, postalCode: { value: e.target.value, message: "", valid: true } }))}
              />
            </CCol>
          </Row>
          <Row>
            <CCol>
              <Dropdown
                id='tripLocalForm-country'
                disabled={!hasEditPermission}
                required={true}
                valid={localForm.country.valid}
                value={localForm.country.value}
                helpText={localForm.country.message}
                placeholder={i18n.t('placeholders.country')}
                label={i18n.t('tripLocalForm.country')}
                onChange={(e) => dispatch(onFormChange({ ...localForm, country: { value: e.target.value, message: "", valid: true } }))}
                options={Object.keys(countryCodes).map(key => {
                  return {
                    label: countryCodes[key],
                    value: key
                  }
                })}
              />
            </CCol>
            <CCol>
              <CustomTextInput
                id='tripLocalForm-city'
                disabled={!hasEditPermission}
                required={true}
                valid={localForm.city.valid}
                value={localForm.city.value}
                helpText={localForm.city.message}
                placeholder={i18n.t('placeholders.location')}
                label={i18n.t('tripLocalForm.local')}
                onChange={(e) => dispatch(onFormChange({ ...localForm, city: { value: e.target.value, message: "", valid: true } }))}
              />
            </CCol>
            <CCol>
              <FormComponent
                id='tripLocalForm-phone'
                disabled={!hasEditPermission}
                required={false}
                valid={localForm.phone.valid}
                value={localForm.phone.value}
                helpText={localForm.phone.message}
                dropdown={false}
                label={i18n.t('tripLocalForm.phone')}
                placeholder="Portugal"
                options={country_codes}
                onChange={(e) => dispatch(onFormChange({ ...localForm, phone: { value: e, message: "", valid: true } }))}
              />
            </CCol>
          </Row>
          <Row>
            <CCol sm={2}><CustomCheckbox
              id={`tripLocalForm-isIsland`}
              disabled={!hasEditPermission}
              checked={localForm.isIsland.value === true ? true : false}
              onClick={e => dispatch(
                onFormChange(
                  {
                    ...localForm,
                    isIsland: { value: e.target.checked, valid: true, message: "" }
                  }
                )
              )}
              text={i18n.t('tripLocalForm.isIsland')}
            /></CCol>

            <CCol sm={2}><CustomCheckbox
              id={`tripLocalForm-isBeach`}
              disabled={!hasEditPermission}
              checked={localForm.isBeach.value === true ? true : false}
              onClick={e => dispatch(
                onFormChange(
                  {
                    ...localForm,
                    isBeach: { value: e.target.checked, valid: true, message: "" }
                  }
                )
              )}
              text={i18n.t('tripLocalForm.isBeach')}
            /></CCol>
          </Row>
        </SectionContainer>
        <SectionContainer title={i18n.t('tripLocalForm.localInfoTitle')} style={{ width: '100%', paddingBottom: '24px' }}>
          <CRow className="g-3">
            <CCol>
              <CustomTextInput
                id='tripLocalForm-description'
                disabled={!hasEditPermission}
                valid={localForm.description.valid}
                value={localForm.description.value}
                helpText={localForm.description.message}
                placeholder={i18n.t('placeholders.description')}
                label={i18n.t('tripLocalForm.description')}
                onChange={(e) => dispatch(onFormChange({ ...localForm, description: { value: e.target.value, message: "", valid: true } }))}
              />
            </CCol>
          </CRow>
          <Row>
            <CCol>
              <Dropdown
                id='tripLocalForm-timeBefore'
                disabled={!hasEditPermission}
                required={true}
                valid={localForm.timeBefore.valid}
                value={localForm.timeBefore.value}
                helpText={localForm.timeBefore.message}
                placeholder={i18n.t('placeholders.timeBefore')}
                label={i18n.t('tripLocalForm.selectMinutes')}
                onChange={(e) => dispatch(onFormChange({ ...localForm, timeBefore: { value: e.target.value, message: "", valid: true } }))}
                options={[{ label: "0 minutos", value: 0 }, { label: "5 minutos", value: 5 }, { label: "10 minutos", value: 10 }, { label: "15 minutos", value: 15 }]}
              />
            </CCol>
            <CCol>
              <CustomTextInput
                id='tripLocalForm-latitude'
                disabled={!hasEditPermission}
                required
                valid={localForm.latitude.valid}
                value={localForm.latitude.value}
                helpText={localForm.latitude.message}
                placeholder={i18n.t('placeholders.latitude')}
                label={i18n.t('tripLocalForm.latitude')}
                onChange={(e) => dispatch(onFormChange({ ...localForm, latitude: { value: e.target.value, message: "", valid: true } }))}
              />
            </CCol>
            <CCol>
              <CustomTextInput
                id='tripLocalForm-longitude'
                disabled={!hasEditPermission}
                required
                valid={localForm.longitude.valid}
                value={localForm.longitude.value}
                helpText={localForm.longitude.message}
                placeholder={i18n.t('placeholders.longitude')}
                label={i18n.t('tripLocalForm.longitude')}
                onChange={(e) => dispatch(onFormChange({ ...localForm, longitude: { value: e.target.value, message: "", valid: true } }))}
              />
            </CCol>
          </Row>
        </SectionContainer>
        <SectionContainer title={i18n.t('tripLocalForm.localPhotosTitle')} style={{ width: '100%', paddingBottom: '24px' }}>
          <CRow className="g-3">
            <CCol>
              <FileUploader
                id='tripLocalForm-photoUpload'
                disabled={!hasEditPermission}
                required={false}
                /* valid={localForm.name.valid}
                value={localForm.name.value}
                helpText={localForm.name.message} */
                label={i18n.t('tripLocalForm.upload')}
                accept='.png, .jpg, .jpeg'
                fileSizeLimit={100000000}
                onChange={(e) => handleAddPhoto(e)} />
            </CCol>
          </CRow>
          <CRow>
            <Photoscomponent
              id='tripLocalForm'
              array={photos}
              onDrag={e => handleDragPhotos(e)}
              onDelete={(e, index) => handleDeletePhoto(e, index)}
            />
            <LocalImageInfo>{i18n.t('tripLocalForm.localPhotoInfo')}</LocalImageInfo>
          </CRow>
        </SectionContainer>
        {
          edit === true && hasPermission(permissions, PERMISSIONS.TRIPS.STOPS.DELETE_STOP) && <ContainerDelete id='tripLocalForm-delete' title={i18n.t('tripLocalForm.removeLocal')} text={<p>{i18n.t('tripLocalForm.removeLocalText')}<br />{i18n.t('common.deleteLine2')}</p>}></ContainerDelete>
        }
        <ButtonWrapper>
          <CustomButton id='tripLocalForm-cancel' onClick={() => dispatch(navigateBack())} text={i18n.t('common.cancelBtn')} color="link" buttonStyle={{ color: theme().danger, textDecoration: 'unset' }} />
          {
            edit ?
              hasPermission(permissions, PERMISSIONS.TRIPS.STOPS.EDIT_STOP) &&
              <CustomButton
                id='tripLocalForm-save'
                disabled={submitButtonState}
                text={i18n.t('common.saveBtn')}
                icon="confirm"
                onClick={e => { handleSubmit(e) }}
                color="success"
                buttonStyle={{ color: "#FFFFFF" }} />
              :
              hasPermission(permissions, PERMISSIONS.TRIPS.STOPS.CREATE_STOP) &&
              <CustomButton
                id='tripLocalForm-save'
                disabled={submitButtonState}
                text={i18n.t('common.saveBtn')}
                icon="confirm"
                onClick={e => { handleSubmit(e) }}
                color="success"
                buttonStyle={{ color: "#FFFFFF" }} />
          }
        </ButtonWrapper>
      </CForm>
    </div>
  )
}

export default Tripextraform