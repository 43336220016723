/**
 * Changepassword scene slice
 *
 * @author Manuel Gil <mgil@ubiwhere.pt>
 *
 *
 */
import { createSlice } from '@reduxjs/toolkit'
import { FormField } from 'types/misc'
import { PasswordForm } from 'types/users/user'

interface initialState {
  showEmailComponent: boolean,
  uuid: string
  emailSent:boolean,
  showChangePasswordComponent:boolean,
  token: string | null,
  error:string,
  email: FormField<string>,
  passwordForm: PasswordForm
}

const initialState: initialState = {
  showEmailComponent: true,
  uuid: "",
  emailSent:false,
  showChangePasswordComponent:false,
  token: null,
  error:'',
  email: {
    value:'',
    message:'',
    valid:false
  },
  passwordForm:{
    changePassword:{
      value:'',
      message:'',
      valid:false
    },
    confirmPassword:{
      value:'',
      message:'',
      valid:false
    }
  }
}

export default createSlice({
  name: 'changepassword',
  initialState,
  reducers: {
    onMount: () => { },
    onUnmount: () => { },
    onError: (state, { payload }) => {
      state.error = payload
    },
    showEmail: (state, {payload}) => {
      state.showEmailComponent = payload
    },
    setEmail: (state, { payload }) => {
      state.email = payload
    },
    setToken: (state, {payload}) => {
      state.token = payload
    },
    setUid:(state, {payload}) => {
      state.uuid = payload
    },
    setEmailSent: (state, {payload}) => {
      state.emailSent = payload
    },
    onSubmitEmail: () => {},
    showChangePassword: (state, { payload }) => {
      state.showChangePasswordComponent = payload
    },
    onChangePasswordForm: (state, { payload }) => {
      state.passwordForm = payload
    },
    onSubmitPassword: () => {},
  }
})