import styled from 'styled-components'
import theme from 'ui/theme'


export const Wrapper = styled.div`
 width: 100%;
 height: 100%;
 padding: 50px;
 display: flex;
 align-items: center;
 justify-content: center;
`

export const Spinner = styled.div`
 display: inline-block;
 position: relative;
 width: 40px;
 height: 40px;
 
 > div {
   box-sizing: border-box;
   display: block;
   position: absolute;
   width: 40px;
   height: 40px;
   margin: 4px;
   border: 4px solid ${theme().gray600Color};
   border-radius: 50%;
   animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
   border-color: ${theme().gray600Color} transparent transparent transparent;
 }
 > div:nth-child(1) {
   animation-delay: -0.45s;
 }
 > div:nth-child(2) {
   animation-delay: -0.3s;
 }
 > div:nth-child(3) {
   animation-delay: -0.15s;
 }
 
 @keyframes lds-ring {
   0% {
     transform: rotate(0deg);
   }
   100% {
     transform: rotate(360deg);
   }
 }
 `