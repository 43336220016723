import { PayloadAction } from '@reduxjs/toolkit'
import { takeLatest, putResolve, call, select  } from 'redux-saga/effects'
import API from 'api'
import { actions, RootState } from 'store/rootSlices'
import { toast } from 'react-toastify'
import * as Check from 'validations';
import { CampaignFormValidations, CampaignFormValidationsEdit, SubmitSagaPayload } from '../utils'
import i18n from 'i18next';
import config from 'config';
import { hasPermission } from 'permissions'
const { PERMISSIONS } = config

//state
const cmpgnID = (state: RootState) => state.TripCampaignForm.campaignID

function* onMountSaga({ payload }: PayloadAction<number>) {
  const { permissions } = yield select((state: RootState) => state.App);

  if (payload) {
    
    //yield putResolve(actions.TripCampaignForm.setAction(true))
    //yield putResolve(actions.TripCampaignForm.populateForm(""))
    const campaignID = payload

    try {
      //get campaign from api
      const campaignInfo = yield call(API.Campaigns.GetCampaign, campaignID)

      if (campaignInfo) {
        yield putResolve(actions.TripCampaignForm.setCampaignID(campaignID))
        yield putResolve(actions.TripCampaignForm.setAction(true))
        yield putResolve(actions.TripCampaignForm.setHasEditPermission(hasPermission(permissions, PERMISSIONS.TRIPS.CAMPAIGNS.EDIT_CAMPAIGN)))
        yield putResolve(actions.TripCampaignForm.populateForm(campaignInfo))
        yield putResolve(actions.TripCampaignForm.populateActivities(campaignInfo.activities))
      }
    } catch (error) {
      toast.error(i18n.t("toasts.getCampaignError"));
    }

  } else {
    // creating a new campaign
    yield putResolve(actions.TripCampaignForm.setAction(false))
  }
}

function* onSubmitSaga({ payload }: PayloadAction<SubmitSagaPayload>) {
  yield putResolve(actions.TripCampaignForm.setSubmitButtonState(true))
  const validation = payload.edit === true ?
    Check.checkValidationAlternative(payload.campaignForm, CampaignFormValidationsEdit)
    :
    Check.checkValidationAlternative(payload.campaignForm, CampaignFormValidations)

  if (validation.invalid === true) {
    const updateValidationMessages = Check.setAndCheckValidationAlternative(payload.campaignForm, payload.campaignForm, CampaignFormValidations)
    yield putResolve(actions.TripCampaignForm.onFormChange(updateValidationMessages))
    yield putResolve(actions.TripCampaignForm.setSubmitButtonState(false))
    toast.error(i18n.t("toasts.invalidFields"));
  }
  else {
    
    if (!payload.edit) {
      try {

        const finalForm = { 
          name: payload.campaignForm.name.value,
          prefix: payload.campaignForm.prefix.value,
          discount: payload.campaignForm.discount.value,
          quantity: payload.campaignForm.quantity.value,
          isDiscountPercentage:payload.campaignForm.isDiscountPercentage.value,
          hasMultipleCodes: payload.campaignForm.hasMultipleCodes.value,
          validityMonths: payload.campaignForm.validityMonths.value,
          sendEmailAfterNumberReservations: payload.campaignForm.sendEmailAfterNumberReservations.value,
          sendEmailBody: payload.campaignForm.sendEmailBody.value,
          isActive: payload.campaignForm.isActive.value,
          activities: payload.campaignActivities
        }

        const campaign = yield call(API.Campaigns.CreateCampaign, finalForm)

        if (campaign) {
          yield putResolve(actions.App.navigateTo('/viagens/configuracoes'))
          toast.success(i18n.t("toasts.createCampaignSuccess"));
        }

      } catch (error) {
        toast.error(i18n.t("toasts.createCampaignError"));
      } finally {
        yield putResolve(actions.TripCampaignForm.setSubmitButtonState(false))
      }
    }
    else {
      const campaignID = yield select(cmpgnID)
      /* const finalForm = { 
        ...payload.campaignForm,
        activities: payload.campaignActivities
      } */

      try {
        const success = yield call(API.Campaigns.EditCampaign, campaignID, payload.campaignForm)

        if (success) {
          yield putResolve(actions.App.navigateTo('/viagens/configuracoes'))
          toast.success(i18n.t("toasts.editCampaignSuccess"))
        }
      } catch (error) {
        toast.warning(i18n.t("toasts.editCampaignError"))
      } finally {
        yield putResolve(actions.TripCampaignForm.setSubmitButtonState(false))
      }
    }
  }

}

function* onUnmountSaga() {}


export default function* watcherSignin() {
  yield takeLatest('tripcampaignform/onMount', onMountSaga)
  yield takeLatest('tripcampaignform/onUnmount', onUnmountSaga)
  yield takeLatest('tripcampaignform/onSubmit', onSubmitSaga)
}

