/**
 * Tripcampaigncodesform scene
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'

import { actions, RootState } from 'store/rootSlices'
import { CCol, CForm, CRow } from '@coreui/react-pro'
import SectionContainer from 'shared/components/Sectioncontainer'
import CustomButton from 'shared/components/CustomButton'
import CustomTextInput from 'shared/components/CustomTextInput'
import Datepicker from 'shared/components/FormComponents/datepicker';
import theme from 'ui/theme';
import CustomCheckbox from 'shared/components/CustomCheckbox';
import { Row, ButtonWrapper } from '../styles';
import { GenerateColumnButton, Button} from './styles'

const Tripcampaigncodesform = ({ t, i18n }) => {

  const dispatch = useDispatch();
  const { id , prefix } = useParams();

  const { onMount, onUnmount, onFormChange, onSubmit } = actions.TripCampaignCodesForm;
  const { campaignCodeForm, edit, submitButtonState } = useSelector((state: RootState) => state.TripCampaignCodesForm)
  const { navigateBack } = actions.App;

  useEffect(() => {
    dispatch(onMount({id, prefix}))

    return () => {
      dispatch(onUnmount())
    }

  }, [dispatch, id, prefix, onMount, onUnmount]);

  const handleSubmit = (e) => {
    dispatch(onSubmit({ campaignCodeForm, e, edit }))
  }


  return (
    <div>
      <CForm>
        {/* {edit === true && <DropdownExport id='tripCampaignCodesForm-dropdownExport' dropdown={true} wrapperStyle={{ maxWidth: '100%' }} />} */}
        <CustomCheckbox
          id='tripCampaignCodesForm-isActive'
          wrapperStyle={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '-25px', fontSize: '20px' }}
          checked={campaignCodeForm.isActive.value ? true : false}
          onClick={e => dispatch(onFormChange({ ...campaignCodeForm, isActive: { value: e.target.checked, valid: true, message: "" } }))}
          text={i18n.t('tripCampaignCodesForm.isActive')}
          switch
        />
        <SectionContainer title={i18n.t('tripCampaignCodesForm.codeTitle')} style={{ width: '100%', paddingBottom: '24px' }}>
          <CRow className="g-3">
            <CCol sm={8}>
              <CustomTextInput
                id='tripCampaignCodesForm-uuid'
                required={true}
                disabled={edit ? true : false}
                valid={campaignCodeForm.uid.valid}
                value={campaignCodeForm.uid.value}
                helpText={campaignCodeForm.uid.message}
                label={i18n.t('tripCampaignCodesForm.code')}
                placeholder={''}
                onChange={(e) => dispatch(onFormChange({ ...campaignCodeForm, uid: { value: e.target.value, message: "", valid: true } }))}
              />
            </CCol>
            <CCol>
              <Datepicker
                id='tripCampaignCodesForm-expireAt'
                required={true}
                valid={campaignCodeForm.expireAt.valid}
                value={campaignCodeForm.expireAt.value}
                helpText={campaignCodeForm.expireAt.message}
                label={i18n.t('tripCampaignCodesForm.expiration')}
                onChange={(date, e) => dispatch(onFormChange({ ...campaignCodeForm, expireAt: { value: date, message: "", valid: true } }))}
              />
            </CCol>
          </CRow>
        </SectionContainer>
        <SectionContainer title={i18n.t('tripCampaignCodesForm.recipientInfoTitle')} style={{ width: '100%', paddingBottom: '24px' }}>
          <CRow>
            <CCol>
              <CustomTextInput
                id='tripCampaignCodesForm-destinationName'
                required={true}
                disabled={edit ? true : false}
                valid={campaignCodeForm.destinationName.valid}
                value={campaignCodeForm.destinationName.value}
                helpText={campaignCodeForm.destinationName.message}
                label={i18n.t('tripCampaignCodesForm.name')}
                placeholder={i18n.t('placeholders.name')}
                onChange={(e) => dispatch(onFormChange({ ...campaignCodeForm, destinationName: { value: e.target.value, message: "", valid: true } }))}
              />
            </CCol>
            <GenerateColumnButton>
              <CustomTextInput
                id='tripCampaignCodesForm-destinationEmail'
                required={true}
                valid={campaignCodeForm.destinationEmail.valid}
                value={campaignCodeForm.destinationEmail.value || ''}
                helpText={campaignCodeForm.destinationEmail.message}
                wrapperStyle={{ width: '78%', alignSelf: 'flex-start' }}
                label={"Email"}
                placeholder={i18n.t('placeholders.email')}
                onChange={(e) => dispatch(onFormChange({ ...campaignCodeForm, destinationEmail: { value: e.target.value, message: "", valid: true } }))}
              />
              <Button id='tripCampaignCodesForm-sendEmail' color="primary" valid={campaignCodeForm.destinationName.valid === false || campaignCodeForm.destinationEmail.valid === false ? false : true}
                variant="outline"
                onClick={(e) => { }}>
                {i18n.t('tripCampaignCodesForm.sendCode')}
              </Button>
            </GenerateColumnButton>
          </CRow>
          <Row>
            <CustomTextInput
              id='tripCampaignCodesForm-destinationBody'
              type="textarea"
              required={true}
              valid={campaignCodeForm.destinationBody.valid}
              value={campaignCodeForm.destinationBody.value}
              helpText={campaignCodeForm.destinationBody.message}
              placeholder={i18n.t('placeholders.description')}
              label={i18n.t('tripCampaignCodesForm.sendDescription')}
              onChange={(e) => dispatch(onFormChange({ ...campaignCodeForm, destinationBody: { value: e.target.value, message: "", valid: true } }))}
            />
          </Row>
        </SectionContainer>
        <ButtonWrapper>
          <CustomButton id='tripCampaignCodesForm-cancel' onClick={() => dispatch(navigateBack())} text={i18n.t('common.cancelBtn')} color="link" buttonStyle={{ color: theme().danger, textDecoration: 'unset' }} />
          <CustomButton id='tripCampaignCodesForm-save' disabled={submitButtonState} text={i18n.t('common.saveBtn')} icon="confirm" onClick={e => handleSubmit(e)} color="success" buttonStyle={{ color: "#FFFFFF" }} />
        </ButtonWrapper>
      </CForm>
    </div>
  )
}

export default Tripcampaigncodesform
