import * as React from "react"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width={14}
            height={14}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M13.375.25l-.12.023L9.25 1.825 4.75.25.52 1.675a.378.378 0 00-.27.36v11.34c0 .21.165.375.375.375l.12-.023 4.005-1.552 4.5 1.575 4.23-1.425a.378.378 0 00.27-.36V.625a.371.371 0 00-.375-.375zM5.5 2.103l3 1.05v8.745l-3-1.05V2.101zm-3.75.742L4 2.087v8.776l-2.25.87V2.845zm10.5 8.31l-2.25.758V3.144l2.25-.87v8.88z"
                fill={props.fill ? props.fill : "#EBEDEF"}
            />
        </svg>
    )
}

export default SvgComponent
