/**
 * Usertimerecorddetails scene
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */
import React, { useEffect } from 'react';
import { Area, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CImage } from '@coreui/react-pro';
import { DateTime, Duration } from 'luxon';
// Store
import { actions, RootState } from 'store/rootSlices';
// Icons
import User from 'assets/icons/user';
import Phone from 'assets/icons/phone';
import Email from 'assets/icons/mail1';
import Download from 'assets/icons/file_download';
// Components
import FilterDatePicker from 'shared/components/FilterDatePicker';
import { presetsLists } from 'shared/components/FilterDatePicker/utils';
import Edittimerecord from './components/Edittimerecord';
import Timerecordevents from './components/Timerecordevents';
import Loadingcontainer from 'shared/components/Loadingcontainer';
import CustomButton from 'shared/components/CustomButton';
// Utils &  Configs
import { hasPermission } from 'permissions';
import config from 'config';
// Sytles
import theme from 'ui/theme';
import { ControlsContainer, Controls1, Controls2 } from 'shared/genericStyledComponents';
import * as Styles from './styles'

const UserTimeRecordDetails = ({ t, i18n }) => {
  const {
    user,
    loading,
    addedTimeRecords,
    editedTimeRecords,
    deletedTimeRecords,
    submitButtonState,
    workdays,
    days,
    hasDateParams,
    graphTab,
    commissions,
    exportLoading
  } = useSelector((state: RootState) => state.UserTimeRecordDetails);

  const { startDate, endDate } = useSelector((state: RootState) => state.TimeRecord);

  const { isSidebarOpen } = useSelector((state: RootState) => state.Dashboard);

  const { permissions } = useSelector((state: RootState) => state.App);

  const { PERMISSIONS } = config;

  const {
    onMount,
    onUnmount,
    setDatesInterval,
    clearAddedTimeRecords,
    clearEditedTimeRecords,
    clearDeletedTimeRecords,
    onSubmitTimeRecords,
    getTimeRecordFile,
    setGraphTab,
  } = actions.UserTimeRecordDetails;

  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();

  useEffect(() => {
    dispatch(onMount({ id, history }));
    return () => {
      dispatch(onUnmount());
    };
  }, [dispatch, onMount, onUnmount, id, history]);

  let commissionsDates = [
    ...new Set(commissions.map((cm) => DateTime.fromISO(cm.date).toISODate())),
  ];

  let addedCommissions = commissionsDates.map((cm) => {
    let sameDayCommissions = commissions.filter((c) => cm === DateTime.fromISO(c.date).toISODate());

    let amounts = sameDayCommissions.reduce(
      (acc, curr) => {
        acc.finalAmount += Number(curr.finalAmount);
        return acc;
      },
      { finalAmount: 0 }
    );

    return {
      date: cm,
      finalAmount: Number(amounts.finalAmount.toFixed(2)),
    };
  });

  let orderedDates = days.map((d) => d.date).reverse();
  let labelDates = orderedDates.map((od) => DateTime.fromISO(od).toFormat('MM/dd'));

  let hoursDataGraph: any[] = [];
  let commissionsDataGraph: any[] = [];

  orderedDates.forEach((od, idx) => {
    if (workdays.find((wd) => wd.date === od)) {
      hoursDataGraph.push({
        date: labelDates[idx],
        [t('timeRecord.hours')]: Number(
          Duration.fromObject({
            minutes: workdays.find((wd) => wd.date === od).timeWorking,
          }).toFormat('hh.mm')
        ),
        [t('timeRecord.onBoard')]: Number(
          Duration.fromObject({
            minutes: workdays.find((wd) => wd.date === od).timeOnBoard,
          }).toFormat('hh.mm')
        ),
      });
    } else {
      hoursDataGraph.push({
        date: labelDates[idx],
        [t('timeRecord.hours')]: 0,
        [t('timeRecord.onBoard')]: 0,
      });
    }

    if (addedCommissions.find((ac) => ac.date === od)) {
      commissionsDataGraph.push({
        date: labelDates[idx],
        [t('timeRecord.commissions')]: addedCommissions.find((ac) => ac.date === od)?.finalAmount,
      });
    } else {
      commissionsDataGraph.push({
        date: labelDates[idx],
        [t('timeRecord.commissions')]: 0,
      });
    }
  });

  // custom tooltip formatter function
  const tooltipFormatter = (value) => {
    const formatedValue = value.toString().split('.').join(':');
    return formatedValue;
  };

  return (
    <>
      <Styles.Wrapper>
        {loading ? (
          <Loadingcontainer />
        ) : (
          <>
            <Styles.UserSummary>
              <ControlsContainer>
                <Controls1>
                  <FilterDatePicker
                    filterName={i18n.t('tripPlannings.today')}
                    range
                    hasBorder
                    child
                    showMonthYearDropdown
                    reRender={hasDateParams}
                    presets={presetsLists().timeRecord}
                    defaultValue={{
                      startDay: startDate ? DateTime.fromISO(startDate).toJSDate() : new Date(),
                      endDay: endDate ? DateTime.fromISO(endDate).toJSDate() : new Date(),
                    }}
                    onChange={(e) => {
                      if (e.startDate && e.endDate) {
                        dispatch(setDatesInterval({ e, history, userId: user.uuid }));
                        dispatch(clearEditedTimeRecords());
                        dispatch(clearAddedTimeRecords());
                        dispatch(clearDeletedTimeRecords());
                      }
                    }}
                  />
                </Controls1>
                <Controls2>
                  {/* <Text>
                      {t('timeRecord.totalIntervalHours', { onBoard: 3, total: 6 })}
                    </Text> */}
                  {hasPermission(
                    permissions,
                    PERMISSIONS.USERS.TIME_RECORD.EXPORT_WORKDAY_ENTRY
                  ) && (
                    <Styles.DownloadButton onClick={() => {
                      if(!exportLoading) dispatch(getTimeRecordFile(user?.uuid))
                    }}>
                      <Download width={30} height={30} />
                    </Styles.DownloadButton>
                  )}
                </Controls2>
              </ControlsContainer>
              <Styles.CustomSectionContainer style={{ width: '28%' }}>
                <Styles.UserMainInfo>
                  <Styles.AvatarWrapper>
                    {user.avatar ? (
                      <CImage src={user.avatar} alt="profile"></CImage>
                    ) : (
                      <User width={80} height={80} viewBox="-5.5 -5 28 28" />
                    )}
                  </Styles.AvatarWrapper>
                  <div>
                    <Styles.CustomUserTitle>{user.name}</Styles.CustomUserTitle>
                    <Styles.Text color={theme().gray700Color}>{user.professionalCategory}</Styles.Text>
                    <Styles.Separator />
                    <Styles.SmallText color={theme().gray500Color}>
                      {`${i18n.t('timeRecord.lastActivity')} : ${
                        user.lastClocked && user.lastClocked.hasFinished === false
                          ? DateTime.fromISO(user.lastClocked.clockIn).toFormat("HH:mm 'of' dd LLL")
                          : i18n.t('timeRecord.offline')
                      }`}
                    </Styles.SmallText>
                  </div>
                </Styles.UserMainInfo>
                <Styles.Separator />
                <Styles.ContactInfo>
                  <Styles.LineInfo>
                    <span>
                      <Phone
                        width={25}
                        height={25}
                        viewBox="-5 -6 25 25"
                        fill={theme().darkColor}
                      />
                      <Styles.Text color={theme().gray700Color}>{i18n.t('timeRecord.phone')}</Styles.Text>
                    </span>
                    <Styles.Text color={theme().gray500Color} alignment="right">
                      {user.phone}
                    </Styles.Text>
                  </Styles.LineInfo>
                  <Styles.LineInfo>
                    <span>
                      <Email
                        width={25}
                        height={25}
                        viewBox="-5 -6 25 25"
                        fill={theme().darkColor}
                      ></Email>
                      <Styles.Text color={theme().gray700Color}>Email:</Styles.Text>
                    </span>
                    <Styles.Text color={theme().gray500Color} alignment="right">
                      {user.email}
                    </Styles.Text>
                  </Styles.LineInfo>
                </Styles.ContactInfo>
                <Styles.Separator />
                <Styles.ContractInfo>
                  <Styles.LineInfo>
                    <Styles.Text color={theme().gray700Color}>{i18n.t('timeRecord.atAnimaris')}</Styles.Text>
                    <Styles.Text color={theme().gray500Color} alignment="right">
                      {user.contractHireDate}
                    </Styles.Text>
                  </Styles.LineInfo>
                  <Styles.LineInfo>
                    <Styles.Text color={theme().gray700Color}>{i18n.t('timeRecord.contractType')}</Styles.Text>
                    <Styles.Text color={theme().gray500Color} alignment="right">
                      {user.contractType}
                    </Styles.Text>
                  </Styles.LineInfo>
                  <Styles.LineInfo>
                    <Styles.Text color={theme().gray700Color}>{i18n.t('timeRecord.jobLocation')}</Styles.Text>
                    <Styles.Text color={theme().gray500Color} alignment="right">
                      Faro
                    </Styles.Text>
                  </Styles.LineInfo>
                </Styles.ContractInfo>
              </Styles.CustomSectionContainer>

              <Styles.GraphSectionContainer>
                <Styles.CustomControlContainer>
                  <Controls1>
                    <Styles.DaysButton
                      id="timeRecord-intervalButton"
                      role="group"
                      aria-label="Basic checkbox toggle button group"
                    >
                      <Styles.Day
                        type="radio"
                        checked={graphTab === 'hours' ? true : false}
                        onClick={(e) => dispatch(setGraphTab('hours'))}
                        button={{ color: 'secondary', variant: 'outline' }}
                        name="btnradio"
                        key={`day${1}`}
                        id={`timeRecord-week${1}`}
                        color="secondary"
                        label={'Horas trabalhadas'}
                      />
                      <Styles.Day
                        type="radio"
                        checked={graphTab === 'commissions' ? true : false}
                        onClick={(e) => dispatch(setGraphTab('commissions'))}
                        button={{ color: 'secondary', variant: 'outline' }}
                        name="btnradio"
                        key={`day${2}`}
                        id={`timeRecord-month${2}`}
                        color="secondary"
                        label={'Total comissões'}
                      />
                    </Styles.DaysButton>
                  </Controls1>
                </Styles.CustomControlContainer>
                {graphTab === 'hours' ? (
                  <Styles.ResponsiveCContainer width="100%" aspect={4}>
                    <Styles.AreaCChart
                      data={hoursDataGraph}
                      height={300}
                      margin={{
                        top: 0,
                        right: 30,
                        left: 0,
                        bottom: 0,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="date" />
                      <YAxis tickCount={5} minTickGap={1} domain={[0, 'auto']} />
                      <Tooltip
                        contentStyle={{ border: '1px solid grey' }}
                        formatter={tooltipFormatter}
                      />
                      <Area
                        type="monotone"
                        dataKey={t('timeRecord.hours')}
                        stroke="blue"
                        fill="#11eeFF90"
                      />
                      <Area
                        type="monotone"
                        dataKey={t('timeRecord.onBoard')}
                        stroke="#3399FF"
                        fill="#99eeee"
                      />
                    </Styles.AreaCChart>
                  </Styles.ResponsiveCContainer>
                ) : (
                  <Styles.ResponsiveCContainer width="100%" aspect={4}>
                    <Styles.AreaCChart
                      data={commissionsDataGraph}
                      height={300}
                      margin={{
                        top: 0,
                        right: 30,
                        left: 0,
                        bottom: 0,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="date" />
                      <YAxis tickCount={5} minTickGap={1} domain={[0, 'auto']} />
                      <Tooltip contentStyle={{ border: '1px solid grey' }} />
                      <Area
                        type="monotone"
                        dataKey={t('timeRecord.commissions')}
                        stroke="blue"
                        fill="#11eeFF90"
                      />
                    </Styles.AreaCChart>
                  </Styles.ResponsiveCContainer>
                )}
              </Styles.GraphSectionContainer>
            </Styles.UserSummary>
            <Edittimerecord t={t} i18n={i18n} />
            <Timerecordevents t={t} i18n={i18n} />
          </>
        )}
      </Styles.Wrapper>

      {(editedTimeRecords.length > 0 ||
        addedTimeRecords.length > 0 ||
        deletedTimeRecords.length > 0) && (
        <Styles.SaveTimeRecords sidebar={isSidebarOpen}>
          <p style={{ color: theme().gray500Color, margin: 0 }}>
            {t('timeRecord.timeRecordsCount', {
              count: editedTimeRecords.length + addedTimeRecords.length + deletedTimeRecords.length,
            })}
          </p>

          <div
            style={{
              display: 'flex',
              width: '350px',
              justifyContent: 'flex-end',
              columnGap: '16px',
              alignItems: 'center',
            }}
          >
            <CustomButton
              id="timeRecord-discardChanges"
              text={t('common.discardChanges', { numChecks: 0 })}
              variant="ghost"
              color="none"
              onClick={() => {
                if (editedTimeRecords.length > 0) dispatch(clearEditedTimeRecords());
                if (addedTimeRecords.length > 0) dispatch(clearAddedTimeRecords());
                if (deletedTimeRecords.length > 0) dispatch(clearDeletedTimeRecords());
              }}
              buttonStyle={{ color: theme().danger }}
            />
            <CustomButton
              id="timeRecord-save"
              text={t('common.saveBtn', { numChecks: 0 })}
              icon="confirm"
              disabled={submitButtonState}
              onClick={() => {
                dispatch(onSubmitTimeRecords());
              }}
              color="success"
              buttonStyle={{ color: theme().white }}
            />
          </div>
        </Styles.SaveTimeRecords>
      )}
    </>
  );
};

export default UserTimeRecordDetails;