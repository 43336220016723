import styled from 'styled-components';

import { CButtonGroup, CFormCheck } from '@coreui/react-pro';
import theme from 'ui/theme';

export const FiltersWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;

  > div {
    margin-left: 30px;
  }
`;

export const ControlsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
  row-gap: 12px;

  > div {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`;

export const TabsWrapper = styled(CButtonGroup)`
  display: flex;
  justify-content: flex-start;
  background-color: ${theme().white};
`;

export const TabsMainWrapper = styled(CButtonGroup)`
  display: flex;
  justify-content: flex-start;
  gap: 24px;
  background-color: ${theme().white};
`;

export const Tab = styled(CFormCheck)`
  margin: 0px;
  border: 1px solid ${theme().secondaryColor};
  padding: 5px 11px !important;
  color: ${theme().secondaryColor};
`;
